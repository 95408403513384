import styled from 'styled-components';

export const GameContainer = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  color: black;
`;
export const SudokuContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.4em;
  font-weight: 300;
`;

export const SudokuOverlayContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-shadow: ${(props) => `${props.overlay ? 'inset 0px 0px 500px 300px rgba(0, 0, 0, .5)' : ''}`};
  filter: ${(props) => `${props.overlay ? 'opacity(80%) brightness(50%)' : ''}`};
`;

export const SudokuGameContainer = styled.div`
  max-width: 800px;
  min-width: 270px;
  margin: 0 auto;
  padding: 0 10px;

  @media screen and (max-width: 670px) {
    width: 100%;
    padding: 0;
  }
`;

export const StatusSectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;

  @media screen and (max-width: 670px) {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
`;

export const StatusContainer = styled.section`
  width: 30%;

  @media screen and (max-width: 670px) {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
`;

// Display Numbers
export const StatusNumbers = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
  top: 40px;
  left: 10px;
  column-gap: 8px;
  row-gap: 8px;
  width: 100%;

  @media screen and (max-width: 670px) {
    top: -10px;
    left: 0;
    width: 100%;
    grid-template-columns: repeat(9, 1fr);
    padding: 0 20px;
  }
`;

export const StatusNumber = styled.button`
  background-color: transparent;
  text-align: center;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.625rem;
  width: 100%;
  padding: 3px 5px 3px 5px;
  cursor: pointer;
  color: ${(props) => (props.selected ? ' white' : 'black')};
  background-color: ${(props) => (props.selected ? '#61a5e2' : 'white')};
  border: 2px solid #61a5e2;
  border-radius: 45%;

  @media screen and (max-width: 500px) {
    font-size: 1.125rem;
  }

  @media screen and (max-width: 300px) {
    font-size: 0.875rem;
  }
`;

// game options

export const StatusOptions = styled.div`
  position: relative;
  top: 50px;
  left: 10px;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 670px) {
    top: 0;
    left: 0;
    width: 100%;
  }
`;

export const ActionButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  text-align: center;
  position: relative;
  width: 33.33%;

  font-size: 1rem;
  letter-spacing: 0.7px;
  line-height: 1.2;
  padding-top: 50px;

  @media screen and (max-width: 500px) {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 670px) {
    top: 0;
    left: 0;
    width: 33.33%;
  }
  @media screen and (max-width: 350px) {
    width: 33%;
  }
`;

export const ActionIcon = styled.i`
  font-size: 1.875rem;
  color: #72a351;
  line-height: 0.8;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  letter-spacing: 0.7px;
  line-height: 0.1;
  top: 20px;

  @media screen and (max-width: 670px) {
    font-size: 2.375rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 2.1875rem;
  }
  @media screen and (max-width: 350px) {
    font-size: 1.875rem;
  }
`;

export const SudokuGameBoardContainer = styled.section`
  width: 70%;

  @media screen and (max-width: 670px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
  }
`;

export const GameBoardContainer = styled.table`
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.625rem;
  margin: 30px 0;
  border: 2px solid #434755;
  border-collapse: collapse;

  @media screen and (max-width: 670px) {
    width: 100%;
  }
`;

export const GameBoardRow = styled.tr`
  &:nth-child(3n) {
    border-bottom: 2px solid #434755;
  }
`;

export const GameBoardCell = styled.td`
  border: 1px solid #c4c3c8;
  padding: 12px 16px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;

  &:button {
    width: 100%;
    height: 100%;
  }

  color: ${(props) => (props.userfilled ? 'black' : props.filled ? 'black' : props.selected ? 'black' : '')};

  background-color: ${(props) =>
    props.highlight ? '#f9c82b' : props.userfilled ? '#fff' : props.selected || props.filled ? '#e0e0e0' : ''};

  &:nth-child(3n) {
    border-right: 2px solid #434755;
  }

  @media screen and (max-width: 670px) {
    text-align: center;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.125rem;
    padding: 8px 0;
  }
  @media screen and (max-width: 350px) {
    font-size: 1rem;
    padding: 4px 0;
  }
`;

export const GameButtonContainer = styled.div`
  border-top: 2px solid #dfdfdf;
  position: relative;
  display: flex;
  width: 100%;
  top: 60px;
  left: 10px;
  height: 80px;
  justify-content: space-between;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  column-gap: 5%;
  padding: 20px 2px 0 2px;
  font-size: 1.25rem;
  margin-bottom: 10px;

  @media screen and (max-width: 670px) {
    top: 0;
    left: 0;
    column-gap: 20%;
    padding: 18px 15% 0 15%;
  }
`;

export const GameButton = styled.button`
  border: 0;
  border-radius: 15px;
  display: inline-block;
  background-color: ${(props) => `${props.done && props.overlay ? '#939393' : '#2a7e3e'}`};
  color: white;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 100%;
  font-size: ${(props) => `${props.overlay ? '1.375rem' : '1.25rem'}`};

  @media screen and (max-width: 670px) {
    padding: 0;
  }
  @media screen and (max-width: 350px) {
    font-size: ${(props) => `${props.overlay ? '1rem' : '1.25rem'}`};
    padding: 0;
  }
`;

export const SolvedContainer = styled.div`
  display: ${(props) => `${props.overlay ? 'block' : 'none'}`};
  position: fixed;
  width: 100%;
  height: 44%;
  top: 56%;
  bottom: 0;
  margin: 0;
  padding: 0;

  background-color: #f0eaf6;

  @media screen and (max-width: 670px) {
    height: 42%;
    top: 68%;
  }
`;

export const SolvedButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 10%;
  padding: 4% 10% 8% 10%;
  height: 80%;

  @media screen and (max-width: 900px) {
    padding-top: 8%;
    padding-bottom: 6%;
    height: 60%;
  }
  @media screen and (max-width: 670px) {
    padding-top: 6%;
    padding-bottom: 4%;
    height: 40%;
  }

  @media screen and (max-width: 500px) {
    height: 40%;
    padding-top: 6%;
    padding-bottom: 4%;
  }

  @media screen and (max-width: 350px) {
    height: 40%;
    padding-top: 7%;
    padding-bottom: 5%;
  }
`;

export const RudySolvedImage = styled.img`
  height: 100%;
`;

export const RudySolvedImageContainer = styled.div`
  background: #9144b8;
  box-shadow: 0 5px 4px 2px rgba(0, 0, 0, 0.3);
  padding-top: 12px;
  height: 35%;
  margin: auto;
  display: flex;
  align-items: center;
`;
