import { Deploy } from 'cordova-plugin-ionic';
import { isEnvironment, LOCAL } from 'Core_Helpers/isEnvironment';
import { appInsights } from 'Core_Helpers/AppInsights';

const configureLiveUpdate = async () => {
  try {
    if (!isEnvironment(LOCAL)) {
      const envConfig = {
        appId: '07672dea',
        channel: process.env.LIVE_CHANNEL ?? 'Production_v2',
        updateMethod: 'none',
      };
      await Deploy.configure(envConfig);
    }
  } catch (error) {
    appInsights.trackException({ exception: error });
  }
};

export { configureLiveUpdate };
