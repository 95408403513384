import React, { forwardRef } from 'react';
import { Switch as MuiSwitch } from '@mui/material';
import PropTypes from 'prop-types';

const Switch = forwardRef(({ inputProps, ...props }, ref) => {
  const extraInputProps = { 'aria-labelledby': props['aria-labelledby'] };
  return <MuiSwitch ref={ref} inputProps={{ ...inputProps, ...extraInputProps }} />;
});

Switch.displayName = 'Switch';

Switch.propTypes = {
  inputProps: PropTypes.object,
  ref: PropTypes.object,
  ['aria-labelledby']: PropTypes.string,
};

export default Switch;
