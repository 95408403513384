import React from 'react';
import {
  PageContainer,
  StyledBackButton,
  StyledContainerWithLinearGradientBackground,
  StyledChatOptionsContainer,
  StyledHeaderContent,
} from './styles';
import { Typography } from '@mui/material';
import { transformText } from 'Core_Helpers/transformText';
import { useIntl } from 'react-intl';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectSyncUser } from 'Core_Redux/user';
import { ArrowBackIos } from '@mui/icons-material';
import TalkToSomeoneItems from 'Core_Pages/TalkToSomeone/TalkToSomeoneItems/TalkToSomeoneItems';
import DirectMessage from 'Core_Pages/DirectMessage/DirectMessage/DirectMessage';
import { DirectMessageContainer } from 'Core_Pages/DirectMessage/DirectMessage/styles';
import { DirectMessageStore } from 'Core_Pages/DirectMessage/Store/DirectMessageStore';
import useMediaType from 'Core_Helpers/useMediaType';
import { breakpoints } from 'Styles/theme';
import { SessionStatus } from 'Core_Pages/DirectMessage/Models/SessionStatus';

const TalkToSomeone = () => {
  const intl = useIntl();
  const mediaType = useMediaType();
  const syncUser = useCoreSelector(selectSyncUser);
  const directMessageStore = DirectMessageStore.Instance().Use();
  const isChatOpen = directMessageStore.IsChatOpen;
  const hasUnreadMessage = directMessageStore.HasUnreadMessage;
  const sessionStatus = directMessageStore.SessionStatus;

  if (hasUnreadMessage || (mediaType !== breakpoints.MOBILE && sessionStatus !== SessionStatus.DISCONNECTED)) {
    directMessageStore.OpenChat();
  }

  const onBack = () => {
    directMessageStore.HideSession();
  };

  return (
    <PageContainer>
      <StyledContainerWithLinearGradientBackground id="Header">
        <StyledHeaderContent className="safe-area">
          <StyledBackButton onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })}>
            <ArrowBackIos />
          </StyledBackButton>
          <Typography align="center" fontSize={18} fontWeight={400}>
            {transformText(intl.formatMessage({ id: 'talk-to-someone.header' }), syncUser)}
          </Typography>

          <Typography align="center" fontSize={24} fontWeight={400} marginTop={'24px'}>
            {intl.formatMessage({ id: 'talk-to-someone.sub-header-1' })}
          </Typography>
          <Typography align="center" fontSize={24} fontWeight={400}>
            {intl.formatMessage({ id: 'talk-to-someone.sub-header-2' })}
          </Typography>
        </StyledHeaderContent>
      </StyledContainerWithLinearGradientBackground>
      <StyledChatOptionsContainer>
        <TalkToSomeoneItems />
      </StyledChatOptionsContainer>
      {isChatOpen && (
        <DirectMessageContainer>
          <DirectMessage />
        </DirectMessageContainer>
      )}
    </PageContainer>
  );
};

export default TalkToSomeone;
