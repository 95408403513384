import { uiTypes } from 'Core_Games/constants/uiTypes';
// import { sceneType } from 'Core_Games/constants/SCENETYPE';
import { gameData } from 'Core_Games/constants/gameData';
import { bodyPart } from 'Core_Games/constants/bodyPart';
import { InteractionType } from 'Core_Games/constants/interactionType';
import { playerActorType } from 'Core_Games/constants/playerActorType';
import { animationType } from 'Core_Games/constants/animationType';

export const keySceneDataTypes = {
  // Objects
  PROJECTILE: 'PROJECTILE',
  BACKGROUND: 'BACKGROUND',
  PROJECTILETHROWN_SOUND: 'PROJECTILETHROWN_SOUND',
  // Captions
  INTRO_CAPTION: 'INTRO_CAPTION',
  AGAIN_CAPTION: 'AGAIN_CAPTION',
  OUTRO_CAPTION: 'OUTRO_CAPTION',
  CARE_CAPTION: 'CARE_CAPTION',
  BACK_CAPTION: 'BACK_CAPTION',
  // Buttons
  DONE_BUTTON: 'DONE_BUTTON',
  LAUNCH_BUTTON: 'LAUNCH_BUTTON',
  MAYBELATER_BUTTON: 'MAYBELATER_BUTTON',
  LETSGO_BUTTON: 'LETSGO_BUTTON',
  OUTRO_BUTTON: 'OUTRO_BUTTON',
  READY_BUTTON: 'READY_BUTTON',
  BACK_BUTTON: 'BACK_BUTTON',
  // Screen
  SCREEN_INTRO: 'SCREEN_INTRO',
  SCREEN_AGAIN: 'SCREEN_AGAIN',
  SCREEN_CARE: 'SCREEN_CARE',
  SCREEN_OUTRO: 'SCREEN_OUTRO',
  SCREEN_BACK: 'SCREEN_BACK',
};

export const chaseConfig = {
  // PHYSICS: [sceneType.FAKE3DPHYSICSWORLD.NAME],
  PHYSICS: ['fake3dphysicsworld'], // TODO: figure out how to get around the odd import issue when importing scenetype into chaseConfig only
  [playerActorType.DOG.NAME]: {
    [InteractionType.FETCH.NAME]: {
      GAMEBACKGROUNDCOLOR: '#FFFFFF',
      NAME: InteractionType.FETCH.NAME,
      FLIPXATSTART: true,
      FLIPTURN: false,
      HORIZON_LINE: 0.4,
      JUMPVELOCITY: 200,
      ACTIONLIMIT: 5,
      GROUND_LINE: 0.9,
      MOVESPEED: 80,
      WALKSPEED: 50,
      ENDX: 0.6,
      ENDY: 0.75,
      STARTX: 0.6,
      STARTY: 0.8,
      PETSCALE: 1.85,
      MAXPETSCALE: 1.85,
      DROPPROJECTILE: false,
      USERENDERER: true,
      TURNBEFORERUNNING: true,
      OBJECTMOVINGTHRESH: {
        x: 200,
        y: 50,
      },
      ANIMATIONS: {
        START: { NAME: animationType.IDLE6, FLIP: false },
        ENDPOSE: { NAME: animationType.POSE_PROUD, FLIP: false },
        STARTPOSE: { NAME: animationType.POSE_PROUD, FLIP: false },
        RETURNTOIDLE: { NAME: animationType.POSE_PROUD, FLIP: true },
        PICKUPTRANSITION: { NAME: animationType.BACKTURN_PARTIAL, FLIP: true },
        PICKUP: { NAME: animationType.OBJECT_INTERACT2, FLIP: true },
        PICKUPFINISH: { NAME: animationType.OBJECT_INTERACT2, REVERSE: true },
      },
      PROJECTILESCALEFACTOR: 0.4,
      RETURNTOSTARTDURATION: 1500,
      BALLPHYSICSPARAMS: {
        SCALE: 0.075,
        MINSCALE: 0.05,
        MAXSCALE: 0.1,
        position: {
          x: 0.0,
          y: 0.10606061,
        },
        worldSize: {
          x: 1.0,
          y: 0.5,
        },
      },
      BACKGROUNDSPLIT: 1,
      BOUNDS: {
        HEIGHT: {
          START: 0.08606061,
          END: 0.363333333,
        },
        WIDTH: {
          START: 0.2,
          END: 0.0,
        },
      },
      CATCH_BODYPART: bodyPart.MOUTH,
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.DOG.PARK,
        [keySceneDataTypes.PROJECTILE]: gameData.ACTOR.ITEM.WEARABLE.BALL,
      },
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.DONE_BUTTON,
              TEXTID: 'pets.buttons.catch.done',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_AGAIN],
            },
            {
              NAME: keySceneDataTypes.LAUNCH_BUTTON,
              TEXTID: 'pets.buttons.catch.throw-ball',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_INTRO, keySceneDataTypes.SCREEN_AGAIN],
            },
            {
              NAME: keySceneDataTypes.MAYBELATER_BUTTON,
              TEXTID: 'pets.buttons.catch.maybe-later',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_CARE],
            },
            {
              NAME: keySceneDataTypes.LETSGO_BUTTON,
              TEXTID: 'pets.buttons.catch.lets-go',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_CARE],
            },
            {
              NAME: keySceneDataTypes.OUTRO_BUTTON,
              TEXTID: 'pets.buttons.catch.ok',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.BACK_BUTTON,
              TEXTID: 'pets.buttons.catch.back',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_BACK],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.INTRO_CAPTION,
              TITLEID: 'pets.caption.title.catch.fetch-intro',
              TEXTID: 'pets.caption.description.catch.ready.dog',
            },
            {
              NAME: keySceneDataTypes.AGAIN_CAPTION,
              TITLEID: 'pets.caption.title.catch.again',
            },
            {
              NAME: keySceneDataTypes.CARE_CAPTION,
              TITLEID: 'pets.caption.title.catch.care',
              TEXTID: 'pets.caption.description.catch.care',
            },
            {
              NAME: keySceneDataTypes.OUTRO_CAPTION,
              TITLEID: 'pets.caption.title.catch.tired',
            },
            {
              NAME: keySceneDataTypes.BACK_CAPTION,
              TITLEID: 'pets.caption.title.catch.back',
            },
          ],
        },
      ],
    },
    [InteractionType.BUTTERFLYCHASE.NAME]: {
      NAME: InteractionType.BUTTERFLYCHASE.NAME,
      FLIPXATSTART: false,
      HORIZON_LINE: 0.7,
      GROUND_LINE: 0.9,
      JUMPVELOCITY: 300,
      ACTIONLIMIT: 1,
      DISABLEPROJECTILEGRAVITY: true,
      FLIPTURN: true,
      CATCH_BODYPART: bodyPart.MOUTH,
      CATCH_OBJECT: false,
      CATCH_TOUCH_TIMES: 1,
      MINPROJECTILESCALE: 0.5,
      PROJECTILESCALEFACTOR: 0.5,
      PETSCALE: 1.675,
      MAXPETSCALE: 1.675,
      STARTX: 0.2,
      STARTY: 0.9,
      PETCHASEOFFSET: 0.2,
      ANIMATIONS: {
        IDLEREADY: { NAME: animationType.SIT, REVERSE: true },
        RETURNTOIDLE: { NAME: animationType.SIT, FLIP: false },
        PICKUP: { NAME: animationType.OBJECT_INTERACT1 },
        PICKUPFINISH: { NAME: animationType.OBJECT_INTERACT1, REVERSE: true },
      },
      CATCHENDPERCENTAGE: { x: -90.85, y: -0.4, MOVESPEED: 225 },
      BALLPHYSICSPARAMS: {
        startFromRight: true,
        velocity: { min: 100, max: 100 },
        bounceFactor: { min: 1, max: 1 },
        position: { x: 0.0, y: 0.0 },
        start: { x: 0.95, z: 0.0, y: 0.4 },
        end: { y: 0.8 },
        worldSize: {
          y: 0.5,
        },
      },
      ASSETS: {
        [keySceneDataTypes.PROJECTILE]: gameData.ACTOR.ITEM.OBJECT.BUTTERFLY,
      },
      UI: [],
    },
  },
  [playerActorType.CAT.NAME]: {
    [InteractionType.BUTTERFLYCHASE.NAME]: {
      NAME: InteractionType.BUTTERFLYCHASE.NAME,
      FLIPXATSTART: true,
      FLIPTURN: true,
      HORIZON_LINE: 0.7,
      JUMPVELOCITY: 450,
      ACTIONLIMIT: 1,
      FLIPATEND: true,
      DISABLEPROJECTILEGRAVITY: true,
      CATCH_BODYPART: bodyPart.MOUTH,
      CATCH_OBJECT: false,
      CATCH_TOUCH_TIMES: 1,
      MINPROJECTILESCALE: 0.5,
      PROJECTILESCALEFACTOR: 0.5,
      ENDY: 0.0,
      PETSCALE: 1.675,
      MAXPETSCALE: 1.675,
      CATCHENDPERCENTAGE: { x: -90.85, y: -1.5, MOVESPEED: 150 },
      PETCHASEOFFSET: 0.5,
      BALLPHYSICSPARAMS: {
        SCALE: 0.2,
        MINSCALE: 0.15,
        startFromRight: true,
        velocity: { min: 100, max: 100 },
        position: { x: 0.0, y: 0.0 },
        bounceFactor: { min: 1, max: 1 },
        start: { x: 0.95, z: 0.0, y: 0.9 },
        end: { y: 0.8 },
        worldSize: {
          y: 0.65,
        },
      },
      ANIMATIONS: {
        PICKUP: { NAME: animationType.OBJECT_INTERACT3 },
        IDLEREADY: { NAME: animationType.SIT, REVERSE: true },
        RETURNTOIDLE: { NAME: animationType.SIT, FLIP: false },
        PICKUPFINISH: { NAME: animationType.OBJECT_INTERACT3, REVERSE: true },
      },
      ASSETS: {
        [keySceneDataTypes.PROJECTILE]: gameData.ACTOR.ITEM.OBJECT.BUTTERFLY,
      },
      UI: [],
    },
    [InteractionType.FETCH.NAME]: {
      NAME: InteractionType.FETCH.NAME,
      FLIPXATSTART: false,
      FLIPTURN: true,
      CATCH_BODYPART: bodyPart.MOUTH,
      ACTIONLIMIT: 5,
      PET_SCALE_TRANSFORM_RATE: 1.0,
      JUMPVELOCITY: 200,
      PETSCALE: 2.75,
      MAXPETSCALE: 2.75,
      PROJECTILESCALEFACTOR: 0.4,
      RETURNTOSTARTDURATION: 1500,
      MOVESPEED: 100,
      WALKSPEED: 50,
      HORIZON_LINE: 0.4,
      GROUND_LINE: 0.8,
      ENDX: 0.6,
      ENDY: 0.7,
      STARTX: 0.5,
      STARTY: 0.7,
      DROPTOLINEPERCENTAGE: 0.575,
      USERENDERER: true,
      TURNBEFORERUNNING: true,
      OBJECTMOVINGTHRESH: {
        x: 200,
        y: 50,
      },
      ANIMATIONS: {
        START: { NAME: animationType.IDLE, FLIP: true },
        ENDPOSE: { NAME: animationType.POSE_HAPPY, FLIP: false },
        STARTPOSE: { NAME: animationType.POSE_HAPPY, FLIP: false },
        RETURNTOIDLE: { NAME: animationType.POUNCE, FLIP: false },
        PICKUPTRANSITION: { NAME: animationType.BACKTURN_PARTIAL, FLIP: true },
        PICKUP: { NAME: animationType.OBJECT_INTERACT2, FLIP: true },
        PICKUPFINISH: { NAME: animationType.OBJECT_INTERACT2, REVERSE: true },
      },
      BALLPHYSICSPARAMS: {
        SCALE: 0.075,
        MINSCALE: 0.05,
        MAXSCALE: 0.4,
        position: {
          x: 0.0,
          y: 0.151515151515,
        },
        worldSize: {
          x: 1.0,
          y: 0.5,
        },
      },
      BACKGROUNDSPLIT: 1,
      BOUNDS: {
        HEIGHT: {
          START: 0.151515151515,
          END: 0.09090909090909,
        },
        WIDTH: {
          START: 0.083333,
          END: 0.083333,
        },
      },
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.CAT.PLAYROOM,
        [keySceneDataTypes.PROJECTILE]: gameData.ACTOR.ITEM.WEARABLE.YARN,
      },
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.DONE_BUTTON,
              TEXTID: 'pets.buttons.catch.done',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_AGAIN],
            },
            {
              NAME: keySceneDataTypes.LAUNCH_BUTTON,
              TEXTID: 'pets.buttons.catch.toss-ball',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_INTRO, keySceneDataTypes.SCREEN_AGAIN],
            },
            {
              NAME: keySceneDataTypes.MAYBELATER_BUTTON,
              TEXTID: 'pets.buttons.catch.maybe-later',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_CARE],
            },
            {
              NAME: keySceneDataTypes.READY_BUTTON,
              TEXTID: 'pets.buttons.catch.ready',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_CARE],
            },
            {
              NAME: keySceneDataTypes.OUTRO_BUTTON,
              TEXTID: 'pets.buttons.catch.ok',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.BACK_BUTTON,
              TEXTID: 'pets.buttons.catch.back',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_BACK],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.INTRO_CAPTION,
              TITLEID: 'pets.caption.title.catch.fetch-intro',
              TEXTID: 'pets.caption.description.catch.ready.cat',
            },
            {
              NAME: keySceneDataTypes.AGAIN_CAPTION,
              TITLEID: 'pets.caption.title.catch.again',
            },
            {
              NAME: keySceneDataTypes.CARE_CAPTION,
              TITLEID: 'pets.caption.title.catch.fun',
              TEXTID: 'pets.caption.description.catch.fun',
            },
            {
              NAME: keySceneDataTypes.OUTRO_CAPTION,
              TITLEID: 'pets.caption.title.catch.tired',
            },
            {
              NAME: keySceneDataTypes.BACK_CAPTION,
              TITLEID: 'pets.caption.title.catch.back',
            },
          ],
        },
      ],
    },
  },
};
