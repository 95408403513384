import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { StyledNavButton, StyledNavIcon, StyledGridContainer } from 'Core_Pages/GreetingCards/GreetingCardPage/styles';

function GreetingCardPage({ children, backNavButton, forwardNavButton, disablePrevPage, disableNextPage }) {
  const intl = useIntl();

  return (
    <>
      <StyledGridContainer>
        <StyledNavButton
          disabled={disablePrevPage}
          onClick={backNavButton}
          aria-label={intl.formatMessage({ id: 'common.previous-page' })}
        >
          <StyledNavIcon aria-hidden="true" className="fas fa-chevron-left" />
        </StyledNavButton>
        {children}
        <StyledNavButton
          disabled={disableNextPage}
          onClick={forwardNavButton}
          aria-label={intl.formatMessage({ id: 'common.next-page' })}
        >
          <StyledNavIcon aria-hidden="true" className="fas fa-chevron-right" />
        </StyledNavButton>
      </StyledGridContainer>
    </>
  );
}

GreetingCardPage.propTypes = {
  children: PropTypes.element.isRequired,
  backNavButton: PropTypes.func,
  forwardNavButton: PropTypes.func,
  disableNextPage: PropTypes.bool,
  disablePrevPage: PropTypes.bool,
};

export default GreetingCardPage;
