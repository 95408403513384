import styled, { css } from 'styled-components';

const baseHiddenStyles = css`
  position: absolute;
  width: 1px;
  height: 0;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background-color: white;
`;

export const ScreenReaderWrapper = styled.div`
  ${baseHiddenStyles};
  color: #000000;
`;

export const ScreenReaderLegend = styled.legend`
  ${baseHiddenStyles};
  color: #000000;
`;

export const ScreenReaderText = styled.p`
  ${baseHiddenStyles};
  color: #000000;
`;
