export const userMetaTagCategories = {
  YOUTH: 'youth',
};

const filterUserMetaByTag = (tagCategory, userMeta) => {
  if (!tagCategory) {
    return true;
  }

  switch (tagCategory) {
    case userMetaTagCategories.YOUTH:
      return userMeta?.age?.isYouth;
    default:
      return true;
  }
};

export default filterUserMetaByTag;
