import { ScreeningModuleStore } from './ScreeningModuleStore';
import React, { useEffect, useState } from 'react';
import { Workflows } from './workflows';
import LoadingWrapper from 'Core_Components/LoadingWrapper/LoadingWrapper';

function Screening() {
  const [loading, setLoading] = useState(true);
  const assessmentStore = ScreeningModuleStore.Instance().Use();
  const WorkflowComponent = Workflows[assessmentStore.Workflow];

  useEffect(() => {
    assessmentStore.initialize().then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      <LoadingWrapper loading={loading}>
        <WorkflowComponent />
      </LoadingWrapper>
    </>
  );
}

export default Screening;
