import React from 'react';
import GreetingPyxir from 'Assets/img/greeting-pyxir.svg';
import { useIntl } from 'react-intl';

const PyxirCharacter = () => {
  const intl = useIntl();
  return (
    <img
      src={GreetingPyxir}
      alt={intl.formatMessage({ id: 'image.pyxir.portrait' })}
      style={{ width: '65px', margin: '20px 0px 0px 12px' }}
    />
  );
};

export default PyxirCharacter;
