import { animationType } from '../../../constants/animationType';
import { sceneType } from '../../../constants/sceneType';
import { uiTypes } from '../../../constants/uiTypes';
import { Storyboard } from '../Storyboard';
import { strollConfig, keySceneDataTypes } from './strollConfig';
import { findAsset, findUI } from '../../../Scripts/configHelper';
import { addCharacterActor } from '../../../Scripts/characterHelper';
import { playerActorType } from '../../../constants/playerActorType';
import { onClickActivityMenu, onClickGoToRoute } from '../../../Scripts/buttonHelper';
import { bodyPart } from 'Core_Games/constants/bodyPart';
import { STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';
import { updateActorScaleTowardsPoint, isAtPoint } from 'Core_Games/Scripts/actorPointHelper';
import { getBackgroundPagePoint, phaserToCssCoordinates } from 'Core_Games/Scripts/transformationHelper';
import { LogInteraction, interactionExceedsLimit } from '../../../Scripts/interactionHelper';

export class Stroll extends Storyboard {
  constructor() {
    super(sceneType.STROLL.NAME);

    this.background = undefined;
    this.speak = undefined;

    this.timerNextPage = undefined;
    this.timerNextPageActive = false;
    this.timerStartChatBubbleSequence = undefined;

    this.timerFadeBubble = undefined;
    this.timerFadeBubbleActive = false;

    this.finishedLoading = false;
    this.familyScale = 0.6;
    this.wheelchairWomanScale = 0.6;
    this.oldmanScale = 0.6;
    this.currentChatBubbleIndex = 0;

    this.initialCameraLerp = 1.0;
    this.cameraLerp = 0.075;
    this.chatBubbleStartDelay = 0.15;

    this.npcs = {};
  }

  init(sceneData) {
    this.sceneData = sceneData;
    this.strollConfig = strollConfig;

    let readyButton = findUI(this, this.strollConfig, uiTypes.BUTTONROW, keySceneDataTypes.READY_BUTTON);
    let yesChatPromptButton = findUI(this, this.strollConfig, uiTypes.BUTTONROW, keySceneDataTypes.YES_BUTTON);
    let backButton = findUI(this, this.strollConfig, uiTypes.BUTTONROW, keySceneDataTypes.BACK_BUTTON);
    let notTodayChatPromptButton = findUI(
      this,
      this.strollConfig,
      uiTypes.BUTTONROW,
      keySceneDataTypes.NOTTODAY_BUTTON,
    );
    let yesCareButton = findUI(this, this.strollConfig, uiTypes.BUTTONROW, keySceneDataTypes.YESOUTRO_BUTTON);
    let notTodayOutroButton = findUI(
      this,
      this.strollConfig,
      uiTypes.BUTTONROW,
      keySceneDataTypes.NOTTODAY_OUTRO_BUTTON,
    );

    super.init(sceneType.STROLL, this.strollConfig, sceneData);

    this.hasLeash = this.currentSceneConfig.HASLEASH;
    this.gutter = this.currentSceneConfig.GUTTER ?? 0;
    this.cameraOffsetX = this.currentSceneConfig.CAMERAOFFSETX ?? 0.5;
    this.deadzoneScaleX = this.currentSceneConfig.DEADZONESCALEX ?? 0.25;
    this.deadZoneHeightOffset = this.currentSceneConfig.DEADZONEOFFSETY ?? 0.571;
    this.horizonLine = this.currentSceneConfig.HORIZON_LINE ?? 0.4;
    this.groundLine = this.currentSceneConfig.GROUND_LINE ?? 0.8;

    readyButton ? (readyButton.ONCLICK = () => this.onClickReady(this)) : readyButton;
    yesChatPromptButton ? (yesChatPromptButton.ONCLICK = () => this.onClickYesChatPrompt(this)) : yesChatPromptButton;
    notTodayChatPromptButton
      ? (notTodayChatPromptButton.ONCLICK = () => this.onClickNotToday(this))
      : notTodayChatPromptButton;
    yesCareButton
      ? (yesCareButton.ONCLICK = () =>
          onClickGoToRoute(sceneData.appData.history, STATICCONTENT, { contentType: 'smalltalk' }))
      : yesCareButton;
    notTodayOutroButton
      ? (notTodayOutroButton.ONCLICK = () => onClickActivityMenu(sceneData.appData.history))
      : notTodayOutroButton;
    backButton ? (backButton.ONCLICK = () => onClickActivityMenu(sceneData.appData.history)) : backButton;
  }

  preload() {
    super.preload();
  }

  create() {
    let _this = this;

    super.create();

    // set initial parameters
    this.engine.engineAssets.load.on(Phaser.Loader.Events.COMPLETE, function () {
      // create timers
      // pet
      _this.timerNextPage = new Phaser.Time.TimerEvent({ delay: 4000 });
      _this.time.addEvent(_this.timerNextPage);
      _this.timerFadeBubble = new Phaser.Time.TimerEvent({ delay: 4000 });
      _this.time.addEvent(_this.timerFadeBubble);
      _this.timerStartChatBubbleSequence = new Phaser.Time.TimerEvent({ delay: 4000 });
      _this.time.addEvent(_this.timerStartChatBubbleSequence);

      _this.pet.play(animationType.SIT);

      _this.cameraOffsetX = _this.pet.displayWidth * _this.cameraOffsetX;
      _this.cameraOffsetY = -_this.pet.displayHeight * 0.5;
      _this.cameraOffsetXFlip = _this.cameraOffsetX + _this.pet.displayWidth * 0.5;
      _this.cameraOffsetXFlipAfterCameraStop =
        _this.cameraOffsetX + _this.pet.displayWidth * 0.5 + _this.pet.displayHeight * 0.5;

      _this.cameras.main.startFollow(_this.pet, false, _this.initialCameraLerp, _this.initialCameraLerp);
      _this.cameras.main.followOffset.set(_this.cameraOffsetX, _this.cameraOffsetY);

      // _this.pet.body.enableGravity = false;
      _this.gameGraphics = _this.add.graphics();

      // add NPCs
      _this.addNPCS();

      _this.background.setDepth(-(Object.keys(_this.npcs).length + 2));

      _this.startLocationStoryX = _this.pet.x;
      _this.startLocationStoryY = _this.pet.y;

      _this.moveToPage();

      _this.firstPageInitialization = true;
      // leash

      _this.finishedLoading = true;
      _this.pet.body.collideWorldBounds = false;

      // physics overlap logic
      _this.addEventLogic(_this, _this.pet, _this.nutrientContainer, _this.bowl);
    });
  }

  update() {
    super.update();

    if (this.finishedLoading) {
      // leash
      if (this.hasLeash && (this.currentPage.USELEASH == undefined || this.currentPage.USELEASH)) {
        this.updateLeash(this);
      } else if (this.hasLeash && this.currentPage.USELEASH !== undefined && !this.currentPage.USELEASH) {
        this.gameGraphics.clear();
      }

      if (
        this.currentPage.SCALE &&
        Math.abs(this.pet.scale - this.pet.getRelativeScale(this.currentPage.SCALE)) > 0.1
      ) {
        this.originalDistanceToPage = updateActorScaleTowardsPoint(
          this.originalDistanceToPage,
          this.pet,
          this.currentLocationX,
          this.currentLocationY,
          this.currentPage.SCALE,
        );
      }
      if (
        this.npcInteractionActive &&
        isAtPoint(
          this.pet,
          this.currentInteractPositionAim.x,
          this.currentInteractPositionAim.y,
          this.currentInteractPositionAimStart.x,
          this.currentInteractPositionAimStart.y,
        )
      ) {
        this.pet.body.stop();
        this.pet.play(this.npcInteractionAnimationToPlayPet);
        if (this.npcInteractAnimationToPlayTransitionNPC) {
          this.npcs[this.currentPage.NPC.ACTOR].play(this.npcInteractAnimationToPlayTransitionNPC);
        } else {
          this.npcs[this.currentPage.NPC.ACTOR].play(this.npcInteractionAnimationToPlayNPC);
        }
        this.npcInteractionActive = false;
        this.npcInteractionWasActive = true;
      }
      this.displayScreen();
      if (
        ((this.timerNextPage.getProgress() >= 0.7 && this.timerNextPageActive) ||
          (this.currentPage.SCENE && this.sceneIsDone)) &&
        !this.timerFadeBubbleActive &&
        (!this.npcInteractionWasActive ||
          !this.currentPage.NPC.ACTOR ||
          (this.npcs[this.currentPage.NPC.ACTOR].interactionAnimationComplete && this.pet.interactionAnimationComplete))
      ) {
        this.timerNextPageActive = false;
        this.engine.engineUi.chatBubbleManager.updateFadeIn(false);
        if (this.npcInteractionWasActive || (this.currentPage.SCENE && this.sceneIsDone)) {
          this.npcInteractionWasActive = false;
          if (this.currentPage.SCENE && this.sceneIsDone) {
            const scrollX = this.cameras.main.scrollX;
            const scrollY = this.cameras.main.scrollY;
            this.cameras.main.startFollow(this.pet, false, this.cameraLerp, this.cameraLerp);
            this.cameras.main.followOffset.set(this.cameraOffsetX, this.cameraOffsetY);
            this.cameras.main.scrollY = scrollY;
            this.cameras.main.scrollX = scrollX;
          } else {
            this.cameras.main.startFollow(this.pet);
            this.cameras.main.followOffset.set(this.cameraOffsetX, this.cameraOffsetY);
          }
        }
        if (this.currentPage.NPC?.ACTOR) {
          if (this.npcs[this.currentPage.NPC.ACTOR].didWalk) {
            this.npcs[this.currentPage.NPC.ACTOR].alpha = 0;
            this.npcs[this.currentPage.NPC.ACTOR].didWalk = false;
          }
          this.npcs[this.currentPage.NPC.ACTOR].interactionAnimationComplete = false;
          this.pet.interactionAnimationComplete = false;
        }
        this.interactionAnimationComplete = true;
        this.moveToPage(++this.currentPageIndex);
      }
      if (
        this.npcWalkInProgress &&
        isAtPoint(this.npcs[this.currentPage.NPC.ACTOR], this.npcs[this.currentPage.NPC.ACTOR].endLocation.x)
      ) {
        this.npcs[this.currentPage.NPC.ACTOR].body.stop();
        this.npcs[this.currentPage.NPC.ACTOR].play(this.currentPage.NPC.ANIMATIONS.IDLE);
        if (this.foreground) {
          this.npcs[this.currentPage.NPC.ACTOR].setDepth(this.foreground.depth + 1);
        }
        this.npcWalkInProgress = false;
        this.npcDidWalk = true;
        if (this.currentPage.SCREEN) {
          this.engine.engineUi.buttonManager.displayScreen(this.currentPage.SCREEN);
        }
        if (this.currentPage.CAPTION) {
          this.engine.engineUi.captionManager.displayCaption(this.currentPage.CAPTION);
        }
        this.startNonChatClickNPCTalkSequence();
      }
      if (
        this.timerFadeBubble.getProgress() >= 0.1 &&
        this.timerFadeBubble.getProgress() < 0.6 &&
        this.timerFadeBubbleActive
      ) {
        this.engine.engineUi.chatBubbleManager.updateFadeIn(true);
      } else if (
        this.timerFadeBubble.getProgress() >= 0.6 &&
        this.timerFadeBubble.getProgress() < 0.7 &&
        this.timerFadeBubbleActive &&
        this.currentChatBubble.BUTTON &&
        !this.chatContinueClicked
      ) {
        this.timerFadeBubble.paused = true;
        this.engine.engineUi.buttonManager.displayScreen(this.currentChatBubbleButtonData.ROWS[0]);
        this.currentChatBubbleButtonData.ONCLICK = () => {
          this.engine.engineUi.buttonManager.displayScreen();
          this.timerFadeBubble.paused = false;
          this.chatContinueClicked = true;
          this.timerNextPageActive = true;
        };
      } else if (
        this.timerFadeBubble.getProgress() >= 0.7 &&
        this.timerFadeBubble.getProgress() < 1.0 &&
        this.timerFadeBubbleActive
      ) {
        this.engine.engineUi.chatBubbleManager.updateFadeIn(false);
        this.moveNPCOffScreen();
      } else if (this.timerFadeBubble.getProgress() >= 1.0 && this.timerFadeBubbleActive) {
        this.chatContinueClicked = false;
        this.engine.engineUi.chatBubbleManager.toggleChatBubble(true);
        this.triggerNextBubble();
      }
    }
  }

  petInteractNPC = () => {
    if (this.currentPage.NPC?.CHATINTERACT) {
      this.npcInteractionActive = true;
      this.cameras.main.stopFollow();
      this.npcInteractAnimationToPlayTransitionNPC =
        this.currentPage.NPC.CHATINTERACT.NPC_INTERACT_TRANSITION_ANIMATION;
      this.npcInteractionAnimationToPlayNPC = this.currentPage.NPC.CHATINTERACT.NPC_INTERACT_ANIMATION;
      this.npcInteractionAnimationToPlayPet = this.currentPage.NPC.CHATINTERACT.PET_INTERACT_ANIMATION;

      this.currentInteractPositionAim = {
        x:
          this.npcs[this.currentPage.NPC.ACTOR].x +
          this.npcs[this.currentPage.NPC.ACTOR].displayWidth *
            (this.currentPage.NPC.CHATINTERACT.PET_POSITION ? this.currentPage.NPC.CHATINTERACT.PET_POSITION.x : 0.5),
        y:
          this.npcs[this.currentPage.NPC.ACTOR].y +
          this.npcs[this.currentPage.NPC.ACTOR].displayHeight *
            (this.currentPage.NPC.CHATINTERACT.PET_POSITION ? this.currentPage.NPC.CHATINTERACT.PET_POSITION.y : 0.5),
      };
      this.currentInteractPositionAimStart = {
        x: this.pet.x,
        y: this.pet.y,
      };
      this.pet.play(this.currentPage.ANIMATIONS.PET_TRANSITION_ANIMATION);
      const currentSpeed = this.currentPage.SPEED ? this.currentPage.SPEED : 300;
      this.physics.moveTo(this.pet, this.currentInteractPositionAim.x, this.currentInteractPositionAim.y, currentSpeed);
    }
  };

  moveNPCOffScreen = () => {
    if (this.npcDidWalk && this.currentChatBubbleIndex >= this.currentChatBubbleLength) {
      this.npcs[this.currentPage.NPC.ACTOR].walkOffscreenTo = {
        x: -(this.game.renderer.width + this.pet.displayWidth * 2),
        y: this.npcs[this.currentPage.NPC.ACTOR].y,
      };

      this.physics.moveTo(
        this.npcs[this.currentPage.NPC.ACTOR],
        this.npcs[this.currentPage.NPC.ACTOR].walkOffscreenTo.x,
        this.npcs[this.currentPage.NPC.ACTOR].walkOffscreenTo.y,
        300,
      );
      this.npcs[this.currentPage.NPC.ACTOR].body.collideWorldBounds = false;
      this.npcs[this.currentPage.NPC.ACTOR].didWalk = true;
      this.npcDidWalk = false;
      this.npcs[this.currentPage.NPC.ACTOR].play(this.currentPage.NPC.WALK.ANIMATION);
      this.npcs[this.currentPage.NPC.ACTOR].setDepth(this.npcs[this.currentPage.NPC.ACTOR].originalDepth);
    }
  };

  triggerNextBubble = () => {
    if (this.currentChatBubbleIndex < this.currentChatBubbleLength) {
      this.currentChatBubble = this.currentPage.NPC.CHATBUBBLES[this.currentChatBubbleIndex];
      this.currentChatBubbleData = findUI(this, this.sceneConfig, uiTypes.CHATBUBBLE, this.currentChatBubble.NAME);
      this.currentChatBubbleButtonData = findUI(
        this,
        this.sceneConfig,
        uiTypes.BUTTONROW,
        this.currentChatBubble.BUTTON,
      );
      let currentCoordinates = {};
      let isFlipped = false;
      if (!this.currentChatBubbleData.ISRESPONSE) {
        isFlipped = this.currentPage.NPC.FLIPCHATBUBBLEOVERRIDE ?? this.npcs[this.currentPage.NPC.ACTOR].flipX;
        let chatBubbleShiftRendererX =
          this.npcs[this.currentPage.NPC.ACTOR].displayWidth *
          this.npcs[this.currentPage.NPC.ACTOR].chatBubbleCoordinatesPercentage.x;
        let chatBubbleShiftRendererY =
          this.npcs[this.currentPage.NPC.ACTOR].displayHeight *
          this.npcs[this.currentPage.NPC.ACTOR].chatBubbleCoordinatesPercentage.y;

        let xLocationNpcBackground = this.npcs[this.currentPage.NPC.ACTOR].getTopLeft().x + chatBubbleShiftRendererX;
        let yLocationNpcBackground = this.npcs[this.currentPage.NPC.ACTOR].getTopLeft().y + chatBubbleShiftRendererY;

        currentCoordinates = phaserToCssCoordinates(this, xLocationNpcBackground, yLocationNpcBackground);
      } else {
        currentCoordinates = {
          x: 0,
          y: this.game.renderer.height * 0.1,
        };
      }
      this.time.addEvent(this.timerFadeBubble);
      this.engine.engineUi.chatBubbleManager.toggleChatBubble(false);
      this.engine.engineUi.chatBubbleManager.displayChatBubble(
        this.currentChatBubble.NAME,
        currentCoordinates,
        isFlipped,
      );
      this.currentChatBubbleIndex++;
    } else {
      this.timerFadeBubbleActive = false;
    }
  };

  moveToPage = (index = undefined) => {
    this.originalDistanceToPage = undefined;
    this.timerStartChatBubbleSequenceActive = false;
    this.currentPageIndex = index ?? this.currentPageIndex;
    this.currentPage = this.currentSceneConfig.PAGES[this.currentPageIndex];
    this.backgroundIndex = this.currentPage.BACKGROUND_INDEX;

    this.engine.engineUi.buttonManager.displayScreen();
    this.engine.engineUi.captionManager.toggleCaption(true);

    this.currentPage.pageCenter = this.getPageCenter(this.backgroundIndex);
    if (this.backgroundIndex == 0) {
      this.currentPage.pageCenter.x = this.startLocationStoryX;
    } else {
      this.currentPage.pageCenter.x += this.startLocationStoryX;
    }

    this.lastLocationX = this.pet.x;
    this.lastLocationY = this.pet.y;

    const newlocation = this.getPagePoint(
      this.backgroundIndex,
      this.currentPage.POSITION?.x ?? 0.5,
      this.currentPage.POSITION?.y ?? 0.5,
    );

    if (this.currentPage.POSITION) {
      this.currentLocationX =
        this.backgroundIndex == 0 && !this.currentPage.POSITION.x ? this.startLocationStoryX : newlocation.x;
    } else {
      this.currentLocationX = this.backgroundIndex == 0 ? this.startLocationStoryX : newlocation.x;
    }
    this.currentLocationY =
      this.currentPage.POSITION && this.currentPage.POSITION.y ? newlocation.y : this.startLocationStoryY;

    // game.physics.moveTo
    this.inTransition = true;
    if (!this.isAtPageCenter()) {
      if (
        (this.currentLocationX < this.pet.x && !this.pet.flipX) ||
        (this.currentLocationX > this.pet.x && this.pet.flipX)
      ) {
        this.triggerTurn = true;
        this.pet.flipX = !this.pet.flipX;
        // if (!this.currentPage.SCENE || this.sceneIsDone) {
        if (!this.currentPage.SCENE) {
          this.pet.flipX
            ? this.cameras.main.followOffset.set(this.cameraOffsetXFlip, this.cameraOffsetY)
            : this.cameras.main.followOffset.set(this.cameraOffsetX, this.cameraOffsetY);
        }
        this.currentTurnAnimation = this.currentPage.ANIMATIONS?.TURN ?? animationType.TURN;
        this.pet.play(this.currentTurnAnimation);
      }

      const currentSpeed = this.currentPage.SPEED ? this.currentPage.SPEED : 300;
      const currentDuration = this.currentPage.DURATION ? this.currentPage.DURATION : 5000;
      if (this.currentPage.SCALE) {
        this.tweens.add({
          targets: this.pet,
          scale: this.currentPage.SCALE,
          ease: 'linear',
          duration: currentDuration,
        });
        this.physics.moveTo(this.pet, this.currentLocationX, this.currentLocationY, currentSpeed, currentDuration);
      } else {
        this.physics.moveTo(this.pet, this.currentLocationX, this.currentLocationY, currentSpeed);
      }
    }

    if (this.currentPage.ANIMATIONS?.TRANSITION && !this.triggerTurn) {
      this.pet.play(this.currentPage.ANIMATIONS.TRANSITION);
    }

    if (this.currentPage.CAMERAOFFSET) {
      const followOffset = {
        x: this.currentPage.CAMERAOFFSET.x
          ? this.pet.displayWidth * this.currentPage.CAMERAOFFSET.x
          : this.cameraOffsetX,
        y: this.currentPage.CAMERAOFFSET.y
          ? this.pet.displayHeight * this.currentPage.CAMERAOFFSET.y
          : this.cameraOffsetY,
      };
      this.cameras.main.setFollowOffset(followOffset.x, followOffset.y);
    }

    // Add NPCs to page
    if (this.currentPage.NPC) {
      //   this.npcs[this.currentPage.NPC.ACTOR].

      this.npcs[this.currentPage.NPC.ACTOR].alpha = this.currentPage.NPC.WALK ? 0 : 1;

      this.npcs[this.currentPage.NPC.ACTOR].scaleActor(this.currentPage.NPC.SCALE);

      this.npcs[this.currentPage.NPC.ACTOR].setOrigin(0.5, 0.5);
      const newLocation = this.getPagePoint(
        this.backgroundIndex,
        this.currentPage.NPC.LOCATIONPERCENTAGE?.x ?? 0.5,
        this.currentPage.NPC.LOCATIONPERCENTAGE?.y ?? 0.5,
      );

      this.npcs[this.currentPage.NPC.ACTOR].endLocation = newLocation;

      this.npcs[this.currentPage.NPC.ACTOR].x = this.npcs[this.currentPage.NPC.ACTOR].endLocation.x;
      this.npcs[this.currentPage.NPC.ACTOR].y = this.npcs[this.currentPage.NPC.ACTOR].endLocation.y;
    }
  };

  getRelativeHeightPoint = (actor, percentage) => {
    const foregroundRatio = this.calculatedSizeRatio
      ? this.calculatedSizeRatio > 1.0
        ? 1.0 / this.foregroundCalculatedHeight
        : this.calculatedSizeRatio < 0.5
        ? this.foregroundCalculatedHeight
        : this.foregroundCalculatedHeight / this.background.scaleY
      : 1.0;

    return (
      this.game.renderer.height * (percentage ?? 0.5) +
      actor.displayHeight * 0.5 +
      (this.background.displayHeight - this.game.renderer.height) * foregroundRatio
    );
  };

  displayScreen = () => {
    if (this.inTransition && this.isAtPageCenter()) {
      this.pet.body.setVelocity(0, 0);

      if (this.currentPage.SCENE) {
        this.currentAddedSceneType = this.currentPage.SCENE;
        const newLocationStart = getBackgroundPagePoint(
          this,
          this.background,
          this.backgroundBoundingBoxes,
          0,
          0,
          this.backgroundIndex,
        );
        const newLocationGroundLine = this.getPagePoint(this.backgroundIndex, 0, this.groundLine);
        const newLocationHorizonLine = this.getPagePoint(this.backgroundIndex, 0, this.horizonLine);
        const startPage = this.getPagePoint(
          this.backgroundIndex,
          this.currentPage.SCENECONFIG.LOCATION.STARTPERCENTAGE &&
            this.currentPage.SCENECONFIG.LOCATION.STARTPERCENTAGE.x
            ? this.currentPage.SCENECONFIG.LOCATION.STARTPERCENTAGE.x
            : 0,
          this.currentPage.SCENECONFIG.LOCATION.STARTPERCENTAGE &&
            this.currentPage.SCENECONFIG.LOCATION.STARTPERCENTAGE.y
            ? this.currentPage.SCENECONFIG.LOCATION.STARTPERCENTAGE.y
            : 0,
        );
        this.sceneStartLocation = this.currentPage.SCENECONFIG.LOCATION?.START
          ? this.getPagePoint(
              this.backgroundIndex,
              this.currentPage.SCENECONFIG.LOCATION.START.x,
              this.currentPage.SCENECONFIG.LOCATION.START.y,
            )
          : { x: this.pet.x, y: this.pet.y };
        const endLocation = this.currentPage.SCENECONFIG.LOCATION?.END
          ? this.getPagePoint(
              this.backgroundIndex,
              this.currentPage.SCENECONFIG.LOCATION.END.x,
              this.currentPage.SCENECONFIG.LOCATION.END.y,
            )
          : this.sceneStartLocation;
        this.currentAddedScene = this.scene.add(
          sceneType[this.currentAddedSceneType.SCENE].NAME,
          sceneType[this.currentAddedSceneType.SCENE].DATA,
          true,
          {
            engine: this.engine,
            pet: this.pet,
            appData: this.sceneData.appData,
            gameData: this.gameData,
            sceneType: this.currentAddedSceneType,
            parentScene: this,
            otherSceneBoundingBox: {
              startX: newLocationStart.absolute.x,
              startYHeightPercentage: newLocationStart.percentage.y,
              startY: newLocationStart.absolute.y,
              horizonLine: newLocationHorizonLine.y,
              groundLine: newLocationGroundLine.y,
              groundLinePercentage: this.groundLine,
              horizonLinePercentage: this.horizonLine,
              startLocationX: this.sceneStartLocation.x ?? this.pet.x,
              startLocationY: this.sceneStartLocation.y ?? this.pet.y,
              endLocationX: endLocation.x ?? this.pet.x,
              endLocationY: endLocation.y ?? this.pet.y,
              startOfPage: startPage,
              originalParentScale: this.pet.scale,
            },
          },
        );
        this.cameras.main.stopFollow();
      }

      if (this.currentPage.NPC) {
        this.currentNPCAnimationToPlay = this.currentPage.NPC.ANIMATIONS.WAVE ?? animationType.IDLE;
        this.currentNPCAnimationToPlayIdle = this.currentPage.NPC.ANIMATIONS.IDLE ?? animationType.IDLE;
        this.npcs[this.currentPage.NPC.ACTOR].playReverse(this.currentPage.NPC.ANIMATIONS.IDLE);
        this.npcs[keySceneDataTypes.HUMAN_OLDMAN].flipX = this.currentPage.NPC.FLIPXATSTART ?? false;
      }
      if (this.currentPage.NPC?.WALK) {
        const newLocation = this.getPagePoint(
          this.backgroundIndex,
          this.currentPage.NPC.WALK.LOCATIONPERCENTAGE?.x ?? 0.5,
          this.currentPage.NPC.WALK.LOCATIONPERCENTAGE?.y ?? 0.5,
        );

        this.currentNPCWalkFrom = newLocation;
        this.npcs[this.currentPage.NPC.ACTOR].x = this.currentNPCWalkFrom.x;
        this.npcs[this.currentPage.NPC.ACTOR].y = this.currentNPCWalkFrom.y;
        this.npcs[this.currentPage.NPC.ACTOR].alpha = 1;
        if (!this.npcs[this.currentPage.NPC.ACTOR].body) {
          this.npcs[this.currentPage.NPC.ACTOR].enablePhysics(this);
        }
        this.physics.moveTo(
          this.npcs[this.currentPage.NPC.ACTOR],
          this.npcs[this.currentPage.NPC.ACTOR].endLocation.x,
          this.npcs[this.currentPage.NPC.ACTOR].endLocation.y,
          300,
        );
        this.npcs[this.currentPage.NPC.ACTOR].play(this.currentPage.NPC.WALK.ANIMATION);
        this.npcWalkInProgress = true;
      } else {
        if (
          this.currentPage.SCREEN &&
          this.currentPage.SCREEN === keySceneDataTypes.STROLL_SCREEN_INTRO &&
          interactionExceedsLimit(this)
        ) {
          this.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.STROLL_SCREEN_BACK);
          this.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.STROLL_BACK_CAPTION);
        } else {
          if (this.currentPage.SCREEN) {
            this.engine.engineUi.buttonManager.displayScreen(this.currentPage.SCREEN);
          }
          if (this.currentPage.CAPTION) {
            this.engine.engineUi.captionManager.displayCaption(this.currentPage.CAPTION);
          }
        }
      }

      if (this.currentPage.ANIMATIONS?.IDLE) {
        this.pet.play(this.currentPage.ANIMATIONS.IDLE);
        this.pet.flipX = this.currentPage.FLIPXATSTART ?? false;
      }
      this.inTransition = false;
      this.startOfChatSequence = true;
    }
    if (!this.inTransition && this.startOfChatSequence) {
      if (
        this.timerStartChatBubbleSequence.getProgress() >= this.chatBubbleStartDelay &&
        this.timerStartChatBubbleSequenceActive
      ) {
        this.startOfChatSequence = false;
        this.startNonChatClickNPCTalkSequence();
      } else if (!this.timerStartChatBubbleSequenceActive) {
        this.timerStartChatBubbleSequenceActive = true;
        this.time.addEvent(this.timerStartChatBubbleSequence);
      }
    }
  };

  startNonChatClickNPCTalkSequence = () => {
    if (!this.currentPage.STARTCHATONCLICK && this.currentPage.NPC?.CHATBUBBLES && !this.npcWalkInProgress) {
      this.currentChatBubbleIndex = 0;
      this.currentChatBubbleLength = this.currentPage.NPC.CHATBUBBLES.length;
      this.triggerNextBubble();

      this.time.addEvent(this.timerNextPage);
      this.timerNextPageActive = true;
      this.timerFadeBubbleActive = true;
    }
  };

  onClickYesChatPrompt = () => {
    this.time.addEvent(this.timerFadeBubble);
    this.timerFadeBubbleActive = true;

    this.engine.engineUi.buttonManager.displayScreen();
    this.engine.engineUi.captionManager.toggleCaption(true);

    this.currentChatBubbleIndex = 0;
    this.currentChatBubbleLength = this.currentPage.NPC.CHATBUBBLES.length;

    this.triggerNextBubble();
    this.petInteractNPC();
  };

  // Scene-Specific Functions

  // Add Collision & event logic
  addEventLogic = (game, actor) => {
    for (let i = 0; i < Object.keys(this.npcs).length; i++) {
      let currentNPC = this.npcs[Object.keys(this.npcs)[i]];
      currentNPC.on(Phaser.Animations.Events.ANIMATION_COMPLETE, function () {
        if (
          currentNPC.animationIsActive(game.currentPage.NPC?.ANIMATIONS?.IDLE ?? animationType.IDLE) &&
          game.currentNPCAnimationToPlay
        ) {
          currentNPC.play(game.currentNPCAnimationToPlay);
        } else if (currentNPC.animationIsActive(game.currentNPCAnimationToPlay)) {
          game.currentNPCAnimationToPlay = undefined;
          currentNPC.play(game.currentNPCAnimationToPlayIdle);
        } else if (
          game.npcInteractionWasActive &&
          !currentNPC.interactionAnimationComplete &&
          currentNPC.animationIsActive(game.npcInteractAnimationToPlayTransitionNPC)
        ) {
          currentNPC.play(game.npcInteractionAnimationToPlayNPC);
          currentNPC.interactionTransitionAnimationPlayed = true;
        } else if (
          game.npcInteractionWasActive &&
          currentNPC.interactionTransitionAnimationPlayed &&
          currentNPC.animationIsActive(game.npcInteractionAnimationToPlayNPC)
        ) {
          currentNPC.playReverse(game.npcInteractAnimationToPlayTransitionNPC);
          currentNPC.interactionAnimationComplete = true;
        } else if (
          currentNPC.interactionTransitionAnimationPlayed &&
          currentNPC.animationIsActive(game.npcInteractAnimationToPlayTransitionNPC)
        ) {
          currentNPC.play(game.currentNPCAnimationToPlayIdle);
          currentNPC.interactionTransitionAnimationPlayed = false;
        }
      });
    }
    actor.on(Phaser.Animations.Events.ANIMATION_COMPLETE, function () {
      if (
        game.triggerTurn &&
        ((game.currentTurnAnimation && actor.animationIsActive(game.currentTurnAnimation)) ||
          (!game.currentTurnAnimation && actor.animationIsActive(animationType.TURN)))
      ) {
        game.triggerTurn = false;
        actor.flipX = !actor.flipX;
        actor.play(game.currentPage.ANIMATIONS.TRANSITION);
      } else if (game.npcInteractionWasActive && actor.animationIsActive(game.npcInteractionAnimationToPlayPet)) {
        actor.play(game.currentPage.ANIMATIONS.IDLE);
        actor.interactionAnimationComplete = true;
      }
    });
  };

  addNPCS = () => {
    let depthCount = 0;
    if (this.foreground) {
      depthCount--;
      depthCount--;
      this.foreground.setDepth(depthCount);
    }
    this.addNPC(
      depthCount,
      playerActorType.WHEELCHAIRWOMAN.NAME,
      keySceneDataTypes.HUMAN_WHEELCHAIRWOMAN,
      this.wheelchairWomanScale,
    );
    this.addNPC(depthCount, playerActorType.FAMILY.NAME, keySceneDataTypes.HUMAN_FAMILY, this.familyScale);
    this.addNPC(depthCount, playerActorType.OLDMAN.NAME, keySceneDataTypes.HUMAN_OLDMAN, this.oldmanScale);
  };

  addNPC = (depthCount, actorName, sceneActorName, scale) => {
    if (findAsset(this, this.sceneConfig, sceneActorName)) {
      depthCount--;
      this.npcs = {
        ...this.npcs,
        [sceneActorName]: addCharacterActor(this, 0, 0, actorName),
      };
      this.npcs[sceneActorName].scaleActor(scale);
      this.npcs[sceneActorName].play(animationType.IDLE);
      this.npcs[sceneActorName].alpha = 0;
      this.npcs[sceneActorName].originalDepth = depthCount;
      this.npcs[sceneActorName].setDepth(depthCount);
    }
  };

  isAtPageCenter = () => {
    return isAtPoint(this.pet, this.currentLocationX, this.currentLocationY, this.lastLocationX, this.lastLocationY);
  };

  updateLeash = (game) => {
    // leash test
    const neckOffsetX = 5;
    const neckPoint = game.pet.getAttachmentLocation(bodyPart.NECK);
    game.startPointLeash = new Phaser.Math.Vector2(
      game.pet.x - game.backgroundBoundingBoxes[0].boundingBoxes[0].page.width * game.background.width,
      game.pet.y + game.pet.displayHeight * 0.5,
    );
    game.endPointLeash = new Phaser.Math.Vector2(neckPoint.x - neckOffsetX, neckPoint.y);
    game.midPointLeash = new Phaser.Math.Vector2(
      (game.startPointLeash.x + game.endPointLeash.x) * 0.5,
      (game.startPointLeash.y + game.endPointLeash.y) * 1.15 * 0.475 + game.pet.displayHeight * 0.05,
    );
    game.leashCurve = new Phaser.Curves.Spline([game.startPointLeash, game.midPointLeash, game.endPointLeash]);
    game.gameGraphics.clear();
    game.gameGraphics.lineStyle(5, Phaser.Display.Color.HexStringToColor('#ED1069').color, 1);
    game.leashCurve.draw(game.gameGraphics, 64);
    game.gameGraphics.fillStyle(Phaser.Display.Color.HexStringToColor('#ED1069').color, 1);
    game.gameGraphics.setDepth(game.pet.depth + 2);
  };

  onClickReady = (game) => {
    if (game.firstPageInitialization) {
      game.startLocationStoryY = game.pet.y;
      game.firstPageInitialization = false;
      game.cameras.main.setLerp(game.cameraLerp, game.cameraLerp);
    }
    game.moveToPage(++game.currentPageIndex);
    LogInteraction(game, game.sceneData?.sceneType);
  };

  onClickNotToday = (game) => {
    game.moveNPCOffScreen();
    game.moveToPage(++game.currentPageIndex);
  };
}
