import styled from 'styled-components';

export const StyledLayoutContainer = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  font-family: 'Karla';
  color: black;
  padding: 0 40px 15px 40px;
  height: 100%;

  h2 {
    margin: 20px 0 16px 0;
    font-size: 20px;
    color: #3c417d;
    font-weight: 700;
  }
`;
