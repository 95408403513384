import HomeNavbarItem from './NavBarItems/HomeNavBarItem';
import RemindersNavbarItem from './NavBarItems/RemindersNavBarItem';
import TalkNavbarItem from './NavBarItems/TalkNavBarItem';
import ResourcesNavbarItem from './NavBarItems/ResourcesNavBarItem';
import ProfileNavbarItem from './NavBarItems/ProfileNavBarItem';
import { HOME, REMINDERS, TALKTOSOMEONE, MENU, PROFILE, CHAT } from 'Core_Pages/Routes/RoutesConfig';

export const navbarItems = [
  {
    route: HOME,
    tabActiveRoutes: [HOME],
    component: HomeNavbarItem,
  },
  {
    route: REMINDERS,
    tabActiveRoutes: [REMINDERS],
    component: RemindersNavbarItem,
  },
  {
    route: TALKTOSOMEONE,
    tabActiveRoutes: [CHAT, TALKTOSOMEONE, TALKTOSOMEONE],
    component: TalkNavbarItem,
  },
  {
    route: MENU,
    tabActiveRoutes: [MENU],
    component: ResourcesNavbarItem,
  },
  {
    route: PROFILE,
    tabActiveRoutes: [PROFILE],
    component: ProfileNavbarItem,
  },
];
