import { cognitionImpairments } from 'Assets/img/my-tools-icons';

export const understandingHowToCareEs = {
  key: 'understanding-how-to-care-es',
  title: 'Cuidar a alguien con necesidades cognitivas',
  titleImage: cognitionImpairments,
  titleImageAlt: '',
  contentId: 41,
  sections: [
    {
      key: 'understanding-how-to-care-intro-es',
      description:
        'Si estás leyendo este artículo, es probable que alguien cercano en tu vida padezca de problemas cognitivos o tenga un diagnóstico de demencia o Alzheimer. Este diagnóstico suele ser complicado y, a medida que la enfermedad avanza, por lo general termina afectándote más a ti que a la persona con limitaciones cognitivas. Estas son algunas opciones que te ayudarán a recorrer este camino.',
    },
    {
      key: 'whats-this-es',
      header: '¿Qué es esta enfermedad?',
      description:
        'La demencia es una categoría de enfermedades que incluye el Alzheimer y muchos otros diagnósticos de deterioro cognitivo. Para los fines de este artículo, utilizaremos el término “demencia” cuando hablemos en términos generales de cualquier enfermedad con deterioro cognitivo. Cuando esta enfermedad comienza, es como si el cerebro de la persona fuera un ovillo de hilo que comienza a desenredarse e incluso a romperse en varios puntos. A veces se desarrolla lentamente y otras veces sucede más rápido. Comienza con el hilo en el exterior del ovillo: la memoria a corto plazo de la persona. Los primeros signos de demencia suelen ser que la persona olvida cosas de las últimas horas, días, meses o años mientras la memoria a largo plazo permanece intacta. A medida que la enfermedad avanza, la memoria a largo plazo también se ve afectada. A veces, la persona con la enfermedad puede recordar sus tareas diarias o puede parecer que está actuando al “volver a la normalidad”. Lamentablemente, esto también es una parte típica de la progresión de la enfermedad. Es como si la persona estuviera mejorando, pero en realidad parece como si las piezas del hilo desenredado se volvieran a conectar por un momento. Por desgracia, volverán a su estado desenredado.',
    },
    {
      key: 'tips-dementia-es',
      header: 'Consejos para ayudar a cuidar a las personas con demencia',
      description:
        'Dependiendo de qué tan avanzada esté la enfermedad en la persona que estás cuidando, es posible que también hayas notado algunos cambios físicos. Por ejemplo, pueden asustarse fácilmente, cansarse más rápido o tener dificultades con habilidades motrices como usar un abrelatas o realizar tareas que requieren el uso de herramientas. La razón de esto se remonta a la analogía del hilo desenredándose o rompiéndose. Cuando somos jóvenes aprendemos tareas como atarnos las agujetas o escribir. Una vez que se aprenden esas habilidades o tareas, se guardan como un recuerdo, como un hilo que se conecta. La demencia en esencia desconecta ese hilo y ya no es posible realizar tareas que alguna vez fueron sencillas. Estos consejos te ayudarán en el cuidado de quienes padecen de esta enfermedad.',
    },
    {
      key: 'approach-person-es',
      header: 'Acércate siempre a la persona desde el frente',
      description:
        '¿Sabías que nuestros cerebros se comunican con nuestros ojos para decirnos dónde mirar y determinar lo que estamos viendo? La demencia afecta la vista y la visión periférica. Piensa en lo que puedes ver mientras estás sentado/a aquí leyendo. Al mirar hacia adelante, todavía puedes ver objetos a tu derecha e izquierda. La demencia a menudo afectará la visión periférica de una persona y se asustará fácilmente si te acercas por un lado o por detrás.',
    },
    {
      key: 'activities-childhood-es',
      header: 'Elige actividades para ver o hacer que la persona disfrutó durante la infancia',
      description:
        'Los recuerdos a largo plazo, en especial aquellos que formaban parte de actividades que disfrutaba cuando era más joven, tienden a permanecer intactos por más tiempo. Como resultado, los juegos de cartas o de mesa a menudo pueden ser una actividad para disfrutar juntos, sobre todo si es un juego que jugó durante su infancia. Por ejemplo, se puede jugar ¡Pesca!, dominó o a juegos sencillos de emparejar, incluso con personas con limitaciones cognitivas graves. Si le encantan los deportes, puedes intentar asistir a un evento deportivo o verlo por televisión.',
    },
    {
      key: 'activities-music-es',
      header: 'Toca, canta o escucha música familiar',
      description:
        'La música puede ser una excelente manera de ayudar a recordar y, a menudo, es una de las últimas partes del cerebro que se ven afectadas por la enfermedad. La música que la persona tocaba, cantaba o escuchaba cuando era más joven a menudo puede traer buenos recuerdos. Puede resultar sorprendente que al cantar canciones que son familiares para ellos, incluso para quienes están en etapas avanzadas de la enfermedad, pueden recordar melodías, palabras y el movimiento de las manos al tocar un instrumento.',
    },
    {
      key: 'remember-always-right-es',
      header: 'Lo que recuerdan siempre es correcto',
      description:
        'Un recuerdo incorrecto puede ser muy frustrante para alguien que sabe que la historia que está compartiendo no es correcta o que está combinada con múltiples recuerdos. Los recuerdos falsos o incorrectos son comunes tanto en las etapas iniciales como avanzadas de la demencia. Por ejemplo, los personajes de un programa de televisión que vio recientemente pueden comenzar a sentirse como recuerdos que ocurrieron en su vida. Corregir a la persona o decirle que su memoria es incorrecta por lo general solo provoca enojo y frustración. Además, si realmente cree que es el héroe de una película que acaban de ver, puede ser divertido seguirle el juego y meterse en el personaje.',
    },
    {
      key: 'avoid-memory-loss-es',
      header: 'Evita llamar la atención sobre su pérdida de memoria',
      description:
        '¿Has estado en una conversación con alguien que te hizo la misma pregunta varias veces? Este suele ser uno de los primeros signos reconocibles de demencia. Señalar que ya te hizo una pregunta puede causar frustración o tristeza en esa persona. Considera la posibilidad de jugar creando respuestas ligeramente diferentes cada vez. Dado que los recuerdos a corto plazo (en especial aquellos que han sucedido en los últimos minutos, horas o días) suelen ser los primeros que se pierden durante el proceso de la enfermedad, es poco probable que recuerden alguna de tus respuestas. ',
    },
  ],
};
