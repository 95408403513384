import YesNo from './Question/YesNo';
import MultiSelect from './Question/MultiSelect';
import SingleSelect from './Question/SingleSelect';
import IncomeSlider from './Question/IncomeSlider';

export const PrapareQuestionType = {
  YESNO: 'YESNO',
  MULTISELECT: 'MULTISELECT',
  SINGLESELECT: 'SINGLESELECT',
  INCOMESLIDER: 'INCOMESLIDER',
};

export const prapareQuestionMap = {
  [PrapareQuestionType.YESNO]: YesNo,
  [PrapareQuestionType.MULTISELECT]: MultiSelect,
  [PrapareQuestionType.SINGLESELECT]: SingleSelect,
  [PrapareQuestionType.INCOMESLIDER]: IncomeSlider,
};
