import { adminstratingMedication } from 'Assets/img/my-tools-icons';

export const administeringMedicationsEs = {
  key: 'administering-medications-es',
  title: 'Administración de medicamentos',
  titleImage: adminstratingMedication,
  titleImageAlt: '',
  contentId: 26,
  sections: [
    {
      key: 'administering-medications-intro-es',
      description:
        'Como cuidador, es crucial que comprendas algunos aspectos sobre la administración medicamentos. Comunícate con tu organización de cuidadores, el representante legalmente autorizado de la persona o su médico o enfermero para comprender lo que puedes y no puedes hacer como miembro de la familia que cuida a la persona o como cuidador remunerado. Las pautas para recetar medicamentos y quiénes pueden evaluar o administrar los medicamentos también varía según el estado en el que vives. En este artículo podrás ver un breve resumen de lo que debes saber como cuidador al ayudar a una persona a tomar sus medicamentos.',
    },
    {
      key: 'medications-overview-es',
      header: 'Introducción sobre los medicamentos',
      description:
        'Los medicamentos tienen diferentes categorías según su potencia, su posibilidad de dependencia o adicción y cómo se administran. Es importante conocer la categoría de tu medicamento por el siguiente motivo:',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: '<b>Resurtidos</b>: comprende qué medicamentos califican para un suministro de 90 días; hay otros que solo puedes resurtir cada 30 días o en otros períodos.',
        },
        {
          text: '<b>Autorización</b>: es importante saber por cuánto tiempo los médicos pueden autorizar ciertos medicamentos. Por ejemplo, algunas sustancias controladas necesitan la autorización de un médico cada vez que se vuelve a surtir, y esta autorización puede demorar algunos días.',
        },
        {
          text: '<b>Medicamentos según la necesidad</b>: como cuidador, no puedes evaluar los síntomas para determinar cuándo ofrecer los medicamentos que se toman según la necesidad. Estos medicamentos solo pueden ser administrados por el representante legalmente autorizado de la persona o por un proveedor médico con licencia para evaluar y administrar medicamentos.',
        },
        {
          text: '<b>Inyectables</b>: si la persona a la que estás cuidando tiene una afección que requiere medicamentos inyectables, como diabetes, debes saber qué puedes y qué no puedes hacer como cuidador. Comunícate con el médico de la persona, su enfermero o con el gerente de prestaciones médicas, o con tu organización de cuidadores para recibir capacitación sobre los signos y síntomas que indican que la persona necesita los medicamentos según la necesidad y las formas en que puedes ayudar a la persona a autoadministrarse el medicamento.',
        },
      ],
    },
    {
      key: 'organizing-medications-es',
      header: 'Organización de los medicamentos',
      description:
        'Como cuidador, estás limitado en lo que puedes hacer para ayudar a organizar los medicamentos de la persona. Lo que puedes y no puedes hacer depende del nivel de funcionamiento cognitivo de la persona. Por ejemplo, si no es cognitivamente capaz de tomar sus propias decisiones y su representante legalmente autorizado está tomando sus decisiones médicas, la persona no puede organizar sus medicamentos de rutina ni solicitar medicamentos "según su necesidad". En estos casos, un médico, farmacéutico, enfermero o el representante legal autorizado de la persona debe organizar los medicamentos. A continuación se ofrecen algunos consejos sobre lo que puedes hacer como cuidador para ayudar con la organización de los medicamentos:',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: '<b>Mantén una lista de todos los medicamentos en el refrigerador</b>: asegúrate de tener una lista actualizada de medicamentos que incluya el nombre, el tipo, el uso, la dosis y una imagen (si es en forma de pastilla) en el refrigerador. En caso de emergencia, los primeros respondientes o socorristas verán el refrigerador para conocer los medicamentos y el diagnóstico de la persona. Esto también es útil para los cuidadores que están en el hogar o si hablas con uno de los médicos de la persona. Puedes pedir esta lista a su médico.  ',
        },
        {
          text: '<b>Pastilleros</b>: normalmente, los enfermeros o el representante legal autorizado de la persona llenarán los pastilleros cada semana para todos los medicamentos de rutina (diarios y dos veces al día). Como cuidador, puedes recordarle a la persona que tiene que tomar sus medicamentos y también abrir y entregar el pastillero. No puedes organizar sus medicamentos ni entregárselos directamente.',
        },
        {
          text: '<b>Dispensadores de medicamentos y paquetes de pastillas automatizados</b>: para los medicamentos que califican para resurtido y entrega automáticos, existen paquetes de pastillas que la persona puede recibir por correo (paquetes con medicamentos etiquetados individualmente según la hora del día) y dispensadores de medicamentos (que el enfermero o el representante legalmente autorizado resurten) que alertan a la persona cuando es el momento de tomar los medicamentos. Pregunta al médico, enfermero o gerente de prestaciones médicas si esto podría ser una buena opción en tu caso.',
        },
      ],
    },
    {
      key: 'creating-calendar-schedule-es',
      header: 'Crear un calendario u horario',
      description:
        'Es importante crear un horario o calendario para realizar un seguimiento de los medicamentos que hay que resurtir, los medicamentos “según la necesidad”, los síntomas específicos de una afección o cualquier otra información solicitada por el gerente de prestaciones médicas, el enfermero o el médico de la persona.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: '<b>Medicamentos que hay que resurtir para 30 días</b>: asegúrate de realizar un seguimiento y solicitar un resurtido al menos una semana antes de que se acabe el medicamento. Si necesitas una autorización médica, agrega unos días más para recibir la autorización.',
        },
        {
          text: '<b>Calendario de autorización del médico</b>: averigua cuándo debes ver al médico en persona para que te resurta los medicamentos. También agrega esto al calendario.',
        },
        {
          text: '<b>Medicamentos que hay que resurtir para 90 días</b>: pregunta al enfermero o al gerente de prestaciones médicas de la persona si califica para resurtido automático de los medicamentos de rutina. Si el medicamento no se puede surtir automáticamente, asegúrate de solicitar un resurtido al menos dos semanas antes de que se acabe.',
        },
        {
          text: '<b>Medicamentos según la necesidad</b>: mantén un calendario de cuándo la persona tomó los medicamentos según su necesidad e incluye los síntomas que experimentó y la hora del día a la que los tomó. Solicita un resurtido al menos una semana (estimado según el uso) antes de que se acabe.',
        },
      ],
    },
  ],
};
