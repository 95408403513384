import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

const Flower = (props) => {
  const intl = useIntl();

  return (
    <svg
      role="img"
      focusable="false"
      aria-labelledby="svgFlowerTitle"
      aria-describedby="svgFlowerDescription"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 900 900"
    >
      <title id="svgFlowerTitle">
        <FormattedMessage id="image.coloring-book.flower" />
      </title>
      <desc id="svgFlowerDescription">{intl.formatMessage({ id: 'image.coloring-book.flower-description' })}</desc>
      <g id="FlowerLayer" data-name="Layer">
        <path
          data-testid="FlowerRegion1"
          onClick={() => props.onFill(0)}
          fill={props.colors[0]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M733.63,241.34c31.68-40.48,44.62-79.81,13.47-105.52,0,0-33.35-36.25-99.66,22.7,10.14,59.95-80.92,172.93-138.92,227.68.94.89,6,5.28,6.9,6.29C641.36,250.85,702.18,237,733.63,241.34Z"
        />
        <path
          onClick={() => props.onFill(1)}
          fill={props.colors[1]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M800,411.11c47.67-19.22,78.54-46.81,64.41-84.65,0,0-10.75-48.07-97.65-30.17-21.2,57-157.48,111.34-234.28,127,.36,1.24,2.7,8.27,3,9.58C716.24,374.44,774.9,391.62,800,411.11Z"
        />
        <path
          onClick={() => props.onFill(2)}
          fill={props.colors[2]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M772.52,591.3c50.9,7.2,91.43-1.26,98.11-41.1,0,0,14.73-47-69.48-75C754.3,514,607.7,491.67,534.74,468.12c-.3,1.25-1.79,8.51-2.22,9.78C722.6,517.73,760.56,561.89,772.52,591.3Z"
        />
        <path
          onClick={() => props.onFill(3)}
          fill={props.colors[3]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M658.66,733.63c40.48,31.68,79.81,44.62,105.52,13.47,0,0,36.25-33.35-22.7-99.66-60,10.14-172.93-78.89-227.15-139.38-.89.93-5.81,6.48-6.82,7.36C650.21,645.61,663,702.18,658.66,733.63Z"
        />
        <path
          onClick={() => props.onFill(4)}
          fill={props.colors[4]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M488.89,800c19.22,47.67,46.81,78.54,84.65,64.41,0,0,48.07-10.75,30.17-97.65-57-21.2-112.4-162.42-127-234.28-1.24.36-8.27,2.7-9.58,3C526.27,722.6,508.38,774.9,488.89,800Z"
        />
        <path
          onClick={() => props.onFill(5)}
          fill={props.colors[5]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M308.7,772.52c-7.2,50.9,1.26,91.43,41.1,98.11,0,0,47,14.73,74.95-69.48C386,754.3,408.69,605.72,431.88,534.74c-1.25-.3-8.51-1.79-9.78-2.22C382.56,716.24,338.11,760.56,308.7,772.52Z"
        />
        <path
          onClick={() => props.onFill(6)}
          fill={props.colors[6]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M166.37,658.66c-31.68,40.48-44.62,79.81-13.47,105.52,0,0,33.35,36.25,99.66-22.7-10.14-60,82.68-178.59,139.38-227.15-.93-.89-6.48-5.81-7.36-6.82C263.92,645.61,197.82,663,166.37,658.66Z"
        />
        <path
          onClick={() => props.onFill(7)}
          fill={props.colors[7]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M100,488.89c-47.67,19.22-78.54,46.81-64.41,84.65,0,0,10.75,48.07,97.65,30.17,21.2-57,159-111,234.28-127-.36-1.24-2.7-8.27-3-9.58C197.93,524.15,125.1,508.38,100,488.89Z"
        />
        <path
          onClick={() => props.onFill(8)}
          fill={props.colors[8]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M127.48,308.7c-50.9-7.2-91.43,1.26-98.11,41.1,0,0-14.73,47,69.48,74.95C145.7,386,292.3,408.33,365.26,431.88c.3-1.25,1.79-8.51,2.22-9.78C187.29,384.58,139.44,338.11,127.48,308.7Z"
        />
        <path
          onClick={() => props.onFill(9)}
          fill={props.colors[9]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M241.34,166.37c-40.48-31.68-79.81-44.62-105.52-13.47,0,0-36.25,33.35,22.7,99.66,59.95-10.14,179.9,84.55,227.15,139.38.89-.93,5.81-6.48,6.82-7.36C261.44,269.56,237,197.82,241.34,166.37Z"
        />
        <path
          onClick={() => props.onFill(10)}
          fill={props.colors[10]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M411.11,100c-19.22-47.67-46.81-78.54-84.65-64.41,0,0-48.07,10.75-30.17,97.65,57,21.2,112.75,162.78,127,234.28,1.24-.36,8.27-2.7,9.58-3C375.85,196.12,391.62,125.1,411.11,100Z"
        />
        <path
          onClick={() => props.onFill(11)}
          fill={props.colors[11]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M591.3,127.48c7.2-50.9-1.26-91.43-41.1-98.11,0,0-47-14.73-75,69.48,38.75,46.85,16.06,195.78-7.13,266.41,1.25.3,8.51,1.79,9.78,2.22C515.42,188,561.89,139.44,591.3,127.48Z"
        />
        <path
          onClick={() => props.onFill(12)}
          fill={props.colors[12]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-2"
          d="M450.37,451.21a.9.9,0,0,1-1.22.37.89.89,0,0,1-.37-1.21A.91.91,0,0,1,450,450,.89.89,0,0,1,450.37,451.21Z"
        />
        <path
          onClick={() => props.onFill(13)}
          fill={props.colors[13]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-2"
          d="M512.72,508.28a87.23,87.23,0,0,1-122.78,4.1,86.06,86.06,0,0,1-4.2-122.12,87.23,87.23,0,0,1,122.78-4.06A86,86,0,0,1,512.72,508.28Z"
        />
        <path
          onClick={() => props.onFill(14)}
          fill={props.colors[14]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-2"
          d="M450,450a1.06,1.06,0,0,1-1.5.05,1,1,0,0,1-.05-1.49,1.06,1.06,0,0,1,1.5-.05A1,1,0,0,1,450,450Z"
        />
        <path
          onClick={() => props.onFill(15)}
          fill={props.colors[15]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M432.9,364.59c-94.58-285.31,5.89-281.11,5.89-281.11,90.85-8.71,51.83,228.68,29.33,281.78a113.17,113.17,0,0,0-17.53-2A111.64,111.64,0,0,0,432.9,364.59Z"
        />
        <path
          onClick={() => props.onFill(16)}
          fill={props.colors[16]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M477.9,367.48C538.65,73.11,623.55,127,623.55,127c83,37.89-69.46,224-115.49,258.69a93,93,0,0,0-30.16-18.19Z"
        />
        <path
          onClick={() => props.onFill(17)}
          fill={props.colors[17]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M515.42,392.49C715.22,167.92,761.81,257,761.81,257c53,74.33-172.13,159.23-229.36,166.29a93,93,0,0,0-17-30.83Z"
        />
        <path
          onClick={() => props.onFill(18)}
          fill={props.colors[18]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M535.41,432.9c285.31-94.58,281.11,5.89,281.11,5.89,8.71,90.85-228.68,51.83-281.78,29.33a113.17,113.17,0,0,0,2-17.53A111.64,111.64,0,0,0,535.41,432.9Z"
        />
        <path
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          onClick={() => props.onFill(19)}
          fill={props.colors[19]}
          className="cls-1"
          d="M532.52,477.9C826.89,538.65,773,623.55,773,623.55c-37.89,83-224-69.46-258.69-115.49a93,93,0,0,0,18.19-30.16Z"
        />
        <path
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          onClick={() => props.onFill(20)}
          fill={props.colors[20]}
          className="cls-1"
          d="M507.51,515.42C732.08,715.22,643,761.81,643,761.81c-74.33,53-159.23-172.13-166.29-229.36a93,93,0,0,0,30.83-17Z"
        />
        <path
          onClick={() => props.onFill(21)}
          fill={props.colors[21]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M467.1,535.41c94.58,285.31-5.89,281.11-5.89,281.11-90.85,8.71-51.83-228.68-29.33-281.78a113.17,113.17,0,0,0,17.53,2A111.64,111.64,0,0,0,467.1,535.41Z"
        />
        <path
          onClick={() => props.onFill(22)}
          fill={props.colors[22]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M422.1,532.52C361.35,826.89,276.45,773,276.45,773c-83-37.89,69.46-224,115.49-258.69a93,93,0,0,0,30.16,18.19Z"
        />
        <path
          onClick={() => props.onFill(23)}
          fill={props.colors[23]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M384.58,507.51C184.78,732.08,138.19,643,138.19,643c-53-74.33,172.13-159.23,229.36-166.29a93,93,0,0,0,17,30.83Z"
        />
        <path
          onClick={() => props.onFill(24)}
          fill={props.colors[24]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M364.59,467.1c-285.31,94.58-281.11-5.89-281.11-5.89-8.71-90.85,228.68-51.83,281.78-29.33a113.17,113.17,0,0,0-2,17.53A111.64,111.64,0,0,0,364.59,467.1Z"
        />
        <path
          onClick={() => props.onFill(25)}
          fill={props.colors[25]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M367.48,422.1C73.11,361.35,127,276.45,127,276.45c37.89-83,224,69.46,258.69,115.49a93,93,0,0,0-18.19,30.16Z"
        />
        <path
          onClick={() => props.onFill(26)}
          fill={props.colors[26]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M392.49,384.58C167.92,184.78,257,138.19,257,138.19c74.33-53,159.23,172.13,166.29,229.36a93,93,0,0,0-30.83,17Z"
        />
        <path
          onClick={() => props.onFill(27)}
          fill={props.colors[27]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-2"
          d="M450,450a.9.9,0,0,1-1.22.37.89.89,0,0,1-.37-1.21.91.91,0,0,1,1.22-.37A.89.89,0,0,1,450,450Z"
        />
      </g>
    </svg>
  );
};

Flower.propTypes = {
  onFill: PropTypes.func,
  colors: PropTypes.array,
};

export default Flower;
