import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Banner from './Banner/Banner';
import Section from './Section/Section';
import {
  StyledContent,
  StyledHeader,
  StyledHeaderImage,
  StyledButtonContainer,
  StyledButton,
  StyledParagraph,
} from './styles';
import { useHistory } from 'react-router-dom';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';
import useLogPsychologyContent from 'Core_Hooks/useLogPsychologyContent';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectUserInfo } from 'Core_Redux/user';

function MyToolsStaticContent({ config }) {
  const { key, title, titleImage, titleImageAlt, description, sections, contentId } = config;
  const [expandedSections, setExpandedSections] = useState([]);
  const intl = useIntl();
  const history = useHistory();
  const user = useCoreSelector(selectUserInfo);
  const { logContentInteraction } = useLogPsychologyContent();

  function handleClick() {
    history.push(HOME);
  }

  function setIsExpanded(sectionKey, expanded) {
    let newValue;
    if (expanded && expandedSections.indexOf(sectionKey) === -1) {
      newValue = [...expandedSections, sectionKey];
    } else {
      newValue = expandedSections.filter((s) => s !== sectionKey);
    }

    setExpandedSections(newValue);
    if (newValue.length > 0) {
      localStorage.setItem(key, newValue.toString());
    } else {
      localStorage.removeItem(key);
    }
  }

  useEffect(() => {
    //It is likely the requirements will change on when the log is to be created
    //this call was made to be flexible on when it is triggered--you're welcome
    logContentInteraction(contentId, user.sub);

    try {
      const storage = localStorage.getItem(key);
      const sections = storage ? storage.split(',') : [];
      setExpandedSections(sections);
    } catch (e) {
      setExpandedSections([]);
    }
  }, []);

  return (
    <>
      <Banner />
      <StyledContent>
        <StyledHeader>{title}</StyledHeader>
        <StyledHeaderImage src={titleImage} alt={titleImageAlt} />
        <StyledParagraph>{description}</StyledParagraph>
        {sections &&
          sections.map((s, i) => (
            <Section
              key={s.key}
              index={i}
              config={s}
              isExpanded={expandedSections.includes(s.key)}
              setIsExpanded={setIsExpanded}
            />
          ))}
        <StyledButtonContainer>
          <StyledButton
            type="submit"
            aria-label={intl.formatMessage({ id: 'common.ok' })}
            variant="contained"
            onClick={handleClick}
          >
            {intl.formatMessage({ id: 'common.ok' })}
          </StyledButton>
        </StyledButtonContainer>
      </StyledContent>
    </>
  );
}
MyToolsStaticContent.propTypes = {
  config: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    titleImage: PropTypes.string,
    titleImageAlt: PropTypes.string,
    description: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.object),
    contentId: PropTypes.number,
  }).isRequired,
};

export default MyToolsStaticContent;
