import React, { useState, useContext, useEffect, useRef } from 'react';
import Chat from 'Core_Components/Chat/Chat';
import useInterval from 'Core_Hooks/useInterval.js';
import AndyProfile from 'Assets/img/andy-profile-small.svg';
import messages from './messages';
import PropTypes from 'prop-types';
import { UserContext } from 'Contexts/UserContext';
import { useIntl } from 'react-intl';
import useGetChatScreen from 'Core_Hooks/useGetChatScreen';
import { BUBBLE } from 'Core_Components/Chat/ChatItem/ChatTypes.js';
import { RIGHT } from 'Core_Components/Chat/ChatItem/Position.js';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectSyncUser } from 'Core_Redux/user';

const DeterminedChat = ({ type }) => {
  const syncUser = useCoreSelector(selectSyncUser);
  const intl = useIntl();

  const statusConfig = {
    andy: {
      loading: true,
      avatar: AndyProfile,
      alt: intl.formatMessage({ id: 'image.andy.typing' }),
      name: 'Andy',
      typingText: {
        left: intl.formatMessage({ id: 'common.message-from-andy' }),
        right: intl.formatMessage({ id: 'common.message-to' }),
      },
    },
    none: { loading: false },
  };

  const config = statusConfig.andy;
  const { userInfo, setUserInfo } = useContext(UserContext);

  const defaultSpeed = process.env.CHAT_DELAY || 2000;
  const [loadedMessages, setLoadedMessages] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatStatus, setChatStatus] = useState(config);
  const [interval, setInterval] = useState(defaultSpeed);
  const [loadedChatCount, setLoadedChatCount] = useState(0);
  const { screens } = useGetChatScreen();
  const [screening, setScreening] = useState();
  const screenRef = useRef(screen);

  const headingLabel = intl.formatMessage({ id: 'title.andy-chat' });

  useEffect(() => {
    setScreening(screens(type, userInfo));
  }, [userInfo]);

  useEffect(() => {
    const messagesToBeLoaded = messages(screening, setScreening, syncUser?.firstName, intl);
    if (messagesToBeLoaded) {
      setInterval(defaultSpeed);
      setLoadedChatCount(messagesToBeLoaded.length + loadedChatCount);
      setLoadedMessages(loadedMessages.concat(messagesToBeLoaded));
    }

    screenRef.current = screen;
  }, [screening]);

  useInterval(() => {
    setChatStatus({ ...config, ...statusConfig.none });
    setChatMessages(chatMessages.concat(loadedMessages[chatMessages.length]));
    setInterval(null);

    const timeout = setTimeout(() => {
      setInterval(defaultSpeed);
      setChatStatus(config);
      if (loadedChatCount === chatMessages.length + 1) {
        setInterval(null);
        setChatStatus({ ...config, ...statusConfig.none });
      }
    }, defaultSpeed / 4);

    return () => clearTimeout(timeout);
  }, interval);

  const processChat = (data) => {
    const currentMessage = chatMessages[chatMessages.length - 1];
    if (currentMessage.handleSubmit) {
      const isValid = currentMessage.handleSubmit(data, userInfo, setUserInfo);
      if (isValid) {
        let mutableMessages = [...chatMessages];

        mutableMessages[mutableMessages.length - 1] = { text: data.text, type: BUBBLE, position: RIGHT };
        setChatMessages(mutableMessages);
      }
    }
  };

  return (
    <>
      <Chat
        headingLabel={headingLabel}
        data-testid="Chat"
        screen={type}
        messages={chatMessages}
        status={chatStatus}
        onSubmit={processChat}
      />
    </>
  );
};

DeterminedChat.propTypes = {
  type: PropTypes.string,
};

export default DeterminedChat;
