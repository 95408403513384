import styled from 'styled-components';

export const StyledTextContainer = styled.div`
  width: 45%;
  height: 100%;
  color: #0f0f0f;
  background-color: #ffff;
  padding: 5%;
  border-radius: 86%;
  text-align: center;
  position: relative;
  opacity: ${(props) => (props.fadeIn ? '0.9' : '0')};
  transition: opacity 0.25s;
  font-weight: ${(props) => (props.isResponse ? '500' : '400')};
  left: ${(props) => (props.x < 0 ? '1vh' : `${props.x}px`)};
  top: ${(props) => (props.y < 0 ? '1vh' : `${props.y}px`)};
  border: 2px solid ${(props) => (props.isResponse ? '#00b3a6' : '#FFFF')};
  @media screen and (max-width: 350px) {
    line-height: ${(props) => (props.isResponse ? '' : '1.1')};
    padding: ${(props) => (props.isResponse ? '' : '3%')};
    font-size: ${(props) => (props.isResponse ? '' : '0.75rem')};
    width: ${(props) => (props.isResponse ? '' : '39%')};
  }
`;

export const Triangle = styled.div`
  z-index: -1;
  width: ${(props) => (props.isResponse ? '65%' : '22%')};
  height: ${(props) => (props.isResponse ? '200%' : '50%')};
  position: absolute;
  top: ${(props) => (props.isResponse ? '-65%' : props.isFlipped ? '98%' : '88%')};
  left: ${(props) => (props.isResponse ? '-30%' : props.isFlipped ? '12%' : '75%')};
  content: '';
  transform: ${(props) =>
    props.isResponse ? 'rotate(58deg)' : props.isFlipped ? 'scaleX(-1.0) rotate(319deg)' : 'rotate(319deg)'};
  border-top: ${(props) => (props.isResponse ? '0vh' : '0px')} solid transparent;
  border-bottom: ${(props) => (props.isResponse ? '17vh' : '61px')} solid transparent;
  border-right: ${(props) => (props.isResponse ? '2vh' : '17px')} solid
    ${(props) => (props.isResponse ? '#00b3a6' : 'transparent')};
  mix-blend-mode: lighten;

  &::after {
    content: '';
    position: absolute;
    top: ${(props) => (props.isResponse ? '0%' : '0')};
    left: ${(props) => (props.isResponse ? '2%' : '29.5px')};
    border-top: ${(props) => (props.isResponse ? '1.0vh' : '0px')} solid transparent;
    border-bottom: ${(props) => (props.isResponse ? '17vh' : '47px')} solid transparent;
    border-right: ${(props) => (props.isResponse ? '1.5vh' : '13px')} solid #ffff;
    height: ${(props) => (props.isResponse ? '100%' : '100%')};
    width: ${(props) => (props.isResponse ? '100%' : '100%')};
  }
`;
