import { control } from 'Assets/img/my-tools-icons';

export const controlEs = {
  key: 'control-es',
  title: 'Cosas que puedo y no puedo controlar',
  titleImage: control,
  titleImageAlt: 'two thinking bubbles, one with green checkmark, one with blue letter x',
  description:
    'Saber lo que está dentro y fuera de nuestro control puede ayudar a reducir el estrés y mejorar la salud en general.  ',
  contentId: 15,
  sections: [
    {
      key: 'control-description-es',
      // eslint-disable-next-line no-irregular-whitespace
      description: `A veces, lidiar con situaciones difíciles puede generar miedo y la necesidad de controlar los resultados. Sin embargo, tratar de controlar lo incontrolable (la actitud de los demás, el término de un trabajo, las enfermedades, etc.) puede consumir mucha energía, generar estrés y tener resultados negativos en el bienestar. \n\nLa mayoría de las veces, el único control real que tenemos es sobre nosotros. Es decir, lo que pensamos, decimos y hacemos (aquí se incluyen la actitud, la mentalidad y la perspectiva). Podemos expresar nuestros puntos de vista y hacer un esfuerzo para influir en los resultados. Sin embargo, en última instancia, no podemos controlar los pensamientos o los comportamientos de las personas, ni cambiar todas las condiciones. Quedarse pensando en cosas sobre las que tenemos poca influencia o control puede generar frustración, sensación de impotencia y agotamiento.\n\nSaber lo que está bajo nuestro control puede ayudarnos a ahorrar tiempo y energía para lo que importa. Cuanto más nos enfocamos en lo que podemos controlar, más eficaces podemos ser para cumplir objetivos y tareas. La actividad del «Círculo de control» es una herramienta poderosa para ayudar a enfocar la atención y la energía. El marco de referencia ayuda a identificar las áreas de la vida en las que puedes tomar medidas directas para influir en los resultados. De manera innata, las personas tienen determinadas áreas donde la influencia será más fuerte que en otras. El concepto es simple y directo:  \n       • Áreas dentro de mi control\n       • Áreas en las que puedo influir\n       • Todo lo demás\nEs una forma eficaz de empoderar la acción hacia los objetivos personales, disminuir la preocupación y el estrés, apoyar el cuidado personal y lograr el éxito a largo plazo.`,
    },
    {
      key: 'control-begin-es',
      header: 'Para empezar',
      description: 'Usa un lápiz y papel o tu computadora/dispositivo para continuar.',
    },
    {
      key: 'control-step-one-es',
      header: 'Paso 1: Haz una lista de tus preocupaciones o inquietudes.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Haz una lista de todas las preocupaciones que tienes en mente. Pueden ser pequeñas o grandes, desde pagar facturas hasta mantenerte sano durante la pandemia. Permítete «descargar el cerebro» de tus pensamientos sin juzgar.',
        },
      ],
    },
    {
      key: 'control-step-two-es',
      header: 'Paso 2: En otra página, dibuja 2 círculos grandes, uno al lado del otro.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Llama a uno de los círculos «Control» y al otro «Sin control».',
        },
      ],
    },
    {
      key: 'control-step-three-es',
      header:
        'Paso 3: Dentro del círculo «Control», escribe los elementos de tu lista sobre los que tienes control (o tienes mucha influencia). ',
    },
    {
      key: 'control-step-four-es',
      header:
        'Paso 4: Dentro del círculo «Sin control», escribe los elementos de tu lista sobre los que no tienes control (o tienes poca influencia).',
    },
    {
      key: 'control-step-five-es',
      header: 'Paso 5: Revisión/evaluación.',
      itemsHeader: 'Círculo Control',
      items: [
        {
          text: 'Revisa lo que puedes controlar. ¿Hay áreas a las que podrías dedicar más tiempo o energía para beneficiarte?',
          subItems: [
            { text: 'En el pasado, ¿cómo lidiabas con las cosas que estaban bajo tu control personal?' },
            { text: '¿Qué acciones fueron útiles?' },
            { text: '¿Qué acciones fueron menos útiles?' },
          ],
        },
      ],
    },
    {
      key: 'control-step-five-no-control-es',
      itemsHeader: 'Círculo Sin control',
      items: [
        {
          text: 'Revisa lo que no puedes controlar. Considera lo que ganarías si dejaras de preocuparte por ellas. ¿Qué tan listo/dispuesto estás para liberarte de esta preocupación y aceptar la situación?',
          subItems: [
            {
              text: 'Hasta ahora, ¿qué tan bien te ha ido en el tratamiento de estas áreas utilizando las estrategias actuales?',
            },
            { text: '¿Hay áreas de las que podrías sacar más beneficio si les dedicaras menos tiempo o energía?' },
            { text: '¿Cómo te sentirías si dejases de preocuparte por ellas?' },
            { text: 'Piensa en algún momento en el que pudiste soltar el control y dejar de preocuparte.' },
            { text: '¿Cómo abandonaste lo incontrolable?' },
            { text: '¿Qué pasos puedes tomar para dejar de preocuparte más por las cosas que no puedes controlar?' },
            { text: 'Si no hay ninguno, ¿qué te impide hacerlo?' },
          ],
        },
      ],
    },
    {
      key: 'control-step-six-es',
      header: 'Paso 6: Ríndete y deja de preocuparte.',
      description:
        'Rendirse aquí significa decidir dejar de lado los esfuerzos improductivos por controlar lo incontrolable. No es lo mismo que verse superado sin elección y convertirse en víctima o prisionero de ello. Rendirse significa abandonar las cosas que no puedes controlar y concentrarte en las cosas que puedes controlar. Implica aceptar que no hay nada más que puedas hacer para cambiar la situación.\n\nSin embargo, no siempre es fácil dejar de lado ciertas preocupaciones con un chasquido de dedos. Aceptarlo y dejar de preocuparse también es un proceso. Puede ser útil darse un tiempo y tomar medidas para:\n       • Validar la preocupación y verla con la mayor compasión posible\n       • Reconocer que la preocupación tiene poco efecto en las cosas sobre las que tienes poco o ningún control\n       • Reconocer que sería mejor utilizar tu energía en otro lugar\n\nSi te das cuenta de que te estás preocupando por cosas que están fuera de tu control, sé amable contigo mismo. Vuelve poco a poco a centrar tu atención en tu círculo de Control y en las acciones que puedes realizar para tu beneficio.',
    },
    {
      key: 'control-self-reflection-es',
      header: 'Autorreflexión',
      description: 'Tómate un tiempo para revisar tu experiencia con esta actividad.',
      itemsHeader: 'Información y sugerencias',
      items: [
        {
          text: '¿Qué has aprendido de este ejercicio?',
        },
        {
          text: '¿Cómo te sentías antes de completar este ejercicio?',
        },
        {
          text: '¿Cómo te sientes después de hacerlo?',
        },
        {
          text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?',
        },
        {
          text: '¿Qué disfrutaste más de este ejercicio?',
        },
        {
          text: '¿Cuáles serían algunos beneficios de hacer esta actividad de manera regular?',
        },
      ],
    },
    {
      key: 'control-wrap-up-es',
      description:
        'Las investigaciones han demostrado que, aunque es importante ser consciente de los límites del control personal, es igual de importante ser consciente de la capacidad de influir en las situaciones para tu beneficio.\n\nElegir conscientemente dedicar tu tiempo a lo que puedes controlar e influir puede empoderar y generar resultados con un efecto más significativo.\n\nCuando las cosas están fuera del control personal, el objetivo es minimizar la cantidad de tiempo, energía y atención que se les dedica.\n\nAceptar y dejar de preocuparse puede ser más fácil de decir que de hacer. Es una práctica continua y una exploración de valores personales para dar forma a resultados, decisiones y dirección.',
    },
  ],
};
