import axios from 'axios';
import 'regenerator-runtime/runtime.js';
import AuthorizationService from 'Onboard_Helpers/authentication/AuthService';
import { isEnvironment, PRODUCTION } from 'Onboard_Helpers/isEnvironment';
import { appInsights } from 'Onboard_Helpers/AppInsights';
import StorageService from 'Onboard_Helpers/authentication/StorageService';
import { areTokensValid } from './authentication/authTokens';

const getAuthClient = async () => {
  const authService = await AuthorizationService.getInstance();

  const authorizedApi = axios.create();

  authorizedApi.interceptors.request.use((request) => {
    request.meta = request.meta || {};
    request.meta.requestStartedAt = new Date().getTime();
    return request;
  });

  authorizedApi.interceptors.response.use(
    (response) => {
      response.responseTime = new Date().getTime() - response.config.meta.requestStartedAt;
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        appInsights.trackTrace({ message: 'User not authenticated.' });
      } else {
        return Promise.reject(error);
      }
    },
  );

  try {
    const storage = await StorageService.getInstance();
    const dateTimeOverride = await storage.getItem('dateTimeOverride');
    if (dateTimeOverride && !isEnvironment(PRODUCTION)) {
      authorizedApi.defaults.headers.common['x-datetime-override'] = dateTimeOverride;
    }
  } catch (error) {
    appInsights.trackException({
      exception: `Exception: Failed to get dateTimeOverride from localStorage.`,
      properties: { errorMessage: error?.message },
    });
  }

  try {
    let tokens = await authService.getTokens();

    if (!tokens) {
      appInsights.trackException({
        exception: 'Exception: No tokens found',
        properties: { errorMessage: error?.message },
      });
      return;
    }

    if (!areTokensValid(tokens.accessToken, tokens.refreshToken)) {
      await authService.tryRefreshTokens();
      tokens = await authService.getTokens();
    }

    authorizedApi.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`;
    return authorizedApi;
  } catch (error) {
    appInsights.trackException({
      exception: 'Exception: Failed to set auth token',
      properties: { errorMessage: error?.message },
    });
  }
};

export default getAuthClient;
