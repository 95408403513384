import ResultsBody from './ResultsBody';
import Description from './Description';
import Header from './Header';
import { ResultVariantTypes } from 'Core_Pages/Screening/Results/Result/ResultContext';

const AdultPHQ4 = {
  [ResultVariantTypes.Header]: Header,
  [ResultVariantTypes.Description]: Description,
  [ResultVariantTypes.Body]: ResultsBody,
};

export default AdultPHQ4;
