import { friendshipIngredients } from 'Assets/img/my-tools-icons';

export const friendshipEs = {
  key: 'friendship-es',
  title: 'Ingredientes de la amistad',
  titleImage: friendshipIngredients,
  titleImageAlt: '',
  contentId: 22,
  sections: [
    {
      key: 'friendship-intro-es',
      hasNoSectionMargin: true,
      description:
        '¿Qué hace que una amistad sea sana? Una que te llene de energía en vez de drenarla. Una amistad sana crece cuando las dos personas que la componen se quieren y participan en ella por igual. Por eso, es importante tener en cuenta el tipo de persona que quieres como amigo. Al igual que cuando se prepara un pastel, las amistades tienen ingredientes especiales, como los siguientes:',
      bulletedItems: [
        { text: 'Bondad' },
        { text: 'Apoyo' },
        { text: 'Escucha' },
        { text: 'Honestidad' },
        { text: 'Confianza' },
        { text: 'Paciencia' },
        { text: 'Perdón' },
        { text: 'Respeto' },
        { text: 'Lealtad' },
        { text: 'Diversión' },
      ],
    },
    {
      key: 'friendship-examples-es',
      description: 'Para saber qué es importante para ti en un amigo, puedes hacerte estas preguntas:',
      bulletedItems: [
        { text: '¿Cuál es el rasgo o la cualidad?' },
        { text: '¿Cómo es?' },
        { text: '¿Por qué es importante?' },
      ],
    },
    {
      key: 'friendship-empathy-es',
      header: 'Empatía',
      description:
        'La empatía es la capacidad de comprender el estado emocional de alguien: cómo se siente y por qué lo siente. La empatía ayuda a aliviar la tensión emocional sin asumir esos sentimientos.\n\nPor ejemplo, el mero hecho de estar con una persona mientras llora crea un espacio valioso y seguro para ella. Solo con escuchar, demuestras que estás a su lado mientras lidian con sus emociones, sin pretender arreglarlas ni juzgarlas.',
    },
    {
      key: 'friendship-trust-es',
      header: 'Confianza',
      description:
        'Los amigos respetan la información y los pensamientos privados de los demás. Por ejemplo, si sospechas que alguien habla de tus asuntos personales a tus espaldas, es probable que te genere desconfianza y que te alejes. La desconfianza arruina las amistades. Es todo lo contrario a la seguridad.',
    },
    {
      key: 'friendship-honesty-es',
      header: 'Honestidad',
      description:
        'Ser honesto genera confianza en las amistades. Incluso decir cosas duras puede ser útil cuando se dicen con honestidad y amabilidad. Y la honestidad crea una sensación de libertad. Ninguna de las dos personas tiene que esforzarse por ser ellas mismas porque siempre son sinceras la una con la otra.',
    },
    {
      key: 'friendship-vulnerability-es',
      header: 'Vulnerabilidad',
      description:
        'Al exponer la información privada y los sentimientos, la persona puede sentirse demasiado abierta y vulnerable. Sin embargo, hacerlo puede ayudar a crear intimidad en las amistades, lo que puede llevar a crear más confianza y una conexión más profunda. La sensación de seguridad para expresar tus verdades abiertamente es una señal de que la amistad tiene una base de confianza.',
    },
    {
      key: 'friendship-reliability-es',
      header: 'Fiabilidad',
      description:
        'Las amistades significativas se dan cuando cada persona siente que puede acercarse a la otra y obtener una respuesta a cambio. Ya sea enviando mensajes de texto todos los días, llamando cada semana o reuniéndose una vez al mes, las conexiones continuas hacen que los momentos difíciles sean más fáciles y los fáciles, mejores.\n\nMereces que te vean, te comprendan y te aprecien. Las amistades sanas comparten el interés y la participación, y ayudan a que el camino por la vida sea rico y significativo.',
    },
  ],
};
