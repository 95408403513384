import styled from 'styled-components';

export const CharacterContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isPyxirHeader ? 'left' : 'center')};
  align-items: center;
  background: ${(props) => props.backgroundColor};
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.15);
  font-size: 20px;
  z-index: 2;
  height: 138px;
  padding: 10px 15px 0 15px;
`;

export const CharacterImage = styled.img`
  align-self: ${(props) => props.imageAlignment};
  margin: ${(props) => (props.isPyxirHeader ? '0 0 12px -25px' : '0')};
`;

export const StyledMessage = styled.h2`
  color: #fafafa;
  letter-spacing: 0.17px;
  line-height: 23px;
  font-size: 20px;
  font-weight: bold;
  width: 95%;
  text-align: ${(props) => (props.isPyxirHeader ? 'left' : 'center')};
  font-weight: 400;
  align-self: ${(props) => props.imageAlignment};
`;

export const GreetingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-left: 30px;
`;

export const GreetingImage = styled.img`
  width: 180px;
`;
