import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { MenuItem, Radio } from '@mui/material';
import { Controller } from 'react-hook-form';
import { StyledContainer, StyledRadioContainer, StyledSelect, StyledSelectRow, StyledSelectContainer } from './styles';
import { ordinals, weekdays } from '../reminderConstants';

function MonthIntervalConfiguration({ control }) {
  const intl = useIntl();

  return (
    <StyledContainer>
      <Controller
        name="monthIntervalConfigOption"
        control={control}
        defaultValue={MonthIntervalConfiguration.ConfigurationOptions.CARDINAL}
        render={({ field: { value, onChange } }) => (
          <StyledRadioContainer>
            <Radio
              color="primary"
              checked={value === MonthIntervalConfiguration.ConfigurationOptions.CARDINAL}
              onChange={() => onChange(MonthIntervalConfiguration.ConfigurationOptions.CARDINAL)}
            />
            <Radio
              color="primary"
              checked={value === MonthIntervalConfiguration.ConfigurationOptions.ORDINAL}
              onChange={() => onChange(MonthIntervalConfiguration.ConfigurationOptions.ORDINAL)}
            />
          </StyledRadioContainer>
        )}
      />
      <StyledSelectContainer>
        <StyledSelectRow>
          <span>{intl.formatMessage({ id: 'reminders.on-day' })}</span>
          <Controller
            name="dayOfMonth"
            control={control}
            defaultValue={1}
            render={({ field: { value, onChange } }) => (
              <StyledSelect value={value} onChange={onChange} left="12px" variant="standard">
                {[...Array(31)].map((i, x) => (
                  <MenuItem key={x} value={x + 1}>
                    {x + 1}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
          />
        </StyledSelectRow>

        <StyledSelectRow>
          <span>{intl.formatMessage({ id: 'reminders.on-the' })}</span>
          <Controller
            name="ordinal"
            control={control}
            defaultValue={ordinals.FIRST}
            render={({ field: { value, onChange } }) => (
              <StyledSelect value={value} onChange={onChange} variant="standard">
                <MenuItem value={ordinals.FIRST}>{intl.formatMessage({ id: 'common.ordinal-first' })}</MenuItem>
                <MenuItem value={ordinals.SECOND}>{intl.formatMessage({ id: 'common.ordinal-second' })}</MenuItem>
                <MenuItem value={ordinals.THIRD}>{intl.formatMessage({ id: 'common.ordinal-third' })}</MenuItem>
                <MenuItem value={ordinals.FOURTH}>{intl.formatMessage({ id: 'common.ordinal-fourth' })}</MenuItem>
                <MenuItem value={ordinals.LAST}>{intl.formatMessage({ id: 'common.ordinal-last' })}</MenuItem>
              </StyledSelect>
            )}
          />
          <Controller
            name="day"
            control={control}
            defaultValue={weekdays.SUNDAY}
            render={({ field: { value, onChange } }) => (
              <StyledSelect value={value} onChange={onChange} variant="standard">
                <MenuItem value={weekdays.SUNDAY}>{intl.formatMessage({ id: 'common.day-sunday' })}</MenuItem>
                <MenuItem value={weekdays.MONDAY}>{intl.formatMessage({ id: 'common.day-monday' })}</MenuItem>
                <MenuItem value={weekdays.TUESDAY}>{intl.formatMessage({ id: 'common.day-tuesday' })}</MenuItem>
                <MenuItem value={weekdays.WEDNESDAY}>{intl.formatMessage({ id: 'common.day-wednesday' })}</MenuItem>
                <MenuItem value={weekdays.THURSDAY}>{intl.formatMessage({ id: 'common.day-thursday' })}</MenuItem>
                <MenuItem value={weekdays.FRIDAY}>{intl.formatMessage({ id: 'common.day-friday' })}</MenuItem>
                <MenuItem value={weekdays.SATURDAY}>{intl.formatMessage({ id: 'common.day-saturday' })}</MenuItem>
              </StyledSelect>
            )}
          />
        </StyledSelectRow>
      </StyledSelectContainer>
    </StyledContainer>
  );
}
MonthIntervalConfiguration.ConfigurationOptions = {
  CARDINAL: 'CARDINAL',
  ORDINAL: 'ORDINAL',
};
MonthIntervalConfiguration.propTypes = {
  control: PropTypes.object.isRequired,
};

export default MonthIntervalConfiguration;
