import { Actor } from '../../../Actor';
import { dogAssetConfig } from './DogConfig';

export class Dog extends Actor {
  constructor(scene, x, y, color, frame) {
    super(scene, x, y, dogAssetConfig, dogAssetConfig.NAME, frame);

    this.spriteName = this.ObjectData.NAME;
    this.Inventory = this.ObjectData.INVENTORY;
    this.Sounds = this.ObjectData.AUDIO;

    this.currentSpriteSheet = color;
    this.originalScale = this.ObjectData.SCALE;
  }
}
