import React from 'react';
import { StyledContainer, StyledParagraphWithPadding } from 'Core_Pages/Pets/featureStyles';
import PropTypes from 'prop-types';
import PageHeader from 'Core_Components/PageHeader/PageHeader';

function PetsHome({ pet }) {
  return (
    <StyledContainer>
      <PageHeader>Pets Home</PageHeader>
      <StyledParagraphWithPadding>You already have a pet</StyledParagraphWithPadding>
      <StyledParagraphWithPadding>{`Pet Type: ${pet.petType}`}</StyledParagraphWithPadding>
      <StyledParagraphWithPadding>{`Pet Name: ${pet.name}`}</StyledParagraphWithPadding>
    </StyledContainer>
  );
}

PetsHome.propTypes = {
  pet: PropTypes.object,
};

export default PetsHome;
