import styled from 'styled-components';
import { Card } from '@mui/material';
import { Close } from '@mui/icons-material';

export const InstallPromptContainer = styled(Card)`
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 100%;
  z-index: 1;
  background-color: white;

  &.toast {
    width: 100%;
    max-width: none;
  }
`;

export const CardHeaderContainer = styled.div`
  font-weight: bold;
  color: black;
  font-size: 16px;
  border-bottom: none;
  margin-right: auto;
  margin-left: 15px;
  margin-bottom: 12px;
  @media screen and (max-width: 370px) {
    font-size: 14px;
  }
`;

export const CardBodyContent = styled.div`
  color: gray;
  font-size: 16px;
`;

export const CardBodyContainer = styled.div`
  background-color: white;
`;

export const InstructionLine = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
`;

export const IconContainer = styled.div`
  width: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 0 0 auto;
`;

export const IosIcon = styled.img`
  height: 2rem;
`;

export const StyledHeaderTextIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseIcon = styled(Close)`
  color: grey;
  width: 18px !important;
`;

export const AndroidCardBodyContainer = styled(CardBodyContainer)`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;
