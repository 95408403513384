import { bullies } from 'Assets/img/my-tools-icons';

export const bullyingEs = {
  key: 'bullying-es',
  title: 'Qué hacer contra los acosadores',
  titleImage: bullies,
  titleImageAlt: '',
  contentId: 18,
  sections: [
    {
      key: 'bullying-intro-es',
      hasNoSectionMargin: true,
      description:
        'Nadie merece ser acosado. Todo el mundo tiene derecho a vivir en un entorno seguro en casa, en la escuela y en el trabajo. No siempre es fácil diferenciar entre el acoso y el mal humor momentáneo. Sin embargo, los acosadores suelen molestar a las personas una y otra vez, y eso no puede (ni debe) tolerarse.\n\nTener que lidiar con un acosador es un desafío. Es importante recuerde el acoso no se trata de ti. Las personas que intimidan son infelices en sus propias vidas. Menospreciar a los demás les da una sensación de poder y control porque quieren que sus víctimas se sientan tan mal como ellos por dentro. Y, así, reciben atención.\n\nLos acosadores suelen molestar a las personas que consideran más débiles y les encanta obtener una reacción de ellos. Harán cosas para presionar los botones y hacer que otros se enojen o se disgusten. El acoso se manifiesta de diferentes maneras y puede ser cometido por cualquiera. Cuando su víctima reacciona como quiere, siente que es el "ganador".',
      bulletedItems: [
        {
          text: '<b>Verbalmente</b>: Burlas, provocaciones, insultos, etc.  ',
        },
        {
          text: '<b>Físicamente</b>: Cualquier forma de tocar (empujones, golpes, agarrones) a una persona que no quiera puede considerarse acoso, además de agresión sexual.',
        },
        {
          text: '<b>Psicológicamente</b>: inventar o contar chismes sobre la gente o ignorarlos para que se sientan mal.',
        },
        {
          text: '<b>Ciberacoso</b> - Envío de mensajes de texto agresivos, publicar insultos sobre otra persona en sus redes, etc.',
        },
      ],
    },
    {
      key: 'bullying-what-to-do-es',
      header: 'Qué hacer',
      description:
        'Al igual que con otras personas, no podemos controlar el comportamiento de un acosador, pero podemos aprender cómo responder a él. Estas son algunas estrategias que puedes tener en cuenta sobre el acoso y ayudar a mantener fuerte tu autoestima y confianza.\n\n• Evitar a los acosadores\n\nLos acosadores actúan allá donde los adultos o las figuras de autoridad no estén cerca. Así que evita las zonas no supervisadas como pasillos, baños, ciertas taquillas en la escuela y lugares donde los matones pasan el rato.\n\nSi recibes mensajes de tus acosadores, apaga inmediatamente su dispositivo. Cuando vuelvas a encenderlo, bloquea a quien te esté acosando.\n\n• Viajar en grupos\n\nLos acosadores suelen molestar a los demás cuando están solos. Intenta estar cerca de amigos, compañeros u otras personas para no ser un objetivo. Un simple "vámonos" puede indicarles a tus amigos que quieres abandonar la situación.\n\n• Ignorar y alejarse\n\nIgnorar los insultos y los comentarios groseros de un acosador no es fácil, especialmente si son molestos o si otras personas quieren que reacciones. Pero nadie te obliga a responder a alguien que te acosa. Pelear contra ellos genera satisfacción en los agresores porque les muestra que pueden llegar a ti. Simplemente ignorar a un agresor demuestra que eres fuerte, que tienes el control de ti mismo y les quita cualquier poder falso sobre ti.\n\nRenunciar a "engancharte" y no mostrar ninguna reacción frente al agresor, y no devolverle el acoso. Respirar hondo, fingir que no los escuchas continuar con lo que estabas haciendo. También puedes mirar o empezar a hablar con otra persona como si nada hubiera pasado. Alejarte de la situación con confianza, de pie, con la barbilla levantada y dirigirte a un lugar seguro, como un aula, una oficina o un negocio, donde haya otras personas presentes.',
    },
    {
      key: 'bullying-standing-up-for-yourself-es',
      header: 'Defenderte a ti mismo',
      description:
        'Recuerda que no tienes que enfrentarse a un acosador, especialmente si no te sientes seguro. Pero si te sientes preparado, hacerles frente les quita su poder e impide que te vean como un objetivo.\n\nPuede que tengas miedo, pero puedes reunir tu valor y mostrarte confiado a través de su lenguaje corporal. Mantente en pie y se valiente, o finge serlo incluso si no te sientes así. Mantén la espalda recta y la cabeza alta sin moverte. Enfréntalos y míralos directamente a los ojos con confianza. Considera practicar primero en casa para que puedas sentirte más cómodo cuando llegue el momento.',
    },
    {
      key: 'bullying-what-to-say-es',
      header: 'Qué decir',
      description:
        'Decir "no" con firmeza muestra que no le temes al acosador y que no aceptas sus abusos. Intenta hablar poco y evita enojarte o ser sarcástico. Puede ayudar hablar alto y firme para demostrar que lo dices en serio. Algunas ideas son ...',
      bulletedItems: [
        {
          text: '"Es suficiente. No vuelvas a hablarme así"',
        },
        {
          text: '"¿Por qué te estás metiendo conmigo? ¡Para!”',
        },
        {
          text: '"¿Qué quieres? No quiero pelear contigo"',
        },
        {
          text: '"No me gusta que me llamen así. Quiero que me llames por mi nombre"',
        },
        {
          text: '"No me toques"',
        },
      ],
    },
    {
      key: 'bullying-stop-es',
      hasNoSectionMargin: true,
      description:
        'Los acosadores al final se dan por vencidos y pasan a otra persona. Pero si no parecen escuchar y el acoso no se detiene, debes alejarte. Puede que sea el momento de pedir ayuda.',
    },
    {
      key: 'bullying-reach-out-es',
      header: 'Comunicarse',
      description:
        'Si continúas sufriendo acoso y te sientes ansioso, estresado, deprimido, triste o temeroso, busca ayuda adicional.\n\n• Si el agresor es un adulto que te amenaza o hace daño, eso es abuso. Habla con alguien sobre ello inmediatamente\n\n• NOTA: Recuerda que puedes hablar con tu miembro del personal de apoyo (ANDY) de Pyx Health. Puede brindarte una comprensión compasiva de tu experiencia y ayudarte a encontrar los recursos que tu plan de salud tiene disponibles. Utiliza el botón «Llamar a ANDY» en tu aplicación de Pyx Health o llámalo al 1-855-499-4777.',
    },
    {
      key: 'bullying-consider-perspective-es',
      header: 'Considerar una nueva perspectiva',
      description:
        'A la larga, un solo matón malvado no merece tu tiempo o energía mental. Una vez que puedas ver la situación desde esa perspectiva, puede que sea más fácil mantener el enfoque en tu propio bienestar. Si bien no debes cambiar solo por el acoso, puedes aprovechar esta oportunidad para hacer cambios positivos en tu vida y crecer como persona. A tu manera ...',
      bulletedItems: [
        {
          text: 'Reconoce lo que sucedió y se sincero sobre cualquier dolor que hayas sentido.',
        },
        {
          text: 'Enfócate en lo que has aprendido y en las cosas que puedes controlar en tu vida.',
        },
        {
          text: 'Encuentra la manera de cerrar la situación y déjala pasar para tener más espacio para lo que depara el futuro.',
        },
        {
          text: 'Practica los autocuidados - Trátate amablemente y tómate un tiempo extra para cuidarte, relajarte y hacer cosas que disfrutes. Escuchar música, practicar tus hobbies, pasar el rato con amigos, meditar, hacer ejercicio o hacer algún tipo de movimiento puede ayudar a recuperar y fortalecer la autoestima.',
        },
      ],
    },
    {
      key: 'bullying-you-got-this-es',
      header: '¡Puedes con esto!',
      description:
        'Recuerda, no hay que avergonzarse por estar asustado de un acosador, alejarse o decírselo a un adulto o figura de autoridad y pedir ayuda. Es mejor mantenerse a salvo y encontrar la ayuda más cercana.',
    },
  ],
};
