import { createElement } from 'react';
import JoinedSessionMessage from '../Components/Messages/JoinedSessionMessage';
import ReceivedMessage from '../Components/Messages/ReceivedMessage';
import SentMessage from '../Components/Messages/SentMessage';
import SessionEndMessage from '../Components/Messages/SessionEndMessage';

export enum MessageType {
  RECEIVED,
  SENT,
  JOINED,
  SESSIONEND,
}

const MessageMap = {
  [MessageType.RECEIVED]: ReceivedMessage,
  [MessageType.SENT]: SentMessage,
  [MessageType.SESSIONEND]: SessionEndMessage,
  [MessageType.JOINED]: JoinedSessionMessage,
};
export class DirectMessage {
  constructor(messageType: MessageType, text: string, from: string, isHuntGroup: boolean) {
    this.messageType = messageType;
    this.text = text;
    this.timestamp = this.CalculateTimeStamp();
    this.from = from;
    this.isHuntGroup = isHuntGroup;
    this.Component = createElement(MessageMap[this.messageType], { message: this });
  }

  private CalculateTimeStamp() {
    const now = new Date();
    const minutes = now.getMinutes();
    const hoursDisplay = now.getHours() % 12 || 12;
    const minutesDisplay = minutes >= 10 ? `${minutes}` : `0${minutes}`;
    const am_pm = now.getHours() >= 12 ? 'PM' : 'AM';

    return `[${hoursDisplay}:${minutesDisplay} ${am_pm}]`;
  }

  messageType: MessageType;
  text: string;
  timestamp: string;
  from: string;
  isHuntGroup: boolean;
  Component;
}
