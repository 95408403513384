import React from 'react';
import { ActionHeader, StyledCard, StyledIcon, ActionLabel } from '../styles';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { toolCardItemConfig, itemTypes } from './toolCardConfig';

function ToolCard({ type, labelId, icon, imageStyle, onClick }) {
  const intl = useIntl();

  const itemType = type ?? itemTypes.DO;
  const config = toolCardItemConfig[itemType];
  const { backgroundColor, titleId } = config;

  return (
    <Button onClick={onClick} fullWidth sx={{ padding: '0px' }}>
      <StyledCard elevation={3}>
        <ActionHeader style={{ backgroundColor: backgroundColor }}>{intl.formatMessage({ id: titleId })}</ActionHeader>
        <ActionLabel fontSize={16} fontWeight={600}>
          {intl.formatMessage({ id: labelId })}
        </ActionLabel>
        <StyledIcon src={icon} style={imageStyle} alt="" />
      </StyledCard>
    </Button>
  );
}

ToolCard.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.string,
  labelId: PropTypes.string,
  onClick: PropTypes.func,
  imageStyle: PropTypes.object,
};

export default ToolCard;
