import styled from 'styled-components';
import { Dialog, TextField, DialogActions, Button } from '@mui/material';
import { legacyBackgroundColors } from 'Styles/colors';

export const SectionContainer = styled.button`
  display: flex;
  color: #fff;
  margin: 5px 0;
  background-color: ${(props) => (props.open ? '#FFF' : `${legacyBackgroundColors.indigo}`)};
  border: none;
  width: 100%;
  align-items: center;

  &:focus > span:nth-child(2) {
    text-decoration: underline;
  }
  &:hover > span:nth-child(2) {
    text-decoration: underline;
  }
`;

export const Icon = styled.i`
  font-size: 1.5625rem;
  color: ${(props) => (props.open ? `${legacyBackgroundColors.indigo}` : '#FFF')};
`;

export const ChevronIcon = styled(Icon)`
  color: ${(props) => (props.open ? '$3F437C' : '#FFF')};
  margin: 12px 25px 12px auto;
`;

export const Label = styled.span`
  display: flex;
  color: ${(props) => (props.open ? `${legacyBackgroundColors.indigo}` : '#FFF')};
  text-align: left;
  font-weight: bold;
  font-size: 1.125rem;
  margin-left: ${(props) => (props.icon == null ? '-30px' : '22px')};
  @media screen and (max-width: 350px) {
    ${(props) => (props.icon == null ? '-10px' : '0')};
  }
`;

export const IconContainer = styled.span`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  width: 74px;
`;

export const Items = styled.div`
  overflow: hidden;
  transition: max-height 0.1s ease-in-out;
  height: auto;
  max-height: ${(props) => (props.hidden ? '0' : '100rem')};
`;

export const StyledDialog = styled(Dialog)`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiDialog-container {
    width: 100%;
  }

  .MuiPaper-root {
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledDateTimePicker = styled(TextField)`
  margin: 8px;
`;

export const StyledButtonContainer = styled(DialogActions)`
  padding-right: 12px;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  height: 44px;
  line-height: 12px;
`;
