import YouthPHQ2 from '../ScreeningImplementations/YouthPHQ2/Results/YouthPHQ2';
import YouthUCLA3 from '../ScreeningImplementations/YouthUCLA3/Results/YouthUCLA3';
import YouthNeeds from '../ScreeningImplementations/YouthNeeds/Results/YouthNeeds';
import AdultUCLA3 from '../ScreeningImplementations/AdultUCLA3/Results/AdultUCLA3';
import AdultPHQ4 from '../ScreeningImplementations/AdultPHQ4/Results/AdultPHQ4';
import AdultNeeds from '../ScreeningImplementations/AdultNeeds/Results/AdultNeeds';
import AdultPrapareSdoh from '../ScreeningImplementations/AdultPrapareSdoh/Results/AdultPrapareSdoh';
import AdultWellBeingAssessment from '../ScreeningImplementations/AdultWellBeing/Results/AdultWellBeing';
import { Screenings } from '../enumerations/enums';

export const Forms = {
  [Screenings.YOUTH_UCLA3]: YouthUCLA3,
  [Screenings.YOUTH_PHQ2]: YouthPHQ2,
  [Screenings.YOUTH_NEEDS]: YouthNeeds,
  [Screenings.ADULT_UCLA3]: AdultUCLA3,
  [Screenings.ADULT_PHQ4]: AdultPHQ4,
  [Screenings.ADULT_NEEDS]: AdultNeeds,
  [Screenings.ADULT_PRAPARE_SDOH]: AdultPrapareSdoh,
  [Screenings.ADULT_WELLBEING]: AdultWellBeingAssessment,
};
