import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { breakpoints } from 'Styles/theme';

export const StyledVideoGrid = styled(Grid)(
  ({ theme }) => `
  height: 100%;
  padding-bottom: 20px;

  margin-top: inherit;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  ${theme.breakpoints.up(breakpoints.DESKTOP)} { 
    padding: 0px;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-self: center;
  }
`,
);
