import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useElementResize = (element) => {
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);

  const resizeObserver = new ResizeObserver((entity) => {
    setScrollHeight(entity[0].target.scrollHeight);
    setClientHeight(entity[0].target.clientHeight);
    setClientWidth(entity[0].target.clientWidth);
  });

  useEffect(() => {
    if (element) {
      resizeObserver.observe(element.current);
    }
    return () => {
      if (element && element.current) {
        resizeObserver.unobserve(element.current);
      }
    };
  }, []);

  return { scrollHeight, clientHeight, clientWidth };
};

export default useElementResize;
