import { Typography } from '@mui/material';
import Question from 'Stories/components/Form/Question';
import React from 'react';
import { useForm } from 'react-hook-form';
import { questions } from './config';
import { useIntl } from 'react-intl';
import { AnswerTypes } from 'Stories/components/Form/answerTypes';
import { ScreeningModuleStore } from 'Core_Pages/Screening/ScreeningModuleStore';
import {
  LikertContinueButton,
  LikertForm,
  LikertWrapper,
  Bookmark,
} from 'Core_Pages/Screening/ScreeningImplementations/styles';
import ErrorModal from 'Core_Components/ErrorModal/ErrorModal';

function AdultUCLA3() {
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const screeningTakerStore = screeningStore.ScreeningForm.Use();
  const intl = useIntl();
  const { control, handleSubmit } = useForm();

  const onAnswer = (question, answer) => screeningTakerStore.updateAnswer(question, answer);
  const onComplete = () => screeningTakerStore.complete();
  const onCloseErrorModal = () => screeningStore.acknowledgeError();

  const formComplete = screeningTakerStore.IsValid;
  const isLoading = screeningTakerStore.IsLoading || screeningStore.IsLoading;
  const hasError = screeningStore.ScreeningHasError;
  const numberOfScreeningsLeft = screeningStore.DueScreenings?.length > 0 ? screeningStore.DueScreenings?.length : 1;
  const currentScreeningIndex = screeningStore.DueScreeningsIndex + 1;

  return (
    <>
      <LikertWrapper fullScreen open={true} aria-labelledby="screening-title" className="safe-area">
        <Bookmark>
          {`${currentScreeningIndex} ${intl.formatMessage({ id: 'common.of' })} ${numberOfScreeningsLeft}`}
        </Bookmark>
        <Typography id="screening-title" component="h1" fontSize={18} fontWeight={700} paddingBottom={'20px'}>
          {intl.formatMessage({ id: 'screenings.form.likert.youth-ucla3.header' })}
        </Typography>
        <LikertForm onSubmit={handleSubmit(onComplete)}>
          {questions.map(({ textId: questionTextId, options, ...question }, index) => {
            return (
              <Question
                key={index}
                text={intl.formatMessage({ id: questionTextId })}
                options={options}
                control={control}
                answerType={AnswerTypes.CARD}
                onChange={(e) => onAnswer(question?.name, e?.currentTarget?.name)}
                {...question}
              />
            );
          })}
        </LikertForm>
        <LikertContinueButton loading={isLoading} fullWidth onClick={onComplete} type="submit" disabled={!formComplete}>
          {intl.formatMessage({ id: 'common.continue' })}
        </LikertContinueButton>
      </LikertWrapper>
      <ErrorModal open={hasError} onClose={onCloseErrorModal} />
    </>
  );
}

export default AdultUCLA3;
