import styled from '@emotion/styled';
import { legacyBackgroundColors } from 'Styles/colors';

export const StyledIndicatorContainer = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  background: ${(props) => (props.isActive ? `${legacyBackgroundColors.indigo}` : '#adadad')};
  span {
    color: white;
    font-size: 20px;
    font-weight: bold;
  }
`;
