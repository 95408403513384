import styled from 'styled-components';
import { purple } from 'Styles/colors';

export const StyledGridContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 320px) {
    padding: 0 2px;
  }
`;

export const StyledNavButton = styled.button`
  background: none;
  color: ${purple._900};

  :disabled {
    color: #b6b8b8;
  }
`;

export const StyledNavIcon = styled.i`
  font-size: 2rem;
`;
