import React from 'react';
import { useIntl } from 'react-intl';
import { ArrowBackIos } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import { completeNameEntry, selectSignupMetadata, selectName, navigateBack } from 'Onboard_Redux/signup';
import Button from 'Stories/components/Button';
import { Header, StyledTextInput } from 'Onboard_Components/Account/styles';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';

export function Name() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const { loading, progress } = useOnboardSelector(selectSignupMetadata);
  const { firstName, lastName } = useOnboardSelector(selectName);
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm();

  function onContinue(data) {
    dispatch(completeNameEntry(data));
  }

  function onBack() {
    dispatch(navigateBack());
  }

  const formComplete = (dirtyFields.firstName && dirtyFields.lastName) || (firstName && lastName);

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <Header>
        <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />
        {intl.formatMessage({ id: 'account.sign-up.create-account' })}
      </Header>
      <form onSubmit={handleSubmit(onContinue)}>
        <Controller
          name="firstName"
          control={control}
          defaultValue={firstName}
          rules={{ required: intl.formatMessage({ id: 'error.blank-field' }) }}
          render={({ field }) => (
            <StyledTextInput
              {...field}
              id="firstNameInput"
              label={intl.formatMessage({ id: 'account.sign-up.first-name' })}
              errorMessage={errors.firstName?.message}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          defaultValue={lastName}
          rules={{ required: intl.formatMessage({ id: 'error.blank-field' }) }}
          render={({ field }) => (
            <StyledTextInput
              {...field}
              id="lastNameInput"
              label={intl.formatMessage({ id: 'account.sign-up.last-name' })}
              errorMessage={errors.lastName?.message}
            />
          )}
        />
        <Button id="continue" fullWidth loading={loading} disabled={!formComplete} type="submit">
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
    </>
  );
}

export default Name;
