import styled from '@emotion/styled';

const StyledTruncatedText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledTitle = styled(StyledTruncatedText)`
  font-size: 18px;
`;

export const StyledSubtitle = styled(StyledTruncatedText)`
  font-size: 14px;
`;
