import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { REMINDERS_CREATE } from 'Core_Pages/Routes/RoutesConfig';
import AddRemindersIcon from '../components/RemindersIcons/AddRemindersIcon';
import ReminderCard from '../components/ReminderCard/ReminderCard';
import NoRemindersCard from '../components/NoRemindersCard/NoRemindersCard';
import { StyledRemindersContentContainer, StyledRemindersPageContainer, StyledSpinnerContainer } from '../styles';
import GreetingPyxir from 'Assets/img/greeting-pyxir.svg';
import { legacyBackgroundColors } from 'Styles/colors';
import CharacterGreetingHeader from 'Core_Components/CharacterGreetingHeader/CharacterGreetingHeader';
import HiFriendMessageEn from 'Assets/img/character-header/pyxir-greeting-2x.en.png';
import HiFriendMessageEs from 'Assets/img/character-header/pyxir-greeting-2x.es.png';
import { useIntl } from 'react-intl';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { fetchReminders, selectAreRemindersLoading, selectReminders } from 'Core_Redux/reminders';
import DeleteReminderSuccessModal from '../components/Modals/DeleteReminderSuccessModal';
import ConfirmReminderDeleteModal from '../components/Modals/ConfirmReminderDeleteModal';
import ConfirmReminderDeactivationModal from '../components/Modals/ConfirmReminderDeactivationModal';
import DeactivateReminderSuccessModal from '../components/Modals/DeactivateReminderSuccessModal';
import SetReminderSuccessModal from '../components/Modals/SetReminderSuccessModal';
import Spinner from 'Core_Components/Spinner/Spinner';

function RemindersHome() {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useCoreDispatch();
  const reminders = useCoreSelector(selectReminders);
  const areRemindersLoading = useCoreSelector(selectAreRemindersLoading);

  useEffect(() => {
    dispatch(fetchReminders());
  }, []);

  const handleAddReminderClicked = () => {
    history.push(REMINDERS_CREATE);
  };

  return (
    <StyledRemindersPageContainer>
      <CharacterGreetingHeader
        isPyxirHeader={true}
        message={intl.formatMessage({ id: 'reminders.current' })}
        imageSrc={GreetingPyxir}
        imageAlt={intl.formatMessage({ id: 'greeting.pyxir' })}
        imageAlignment="flex-start"
        backgroundColor={legacyBackgroundColors.indigo}
        greeting={{
          src: intl.formatMessage({ id: 'hi.friend' }).includes('en.svg') ? HiFriendMessageEn : HiFriendMessageEs,
          alt: intl.formatMessage({ id: 'image.pyxir.hifriend' }),
        }}
      />
      {areRemindersLoading ? (
        <StyledSpinnerContainer>
          <Spinner />
        </StyledSpinnerContainer>
      ) : (
        <StyledRemindersContentContainer>
          {reminders?.length ? (
            reminders.map((r) => <ReminderCard key={r.guid} data={r} />)
          ) : (
            <NoRemindersCard handleButtonClick={handleAddReminderClicked} />
          )}
          <AddRemindersIcon onClick={handleAddReminderClicked} />
        </StyledRemindersContentContainer>
      )}
      <DeleteReminderSuccessModal />
      <ConfirmReminderDeactivationModal />
      <ConfirmReminderDeleteModal />
      <DeactivateReminderSuccessModal />
      <SetReminderSuccessModal />
    </StyledRemindersPageContainer>
  );
}

export default RemindersHome;
