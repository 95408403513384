import styled from '@emotion/styled';

export const StyledYesNoQuestion = styled.div`
  .FormQuestion-root {
    height: 425px;
    width: 285px;
    background-color: rgba(255, 255, 255, 0.8);
    display: grid;
    grid-template-rows: min-content auto;
    border-radius: 4px;
    margin-bottom: 24px;
    padding: 24px;
  }

  .FormQuestion-question {
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    margin-bottom: 24px;
  }

  .MuiFormControlLabel-root {
    margin: 0;
    padding: 0;
    width: 100%;
    justify-content: flex-start;
  }

  .MuiFormControlLabel-label {
    font-size: 18px;
    font-weight: 700;
  }

  .MuiRadio-root {
    color: white;
    padding: 6px;
    margin: 0;
  }

  .MuiRadio-root.Mui-checked {
    color: #29265e;
  }

  .MuiSvgIcon-root {
    border-radius: 50%;
    background-color: white;
    border: 1px solid black;
    path: {
      transform: translate(-2px, -2px) scale(1.2);
    }
  }
`;
