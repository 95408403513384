export const questions = [
  {
    name: '92381357-5c83-4c49-abb8-86802e1dffa8',
    textId: 'screenings.form.likert.youth-ucla3.question-1',
    options: [
      {
        name: '22e1fdf7-3c52-45a7-a5ed-3526eb3a36a6',
        textId: 'screenings.form.likert.youth-ucla3.answer-hardly',
      },
      {
        name: 'ed55a88f-ded6-4024-8c54-f402ff39ed93',
        textId: 'screenings.form.likert.youth-ucla3.answer-sometimes',
      },
      {
        name: 'c7d1b100-1414-4237-a366-2830ba166f48',
        textId: 'screenings.form.likert.youth-ucla3.answer-often',
      },
    ],
  },
  {
    name: '12fbcb14-bdc2-4a8f-abb3-d6e97b532cd8',
    textId: 'screenings.form.likert.youth-ucla3.question-2',
    options: [
      {
        name: 'b5844d0c-6aab-4a72-9f32-8d2204f720bb',
        textId: 'screenings.form.likert.youth-ucla3.answer-hardly',
      },
      {
        name: '1d1240f7-a5df-4d28-b4d7-d36fcb314024',
        textId: 'screenings.form.likert.youth-ucla3.answer-sometimes',
      },
      {
        name: '37ac84e4-d790-4609-9945-8301b27cbb88',
        textId: 'screenings.form.likert.youth-ucla3.answer-often',
      },
    ],
  },
  {
    name: '65ec7994-399c-4519-853e-5c3eb5266bf0',
    textId: 'screenings.form.likert.youth-ucla3.question-3',
    options: [
      {
        name: '772efa89-d811-46b9-a429-a508915dc4b3',
        textId: 'screenings.form.likert.youth-ucla3.answer-hardly',
      },
      {
        name: 'eb66988b-dd04-4903-95df-2d8cfb25eea4',
        textId: 'screenings.form.likert.youth-ucla3.answer-sometimes',
      },
      {
        name: '40699625-ac2f-4a3e-92bd-c56aba5cce50',
        textId: 'screenings.form.likert.youth-ucla3.answer-often',
      },
    ],
  },
];
