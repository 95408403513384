import styled from 'styled-components';

export const ButtonRowContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
`;

export const StyledButtonContainer = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledChatButton = styled.button`
  user-select: none;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4a4f7f;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 0.9375rem;
  letter-spacing: 0.03rem;
  line-height: 1.1875rem;
  min-height: 42px;
  width: 100%;
  max-width: 320px;
  min-width: 135px;
  margin: 0 15px;
  text-align: center;

  @media screen and (max-width: 350px) {
    margin: 0 5px;
  }
`;
