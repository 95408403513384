import { createDispatchHook, createSelectorHook } from 'react-redux';
import { createContext } from 'react';

export const coreContext = createContext();
export const onboardContext = createContext();

//consider moving these into separate locations
export const useCoreDispatch = createDispatchHook(coreContext);
export const useCoreSelector = createSelectorHook(coreContext);

export const useOnboardDispatch = createDispatchHook(onboardContext);
export const useOnboardSelector = createSelectorHook(onboardContext);
