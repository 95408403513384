import {
  buildingConfidence,
  buildingSupportSystem,
  caregiverBurnout,
  caringForYourself,
  childActingOut,
  fosterTips,
  griefLossTransitions,
  knowingWhenYouNeedHelp,
  learningEmpathy,
  relaxationActivities,
  rephrasingThoughts,
  supportingMentalHealthNeeds,
} from 'Assets/img/my-tools-icons';
import urlcat from 'urlcat';
import { itemTypes } from '../components/ToolCard/toolCardConfig';
import { STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';

export const guardianItems = [
  {
    icon: buildingSupportSystem,
    labelId: 'my-tools.buildingsupportsystem',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'buildingsupportsystemguardian' }),
    loggedEvent: 'Building_Support_System_Guardian_Content_Clicked',
  },
  {
    icon: buildingConfidence,
    labelId: 'my-tools.buildingconfidence',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'buildingconfidenceguardian' }),
    loggedEvent: 'Building_Confidence_Guardian_Content_Clicked',
  },
  {
    icon: caregiverBurnout,
    labelId: 'my-tools.caregiverburnout',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'caregiverburnoutguardian' }),
    loggedEvent: 'Caregiver_Burnout_Guardian_Content_Clicked',
  },
  {
    icon: caringForYourself,
    labelId: 'my-tools.caringforyourself',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'caringforyourselfguardian' }),
    loggedEvent: 'Caring_For_Yourself_Guardian_Content_Clicked',
  },
  {
    icon: griefLossTransitions,
    labelId: 'my-tools.griefandloss',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'griefandlosstransition' }),
    loggedEvent: 'Grief_And_Loss_Guardian_Content_Clicked',
  },
  {
    icon: fosterTips,
    labelId: 'my-tools.fosterparentingtips',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'fosterparentingtips' }),
    loggedEvent: 'Foster_Parenting_Tips_Content_Clicked',
  },
  {
    icon: knowingWhenYouNeedHelp,
    labelId: 'my-tools.knowingwhenyouneedhelp',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'knowingwhenyouneedhelpguardian' }),
    loggedEvent: 'Knowing_When_You_Need_Help_Guardian_Content_Clicked',
  },
  {
    icon: learningEmpathy,
    labelId: 'my-tools.learningempathy',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'learningempathy' }),
    loggedEvent: 'Learning_Empathy_Content_Clicked',
  },
  {
    icon: childActingOut,
    labelId: 'my-tools.childisactingout',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'childisactingout' }),
    loggedEvent: 'My_Child_Is_Acting_Out_Content_Clicked',
  },
  {
    icon: relaxationActivities,
    labelId: 'my-tools.relaxationactivities',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'relaxationactivitiesguardian' }),
    loggedEvent: 'Relaxation_Activities_Guardian_Content_Clicked',
  },
  {
    icon: rephrasingThoughts,
    labelId: 'my-tools.rephrasingthoughts',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'rephrasingthoughtsguardian' }),
    loggedEvent: 'Rephrasing_Thoughts_Guardian_Content_Clicked',
  },
  {
    icon: supportingMentalHealthNeeds,
    labelId: 'my-tools.supportingmychildwithtrauma',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'supportingmychildwithtrauma' }),
    loggedEvent: 'Supporting_My_Child_With_Trauma_Content_Clicked',
  },
];
