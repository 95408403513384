import styled from 'styled-components';
import { legacyFontColors } from 'Styles/colors';

export const StyledTextBox = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  width: 90%;
  margin: 0 auto;
  border-radius: 7px;
  height: fit-content;
`;

export const StyledTextContainer = styled.div`
  text-align: center;
  border: 1px solid transparent;
  margin-top: 20px;
  white-space: pre-wrap;
  padding: 0 3%;

  @media screen and (max-width: 350px) {
    margin-top: 10px;
    font-size: 0.85rem;
  }
`;

export const StyledHeader = styled.h2`
  color: ${legacyFontColors.indigo};
  font-weight: 700;

  @media screen and (max-width: 350px) {
    margin-top: 10px;
    font-size: 1.1rem;
  }
`;

export const StyledParagraph = styled.p``;
