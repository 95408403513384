import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { AnswerTypes, answerMap } from '../answerTypes';

const Answer = ({ questionName, control, answerType, controllerProps, ...props }) => {
  const labeledBy = `${questionName}-question`;
  return (
    <Controller
      name={questionName}
      control={control}
      {...controllerProps}
      render={(field) => {
        const Component = answerMap[answerType];
        return (
          <Component
            {...field}
            id={`${questionName}-answer`}
            aria-labelledby={labeledBy}
            className="FormQuestion-answer"
            {...props}
          />
        );
      }}
    />
  );
};

Answer.displayName = 'Answer';

Answer.propTypes = {
  answerType: PropTypes.oneOf(Object.values(AnswerTypes)),
  controllerProps: PropTypes.object,
  control: PropTypes.object.isRequired,
  questionName: PropTypes.string,
};

export default Answer;
