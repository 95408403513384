import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { StyledGrid } from '../styles';
import { Button } from '@mui/material';
import GradientCard from 'Core_Components/GradientCard/GradientCard';
import { appInsights } from 'Core_Helpers/AppInsights';
import { selectUserInfo, selectUserMeta } from 'Core_Redux/user';
import { useCoreSelector } from 'Contexts/StoreContext';
import hasPermissions from 'Core_Helpers/authentication/hasPermissions';
import filterUserMetaByTag from 'Core_Pages/MyTools/userMetaHelpers';
import urlcat from 'urlcat';
import { ToolTypes } from 'Core_Pages/MyTools/enumerations/enums';
import { MYTOOLS } from 'Core_Pages/Routes/RoutesConfig';
import { positivityMindset } from 'Assets/img/my-tools-icons';
import { mindsetItems } from 'Core_Pages/MyTools/MyToolsPage/MyMindsetTools/config';

function MyMindsetTile() {
  const history = useHistory();
  const intl = useIntl();
  const profile = useCoreSelector(selectUserInfo);
  const userMeta = useCoreSelector(selectUserMeta);

  function onToolClick() {
    appInsights.trackEvent({ name: 'MyTools_Mindset' });
    history.push(urlcat(MYTOOLS, { toolType: 'mindset' }));
  }

  const getLessonTotal = () => {
    return mindsetItems.filter(
      (x) => hasPermissions(x.permissions, x.roles, profile) && filterUserMetaByTag(x.contentCategory, userMeta),
    )?.length;
  };

  return (
    <StyledGrid mobile={12} tablet={6}>
      <Button onClick={onToolClick} fullWidth sx={{ padding: '0px' }}>
        <GradientCard
          titleId="home.tools.mindset"
          backgroundColor="#FFFABE"
          icon={positivityMindset}
          to={urlcat(MYTOOLS, { toolType: 'mindset' })}
          toolType={ToolTypes.MINDSET}
          imageStyle={{ margin: '0px 8px' }}
          label={`${getLessonTotal()} ${intl.formatMessage({ id: 'home.tools.label' })}`}
        />
      </Button>
    </StyledGrid>
  );
}

export default MyMindsetTile;
