import React, { useState, useEffect, Fragment } from 'react';
import SectionItem from '../SectionItem/SectionItem';
import PropTypes from 'prop-types';
import {
  SectionContainer,
  Icon,
  ChevronIcon,
  Label,
  Items,
  IconContainer,
  StyledDialog,
  StyledDateTimePicker,
  StyledButtonContainer,
  StyledButton,
} from './styles';
import { appInsights } from 'Core_Helpers/AppInsights';
import { useIntl } from 'react-intl';
import { isEnvironment, PRODUCTION } from 'Core_Helpers/isEnvironment';
import useAxiosPutSubmit from 'Core_Hooks/api/useAxiosPutSubmit/useAxiosPutSubmit';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectUserInfo } from 'Core_Redux/user';
import StorageService, { DATETIME_OVERRIDE_KEY } from 'Core_Helpers/authentication/StorageService';

const Section = ({ items = [], sectionName, expanded, icon, isExpandable, index, activeTab, activateTab }) => {
  const intl = useIntl();
  const [open, setOpen] = useState(expanded || !isExpandable);
  const [isTimeWarpDialogOpen, setIsTimeWarpDialogOpen] = useState(false);
  const [dateTimeValue, setDateTimeValue] = useState(new Date().toISOString().split('.')[0]);
  const [data, setData] = useState();
  const user = useCoreSelector(selectUserInfo);
  const updateThriveProgressUrl = `/v1/thrive/progress/${user?.sub}/update`;
  const { response } = useAxiosPutSubmit(`${process.env.API_BASE_URL}${updateThriveProgressUrl}`, data);

  useEffect(() => {
    if (activeTab !== index) {
      setOpen(false);
    }
  }, [activeTab]);

  const handleClick = () => {
    if (isExpandable) {
      if (!open) {
        appInsights.trackEvent({ name: `Expanded_${sectionName}` });
      }
      activateTab(index);
      setOpen(!open);
    }
  };

  const healthToolsValue = intl.formatMessage({ id: 'menu.health-tools' });

  const renderCalendar = (index, menuItemId) => {
    if (!isEnvironment(PRODUCTION)) {
      return (
        <SectionItem
          label="Time Warp"
          linkType="AppView"
          icon="fas fa-calendar"
          severity="Custom"
          key={index}
          menuItemId={menuItemId}
          clickHandler={() => setIsTimeWarpDialogOpen(true)}
        />
      );
    }
    return null;
  };

  const setLocalStorageDateTime = async () => {
    const storage = await StorageService.getInstance();
    await storage.setItem(DATETIME_OVERRIDE_KEY, `${dateTimeValue}Z`);
    setIsTimeWarpDialogOpen(false);
  };

  const clearDateTimeOverride = async () => {
    const storage = await StorageService.getInstance();
    await storage.removeItem(DATETIME_OVERRIDE_KEY);
    setIsTimeWarpDialogOpen(false);
  };

  return (
    <div role="group">
      <SectionContainer
        aria-expanded={open}
        open={open}
        id={`Section${sectionName.replace(/\s/g, '')}`}
        aria-controls={`Items${sectionName.replace(/\s/g, '')}`}
        onClick={handleClick}
      >
        <IconContainer>
          <Icon open={open} className={`${icon} `} aria-hidden="true" />
        </IconContainer>
        <Label open={open} icon={icon}>
          {sectionName}
        </Label>
        {isExpandable && <ChevronIcon open={open} aria-hidden="true" className="fa fa-chevron-down" />}
      </SectionContainer>
      <Items
        hidden={!open}
        id={`Items${sectionName.replace(/\s/g, '')}`}
        aria-labelledby={`Section${sectionName.replace(/\s/g, '')}`}
      >
        {items.map(({ title, type, value, icon, severity, menuItemId }, index) => {
          return (
            <Fragment key={index}>
              <SectionItem
                key={index}
                label={title}
                linkType={type}
                link={value}
                icon={icon}
                severity={severity}
                menuItemId={menuItemId}
                data-testid={'SectionItem'}
              />
              {index === items.length - 1 && sectionName.toLowerCase() === healthToolsValue.toLowerCase() && (
                <>{renderCalendar(++index, menuItemId)}</>
              )}
            </Fragment>
          );
        })}
      </Items>
      <StyledDialog open={isTimeWarpDialogOpen} onClose={() => setIsTimeWarpDialogOpen(false)}>
        <StyledDateTimePicker
          id="datetime-local"
          label="Date Picker"
          type="datetime-local"
          value={dateTimeValue}
          variant="standard"
          onChange={(evt) => setDateTimeValue(evt.target.value)}
        />
        <StyledButtonContainer>
          <StyledButton
            onClick={() => setIsTimeWarpDialogOpen(false)}
            data-testid="CancelOverride"
            variant="contained"
            color="primary"
          >
            Cancel
          </StyledButton>
          <StyledButton onClick={clearDateTimeOverride} data-testid="ClearOverride" variant="contained" color="primary">
            Clear Override
          </StyledButton>
          <StyledButton
            onClick={setLocalStorageDateTime}
            data-testid="ApplyOverride"
            variant="contained"
            color="primary"
          >
            Apply Override
          </StyledButton>
          <StyledButton onClick={() => setData({ userId: user?.sub })} variant="contained" color="primary">
            Update Thrive Progress
          </StyledButton>
        </StyledButtonContainer>
        {response && <p>Thrive Progress Updated</p>}
      </StyledDialog>
    </div>
  );
};

Section.propTypes = {
  items: PropTypes.array,
  index: PropTypes.number,
  activeTab: PropTypes.number,
  activateTab: PropTypes.func,
  sectionName: PropTypes.string,
  expanded: PropTypes.bool,
  icon: PropTypes.string,
  isExpandable: PropTypes.bool,
};

export default Section;
