import React, { useEffect } from 'react';
import Routes from 'Onboard_Pages/Routes/Routes';
import { browserHistory } from 'Onboard_Helpers/AppInsights';
import { patchSyncUser, selectSyncUser, selectUserInfo } from 'Onboard_Redux/user';
import { useOnboardSelector, useOnboardDispatch } from 'Contexts/StoreContext';
import {
  useLazySynchronizeUserQuery,
  useLazyGetNetworkGroupConfigQuery,
  useGetAgreementsMutation,
  useLazyGetUserMetaQuery,
} from 'Onboard_Redux/services/api';
import { selectAuthenticationStatus, authenticationStatusUpdated } from 'Onboard_Redux/authentication';

const Onboard = () => {
  const appUserInfo = useOnboardSelector(selectUserInfo);
  const syncUser = useOnboardSelector(selectSyncUser);
  const dispatch = useOnboardDispatch();
  const [synchronizeUser] = useLazySynchronizeUserQuery();
  const [getNetworkGroupConfig] = useLazyGetNetworkGroupConfigQuery();
  const [getAgreements] = useGetAgreementsMutation();
  const [getUserMeta] = useLazyGetUserMetaQuery();
  const authenticationStatus = useOnboardSelector(selectAuthenticationStatus);

  useEffect(() => {
    dispatch(authenticationStatusUpdated({ history: browserHistory }));
  }, [authenticationStatus]);

  useEffect(() => {
    if (appUserInfo?.sub) {
      synchronizeUser(appUserInfo.sub);
      getNetworkGroupConfig(appUserInfo.sub);
      getAgreements(appUserInfo.sub);
      getUserMeta(appUserInfo.sub);
    }
  }, [appUserInfo]);

  useEffect(() => {
    if (syncUser && syncUser.activeProfileId) {
      getNetworkGroupConfig(syncUser.userId);
    }
  }, [syncUser]);

  useEffect(() => {
    if (syncUser && appUserInfo) {
      let patchObject = {};
      const storedTimezone = syncUser.timeZoneId;
      const actualTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (storedTimezone != actualTimezone) {
        patchObject.timeZoneId = actualTimezone;
      }
      dispatch(patchSyncUser({ userId: +appUserInfo.sub, patchObject }));
    }
  }, [syncUser]);

  return <Routes />;
};

export default Onboard;
