import React, { useEffect } from 'react';
import { Grid, Grow, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { InterventionEngineModuleStore } from './InterventionEngineModuleStore';
import { TodoContainer, TodoHeader, TodoItem } from './styles';
import Button from 'Stories/components/Button';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { UserResourcesStore } from 'Core_Services/userResources/UserResourcesStore';
import { UserAccessor } from 'Core_Helpers/UserAccessor';
import { MyVideoToolsModuleStore } from 'Core_Pages/Video/MyVideoToolsModuleStore';

const TodoItems = () => {
  const interventionModuleStore = InterventionEngineModuleStore.Instance().Use();
  const userResourcesStore = UserResourcesStore.Instance().Use();
  const videoStore = MyVideoToolsModuleStore.Instance().Use();
  const userAccessor = new UserAccessor();
  const todoItems = interventionModuleStore.TodoList;
  const hasPermission = userResourcesStore.HasInterventionEngineResource;
  const userMeta = userAccessor.User?.Meta;
  const hasVideos = videoStore.VideoCount > 0;
  const intl = useIntl();
  const history = useHistory();

  const onTodoClick = (item) => {
    interventionModuleStore.startTodoItem(item);
    history.push(item?.link);
  };

  useEffect(() => {
    // Consider that we are approaching the technical limitations of the to-do list MVP,
    // and the next iteration of this feature should include a more comprehensive refactor before further additions are made.
    interventionModuleStore.getTodoList();
  }, [userMeta, hasPermission, hasVideos]);

  if (!hasPermission) {
    return <></>;
  }

  return (
    <Grid item mobile={12} tablet={6}>
      <Grow in={true}>
        <TodoContainer>
          <TodoHeader>
            <Typography fontSize={22} fontWeight={700} color={'white'}>
              {intl.formatMessage({ id: 'intervention-engine.todo.title' })}
            </Typography>
          </TodoHeader>
          {todoItems.map((item, key) => {
            const CheckIcon = item?.completed ? CheckCircleOutlineIcon : CircleIcon;
            return (
              <TodoItem
                key={key}
                variant={Button.variant.TEXT}
                onClick={() => onTodoClick(item)}
                disabled={item?.completed}
              >
                <CheckIcon />
                <Typography textAlign={'left'}>{item?.name}</Typography>
              </TodoItem>
            );
          })}
        </TodoContainer>
      </Grow>
    </Grid>
  );
};

export default TodoItems;
