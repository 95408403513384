import { balanceSenses } from 'Assets/img/my-tools-icons';

export const balancedSensesEs = {
  key: 'balanced-senses-es',
  title: 'Cómo permanecer equilibrado mediante tus 5 sentidos',
  titleImage: balanceSenses,
  titleImageAlt: '',
  contentId: 24,
  sections: [
    {
      key: 'balanced-senses-intro-es',
      hasNoSectionMargin: true,
      description:
        'Durante esos momentos cuando las cosas se tornan más desafiantes y se comienza a acumular ansiedad, el cuerpo ya tiene maneras establecidas para ayudar a disminuir el estrés: las percepciones sensoriales. Prueba este sencillo conteo regresivo que pasa por los cinco sentidos para ayudarte a sentirte más calmado, equilibrado y “con los pies en la tierra” cuando enfrentes estrés o enojo.\n\nPrimero, haz algunas respiraciones profundas y mira a tu alrededor. Después, describe el espacio a través de las percepciones sensoriales de la vista, el oído, el olfato, el tacto y el gusto. Al contar del cinco al uno, ¿cuáles son…',
      bulletedItems: [
        {
          text: '5 cosas que puedes ver?',
        },
        {
          text: '4 cosas que puedes sentir?',
        },
        {
          text: '3 cosas que puedes escuchar?',
        },
        {
          text: '2 cosas que puedes oler?',
        },
        {
          text: '1 cosa que puedes saborear?',
        },
      ],
    },
    {
      key: 'balanced-senses-finale-es',
      description:
        'Está bien decir tus comentarios en la mente o en voz alta. Esta sencilla técnica puede ayudarte a bloquear pensamientos de evasión y calmar tus emociones exaltadas. Tus sentidos pueden apoyarte en dondequiera que estés, ayudarte a alejar la atención de los sentimientos negativos y reenfocarte para permanecer fuerte y así poder manejar el asunto con confianza en ti mismo.\n\n¡Adelante, prueba esto ahora! ¿Cuáles son las cosas que notas? ¿Cómo te sentías antes de hacer esto? ¿Cómo te sientes después de esto?',
    },
  ],
};
