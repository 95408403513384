import { animationType } from '../constants/animationType';

export const updateActorScaleTowardsPoint = (
  game,
  originalDistance,
  actor,
  newX,
  newY,
  scaleAim,
  horizonLine,
  groundLine,
  minimumActorScale,
) => {
  if (!originalDistance) {
    originalDistance = Phaser.Math.Distance.Between(0, groundLine, 0, horizonLine);
    actor.originalDistanceScale = actor.scale;
  }
  const bottomOriginal = actor.getCenter().y;
  const currentDistance = Phaser.Math.Distance.Between(0, groundLine, 0, bottomOriginal);
  let distanceRatioFromGroundLine = (originalDistance - currentDistance) / originalDistance;

  // Ensure animal can't flip if it goes past horizon line
  if (distanceRatioFromGroundLine < 0) {
    distanceRatioFromGroundLine = 0;
  } else if (distanceRatioFromGroundLine > 1) {
    distanceRatioFromGroundLine = 1;
  }
  const actorDifference = actor.originalDistanceScale - minimumActorScale;
  const updatedCalculation = actorDifference * distanceRatioFromGroundLine + minimumActorScale;
  game.scaleAim = updatedCalculation;

  actor.scaleAimRatio = game.scaleAim;
  game.scaleAimRatio = game.scaleAim;
  actor.setScale(game.scaleAim);
  return originalDistance;
};

export const moveActorToPointWithScaling = (
  game,
  actor,
  transformRate,
  x,
  y,
  originalDistance,
  jumpVelocity,
  currentFetchObjectLocationCoordinates,
  moveSpeed = 75,
  currentMoveAnimation = animationType.RUN,
  minimumActorScale = 0.5,
  maxActorScale = 1.0,
  horizonLine = 0,
  groundLine = 0,
  // eslint-disable-next-line no-unused-vars
  otherSceneBox = undefined,
  scaleAim = undefined,
  itemBodyPart = undefined,
) => {
  // tell the pet to move to point
  // if basing the turn angle on some location of an item close to some body part
  if (itemBodyPart) {
    let currentFetchObjectLocation;
    currentFetchObjectLocation = {
      x: currentFetchObjectLocationCoordinates.x,
      y: currentFetchObjectLocationCoordinates.y,
    };
    if (!actor.body.allowGravity) {
      let itemBodyPartLocation = {
        x: x - (actor.flipX ? 1 : -1) * (actor.getCenter().x - currentFetchObjectLocation.x),
        y: y + (actor.getCenter().y - currentFetchObjectLocation.y),
      };
      if (
        game.parentScene &&
        (!actor.parentVelocityDirectionX || actor.parentVelocityDirectionX != (x < actor.getCenter().x ? -1 : 1))
      ) {
        actor.parentVelocityDirectionX = x < actor.getCenter().x ? -1 : 1;
      }
      game.physics.moveTo(actor, itemBodyPartLocation.x, itemBodyPartLocation.y, moveSpeed);
    }
    game.turnAngle = Phaser.Math.Angle.Between(currentFetchObjectLocation.x, currentFetchObjectLocation.y, x, y);
  } else {
    if (!actor.body.allowGravity) {
      game.physics.moveTo(actor, x, y, moveSpeed);
    }
    game.turnAngle = Phaser.Math.Angle.Between(actor.getCenter().x, actor.getCenter().y, x, y);
  }

  // If there is a horizon line, jump if over the horizon line
  if (horizonLine && actor.getBottomCenter().y < horizonLine && !actor.body.allowGravity) {
    actor.body.setVelocityY(-1 * jumpVelocity);
    actor.body.allowGravity = true;
    actor.play(animationType.JUMP);
  } else if (horizonLine && actor.getBottomCenter().y >= horizonLine && actor.body.allowGravity) {
    actor.body.allowGravity = false;
    actor.play(currentMoveAnimation);
  } else if (horizonLine && actor.body.allowGravity && Math.abs(actor.body.velocity.y) <= 5.0) {
    // actor.playReverse(animationType.JUMP);
    actor.play(animationType.LAND);
  }

  // update scale of pet to return to original pet scale
  if (Math.abs(actor?.body?.velocity.x) > 0 || Math.abs(actor?.body?.velocity.y) > 0) {
    // const currentScaleAim = scaleAim ?? game.configurablePetScale ?? actor.originalScale;
    originalDistance = updateActorScaleTowardsPoint(
      game,
      originalDistance,
      actor,
      x,
      y,
      scaleAim,
      horizonLine,
      groundLine,
      minimumActorScale,
      maxActorScale,
      transformRate,
    );
  }
  return originalDistance;
};

export const resetActorAtPoint = (game, actor, x, y) => {
  const theChosenScale = game.scaleTo
    ? game.scaleTo
    : actor.sceneOriginalScale
    ? actor.sceneOriginalScale
    : game.configurablePetScale
    ? game.configurablePetScale
    : actor.originalScale;
  actor.scaleActor(theChosenScale);
  actor.x = x;
  actor.y = y;
  actor.body.stop();
};

export const actorBodyPartNearItemNotEquipped = (game, actor, item, itemCoordinates, scaleAim, bodyPart) => {
  let actorPartCoordinates;
  if (game.currentPickupFrameToCheck) {
    const currentFetchObjectLocation = actor.getAttachmentLocation(bodyPart, game.currentPickupFrameToCheck);
    actorPartCoordinates = {
      x: currentFetchObjectLocation.x,
      y: currentFetchObjectLocation.y,
    };
  } else {
    actorPartCoordinates = {
      x: actor.getAttachmentLocation(bodyPart).x,
      y: actor.getAttachmentLocation(bodyPart).y,
    };
  }

  const actorWithinThreshX = Math.abs(actorPartCoordinates.x - itemCoordinates.x) < actor.displayWidth * 0.2;
  const actorWithinThreshY = Math.abs(actorPartCoordinates.y - itemCoordinates.y) < actor.displayHeight * 0.2;
  return (
    actor.itemEquipped(bodyPart, item.name) ||
    (actorWithinThreshX && actorWithinThreshY && !actor.itemEquipped(bodyPart, item.name))
  );
};

export const setStartLocation = (_this, scale) => {
  const startLocation = getRelativePoint({
    _this: _this,
    actor: _this.pet,
    scale: scale,
    height: _this.petHeight,
    width: _this.petWidth,
    locationPercentageX: _this.startLocationPercentageX,
    locationPercentageY: _this.startLocationPercentageY,
    offsetX: _this.startXOffset,
    offsetY: _this.startYOffset,
  });
  const endLocation = getRelativePoint({
    _this: _this,
    actor: _this.pet,
    scale: scale,
    height: _this.petHeight,
    width: _this.petWidth,
    locationPercentageX: _this.endLocationPercentageX,
    locationPercentageY: _this.endLocationPercentageY,
    offsetX: _this.endXOffset,
    offsetY: _this.endYOffset,
  });
  return { startLocation: startLocation, endLocation: endLocation };
};

export const getRelativePoint = (options) => {
  if (!options._this) return;
  const currentScale = options.scale ?? options.actor.scale;
  return {
    x:
      (options.relativeLocationX ?? options._this.game.renderer.width) * (options.locationPercentageX ?? 0.5) +
      (options._this.isFacingRight ? -1 : 1) * options._this.game.renderer.width * (options.offsetX ?? 0) -
      (options.width ?? options.actor.width) * currentScale,
    y:
      (options.relativeLocationY ?? options._this.game.renderer.height) * (options.locationPercentageY ?? 0.5) +
      (options._this.isFacingRight ? -1 : 1) * options._this.game.renderer.height * (options.offsetY ?? 0) -
      (options.height ?? options.actor.height) * currentScale,
  };
};

export const isAtPoint = (
  actor,
  x,
  y = undefined,
  lastLocationX = undefined,
  lastLocationY = undefined,
  threshX = 2.0,
  threshY = 5.0,
) => {
  const isPastLastLocationX = lastLocationX < x ? actor.x >= x : actor.x <= x;
  const isPastLastLocationY = lastLocationY < y ? actor.y >= y : actor.y <= y;
  return (
    (Math.abs(actor.x - x) <= threshX || isPastLastLocationX) &&
    (!y || Math.abs(actor.y - y) <= threshY || isPastLastLocationY)
  );
};
