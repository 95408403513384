import { animationType } from 'Core_Games/constants/animationType';
import { sceneType } from 'Core_Games/constants/sceneType';
import { uiTypes } from 'Core_Games/constants/uiTypes';
import { activityMenuConfig, keySceneDataTypes } from './activityMenuConfig';
import { findUIData } from 'Core_Games/Scripts/configHelper';
import { Menu } from 'Core_Games/Scene/Menu/Menu';
import { onClickGoToGameRoute, onClickGoToRoute } from 'Core_Games/Scripts/buttonHelper';
import { getPetInfo, resetPetInfo, packUserInfoFromGame, LogInteraction } from 'Core_Games/Scripts/interactionHelper';
import { isNowPastDate } from 'Core_Games/Scripts/dateHelper';
import { InteractionLimit } from 'Core_Games/constants/interactionLimit';

export class ActivityMenu extends Menu {
  constructor() {
    super(sceneType.ACTIVITYMENU.NAME);

    this.background = undefined;
    this.speak = undefined;
    this.bowlScale = 0.25;
    this.nutrientScale = 0.33;
    this.yOffset = 25;
    this.headerPadding = 5;
  }

  init(sceneData) {
    this.sceneData = sceneData;
    this.activityMenuConfig = activityMenuConfig;
    this.petState = this.sceneData.gameData.gameData.petState;

    let menuUiData = findUIData(this, this.activityMenuConfig, uiTypes.MENUSECTION);
    let backButton = menuUiData.BACKITEM;

    backButton
      ? (backButton.ONCLICK = () =>
          onClickGoToRoute(sceneData.appData.history, backButton?.LINK?.ROUTE, backButton?.LINK?.PARAMS))
      : backButton;

    for (let i = 0; i < menuUiData.DATA.length; i++) {
      let menuData = menuUiData.DATA[i];
      for (let y = 0; y < menuData.MENUSECTIONITEMS.length; y++) {
        let menuSectionItemData = menuData.MENUSECTIONITEMS[y];
        if (menuSectionItemData.LINK) {
          findUIData(this, this.activityMenuConfig, uiTypes.MENUSECTION).DATA[i].MENUSECTIONITEMS[y].ONCLICK = () => {
            onClickGoToGameRoute(sceneData.appData.history, menuSectionItemData.LINK.NAME);
          };
        }
      }
    }

    super.init(sceneType.ACTIVITYMENU, this.activityMenuConfig, sceneData);
  }

  preload() {
    super.preload();
  }

  create() {
    let _this = this;
    this.petType = this.sceneData.appData.info.data.gameData.petState.petType;

    super.create();

    // set initial parameters
    this.engine.engineAssets.load.on(Phaser.Loader.Events.COMPLETE, function () {
      _this.finishedLoading = true;

      _this.pet.x =
        _this.game.renderer.width * 1.0 - _this.pet.displayWidth * 0.9 - _this.game.renderer.width * _this.startXOffset;
      _this.pet.y = _this.game.renderer.height * 0.0 + _this.yOffset;

      const minHeaderDisplayHeight = _this.pet.getBottomCenter().y + _this.headerPadding;

      const screenToShow = _this.getPetHealthState();

      const animationToPlay =
        screenToShow == keySceneDataTypes.SCREEN_SAD ? animationType.POSE_SAD : animationType.POSE_HAPPY;

      _this.pet.play(animationToPlay);

      _this.engine.engineUi.menuSectionManager.displayScreen(screenToShow, minHeaderDisplayHeight);

      // enforce activity limits (disables buttons if exceeds limit)
      _this.enforceActivityLimits();

      _this.background.setDisplaySize(_this.game.renderer.width, minHeaderDisplayHeight);
      _this.background.y = minHeaderDisplayHeight / 2;

      // for scrolling to be updated for ui
      _this.originalPetLocationY = _this.pet.y;
      _this.originalBackgroundLocationY = _this.background.y;

      // physics overlap logic
      _this.addEventLogic(_this, _this.pet, _this.nutrientContainer, _this.bowl);
    });
  }

  update() {
    super.update();

    if (this.finishedLoading) {
      // keep phaser sprites updated with UI scrolling
      this.pet.y = this.originalPetLocationY - this.engine.engineUi.menuSectionManager.scrollHeight;
      this.background.y = this.originalBackgroundLocationY - this.engine.engineUi.menuSectionManager.scrollHeight;
    }
  }

  // Scene-Specific Functions
  getPetHealthState = () => {
    this.setActivityLimits();
    return this.getPetHealthScreenType();
  };

  setActivityLimits = () => {
    this.activityLimits = {
      [keySceneDataTypes.MENUSECTION_FOOD]: {
        config: this.getMenuItemConfig(keySceneDataTypes.MENUSECTION_FOOD),
      },
      [keySceneDataTypes.MENUSECTION_WATER]: {
        config: this.getMenuItemConfig(keySceneDataTypes.MENUSECTION_WATER),
      },
      [keySceneDataTypes.MENUSECTION_ATTENTION]: {
        config: this.getMenuItemConfig(keySceneDataTypes.MENUSECTION_ATTENTION),
      },
      [keySceneDataTypes.MENUSECTION_GOOUTSIDE]: {
        config: this.getMenuItemConfig(keySceneDataTypes.MENUSECTION_GOOUTSIDE),
      },
      [keySceneDataTypes.MENUSECTION_CHASE]: {
        config: this.getMenuItemConfig(keySceneDataTypes.MENUSECTION_CHASE),
      },
      [keySceneDataTypes.MENUSECTION_TREAT]: {
        config: this.getMenuItemConfig(keySceneDataTypes.MENUSECTION_TREAT),
      },
    };
  };

  getMenuItemConfig = (menuSceneType) => {
    const currentUIData = findUIData(this, this.activityMenuConfig, uiTypes.MENUSECTION);
    const currentMenuSectionData = currentUIData?.DATA?.find((x) =>
      x.MENUSECTIONITEMS.some((y) => y.KEY == menuSceneType),
    )?.MENUSECTIONITEMS;
    return currentMenuSectionData.find((x) => x.KEY == menuSceneType);
  };

  getPetHealthScreenType = () => {
    if (
      Object.keys(this.activityLimits).some(
        (x) =>
          this.activityLimits[x]?.config?.REQUIRED && isNowPastDate(this.petState[this.activityLimits[x].config.DATE]),
      )
    ) {
      return keySceneDataTypes.SCREEN_SAD;
    } else {
      return keySceneDataTypes.SCREEN_HEALTHY;
    }
  };

  // Add Collision & event logic
  // eslint-disable-next-line no-unused-vars
  addEventLogic = (game, actor) => {};

  interactionData = (interactionType) => {
    const interactionData = getPetInfo()?.interactions;
    return interactionData && interactionData[interactionType.CODE] ? interactionData[interactionType.CODE] : 0;
  };

  activityExceedsLimit = (interactionData, limit = -1) => {
    return limit >= 0 && interactionData >= limit;
  };

  enforceActivityLimits = () => {
    // update buttons if member has exceeded the activity limit
    for (var menuItem in this.activityLimits) {
      const currentMenuItem = this.activityLimits[menuItem];
      const currentLimit = this.getActivityLimit(currentMenuItem?.config?.LINK?.CODE)?.LIMIT;
      const petInfo = getPetInfo();

      // if the date is past today (limits are daily only), then reset the pet info
      // if we need to change this later to not be daily only, we should update the logic here accordingly
      if (!petInfo || isNowPastDate(petInfo.lastUpdated)) {
        resetPetInfo(packUserInfoFromGame(this));
      }

      const currentData = this.interactionData(currentMenuItem?.config?.LINK);
      const lastInteractionActivityDate = this.petState[currentMenuItem.config.DATE];

      // if the pet state shows that there was an interaction completed today,
      // then the interaction must have been done at least once, so update the info
      // accordingly
      if (
        currentData == 0 &&
        lastInteractionActivityDate !== undefined &&
        !isNowPastDate(lastInteractionActivityDate, 1.0) &&
        currentMenuItem?.config?.LINK?.CODE
      ) {
        LogInteraction(this, currentMenuItem?.config?.LINK, false);
      }

      if (this.activityExceedsLimit(currentData, currentLimit)) {
        this.engine.engineUi.menuSectionManager.toggleMenuSecionData(menuItem, true);
      }
    }
  };

  getActivityLimit = (interactionCode) => {
    if (!interactionCode) {
      return -1;
    }
    const petLimits = InteractionLimit[this.petType];
    const interactionLimit = petLimits ? petLimits[interactionCode] : -1;
    return interactionLimit;
  };
}
