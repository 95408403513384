import React, { useEffect, useMemo, useRef, useState } from 'react';
import ChatItem from './ChatItem/ChatItem';
import { ChatContainer, ChatList } from './styles';
import ChatLoading from './ChatLoading/ChatLoading';
import PropTypes from 'prop-types';
import ChatScreenReader from './ChatScreenReader/ChatScreenReader';

const Chat = ({ messages, status, onSubmit }) => {
  const refreshSpeed = 850;
  const messagesEndRef = useRef(null);
  const [screenReaderChatItem, setScreenReaderChatItem] = useState();
  const scrollChatToBottom = () => messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });

  // Ensures that when the user returns back to the chat, that the bottom options are shown
  useEffect(() => {
    window.addEventListener('visibilitychange', scrollChatToBottom);
    return () => {
      window.removeEventListener('visibilitychange', scrollChatToBottom);
    };
  }, []);

  useEffect(() => {
    let timeout;
    if (messages && messagesEndRef?.current?.scrollIntoView) {
      // Once messages are updated then it scrolls to bottom
      timeout = setTimeout(() => {
        scrollChatToBottom();
      }, refreshSpeed);
    }

    if (messages?.length) {
      const { value, text, position, alt, buttons, type } = messages[messages.length - 1];

      setScreenReaderChatItem(
        <ChatScreenReader
          position={position}
          alt={alt}
          text={text}
          type={type}
          status={status}
          buttons={buttons}
          value={value}
        />,
      );
    }

    return () => clearTimeout(timeout);
  }, [messages]);

  const chatItems = useMemo(() => {
    return messages.map((message, i) => {
      if (message) {
        const { messageText, responseChoices, isEnabled, value, text, position, style, alt, buttons, screen, type } =
          message;
        return (
          <ChatItem
            onSubmit={onSubmit}
            key={`ChatItem_${type}_${i}`}
            type={type}
            status={status}
            index={i}
            messageText={messageText}
            responseChoices={responseChoices}
            isEnabled={isEnabled}
            value={value}
            text={text}
            position={position}
            style={style}
            alt={alt}
            buttons={buttons}
            screen={screen}
          />
        );
      }
    });
  }, [messages, onSubmit]);

  return (
    <ChatContainer role="region" aria-labelledby="PageTitle">
      <div aria-live="polite" aria-atomic="true" aria-relevant="additions">
        {screenReaderChatItem}
      </div>
      <ChatList>
        {chatItems}
        <ChatLoading loading={status.loading} avatar={status.avatar} alt={status.alt} />
        <div ref={messagesEndRef} />
      </ChatList>
    </ChatContainer>
  );
};

Chat.propTypes = {
  messages: PropTypes.array.isRequired,
  status: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};

export default Chat;
