export const activityCategories = [
  { code: 'HOUSEWORK', label: 'reminders.activity-type-housework' },
  { code: 'LEISURE', label: 'reminders.activity-type-leisure' },
  { code: 'EDUCATION', label: 'reminders.activity-type-education' },
  { code: 'SELFCARE', label: 'reminders.activity-type-selfcare' },
  { code: 'FITNESS', label: 'reminders.activity-type-fitness' },
  { code: 'WORK', label: 'reminders.activity-type-work' },
  { code: 'OTHER', label: 'reminders.activity-type-other' },
];

export const mapCodeToLabel = (code) => activityCategories.find((c) => c.code === code).label;
