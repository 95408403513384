import styled from '@emotion/styled';
import { Switch } from '@mui/material';
import { legacyBackgroundColors } from 'Styles/colors';

export const StyledSwitchLabel = styled.label`
  position: relative;
  right: 10px;
`;

export const StyledSwitch = styled(Switch)`
  .MuiSwitch-switchBase.Mui-checked {
    color: ${legacyBackgroundColors.indigo};
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${legacyBackgroundColors.indigo};
  }
`;
