import { createSlice } from '@reduxjs/toolkit';
import identityApi from './services/identity';
import hasValidationErrorForProperty from 'Core_Components/Account/LogIn/hasValidationErrorForProperty';
import {
  LOGIN_SCREEN,
  FORGOT_CREDENTIAL,
  OTP,
  RESET_PASSWORD,
  CONFIRMATION,
  TIMEOUT,
} from 'Core_Components/Account/LogIn/steps';

const initialState = {
  step: LOGIN_SCREEN,
  credentialType: '',
  recoveryMethod: '',
  recoveryMethodInput: '',
  passwordResetOtp: null,
  birthday: null,
  shouldRedirectToHome: false,
  passwordChanged: false,
};

export const selectStep = (state) => state.login.step;
export const selectCredentialType = (state) => state.login.credentialType;
export const selectRecoveryMethod = (state) => state.login.recoveryMethod;
export const selectRecoveryMethodInput = (state) => state.login.recoveryMethodInput;
export const selectBirthday = (state) => state.login.birthday;
export const selectPasswordResetOtp = (state) => state.login.passwordResetOtp;
export const selectShouldRedirectToHome = (state) => state.login.shouldRedirectToHome;
export const selectPasswordChanged = (state) => state.login.passwordChanged;

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    recoverCredential: (state, action) => {
      state.step = FORGOT_CREDENTIAL;
      state.credentialType = action.payload.credentialType;
    },
    submitCredentialRecovery: (state, { payload }) => {
      state.recoveryMethod = payload.recoveryMethod;
      state.recoveryMethodInput = payload.recoveryMethodInput;
      state.birthday = payload.birthday;
    },
    navigate: (state, action) => {
      state.step = action.payload.target;
    },
    completeOtpEntry: (state, { payload }) => {
      state.passwordResetOtp = payload.otp;
    },
    resetPasswordChanged: (state) => {
      state.passwordChanged = false;
    },
    resetLoginState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(identityApi.endpoints.forgotUsername.matchFulfilled, (state) => {
      state.step = CONFIRMATION;
    });
    builder.addMatcher(identityApi.endpoints.forgotPassword.matchFulfilled, (state) => {
      state.step = OTP;
    });
    builder.addMatcher(identityApi.endpoints.forgotPasswordVerifyOtp.matchFulfilled, (state) => {
      state.step = RESET_PASSWORD;
    });
    builder.addMatcher(identityApi.endpoints.updatePassword.matchRejected, (state, action) => {
      state.passwordChanged = false;

      if (action.payload?.status === 400 && hasValidationErrorForProperty(action.payload, 'Code')) {
        state.step = TIMEOUT;
      }
    });
    builder.addMatcher(identityApi.endpoints.updatePassword.matchFulfilled, (state) => {
      state.passwordChanged = true;
      state.step = LOGIN_SCREEN;
    });
  },
});

export const {
  submitCredentialRecovery,
  recoverCredential,
  navigate,
  completeOtpEntry,
  resetPasswordChanged,
  resetLoginState,
} = loginSlice.actions;

export default loginSlice.reducer;
