import { combineReducers, configureStore } from '@reduxjs/toolkit';
import api from 'Core_Redux/services/api';
import identityApi from 'Core_Redux/services/identity';
import agreementsReducer from 'Core_Redux/agreements';
import authenticationReducer from 'Core_Redux/authentication';
import loginReducer from 'Core_Redux/login';
import networkGroupReducer from 'Core_Redux/networkGroup';
import remindersReducer from 'Core_Redux/reminders';
import signupReducer from 'Core_Redux/signup';
import userReducer from 'Core_Redux/user';
import thriveReducer from 'Core_Redux/thrive';
import { persistStore, persistReducer, FLUSH, REHYDRATE, REGISTER, PAUSE, PERSIST, PURGE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const rootReducer = combineReducers({
  agreements: agreementsReducer,
  authentication: authenticationReducer,
  login: loginReducer,
  networkGroup: networkGroupReducer,
  reminders: remindersReducer,
  signup: signupReducer,
  user: userReducer,
  thrive: thriveReducer,
  [api.reducerPath]: api.reducer,
  [identityApi.reducerPath]: identityApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['agreements', 'authentication', 'networkGroup', 'thrive', 'user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default (() => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // redux-persist actions do NOT conform to this middleware
        // https://github.com/rt2zz/redux-persist/issues/988
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, REGISTER, PAUSE, PERSIST, PURGE],
        },
      })
        .concat(api.middleware)
        .concat(identityApi.middleware),
  });
  const persistor = persistStore(store);
  return { store, persistor };
})();
