import { atom, getDefaultStore, useAtom } from 'jotai';
import { Singleton } from 'Core_Helpers/Singleton';
import { IStateStore } from 'Core_Helpers/IStateStore';
import getDateTimeOverride from 'Core_Helpers/localStorage/getDateTimeOverride/getDateTimeOverride';

const CUTOFFDATE = '2023-01-01';
const ACKNOWLEDGEMENTCOUNTTHRESHOLD = 1;
const STORAGEKEY = 'TBD_Unique_Storage_Key';

export class WhatsNewModalModuleStore extends Singleton implements IStateStore {
  private atomStore = getDefaultStore();
  private isOpenAtom;
  private hasBeenShown = false;

  constructor() {
    super();
    this.isOpenAtom = atom(false);
  }

  public Use = () => {
    useAtom(this.isOpenAtom);

    return this;
  };

  public get IsOpen(): any {
    return this.atomStore.get(this.isOpenAtom);
  }

  public close() {
    this.atomStore.set(this.isOpenAtom, false);
  }

  public open() {
    this.atomStore.set(this.isOpenAtom, true);
    this.hasBeenShown = true;
  }

  public readyForDisplay() {
    return (
      !this.hasBeenShown && this.getAcknowledgmentCount() < ACKNOWLEDGEMENTCOUNTTHRESHOLD && this.isTimeForWhatsNew()
    );
  }

  public acknowledge() {
    const ackCount = JSON.parse(localStorage.getItem(STORAGEKEY)) ?? 0;

    localStorage.setItem(STORAGEKEY, (ackCount + 1).toString());
  }

  private getAcknowledgmentCount() {
    const count = JSON.parse(localStorage.getItem(STORAGEKEY));
    return count;
  }

  private isTimeForWhatsNew() {
    const dateTimeOverride = getDateTimeOverride();
    const today = dateTimeOverride ? new Date(dateTimeOverride) : new Date();
    return today < new Date(CUTOFFDATE);
  }
}
