import styled from '@emotion/styled';
import CircleIcon from '@mui/icons-material/Circle';

export const AlertIcon = styled(CircleIcon)`
  color: #f71d1d;
  position: absolute;
  right: 16px;
  font-size: 14px;
  width: 14px;
`;
