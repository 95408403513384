import React from 'react';
import PropTypes from 'prop-types';
import { StyledHeader } from './styles';

function PageHeader({ marginTop, fontWeight, color, alignSelf, children }) {
  return (
    <StyledHeader marginTop={marginTop} fontWeight={fontWeight} color={color} alignSelf={alignSelf}>
      {children}
    </StyledHeader>
  );
}
PageHeader.propTypes = {
  alignSelf: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.string,
  fontWeight: PropTypes.string,
  marginTop: PropTypes.string,
};

export default PageHeader;
