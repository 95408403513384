import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyledContent, StyledHeader, StyledModalBodyContainer, StyledModalContainer } from './styles';
import { DirectMessageStore } from 'Core_Pages/DirectMessage/Store/DirectMessageStore';
import { Button, Grid, Typography } from '@mui/material';
import { ModalStatusTypes } from 'Core_Pages/DirectMessage/Models/ModalStatusTypes';
import { useLocation } from 'react-router-dom';
import { TALKTOSOMEONE } from 'Core_Pages/Routes/RoutesConfig';

const SessionExpiredModal = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const directMessageStore = DirectMessageStore.Instance().Use();
  const showDMSessionExpiredModal = directMessageStore.ShowSessionExpiredModal;

  useEffect(() => {
    if (showDMSessionExpiredModal == ModalStatusTypes.OPEN) {
      if (location?.pathname !== TALKTOSOMEONE) {
        setOpen(true);
      } else {
        directMessageStore.CloseSessionExpiredModal();
      }
    }
  }, [showDMSessionExpiredModal]);

  const onContinue = () => {
    directMessageStore.CloseSessionExpiredModal();
    setOpen(false);
  };

  return (
    <StyledModalContainer id={'expired-dm-session-modal'} open={open || false}>
      <StyledModalBodyContainer>
        <Grid container textAlign="left">
          <Grid item mobile={12}>
            <StyledHeader>{intl.formatMessage({ id: 'direct-message.session-expired' })}</StyledHeader>
          </Grid>
          <Grid item mobile={12}>
            <StyledContent>{intl.formatMessage({ id: 'direct-message.session-expired-detail' })}</StyledContent>
          </Grid>
          <Grid item mobile={8} textAlign="right"></Grid>
          <Grid item mobile={4}>
            <Button variant="text" onClick={onContinue}>
              <Typography fontSize="18px" fontWeight="700">
                {intl.formatMessage({ id: 'common.okay' })}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </StyledModalBodyContainer>
    </StyledModalContainer>
  );
};

export default SessionExpiredModal;
