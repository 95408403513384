import React, { useState } from 'react';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import { useIntl } from 'react-intl';
import { Divider } from '@mui/material';
import Button from 'Stories/components/Button';
import { Header, StyledTextInput, StyledPasswordInput } from 'Onboard_Components/Account/styles';
import { Controller, useForm } from 'react-hook-form';
import { recoverCredential, resetPasswordChanged, selectPasswordChanged } from 'Onboard_Redux/login';
import { useLoginMutation } from 'Onboard_Redux/services/identity';
import { ForgotCredentialLink, CredentialError } from './styles';
import { clearTokens } from 'Onboard_Redux/authentication';
import Snackbar from 'Onboard_Components/Account/Snackbar';

function LogInScreen() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const [login, { isLoading: loggingIn, isError }] = useLoginMutation();
  const [isPending, setIsPending] = useState(false);
  const passwordChanged = useOnboardSelector(selectPasswordChanged);

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm({ defaultValues: { username: '', password: '' } });

  const formComplete = dirtyFields.username && dirtyFields.password;

  async function onSubmit({ username, password }) {
    try {
      setIsPending(true);
      await dispatch(clearTokens());
      await login({ username, password });
    } catch (e) {
      setIsPending(false);
      reset();
    }
  }

  function onForgotUsernameClick() {
    dispatch(recoverCredential({ credentialType: 'username' }));
  }

  function onForgotPasswordClick() {
    dispatch(recoverCredential({ credentialType: 'password' }));
  }

  function onClosePasswordChanged() {
    dispatch(resetPasswordChanged());
  }

  const isLoading = loggingIn || (isPending && !isError);

  return (
    <>
      <Header>{intl.formatMessage({ id: 'account.log-in' })}</Header>
      {isError && <CredentialError>{intl.formatMessage({ id: 'account.log-in.invalid-credentials' })}</CredentialError>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <StyledTextInput
              {...field}
              id="usernameInput"
              label={intl.formatMessage({ id: 'account.log-in.username' })}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <StyledPasswordInput {...field} label={intl.formatMessage({ id: 'account.log-in.password' })} />
          )}
        />
        <Button
          fullWidth
          id="loginSubmitButton"
          sx={{ marginBottom: '32px' }}
          disabled={!formComplete}
          type="submit"
          loading={isLoading}
        >
          {intl.formatMessage({ id: 'common.submit' })}
        </Button>
      </form>
      <Divider sx={{ marginBottom: '16px' }} />
      <ForgotCredentialLink id="forgotUsernameLink" component="button" onClick={onForgotUsernameClick}>
        {intl.formatMessage({ id: 'account.log-in.forgot-username' })}
      </ForgotCredentialLink>
      <ForgotCredentialLink id="forgotPasswordLink" component="button" onClick={onForgotPasswordClick}>
        {intl.formatMessage({ id: 'account.log-in.forgot-password' })}
      </ForgotCredentialLink>
      <Snackbar
        message={intl.formatMessage({ id: 'account.log-in.password-changed' })}
        open={passwordChanged}
        onClose={onClosePasswordChanged}
      />
    </>
  );
}

export default LogInScreen;
