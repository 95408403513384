import React from 'react';
import { Modal } from '@mui/material';
import Button from 'Stories/components/Button';
import {
  StyledBox,
  StyledCloseIconContainer,
  StyledBoxContent,
  StyledUpdateModalTitle,
  StyledUpdateModalText,
} from './styles';
import { pyxirRocket } from 'Assets/img/home-option-icons';
import CloseIcon from '@mui/icons-material/Close';
import { useIntl } from 'react-intl';
import { UpdateAppPromptModuleStore } from 'Stores/UpdateAppPromptModuleStore';

function UpdateAppModal() {
  const intl = useIntl();
  const store = UpdateAppPromptModuleStore.Instance().Use();
  const isOpen = store.IsOpen;

  function onClose() {
    store.close();
  }

  function handleClick() {
    store.acknowledgeAppUpdate();
    store.close();
  }

  return (
    <Modal open={isOpen} className="safe-area" style={{ zIndex: 1400 }}>
      <StyledBox>
        <StyledCloseIconContainer aria-label={intl.formatMessage({ id: 'button.close' })} onClick={onClose}>
          <CloseIcon fontSize="inherit" fontWeight="inherit" />
        </StyledCloseIconContainer>
        <StyledBoxContent>
          <img alt="" src={pyxirRocket} style={{ marginBottom: '12px' }} />
          <StyledUpdateModalTitle component="h1">
            {intl.formatMessage({ id: 'modal.update-app-title' })}
          </StyledUpdateModalTitle>
          <StyledUpdateModalText>{intl.formatMessage({ id: 'modal.update-app-content-1' })}</StyledUpdateModalText>
          <StyledUpdateModalText style={{ marginBottom: '75px' }}>
            {intl.formatMessage({ id: 'modal.update-app-content-2' })}
          </StyledUpdateModalText>
          <Button fullWidth onClick={handleClick}>
            {intl.formatMessage({ id: 'common.update' })}
          </Button>
        </StyledBoxContent>
      </StyledBox>
    </Modal>
  );
}

export default UpdateAppModal;
