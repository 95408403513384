import styled from '@emotion/styled';
import { TextField as MuiTextField } from '@mui/material';

export const TextField = styled(MuiTextField)`
  label {
    color: ${({ theme, color }) => theme.palette[color].main};
  }
`;

export const PrimaryTextField = styled(TextField)`
  .MuiFilledInput-underline:after,
  .MuiInput-underline:after {
    border-bottom-color: ${({ theme }) => theme.palette.secondary.main};
  }

  .MuiOutlinedInput-root.Mui-focused {
    fieldset {
      border-color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;
