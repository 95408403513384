import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import theme from 'Styles/theme';

const MedicationConfig = ({ control, errors }) => {
  const intl = useIntl();

  return (
    <>
      <Controller
        name="medicationName"
        control={control}
        defaultValue=""
        rules={{
          required: intl.formatMessage({ id: 'error.min-characters' }, { min: 3 }),
          minLength: { value: 3, message: intl.formatMessage({ id: 'error.min-characters' }, { min: 3 }) },
          maxLength: { value: 100, message: intl.formatMessage({ id: 'error.max-characters' }, { max: 100 }) },
        }}
        render={({ field: { value, onChange, ref } }) => (
          <TextField
            inputRef={ref}
            inputProps={{ 'aria-label': intl.formatMessage({ id: 'reminders.medication-name' }) }}
            value={value}
            onChange={onChange}
            placeholder={`${intl.formatMessage({ id: 'reminders.medication-name' })} *`}
            variant="outlined"
            sx={{
              '.MuiInputBase-input::placeholder': {
                color: errors.medicationName ? `${theme.palette.error.main}` : 'inherit',
              },
            }}
            error={!!errors.medicationName}
            helperText={errors.medicationName?.message}
          />
        )}
      />
      <Controller
        name="medicationDose"
        control={control}
        defaultValue=""
        rules={{
          maxLength: { value: 32, message: intl.formatMessage({ id: 'error.max-characters' }, { max: 32 }) },
        }}
        render={({ field: { value, onChange, ref } }) => (
          <TextField
            inputRef={ref}
            inputProps={{ 'aria-label': intl.formatMessage({ id: 'reminders.medication-dose' }) }}
            value={value}
            onChange={onChange}
            placeholder={intl.formatMessage({ id: 'reminders.medication-dose' })}
            variant="outlined"
            helperText={errors.medicationDose?.message}
          />
        )}
      />
    </>
  );
};

MedicationConfig.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default MedicationConfig;
