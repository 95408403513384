import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { black } from 'Styles/colors';
import { Grid, Typography } from '@mui/material';
import { StyledMessageReceived, StyledMessageReceivedWrapper } from './styles';
import { supportAgent } from 'Assets/img/talk-to-someone-icons';

const ReceivedMessage = ({ message }) => {
  return (
    <Grid container justifyContent="left" alignItems="left">
      <Grid item mobile={11}>
        <StyledMessageReceivedWrapper>
          <Typography
            fontSize="18px"
            textAlign="left"
            color={black._60}
            marginLeft={!message.isHuntGroup ? '38px' : '8px'}
          >
            {message.timestamp} {message.from}
          </Typography>
          <div style={{ display: 'flex', marginTop: '4px' }}>
            {!message.isHuntGroup ? <img alt="" src={supportAgent} style={{ alignSelf: 'flex-start' }} /> : null}
            <StyledMessageReceived>
              <Typography fontSize="18px" textAlign="left">
                {parse(message.text)}
              </Typography>
            </StyledMessageReceived>
          </div>
        </StyledMessageReceivedWrapper>
      </Grid>
    </Grid>
  );
};

ReceivedMessage.propTypes = {
  message: PropTypes.object,
};

export default ReceivedMessage;
