import { RRule } from 'rrule';

export const frequencies = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};

export const ordinals = {
  FIRST: 'FIRST',
  SECOND: 'SECOND',
  THIRD: 'THIRD',
  FOURTH: 'FOURTH',
  LAST: 'LAST',
};

export const weekdays = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
};
export const weekdayArray = Object.keys(weekdays);

export const weekdayToRule = (day) => {
  switch (day) {
    case weekdays.SUNDAY:
      return RRule.SU;
    case weekdays.MONDAY:
      return RRule.MO;
    case weekdays.TUESDAY:
      return RRule.TU;
    case weekdays.WEDNESDAY:
      return RRule.WE;
    case weekdays.THURSDAY:
      return RRule.TH;
    case weekdays.FRIDAY:
      return RRule.FR;
    case weekdays.SATURDAY:
      return RRule.SA;
  }
};

export const ruleToWeekday = (rule) => {
  switch (rule) {
    case RRule.SU.weekday:
      return weekdays.SUNDAY;
    case RRule.MO.weekday:
      return weekdays.MONDAY;
    case RRule.TU.weekday:
      return weekdays.TUESDAY;
    case RRule.WE.weekday:
      return weekdays.WEDNESDAY;
    case RRule.TH.weekday:
      return weekdays.THURSDAY;
    case RRule.FR.weekday:
      return weekdays.FRIDAY;
    case RRule.SA.weekday:
      return weekdays.SATURDAY;
  }
};

export const ordinalToRule = (ordinal) => {
  switch (ordinal) {
    case ordinals.FIRST:
      return 1;
    case ordinals.SECOND:
      return 2;
    case ordinals.THIRD:
      return 3;
    case ordinals.FOURTH:
      return 4;
    case ordinals.LAST:
      return -1;
  }
};

export const ruleToOrdinal = (rule) => {
  switch (rule) {
    case 1:
      return ordinals.FIRST;
    case 2:
      return ordinals.SECOND;
    case 3:
      return ordinals.THIRD;
    case 4:
      return ordinals.FOURTH;
    case -1:
      return ordinals.LAST;
  }
};
