import React from 'react';
import PropTypes from 'prop-types';
import ThriveBadge from 'Core_Pages/Thrive/ThriveActivities/ThriveBadge/ThriveBadge';
import { ThriveWeekContainer, BadgesContainer, WeekHeader, WeekDivider } from './styles';
import { ListItemText } from '@mui/material';
import { useIntl } from 'react-intl';
import { badgeStatuses } from 'Core_Pages/Thrive/ThriveConfig/enums';
import { badges } from 'Core_Pages/Thrive/ThriveConfig/badges';

function ThriveWeek({ index, disabled, thriveWeek }) {
  const intl = useIntl();

  const thriveBadges = thriveWeek?.badges?.slice()?.sort((a, b) => {
    if (a.badgeStatus === badgeStatuses.COMPLETE && b.badgeStatus === badgeStatuses.COMPLETE) {
      return new Date(a.completionDateUtc) - new Date(b.completionDateUtc);
    } else if (a.badgeStatus === badgeStatuses.COMPLETE) {
      return -1;
    }
  });

  function getBadgeConfig(badge) {
    const { statuses, ...props } = badges[badge?.badgeType];
    const badgeStatusConfig = statuses[badge?.badgeStatus];
    const badgeDisabled = badge?.badgeStatus !== badgeStatuses.COMPLETE;
    const thriveBadgeProps = { ...props, ...badgeStatusConfig, disabled: badgeDisabled };
    return thriveBadgeProps;
  }

  return (
    <ThriveWeekContainer disabled={disabled}>
      <WeekHeader>
        <WeekDivider textAlign="left">
          <ListItemText primaryTypographyProps={{ fontSize: 18, fontWeight: 700 }}>
            {`${intl.formatMessage({ id: 'common.week' })} ${index + 1}`}
          </ListItemText>
        </WeekDivider>
      </WeekHeader>
      <BadgesContainer>
        {thriveBadges?.map((badge, badgeIndex) => (
          <ThriveBadge key={`${index}-${badgeIndex}`} {...getBadgeConfig(badge)} />
        ))}
      </BadgesContainer>
    </ThriveWeekContainer>
  );
}

ThriveWeek.propTypes = {
  index: PropTypes.number,
  thriveWeek: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ThriveWeek;
