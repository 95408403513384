const isIOS = () => {
  if (navigator.standalone) {
    //user has already installed the app
    return false;
  }
  const ua = window.navigator.userAgent;
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i);

  return isIPad || isIPhone;
};

const useIosInstallPrompt = () => {
  return [isIOS()];
};

export default useIosInstallPrompt;
