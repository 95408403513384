import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import GreetingCardsLanding from 'Core_Pages/GreetingCards/GreetingCardsLanding/GreetingCardsLanding';
import DesignYourCard from 'Core_Pages/GreetingCards/DesignYourCard/DesignYourCard';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectSyncUser } from 'Core_Redux/user';
import GreetingCardsInbox from 'Core_Pages/GreetingCards/GreetingCardsInbox/GreetingCardsInbox';
import GreetingCardsSubmission from 'Core_Pages/GreetingCards/GreetingCardsSubmission/GreetingCardsSubmission';
import { StyledGreetingCardsContainer } from 'Core_Pages/GreetingCards/featureStyles';
import MessageSelection from 'Core_Pages/GreetingCards/MessageSelection/MessageSelection';
import ImageSelection from 'Core_Pages/GreetingCards/ImageSelection/ImageSelection';
import getSvgImageConfig from 'Core_Helpers/getSvgImageConfig';

const CARD_KEY = 'card-data';

function GreetingCards() {
  const [userFirstName, setUserFirstName] = useState('');
  const syncUser = useCoreSelector(selectSyncUser);
  const { state } = useLocation();
  const [card, setCard] = useState({
    imageId: 0,
    storagePath: '',
    imageDescription: '',
    messageId: 0,
    message: '',
    coloringBookImageKey: '',
    coloringBookImageColors: [],
  });

  useEffect(() => {
    if (state?.card) {
      handleSetCard(state.card);
    } else {
      const storedCard = JSON.parse(localStorage.getItem(CARD_KEY));
      if (storedCard) setCard(storedCard);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(CARD_KEY, JSON.stringify(card));
  }, [card]);

  useEffect(() => {
    if (syncUser) {
      setUserFirstName(syncUser?.firstName);
    }
  }, [syncUser]);

  const handleSetCard = (newCardData) => {
    // Check if a card is a defined image or else it is a coloring book image
    if (newCardData.imageId) {
      setCard({
        ...newCardData,
        coloringBookImageKey: '',
        coloringBookImageColors: [],
      });
    } else {
      setCard({
        ...newCardData,
        coloringBookImageKey: newCardData.coloringBookImageKey,
        coloringBookImageColors: newCardData.coloringBookImageColors,
      });
    }
  };

  return (
    <StyledGreetingCardsContainer data-testid={'GreetingCards'}>
      <Switch>
        <Route exact path={paths.GREETINGCARDS}>
          <GreetingCardsLanding user={userFirstName} />
        </Route>
        <Route exact path={paths.DESIGNYOURCARD}>
          <DesignYourCard />
        </Route>
        <Route exact path={paths.GREETINGCARDSINBOX}>
          <GreetingCardsInbox />
        </Route>
        <Route exact path={paths.GREETINGCARDSIMAGESELECTION}>
          <ImageSelection setCard={handleSetCard} />
        </Route>
        <Route exact path={paths.GREETINGCARDSUBMISSION}>
          <GreetingCardsSubmission
            card={card}
            user={userFirstName}
            image={getSvgImageConfig(card?.coloringBookImageKey, card?.coloringBookImageColors, () => {})}
          />
        </Route>
        <Route exact path={paths.GREETINGCARDSMESSAGESELECTION}>
          <MessageSelection card={card} setCard={setCard} />
        </Route>
        <Route exact path={paths.GREETINGCARDSIMAGESELECTION}>
          <ImageSelection setCard={setCard} />
        </Route>
      </Switch>
    </StyledGreetingCardsContainer>
  );
}

export default GreetingCards;
