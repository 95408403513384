import React from 'react';
import PropTypes from 'prop-types';
import { LoadingContainer, Loading, Dot } from './styles';

const ChatLoading = ({ loading, avatar, alt = '' }) => {
  return (
    <>
      {loading ? (
        <LoadingContainer data-testid="LoadingContainer">
          <img alt={alt} style={{ padding: '15px 0', height: '65px' }} src={avatar} />
          <Loading aria-hidden="true">
            <Dot delay={`${-0.48}s`} />
            <Dot delay={`${-0.32}s`} />
            <Dot delay={`${-0.16}s`} />
          </Loading>
        </LoadingContainer>
      ) : null}
    </>
  );
};

ChatLoading.propTypes = {
  loading: PropTypes.bool,
  avatar: PropTypes.string,
  alt: PropTypes.string,
};

export default ChatLoading;
