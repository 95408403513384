import { navigatingChallengingHealthcareSystem } from 'Assets/img/my-tools-icons';

export const navigatingHealthcareEs = {
  key: 'navigating-healthcare-es',
  title: 'El desafiante sistema de atención médica',
  titleImage: navigatingChallengingHealthcareSystem,
  titleImageAlt: '',
  contentId: 37,
  sections: [
    {
      key: 'navigating-healthcare-intro-es',
      description:
        'Al cuidar a alguien con una enfermedad crónica (como un deterioro cognitivo, una enfermedad cardíaca o pulmonar, un trastorno neurológico o cáncer), comprender cómo obtener la atención adecuada puede resultar difícil. En este artículo se proporciona una descripción general de quiénes cubren los costos de atención y las opciones disponibles.',
    },
    {
      key: 'who-pays-es',
      header: '¿Quién paga la atención médica?',
      description:
        'Lamentablemente, no existe una respuesta fácil a esta pregunta. Para ayudar a explicar las diferentes opciones, describiremos algunas categorías. Tú o la persona a tu cuidado pueden encajar en varias categorías, así que asegúrate de leer todas. Si tienes dudas sobre la cobertura o necesitas ayuda para tomar la mejor decisión para alguien a quien cuidas, comunícate con el médico o proveedor de atención médica de la persona. La mayoría de los proveedores cuentan con trabajadores sociales o gerentes de prestaciones médicas que pueden ofrecer información y apoyo para tomar una decisión que se ajuste a tus necesidades.',
    },
    {
      key: 'medicare-es',
      header: 'Medicare',
      description:
        'Las personas mayores de 65 años o discapacitadas califican para Medicare. Medicare se creó para ayudar a pagar la atención preventiva o ayudar a las personas a mejorar. Uno de los aspectos más confusos de Medicare es que normalmente deja de pagar una vez que una persona deja de progresar o mejorar. Por ejemplo, si te caes y te rompes el brazo, Medicare pagará completamente la ambulancia al hospital, las facturas del hospital, las sesiones en un centro de rehabilitación y la terapia de atención a domicilio hasta que dejes de mejorar. Existen límites en los montos que pagan. Solo podrán pagar los primeros 20 días en un centro de rehabilitación, pero pagarán el 80 % de la factura durante los siguientes 80 días. Por lo general, Medicare paga las consultas anuales al médico, los exámenes de detección, las vacunas y la atención especializada, como la de un endocrinólogo para controlar la diabetes.',
    },
    {
      key: 'medicare-supplement-es',
      header: 'Complemento para Medicare o Medicare Advantage',
      description:
        'Entonces, ¿quién paga la atención adicional que Medicare no cubrirá? Es posible que hayas recibido materiales o que hayas visto anuncios de televisión sobre el pago de una tarifa mensual baja para obtener un seguro que complemente o reemplace a Medicare. Existen dos tipos de planes que puedes obtener: un plan que se hace cargo de tu beneficio de Medicare y otro que es un complemento de tu Medicare. Las opciones que suelen ser menos costosas, como el plan Medicare Advantage, se hacen cargo de tu beneficio de Medicare y básicamente hacen las mismas cosas que Medicare, y a menudo ofrecen cobertura adicional o extendida. Por ejemplo, pueden pagar más de 20 días de rehabilitación en un centro diferente al de Medicare tradicional. Esto puede parecer una excelente opción, pero es importante saber que estos planes de Medicare Advantage establecen las tarifas que pagan a tus médicos y otros proveedores de atención médica, y que solo puedes recibir atención de médicos que tienen contrato con este plan. Si estás considerando contratar un plan Medicare Advantage o ya tienes uno, te recomendamos que revises todos los médicos u hospitales que utilizas o que podrías querer usar para asegurarte de que puedas recibir atención con este plan. Los planes complementarios de Medicare a menudo tienen un precio más alto y permiten que Medicare siga siendo tu seguro principal, pero ofrecen atención y cobertura más amplias además de Medicare. Si tienes Medicaid (ver más adelante), también actúa como un complemento de Medicare, ya que cubrirá los costos adicionales que Medicare no cubre.',
    },
    {
      key: 'commercial-insurances-es',
      header: 'Seguros comerciales',
      description:
        'Si tienes menos de 65 años, es posible que debas pagar un plan de seguro tradicional a través de tu empleador o mediante un plan de mercado abierto en tu estado. Al elegir tu compañía de seguros, te recomendamos revisar todos los médicos u hospitales que utilizas o que deseas utilizar para asegurarte de que acepten tu plan. Proporciona toda tu información de ingresos si solicitas un seguro a través del mercado abierto, ya que podrías calificar para recibir ayuda con el pago o una tarifa mensual más baja.',
    },
    {
      key: 'longterm-insurance-es',
      header: 'Seguro de cuidados a largo plazo',
      description:
        'Lamentablemente, ninguna de las opciones anteriores cubre las necesidades de atención a largo plazo. Esto significa que no pagarán la ayuda adicional de un cuidador si la persona a la que cuidas necesita ayuda para caminar, cambiarse la ropa, bañarse u otras actividades de la vida cotidiana. Sin embargo, el seguro de atención a largo plazo, los beneficios para veteranos y Medicaid pueden pagar el apoyo adicional de un cuidador en tu hogar o la atención a largo plazo en un centro. Si contrataste un seguro de atención a largo plazo, puedes utilizar ese beneficio para pagar atención adicional. La mayoría de las pólizas de atención a largo plazo tienen reglas específicas sobre cuándo y cómo se pueden utilizar estos fondos. Comunícate con tu médico, proveedor de atención en el hogar, cuidado en el hogar o el hospital si necesitas ayuda para comprender este beneficio.',
    },
    {
      key: 'veterans-benefits-es',
      header: 'Beneficios para veteranos',
      description:
        'Si la persona a quien cuidas o su cónyuge sirvió en el ejército y tiene un DD2-14, puedes ser elegible para recibir atención y servicios a través de la Administración de Veteranos. Pueden ser elegibles para niveles más altos de atención según el tiempo y el conflicto en el que prestaron su servicio y si sus diagnósticos actuales están relacionados con su tiempo de servicio. Comunícate con la Administración de Veteranos local para obtener más información.',
    },
    {
      key: 'medicaid-es',
      header: 'Medicaid',
      description:
        'Medicaid es un beneficio al que puedes optar principalmente en función de tus ingresos y activos. Hay otras formas de calificar, pero por lo general no afectan a las personas que necesitan atención a largo plazo. Si tu ingreso mensual no cubre todos tus gastos y no tienes ahorros, puedes calificar para Medicaid. Medicaid cubrirá todos los gastos de atención médica y los cuidados adicionales en el hogar o incluso podría pagarte para que seas cuidador. Si la persona a la que estás cuidando tiene Medicaid o puede ser elegible para este beneficio, comunícate con su médico, proveedor de atención médica en el hogar, cuidado en el hogar o el hospital para que te ayuden a comprender este beneficio y para calificar para recibir ayuda adicional.',
    },
  ],
};
