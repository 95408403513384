import styled from '@emotion/styled';
import { Divider, List } from '@mui/material';

export const ThriveWeekContainer = styled(List)`
  flex-flow: column;
  padding-right: 0px;

  .MuiListItem-root {
    padding: 0px;
    width: auto;
    align-items: flex-start;
  }

  .MuiListItemText-root {
    color: black;
    margin-right: auto;
  }

  &.Mui-disabled:last-of-type:before {
    height: 80%;
    border-bottom: 3px solid #e6e6e6;
  }
`;

export const BadgesContainer = styled.div`
  display: flex;
  margin-right: auto;
  column-gap: 5px;
  padding: 8px 0px 0px 0px;
`;

export const WeekHeader = styled.div`
  width: 100%;
`;

export const WeekDivider = styled(Divider)`
  padding-left: 10px;

  .MuiDivider-wrapper {
    padding-left: 0px;
  }

  &::after {
    border-width: medium;
    border-color: #e6e6e6;
  }

  &::before {
    width: 0%;
  }
`;
