import styled from '@emotion/styled';
import { Dialog, DialogTitle, Typography } from '@mui/material';
import { purple, black } from 'Styles/colors';
import { breakpoints } from 'Styles/theme';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 550px;
  }
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr fit-content(75%) 1fr;
  background-color: #fef7e3;
`;

export const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => `
    align-self: center;
    font-weight: bold;
    font-size: 24px;

    ${theme.breakpoints.down(breakpoints.TABLET)} {
      font-size: 18px;
      padding: 0 0 0 14px;
    }
    `,
);

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ContentContainer = styled.div(
  ({ theme }) => `
    height: 100%;
    margin: 0 auto;
    padding: 16px 16px 32px;

    display: flex;
    flex-direction: column;
    color: ${black._87};

    ${theme.breakpoints.up(breakpoints.MOBILE)} {
        width: 65%;
    }
    ${theme.breakpoints.up(breakpoints.DESKTOP)} {
        width: 48%;
    }
    ${theme.breakpoints.down(breakpoints.TABLET)} {
        width: 90%;
    }
    `,
);

export const BulletPointContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Bullet = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${purple._900};
  border-radius: 50%;
  text-align: center;
  color: white;
  margin: 20px 0 0 0;
`;

export const BulletText = styled(Typography)`
  font-size: 16px;
  margin: 12px 0 12px 12px;
`;

export const CtaText = styled(Typography)(
  ({ theme }) => `
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding-top: 12px;
  margin: auto;

  ${theme.breakpoints.up(breakpoints.MOBILE)} {
    width: 90%;
  }
  ${theme.breakpoints.down(breakpoints.MOBILE)} {
    width: 70%;
  }
`,
);

export const ContentText = styled.div(
  ({ theme }) => `
  font-size: 16px;
  text-align: center;
  padding-top: 12px;
  margin: auto;
  margin-bottom: 30px;

  ${theme.breakpoints.up(breakpoints.MOBILE)} {
    width: 90%;
  }
  ${theme.breakpoints.down(breakpoints.MOBILE)} {
    width: 70%;
  }
`,
);

export const HeaderText = styled.div(
  ({ theme }) => `
  font-size: 16px;
  text-align: center;
  padding-top: 12px;
  margin: auto;
  font-weight: 700;

  ${theme.breakpoints.up(breakpoints.MOBILE)} {
    width: 89%;
  }
  ${theme.breakpoints.down(breakpoints.MOBILE)} {
    width: 70%;
  }

  &::after {
    content: '';
    width: 54%;
    height: 1px;
    background-color: ${black._38};
    display: flex;
    margin: 0 auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.15);
    margin-top: 15px;
    margin-bottom: 7px;
  }
`,
);
