import { thingAboutMe } from 'Assets/img/my-tools-icons';

export const thingsILikeEn = {
  key: 'things-i-like-about-me',
  title: 'Things I like about me',
  titleImage: thingAboutMe,
  titleImageAlt: '',
  contentId: 20,
  sections: [
    {
      key: 'things-i-like-intro',
      hasNoSectionMargin: true,
      description:
        'Isn’t it amazing? You are the only YOU there is on planet Earth! You are unique, inside and out. Despite what anyone else may say or imply, you have qualities, gifts, and talents no one else can give to the world – and that’s a fact!\n\nFeeling good about yourself is part of self-love and a healthy, authentic self-image. Everyone has strengths and weaknesses. As much as anybody in the entire universe, you deserve your love and affection. Acknowledging your one-of-a-kind self and the things that set you apart can help keep negative thinking away and strengthen positive feelings every day. To help inspire your inner ‘influencer,’ here’s a fun activity to empower a healthy self-image without fear of being egotistic or self-absorbed.',
    },
    {
      key: 'things-i-like-exercise',
      description:
        'Simply think about the things you like about yourself and jot them down. Then, take some time to acknowledge these truths and sit with the feelings you get from them.\n\nFor example, you could keep a pad of paper next to your bed and every night before you go to bed, write down three things you liked about yourself that day. Then, linger in how those truths make you feel before falling asleep. It can be helpful to take a deep breath in with each one you read to help ‘ground’ the feeling inside. In the morning, read the list again before you get going to acknowledge your truths and empower your self-view. Keep adding new things to your list every day or as often you want.\n\nAny way you want to do this is okay. These are your unique truths; no one else’s opinion matters here. Take your time and let them come out. Look all around at your personality, what you can do, how you treat others, who you are, etc. Some questions to consider are…',
      bulletedItems: [
        { text: 'I feel good about my...' },
        { text: 'The things I’m good at are...' },
        { text: 'Some challenges I’ve overcome before are...' },
        { text: 'Some compliments I’ve received are...' },
        { text: 'It makes me feel good when I...' },
        { text: 'What I like about my personality is...' },
        { text: 'My physical features I like are...' },
        { text: 'I’ve succeeded before at...' },
        { text: 'I’ve helped others feel good by...' },
        { text: 'The characteristics I’m most proud of in myself are...' },
        { text: 'Something I really enjoy is...' },
        { text: 'I like who I am because...' },
        { text: 'How I am uniquely different from others is...' },
        { text: 'I really admire myself for...' },
        { text: 'I’m naturally gifted at...' },
      ],
    },
    {
      key: 'things-i-like-outro',
      description:
        'Becoming aware of your personal truths can help increase feelings of well-being, confidence, and happiness. So, celebrate yourself! This simple activity can help improve positive thinking skills, remind your mind and body to keep looking for uplifting things, and even help others do the same, too!',
    },
  ],
};
