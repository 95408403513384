import { animationType } from 'Core_Games/constants/animationType';

export class Actor extends Phaser.GameObjects.Sprite {
  constructor(scene, x, y, objectData, texture, frame) {
    super(scene, x, y, texture, frame);
    scene.sys.updateList.add(this);
    scene.sys.displayList.add(this);
    this.setScale(1);
    this.setOrigin(0, 0);

    this.Inventory = null;
    this.ObjectData = objectData ?? null;
    this.Sounds = null;

    this.ConfigurableAnimationEvents = [];
    this.atlasPath = `${this.ObjectData.SPRITESHEET_FOLDER}/${this.ObjectData.SPRITESHEET_NAME}`;

    this.isTurned = false;

    this.createConfigurableAnimationEvents();
  }

  createConfigurableAnimationEvents = () => {
    for (let anim in animationType) {
      const currentFrameData = this.getSpriteSheetConfig(anim);
      if (currentFrameData?.ANIMATIONS[anim]?.flipOnYoyo) {
        this.ConfigurableAnimationEvents.push(anim);
      }
    }

    this.on(Phaser.Animations.Events.ANIMATION_UPDATE, function (anim, frame) {
      if (
        anim.yoyo &&
        anim.getLastFrame().frame.name == frame.frame.name &&
        this.ConfigurableAnimationEvents.includes(this.rawAnimName(anim.key))
      ) {
        this.flipX = !this.flipX;
      }
    });
  };

  enablePhysics = (game) => {
    game.physics.world.enable(this);
    this.body.allowGravity = false;
    this.body.collideWorldBounds = true;
  };

  // Equipping/Unequipping Item functions

  equip = (bodyPart, key, item) => {
    if (!this.itemEquipped(bodyPart, key)) {
      const currentIndex = this.Inventory[bodyPart].findIndex((x) => x.NAME == key);
      if (currentIndex >= 0) {
        this.Inventory[bodyPart][currentIndex] = {
          ...this.getItem(bodyPart, key),
          NAME: key,
          OBJECT: item,
        };
      } else {
        this.Inventory[bodyPart].push({
          NAME: key,
          OBJECT: item,
        });
      }
    }
  };

  unequip = (bodyPart) => {
    this.Inventory[bodyPart] = [];
  };

  getItem = (bodyPart, objectKey) => {
    return this.Inventory[bodyPart].find((x) => x.NAME == objectKey);
  };

  itemEquipped = (bodyPart, objectKey = undefined) => {
    const attachedObjects = this.Inventory[bodyPart];
    if (bodyPart && objectKey) {
      return (
        attachedObjects?.length > 0 &&
        attachedObjects.findIndex((x) => x.NAME == objectKey && x.OBJECT != undefined) >= 0
      );
    } else {
      return (
        attachedObjects?.length > 0 &&
        attachedObjects.findIndex((x) => x.NAME != undefined && x.OBJECT != undefined) >= 0
      );
    }
  };

  getAttachmentLocation = (bodyPart, givenFrameNumber = undefined) => {
    const currentFrameData = this.getSpriteSheetConfig(this.rawAnimName())?.ATTACHPOINTS[bodyPart];
    const frameNumber = givenFrameNumber ? givenFrameNumber : this.getFrameNumber(this.frame.name);
    const currentConfigData = currentFrameData[frameNumber];

    return {
      x:
        this.getCenter().x +
        this.width *
          this.scale *
          (this.flipX || (currentConfigData?.flipOverride && !this.flip) ? -1 : 1) *
          (currentConfigData?.x ?? 0.0),
      y: this.getCenter().y + this.height * this.scale * (currentConfigData?.y ?? 0.0),
      depth: currentConfigData?.depth,
    };
  };

  getLastFrameNumberOfAnimation = (animationName) => {
    const frames = this.getSpriteSheetConfig(animationName)?.ANIMATIONS[animationName]?.frames;
    return frames ? frames[frames.length - 1] : undefined;
  };

  //
  updateItemLocations = () => {
    for (let attachPoint in this.Inventory) {
      const currentItemSlot = this.Inventory[attachPoint];

      if (currentItemSlot?.length > 0 && this.rawAnimName()) {
        const currentFrameData = this.getSpriteSheetConfig(this.rawAnimName());

        for (let i = 0; i < currentItemSlot.length; i++) {
          const currentData = currentItemSlot[i];

          this.updateItemLocation(
            attachPoint,
            currentData.NAME,
            currentData.OBJECT,
            currentFrameData.ATTACHPOINTS[attachPoint],
          );
        }
      }
    }
  };

  updateItemLocation = (bodyPart, key, item, frameConfig) => {
    if (this.itemEquipped(bodyPart, key)) {
      const currentConfigData = frameConfig ? frameConfig[this.getFrameNumber(this.frame.name)] : undefined;
      if (frameConfig && item && currentConfigData) {
        const xTransform =
          this.width *
          this.scale *
          (currentConfigData?.flipOverride ? -1 : 1) *
          (this.flipX ? -1 : 1) *
          (currentConfigData?.x ?? 0.0);
        const yTransform = this.height * this.scale * (currentConfigData?.y ?? 0.0);
        const scaleTransform = this.scale * ((currentConfigData?.scale ?? 1.0) / this.originalScale);

        if (currentConfigData.rotation !== undefined) {
          item.rotation = (this.flipX ? -1 : 1) * currentConfigData.rotation;
        }

        if (this.depth > 0 && (item.depth < this.depth || currentConfigData.depth)) {
          item.setDepth(this.depth + (currentConfigData.depth ?? 1));
        }

        item.alpha = this.alpha == 0 || item.ghostEquip ? 0 : 1;
        if (!item.allowExternalUpdate) {
          item.x = this.getCenter().x + xTransform;
          item.y = this.getCenter().y + yTransform;
        }
        item.flipX = currentConfigData?.flipOverride ? !this.flipX : this.flipX;
        item.setScale(scaleTransform, scaleTransform);

        // FOR DEBUGGING ATTACHMENT POINTS

        // const currentAnimations = ['OBJECT_INTERACT2', 'RUNAWAY'];
        // const currentBodyPart = 'NECK';
        // // if (bodyPart == currentBodyPart && this.frame.name !== this.lastAnimationFrame) {
        // if (
        //   bodyPart == currentBodyPart &&
        //   currentAnimations.includes(this.rawAnimName())
        //   // &&
        //   // this.getFrameNumber(this.frame.name) == 131
        // ) {
        //   // eslint-disable-next-line no-console
        //   console.log(currentConfigData);
        //   console.log(`${this.rawAnimName()} ${this.getFrameNumber(this.frame.name)}`);
        //   this.lastAnimationFrame = this.frame.name;
        //   debugger;
        // }
      } else if (item) {
        item.setScale(0.0, 0.0);
      }
    }
  };

  // helper for debugging attachment points
  changeItemX = (currentConfigData, item, percentageX) => {
    item.x =
      this.getCenter().x +
      this.width *
        this.scale *
        (currentConfigData?.flipOverride ? -1 : 1) *
        (this.flipX ? -1 : 1) *
        (percentageX ?? 0.0);
  };
  changeItemY = (currentConfigData, item, percentageY) => {
    item.y = this.getCenter().y + this.height * this.scale * (percentageY ?? 0.0);
  };
  changeItemScale = (currentConfigData, item, proposedScale) => {
    const currentProposed = this.scale * ((proposedScale ?? 1.0) / this.originalScale);
    item.setScale(currentProposed);
  };

  getSpriteSheetConfig = (animationName) => {
    return this.ObjectData?.DATA?.find((x) => x.ANIMATIONS[animationName]);
  };

  // For sprite sheets with palette shifting, custom name, otherwise just the animation name
  getAnimName = (animationName) => {
    return this.spriteName && this.currentSpriteSheet
      ? `${this.getName()}-${animationName}`
      : `${this.getName()}-${animationName}`;
  };

  getName = () => {
    return this.spriteName && this.currentSpriteSheet
      ? `${this.spriteName}-${this.currentSpriteSheet}`
      : this.spriteName;
  };

  getFrameNumber = (frameName) => {
    return +frameName
      .replace(this.atlasPath, '')
      .replace(/\.(png|pvr|pvr\.gz|pvr\.ccz|jpg|bmp|tga|tiff|pkm|WebP|atf|ktx|dds)$/g, '');
  };

  rawAnimName = (animationName = undefined) => {
    return (animationName ?? this.anims.getName()).replace(`${this.getName()}-`, '');
  };

  // check if the animation name is currently what is being used on the sprite
  animationIsActive = (animationName) => {
    return this.rawAnimName() === animationName;
  };

  play = (animationName) => {
    this.anims.play(this.getAnimName(animationName));
  };

  playReverse = (animationName) => {
    this.anims.playReverse(this.getAnimName(animationName));
  };

  scaleActor = (scale) => {
    this.setScale(this.originalScale * scale);
  };

  scaleActorDeviceBased = (scale) => {
    this.setScale(this.getRelativeScale(scale));
  };

  getRelativeActorScale = (scale) => {
    return this.originalScale * scale;
  };

  getRelativeScale = (scale) => {
    if (this.rawAnimName() == '') {
      this.play(animationType.IDLE);
      this.currentWidthRatio =
        this.scene.game.renderer.width / this.width < 1.0
          ? this.scene.game.renderer.width / this.width
          : this.width / this.scene.game.renderer.width;
      this.currentHeightRatio =
        this.scene.game.renderer.height / this.height < 1.0
          ? this.scene.game.renderer.height / this.height
          : this.height / this.scene.game.renderer.height;
      this.currentScaleRatio =
        this.currentHeightRatio < this.currentWidthRatio ? this.currentHeightRatio : this.currentWidthRatio;
    }

    this.curRatio = this.originalScale * scale;
    return this.curRatio / this.currentScaleRatio;
  };
}
