import styled from '@emotion/styled';
import { Checkbox, FormControlLabel, Link as MuiLink } from '@mui/material';
import { black, blue } from 'Styles/colors';

export const Label = styled(FormControlLabel)`
  span {
    font-size: 16px;
    color: ${black._60};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .MuiSvgIcon-root {
    font-size: 22px;
  }
`;

export const CheckboxLink = styled(MuiLink)`
  font-size: 16px;
  text-decoration: underline;
  color: ${blue._800};

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
`;
