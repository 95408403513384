import { Preferences } from '@capacitor/preferences';

export class CapacitorStorage {
  async getItem(name) {
    return Preferences.get({ key: name }).then((returned) => returned.value);
  }

  async removeItem(name) {
    return Preferences.remove({ key: name }).then(() => {});
  }

  async clear() {
    return Preferences.clear().then(() => {});
  }

  async setItem(name, value) {
    return Preferences.set({ key: name, value: value }).then(() => {});
  }
}
