import reduxStore from 'Core_Redux/store';
import { actionTypes, SDOH_RESOURCE } from './actionTypes';
import { UserAccessor } from './UserAccessor';
import { buttonTypes } from './buttonTypes';

const resourceTypes = {
  PHONE: buttonTypes.PHONE,
  PHONENUMBER: buttonTypes.PHONE,
  INAPPURL: buttonTypes.APP,
  APPVIEW: buttonTypes.APP,
  OUTOFAPPURL: buttonTypes.LINK,
  LINK: buttonTypes.LINK,
};

export class NetworkGroupResourceAccessor {
  private static _instance: NetworkGroupResourceAccessor = new NetworkGroupResourceAccessor();
  private userAccessor: UserAccessor;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (NetworkGroupResourceAccessor._instance) {
      return NetworkGroupResourceAccessor._instance;
    }
    this.userAccessor = new UserAccessor();
  }

  private convertResources(resources, actionType) {
    return resources
      .filter((option) => option.event === actionTypes[actionType])
      .map(({ type, value, title_en, title_es }) => ({
        type: resourceTypes[type?.toUpperCase()] ?? resourceTypes.LINK,
        title: this.userAccessor.User.Language === 'es' ? title_es : title_en,
        value: value,
      }));
  }

  public get Resources() {
    const networkGroupState = reduxStore.store.getState().networkGroup.networkGroupConfig;
    const needsResources = this.convertResources(networkGroupState?.followUpResources, SDOH_RESOURCE);

    return {
      Needs: needsResources,
    };
  }
}
