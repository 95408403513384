import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChatBubble from 'Core_Components/Chat/ChatBubble/ChatBubble';
import ButtonRow from 'Core_Components/ButtonRow/ButtonRow';
import ChatImage from 'Core_Components/Chat/ChatImage/ChatImage';
import Wheel from 'Core_Components/Wheel/Wheel';
import { FormattedMessage } from 'react-intl';
import { ScreenReaderLegend } from 'Styles/ScreenReaderOnly';

const ChatItem = (props) => {
  const showRate = 400;
  const [show, setShow] = useState(false);
  const [showButtonRow, setShowButtonRow] = useState(false);

  useEffect(() => {
    let timeout;
    if (props.type === 'buttonRow') {
      timeout = setTimeout(() => setShow(true), showRate);
    } else {
      setShow(true);
    }

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    let timeout;
    if (props.type === 'buttonRow') {
      timeout = setTimeout(() => setShowButtonRow(true), showRate * 2);
    }
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {show && (
        <div data-testid={'ChatItem'}>
          {
            {
              bubble: <ChatBubble {...props} />,
              buttonRow: (
                <fieldset>
                  <ScreenReaderLegend>
                    <FormattedMessage id="chat.chat-responses" />
                  </ScreenReaderLegend>
                  {showButtonRow && <ButtonRow {...props} />}
                </fieldset>
              ),
              image: <ChatImage {...props} />,
              wheel: <Wheel {...props} />,
            }[props.type]
          }
        </div>
      )}
    </>
  );
};

ChatItem.propTypes = {
  type: PropTypes.string,
};

export default ChatItem;
