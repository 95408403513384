import { affirmations } from 'Assets/img/my-tools-icons';

export const affirmationsEs = {
  key: 'affirmations-es',
  title: 'Crea tus propias afirmaciones',
  titleImage: affirmations,
  titleImageAlt: '',
  description:
    'Todos pasamos por altos y bajos. Pero podemos aprovechar más los altos con una simple herramienta: ¡las afirmaciones positivas!',
  contentId: 21,
  sections: [
    {
      key: 'affirmations-intro-es',
      hasNoSectionMargin: true,
      description:
        'Las afirmaciones son frases positivas que transforman los pensamientos negativos en pensamientos útiles. Por ejemplo, el pensamiento negativo “Qué menso soy” se puede cambiar por uno más útil con la afirmación “Soy una persona capaz y aprendo cosas todos los días”.\n\nDado que la mente tiene el poder de crear, las afirmaciones declaran y “afirman” el resultado deseado en lugar de los pensamientos negativos que subyacen. De la misma manera que los atletas profesionales y los artistas usan la visualización para “ver” su éxito, las afirmaciones ayudan a estimular y entrenar a nuestro “influenciador interno”–la mente subconsciente–, para enfocarse en lo que se desea en lugar de lo que no se desea.\n\nEl uso de afirmaciones puede ayudarte a tomar el control de las experiencias que deseas. A continuación encontrarás algunos consejos para crear tus propias afirmaciones.',
    },
    {
      key: 'creating-affirmations-es',
      header: 'Crear afirmaciones',
      description:
        'Las afirmaciones se centran en ti y se enfocan en un área específica. Por lo general, son cortas para que sean más fáciles de recordar, pero pueden ser tan largas como prefieras.\n\nPara comenzar, piensa en cualquier pensamiento negativo que tengas en mente. Puedes decírtelo a ti mismo o escribirlo. A continuación, piensa en el opuesto positivo y di lo que quieres en su lugar. Es útil pensar en cómo te sentirás una vez que lo hayas logrado. Aquí tienes algunos ejemplos.',
      bulletedItems: [
        {
          text: 'Soy una persona valiosa.',
        },
        {
          text: 'Soy lo suficientemente bueno.',
        },
        {
          text: 'Estoy orgulloso de mí mismo.',
        },
        {
          text: '¡Puedo hacerlo!',
        },
        {
          text: 'Soy perseverante.',
        },
        {
          text: 'Hago las cosas lo mejor que puedo.',
        },
        {
          text: 'Soy una persona amada.',
        },
        {
          text: 'Me gusta ser yo mismo.',
        },
        {
          text: 'Cada día que pasa soy mejor.',
        },
        {
          text: 'Me estoy creando a mí mismo y eso es maravilloso.',
        },
        {
          text: 'Me doy permiso para hacer lo mejor para mí.',
        },
        {
          text: 'Me trato a mí mismo y a los demás con respeto.',
        },
        {
          text: 'Aprecio mi habilidad de mantener la calma en todas las situaciones.',
        },
        {
          text: 'Tengo pensamientos positivos sobre mí mismo y los demás.',
        },
      ],
    },
    {
      key: 'using-affirmations-es',
      header: 'Usar la afirmaciones',
      description:
        'Para comenzar, escríbelas y colócalas en un lugar que veas con frecuencia, como el espejo del baño, tu escritorio o la cartera. Luego revísalas con frecuencia para recordar tus metas. Usa diarios, fichas, notas Post-it, pizarras blancas, notas electrónicas, etc.\n\nLas afirmaciones se graban mejor en la cabeza cuando se repiten. Para adquirir el hábito de usarlas,intenta establecer un horario para concentrarte en ellas. Por ejemplo, repítelas antes de levantarte de la cama por la mañana o mientras te cepillas los dientes por la noche. Dilas durante la hora del almuerzo, en el autobús o durante el tiempo de cuidado personal para que se conviertan en una parte habitual de tu vida.\n\n¡Intenta usar afirmaciones para aprovechar el poder del pensamiento positivo y así lograr un impacto en tus experiencias!',
      bulletedItems: [
        {
          text: 'Empieza tus afirmaciones con “Yo” o “A mí”.',
        },
        {
          text: 'Haz todas tus afirmaciones con frases positivas.',
        },
        {
          text: 'Escribe tus afirmaciones en presente.',
        },
        {
          text: 'Escríbelas agradecido de ya tener o ser lo que quieres.',
        },
        {
          text: 'Ve, percibe y siente tus afirmaciones como si ya hubieran ocurrido.',
        },
      ],
    },
  ],
};
