import { animationType } from 'Core_Games/constants/animationType';
import { addCharacterActor } from 'Core_Games/Scripts/characterHelper';
import { Scene } from '../Scene';

export class Menu extends Scene {
  constructor(childName) {
    super(childName);

    this.flipXOverrideAtStart = false;
  }

  init(scene, sceneConfig, sceneData) {
    super.init(scene, sceneConfig, sceneData);

    this.currentSceneConfig = this.sceneConfig[this.sceneData.gameData.gameData.petState.petType][this.sceneType.NAME];

    this.cameras.main.backgroundColor = Phaser.Display.Color.HexStringToColor(
      this.currentSceneConfig.GAMEBACKGROUNDCOLOR ?? '#FFFFFF',
    );

    this.flipXOverrideAtStart = this.currentSceneConfig.FLIPXATSTART ?? false;
    this.startLocationPercentageX = this.currentSceneConfig.STARTX ?? 1.0;
    this.startLocationPercentageY = this.currentSceneConfig.STARTY ?? 0.9;
    this.startXOffset = this.currentSceneConfig.STARTXOFFSET ?? 0.0;
    this.startYOffset = this.currentSceneConfig.STARTYOFFSET ?? 0.0;
    this.isFacingRight = this.currentSceneConfig.RIGHTFACING ?? false;
  }

  preload() {
    super.preload();
  }

  create() {
    super.create();

    let _this = this;
    let _sceneConfig = this.currentSceneConfig;
    let petType = _this.sceneData.gameData.gameData.petState.petType;
    let petAsset = _this.engine.engineAssets.actors.find((x) => x.NAME == petType);

    this.engine.engineAssets.load.on(Phaser.Loader.Events.COMPLETE, function () {
      if (_sceneConfig.ASSETS.BACKGROUND) {
        _this.background = _this.add
          .sprite(
            _this.game.renderer.width / 2,
            _this.game.renderer.height / 2,
            _this.gameData.gameType.ATLAS,
            _sceneConfig.ASSETS.BACKGROUND.DATA,
          )
          .setDisplaySize(_this.game.renderer.width, _this.game.renderer.height);
      }

      // set asset pet scale data
      _this.originalPetScale = petAsset.SCALE;
      _this.petWidth = petAsset.WIDTH;
      _this.petHeight = petAsset.HEIGHT;

      _this.configurablePetScale = _sceneConfig.PETSCALE ?? 1;
      _this.setStartLocation(_this, _this.configurablePetScale);

      // add pet to scene
      _this.pet = addCharacterActor(
        _this,
        _this.startLocationX,
        _this.startLocationY,
        _this.sceneData.gameData.gameData.petState.petType,
      );
      _this.pet.scaleActor(_this.configurablePetScale);
      _this.pet.play(animationType.IDLE);
      _this.pet.flipX = _this.flipXOverrideAtStart;

      _this.setStartLocation(_this, _this.pet.getRelativeScale(_this.configurablePetScale));

      // start & end locations (configurable)
      _this.mainActorLocationX =
        _this.game.renderer.width * _this.startLocationPercentageX +
        (_this.isFacingRight ? -1 : 1) * _this.game.renderer.width * _this.startXOffset -
        _this.petWidth * _this.pet.getRelativeScale(_this.configurablePetScale);
    });
  }

  setStartLocation = (_this, scale) => {
    _this.startLocationX =
      _this.game.renderer.width * _this.startLocationPercentageX +
      (_this.isFacingRight ? -1 : 1) * _this.game.renderer.width * _this.startXOffset -
      _this.petWidth * scale;
    _this.startLocationY =
      _this.game.renderer.height * _this.startLocationPercentageY +
      (_this.isFacingRight ? -1 : 1) * _this.game.renderer.height * _this.startYOffset -
      _this.petHeight * scale;

    _this.endLocationX = _this.game.renderer.width * _this.endLocationPercentageX - _this.petWidth * scale;
    _this.endLocationY = _this.game.renderer.height * _this.endLocationPercentageY - _this.petHeight * scale;
  };

  update() {
    super.update();
  }
}
