import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Button from 'Stories/components/Button';
import { ArrowBackIos } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { Header, Subheader, StyledPhoneInput } from 'Onboard_Components/Account/styles';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import {
  navigateBack,
  selectSignupMetadata,
  selectContact,
  startMatchingProfileEntry,
  selectName,
  selectCredentials,
  completePhoneEntry,
  phoneTypes,
  selectUserId,
} from 'Onboard_Redux/signup';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import ToggleCard from '../../ToggleCard';
import { PHONE } from '../steps';
import {
  useCreateAccountMutation,
  useValidateMutation,
  useUpdateAccountMutation,
} from 'Onboard_Redux/services/identity';
import hasErrorForProperty from '../hasValidationErrorForProperty';
import { selectLanguage, selectUserInfo } from 'Onboard_Redux/user';
import { retryRequest } from 'Core_Helpers/retryRequest';
import { ErrorMessage, Message } from './styles';

function Phone() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const { firstName, lastName } = useOnboardSelector(selectName);
  const { phoneNumber, landlinePhoneNumber, emailAddress, phoneType } = useOnboardSelector(selectContact);
  const { username, password } = useOnboardSelector(selectCredentials);
  const { progress, initialStep } = useOnboardSelector(selectSignupMetadata);
  const { userId: registeredUserId } = useOnboardSelector(selectUserId);
  const language = useOnboardSelector(selectLanguage);
  const user = useOnboardSelector(selectUserInfo);
  const [createAccount, { isLoading: creatingAccount, error: createError }] = useCreateAccountMutation();
  const [updateAccount, { isLoading: updatingAccount, error: updateError }] = useUpdateAccountMutation();
  const [validate, { isLoading: validatingAccount, error: validationError }] = useValidateMutation();
  const [isPending, setIsPending] = useState(false);
  const [customError, setCustomError] = useState();

  const accountCreationPending = isPending && !validationError && !updateError && !createError;
  const isLoading = creatingAccount || validatingAccount || updatingAccount || accountCreationPending;
  const userId = user?.sub ?? registeredUserId;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
    watch,
  } = useForm();

  const selectedPhoneType = watch('phoneType');
  const phoneNumberError =
    hasErrorForProperty(validationError, 'PhoneNumber') ||
    hasErrorForProperty(validationError, 'LandlinePhoneNumber') ||
    errors.phoneNumber
      ? intl.formatMessage({ id: 'error.invalid-input' })
      : null;

  function onBack() {
    if (!isLoading) {
      dispatch(navigateBack());
    }
  }

  async function onContinue({ phoneNumber, phoneType }) {
    setCustomError();

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const landlinePhoneNumber = phoneType === phoneTypes.LANDLINE ? phoneNumber : '';
    const mobilePhoneNumber = phoneType === phoneTypes.LANDLINE ? '' : phoneNumber;

    await validate({
      firstName,
      lastName,
      username,
      password,
      phoneNumber: mobilePhoneNumber,
      landlinePhoneNumber,
      emailAddress,
      locale: language,
      timeZoneId: timeZone,
    })
      .unwrap()
      .catch((errors) => {
        if (hasErrorForProperty(errors, 'UserMayExist')) {
          dispatch(startMatchingProfileEntry({ phoneNumber, phoneType, landlinePhoneNumber }));
          return Promise.reject();
        } else if (hasErrorForProperty(errors, 'PhoneNumber')) {
          return Promise.reject();
        } else if (hasErrorForProperty(errors, 'LandlinePhoneNumber')) {
          return Promise.reject();
        }
      })
      .then(
        // on fulfilled
        async () => {
          dispatch(completePhoneEntry({ phoneNumber: mobilePhoneNumber, landlinePhoneNumber, phoneType }));

          if (phoneType === phoneTypes.MOBILE) {
            if (!username || userId) {
              // if user closes app or logs in after starting registration, username will not be available
              // since we cannot use create account which requires username & password, update account
              await updateAccount({
                phoneNumber,
                landlinePhoneNumber: '',
                emailAddress: '',
                userId,
              });
            } else {
              // if 'Welcome Back' we don't want to create account but still need to get tokens
              setIsPending(true);
              await retryRequest(
                async () =>
                  await createAccount({
                    firstName,
                    lastName,
                    username,
                    password,
                    phoneNumber,
                    landlinePhoneNumber: null,
                    emailAddress: null,
                    locale: language,
                    timeZoneId: timeZone,
                  }).unwrap(),
                3,
              ).catch((err) => {
                if (err) {
                  setIsPending(false);
                  setCustomError(intl.formatMessage({ id: 'error.something-wrong' }));
                }
              });
            }
          }
        },
        // on reject
        () => {
          return;
        },
      );
  }

  const isStartingStep = initialStep == PHONE;

  const formComplete =
    (dirtyFields.phoneNumber && dirtyFields.phoneType) || ((phoneNumber || landlinePhoneNumber) && phoneType);

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      {isStartingStep ? (
        <div>
          <Header sx={{ margin: '0 0 24px' }}>{intl.formatMessage({ id: 'account.sign-up.welcome-back' })}</Header>
          <Subheader>{intl.formatMessage({ id: 'account.sign-up.finish-up-message' })}</Subheader>
        </div>
      ) : (
        <Header>
          {!userId && <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />}
          {intl.formatMessage({ id: 'account.sign-up.verify-phone-number' })}
        </Header>
      )}
      <form onSubmit={handleSubmit(onContinue)}>
        {isStartingStep && (
          <Typography component="h2">{intl.formatMessage({ id: 'account.sign-up.verify-phone-number' })}</Typography>
        )}
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue={phoneNumber ? phoneNumber : landlinePhoneNumber}
          rules={{
            validate: (v) => v?.length === 10,
          }}
          render={({ field }) => (
            <StyledPhoneInput
              {...field}
              id="phoneNumberInput"
              label={intl.formatMessage({ id: 'account.log-in.phone-number' })}
              errorMessage={phoneNumberError}
            />
          )}
        />
        <Typography component="h2" sx={{ padding: '12px 0px 0px 15px' }}>
          {intl.formatMessage({ id: 'account.sign-up.phone-type' })}
        </Typography>
        <Controller
          name="phoneType"
          control={control}
          defaultValue={phoneType}
          rules={{ required: true }}
          render={({ field: { value, onChange, ref } }) => (
            <ToggleButtonGroup
              fullWidth
              sx={{ display: 'flex', gap: '10%', margin: '0 auto', padding: '3px 0px 0px' }}
              size="large"
              exclusive
              onChange={(_, newValue) => newValue && onChange(newValue)}
              ref={ref}
              value={value}
            >
              <ToggleButton
                selected={value === phoneTypes.MOBILE}
                value={phoneTypes.MOBILE}
                fullWidth
                sx={{ margin: '0', padding: '0' }}
              >
                <ToggleCard
                  selected={value === phoneTypes.MOBILE}
                  value={phoneTypes.MOBILE}
                  label={intl.formatMessage({ id: 'account.sign-up.mobile' })}
                >
                  <PhoneIphoneIcon />
                </ToggleCard>
              </ToggleButton>
              <ToggleButton
                selected={value === phoneTypes.LANDLINE}
                value={phoneTypes.LANDLINE}
                fullWidth
                sx={{ margin: '0px', padding: '0px' }}
              >
                <ToggleCard
                  selected={value === phoneTypes.LANDLINE}
                  value={phoneTypes.LANDLINE}
                  label={intl.formatMessage({ id: 'account.sign-up.landline' })}
                >
                  <PhoneOutlinedIcon />
                </ToggleCard>
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        />
        <Button
          id="continue"
          fullWidth
          disabled={!formComplete}
          loading={isLoading}
          type="submit"
          sx={{ margin: '25px 0' }}
        >
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
        <ErrorMessage fontSize={14}>{customError}</ErrorMessage>
        {selectedPhoneType === phoneTypes.MOBILE && (
          <Message fontSize={14}>{intl.formatMessage({ id: 'account.sign-up.phone-disclosure' })}</Message>
        )}
      </form>
    </>
  );
}

export default Phone;
