export const QUESTION = 'Question';
export const ANSWER = 'Answer';
export const STATEMENT = 'Statement';
export const IMAGE = 'Image';
export const VIDEO = 'Video';
export const URL = 'Url';
export const SMARTWHEEL = 'SmartWheel';
export const SMARTBUTTON = 'SmartButton';
export const FREETEXT = 'FreeText';
export const PHONECALL = 'PhoneCall';
export const INAPPCHAT = 'InAppChat';
export const DAILYINSIGHTINTERVENTION = 'DailyInsightIntervention';

export const interactionTypes = {
  1: QUESTION,
  2: ANSWER,
  3: STATEMENT,
  4: IMAGE,
  5: VIDEO,
  6: URL,
  7: SMARTWHEEL,
  8: SMARTBUTTON,
  9: FREETEXT,
  10: PHONECALL,
  11: INAPPCHAT,
  12: DAILYINSIGHTINTERVENTION,
};

export const CHAT = 'Chat';
export const EMOJIWHEEL = 'EmojiWheel';

export const encounterTypes = {
  1: CHAT, // Open
  2: CHAT, // EvidenceBasedScreeningMood
  3: EMOJIWHEEL, // DailyCheckinMood
  4: CHAT, // PatientToCircle
  5: CHAT, // PatientToMember
  7: EMOJIWHEEL, // DailyCheckinWellness
  8: CHAT, // DailyCheckinActivity
  9: CHAT, // GeofenceBreak
  10: CHAT, // MoodFollowUp
  11: CHAT, // WellnessFollowUp
  12: CHAT, // ActivityFollowUp
  13: CHAT, // EvidenceBasedScreeningWellness
  14: CHAT, // EvidenceBasedScreeningActivity
  15: CHAT, // SocialDeterminantsFollowup
  16: CHAT, // PyxPalDeparture
  17: CHAT, // SentimentAcknowledgment
  18: CHAT, // SentimentFollowup
};
