import React from 'react';
import PropTypes from 'prop-types';
import { CharacterContainer, GreetingImage, StyledMessage, CharacterImage, GreetingContainer } from './styles';
import { ScreenReaderText } from 'Styles/ScreenReaderOnly';

const CharacterGreetingHeader = ({
  imageAlignment,
  backgroundColor,
  imageSrc,
  imageAlt,
  isPyxirHeader,
  greeting = {},
  message,
}) => {
  return (
    <CharacterContainer backgroundColor={backgroundColor} role="heading" aria-level={2} isPyxirHeader={isPyxirHeader}>
      <CharacterImage
        role="img"
        imageAlignment={imageAlignment}
        src={imageSrc}
        alt={imageAlt}
        isPyxirHeader={isPyxirHeader}
      />
      <GreetingContainer>
        <GreetingImage
          src={greeting.src}
          role={'presentation'}
          alt=""
          aria-describedby="greetingAlt"
          aria-hidden="true"
          isPyxirHeader={isPyxirHeader}
        />
        <ScreenReaderText id="greetingAlt">{greeting.alt}</ScreenReaderText>
        <StyledMessage imageAlignment={imageAlignment} isPyxirHeader={isPyxirHeader}>
          {message}
        </StyledMessage>
      </GreetingContainer>
    </CharacterContainer>
  );
};

CharacterGreetingHeader.propTypes = {
  message: PropTypes.string,
  imageAlignment: PropTypes.string,
  backgroundColor: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  isPyxirHeader: PropTypes.bool,
  greeting: PropTypes.object.isRequired,
};

export default CharacterGreetingHeader;
