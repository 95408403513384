import styled from '@emotion/styled';
import { Box, IconButton, Typography } from '@mui/material';
import { breakpoints } from 'Styles/theme';

export const StyledBox = styled(Box)(
  ({ theme }) => `
    background-color: #3399ff;
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 100%;
    width: 100%;
    margin-top: 27px;

    ${theme.breakpoints.up(breakpoints.TABLET)} {
      position: absolute;
      height: 610px;
      width: 575px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: inherit;
    }
  `,
);

export const StyledCloseIconContainer = styled(IconButton)`
  text-align: right;
  color: #ffffff;
  padding: 12px;
  margin-left: auto;
  font-size: 38px;
`;

export const StyledBoxContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding-top: 50px;
`;

export const StyledUpdateModalTitle = styled(Typography)`
  font-size: 28px;
  font-weight: 600;
  width: 219px;
  margin-bottom: 32px;
  line-height: inherit;
`;

export const StyledUpdateModalText = styled(Typography)`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: inherit;
`;
