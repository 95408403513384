import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Core_Helpers/getAuthClient';
import urlcat from 'urlcat';

const funFactUrl = 'v5.0/funfact';
const options = {
  revalidateOnFocus: false,
};

const getFunFact = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useFunFact = (swrOptions) => {
  const [isPending, setIsPending] = useState(true);
  const [funFact, setFunFact] = useState();
  const {
    data: funFactResult,
    error,
    mutate,
  } = useSWR(() => urlcat(process.env.API_BASE_URL, funFactUrl), getFunFact, {
    ...options,
    ...swrOptions,
  });

  useEffect(() => {
    if (funFactResult) {
      setFunFact(funFactResult.data);
    }

    setIsPending(!funFactResult && !error);
  }, [funFactResult, error]);

  const dismissed = () => {
    // Clear SWR cache so it doesn't reappear on next load
    return mutate(null, false);
  };

  return { funFact, error, isPending, dismissed };
};

export default useFunFact;
