import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import GreetingPyxir from 'Assets/img/greeting-pyxir.svg';
import { MenuGreetingContainer, Pyxir, Content } from './styles';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectSyncUser } from 'Core_Redux/user';

const MenuGreeting = () => {
  const syncUser = useCoreSelector(selectSyncUser);
  const intl = useIntl();

  return (
    <MenuGreetingContainer>
      <Pyxir alt={intl.formatMessage({ id: 'image.pyxir.greeting' })} src={GreetingPyxir} />
      <Content data-testid="Content">
        {syncUser?.firstName ? (
          <>
            <FormattedMessage id="common.hi" /> {syncUser?.firstName}
          </>
        ) : (
          <FormattedMessage id="common.hi-friend" />
        )}
      </Content>
    </MenuGreetingContainer>
  );
};

export default MenuGreeting;
