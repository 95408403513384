import React from 'react';
import PropTypes from 'prop-types';
import { Header } from './styles';

function HeaderBar({ backgroundColor, children, ...props }) {
  return (
    <Header sx={{ boxShadow: 6 }} backgroundColor={backgroundColor} {...props}>
      {children}
    </Header>
  );
}

HeaderBar.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default HeaderBar;
