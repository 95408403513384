import styled from 'styled-components';
import { StyledButton } from 'Core_Pages/Thrive/featureStyles';
import { black } from 'Styles/colors';
import { Button } from '@mui/material';

export const ThriveActivitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const StyledBackButton = styled(Button)`
  position: absolute;
  left: 20px;
  color: ${black._100};
  padding: 0px;
  left: 0;
`;

export const StyledInlineBlockDiv = styled.div`
  display: inline-block;
`;

export const StyledSmallPlantImage = styled.img`
  width: 45px;
  height: auto;
  margin-right: 20px;
`;

export const StyledImageWithTextContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export const StyledContainerWithLinearGradientBackground = styled.div`
  background: linear-gradient(#ffe594, #ffffff);
  padding: 0 10px 40px 10px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  color: ${black._100};
  align-items: center;
`;

export const StyledButtonWithMargin = styled(StyledButton)`
  margin: 20px 0;
`;

export const ScrollableContent = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
