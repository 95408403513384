export const questions = [
  {
    name: 'c705e5fc-2cf6-426d-a487-58e10143bad6',
    textId: 'screenings.form.likert.youth-phq2.question-1',
    options: [
      {
        name: 'efdb81c3-2265-4813-aad7-c6b04a355cfc',
        text: '0',
        ariaLabel: 'screenings.form.likert.youth-phq2.answer-not-at-all',
      },
      {
        name: '45050fa1-75e8-40f6-bd0e-5aa2cfea759c',
        text: '1',
        ariaLabel: 'screenings.form.likert.youth-phq2.answer-several-days',
      },
      {
        name: 'f6a88f56-52bd-465a-9e6f-fd7f65b88706',
        text: '2',
        ariaLabel: 'screenings.form.likert.youth-phq2.answer-over-half',
      },
      {
        name: '367afad8-bdfa-4f91-b846-9a710d6694ac',
        text: '3',
        ariaLabel: 'screenings.form.likert.youth-phq2.answer-nearly-daily',
      },
    ],
  },
  {
    name: '6e4a9d51-2d0a-4242-b987-d7a38056f6f1',
    textId: 'screenings.form.likert.youth-phq2.question-2',
    options: [
      {
        name: 'a2156787-8617-4dbe-afef-751a68bed3ad',
        text: '0',
        ariaLabel: 'screenings.form.likert.youth-phq2.answer-not-at-all',
      },
      {
        name: 'd2c8f9ec-c35f-4108-90b5-cadb7b1ce819',
        text: '1',
        ariaLabel: 'screenings.form.likert.youth-phq2.answer-several-days',
      },
      {
        name: '047441bb-57cb-4638-88c0-398d650f9062',
        text: '2',
        ariaLabel: 'screenings.form.likert.youth-phq2.answer-over-half',
      },
      {
        name: '9188cf94-1b11-4e37-927d-e8fd752d80f2',
        text: '3',
        ariaLabel: 'screenings.form.likert.youth-phq2.answer-nearly-daily',
      },
    ],
  },
];
