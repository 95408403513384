import { useState, useRef, useEffect } from 'react';
import { App as CapacitorApp } from '@capacitor/app';

const useGameTimer = (pauseGame, resumeGame) => {
  const [timer, setTimer] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const countRef = useRef(null);

  useEffect(() => {
    CapacitorApp.addListener('appStateChange', async ({ isActive }) => {
      if (isActive) {
        handleResume();
      } else {
        handlePause();
      }
    });

    return () => {
      clearInterval(countRef.current);
    };
  }, []);

  const handleStart = () => {
    setIsTimerActive(true);
    setIsPaused(false);
    addInterval();
  };

  const handlePause = () => {
    setIsPaused(true);
    clearInterval(countRef.current);
    if (pauseGame) {
      pauseGame();
    }
  };

  const handleResume = () => {
    setIsPaused(false);
    addInterval();
    if (resumeGame) {
      resumeGame();
    }
  };

  const addInterval = () => {
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handleReset = () => {
    clearInterval(countRef.current);
    setIsTimerActive(false);
    setIsPaused(false);
    setTimer(0);
  };

  return { timer, isTimerActive, isPaused, handleStart, handlePause, handleResume, handleReset };
};

export default useGameTimer;
