import { CapacitorStorage } from './CapacitorStorage';
import { Capacitor } from '@capacitor/core';
import getAuthClient from 'Onboard_Helpers/getAuthClient';
import urlcat from 'urlcat';
import { appInsights } from 'Onboard_Helpers/AppInsights';
import StorageService, { TOKEN_RESPONSE_KEY, USERINFO_RESPONSE_KEY } from './StorageService';
import identityApi from 'Onboard_Redux/services/identity';
import { logout } from 'Onboard_Redux/authentication';

const APP_OPENED_KEY = 'app_opened';

const AuthorizationService = (() => {
  let instance = null;
  let storage = null;
  //set store outside of functions for future use of redux outside of createInstance()
  let configuredStore = null;
  let isTokenRefreshPending = false;
  let tokenPromise = null;

  async function createInstance(store) {
    appInsights.trackTrace({ message: 'Diagnostic: Initializing Authorization Service' });
    if (instance) return instance;

    storage = await StorageService.getInstance();
    configuredStore = store;

    if (Capacitor.isNativePlatform()) {
      if (Capacitor.getPlatform() === 'ios') {
        const hasBeenOpened = await checkHasBeenOpened();
        if (!hasBeenOpened) {
          await removeAllData();
          // Had to reload the window in order for it to continue beyond the splash screen
          // There is probably a good reason why its getting stuck
          window.location.reload();
        }
      }
    }

    async function tryRefreshTokens() {
      if (isTokenRefreshPending) {
        return tokenPromise;
      }

      isTokenRefreshPending = true;

      // eslint-disable-next-line no-async-promise-executor
      tokenPromise = new Promise(async (resolve, reject) => {
        let parsedTokens;
        const tokens = await storage.getItem(TOKEN_RESPONSE_KEY);

        if (tokens) {
          parsedTokens = JSON.parse(tokens);
        }

        configuredStore
          .dispatch(identityApi.endpoints.refreshToken.initiate({ refreshToken: parsedTokens?.refreshToken }))
          .unwrap()
          .then(async (res) => {
            //Ensure that tokens are written to local storage prior to resolving
            const tokenValues = JSON.stringify(res);
            await storage.setItem(TOKEN_RESPONSE_KEY, tokenValues);

            isTokenRefreshPending = false;
            resolve();
            return;
          })
          .catch(({ error }) => {
            appInsights.trackException({
              exception: error,
              properties: { errorMessage: error?.message },
            });
            //Token refresh failed, user needs to re-authenticate
            configuredStore.dispatch(logout());
            reject();
            isTokenRefreshPending = false;
          });
      });

      return tokenPromise;
    }

    async function checkHasBeenOpened() {
      const capStorage = new CapacitorStorage();
      const result = await capStorage.getItem(APP_OPENED_KEY);

      // storage returns as strings
      const hasBeenOpened = result === 'true';
      if (!hasBeenOpened) {
        await capStorage.setItem(APP_OPENED_KEY, 'true');
      }
      return hasBeenOpened;
    }

    async function completeLogout() {
      appInsights.trackTrace({ message: 'Diagnostic: Completing Logout' });
      await storage.clear();
    }

    async function getTokens() {
      const storageTokens = await storage.getItem(TOKEN_RESPONSE_KEY);
      return JSON.parse(storageTokens);
    }

    async function getUserInfo() {
      const authClient = await getAuthClient();
      const response = await authClient.post(urlcat(process.env.AUTHORITY, process.env.USER_INFO_URI));
      storage.setItem(USERINFO_RESPONSE_KEY, JSON.stringify(response.data));
      return response.data;
    }

    return {
      tryRefreshTokens,
      completeLogout,
      getTokens,
      getUserInfo,
    };
  }

  async function removeAllData() {
    appInsights.trackTrace({ message: 'Diagnostic: Removing all storage data' });
    storage.clear();
  }

  return {
    getInstance: async (store = null) => {
      if (instance) {
        return instance;
      }

      try {
        instance = await createInstance(store);
        return instance;
      } catch (error) {
        appInsights.trackException({
          exception: 'Exception: Unable to create auth service instance',
          properties: { errorMessage: error?.message },
        });
      }
    },
  };
})();

export default AuthorizationService;
