import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { QuestionWrapper, AnswerWrapper } from 'Stories/components/Form/styles';
import { Typography } from '@mui/material';
import { StyledNumberInputQuestion } from './numberTextStyles';
import Button from 'Stories/components/Button';
import { Controller } from 'react-hook-form';
import { StyledTextField } from 'Stories/components/Form/Answer/Textbox/styles';

const NumberText = ({ controllerProps, control, name, text, onChange, ...props }) => {
  const intl = useIntl();
  const labeledBy = intl.formatMessage({ id: 'screenings.answer.well-being.number-of-days-zero-to-thirty' });
  const [selected, setSelected] = useState(null);
  const [isValid, setIsValid] = useState(true);

  const continueClicked = () => {
    onChange(selected);
    setSelected('');
  };

  useEffect(() => {
    if (selected < 0 || selected > 30 || selected === null) {
      setIsValid(false);
    } else setIsValid(true);
  }, [selected]);

  return (
    <StyledNumberInputQuestion>
      <QuestionWrapper className="FormQuestion-root">
        <Typography id={`${name}-question`} className="FormQuestion-question">
          {text}
        </Typography>
        <AnswerWrapper id={`${name}-question-answers`} className="FormAnswer-root">
          <Controller
            name={name}
            control={control}
            {...controllerProps}
            render={(field) => {
              return (
                <StyledTextField
                  {...field}
                  id={`${name}-answer`}
                  style={{ width: '100%' }}
                  InputProps={{
                    inputProps: { maxLength: 2, inputMode: 'numeric', pattern: '[0-9]*' },
                  }}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (value || value === 0) setSelected(value);
                    else setSelected(null);
                  }}
                  variant="filled"
                  label={labeledBy}
                  aria-labelledby={labeledBy}
                  className="FormQuestion-answer"
                  {...props}
                />
              );
            }}
          />
        </AnswerWrapper>
      </QuestionWrapper>
      <Button style={{ width: '100%' }} onClick={continueClicked} disabled={!isValid}>
        {intl.formatMessage({ id: 'common.continue' })}
      </Button>
    </StyledNumberInputQuestion>
  );
};

NumberText.propTypes = {
  controllerProps: PropTypes.object,
  control: PropTypes.object,
  name: PropTypes.string,
  text: PropTypes.string,
  answerType: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  filteredOptions: PropTypes.array,
};

export default NumberText;
