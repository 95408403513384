import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
  font-size: 14px;
  :last-of-type {
    margin-bottom: 15px;
  }
`;

export const StyledLabel = styled.span`
  font-weight: 700;
  width: 77px;
  wrap: no-wrap;
`;

export const StyledInputContainer = styled.div`
  width: 100%;
  padding-left: 4px;
  border-bottom: 1px solid black;
  display: flex;
`;

export const StyledPromptText = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const StyledInput = styled.input`
  width: 100%;
  border: none;
  background: inherit;
`;
