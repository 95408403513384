import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import { useForm } from 'react-hook-form';
import { selectSignupMetadata } from 'Onboard_Redux/signup';
import { Tab, CircularProgress } from '@mui/material';
import Button from 'Stories/components/Button';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import ControlledAgreementCheckbox from './ControlledAgreementCheckbox';
import { TabContent, Tabs, CircularProgressContainer } from './styles';
import { TabContext } from '@mui/lab';
import { selectAgreements, sendAgreementAcknowledgement } from 'Onboard_Redux/agreements';

const CenteredCircularProgress = () => (
  <CircularProgressContainer>
    <CircularProgress />
  </CircularProgressContainer>
);

function Agreements() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const [tab, setTab] = useState('terms');
  const { loading, progress } = useOnboardSelector(selectSignupMetadata);
  const { control, handleSubmit, watch } = useForm();
  const watchFields = watch();
  const formComplete = watchFields.termsAndConditions && watchFields.privacyPolicy;
  const unreadAgreements = useOnboardSelector(selectAgreements);
  const [privacyAgreement, setPrivacyAgreement] = useState();
  const [termsAgreement, setTermsAgreement] = useState();
  const [acceptedAgreements, setAcceptedAgreements] = useState([]);

  const handleChange = (_, newTab) => setTab(newTab);

  const agreementType = {
    privacy: 5,
    terms: 6,
  };

  useEffect(() => {
    if (unreadAgreements) {
      setPrivacyAgreement(
        unreadAgreements?.agreementList?.find((agreement) => agreement?.agreementTypeId === agreementType.privacy),
      );
      setTermsAgreement(
        unreadAgreements?.agreementList?.find((agreement) => agreement?.agreementTypeId === agreementType.terms),
      );
    }
  }, [unreadAgreements]);

  useEffect(() => {
    if (acceptedAgreements.length > 0) {
      dispatch(sendAgreementAcknowledgement({ acceptedAgreements }));
    }
  }, [acceptedAgreements]);

  function acceptAgreements() {
    let agreementsToAcknowledge = [];

    if (privacyAgreement) {
      agreementsToAcknowledge.push({
        'profile-id': unreadAgreements.profileId,
        'agreement-type': privacyAgreement.agreementTypeId,
        'agreement-id': privacyAgreement.id,
        initials: null,
      });
    }

    if (termsAgreement) {
      agreementsToAcknowledge.push({
        'profile-id': unreadAgreements.profileId,
        'agreement-type': termsAgreement.agreementTypeId,
        'agreement-id': termsAgreement.id,
        initials: null,
      });
    }

    setAcceptedAgreements(agreementsToAcknowledge);
  }

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <TabContext value={tab}>
        <Tabs value={tab} onChange={handleChange} textColor="secondary" indicatorColor="secondary">
          <Tab value="terms" label={intl.formatMessage({ id: 'agreements.terms-and-conditions_title' })} />
          <Tab value="privacy" label={intl.formatMessage({ id: 'agreements.privacy-policy_title' })} />
        </Tabs>
        <TabContent value="terms">
          {termsAgreement?.description ? termsAgreement?.description : <CenteredCircularProgress />}
        </TabContent>
        <TabContent value="privacy">
          {privacyAgreement?.description ? privacyAgreement?.description : <CenteredCircularProgress />}
        </TabContent>
      </TabContext>
      <form onSubmit={handleSubmit(acceptAgreements)}>
        <ControlledAgreementCheckbox
          id="termsAndConditionsInput"
          linkTo={termsAgreement?.contentHref ?? ''}
          control={control}
          fieldName="termsAndConditions"
          linkPrefix={intl.formatMessage({ id: 'agreements.agree-to.terms-and-conditions' })}
          linkLabel={intl.formatMessage({ id: 'agreements.terms-and-conditions' })}
        />
        <ControlledAgreementCheckbox
          id="privacyPolicyInput"
          linkTo={privacyAgreement?.contentHref ?? ''}
          control={control}
          fieldName="privacyPolicy"
          linkPrefix={intl.formatMessage({ id: 'agreements.agree-to.privacy-policy' })}
          linkLabel={intl.formatMessage({ id: 'agreements.privacy-policy' })}
        />
        <Button fullWidth loading={loading} disabled={!formComplete} type="submit" sx={{ marginTop: '8px' }}>
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
    </>
  );
}

export default Agreements;
