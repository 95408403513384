import { relaxationActivities } from 'Assets/img/my-tools-icons';

export const relaxationActivitiesEs = {
  key: 'relaxation-activities-es',
  title: 'Actividades de relajación',
  titleImage: relaxationActivities,
  titleImageAlt: '',
  contentId: 38,
  sections: [
    {
      key: 'relaxation-activities-intro-es',
      description:
        'Cuidar a los demás requiere paciencia y entrega. Puede sentirse como si tu energía se agotara. Entonces, ¿cómo te puedes recargar? El primer paso es saber qué actividades te ayudan a relajarte. Quizás sea con un baño largo y placentero, o al leer un libro. Sea lo que sea, dedícale un tiempo. Date permiso para tomarte un tiempo para ti. ¡Te lo mereces! Incluso si solo tienes unos minutos al día y te sientes muy estresado/a, hemos creado algunos ejercicios de relajación que puedes hacer en tu tiempo libre. Están destinados a ayudarte a recargar tu energía rápidamente o pueden usarse para comenzar o finalizar el día. Usa estas herramientas cuando lo desees. Están diseñados para tomar tan poco o tanto tiempo como tengas disponible.',
    },
    {
      key: 'belly-breathing-es',
      header: 'Respiración abdominal profunda (aproximadamente de 3 a 5 minutos)',
      description:
        'Es posible que hayas escuchado que cuando te encuentras en un momento de estrés simplemente debes "respirar profundamente". A veces hace falta más de una respiración profunda. Aquí tienes un ejercicio sencillo que puedes realizar en menos de cinco minutos. Lee primero los tres pasos del ejercicio de respiración abdominal profunda que aparecen a continuación y luego pruébalo tú mismo, haciendo una pausa después de cada paso. La respiración abdominal profunda se puede repetir a lo largo del día, en cualquier momento que sientas que necesitas un descanso.',
      itemsHeader: 'Cuéntame más',
      items: [
        { key: 'step-one-es', description: '<b>Paso 1</b>' },
        {
          text: 'Siéntate en una habitación donde puedas estar a solas. Si estás sentado/a en una silla, asegúrate de poder tener los dos pies en el suelo cómodamente. Si estás sentado/a en el suelo, asegúrate de estar en una posición cómoda, por ejemplo, siéntate erguido/a con las piernas cruzadas.',
        },
        { text: 'Coloca la mano derecha en la parte superior del pecho y la mano izquierda sobre el estómago.' },
        { text: 'Cierra los ojos.' },
        {
          text: 'Mantén las manos sobre el pecho el tiempo suficiente para notar tu ritmo de respiración actual y sentir los latidos de tu corazón.',
        },
        {
          text: 'Observa cómo te sientes. Reconoce tus pensamientos y cómo te sientes. Si te sientes estresado/a, dale lugar a ese pensamiento e incluso permítete decirlo en voz alta.',
        },
        { key: 'step-two-es', description: '<b>Paso 2</b>' },
        {
          text: 'Respira profundamente por la nariz para llenar el abdomen de aire. Cuando no puedas llenar más el abdomen, exhala por la boca, como si estuvieras inflando un globo.',
        },
        {
          text: 'Observa si tu mano derecha o izquierda se mueven cuando inhalas y exhalas el aire abdominal. Si tu mano derecha se mueve, tu respiración abdominal es demasiado superficial. Busca que solo se mueva tu mano izquierda mientras tu abdomen inhala y exhala.',
        },
        {
          text: 'Observa tus hombros. ¿Se mueven hacia arriba cuando respiras? Trabaja para relajar los hombros y no dejar que se muevan mientras respiras. Deja que tu abdomen haga todo el trabajo.',
        },
        { key: 'step-three-es', description: '<b>Paso 3</b>' },
        { text: 'Haz al menos tres respiraciones profundas con el abdomen por tu cuenta.' },
        { text: 'Observa cualquier cambio en tu respiración o cómo te sientes.' },
        {
          text: 'Tómate un tiempo ahora para observar cómo te sientes. ¿Te sientes igual o diferente respecto a cuando entraste a la habitación?',
        },
      ],
    },
    {
      key: 'mindfulness-exercise-es',
      header: 'Ejercicio sencillo de atención plena: una ducha de lluvia fresca (aproximadamente 5 a 7 minutos)',
      description:
        'Lee a continuación los cuatro pasos de este ejercicio de atención plena sobre una ducha de lluvia fresca. A continuación, pruébalo tú mismo/a y haz una pausa después de cada paso. Este ejercicio se puede repetir a lo largo del día, en cualquier momento que sientas que necesitas un descanso.',
      itemsHeader: 'Cuéntame más',
      items: [
        { key: 'step-one-es', description: '<b>Paso 1</b>' },
        {
          text: 'Encuentra un lugar tranquilo para sentarte o recostarte. Si estás sentado/a en una silla, asegúrate de poder tener los dos pies en el suelo cómodamente y de sentarte erguido/a. Si estás sentado/a en el suelo, asegúrate de estar en una posición cómoda, por ejemplo, siéntate erguido/a con las piernas cruzadas. Si estás acostado/a, asegúrate de estar boca arriba con las piernas estiradas y los brazos cómodamente a los lados.',
        },
        {
          text: 'Estira los brazos con las palmas hacia arriba. Si estás sentado/a, coloca el dorso de las manos cómodamente sobre las piernas cerca de las rodillas.',
        },
        { key: 'step-two-es', description: '<b>Paso 2</b>' },
        { text: 'Cierra los ojos. Mira la parte posterior de tus párpados. Debe verse negro y transmitir calma.' },
        {
          text: 'Observa los latidos de tu corazón. Nota si tienes algún pensamiento que te venga a la mente. Es posible que a lo largo de este ejercicio se te ocurran pensamientos, como tareas que necesitas realizar o una discusión que acabas de tener. Esto es normal. Reconoce el pensamiento y luego permítete volver a centrarte en la parte posterior de los párpados.',
        },
        {
          text: 'Si te sientes estresado/a, tu respiración es superficial o te falta el aire, quédate así durante un par de minutos o hasta que notes que tu respiración empieza a volverse más lenta, más tranquila.',
        },
        { key: 'step-three-es', description: '<b>Paso 3</b>' },
        { text: 'Visualízate de pie y mirando al cielo.' },
        {
          text: 'Piensa en el cielo abriéndose. Comienza una lluvia suave y fresca, una lluvia que se siente reconfortante y relajante al golpear la parte superior de tu cabeza.',
        },
        {
          text: 'Ahora piensa en todas las emociones o los pensamientos preocupantes o estresantes que te están frenando en este momento. Pueden ser cosas como tu abrumadora lista de tareas pendientes o pensamientos negativos de duda sobre ti mismo/a. Reconócelos.',
        },
        { key: 'step-four-es', description: '<b>Paso 4</b>' },
        {
          text: 'Ahora es el momento de dejar ir esos pensamientos. No hay necesidad de dedicarles más tiempo o espacio.',
        },
        {
          text: 'El objetivo de este ejercicio es limpiar cualquier pensamiento no deseado o poco útil y abrir espacio para pensamientos más positivos y de apoyo.',
        },
        { text: 'Visualiza la lluvia cayendo sobre tu cara y arrastrando todo lo que no deseas.' },
        {
          text: 'Sigue lentamente esos pensamientos mientras se deslizan por tus brazos, se alejan de tus dedos y se van de ti. Sigue cualquier resto de sensación bajando por tus piernas y las puntas de los dedos de los pies; se fueron de tu cuerpo.',
        },
        {
          key: 'exit-stage-left-es',
          description:
            'A veces es posible que te enfrentes a pensamientos o cosas que son más difíciles de soltar. Eso está bien. Repite el ejercicio y, más lentamente, sigue las gotas de lluvia que caen y se alejan de ti. Es posible que sea necesario realizar este ejercicio varias veces para deshacerte de un pensamiento negativo.',
        },
      ],
    },
  ],
};
