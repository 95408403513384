import React from 'react';
import { createRoot } from 'react-dom/client';
import AppProvider from './AppProvider';
import { register, unregister } from './serviceWorker';
import 'regenerator-runtime/runtime.js';
import { isEnvironment, LOCAL } from 'Core_Helpers/isEnvironment';
import { configureLiveUpdate } from 'Core_Helpers/liveUpdate';
import { AppInitializationOrchestrator } from './services/AppInitializationOrchestrator';
import coreStore from 'Core_Redux/store.js';
import { default as onboardGetStore } from 'Onboard_Redux/store.js';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { coreContext, onboardContext } from 'Contexts/StoreContext';
import { setupIonicReact } from '@ionic/react';
import { NotificationPromptStore } from './stores/NotificationPromptStore';
import { UpdateAppPromptModuleStore } from '../shared/stores/UpdateAppPromptModuleStore';

import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@capacitor/push-notifications';
import { registerNotificationActions } from './services/pushEventActions';

let configuredCoreStore;
let configuredOnboardStore;
let appInitOrchestrator;

async function initialize() {
  await initializeServices();
  await initializeUserInformation();
}

async function initializeServices() {
  //Redux store retrieval should only occur at this level of the app
  //if this is required in other portions of the application, reference this instance.
  configuredCoreStore = coreStore;
  configuredOnboardStore = onboardGetStore();
  appInitOrchestrator = new AppInitializationOrchestrator(configuredCoreStore, configuredOnboardStore);
  //If we begin to venture into more experiences, we may want to consider instantiating these conditionally.
  await appInitOrchestrator.orchestrateCoreExperienceInitAsync();
  await appInitOrchestrator.orchestrateOnboardExperienceInitAsync();
}

async function initializeUserInformation() {
  //This should be the section where we explore user information hydration outside of the basic items in this iteration.
  await appInitOrchestrator.orchestrateUserExperienceInitAsync();
}

function startApp() {
  const renderApp = () => {
    setupIonicReact({});
    document.getElementById('splash-image').remove();

    const container = document.getElementById('root');
    const root = createRoot(container);

    root.render(
      <Provider store={configuredCoreStore.store} context={coreContext}>
        <Provider store={configuredOnboardStore.store} context={onboardContext}>
          <PersistGate loading={null} persistor={coreStore.persistor}>
            <AppProvider coreStore={configuredCoreStore} onboardStore={configuredOnboardStore} />
          </PersistGate>
        </Provider>
      </Provider>,
    );
  };

  async function setupBinary() {
    await configureLiveUpdate();
    await UpdateAppPromptModuleStore.Instance().checkForLiveUpdate();
    await NotificationPromptStore.Instance().promptNativeNotificationAsync();
    registerNotificationActions();
    renderApp();
  }

  if (window.cordova) {
    //deviceready is an event that is triggered when all plugins have been instantiated
    //it is IMPORTANT that we do not set up any binary calls before this has been triggered
    //https://cordova.apache.org/docs/en/4.0.0/cordova/events/events.deviceready.html
    document.addEventListener('deviceready', setupBinary, false);
  } else {
    renderApp();
  }
}

export function initializeApp() {
  initialize().then(() => startApp());
}

// Only register when not on local to prevent caching while you work
!isEnvironment(LOCAL) ? register() : unregister();
