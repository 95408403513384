import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { RadioWrapper } from './styles';
import PropTypes from 'prop-types';
import { RadioGroup, FormControlLabel, Radio as MuiRadio } from '@mui/material';

const Radio = forwardRef(({ id, filteredOptions, options, updateSelection, props }, ref) => {
  const intl = useIntl();
  const radioOptions = filteredOptions ?? options;
  return (
    <RadioWrapper>
      <RadioGroup ref={ref} id={`${id}-group`} {...props}>
        {radioOptions.map((answer) => {
          const label = intl.formatMessage({ id: `${answer.textId}` });
          return (
            <FormControlLabel
              key={`id-${answer.name}`}
              value={answer.name}
              onChange={(e) => updateSelection(e.target.value)}
              control={<MuiRadio />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </RadioWrapper>
  );
});

Radio.propTypes = {
  id: PropTypes.string,
  filteredOptions: PropTypes.array,
  options: PropTypes.array,
  props: PropTypes.object,
  ref: PropTypes.object,
  updateSelection: PropTypes.func,
};

Radio.displayName = `Radio`;

export default Radio;
