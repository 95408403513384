import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  width: 100%;
  height: 100%;
  color: black;
  font-size: 0.75em;

  @media screen and (max-width: 670px) {
    left: 0;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  border-bottom: black 1px solid;

  @media screen and (max-width: 500px) {
    margin: 0;
  }

  @media screen and (max-width: 300px) {
    padding-left: 0;
    margin: 0;
  }
`;

export const DropdownInteractiveElement = styled.div`
  border: black;
  height: 100%;
  width: 100%;

  ${({ className }) => className === 'DropdownInteractiveElementOpen' && `background-color: #F2F2F2`}
`;

export const DropdownLabel = styled.label`
  color: purple;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 5px;
  padding: 4px;
  margin-bottom: 8px;
  display: inline-block;
`;

export const DropdownList = styled.ul`
  list-style: none;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: white;

  @media screen and (max-width: 670px) {
    position: absolute;
    top: 0;
    transform: translateY(-100%);
  }
`;

export const DropdownListItem = styled.li`
  width: 100%;
  height: 100%;
  color: black;

  .listItemButton {
    display: flex;
    justify-content: space-evenly;
    padding: 10px 15px 10px 15px;
    font-size: 1.25em;
    width: 100%;
    background-color: white;

    :hover {
      background-color: #f2f2f2;
    }
  }
`;
