import styled from '@emotion/styled';
import { ScreeningDialog } from '../styles';
import { breakpoints } from 'Styles/theme';
import { leaf, mountainBackground } from 'Assets/img/screening-icons';
import CloseIcon from '@mui/icons-material/Close';
import { Card, IconButton, Typography } from '@mui/material';
import Button from 'Stories/components/Button/Button';
import { teal } from 'Styles/colors';
import ResourceButton from './components/ResultsResources/ResourceButton/ResourceButton';

export const StyledResultsDialog = styled(ScreeningDialog)`
  .MuiDialog-scrollPaper {
    background-color: black;
    background-image: linear-gradient(rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.68)),
      url(${(props) => (props.hasMountainBackground ? mountainBackground : leaf)}), linear-gradient(black, black);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    height: 100vh;
    width: 100vw;
  }

  .MuiDialog-paper {
    background-color: transparent;
    border: none;
  }
`;

export const StyledCloseIconContainer = styled(IconButton)`
  text-align: right;
  padding: 0;
  margin-left: auto;
  color: white;
  font-size: 42px;
  float: right;
  align-items: start;
  padding: 5px;
  height: min-content;
  float: right;
  margin-right: -3%;
`;

export const ResultContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  width: 95%;

  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  ${theme.breakpoints.down(breakpoints.TABLET)} {
    height: 100%;
  }

  ${theme.breakpoints.up(breakpoints.TABLET)} {
    max-width: 327px;
    height: 100%;
    margin-top: 5%
  }
`,
);

export const StyledContinueButton = styled(Button)(
  ({ theme }) => `
  background-color: ${teal._300};
  color: white;
  min-height: 48px;

  ${theme.breakpoints.down(breakpoints.TABLET)} {
    margin-top: auto;
  }
`,
);

export const ContinueButtonWrapper = styled.div(
  ({ theme }) => `
  ${theme.breakpoints.down(breakpoints.TABLET)} {
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 40px;
  }
`,
);

export const ResourcesCard = styled(Card)`
  background-color: #006850;
  width: 95%;
  align-self: center;
  padding: 20px 10px 35px 10px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 20px;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 30px;
  overflow: visible;

  > .ResourceContainer:only-of-type {
    margin: 0 auto;
  }
`;

export const ResourceContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 39%;
  text-align: center;
  row-gap: 15px;
  margin: 0px 5%;

  .MuiButtonBase-root {
    background-color: ${teal._300};
    color: white;
    min-height: 48px;
  }
`;

export const Pyxir = styled.img`
  height: 85px;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  margin: 0 auto;
  width: 100%;
  margin: 30px 0;
`;

export const StyledResultsCloseIcon = styled(CloseIcon)`
  font-size: 50px;
  color: white;
`;

export const DialogHeader = styled.div`
  h1 {
    display: flex;
    width: 85%;
    margin: 0 auto;
    text-align: center;
    white-space: pre-wrap;
  }
`;

export const StyledDescription = styled(Typography)`
  width: 99%;
  display: flex;
  margin: 0 auto;
  text-align: center;
`;

export const StyledDescriptionButton = styled(ResourceButton)`
  width: 99%;
  display: flex;
  margin: 0 auto;
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  padding: 0 0 20px 0;
`;

export const StyledSegmentShapeWrapper = styled.div`
  margin-top: 20px;
  .SegmentContainer {
    width: 148%;
    margin-left: -24%;
  }

  .SegmentShapeClipping {
    mask-size: 61%;
  }

  .SegmentShape {
    width: 11.111111%;

    &.segment-5 {
      width: 16.111111%;
    }
  }
`;

export const SymptomResult = styled.div`
  display: flex;
  color: white;
  font-size: 16px;
  font-weight: 700;
  column-gap: 15px;
  width: 70%;
  row-gap: 10px;
`;

export const SymptomContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  align-self: center;
  flex-flow: wrap;
  justify-content: space-between;
  row-gap: 10px;
  margin-top: 30px;
  margin-bottom: 20px;

  .MuiChip-root {
    width: 30%;
    border-radius: 5px;
    width: 70px;
    font-weight: 700;
    font-size: 16px;
  }

  .MuiChip-colorSuccess {
    background-color: #079514;
    border-radius: 5px;
  }

  .MuiChip-colorError {
    background-color: #b64b45;
    border-radius: 5px;
  }
`;
