import React from 'react';
import MessagingCard from './TalkToSomeoneCards/MessagingCard';
import CallCard from './TalkToSomeoneCards/CallCard';
import PyxirChatCard from './TalkToSomeoneCards/PyxirChatCard';
import { UserResourcesStore } from 'Core_Services/userResources/UserResourcesStore';
import { Grid } from '@mui/material';
import useMediaType from 'Core_Helpers/useMediaType';
import { breakpoints } from 'Styles/theme';

const TalkToSomeoneItems = () => {
  const mediaType = useMediaType();
  const isTabletScreen = mediaType === breakpoints.DESKTOP || mediaType === breakpoints.TABLET;

  const userResourcesStore = UserResourcesStore.Instance().Use();
  const hasDirectMessageResource = userResourcesStore.HasDirectMessageUserResource;
  const hasCallAgentResource = userResourcesStore.HasCallAgentUserResource;

  // The order of the options in `talkOptions` dictates the order they are displayed
  let talkOptions = [];
  if (hasDirectMessageResource) {
    talkOptions.push(MessagingCard);
  }
  if (hasCallAgentResource) {
    talkOptions.push(CallCard);
  }
  talkOptions.push(PyxirChatCard);

  const gridDirection = isTabletScreen ? 'row' : 'column';

  return (
    <Grid container spacing={2}>
      {talkOptions.map((Option, index) => {
        return (
          <Grid item direction={gridDirection} mobile={12} tablet={6} key={index}>
            <Option
              isTablet={isTabletScreen}
              hasBorder={(isTabletScreen && index >= 2) || (!isTabletScreen && index >= 1)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TalkToSomeoneItems;
