import styled from '@emotion/styled';
import { LinearProgress } from '@mui/material';
import { purple } from 'Styles/colors';

export const StyledProgressBar = styled(LinearProgress)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 9px;

  .MuiLinearProgress-bar {
    background-color: ${purple._400};
  }
`;
