/* istanbul ignore file */

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { Deploy } from 'cordova-plugin-ionic';
import { createBrowserHistory } from 'history';

export const browserHistory = createBrowserHistory({ basename: '' });
export const isInsightsEnabled = process.env.INSIGHTS_ENABLED && JSON.parse(process.env.INSIGHTS_ENABLED);
const reactPlugin = new ReactPlugin();
export const ai = new ApplicationInsights({
  config: {
    connectionString: process.env.APPLICATIONINSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
if (isInsightsEnabled) {
  ai.loadAppInsights();
  ai.addTelemetryInitializer((telemetryItem) => {
    telemetryItem.tags['ai.cloud.role'] = process.env.CLOUD_ROLE_NAME ?? 'app-local';
    Deploy.getCurrentVersion().then((v) => {
      telemetryItem.tags['app.version'] = v?.buildId || process.env.APP_VERSION || process.env.FALLBACK_APP_VERSION;
    });
  });
}

export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
