import OptionalCheckin from './AdditionalQuestions/OptionalCheckin';
import Results from './Results/Results';
import ScreeningForm from './ScreeningForm/ScreeningForm';

export const WorkFlowTypes = {
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  OPTIONALTRANSITION: 'OPTIONALTRANSITION',
  RESULTS: 'RESULTS',
};

export const Workflows = {
  [WorkFlowTypes.QUESTIONNAIRE]: ScreeningForm,
  [WorkFlowTypes.OPTIONALTRANSITION]: OptionalCheckin,
  [WorkFlowTypes.RESULTS]: Results,
};
