import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  &::after {
    content: '|';
    width: 100%;
    color: transparent;
    visibility: hidden;
    background: transparent;
    font-size: 10px;
  }
`;

export const StyledBannerText = styled.p`
  font-size: 20px;
  color: #4a4f7f;
  position: relative;
  top: 125px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  margin-top: -66px;
`;

export const StyledCongratsImage = styled.img`
  align-self: center;
  display: flex;
  margin-bottom: -30px;
`;

export const StyledTextContainer = styled.div`
  padding: 0 50px;
  background-color: white;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  align-self: center;
  margin-top: auto;
`;
