import { thriveStatuses, thriveWorkflowTypes } from '../enums';
import { workflowTypes } from './workflow';

export const workflowStateDefault = {
  shownWelcome: false,
};

export const getWorkflowState = (state) => {
  let response = {
    workflowState: state?.workflowState ?? workflowStateDefault,
    workflowType: thriveWorkflowTypes.DEFAULT,
  };

  if (state?.thriveAccess?.thriveStatus === thriveStatuses.GRADUATE && state.congrats?.showCongrats) {
    response.workflowType = workflowTypes.CONGRATS;
  }

  if (state?.thriveAccess?.thriveStatus !== thriveStatuses.ACTIVE) {
    response.workflowState = workflowStateDefault;
    return response;
  }

  if (!state?.workflowState?.shownWelcome && state?.thriveAssessmentWeek === 1) {
    response.workflowType = workflowTypes.WELCOME;
  }

  return response;
};
