import { thriveProgramTypes } from './enums';
import { standardProgram } from './Standard/program';
import { youthProgram } from './Youth/program';

export const thrivePrograms = {
  [thriveProgramTypes.STANDARD]: standardProgram,
  [thriveProgramTypes.YOUTH]: youthProgram,
};

export const getThriveConfig = ({ thriveAccess }) => {
  if (!thriveAccess) {
    return;
  }

  const thriveProgram = thrivePrograms[thriveAccess?.programType];

  return {
    program: thriveProgram,
  };
};

export const getCurrentWorkflow = (thrive, thriveWorkflowType) => {
  const { program } = getThriveConfig(thrive);
  const { workflow, getWorkflowState } = program;
  let workflowType = thriveWorkflowType;

  if (!thriveWorkflowType) {
    workflowType = getWorkflowState(thrive)?.workflowType;
  }

  const currentWorkflow = workflow[workflowType];

  return {
    workflowType,
    workflow: currentWorkflow,
    steps: currentWorkflow?.steps,
  };
};
