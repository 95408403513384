import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

const Wolf = (props) => {
  const intl = useIntl();

  return (
    <svg
      role="img"
      focusable="false"
      aria-labelledby="svgWolfTitle"
      aria-describedby="svgWolfDescription"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 900 900"
    >
      <title id="svgWolfTitle">
        <FormattedMessage id="image.coloring-book.wolf" />
      </title>
      <desc id="svgWolfDescription">{intl.formatMessage({ id: 'image.coloring-book.wolf-description' })}</desc>
      <g id="WolfLayer" data-name="Layer">
        <polygon
          onClick={() => props.onFill(0)}
          fill={props.colors[0]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="326.52 337.03 354.29 378.24 405.56 400.9 401.44 376.81 364.47 338.89 326.52 337.03"
        />
        <polygon
          onClick={() => props.onFill(1)}
          fill={props.colors[1]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="423.9 340.04 405.56 400.9 494.53 401.13 476.1 340.04 423.9 340.04"
        />
        <polygon
          onClick={() => props.onFill(2)}
          fill={props.colors[2]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="610.91 390.82 595.73 439.48 669.81 428.54 610.91 390.82"
        />
        <polygon
          onClick={() => props.onFill(3)}
          fill={props.colors[3]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="289.19 390.65 332.34 426.78 405.56 400.9 353.68 377.59 326.52 337.03 289.19 390.65"
        />
        <polygon
          onClick={() => props.onFill(4)}
          fill={props.colors[4]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="364.06 283.23 326.6 337.39 364.33 338.47 364.9 339.13 401.44 376.81 364.06 283.23"
        />
        <polygon
          onClick={() => props.onFill(5)}
          fill={props.colors[5]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="228.17 429.66 304.09 439.47 289.19 390.65 228.17 429.66"
        />
        <polygon
          onClick={() => props.onFill(6)}
          fill={props.colors[6]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="580.47 527.45 575.64 502.81 543.84 527.03 529.96 587.45 583.4 540.38 580.47 527.45"
        />
        <polygon
          onClick={() => props.onFill(7)}
          fill={props.colors[7]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="426.43 593.59 473.73 593.45 495.22 563.73 449.26 544.22 403.96 563.03 426.43 593.59"
        />
        <polygon
          onClick={() => props.onFill(8)}
          fill={props.colors[8]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="391.82 604.89 509.03 604.47 529.43 588.04 543.84 527.03 493.94 528.98 473.82 506.86 449.59 507.07 449.26 544.22 495.8 563.27 473.62 593.47 426.38 593.47 404.2 563.27 449.26 544.22 449.59 507.07 426.19 506.86 405.79 530.7 356.3 527.56 370.57 588.04 391.82 604.89"
        />
        <polygon
          onClick={() => props.onFill(9)}
          fill={props.colors[9]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="356.3 527.56 324.36 503.09 319.05 539.41 319.05 539.41 370.06 586.89 356.3 527.56"
        />
        <polygon
          onClick={() => props.onFill(10)}
          fill={props.colors[10]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="376.54 271.87 405.56 400.9 423.32 339.99 406.29 250.83 376.54 271.87"
        />
        <polygon
          onClick={() => props.onFill(11)}
          fill={props.colors[11]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="523.46 271.87 563.34 219.08 610.13 55.82 514.99 156.17 493.71 250.83 523.46 271.87"
        />
        <polygon
          onClick={() => props.onFill(12)}
          fill={props.colors[12]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="567.43 426.81 494.53 401.13 510.9 480.15 543.84 527.03 574.83 503.7 595.73 439.48 595.73 439.48 610.91 390.82 567.43 426.81"
        />
        <polygon
          onClick={() => props.onFill(13)}
          fill={props.colors[13]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="583.4 540.38 627.08 505.4 595.73 439.48 575.57 502.63 583.4 540.38"
        />
        <polygon
          onClick={() => props.onFill(15)}
          fill={props.colors[15]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="604.42 242.8 638.31 171.28 610.13 55.82 563.34 219.08 604.42 242.8"
        />
        <polygon
          onClick={() => props.onFill(16)}
          fill={props.colors[16]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="543.84 527.03 510.85 480.14 494.53 401.13 494.53 401.13 479.14 401.53 493.94 528.98 543.84 527.03"
        />
        <polygon
          onClick={() => props.onFill(17)}
          fill={props.colors[17]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="405.56 400.9 389.17 480.56 356.3 527.56 405.79 530.7 421.13 401.31 405.56 400.9 405.56 400.9"
        />
        <polygon
          onClick={() => props.onFill(18)}
          fill={props.colors[18]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="473.82 506.86 493.94 528.98 479.14 401.53 450 401.27 420.89 401.62 405.79 530.7 426.19 506.86 473.82 506.86"
        />
        <polygon
          onClick={() => props.onFill(19)}
          fill={props.colors[19]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="275.73 507.36 319.05 539.41 324.43 501.63 304.09 439.47 275.73 507.36"
        />
        <polygon
          onClick={() => props.onFill(20)}
          fill={props.colors[20]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="356.3 527.56 389.1 480.15 405.56 400.9 332.57 426.81 289.19 390.65 304.09 439.47 304.09 439.47 325.17 503.7 356.3 527.56"
        />
        <polygon
          onClick={() => props.onFill(21)}
          fill={props.colors[21]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="250.69 644.51 284.52 719.91 319.05 539.41 250.69 644.51"
        />
        <polygon
          onClick={() => props.onFill(22)}
          fill={props.colors[22]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="615.77 719.54 583.4 540.38 532.75 585.22 529.96 587.73 498.55 614.54 450.1 624.8 450.11 854.58 540.97 644.22 615.77 719.54"
        />
        <polygon
          onClick={() => props.onFill(23)}
          fill={props.colors[23]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="450.11 854.58 615.77 719.54 540.97 644.22 450.11 854.58"
        />
        <polygon
          onClick={() => props.onFill(24)}
          fill={props.colors[24]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="615.77 719.54 649.18 644.65 583.4 540.38 615.77 719.54"
        />
        <polygon
          onClick={() => props.onFill(25)}
          fill={props.colors[25]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="369.78 587.17 366.43 584.1 319.05 539.41 284.52 719.91 359.25 644.22 450.11 854.58 450.1 624.8 401.45 614.54 369.78 587.17"
        />
        <polygon
          onClick={() => props.onFill(26)}
          fill={props.colors[26]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="509.03 604.47 391.82 604.89 400.75 614.24 450.1 624.8 498.99 614.24 509.03 604.47"
        />
        <polygon
          onClick={() => props.onFill(27)}
          fill={props.colors[27]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="284.52 719.91 450.11 854.58 359.25 644.22 284.52 719.91"
        />
        <polygon
          onClick={() => props.onFill(28)}
          fill={props.colors[28]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="649.18 644.65 653.52 549.98 583.4 540.38 649.18 644.65"
        />
        <polygon
          onClick={() => props.onFill(29)}
          fill={props.colors[29]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="663.28 275.61 563.34 219.08 563.34 219.08 523.46 271.87 498.77 377.9 536.03 282.87 579.17 345.26 580.21 346.75 610.91 390.82 610.91 390.82 669.81 428.54 648.63 362.38 701.37 366.83 663.28 275.61"
        />
        <polygon
          onClick={() => props.onFill(30)}
          fill={props.colors[30]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="626.87 505.45 583.4 540.38 653.52 549.98 678.17 531.75 669.81 428.54 595.73 439.48 626.87 505.45"
        />
        <polygon
          onClick={() => props.onFill(31)}
          fill={props.colors[31]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="336.26 218.86 289.87 55.82 261.69 171.28 295.57 242.8 336.26 218.86"
        />
        <polygon
          onClick={() => props.onFill(32)}
          fill={props.colors[32]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="535.64 338.52 498.77 377.9 494.53 401.13 545.71 378.24 572.85 338.15 535.64 338.52"
        />
        <polygon
          onClick={() => props.onFill(33)}
          fill={props.colors[33]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="493.71 250.83 476.68 339.99 494.53 401.13 523.46 271.87 493.71 250.83"
        />
        <polygon
          onClick={() => props.onFill(34)}
          fill={props.colors[34]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="245.31 549.77 250.69 644.51 319.05 539.41 245.31 549.77"
        />
        <polygon
          onClick={() => props.onFill(35)}
          fill={props.colors[35]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="406.29 250.83 424.01 340.04 475.99 340.04 493.71 250.83 493.71 250.83 514.99 156.17 479.03 145.38 420.97 145.38 385.01 156.17 406.29 250.83 406.29 250.83"
        />
        <polygon
          onClick={() => props.onFill(36)}
          fill={props.colors[36]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="498.77 377.9 535.67 338.47 573.4 337.39 535.95 283.23 498.77 377.9"
        />
        <polygon
          onClick={() => props.onFill(37)}
          fill={props.colors[37]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="376.54 271.87 406.29 250.83 385.01 156.17 289.87 55.82 336.26 218.86 376.54 271.87"
        />
        <polygon
          onClick={() => props.onFill(38)}
          fill={props.colors[38]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="289.19 390.65 319.79 346.75 320.83 345.26 363.97 282.87 401.44 376.81 376.54 271.87 336.26 218.86 236.72 275.61 198.63 366.83 251.37 362.38 228.17 429.66 289.19 390.65 289.19 390.65"
        />
        <polygon
          onClick={() => props.onFill(39)}
          fill={props.colors[39]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="275.64 507.69 304.09 439.47 228.17 429.66 220.99 531.75 245.71 549.63 319.05 539.41 275.64 507.69"
        />
        <polygon
          onClick={() => props.onFill(40)}
          fill={props.colors[40]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="546.43 378.33 494.53 401.13 567.66 426.78 610.91 390.82 573.22 338.15 546.43 378.33"
        />
      </g>
    </svg>
  );
};

Wolf.propTypes = {
  onFill: PropTypes.func,
  colors: PropTypes.array,
};

export default Wolf;
