import { DirectMessageAuthentication } from '../Models/DirectMessageAuthentication';
import { DirectMessageAPIService } from './Utilities/DirectMessageAPIService';
import { AgentPoolStatusTypes } from '../Models/AgentPoolStatusTypes';
import { WebSocketService } from './WebSocketService';
import { appInsights } from 'Core_Helpers/AppInsights';
import reduxStore from 'Core_Redux/store';
import { ErrorType } from '../Models/ErrorType';
import { detect } from 'detect-browser';

const RECONNECTTHRESHOLD = 10;
const RETRYFREQUENCY = 500;
const MONITORAGENTPOOLSTATUSFREQUENCY = 30000;
export class DirectMessageService implements IDirectMessageService {
  linkLiveAuthentication: DirectMessageAuthentication;
  session = ''; // This is the string that predicates the POST register call
  sessionId = ''; // This is Link Live's internal ID for the session used in session cmd objects
  socketService: WebSocketService;
  clientSeq = 0;
  serverSeq = 0;
  serverAck = 0;
  messageBuffer: Array<ISequencedMessage> = [];
  messageRetryCount = 0;
  isSessionEnded = true;
  refreshBuffer = 30;
  agents: Array<Agent> = [];
  connecting = true;
  huntGroupPid = '';
  OnMessageReceivedCallback: (message: string, agent: Agent) => void;
  OnErrorCallback: (errorType: ErrorType, message: string) => void;
  OnConnectedCallback: () => void;
  OnSessionEndCallback: () => void;
  IsAgentOnlineChangeCallback: (isAgentOnline: boolean) => void;
  OnAgentJoinCallback: (agent: Agent) => void;
  OnAgentTypingCallback: () => void;
  browser = detect();
  //this error type is meant to be used for any failure that occurs during session initialization
  initSessionErrorType = this.browser?.name == 'safari' ? ErrorType.CORSNETWORKERROR : ErrorType.GENERALNETWORKERROR;

  constructor() {
    this.socketService = new WebSocketService(this.onWSMessage, this.onWSOpen, this.onWSError);
    this.retryUnacknowledgedMessages();
  }

  public Initialize = async () => {
    if (this.linkLiveAuthentication?.auth_token && this.session) {
      return;
    }

    try {
      const authResult = await DirectMessageAPIService.GetDirectMessageAuth();
      if (!authResult) {
        this.OnErrorCallback(this.initSessionErrorType, 'Error authenticating');
        this.EndSession();
        return;
      }

      const directMessageAuth: DirectMessageAuthentication = {
        pid: authResult.data.pid,
        auth_token: authResult.data.token,
        token_expire_secs: authResult.data.token_expire_secs,
      };

      this.linkLiveAuthentication = directMessageAuth;

      this.reauth();
      this.Connect();
    } catch (e) {
      this.OnErrorCallback(this.initSessionErrorType, 'Error while connecting.');
      this.EndSession();
      return;
    }
  };

  private Connect = async () => {
    const sessionResult = await DirectMessageAPIService.RegisterSession(this.linkLiveAuthentication);
    if (!sessionResult) {
      this.OnErrorCallback(this.initSessionErrorType, 'Error starting session');
      this.EndSession();
      return;
    }

    const session = sessionResult.data[0].registering.session;
    this.session = session;

    this.openWebsocket(this.session);
    this.isSessionEnded = false;
  };

  public SendMessage = (message: string): void => {
    const messageObject = {
      session: { id: this.sessionId, cmd: 'message', info: { content_type: 'text/html', content: message } },
      seq: this.clientSeq + 1,
    };
    this.messageBuffer.push(messageObject);
    this.socketService.Send(JSON.stringify(messageObject));

    this.clientSeq = this.clientSeq + 1;
  };

  public MonitorAgentPoolStatus = async () => {
    const agentPoolStatus = await this.getAgentPoolStatus();
    this.IsAgentOnlineChangeCallback(agentPoolStatus?.status === AgentPoolStatusTypes.ONLINE);
    this.huntGroupPid = agentPoolStatus.linkLivePid;

    setTimeout(async () => {
      this.MonitorAgentPoolStatus();
    }, MONITORAGENTPOOLSTATUSFREQUENCY);
  };

  private getAgentPoolStatus = async () => {
    const statusResult = await DirectMessageAPIService.GetAgentPoolStatus();
    return statusResult?.data?.agentPools[0];
  };

  private retryUnacknowledgedMessages = () => {
    setTimeout(() => {
      if (!this.isSessionEnded) {
        if (this.messageBuffer.length) {
          this.messageRetryCount = this.messageRetryCount + 1;
          this.socketService.Send(JSON.stringify(this.messageBuffer));
        }
        if (this.messageRetryCount > RECONNECTTHRESHOLD && !this.connecting) {
          this.connecting = true;
          this.socketService.Reconnect();
        }
      }
      this.retryUnacknowledgedMessages();
    }, RETRYFREQUENCY);
  };

  private onWSMessage = (event: any): void => {
    const messages = JSON.parse(event.data);

    let updateToSeq = this.serverSeq;
    if (Array.isArray(messages)) {
      messages.forEach((messageData) => {
        updateToSeq = this.handleWSMessage(messageData) ?? updateToSeq;
      });
      this.serverSeq = updateToSeq;
    } else {
      updateToSeq = this.handleWSMessage(messages);
    }

    if (updateToSeq > this.serverSeq) {
      this.serverSeq = updateToSeq;
    }
    this.acknowledge(this.serverSeq);
  };

  private handleWSMessage = (messageData: any) => {
    if (messageData.seq && this.serverSeq < messageData.seq) {
      const session = messageData?.session;

      if (session?.id) {
        this.sessionId = session.id;
      }

      if (session?.cmd == 'message') {
        this.onMessage(session.info);
      }
      if (session?.cmd == 'bye') {
        this.EndSession();
      }
      if (session?.cmd == 'typing') {
        this.OnAgentTypingCallback();
      }
      if (session?.cmd == 'info') {
        const info = session.info;
        const endpoint = info?.endPoints?.endPoint[0];
        if (endpoint && endpoint.action == 'join') {
          this.onAgentJoin(endpoint);
        }
      }
    }
    if (messageData?.failed) {
      this.OnErrorCallback(this.initSessionErrorType, messageData?.failed);
      this.EndSession();
    }
    if (messageData.ack) {
      this.messageRetryCount = 0;
      this.messageBuffer = this.messageBuffer.filter((message) => message.seq > messageData.ack);
    }

    return messageData.seq ?? 0;
  };

  private onMessage(info: any) {
    const fromAgent = this.agents.find((agent) => info.from == agent.id);
    this.OnMessageReceivedCallback(info.content, fromAgent);
  }

  private onAgentJoin(endpoint: any) {
    if (!this.agents.find((agent) => endpoint.pid == agent.id)) {
      const isHuntGroup = endpoint.pid === this.huntGroupPid;

      if (isHuntGroup) {
        this.OnConnectedCallback();
        this.SendUserInfo();
      }

      const agent = new Agent(endpoint.pid, endpoint.display, isHuntGroup);
      this.OnAgentJoinCallback(agent);
      this.agents.push(agent);
    }
  }

  private async SendUserInfo() {
    const userInfo = reduxStore.store.getState().user.syncUser;
    const birthDateString = new Date(userInfo.birthDay).toLocaleDateString('en-US');
    const info = `
      <div>
        <h1>User Info</h1>
        <p>UserId: ${userInfo.userId}</p>
        <p>First Name: ${userInfo.firstName}</p>
        <p>Last Name: ${userInfo.lastName}</p>
        <p>Organization: ${userInfo.organizationName}</p>
        <p>Network: ${userInfo.networkName}</p>
        <p>Group: ${userInfo.groupName}</p>
        <p>Birth date: ${birthDateString}</p>
        <p>Phone Number: ${userInfo.phoneNumber}</p>
        <p>Locale: ${userInfo.language}</p>
      </div>`;
    this.SendMessage(info);
  }

  public EndSession() {
    const endSessionObject = {
      session: {
        cmd: 'bye',
        id: this.sessionId,
      },
      seq: this.clientSeq + 1,
    };
    this.clientSeq = this.clientSeq + 1;
    this.socketService.Send(JSON.stringify(endSessionObject));
    this.socketService.Close();
    this.isSessionEnded = true;
    this.onSessionEnd();
    this.OnSessionEndCallback();
  }

  private onSessionEnd() {
    this.isSessionEnded = true;
    this.socketService.Close();
    this.linkLiveAuthentication = null;
    this.session = '';
    this.sessionId = '';
    this.clientSeq = 0;
    this.serverSeq = 0;
    this.serverAck = 0;
    this.messageBuffer = [];
    this.messageRetryCount = 0;
    this.agents = [];
  }

  private acknowledge = (seq: number) => {
    this.socketService.Send(`[{"ack": ${seq}}]`);
  };

  private onWSError = (event: any): void => {
    appInsights.trackTrace({ message: 'Diagnostic: Direct Messaging - Websocket Error' });
    this.OnErrorCallback(this.initSessionErrorType, event);
    this.socketService.Close();
    this.EndSession();
  };

  private onWSOpen = (event: any): void => {
    if (this.sessionId) {
      return;
    }

    const inviteObject = {
      session: {
        cmd: 'invite',
        pid: this.huntGroupPid,
        seq: this.clientSeq + 1,
      },
      seq: this.clientSeq + 1,
    };
    this.clientSeq = this.clientSeq + 1;
    this.socketService.Send(JSON.stringify(inviteObject));
    this.messageBuffer.push(inviteObject);
  };

  private openWebsocket = async (session_id: string) => {
    this.socketService.Connect((process.env.LINK_LIVE_WS_URL as string) + `${session_id}`);
  };

  private reauth = async () => {
    const nextTokenRefreshMilliseconds = (this.linkLiveAuthentication.token_expire_secs - this.refreshBuffer) * 1000;

    setTimeout(async () => {
      if (!this.isSessionEnded) {
        const result = await DirectMessageAPIService.ReAuthSession(this.linkLiveAuthentication.auth_token);
        if (result) {
          this.linkLiveAuthentication.token_expire_secs = result.data.token_expire_secs;
          this.reauth();
        }
      }
    }, nextTokenRefreshMilliseconds);
  };
}

export interface IDirectMessageService {
  Initialize(): Promise<void>;
  SendMessage(message: string): void;
  OnMessageReceivedCallback(message: string, agent: Agent): void;
  OnErrorCallback(errorType: ErrorType, message: string): void;
  OnConnectedCallback(): void;
}

interface ISequencedMessage {
  seq: number;
}

export class Agent {
  constructor(id, display, isHuntGroup) {
    this.id = id;
    this.display = display;
    this.isHuntGroup = isHuntGroup;
  }

  public id: string;
  public display: string;
  public isHuntGroup: boolean;
}
