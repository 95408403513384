import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { RadioGroup, FormControlLabel, Checkbox as MuiCheckbox, FormControl, FormGroup } from '@mui/material';

const Checkbox = forwardRef(({ filteredOptions, updateSelection, options, ...props }, ref) => {
  const intl = useIntl();
  const extraInputProps = { 'aria-labelledby': props['aria-labelledby'] };
  const checkBoxOptions = filteredOptions ?? options;

  return (
    <FormControl>
      <FormGroup aria-labelledby={props['aria-labelledby']} {...extraInputProps}>
        <RadioGroup ref={ref} style={{ textAlign: 'left' }}>
          {checkBoxOptions.map((answer) => {
            const label = intl.formatMessage({ id: `${answer.textId}` });
            return (
              <FormControlLabel
                key={`id-${answer.name}`}
                value={answer.name}
                onChange={(e) => updateSelection(e.target.value)}
                control={<MuiCheckbox />}
                label={label}
              />
            );
          })}
        </RadioGroup>
      </FormGroup>
    </FormControl>
  );
});

Checkbox.displayName = `Checkbox`;

Checkbox.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  filteredOptions: PropTypes.array,
  ref: PropTypes.object,
  ['aria-labelledby']: PropTypes.string,
  updateSelection: PropTypes.func,
};

export default Checkbox;
