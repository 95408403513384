import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useOnboardSelector, useOnboardDispatch } from 'Contexts/StoreContext';
import { selectLanguage } from 'Onboard_Redux/user';
import { completeLanguageSelection, selectSignupMetadata } from 'Onboard_Redux/signup';
import { FormControl, MenuItem } from '@mui/material';
import { Language } from '@mui/icons-material';
import Button from 'Stories/components/Button';
import { Header, CenteredSelect } from 'Onboard_Components/Account/styles';
import { ExperienceContext } from 'Contexts/ExperienceContext';

export function LanguageSelect() {
  const intl = useIntl();
  const experience = useContext(ExperienceContext);
  const dispatch = useOnboardDispatch();
  const { loading } = useOnboardSelector(selectSignupMetadata);
  const language = useOnboardSelector(selectLanguage);

  const languageOptions = [
    { language: 'English', code: 'en' },
    { language: 'Español', code: 'es' },
  ];

  function onContinue() {
    dispatch(completeLanguageSelection());
  }

  function onChange(e) {
    experience.updateLanguage(e.target.value);
  }

  return (
    <>
      <Header>{intl.formatMessage({ id: 'account.welcome' })}</Header>
      <form onSubmit={onContinue}>
        <FormControl fullWidth variant="filled">
          <CenteredSelect
            id="languageInput"
            renderValue={(value) => (
              <>
                <Language />
                <span>{languageOptions.find((o) => o.code === value).language}</span>
              </>
            )}
            value={language ?? 'en'}
            onChange={onChange}
          >
            {languageOptions.map((o) => (
              <MenuItem key={o.code} value={o.code}>
                {o.language}
              </MenuItem>
            ))}
          </CenteredSelect>
        </FormControl>
        <Button id="continue" fullWidth loading={loading} type="submit" sx={{ margin: '8px 0' }}>
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
    </>
  );
}

export default LanguageSelect;
