import React, { useState, useEffect, useRef } from 'react';
import OnboardingHeader from 'Core_Components/OnboardingHeader/OnboardingHeader';
import { useForm } from 'react-hook-form';
import {
  ProfilePageContainer,
  PageTitle,
  ProfileFormContainer,
  SupportText,
  SupportButton,
  InputStyle,
  SubmitButton,
  SupportButtonContainer,
  Label,
  ScrollableContent,
  ProfileForm,
  ProfileSetting,
  ErrorMessage,
  LogoutButton,
  HeaderContainer,
  ButtonLink,
  InfoMessage,
} from './styles';
import { useCoreDispatch, useCoreSelector } from 'Contexts/StoreContext';
import { selectSyncUser, selectUserInfo } from 'Core_Redux/user';
import { useLazySynchronizeUserQuery } from 'Core_Redux/services/api';
import { logout } from 'Core_Redux/authentication';
import { useHistory } from 'react-router-dom';
import ConditionalLink from 'Core_Components/ConditionalLink/ConditionalLink';
import { FormattedMessage, useIntl } from 'react-intl';
import { appInsights } from 'Core_Helpers/AppInsights';
import useAxiosPatch from 'Core_Hooks/api/useAxiosPatch/useAxiosPatch';
import { ACCOUNTDELETEDISCLAIMER, HOME } from 'Core_Pages/Routes/RoutesConfig';
import Button from 'Stories/components/Button';
import { Deploy } from 'cordova-plugin-ionic';

const Profile = () => {
  const intl = useIntl();
  const syncUser = useCoreSelector(selectSyncUser);
  const userInfo = useCoreSelector(selectUserInfo);
  const [data, setData] = useState();
  const [userId, setUserId] = useState();
  const [firstNameError, setFirstNameError] = useState();
  const [lastNameError, setLastNameError] = useState();
  const [healthPlanIdContent, setHealthPlanIdContent] = useState();
  const [submitPressed, setSubmitPressed] = useState(false);
  const [version, setVersion] = useState(process.env.FALLBACK_APP_VERSION);
  const [liveUpdateVersion, setLiveUpdateVersion] = useState(null);
  const { response, isLoading } = useAxiosPatch(`${process.env.API_BASE_URL}/v5/members/${userId}`, data);
  const history = useHistory();
  const dispatch = useCoreDispatch();
  const { register, handleSubmit } = useForm({});
  const pageTitleRef = useRef(null);
  const firstNameId = 'firstName';
  const lastNameId = 'lastName';
  const [synchronizeUser] = useLazySynchronizeUserQuery();
  const firstNameInputRef = register(firstNameId);
  const lastNameInputRef = register(lastNameId);

  useEffect(() => {
    pageTitleRef?.current?.focus();
    getAzureBuildVersion();
    getLiveUpdateVersion();
  }, []);

  useEffect(() => {
    if (response) {
      synchronizeUser(userInfo.sub);
      history.push(HOME);
    }
  }, [response]);

  useEffect(() => {
    if (syncUser?.healthPlanId) {
      setHealthPlanIdContent(syncUser.healthPlanId);
    } else if (syncUser?.unconfirmedHealthPlanId) {
      setHealthPlanIdContent(syncUser.unconfirmedHealthPlanId);
    }
  }, [syncUser]);

  async function getLiveUpdateVersion() {
    const receivedVersion = await Deploy.getCurrentVersion();
    if (receivedVersion) {
      setLiveUpdateVersion(receivedVersion.buildId);
    }
  }

  function getAzureBuildVersion() {
    // builds ran in Azure will have this env variable
    if (process.env.APP_VERSION) {
      setVersion(process.env.APP_VERSION);
    }
  }

  const onLogoutClick = async () => {
    appInsights.trackEvent({ name: 'LogoutClicked' });
    appInsights.trackTrace({ message: 'Diagnostic: User clicked logout button' });
    await dispatch(logout());
  };

  const validateName = (data, errorMessage, setErrorMessage) => {
    if (data?.length < 1) {
      setTimeout(() => {
        setErrorMessage(intl.formatMessage({ id: errorMessage }));
      }, 100);
    } else {
      setErrorMessage();
    }
  };

  const onChange = (e) => {
    const { id, value } = e.target;
    if (id === firstNameId) {
      validateName(value, 'profile.invalid.firstname', setFirstNameError);
    } else if (id === lastNameId) {
      validateName(value, 'profile.invalid.lastname', setLastNameError);
    }
  };

  const validateForm = (data) => {
    // Submit Pressed is here due to business logic
    // of how we want to not check validation until the form is attempted to be submitted
    setSubmitPressed(true);

    validateName(data?.firstName, 'profile.invalid.firstname', setFirstNameError);
    validateName(data?.lastName, 'profile.invalid.lastname', setLastNameError);

    if (data?.firstName?.length < 1) {
      firstNameInputRef?.current?.focus();
    } else if (data?.lastName?.length < 1) {
      lastNameInputRef?.current?.focus();
    }

    return data?.firstName?.length > 0 && data?.lastName?.length > 0;
  };

  const onSubmit = async (formData) => {
    if (!isLoading && validateForm(formData)) {
      appInsights.trackEvent({ name: 'ProfileSave' });
      setUserId(userInfo.sub);

      let memberPatchItems = {};
      if (formData?.firstName !== syncUser?.firstName) {
        memberPatchItems.firstName = formData.firstName;
      }
      if (formData?.lastName !== syncUser?.lastName) {
        memberPatchItems.lastName = formData.lastName;
      }
      if (Object.keys(memberPatchItems).length > 0) {
        setData({ memberPatchItems });
      }
    }
  };

  const returnProfileForm = () => {
    return (
      <ProfileFormContainer data-testid="ProfileFormContainer">
        <ProfileForm data-testid="ProfileForm" onSubmit={handleSubmit(onSubmit)} aria-live="assertive">
          <PageTitle tabIndex="-1" ref={pageTitleRef}>
            <FormattedMessage id="profile.my-profile" />
          </PageTitle>
          <ProfileSetting>
            <Label htmlFor={firstNameId}>
              <FormattedMessage id="profile.first-name" />
            </Label>
            <InputStyle
              type="text"
              id={firstNameId}
              name={firstNameInputRef.name}
              data-testid="FirstNameInput"
              defaultValue={syncUser?.firstName}
              ref={firstNameInputRef.ref}
              onChange={(e) => {
                firstNameInputRef.onChange(e);
                onChange(e);
              }}
              onBlur={(e) => {
                firstNameInputRef.onChange(e);
                onChange(e);
              }}
              hasError={!!firstNameError && submitPressed}
              aria-invalid={!!firstNameError && submitPressed}
              aria-describedby={firstNameError ? 'FirstNameError' : firstNameId}
              aria-required="true"
            />
            {firstNameError && submitPressed && (
              <ErrorMessage id="FirstNameError" data-testid="FirstNameError">
                {firstNameError}
              </ErrorMessage>
            )}

            <Label htmlFor={lastNameId}>
              <FormattedMessage id="profile.last-name" />
            </Label>
            <InputStyle
              type="text"
              name={lastNameInputRef.name}
              id={lastNameId}
              data-testid="LastNameInput"
              defaultValue={syncUser?.lastName}
              ref={lastNameInputRef.ref}
              onChange={(e) => {
                lastNameInputRef.onChange(e);
                onChange(e);
              }}
              onBlur={(e) => {
                lastNameInputRef.onChange(e);
                onChange(e);
              }}
              hasError={!!lastNameError && submitPressed}
              aria-invalid={!!lastNameError && submitPressed}
              aria-describedby={lastNameError ? 'LastNameError' : lastNameId}
              aria-required="true"
            />
            {lastNameError && submitPressed && (
              <ErrorMessage id="LastNameError" data-testid="LastNameError">
                {lastNameError}
              </ErrorMessage>
            )}
            <Label htmlFor="healthPlanId">
              <FormattedMessage id="common.health-plan-id" />
            </Label>
            <InputStyle
              type="text"
              name="healthPlanId"
              id="healthPlanId"
              disabled={true}
              defaultValue={healthPlanIdContent}
              aria-describedby="healthPlanId"
            />
          </ProfileSetting>
          <SubmitButton
            disabled={isLoading || ((firstNameError || lastNameError) && submitPressed)}
            data-testid="SubmitButton"
            type="submit"
          >
            <FormattedMessage id="profile.save" />
          </SubmitButton>
        </ProfileForm>
      </ProfileFormContainer>
    );
  };

  return (
    <ProfilePageContainer>
      <OnboardingHeader data-testid="Header" hasMenu={true} />
      <ScrollableContent>
        {returnProfileForm()}
        <SupportText id="SupportText">
          <FormattedMessage id="profile.support" />
        </SupportText>
        <HeaderContainer>
          <SupportButtonContainer aria-describedby="SupportText">
            <ConditionalLink linkType={'Phone'} link={'18554994777'}>
              <SupportButton id="SupportButton">
                <FormattedMessage id="profile.call-support" />
              </SupportButton>
            </ConditionalLink>
          </SupportButtonContainer>
          <LogoutButton onClick={onLogoutClick}>
            <FormattedMessage id="profile.logout" />
          </LogoutButton>
          <ButtonLink
            id="DeleteButton"
            onClick={() => history.push(ACCOUNTDELETEDISCLAIMER)}
            variant={Button.variant.TEXT}
          >
            {intl.formatMessage({ id: 'profile.delete-my-account' })}
          </ButtonLink>
          <InfoMessage>
            {intl.formatMessage({ id: 'common.version' })} {liveUpdateVersion || version}
          </InfoMessage>
        </HeaderContainer>
      </ScrollableContent>
    </ProfilePageContainer>
  );
};

export default Profile;
