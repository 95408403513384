import styled from 'styled-components';

export const StyledGreetingCardWrapperButton = styled.button`
  display: grid;
  background: transparent;
`;

export const StyledParagraph = styled.p`
  color: #000000;
  text-align: center;
  font-size: 16px;
  margin: 0 24px;
`;

export const StyledImage = styled.img`
  max-width: 148px;
  margin: auto;
`;
