import styled from '@emotion/styled';
import { StyledCard } from '../styles';
import { CircularProgress } from '@mui/material';

export const StyledReminderCard = styled(StyledCard)`
  padding: 12px;
`;

export const StyledCardTitleContainer = styled.div`
  min-height: 22px;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  column-gap: 12px;
  margin: 0 36px;
`;

export const StyledChipContainer = styled.div`
  margin: 8px 0 4px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 8px;
`;

export const StyledChip = styled.div`
  color: black;
  padding: 5px 20px;
  font-size: 14px;
  border: 1px solid #d6d8dd;
  border-radius: 13px;
  text-align: center;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  position: relative;
  margin: 34px 0;
  // centering without flexbox
  // 20px represents half of spinner element's width
  // transform: translate() doesn't work because of the spinner's animation
  left: calc(50% - 20px);
`;
