import React from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { black } from 'Styles/colors';

const JoinedSessionMessage = ({ message }) => {
  const intl = useIntl();

  return (
    <Grid justifyContent="center" alignItems="center" marginBottom="12px">
      <Typography fontSize="16px" textAlign="center" color={black._60} width="75%" margin="auto">
        {message.timestamp} {message.from} {intl.formatMessage({ id: 'direct-message.joined-chat' })}
      </Typography>
    </Grid>
  );
};

JoinedSessionMessage.propTypes = {
  message: PropTypes.object,
};

export default JoinedSessionMessage;
