import React from 'react';
import PropTypes from 'prop-types';
import { StyledTextContainer, Triangle } from './styles';

function ChatBubble({ text, fadeIn, isResponse, coordinates, disabled, isFlipped }) {
  return !disabled ? (
    <StyledTextContainer
      disabled={!fadeIn}
      fadeIn={fadeIn}
      isResponse={isResponse}
      x={coordinates?.x}
      y={coordinates?.y}
    >
      {text}
      <Triangle isResponse={isResponse} isFlipped={isFlipped} />
    </StyledTextContainer>
  ) : null;
}
ChatBubble.propTypes = {
  text: PropTypes.string,
  coordinates: PropTypes.object,
  isResponse: PropTypes.bool,
  fadeIn: PropTypes.bool,
  disabled: PropTypes.bool,
  isFlipped: PropTypes.bool,
};

export default ChatBubble;
