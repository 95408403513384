import React from 'react';
import { useIntl } from 'react-intl';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { useHistory } from 'react-router-dom';
import { closeModal, selectCurrentModal } from 'Core_Redux/reminders';
import SuccessModal from './SuccessModal';
import { REMINDERS } from 'Core_Pages/Routes/RoutesConfig';
import { SET_SUCCESS } from './modals';

function SetReminderSuccessModal() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useCoreDispatch();
  const currentModal = useCoreSelector(selectCurrentModal);

  const handleAcknowledge = () => {
    dispatch(closeModal());
    history.push(REMINDERS);
  };

  return (
    <SuccessModal
      id={'ReminderSetSuccess'}
      open={currentModal == SET_SUCCESS}
      header={intl.formatMessage({ id: 'reminders.modal-set' })}
      content={intl.formatMessage({ id: 'notifications.soon' })}
      onClose={handleAcknowledge}
    />
  );
}

export default SetReminderSuccessModal;
