import React, { useEffect } from 'react';
import useThriveAssessment from 'Core_Hooks/useThriveAssessment';
import Question from 'Core_Components/Question/Question';
import { THRIVEASSESSMENTRESULTS } from 'Core_Pages/Routes/RoutesConfig';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { selectUserInfo } from 'Core_Redux/user';
import { selectThriveAssessment, completeThriveAssessment } from 'Core_Redux/thrive';

const ThriveAssessment = () => {
  const dispatch = useCoreDispatch();
  const user = useCoreSelector(selectUserInfo);
  const { assessments } = useCoreSelector(selectThriveAssessment);
  const { currentQuestion, questionCount, saveAnswer, isLoading, isComplete, calculateScore } = useThriveAssessment(
    assessments?.thriveAssessmentTerm,
    user.sub,
  );
  const history = useHistory();

  useEffect(() => {
    if (isComplete === true) {
      dispatch(completeThriveAssessment(calculateScore()));
      history.replace(THRIVEASSESSMENTRESULTS);
    }
  }, [isComplete]);

  return (
    <>
      <ScreenReaderWrapper>
        <h1>
          <FormattedMessage id="title.thrive-assessment" />
        </h1>
      </ScreenReaderWrapper>
      <Question
        onSubmit={saveAnswer}
        question={currentQuestion}
        hasBackground={true}
        isLoading={isLoading}
        header={{ currentQuestion: currentQuestion?.questionNumber, questionCount }}
      />
    </>
  );
};

export default ThriveAssessment;
