import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import GoogleFontIcon from 'Core_Components/GoogleFontIcon/GoogleFontIcon';
import { StyledRemindersIcon } from './styles';

function AddRemindersIcon({ onClick }) {
  const intl = useIntl();

  return (
    <StyledRemindersIcon
      onClick={onClick}
      data-testid="remindersIcon"
      aria-label={intl.formatMessage({ id: 'reminders.add-reminder' })}
    >
      <GoogleFontIcon name={GoogleFontIcon.Name.NotificationsAdd} variant={GoogleFontIcon.Variant.Outlined} />
    </StyledRemindersIcon>
  );
}

AddRemindersIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddRemindersIcon;
