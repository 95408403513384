import YesNo from './Question/YesNo';
import SingleSelect from './Question/SingleSelect';
import NumberText from './Question/NumberText';

export const WellBeingAssessmentQuestionType = {
  YESNO: 'YESNO',
  SINGLESELECT: 'SINGLESELECT',
  NUMBERTEXT: 'NUMBERTEXT',
};

export const wellBeingQuestionMap = {
  [WellBeingAssessmentQuestionType.YESNO]: YesNo,
  [WellBeingAssessmentQuestionType.SINGLESELECT]: SingleSelect,
  [WellBeingAssessmentQuestionType.NUMBERTEXT]: NumberText,
};
