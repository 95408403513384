import LogInScreen from 'Onboard_Components/Account/LogIn/LogInScreen';
import ForgotCredential from 'Onboard_Components/Account/LogIn/ForgotCredential';
import Confirmation from 'Onboard_Components/Account/LogIn/Confirmation';
import ResetPassword from 'Onboard_Components/Account/LogIn/ResetPassword';
import LogInOtp from 'Onboard_Components/Account/LogIn/LogInOtp';
import LogInTimeOut from 'Onboard_Components/Account/LogIn/LogInTimeOut';

export const LOGIN_SCREEN = 'LogInScreen';
export const FORGOT_CREDENTIAL = 'ForgotCredential';
export const CONFIRMATION = 'Confirmation';
export const RESET_PASSWORD = 'ResetPassword';
export const OTP = 'LogInOtp';
export const TIMEOUT = 'LogInTimeOut';

export const stepToComponentMap = {
  [LOGIN_SCREEN]: LogInScreen,
  [FORGOT_CREDENTIAL]: ForgotCredential,
  [CONFIRMATION]: Confirmation,
  [RESET_PASSWORD]: ResetPassword,
  [OTP]: LogInOtp,
  [TIMEOUT]: LogInTimeOut,
};
