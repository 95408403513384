import styled from 'styled-components';
import { black } from 'Styles/colors';

export const WheelContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${black._100};
  justify-content: flex-end;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.div`
  margin: 0 0 8px;
  padding: 10px 36px 10px;
  text-align: center;
  line-height: 19px;
  background-color: #f2f2f2;
  border-radius: 10px;
`;

export const Icon = styled.i`
  font-size: 25px;
  color: #2f6e6f;
`;

export const Select = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Button = styled.button`
  color: #fff;
  background: #4a4f7f;
  border-radius: 10px;
  width: 284px;
  letter-spacing: 0.009375rem;
  line-height: 1.375rem;
  text-align: center;
  border: none;
  height: 3.125rem;
  font-size: 1.115rem;
  margin-bottom: 15px;
`;

export const ChevronButton = styled.button`
  background: none;
  border: none;
`;
