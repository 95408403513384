import { interactionTypes } from 'Core_Components/Encounter/EncounterDictionary';
import { SUBMIT, LINKBUTTON } from 'Core_Components/ButtonRow/ButtonTypes.js';
import { BUBBLE, IMAGE, BUTTONROW, WHEEL } from 'Core_Components/Chat/ChatItem/ChatTypes.js';
import { LEFT, RIGHT } from 'Core_Components/Chat/ChatItem/Position.js';
import { OUT_OF_APP_URL, PHONE } from 'Core_Helpers/resourceTypes';
import { actionTypes, FOOD_FOLLOW_UP, SDOH_FOLLOW_UP } from 'Core_Helpers/actionTypes';
import { appViews, FIND_SERVICES } from 'Core_Helpers/appViews';

const typeMapping = {
  Question: BUBBLE,
  Answer: BUBBLE,
  Image: IMAGE,
  SmartButton: BUBBLE,
};

const CALL = 'call';
const HOTLINE = 'hotline';
const SUPPORT_NUMBER = '211';
const SUPPORT_URL = 'https://www.211.org/';

// Work around to prevent any breakage in current mobile app
const translateSpanishResource = (text) => {
  if (text === 'Llamar a la línea de Recursos') {
    return 'Línea de Recursos';
  }

  if (text === 'Llamar a la línea directa de Enfermeria') {
    return 'Línea de Enfermeria';
  }

  return text;
};

// Work around to account for missing pages that the PWA doesn't support
export const filterAppViews = (resource) => {
  if (resource && resource.view === appViews[FIND_SERVICES]) {
    resource.event = SUPPORT_URL;
    resource.type = OUT_OF_APP_URL;
  }

  return resource;
};

const createButtonRow = (responseChoices, resources, messageText) => {
  return {
    type: BUTTONROW,
    buttons: responseChoices.map((choice) => {
      if (choice.name.toLowerCase().includes(CALL) || choice.name.toLowerCase().includes(HOTLINE)) {
        // A temporary fix to resolve SDOH followup chat issues discovered when working on PYX-4433. A follow-up ticket PYX-4515 has been created to address developing a more robust implementation.
        const normalizedResourcesStructure = resources.followUpResources
          ? resources?.followUpResources?.find((r) => r.event === actionTypes[SDOH_FOLLOW_UP])
          : resources?.find((r) => r.event === actionTypes[SDOH_FOLLOW_UP]);
        const resource = filterAppViews(normalizedResourcesStructure);
        return {
          text: translateSpanishResource(choice.text),
          type: LINKBUTTON,
          value: choice,
          linkType: resource ? resource.type : PHONE,
          link: resource ? resource.value : SUPPORT_NUMBER,
        };
      } else if (
        (choice.name === 'Yes' || choice.name === 'Sí') &&
        (messageText.includes('food') || messageText.includes('alimentarios'))
      ) {
        const resource = filterAppViews(
          resources?.followUpResources?.find((resource) => resource.event === actionTypes[FOOD_FOLLOW_UP]),
        );
        return {
          text: choice.text,
          type: LINKBUTTON,
          value: choice,
          linkType: resource ? resource.type : OUT_OF_APP_URL,
          link: resource ? resource.value : SUPPORT_URL,
        };
      } else {
        return { text: choice.text, type: SUBMIT, value: choice };
      }
    }),
  };
};

const addAnswers = ({ responseChoices, messageText }, resources) => {
  if (responseChoices[0].json) {
    const parsedMessage = JSON.parse(responseChoices[0].json);
    if (parsedMessage.Slices) {
      return {
        type: WHEEL,
        isEnabled: true,
        responseChoices: [{ json: responseChoices[0].json }],
      };
    }
  }

  return createButtonRow(responseChoices, resources, messageText);
};

const encounterMessageToChatConfig = (chatMessage, resources) => {
  const messages = [];

  if (chatMessage?.messageText) {
    const main = {
      text: chatMessage.messageText,
      type: typeMapping[interactionTypes[chatMessage.interactionTypeId]] || BUBBLE,
      position: chatMessage.isSenderBot ? LEFT : RIGHT,
    };

    messages.push(main);

    if (chatMessage.responseChoices && chatMessage.responseChoices.length) {
      const answers = addAnswers(chatMessage, resources);
      messages.push(answers);
    }
  }

  return messages;
};

export default encounterMessageToChatConfig;
