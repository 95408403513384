import React from 'react';
import { appInsights } from 'Core_Helpers/AppInsights';
import { Collapse } from '@mui/material';
import PropTypes from 'prop-types';
import { selectLanguage } from 'Core_Redux/user';
import { useCoreSelector } from 'Contexts/StoreContext';
import { ActivityTab, StyledCard } from './styles';
import { CardTypeMap, CardTypes } from '../config';

function ActivityItem({ onClick, loading, hasPermissions, loggedEvent, cardType, ...props }) {
  const language = useCoreSelector(selectLanguage);

  const { titleId, altId, icon, icon_es, backgroundColor, imageStyle, ...rest } = props;
  const activityCardProps = { titleId, altId, icon, backgroundColor };
  const cardIconStyle = { width: '68px', ...imageStyle };

  if (language == 'es' && icon_es) {
    activityCardProps.icon = icon_es;
  }

  function onActivityClick(e) {
    appInsights.trackEvent({ name: loggedEvent });
    onClick(e);
  }

  const show = hasPermissions && !loading;
  const CardComponent = CardTypeMap[cardType];

  return (
    <Collapse appear in={show} orientation="horizontal">
      <ActivityTab
        {...rest}
        tabIndex={0}
        onClick={onActivityClick}
        icon={<CardComponent CardComponent={StyledCard} imageStyle={cardIconStyle} {...activityCardProps} />}
      />
    </Collapse>
  );
}

ActivityItem.propTypes = {
  titleId: PropTypes.string,
  altId: PropTypes.string,
  icon: PropTypes.any,
  icon_es: PropTypes.any,
  imageStyle: PropTypes.object,
  backgroundColor: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  loggedEvent: PropTypes.string,
  hasPermissions: PropTypes.bool,
  loading: PropTypes.bool,
  cardType: PropTypes.string,
};

ActivityItem.defaultProps = {
  hasPermissions: true,
  loading: false,
  cardType: CardTypes.DEFAULT,
};

export default ActivityItem;
