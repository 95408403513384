import { control } from 'Assets/img/my-tools-icons';

export const controlEn = {
  key: 'control',
  title: 'Things I Can and Can’t Control',
  titleImage: control,
  titleImageAlt: 'two thinking bubbles, one with green checkmark, one with blue letter x',
  description: 'Knowing what is within and outside of our control can help reduce stress and improve overall health.  ',
  contentId: 15,
  sections: [
    {
      key: 'control-description',
      // eslint-disable-next-line no-irregular-whitespace
      description: `Sometimes dealing with difficult situations can trigger fear and a need to control the outcomes. But trying to control the uncontrollable – how others act, work closings, illness, etc. – can take a lot of energy, create stress, and have negative results on well-being.\n\nMost of the time, the only real control we have is over ourselves. This is what we think, say, and do; attitude, mindset, and perspective all fall here. We can express our views and make efforts to influence outcomes. But, ultimately, we cannot control people’s thoughts or behaviors, or change all conditions. Getting caught up in areas we have little influence or control over can lead to frustration, feeling powerless, and burnout.\n\nKnowing what is under our control can help conserve time and energy for the things that matter. The more we focus on what we can control, the more effective we can be in accomplishing goals and tasks. The “Circle of Control” activity is a powerful tool to help focus attention and energy. The framework helps identify the areas of life you can take direct action upon to impact the outcomes. People naturally have certain areas where influence will be stronger than others. The concept is simple and straightforward: \n       • Areas within my control\n       • Areas I can influence\n       • Everything else\nIt’s an effective way to empower action towards personal goals, lessen worry and stress, support self-care, and gain long-term success.`,
    },
    {
      key: 'control-begin',
      header: 'To begin',
      description: 'Use paper and pen or your computer/device to proceed.',
    },
    {
      key: 'control-step-one',
      header: 'Step 1. Make a list of your worries or concerns. ',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'List all the concerns you have on your mind. They can be small or large, from paying bills to keeping healthy in the pandemic. Allow yourself to ‘brain dump’ your thoughts without judgement.',
        },
      ],
    },
    {
      key: 'control-step-two',
      header: 'Step 2: On another page, draw 2 large circles, side by side.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Name one of the circles “Control” and the other one “No Control.” ',
        },
      ],
    },
    {
      key: 'control-step-three',
      header:
        'Step 3: Inside the “Control” circle, write in the items from your list you have control over (or can influence)',
    },
    {
      key: 'control-step-four',
      header:
        'Step 4: Inside the “No Control” circle, write in items from your list you have no control over (or little influence)',
    },
    {
      key: 'control-step-five',
      header: 'Step 5: Review / Assessment',
      itemsHeader: 'Circle of Control',
      items: [
        {
          text: 'Review the things you can control. Are there any areas you could spend more time or energy on to benefit you?',
          subItems: [
            { text: 'In the past, how did you deal with the things that were under your personal control?' },
            { text: 'Which actions were helpful?' },
            { text: 'Which actions were less helpful?' },
          ],
        },
      ],
    },
    {
      key: 'control-step-five-no-control',
      itemsHeader: 'Circle of No Control',
      items: [
        {
          text: 'Review the things you cannot control. Consider what you would gain by letting go of worry about them. How ready/willing are you to release this worry and surrender to accepting the situation?',
          subItems: [
            { text: 'So far, how successful have you been in dealing with these areas using current strategies?' },
            { text: 'Are there any areas you could benefit more from by spending less time or energy towards?' },
            { text: 'What would it be like for you to surrender to them?' },
            { text: 'Can you think of times when you were able to let go of control and surrender?' },
            { text: 'How did you let go of the uncontrollable?' },
            { text: 'What step(s) can you take to surrender more to the uncontrollable?' },
            { text: 'If not, what is preventing you from doing so?' },
          ],
        },
      ],
    },
    {
      key: 'control-step-six',
      header: 'Step 6: Surrendering & letting go',
      description:
        'Surrendering here means deciding to let go of unproductive efforts to control the uncontrollable. It is not the same as being overtaken without choice and becoming a victim or prisoner to it. Surrendering means letting go of things you cannot control and focusing on the things you can control. It involves accepting there is nothing more you can do to change the situation.\n\nHowever, it’s not always easy to let go of certain worries at the snap of a finger. Surrender and acceptance is also a process. It can be helpful to take allow yourself some time and take steps to\n       • Validate the worry and view it as compassionately as you can\n       • Acknowledge worrying has little impact on things you have little or no control\n       • Recognize your energy would be better used elsewhere\n\nIf you catch yourself worrying about things outside of your control, practice self-kindness. Gently bring your attention back to your Circle of Control and the actions you can take to your benefit.',
    },
    {
      key: 'control-self-reflection',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your experience with this activity.',
      itemsHeader: 'Insights & pointers',
      items: [
        {
          text: 'What have you learned from this exercise?',
        },
        {
          text: 'How did you feel before completing this exercise?',
        },
        {
          text: 'How do you feel after it?',
        },
        {
          text: 'What part(s) of this exercise did you find most challenging? Why?',
        },
        {
          text: 'What did you enjoy most about this exercise?',
        },
        {
          text: 'What would be some benefits of doing this activity more often?',
        },
      ],
    },
    {
      key: 'control-wrap-up',
      description:
        'Research has shown that although it is important to be aware of the limits of personal control, it is equally important to be aware of the ability to influence situations to your benefit.\n\nConsciously choosing to spend your time on what you can control and influence can be empowering and create results with more meaningful impact.\n\nWhen things are outside of personal control, the aim is to minimize the amount of time, energy, and attention given them.\n\nLetting go and acceptance can be easier said than done. It is an ongoing practice and exploration of personal values to shape results, decisions, and direction.',
    },
  ],
};
