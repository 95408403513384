import { teal } from 'Styles/colors';

export const itemTypes = {
  DO: 'DO',
  PLAY: 'PLAY',
};

export const toolCardItemConfig = {
  [itemTypes.DO]: {
    backgroundColor: `${teal._300}`,
    titleId: 'tools.actions.do',
  },
  [itemTypes.PLAY]: {
    backgroundColor: '#F7941D',
    titleId: 'tools.actions.play',
  },
};
