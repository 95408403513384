import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Answer from 'Stories/components/Form/Answer/Answer';
import { QuestionWrapper, AnswerWrapper } from 'Stories/components/Form/styles';
import { Typography } from '@mui/material';
import { StyledYesNoQuestion } from './yesNoStyles';
import SkipButton from './components/SkipButton';
import Button from 'Stories/components/Button';

const YesNo = ({ name, text, skipOption, onChange, ...props }) => {
  const intl = useIntl();
  const [selected, setSelected] = useState('');
  const updateSelection = (chosenOption) => {
    setSelected(chosenOption);
  };
  const continueClicked = () => {
    onChange(selected);
    setSelected('');
  };

  return (
    <StyledYesNoQuestion>
      <QuestionWrapper className="FormQuestion-root">
        <Typography id={`${name}-question`} className="FormQuestion-question">
          {text}
        </Typography>
        <AnswerWrapper id={`${name}-question-answers`} className="FormAnswer-root">
          <Answer questionName={name} updateSelection={updateSelection} {...props} />
        </AnswerWrapper>
        <SkipButton skipOption={skipOption} onChange={onChange} />
      </QuestionWrapper>
      <Button style={{ width: '100%' }} onClick={continueClicked} disabled={!selected}>
        {intl.formatMessage({ id: 'common.continue' })}
      </Button>
    </StyledYesNoQuestion>
  );
};

YesNo.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  answerType: PropTypes.string,
  options: PropTypes.array,
  skipOption: PropTypes.object,
  onChange: PropTypes.func,
  filteredOptions: PropTypes.array,
};

export default YesNo;
