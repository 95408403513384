import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { black } from 'Styles/colors';

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Content = styled(Grid)`
  padding-top: 20px;
`;

export const SectionHeader = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: ${black._87};

  padding: 0px 15px;
`;
