export const animationType = {
  // Character (Pet) Animations
  BEG: 'BEG',
  HUNGRY: 'HUNGRY',
  THIRSTY: 'THIRSTY',
  EAT: 'EAT',
  DRINK: 'DRINK',
  WATCH: 'WATCH',
  IDLE: 'IDLE',
  IDLE3: 'IDLE3',
  SIT: 'SIT',
  RUN: 'RUN',
  RUNAWAY: 'RUNAWAY',
  RUNTOWARDS: 'RUNTOWARDS',
  BOUND: 'BOUND',
  HALFTURN: 'HALFTURN',
  TURN: 'TURN',
  TURN_TO_ANGLE: 'TURN_TO_ANGLE',
  FRONTTURN_HALF: 'FRONTTURN_HALF',
  BACKTURN: 'BACKTURN',
  BACKTURN_HALF: 'BACKTURN_HALF',
  BACKTURN_PARTIAL: 'BACKTURN_PARTIAL',
  BACKTURN_FACEAWAY: 'BACKTURN_FACEAWAY',
  JUMP: 'JUMP',
  JUMP_FORWARD: 'JUMP_FORWARD',
  LAND_FORWARD: 'LAND_FORWARD',
  JUMP_BACK: 'JUMP_BACK',
  LAND_BACK: 'LAND_BACK',
  WALK: 'WALK',
  EATTREAT: 'EATTREAT',
  NUDGE: 'NUDGE',
  POUNCE: 'POUNCE',
  LAND: 'LAND',
  WALK2: 'WALK2',
  WAVE: 'WAVE',
  WAVE2: 'WAVE2',
  LAY: 'LAY',
  SILLY: 'SILLY',
  SLEEP: 'SLEEP',
  BEND: 'BEND',
  WALK_FRONT: 'WALK_FRONT',
  WALK_BACK: 'WALK_BACK',
  WALK_BACK_ANGLE: 'WALK_BACK_ANGLE',
  WALK_FRONT_ANGLE: 'WALK_FRONT_ANGLE',
  // Interact
  OBJECT_INTERACT: 'OBJECT_INTERACT',
  OBJECT_INTERACT1: 'OBJECT_INTERACT1',
  OBJECT_INTERACT2: 'OBJECT_INTERACT2',
  OBJECT_INTERACT3: 'OBJECT_INTERACT3',
  // transitions
  OBJECT_INTERACT_TRANSITION: 'OBJECT_INTERACT_TRANSITION',
  // Object Animations
  FILL: 'FILL',
  IDLE2: 'IDLE2',
  IDLE4: 'IDLE4',
  IDLE5: 'IDLE5',
  IDLE6: 'IDLE6',
  DRINKHALF: 'DRINKHALF',
  DRINKREMAINING: 'DRINKREMAINING',
  // Nutrition Animations
  SITBENDEAT: 'SITBENDEAT',
  SITBENDDRINK: 'SITBENDDRINK',
  SITBENDEATFINISH: 'SITBENDEATFINISH',
  SITBENDDRINKFINISH: 'SITBENDDRINKFINISH',
  //Attention Antimations
  STAND_ATTENTION_TRANSITION: 'STAND_ATTENTION_TRANSITION',
  PENDING_ATTENTION: 'PENDING_ATTENTION',
  ATTENTION: 'ATTENTION',
  PENDING_SNOOZE: 'PENDING_SNOOZE',
  SNOOZE: 'SNOOZE',
  // Poses
  POSE_HAPPY: 'POSE_HAPPY',
  POSE_PROUD: 'POSE_PROUD',
  POSE_SAD: 'POSE_SAD',
  // Treat Boxes
  PENDING_TREAT_THROW: 'PENDING_TREAT_THROW',
  STEPINTO: 'STEPINTO',
};
