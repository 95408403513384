import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { StyledButton, StyledContent, StyledHeader, StyledModalBodyContainer, StyledModalContainer } from './styles';

function SuccessModal({ id, open, header, content, onClose }) {
  const intl = useIntl();

  return (
    <StyledModalContainer
      id={`${id}_Success`}
      open={open || false}
      aria-labelledby={`${id}_Success_Title`}
      aria-describedby={`${id}_Success_Content`}
    >
      <StyledModalBodyContainer>
        <StyledHeader id={`${id}_Success_Title`}>{header}</StyledHeader>
        <StyledContent id={`${id}_Success_Content`}>{content}</StyledContent>
        <StyledButton onClick={onClose}>{intl.formatMessage({ id: 'common.got-it-exclamation' })}</StyledButton>
      </StyledModalBodyContainer>
    </StyledModalContainer>
  );
}

SuccessModal.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
SuccessModal.defaultProps = {
  open: false,
};

export default SuccessModal;
