import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import {
  navigateBack,
  selectSignupMetadata,
  completeParentalStatusEntry,
  skipParentalStatus,
} from 'Onboard_Redux/signup';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { Header, Select, StyledDateInput } from 'Onboard_Components/Account/styles';
import { ArrowBackIos } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, FormGroup } from '@mui/material';
import Button from 'Stories/components/Button';
import SkipButton from 'Onboard_Components/Account/SignUp/SkipButton';
import { purple } from 'Styles/colors';
import { Label, StyledCheckbox } from './styles';

function ParentalStatus() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const { progress } = useOnboardSelector(selectSignupMetadata);
  const {
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm();
  const isPregnant = watch('isPregnant');
  const [isPending, setIsPending] = useState(false);

  function onBack() {
    dispatch(navigateBack());
  }

  function onSkip() {
    setIsPending(true);
    dispatch(skipParentalStatus());
  }

  const parentalStatusOptions = [
    { status: intl.formatMessage({ id: 'account.sign-up.parental-status-none' }), code: 'NOCHILDREN' },
    { status: intl.formatMessage({ id: 'account.sign-up.parental-status-one' }), code: 'ONECHILD' },
    { status: intl.formatMessage({ id: 'account.sign-up.parental-status-two' }), code: 'TWOCHILDREN' },
    { status: intl.formatMessage({ id: 'account.sign-up.parental-status-three-plus' }), code: 'THREEORMORECHILDREN' },
    { status: intl.formatMessage({ id: 'account.sign-up.prefer-not-to-specify' }), code: 'PREFERNOTTOSAY' },
  ];

  const formComplete = dirtyFields.parentalStatus && (!isPregnant || dirtyFields.pregnancyDueDate);

  function onSubmit(data) {
    setIsPending(true);
    dispatch(
      completeParentalStatusEntry({
        isPregnant: data?.isPregnant,
        pregnancyDueDate: data?.pregnancyDueDate,
        parentalStatus: data?.parentalStatus,
      }),
    );
  }

  const minDate = new Date(new Date().setHours(0, 0, 0, 0));

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <Header>
        <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />
        {intl.formatMessage({ id: 'account.sign-up.a-little-about-you' })}
      </Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="isPregnant"
          control={control}
          defaultValue={false}
          render={({ field: { onChange } }) => {
            return (
              <FormGroup sx={{ marginBottom: '10px' }}>
                <Label
                  control={<StyledCheckbox color="secondary" onChange={(e) => onChange(e.target.checked)} />}
                  label={intl.formatMessage({ id: 'account.sign-up.parental-status-pregnant' })}
                />
              </FormGroup>
            );
          }}
        />
        {isPregnant && (
          <Controller
            name="pregnancyDueDate"
            control={control}
            defaultValue=""
            rules={{
              validate: (v) => {
                const enteredDate = new Date(v);
                return (
                  (!isNaN(enteredDate) && minDate <= enteredDate) || intl.formatMessage({ id: 'error.invalid-input' })
                );
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <StyledDateInput
                {...field}
                disablePast
                views={['day']}
                error={!!errors.pregnancyDueDate?.message}
                errorMessage={errors.pregnancyDueDate?.message}
                slotProps={{ textField: { error: !!error, helperText: error?.message } }}
                label={intl.formatMessage({ id: 'account.sign-up.parental-status-due-date' })}
              />
            )}
          />
        )}
        <Controller
          name="parentalStatus"
          control={control}
          defaultValue=""
          render={({ field: { value } }) => (
            <>
              <FormControl fullWidth variant="filled">
                <InputLabel id="parentalStatusSelectLabel" sx={{ color: purple._900 }}>
                  {intl.formatMessage({ id: 'account.sign-up.parental-status_prompt' })}
                </InputLabel>
                <Select
                  labelId="parentalStatusSelectLabel"
                  onChange={(data) => {
                    if (!isPregnant) {
                      setValue('pregnancyDueDate', ' ');
                    }
                    setValue('parentalStatus', data.target.value, { shouldDirty: true });
                  }}
                  value={value}
                >
                  {parentalStatusOptions.map((o) => (
                    <MenuItem key={o.code} value={o.code}>
                      {o.status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        />
        <Button
          fullWidth
          type="submit"
          disabled={!formComplete || isPending}
          loading={isPending}
          sx={{ margin: '8px 0' }}
        >
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
      <SkipButton disabled={isPending} onClick={onSkip}>
        {intl.formatMessage({ id: 'common.skip' })}
      </SkipButton>
    </>
  );
}

export default ParentalStatus;
