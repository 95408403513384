import { learningEmpathy } from 'Assets/img/my-tools-icons';

export const learningEmpathyEn = {
  key: 'learning-empathy',
  title: 'Learning Empathy',
  titleImage: learningEmpathy,
  titleImageAlt: '',
  contentId: 49,
  sections: [
    {
      key: 'learning-empathy-intro',
      description:
        'What is empathy and why is it important? The ability to provide empathy is one of the greatest gifts that you can give others. Think about a time when you felt stressed or frustrated and you talked to someone about it. Then, instead of providing empathy, they offered you several solutions to “solve” your stress or tell you why you should not be upset. What would a better response have been? Perhaps a more empathetic one? Providing and receiving empathy can help you feel less lonely by improving the way that you connect with others and yourself. Here are some ways to learn about and practice being more empathetic.',
    },
    {
      key: 'empathy-everyone',
      header: 'Empathy is for Everyone',
      description:
        'Thankfully, empathy is not a genetic or inherited skill, it is something that anyone can learn. Sometimes people think that empathy is not important, or it does not push another person to be better. Here are a few reasons why empathy is important.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'When you provide an empathetic response, you allow the other person to feel validated',
        },
        {
          text: 'Providing an empathetic response encourages the other person to continue to talk more and allow them a safe space to share more about what they are feeling',
        },
        {
          text: 'Offering empathy provides a space for the other person to determine what will make them feel better',
        },
        {
          text: 'In parenting, empathy can help the child to feel more independent and confident they can come up with a better outcome–without you telling them what they should do',
        },
      ],
    },
    {
      key: 'learning-about-empathy-sec',
      header: 'Learning about Empathy',
      description:
        'Empathy is allowing yourself to think about what the other person is saying or feeling from their perspective. It is human nature to want to offer a solution when someone you care about may be feeling upset. How you offer support and respond to others can bring you a greater sense of peace and can lead to feeling less isolated or alone. The good news is there are several different types of empathetic responses!',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Scenario: You tell someone you trust that you are feeling stressed and upset.',
        },
        {
          text: 'Here are a few different types of empathetic responses that could be used:',
          subItems: [
            { text: 'Rephrasing with Empathy: I’m sorry to hear that you are feeling stressed.' },
            { text: 'Validation: That sounds really frustrating.' },
            { text: 'Perspective Analysis: It’s understandable that you are upset about that.' },
            { text: 'Gratitude: Thank you for trusting me and sharing this with me.' },
            {
              text: 'Encouraging Self-Reflection: Do you feel comfortable telling me more about why this makes you feel upset?',
            },
          ],
        },
      ],
    },
    {
      key: 'practicing-empathy',
      header: 'Practicing Empathy',
      description:
        'Below are a few scenarios followed by an example empathetic response. Review each example and think about or write down what you think an empathetic response could be. Keep in mind that each scenario could have multiple empathetic responses and that our example is not the only “right” one.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Scenario: I am so worried about my upcoming math test. I do not understand this stuff.',
          subItems: [
            {
              text: 'Empathetic Response: I am sorry to hear that you are worried about it. Can you tell me more about what parts you are having a hard time understanding?',
            },
          ],
        },
        {
          text: 'Scenario: No one wants to hang out with me. I am horrible at making friends.',
          subItems: [
            {
              text: 'Empathetic Response: Thank you for telling be about this. Making friends can be challenging and sometimes does not happen a quickly as we want it to.',
            },
          ],
        },
        {
          text: 'Scenario: My neighbor told me that I need to control my kids better. Can you believe that?',
          subItems: [
            {
              text: 'Empathetic Response: I am sorry that you are having to listen to that. Feeling judged by others, who do not know what you are going through, can be really frustrating.',
            },
          ],
        },
        {
          text: 'Scenario: I cannot seem to find time to complete all of my daily tasks. There are not enough hours in the day.',
          subItems: [
            {
              text: 'Empathetic Response: That sounds frustrating. It can be challenging to find time in our busy lives. Do you feel like there have been times when you have been great at completing your daily tasks?',
            },
          ],
        },
        {
          text: 'Scenario: I feel sick and cannot seem to get better.',
          subItems: [
            {
              text: 'Empathetic Response: I’m sorry you do not feel well. Let me know if there is anything that I can do to help make you more comfortable.',
            },
          ],
        },
      ],
    },
  ],
};
