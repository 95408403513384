import { gratitudeLetter } from 'Assets/img/my-tools-icons';

export const gratitudeLetterEn = {
  key: 'gratitude-letter',
  title: 'Gratitude Letter',
  titleImage: gratitudeLetter,
  titleImageAlt: '',
  description: 'Being thankful to others through a “gratitude letter” is a great way to help feel uplifted!',
  contentId: 4,
  sections: [
    {
      key: 'think-grateful',
      header: 'Think of someone you’re grateful for in your life.',
      description:
        'Today, think of someone who has had a positive influence on your life. Who is someone who has been helpful or kind to you, or acted in a way that made you feel thankful?',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'These people might be a family member, friend, or someone else like a teacher, or perhaps a food server, or even an actor!',
        },
        {
          text: 'Think about how this person has affected your life... what life is like because of them.',
        },
        { text: 'How has their behavior impacted you?' },
        { text: 'How do you feel when you think of them?' },
      ],
    },
    {
      key: 'write-letter',
      header: 'Write a letter to them about why you are thankful for them.',
      description: 'Use paper and pen or type your letter on a computer/device.',
      itemsHeader: 'Tell me more',
      items: [
        { text: 'Your gratitude letter does not need to be long... and spelling or grammar don’t matter!' },
        {
          text: 'Take 10-20 minutes to write the letter expressing your gratitude for what they gave to your life. ',
        },
        { text: 'It’s okay to start with just 1 or 2 things for now.' },
        { text: 'You can write a gratitude letter as many times as you want to on your Thrive Pathway!' },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your experience doing this activity.',
      itemsHeader: 'Insights & pointers',
      items: [
        { text: 'How do you feel before completing this exercise?' },
        { text: 'How do you feel after it?' },
        { text: 'What part(s) of this exercise did you find most challenging? Why?' },
        { text: 'What did you enjoy most about this exercise?', divider: true },
        {
          text: 'Expressing gratitude to the people that make you feel good provides an opportunity to enhance the advantages of well-being – and supports long-lasting health benefits!',
        },
        {
          text: 'When your gratitude letter is done, you could send it to the person, or deliver it personally if you would like to.',
        },
        {
          text: 'And, if possible, ask them to read it while you are with them to share your appreciation and grow your relationship even further!',
        },
      ],
    },
  ],
};
