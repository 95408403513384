import { useState } from 'react';
import getAuthClient from 'Core_Helpers/getAuthClient';
import urlcat from 'urlcat';

const cardMessagesUrl = '/v1/cards/messages';

const getCardsMessages = async (url) => {
  const authorizedApiClient = await getAuthClient();
  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetCardsMessages = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getMessages = async (options = {}) => {
    try {
      setIsLoading(true);
      const messages = await getCardsMessages(urlcat(process.env.API_BASE_URL, cardMessagesUrl, options));
      setIsLoading(false);
      return messages;
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  };
  return { isLoading, getMessages };
};

export default useGetCardsMessages;
