import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { StyledCard } from '../styles';
import { purple } from 'Styles/colors';

export const StyledNoRemindersCard = styled(StyledCard)`
  padding: 37px 0;
  text-align: center;
`;

export const StyledText = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin: 0 48px 29px;
`;

export const StyledAddReminderButton = styled(Button)`
  background-color: ${purple._900};
  border-radius: 15px;
  font-weight: 700;
  font-size: 16px;
  text-transform: none;
`;
