import React from 'react';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import { Route, Switch } from 'react-router-dom';
import RemindersHome from './Home/RemindersHome';
import CreateReminder from 'Core_Pages/Reminders/Create/CreateReminder';
import EditReminder from 'Core_Pages/Reminders/Edit/EditReminder';

const Reminders = () => {
  return (
    <Switch>
      <Route exact path={paths.REMINDERS_CREATE}>
        <CreateReminder />
      </Route>
      <Route exact path={paths.REMINDERS_EDIT}>
        <EditReminder />
      </Route>
      <Route exact path={paths.REMINDERS}>
        <RemindersHome />
      </Route>
    </Switch>
  );
};

export default Reminders;
