import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledTextField } from './styles';

const Textbox = forwardRef(({ inputProps, ...props }, ref) => {
  const extraInputProps = { 'aria-labelledby': props['aria-labelledby'] };
  return (
    <StyledTextField
      className="FormAnswer-answer-textbox"
      inputProps={{ ...inputProps, ...extraInputProps }}
      ref={ref}
      {...props}
    />
  );
});

Textbox.displayName = 'Textbox';

Textbox.propTypes = {
  inputProps: PropTypes.object,
  ref: PropTypes.object,
  ['aria-labelledby']: PropTypes.string,
};

export default Textbox;
