import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const StyledParagraph = styled.p`
  color: #000000;
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.5px;
`;

export const StyledImageFieldset = styled.fieldset`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 300px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const HiddenRadio = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

export const RadioContainer = styled.span`
  flex: 1;
  flex-basis: 50%;
  max-height: 130px;
  max-width: 130px;
  padding: 5px;
  background: ${(props) => (props.active ? 'HSLA(221, 100%, 50%, 0.05)' : 'none')};
  box-shadow: ${(props) => (props.active ? '0 0 0 3px HSL(216, 100%, 50%)' : 'none')};
  border-radius: 12px;
`;
