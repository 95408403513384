import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { questions } from './config';
import { ScreeningModuleStore } from 'Core_Pages/Screening/ScreeningModuleStore';
import { StyledCircularProgress } from 'Core_Components/LoadingWrapper/styles';
import ErrorModal from 'Core_Components/ErrorModal/ErrorModal';
import { WellBeingWrapper, WellBeingProgressBar } from './styles';
import { displayDarkStatusBar } from 'Core_Helpers/statusBar';
import { wellBeingQuestionMap } from './wellbeingQuestionType';

function AdultWellBeingAssessment() {
  const intl = useIntl();
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const screeningTakerStore = screeningStore.ScreeningForm.Use();
  const { control } = useForm();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const isLoading = screeningTakerStore.IsLoading || screeningStore.IsLoading;
  const hasError = screeningStore.ScreeningHasError;

  const onCloseErrorModal = () => screeningStore.acknowledgeError();
  const onAnswer = (question, answer) => {
    const nextQuestion = currentQuestion + 1;
    screeningTakerStore.updateAnswer(question, answer);

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(nextQuestion);
    }

    if (screeningTakerStore.IsValid) {
      screeningTakerStore.complete();
    }
  };

  useEffect(() => {
    displayDarkStatusBar();
  }, []);

  if (isLoading) {
    return <StyledCircularProgress />;
  }

  const Component = wellBeingQuestionMap[questions[currentQuestion]?.questionType];
  const filteredOptions = questions[currentQuestion]?.options;

  return (
    <>
      <WellBeingWrapper fullScreen open={true} aria-labelledby="screening-title">
        <WellBeingProgressBar variant="determinate" value={currentQuestion * 20} />
        <Typography id="screening-title" component="h1">
          {intl.formatMessage({ id: 'screenings.adult-well-being' })}
        </Typography>
        <Component
          control={control}
          text={intl.formatMessage({ id: `${questions[currentQuestion]?.textId}` })}
          answerType={questions[currentQuestion]?.answerType}
          filteredOptions={filteredOptions}
          onChange={(answer) => {
            const answerOptionGuid = answer.currentTarget?.name;
            onAnswer(questions[currentQuestion]?.name, answerOptionGuid ? answerOptionGuid : answer);
          }}
          {...questions[currentQuestion]}
        />
      </WellBeingWrapper>
      <ErrorModal open={hasError} onClose={onCloseErrorModal} />
    </>
  );
}

export default AdultWellBeingAssessment;
