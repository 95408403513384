import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ResourceContainer, ResourcesCard } from '../../styles';
import { appInsights } from 'Core_Helpers/AppInsights';
import ResourceButton from './ResourceButton/ResourceButton';
import { useIntl } from 'react-intl';

const ResourceText = {
  [ResourceButton.variant.LINK]: 'common.go',
  [ResourceButton.variant.PHONE]: 'common.call',
};

const ResultsResources = ({ resources, title, memberType }) => {
  const intl = useIntl();

  const onClick = (resourceTitle) => {
    appInsights.trackEvent({ name: `Screening_Results_${memberType}_${resourceTitle}_Resource_Clicked` });
  };

  return (
    <ResourcesCard>
      <Typography fontSize={18} fontWeight={700}>
        {title}
      </Typography>
      {resources?.map(({ title: resourceTitle, type, value }, index) => (
        <ResourceContainer className="ResourceContainer" key={index}>
          <Typography fontSize={18} fontWeight={700}>
            {resourceTitle}
          </Typography>
          {Object.keys(ResourceText).includes(type) && (
            <ResourceButton onClick={() => onClick(resourceTitle)} type={type} value={value}>
              {intl.formatMessage({ id: ResourceText[type] })}
            </ResourceButton>
          )}
        </ResourceContainer>
      ))}
    </ResourcesCard>
  );
};

ResultsResources.propTypes = {
  title: PropTypes.string,
  memberType: PropTypes.string.isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.oneOf(Object.keys(ResourceText)),
    }),
  ),
};

export default ResultsResources;
