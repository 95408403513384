import React from 'react';
import PropTypes from 'prop-types';
import Greeting from 'Core_Components/Greeting/Greeting';
import { StyledLayoutContainer } from './styles';

function HiFriendLayout({ greetingMessage, children }) {
  return (
    <>
      <Greeting message={greetingMessage} />
      <StyledLayoutContainer>{children}</StyledLayoutContainer>
    </>
  );
}

HiFriendLayout.propTypes = {
  greetingMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node.isRequired,
};

export default HiFriendLayout;
