import { caringConnections } from 'Assets/img/my-tools-icons';

export const threeCaringConnectionsEn = {
  key: 'three-caring-connections',
  title: '3 Caring Connections',
  titleImage: caringConnections,
  titleImageAlt: '',
  description:
    'Positive interactions with others can help enhance feelings of whole-hearted well-being and social connection!',
  contentId: 3,
  sections: [
    {
      key: 'three-meaningful',
      header: 'Each day for the next week, make 3 meaningful interactions with other people.',
      description: 'Look for 3 opportunities to interact and connect with others over the next week.',
      itemsHeader: 'Tell me more',
      items: [
        { text: 'The people you interact with is up to you – friends, relatives, colleagues, or even strangers.' },
        { text: 'Your interactions can be with 3 different people or the same person.' },
        { text: 'Approach interactions with warmth, respect, and good will towards the other person.' },
        {
          text: 'Make an attempt to stay present, respectfully listen to the other person with an open heart, and use eye contact.',
        },
        { text: 'Try to engage with light-hearted thoughts and feelings.' },
      ],
    },
    {
      key: 'recall-interactions',
      header: 'Then each night before bed, recall your interactions.',
      description:
        'Call to mind your 3 interactions and assess your connection to the other person during the interaction.',
      itemsHeader: 'Tell me more',
      items: [
        { text: 'Think of how connected you felt to the other person during your interactions.' },
        {
          text: 'Then, either in your head or on paper, use a rating scale from 1 to 7 where “1” stands for “Not true at all” and “7” stands for “Very true,” rank your connection to the other person about feeling...',
          subItems: [
            { text: '‘In tune’ with the other person/people' },
            { text: 'Closeness to the other person/people' },
          ],
        },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your experience with this activity.',
      itemsHeader: 'Insights & pointers',
      items: [
        { text: 'Why did you feel connected to the other person?' },
        { text: 'Why did you not feel so connected to the other person?' },
        { text: 'What are possible things you could do next time to enhance your connection(s).' },
        { text: 'What have you learned from this exercise?' },
        { text: 'How did you feel before completing this exercise?' },
        { text: 'How do you feel after it?' },
        { text: 'What part(s) of this exercise did you find most challenging? Why?' },
        { text: 'What did you enjoy most about this exercise?' },
        { text: 'Which aspects/elements of the challenge/goal do you want to work on first?' },
        { text: 'What would help make the first step easier?' },
        { text: 'What would be some benefits of people doing this activity more often?', divider: true },
        {
          text: 'Creating meaningful interactions with others, even in small, ‘micro-moments,’ can support well-being and boost daily positive emotions.',
        },
        {
          text: 'Interacting with others in a way that feels ‘in tune’ or close with them can help generate positive emotions.',
        },
      ],
    },
  ],
};
