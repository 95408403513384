import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { CheckinModuleStore } from './CheckinModuleStore';
import {
  CheckinsContainer,
  DialogHeader,
  StyledCloseIconContainer,
  CompletedContainer,
  ButtonInfoLink,
} from './styles';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';
import CloseIcon from '@mui/icons-material/Close';
import { useIntl } from 'react-intl';
import LoadingWrapper from 'Core_Components/LoadingWrapper/LoadingWrapper';
import { celebration } from 'Assets/img/screening-icons';
import InfoDialog from './InfoDialog/InfoDialog';
import ScreeningStepper from './ScreeningStepper/ScreeningStepper';
import Button from 'Stories/components/Button';

function Checkin() {
  const history = useHistory();
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [infoOpened, setInfoOpened] = useState(false);
  const checkinModuleStore = CheckinModuleStore.Instance().Use();
  const availableScreenings = checkinModuleStore.AvailableScreenings;
  const nextScreening = availableScreenings?.length - (checkinModuleStore.DueScreeningsCount ?? 0);
  const hasCompletedScreenings = !loading && availableScreenings.length === 0;

  const hydrateCheckinStore = async () => {
    await checkinModuleStore.hydrateDueScreenings();
    await checkinModuleStore.hydrateScreeningScores();
  };

  useEffect(() => {
    hydrateCheckinStore().then(() => {
      setLoading(false);
    });
  }, []);

  const onCloseCheckins = () => {
    history.push(HOME);
  };

  return (
    <CheckinsContainer fullScreen open={true} aria-labelledby="checkins-title" className="safe-area">
      <DialogHeader>
        <Typography id="checkins-title" component="h1" fontSize={24} fontWeight={700}>
          {intl.formatMessage({ id: 'home.activities.check-ins' })}
        </Typography>
        <StyledCloseIconContainer aria-label={intl.formatMessage({ id: 'button.close' })} onClick={onCloseCheckins}>
          <CloseIcon fontSize="inherit" fontWeight="inherit" />
        </StyledCloseIconContainer>
      </DialogHeader>
      <LoadingWrapper loading={loading}>
        {hasCompletedScreenings ? (
          <CompletedContainer>
            <Typography fontSize={24}>{intl.formatMessage({ id: 'screenings.check-in.completed' })}</Typography>
            <Typography fontSize={18}>{intl.formatMessage({ id: 'screenings.check-in.check-later' })}</Typography>
            <img src={celebration} alt="" />
          </CompletedContainer>
        ) : (
          <>
            <ScreeningStepper
              screenings={availableScreenings}
              nextScreening={nextScreening}
              onClickCheckin={checkinModuleStore.startCheckin}
            />
            <ButtonInfoLink id="InfoButton" onClick={() => setInfoOpened(true)} variant={Button.variant.TEXT}>
              {intl.formatMessage({ id: 'screenings.check-in.questionnaire-info' })}
            </ButtonInfoLink>
            <InfoDialog open={infoOpened} onClose={() => setInfoOpened(false)} />
          </>
        )}
      </LoadingWrapper>
    </CheckinsContainer>
  );
}

export default Checkin;
