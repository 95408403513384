import { animationType } from 'Core_Games/constants/animationType';
import { addCharacterActor } from './characterHelper';
import { getBackgroundPagePoint, getZoomScale, splitBackgrounds } from './transformationHelper';

export const addCharacterToScene = (_this, startCoordinates, characterType, characterScale, flipX) => {
  let coordinates = {
    x: startCoordinates.x ? startCoordinates.x : 0,
    y: startCoordinates.y ? startCoordinates.y : 0,
  };
  let currentCharacter = addCharacterActor(_this, coordinates.x, coordinates.y, characterType);
  currentCharacter.scaleActor(characterScale);
  currentCharacter.play(animationType.IDLE);
  currentCharacter.enablePhysics(_this);
  currentCharacter.flipX = flipX;

  return currentCharacter;
};

export const addBackgroundsToScene = (_this) => {
  // split backgrounds up based on config (scalar framework)
  const isCombined = _this.combinedBackground ? true : false;
  let currentBackgroundsToSplit = isCombined ? [_this.combinedBackground] : _this.backgrounds;

  _this.backgroundBoundingBoxes = splitBackgrounds(
    currentBackgroundsToSplit,
    _this.bounds.height.start,
    _this.bounds.height.end,
    _this.bounds.width.start,
    _this.bounds.width.end,
    _this.backgroundSplit,
    _this.bounds.gap.width,
  );

  // set up backgrounds (set up display size & origin, set max height/width, and get the device required zoom scale)
  _this.maxHeight = 0;
  _this.maxWidth = 0;
  for (let i = 0; i < _this.backgroundBoundingBoxes.length; i++) {
    let currentBackgroundData = _this.backgroundBoundingBoxes[i].data.data;
    if (!isCombined) {
      currentBackgroundData.x = 0;
      currentBackgroundData.y = 0;
      if (currentBackgroundData.isScaled) {
        currentBackgroundData.setDisplaySize(currentBackgroundData.displayWidth, currentBackgroundData.displayHeight);
      } else {
        currentBackgroundData.setDisplaySize(currentBackgroundData.width, currentBackgroundData.height);
      }

      currentBackgroundData.setOrigin(0, 0);
    }

    if (currentBackgroundData.isScaled) {
      if (_this.maxHeight < currentBackgroundData.displayHeight) {
        _this.maxHeight = currentBackgroundData.displayHeight;
      }
      if (_this.maxWidth < currentBackgroundData.displayWidth) {
        _this.maxWidth = currentBackgroundData.displayWidth;
      }
    } else {
      if (_this.maxHeight < currentBackgroundData.height) {
        _this.maxHeight = currentBackgroundData.height;
      }
      if (_this.maxWidth < currentBackgroundData.width) {
        _this.maxWidth = currentBackgroundData.width;
      }
    }
  }

  if (isCombined) {
    _this.maxWidth = _this.combinedBackground.width;
    _this.maxHeight = _this.combinedBackground.height;
  }

  // get zoom scale for initial page
  _this.zoomScaleInitial = getZoomScale(_this, _this.backgrounds[0], _this.backgroundBoundingBoxes, isCombined);
};

export const useCamera = (_this, actor, isCombined, cameraStartPercentage, index = 0) => {
  const currentBackground = isCombined ? _this.backgrounds[index].data : _this.background;
  const currentBackgroundBoundingBoxes = isCombined
    ? _this.backgroundBoundingBoxes[index].boundingBoxes[0]
    : _this.backgroundBoundingBoxes[0].boundingBoxes[index];
  // set camera
  _this.cameras.main.setBounds(0, 0, _this.maxWidth, _this.maxHeight);
  _this.physics.world.setBounds(
    -(actor.displayWidth * 0.5),
    _this.allowBoundPadding ? -(actor.displayHeight * 0.5) : 0,
    _this.maxWidth + actor.displayWidth,
    _this.allowBoundPadding ? _this.maxHeight + actor.displayHeight : _this.maxHeight,
  );

  const zoomBasedStartY =
    cameraStartPercentage && cameraStartPercentage.zoomBasedStartY ? 1.0 - _this.zoomScaleInitial.zoom : undefined;

  const cameraStartLocationPercentage = {
    x: cameraStartPercentage && cameraStartPercentage.x ? cameraStartPercentage.x : 0.0,
    y: zoomBasedStartY
      ? zoomBasedStartY
      : cameraStartPercentage && cameraStartPercentage.y
      ? cameraStartPercentage.y
      : 0.0,
  };

  const currentCamLocation = getBackgroundPagePoint(
    _this,
    currentBackground,
    _this.backgroundBoundingBoxes,
    cameraStartLocationPercentage.x,
    cameraStartLocationPercentage.y,
    index,
    true,
  );
  _this.currentCamLocation = currentCamLocation;
  _this.cameras.main.zoomTo(_this.zoomScaleInitial.zoom, 0);

  const extraX = _this.zoomScaleInitial.isMax
    ? 0
    : Math.abs(_this.cameras.main.worldView.width * _this.zoomScaleInitial.zoom * currentCamLocation.percentage.x);

  _this.cameras.main.scrollX = currentCamLocation.absolute.x - extraX;

  // Adjust scroll Y based on zoom according to background to height ratio
  // TODO - create a concrete equation for finding the center of the min bounding box based on zoom
  // (most likely should be done after zoom equation adjusted)

  const zoomFactor = _this.zoomScaleInitial.isMax
    ? _this.zoomScaleInitial.zoom - 1.0
    : 1.0 - _this.zoomScaleInitial.zoom;
  const extraY = _this.cameras.main.worldView.height * zoomFactor * currentCamLocation.percentage.y;
  _this.cameras.main.scrollY =
    currentCamLocation.absolute.y -
    currentBackground.height *
      (1.0 - currentBackgroundBoundingBoxes.min.end.y) *
      ((_this.game.renderer.height / currentBackground.height) * _this.zoomScaleInitial.zoom) +
    extraY;
};
