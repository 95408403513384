import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ResultContext, ResultStates } from '../ResultContext';
import { Forms } from '../../forms';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { StyledSegmentShapeWrapper } from '../../styles';
import SegmentShape from '../../components/SegmentShape/SegmentShape';
import { results_bar } from 'Assets/img/screening-icons';

const Body = ({ DefaultComponent, ErrorComponent, ...rest }) => {
  const intl = useIntl();
  const { screeningGuid, state, variant } = useContext(ResultContext);

  const renderError = () => (
    <>
      <Typography fontWeight={700} fontSize={16} color="white" alignSelf="center">
        404 {intl.formatMessage({ id: 'common.error' })}
      </Typography>
      <StyledSegmentShapeWrapper style={{ marginBottom: '30px' }}>
        <SegmentShape svg={results_bar} />
      </StyledSegmentShapeWrapper>
    </>
  );

  const ComponentStates = {
    [ResultStates.Default]: DefaultComponent,
    [ResultStates.Error]: ErrorComponent ?? renderError,
  };

  if (state !== ResultStates.Found) {
    const Component = ComponentStates[state];
    if (!Component) return <></>;
    return <Component />;
  }

  const FormComponent = Forms[screeningGuid][variant];

  return <FormComponent {...rest} />;
};

Body.propTypes = {
  DefaultComponent: PropTypes.element,
  ErrorComponent: PropTypes.element,
};

export default Body;
