import styled from '@emotion/styled';
import { legacyBackgroundColors } from 'Styles/colors';

export const StyledAlarmBlockIcon = styled.div`
  position: absolute;
  top: 20px;
  left: 27px;
  background-color: ${legacyBackgroundColors.indigo};
  height: 60px;
  width: 60px;
  border-radius: 8px;
  text-align: center;
  span {
    color: white;
    font-size: 32px;
    line-height: 58px;
  }
`;
