export const minimumAgeRequirement = 13;
export const minBirthDate = new Date('1900-01-01');

export const calculateAge = (dob) => {
  if (!dob) {
    return;
  }
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms);
  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

export const isFutureDate = (date) => {
  return date > new Date();
};

const daysInMonth = (m, y) => {
  switch (m) {
    case 2:
      return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
    case 9:
    case 4:
    case 6:
    case 11:
      return 30;
    default:
      return 31;
  }
};

export const isDateValid = (d, m, y) => {
  const parsedDay = parseInt(d);
  const parsedMonth = parseInt(m);
  const parsedYear = parseInt(y);
  if (isNaN(parsedDay) || isNaN(parsedMonth) || isNaN(parsedYear)) {
    return false;
  }
  return (
    isMonthValid(parsedMonth) &&
    isYearValid(parsedYear) &&
    parsedDay >= 1 &&
    parsedDay <= daysInMonth(parsedMonth, parsedYear)
  );
};

export const isMonthValid = (value) => {
  const parsedMonth = parseInt(value);
  if (isNaN(parsedMonth)) {
    return false;
  }
  return parsedMonth >= 1 && parsedMonth <= 12;
};

export const isYearValid = (value) => {
  const parsedYear = parseInt(value);
  if (isNaN(parsedYear)) {
    return false;
  }
  return parsedYear >= 1800 && parsedYear <= 9999;
};

export const isDayValid = (value) => {
  const parsedDay = parseInt(value);
  if (isNaN(parsedDay)) {
    return false;
  }
  return parsedDay >= 1 && parsedDay <= 31;
};

/**
 * Returns given date as string in YYYY-MM-DD format
 */
export const formatDateAsString = (date) => {
  const [formattedDate] = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T');
  return formattedDate;
};

export const toStandardizedISOString = (date) => {
  if (!date || isNaN(date)) {
    return date;
  }

  return new Date(new Date(date.getTime() - date.getTimezoneOffset() * 60000).setUTCHours(0, 0, 0, 0)).toISOString();
};

export const toUtcDate = (date) => {
  const currentDate = new Date(date);
  return new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate()));
};

// returns the date relative to now given the offset in days
export const getUtcDate = (daysOffset) => {
  const now = new Date();
  return toUtcDate(new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysOffset));
};
