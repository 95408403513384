import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Segment, SegmentContainer, SegmentLabel, StyledShapeClipping } from './styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SegmentTooltipWrapper from './SegmentTooltipWrapper';
import { getImageMetaAsync } from 'Core_Helpers/getImageMetaAsync';

function SegmentShape({ svg, segments, currentSegment }) {
  const [svgSize, setSvgSize] = useState(null);

  useEffect(() => {
    getImageMetaAsync(svg).then((response) => {
      if (response) {
        setSvgSize({ width: response.naturalWidth, height: response.naturalHeight });
      }
    });
  }, []);

  const segmentSize = svgSize ? 100.0 / (svgSize.width / svgSize.height) + 1 : 100;

  return (
    <>
      {svgSize && (
        <SegmentContainer className="SegmentContainer" style={{ marginTop: currentSegment ? '30px' : '0px' }}>
          <StyledShapeClipping
            className="SegmentShapeClipping"
            svg={svg}
            style={{ aspectRatio: `${svgSize?.width} / ${svgSize?.height}` }}
          >
            {segments.map(({ color, label }, index) => (
              <SegmentTooltipWrapper
                key={index}
                open={index === currentSegment}
                className="SegmentTooltip"
                placement="top"
                PopperProps={{
                  placement: 'top',
                  modifiers: [
                    {
                      name: 'flip',
                      enabled: false,
                    },
                    {
                      name: 'preventOverflow',
                      enabled: true,
                      options: {
                        altAxis: false,
                      },
                    },
                  ],
                }}
                title={
                  <SegmentLabel>
                    <div className="SegmentLabel-text">{label}</div>
                    <ArrowDropDownIcon className="SegmentLabel-arrow" />
                  </SegmentLabel>
                }
              >
                <Segment
                  className={`SegmentShape segment-${index + 1}`}
                  widthPercentage={segmentSize}
                  style={{ backgroundColor: color }}
                  fill={color}
                />
              </SegmentTooltipWrapper>
            ))}
          </StyledShapeClipping>
        </SegmentContainer>
      )}
    </>
  );
}

SegmentShape.propTypes = {
  svg: PropTypes.string.isRequired,
  segments: PropTypes.arrayOf(PropTypes.shape({ color: PropTypes.string.isRequired, label: PropTypes.string })),
  currentSegment: PropTypes.number,
};

SegmentShape.defaultProps = {
  segments: [
    { color: '#079514' },
    { color: '#E4EB00' },
    { color: '#E19D34' },
    { color: '#E15834' },
    { color: '#B64B45' },
  ],
};

export default SegmentShape;
