import React from 'react';
import PropTypes from 'prop-types';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectLanguage } from 'Core_Redux/user';
import { useIntl } from 'react-intl';
import Separator from './Separator/Separator';
import { StyledDateRangeContainer } from './styles';

function DateRange({ startDate, endDate }) {
  const intl = useIntl();
  const language = useCoreSelector(selectLanguage);

  const formattedStartDate = new Date(startDate).toLocaleDateString(language, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const starting = `${intl.formatMessage({ id: 'reminders.started' })} ` + formattedStartDate;

  let ending = `${intl.formatMessage({ id: 'reminders.no-end-date' })} `;
  if (endDate) {
    const formattedEndDate = new Date(endDate).toLocaleDateString(language, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    ending = `${intl.formatMessage({ id: 'reminders.ending' })} ` + formattedEndDate;
  }

  return (
    <StyledDateRangeContainer>
      <span>{starting}</span>
      <Separator />
      <span>{ending}</span>
    </StyledDateRangeContainer>
  );
}

DateRange.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
};
DateRange.defaultProps = {
  endDate: null,
};

export default DateRange;
