import { senseOfSelf } from 'Assets/img/my-tools-icons';

export const senseOfSelfEn = {
  key: 'sense-of-self',
  title: 'Maintaining a Sense of Self',
  titleImage: senseOfSelf,
  titleImageAlt: '',
  contentId: 36,
  sections: [
    {
      key: 'sense-of-self-intro',
      description:
        'Being a caregiver can be physically and emotionally exhausting. It can be lonely. You may be feeling isolated – like you are on an island while the world has continued to go on all around you, without you. As a caregiver it is important to understand and recognize when your feelings and your body is you that you may need to take better care of yourself.',
    },
    {
      key: 'body-tell-me',
      header: 'What my Body is Trying to Tell Me',
      description:
        'Are you feeling tired often or is it hard to get out of bed in the morning? Spending extended time in bed, wanting to rest often, having difficulty sleeping, and changes in your weight all are common signs of caregiver burnout. It is normal to lose sleep as a caregiver – in particular if the individual you are caring for needs help during the night, or they keep you from sleeping. Being a caregiver can create stress in your life, which can lead to unhealthy eating, sleeping, and daily activity habits. As your emotional and physical well-being decline, these unhealthy patterns can create serious medical or mental health conditions. It is important to maintain a sense of self and stay true to who you are to not only continue to be a good caregiver, but also to give yourself a better quality of life.',
    },
    {
      key: 'feeling-tell-me',
      header: 'What my Feelings are Trying to Tell Me',
      description:
        'Feeling alone, overwhelmed, sad, angry, or frustrated are all common as a caregiver. You are giving so much of yourself that you may have found that you have less interest in previously enjoyed activities or are tearful without knowing why. If any of these feelings resonate with you, then it’s probably time to listen and find ways to support yourself.',
    },
    {
      key: 'help-maintain-self',
      header: 'How I can Help Maintain My Sense of Self',
      description:
        'Oftentimes caregivers, like you, are wiling to give of yourself to others, but struggle to ask for or allow help. One of the best ways to combat isolation and loneliness is to build some time into your caregiving routine to focus on you – to find yourself again, in amongst your busy and full schedule. Caring for yourself helps you to best care for others. Here are some things that you can do to help you find some time for yourself.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Take a break – it may feel hard to leave those that you are caring for, but it is important. Make sure to have a great plan in place for them to be cared for such as a respite services or trusted family and friends to give you some time away.',
        },
        {
          text: 'Find enough time for sleep. Sleep is so important for your overall physical and mental well-being and often overlooked. Perhaps you tell yourself that you do not want help caring for your individual during the night because you can rest during the day, or you think you can function on less sleep. There are support options available to help you sleep at night. Make it a priority to talk to the individuals care team to see what an option may be to help you get more sleep. If you want to be a good caregiver during the day, you need good sleep at night.',
        },
        {
          text: 'Seek out people who can support you. This can be a support group with other caregivers, a friend or acquaintance, or a local group that you would like to join.',
        },
        {
          text: 'Make sure to do something for yourself every day. It can be big or small, but make sure you do something.',
        },
        {
          text: 'What activities did you like to do before you were a caregiver? Make it a priority to do one of those activities each week. It is important to be intentional about making time for yourself and to do activities you enjoy.',
        },
        {
          text: 'Use the time that you do have for yourself to participate in activities that make you feel renewed like making time for a walk or scheduling 30 minutes to sit and have your morning coffee. Get involved in activities that are new to or based your own interests (or things that may have been of interest to you) to feel connected to your sense of self and help keep you in touch with your friends and loved ones. This could be walking or hiking with a friend or joining a local yoga group.',
        },
      ],
    },
  ],
};
