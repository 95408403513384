import { caregiverBurnout } from 'Assets/img/my-tools-icons';

export const caregiverBurnoutGuardianEn = {
  key: 'caregiver-burnout-guardian',
  title: 'Caregiver Burnout',
  titleImage: caregiverBurnout,
  titleImageAlt: '',
  contentId: 44,
  sections: [
    {
      key: 'caregiver-burnout-intro',
      description:
        'Chances are that if you opened this article, you may be feeling tired, stressed, worried, or perhaps you are not sure if you can continue being a caregiver. It is likely that you are feeling burned out. Being a caregiver can be a thankless job that leaves you feeling lonely and self-isolated. Here we offer you some signs of burnout to better understand what you may be feeling and some potential ways to reduce these feelings.',
    },
    {
      key: 'signs-of-burnout',
      header: 'Physical and Emotional Signs of Burnout',
      description:
        'There are several common physical and emotional signs of burnout. We provide a short list of some of the most common signs of burnout below. We put them into two different categories–typical signs of burnout, and when to know if you should reach out to your doctor, a mental health professional, or others for help.',
      itemsHeader: 'Tell me more',
      items: [
        { key: 'signs-burnout', description: '<b>Typical Signs of Burnout</b>' },
        { text: 'Tired often or exhaustion' },
        { text: 'Difficulty sleeping' },
        { text: 'Feeling angry or frustrated more often' },
        { text: 'Changes in appetite or weight' },
        { text: 'Loss of interest in enjoyable activities' },
        { text: 'Feeling sad' },
        { text: 'Withdrawal from family and friends' },
        {
          key: 'secondary-accordion-header',
          description:
            '<b>Signs that you need help</b><br /><br />The following are all reasons that you should reach out for help:',
        },
        { text: 'You feel like you want to hurt yourself or someone else' },
        { text: 'You feel hopeless or helpless' },
        {
          text: 'You feel so sad or depressed that you have a difficult time getting out of bed or stay in bed longer than a typical eight hours',
        },
        {
          text: 'You feel dizzy, like your heart is racing, nauseous, or experience any other physical symptom that does not go away after consecutive days',
        },
      ],
    },
    {
      key: 'reasons-for-burnout',
      header: 'Reasons You May be Experiencing Burnout',
      description:
        'If you are experiencing any of these physical or emotional symptoms, here are a few reasons you might be feeling that way.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Feeling as if you have lost control of your daily activities, your thoughts, or ability to do the things you enjoy.',
        },
        {
          text: 'Unrealistic expectations about your role – you may be envisioning a more idyllic relationship with the one you are caring for or had expected to get more sleep or time to yourself',
        },
        {
          text: 'Unrealistic expectations of yourself – it is easy to compare yourself to others and think that you should be doing more or that you should do it better',
        },
      ],
    },
    {
      key: 'burnout-validation',
      header: 'Validation',
      description:
        'It is easy to quickly forget about your own needs if you are caring for or parenting another person. Here are a few sentences of validation that can help you remember why you are a caregiver and to help you feel great about how you are helping others.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Being a caregiver is hard work – you are strong and capable of doing this work.',
        },
        {
          text: 'Those you are caring for are lucky to have you.',
        },
        { text: 'You are making the lives of those around you better – even if it does not always feel that way.' },
        { text: 'You are resilient, strong, and have a wonderful heart.' },
      ],
    },
    {
      key: 'reduce-burnout',
      header: 'How you can Reduce Your Feelings of Burnout',
      description:
        'Caring for yourself helps you to care for others. Here are some things that you can do to help you feel less burned out.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Take a break – it may feel hard to leave those that you are caring for, but it is important. Make sure to have a great plan in place for them to be cared for, such as respite services or trusted family and friends to give you some time away.',
        },
        {
          text: 'Practice self-care such as deep breathing, meditation, yoga, or other relaxation activities. Check out some of the other resources and relaxation activities in our Pyx app.',
        },
        {
          text: 'Talk to a doctor or mental health provider about how you are feeling.',
        },
        {
          text: 'Join a support group with other caregivers.',
        },
        {
          text: 'Make sure to do something for yourself every day. It can be big or small, but make sure you do something.',
        },
      ],
    },
  ],
};
