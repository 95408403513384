import styled from 'styled-components';
import { legacyFontColors } from 'Styles/colors';

export const GreetingCardContainer = styled.div`
  width: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GreetingCardMessageContainer = styled.div`
  text-align: center;
  border: 1px solid #f3f1f4;
  width: 80%;
  position: relative;
  background: white;
  box-shadow: 5px 5px 5px #b6b8b8;
  padding-top: 5%;

  &::after {
    z-index: -1;
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 5px;
    top: 0;
    background-color: #f3f1f4;
    box-shadow: 0 0 0 0 #f3f1f4, 8px 9px 11px #b6b8b8;
    transform: rotate(10deg) scale(1.01);
  }
`;

export const GreetingCardColoringBookImage = styled.div`
  max-width: 150px;
  max-height: 150px;
  margin: auto;
`;

export const GreetingCardImage = styled.img`
  margin: 10px;
  max-width: 150px;
  max-height: 150px;
`;

export const GreetingCardMessage = styled.p`
  font-size: 0.875rem;
  color: ${legacyFontColors.indigo};
  margin-top: 0;
`;

export const GreetingCardFrom = styled.p`
  margin-top: 0;
  line-height: 0.7rem;
  font-size: 1.5625rem;
  color: ${legacyFontColors.indigo};
  font-family: Noteworthy, cursive;
`;
