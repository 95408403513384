import styled from '@emotion/styled';
import { teal, black } from 'Styles/colors';

export const ToggleContainer = styled.button`
  position: relative;
  overflow: hidden;
  margin: 16px auto 32px;
  height: 33px;
  width: fit-content;
  border: 1px solid #00000061;
  border-radius: 15px;
  background: ${teal._050};
  color: ${black._87};
  white-space: nowrap;
  padding: 0 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 44px;
  flex-shrink: 0;
  font-size: 16px;
`;

export const Overlay = styled.span`
  position: absolute;
  left: 0;
  height: 33px;
  width: fit-content;
  background: ${(props) => (props.isDisabled ? teal._050 : teal._300)};
  border: 1px solid #00000061;
  border-radius: 15px;
  border-color: #979797;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform ease 100ms;
  &.right {
    left: auto;
    right: 0;
  }
`;
