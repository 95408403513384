import { useEffect, useState } from 'react';
import urlcat from 'urlcat';
import getAuthClient from 'Core_Helpers/getAuthClient';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectLanguage } from 'Core_Redux/user';
import { thriveDimensionTypes } from 'Core_Pages/Thrive/ThriveConfig/enums';

// TODO move mock into test after connecting to api
const answersMockEn = [
  { id: 1, text: 'Never' },
  { id: 2, text: 'Rarely' },
  { id: 3, text: 'Sometimes' },
  { id: 4, text: 'Usually' },
];

const answersMockEs = [
  { id: 1, text: 'Nunca' },
  { id: 2, text: 'Rara vez' },
  { id: 3, text: 'A veces' },
  { id: 4, text: 'Normalmente' },
];
export const mockQuestions = {
  en: {
    questions: [
      {
        questionNumber: 1,
        text: 'I have someone to talk to and feel supported and respected.',
        answers: answersMockEn,
        dimension: thriveDimensionTypes.SOCIAL,
      },
      {
        questionNumber: 2,
        text: 'I am satisfied with the quantity and quality of my social activities.',
        answers: answersMockEn,
        dimension: thriveDimensionTypes.SOCIAL,
      },
      {
        questionNumber: 3,
        text: 'I can manage stress in a way that works for my lifestyle.',
        answers: answersMockEn,
        dimension: thriveDimensionTypes.EMOTIONAL,
      },
      {
        questionNumber: 4,
        text: 'I have a person or group of people I can reach out to when I am stressed.',
        answers: answersMockEn,
        dimension: thriveDimensionTypes.EMOTIONAL,
      },
      {
        questionNumber: 5,
        text: 'I engage in an adequate amount of physical exercise.',
        answers: answersMockEn,
        dimension: thriveDimensionTypes.PHYSICAL,
      },
      {
        questionNumber: 6,
        text: 'I typically get an adequate amount of sleep each night.',
        answers: answersMockEn,
        dimension: thriveDimensionTypes.PHYSICAL,
      },
      {
        questionNumber: 7,
        text: 'I eat a balanced nutritional diet.',
        answers: answersMockEn,
        dimension: thriveDimensionTypes.PHYSICAL,
      },
      {
        questionNumber: 8,
        text: 'I search for learning opportunities.',
        answers: answersMockEn,
        dimension: thriveDimensionTypes.INTELLECTUAL,
      },
      {
        questionNumber: 9,
        text: 'I engage in stimulating mental activities.',
        answers: answersMockEn,
        dimension: thriveDimensionTypes.INTELLECTUAL,
      },
    ],
  },
  es: {
    questions: [
      {
        questionNumber: 1,
        text: 'Tengo alguien con quien hablar y me siento apoyado y respetado ',
        answers: answersMockEs,
        dimension: thriveDimensionTypes.SOCIAL,
      },
      {
        questionNumber: 2,
        text: 'Estoy satisfecho con la cantidad y calidad de mis interacciones sociales',
        answers: answersMockEs,
        dimension: thriveDimensionTypes.SOCIAL,
      },
      {
        questionNumber: 3,
        text: 'Puedo gestionar el estrés de forma que se adapte a mi estilo de vida',
        answers: answersMockEs,
        dimension: thriveDimensionTypes.EMOTIONAL,
      },
      {
        questionNumber: 4,
        text: 'Tengo una persona o grupo de personas a las que puedo recurrir cuando estoy estresado',
        answers: answersMockEs,
        dimension: thriveDimensionTypes.EMOTIONAL,
      },
      {
        questionNumber: 5,
        text: 'Busco oportunidades de aprendizaje ',
        answers: answersMockEs,
        dimension: thriveDimensionTypes.INTELLECTUAL,
      },
      {
        questionNumber: 6,
        text: 'Realizo actividades estimulantes para la mente',
        answers: answersMockEs,
        dimension: thriveDimensionTypes.INTELLECTUAL,
      },
      {
        questionNumber: 7,
        text: 'Hago una cantidad adecuada de ejercicio físico',
        answers: answersMockEs,
        dimension: thriveDimensionTypes.PHYSICAL,
      },
      {
        questionNumber: 8,
        text: 'Suelo dormir una cantidad adecuada de horas cada noche',
        answers: answersMockEs,
        dimension: thriveDimensionTypes.PHYSICAL,
      },
      {
        questionNumber: 9,
        text: 'Llevo una dieta nutricional equilibrada',
        answers: answersMockEs,
        dimension: thriveDimensionTypes.PHYSICAL,
      },
    ],
  },
};

function useThriveAssessment(assessmentTerm, userId) {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [answers, setAnswers] = useState([]);

  const language = useCoreSelector(selectLanguage);
  const mock = mockQuestions[language];

  const answerToEnglish = (answerId) => {
    return questionToEnglish()?.answers.find((a) => a.id == answerId);
  };
  const questionToEnglish = () => {
    return mockQuestions['en']?.questions?.find((q) => q.questionNumber == currentQuestion.questionNumber);
  };

  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    nextQuestion();
  }, [answers]);

  async function getQuestions() {
    setIsLoading(true);
    setQuestions(mock.questions);
    setCurrentQuestion(mock.questions.find((q) => q.questionNumber === 1));
    setIsLoading(false);
  }

  function nextQuestion() {
    if (questions.length > 0) {
      const nextQuestion = questions.find((q) => q.questionNumber === currentQuestion.questionNumber + 1);
      if (nextQuestion) {
        setCurrentQuestion(nextQuestion);
      } else {
        saveScores();
        setIsComplete(true);
      }
    }
  }

  async function saveAnswer(answer) {
    setAnswers((prevAnswers) => [
      ...prevAnswers,
      {
        dimension: currentQuestion.dimension,
        questionText: questionToEnglish()?.text,
        answerText: answerToEnglish(answer.id)?.text,
        answerValue: answer.id,
      },
    ]);
  }

  async function saveScores() {
    const authClient = await getAuthClient();
    const THRIVE_SCORE_URI = urlcat(process.env.API_BASE_URL, 'v1/thrive/assessments/scores');

    await authClient.post(THRIVE_SCORE_URI, {
      userId: userId,
      answers: answers,
      term: assessmentTerm,
    });
  }

  function getTotal(score, answers, dimension) {
    const total = answers.filter((x) => x.dimension == dimension)?.length;
    return score / total;
  }

  function calculateScore() {
    let socialScore = 0;
    let emotionalScore = 0;
    let physicalScore = 0;
    let intellectualScore = 0;
    answers.forEach((a) => {
      if (a.dimension === thriveDimensionTypes.SOCIAL) {
        socialScore += a.answerValue;
      }
      if (a.dimension === thriveDimensionTypes.EMOTIONAL) {
        emotionalScore += a.answerValue;
      }
      if (a.dimension === thriveDimensionTypes.PHYSICAL) {
        physicalScore += a.answerValue;
      }
      if (a.dimension === thriveDimensionTypes.INTELLECTUAL) {
        intellectualScore += a.answerValue;
      }
    });
    socialScore = getTotal(socialScore, answers, thriveDimensionTypes.SOCIAL);
    emotionalScore = getTotal(emotionalScore, answers, thriveDimensionTypes.EMOTIONAL);
    physicalScore = getTotal(physicalScore, answers, thriveDimensionTypes.PHYSICAL);
    intellectualScore = getTotal(intellectualScore, answers, thriveDimensionTypes.INTELLECTUAL);
    return { socialScore, emotionalScore, physicalScore, intellectualScore };
  }

  return {
    currentQuestion,
    questionCount: questions.length,
    saveAnswer,
    isLoading,
    isComplete,
    calculateScore,
  };
}

export default useThriveAssessment;
