import React from 'react';
import PropTypes from 'prop-types';
import { StyledSnackbar } from './styles';
import Fade from '@mui/material/Fade';

function Snackbar({ children, ...props }) {
  return <StyledSnackbar {...props}>{children}</StyledSnackbar>;
}

Snackbar.propTypes = {
  children: PropTypes.string,
};

Snackbar.defaultProps = {
  transitionDuration: 1000,
  autoHideDuration: 3000,
  TransitionComponent: Fade,
};

export default Snackbar;
