import React from 'react';
import { useIntl } from 'react-intl';
import { useOnboardSelector } from 'Contexts/StoreContext';
import { Typography } from '@mui/material';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { Header } from 'Onboard_Components/Account/styles';
import { selectSignupMetadata } from 'Onboard_Redux/signup';
import Button from 'Stories/components/Button';
import { SupportLink, SupportText } from './styles';

function MatchingEmail() {
  const intl = useIntl();
  const { progress } = useOnboardSelector(selectSignupMetadata);

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <Header>{intl.formatMessage({ id: 'account.sign-up.verify-email' })}</Header>
      <Typography textAlign={'center'} fontSize={16} sx={{ width: '92%', alignSelf: 'center', fontWeight: '700' }}>
        {intl.formatMessage({ id: 'account.sign-up.matching-email-text' })}
      </Typography>
      <Typography
        textAlign={'center'}
        fontSize={16}
        sx={{ width: '92%', alignSelf: 'center', margin: '24px 0px', fontWeight: '700' }}
      >
        {intl.formatMessage({ id: 'account.sign-up.matching-email-cta' })}
      </Typography>
      <SupportLink href={`tel:+${process.env.ANDY_PHONE_NUMBER}`}>
        <Button>{intl.formatMessage({ id: 'common.call' })}</Button>
      </SupportLink>
      <SupportText>{intl.formatMessage({ id: 'account.layout.contact-support' })}</SupportText>
      <SupportText>{process.env.ANDY_PHONE_NUMBER}</SupportText>
    </>
  );
}

export default MatchingEmail;
