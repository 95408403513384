import styled from '@emotion/styled';
import { black } from 'Styles/colors';

export const ChatBubbleContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.position === 'right' ? 'row-reverse' : '')};
  padding: 5px 0;
`;

export const ChatText = styled.div`
  border-radius: 10px;
  padding: 15px;
  letter-spacing: 0.03rem;
  line-height: 1.1875rem;
  font-size: 1rem;
  background-color: ${(props) => (props.position === 'right' ? '#2f6e6f' : '#f2f2f2')};
  color: ${(props) => (props.position === 'right' ? '#fff' : black._100)};

  a {
    color: #0063cc;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
    }
  }
`;
