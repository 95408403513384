import styled from '@emotion/styled';
import Button from 'Stories/components/Button';
import { blue } from 'Styles/colors';

export const ResendButton = styled(Button)`
  width: fit-content;
  padding: 16px;
  margin-bottom: 12px;
  font-size: 18px;
  color: ${blue._800};
`;

export const ConfirmationContentContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

export const ConfirmationText = styled.div`
  text-align: center;
  margin: 0px 26px 16px;
`;
