import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { selectSignupMetadata, navigateBack, selectBirthday } from 'Onboard_Redux/signup';
import { ArrowBackIos } from '@mui/icons-material';
import { Header, StyledTextInput } from 'Onboard_Components/Account/styles';
import SkipButton from '../SkipButton';
import Button from 'Stories/components/Button';
import { Controller, useForm } from 'react-hook-form';
import {
  useOnboardMutation,
  useOnboardByCodeMutation,
  useValidatePartnerCodeMutation,
} from 'Onboard_Redux/services/api';
import { InformationText } from './styles';
import { userOnboarded } from 'Onboard_Redux/user';
import { confirmedUserUpdated } from 'Onboard_Redux/authentication';
import { ClearAdornment } from 'Stories/components/Input/Adornments';

function PartnerCode() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const { loading: signUpLoading, progress } = useOnboardSelector(selectSignupMetadata);
  const { birthday } = useOnboardSelector(selectBirthday);
  const [validatePartnerCode, { isLoading: codeValidationLoading, error: codeValidationError }] =
    useValidatePartnerCodeMutation();
  const [onboardByCode, { isLoading: onboardingByCodeLoading, error: onboardByCodeError }] = useOnboardByCodeMutation();
  const [onboard, { isLoading: userOnboardingLoading, error: onboardError }] = useOnboardMutation();
  const {
    control,
    formState: { dirtyFields },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues: { partnerCode: '' } });
  const [isPending, setIsPending] = useState(false);

  function onBack() {
    if (!isLoading) {
      dispatch(navigateBack());
    }
  }

  async function onContinue(data) {
    setIsPending(true);
    await dispatch(confirmedUserUpdated()).unwrap();
    await validatePartnerCode({ code: data.partnerCode }).unwrap();
    await onboardByCode({ code: data.partnerCode, birthday }).unwrap();
    dispatch(userOnboarded());
  }

  async function onSkip() {
    setIsPending(true);
    await dispatch(confirmedUserUpdated()).unwrap();
    await onboard({ healthPlanId: null, birthday }).unwrap();
    dispatch(userOnboarded());
  }

  function clearField() {
    setValue('partnerCode', '');
  }

  const partnerCodeOnboardErrorMessage =
    (codeValidationError || onboardByCodeError) && intl.formatMessage({ id: 'account.sign-up.partner-code-invalid' });

  const isLoading =
    (isPending && !onboardError && !onboardByCodeError && !codeValidationError) ||
    userOnboardingLoading ||
    onboardingByCodeLoading ||
    codeValidationLoading;

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <Header>
        {!isLoading && <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />}
        {intl.formatMessage({ id: 'account.sign-up.partner-code-title' })}
      </Header>
      <form onSubmit={handleSubmit(onContinue)}>
        <Controller
          name="partnerCode"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <StyledTextInput
              {...field}
              id="partnerCodeInput"
              label={intl.formatMessage({ id: 'account.sign-up.partner-code' })}
              error={!!codeValidationError || !!onboardByCodeError}
              errorMessage={partnerCodeOnboardErrorMessage}
              InputProps={{
                endAdornment: <ClearAdornment onClick={clearField} />,
              }}
            />
          )}
        />
        <Button fullWidth loading={signUpLoading || isLoading} disabled={!dirtyFields.partnerCode} type="submit">
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
      <SkipButton disabled={signUpLoading || isLoading} onClick={onSkip}>
        {intl.formatMessage({ id: 'common.skip' })}
      </SkipButton>
      <InformationText>{intl.formatMessage({ id: 'account.sign-up.partner-code-info' })}</InformationText>
    </>
  );
}

export default PartnerCode;
