import styled from 'styled-components';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export const StyledContainer = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const StyledMenuOptionsContainer = styled.div`
  min-height: fit-content;
  width: 100%;
  height: fit-content;
  background-color: white;
`;

export const ColoredText = styled.b`
  color: #f57c00;
  font-size: 26px;
  vertical-align: middle;
  padding-right: 7px;
  display: inline-block;
`;
export const StyledHeader = styled.div`
  height: ${(props) => props.minActorHeight}px;
  padding: 1% 7%;
  border-bottom: 1px solid rgb(0 0 0 / 12%);
`;

export const StyledHeaderText = styled.h1`
  color: #4a4f7f;
  font-weight: 700;
  font-size: 20px;
`;

export const StyledParagraph = styled.p`
  color: #0f0f0f;
  display: inline-block;
  margin-top: 10px;
`;

export const StyledDescriptionContainer = styled.div`
  max-width: 75%;
  display: flex;
`;

export const MenuNoHeaderSection = styled.div`
  display: flex;
`;

export const StyledListSubheader = styled(ListSubheader)`
  &&& {
    display: flex;
    background: linear-gradient(#ede7f6, #fafafa);
    padding: 17px 0px 7px 90px;

    & > h2 {
      font-weight: 700;
      color: black;
      align-items: center;
      font-size: 1rem;
      font-family: 'Karla';
    }
  }
`;

export const StyledListItemText = styled(ListItemText)`
  font-family: 'Karla';
`;

export const StyledListItem = styled(ListItem)`
  &&& {
    display: flex;
    color: black;
    align-items: center;
    gap: 20px;
    font-weight: bold;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid;
  opacity: 12%;
  color: black;
  margin-left: 92px;
`;

export const StyledList = styled(List)`
  &&& {
    padding-bottom: 0;
    overflow-y: auto;
  }
`;

export const StyledIcon = styled(ListItemIcon)`
  height: 50px;
`;

export const ScrollableContent = styled.div`
  overflow: auto;
`;
