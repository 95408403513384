import { habitTracker } from 'Assets/img/my-tools-icons';

export const habitTrackerEn = {
  key: 'habit tracker',
  title: 'Habit Tracker',
  titleImage: habitTracker,
  titleImageAlt: '',
  description: 'Tracking actions can be helpful to add positive, new habits to your wellness pathway!',
  contentId: 7,
  sections: [
    {
      key: 'think-new-habits',
      header: 'Think about a new habit or a change you’d like to make to support your well-being.',
      description: 'It’s okay to have more than 1 habit to add but try to have less than 5 for now.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Begin with small habits that are easy to add to your everyday life. Think about activities that could take a short time to complete and try to be specific. Here are a few examples of small, clear habits.',
          subItems: [
            { text: 'Reading 1 page of a book before bed every night' },
            { text: 'Writing in your journal every day' },
            { text: 'Being quiet for 10 minutes every day' },
            { text: 'Flossing my teeth before bed every night' },
            { text: 'Walking outside for 15 minutes twice a week' },
          ],
        },
        {
          text: 'How often you do your new habit action(s) is up to you! You can select every day or choose a longer timeframe to do them in such as a few times week or even weekly.',
        },
        {
          text: 'Some helpful hints to ask when choosing your new habit...',
          subItems: [
            { text: 'Can it be done regularly, every day if possible?' },
            { text: 'Does it stretch my abilities but is still manageable?' },
            { text: 'Will it improve my physical health? Mental health? Relationships? etc.' },
            { text: 'Does it encourage creativity and play?' },
            { text: 'Do I have support to do this?' },
          ],
        },
      ],
    },
    {
      key: 'create-grid',
      header: 'Create a grid chart to track your habit actions over the next month.',
      description:
        'This chart will be used like an “at-a-glance” calendar to record your progress. Use paper and pen or type on a computer/device.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Make a horizontal chart with 32 columns. Make column 1 wider than the rest. Columns 2-32 will be used as the days of the month, starting with 1 and ending on 31.',
        },
        { text: 'Name column 1: “Habit Goal.”' },
        {
          text: 'Starting with column 2, name the remaining columns with numbers 1 to 31, one after the other, to create the calendar days.',
        },
        { text: 'Then, add a few horizontal rows beneath the top row to begin. You can add more later.' },
        { text: 'Write the month on top of the chart.' },
      ],
    },
    {
      key: 'track-habits',
      header: 'Begin tracking when you acted out your new habit on the chart.',
      description: 'Track and record when you do your new habit action.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'In column 1 below the row named “Habit,” write down the new habit action you want to make. You can describe it as briefly or be as detailed as you’d like.',
        },
        { text: 'Each time you do your new habit activity, use columns 2-32 to mark the calendar day you did it.' },
        {
          text: 'It’s okay if you miss a day! Just leave that day blank and continue the next day or as soon as you can again. The habit tracker is flexible and can be adjusted when you need to.',
        },
        {
          text: 'Fill out your habit tracker! You’ll get immediate feedback and see your progress each time you use it!',
        },
        {
          text: 'Use your habit tracker as a visual reminder to see the progress you’re making and to help you keep moving towards and supporting your own well-being.',
        },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your experience with this activity.',
      itemsHeader: 'Insights & pointers',
      items: [
        { text: 'What have you learned from this exercise?' },
        { text: 'How did you feel before completing this exercise?' },
        { text: 'How do you feel after it?' },
        { text: 'What part(s) of this exercise did you find most challenging? Why?' },
        { text: 'What did you enjoy most about this exercise?' },
        { text: 'Which aspects/elements of the challenge/goal do you want to work on first?' },
        { text: 'What would help make the first step easier?' },
        { text: 'What would be some benefits of doing this activity more often in general?', divider: true },
        {
          text: 'Simplicity is an important part of developing new habits and actions. Start with 1 simple habit to begin with that you can do in a short amount of time and add more new habits when you feel comfortable.',
        },
        {
          text: 'Put your habit tracker somewhere you can easily see it and remember to fill it out, such as on the fridge, a bedside table, or on the top of your desk.',
        },
        {
          text: 'Remember to be kind with yourself on your Thrive Pathway! Habits can be a valuable building block of positive change but the length of time to develop new habits for personal wellness varies among people.',
        },
        {
          text: 'Since physical and social settings can trigger habits, it can be useful to change your surroundings or routines to support new, positive actions.',
        },
      ],
    },
  ],
};
