import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { LocalPhone } from '@mui/icons-material';
import DeviceInformation from 'Onboard_Components/DeviceInformation';
import {
  PageHeader,
  InformationContainer,
  PyxirImage,
  SupportLink,
  SupportText,
  PageContainer,
  ContentContainer,
  LearnMoreLink,
} from 'Onboard_Pages/Account/Layout/styles';
import HeaderPyxir from 'Assets/img/home-option-icons/pyxir.svg';
import { displayDarkStatusBar } from 'Onboard_Helpers/statusBar';

function AccountLayout({ children }) {
  const intl = useIntl();

  useEffect(() => {
    displayDarkStatusBar();
  }, []);

  return (
    <div>
      <PageHeader className="safe-area">
        <InformationContainer>
          <DeviceInformation />
          <LearnMoreLink href="https://www.pyxhealth.com/member-home/">
            {intl.formatMessage({ id: 'account.layout.learn-more' })}
          </LearnMoreLink>
        </InformationContainer>
        <PyxirImage alt="" aria-hidden="true" src={HeaderPyxir} />
        <SupportLink href={`tel:+${process.env.ANDY_PHONE_NUMBER}`}>
          <LocalPhone />
          <span>{intl.formatMessage({ id: 'account.layout.support' })}</span>
        </SupportLink>
        <SupportText>
          <span>{intl.formatMessage({ id: 'account.layout.contact-support' })}</span>
          <span>{process.env.ANDY_PHONE_NUMBER}</span>
        </SupportText>
      </PageHeader>
      <PageContainer>
        <ContentContainer>{children}</ContentContainer>
      </PageContainer>
    </div>
  );
}
AccountLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AccountLayout;
