import styled from '@emotion/styled';

export const QuestionWrapper = styled.div`
  //
`;

export const StyledQuestion = styled.div`
  //
`;

export const AnswerWrapper = styled.div`
  //
`;
