import styled from '@emotion/styled';
import { Button, Card, Grid } from '@mui/material';
import { breakpoints } from 'Styles/theme';
import { black } from 'Styles/colors';

export const StyledGrid = styled(Grid)(
  ({ theme }) => `
    padding: 0px 20px 30px 20px;

    margin-top: inherit;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  
    ${theme.breakpoints.up(breakpoints.DESKTOP)} { 
        width: 80%;
        align-self: center;
    }
  `,
);

export const StyledToolsGrid = styled(Grid)`
  &.MuiGrid-item {
    padding-left: 32px;

    @media screen and (max-width: 400px) {
      width: 24px;
    }
  }
`;

export const StyledBackButton = styled(Button)`
  position: absolute;
  left: 20px;
  color: ${black._60};
  padding: 0px;
  left: 0;
`;

export const ToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledCard = styled(Card)(
  ({ theme }) => `
      width: 100%;
      height: 276;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    
      ${theme.breakpoints.up(breakpoints.TABLET)} { 
          flex-direction: row-reverse;
          padding: 5px 70px 5px 20px;
      }
    
      ${theme.breakpoints.up(breakpoints.DESKTOP)} { 
          padding: 5px 50px 5px 50px;
      }
    
      position: relative;
    `,
);

export const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background: white;
`;
