import styled from '@emotion/styled';
import Button from 'Stories/components/Button';
import { blue } from 'Styles/colors';

export const HealthPlanInfoTextContainer = styled.div`
  text-align: center;
  margin-top: -16px;
  padding: 0 24px;

  p {
    margin: 0;
  }

  p:last-of-type {
    margin-bottom: 32px;
  }
`;

export const SeeSampleButton = styled(Button)`
  height: fit-content;
  font-size: 16px;
  color: ${blue._800};
  margin: 12px 0;
`;
