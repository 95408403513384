import { Scene } from '../Scene';
import { addBackgroundsToScene, addCharacterToScene, useCamera } from 'Core_Games/Scripts/sceneHelper';

export class SingleAction extends Scene {
  constructor(childName) {
    super(childName);

    this.flipXOverrideAtStart = false;
    this.actionTimes = 0;
    this.actionLimit = 1;
    this.singleActionBackgroundIndex = 0;
  }

  init(scene, sceneConfig, sceneData) {
    super.init(scene, sceneConfig, sceneData);
    this.currentSceneConfig = this.sceneConfig[this.sceneData.gameData.gameData.petState.petType][this.sceneType.NAME];

    if (!this.parentScene) {
      this.cameras.main.backgroundColor = Phaser.Display.Color.HexStringToColor(
        this.currentSceneConfig.GAMEBACKGROUNDCOLOR ?? '#FFFFFF',
      );
    }

    this.actionLimit = this.currentSceneConfig.ACTIONLIMIT ?? 1;
    this.flipXOverrideAtStart = this.currentSceneConfig.FLIPXATSTART ?? false;
    this.flipTurn = this.currentSceneConfig.FLIPTURN ?? this.flipXOverrideAtStart;

    this.startLocationPercentageX = this.currentSceneConfig.STARTX ?? 1.0;
    this.startLocationPercentageY = this.currentSceneConfig.STARTY ?? 0.9;
    this.startXOffset = this.currentSceneConfig.STARTXOFFSET ?? 0.0;
    this.startYOffset = this.currentSceneConfig.STARTYOFFSET ?? 0.0;
    this.isFacingRight = this.currentSceneConfig.RIGHTFACING ?? false;
    this.endLocationPercentageX = this.currentSceneConfig.ENDX ?? this.startLocationPercentageX;
    this.endLocationPercentageY = this.currentSceneConfig.ENDY ?? this.startLocationPercentageY;
    this.brushOffset = this.currentSceneConfig.BRUSHOFFSET ?? 1;
    this.endXOffset = this.currentSceneConfig.ENDXOFFSET ?? this.startXOffset;
    this.endYOffset = this.currentSceneConfig.ENDYOFFSET ?? this.startYOffset;
    this.cameraStartPercentage = {
      x:
        this.currentSceneConfig.CAMERASTARTPERCENTAGE && this.currentSceneConfig.CAMERASTARTPERCENTAGE.x
          ? this.currentSceneConfig.CAMERASTARTPERCENTAGE.x
          : 0.5,
      y:
        this.currentSceneConfig.CAMERASTARTPERCENTAGE && this.currentSceneConfig.CAMERASTARTPERCENTAGE.y
          ? this.currentSceneConfig.CAMERASTARTPERCENTAGE.y
          : 0.5,
      zoomBasedStartY:
        this.currentSceneConfig.CAMERASTARTPERCENTAGE && this.currentSceneConfig.CAMERASTARTPERCENTAGE.zoomBasedStartY
          ? this.currentSceneConfig.CAMERASTARTPERCENTAGE.zoomBasedStartY
          : false,
    };
  }

  preload() {
    super.preload();
  }

  create() {
    super.create();

    let _this = this;
    let _sceneConfig = this.currentSceneConfig;
    let petType = _this.sceneData.gameData.gameData.petState.petType;
    let petAsset = _this.engine.engineAssets.actors.find((x) => x.NAME == petType);

    if (this.engineComplete) {
      _this.createSingleActionScene(_this, _sceneConfig, petType, petAsset);
    } else {
      this.engine.engineAssets.load.on(Phaser.Loader.Events.COMPLETE, function () {
        _this.createSingleActionScene(_this, _sceneConfig, petType, petAsset);
      });
    }
  }

  createSingleActionScene(_this, _sceneConfig, petType, petAsset) {
    if (!_this.background) {
      if (_sceneConfig.ASSETS.BACKGROUND && !_this.background) {
        _this.background = _this.add.sprite(0, 0, _this.gameData.gameType.ATLAS, _sceneConfig.ASSETS.BACKGROUND.DATA);
        _this.background.isScaled = false;
        _this.backgrounds.push({ key: _sceneConfig.ASSETS.BACKGROUND.NAME, data: _this.background });
      }
      //set configurable background
      if (_sceneConfig.BACKGROUNDTRANSFORM) {
        _this.background.setScale(1.0, _sceneConfig.BACKGROUNDTRANSFORM.scaleY);
        _this.background.isScaled = true;
      }

      // Start location depends on if we are using the parent scene or not
      if (!_this.parentScene || !_this.otherSceneBoundingBox) {
        _this.useSingleActionBackground = true;

        addBackgroundsToScene(_this);

        const startLocation = _this.getPagePoint(0, _this.startLocationPercentageX, _this.startLocationPercentageY);
        const endLocation = _this.getPagePoint(0, _this.endLocationPercentageX, _this.endLocationPercentageY);
        _this.startLocationX = startLocation.x;
        _this.startLocationY = startLocation.y;
        _this.endLocationX = endLocation && endLocation.x ? endLocation.x : startLocation.x;
        _this.endLocationY = endLocation && endLocation.y ? endLocation.y : startLocation.y;
      } else {
        _this.singleActionBackgroundIndex = _this.parentScene.backgroundIndex;
        _this.startLocationX = _this.otherSceneBoundingBox.startLocationX;
        _this.startLocationY = _this.otherSceneBoundingBox.startLocationY;
        _this.endLocationX = _this.otherSceneBoundingBox.endLocationX ?? _this.otherSceneBoundingBox.startLocationX;
        _this.endLocationY = _this.otherSceneBoundingBox.endLocationY ?? _this.otherSceneBoundingBox.startLocationtY;
        _this.pet.x = _this.startLocationX;
        _this.pet.y = _this.startLocationY;
      }
    }

    if (!_this.pet) {
      // set asset pet scale data
      _this.originalPetScale = petAsset.SCALE;
      _this.petWidth = petAsset.WIDTH;
      _this.petHeight = petAsset.HEIGHT;

      // add pet to scene
      _this.pet = addCharacterToScene(
        _this,
        { x: _this.startLocationX, y: _this.startLocationY },
        petType,
        _this.configurablePetScale,
        _this.flipXOverrideAtStart,
      );
      _this.pet.scaleActor(_this.configurablePetScale);
    }

    if (_this.useSingleActionBackground) {
      useCamera(_this, _this.pet, false, {
        x: _this.cameraStartPercentage.x,
        y: _this.cameraStartPercentage.y,
        zoomBasedStartY: _this.cameraStartPercentage.zoomBasedStartY,
      });
    }
  }

  update() {
    super.update();
  }
}
