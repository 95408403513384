import React from 'react';
import { StyledBoldParagraph, StyledGoals, StyledParagraph } from './styles';
import HiFriendLayout from 'Core_Components/Layout/HiFriendLayout/HiFriendLayout';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import PageHeader from 'Core_Components/PageHeader/PageHeader';
import { StyledButton, StyledButtonContainer } from 'Core_Pages/Thrive/featureStyles';
import { mockScoreMessaging, DIMENSION } from './mockScoreMessaging';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { selectLanguage, selectSyncUser } from 'Core_Redux/user';
import { nextPage, selectThrive, selectThriveAssessment } from 'Core_Redux/thrive';
import { getThriveConfig } from 'Core_Pages/Thrive/ThriveConfig/thriveConfig';
import { useHistory } from 'react-router-dom';

const ThriveAssessmentResults = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useCoreDispatch();
  const language = useCoreSelector(selectLanguage);
  const { firstName } = useCoreSelector(selectSyncUser);
  const thrive = useCoreSelector(selectThrive);
  const { assessmentScore } = useCoreSelector(selectThriveAssessment);
  const thriveConfig = getThriveConfig(thrive);
  const workflow = thriveConfig ? thriveConfig.program.workflow[thrive.workflowType] : null;

  const onNext = () => dispatch(nextPage({ history }));

  const getMessageConfig = (dimension) => {
    return mockScoreMessaging[language][dimension];
  };

  const getScoreMessage = (score, message) => {
    return score < 3 ? message.negative : score < 4 ? message.neutral : message.positive;
  };

  const transformText = (message) => {
    return message.replace(/({Profile:FirstName})/g, firstName);
  };

  return (
    <HiFriendLayout greetingMessage={intl.formatMessage({ id: 'thrive.completed-surveys' })}>
      <ScreenReaderWrapper>
        <h1>
          <FormattedMessage id="title.thrive-assessment-results" />
        </h1>
      </ScreenReaderWrapper>
      <PageHeader alignSelf={'start'}>{`${intl.formatMessage({ id: 'common.thanks' })}, ${firstName}!`}</PageHeader>
      <StyledGoals>
        <StyledParagraph>
          {transformText(workflow?.resultsOverviewId ? intl.formatMessage({ id: workflow.resultsOverviewId }) : '')}
        </StyledParagraph>
        <ul>
          <li>{getScoreMessage(assessmentScore?.socialScore, getMessageConfig(DIMENSION.SOCIAL))}</li>
          <li>{getScoreMessage(assessmentScore?.emotionalScore, getMessageConfig(DIMENSION.EMOTIONAL))}</li>
          <li>{getScoreMessage(assessmentScore?.intellectualScore, getMessageConfig(DIMENSION.INTELLECTUAL))}</li>
          <li>{getScoreMessage(assessmentScore?.physicalScore, getMessageConfig(DIMENSION.PHYSICAL))}</li>
        </ul>
      </StyledGoals>
      <StyledBoldParagraph>{intl.formatMessage({ id: 'thrive.keep-up' })}</StyledBoldParagraph>
      <StyledButtonContainer>
        <StyledButton variant="contained" onClick={onNext}>
          {intl.formatMessage({ id: 'common.lets-go' })}
        </StyledButton>
      </StyledButtonContainer>
    </HiFriendLayout>
  );
};

export default ThriveAssessmentResults;
