import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const StyledImage = styled.img`
  margin: auto;
  max-width: 600px;
  display: flex;
  margin: 0px;
`;

export const StyledImageContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const ContentContainer = styled(Grid)`
  &.MuiGrid-item {
    padding-left: 0px;
  }
`;
