import styled from 'styled-components';

export const MenuGreetingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(180deg, #0f145b 0%, #3d4072 100%);
`;

export const Pyxir = styled.img`
  transform: translateX(-14px);
  height: 80px;
  margin: 10px 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 0;
  font-size: 1.375rem;
`;
