import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Select as MuiSelect } from '@mui/material';
import { black } from 'Styles/colors';
import { TextInput, PasswordInput, PhoneInput, InputProps, DateInput } from 'Stories/components/Input';

export const Header = styled.h1`
  position: relative;
  text-align: center;
  margin: 0 0 32px;
  padding: 0 26px;

  svg {
    position: absolute;
    top: 7px;
    left: 10px;
    font-size: 20px;
  }
`;

export const Subheader = styled.div`
  position: relative;
  text-align: center;
  margin: 0 0 24px;
  font-size: 18px;
`;

export const Select = styled(MuiSelect)`
  margin-bottom: 16px;

  .MuiSelect-select {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  svg {
    color: ${black._38};
  }
`;

export const CenteredSelect = styled(Select)`
  .MuiSelect-select {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;
  }
`;

const attrs = { fullWidth: true, variant: InputProps.variant.FILLED, sx: { marginBottom: '16px' } };

export const StyledTextInput = forwardRef((props, ref) => <TextInput ref={ref} {...attrs} {...props} />);
StyledTextInput.displayName = 'TextInput';

export const StyledPasswordInput = forwardRef((props, ref) => <PasswordInput ref={ref} {...attrs} {...props} />);
StyledPasswordInput.displayName = 'PasswordInput';

export const StyledPhoneInput = forwardRef((props, ref) => <PhoneInput ref={ref} {...attrs} {...props} />);
StyledPhoneInput.displayName = 'PhoneInput';

export const StyledDateInput = forwardRef((props, ref) => <DateInput ref={ref} {...attrs} {...props} />);
StyledDateInput.displayName = 'DateInput';
