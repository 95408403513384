import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledSubtitle } from './styles';
import TooltipWrapper from './TooltipWrapper';

function CardSubtitle({ children }) {
  const thisRef = useRef();
  const [includeTooltip, setIncludeTooltip] = useState(false);

  useEffect(() => {
    setIncludeTooltip(thisRef.current.offsetWidth < thisRef.current.scrollWidth);
  }, [children]);

  if (includeTooltip) {
    return (
      <TooltipWrapper title={children}>
        <StyledSubtitle ref={thisRef}>{children}</StyledSubtitle>
      </TooltipWrapper>
    );
  }

  return <StyledSubtitle ref={thisRef}>{children}</StyledSubtitle>;
}

CardSubtitle.propTypes = {
  children: PropTypes.string,
};

export default CardSubtitle;
