import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import ActivityCategoryToggle from './ActivityCategoryToggle/ActivityCategoryToggle';
import { StyledActivitiesConfigHeader, StyledErrorText, StyledToggleContainer } from './styles';
import { activityCategories } from './activityCategories';
import theme from 'Styles/theme';

function ActivitiesConfig({ control, errors, validate }) {
  const intl = useIntl();

  // entire set of category errors will be the same - take first message
  const activityCategoriesErrorMessage = () => Object.values(errors.activityCategories)[0].message;

  return (
    <>
      <StyledActivitiesConfigHeader>
        {intl.formatMessage({ id: 'reminders.activity-header' })}
      </StyledActivitiesConfigHeader>
      <StyledToggleContainer>
        {activityCategories.map((c) => (
          <ActivityCategoryToggle
            key={c.code}
            label={intl.formatMessage({ id: c.label })}
            categoryCode={c.code}
            control={control}
            validate={validate}
          />
        ))}
      </StyledToggleContainer>
      {errors.activityCategories && <StyledErrorText>^ {activityCategoriesErrorMessage()}</StyledErrorText>}
      <Controller
        name="activityName"
        control={control}
        defaultValue=""
        rules={{
          required: intl.formatMessage({ id: 'error.min-characters' }, { min: 3 }),
          minLength: { value: 3, message: intl.formatMessage({ id: 'error.min-characters' }, { min: 3 }) },
          maxLength: { value: 100, message: intl.formatMessage({ id: 'error.max-characters' }, { max: 100 }) },
        }}
        render={({ field: { value, onChange, ref } }) => (
          <TextField
            inputRef={ref}
            inputProps={{ 'aria-label': intl.formatMessage({ id: 'reminders.activity-name' }) }}
            value={value}
            onChange={onChange}
            placeholder={intl.formatMessage({ id: 'reminders.activity-name' })}
            variant="outlined"
            sx={{
              '.MuiInputBase-input::placeholder': {
                color: errors.activityName ? `${theme.palette.error.main}` : 'inherit',
              },
            }}
            error={!!errors.activityName}
            helperText={errors.activityName?.message}
          />
        )}
      />
    </>
  );
}

ActivitiesConfig.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
};

export default ActivitiesConfig;
