import { onboardContext } from 'Contexts/StoreContext';
import { createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';
import { buildCreateApi, coreModule, reactHooksModule } from '@reduxjs/toolkit/query/react';

export const customCreateApi = buildCreateApi(
  coreModule(),
  reactHooksModule({
    useDispatch: createDispatchHook(onboardContext),
    useSelector: createSelectorHook(onboardContext),
    useStore: createStoreHook(onboardContext),
  }),
);
