import styled from '@emotion/styled';
import { Tabs } from '@mui/material';
import { black } from 'Styles/colors';

export const ArrowTabs = styled(Tabs)`
  position: relative;

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-flexContainer {
    justify-content: center;
  }

  .MuiTabScrollButton-root {
    background-color: ${black._74};
    height: 31%;
    position: absolute;
    top: 34.5%;
    z-index: 2;

    .MuiSvgIcon-root {
      font-size: 65px;
    }
  }

  .MuiTabs-scroller {
    display: flex;
  }

  // left arrow
  .MuiTabScrollButton-root:first-of-type {
    left: 0;
  }

  // right arrow
  .MuiTabScrollButton-root:last-of-type {
    right: 0;
  }
`;
