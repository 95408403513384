import React from 'react';
import PropTypes from 'prop-types';
import GreetingPyxir from 'Assets/img/greeting-pyxir.svg';
import HiFriendMessageEn from 'Assets/img/hi-friend-en.svg';
import HiFriendMessageEs from 'Assets/img/hi-friend-es.svg';
import {
  GreetingContainer,
  HiFriendImage,
  PyxirGreetingImage,
  PyxirImageContainer,
  PyxirTextContainer,
  StyledMessage,
} from './styles';
import { useIntl } from 'react-intl';

const Greeting = ({ message }) => {
  const intl = useIntl();
  const hiFriendImage = intl.formatMessage({ id: 'hi.friend' });

  return (
    <GreetingContainer>
      <PyxirImageContainer>
        <PyxirGreetingImage>
          <img alt={intl.formatMessage({ id: 'greeting.pyxir' })} src={GreetingPyxir} />
        </PyxirGreetingImage>
        <PyxirTextContainer>
          <HiFriendImage>
            <img
              alt={intl.formatMessage({ id: 'image.pyxir.hifriend' })}
              src={HiFriendMessageEn.includes(hiFriendImage) ? HiFriendMessageEn : HiFriendMessageEs}
            />
          </HiFriendImage>
          {message ? <StyledMessage data-testid="Question">{message}</StyledMessage> : null}
        </PyxirTextContainer>
      </PyxirImageContainer>
    </GreetingContainer>
  );
};

Greeting.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default Greeting;
