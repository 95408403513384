import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { black, purple } from 'Styles/colors';

export const Header = styled(Box)`
  color: ${black._87};
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: center;
  background: linear-gradient(#ffffff, ${(props) => props.backgroundColor ?? purple._050});
  padding: 20px;
  position: relative;
`;
