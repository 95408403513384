import { navigatingChallengingHealthcareSystem } from 'Assets/img/my-tools-icons';

export const navigatingHealthcareEn = {
  key: 'navigating-healthcare',
  title: 'The Challenging Health Care System',
  titleImage: navigatingChallengingHealthcareSystem,
  titleImageAlt: '',
  contentId: 37,
  sections: [
    {
      key: 'navigating-healthcare-intro',
      description:
        'When caring for someone with a chronic condition – such as cognition impairment, heart or lung disease, a neurological disorder, or cancer – understanding how to navigate the right care can be challenging. This article will give an overview of who pays for care and options that are available.',
    },
    {
      key: 'who-pays',
      header: 'Who Pays for Health Care Needs?',
      description:
        'Unfortunately, there is no easy answer to this question. To help explain the different options we are going to describe a few categories. You or the individual that you are caring for may fit in multiple categories, so make sure to read them all. If you are confused about coverage or need help making the best choice for someone you are caring for, reach out to the individual’s doctor or health care provider. Most providers have social workers or care managers who can offer education and support to make a decision that fits your needs.',
    },
    {
      key: 'medicare',
      header: 'Medicare',
      description:
        'People over the age of 65 or disabled are eligible for Medicare. Medicare was created to help pay for preventive care or to help people get better. One of the more confusing aspects of Medicare is that it typically stops paying once a person stops progressing or improving. For example, if you fall and break your arm, Medicare will completely pay for your ambulance ride to the hospital, your hospital bills, a stay at a rehabilitation facility, and home care therapy until you stop improving. There are limits to how much they will pay. They may only pay for the first 20 days in a rehabilitation facility but will pay 80% of the bill for the next 80 days. Medicare typically pays for annual doctor’s visits, screenings, immunizations, and specialty care such as to an endocrinologist to manage diabetes.',
    },
    {
      key: 'medicare-supplement',
      header: 'Medicare Supplement or Medicare Advantage',
      description:
        'So, what pays for additional care that Medicare will not cover? You may have received materials or seen TV ads about paying a low monthly fee to get insurance that is either in addition to or replaces Medicare. There are two types of plans that you can get – a plan that takes over your Medicare benefit and one that is a supplement to your Medicare. The typically less expensive options, Medicare Advantage plan takes over your Medicare benefit and essentially does the same things as Medicare and often provides additional or extended coverage. For example, they may pay for more than 20 days for rehabilitation in a facility than traditional Medicare. This may sound like a great option, but it’s good to know that these Medicare Advantage plans create the rates that they pay your doctors and other health care providers, and you can only receive care from doctors that are contracted with this plan. If you are considering or have a Medicare Advantage plan, it is recommended that you review all your doctors or hospitals that you use or may want to use to assure you can receive care with this plan. Medicare Supplement plans often come with a higher price tag, and they allow Medicare to still be your primary insurance but offers more or extended care and coverage in addition to your Medicare. If you are on Medicaid (see below) this also acts as a Medicare Supplement as it will cover the additional costs that Medicare does not cover.',
    },
    {
      key: 'commercial-insurances',
      header: 'Commercial Insurances',
      description:
        'If under the age of 65, you may be paying for a traditional insurance plan either through your employer or through an open market plan in your state. When choosing your insurance carrier we recommended reviewing all doctors or hospitals you use or may want to use to ensure they accept your plan. Provide all your income information if you apply for insurance through the open market as you may be eligible for payment assistance or a lower monthly rate.',
    },
    {
      key: 'longterm-insurance',
      header: 'Long-Term Care Insurance',
      description:
        'Unfortunately, none of the options above cover long-term care needs. This means they will not pay for additional caregiver help if the one you are caring for needs assistance with walking, changing clothes, bathing, or other activities of daily living. However, long-term care insurance, Veterans benefits, and Medicaid all may pay for additional caregiver support in your home or for long-term facility care. If long-term care insurance was purchased, you can use that benefit to pay for additional care. Most long-term care policies have specific rules on when and how you can use these funds. Contact your doctor, home health, home care, or hospital provider for help understanding this benefit.',
    },
    {
      key: 'veterans-benefits',
      header: 'Veterans Benefits',
      description:
        'If the individual who you are caring for or their spouse served in the military and has a DD2-14, you may be eligible to receive care and services through the Veterans Administration. They may be eligible for higher levels of care depending on the time and conflict in which they served and whether their current diagnoses are related to their time in service. Reach out to your local Veterans Administration to learn more.',
    },
    {
      key: 'medicaid',
      header: 'Medicaid',
      description:
        'Medicaid is a benefit that you can quality for primarily based on your income and assets. There are other ways to qualify, but typically those do not impact people needing long-term care. If your monthly income does not cover all your expenses, and you do not have savings, you may qualify for Medicaid. Medicaid will cover all health care expenses and will cover additional care in the home or could even pay you to be a caregiver. If the individual you are caring for is on Medicaid or may be eligible for this benefit, reach out to their doctor, home health, home care, or hospital provider for help understanding this benefit and to qualify for additional help.',
    },
  ],
};
