import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { QuestionWrapper, AnswerWrapper } from 'Stories/components/Form/styles';
import { Typography, Slider as MuiSlider, IconButton } from '@mui/material';
import { StyledIncomeSliderQuestion } from './incomeSliderStyles';
import SkipButton from './components/SkipButton';
import Button from 'Stories/components/Button';
import { Controller } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const IncomeSlider = ({ controllerProps, name, text, skipOption, onChange, filteredOptions, control, ...props }) => {
  const intl = useIntl();
  const [income, setIncome] = useState({ answerOptionGuid: '', value: 0 });

  const continueClicked = () => {
    onChange(income);
  };

  const marks = [
    {
      value: 0,
      label: '$0',
    },
    {
      value: 100,
      label: '$250k',
    },
  ];

  return (
    <StyledIncomeSliderQuestion>
      <QuestionWrapper className="FormQuestion-root">
        <div className="FormQuestion-wrapper-question">
          <Typography id={`${name}-question`} className="FormQuestion-question" style={{ fontSize: '16px' }}>
            {text}
          </Typography>
          <Typography
            style={{ fontSize: '24px', fontWeight: '700', margin: '24px 0 12px 0px' }}
          >{`$${income.value.toLocaleString()}`}</Typography>
          <AnswerWrapper className="FormAnswer-root" style={{ width: '130px' }}>
            <div
              style={{
                display: 'inline-grid',
                gridTemplateColumns: '60px 120px 60px',
                alignItems: 'flex-start',
                marginBottom: '16px',
              }}
            >
              <IconButton
                style={{ color: 'white' }}
                disabled={income.value <= 1000}
                onClick={() => setIncome({ answerOptionGuid: filteredOptions[0].name, value: income.value - 1000 })}
              >
                <RemoveIcon />
              </IconButton>
              <Controller
                {...controllerProps}
                name={name}
                control={control}
                defaultValue={0}
                render={() => {
                  return (
                    <MuiSlider
                      name="IncomeSlider"
                      onChange={(e) => {
                        setIncome({ answerOptionGuid: filteredOptions[0].name, value: e.target.value * 2500 });
                      }}
                      value={income.value / 2500}
                      marks={marks}
                      {...props}
                    />
                  );
                }}
              />
              <IconButton
                style={{ color: 'white' }}
                disabled={income.value >= 249000}
                onClick={() => setIncome({ answerOptionGuid: filteredOptions[0].name, value: income.value + 1000 })}
              >
                <AddIcon />
              </IconButton>
            </div>
          </AnswerWrapper>
          <Typography style={{ fontSize: '14px' }}>
            {intl.formatMessage({ id: 'screenings.form.adult-prapare.question-12b' })}
          </Typography>
        </div>
        <SkipButton skipOption={skipOption} onChange={onChange} />
      </QuestionWrapper>
      <Button style={{ width: '100%' }} onClick={continueClicked} disabled={!income.answerOptionGuid}>
        {intl.formatMessage({ id: 'common.continue' })}
      </Button>
    </StyledIncomeSliderQuestion>
  );
};

IncomeSlider.propTypes = {
  controllerProps: PropTypes.object,
  name: PropTypes.string,
  text: PropTypes.string,
  control: PropTypes.object,
  skipOption: PropTypes.object,
  onChange: PropTypes.func,
  filteredOptions: PropTypes.array,
};

export default IncomeSlider;
