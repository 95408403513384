import styled from '@emotion/styled';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

export const StyledContainer = styled.section`
  margin-top: ${(props) => (props.hasNoSectionMargin ? '0;' : '40px;')};
  width: 100%;
`;

export const StyledHeader = styled.h2`
  margin: 0 0 9px;
  color: #3f457e;
  font-size: 18px;
`;

export const StyledDescription = styled.p`
  margin-bottom: 10px;
  white-space: pre-line;
  line-height: 24px;
  margin-top: 0;
`;

export const StyledAccordion = styled(Accordion)`
  margin-top: 10px;
  font-size: 14px;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 10px;
  color: #3f457e;
  min-height: 20px;

  &.Mui-expanded {
    min-height: 20px;
    margin-bottom: 12px;
  }

  > .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiAccordionSummary-expandIcon {
    padding: 0 12px;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0 12px;
  display: flex;
  flex-direction: column;
`;

export const StyledItemsDescription = styled.div`
  padding-bottom: 12px;
`;

export const StyledItemsDropdownDescription = styled.div`
  padding-bottom: 12px;
  padding-top: 12px;
`;

export const StyledSectionList = styled.ul`
  padding-left: 16px;
`;

export const StyledItemDivider = styled.hr`
  margin: 8px 0 8px -12px;
  background: #000;
`;

export const StyledListItem = styled.li`
  list-style: none;
`;
