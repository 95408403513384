import styled from 'styled-components';
import { Dialog, DialogContent } from '@mui/material';

export const StyledModalContainer = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 15px;
    text-align: center;
    color: #000000;
  }
`;

export const StyledModalBodyContainer = styled(DialogContent)`
  padding: 20px;
  line-height: 23px;
  letter-spacing: 0.17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 35px;
  max-width: 360px;
`;

export const StyledHeader = styled.h2`
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  padding: 12px;
`;

export const StyledContent = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  overflow: auto;
  padding: 0 5px;
`;

export const StyledMultiButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;
