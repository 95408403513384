import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledParagraphText = styled.p`
  line-height: 1.3;
  color: black;
`;

export const StyledBoldParagraphText = styled(StyledParagraphText)`
  font-weight: bold;
`;

export const StyledParagraphTextWithMarginBottom = styled(StyledParagraphText)`
  margin-bottom: 20px;
`;

export const StyledParagraphTextWithMarginTopAndBottom = styled(StyledParagraphText)`
  margin: 20px 0;
`;

export const StyledVerticallyCenteredParagraph = styled(StyledParagraphText)`
  margin: auto 0;
`;

export const StyledParagraphTextWithNoMargin = styled(StyledParagraphText)`
  margin: 0;
`;

export const StyledButton = styled(Button)`
  align-self: center;
  width: 160px;
  border-radius: 15px;
  height: 40px;
  font-weight: 700;
  background-color: #3249a5;
  color: #ffffff;
  margin-top: auto;

  &:hover {
    background-color: #3249a5;
    color: #ffffff;
  }
`;

export const StyledButtonContainer = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
`;

export const StyledPlantImage = styled.img`
  margin: auto;
  width: 33%;
  max-width: 600px;
  height: auto;
`;
