import { relaxationActivities } from 'Assets/img/my-tools-icons';

export const relaxationActivitiesEn = {
  key: 'relaxation-activities',
  title: 'Relaxation Activities',
  titleImage: relaxationActivities,
  titleImageAlt: '',
  contentId: 38,
  sections: [
    {
      key: 'relaxation-activities-intro',
      description:
        'Caring for others takes patience and giving of yourself. It can feel like it drains your energy. So how do you recharge? The first step is knowing what activities help you relax. Perhaps it is a nice, long bath or reading a book. Whatever it is, make time for it. Give yourself permission to take time for yourself. You deserve it! Even if you only have a few minutes in your day and are feeling very stressed, we have created a few relaxation exercises that you can do on your own time. They are meant to help you quickly recharge or can be used to start or end your day. Use these tools whenever you would like. They are designed to take as little or as much time as you have.',
    },
    {
      key: 'belly-breathing',
      header: 'Deep Belly Breathing (approximately 3-5 minutes)',
      description:
        'You may have heard that when you are in a moment of stress that you should simply “take a deep breath.” Sometimes it takes more than one deep breath. Here is a simple exercise that you can do in less than five minutes. Read through all three steps of the deep belly breathing exercise below first and then try it yourself, pausing after each step. Deep belly breathing can be repeated throughout your day, anytime you feel like you need a break.',
      itemsHeader: 'Tell me more',
      items: [
        { key: 'step-one', description: '<b>Step 1</b>' },
        {
          text: 'Find a seated position in a room where you can be alone. If you are seated in a chair, make sure that you can have two feet on the floor comfortably. If you are sitting on the floor, make sure that you are in a comfortable position, perhaps sitting tall with your legs crossed.',
        },
        { text: 'Place your right hand at the top of your chest and your left hand on your stomach.' },
        { text: 'Close your eyes.' },
        {
          text: 'Hold your hands on your chest long enough to notice your current breathing pattern and to feel your heart beating.',
        },
        {
          text: 'Observe how you feel. Acknowledge your thoughts and how you are feeling. If you are feeling stressed, allow yourself to have that thought and even say it out loud.',
        },
        { key: 'step-two', description: '<b>Step 2</b>' },
        {
          text: 'Take a deep breath in through your nose to fill up your belly with air. When you cannot fill your belly any further, empty your belly of air through your mouth, as if you were blowing up a balloon.',
        },
        {
          text: 'Notice if your right or left hand moves when you take in and let out this belly breath. If your right hand moves, your belly breath is too shallow. You want only your left hand to move as your belly breathes in and out.',
        },
        {
          text: 'Notice your shoulders. Do they move up when you are breathing? Work to relax your shoulders and not to let them move as you are breathing. Let your belly do all the work.',
        },
        { key: 'step-three', description: '<b>Step 3</b>' },
        { text: 'Do at least three deep belly breaths on your own.' },
        { text: 'Observe any shifts in your breathing or how you feel.' },
        {
          text: 'Take time now to observe how you feel. Do you feel the same or different compared to when you entered the room?',
        },
      ],
    },
    {
      key: 'mindfulness-exercise',
      header: 'Easy Mindfulness Exercise – A Cool Rain Shower (Approximately 5-7 minutes)',
      description:
        'Read through all four steps of this cool rain shower mindfulness exercise below. Next, try it yourself, pausing after each step. This exercise can be repeated throughout your day, anytime you feel like you need a break.',
      itemsHeader: 'Tell me more',
      items: [
        { key: 'step-one', description: '<b>Step 1</b>' },
        {
          text: 'Find a quiet place to sit or lay down. If you are seated in a chair, make sure that you can have two feet on the floor comfortably and that you are sitting up tall. If you are sitting on the floor, make sure that you are in a comfortable position, perhaps sitting tall with your legs crossed. If you are lying down, make sure that you are on your back with your legs stretched out and your arms comfortably at your side.',
        },
        {
          text: 'Stretch out your arms with your palms up. If you are seated, place the backs of your hands comfortably on your legs near your knees.',
        },
        { key: 'step-two', description: '<b>Step 2</b>' },
        { text: 'Close your eyes. Look at the backs of your eyelids. It should be black and feel calm.' },
        {
          text: 'Observe your heart beating. Notice if you have any thoughts that come to your mind. Thoughts may come to you throughout this exercise, such as tasks you need to complete or thinking of an argument that you just had. This is normal. Acknowledge the thought, then allow yourself to bring your focus back to the back of your eyelids.',
        },
        {
          text: 'If you are feeling stressed, your breathing is shallow, or you feel out of breath, stay like this for a couple of minutes or until you notice that your breathing starts to become slower, calmer.',
        },
        { key: 'step-three', description: '<b>Step 3</b>' },
        { text: 'Visualize yourself standing and looking up at the sky.' },
        {
          text: 'Think of the sky opening. A gentle, cool rain begins—rain that feels soothing and relaxing as it hits the top of your head.',
        },
        {
          text: 'Now think of all the troubling or stressful thoughts or emotions that are holding you back in this moment. They may be things like your overwhelming to-do list, or negative thoughts of self-doubt. Recognize them.',
        },
        { key: 'step-four', description: '<b>Step 4</b>' },
        {
          text: 'Now it is time to let those thoughts go. There is no need to give those thoughts any more time or space.',
        },
        {
          text: 'The goal of this exercise is to wash any unwanted and unhelpful thoughts away, making space for more positive and supportive thoughts.',
        },
        { text: 'Visualize the rain falling down over your face and washing away anything unwanted.' },
        {
          text: 'Follow those things slowly as they wash down your arms, off your fingertips, and off of you. Follow anything remaining down your legs and off the ends of your toes; they are free from you.  ',
        },
        {
          key: 'exit-stage-left',
          description:
            'Sometimes you may have thoughts or things that are harder to get rid of. That is okay. Repeat the exercise, and more slowly follow the rain drops down and away from you. It may take multiple times of completing this exercise to rid yourself of a negative thought.',
        },
      ],
    },
  ],
};
