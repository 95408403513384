import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useCoreDispatch } from 'Contexts/StoreContext';
import { editReminder } from 'Core_Redux/reminders';
import CharacterGreetingHeader from 'Core_Components/CharacterGreetingHeader/CharacterGreetingHeader';
import ReminderConfigurationForm from '../components/ReminderConfigurationForm/ReminderConfigurationForm';
import SetReminderSuccessModal from '../components/Modals/SetReminderSuccessModal';
import GreetingPyxir from 'Assets/img/greeting-pyxir.svg';
import HiFriendMessageEn from 'Assets/img/character-header/pyxir-greeting-2x.en.png';
import HiFriendMessageEs from 'Assets/img/character-header/pyxir-greeting-2x.es.png';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { legacyBackgroundColors } from 'Styles/colors';
import { StyledRemindersContentContainer, StyledRemindersPageContainer } from 'Core_Pages/Reminders/styles';
import { SET_SUCCESS } from '../components/Modals/modals';

function EditReminder() {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useCoreDispatch();

  function submit(updatedReminder) {
    updatedReminder.guid = location.state.guid;
    updatedReminder.isActive = true;
    dispatch(editReminder({ updatedReminder, successModal: SET_SUCCESS }));
  }

  return (
    <StyledRemindersPageContainer>
      <ScreenReaderWrapper>
        <h1>{intl.formatMessage({ id: 'title.reminders-edit' })}</h1>
      </ScreenReaderWrapper>
      <CharacterGreetingHeader
        isPyxirHeader={true}
        message={intl.formatMessage({ id: 'reminders.edit-reminder-below' })}
        imageSrc={GreetingPyxir}
        imageAlt={intl.formatMessage({ id: 'greeting.pyxir' })}
        imageAlignment="flex-start"
        backgroundColor={legacyBackgroundColors.indigo}
        greeting={{
          src: intl.formatMessage({ id: 'hi.friend' }).includes('en.svg') ? HiFriendMessageEn : HiFriendMessageEs,
          alt: intl.formatMessage({ id: 'image.pyxir.hifriend' }),
        }}
      />
      <StyledRemindersContentContainer>
        <ReminderConfigurationForm submit={submit} defaultValues={location.state}></ReminderConfigurationForm>
      </StyledRemindersContentContainer>
      <SetReminderSuccessModal />
    </StyledRemindersPageContainer>
  );
}

export default EditReminder;
