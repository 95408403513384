import React from 'react';
import { Typography } from '@mui/material';
import { StyledCloseIconContainer } from '../styles';
import { StyledScreeningInfoContainer, StyledDialog } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { black } from 'Styles/colors';
import { useIntl } from 'react-intl';

const questionnaires = [
  { titleId: 'screenings.check-in.loneliness-title', descriptionId: 'screenings.check-in.loneliness-description' },
  { titleId: 'screenings.check-in.depression-title', descriptionId: 'screenings.check-in.depression-description' },
  { titleId: 'screenings.check-in.needs-title', descriptionId: 'screenings.check-in.needs-description' },
];

function InfoDialog({ open, onClose }) {
  const intl = useIntl();

  return (
    <StyledDialog open={open} onClose={onClose} aria-labelledby="info-dialog">
      <StyledCloseIconContainer aria-label={intl.formatMessage({ id: 'button.close' })} onClick={onClose}>
        <CloseIcon fontSize="inherit" fontWeight="inherit" />
      </StyledCloseIconContainer>
      <Typography id="info-dialog" fontSize={18}>
        {intl.formatMessage({ id: 'screenings.check-in.questionnaire-info' })}
      </Typography>
      <StyledScreeningInfoContainer>
        {questionnaires.map(({ titleId, descriptionId }, index) => (
          <div key={index}>
            <Typography fontSize={16} color={black._60} paddingBottom={'3px'}>
              {intl.formatMessage({ id: titleId })}
            </Typography>
            <Typography fontSize={16}>{intl.formatMessage({ id: descriptionId })}</Typography>
          </div>
        ))}
      </StyledScreeningInfoContainer>
    </StyledDialog>
  );
}

InfoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default InfoDialog;
