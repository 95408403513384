export const questions = [
  {
    name: 'ba671ac9-7196-4e88-903b-6cc855137696',
    textId: 'screenings.form.youth-needs.question-1',
    options: [
      {
        name: '78e48dd1-258c-4fbe-bd40-f579119b06fc',
        textId: 'common.no',
      },
      {
        name: 'cb0639c9-ed37-49ad-834b-f55320216cc8',
        textId: 'common.yes',
      },
    ],
  },
  {
    name: '6524a0b6-1b20-44ac-8a29-3b9a42b533f8',
    textId: 'screenings.form.youth-needs.question-2',
    options: [
      {
        name: '0c5b8e44-5fd3-4533-95a5-df1067cc4a4c',
        textId: 'common.no',
      },
      {
        name: '7cb64579-abe1-46d4-af72-4d9b38848ec3',
        textId: 'common.yes',
      },
    ],
  },
  {
    name: '40769842-8115-45d3-94e6-5d708131d8cc',
    textId: 'screenings.form.youth-needs.question-3',
    options: [
      {
        name: '07778e8f-6a1c-49bb-938f-9c3083421bdf',
        textId: 'common.no',
      },
      {
        name: 'f8162c46-9e70-4bc8-95af-d461c13c2f59',
        textId: 'common.yes',
      },
    ],
  },
  {
    name: 'a41161f7-553a-4852-bba8-988f9d5bd3ac',
    textId: 'screenings.form.youth-needs.question-4',
    options: [
      {
        name: 'ed439c3f-5e76-4819-a904-87292da41c5d',
        textId: 'common.no',
      },
      {
        name: '6572bb1b-0027-48ac-b152-009b36709f53',
        textId: 'common.yes',
      },
    ],
  },
  {
    name: 'a153dfc1-40e9-49d1-94b0-cd9db4da0023',
    textId: 'screenings.form.youth-needs.question-5',
    options: [
      {
        name: '5849d8f0-fa5c-4128-818f-a6550d9d049c',
        textId: 'common.no',
      },
      {
        name: '41eab832-9f3e-4ed2-9384-d38f83231f46',
        textId: 'common.yes',
      },
    ],
  },
  {
    name: '644c5538-00fc-45d8-adde-433dd27c3acc',
    textId: 'screenings.form.youth-needs.question-6',
    options: [
      {
        name: '1143668c-cb65-48cc-9525-2733447d53a8',
        textId: 'common.no',
      },
      {
        name: '1681cd18-c2db-47a0-b372-5635d7f01d40',
        textId: 'common.yes',
      },
    ],
  },
  {
    name: '0262bd99-25ee-4e06-9bd2-11666328005a',
    textId: 'screenings.form.youth-needs.question-7',
    options: [
      {
        name: '2de62de8-d114-43ba-9f4b-b40eeba73990',
        textId: 'common.no',
      },
      {
        name: '71e26680-4682-471b-9c3e-25f7b7419fc0',
        textId: 'common.yes',
      },
    ],
  },
  {
    name: '3ec39b3a-e4f2-4ac9-8dab-3de9f09a7e27',
    textId: 'screenings.form.youth-needs.question-8',
    options: [
      {
        name: 'e1fc2990-79cc-4ba8-b92d-0c1e31814f3c',
        textId: 'common.no',
      },
      {
        name: '2608f03d-82a9-496d-b770-dff6a52fe354',
        textId: 'common.yes',
      },
    ],
  },
  {
    name: '61d2b911-7a98-46c7-89c4-c909f5550518',
    textId: 'screenings.form.youth-needs.question-9',
    options: [
      {
        name: '379a519e-4a18-4b82-857c-4a0e77215697',
        textId: 'common.no',
      },
      {
        name: '257935fe-13b1-478e-8850-d1ab41214948',
        textId: 'common.yes',
      },
    ],
  },
  {
    name: 'ce2f0425-1bd7-481b-a335-2f77ff162596',
    textId: 'screenings.form.youth-needs.question-10',
    options: [
      {
        name: 'ec2372db-6460-4007-b72f-2d134ce258ca',
        textId: 'common.no',
      },
      {
        name: '53426981-153a-4653-8940-519d9ce4b0a7',
        textId: 'common.yes',
      },
    ],
  },
];
