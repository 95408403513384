import styled from '@emotion/styled';

export const StyledRemindersPageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  color: black;
  font-family: karla;
  overflow: hidden;
`;

export const StyledRemindersContentContainer = styled.div`
  width: 100%;
  align-self: center;
  padding: 12px 12px 108px 12px;
  overflow: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 12px;
`;

export const StyledSpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  background: white;
`;
