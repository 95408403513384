const KEEPALIVEFREQUENCY = 30000;

export class WebSocketService {
  isSessionActive: boolean;
  socket: WebSocket;
  url: string;
  onmessageCallback;
  onopenCallback;
  onerrorCallback;

  constructor(onWSMessage, onWSOpen, onWSError) {
    this.onmessageCallback = onWSMessage;
    this.onopenCallback = onWSOpen;
    this.onerrorCallback = onWSError;
  }

  public Connect = (url: string) => {
    if (!this.isSessionActive) {
      this.url = url;
      this.socket = new WebSocket(url);
      this.socket.onmessage = this.onmessageCallback;
      this.socket.onopen = this.onopenCallback;
      this.socket.onerror = this.onerrorCallback;
      this.socket.onclose = this.OnClose;
      this.isSessionActive = true;
      this.KeepAlive();
    }
  };

  public Send = (message: string) => {
    if (this.socket?.readyState == WebSocket.OPEN) {
      this.socket.send(message);
    }
  };

  public Close = () => {
    this.isSessionActive = false;
    this.socket?.close();
  };

  public Reconnect = () => {
    this.socket = new WebSocket(this.url);
    this.socket.onmessage = this.onmessageCallback;
    this.socket.onopen = this.onopenCallback;
    this.socket.onerror = this.onerrorCallback;
    this.socket.onclose = this.OnClose;
  };

  private OnClose = () => {
    if (this.isSessionActive) {
      this.Reconnect();
    }
  };

  private KeepAlive = () => {
    setTimeout(() => {
      if (this.socket.readyState == WebSocket.OPEN) {
        this.socket?.send('[]');
        if (this.isSessionActive) {
          this.KeepAlive();
        }
      }
    }, KEEPALIVEFREQUENCY);
  };
}
