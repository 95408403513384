import { getCurrentFullDate, isNowPastDate } from './dateHelper';
import { InteractionLimit } from '../constants/interactionLimit';
import { InteractionType } from '../constants/interactionType';

export const LogInteraction = (game, interactionType, sendRequest = true) => {
  const currentUserId = game.sceneData.gameData.gameData.userId;
  const currentProfileId = game.sceneData.appData.userInfo.activeProfileId;

  if (sendRequest) {
    game.sceneData.appData.createPetInteraction(currentUserId, {
      InteractionType: interactionType?.CODE,
    });
  }

  let currentPetInfo = getPetInfo();

  // if the profile id is different (i.e. as is the case with member migrations or switches)
  // then reset the pet info
  if (!currentPetInfo || currentPetInfo?.profileId !== currentProfileId) {
    resetPetInfo(packUserInfoFromGame(game));
    currentPetInfo = getPetInfo();
  }

  if (currentPetInfo.interactions[interactionType?.CODE] !== undefined) {
    currentPetInfo.interactions[interactionType?.CODE]++;
  } else {
    currentPetInfo.interactions = { ...currentPetInfo.interactions, [interactionType?.CODE]: 1 };
  }

  setPetInfo(packUserInfoFromGame(game), currentPetInfo);
};

export const getPetInfo = () => {
  return JSON.parse(localStorage.getItem('PetInfo'));
};
export const setPetInfo = (userInfo, data) => {
  if (!getPetInfo()) {
    resetPetInfo(userInfo, data);
  } else {
    if (isNowPastDate(getPetInfo().lastUpdated)) {
      resetPetInfo(userInfo, data);
    } else {
      localStorage.setItem('PetInfo', JSON.stringify({ ...getPetInfo(), ...data }));
    }
  }
};

export const resetPetInfo = (userInfo, data) => {
  let userData;
  if (userInfo) {
    userData = { profileId: userInfo.profileId, userId: userInfo.userId };
  }
  let newData = { lastUpdated: getCurrentFullDate(), interactions: {} };
  newData = { ...newData, ...userData, ...data };
  localStorage.setItem('PetInfo', JSON.stringify(newData));
};

export const packUserInfoFromGame = (game) => {
  return {
    profileId: game.sceneData.appData.userInfo.activeProfileId,
    userId: game.sceneData.gameData.gameData.userId,
  };
};

export const interactionExceedsLimit = (game) => {
  const petType = game.sceneData.appData.info.data.gameData.petState.petType;
  const interactionName = game.sceneData.gameData.sceneType;

  const interactionType = InteractionType[interactionName];

  const limit = InteractionLimit[petType][interactionType?.CODE];

  const petInfo = getPetInfo();

  if (petInfo) {
    const currentTimes = petInfo?.interactions[interactionType?.CODE] ?? 0;
    return currentTimes >= limit?.LIMIT;
  } else {
    return false;
  }
};
