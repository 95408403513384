export const badgeTypes = {
  ANDYCALL: 'ANDYCALL',
  MYTOOLS: 'MYTOOLS',
  PYXIR: 'PYXIR',
  SCREENING: 'SCREENING',
};

export const badgeStatuses = {
  NOTSTARTED: 'NOTSTARTED',
  COMPLETE: 'COMPLETE',
  MISSED: 'MISSED',
};

export const thriveStatuses = {
  GRADUATE: 'GRADUATE',
  ACTIVE: 'ACTIVE',
  DROPOUT: 'DROPOUT',
};

export const thriveProgramTypes = {
  YOUTH: 'YOUTH',
  STANDARD: 'STANDARD',
};

export const thriveDimensionTypes = {
  SOCIAL: 'SOCIAL',
  EMOTIONAL: 'EMOTIONAL',
  PHYSICAL: 'PHYSICAL',
  INTELLECTUAL: 'INTELLECTUAL',
};

export const thriveWorkflowTypes = {
  DEFAULT: 'DEFAULT',
};
