import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { StyledContent, StyledHeader, StyledModalBodyContainer, StyledModalContainer } from './styles';
import { DirectMessageStore } from 'Core_Pages/DirectMessage/Store/DirectMessageStore';
import { Button, Grid, Typography } from '@mui/material';

const EndSessionConfirmationModal = ({ open, onCloseCallback }) => {
  const intl = useIntl();
  const directMessageStore = DirectMessageStore.Instance().Use();

  const onCancel = () => {
    onCloseCallback();
  };

  const onContinue = () => {
    directMessageStore.EndSession();
    onCloseCallback();
  };

  return (
    <StyledModalContainer id={'end-dm-session-modal'} open={open || false}>
      <StyledModalBodyContainer>
        <Grid container textAlign="left">
          <Grid item mobile={12}>
            <StyledHeader>{intl.formatMessage({ id: 'direct-message.end-session.modal-header' })}</StyledHeader>
          </Grid>
          <Grid item mobile={12}>
            <StyledContent>{intl.formatMessage({ id: 'direct-message.end-session.modal-content' })}</StyledContent>
          </Grid>
          <Grid item mobile={8} textAlign="right">
            <Button variant="text" sx={{ marginRight: '10px' }} onClick={onCancel}>
              <Typography fontSize="18px" fontWeight="700">
                {intl.formatMessage({ id: 'common.cancel' })}
              </Typography>
            </Button>
          </Grid>
          <Grid item mobile={4}>
            <Button variant="text" onClick={onContinue}>
              <Typography fontSize="18px" fontWeight="700">
                {intl.formatMessage({ id: 'common.continue' })}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </StyledModalBodyContainer>
    </StyledModalContainer>
  );
};

EndSessionConfirmationModal.propTypes = {
  open: PropTypes.bool,
  onCloseCallback: PropTypes.func,
};

export default EndSessionConfirmationModal;
