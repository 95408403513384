export const Config = {
  KEY: 'FRONTDOOR',
  ANIMATIONS: {
    IDLE: {
      frames: [0],
      frameRate: 5,
      repeat: 0,
    },
    IDLE2: {
      frames: [4],
      frameRate: 5,
      repeat: 0,
    },
    OBJECT_INTERACT: {
      frames: [0, 1, 2, 3, 4],
      frameRate: 5,
      repeat: 0,
    },
  },
  ATTACHPOINTS: {
    MOUTH: {},
    NECK: {},
  },
};
