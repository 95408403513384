import { thingAboutMe } from 'Assets/img/my-tools-icons';

export const thingsILikeEs = {
  key: 'things-i-like-about-me-es',
  title: 'Cosas que me gustan de mí',
  titleImage: thingAboutMe,
  titleImageAlt: '',
  contentId: 20,
  sections: [
    {
      key: 'things-i-like-intro-es',
      hasNoSectionMargin: true,
      description:
        '¿No es sorprendente? ¡No hay otro TÚ en el planeta Tierra! Tú eres único, por dentro y por fuera. A pesar de lo que cualquier otra persona pueda decir o insinuar, tienes tienen cualidades, dones y talentos que nadie más puede dar al mundo, ¡y eso es así!\n\nSentirse bien consigo mismo es parte del amor propio y de una autoimagen saludable y auténtica. Todo el mundo tiene fortalezas y debilidades. Igual que el resto del mundo, mereces amor y afecto. Reconocer que único y las cosas que te diferencian de los demás puede ayudarte a alejar los pensamientos negativos y a fortalecer los sentimientos positivos todos los días. Esta es una actividad divertida para potenciar una autoimagen saludable sin temor a ser egoísta o ensimismado y poder inspirar a tu influencer interior.',
    },
    {
      key: 'things-i-like-exercise-es',
      description:
        'Solo piensa en las cosas que le gustan de ti y anótalas. Luego, toma un tiempo para reconocer estas verdades y reflexiona cómo te hacen sentir.\n\nPor ejemplo, podrías tener una libreta de papel junto a tu cama y anotar cada noche antes de irte a dormir tres cosas que te gustaron de ti ese día. Luego, piensa en cómo te hacen sentir esas verdades antes de dormirte. Respirar profundamente con cada lectura puede ser útil para "arraigar" el sentimiento interior. Por la mañana, lee otra vez la lista antes de comenzar el día para reconocer esas verdades y empoderar tu autoimagen. Sigue añadiendo cosas nuevas a tu lista cada día o cuando quieras.\n\nEstará bien de cualquier manera que lo hagas. Estas son tus verdades únicas; aquí no importa la opinión de nadie más. Tómate tu tiempo y deja que salga. Observa toda tu personalidad, lo que puedes hacer, cómo tratas a los demás, quién eres, etc. Algunas preguntas que puedes hacerte son...',
      bulletedItems: [
        { text: 'Me siento bien con mi…' },
        { text: 'Las cosas en las que destaco son...' },
        { text: 'Algunos desafíos que he superado son...' },
        { text: 'Algunos elogios que he recibido son...' },
        { text: 'Me hace sentir bien cuando...' },
        { text: 'Lo que me gusta de mi personalidad es...' },
        { text: 'Mis características físicas que me gustan son...' },
        { text: 'He tenido éxito antes en...' },
        { text: 'He ayudado a otros a sentirse bien al...' },
        { text: 'Las características de las que más me enorgullezco son...' },
        { text: 'Algo que disfruto mucho es...' },
        { text: 'Me gusta quién soy porque...' },
        { text: 'Lo que me diferencia de los demás de forma única es...' },
        { text: 'Realmente me admiro por...' },
        { text: 'Se me da bien por naturaleza...' },
      ],
    },
    {
      key: 'things-i-like-outro-es',
      description:
        'Tomar conciencia de tus verdades personales puede ayudarte a aumentar los sentimientos de bienestar, confianza y felicidad. ¡Así que celébrate a ti mismo! Esta sencilla actividad puede ayudar a mejorar recordarle a tu mente y a su cuerpo que debe seguir buscando cosas que lo animen, e incluso ayudar a otros a hacer lo mismo.',
    },
  ],
};
