import styled from '@emotion/styled';
import { Paper, Stepper } from '@mui/material';
import Button from 'Stories/components/Button/Button';
import { breakpoints } from 'Styles/theme';
import { purple } from 'Styles/colors';

export const StepperContainer = styled.div`
  margin-left: 15px;
  padding-left: 20px;
  padding-right: 8px;
  border-left: 5px solid #bdbdbd;
  margin-top: -20px;
`;

export const ScreeningContainer = styled(Paper)`
  display: flex;
  flex-flow: column;
  padding: 20px;
  width: 90%;
  margin: 0 auto;
  white-space: pre-wrap;

  &.MuiPaper-root {
    border: none;
    border-radius: 5px;
  }
`;

export const StepperButton = styled(Button)(
  ({ theme }) => `
    width: 80%;
    margin: 0 auto;
    margin-top: 15px;
  
    ${theme.breakpoints.down(breakpoints.DESKTOP)} {
      width: 65%;
    }
  `,
);

export const VerticalConnectedStepper = styled(Stepper)(
  ({ theme }) => `
    padding: 10px;
  
    ${theme.breakpoints.up(breakpoints.TABLET)} {
      padding: 10px 20%;
    }
  
    ${theme.breakpoints.up(breakpoints.DESKTOP)} {
      padding: 10px 33%;
    }
  
    .MuiStepConnector-root {
      margin-left: 15px;
    }
  
    .MuiStepConnector-line {
      border-left-width: 5px;
      height: 100%;
    }
  
    .MuiStepLabel-iconContainer {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      margin-left: 7px;
      background-color: white;
      align-self: flex-start;
  
      &.Mui-active {
        border: 4px solid ${purple._900};
      }
      &.Mui-disabled {
        border: 2px solid #979797;
        padding: 2px;
      }
  
      &.Mui-active,
      &.Mui-disabled {
        .MuiSvgIcon-root, .MuiStepIcon-text {
          display: none;
        }
      }
      &.Mui-completed > .MuiSvgIcon-root {
        width: 22px;
        margin-top: -2px;
      }
    }
  
    .MuiStepLabel-root {
      padding: 0px;
    }
  
    .MuiStep-root:last-of-type > .StepperContent {
      border-left: none;
    }
  `,
);
