import React from 'react';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import Button from 'Stories/components/Button';
import { Header, StyledPasswordInput } from 'Onboard_Components/Account/styles';
import { useOnboardSelector } from 'Contexts/StoreContext';
import { selectBirthday, selectPasswordResetOtp, selectRecoveryMethodInput } from 'Onboard_Redux/login';
import { useUpdatePasswordMutation } from 'Onboard_Redux/services/identity';

function ResetPassword() {
  const intl = useIntl();
  const otp = useOnboardSelector(selectPasswordResetOtp);
  const recoveryMethodInput = useOnboardSelector(selectRecoveryMethodInput);
  const birthday = useOnboardSelector(selectBirthday);
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  const {
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
    getValues,
  } = useForm();

  const formComplete = dirtyFields.createPassword && dirtyFields.confirmPassword;

  function onSubmit({ createPassword }) {
    updatePassword({ password: createPassword, code: otp, emailOrPhoneNumber: recoveryMethodInput, birthday });
  }

  return (
    <>
      <Header>{intl.formatMessage({ id: 'account.log-in.reset-password' })}</Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="createPassword"
          control={control}
          defaultValue=""
          rules={{ minLength: { value: 6, message: intl.formatMessage({ id: 'error.min-characters' }, { min: 6 }) } }}
          render={({ field }) => (
            <StyledPasswordInput
              {...field}
              label={intl.formatMessage({ id: 'account.log-in.create-new-password' })}
              errorMessage={errors.createPassword?.message}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          defaultValue=""
          rules={{
            validate: (v) =>
              v === getValues('createPassword') || intl.formatMessage({ id: 'account.log-in.password-match-error' }),
          }}
          render={({ field }) => (
            <StyledPasswordInput
              {...field}
              label={intl.formatMessage({ id: 'account.log-in.confirm-new-password' })}
              errorMessage={errors.confirmPassword?.message}
            />
          )}
        />
        <Button fullWidth loading={isLoading} type="submit" disabled={!formComplete} sx={{ margin: '8px 0' }}>
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
    </>
  );
}

export default ResetPassword;
