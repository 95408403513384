import React from 'react';
import { useIntl } from 'react-intl';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { selectCurrentModal, deleteReminder, cancelDelete } from 'Core_Redux/reminders';
import {
  StyledButton,
  StyledCancelButton,
  StyledMultiButtonContainer,
  StyledContent,
  StyledHeader,
  StyledModalBodyContainer,
  StyledModalContainer,
} from './styles';
import { CONFIRM_DELETE } from './modals';

function ConfirmReminderDeleteModal() {
  const intl = useIntl();
  const dispatch = useCoreDispatch();
  const currentModal = useCoreSelector(selectCurrentModal);

  const handleCancel = () => dispatch(cancelDelete());
  const handleConfirm = () => dispatch(deleteReminder());

  return (
    <StyledModalContainer
      id="DeleteReminder_Confirm"
      open={currentModal === CONFIRM_DELETE}
      aria-labelledby="DeleteReminder_Confirm_Title"
      aria-describedby="DeleteReminder_Confirm_Content"
    >
      <StyledModalBodyContainer>
        <StyledHeader id="DeleteReminder_Confirm_Title">
          {intl.formatMessage({ id: 'reminders.modal-confirm-deletion-header' })}
        </StyledHeader>
        <StyledContent id="DeleteReminder_Confirm_Content">
          {intl.formatMessage({ id: 'reminders.modal-confirm-deletion-body' })}
        </StyledContent>
        <StyledMultiButtonContainer>
          <StyledCancelButton onClick={handleCancel}>{intl.formatMessage({ id: 'common.cancel' })}</StyledCancelButton>
          <StyledButton onClick={handleConfirm}>{intl.formatMessage({ id: 'common.delete' })}</StyledButton>
        </StyledMultiButtonContainer>
      </StyledModalBodyContainer>
    </StyledModalContainer>
  );
}

export default ConfirmReminderDeleteModal;
