import styled from '@emotion/styled';
import { MenuItem, Select } from '@mui/material';
import { legacyBackgroundColors } from 'Styles/colors';

export const StyledSelect = styled(Select)`
  margin: 10px 0 20px 75px;

  // select arrow icon
  & .MuiSelect-icon {
    color: ${legacyBackgroundColors.indigo};
  }
  & .MuiSelect-root:focus {
    background: inherit;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${legacyBackgroundColors.indigo};
  font-weight: 700;
  font-size: 14px;
`;
