import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

const Deer = (props) => {
  const intl = useIntl();

  return (
    <svg
      role="img"
      focusable="false"
      aria-labelledby="svgDeerTitle"
      aria-describedby="svgDeerDescription"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 900 900"
    >
      <title id="svgDeerTitle">
        <FormattedMessage id="image.coloring-book.deer" />
      </title>
      <desc id="svgDeerDescription">{intl.formatMessage({ id: 'image.coloring-book.deer-description' })}</desc>
      <g id="DeerLayer" data-name="Layer">
        <polygon
          onClick={() => props.onFill(0)}
          fill={props.colors[0]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="473.96 498.24 461.55 521.81 481.11 522.71 480.94 546.27 465.59 546.86 459.81 564.33 500 549.07 496.96 524.96 473.96 498.24"
        />
        <polygon
          onClick={() => props.onFill(1)}
          fill={props.colors[1]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="375.58 405.86 376.09 422.73 409.78 449.36 430.29 417.68 426.83 358.34 366.36 380.85 375.58 405.86"
        />
        <polygon
          onClick={() => props.onFill(2)}
          fill={props.colors[2]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="416.33 524.56 416.49 546.44 434.1 547.9 441.58 564.47 459.81 564.33 465.59 546.86 480.94 546.27 481.11 522.71 438.44 522.4 416.33 524.56"
        />
        <polygon
          onClick={() => props.onFill(3)}
          fill={props.colors[3]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="492.4 448.75 492.4 448.75 470.47 418.38 466.16 463.42 473.96 498.24 503.04 464.53 492.4 448.75"
        />
        <polygon
          onClick={() => props.onFill(4)}
          fill={props.colors[4]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="450.65 479.59 466.16 463.42 475.09 358.02 451.16 361.21 426.83 358.34 433.87 463.5 450.65 479.59"
        />
        <polygon
          onClick={() => props.onFill(5)}
          fill={props.colors[5]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="466.16 463.42 450.65 479.59 433.87 463.5 424.48 497.76 438.44 522.4 461.55 521.81 473.96 498.24 466.16 463.42"
        />
        <polygon
          onClick={() => props.onFill(6)}
          fill={props.colors[6]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="518.29 463.62 545.76 452.08 526.67 422.82 492.4 448.75 503.04 464.53 518.29 463.62"
        />
        <polygon
          onClick={() => props.onFill(7)}
          fill={props.colors[7]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="398.8 549.04 441.58 564.47 434.1 547.9 416.49 546.44 416.33 524.56 438.44 522.4 424.48 497.76 398.51 524.84 398.8 549.04"
        />
        <polygon
          onClick={() => props.onFill(8)}
          fill={props.colors[8]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="533.62 338.62 533.62 338.62 573.81 291.93 591.9 237.67 569.15 167.02 563.1 189.71 574.36 241.4 573.85 242.11 488.01 362.15 533.99 378.45 533.62 338.62"
        />
        <polygon
          onClick={() => props.onFill(9)}
          fill={props.colors[9]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="492.4 448.75 526.67 422.82 525.12 403.66 533.99 378.45 475.09 358.02 470.47 418.38 492.4 448.75"
        />
        <polygon
          onClick={() => props.onFill(10)}
          fill={props.colors[10]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="355.35 447.56 383.98 463.51 402.32 464.54 409.78 449.36 376.09 422.73 355.35 447.56"
        />
        <polygon
          onClick={() => props.onFill(11)}
          fill={props.colors[11]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="533.59 368.1 632.5 294.46 651.75 251.9 639.03 196.46 638.01 249.62 601.71 291.22 533.62 338.62 533.59 368.1"
        />
        <polygon
          onClick={() => props.onFill(12)}
          fill={props.colors[12]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="505.86 564.92 500 549.07 459.81 564.33 441.58 564.47 398.8 549.04 392.36 564.86 411.64 578.7 493.39 577.98 505.86 564.92"
        />
        <polygon
          onClick={() => props.onFill(13)}
          fill={props.colors[13]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="433.87 463.5 430.29 417.68 409.78 449.36 402.32 464.54 424.48 497.76 433.87 463.5"
        />
        <polygon
          onClick={() => props.onFill(14)}
          fill={props.colors[14]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="493.39 577.98 411.64 578.7 451.88 608.67 493.39 577.98"
        />
        <polygon
          onClick={() => props.onFill(15)}
          fill={props.colors[15]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="451.88 608.67 411.64 578.7 341.87 738.85 451.85 852.5 556.71 738.78 493.39 577.98 451.88 608.67"
        />
        <polygon
          onClick={() => props.onFill(16)}
          fill={props.colors[16]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="545.76 452.08 545.76 452.08 538.61 469.5 554.64 450.55 606.86 441.26 636.85 423.27 680.91 350.95 595.28 394.45 545.76 452.08"
        />
        <polygon
          onClick={() => props.onFill(17)}
          fill={props.colors[17]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="525.12 403.66 526.67 422.82 545.76 452.08 595.28 394.45 680.91 350.95 533.99 378.45 525.12 403.66"
        />
        <polygon
          onClick={() => props.onFill(18)}
          fill={props.colors[18]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="518.29 463.62 518.29 463.62 503.04 464.53 473.96 498.24 496.96 524.96 500 549.07 505.86 564.92 532.54 484.74 534.07 480.39 545.76 452.08 518.29 463.62"
        />
        <polygon
          onClick={() => props.onFill(19)}
          fill={props.colors[19]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="505.86 564.92 579.37 680.32 531.31 488.98 505.86 564.92"
        />
        <polygon
          onClick={() => props.onFill(20)}
          fill={props.colors[20]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="493.39 577.98 493.39 577.98 556.71 738.78 579.37 680.32 505.86 564.92 493.39 577.98"
        />
        <polygon
          onClick={() => props.onFill(21)}
          fill={props.colors[21]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="769.95 125.62 746.35 79.8 714.59 55.98 740.61 102.92 742.79 132.8 707.46 127.03 690.87 110 713.38 157.68 749.75 200.79 697.16 268.89 632.5 294.46 533.59 368.1 533.99 378.45 719.1 290.65 768.48 239.97 798.68 125.09 822.87 84.55 822.33 54.72 769.95 125.62"
        />
        <polygon
          onClick={() => props.onFill(22)}
          fill={props.colors[22]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="366.36 380.85 412.1 363.45 325.64 244.81 336.9 193.12 330.85 170.43 308.1 241.08 326.19 295.33 365.89 342.51 366.36 380.85"
        />
        <polygon
          onClick={() => props.onFill(23)}
          fill={props.colors[23]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="392.36 564.86 319.85 680.15 341.87 738.85 411.64 578.7 392.36 564.86"
        />
        <polygon
          onClick={() => props.onFill(24)}
          fill={props.colors[24]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="366.15 368.85 365.89 342.51 298.12 294.5 297.98 294.35 261.99 253.02 261.97 252.31 260.96 199.87 248.25 255.31 268.53 296.14 366.15 368.85"
        />
        <polygon
          onClick={() => props.onFill(25)}
          fill={props.colors[25]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="355.35 447.56 376.09 422.73 375.58 405.86 366.36 380.85 344.63 381.53 224.47 354.26 308.92 394.45 355.35 447.56"
        />
        <polygon
          onClick={() => props.onFill(26)}
          fill={props.colors[26]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="366.15 368.85 268.53 296.14 202.84 272.3 202.52 271.89 150.25 204.2 186.63 161.08 209.13 113.4 192.54 130.44 157.21 136.21 159.39 106.33 159.6 105.95 185.41 59.39 153.65 83.21 130.05 129.03 77.67 58.13 77.13 87.96 101.32 128.5 131.52 243.38 180.9 294.06 366.36 380.85 366.15 368.85"
        />
        <polygon
          onClick={() => props.onFill(27)}
          fill={props.colors[27]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="368.47 488.85 319.85 680.15 392.36 564.86 368.47 488.85"
        />
        <polygon
          onClick={() => props.onFill(28)}
          fill={props.colors[28]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="355.35 447.56 308.92 394.45 224.47 354.26 224.47 354.26 260.85 423.35 286.86 441.25 350.12 450.54 361.68 466.52 355.35 447.56 355.35 447.56"
        />
        <polygon
          onClick={() => props.onFill(29)}
          fill={props.colors[29]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          points="367.5 484.83 392.36 564.86 398.8 549.04 398.51 524.84 424.48 497.76 402.32 464.54 383.98 463.51 383.98 463.51 355.35 447.56 366.22 480.41 367.5 484.83"
        />
      </g>
    </svg>
  );
};

Deer.propTypes = {
  onFill: PropTypes.func,
  colors: PropTypes.array,
};

export default Deer;
