import { bowlAssetConfig } from '../Actor/Item/Object/Bowl/BowlConfig';
import { frontDoorAssetConfig } from '../Actor/Item/Object/FrontDoor/FrontDoorConfig';
import { nutrientContainerAssetConfig } from '../Actor/Item/Object/NutrientContainer/NutrientContainerConfig';
import { gameImportType } from './gameImportType';
import { butterflyAssetConfig } from 'Core_Games/Actor/NPC/Ambience/Butterfly/ButterflyConfig';
import { familyAssetConfig } from '../Actor/NPC/Human/Family/familyConfig';
import { oldManAssetConfig } from '../Actor/NPC/Human/OldMan/oldManConfig';
import { wheelchairWomanAssetConfig } from '../Actor/NPC/Human/WheelchairWoman/wheelchairWomanConfig';
import { puppyAssetConfig } from '../Actor/NPC/Stray/Puppy/puppyConfig';
import { kittenAssetConfig } from '../Actor/NPC/Stray/Kitten/kittenConfig';

export const gameData = {
  ACTOR: {
    PLAYER: {},
    NPC: {
      HUMAN: {
        FAMILY: {
          NAME: 'FAMILY',
          DATA: familyAssetConfig,
          TYPE: gameImportType.SPRITESHEET,
        },
        OLDMAN: {
          NAME: 'OLDMAN',
          DATA: oldManAssetConfig,
          TYPE: gameImportType.SPRITESHEET,
        },
        WHEELCHAIRWOMAN: {
          NAME: 'WHEELCHAIRWOMAN',
          DATA: wheelchairWomanAssetConfig,
          TYPE: gameImportType.SPRITESHEET,
        },
      },
      STRAY: {
        PUPPY: {
          NAME: 'PUPPY',
          DATA: puppyAssetConfig,
          TYPE: gameImportType.SPRITESHEET,
        },
        KITTEN: {
          NAME: 'KITTEN',
          DATA: kittenAssetConfig,
          TYPE: gameImportType.SPRITESHEET,
        },
      },
    },
    ITEM: {
      WEARABLE: {
        BANDANA: {
          NAME: 'BANDANA',
          DATA: 'props/Dog-Collar-Color.png',
          TYPE: gameImportType.IMAGE,
        },
        BALL: {
          NAME: 'BALL',
          DATA: 'props/Purple-Tennis-Ball.png',
          TYPE: gameImportType.IMAGE,
        },
        YARN: {
          NAME: 'YARN',
          DATA: 'props/Yarn-Ball.png',
          TYPE: gameImportType.IMAGE,
        },
      },
      OBJECT: {
        BOWL: {
          NAME: 'BOWL',
          DATA: bowlAssetConfig,
          TYPE: gameImportType.SPRITESHEET,
        },
        NUTRIENTCONTAINER: {
          NAME: 'NUTRIENTCONTAINER',
          DATA: nutrientContainerAssetConfig,
          TYPE: gameImportType.SPRITESHEET,
        },
        BUTTERFLY: {
          NAME: 'BUTTERFLY',
          DATA: butterflyAssetConfig,
          TYPE: gameImportType.SPRITESHEET,
        },
        BRUSH: {
          NAME: 'BRUSH',
          DATA: 'props/Pyx-Pets-Brush.png',
          TYPE: gameImportType.IMAGE,
        },
        PILLOW: {
          NAME: 'PILLOW',
          DATA: 'props/685-Cat-Pillow.png',
          TYPE: gameImportType.IMAGE,
        },
        FRONTDOOR: {
          NAME: 'FRONTDOOR',
          DATA: frontDoorAssetConfig,
          TYPE: gameImportType.SPRITESHEET,
        },
        TREATBOX: { NAME: 'TREATBOX', DATA: 'props/Treat-Box.png', TYPE: gameImportType.IMAGE },
        TREATBOXINNER: { NAME: 'TREATBOXINNER', DATA: 'props/Treat-Box-Inner.png', TYPE: gameImportType.IMAGE },
        TREATBOXMIDDLE: { NAME: 'TREATBOXMIDDLE', DATA: 'props/Treat-Box-Middle.png', TYPE: gameImportType.IMAGE },
        TREATBOXOUTER: { NAME: 'TREATBOXOUTER', DATA: 'props/Treat-Box-Outer.png', TYPE: gameImportType.IMAGE },
        TREAT_DOG: { NAME: 'TREAT_DOG', DATA: 'props/Treat.png', TYPE: gameImportType.IMAGE },
        TREAT_CAT: { NAME: 'TREAT_CAT', DATA: 'props/Cat-Treat.png', TYPE: gameImportType.IMAGE },
        TREATBALL: { NAME: 'TREATBALL', DATA: 'props/Treat-Ball.png', TYPE: gameImportType.IMAGE },
      },
      CONSUMABLE: {},
    },
  },
  BACKGROUND: {
    DOG: {
      PARK: {
        NAME: 'Background_Park',
        DATA: 'background/Fetch-Background.png',
        TYPE: gameImportType.IMAGE,
      },
      FOOD: {
        NAME: 'Background_Dog_Food',
        DATA: 'background/675-Kitchen-Dog-Food.png',
        TYPE: gameImportType.IMAGE,
      },
      ATTENTION: {
        NAME: 'Background_Attention',
        DATA: 'background/685-Living-Room-Background.png',
        TYPE: gameImportType.IMAGE,
      },
      ACTIVITYMENU: {
        NAME: 'Background_Dog_ActivityMenu',
        DATA: 'background/672-Pyx-Activities-Header.png',
        TYPE: gameImportType.IMAGE,
      },
      TREAT: {
        NAME: 'Background_Treat',
        DATA: 'background/Treat-Background.png',
        TYPE: gameImportType.IMAGE,
      },
      STROLL: {
        NAME: 'Background_Stroll_Dog',
        DATA: 'background/695-Park-Walk-Dog.png',
        TYPE: gameImportType.IMAGE,
      },
    },
    CAT: {
      FOOD: {
        NAME: 'Background_Cat_Food',
        DATA: 'background/675-Kitchen-Cat-Food.png',
        TYPE: gameImportType.IMAGE,
      },
      ATTENTION: {
        NAME: 'Background_Attention',
        DATA: 'background/685-Living-Room-Background.png',
        TYPE: gameImportType.IMAGE,
      },
      ACTIVITYMENU: {
        NAME: 'Background_Cat_ActivityMenu',
        DATA: 'background/672-Pyx-Activities-Header.png',
        TYPE: gameImportType.IMAGE,
      },
      PLAYROOM: {
        NAME: 'Background_Cat_Play',
        DATA: 'background/705-Cat-Play-Room.png',
        TYPE: gameImportType.IMAGE,
      },
      STROLL: {
        NAME: 'Background_Stroll_Cat',
        DATA: 'background/696-Cat-Garden-Full-Background-sky.png',
        TYPE: gameImportType.IMAGE,
      },
    },
    ALL: {
      LIVINGROOM: {
        NAME: 'Background_All_LivingRoom_MakeFriend',
        DATA: 'background/700-Living-Room-Background2.png',
        TYPE: gameImportType.IMAGE,
      },
      WATER: {
        NAME: 'Background_All_Water',
        DATA: 'background/680-Kitchen-Water.png',
        TYPE: gameImportType.IMAGE,
      },
    },
  },
  FOREGROUND: {
    CAT: {
      STROLL: {
        NAME: 'Foreground_Stroll_Cat',
        DATA: 'background/696-Cat-Garden-Full-Background-nosky.png',
        TYPE: gameImportType.IMAGE,
      },
    },
    ALL: {
      MAKEFRIEND_FRONTDOOR: {
        NAME: 'Foreground_MakeFriend_FrontDoor_All',
        DATA: 'background/700-Front-Door-Background@2x.png',
        TYPE: gameImportType.IMAGE,
      },
    },
  },
};
