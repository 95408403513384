import styled from 'styled-components';
import { Select as MuiSelect } from '@mui/material';

export const Select = styled(MuiSelect)`
  margin-bottom: 16px;
  .MuiSelect-select {
    color: #494e83;
  }
  svg {
    color: black;
  }
`;

export const StyledSelect = styled(Select)`
  .MuiSelect-select {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;
  }
`;
