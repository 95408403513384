import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ResultContext, ResultStates } from '../ResultContext';
import { Forms } from '../../forms';

const Resources = ({ DefaultComponent, ErrorComponent, ...rest }) => {
  const { screeningGuid, state, variant } = useContext(ResultContext);

  const ComponentStates = {
    [ResultStates.Default]: DefaultComponent,
    [ResultStates.Error]: ErrorComponent,
  };

  if (state !== ResultStates.Found) {
    const Component = ComponentStates[state];
    if (!Component) return <></>;
    return <Component />;
  }

  const FormComponent = Forms[screeningGuid][variant];

  return <FormComponent {...rest} />;
};

Resources.propTypes = {
  DefaultComponent: PropTypes.element,
  ErrorComponent: PropTypes.element,
};

export default Resources;
