import React, { useContext, useEffect } from 'react';
import { Content, HomeContainer, SectionHeader } from './styles';
import { appInsights } from 'Core_Helpers/AppInsights';
import PushNotificationPrompt from 'Core_Components/PushNotificationPrompt/PushNotificationPrompt';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { FormattedMessage, useIntl } from 'react-intl';
import useHardwareBackButton from 'Core_Hooks/useHardwareBackButton';
import { isNative } from 'Core_Helpers/isNative';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import { useCoreDispatch, useCoreSelector } from 'Contexts/StoreContext';
import { useHistory } from 'react-router-dom';
import { selectSyncUser, selectUserInfo, selectUserVerified } from 'Core_Redux/user';
import { SplashScreen } from '@capacitor/splash-screen';
import { selectShouldRedirectToAgreements } from 'Core_Redux/agreements';
import { selectSignupStatus, signupStatuses } from 'Core_Redux/signup';
import { Grid } from '@mui/material';
import GreetingHeader from './GreetingHeader/GreetingHeader';
import Reminder from './Reminder/Reminder';
import ActivityTabs from './ActivityTabs/ActivityTabs';
import FunFactGrid from './FunFactGrid/FunFactGrid';
import MyToolsTileGrid from './MyToolsTileGrid/MyToolsTileGrid';
import { retrievePermissionsToDetermineThrive } from 'Core_Redux/authentication';
import api from 'Core_Redux/services/api';
import { determineThriveWorkflowAndRedirect, selectThriveAccess } from 'Core_Redux/thrive';
import hasPermissions from 'Core_Helpers/authentication/hasPermissions';
import * as permissions from 'Core_Helpers/authentication/PermissionsConfig';
import { ExperienceContext } from 'Contexts/ExperienceContext';
import { CheckinModuleStore } from 'Core_Pages/Checkin/CheckinModuleStore';
import TodoItems from 'Core_Pages/InterventionEngine/TodoItems';
import Modals from './Modals/Modals';
import { MyVideoToolsModuleStore } from 'Core_Pages/Video/MyVideoToolsModuleStore';

const Home = () => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useCoreDispatch();
  const shouldRedirectToAgreements = useCoreSelector(selectShouldRedirectToAgreements);
  const { status } = useCoreSelector(selectSignupStatus);
  const syncUser = useCoreSelector(selectSyncUser);
  const user = useCoreSelector(selectUserInfo);
  const userVerified = useCoreSelector(selectUserVerified);
  const thriveAccess = useCoreSelector(selectThriveAccess);
  const experience = useContext(ExperienceContext);
  const checkinModuleStore = CheckinModuleStore.Instance().Use();
  const videoStore = MyVideoToolsModuleStore.Instance().Use();

  useEffect(() => {
    if (isNative()) {
      SplashScreen.hide();
    }
    appInsights.trackEvent({ name: 'Home Page' });
    checkinModuleStore.hydrateDueScreenings();
    checkinModuleStore.hydrateScreeningScores();
    videoStore.getVideoShowcase();
  }, []);

  useEffect(() => {
    if ((userVerified === false || !syncUser?.activeProfileId) && status !== signupStatuses.COMPLETED) {
      experience.experienceUpdatedAsync();
    }
  }, [user]);

  useEffect(() => {
    if (shouldRedirectToAgreements) {
      history.push(paths.UNSIGNEDAGREEMENTS);
    }
  }, [shouldRedirectToAgreements]);

  useEffect(() => {
    dispatch(api.endpoints.getThriveAccess.initiate(user?.sub));
  }, []);

  useEffect(() => {
    if (thriveAccess && thriveAccess?.thriveStatus !== 'DROPOUT') {
      if (
        !hasPermissions(permissions.THRIVEPATHWAY, [], user) ||
        !hasPermissions(permissions.THRIVEASSESSMENT, [], user)
      ) {
        dispatch(retrievePermissionsToDetermineThrive({ userId: user?.sub, history }));
        return;
      }
      dispatch(determineThriveWorkflowAndRedirect({ userId: user?.sub, history }));
    }
  }, [thriveAccess]);

  if (isNative()) {
    useHardwareBackButton();
  }

  return (
    <HomeContainer>
      <ScreenReaderWrapper>
        <h1>
          <FormattedMessage id="title.home" />
        </h1>
      </ScreenReaderWrapper>
      <GreetingHeader syncUser={syncUser} />
      <Content container justifyContent="center">
        <TodoItems />
        <Grid item mobile={12} desktop={10} />
        <FunFactGrid />
        <Grid item mobile={12} desktop={10}>
          <SectionHeader>{intl.formatMessage({ id: 'home.headers.my-activities' })}</SectionHeader>
        </Grid>
        <Grid item mobile={12} desktop={10}>
          <ActivityTabs />
        </Grid>
        <Grid item mobile={12} desktop={10}>
          <SectionHeader>{intl.formatMessage({ id: 'home.headers.my-tools' })}</SectionHeader>
        </Grid>
        <Grid container justifyContent="left" mobile={12} desktop={10}>
          <MyToolsTileGrid />
        </Grid>
        <Grid item mobile={12} desktop={10}>
          <Reminder />
        </Grid>
      </Content>
      <PushNotificationPrompt />
      <Modals />
    </HomeContainer>
  );
};

export default Home;
