import React from 'react';
import PropTypes from 'prop-types';
import { StyledListSubheader, StyledList } from './styles';
import MenuSectionItem from './MenuSectionItem';

function MenuSection({ title, children }) {
  return (
    <StyledList
      subheader={
        <StyledListSubheader>
          <h2>{title}</h2>
        </StyledListSubheader>
      }
      component="nav"
    >
      {children}
    </StyledList>
  );
}
MenuSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.arrayOf(MenuSectionItem)]),
};

export default MenuSection;
