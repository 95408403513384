import styled from 'styled-components';

export const StyledRow = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 15px 20px;

  button {
    flex-grow: 1;
    margin-right: 15px;

    :nth-last-child(-n + 2) {
      width: 50%;
    }

    :last-child {
      margin-right: 0;
    }

    :focus {
      outline: none;
    }

    :only-child {
      margin: 0 20%;
      padding: 2% 0;
    }
  }
`;
