export const Config = {
  KEY: 'BUTTERFLY_FLY',
  ANIMATIONS: {
    IDLE: {
      frames: [0, 1, 2, 3],
      frameRate: 12,
      repeat: -1,
      yoyo: true,
    },
  },
  ATTACHPOINTS: {
    MOUTH: {},
    NECK: {},
  },
};
