import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'Core_Components/PageHeader/PageHeader';
import { useHistory } from 'react-router-dom';
import cat from 'Assets/img/adoption-cat-670.gif';
import dog from 'Assets/img/adoption-dog-670.gif';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import { useIntl } from 'react-intl';
import {
  StyledButtonWithMargin,
  StyledContainerWithBackgroundImage,
  StyledParagraphWithPaddingAndMargin,
  StyledPetImageOnBackground,
  SpinnerContainer,
} from 'Core_Pages/Pets/featureStyles';
import { PetsConfig } from 'Core_Pages/Pets/PetsConfig';
import useCreatePetInteraction from 'Core_Hooks/useCreatePetInteraction';
import { InteractionType } from 'Core_Games/constants/interactionType';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectUserInfo } from 'Core_Redux/user';
import Spinner from 'Core_Components/Spinner/Spinner';

function PetsVisitDaily({ pet }) {
  const history = useHistory();
  const intl = useIntl();
  const [hasAcknowledgedWelcome, setHasAcknowledgedWelcome] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { petInteractionIsPending, createPetInteraction } = useCreatePetInteraction();
  const profile = useCoreSelector(selectUserInfo);

  useEffect(() => {
    if (hasAcknowledgedWelcome) {
      if (!petInteractionIsPending) {
        history.push(paths.ACTIVITYMENU);
        history.go(0);
      } else {
        setIsLoading(true);
      }
    }
  }, [petInteractionIsPending]);

  const logPetInteraction = () => {
    setHasAcknowledgedWelcome(true);
    createPetInteraction(profile.sub, {
      InteractionType: InteractionType.SHOWNWELCOME.CODE,
    });
  };

  return isLoading ? (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  ) : (
    <StyledContainerWithBackgroundImage pet={pet}>
      <PageHeader>{intl.formatMessage({ id: 'pets.adoption-center.visit-daily.header' })}</PageHeader>
      <StyledParagraphWithPaddingAndMargin
        data-testid={'VisitDailyParagraph'}
        dangerouslySetInnerHTML={{
          __html:
            pet.petType === PetsConfig.types.dog
              ? `${intl.formatMessage({ id: 'pets.adoption-center.visit-daily.paragraph.dog' })} ${
                  pet?.name
                } ${intl.formatMessage({ id: 'pets.adoption-center.visit-daily.paragraph.dog.end' })}`
              : `${intl.formatMessage({ id: 'pets.adoption-center.visit-daily.paragraph.cat' })} ${
                  pet?.name
                } ${intl.formatMessage({ id: 'pets.adoption-center.visit-daily.paragraph.cat.end' })}`,
        }}
      />
      <StyledPetImageOnBackground
        alt={
          pet.petType === PetsConfig.types.dog
            ? intl.formatMessage({ id: 'pets.adoption-center.hello.dog' })
            : intl.formatMessage({ id: 'pets.adoption-center.hello.cat' })
        }
        src={pet.petType === PetsConfig.types.dog ? dog : cat}
      />
      <StyledButtonWithMargin onClick={() => logPetInteraction()}>
        {intl.formatMessage({ id: 'common.got-it' })}
      </StyledButtonWithMargin>
    </StyledContainerWithBackgroundImage>
  );
}

PetsVisitDaily.propTypes = {
  pet: PropTypes.object,
};

export default PetsVisitDaily;
