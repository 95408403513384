import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import Question from 'Stories/components/Form/Question';
import { useForm } from 'react-hook-form';
import { questions } from './config';
import { AnswerTypes } from 'Stories/components/Form/answerTypes';
import { ScreeningModuleStore } from 'Core_Pages/Screening/ScreeningModuleStore';
import { StyledCircularProgress } from 'Core_Components/LoadingWrapper/styles';
import ErrorModal from 'Core_Components/ErrorModal/ErrorModal';
import { NeedsWrapper, StyledNeedsForm, NeedsProgressBar, StyledBookmark } from './styles';
import { displayDarkStatusBar } from 'Core_Helpers/statusBar';

const cardColors = [
  '#565c89',
  '#67973E',
  '#008A8A',
  '#24215D',
  '#8E1460',
  '#C7770E',
  '#007874',
  '#393E83',
  '#67973E',
  '#555B8A',
];

function AdultNeeds() {
  const intl = useIntl();
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const screeningTakerStore = screeningStore.ScreeningForm.Use();
  const { control } = useForm();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const isLoading = screeningTakerStore.IsLoading || screeningStore.IsLoading;
  const hasError = screeningStore.ScreeningHasError;
  const numberOfScreeningsLeft = screeningStore.DueScreenings?.length > 0 ? screeningStore.DueScreenings?.length : 1;
  const currentScreeningIndex = screeningStore.DueScreeningsIndex + 1;

  const onCloseErrorModal = () => screeningStore.acknowledgeError();
  const onAnswer = (question, answer) => {
    const nextQuestion = currentQuestion + 1;
    screeningTakerStore.updateAnswer(question, answer);

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(nextQuestion);
    }

    if (screeningTakerStore.IsValid) {
      screeningTakerStore.complete();
    }
  };

  useEffect(() => {
    displayDarkStatusBar();
  }, []);

  if (isLoading) {
    return <StyledCircularProgress />;
  }

  return (
    <>
      <NeedsWrapper fullScreen open={true} aria-labelledby="screening-title">
        <NeedsProgressBar variant="determinate" value={currentQuestion * 10} />
        <StyledBookmark>
          {`${currentScreeningIndex} ${intl.formatMessage({ id: 'common.of' })} ${numberOfScreeningsLeft}`}
        </StyledBookmark>
        <Typography id="screening-title" component="h1">
          {intl.formatMessage({ id: 'screenings.adult-needs' })}
        </Typography>
        <StyledNeedsForm cardColor={cardColors[currentQuestion]}>
          {questions[currentQuestion] && (
            <Question
              control={control}
              text={intl.formatMessage({ id: `${questions[currentQuestion]?.textId}` })}
              answerType={AnswerTypes.CARD}
              options={questions[currentQuestion]?.options}
              onChange={(e) => onAnswer(questions[currentQuestion]?.name, e?.currentTarget?.name)}
              {...questions[currentQuestion]}
            />
          )}
        </StyledNeedsForm>
      </NeedsWrapper>
      <ErrorModal open={hasError} onClose={onCloseErrorModal} />
    </>
  );
}

export default AdultNeeds;
