import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { legacyBackgroundColors } from 'Styles/colors';

const TooltipWrapper = ({ className, children, title }) => {
  return (
    <Tooltip PopperProps={{ disablePortal: true }} classes={{ tooltip: className }} title={title} enterTouchDelay={0}>
      {children}
    </Tooltip>
  );
};

TooltipWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
};

export default styled(TooltipWrapper)`
  background: ${legacyBackgroundColors.indigo};
  font-size: 14px;
  color: white;
`;
