import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import GoogleFontIcon from 'Core_Components/GoogleFontIcon/GoogleFontIcon';
import { StyledActionIconContainer } from './styles';

const ActionButton = forwardRef(({ onClick, ...props }, ref) => {
  const intl = useIntl();

  return (
    <StyledActionIconContainer
      onClick={onClick}
      ref={ref}
      role="button"
      aria-label={intl.formatMessage({ id: 'common.ellipsis' })}
      {...props}
    >
      <GoogleFontIcon name={GoogleFontIcon.Name.MoreHoriz} />
    </StyledActionIconContainer>
  );
});

ActionButton.displayName = 'ActionButton';
ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ActionButton;
