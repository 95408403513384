import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

export const ScreeningDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: white;
  }

  .MuiDialog-paperScrollPaper {
    background-color: #ededed;
    display: flex;
    flex-direction: column;
    height: 99%;
    overflow-y: auto;
    margin-top: auto;
    border-radius: 3px 3px 0px 0px;
    border: 1px solid #b7b7b7;
    border-bottom: none;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
