import { buildingSupportSystem } from 'Assets/img/my-tools-icons';

export const buildingSupportSystemGuardianEn = {
  key: 'building-support-system-Guardian',
  title: 'Building a Support System',
  titleImage: buildingSupportSystem,
  titleImageAlt: '',
  contentId: 42,
  sections: [
    {
      key: 'building-support-system-intro',
      description:
        'Feeling alone, lonely, or isolated are all normal and natural feelings as a foster parent or caregiver. Here are some tips to think about when building your support system.',
    },
    {
      key: 'find-other-caregivers',
      header: 'Find Other Foster Parents or Caregivers',
      description:
        'Finding others with similar situations can be helpful to talk through and support each other through shared challenges.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Talking about your joys and challenges as a foster parent or caregiver in a shared space with others, who are also doing the same, can offer validation and support',
        },
        {
          text: 'It can be valuable to find an experienced foster parent who you can talk with about their experiences and what is and is not normal',
        },
        {
          text: 'Once you are a veteran foster parent, offering your advice to others who are just getting started on this journey could feel restorative for you and beneficial for them',
        },
        {
          text: 'There are support groups for foster parents led by mental health professionals – reach out to your foster care agency to see if they have something to offer',
        },
      ],
    },
    {
      key: 'find-empathetic-listeners',
      header: 'Find Empathetic Listeners',
      description:
        'Finding others who do not have the challenges you have, but are still sensitive to those challenges, is helpful.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'They can provide support to you with the energy, patience, and empathy that others who are in similar fostering home may not have the ability to give',
        },
        {
          text: 'If you are having a hard time finding an empathetic listener, connecting with a mental health therapist who can provide you with a safe space to express your emotions can feel supportive. Your doctor or health insurance provider is the best starting point to find a great mental health therapist, and we also have resources in our app.',
        },
      ],
    },
    {
      key: 'appreciation-support',
      header: 'Express Appreciation for Your Support',
      description: 'Once you find your support system, tell them how important they are to you.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'If you feel like someone is a support to you, let them know that – often',
        },
        {
          text: 'Tell them the importance they have in your life and the ways that they help you',
        },
        {
          text: 'This helps to let them know that not only have they been important to you in the past, but they are also an important part of your support system moving forward',
        },
      ],
    },
    {
      key: 'toss-ego-take-help',
      header: 'Toss Your Ego and Take the Help',
      description:
        'Whether they say it or not, many people who are not foster parents or part of the foster system, have great empathy and respect for you as a caregiver.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'If someone offers to help, take it!',
        },
        {
          text: 'Let them make you dinner',
        },
        {
          text: 'Let them come help clean your house or mow your lawn',
        },
        {
          text: 'Let them offer to buy clothes or items for the child that you are fostering',
        },
        {
          text: 'Let them know what you need or what is most helpful to you',
        },
      ],
    },
  ],
};
