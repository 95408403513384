import { Config as flyConfig } from './objectData/FlyConfig';
import { bodyPart } from '../../../../constants/bodyPart';
import { gameImportType } from '../../../../constants/gameImportType';
import { actorType } from '../../../../constants/actorType';

export const butterflyAssetConfig = {
  NAME: 'BUTTERFLY',
  ACTORTYPE: actorType.OBJECT,
  DATA: [flyConfig],
  TYPE: gameImportType.SPRITESHEET,
  SPRITESHEET_FOLDER: 'butterfly',
  SPRITESHEET_NAME: 'Butterfly-Sprite-Map-',
  SCALE: 0.15,
  INVENTORY: {
    [bodyPart.MOUTH]: [],
    [bodyPart.NECK]: [],
  },
  AUDIO: {},
  HEIGHT: 400,
  WIDTH: 400,
};
