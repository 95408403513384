import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { copyTokensToLocalStorage } from 'Core_Redux/authentication';
import { appInsights } from 'Core_Helpers/AppInsights';
import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from 'Core_Helpers/authentication/AuthService';
import { areTokensValid } from 'Core_Helpers/authentication/authTokens';
import { customCreateApi } from './customApi';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.AUTHORITY,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = getState().authentication.accessToken;
    if (token) headers.set('Authorization', `Bearer ${token}`);
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  const { authentication } = api.getState();

  if (
    authentication?.accessToken &&
    authentication?.refreshToken &&
    !args?.url?.includes('connect/token') &&
    !areTokensValid(authentication.accessToken, authentication.refreshToken)
  ) {
    const authService = await AuthService.getInstance();
    await authService.tryRefreshTokens();
  }

  return await baseQuery(args, api, extraOptions);
};

export const accountCreated = createAsyncThunk('authentication/accountCreated', async (_, api) => {
  const { signup } = api.getState();
  await api.dispatch(
    identityApi.endpoints.getSignUpTokens.initiate({ username: signup.username, password: signup.password }),
  );
  await api.dispatch(identityApi.endpoints.userInfo.initiate());
});

const tags = {
  USER_INFO: 'userInfo',
};

const identityApi = customCreateApi({
  reducerPath: 'identityApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ username, password }) => {
        const params = new URLSearchParams({
          grant_type: 'password',
          client_id: 'pyx.pwa',
          username,
          password,
        });

        return {
          url: 'connect/token',
          method: 'POST',
          body: params,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        };
      },
      transformResponse: (response) => ({ accessToken: response.access_token, refreshToken: response.refresh_token }),
      invalidatesTags: () => [{ type: tags.USER_INFO, id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { accessToken, refreshToken } = data;

          // keep tokens available in local storage until all uses are removed
          await dispatch(copyTokensToLocalStorage({ accessToken, refreshToken }));
        } catch ({ error }) {
          appInsights.trackException({ exception: error, properties: { errorMessage: error?.message } });
        }
      },
    }),
    getSignUpTokens: builder.mutation({
      query: ({ username, password }) => {
        const params = new URLSearchParams({
          grant_type: 'password',
          client_id: 'pyx.pwa',
          username,
          password,
        });

        return {
          url: 'connect/token',
          method: 'POST',
          body: params,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        };
      },
      transformResponse: (response) => ({ accessToken: response.access_token, refreshToken: response.refresh_token }),
      invalidatesTags: () => [{ type: tags.USER_INFO, id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { accessToken, refreshToken } = data;

          // keep tokens available in local storage until all uses are removed
          await dispatch(copyTokensToLocalStorage({ accessToken, refreshToken }));
        } catch ({ error }) {
          appInsights.trackException({ exception: error, properties: { errorMessage: error?.message } });
        }
      },
    }),
    refreshToken: builder.mutation({
      query: ({ refreshToken }) => {
        const params = new URLSearchParams({
          grant_type: 'refresh_token',
          client_id: 'pyx.pwa',
          refresh_token: refreshToken,
        });

        return {
          url: 'connect/token',
          method: 'POST',
          body: params,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        };
      },
      transformResponse: (response) => ({ accessToken: response.access_token, refreshToken: response.refresh_token }),
      invalidatesTags: () => [{ type: tags.USER_INFO, id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { accessToken, refreshToken } = data;

          await dispatch(copyTokensToLocalStorage({ accessToken, refreshToken }));
        } catch ({ error }) {
          appInsights.trackException({ exception: error, properties: { errorMessage: error?.message } });
        }
      },
    }),
    userInfo: builder.query({
      query: () => {
        return {
          url: 'connect/userinfo',
          method: 'POST',
        };
      },
      providesTags: () => [{ type: tags.USER_INFO, id: 'LIST' }],
    }),
    forgotUsername: builder.mutation({
      query: ({ emailOrPhoneNumber, birthday }) => {
        const params = new URLSearchParams({ emailOrPhoneNumber });
        if (birthday) params.append('birthday', birthday);
        return { url: `api/v1/forgot/username?${params.toString()}` };
      },
    }),
    forgotPassword: builder.mutation({
      query: ({ emailOrPhoneNumber, birthday }) => {
        const params = new URLSearchParams({ emailOrPhoneNumber });
        if (birthday) params.append('birthday', birthday);
        return { url: `api/v1/forgot/password?${params.toString()}` };
      },
    }),
    forgotPasswordVerifyOtp: builder.mutation({
      query: ({ emailOrPhoneNumber, code }) => {
        return {
          url: 'api/v1/forgot/password/otp-verify',
          body: {
            emailOrPhoneNumber,
            code,
          },
          method: 'POST',
        };
      },
    }),
    updatePassword: builder.mutation({
      query: ({ password, code, emailOrPhoneNumber, birthday }) => {
        return {
          url: 'api/v1/forgot/password',
          body: {
            password,
            code,
            emailOrPhoneNumber,
            birthday,
          },
          method: 'PUT',
        };
      },
    }),
    validate: builder.mutation({
      query: ({
        firstName,
        lastName,
        username,
        password,
        phoneNumber,
        landlinePhoneNumber,
        emailAddress,
        locale,
        timeZoneId,
      }) => {
        return {
          url: 'api/v1/register/validate',
          body: {
            firstName,
            lastName,
            username,
            password,
            phoneNumber,
            landlinePhoneNumber,
            emailAddress,
            locale,
            timeZoneId,
          },
          method: 'POST',
        };
      },
    }),
    createAccount: builder.mutation({
      query: ({
        firstName,
        lastName,
        username,
        password,
        emailAddress,
        phoneNumber,
        landlinePhoneNumber,
        locale,
        timeZoneId,
      }) => {
        return {
          url: 'api/v1/register',
          body: {
            firstName,
            lastName,
            username,
            password,
            emailAddress,
            phoneNumber,
            landlinePhoneNumber,
            locale,
            timeZoneId,
          },
          method: 'POST',
        };
      },
    }),
    getOTP: builder.mutation({
      query: ({ emailOrPhoneNumber }) => `api/v1/register/verify?emailOrPhoneNumber=${emailOrPhoneNumber}`,
    }),
    verifyOTP: builder.mutation({
      query: ({ emailOrPhoneNumber, code }) => {
        return {
          url: 'api/v1/register/verify',
          body: {
            emailOrPhoneNumber,
            code,
          },
          method: 'POST',
        };
      },
    }),
    updateAccount: builder.mutation({
      query: ({ phoneNumber, landlinePhoneNumber, emailAddress, userId }) => {
        return {
          url: `api/v1/register/${userId}`,
          body: {
            phoneNumber,
            landlinePhoneNumber,
            emailAddress,
          },
          method: 'PUT',
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useGetSignUpTokensMutation,
  useRefreshTokenMutation,
  useForgotUsernameMutation,
  useForgotPasswordMutation,
  useForgotPasswordVerifyOtpMutation,
  useUpdatePasswordMutation,
  useValidateMutation,
  useCreateAccountMutation,
  useGetOTPMutation,
  useVerifyOTPMutation,
  useUpdateAccountMutation,
} = identityApi;

export default identityApi;
