import { LanguageService } from 'Core_Helpers/LanguageService';
import { UserAccessor } from 'Core_Helpers/UserAccessor';
import { UserResourceAccessor } from 'Core_Helpers/UserResourceAccessor';
import hasPermissions from 'Core_Helpers/authentication/hasPermissions';
import { brainBoosterItems } from 'Core_Pages/MyTools/MyToolsPage/MyBrainBoosterTools/config';
import { caregivingItems } from 'Core_Pages/MyTools/MyToolsPage/MyCaregivingTools/config';
import { guardianItems } from 'Core_Pages/MyTools/MyToolsPage/MyGuardianTools/config';
import { mindsetItems } from 'Core_Pages/MyTools/MyToolsPage/MyMindsetTools/config';
import { physicalHealthItems } from 'Core_Pages/MyTools/MyToolsPage/MyPhysicalHealthTools/config';
import { relationshipItems } from 'Core_Pages/MyTools/MyToolsPage/MyRelationshipTools/config';
import { CHAT, STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';
import { matchPath } from 'react-router';
import { MyVideoToolsModuleStore } from 'Core_Pages/Video/MyVideoToolsModuleStore';
import { UserResourcesStore } from 'Core_Services/userResources/UserResourcesStore';
import { convertStringToUpperCamelCase } from 'Helpers/StringHelper';

const storageKey = 'todo_list';

// Temporary service to house non-api MVP version of intervention version.
// To be replaced with endpoints once the API portion has been built out.
export class TodoListDataService {
  private static _instance: TodoListDataService = new TodoListDataService();
  private lastUpdatedOn: Date;
  private userId: number;
  private currentList: Array<any>;
  private userAccessor: UserAccessor;
  private userResourceAccessor: UserResourceAccessor;
  private languageService: LanguageService;
  private myVideoToolsModuleStore = MyVideoToolsModuleStore.Instance();
  private userResourcesStore = UserResourcesStore.Instance();

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (TodoListDataService._instance) {
      return TodoListDataService._instance;
    }

    this.userAccessor = new UserAccessor();
    this.userResourceAccessor = new UserResourceAccessor();
    this.languageService = new LanguageService();
    this.getList();
  }

  private getList = () => {
    const list = JSON.parse(localStorage.getItem(storageKey));

    if (!list) return null;

    this.currentList = list.items;
    this.lastUpdatedOn = list.updatedOn;
    this.userId = list.userId;
  };

  private updateList = (list: Array<any>, date: Date) => {
    if (!list || list?.length < 1) return;
    this.lastUpdatedOn = date;
    this.currentList = list;
    this.userId = this.userAccessor.User.UserId;
    const listData = { items: list, updatedOn: this.lastUpdatedOn, userId: this.userId };
    localStorage.setItem(storageKey, JSON.stringify(listData));
  };

  private getRandomElements = (arr: Array<any>, num: number) => {
    const temp = [...arr];
    const elements = [];

    for (let i = 0; i < num; i++) {
      const nextRandomIndex = ~~(Math.random() * temp.length);
      elements.push(temp[nextRandomIndex]);
      temp.splice(nextRandomIndex, 1);
    }

    return elements;
  };

  // todo correct item key for videos (used for custom events)
  private getAvailableVideoOptions = () => {
    return this.myVideoToolsModuleStore.Videos.map((v) => {
      return {
        name: v.name,
        key: `${convertStringToUpperCamelCase(v.name)}_video`,
        link: v.uri,
        completed: false,
      };
    });
  };

  // Get available static content and transform it based on the user's permissions
  // Should be removed once we replace retrieval of suggestions with an API endpoint
  private getAvailableStaticContentOptions = () => {
    const profile = {
      permission: this.userResourceAccessor.Resources.Permissions,
      role: this.userResourceAccessor.Resources.Roles,
    };

    // Get available static content
    const staticContent = [...relationshipItems, ...mindsetItems, ...brainBoosterItems, ...physicalHealthItems];

    if (this.userAccessor.User.Meta?.caregiver?.isGuardian) {
      staticContent.concat(guardianItems);
    }

    if (this.userAccessor.User.Meta?.caregiver?.isAdultCaregiver) {
      staticContent.concat(caregivingItems);
    }

    return staticContent
      .filter(
        (x) =>
          hasPermissions(x.permissions, x.roles, profile) &&
          (!x.contentCategory || (x.contentCategory === 'youth' && this.userAccessor.User.Meta?.age?.isYouth)),
      )
      .map((y) => {
        const match = matchPath(y.to, STATICCONTENT);
        const contentType = match?.params?.contentType;
        return {
          name: this.languageService.Intl.formatMessage({ id: y.labelId }),
          key: contentType,
          link: y.to,
          completed: false,
        };
      });
  };

  private getAvailableOptions = () => {
    const staticContentOptions = this.getAvailableStaticContentOptions();

    if (
      this.userResourcesStore.HasMyVideoToolsResource &&
      this.userAccessor.User.Language === 'en' &&
      this.myVideoToolsModuleStore.VideoCount > 0
    ) {
      return [].concat(staticContentOptions, this.getAvailableVideoOptions());
    }

    return staticContentOptions;
  };

  public completeTodoListItem = async (request: { name: string }) => {
    const updatedList = [...this.currentList];
    const itemIndex = updatedList.findIndex((x) => x.name === request?.name);
    updatedList[itemIndex].completed = true;
    this.updateList(updatedList, this.lastUpdatedOn);

    return { response: this.currentList };
  };

  public isReadyToGenerateNewList = async () => {
    const hasUserMeta = !!this.userAccessor.User.Meta;
    const hasUserId = !!this.userAccessor.User.UserId;
    const hasInterventionEngineResource = this.userResourcesStore.HasInterventionEngineResource;
    const hasVideoResource =
      this.userResourcesStore.HasInterventionEngineResource && this.userResourcesStore.HasMyVideoToolsResource;
    const videosReady = !this.myVideoToolsModuleStore.IsLoading;

    if (!hasUserMeta || !hasUserId || !hasInterventionEngineResource || (hasVideoResource && !videosReady))
      return false;

    return true;
  };

  public checkForTodaysTodoList = async () => {
    if (
      !!this.lastUpdatedOn &&
      new Date().toDateString() == new Date(this.lastUpdatedOn).toDateString() &&
      this.userId === this.userAccessor.User.UserId
    ) {
      return { response: this.currentList };
    }
  };

  public generateNewToDoList = async () => {
    const response = this.getRandomElements(this.getAvailableOptions(), 3).concat([
      {
        name: this.languageService.Intl.formatMessage({ id: 'intervention-engine.todo-item.chat' }),
        key: 'PyxirChat',
        link: CHAT,
        completed: false,
      },
    ]);

    this.updateList(response, new Date());

    return { response };
  };
}
