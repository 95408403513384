import styled from '@emotion/styled';
import { black } from 'Styles/colors';

export const FunFactContainer = styled.div`
  color: ${black._87};
  justify-content: center;
  text-align: center;
  padding: 10px;
  width: 100%;
`;

export const FunFactHeader = styled.h2`
  margin-bottom: 2px;
  font-size: 1.125rem;
  font-weight: 700;
`;

export const FunFactContent = styled.p`
  line-height: 1.25rem;
  margin-top: 0;
`;
