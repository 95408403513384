import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ChatImageContainer, StyledChatImage, Placeholder, IconContainer } from './styles';

const ChatImage = ({ text, position, style, alt = '', index = 1 }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isAnimated, setIsAnimated] = useState(true);
  const [isGif, setIsGif] = useState(false);
  const [gifText, setGifText] = useState(text);

  useEffect(() => {
    if (text.includes('media.giphy.com')) {
      setGifText(normalizeGifUrl('giphy.gif'));
      setIsGif(true);
      //GIFs may appear before they reach a 'loaded' state, this leads to a better user experience
      setHasLoaded(true);
    }
  }, []);

  const updateGif = () => {
    setIsAnimated(!isAnimated);
    if (isAnimated) {
      setGifText(normalizeGifUrl('giphy_s.gif'));
    } else {
      setGifText(normalizeGifUrl('giphy.gif'));
    }
  };

  const normalizeGifUrl = (urlEnd) => {
    const normalizedGifUrlArr = text.split('/');
    normalizedGifUrlArr[normalizedGifUrlArr.length - 1] = urlEnd;
    return normalizedGifUrlArr.join('/');
  };

  return (
    <ChatImageContainer position={position}>
      <StyledChatImage
        data-testid="ChatImage"
        alt={alt}
        aria-hidden={alt !== '' ? 'false' : 'true'}
        style={style}
        src={isGif ? gifText : text}
        onLoad={() => setHasLoaded(true)}
      />
      {hasLoaded ? null : <Placeholder data-testid="Placeholder" />}
      {isGif ? (
        <IconContainer
          data-testid="IconContainer"
          aria-label={isAnimated ? 'Disable GIF' : 'Enable GIF'}
          aria-pressed={!isAnimated}
          tabIndex={Math.floor(1000 / index)}
          onClick={() => updateGif()}
        >
          <i
            aria-hidden="true"
            data-testid="Icon"
            style={{ float: 'right', margin: '5px' }}
            className={isAnimated ? `fas fa-pause-circle` : `fas fa-play-circle`}
          />
        </IconContainer>
      ) : (
        ''
      )}
    </ChatImageContainer>
  );
};

ChatImage.propTypes = {
  text: PropTypes.string,
  position: PropTypes.string,
  style: PropTypes.object,
  alt: PropTypes.string,
  index: PropTypes.number,
};

export default ChatImage;
