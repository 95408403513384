import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { StyledAddReminderButton, StyledNoRemindersCard, StyledText } from './styles';

function NoRemindersCard({ handleButtonClick }) {
  const intl = useIntl();

  return (
    <StyledNoRemindersCard>
      <StyledText>{intl.formatMessage({ id: 'reminders.no-reminders' })}</StyledText>
      <StyledAddReminderButton name="" variant="contained" color="primary" onClick={handleButtonClick}>
        {intl.formatMessage({ id: 'reminders.add-reminder' })}
      </StyledAddReminderButton>
    </StyledNoRemindersCard>
  );
}

NoRemindersCard.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
};

export default NoRemindersCard;
