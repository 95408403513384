import { pregnancy } from 'Assets/img/my-tools-icons';

export const pregnancyEn = {
  key: 'pregnancy',
  title: 'Staying connected during pregnancy - Tips to help feel better',
  titleImage: pregnancy,
  titleImageAlt: 'pregnant person silouhette',
  description:
    'Feeling lonely during pregnancy is common. Even for women who have given birth before. Loneliness can happen at any stage of pregnancy. It can affect both the mom’s well-being and the growing baby, so it is important to address.\n\nHere are some tips to find support and reduce loneliness and isolation during pregnancy.',
  contentId: 14,
  sections: [
    {
      key: 'pregnancy-talk-about-it',
      header: 'Talk about it',
      description:
        'Talk with your partner, friends, family, other pregnant women, or healthcare provider and share your feelings openly. Even if it feels clumsy, allow yourself to express your feelings. It is very helpful to manage stress levels and reduce feelings of isolation.',
    },
    {
      key: 'pregnancy-support-group',
      header: 'Join a pregnancy support group',
      description:
        'Many groups for expecting mothers can be found in local communities or online. Finding a “tribe” of other pregnant women through informal meet ups to group exercise classes can be a great way to connect and share experiences.\n\nNOTE: Your Pyx Health ANDY is available to talk to. They can provide caring understanding and help find resources your health care plan has available. Use the “Call ANDY” button on your Pyx Health app or call them at 1-855-499-4777.',
    },
    {
      key: 'pregnancy-bump-time',
      header: 'Baby bump time',
      description:
        'Pause for a while with your belly bump. From as early as 15 weeks the baby can hear voices. Try singing to your bump, reading out loud to it, or having a warm bath “together.” Belly painting your baby bump with body-friendly paints is a special way to connect with your baby, either with other pregnant women or alone at home. Babies are known to react with their mom in these playful ways. It’s a fun way to connect and help boost positive feelings.',
    },
    {
      key: 'pregnancy-online-help',
      header: 'Online help',
      description:
        'To help ease your mind, search online for videos or podcasts of pregnancy topics. Information about what happens during each week of pregnancy is available from medical professionals, midwives, experienced moms, and natural health practitioners.',
    },
    {
      key: 'pregnancy-take-break',
      header: 'Take a break',
      description:
        'Do something to help take your mind off the pregnancy for a while. It can be active or not, from pampering time at home or mindful breathing, to watching a feel-good movie or catching up with friends over lunch. Taking a mental break can help interrupt unpleasant feelings of loneliness.',
    },
    {
      key: 'pregnancy-bottom-line',
      header: 'Bottom line',
      description:
        'Be kind with yourself. It’s normal to feel lonely or an occasional emotional wobble. Here are a few positive affirmations to help maintain balance:',
      itemsHeader: 'Pregnancy affirmations',
      items: [
        {
          text: 'My body was made to do this.',
        },
        {
          text: 'I am resilient, strong, and brave.',
        },
        {
          text: 'I am capable of amazing things.',
        },
        {
          text: 'I breathe deeply and I am calm.',
        },
        {
          text: "I'm amazing, I'm growing a small human!",
        },
        {
          text: "I'm a force to be reckoned with.",
        },
        {
          text: 'I am on a journey, ever growing and developing.',
        },
        {
          text: "I'm fine with who I am, and I love who I'm becoming",
        },
        {
          text: 'My body tells me what it needs. My job is to listen.',
        },
        {
          text: 'I love my tiger stripes.',
        },
      ],
    },
  ],
};
