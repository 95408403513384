import { animationType } from '../../../constants/animationType';
import { sceneType } from '../../../constants/sceneType';
import { uiTypes } from '../../../constants/uiTypes';
import { Storyboard } from '../Storyboard';
import { friendTimeConfig, keySceneDataTypes } from './friendTimeConfig';
import { findAsset, findUI } from '../../../Scripts/configHelper';
import { addCharacterActor } from '../../../Scripts/characterHelper';
import { onClickActivityMenu, onClickGoToRoute } from '../../../Scripts/buttonHelper';
import { STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';
import { isAtPoint } from 'Core_Games/Scripts/actorPointHelper';
import { bodyPart } from '../../../constants/bodyPart';
import { LogInteraction } from '../../../Scripts/interactionHelper';

export class FriendTime extends Storyboard {
  constructor() {
    super(sceneType.FRIENDTIME.NAME);

    // scales
    this.npcFriendScale = 0.5;
    this.objectDoorScale = 1.025;
    this.objectPillowScale = 0.25;
    this.objectNutrientBowlScale = 0.35;
    this.objectNutrientContainerScale = 0.4;

    // time taken to fade for going to next page (fade effect)
    this.transitionTime = 1000;

    // indexes
    this.currentPageIndex = 0;
    this.backgroundIndex = 0;
    this.currentObjectIndex = -1;
    this.currentObjectSequentialIndex = -1;
    this.waitForAnimationIndexNPC = -1;
    this.waitForAnimationIndexMain = -1;
    this.currentAnimationIndexNPC = 0;
    this.currentAnimationIndexMain = 0;

    //
    this.background = undefined;

    // timers
    this.timerNextPage = undefined;
    this.timerNextPageActive = false;

    this.finishedLoading = false;
    this.npcs = {};
  }

  init(sceneData) {
    this.sceneData = sceneData;
    this.friendTimeConfig = friendTimeConfig;

    // set up button data
    let comeInButton = findUI(
      this,
      this.friendTimeConfig,
      uiTypes.BUTTONROW,
      keySceneDataTypes.BUTTON_FRIENDTIME_INTRO,
    );
    let noButton = findUI(this, this.friendTimeConfig, uiTypes.BUTTONROW, keySceneDataTypes.BUTTON_FRIENDTIME_OUTRO_NO);
    let yesButton = findUI(
      this,
      this.friendTimeConfig,
      uiTypes.BUTTONROW,
      keySceneDataTypes.BUTTON_FRIENDTIME_OUTRO_YES,
    );

    super.init(sceneType.FRIENDTIME, this.friendTimeConfig, sceneData);

    // add on click functionality for buttons
    comeInButton ? (comeInButton.ONCLICK = () => this.onClickReady(this)) : comeInButton;
    noButton ? (noButton.ONCLICK = () => onClickActivityMenu(sceneData.appData.history)) : noButton;
    yesButton
      ? (yesButton.ONCLICK = () =>
          onClickGoToRoute(sceneData.appData.history, STATICCONTENT, { contentType: 'socialsupport' }))
      : yesButton;

    this.gutter = this.currentSceneConfig.GUTTER ?? 0;
    this.cameraOffsetX = this.currentSceneConfig.CAMERAOFFSETX ?? 0.5;
    this.horizonLine = this.currentSceneConfig.HORIZON_LINE ?? 0.4;
    this.groundLine = this.currentSceneConfig.GROUND_LINE ?? 0.8;
  }

  preload() {
    super.preload();
  }

  create() {
    let _this = this;

    super.create();

    // set initial parameters
    this.engine.engineAssets.load.on(Phaser.Loader.Events.COMPLETE, function () {
      // create timers
      _this.timerNextPage = new Phaser.Time.TimerEvent({ delay: 4000 });
      _this.time.addEvent(_this.timerNextPage);

      _this.startLocationStoryX = _this.pet.x;
      _this.startLocationStoryY = _this.pet.y;

      _this.pet.body.collideWorldBounds = false;

      _this.addObjects(_this);

      _this.pet.enablePhysics(_this);
      _this.npcFriend.enablePhysics(_this);

      // unequip dog collar
      if (_this.pet.Inventory[bodyPart.NECK]?.length) {
        _this.pet.Inventory[bodyPart.NECK][0].OBJECT.alpha = 0;
        _this.pet.unequip(bodyPart.NECK);
      }

      _this.goToNextPage(_this.currentPageIndex);

      _this.finishedLoading = true;

      // physics overlap logic
      _this.addEventLogic(_this, _this.pet, _this.nutrientContainer, _this.bowl);
    });
  }

  update() {
    super.update();

    if (this.finishedLoading) {
      // check if actor is moving
      if (
        this.pet.movementInTransition &&
        isAtPoint(
          this.pet,
          this.pet.movementInTransitionCoordinates.x,
          this.pet.movementInTransitionCoordinates.y,
          this.pet.movementInTransitionCoordinatesLast.x,
          this.pet.movementInTransitionCoordinatesLast.y,
        )
      ) {
        this.playNextAnimationMain = true;
        this.pet.body.stop();
        this.pet.movementInTransition = false;
      }
      if (
        this.npcFriend.movementInTransition &&
        isAtPoint(
          this.npcFriend,
          this.npcFriend.movementInTransitionCoordinates.x,
          this.npcFriend.movementInTransitionCoordinates.y,
          this.npcFriend.movementInTransitionCoordinatesLast.x,
          this.npcFriend.movementInTransitionCoordinatesLast.y,
        )
      ) {
        this.playNextAnimationNPC = true;
        this.npcFriend.body.stop();
        this.npcFriend.movementInTransition = false;
      }
      //
      if (!this.pageTransitionInProgress && this.startPageOnTransitionEnd) {
        this.playPage();
      } else if (
        !this.endPageTransitionInProgress &&
        !this.startPageOnTransitionEnd &&
        this.nextPageOnTransitionEnd &&
        this.characterPageDoneMain &&
        this.characterPageDoneNPC
      ) {
        this.goToNextPage(++this.currentPageIndex);
      }
    }
  }

  // Add Collision & event logic
  addEventLogic = (game, actor) => {
    game.cameras.main.on(
      Phaser.Cameras.Scene2D.Events.FADE_IN_COMPLETE,
      function () {
        if (game.pageTransitionInProgress) {
          game.pageTransitionInProgress = false;
          game.playNextAnimationNPC = true;
          game.playNextAnimationMain = true;
        }
      },
      game,
    );
    game.cameras.main.on(
      Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE,
      function () {
        if (game.endPageTransitionInProgress) {
          game.endPageTransitionInProgress = false;
        }
      },
      game,
    );

    game.npcFriend.on(Phaser.Animations.Events.ANIMATION_COMPLETE, function () {
      if (
        game.currentNPCAnimations &&
        game.currentAnimationIndexNPC > -1 &&
        game.npcFriend.animationIsActive(game.currentNPCAnimations[game.currentAnimationIndexNPC])
      ) {
        game.playNextAnimationNPC = true;
      }
    });
    game.npcFriend.on(Phaser.Animations.Events.ANIMATION_REPEAT, function () {
      if (
        game.currentNPCAnimations &&
        game.currentAnimationIndexNPC > -1 &&
        game.npcFriend.animationIsActive(game.currentNPCAnimations[game.currentAnimationIndexNPC]) &&
        game.npcFriend.currentSceneAnimationData &&
        game.npcFriend.currentSceneAnimationData.REPEAT
      ) {
        if (game.npcFriend.currentAnimationRepeatCount <= game.npcFriend.currentSceneAnimationData.REPEAT) {
          game.npcFriend.currentAnimationRepeatCount++;
        } else {
          game.playNextAnimationNPC = true;
        }
      }
    });

    for (let i = 0; i < Object.keys(this.npcs).length; i++) {
      const npcKey = Object.keys(this.npcs)[i];
      let currentNPC = this.npcs[npcKey];
      if (npcKey != keySceneDataTypes.NPC_FRIEND) {
        currentNPC.on(Phaser.Animations.Events.ANIMATION_COMPLETE, function () {
          if (currentNPC.animationIsActive(game.currentObjectAnimation)) {
            const objectIndex = game.currentObjectIndex;
            const nextObjectIndex = game.currentObjectIndex + 1;
            const nextObjectAnimationIndex = game.currentObjectSequentialIndex + 1;
            if (game.objectAnimationPlayFirstInProgress) {
              game.objectAnimationPlayFirstInProgress = false;
            } else if (game.objectAnimationPlayAsInProgress) {
              // TODO - check the entire list of objects rather than just the immediately next one
              if (game.objectHasPlayAsSequentialAnimation(game, objectIndex, nextObjectAnimationIndex)) {
                game.currentObjectSequentialIndex = nextObjectAnimationIndex;
                const objectData = game.currentPage.OBJECTS[game.currentObjectIndex];
                game.playAsObjectAnimation(game, objectData);
              } else if (game.objectHasPlayAsSequentialAnimation(game, nextObjectIndex, 0)) {
                game.currentPage.OBJECTS[game.currentObjectIndex].playAsInProgress = false;
                if (game.currentPage.OBJECTS[game.currentObjectIndex].ALPHAONEND !== undefined) {
                  game.npcs[game.currentPage.OBJECTS[game.currentObjectIndex].NAME].alpha =
                    game.currentPage.OBJECTS[game.currentObjectIndex].ALPHAONEND;
                }
                game.currentObjectIndex = nextObjectIndex;
                game.currentObjectSequentialIndex = 0;
                const objectData = game.currentPage.OBJECTS[game.currentObjectIndex];
                game.playAsObjectAnimation(game, objectData);
              } else {
                //  continue with normal scene
                game.objectAnimationPlayAsInProgress = false;
              }
            }
          }
        });
      }
    }

    actor.on(Phaser.Animations.Events.ANIMATION_COMPLETE, function () {
      if (
        game.currentMainAnimations &&
        game.currentAnimationIndexMain > -1 &&
        actor.animationIsActive(game.currentMainAnimations[game.currentAnimationIndexMain])
      ) {
        game.playNextAnimationMain = true;
      }
    });
    actor.on(Phaser.Animations.Events.ANIMATION_REPEAT, function () {
      if (
        game.currentMainAnimations &&
        game.currentAnimationIndexMain > -1 &&
        actor.animationIsActive(game.currentMainAnimations[game.currentAnimationIndexMain]) &&
        actor.currentSceneAnimationData &&
        actor.currentSceneAnimationData.REPEAT
      ) {
        if (actor.currentAnimationRepeatCount < actor.currentSceneAnimationData.REPEAT) {
          actor.currentAnimationRepeatCount++;
        } else {
          game.playNextAnimationMain = true;
        }
      }
    });
  };

  objectHasPlayAsSequentialAnimation = (game, objectIndex, animationIndex) => {
    return (
      game.currentPage.OBJECTS[objectIndex] !== undefined &&
      game.currentPage.OBJECTS[objectIndex].ANIMATION &&
      game.currentPage.OBJECTS[objectIndex].ANIMATION.ANIMATIONS_SEQUENTIAL &&
      game.currentPage.OBJECTS[objectIndex].ANIMATION.ANIMATIONS_SEQUENTIAL[animationIndex] &&
      game.currentPage.OBJECTS[objectIndex].ANIMATION.ANIMATIONS_SEQUENTIAL[animationIndex].PLAYAS
    );
  };

  // Scene-Specific Functions
  goToNextPage = () => {
    // set up page parameters
    this.currentPage = this.currentSceneConfig.PAGES[this.currentPageIndex];
    this.backgroundIndex = this.currentPage.BACKGROUND_INDEX;

    this.currentMainAnimations = this.currentPage.INTERACT.MAIN.ANIMATIONS_SEQUENTIAL.map((x) => x.NAME);
    this.currentNPCAnimations = this.currentPage.INTERACT.NPC.ANIMATIONS_SEQUENTIAL.map((x) => x.NAME);

    this.currentAnimationIndexNPC = -1;
    this.currentAnimationIndexMain = -1;

    this.waitForAnimationIndexMain = -1;
    this.waitForAnimationIndexNPC = -1;

    this.characterPageDoneMain = false;
    this.characterPageDoneNPC = false;

    // place objects
    if (this.currentPage.OBJECTS) {
      for (let i = 0; i < this.currentPage.OBJECTS.length; i++) {
        const objectData = this.currentPage.OBJECTS[i];
        if (!objectData.COORDINATES.RELATIVETOPET) {
          this.npcs[objectData.NAME].currentObjectCoordinates = this.setUpActor(
            this.npcs[objectData.NAME],
            this.currentPage.OBJECTS[i],
          );
        }
        if (objectData.ANIMATION) {
          if (objectData.ANIMATION.PLAYFIRST && this.currentObjectIndex < 0) {
            this.objectAnimationPlayFirstInProgress = true;
            this.currentObjectIndex = i;
            this.currentObjectAnimation = objectData.ANIMATION.NAME;
            this.npcs[objectData.NAME].play(this.currentObjectAnimation);
          } else if (
            this.currentObjectSequentialIndex < 0 &&
            this.currentObjectIndex < 0 &&
            objectData.ANIMATION.ANIMATIONS_SEQUENTIAL &&
            objectData.ANIMATION.ANIMATIONS_SEQUENTIAL[0].PLAYAS
          ) {
            this.currentObjectIndex = i;
            this.currentObjectSequentialIndex = 0;
            this.playAsObjectAnimation(this, objectData);
          } else {
            this.playNextAnimationMain = true;
            this.playNextAnimationNPC = true;
          }
        }
      }
    }

    //
    if (this.currentPage.TRANSITION) {
      this.startPageOnTransitionEnd = true;
      this.pageTransitionInProgress = true;
      this.transitionPage(this.currentPage.TRANSITION.TYPE, true, this.transitionTime);
    } else {
      this.startPageOnTransitionEnd = true;
      this.pageTransitionInProgress = false;
    }
  };

  playAsObjectAnimation = (game, objectData) => {
    game.currentObjectAnimation = objectData.ANIMATION.ANIMATIONS_SEQUENTIAL[game.currentObjectSequentialIndex].NAME;
    game.npcs[objectData.NAME].playAsInProgress = true;
    game.objectAnimationPlayAsInProgress = true;

    // play animations for pet & npcs as dictated by object
    for (let currentPlayAs in objectData.ANIMATION.ANIMATIONS_SEQUENTIAL[game.currentObjectSequentialIndex].PLAYAS) {
      const currentPlayAsAnimationData =
        objectData.ANIMATION.ANIMATIONS_SEQUENTIAL[game.currentObjectSequentialIndex].PLAYAS[currentPlayAs];
      if (currentPlayAs === 'PET') {
        game.playActorPlayAsAnimation(game.pet, currentPlayAsAnimationData);
      } else if (game.npcs[currentPlayAs] !== undefined) {
        game.playActorPlayAsAnimation(game.npcs[currentPlayAs], currentPlayAsAnimationData);
      }
    }

    // play object animation
    game.npcs[objectData.NAME].play(game.currentObjectAnimation);
  };

  playActorPlayAsAnimation = (actor, animationData) => {
    if (animationData.FLIPX !== undefined) {
      actor.flipX = animationData.FLIPX;
    }
    actor.play(animationData.NAME);
  };

  playPage = () => {
    // go through animations & move to as applicable
    if (
      this.playNextAnimationMain &&
      !this.objectAnimationPlayFirstInProgress &&
      !this.pet.movementInTransition &&
      !this.objectAnimationPlayAsInProgress
    ) {
      this.playNextAnimationMain = false;
      this.currentAnimationIndexMain++;
      const nextAnimation = this.currentMainAnimations[this.currentAnimationIndexMain];

      if (nextAnimation) {
        this.nextActorAction(this.pet, nextAnimation, this.currentPage.INTERACT.MAIN.ANIMATIONS_SEQUENTIAL);
      } else {
        this.characterPageDoneMain = true;
      }
    }

    if (
      this.playNextAnimationNPC &&
      !this.objectAnimationPlayFirstInProgress &&
      !this.npcFriend.movementInTransition &&
      !this.objectAnimationPlayAsInProgress
    ) {
      this.playNextAnimationNPC = false;
      this.currentAnimationIndexNPC++;
      const nextAnimation = this.currentNPCAnimations[this.currentAnimationIndexNPC];

      if (nextAnimation) {
        this.nextActorAction(this.npcFriend, nextAnimation, this.currentPage.INTERACT.NPC.ANIMATIONS_SEQUENTIAL);
      } else {
        this.characterPageDoneNPC = true;
      }
    }

    // if all animations are done, transition to end page
    if (this.characterPageDoneNPC && this.characterPageDoneMain) {
      this.endPage();
    }
  };

  nextActorAction = (actor, nextAnimation, config) => {
    if (nextAnimation) {
      const nextAnimationData = config.find((x) => x.NAME == nextAnimation);
      actor.currentSceneAnimationData = nextAnimationData;
      if (nextAnimationData.FLIPX !== undefined) {
        actor.flipX = nextAnimationData.FLIPX;
      }
      if (nextAnimationData.DEPTH !== undefined) {
        actor.setDepth(nextAnimationData.DEPTH);
      }
      if (nextAnimationData.ALPHA !== undefined) {
        actor.alpha = nextAnimationData.ALPHA;
      }
      if (nextAnimationData.TRANSITION_EFFECT) {
        actor.alpha = 0;
        this.tweens.add({
          targets: actor,
          alpha: 1,
          ease: 'linear',
          duration: 1000,
        });
      }
      if (nextAnimationData.COORDINATES) {
        actor.movementInTransition = true;
        actor.movementInTransitionCoordinatesLast = {
          x: actor.x,
          y: actor.y,
        };
        actor.movementInTransitionCoordinates = this.getPagePoint(
          this.backgroundIndex,
          nextAnimationData.COORDINATES.x,
          nextAnimationData.COORDINATES.y,
        );
        this.physics.moveTo(
          actor,
          actor.movementInTransitionCoordinates.x,
          actor.movementInTransitionCoordinates.y,
          300,
          3000,
        );
      }
      actor.currentAnimationRepeatCount = 0;
      actor.play(nextAnimation);
    }
  };

  endPage = () => {
    // show captions & buttons
    // if no captions & buttons to show (or if button says "next page"), transition to next page
    if (this.timerNextPageActive && this.timerNextPage.getProgress() >= 0.5) {
      this.endPageTransition(this);
    } else if (this.currentPage.SCREEN || this.currentPage.CAPTION) {
      if (this.engine.engineUi.buttonManager.screenExists(this.currentPage.SCREEN)) {
        this.engine.engineUi.buttonManager?.displayScreen(this.currentPage.SCREEN);
      }
      if (this.engine.engineUi.captionManager.captionExists(this.currentPage.CAPTION) && !this.timerNextPageActive) {
        this.engine.engineUi.captionManager?.showCaption(this.currentPage.CAPTION);
        if (!this.currentPage.SCREEN) {
          this.time.addEvent(this.timerNextPage);
          this.timerNextPageActive = true;
        }
      }
    } else if (!this.timerNextPageActive) {
      this.time.addEvent(this.timerNextPage);
      this.timerNextPageActive = true;
    }
  };

  endPageTransition = (_this) => {
    _this.startPageOnTransitionEnd = false;
    _this.nextPageOnTransitionEnd = true;
    _this.currentObjectIndex = -1;
    _this.currentObjectSequentialIndex = -1;
    _this.timerNextPageActive = false;
    _this.engine.engineUi.captionManager.toggleCaption(true);
    _this.engine.engineUi.buttonManager.displayScreen();
    _this.transitionPage(_this.currentPage.ENDPAGETRANSITION.TYPE, false, _this.transitionTime);
  };

  transitionPage = (transitionType, toggle = false, time = 6000) => {
    // if starting a page
    if (toggle) {
      if (transitionType == keySceneDataTypes.TRANSITION_FADE) {
        this.cameras.main.fadeIn(time);

        // place objects (we can teleport them since they are fading in/out rather than moving to the next page)
        if (this.currentPage.INTERACT) {
          if (this.currentPage.INTERACT.NPC) {
            this.npcStartCoordinates = this.setUpActor(
              this.npcs[keySceneDataTypes.NPC_FRIEND],
              this.currentPage.INTERACT.NPC,
            );
          }
          if (this.currentPage.INTERACT.MAIN) {
            this.mainStartCoordinates = this.setUpActor(this.pet, this.currentPage.INTERACT.MAIN);

            const currentIndex = this.backgroundIndex ?? 0;
            const currentBackgroundData = this.backgroundBoundingBoxes[0].data.data[currentIndex].data;

            this.cameras.main.setBounds(
              currentBackgroundData.x,
              currentBackgroundData.y,
              currentBackgroundData.displayWidth,
              currentBackgroundData.displayHeight,
            );
            this.cameras.main.scrollX = this.getPagePoint(
              this.backgroundIndex,
              this.currentPage.CAMERA_POSITION.x,
              this.currentPage.CAMERA_POSITION.y,
            ).x;
            this.cameras.main.scrollY = this.getPagePoint(
              this.backgroundIndex,
              this.currentPage.CAMERA_POSITION.x,
              this.currentPage.CAMERA_POSITION.y,
            ).y;
          }
        }
        // update background as needed
        if (this.currentPage.BACKGROUND_DEPTH) {
          this.backgrounds[this.backgroundIndex].data.setDepth(this.currentPage.BACKGROUND_DEPTH);
        }
      }
    }
    // if ending a page
    else {
      if (transitionType == keySceneDataTypes.TRANSITION_FADE) {
        this.cameras.main.fadeOut(time);
        this.endPageTransitionInProgress = true;
      }
    }
  };

  setUpActor = (actor, config) => {
    let currentCoords = this.placeActor(actor, config.COORDINATES);
    if (config.DEPTH) {
      actor.setDepth(config.DEPTH);
    }
    if (config.SCALE) {
      if (actor.isNativeSprite) {
        actor.setScale(config.SCALE);
      } else {
        actor.scaleActor(config.SCALE);
      }
    }
    if (config.ANIMATION_IDLE) {
      actor.play(config.ANIMATION_IDLE);
    }
    if (config.FLIPX !== undefined) {
      actor.flipX = config.FLIPX;
    }
    if (config.ALPHA !== undefined) {
      actor.alpha = config.ALPHA;
    }
    return currentCoords;
  };

  placeActor = (actor, coordinates) => {
    let calculatedCoordinates = this.getPagePoint(this.backgroundIndex, coordinates.x, coordinates.y);
    actor.x = calculatedCoordinates.x;
    actor.y = calculatedCoordinates.y;
    return calculatedCoordinates;
  };

  addObjects = (_this) => {
    // Actors
    _this.npcFriend = _this.addObject(_this, keySceneDataTypes.NPC_FRIEND, _this.npcFriendScale);
    _this.objectDoor = _this.addObject(_this, keySceneDataTypes.OBJECT_FRONTDOOR, _this.objectDoorScale);
    _this.objectNutrientContainer = _this.addObject(
      _this,
      keySceneDataTypes.OBJECT_NUTRIENTCONTAINER,
      _this.objectNutrientContainerScale,
    );
    _this.objectNutrientBowl = _this.addObject(
      _this,
      keySceneDataTypes.OBJECT_NUTRIENTBOWL,
      _this.objectNutrientBowlScale,
    );

    // Native Sprites
    _this.pillow = _this.addNativeObject(_this, keySceneDataTypes.OBJECT_PILLOW, _this.objectPillowScale);
  };

  addObject = (_this, actorName, scale) => {
    let currentObject;
    const currentAsset = findAsset(_this, _this.friendTimeConfig, actorName);

    if (currentAsset) {
      currentObject = addCharacterActor(_this, 0, 0, currentAsset.NAME);
      currentObject.currentAnimationRepeatCount = 0;
      currentObject.scaleActor(scale);
      currentObject.play(animationType.IDLE);
      currentObject.isNativeSprite = false;
      currentObject.sceneName = actorName;
      _this.npcs = {
        ..._this.npcs,
        [actorName]: currentObject,
      };
    }
    return currentObject;
  };

  addNativeObject = (_this, actorName, scale) => {
    let currentObject;
    const currentAsset = findAsset(_this, _this.friendTimeConfig, actorName);

    if (currentAsset) {
      currentObject = _this.add.sprite(0, 0, this.gameData.gameType.ATLAS, currentAsset.DATA);
      currentObject.isNativeSprite = true;
      currentObject.currentAnimationRepeatCount = 0;
      currentObject.setScale(scale);
      currentObject.sceneName = actorName;
      _this.npcs = {
        ..._this.npcs,
        [actorName]: currentObject,
      };
    }
  };

  onClickReady = (_this) => {
    LogInteraction(_this, _this.sceneData?.sceneType);
    _this.endPageTransition(_this);
  };
}
