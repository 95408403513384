import styled from '@emotion/styled';
import { Snackbar } from '@mui/material';
import { teal } from 'Styles/colors';

export const StyledSnackbar = styled(Snackbar)`
  &.MuiSnackbar-root {
    width: 90%;
    margin: 0 auto;

    & .MuiPaper-root {
      background-color: ${teal._300};
      border-radius: 5px;
      color: #000000;
      font-size: 16px;
      font-weight: 700;
      box-shadow: none;
    }
  }
`;
