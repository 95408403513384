import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const StyledContent = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  align-items: center;
  background: linear-gradient(#f1ecf8, #fff);
  color: #000;
  font-size: 16px;
  overflow: auto;
`;

export const StyledHeader = styled.h1`
  text-align: center;
  margin: 0 0 9px;
  color: #4a4f7f;
  font-size: 20px;
  white-space: pre-wrap;
`;

// TODO update when assets are added
export const StyledHeaderImage = styled.img`
  margin: 3px 0 12px 0;
  height: 45px;
  width: 45px;
`;

export const StyledButtonContainer = styled.span`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  margin-top: 31px;
  width: 140px;
  border-radius: 4px;
  background-color: #3c417d;
  color: white;
  text-transform: none;
  padding: 6px 16px;
`;

export const StyledParagraph = styled.p`
  line-height: 24px;
  margin-top: 0;
`;
