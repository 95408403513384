import { legacyBackgroundColors } from 'Styles/colors';
import styled from '@emotion/styled';

export const StyledToggleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
`;

export const StyledHiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  + label {
    display: flex;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #d8d8d8;
    color: #262626;
    font-size: 14px;

    span {
      margin: auto;
    }
  }

  &:checked + label {
    background: ${legacyBackgroundColors.indigo};
    color: white;
  }
`;
