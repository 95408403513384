import styled from '@emotion/styled';
import { purple, black } from 'Styles/colors';
import { breakpoints } from 'Styles/theme';
import { Link as MuiLink } from '@mui/material';

export const PageHeader = styled.div`
  min-height: 7rem;
  background-color: ${purple._700};
  padding: 0 1rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  box-shadow: 2px 5px 4px ${black._38};
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 5px;
`;

export const PyxirImage = styled.img`
  align-self: flex-end;
  justify-self: center;
`;

export const LearnMoreLink = styled(MuiLink)`
  color: #fff;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
`;

export const SupportLink = styled.a(
  ({ theme }) => `
  justify-self: flex-end;
  height: 69px;
  width: 94px;
  background: ${purple._700};
  color: white;
  border: 1px solid #979797;
  border-radius: 4px;
  font-size: 18px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
  
  ${theme.breakpoints.up(breakpoints.TABLET)} {
    display: none;
  }
`,
);

export const SupportText = styled.div(
  ({ theme }) => `
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${theme.breakpoints.down(breakpoints.TABLET)} {
    display: none;
  }
`,
);

export const PageContainer = styled.div`
  position: relative;
  overflow-y: auto;

  height: 85%;
`;

export const ContentContainer = styled.div`
  height: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding: 16px 16px 32px;

  display: flex;
  flex-direction: column;
  color: ${black._87};
`;
