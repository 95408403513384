import styled from '@emotion/styled';

export const StyledIconContainer = styled.button`
  margin: 0 -1px;
  background-color: white;
  span {
    opacity: 50%;
    font-size: 14px;
  }
`;
