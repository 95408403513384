import React from 'react';
import { Typography } from '@mui/material';
import HeaderBar from 'Core_Components/HeaderBar/HeaderBar';
import { useIntl } from 'react-intl';
import { StyledBackButton, ToolContainer, SpinnerContainer } from '../styles';
import { useHistory, useParams } from 'react-router-dom';
import { HOME, MYTOOLS } from 'Core_Pages/Routes/RoutesConfig';
import { ArrowBackIos } from '@mui/icons-material';
import useMediaType from 'Core_Helpers/useMediaType';
import { breakpoints } from 'Styles/theme';
import { MyVideoToolsModuleStore } from '../../../Video/MyVideoToolsModuleStore';
import Spinner from 'Core_Components/Spinner/Spinner';
import VideoView from './VideoView/VideoView';
import VideoSelection from './VideoSelection/VideoSelection';
import { StyledVideoGrid } from './styles';
import urlcat from 'urlcat';

function MyVideoTools() {
  const intl = useIntl();
  const { id } = useParams();
  const history = useHistory();
  const mediaType = useMediaType();
  const videoStore = MyVideoToolsModuleStore.Instance().Use();
  const videosLoading = videoStore.IsLoading;

  function onBack() {
    const route = id ? urlcat(MYTOOLS, { toolType: 'videos' }) : HOME;
    history.push(route);
  }

  return (
    <ToolContainer>
      <HeaderBar backgroundColor="#FBC290" sx={{ boxShadow: 2, zIndex: 1 }}>
        {mediaType !== breakpoints.DESKTOP && (
          <StyledBackButton onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })}>
            <ArrowBackIos />
          </StyledBackButton>
        )}
        <Typography component="h2" fontSize={18} fontWeight={700}>
          {intl.formatMessage({ id: 'home.tools.videos' })}
        </Typography>
      </HeaderBar>
      <StyledVideoGrid container>
        {videosLoading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        <VideoSelection />
        <VideoView />
      </StyledVideoGrid>
    </ToolContainer>
  );
}

export default MyVideoTools;
