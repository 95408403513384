import { createSlice, createAsyncThunk, isFulfilled, isPending, isRejected, isAnyOf } from '@reduxjs/toolkit';
import { appInsights } from 'Onboard_Helpers/AppInsights';
import { logout } from './authentication';
import api from './services/api';

export const sendAgreementAcknowledgement = createAsyncThunk(
  'agreements/sendAgreementAcknowledgement',
  async ({ acceptedAgreements }, thunkApi) => {
    await acceptedAgreements.forEach((agreement) => {
      thunkApi.dispatch(api.endpoints.acknowledgeAgreements.initiate(agreement));
    });
    return acceptedAgreements.map((o) => o['agreement-id']);
  },
);

export const sendUnsignedAgreementAcknowledgement = createAsyncThunk(
  'agreements/sendUnsignedAgreementAcknowledgement',
  async ({ acceptedAgreements }, thunkApi) => {
    await acceptedAgreements.forEach((agreement) => {
      thunkApi.dispatch(api.endpoints.acknowledgeAgreements.initiate(agreement));
    });
    return acceptedAgreements.map((o) => o['agreement-id']);
  },
);

const initialState = {
  unreadAgreements: {},
  shouldRedirectToAgreements: false,
};

export const agreements = createSlice({
  name: 'agreements',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getAgreements.matchRejected, (state, action) => {
      appInsights.trackException({ exception: action.error });
    });
    builder.addMatcher(api.endpoints.getAgreements.matchFulfilled, (state, action) => {
      state.unreadAgreements = action.payload || {};
      state.shouldRedirectToAgreements = action.payload.agreementList.length > 0;
    });
    builder.addMatcher(
      isAnyOf(isPending(sendAgreementAcknowledgement), isPending(sendUnsignedAgreementAcknowledgement)),
      (state) => {
        state.shouldRedirectToAgreements = false;
      },
    );
    builder.addMatcher(
      isAnyOf(isFulfilled(sendAgreementAcknowledgement), isFulfilled(sendUnsignedAgreementAcknowledgement)),
      (state, action) => {
        state.shouldRedirectToAgreements = false;

        let acknowledgedAgreementIds = action.payload;
        let remainingAgreements = acknowledgedAgreementIds.filter((a) => {
          !state.unreadAgreements.agreementList.includes(a['agreement-id']);
        });
        if (remainingAgreements.length === 0) {
          state.unreadAgreements = { agreementList: [] };
        }
      },
    );
    builder.addMatcher(
      isAnyOf(isRejected(sendAgreementAcknowledgement), isRejected(sendUnsignedAgreementAcknowledgement)),
      (state, action) => {
        appInsights.trackException({ exception: action.error });
        state.unreadAgreements = {};
      },
    );
    builder.addMatcher(isFulfilled(logout), () => initialState);
  },
});

export const selectShouldRedirectToAgreements = (state) => state.agreements.shouldRedirectToAgreements;
export const selectAgreements = (state) => state.agreements.unreadAgreements;

export default agreements.reducer;
