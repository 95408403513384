import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Stories/components/Button';
import { black } from 'Styles/colors';

function SkipButton({ children, ...props }) {
  return (
    <Button
      sx={{
        color: black._87,
        fontSize: '16px',
        marginTop: '18px',
        width: 'fit-content',
        marginInline: 'auto',
        padding: '0 16px',
      }}
      variant={Button.variant.TEXT}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}

SkipButton.propTypes = {
  children: PropTypes.string.isRequired,
};

export default SkipButton;
