import { playerActorType } from 'Core_Games/constants/playerActorType';
import { getBackgroundCenter, getBackgroundPagePoint } from 'Core_Games/Scripts/transformationHelper';
import { sceneType } from '../constants/sceneType';

export class Scene extends Phaser.Scene {
  constructor(childName = '') {
    super(`${sceneType.SCENE.NAME}_${childName}`);
    this.childName = childName;

    this.playerActors = [];
    this.lastInteractionTime = undefined;
    this.backgrounds = [];
  }

  init(scene, sceneConfig, sceneData) {
    this.gameData = sceneData.gameData;
    this.appData = sceneData.appData;
    this.sceneType = sceneData.sceneType;
    this.sceneConfig = sceneConfig;
    this.sceneKey = scene;
    this.engine = sceneData.engine;
    this.engineComplete = this.engine ? true : false;
    this.pet = sceneData.pet;
    this.otherSceneBoundingBox = sceneData.otherSceneBoundingBox;
    this.parentScene = sceneData.parentScene;
    if (!this.engineComplete) {
      this.cameras.main.setBackgroundColor('black');
    }

    this.currentSceneConfig = this.sceneConfig[this.sceneData.gameData.gameData.petState.petType][this.sceneType.NAME];

    this.allowBoundPadding = this.currentSceneConfig.ALLOWBOUNDPADDING ?? true;
    this.configurablePetScale = this.currentSceneConfig.PETSCALE ?? 1;

    // Background Params
    if (this.currentSceneConfig.BOUNDS) {
      this.bounds = {
        height: {
          start: this.currentSceneConfig.BOUNDS.HEIGHT.START ?? 0.0,
          end: this.currentSceneConfig.BOUNDS.HEIGHT.END ?? 0.0,
        },
        width: {
          start: this.currentSceneConfig.BOUNDS.WIDTH.START ?? 0.0,
          end: this.currentSceneConfig.BOUNDS.WIDTH.END ?? 0.0,
        },
        gap: {
          width: this.currentSceneConfig.BOUNDS.WIDTH.GAP ?? 0.0,
        },
      };
    }
  }

  preload() {}

  create() {
    if (!this.engine) {
      this.engine = this.scene.add(sceneType.ENGINE.NAME, sceneType.ENGINE.DATA, true, {
        sceneKey: this.sceneKey,
        sceneType: this.sceneType,
        sceneConfig: this.sceneConfig,
        petConfig: playerActorType[this.sceneData.gameData.gameData.petState.petType].CONFIG,
        gameData: this.gameData,
        appData: this.appData,
      });
    }
  }

  update() {
    if (this.engine) {
      this.engine.update();
    }

    if (this.playerActors) {
      for (let i = 0; i < this.playerActors.length; i++) {
        this.playerActors[i].updateItemLocations();
      }
    }
  }

  getSceneInfo = () => {
    return { lastInteractionTime: this.lastInteractionTime };
  };

  getPageCenter = (index) => {
    return getBackgroundCenter(this.backgroundBoundingBoxes, index);
  };

  getPagePoint = (index, percentageX, percentageY, useChildPoint = false) => {
    let currentBackground;
    let backgroundIndex = index ?? 0;
    if (
      !this.background &&
      this.backgrounds &&
      this.backgrounds[backgroundIndex] &&
      this.backgrounds[backgroundIndex].data &&
      this.backgrounds[backgroundIndex].data.isCombined
    ) {
      currentBackground = this.combinedBackground;
    } else {
      currentBackground = this.background;
    }
    if (this.parentScene && !useChildPoint) {
      return this.parentScene.getPagePoint(this.parentScene.backgroundIndex, percentageX, percentageY);
    } else {
      if (currentBackground && this.backgroundBoundingBoxes) {
        const backgroundPagePoint = getBackgroundPagePoint(
          this,
          currentBackground,
          this.backgroundBoundingBoxes,
          percentageX,
          percentageY,
          index,
        );
        return {
          x: backgroundPagePoint.absolute.x,
          y: backgroundPagePoint.absolute.y,
        };
      } else {
        return {
          x: this.game.renderer.width * percentageX,
          y: this.game.renderer.height * percentageY,
        };
      }
    }
  };
}
