import { IonicSecureStorage } from 'Core_Helpers/authentication/IonicSecureStorage';
import { appInsights } from 'Core_Helpers/AppInsights';

export const DATETIME_OVERRIDE_KEY = 'dateTimeOverride';
export const TOKEN_RESPONSE_KEY = 'token_response';
export const USERINFO_RESPONSE_KEY = 'userinfo_response';
export const PUSH_NOTIFICATION_KEY = 'allowNotifications';

const StorageService = (() => {
  const STORAGE_NAME = 'pyx-health';

  let instance = null;

  async function createInstance() {
    appInsights.trackTrace({ message: 'Diagnostic: Initializing Storage Service' });

    if (instance) return instance;

    if (window.cordova) {
      await initializeSecureStorage();
    } else {
      appInsights.trackTrace({ message: 'Diagnostic: Establishing local storage' });
      instance = localStorage;
    }

    return instance;
  }

  async function initializeSecureStorage() {
    if (await IonicSecureStorage.checkAvailability(STORAGE_NAME)) {
      appInsights.trackTrace({ message: 'Diagnostic: Establishing secure storage' });
      instance = new IonicSecureStorage(STORAGE_NAME);
    } else {
      appInsights.trackTrace({ message: 'Diagnostic: Secure storage unavailable, falling back to local storage' });
      instance = localStorage;
    }
  }

  return {
    getInstance: async () => {
      instance = instance || (await createInstance());
      return instance;
    },
    clearInstance: () => (instance = null),
  };
})();

export default StorageService;
