import React, { useEffect } from 'react';
import PropsTypes from 'prop-types';
import Phaser from 'phaser';
import { GameContainer } from './styles';
import { logDiagnosticTrace } from 'Core_Helpers/logDiagnosticTrace';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { appInsights } from 'Core_Helpers/AppInsights';

function PhaserGame({ id, gameData, config }) {
  const gameId = `${id}-phaser-game`;

  useEffect(() => {
    let game = initializeGame();
    logDiagnosticTrace('PhaserGame Initialized');

    return () => {
      game.destroy(true);
      logDiagnosticTrace('PhaserGame Ending');
    };
  }, []);

  const initializeGame = () => {
    let gameConfiguration = config;
    gameConfiguration.parent = gameId;
    gameConfiguration.callbacks = {
      preBoot: (game) => {
        game.registry.merge(gameData);
      },
    };
    return new Phaser.Game(gameConfiguration);
  };

  return (
    <AppInsightsErrorBoundary appInsights={appInsights}>
      <GameContainer id={gameId} />
    </AppInsightsErrorBoundary>
  );
}

PhaserGame.propTypes = {
  id: PropsTypes.string.isRequired,
  gameData: PropsTypes.object,
  config: PropsTypes.object,
};

export default PhaserGame;
