import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import HiFriendLayout from 'Core_Components/Layout/HiFriendLayout/HiFriendLayout';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { StyledButton, StyledButtonContainer } from 'Core_Pages/Thrive/featureStyles';
import { Grid, Typography } from '@mui/material';
import { ContentContainer, StyledImage } from './styles';
import { contentTypes } from './enums';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { nextPage, selectThrive } from 'Core_Redux/thrive';
import hasPermissions from 'Core_Helpers/authentication/hasPermissions';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';
import { selectUserInfo, selectSyncUser } from 'Core_Redux/user';
import { getThriveConfig } from 'Core_Pages/Thrive/ThriveConfig/thriveConfig';
import Spinner from 'Core_Components/Spinner/Spinner';
import { transformText } from 'Core_Helpers/transformText';

function ThriveContent() {
  const dispatch = useCoreDispatch();
  const history = useHistory();
  const { thriveContentType } = useParams();
  const [config, setConfig] = useState();
  const [loading, setLoading] = useState(true);
  const thrive = useCoreSelector(selectThrive);
  const thriveConfig = getThriveConfig(thrive);
  const profile = useCoreSelector(selectUserInfo);
  const user = useCoreSelector(selectSyncUser);
  const intl = useIntl();

  const onNext = () => dispatch(nextPage({ history }));

  useEffect(() => {
    const contentType = thriveContentType?.replace('-', '')?.toUpperCase();
    const content = thriveConfig?.program?.thriveContent[contentType];

    if (content) {
      setConfig(content);
      setLoading(false);

      if (content?.onEnterDispatch) {
        content.onEnterDispatch({ dispatch });
      }
    }
  }, []);

  const descriptionOnlyStyle = {
    padding: '8px 0px 8px 0px',
  };

  if (loading || !profile) {
    return <Spinner />;
  }

  if (!hasPermissions(config?.permissions, config?.roles, profile)) {
    return <Redirect to={HOME} />;
  }

  const {
    content,
    title,
    header,
    headerProps,
    buttonText,
    gridContainerProps,
    ContainerComponent,
    CustomButton,
    buttonProps,
  } = config;

  const ThriveContentContainer = ContainerComponent ?? HiFriendLayout;
  const ThriveButton = CustomButton ?? StyledButton;

  return (
    <ThriveContentContainer>
      <ScreenReaderWrapper>
        <h1>
          <FormattedMessage id={title} />
        </h1>
      </ScreenReaderWrapper>
      <h2 {...headerProps}>{intl.formatMessage({ id: header })}</h2>
      <Grid container alignItems={'center'} spacing={1} {...gridContainerProps}>
        {content?.map(
          (
            { contentType, src, textId, altId, imageStyle, listGridProps, gridProps, items, gridGroupProps, ...props },
            index,
          ) => {
            const descriptionStyle = contentType === contentTypes.LIST_IMAGE ? {} : descriptionOnlyStyle;
            return (
              <ContentContainer
                key={index}
                item
                container
                direction="column"
                columns={12}
                paddingLeft={0}
                {...gridGroupProps}
              >
                {contentType === contentTypes.LIST_IMAGE && (
                  <Grid item mobile={3} {...gridProps} {...listGridProps}>
                    <StyledImage alt={altId ? intl.formatMessage({ id: altId }) : ''} src={src} style={imageStyle} />
                  </Grid>
                )}
                <Grid
                  item
                  mobile={contentType === contentTypes.LIST_IMAGE ? 9 : 12}
                  sx={descriptionStyle}
                  {...gridProps}
                >
                  {contentType === contentTypes.IMAGE && (
                    <StyledImage alt={intl.formatMessage({ id: altId })} src={src} style={imageStyle} {...props} />
                  )}
                  {(contentType === contentTypes.TEXT || (contentType === contentTypes.LIST_IMAGE && !items)) && (
                    <Typography fontSize={16} {...props}>
                      {transformText(intl.formatMessage({ id: textId }), user)}
                    </Typography>
                  )}
                  {(contentType === contentTypes.TEXTSECTION || (contentType === contentTypes.LIST_IMAGE && items)) &&
                    items?.map((item, subIndex) => {
                      return (
                        <Typography key={subIndex} fontSize={16} {...item.props}>
                          {transformText(intl.formatMessage({ id: item.textId }), user)}
                        </Typography>
                      );
                    })}
                </Grid>
              </ContentContainer>
            );
          },
        )}
      </Grid>
      <StyledButtonContainer>
        <ThriveButton variant="contained" onClick={onNext} {...buttonProps}>
          {intl.formatMessage({ id: buttonText })}
        </ThriveButton>
      </StyledButtonContainer>
    </ThriveContentContainer>
  );
}

export default ThriveContent;
