import React from 'react';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import { completeBirthdayEntry, selectSignupMetadata } from 'Onboard_Redux/signup';
import Button from 'Stories/components/Button';
import { Header, StyledDateInput } from 'Onboard_Components/Account/styles';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { minimumAgeRequirement, calculateAge, minBirthDate } from 'Onboard_Helpers/dates';

function Birthday() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const { loading, progress } = useOnboardSelector(selectSignupMetadata);
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm();

  function onContinue(data) {
    dispatch(completeBirthdayEntry(data));
  }

  const formComplete = dirtyFields.birthday;

  const birthdayValidationMessage = (v) => {
    const enteredDate = new Date(v);
    if (calculateAge(enteredDate) < minimumAgeRequirement) {
      return intl.formatMessage({ id: 'error.age-requirement-not-met' });
    }
    if (enteredDate < minBirthDate || isNaN(enteredDate)) {
      return intl.formatMessage({ id: 'error.invalid-input' });
    } else {
      null;
    }
  };

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <Header>{intl.formatMessage({ id: 'account.sign-up.enter-birthday' })}</Header>
      <form onSubmit={handleSubmit(onContinue)}>
        <Controller
          name="birthday"
          control={control}
          defaultValue=""
          rules={{
            validate: (v) => birthdayValidationMessage(v),
          }}
          render={({ field, fieldState: { error } }) => (
            <StyledDateInput
              {...field}
              disableFuture
              id="birthdayInput"
              error={!!errors.birthday?.message}
              errorMessage={errors.birthday?.message}
              slotProps={{ textField: { error: !!error, helperText: error?.message } }}
              label={intl.formatMessage({ id: 'dob.birthday' })}
              inputProps={{
                'aria-label': intl.formatMessage({ id: 'account.sign-up.enter-birthday' }),
                placeholder: 'mm/dd/yyyy',
              }}
            />
          )}
        />
        <Button fullWidth loading={loading} disabled={!formComplete} type="submit">
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
    </>
  );
}

export default Birthday;
