import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { appInsights } from 'Core_Helpers/AppInsights';
import PropTypes from 'prop-types';
import { Tab } from '@mui/material';
import { TALKTOSOMEONE } from 'Core_Pages/Routes/RoutesConfig';
import { TalkIcon, TalkAlertIcon } from '../svgs';
import { DirectMessageStore } from 'Core_Pages/DirectMessage/Store/DirectMessageStore';

const TalkNavbarItem = ({ onClick, ...props }) => {
  const history = useHistory();
  const intl = useIntl();
  const directmessageStore = DirectMessageStore.Instance().Use();
  const hasUnreadMessage = directmessageStore.HasUnreadMessage;

  const { selected } = props;

  const Icon = hasUnreadMessage ? TalkAlertIcon : TalkIcon;

  const onNavItemClick = (event) => {
    onClick(event);
    history.push(TALKTOSOMEONE);
    appInsights.trackEvent({ name: 'Talk_To_Someone_Clicked' });
  };

  return (
    <>
      <Tab
        {...props}
        icon={<Icon aria-hidden="true" />}
        label={intl.formatMessage({ id: 'menu-header.talk-to-someone' })}
        href={TALKTOSOMEONE}
        aria-current={selected}
        onClick={onNavItemClick}
      />
    </>
  );
};

TalkNavbarItem.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default TalkNavbarItem;
