import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from 'Core_Helpers/authentication/AuthService';
import { areTokensValid } from 'Core_Helpers/authentication/authTokens';
import getDateTimeOverride from 'Core_Helpers/localStorage/getDateTimeOverride/getDateTimeOverride';
import { customCreateApi } from './customApi';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = getState().authentication.accessToken;
    const dateTimeOverride = getDateTimeOverride();
    if (token) headers.set('Authorization', `Bearer ${token}`);
    if (dateTimeOverride) headers.set('x-datetime-override', dateTimeOverride);
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  const { authentication } = api.getState();

  if (
    authentication?.accessToken &&
    authentication?.refreshToken &&
    !areTokensValid(authentication.accessToken, authentication.refreshToken)
  ) {
    const authService = await AuthService.getInstance();
    await authService.tryRefreshTokens();
  }

  return await baseQuery(args, api, extraOptions);
};

const tags = {
  USER: 'user',
};

const api = customCreateApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    getAgreements: builder.mutation({
      query: (userId) => `v5/members/${userId}/agreements`,
      providesTags: () => [{ type: tags.USER, id: 'LIST' }],
      invalidatesTags: () => [{ type: tags.USER, id: 'LIST' }],
      transformResponse: (response) => {
        if (!response) {
          return {
            agreementList: [],
          };
        } else {
          return response;
        }
      },
    }),
    acknowledgeAgreements: builder.mutation({
      query: (agreement) => {
        return {
          url: 'v1.0/profile/disclosure/acknowledge',
          body: agreement,
          method: 'POST',
        };
      },
    }),
    getNetworkGroupConfig: builder.query({
      query: (userId) => `v1/healthplans/resources?userId=${userId}`,
    }),
    getThriveAccess: builder.mutation({
      query: (userId) => `v1/thrive/access/${userId}`,
    }),
    getThriveAssessments: builder.mutation({
      query: (userId) => `v1/thrive/assessments/scores/pending?userId=${userId}`,
    }),
    getThriveProgress: builder.mutation({
      query: (userId) => `v1/thrive/progress/${userId}`,
    }),
    onboard: builder.mutation({
      query: ({ birthday, healthPlanId }) => {
        return {
          url: 'v3.0/onboarding/onboard',
          body: {
            DOB: birthday,
            HealthPlan: healthPlanId,
          },
          method: 'POST',
        };
      },
    }),
    synchronizeUser: builder.query({
      query: (userId) => `v5/members/${userId}`,
    }),
    updateSyncUser: builder.mutation({
      query: ({ userId, patchObject }) => ({
        url: `v5/members/${userId}`,
        method: 'PATCH',
        body: { memberPatchItems: patchObject },
      }),
    }),
    getPendingHiFriendSurvey: builder.query({
      query: (userId) => `v1/survey/response/pending?userId=${userId}`,
    }),
    completeHiFriendSurvey: builder.mutation({
      query: ({ userId, answers }) => {
        return {
          url: 'v1/survey/response',
          body: {
            userId,
            answers,
          },
          method: 'POST',
        };
      },
    }),
    getUserMeta: builder.query({
      query: (userId) => `v1/usermeta/${userId}`,
    }),
    getThriveCongrats: builder.query({
      query: (userId) => `v1/thrive/access/${userId}/congrats`,
    }),
    logThriveCongrats: builder.mutation({
      query: ({ userId }) => {
        return {
          url: `v1/thrive/access/${userId}/congrats`,
          method: 'POST',
          body: {
            userId,
          },
        };
      },
    }),
  }),
});

export const {
  useGetThriveAccessMutation,
  useGetThriveAssessmentsMutation,
  useGetThriveProgressMutation,
  useLazyGetNetworkGroupConfigQuery,
  useAcknowledgeAgreementsMutation,
  useGetAgreementsMutation,
  useLazySynchronizeUserQuery,
  useOnboardMutation,
  useUpdateSyncUserMutation,
  useGetPendingHiFriendSurveyQuery,
  useCompleteHiFriendSurveyMutation,
  useLazyGetUserMetaQuery,
  useGetThriveCongratsQuery,
  useLogThriveCongratsMutation,
} = api;

export default api;
