import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { pyxirIcon } from 'Assets/img/talk-to-someone-icons';
import TalkToSomeoneCardStatus from './Status/TalkToSomeoneCardStatus';
import { StyledButton, StyledCard, StyledIcon } from './styles';
import { CHAT } from 'Core_Pages/Routes/RoutesConfig';
import { useHistory } from 'react-router-dom';

const PyxirChatCard = ({ hasBorder }) => {
  const intl = useIntl();
  const history = useHistory();

  const onClick = () => history.push(CHAT);

  return (
    <StyledCard hasborder={hasBorder}>
      <Grid container>
        <Grid item mobile={3}>
          <StyledIcon src={pyxirIcon} alt={intl.formatMessage({ id: 'talk-to-someone.pyx-robot.icon' })} />
        </Grid>
        <Grid item mobile={9}>
          <Grid container marginTop={'16px'}>
            <Grid item mobile={6}>
              <Typography align="left" fontSize={18} fontWeight={700}>
                {intl.formatMessage({ id: 'talk-to-someone.pyx-robot' })}
              </Typography>
            </Grid>
            <Grid item mobile={6}>
              <TalkToSomeoneCardStatus isOnline={true} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item mobile={10}>
              <Typography align="left" fontSize={16} fontWeight={400}>
                {intl.formatMessage({ id: 'talk-to-someone.pyx-robot-caption' })}
              </Typography>
            </Grid>
            <Grid item mobile={12} container direction="column" alignItems="flex-end" justify="flex-start">
              <StyledButton variant="contained" onClick={onClick}>
                {intl.formatMessage({ id: 'talk-to-someone.auto-chat' })}
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

PyxirChatCard.propTypes = {
  hasBorder: PropTypes.bool,
};

export default PyxirChatCard;
