import { habitTracker } from 'Assets/img/my-tools-icons';

export const habitTrackerEs = {
  key: 'habit tracker',
  title: 'Seguimiento de Hábitos',
  titleImage: habitTracker,
  titleImageAlt: '',
  description:
    '¡El seguimiento de las acciones puede ser útil para agregar nuevos hábitos positivos a tu camino hacia el bienestar!',
  contentId: 7,
  sections: [
    {
      key: 'think-new-habits',
      header: 'Piensa en un nuevo hábito o un cambio que te gustaría hacer para apoyar tu bienestar.',
      description: 'Está bien tener más de un hábito que agregar, pero trata de tener menos de 5 por ahora.',
      itemsHeader: 'Cuéntame más:',
      items: [
        {
          text: 'Comienza con pequeños hábitos que sean fáciles de agregar a tu vida diaria. Piensa en las actividades que podrían tardar un poco en completarse y trata de ser específico. A continuación encontrarás algunos ejemplos de hábitos pequeños y claros.',
          subItems: [
            { text: 'Leer 1 página de un libro antes de ir a dormir todas las noches' },
            { text: 'Escribir en mi diario todos los días' },
            { text: 'Estar en silencio durante 10 minutos todos los días' },
            { text: 'Usar hilo dental todas las noches antes de ir a dormir' },
            { text: 'Caminar al aire libre durante 15 minutos dos veces por semana' },
          ],
        },
        {
          text: '¡El momento para llevar a cabo tus nuevos hábitos depende de ti! Puedes hacerlo todos los días o elegir un período de tiempo más largo para hacerlo, como varias veces a la semana o incluso una vez por semana.',
        },
        {
          text: 'Algunas sugerencias útiles para preguntarte al elegir tu nuevo hábito...',
          subItems: [
            { text: '¿Se puede hacer regularmente, todos los días si es posible?' },
            { text: '¿Desarrolla mis habilidades y es fácil de llevar a cabo?' },
            { text: '¿Mejorará mi salud física? ¿Salud mental? ¿Relaciones? Etc.' },
            { text: '¿Alienta la creatividad y el juego?' },
            { text: '¿Tengo apoyo para hacerlo?' },
          ],
        },
      ],
    },
    {
      key: 'create-grid',
      header: 'Crea un cuadro para realizar un seguimiento de tus acciones habituales durante el próximo mes.',
      description:
        'Este cuadro se utilizará como un calendario del “mes completo” para registrar tu progreso. Usa lápiz y papel, o escribe en una computadora/dispositivo.',
      itemsHeader: 'Cuéntame más:',
      items: [
        {
          text: 'Crea un cuadro horizontal con 32 columnas. La columna 1 debe ser más ancha que el resto. Las columnas 2 a 32 se utilizarán como días del mes, comenzando con 1 y terminando en 31.',
        },
        { text: 'Asigna un nombre a la columna 1: “Objetivo del hábito”.' },
        {
          text: 'Comenzando con la columna 2, asigna un nombre a las columnas restantes con los números del 1 al 31, uno tras otro, para crear los días del mes.',
        },
        {
          text: 'Luego, agrega algunas filas horizontales debajo de la fila superior para comenzar. Puedes agregar más filas en otro momento.',
        },
        { text: 'Escribe el mes en la parte superior de la tabla.' },
      ],
    },
    {
      key: 'track-habits',
      header: 'Comienza el seguimiento en la tabla en el momento en que lleves a la acción tu nuevo hábito.',
      description: 'Realiza un seguimiento y registra cuándo pones en práctica tu nuevo hábito.',
      itemsHeader: 'Cuéntame más:',
      items: [
        {
          text: 'En la columna 1 debajo de la fila denominada “Hábito”, escribe el nuevo hábito que deseas incorporar. Puedes describirlo tan breve o detalladamente como lo desees.',
        },
        {
          text: 'Inmediatamente después de realizar tu nuevo hábito, usa las columnas 2 a 32 para marcar el día calendario en que realizaste la acción.',
        },
        {
          text: '¡Está bien si omites un día! Simplemente deja ese día en blanco y continúa al día siguiente o tan pronto como puedas nuevamente. El seguidor de hábitos es flexible y se puede ajustar cuando sea necesario.',
        },
        {
          text: '¡Completa tu seguidor de hábitos! ¡Recibirás comentarios inmediatos y verás tu progreso cada vez que lo uses!',
        },
        {
          text: 'Usa tu seguidor de hábitos como un recordatorio visual para ver el progreso que estás haciendo, y para ayudarte a seguir avanzando hacia tu propio bienestar y recibir apoyo.',
        },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Autorreflexión',
      description: 'Dedica un momento de tranquilidad para revisar tu experiencia con esta actividad.',
      itemsHeader: 'Percepciones y marcadores del camino',
      items: [
        { text: '¿Qué has aprendido de este ejercicio?' },
        { text: '¿Cómo te sentías antes de completar este ejercicio?' },
        { text: '¿Cómo te sientes después de hacerlo?' },
        { text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?' },
        { text: '¿Qué disfrutaste más de este ejercicio?' },
        { text: '¿En qué aspectos/elementos del desafío/objetivo deseas trabajar en primer lugar?' },
        { text: '¿Qué ayudaría a facilitar el primer paso?' },
        {
          text: '¿Cuáles serían algunos de los beneficios de que las personas realicen esta actividad con más frecuencia en general?',
          divider: true,
        },
        {
          text: 'La simplicidad es una parte importante del desarrollo de nuevos hábitos y acciones. Para empezar, comienza con un hábito simple que puedas realizar en poco tiempo y agrega más hábitos nuevos cuando te sientas cómodo.',
        },
        {
          text: 'Coloca tu seguidor de hábitos en un lugar donde puedas verlo fácilmente, como en el refrigerador, una mesita de noche o en tu escritorio, y recuerda completarlo.',
        },
        {
          text: '¡Recuerda ser amable contigo en tu Camino para triunfar! Los hábitos pueden ser un componente valioso de un cambio positivo, pero el tiempo necesario para desarrollar nuevos hábitos para el bienestar personal varía de una persona a otra.',
        },
        {
          text: 'Como los entornos físicos y sociales pueden desencadenar hábitos, puede resultar útil cambiar el entorno o las rutinas para respaldar nuevas acciones positivas.',
        },
      ],
    },
  ],
};
