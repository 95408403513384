import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from '../helpers/getAuthClient';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectUserInfo } from 'Core_Redux/user';
import urlcat from 'urlcat';

const cardsUrl = 'v1/cards';
const options = {
  revalidateOnFocus: false,
};

const searchCards = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useSearchCards = (swrOptions = {}) => {
  const user = useCoreSelector(selectUserInfo);
  const [isPending, setIsPending] = useState(true);
  const [cardResults, setCardResults] = useState();

  const { data: cardResultsData, error } = useSWR(
    () =>
      urlcat(`${process.env.API_BASE_URL}/${cardsUrl}`, {
        sentToUserId: user.sub,
        isRead: false,
      }),
    searchCards,
    {
      ...options,
      ...swrOptions,
    },
  );

  useEffect(() => {
    if (cardResultsData) {
      setCardResults(cardResultsData.data);
    }

    setIsPending(!cardResultsData && !error);
  }, [cardResultsData, error]);

  return { cards: cardResults, searchCardsError: error, isSearchCardsPending: isPending };
};

export default useSearchCards;
