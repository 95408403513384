import { bodyAwareness, increaseMovement, balanceSenses } from 'Assets/img/my-tools-icons';
import urlcat from 'urlcat';
import { STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';
import { itemTypes } from '../components/ToolCard/toolCardConfig';
import { userMetaTagCategories } from 'Core_Pages/MyTools/userMetaHelpers';

export const physicalHealthItems = [
  {
    icon: bodyAwareness,
    labelId: 'my-tools.body-awareness',
    to: urlcat(STATICCONTENT, { contentType: 'bodyawareness' }),
    loggedEvent: 'Body_Awareness_Static_Content_Clicked',
  },
  {
    icon: increaseMovement,
    labelId: 'my-tools.increase-movement',
    to: urlcat(STATICCONTENT, { contentType: 'increasemovement' }),
    loggedEvent: 'Increase_Movement_Static_Content_Clicked',
  },
  {
    icon: balanceSenses,
    labelId: 'my-tools.balancedsenses',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'balancedsenses' }),
    loggedEvent: 'Balance_Using_Sense_Content_Clicked',
    contentCategory: userMetaTagCategories.YOUTH,
  },
];
