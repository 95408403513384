import Phaser from 'phaser';
import { Game } from 'Core_Games/game';
import phaserReact from 'Libraries/phaser-react/index';

export const gameConfig = {
  type: Phaser.AUTO,
  scale: {
    mode: Phaser.Scale.ScaleModes.RESIZE,
    autoCenter: Phaser.Scale.CENTER_BOTH,
    width: '100%',
    height: '100%',
  },
  backgroundColor: '#ffffff',
  plugins: {
    global: [
      {
        key: 'phaser-react',
        plugin: phaserReact,
        start: true,
      },
    ],
  },
  dom: {
    createContainer: true,
    updateContainer: true,
  },
  render: {
    antialias: true,
    pixelArt: false,
    roundPixels: false,
  },
  physics: {
    default: 'arcade',
    arcade: {
      gravity: { y: 400 },
      debug: false,
    },
  },
  scene: Game,
};
