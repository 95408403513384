import React from 'react';
import { useIntl } from 'react-intl';
import ResultsResources from 'Core_Pages/Screening/Results/components/ResultsResources/ResultsResources';
import { NetworkGroupResourceAccessor } from 'Core_Helpers/NetworkGroupResourceAccessor';

const Resources = () => {
  const intl = useIntl();
  const needsResources = new NetworkGroupResourceAccessor().Resources?.Needs;

  return (
    <ResultsResources
      resources={needsResources}
      memberType={'Youth'}
      title={intl.formatMessage({ id: 'screenings.results.youth.resources.needs' })}
    />
  );
};

export default Resources;
