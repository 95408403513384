import { legacyBackgroundColors } from 'Styles/colors';
import styled from '@emotion/styled';

export const StyledHiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  + label {
    margin: 0 6px 10px 0;
    border: 1px solid ${legacyBackgroundColors.indigo};
    border-radius: 12px;
    padding: 3px 10px;
    background: #3f437c25;
    color: ${legacyBackgroundColors.indigo};
    font-size: 14px;
    font-weight: 700;

    // hide checkmark
    span {
      display: none;
    }
  }

  &:checked + label {
    display: flex;
    flex-wrap: nowrap;

    background: ${legacyBackgroundColors.indigo};
    color: white;

    // display checkmark
    span {
      display: block;
      margin-right: 4px;
      font-size: 16px;
    }
  }
`;
