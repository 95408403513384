import { mindfulEating } from 'Assets/img/my-tools-icons';

export const mindfulEatingEn = {
  key: 'mindful-eating',
  title: 'Mindful Eating',
  titleImage: mindfulEating,
  titleImageAlt: '',
  description: 'Becoming aware of feelings while eating can help boost energy and support overall well-being!',
  contentId: 13,
  sections: [
    {
      key: 'food-item',
      header: 'Select a single food item and go to a calm, quiet location with it',
      description:
        'Pick a food item you enjoy and go to a place where you will be safe, secure, and undisturbed for a little while.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Choose an easy, convenient food item you can hold in your hand such as a piece of fruit, vegetable, bread item, dairy product, etc.',
        },
        {
          text: 'Select a location inside or outdoors where you can comfortably sit.',
        },
        {
          text: 'Avoid distractions and multitasking during this time.',
        },
        {
          text: 'If you’d like, bring paper and pen to write down observations and notes.',
        },
      ],
    },
    {
      key: 'deep-breaths',
      header: 'Once you’re comfortable, take a few deep breaths and notice how your body feels.',
      description: 'For a few minutes, focus on your breathing; notice where it flows and how your body feels.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Slowly breathe in and out in a way that’s comfortable for you.',
        },
        {
          text: 'Quiet your mind and notice how your body feels. How is your energy today? Do you feel any stress? Where are your thoughts?',
        },
        {
          text: 'Gently release thoughts of the day and move your attention to the present moment.',
        },
        {
          text: 'If your mind wanders, simply acknowledge that and gently bring your attention back to your breathing.',
        },
        {
          text: 'It’s okay to keep your eyes open or have them closed as you breathe.',
        },
      ],
    },
    {
      key: 'food-feelings',
      header: 'Next, turn your focus to the food item you selected and your feelings.',
      description: 'In a relaxed, non-judgmental way, observe the item and notice any sensations that come up.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Hold the item in your hand(s) and take note of its qualities, describing it to yourself, out loud, or in writing.',
        },
        {
          text: 'Helpful hints for describing its qualities: how does it...',
          subItems: [
            {
              text: 'Look – Let your eyes explore every part of it. What is its color? Shape? Size?',
            },
            {
              text: 'Feel – What is the texture like? Smooth? Bumpy? Squishy? Hard?',
            },
            {
              text: "Smell – Hold the item to your nose and takein the scent. Does anything happen in your mouth or stomach? Did you hear a rumble? Develop saliva? Did you see pictures in your mind's eye? Recall memories?",
            },
            {
              text: 'Sound – Move the item to your ear and observe any sound it makes. Does it swish? Hum? Rattle?',
            },
          ],
        },
        {
          text: 'Next, slowly bring it up to your mouth and gently take a bite, but do not chew. Observe how it gets in your mouth and spend a few moments noticing how you feel with it in your mouth.',
        },
        {
          text: 'Begin to chew the item for one or two slow bites and truly savor it. Notice your experience with its taste. Without swallowing it, notice how it feels in your mouth. Does it change as you continue chewing? Make sure to chew it for a while to savor the flavor.',
        },
        {
          text: 'Notice if you can detect the sensation to swallow before swallowing it. After swallowing, can you feel the item moving down into your stomach?',
        },
        {
          text: 'Before taking the next bite, check in to sense how your body feels after swallowing it fully.',
        },
        {
          text: 'Continue eating the item, slowly chewing and focusing on the process of eating with your full attention for as long as you can and feel comfortable doing so.',
        },
      ],
    },
    {
      key: 'moment-of-gratitude',
      header: 'When done, finish with a moment of gratitude.',
      description:
        'Acknowledge your food item and the effort that went into growing and preparing it for you nourishment.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Pause for a moment and think about what you noticed and how you felt while eating your food item.',
        },
        {
          text: "Consider all the elements your item needed to grow and get to you, including people. If you'd like, draw a picture of the item and its journey.",
        },
        { text: 'In your own way, express gratitude for you food item and its nourishment to your body and being.' },
      ],
    },
    {
      key: 'self-reflection',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your experience with this activity.',
      itemsHeader: 'Insights and pointers',
      items: [
        { text: 'What have you learned from this exercise?' },
        { text: 'How did you feel before completing this exercise?' },
        { text: 'How do you feel after it?' },
        { text: 'What part(s) of this exercise did you find most challenging? Why?' },
        { text: 'What did you enjoy most about this exercise?' },
        { text: 'What would be some benefits of doing this activity more often?', divider: true },
        {
          text: 'Becoming mindful about what we eat, how we eat it, and the incredible journey food takes from its origins to us can help strengthen a positive relationship with food, as well as help develop a greater sense of gratitude and appreciation for supporting our health and well-being.',
        },
        {
          text: 'Slowing down and stepping away from multitasking and distractions to truly savor food can help maintain balanced health and boost positive feelings throughout the day.',
        },
        {
          text: 'Developing awareness of the thoughts and sensations associated with eating can help strengthen cues to support positive, integrated physical and emotional well-being.',
        },
        {
          text: 'Enjoy some time checking in with yourself on your Thrive Pathway and experiencing the process of mindful eating!',
        },
      ],
    },
  ],
};
