import { legacyBackgroundColors } from 'Styles/colors';
import styled from 'styled-components';

export const GreetingContainer = styled.div`
  height: ${(props) => (props.isNative ? '125px' : '110px')};
  background-color: ${legacyBackgroundColors.indigo};
  z-index: 1;
  align-items: center;
  box-shadow: 2px 5px 4px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: -20px;
  padding: ${(props) => (props.isNative ? '40px' : '0px')} 20px 0 10px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const Pyxir = styled.img`
  height: 85px;
  align-self: flex-end;
  justify-self: center;
`;
