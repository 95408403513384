import { bodyPart } from '../../../../constants/bodyPart';
import { Config as dogNutrientContainerConfig } from './objectData/DogNutrientConfig';
import { Config as catNutrientContainerConfig } from './objectData/CatNutrientConfig';
import { gameImportType } from '../../../../constants/gameImportType';
import { actorType } from '../../../../constants/actorType';

export const nutrientContainerAssetConfig = {
  NAME: 'NUTRIENTCONTAINER',
  SPRITESHEET_FOLDER: 'nutrient-container',
  SPRITESHEET_NAME: 'NutrientContainer-SpriteMap-',
  DATA: [dogNutrientContainerConfig, catNutrientContainerConfig],
  ACTORTYPE: actorType.OBJECT,
  TYPE: gameImportType.SPRITESHEET,
  INVENTORY: {
    [bodyPart.MOUTH]: [],
    [bodyPart.NECK]: [],
  },
  AUDIO: {},
  SCALE: 1.0,
  HEIGHT: 800,
  WIDTH: 800,
};
