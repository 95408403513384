import React from 'react';
import { useIntl } from 'react-intl';
import { ScreeningModuleStore } from 'Core_Pages/Screening/ScreeningModuleStore';
import ResultsSymptom from 'Core_Pages/Screening/Results/components/ResultsSymptom/ResultsSymptom';
import { Screenings } from 'Core_Pages/Screening/enumerations/enums';
import SymptomWrapper from 'Core_Pages/Screening/Results/components/ResultsSymptom/SymptomWrapper';
import { responseIndicators } from './config';

const screeningGuid = Screenings.ADULT_PHQ4;

const ResultsBody = () => {
  const intl = useIntl();
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const result = screeningStore.ScreeningResults.find((x) => x.screeningGuid === screeningGuid);

  const symptomIsUrgent = (responseIndicator) => {
    return result.subScores.some((x) => x.responseIndicator === responseIndicator && x.hasUrgentNeed);
  };

  return (
    <SymptomWrapper>
      <ResultsSymptom
        title={intl.formatMessage({ id: 'screenings.results.adult.phq4.symptoms.depression' })}
        value={symptomIsUrgent(responseIndicators.DEPRESSION)}
      />
      <ResultsSymptom
        title={intl.formatMessage({ id: 'screenings.results.adult.phq4.symptoms.anxiety' })}
        value={symptomIsUrgent(responseIndicators.ANXIETY)}
      />
    </SymptomWrapper>
  );
};

export default ResultsBody;
