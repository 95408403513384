import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'Core_Components/LinkButton/LinkButton';
import { ButtonRowContainer, StyledChatButton, StyledButtonContainer } from './styles';
import { Interweave } from 'interweave';

const ButtonRow = ({ buttons, style, screen, onSubmit }) => {
  const linkStyle = { width: '100%', display: 'flex', justifyContent: 'center' };

  const submit = (value) => {
    if (onSubmit) {
      onSubmit(value);
    }
  };

  const createButtons = (button, index) => {
    const { type, ...buttonProps } = button;
    return (
      <Fragment key={`ButtonFragment_${index}`}>
        {
          {
            linkButton: (
              <StyledButtonContainer key={`ButtonContainer${index}`}>
                <LinkButton
                  tabIndex={window.location.pathname.split('/').pop() === 'chat' ? '1' : '0'}
                  style={linkStyle}
                  {...buttonProps}
                >
                  {button.className ? null : (
                    <StyledChatButton
                      data-testid={`ChatButton${index}`}
                      key={index}
                      onClick={() => submit(button.value)}
                    >
                      <Interweave content={button.text} />
                      {button.children}
                    </StyledChatButton>
                  )}
                </LinkButton>
              </StyledButtonContainer>
            ),
            button: (
              <button className={button.className} key={`button${index}`} {...buttonProps}>
                {button.text}
                {button.children}
              </button>
            ),
            submitButton: (
              <StyledButtonContainer key={`ButtonContainer${index}`}>
                <StyledChatButton
                  // eslint-disable-next-line
                  tabIndex={window.location.pathname.split('/').pop() === 'chat' ? '1' : '0'}
                  data-testid={`ChatButton${index}`}
                  onClick={() => submit(button.value)}
                  {...buttonProps}
                >
                  <Interweave content={button.text} />
                </StyledChatButton>
              </StyledButtonContainer>
            ),
          }[type]
        }
      </Fragment>
    );
  };

  return (
    <>
      {buttons.length ? (
        <ButtonRowContainer data-testid="ButtonRow" screen={screen} style={style}>
          {buttons.map(createButtons)}
        </ButtonRowContainer>
      ) : null}
    </>
  );
};

ButtonRow.propTypes = {
  buttons: PropTypes.array,
  style: PropTypes.object,
  screen: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default ButtonRow;
