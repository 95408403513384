import { caringForYourself } from 'Assets/img/my-tools-icons';

export const caringForYourselfEs = {
  key: 'caring-for-yourself-es',
  title: 'Cómo cuidar de ti mismo',
  titleImage: caringForYourself,
  titleImageAlt: '',
  contentId: 31,
  sections: [
    {
      key: 'caring-for-yourself-intro-es',
      description:
        'Cuidar a un ser querido es uno de los trabajos más difíciles que existen y puede generar sensaciones de aislamiento y soledad. Tu disposición para asumir este papel demuestra que tienes un gran corazón y deseas entregarte para ayudar a los demás. ¡Qué maravillosa labor estás llevando a cabo! A continuación verás algunos consejos que pueden ayudarte a cuidar de ti mismo/a para poder cuidar mejor a quienes te rodean.',
    },
    {
      key: 'take-time-es',
      header: 'Dedica un tiempo al autocuidado',
      description:
        'Cuidarte a ti mismo/a te ayuda a cuidar a los demás. Lee esa última frase nuevamente. Serás un mejor cuidador y padre/madre cuando tus necesidades estén cubiertas y te tomes tiempo para ti mismo/a. Las formas de practicar el autocuidado pueden variar de un día a otro y de una semana a la siguiente. Por ejemplo, escucha lo que necesitan tu mente y tu cuerpo. A veces, el autocuidado solo requiere unos minutos de tu tiempo, mientras que otras veces puede llevarte unas horas sentirte recargado/a y renovado/a. Aquí hay algunos ejemplos de autocuidado que puedes considerar:',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Respiración profunda, meditación, yoga u otras actividades de relajación: consulta algunos de los demás recursos y actividades de relajación en nuestra aplicación Pyx.',
        },
        {
          text: 'Tómate una hora para pasar tiempo a solas leyendo, escribiendo un diario o simplemente disfrutando de la tranquilidad.',
        },
        {
          text: '¡Pasa un tiempo al aire libre! El sol y el aire fresco pueden ayudar a despejar tu mente y ofrecerte una sensación de calma.',
        },
        { text: 'Sal a caminar o haz ejercicio de una manera que te resulte agradable.' },
        {
          text: 'Encuentra un deporte o actividad que disfrutes y únete a un grupo o liga que se reúna regularmente: ¿un club de canasta o de lanzamiento de hachas?',
        },
        {
          text: 'Dedica un tiempo para tomar un baño tibio: las sales de baño o los aromas como la lavanda y la menta pueden ser naturales y relajantes.',
        },
        {
          text: 'Agenda tiempo con un amigo o tu pareja: construir y mantener relaciones te ayuda a sentirte menos solo/a.',
        },
      ],
    },
    {
      key: 'schedule-and-goals-es',
      header: 'Anota tu horario y tus objetivos',
      description:
        'Busca una libreta, una agenda o una aplicación en tu teléfono que te ayuden a mantenerte organizado/a. Anota tus objetivos para cada día, semana, mes o año. Cuando tienes niños de crianza que entran y salen de tu casa, tu rutina puede cambiar rápidamente. Para cuidarte mejor, es importante no solo conocer tu rutina, sino también lo que quieres lograr cada día. ¡Está comprobado que escribirlo (o anotarlo electrónicamente en un calendario u otra aplicación) te ayudará a lograrlo! Estos son algunos ejemplos de metas diarias, semanales y anuales que puedes establecer:',
      itemsHeader: 'Cuéntame más',
      items: [
        { key: 'daily-header-es', description: '<b>Diarias</b>' },
        {
          text: 'Tomarme unos minutos para respirar profundamente',
        },
        {
          text: 'Leer un libro durante 30 minutos',
        },
        {
          text: 'Caminar 20 minutos',
        },
        {
          text: 'Llevar un diario de cómo me siento',
        },
        { key: 'weekly-header-es', description: '<b>Semanales</b>' },
        {
          text: 'Agendar un almuerzo con un amigo',
        },
        {
          text: 'Caminar cuatro días esta semana',
        },
        {
          text: 'Planificar tus comidas',
        },
        {
          text: 'Programar una visita de salud mental',
        },
        {
          text: 'Rentar una pista de boliche para la familia',
        },
        { key: 'annual-header-es', description: '<b>Anuales</b>' },
        { text: 'Leer 5 libros' },
        { text: 'Reunirme con un terapeuta de salud mental 10 veces' },
        { text: 'Unirme a una liga de paddle' },
        { text: 'Aprender una nueva habilidad' },
        { text: 'Caminar o hacer ejercicio al menos cuatro veces por semana' },
      ],
    },
  ],
};
