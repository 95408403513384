import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'Core_Components/PageHeader/PageHeader';
import {
  StyledButtonWithMargin,
  StyledContainerWithBackgroundImage,
  StyledParagraphWithPaddingAndMargin,
  StyledPetImageOnBackground,
} from 'Core_Pages/Pets/featureStyles';
import { useHistory } from 'react-router-dom';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import cat from 'Assets/img/adoption-cat-670.gif';
import dog from 'Assets/img/adoption-dog-670.gif';
import { useIntl } from 'react-intl';
import { PetsConfig } from 'Core_Pages/Pets/PetsConfig';

function PetsHello({ pet, userFirstName }) {
  const history = useHistory();
  const intl = useIntl();

  return (
    <StyledContainerWithBackgroundImage pet={pet}>
      <PageHeader>{`${intl.formatMessage({ id: 'common.hello' })} ${pet?.name}`}</PageHeader>
      <StyledParagraphWithPaddingAndMargin>
        {`${intl.formatMessage({ id: 'pets.adoption-center.companion' })} ${userFirstName}`}. ❤️
      </StyledParagraphWithPaddingAndMargin>
      <StyledPetImageOnBackground
        alt={
          pet.petType === PetsConfig.types.dog
            ? intl.formatMessage({ id: 'pets.adoption-center.hello.dog' })
            : intl.formatMessage({ id: 'pets.adoption-center.hello.cat' })
        }
        src={pet.petType === PetsConfig.types.dog ? dog : cat}
      />
      <StyledButtonWithMargin onClick={() => history.push(paths.PETSVISITDAILY)}>
        {intl.formatMessage({ id: 'common.thanks' })}
      </StyledButtonWithMargin>
    </StyledContainerWithBackgroundImage>
  );
}

PetsHello.propTypes = {
  pet: PropTypes.object,
  userFirstName: PropTypes.string,
};

export default PetsHello;
