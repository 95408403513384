import { bathingAndToileting } from 'Assets/img/my-tools-icons';

export const bathingToiletingEn = {
  key: 'bathing-toileting',
  title: 'Bathing and Toileting How-To',
  titleImage: bathingAndToileting,
  titleImageAlt: '',
  contentId: 27,
  sections: [
    {
      key: 'bathing-toileting-intro',
      description:
        'This article for bathing and toileting is not fully comprehensive but offers several helpful tips so that you can provide personal care successfully. It is important to speak with the provider, care manager, nurse, or therapist of the individual you are caring for to learn about available trainings to provide personal cares such as bathing and toileting. Many organizations will provide free trainings, videos, and resources to help you provide personal care and without causing harm to you or your body. This article begins with general tips, followed by more specific tips for both activities. If you have any questions – just ask!',
    },
    {
      key: 'general-tips-bathing-toileting',
      header: 'General Tips for Bathing and Toileting ',
      description:
        'Bathing and toileting are very private activities and are often uncomfortable for an individual to complete in front of another person. You do not want to force the person you are caring for to do something they do not want to do or something that is unsafe. It is your goal to help them be as independent as possible and to provide a space that encourages aging with dignity. Here are some general tips to keep in mind.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: '<b>Know the needs of the individual.</b> It is important to understand what is most comfortable for the individual and their physical, emotional, and cognitive needs. Explain what and how you will be providing care, early and often, to make sure they do not feel afraid or threatened. If they have a cognitive impairment, you may need to repeat yourself and speak very clearly.',
        },
        {
          text: '<b>Know their preferences and capabilities.</b> Do not give an individual a bed bath who is capable of and wants to use the shower. If they usually bathe before bed at night, do not force them to bathe when they wake up in the morning. When toileting, you may need to give them time and space instead of hovering. Ask them what they want and learn their preferred methods.',
        },
        {
          text: '<b>Speak to the individual how you want to be spoken to.</b> Just because the individual needs help with bathing does not mean they are a child or should be treated like one. Use empowering language that is how you would talk to any other adult. Avoid baby talk or using childlike language like, “How are you doing, big guy?” or “Let’s go, sweetie.”',
        },
        {
          text: '<b>Anticipate their needs.</b> Creating a schedule for both bathing and toileting can help you feel less rushed, lead to fewer injuries, and will help the individual know what to expect throughout their day. For bathing, learn how often they need or want to be bathed. On days they are not bathing, try to learn what they like for a cleanliness routine. For example, do they like lotion rubbed on or to have their face washed in the mornings? For toileting, a schedule can lead to fewer accidents and injuries, and may also help with hydration. If a toileting schedule is not the best fit for the individual, you can still track their toileting and can encourage or proactively take them to the bathroom, before an urgent need arises.',
        },
        {
          text: '<b>Provide safe transfers.</b> If the individual needs help, make sure you know how to complete a safe transfer. If the person you are caring for is frail, you should never pull on their hands or arms as this could injure their bones or create painful bruising. Hospitals, home health agencies, and doctors all have staff you can observe and learn from. There are trainings, videos, and other resources available – you just need to ask. Make sure you have grab bars to help support you and the individual and use that gait belt!',
        },
        {
          text: '<b>Use good body mechanics.</b> For both bathing and toileting, it is very important to avoid bending or twisting. Rely on the strongest part of your body – your legs! Providing care can be physically taxing, and preserving your health and wellness is very important.',
        },
        {
          text: '<b>Use equipment and consider modifications.</b> Explore if home modifications are possible, such as for a walk-in shower instead of a tub. When bathing, a shower chair, hand wand, or other bathing equipment may prove useful, and for toileting, a raised toilet seat could lessen the transfer load. Equipment and home modifications are increasingly more common and accessible so be sure to ask around!',
        },
        {
          text: '<b>Wear proper attire.</b> Closed toe shoes with good tread and comfortable but loose-fitting clothing are recommended. If you do not live in the same home as the individual that you are caring for, always have an extra change of clothes with you.',
        },
        {
          text: '<b>Be willing to adapt how you provide care.</b> Think about how you provide bathing and toileting support today. Do any of these tips resonate with you? Are there things you can do as a caregiver to complete this task more easily and better for both yourself and the individual you are caring for? ',
        },
      ],
    },
    {
      key: 'bathing-tips',
      header: 'Bathing Tips',
      description: 'Here are some tips specific to bathing to keep in mind.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: '<b>Make the bathroom inviting.</b> Observe the temperature of the bathroom and take time to make it warm. Find ways to make the experience more comfortable, like playing music or adding scents such as lavender.',
        },
        {
          text: '<b>Water temperature check.</b> Make sure the temperature is not too hot by using the inside of your wrist to test the water.',
        },
        {
          text: '<b>Prepare the space ahead of time.</b> Have the supplies you need ready to go before starting the bathing process including the toiletries, towels, and any equipment.',
        },
        {
          text: '<b>Know the order of how you bathe the individual.</b> The order of body parts and areas to clean is important. For example, if they are bathing in a tub, washing their hair first may lead to them feeling cold during the rest of the bath.',
        },
        {
          text: '<b>Observe changes.</b> Watch for changes such as new or worsening wounds or bruises, changes in skin color, or any other physical changes. These are all things that you should report right away to their doctor.',
        },
      ],
    },
    {
      key: 'toileting-tips',
      header: 'Toileting Tips',
      description: 'Here are some tips specific to toileting to keep in mind.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: '<b>Learn the best way to toilet the individual.</b> The best way to toilet someone who needs care can change day-to-day or hour-to-hour. Make sure to have multiple methods (and all necessary equipment) available. The individual may have more energy and want to walk to the bathroom in the morning but may want to stay in bed and use the bed pan in the evening. Be flexible, adaptable, and do not offer your opinion about what method you think is best for them. Do not force the individual to push themselves physically during toileting – this can quickly lead to injury to you or the individual.',
        },
        {
          text: '<b>Protect yourself and the individual from infection.</b> Make sure you have a large supply of infection prevention equipment such as gloves, cleaning supplies, and wipes. Create a system to always remain fully stocked – by using recurring orders or reaching out to the care manger or social worker to make sure they can have supplies to you before you run out. If the individual is having difficulty with skin breakdown, rashes, dry, or irritated skin, you may need to change the brand or type of gloves or wipes that you are using. Even when you are wearing gloves – wash your hands often (which includes before and after assisting with toileting).',
        },
        {
          text: '<b>Know the proper techniques to wiping and changing.</b> Talk to your doctor or care manager about trainings or videos to make sure that you are completing this task properly. For example, women have different needs when it comes to wiping to prevent infection than men do. There are important skills you can gain from your providers about how to decrease the risk of the skin breakdown, such as making sure the area is completely clean, using non-irritating wipes, and using skin barriers or creams (only as recommended by your care provider).',
        },
        {
          text: '<b>Observe changes.</b> Watch for changes in urine including color, odor, frequency, and amount. These are all changes that you should report right away to their doctor.',
        },
      ],
    },
  ],
};
