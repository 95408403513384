import { decidingHealthcareOptions } from 'Assets/img/my-tools-icons';

export const decidingHealthcareOptionsEn = {
  key: 'deciding-healthcare-options',
  title: 'Healthcare Options',
  titleImage: decidingHealthcareOptions,
  titleImageAlt: '',
  contentId: 33,
  sections: [
    {
      key: 'deciding-healthcare-intro',
      description:
        'When you are caring for someone with a chronic condition – such as cognition impairment, heart or lung disease, a neurological disorder, or cancer – it can be hard to understand what health care options are best. This article provides an overview of some of the different health care providers that you can use and how to decide when to use them.',
    },
    {
      key: 'care-available',
      header: 'What Care is Available?',
      description:
        'If the individual that you are caring for has a chronic condition, you may be working with multiple health care providers already. A primary care doctor or a care manager may be helping you navigate all the different options available. You can always call the individual’s primary care doctor or care manager to ask for help navigating or coordinating the care that the individual may be receiving from other providers.',
    },
    {
      key: 'immediate-care-needs',
      header: 'Immediate Care Needs',
      description:
        'If the individual you are caring for is experiencing a life-threatening need, you should always call 911 or go to the hospital. However, there are several scenarios that may require care more quickly and may not require care in the hospital. It is recommended to maintain an active list of all of the individual’s doctors, including all options to receive care, and have it on your fridge or in an easy to access place for quick use. Here are a few options to keep in mind that are typically available and may be a good alternative to using the hospital:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: '<b>Nurse lines</b> – Often, a primary care doctor or health insurance will have a 24/7 nurse line that you can call to seek advice about what type of care is needed. They can tell you if you need to go to the hospital or if treatment at home or a telehealth visit may be a good option.',
        },
        {
          text: '<b>Behavioral health crisis line</b> – If you or the individual you are caring for is experiencing a mental health or substance use crisis, you can use the national 988 crisis line (dial or text 988), or many providers and health insurances (such as Medicaid) have a number you can call.',
        },
        {
          text: '<b>Telehealth</b> – The individual’s primary care doctor, health insurance, or Medicaid plan may have a telehealth option that is available after hours or even 24/7. This is often a great option if you need a medication quickly or need to know if there is care at home that you can do.',
        },
        {
          text: '<b>Urgent care</b> – Make sure that you know the locations and hours of all the urgent cares close to you. Urgent care is often a better and less expensive option for care, such as if you need medications for an infection.',
        },
        {
          text: '<b>24/7 pharmacy</b> – Make sure you know the locations and hours of your preferred pharmacy and the closest 24/7 pharmacy that takes the individual’s insurance.',
        },
      ],
    },
    {
      key: 'rehab-hospitilization',
      header: 'Rehabilitation after an Injury or Hospitalization',
      description:
        'After an injury or if the individual you are caring for was hospitalized, the hospital may recommend therapy at a facility to help recover and get as close as possible to their pre-injury or pre-hospitalization functioning level. This is typically a covered benefit and can be a helpful environment to receive nursing and therapy care that a doctor oversees.',
    },
    {
      key: 'home-services',
      header: 'Services in the Home',
      description:
        'There are more and more services that can come to the home. It may be helpful to know what in-home services are available, especially if the individual you are caring for has difficulty being transported out of the home. For example, there are in-home primary care doctors and other specialists who may offer home visits. Ask your current providers if this is something they offer, or you can ask the care manager if they can help you find providers who can come to the home. You can typically receive nursing and other therapies in the home through a home health agency. If you have questions about care and you have a home health care agency in the home for nursing or therapy, be sure to ask if you can meet with a social worker. Social work is a covered benefit if you are receiving other services and can be very useful when you have questions or are having trouble navigating the individual’s care or daily needs.',
    },
  ],
};
