import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0px 10px 35px 20px;
  }
`;

export const StyledScreeningInfoContainer = styled.div`
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  padding-top: 23px;
`;
