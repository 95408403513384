import { ApiService } from 'Core_Helpers/ApiService';

export class ScreeningScheduleApiDataService {
  private static _instance: ScreeningScheduleApiDataService = new ScreeningScheduleApiDataService();
  private api: ApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (ScreeningScheduleApiDataService._instance) {
      return ScreeningScheduleApiDataService._instance;
    }
    this.api = new ApiService();
  }

  public getScreeningSchedule = async (request: { userId: number }) => {
    if (!request?.userId) return;
    const { response } = await this.api.get('/v1/screeningschedule/:userId', request);
    return response;
  };
}
