import styled from '@emotion/styled';
import { black, purple } from 'Styles/colors';
import Button from 'Stories/components/Button';
import { Typography } from '@mui/material';

export const ButtonLink = styled(Button)`
  font-weight: 700;
  color: ${black._87};
`;

export const ProfilePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
  overflow: hidden;
`;

export const ProfileFormContainer = styled.div`
  width: 100%;
  padding-top: 90px;
  @media screen and (max-width: 350px) {
    padding-top: 60px;
  }
`;

export const ProfileForm = styled.form`
  width: 100%;
  text-align: center;
`;

export const PageTitle = styled.h1`
  color: #4a4f7f;
  font-size: 28px;
  margin-top: 0;
`;

export const SupportText = styled.div`
  font-weight: bold;
  color: #2b7e3e;
  text-align: center;
  font-size: 16px;
  justify-self: center;
  align-self: center;
  line-height: 24px;
`;

export const SupportButtonContainer = styled.div``;

export const SupportButton = styled.div`
  display: flex;
  justify-content: center;
  color: #4a4f7f;
  width: 100%;
  height: 35px;
  border-radius: 25px;
  border-color: #4a4f7f;
  border-style: solid;
  justify-self: center;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const InputStyle = styled.input`
  background-color: ${(props) => (props.disabled ? purple._050 : '#ffffff')};
  border-radius: 10px;
  padding-left: 10px;
  height: 36px;
  border: 2px solid ${(props) => (props.hasError ? '#c92213' : '#0f145b')};
  color: #0f145b;
  margin-bottom: ${(props) => (props.hasError ? '0' : '10px')};
`;

export const SubmitButton = styled.button`
  background-color: ${(props) => (props.disabled ? '#979CCC' : '#4a4f7f')};
  color: white;
  width: 100%;
  height: 35px;
  border: 1px solid ${(props) => (props.disabled ? '#979CCC' : '#4a4f7f')};
  border-radius: 25px;
  margin: 30px auto 20px auto;
  font-size: 16px;
`;

export const Label = styled.label`
  color: #4a4f7f;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
  text-align: left;
`;

export const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 0 25px;
  margin-top: -30px;
`;

export const HeaderText = styled.h3`
  color: #4a4f7f;
  font-size: 28px;
  font-weight: normal;
  margin-top: 15px;
`;

export const ProfileSetting = styled.div`
  border: 1px solid #d0d0d0;
  background: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px 20px;
  border-radius: 10px;
`;

export const ErrorMessage = styled.span`
  margin: 0;
  color: #c92213;
  display: flex;
  flex-direction: column;
  min-width: 270px;
  text-align: left;
  margin-bottom: 10px;
`;

export const LogoutButton = styled.button`
  background-color: #fff;
  color: #4a4f7f;
  height: 35px;
  border-radius: 25px;
  border: 3px solid #4a4f7f;
  width: 100%;
  font-size: 16px;
`;

export const HeaderContainer = styled.div`
  margin: 15px 0 25px 0;
  padding: 0 0 10px 0;
  width: 100%;
  display: flex;
  column-gap: 2%;
  flex-direction: column;
  justify-content: center;
`;

export const InfoMessage = styled(Typography)`
  text-align: center;
  color: ${black._87};
  font-style: italic;
  font-size: 14px;
`;
