import styled from 'styled-components';

export const StartGameContainer = styled.div`
  text-align: center;
  font-family: karla;
  color: black;
  height: 100%;
  width: 100%;
`;

export const BannerSection = styled.div`
  background-color: #efebf5;
  height: 30%;
  width: 100%;
  margin: auto;
  display: block;
`;

export const BannerImage = styled.img`
  height: 100%;
  width: auto;
  max-width: 200px;
  max-height: 200px;
`;

export const GameTitleSection = styled.div`
  background-color: #9146b9;
  padding-left: 5%;
  display: flex;
  justify-content: left;
  height: 9%;
  color: white;
  box-shadow: 2px 5px 4px 0 rgba(0, 0, 0, 0.3);
`;

export const GameTitle = styled.h2`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
`;

export const DescriptionSection = styled.div`
  padding: 10px;
  height: auto;
  font-size: 18px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const DescriptionHeader = styled.h3`
  font-size: 20px;
  color: #9146b9;
  display: flex;
  justify-content: left;
  margin: 5% auto 10px 5%;
`;

export const Description = styled.p`
  display: flex;
  text-align: left;
  margin-left: 5%;
  line-height: 27px;
  margin-top: 0;
`;

export const DirectionsHeader = styled(Description)``;

export const DirectionStep = styled.li`
  display: list-item;
  text-align: left;
  margin-left: 5%;
  line-height: 27px;
  margin-top: 0;
  margin-bottom: 6px;
`;

export const StyledHaveFun = styled(Description)`
  font-weight: bold;
`;

export const StartGameButton = styled.button`
  background-color: #277f3b;
  color: white;
  border: none;
  border-radius: 15px;
  font-family: lato;
  font-weight: bold;
  font-size: 20px;
  padding: 10px 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto auto auto;
  width: 50%;
`;

export const ReturnHome = styled.button`
  margin: 10px;
  background-color: white;
  border: none;
  color: #277f3b;
  margin: 10px auto;
  font-size: 18px;
`;
