import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  DisclaimerContainer,
  Description,
  StyledTitle,
  StyledCloseIconContainer,
  PageContainer,
  StyledTitleContainer,
  ErrorMessage,
} from './styles';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from 'Stories/components/Button';
import { ACCOUNTDELETED, PROFILE } from 'Core_Pages/Routes/RoutesConfig';
import { useHistory } from 'react-router-dom';
import useDeleteMember from 'Core_Hooks/useDeleteMember';
import AuthService from 'Core_Helpers/authentication/AuthService';
import { logout, selectIsAuthenticated } from 'Core_Redux/authentication';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { displayLightStatusBar } from 'Core_Helpers/statusBar';

function DeleteDisclaimer() {
  const intl = useIntl();
  const dispatch = useCoreDispatch();
  const history = useHistory();
  const isAuthenticated = useCoreSelector(selectIsAuthenticated);

  const { error, isPending: deletePending, isComplete: deleteComplete, deleteMember } = useDeleteMember();

  const accountDeletedText = intl.formatMessage({ id: 'profile.delete-account.disclaimer' });

  const onClickContinueDelete = async () => {
    const authService = await AuthService.getInstance();
    if (!deletePending) {
      const user = await authService.getUserInfo();
      await deleteMember(user.sub);
    }
  };

  useEffect(() => {
    displayLightStatusBar();
  }, []);

  useEffect(() => {
    if (deleteComplete && !error) {
      dispatch(logout());
    }
  }, [deleteComplete]);

  useEffect(() => {
    if (!isAuthenticated && deleteComplete) {
      history.push(ACCOUNTDELETED);
    }
  }, [isAuthenticated]);

  const onClose = () => {
    history.push(PROFILE);
  };

  return (
    <PageContainer className="safe-area">
      <StyledTitleContainer>
        <StyledTitle id={`Account-Delete-Title`}>{intl.formatMessage({ id: 'profile.delete-account' })}</StyledTitle>
        <StyledCloseIconContainer aria-label={intl.formatMessage({ id: 'button.close' })} onClick={onClose}>
          <CloseIcon fontSize="inherit" fontWeight="inherit" />
        </StyledCloseIconContainer>
      </StyledTitleContainer>

      <DisclaimerContainer>
        <Description>{accountDeletedText}</Description>
        {error && <ErrorMessage>{intl.formatMessage({ id: 'profile.delete-account.delete-error' })}</ErrorMessage>}
      </DisclaimerContainer>

      <Button
        fullWidth
        id={`Delete_Button`}
        loading={deletePending}
        autoCapitalize="none"
        loadingIndicator={<CircularProgress color="inherit" thickness={3} size={37} />}
        onClick={onClickContinueDelete}
      >
        {intl.formatMessage({ id: 'common.delete' })}
      </Button>
    </PageContainer>
  );
}

export default DeleteDisclaimer;
