import styled from '@emotion/styled';
import { breakpoints } from 'Styles/theme';
import { LikertForm, LikertWrapper } from 'Core_Pages/Screening/ScreeningImplementations/styles';

export const StyledLikertWrapper = styled(LikertWrapper)(
  ({ theme }) => `
    ${theme.breakpoints.up(breakpoints.TABLET)} {
      h1 {
        font-size: 24px;
        width: 50%;
        margin: 0 auto;
      }
    }
  `,
);

export const StyledLikertForm = styled(LikertForm)(
  ({ theme }) => `
  width: 100%;
  padding: 20px 0px;
  max-width: 510px;

  &&& { 
  ${theme.breakpoints.up(breakpoints.TABLET)} {
      .FormQuestion-answer-card {
        font-size: 34px;
      }
    }
    ${theme.breakpoints.up(breakpoints.DESKTOP)} {
      .FormQuestion-answer-card {
        font-size: 24px;
      }
    }
  }

  ${theme.breakpoints.up(breakpoints.DESKTOP)} {
    max-width: 380px;
  }

  .FormQuestion-answer-unchecked,
  .FormQuestion-answer-checked {
    width: 56px;
    height: 46px;

    ${theme.breakpoints.up(breakpoints.TABLET)} {
      width: 96px;
      height: 78px;
    }

    ${theme.breakpoints.up(breakpoints.DESKTOP)} {
      width: 71px;
      height: 58px;
    }
  }

  .FormQuestion-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    padding-bottom: 10px;
    width: 95%;
    margin: 0 auto;
  }

  .FormQuestion-question {
    width: 100%;
    
    ${theme.breakpoints.up(breakpoints.TABLET)} {
      font-size: 24px;
    }
    ${theme.breakpoints.up(breakpoints.DESKTOP)} {
      font-size: 18px;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .FormQuestion-answer-group {
    display: flex;
    justify-content: space-between;
  }
`,
);

export const AnswerKey = styled.div(
  ({ theme }) => `
  background-color: rgba(0, 0, 0, 0.06);
  height: auto;
  min-height: 52px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  flex: 1 1 0;

  ${theme.breakpoints.up(breakpoints.TABLET)} {
    font-size: 24px;
    min-height: 72px;
  }

  ${theme.breakpoints.up(breakpoints.DESKTOP)} {
    font-size: 22px;
  }
`,
);

export const AnswerKeyWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  width: 95%;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 470px;

  ${theme.breakpoints.up(breakpoints.DESKTOP)} {
    max-width: 360px;
  }
`,
);

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
