import { ApiService } from 'Core_Helpers/ApiService';
import { retryRequest } from 'Core_Helpers/retryRequest';

export class ScreeningScoresApiDataService {
  private static _instance: ScreeningScoresApiDataService = new ScreeningScoresApiDataService();
  private api: ApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (ScreeningScoresApiDataService._instance) {
      return ScreeningScoresApiDataService._instance;
    }
    this.api = new ApiService();
  }

  public getScreeningScores = async (
    request: {
      scoreDateStartUtc?: string;
      scoreDateEndUtc?: string;
      screeningResponseGuids?: string;
      userId: number;
    },
    retries = 1,
  ) => {
    if (!request?.userId) return;
    const { response, hasError } = await retryRequest(
      () => this.api.get('/v1/screeningscore/search', request),
      retries,
    );
    return { response, hasError };
  };
}
