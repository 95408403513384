import styled from 'styled-components';

export const GreetingContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  background: #3d407d;
  box-shadow: 2px 5px 4px 0 rgba(0, 0, 0, 0.3);
  font-size: 20px;
  z-index: 1;
`;

export const PyxirGreetingImage = styled.div`
  margin-left: -11px;
`;

export const HiFriendImage = styled.div`
  margin-top: -30px;
`;

export const PyxirImageContainer = styled.div`
  display: grid;
  grid-template-columns: 111px auto;
  padding: 12px 0;
  line-height: 13px;
`;

export const StyledMessage = styled.h2`
  color: #fafafa;
  letter-spacing: 0.17px;
  line-height: 23px;
  font-size: 20px;
  margin-bottom: -3px;
`;

export const PyxirTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-right: 20px;
  margin-top: 30px;
`;
