import styled from 'styled-components';

export const Toggle = styled.div`
  display: block;
  width: 18px;
  height: 18px;
  background: white;
  position: absolute;
  margin-top: 2px;
  margin-right: ${(props) => (props.checked ? '0px' : '30px')};
  margin-left: ${(props) => (props.checked ? '30px' : '0px')};
  border: 2 solid white;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
`;

export const SwitchToggle = styled.button`
  width: 64px;
  height: 24px;
  text-transform: uppercase;
  border: 0 solid #bbb;
  border-radius: 20px;
  text-align: left;
  text-align: left;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in 0s;
  color: #fff;
  background-color: ${(props) => (props.checked ? '#4a4f7f' : '#bbb')};
`;
