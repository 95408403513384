import styled from '@emotion/styled';
import { Tabs, AppBar } from '@mui/material';
import { black, purple } from 'Styles/colors';
import { breakpoints } from 'Styles/theme';

export const SkipContent = styled.a`
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  margin: 0;
  clip: rect(1px, 1px, 1px, 1px);
  color: white;
  background-color: teal;

  &:focus {
    z-index: 1101;
    clip: auto;
    background-color: teal;
    color: white;
    font-weight: bold;
    width: auto;
    padding: 10px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    top: ${(props) => (props.isNative ? '46px' : '12px')};
    left: 4px;
    height: 48px;
  }
`;

export const StartOfContent = styled.div`
  display: flex;
  overflow: auto !important;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const StyledOverlay = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
`;

export const PageWrapper = styled.div(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up(breakpoints.DESKTOP)} {
    flex-direction: row;
  }
`,
);

export const StyledAppBar = styled(AppBar)(
  ({ theme }) => `
  position: relative;
  background-color: #ffffff;
  border-bottom: 1px solid #979797;

${theme.breakpoints.up(breakpoints.DESKTOP)} {
  width: fit-content;
  height: 100%;
  left: 0;
}
`,
);

export const NavBar = styled(Tabs)(
  ({ theme }) => `
  color: ${purple._900};

  ${theme.breakpoints.up(breakpoints.DESKTOP)} {
    align-items: center;
    width: fit-content;
    flex-direction: column;
    height: 100%;
    margin-top: 110px;

    .MuiTabs-flexContainer {
      align-items: center;
    }
  }


  .MuiTabs-root {
    padding: 0px;
  }

  .MuiTab-root {
    font-size: 12px;
    padding: 0px 6px;
    font-weight: 600;

    ${theme.breakpoints.up(breakpoints.DESKTOP)} {
      padding: 50px 10px
    }

    min-width: min-content;
    width: 24px;

    .MuiTab-iconWrapper {
      font-size: 36px;
      margin: 0 0 1px 0;
    }

    .MuiIcon-root {
      margin: 0 0 1px 0;
      height: fit-content;
      width: fit-content;
      font-size: 30px;

      img {
        max-width: 50px;
      }
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  &[aria-selected='false'] {
    color: ${black._60};
  }
`,
);
