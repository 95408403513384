import React from 'react';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import {
  HeaderContainer,
  StyledDialogTitle,
  IconContainer,
  ContentContainer,
  ContentText,
  HeaderText,
  StyledDialog,
} from './styles';
import GreetingPyxir from 'Assets/img/greeting-pyxir.svg';
import Button from 'Stories/components/Button';
import { useHistory } from 'react-router-dom';
import { CHECKINS } from 'Core_Pages/Routes/RoutesConfig';
import { WhatsNewModalModuleStore } from './WhatsNewModalModuleStore';

function WhatsNew() {
  const intl = useIntl();
  const history = useHistory();

  const modalStore = WhatsNewModalModuleStore.Instance().Use();
  const isOpen = modalStore.IsOpen;

  function onClose() {
    modalStore.acknowledge();
    modalStore.close();
  }

  function onClick() {
    onClose();
    history.push(CHECKINS);
  }

  return (
    <>
      <StyledDialog open={isOpen}>
        <HeaderContainer>
          <img
            src={GreetingPyxir}
            alt={intl.formatMessage({ id: 'image.pyxir.portrait' })}
            style={{ width: '50px', margin: '10px' }}
          />
          <StyledDialogTitle>{intl.formatMessage({ id: 'dialog.what-is-new.screenings.title' })}</StyledDialogTitle>
          <IconContainer>
            <CloseIcon
              role="button"
              aria-label={intl.formatMessage({ id: 'button.close' })}
              onClick={onClose}
              fontSize="large"
              sx={{ margin: '5px' }}
            />
          </IconContainer>
        </HeaderContainer>
        <ContentContainer>
          <HeaderText>{intl.formatMessage({ id: 'dialog.what-is-new.screenings.overview' })}</HeaderText>
          <ContentText>
            {intl.formatMessage(
              { id: 'dialog.what-is-new.screenings.description' },
              { b: (item) => <strong>{item}</strong> },
            )}
          </ContentText>
          <Button onClick={onClick}>{intl.formatMessage({ id: 'dialog.what-is-new.screenings.button' })}</Button>
        </ContentContainer>
      </StyledDialog>
    </>
  );
}

export default WhatsNew;
