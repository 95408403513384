import React from 'react';
import PropTypes from 'prop-types';
import { StyledHiddenRadio } from './styles';

function RadioOptions({ choices, ChoiceComponent, selectChoice, selectedChoice }) {
  if (choices?.length === 0) return null;

  const handleChange = (e) => selectChoice(e.target.value);

  const handleFocus = (e) => {
    e.target.parentNode.scrollTop = e.target.offsetTop - e.target.parentNode.offsetTop;
  };

  return (
    <>
      {choices.map((choice, i) => {
        const isChecked = choice === selectedChoice;
        return (
          <React.Fragment key={choice}>
            <StyledHiddenRadio
              id={i}
              data-testid={`Choice_${i}`}
              type="radio"
              name="choice"
              value={choice}
              checked={isChecked}
              onChange={handleChange}
              onFocus={handleFocus}
            />
            <ChoiceComponent className={isChecked ? 'checked' : ''} htmlFor={i}>
              {choice}
            </ChoiceComponent>
          </React.Fragment>
        );
      })}
    </>
  );
}

RadioOptions.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.string).isRequired,
  ChoiceComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  selectChoice: PropTypes.func.isRequired,
  selectedChoice: PropTypes.string,
};

export default RadioOptions;
