import { useEffect } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { useIonRouter } from '@ionic/react';
import { appInsights } from 'Core_Helpers/AppInsights';

const useHardwareBackButton = () => {
  const ionRouter = useIonRouter();
  useEffect(() => {
    CapacitorApp.addListener('backButton', async () => {
      if (!ionRouter.canGoBack()) {
        appInsights.trackEvent({ name: 'App_Closed' });
        CapacitorApp.exitApp();
      }
    });
  }, []);
};

export default useHardwareBackButton;
