import styled from '@emotion/styled';

export const StyledActionIconContainer = styled.button`
  position: absolute;
  padding: 0;
  top: 12px;
  right: 12px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  text-align: center;
  background: #e6e8ec;
  span {
    color: black;
    font-size: 24px;
  }
`;
