import React, { useState } from 'react';
import { StyledHeader, ExploreContainer, EndSessionContainer } from './styles';
import { Grid, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EndSessionConfirmationModal from './Modals/EndSessionConfirmationModal';
import { DirectMessageStore } from '../Store/DirectMessageStore';
import { SessionStatus } from '../Models/SessionStatus';
import { useIntl } from 'react-intl';

const DirectMessageHeader = () => {
  const intl = useIntl();

  const directMessageStore = DirectMessageStore.Instance().Use();
  const sessionStatus = directMessageStore.SessionStatus;

  const [isEndSessionModalOpen, setIsEndSessionModalOpen] = useState(false);

  const onClickHome = () => {
    directMessageStore.HideSession();
  };

  const onClickEndSession = () => {
    if (sessionStatus == SessionStatus.CONNECTED) {
      setIsEndSessionModalOpen(true);
    }
  };

  return (
    <StyledHeader className="safe-area">
      <EndSessionConfirmationModal
        open={isEndSessionModalOpen}
        onCloseCallback={() => setIsEndSessionModalOpen(false)}
      />
      <Grid container columnSpacing={1} alignItems="center" minHeight={60} margin="4px 0px">
        <Grid item mobile={2}>
          <ExploreContainer onClick={onClickHome}>
            <HomeOutlinedIcon fontSize="large" />
            <Typography fontSize={12} lineHeight={0}>
              {intl.formatMessage({ id: 'direct-message.explore' })}
            </Typography>
          </ExploreContainer>
        </Grid>
        <Grid item mobile={6}>
          <Typography fontSize={22}>{intl.formatMessage({ id: 'direct-message.live-chat' })}</Typography>
        </Grid>
        <Grid item mobile={4}>
          {sessionStatus == SessionStatus.CONNECTED && (
            <EndSessionContainer>
              <Typography onClick={onClickEndSession} fontSize={16} lineHeight={1}>
                {intl.formatMessage({ id: 'direct-message.end-session' })}
              </Typography>
            </EndSessionContainer>
          )}
        </Grid>
      </Grid>
    </StyledHeader>
  );
};

export default DirectMessageHeader;
