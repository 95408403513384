import { ApiService } from 'Core_Helpers/ApiService';
import { retryRequest } from 'Core_Helpers/retryRequest';
export class GetUserResourcesApiDataService {
  private static _instance: GetUserResourcesApiDataService = new GetUserResourcesApiDataService();
  private api: ApiService;
  constructor() {
    // Make sure we don't create more than one instance of this service
    if (GetUserResourcesApiDataService._instance) {
      return GetUserResourcesApiDataService._instance;
    }
    this.api = new ApiService();
  }
  public getUserResources = async (userId, retries = 1) => {
    if (!userId) return;

    const { response, hasError } = await retryRequest(
      () => this.api.get(`/v1/userresources/${userId}/Pyx.App`, {}),
      retries,
    );

    return { response, hasError };
  };
}
