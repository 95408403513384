import React from 'react';
import { Typography } from '@mui/material';
import { SelectableCard, StyledDivider, Icon } from './styles';
import PropTypes from 'prop-types';
import { teal, black } from 'Styles/colors';

const ToggleCard = ({ label, selected, children }) => {
  return (
    <SelectableCard
      raised
      selected={selected}
      sx={{ backgroundColor: selected ? teal._700 : '#FFFFFF', color: selected ? '#FFFFFF' : black._87 }}
    >
      <Icon>{children}</Icon>
      <StyledDivider sx={{ backgroundColor: selected ? '#FFFFFF' : '#979797' }} />
      <Typography fontSize={16} align="center">
        {label}
      </Typography>
    </SelectableCard>
  );
};

ToggleCard.displayName = 'ToggleCard';

ToggleCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.element]),
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

export default ToggleCard;
