import { useState } from 'react';
import urlcat from 'urlcat';
import getAuthClient from 'Core_Helpers/getAuthClient';

const interactionUrl = 'v1.0/PositivePsychology/:userId/Interaction';

const useLogPsychologyContent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const logContentInteraction = async (contentId, userId) => {
    setIsLoading(true);

    if (contentId <= 0) {
      setIsLoading(false);
      return;
    }

    const authClient = await getAuthClient();
    const LOG_INTERACTION_URI = urlcat(process.env.API_BASE_URL, interactionUrl, {
      userId,
    });

    try {
      let result = await authClient.post(LOG_INTERACTION_URI, {
        positivePsychologyContentId: contentId,
      });
      if (result) {
        setIsLoading(false);
      }
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      throw e;
    }
  };

  return {
    isError,
    isLoading,
    logContentInteraction,
  };
};

export default useLogPsychologyContent;
