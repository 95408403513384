import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';

const SegmentTooltipWrapper = ({ className, title, children, ...props }) => {
  return (
    <Tooltip classes={{ tooltip: className }} title={title} {...props}>
      {children}
    </Tooltip>
  );
};

SegmentTooltipWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default styled(SegmentTooltipWrapper)`
  margin-bottom: -30% !important;
  background: none;
`;
