import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isEnvironment, QA } from 'Core_Helpers/isEnvironment';

const Header = ({ title }) => {
  return (
    <Helmet>
      <title data-testid="Title">{`${title} - Pyx Health`}</title>
      {isEnvironment(QA) ? <script src="https://cdn.levelaccess.net/accessjs/YW1wMTI4ODI/access.js" /> : ''}
    </Helmet>
  );
};
Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
