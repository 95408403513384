import { memoryImpairments } from 'Assets/img/my-tools-icons';

export const memoryLossEs = {
  key: 'memory-loss-es',
  title: 'Involucrar a personas con pérdida de memoria',
  titleImage: memoryImpairments,
  titleImageAlt: '',
  contentId: 25,
  sections: [
    {
      key: 'activities-to-engage-es',
      description:
        'Si estás leyendo este artículo, es probable que alguien cercano en tu vida padezca de pérdida de memoria, problemas cognitivos o un diagnóstico de demencia o Alzheimer. Este diagnóstico suele ser complicado y, a medida que la enfermedad avanza, por lo general termina afectándote más a ti que a la persona con limitaciones cognitivas. Estas son algunas de las actividades que puedes realizar durante las diferentes etapas de la enfermedad y que pueden ayudarte a lograr una mejor conexión con esta persona o a crear interacciones más agradables a lo largo de cada día.',
    },
    {
      key: 'finding-interest-es',
      header: 'Encontrar actividades que sean de interés',
      description:
        'Puede ser desafiante encontrar actividades que llenen el día a día para alguien con limitaciones cognitivas. A medida que la enfermedad avanza, la persona con pérdida de memoria suele tener menos capacidad de atención o una mayor dificultad para mantener su concentración en una tarea o actividad. Para involucrar a alguien con pérdida de memoria, puede ser importante encontrar tareas que mantengan su atención el mayor tiempo posible. Para ello tienes que buscar actividades que hayan sido su pasión en el pasado. Por ejemplo, si estás cuidando a una persona que era mecánico automotriz, encuentra algunas piezas viejas de automóviles que pueda "arreglar". Si trabajó en construcción, busca a alguien que pueda fabricar una tabla lisa y lijada con algunos agujeros semiperforados (por seguridad) para que construya algo usando un martillo o un desarmador. Si le encantaba coser o hacer punto de cruz, busca algunas manualidades que se puedan hacer con agujas más grandes y menos afiladas. A medida que la enfermedad avanza, los artículos (e incluso juguetes) que son similares a los que solían amar pueden convertirse en actividades que disfruten una y otra vez. Se pueden comprar artículos como mantas o materiales con múltiples actividades, como botones, cremalleras u otros accesorios. Estas actividades no solo son excelentes para disfrutar, sino que también ayudan a mantener las habilidades de motricidad fina y trabajar en ellas.',
    },
    {
      key: 'memory-interests-music-es',
      header: 'Música',
      description:
        'La música puede ser una excelente manera de ayudar a recordar y, a menudo, es una de las últimas partes del cerebro que se ven afectadas por la enfermedad. La música que la persona tocaba, cantaba o escuchaba cuando era más joven a menudo puede traer buenos recuerdos. Puede resultar sorprendente que al cantar canciones que son familiares para ellos, incluso para quienes están en etapas avanzadas de la enfermedad, pueden recordar melodías, palabras y el movimiento de las manos al tocar un instrumento. También existen muchas aplicaciones de música gratuitas que puedes usar para buscar canciones por artista, año o género.',
    },
    {
      key: 'memory-interests-games-es',
      header: 'Juegos',
      description:
        '¿Sabías que los juegos pueden ayudar a retener recuerdos y a retrasar la progresión de las enfermedades de deterioro cognitivo? Hay muchos tipos de juegos que ayudan con los aspectos cognitivos. Comienza con juegos que la persona haya disfrutado anteriormente. De esta manera, podrá usar su memoria a largo plazo para recordar cómo jugar en lugar de tratar de enseñarle un nuevo juego. Los juegos de cartas o de mesa pueden ser muy buenos, así como cualquier tipo de juego con fichas que necesiten emparejarse o secuenciarse. Existen juegos de números y secuencias que están hechos para jóvenes o que salieron al mercado con otra marca para dirigirlos a esta población mayor. Los juegos de tipo trivia pueden ser divertidos y fomentan el uso de la memoria a largo y corto plazo.',
    },
    {
      key: 'memory-interests-get-outside-es',
      header: 'Pasar un tiempo al aire libre',
      description:
        'La luz del sol y el aire fresco pueden mejorar mucho nuestro bienestar emocional. Estas son algunas de las actividades que pueden hacer juntos al aire libre:',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Salgan a caminar (o empuja la silla de ruedas de la persona a la que cuidas) por un hermoso sendero en un área natural, un parque o alrededor de tu vecindario.',
        },
        {
          text: 'Pueden disfrutar los espacios de jardinería de suelo elevado por horas al trabajar en la tierra y ver cómo crecen los vegetales o las flores. Se pueden fabricar o comprar canteros de diferentes tipos y alturas que son ideales para jardinería mientras la persona está sentada en una silla de ruedas o en una silla normal. Esta puede ser una gran actividad para quienes disfrutaron de trabajar con sus manos.',
        },
        {
          text: 'Hagan un viaje para ver un evento. Ir a un evento deportivo, una obra de teatro, un concierto musical o una exhibición de arte puede ayudar a recordar y ser una actividad agradable tanto para ti como para la persona a la que estás cuidando. Intenta asistir a eventos más pequeños que sean menos estimulantes o abrumadores, como un partido de fútbol para niños o una feria de arte local.',
        },
      ],
    },
    {
      key: 'memory-outro-es',
      description:
        'Las actividades al aire libre pueden ser desafiantes dependiendo de la movilidad de la persona a la que estás cuidando. Por ejemplo, busca o averigua sobre las opciones de transporte que pueden llevar sillas de ruedas usando un elevador. También puede ser útil abrir las ventanas en un día agradable o colocar la silla o la cama de la persona junto a una ventana abierta.',
    },
  ],
};
