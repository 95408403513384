import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { appInsights } from 'Core_Helpers/AppInsights';
import PropTypes from 'prop-types';
import { Tab } from '@mui/material';
import { MENU } from 'Core_Pages/Routes/RoutesConfig';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';

const ResourcesNavbarItem = ({ onClick, ...props }) => {
  const history = useHistory();
  const intl = useIntl();

  const { selected } = props;

  const onNavItemClick = (event) => {
    onClick(event);
    history.push(MENU);
    appInsights.trackEvent({ name: 'Resources_Clicked' });
  };

  const Icon = selected ? MedicalServicesIcon : MedicalServicesOutlinedIcon;

  return (
    <>
      <Tab
        {...props}
        icon={<Icon aria-hidden="true" />}
        label={intl.formatMessage({ id: 'menu-header.resources' })}
        href={MENU}
        aria-current={selected}
        onClick={onNavItemClick}
      />
    </>
  );
};

ResourcesNavbarItem.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default ResourcesNavbarItem;
