import { Route, Switch, useHistory } from 'react-router-dom';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import React, { useEffect, useState } from 'react';
import PetsWelcome from 'Core_Pages/Pets/AdoptionCenter/PetsWelcome/PetsWelcome';
import { StyledGamePetsContainer, StyledPetsContainer } from 'Core_Pages/Pets/featureStyles';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectSyncUser, selectUserInfo } from 'Core_Redux/user';
import AdoptionCenter from 'Core_Pages/Pets/AdoptionCenter/AdoptionCenter';
import DogAdoption from 'Core_Pages/Pets/AdoptionCenter/DogAdoption/DogAdoption';
import PetsHello from 'Core_Pages/Pets/AdoptionCenter/PetsHello/PetsHello';
import CatAdoption from 'Core_Pages/Pets/AdoptionCenter/CatAdoption/CatAdoption';
import PetsVisitDaily from 'Core_Pages/Pets/AdoptionCenter/PetsVisitDaily/PetsVisitDaily';
import PetsGame from 'Core_Pages/Pets/PetsGame/PetsGame';
import useGetPet from 'Core_Hooks/useGetPet';
import Spinner from 'Core_Components/Spinner/Spinner';
import PetsHome from 'Core_Pages/Pets/PetsHome/PetsHome';

function Pets() {
  const history = useHistory();
  const syncUser = useCoreSelector(selectSyncUser);
  const profile = useCoreSelector(selectUserInfo);
  const [justAdopted, setJustAdopted] = useState();
  const { isLoading, getPetForUser } = useGetPet();
  const [pet, setPet] = useState({
    petType: '',
    name: '',
  });

  useEffect(() => {
    if (!justAdopted) {
      getPetForUser(profile?.sub).then(
        (response) => {
          if (!response?.data) {
            history.replace(paths.PETSWELCOME);
          } else {
            if (!response.data?.shownWelcomeOnUtc) {
              history.replace(paths.PETSHELLO);
            }
            setPet(response.data);
            if (history.location.pathname === paths.PETS) {
              history.replace(paths.ACTIVITYMENU);
            }
          }
        },
        () => {
          history.replace(paths.PETSWELCOME);
        },
      );
    }
  }, []);

  if (isLoading || !profile || !syncUser) {
    return <Spinner />;
  }

  return (
    <Switch>
      <Route exact path={paths.PETSWELCOME}>
        <StyledPetsContainer data-testid={'Pets'}>
          <PetsWelcome />
        </StyledPetsContainer>
      </Route>
      <Route exact path={paths.ADOPTIONCENTER}>
        <StyledPetsContainer data-testid={'Pets'}>
          <AdoptionCenter pet={pet} setPet={setPet} userFirstName={syncUser?.firstName} />
        </StyledPetsContainer>
      </Route>
      <Route exact path={paths.DOGADOPTION}>
        <StyledPetsContainer data-testid={'Pets'}>
          <DogAdoption setJustAdopted={setJustAdopted} pet={pet} setPet={setPet} />
        </StyledPetsContainer>
      </Route>
      <Route exact path={paths.CATADOPTION}>
        <StyledPetsContainer data-testid={'Pets'}>
          <CatAdoption setJustAdopted={setJustAdopted} pet={pet} setPet={setPet} />
        </StyledPetsContainer>
      </Route>
      <Route exact path={paths.PETSHELLO}>
        <StyledPetsContainer data-testid={'Pets'}>
          <PetsHello pet={pet} userFirstName={syncUser?.firstName} />
        </StyledPetsContainer>
      </Route>
      <Route exact path={paths.PETSVISITDAILY}>
        <StyledPetsContainer data-testid={'Pets'}>
          <PetsVisitDaily pet={pet} />
        </StyledPetsContainer>
      </Route>
      <Route exact path={paths.PETSHOME}>
        <StyledPetsContainer data-testid={'Pets'}>
          <PetsHome pet={pet} />
        </StyledPetsContainer>
      </Route>
      <Route exact path={paths.PETSGAME}>
        <StyledGamePetsContainer data-testid={'Pets'}>
          {pet?.petId && profile ? (
            <PetsGame pet={pet} syncUser={syncUser} history={history} userId={+profile?.sub} />
          ) : null}
        </StyledGamePetsContainer>
      </Route>
    </Switch>
  );
}

export default Pets;
