import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Core_Helpers/getAuthClient';
import urlcat from 'urlcat';

const feedUrl = '/v5/chat/get-feed/:encounterHistoryId';
const RETRY_DELAY = 3000;
const RETRY_COUNT = 3;
const options = {
  revalidateOnFocus: false,
};

const getFeed = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useFeed = (encounter, swrOptions) => {
  const [isPending, setIsPending] = useState(true);
  const [feed, setFeed] = useState();

  const {
    data: feedResult,
    error,
    isValidating,
    mutate,
  } = useSWR(
    () => urlcat(process.env.API_BASE_URL, feedUrl, { encounterHistoryId: encounter.encounterHistoryId }),
    getFeed,
    {
      ...options,
      ...swrOptions,
      onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
        if (retryCount > RETRY_COUNT || error.status === 404) return;
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), RETRY_DELAY);
      },
    },
  );

  useEffect(() => {
    if (feedResult) {
      setFeed(feedResult.data);
    }
    setIsPending(!feedResult || (!!error && !isValidating));
  }, [feedResult, error]);

  const resetFeed = () => {
    setFeed();
    setIsPending(false);
  };

  const updateFeed = (lastAnswer, nextMessage) => {
    if (lastAnswer && nextMessage && feed) {
      let mutableFeed = [...feed];
      mutableFeed[mutableFeed.length - 1] = lastAnswer;
      mutate({ data: [...mutableFeed, ...nextMessage] }, false);
    } else {
      mutate();
    }
  };

  return { feed, error, isPending, updateFeed, resetFeed };
};

export default useFeed;
