import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { black } from 'Styles/colors';

export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: #048600;
  font-size: 95px;
  margin-bottom: 12px;
  text-align: center;
  width: 100%;
  margin-top: 125px;
`;

export const SuccessfulDeleteContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
`;

export const Description = styled.div`
  color: ${black._100};
  font-size: 18px;
  width: 85%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 24px;
  max-width: 200px;
`;
