import styled from 'styled-components';

export const StyledHeader = styled.h1`
  text-align: center;
  font-size: 20px;
  padding: 0 10px;
  align-self: ${(props) => props.alignSelf ?? 'center'};
  color: ${(props) => props.color ?? '#114a9d'};
  margin-top: ${(props) => props.marginTop ?? '45px'};
  font-weight: ${(props) => props.fontWeight ?? 'bold'};

  @media screen and (max-height: 525px) {
    margin-top: 10px;
  }
`;
