import React, { useEffect, useContext } from 'react';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import {
  selectStep,
  startWelcomeBackPhoneFlow,
  selectSignupStatus,
  startWelcomeBackOnboardingFlow,
  selectSignupMetadata,
  signupStatuses,
} from 'Onboard_Redux/signup';
import { stepToComponentMap } from 'Onboard_Components/Account/SignUp/steps';
import Layout from 'Onboard_Pages/Account/Layout';
import Toggle from 'Onboard_Components/Account/Toggle';
import { useHistory } from 'react-router-dom';
import { selectSyncUser, selectUserInfo, selectUserVerified } from 'Onboard_Redux/user';
import {
  selectIsAuthenticated,
  selectAuthenticationStatus,
  authenticationStatuses,
} from 'Onboard_Redux/authentication';
import { isNative } from 'Onboard_Helpers/isNative';
import { SplashScreen } from '@capacitor/splash-screen';
import { LOGIN } from 'Onboard_Pages/Routes/RoutesConfig';
import { ExperienceContext } from 'Contexts/ExperienceContext';

function SignUp() {
  const history = useHistory();
  const currentStep = useOnboardSelector(selectStep);
  const { progress, initialStep } = useOnboardSelector(selectSignupMetadata);
  const Step = stepToComponentMap[currentStep];
  const dispatch = useOnboardDispatch();
  const user = useOnboardSelector(selectUserInfo);
  const syncUser = useOnboardSelector(selectSyncUser);
  const { status } = useOnboardSelector(selectSignupStatus);
  const userVerified = useOnboardSelector(selectUserVerified);
  const authenticationStatus = useOnboardSelector(selectAuthenticationStatus);
  const experience = useContext(ExperienceContext);

  const isLoggedIn = useOnboardSelector(selectIsAuthenticated);

  useEffect(() => {
    if (isNative()) {
      SplashScreen.hide();
    }
  }, []);

  useEffect(() => {
    // Don't redirect to home if user isn't verified and signup isn't completed
    if (
      isLoggedIn &&
      userVerified &&
      syncUser?.activeProfileId &&
      (status === signupStatuses.COMPLETED || progress < 1)
    ) {
      experience.experienceUpdatedAsync();
    }
  }, [isLoggedIn, status, syncUser]);

  useEffect(() => {
    // In case user closed the app and signup has reset, redirect to welcome back flow
    const requiresAuthenticationRedirection = [
      authenticationStatuses.AUTHENTICATED_REQUIRES_CONFIRMATION,
      authenticationStatuses.AUTHENTICATED_REQUIRES_ONBOARD,
    ].includes(authenticationStatus);

    if (
      (isLoggedIn || requiresAuthenticationRedirection) &&
      (progress < 1 || currentStep === initialStep) &&
      user &&
      syncUser?.activeProfileId == null
    ) {
      if (userVerified || authenticationStatus === authenticationStatuses.AUTHENTICATED_REQUIRES_ONBOARD) {
        dispatch(startWelcomeBackOnboardingFlow(user));
      } else if (
        userVerified === false ||
        authenticationStatus === authenticationStatuses.AUTHENTICATED_REQUIRES_CONFIRMATION
      ) {
        dispatch(startWelcomeBackPhoneFlow(user));
      }
    }
  }, [isLoggedIn, userVerified]);

  function onToggle() {
    history.push(LOGIN);
  }

  return (
    <Layout>
      <Toggle selectedOption={Toggle.states.SIGNUP} onToggle={onToggle} />
      <Step />
    </Layout>
  );
}

export default SignUp;
