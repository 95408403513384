import { knowingWhenYouNeedHelp } from 'Assets/img/my-tools-icons';

export const knowingWhenYouNeedHelpGuardianEn = {
  key: 'knowing-when-you-need-help-guardian',
  title: 'Knowing When You Need Help',
  titleImage: knowingWhenYouNeedHelp,
  titleImageAlt: '',
  contentId: 48,
  sections: [
    {
      key: 'knowing-when-you-need-help-intro',
      description:
        'How do you know when you need help? After giving of your time, energy, and emotional support to others, it can be difficult to know when it’s time to reach out for help for yourself. When there are so many emotions around day-to-day life, it is completely normal to experience a range of feelings such as feeling sad or nervous. Here a few general guidelines to know when to reach out for help.',
    },
    {
      key: 'sign-of-help',
      header: 'Sure Signs That You Need Help',
      description:
        'If you have had thoughts of wanting to hurt yourself or someone else, you feel like life may not be worth living, sleeping too much or little energy, or you find little interest or pleasure in doing things – these are all sure signs that you should reach out for help.',
    },
    {
      key: 'trust-gut',
      header: 'Trust Your Gut',
      description:
        'When you think back on your last few months, have your emotions felt different or off? For example, are there multiple days where you do not feel motivated? Do you feel angry without a good explanation? These may be a sign that there are underlying feelings that you need to work through.',
    },
    {
      key: 'family-friends',
      header: 'Family or Friends Say Something',
      description:
        'Even when you feel like you can manage your emotions, those closest to you know when you may be struggling. Trust them. Sometimes frequent outbursts of anger or unexplained sad emotions let our loved ones know that you need help.',
    },
    {
      key: 'feeling-sad',
      header: 'Feeling Sad',
      description:
        'When something in your life causes disappointment or pain, feelings of sadness are completely normal. When those feelings do not begin to resolve over time and you start feeling more hopeless or have lost interest in enjoyable activities or tasks, this is where there can be a shift to depression. Depression is a mental condition that can improve with the right help. If you have daily (or almost daily) feelings of unexplained sadness or hopelessness, have lost pleasure in typically enjoyable activities, or are tired more often than normal – these are all feelings that are not normal, and you should reach out for help.',
    },
    {
      key: 'feeling-nervous',
      header: 'Feeling Nervous',
      description:
        'There are many reasons to feel nervous. Sometimes if we are bothered by a relationship or something going on in our lives, we tend to think about it a lot and perhaps worry about it. While feeling worried or nervous can be normal, it is not normal when you start to have physical symptoms like your stomach or head hurts, or you feel like your heart is racing. Daily (or almost daily) feelings of nervousness or worrisome thoughts that do not go away can be a symptom of anxiety. Having anxiety is super common and can get better with help! If you think you may be having symptoms of anxiety, you should reach out for help.',
    },
    {
      key: 'reach-out-for-help',
      header: 'Reaching out for Help',
      description:
        'First of all, kudos to you for taking the first step to feeling better! Next, do you have a doctor? If yes, your doctor can likely offer you some great referrals for mental health therapists that can help you process through your emotional needs. Second, do you have health insurance? You can reach out to your health insurance company such as by calling the member services number on your card and looking online or in your patient portal. We have resources in your area in the resource tab on our home screen as well. But most importantly, speak up and reach out to make sure you get support!',
    },
  ],
};
