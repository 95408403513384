export const Config = {
  KEY: 'movement',
  ANIMATIONS: {
    RUN: {
      frames: [29, 30, 31, 32, 33, 34, 35, 36],
      frameRate: 16,
      repeat: -1,
    },
    RUNAWAY: {
      frames: [142, 143, 144, 145, 146, 147, 148, 149],
      frameRate: 16,
      repeat: -1,
    },
    RUNTOWARDS: {
      frames: [134, 135, 136, 137, 138, 139, 140, 141],
      frameRate: 16,
      repeat: -1,
    },
    BOUND: {
      frames: [7, 8, 9, 10, 11, 12],
      frameRate: 16,
      repeat: -1,
    },
    HALFTURN: {
      frames: [2, 1],
      frameRate: 12,
      repeat: 0,
    },
    FRONTTURN_HALF: {
      frames: [1, 0],
      frameRate: 8,
      repeat: 0,
      yoyo: true,
      flipOnYoyo: true,
    },
    TURN: {
      frames: [2, 1, 0],
      frameRate: 12,
      repeat: 0,
      yoyo: true,
      flipOnYoyo: true,
    },
    BACKTURN: {
      frames: [3, 4, 5, 6],
      frameRate: 12,
      repeat: 0,
      yoyo: true,
      flipOnYoyo: true,
    },
    BACKTURN_HALF: {
      frames: [5, 6],
      frameRate: 12,
      repeat: 0,
      yoyo: true,
      flipOnYoyo: true,
    },
    BACKTURN_PARTIAL: {
      frames: [5, 4, 3],
      frameRate: 12,
      repeat: 0,
      flipOnYoyo: true,
    },
    TURN_TO_ANGLE: {
      frames: [2, 3, 4, 5],
      frameRate: 8,
      repeat: 0,
    },
    IDLE: {
      frames: [37, 38, 39, 40],
      frameRate: 16,
      repeat: 2,
      yoyo: true,
    },
    IDLE2: {
      frames: [58, 59, 60, 61],
      frameRate: 8,
      repeat: 0,
    },
    IDLE3: {
      frames: [45],
      frameRate: 12,
      repeat: 0,
    },
    IDLE4: {
      frames: [54],
      frameRate: 12,
      repeat: 0,
    },
    IDLE5: {
      frames: [62],
      frameRate: 12,
      repeat: 0,
    },
    IDLE6: {
      frames: [87],
      frameRate: 12,
      repeat: 0,
    },
    SILLY: {
      frames: [54, 55, 56, 57],
      frameRate: 12,
      repeat: 0,
    },
    LAY: {
      frames: [41, 42, 43, 44],
      frameRate: 16,
      repeat: 0,
    },
    SIT: {
      frames: [26, 27, 28],
      frameRate: 16,
      repeat: 0,
    },
    JUMP: {
      frames: [18, 19, 20, 21],
      frameRate: 16,
      repeat: 0,
    },
    LAND: {
      frames: [21, 22, 23, 24, 25],
      frameRate: 12,
      repeat: 0,
    },
    WALK: {
      frames: [13, 14, 15, 16, 17],
      frameRate: 8,
      repeat: -1,
    },
    WALK_FRONT: {
      frames: [115, 116, 117],
      frameRate: 8,
      repeat: -1,
    },
    WALK_FRONT_ANGLE: {
      frames: [121, 122, 123, 124, 125],
      frameRate: 8,
      repeat: -1,
    },
    WALK_BACK_ANGLE: {
      frames: [126, 127, 128, 129, 130],
      frameRate: 8,
      repeat: -1,
    },
    WALK2: {
      frames: [58, 59, 60],
      frameRate: 4,
      repeat: -1,
    },
    NUDGE: {
      frames: [82, 83, 84, 85, 86],
      frameRate: 8,
      repeat: 0,
    },
    OBJECT_INTERACT: {
      frames: [82, 83, 86],
      frameRate: 8,
      repeat: 0,
    },
    OBJECT_INTERACT1: {
      frames: [18, 19, 20, 21],
      frameRate: 6,
      repeat: 0,
    },
    OBJECT_INTERACT2: {
      frames: [131, 132, 133],
      frameRate: 8,
      repeat: 0,
    },
    HUNGRY: {
      frames: [79, 80, 81],
      frameRate: 14,
      repeat: 0,
    },
    THIRSTY: {
      frames: [76, 77, 78],
      frameRate: 14,
      repeat: 0,
    },
    SITBENDEAT: {
      frames: [66, 67, 68],
      frameRate: 14,
      repeat: 0,
    },
    SITBENDDRINK: {
      frames: [62, 63],
      frameRate: 14,
      repeat: 0,
    },
    SITBENDDRINKFINISH: {
      frames: [63, 62],
      frameRate: 14,
      repeat: 0,
    },
    SITBENDEATFINISH: {
      frames: [71, 68, 67, 66],
      frameRate: 14,
      repeat: 0,
    },
    WATCH: {
      frames: [72, 73, 74, 75],
      frameRate: 14,
      repeat: -1,
      yoyo: true,
    },
    EAT: {
      frames: [69, 70],
      frameRate: 12,
      repeat: -1,
    },
    DRINK: {
      frames: [64, 65],
      frameRate: 12,
      repeat: -1,
    },
    POSE_HAPPY: {
      frames: [93],
      frameRate: 12,
      repeat: 0,
    },
    POSE_PROUD: {
      frames: [92],
      frameRate: 12,
      repeat: 0,
    },
    POSE_SAD: {
      frames: [94],
      frameRate: 12,
      repeat: 0,
    },
    PENDING_ATTENTION: {
      frames: [46],
      frameRate: 10,
      repeat: 0,
    },
    ATTENTION: {
      frames: [46, 47, 48, 49],
      frameRate: 4,
      repeat: 2,
    },
    PENDING_SNOOZE: {
      frames: [50, 51, 52],
      frameRate: 2,
      repeat: 0,
    },
    SNOOZE: {
      frames: [53],
      frameRate: 1,
      repeat: 0,
    },
    PENDING_TREAT_THROW: {
      frames: [87],
      frameRate: 10,
      repeat: 0,
    },
    EATTREAT: {
      frames: [87, 88, 89, 90],
      frameRate: 8,
      repeat: 0,
    },
    STEPINTO: {
      frames: [96, 97, 98, 99, 100, 101, 102],
      frameRate: 8,
      repeat: 0,
    },
  },
  ATTACHPOINTS: {
    MOUTH: {
      0: {
        x: 0.03,
        y: 0.04,
        scale: 0.03,
        depth: 1,
      },
      1: {
        x: -0.17,
        y: -0.1,
        scale: 0.0275,
        depth: -1,
      },
      2: {
        x: -0.25,
        y: -0.05,
        scale: 0.0275,
        depth: -1,
      },
      3: {
        x: 0,
        y: -0.2,
        scale: 0.0375,
      },
      4: {
        x: -0.26,
        y: -0.17,
        scale: 0.0375,
      },
      5: {
        x: -0.17,
        y: -0.18,
        scale: 0.0375,
      },
      6: {
        x: 0,
        y: -0.2,
        scale: 0.0375,
      },
      15: {
        x: -0.43,
        y: -0.08,
        scale: 0.0375,
      },
      16: {
        x: -0.45,
        y: -0.04,
        scale: 0.0375,
      },
      17: {
        x: -0.46,
        y: -0.15,
        scale: 0.0375,
      },
      18: {
        x: -0.4,
        y: -0.21,
        scale: 0.0375,
      },
      19: {
        x: -0.38,
        y: -0.15,
        scale: 0.0375,
      },
      20: {
        x: -0.41,
        y: -0.1,
        scale: 0.0375,
      },
      21: {
        x: 0.16,
        y: -0.25,
        scale: 0.085,
        rotation: -0.4,
      },
      26: {
        x: 0.35,
        y: 0,
        scale: 0.0375,
        depth: -1,
      },
      27: {
        x: 0.38,
        y: -0.03,
        scale: 0.0375,
        depth: -1,
      },
      28: {
        x: 0.33,
        y: -0.11,
        scale: 0.0375,
        depth: -1,
      },
      29: {
        x: 0.4,
        y: -0.08,
        scale: 0.0375,
        depth: -1,
      },
      30: {
        x: 0.39,
        y: -0.08,
        scale: 0.0375,
        depth: -1,
      },
      31: {
        x: 0.42,
        y: -0.08,
        scale: 0.0375,
        depth: -1,
      },
      32: {
        x: 0.42,
        y: 0.03,
        scale: 0.0375,
        depth: -1,
      },
      33: {
        x: 0.42,
        y: 0.09,
        scale: 0.0375,
        depth: -1,
      },
      34: {
        x: 0.4,
        y: 0.04,
        scale: 0.0375,
        depth: -1,
      },
      35: {
        x: 0.35,
        y: 0.07,
        scale: 0.0375,
        depth: -1,
      },
      36: {
        x: 0.38,
        y: 0.05,
        scale: 0.0375,
        depth: -1,
      },
      37: {
        x: -0.26,
        y: -0.15,
        scale: 0.0375,
      },
      38: {
        x: -0.26,
        y: -0.15,
        scale: 0.0375,
      },
      39: {
        x: -0.26,
        y: -0.15,
        scale: 0.0375,
      },
      40: {
        x: -0.26,
        y: -0.15,
        scale: 0.0375,
      },
      81: {
        x: 0.28,
        y: 0.1,
        scale: 0.0375,
      },
      82: {
        x: 0.3,
        y: 0.11,
        scale: 0.0375,
      },
      83: {
        x: 0.26,
        y: 0.25,
        scale: 0.0375,
      },
      85: {
        x: 0.3,
        y: 0.185,
        scale: 0.0375,
      },
      86: {
        x: 0.2,
        y: 0.25,
        scale: 0.0375,
      },
      87: {
        x: 0.26,
        y: 0.25,
        scale: 0.0375,
      },
      88: {
        x: -0.26,
        y: -0.15,
        scale: 0.0375,
      },
      92: {
        x: -0.165,
        y: -0.25,
        scale: 0.0375,
        depth: -1,
      },
      131: {
        x: 0.2815,
        y: 0.05,
        scale: 0.0365,
        depth: -1,
      },
      132: {
        x: 0.32,
        y: 0.15,
        scale: 0.0365,
        depth: -1,
      },
      133: {
        x: 0.295,
        y: 0.235,
        scale: 0.0365,
        depth: -1,
      },
      134: {
        x: 0.16,
        y: -0.0675,
        scale: 0.0365,
        depth: 1,
      },
      135: {
        x: 0.171,
        y: -0.0585,
        scale: 0.0375,
        depth: 1,
      },
      136: {
        x: 0.185,
        y: -0.028,
        scale: 0.0375,
        depth: 1,
      },
      137: {
        x: 0.225,
        y: 0.004,
        scale: 0.0375,
        depth: 1,
      },
      138: {
        x: 0.23,
        y: 0.02,
        scale: 0.0375,
        depth: 1,
      },
      139: {
        x: 0.22,
        y: 0.025,
        scale: 0.0375,
        depth: 1,
      },
      140: {
        x: 0.23,
        y: 0.0675,
        scale: 0.0375,
        depth: 1,
      },
      141: {
        x: 0.225,
        y: 0.035,
        scale: 0.0375,
        depth: 1,
      },
    },
    NECK: {
      0: {
        x: 0,
        y: 0.01,
        scale: 0,
      },
      1: {
        x: -0.015,
        y: -0.075,
        scale: 0.09,
        rotation: -1.45,
      },
      2: {
        x: -0.07,
        y: -0.065,
        scale: 0.095,
        rotation: -1.55,
      },
      3: {
        x: -0.15,
        y: -0.045,
        scale: 0.1,
        rotation: -1.7,
      },
      4: {
        x: -0.06,
        y: -0.075,
        scale: 0.095,
        rotation: 1.7,
      },
      5: {
        x: -0.055,
        y: -0.08,
        scale: 0.1,
        rotation: 1.8,
      },
      6: {
        x: 0,
        y: 0.01,
        scale: 0,
      },
      13: {
        x: 0.14,
        y: 0.02515,
        scale: 0.09,
        rotation: -0.1,
      },
      14: {
        x: 0.14,
        y: 0.02515,
        scale: 0.09,
        rotation: -0.1,
      },
      15: {
        x: 0.141,
        y: 0.02515,
        scale: 0.09,
        rotation: -0.1,
      },
      16: {
        x: 0.142,
        y: 0.0255,
        scale: 0.09,
        rotation: -0.1,
      },
      17: {
        x: 0.142,
        y: 0.026,
        scale: 0.09,
        rotation: -0.1,
      },
      18: {
        x: 0.135,
        y: 0.0335,
        scale: 0.0825,
        rotation: 0.025,
      },
      19: {
        x: 0.155,
        y: -0.08,
        scale: 0.085,
        rotation: -0.3,
      },
      20: {
        x: 0.1425,
        y: -0.15,
        scale: 0.085,
        rotation: -0.4,
      },
      21: {
        x: 0.12,
        y: -0.23,
        scale: 0.085,
        rotation: -0.55,
      },
      22: {
        x: 0.17,
        y: -0.2175,
        scale: 0.085,
        rotation: -0.1,
      },
      23: {
        x: 0.18,
        y: -0.055,
        scale: 0.085,
        rotation: 0.1,
      },
      24: {
        x: 0.185,
        y: 0.061,
        scale: 0.085,
        rotation: 0.15,
      },
      25: {
        x: 0.1975,
        y: 0.18875,
        scale: 0.085,
        rotation: 0.4,
      },
      26: {
        x: 0.15,
        y: 0.025,
        scale: 0.085,
        rotation: 0.1,
      },
      27: {
        x: 0.17,
        y: -0.025,
        scale: 0.085,
        rotation: 0.1,
      },
      28: {
        x: 0.135,
        y: -0.0375,
        scale: 0.085,
        rotation: -0.3,
      },
      29: {
        x: 0.173,
        y: -0.135,
        scale: 0.085,
        rotation: 0.33,
      },
      30: {
        x: 0.17,
        y: -0.13245,
        scale: 0.085,
        rotation: 0.26,
      },
      31: {
        x: 0.19,
        y: -0.13719,
        scale: 0.085,
        rotation: 0.26,
      },
      32: {
        x: 0.21,
        y: -0.0625,
        scale: 0.085,
        rotation: 0.5,
      },
      33: {
        x: 0.23,
        y: -0.035,
        scale: 0.085,
        rotation: 0.7,
      },
      34: {
        x: 0.2175,
        y: -0.0715,
        scale: 0.085,
        rotation: 0.6,
      },
      35: {
        x: 0.1675,
        y: -0.0625,
        scale: 0.085,
        rotation: 0.65,
      },
      36: {
        x: 0.17,
        y: -0.071,
        scale: 0.085,
        rotation: 0.55,
      },
      37: {
        x: 0.12,
        y: 0.0485,
        scale: 0.084,
        rotation: 0.08,
      },
      38: {
        x: 0.121,
        y: 0.0485,
        scale: 0.084,
        rotation: 0.08,
      },
      39: {
        x: 0.122,
        y: 0.0485,
        scale: 0.084,
        rotation: 0.08,
      },
      40: {
        x: 0.123,
        y: 0.0485,
        scale: 0.084,
        rotation: 0.08,
      },
      82: {
        x: 0.145,
        y: 0.02,
        scale: 0.095,
        rotation: 0.15,
      },
      85: {
        x: 0.145,
        y: 0.03,
        scale: 0.095,
        rotation: 0.7,
      },
      86: {
        x: 0.145,
        y: 0.035,
        scale: 0.1125,
        rotation: 0.7,
      },
      87: {
        x: -0.0515,
        y: -0.15,
        scale: 0,
        rotation: 1.75,
      },
      92: {
        x: -0.1,
        y: -0.055,
        scale: 0.075,
        rotation: 2.1,
      },
      93: {
        x: -0.01,
        y: -0.055,
        scale: 0.075,
        rotation: 2,
      },
      94: {
        x: -0.0485,
        y: -0.055,
        scale: 0.085,
        rotation: 2.75,
      },
      121: {
        x: 0.004,
        y: -0.09,
        scale: 0.0915,
        rotation: -0.635,
      },
      122: {
        x: 0.004,
        y: -0.09,
        scale: 0.0915,
        rotation: -0.635,
      },
      123: {
        x: 0.004,
        y: -0.09,
        scale: 0.0915,
        rotation: -0.635,
      },
      124: {
        x: 0.006,
        y: -0.09,
        scale: 0.0915,
        rotation: -0.635,
      },
      125: {
        x: 0.009,
        y: -0.09,
        scale: 0.0925,
        rotation: -0.635,
      },
      126: {
        x: 0.1205,
        y: -0.095,
        scale: 0.101,
        rotation: 2.5,
      },
      127: {
        x: 0.1205,
        y: -0.095,
        scale: 0.101,
        rotation: 2.5,
      },
      128: {
        x: 0.1205,
        y: -0.095,
        scale: 0.101,
        rotation: 2.5,
      },
      129: {
        x: 0.1205,
        y: -0.095,
        scale: 0.101,
        rotation: 2.5,
      },
      130: {
        x: 0.1205,
        y: -0.095,
        scale: 0.101,
        rotation: 2.5,
      },
      131: {
        x: 0.16,
        y: -0.0415,
        scale: 0.101,
        rotation: 0.4,
      },
      132: {
        x: 0.225,
        y: 0.05,
        scale: 0.1,
        rotation: 0.4,
      },
      133: {
        x: 0.225,
        y: 0.1,
        scale: 0.1,
        rotation: 0.7,
      },
      134: {
        x: -0.007,
        y: -0.055,
        scale: 0.1,
        rotation: -0.55,
      },
      135: {
        x: 0.0095,
        y: -0.055,
        scale: 0.09,
        rotation: -0.55,
      },
      136: {
        x: 0.0175,
        y: -0.045,
        scale: 0.09,
        rotation: -0.45,
      },
      137: {
        x: 0.065,
        y: -0.03,
        scale: 0.09,
        rotation: -0.39,
      },
      138: {
        x: 0.065,
        y: -0.02,
        scale: 0.095,
        rotation: -0.35,
      },
      139: {
        x: 0.0655,
        y: -0.015,
        scale: 0.1,
        rotation: -0.35,
      },
      140: {
        x: 0.065,
        y: 0.015,
        scale: 0.095,
        rotation: -0.15,
      },
      141: {
        x: 0.0625,
        y: -0.0055,
        scale: 0.098,
        rotation: -0.25,
      },
      142: {
        x: 0.12,
        y: -0.08,
        scale: 0.101,
        rotation: 2.5,
      },
      143: {
        x: 0.12,
        y: -0.08,
        scale: 0.101,
        rotation: 2.5,
      },
      144: {
        x: 0.125,
        y: -0.0785,
        scale: 0.101,
        rotation: 2.45,
      },
      145: {
        x: 0.14,
        y: -0.0785,
        scale: 0.101,
        rotation: 2.5,
      },
      146: {
        x: 0.15,
        y: -0.06,
        scale: 0.101,
        rotation: 2.7,
      },
      147: {
        x: 0.175,
        y: -0.02,
        scale: 0.101,
        rotation: 2.79,
      },
      148: {
        x: 0.165,
        y: -0.02,
        scale: 0.101,
        rotation: 2.75,
      },
      149: {
        x: 0.128,
        y: -0.075,
        scale: 0.101,
        rotation: 2.5,
      },
    },
  },
};
