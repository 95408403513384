import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { NavBar, SkipContent, StartOfContent, StyledOverlay, StyledAppBar, PageWrapper } from './styles';
import PropTypes from 'prop-types';
import { navbarItems } from './navbarConfig';
import { FormattedMessage } from 'react-intl';
import { Capacitor } from '@capacitor/core';
import { displayLightStatusBar } from 'Core_Helpers/statusBar';
import { PyxirCharacter } from './svgs';
import useMediaType from 'Core_Helpers/useMediaType';
import { breakpoints } from 'Styles/theme';

function Navbar({ children, disabled }) {
  const location = useLocation();
  const mainRef = useRef(null);
  const mediaType = useMediaType();

  const preventReload = (event) => {
    event.preventDefault();
    mainRef.current.focus();
  };

  const currentRouteIndex = navbarItems.findIndex((x) => x.tabActiveRoutes?.includes(location?.pathname));

  const tab = currentRouteIndex >= 0 ? currentRouteIndex : 0;

  const isDesktopScreen = mediaType === breakpoints.DESKTOP;

  let isIosNative = Capacitor.getPlatform() === 'ios';

  useEffect(() => {
    displayLightStatusBar();
  }, []);

  return (
    <>
      <PageWrapper>
        <SkipContent isNative={isIosNative} href="#StartOfContent" onClick={preventReload}>
          <FormattedMessage id="menu.skip" />
        </SkipContent>
        <StyledAppBar elevation={isDesktopScreen ? 3 : 0}>
          {disabled && <StyledOverlay data-testid={'DisabledMenuOverlay'} />}
          {isDesktopScreen && <PyxirCharacter />}
          <NavBar
            disabled={disabled}
            value={tab}
            centered
            variant="fullWidth"
            orientation={isDesktopScreen ? 'vertical' : 'horizontal'}
            className="safe-area"
          >
            {navbarItems.map(({ component: Component }, index) => (
              <Component key={index} tabIndex={0} onClick={preventReload} />
            ))}
          </NavBar>
        </StyledAppBar>
        <StartOfContent ref={mainRef} id="StartOfContent" role="main" tabIndex="-1">
          {children}
        </StartOfContent>
      </PageWrapper>
    </>
  );
}

Navbar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  disabled: PropTypes.bool,
};

export default Navbar;
