import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Answer from 'Stories/components/Form/Answer/Answer';
import { QuestionWrapper, AnswerWrapper } from 'Stories/components/Form/styles';
import { Typography } from '@mui/material';
import { StyledMultiSelectQuestion } from './multiSelectStyles';
import SkipButton from './components/SkipButton';
import Button from 'Stories/components/Button';

const MultiSelect = ({ name, onChange, skipOption, text, ...props }) => {
  const intl = useIntl();
  const [selected, setSelected] = useState({});
  const updateSelection = (chosenOption) => {
    setSelected({ ...selected, [chosenOption]: !selected[chosenOption] });
  };
  const continueClicked = () => {
    const final = Object.keys(selected).filter((x) => selected[x] !== false);
    onChange(final);
    setSelected({});
  };

  return (
    <StyledMultiSelectQuestion>
      <QuestionWrapper id={`${name}-form-question`} className="FormQuestion-root">
        <Typography id={`${name}-question`} className="FormQuestion-question">
          {text}
        </Typography>
        <Typography id={`${name}-question-description`} className="FormQuestion-description">
          {intl.formatMessage({ id: 'screenings.form.adult-prapare.check-all-that-apply' })}
        </Typography>
        <AnswerWrapper id={`${name}-answer-wrapper`} className="FormAnswer-root">
          <Answer questionName={name} updateSelection={updateSelection} {...props} />
        </AnswerWrapper>
        <SkipButton skipOption={skipOption} onChange={onChange} />
      </QuestionWrapper>
      <Button style={{ width: '100%' }} onClick={continueClicked} disabled={!Object.values(selected).includes(true)}>
        {intl.formatMessage({ id: 'common.continue' })}
      </Button>
    </StyledMultiSelectQuestion>
  );
};

MultiSelect.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  answerType: PropTypes.string,
  options: PropTypes.array,
  skipOption: PropTypes.object,
  onChange: PropTypes.func,
};

export default MultiSelect;
