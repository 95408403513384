import { reframeJudgements } from 'Assets/img/my-tools-icons';

export const reframeJudgementsEn = {
  key: 'reframe-judgements',
  title: 'Reframe Judgement',
  titleImage: reframeJudgements,
  titleImageAlt: '',
  description: 'Changing negative thoughts with more supporting ones can help boost overall well-being!',
  contentId: 9,
  sections: [
    {
      key: 'create-chart',
      header: 'To begin, create a 4-column chart.',
      description: 'Use paper and pen or your computer/device to create.',
      itemsHeader: 'Tell me more',
      items: [
        { text: 'Name column 1 “Situation”' },
        { text: 'Name column 2 “My initial judgement”' },
        { text: 'Name column 3 “My emotion/feeling”' },
        { text: 'Name column 4 “Reframed thoughts”' },
        { text: 'Make a few horizontal rows across all 4 columns to begin with; you can add more later.' },
      ],
    },
    {
      key: 'write-negative-judgements',
      header: 'Next, over the next week, write down negative judgements on the chart.',
      description:
        'Notice when you have negative judgements about other people, groups, yourself, situations, etc., and write them on the chart. It’s okay if you don’t recall every one!',
      itemsHeader: 'Tell me more',
      items: [
        { text: 'In column 1, describe what happened to cause your judgement.' },
        { text: 'In column 2 write down your initial judgement thought about it.' },
        { text: 'You can be as brief or as detailed as you want.' },
      ],
    },
    {
      key: 'write-emotions',
      header: 'Then think about how your judgements and thoughts affected your emotions.',
      description:
        'In column 3, write down the feeling(s) or emotion(s) you had following your thoughts of the situation.',
      itemsHeader: 'Tell me more',
      items: [
        { text: 'With an open mind, notice how your thoughts about the situation made you feel.' },
        {
          text: 'Notice if your inner self-talk about the situation gave you helpful or positive feelings, or unpleasant, negative emotions.',
        },
        {
          text: 'Recall any physical sensations you felt in your body at the time, and as you remember the event(s) now.',
        },
      ],
    },
    {
      key: 'reframe-negative-thoughts',
      header: 'Next, reframe negative thoughts to be more helpful.',
      description:
        'In column 4, write down some other ways to think about the situation(s) in column 1 that are more supportive to your emotional well-being.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Think of some positive, supportive, or realistic thoughts about the situation to reframe unhelpful judgements or negative inner self-talk about it.',
        },
        {
          text: 'Helpful hints: Some questions to ask for reframing thoughts...',
          subItems: [
            { text: 'Could I see this event as a learning experience to grow from vs. a threat to attack?' },
            { text: 'What advice would I give to a best friend if they were going through a similar situation?' },
            { text: 'What is the "gift" in this situation for me?' },
            { text: 'Is this thought true?' },
            { text: 'Is it worth spending my energy this way?' },
            { text: 'What are other ways to view this situation that serve me better?' },
            { text: 'What do I gain from this judgment/thought? What do I lose?' },
            { text: 'What will I think of this situation in a week? ' },
            { text: 'Is looping this thought in my mind helpful?' },
          ],
        },
      ],
    },
    {
      key: 'self-reflection',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your experience with this activity.',
      itemsHeader: 'Awareness Insights',
      items: [
        {
          text: 'What have you learned from this exercise?',
        },
        { text: 'How did you feel before completing this exercise?' },
        { text: 'How do you feel after it?' },
        { text: 'What part(s) of this exercise did you find most challenging? Why?' },
        { text: 'What did you enjoy most about this exercise?' },
        { text: 'What would be some benefits of doing this activity more often in general?' },
      ],
    },
    {
      key: 'self-reflection-pointers',
      itemsHeader: 'Pathway Pointers',
      items: [
        {
          text: 'Because emotions follow thoughts, negative judgements or thoughts can greatly affect how we feel without realizing it.',
        },
        {
          text: 'Mindfully reframing negative situations helps to support emotional balance and alters unhelpful reactions without changing the facts of the situation.',
        },
        {
          text: 'Be kind to yourself when learning to reframe thinking patterns. It may be helpful to look at reframing as gaining supportive habits vs. having to stomp out negativity.',
        },
        {
          text: 'Allow yourself some time as you’re taking new steps towards wellness on your Thrive Pathway!',
        },
      ],
    },
  ],
};
