import { InteractionType } from 'Core_Games/constants/interactionType';
import { playerActorType } from 'Core_Games/constants/playerActorType';
import { gameData } from 'Core_Games/constants/gameData';
import { uiTypes } from 'Core_Games/constants/uiTypes';
import { animationType } from 'Core_Games/constants/animationType';

export const keySceneDataTypes = {
  // Objects
  PROJECTILE: 'PROJECTILE',
  TREATBOX: 'TREATBOX',
  TREATBOXOUTER: 'TREATBOXOUTER',
  TREATBOXMIDDLE: 'TREATBOXMIDDLE',
  TREATBOXINNER: 'TREATBOXINNER',
  BACKGROUND: 'BACKGROUND',
  PROJECTILETHROWN_SOUND: 'PROJECTILETHROWN_SOUND',
  TREAT: 'TREAT',
  // Captions
  INTRO_CAPTION: 'INTRO_CAPTION',
  STUCK_CAPTION: 'STUCK_CAPTION',
  BACK_CAPTION: 'BACK_CAPTION',
  // Buttons
  TOSS_BUTTON: 'TOSS_BUTTON',
  TOSS_AGAIN_BUTTON: 'TOSS_AGAIN_BUTTON',
  MAYBE_LATER_BUTTON: 'MAYBE_LATER_BUTTON',
  LETS_GO_BUTTON: 'LETS_GO_BUTTON',
  BACK_BUTTON: 'BACK_BUTTON',
  // Screen
  SCREEN_INTRO: 'SCREEN_INTRO',
  SCREEN_AGAIN: 'SCREEN_AGAIN',
  SCREEN_OUTRO: 'SCREEN_OUTRO',
  SCREEN_BACK: 'SCREEN_BACK',
};

export const treatConfig = {
  PHYSICS: ['fake3dphysicsworld'],
  [playerActorType.DOG.NAME]: {
    [InteractionType.TREAT.NAME]: {
      GAMEBACKGROUNDCOLOR: '#FFFFFF',
      NAME: InteractionType.TREAT.NAME,
      PETSCALE: 0.75,
      WALKSPEED: 75,
      EATINGPOSITION: 0.55,
      NUDGEOFFSETX: 0.75,
      NUDGEOFFSETY: 0.825,
      PETOFFSETX: 0.95,
      PETOFFSETY: 0.9,
      TREATBOXLOCATIONX: 0.9,
      BACKGROUNDSPLIT: 1,
      STEPINTOOFFSETX: 0.85,
      STEPINTOOFFSETY: 0.85,
      PETWALKOFFSETX: 0.9,
      SHOULDJUMP: false,
      SHOULDTURN: false,
      SHOULDENTERFROMTOP: false,
      ANIMATIONS: {
        POSE_END: animationType.IDLE6,
      },
      BOUNDS: {
        HEIGHT: {
          START: 0.10606061,
          END: 0.0,
        },
        WIDTH: {
          START: 0.05555,
          END: 0.41666666666,
        },
      },
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.DOG.TREAT,
        [keySceneDataTypes.TREATBOX]: gameData.ACTOR.ITEM.OBJECT.TREATBOX,
        [keySceneDataTypes.TREATBOXOUTER]: gameData.ACTOR.ITEM.OBJECT.TREATBOXOUTER,
        [keySceneDataTypes.TREATBOXMIDDLE]: gameData.ACTOR.ITEM.OBJECT.TREATBOXMIDDLE,
        [keySceneDataTypes.TREATBOXINNER]: gameData.ACTOR.ITEM.OBJECT.TREATBOXINNER,
        [keySceneDataTypes.TREAT]: gameData.ACTOR.ITEM.OBJECT.TREAT_DOG,
        [keySceneDataTypes.PROJECTILE]: gameData.ACTOR.ITEM.OBJECT.TREATBALL,
      },
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.TOSS_BUTTON,
              TEXTID: 'pets.buttons.treat.toss-ball',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_INTRO],
            },
            {
              NAME: keySceneDataTypes.TOSS_AGAIN_BUTTON,
              TEXTID: 'pets.buttons.treat.toss-ball-again',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_AGAIN],
            },
            {
              NAME: keySceneDataTypes.MAYBE_LATER_BUTTON,
              TEXTID: 'pets.buttons.treat.maybe-later',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.LETS_GO_BUTTON,
              TEXTID: 'pets.buttons.treat.lets-go',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.BACK_BUTTON,
              TEXTID: 'pets.buttons.treat.back',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_BACK],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.INTRO_CAPTION,
              TITLEID: 'pets.caption.title.treat.intro',
              TEXTID: 'pets.caption.description.treat.intro',
            },
            {
              NAME: keySceneDataTypes.STUCK_CAPTION,
              TITLEID: 'pets.caption.title.treat.stuck',
              TEXTID: 'pets.caption.description.treat.stuck',
            },
            {
              NAME: keySceneDataTypes.OUTRO_CAPTION,
              TITLEID: 'pets.caption.title.treat.outro',
              TEXTID: 'pets.caption.description.treat.outro',
            },
            {
              NAME: keySceneDataTypes.BACK_CAPTION,
              TITLEID: 'pets.caption.title.treat.back',
            },
          ],
        },
      ],
    },
  },
  [playerActorType.CAT.NAME]: {
    [InteractionType.TREAT.NAME]: {
      NAME: InteractionType.TREAT.NAME,
      PETSCALE: 0.5,
      WALKSPEED: 75,
      EATINGPOSITION: 0.65,
      NUDGEOFFSETX: 0.85,
      NUDGEOFFSETY: 0.85,
      PETOFFSETX: 1.15,
      PETOFFSETY: 1,
      TREATBOXLOCATIONX: 0.9,
      BACKGROUNDSPLIT: 1,
      STEPINTOOFFSETX: 0.15,
      STEPINTOOFFSETY: 0.85,
      JUMPINTOOFFSETX: 1,
      PETWALKOFFSETX: 1.125,
      SHOULDJUMP: true,
      SHOULDTURN: true,
      SHOULDENTERFROMTOP: true,
      BOUNDS: {
        HEIGHT: {
          START: 0.10606061,
          END: 0.0,
        },
        WIDTH: {
          START: 0.05555,
          END: 0.41666666666,
        },
      },
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.DOG.TREAT,
        [keySceneDataTypes.TREATBOX]: gameData.ACTOR.ITEM.OBJECT.TREATBOX,
        [keySceneDataTypes.TREATBOXOUTER]: gameData.ACTOR.ITEM.OBJECT.TREATBOXOUTER,
        [keySceneDataTypes.TREATBOXMIDDLE]: gameData.ACTOR.ITEM.OBJECT.TREATBOXMIDDLE,
        [keySceneDataTypes.TREATBOXINNER]: gameData.ACTOR.ITEM.OBJECT.TREATBOXINNER,
        [keySceneDataTypes.TREAT]: gameData.ACTOR.ITEM.OBJECT.TREAT_CAT,
        [keySceneDataTypes.PROJECTILE]: gameData.ACTOR.ITEM.OBJECT.TREATBALL,
      },
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.TOSS_BUTTON,
              TEXTID: 'pets.buttons.treat.toss-ball',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_INTRO],
            },
            {
              NAME: keySceneDataTypes.TOSS_AGAIN_BUTTON,
              TEXTID: 'pets.buttons.treat.toss-ball-again',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_AGAIN],
            },
            {
              NAME: keySceneDataTypes.MAYBE_LATER_BUTTON,
              TEXTID: 'pets.buttons.treat.maybe-later',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.LETS_GO_BUTTON,
              TEXTID: 'pets.buttons.treat.lets-go',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.BACK_BUTTON,
              TEXTID: 'pets.buttons.treat.back',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_BACK],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.INTRO_CAPTION,
              TITLEID: 'pets.caption.title.treat.intro',
              TEXTID: 'pets.caption.description.treat.intro',
            },
            {
              NAME: keySceneDataTypes.STUCK_CAPTION,
              TITLEID: 'pets.caption.title.treat.stuck',
              TEXTID: 'pets.caption.description.treat.stuck',
            },
            {
              NAME: keySceneDataTypes.OUTRO_CAPTION,
              TITLEID: 'pets.caption.title.treat.outro',
              TEXTID: 'pets.caption.description.treat.outro',
            },
            {
              NAME: keySceneDataTypes.BACK_CAPTION,
              TITLEID: 'pets.caption.title.treat.back',
            },
          ],
        },
      ],
    },
  },
};
