import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import { DateTime } from 'luxon';
import {
  StyledContainer,
  StyledInput,
  StyledInputContainer,
  StyledLabel,
  StyledTextButton,
  StyledErrorText,
} from './styles';

function EndDateField({ control, validate, errors }) {
  const intl = useIntl();

  return (
    <Controller
      name="endDateUtc"
      control={control}
      defaultValue={null}
      rules={{ validate }}
      render={({ field: { value, onChange } }) => {
        if (value) {
          const formattedDate = DateTime.fromISO(value).toISODate();
          return (
            <>
              <StyledContainer>
                <StyledLabel id="endDateInput">{intl.formatMessage({ id: 'common.end' })}</StyledLabel>
                <StyledInputContainer>
                  <StyledInput
                    type="date"
                    aria-labelledby="endDateInput"
                    value={formattedDate}
                    onChange={onChange}
                    max="9999-12-30"
                  />
                </StyledInputContainer>
              </StyledContainer>
              {errors.endDateUtc && (
                <StyledErrorText>^ {intl.formatMessage({ id: 'reminders.date-error' })}</StyledErrorText>
              )}
              <StyledTextButton type="button" onClick={() => onChange(null)}>
                {intl.formatMessage({ id: 'reminders.remove-end-date' })}
              </StyledTextButton>
            </>
          );
        } else {
          return (
            <StyledTextButton type="button" onClick={() => onChange(DateTime.local().toISODate())}>
              {intl.formatMessage({ id: 'reminders.choose-end-date' })}
            </StyledTextButton>
          );
        }
      }}
    />
  );
}

EndDateField.propTypes = {
  control: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default EndDateField;
