import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Stories/components/Button/Button';

const ResourceButton = ({ onClick, type, value, children, ...props }) => {
  const getButtonProps = (type, value) => {
    const buttonType = ResourceButton.variant[type] ?? ResourceButton.variant.LINK;
    if (buttonType === ResourceButton.variant.APP) {
      return {
        to: value ?? '#',
      };
    } else if (buttonType === ResourceButton.variant.LINK) {
      return {
        href: value,
        target: '_blank',
        rel: 'noreferrer',
      };
    } else {
      return {
        href: '#',
        onClick: (event) => {
          event.preventDefault();
          window.open(`tel:${value}`, '_self');
          if (onClick) {
            onClick(event);
          }
        },
      };
    }
  };

  return (
    <Button onClick={onClick} {...getButtonProps(type, value)} {...props}>
      {children}
    </Button>
  );
};

ResourceButton.variant = {
  PHONE: 'PHONE',
  LINK: 'LINK',
  APP: 'APP',
};

ResourceButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.values(ResourceButton.variant)),
  value: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default ResourceButton;
