import { sceneType } from '../../constants/sceneType';

export class Physics extends Phaser.Scene {
  constructor() {
    super(sceneType.PHYSICS.NAME);
  }

  init(sceneData) {
    this.physicsConfig = sceneData.sceneConfig.PHYSICS;
  }

  preload() {}

  create() {
    if (this.physicsConfig?.includes(sceneType.FAKE3DPHYSICSWORLD.NAME)) {
      this.fake3dworld = this.scene.add(sceneType.FAKE3DPHYSICSWORLD.NAME, sceneType.FAKE3DPHYSICSWORLD.DATA, true, {
        sceneConfig: this.sceneConfig,
        petConfig: this.petConfig,
      });
    }
  }

  update() {
    if (this.fake3dworld) {
      this.fake3dworld.update();
    }
  }
}
