export const Config = {
  KEY: 'movement',
  ANIMATIONS: {
    RUN: {
      frames: [26, 27, 28, 29, 30, 31, 32, 33],
      frameRate: 16,
      repeat: -1,
    },
    RUNAWAY: {
      frames: [144, 145, 146, 147, 148, 149, 150, 151],
      frameRate: 16,
      repeat: -1,
    },
    RUNTOWARDS: {
      frames: [136, 137, 138, 139, 140, 141, 142, 143],
      frameRate: 16,
      repeat: -1,
    },
    JUMP: {
      frames: [7, 8, 9],
      frameRate: 8,
      repeat: 0,
    },
    LAND: {
      frames: [10, 11],
      frameRate: 12,
      repeat: 0,
    },
    JUMP_FORWARD: {
      frames: [107, 108, 109],
      frameRate: 8,
      repeat: 0,
    },
    LAND_FORWARD: {
      frames: [110, 111, 112],
      frameRate: 8,
      repeat: 0,
    },
    JUMP_BACK: {
      frames: [113, 114, 115],
      frameRate: 8,
      repeat: 0,
    },
    LAND_BACK: {
      frames: [116, 117, 118],
      frameRate: 8,
      repeat: 0,
    },
    TURN: {
      frames: [2, 1, 0],
      frameRate: 12,
      repeat: 0,
      yoyo: true,
      flipOnYoyo: true,
    },
    FRONTTURN_HALF: {
      frames: [1, 0],
      frameRate: 8,
      repeat: 0,
      yoyo: true,
      flipOnYoyo: true,
    },
    BACKTURN: {
      frames: [3, 4, 5],
      frameRate: 12,
      repeat: 0,
      yoyo: true,
      flipOnYoyo: true,
    },
    BACKTURN_FACEAWAY: {
      frames: [3, 4, 5],
      frameRate: 12,
      repeat: 0,
    },
    BACKTURN_HALF: {
      frames: [4, 5],
      frameRate: 8,
      repeat: 0,
      yoyo: true,
      flipOnYoyo: true,
    },
    TURN_TO_ANGLE: {
      frames: [2, 3, 4],
      frameRate: 8,
      repeat: 0,
    },
    BACKTURN_PARTIAL: {
      frames: [4, 3],
      frameRate: 12,
      repeat: 0,
      flipOnYoyo: true,
    },
    IDLE: {
      frames: [34, 35, 36, 37],
      frameRate: 16,
      repeat: 2,
      yoyo: true,
    },
    IDLE2: {
      frames: [52, 56, 57, 58, 59],
      frameRate: 8,
      repeat: 0,
    },
    IDLE3: {
      frames: [60],
      frameRate: 16,
      repeat: 0,
    },
    IDLE4: {
      frames: [52],
      frameRate: 16,
      repeat: 0,
    },
    IDLE5: {
      frames: [13],
      frameRate: 16,
      repeat: 0,
    },
    IDLE6: {
      frames: [0],
      frameRate: 16,
      repeat: 0,
    },
    OBJECT_INTERACT: {
      frames: [17, 18, 19, 20, 21, 22, 22],
      frameRate: 13,
      repeat: 0,
      yoyo: true,
    },
    OBJECT_INTERACT1: {
      frames: [17, 18, 19, 20, 21, 22],
      frameRate: 16,
      repeat: 0,
      yoyo: true,
    },
    OBJECT_INTERACT2: {
      frames: [133, 134, 135],
      frameRate: 12,
      repeat: 0,
    },
    OBJECT_INTERACT3: {
      frames: [17, 18, 19, 20, 21, 22, 22],
      frameRate: 13,
      repeat: 0,
    },
    SIT: {
      frames: [23, 24, 25],
      frameRate: 16,
      repeat: 0,
    },
    POUNCE: {
      frames: [92, 93, 94, 95],
      frameRate: 16,
      repeat: 0,
    },
    WALK: {
      frames: [13, 14, 15, 16],
      frameRate: 8,
      repeat: -1,
    },
    WALK2: {
      frames: [60, 61, 62, 63],
      frameRate: 8,
      repeat: -1,
    },
    WALK_BACK_ANGLE: {
      frames: [128, 129, 130, 131, 132],
      frameRate: 4,
      repeat: -1,
    },
    WALK_FRONT_ANGLE: {
      frames: [123, 124, 125, 126, 127],
      frameRate: 4,
      repeat: -1,
    },
    NUDGE: {
      frames: [152, 153, 154, 155, 156],
      frameRate: 8,
      repeat: 0,
    },
    SITBENDEAT: {
      frames: [70, 71],
      frameRate: 14,
      repeat: 0,
    },
    SITBENDDRINK: {
      frames: [66, 67],
      frameRate: 14,
      repeat: 0,
    },
    SITBENDDRINKFINISH: {
      frames: [67, 66, 65],
      frameRate: 14,
      repeat: 0,
    },
    SITBENDEATFINISH: {
      frames: [72, 71, 70],
      frameRate: 14,
      repeat: 0,
    },
    WATCH: {
      frames: [75, 76, 77],
      frameRate: 14,
      repeat: -1,
      yoyo: true,
    },
    EAT: {
      frames: [73, 74],
      frameRate: 12,
      repeat: -1,
    },
    DRINK: {
      frames: [68, 69],
      frameRate: 12,
      repeat: -1,
    },
    POSE_HAPPY: {
      frames: [96],
      frameRate: 12,
      repeat: -1,
    },
    POSE_PROUD: {
      frames: [99],
      frameRate: 12,
      repeat: -1,
    },
    POSE_SAD: {
      frames: [98],
      frameRate: 12,
      repeat: -1,
    },
    HUNGRY: {
      frames: [81, 82, 83],
      frameRate: 14,
      repeat: 0,
    },
    THIRSTY: {
      frames: [78, 79, 80],
      frameRate: 7,
      repeat: 0,
    },
    STAND_ATTENTION_TRANSITION: {
      frames: [38, 39, 40, 41, 42],
      frameRate: 4,
      repeat: 0,
    },
    PENDING_ATTENTION: {
      frames: [43],
      frameRate: 4,
      repeat: 0,
    },
    ATTENTION: {
      frames: [43, 45, 46, 47],
      frameRate: 4,
      repeat: 2,
    },
    PENDING_SNOOZE: {
      frames: [48, 49, 50],
      frameRate: 2,
      repeat: 0,
    },
    SNOOZE: {
      frames: [51],
      frameRate: 1,
      repeat: 0,
    },
    PENDING_TREAT_THROW: {
      frames: [92],
      frameRate: 10,
      repeat: 0,
    },
    EATTREAT: {
      frames: [70, 71, 72, 73, 74],
      frameRate: 8,
      repeat: 0,
    },
    STEPINTO: {
      frames: [100, 101, 102, 103, 104, 105, 106],
      frameRate: 8,
      repeat: 0,
    },
    WALK_FRONT: {
      frames: [119, 120],
      frameRate: 8,
      repeat: -1,
    },
    WALK_BACK: {
      frames: [121, 122],
      frameRate: 8,
      repeat: -1,
    },
  },
  ATTACHPOINTS: {
    MOUTH: {
      0: {
        x: 0.06,
        y: -0.035,
        scale: 0.05,
      },
      1: {
        x: -0.01,
        y: -0.035,
        scale: 0.05,
      },
      2: {
        x: -0.3,
        y: 0,
        scale: 0.05,
      },
      3: {
        x: -0.36,
        y: -0.08,
        scale: 0.05,
      },
      4: {
        x: -0.26,
        y: -0.17,
        scale: 0.05,
      },
      5: {
        x: 0.2,
        y: -0.1,
        scale: 0.05,
      },
      15: {
        x: -0.43,
        y: -0.08,
        scale: 0.05,
      },
      16: {
        x: -0.45,
        y: -0.04,
        scale: 0.05,
      },
      17: {
        x: -0.46,
        y: -0.15,
        scale: 0.05,
      },
      18: {
        x: -0.4,
        y: -0.21,
        scale: 0.05,
      },
      19: {
        x: -0.38,
        y: -0.15,
        scale: 0.05,
      },
      20: {
        x: -0.41,
        y: -0.1,
        scale: 0.05,
      },
      23: {
        x: 0.28,
        y: 0.09,
        scale: 0.05,
      },
      24: {
        x: 0.22,
        y: 0.04,
        scale: 0.05,
      },
      25: {
        x: 0.15,
        y: 0.01,
        scale: 0.05,
      },
      26: {
        x: 0.36,
        y: -0.02,
        scale: 0.05,
      },
      27: {
        x: 0.36,
        y: -0.02,
        scale: 0.05,
      },
      28: {
        x: 0.36,
        y: -0.02,
        scale: 0.05,
      },
      29: {
        x: 0.36,
        y: -0.02,
        scale: 0.05,
      },
      30: {
        x: 0.36,
        y: -0.02,
        scale: 0.05,
      },
      31: {
        x: 0.36,
        y: -0.02,
        scale: 0.05,
      },
      32: {
        x: 0.36,
        y: -0.02,
        scale: 0.05,
      },
      33: {
        x: 0.36,
        y: -0.02,
        scale: 0.05,
      },
      36: {
        x: -0.26,
        y: -0.15,
        scale: 0.05,
      },
      37: {
        x: -0.26,
        y: -0.15,
        scale: 0.05,
      },
      38: {
        x: -0.26,
        y: -0.15,
        scale: 0.05,
      },
      39: {
        x: -0.26,
        y: -0.15,
        scale: 0.05,
      },
      92: {
        x: -0.12,
        y: -0.05,
        scale: 0.05,
      },
      93: {
        x: -0.1,
        y: 0.11,
        scale: 0.05,
      },
      94: {
        x: -0.1,
        y: 0.17,
        scale: 0.05,
      },
      95: {
        x: -0.1,
        y: 0.17,
        scale: 0.05,
      },
      104: {
        x: 0.36,
        y: 0.1,
        scale: 0.05,
      },
      105: {
        x: 0.36,
        y: 0.1,
        scale: 0.05,
      },
      106: {
        x: 0.36,
        y: 0.1,
        scale: 0.05,
      },
      126: {
        x: -0.3,
        y: -0.08,
        scale: 0.05,
      },
      127: {
        x: -0.2,
        y: -0.08,
        scale: 0.05,
      },
      128: {
        x: -0.17,
        y: -0.08,
        scale: 0.05,
      },
      129: {
        x: -0.13,
        y: -0.08,
        scale: 0.05,
      },
      130: {
        x: -0.09,
        y: -0.08,
        scale: 0.05,
      },
      131: {
        x: -0.05,
        y: -0.08,
        scale: 0.05,
      },
      132: {
        x: -0.01,
        y: -0.08,
        scale: 0.05,
      },
      133: {
        x: 0.2,
        y: 0,
        scale: 0.05,
        depth: -1,
      },
      134: {
        x: 0.375,
        y: 0.2,
        scale: 0.05,
      },
      135: {
        x: 0.35,
        y: 0.32,
        scale: 0.05,
      },
      136: {
        x: 0.2,
        y: -0.005,
        scale: 0.05,
        depth: 1,
      },
      137: {
        x: 0.225,
        y: -0.015,
        scale: 0.05,
      },
      138: {
        x: 0.225,
        y: -0.02,
        scale: 0.05,
      },
      139: {
        x: 0.2375,
        y: -0.02,
        scale: 0.05,
      },
      140: {
        x: 0.225,
        y: 0.06,
        scale: 0.05,
      },
      141: {
        x: 0.225,
        y: 0.07,
        scale: 0.05,
      },
      142: {
        x: 0.225,
        y: 0.06,
        scale: 0.05,
      },
      143: {
        x: 0.225,
        y: 0.06,
        scale: 0.05,
      },
    },
    NECK: {
      0: {
        x: 0,
        y: 0.01,
        scale: 0,
      },
      1: {
        x: 0,
        y: -0.08,
        scale: 0.085,
      },
      2: {
        x: -0.05,
        y: -0.1,
        scale: 0.085,
        rotation: 0.35,
      },
      3: {
        x: -0.05,
        y: -0.1,
        scale: 0.085,
        rotation: 0.4,
      },
      4: {
        x: -0.05,
        y: -0.1,
        scale: 0.085,
        rotation: 0.4,
      },
      5: {
        x: -0.05,
        y: -0.1,
        scale: 0.085,
        rotation: 0.5,
      },
      6: {
        x: 0,
        y: 0.01,
        scale: 0,
      },
      15: {
        x: -0.15,
        y: -0.04,
        scale: 0.085,
      },
      16: {
        x: -0.15,
        y: -0.05,
        scale: 0.085,
        rotation: -0.33,
      },
      17: {
        x: -0.17,
        y: -0.12,
        scale: 0.085,
        rotation: 0.05,
      },
      18: {
        x: -0.12,
        y: -0.12,
        scale: 0.085,
      },
      19: {
        x: -0.1,
        y: -0.07,
        scale: 0.085,
      },
      20: {
        x: -0.1,
        y: -0.03,
        scale: 0.085,
        rotation: -0.1,
      },
      23: {
        x: -0.13,
        y: 0.025,
        scale: 0.085,
        rotation: 0.3,
      },
      24: {
        x: -0.14,
        y: 0.055,
        scale: 0.085,
      },
      25: {
        x: -0.1,
        y: 0.125,
        scale: 0.085,
        rotation: -0.1,
      },
      26: {
        x: 0,
        y: -0.2,
        scale: 0.085,
      },
      27: {
        x: 0,
        y: -0.2,
        scale: 0.085,
      },
      28: {
        x: 0,
        y: -0.2,
        scale: 0.085,
      },
      29: {
        x: 0,
        y: -0.2,
        scale: 0.085,
      },
      30: {
        x: 0,
        y: -0.2,
        scale: 0.085,
      },
      31: {
        x: 0,
        y: -0.2,
        scale: 0.085,
      },
      32: {
        x: 0,
        y: -0.2,
        scale: 0.085,
      },
      33: {
        x: 0,
        y: -0.2,
        scale: 0.085,
      },
      36: {
        x: 0.12,
        y: 0.115,
        scale: 0.085,
        rotation: 0.15,
      },
      37: {
        x: 0.121,
        y: 0.115,
        scale: 0.085,
        rotation: 0.15,
      },
      38: {
        x: 0.122,
        y: 0.115,
        scale: 0.085,
        rotation: 0.15,
      },
      39: {
        x: 0.123,
        y: 0.115,
        scale: 0.085,
        rotation: 0.15,
      },
    },
  },
};
