import { THRIVEPATHWAY, CHECKINS, TALKTOSOMEONE } from 'Core_Pages/Routes/RoutesConfig';
import { checkins, thrive, thriveCompleteEnglish, thriveCompleteSpanish } from 'Assets/img/home-option-icons';
import * as permissions from 'Core_Helpers/authentication/PermissionsConfig';
import { talk_to_someoneIcon } from 'Assets/img/talk-to-someone-icons';
import { CardTypes } from './ActivityTabs/config';

export const accessTypes = {
  THRIVE: 'THRIVE',
  THRIVEGRADUATE: 'THRIVEGRADUATE',
  CHECKINSREQUIREDDONE: 'CHECKINSREQUIREDDONE',
  CHECKINSREQUIRED: 'CHECKINSREQUIRED',
  CHECKINSSTANDARD: 'CHECKINSSTANDARD',
  TALKTOSOMEONENOMESSAGE: 'TALKTOSOMEONENOMESSAGE',
  TALKTOSOMEONEUNREADMESSAGE: 'TALKTOSOMEONEUNREADMESSAGE',
};

export const activities = [
  {
    titleId: 'home.activities.check-ins',
    backgroundColor: '#E8E6CA',
    icon: checkins,
    to: CHECKINS,
    access: accessTypes.CHECKINSREQUIREDDONE,
    imageStyle: { paddingBottom: '8px' },
    loggedEvent: 'Check_Ins_Clicked',
  },
  {
    titleId: 'home.activities.check-ins',
    backgroundColor: '#E8E6CA',
    icon: checkins,
    to: CHECKINS,
    access: accessTypes.CHECKINSREQUIRED,
    cardType: CardTypes.WARNING,
    imageStyle: { paddingBottom: '8px' },
    loggedEvent: 'Check_Ins_Clicked',
  },
  {
    titleId: 'home.activities.talk-to-someone',
    altId: 'home.activities.talk-to-someone.icon',
    backgroundColor: '#C0BFCF',
    icon: talk_to_someoneIcon,
    access: accessTypes.TALKTOSOMEONENOMESSAGE,
    to: TALKTOSOMEONE,
    imageStyle: { width: '80px', marginBottom: '12px' },
    loggedEvent: 'Talk_To_Someone_Clicked',
  },
  {
    titleId: 'home.activities.talk-to-someone',
    altId: 'home.activities.talk-to-someone.icon',
    backgroundColor: '#C0BFCF',
    icon: talk_to_someoneIcon,
    cardType: CardTypes.WARNING,
    access: accessTypes.TALKTOSOMEONEUNREADMESSAGE,
    to: TALKTOSOMEONE,
    imageStyle: { width: '80px', marginBottom: '12px' },
    loggedEvent: 'Talk_To_Someone_Clicked',
  },
  {
    titleId: 'home.activities.thrive-program',
    backgroundColor: '#FCEEC1',
    icon: thrive,
    altId: 'home.activities.thrive-program',
    to: THRIVEPATHWAY,
    access: accessTypes.THRIVE,
    permissions: permissions.THRIVEPATHWAY,
    loggedEvent: 'Thrive_Program_Clicked',
  },
  {
    titleId: 'home.activities.thrive-program',
    backgroundColor: '#FCEEC1',
    icon: thriveCompleteEnglish,
    icon_es: thriveCompleteSpanish,
    altId: 'thrive.complete',
    imageStyle: { width: '95%' },
    access: accessTypes.THRIVEGRADUATE,
    permissions: permissions.THRIVEPATHWAY,
  },
];
