import React from 'react';
import { StyledArrowUpward, StyledMessageInputContainer } from './styles';
import { Input } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { DirectMessageStore } from '../Store/DirectMessageStore';
import { Controller, useForm } from 'react-hook-form';
import { SessionStatus } from '../Models/SessionStatus';
import { useIntl } from 'react-intl';

const MessageInput = () => {
  const intl = useIntl();
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues: { messageInput: '' } });
  const directMessageStore = DirectMessageStore.Instance().Use();
  const sessionStatus = directMessageStore.SessionStatus;

  function handleSend(data) {
    directMessageStore.SendMessage(data.messageInput);
    reset();
  }

  return (
    <StyledMessageInputContainer>
      <form onSubmit={handleSubmit(handleSend)}>
        <Controller
          name="messageInput"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              {...field}
              inputProps={{
                'aria-label': 'message-input',
              }}
              name="messageInput"
              placeholder={intl.formatMessage({ id: 'direct-message.write-a-message' })}
              multiline
              maxRows={2}
              disableUnderline={true}
              sx={{ width: '100%', padding: '4px 10px' }}
              disabled={sessionStatus != SessionStatus.CONNECTED}
              endAdornment={
                <InputAdornment position="end" margin="10px">
                  <IconButton
                    aria-label={intl.formatMessage({ id: 'direct-message.send-message' })}
                    disabled={!isDirty}
                    type="submit"
                    edge="end"
                  >
                    <StyledArrowUpward isdirty={isDirty} />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
      </form>
    </StyledMessageInputContainer>
  );
};

export default MessageInput;
