import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';

export const StyledLoadingButton = styled(LoadingButton)(
  ({ theme, color }) => `
  height: 48px;
  
  &.MuiLoadingButton-loading {
    background-color: ${theme.palette[color].main};

    .MuiLoadingButton-loadingIndicator {
      color: ${theme.palette[color].contrastText};
    }
  }
`,
);
