import React, { useEffect, useRef, useState } from 'react';
import { EMOJIWHEEL, encounterTypes } from './EncounterDictionary';
import PropTypes from 'prop-types';
import Chat from 'Core_Components/Chat/Chat';
import PyxirProfile from 'Assets/img/pyxirhead.svg';
import encounterMessageToChatConfig from 'Core_Helpers/encounterMessageToChatConfig';
import feedToChatConfig from 'Core_Helpers/feedToChatConfig';
import { BUBBLE, WHEEL } from 'Core_Components/Chat/ChatItem/ChatTypes.js';
import { RIGHT } from 'Core_Components/Chat/ChatItem/Position.js';
import { useIntl } from 'react-intl';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectNetworkGroupConfig } from 'Core_Redux/networkGroup';
import ChatHeader from './Header/ChatHeader';

const Encounter = ({ encounter, onSubmit, isLoading, nextMessage, feed, resetEncounter }) => {
  const intl = useIntl();
  const pyxirConfig = {
    avatar: PyxirProfile,
    alt: intl.formatMessage({ id: 'image.pyxir.typing' }),
    name: 'Pyxir',
    typingText: {
      left: intl.formatMessage({ id: 'common.message-from-pyxir' }),
      right: intl.formatMessage({ id: 'common.message-to' }),
    },
  };
  const chatSpeed = process.env.CHAT_DELAY || 2000;
  const networkGroupConfig = useCoreSelector(selectNetworkGroupConfig);
  const [messages, setMessages] = useState([]);
  const messagesRef = useRef(messages);
  const [status, setStatus] = useState({
    ...pyxirConfig,
    ...{
      loading: isLoading,
    },
  });
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setStatus({
      ...pyxirConfig,
      ...{
        loading: isLoading,
      },
    });
  }, [isLoading]);

  useEffect(() => {
    if (nextMessage?.length) {
      if (!handleEmojiWheelNextMessage()) {
        addMessages(nextMessage, (message) =>
          updateMessages(messagesRef.current.concat(encounterMessageToChatConfig(message, networkGroupConfig))),
        );
      }
    } else if (nextMessage) {
      triggerNextEncounter();
    }
  }, [nextMessage]);

  useEffect(() => {
    if (encounter?.chatMessages && feed && !isInitialized && networkGroupConfig) {
      setIsInitialized(true);
      // If we have a feed you just need to load that
      feed?.length ? addFeed() : addEncounter();
    }
  }, [encounter, feed, networkGroupConfig]);

  const triggerNextEncounter = () => {
    setIsInitialized(false);
    resetEncounter();
  };

  const addFeed = () => {
    // If we can't answer anything try restarting the encounter
    if (feed[feed.length - 1].responseChoices?.length) {
      updateMessages(
        messages.concat(
          feed.flatMap((chatMessage, index) =>
            feedToChatConfig(chatMessage, index === feed.length - 1, networkGroupConfig.followUpResources),
          ),
        ),
      );
    } else {
      addEncounter();
    }
  };

  function updateMessages(newState) {
    messagesRef.current = newState;
    setMessages(newState);
  }

  const addEncounter = () => {
    let combinedMessages = [];

    if (encounterTypes[encounter.encounterTypeId] === EMOJIWHEEL && !feed.length) {
      const wheelChatMessage = { type: WHEEL, ...encounter.chatMessages[0] };
      combinedMessages.push(wheelChatMessage);
    } else {
      combinedMessages.push(
        encounter.chatMessages.flatMap((chatMessage) => encounterMessageToChatConfig(chatMessage, networkGroupConfig)),
      );
    }

    addMessages(combinedMessages.flat(), (message) => updateMessages(messagesRef.current.concat(message)));
  };

  const addMessages = (messageList, updateCallback) => {
    setStatus({
      ...pyxirConfig,
      ...{
        loading: false,
      },
    });
    messageList.forEach((message, index) => {
      setTimeout(() => {
        updateCallback(message);
        if (index + 1 >= messageList.length) {
          setStatus({
            ...pyxirConfig,
            ...{
              loading: false,
            },
          });
        }
      }, index * chatSpeed);
    });
  };

  const handleEmojiWheelNextMessage = () => {
    // check if next message is an emoji wheel and reset to get the emojiwheel encounter
    const { responseChoices } = nextMessage[0];
    if (responseChoices?.length && responseChoices[0].json) {
      const parsedMessage = JSON.parse(responseChoices[0].json);
      if (parsedMessage.Slices) {
        triggerNextEncounter();
        return true;
      }
    }

    return false;
  };

  const processChat = (data) => {
    let mutableMessages = [...messages];
    const currentMessage = mutableMessages[mutableMessages.length - 1];

    if (encounterTypes[encounter.encounterTypeId] === EMOJIWHEEL && currentMessage.type === WHEEL) {
      mutableMessages[mutableMessages.length - 1] = {
        isEnabled: false,
        ...currentMessage,
      };
      mutableMessages.push({ text: data.text, type: BUBBLE, position: RIGHT });
    } else {
      mutableMessages[mutableMessages.length - 1] = { text: data.text, type: BUBBLE, position: RIGHT };
    }

    updateMessages(mutableMessages);
    onSubmit(data);
  };

  return (
    <>
      <ChatHeader />
      <Chat messages={messages} status={status} onSubmit={processChat} />
    </>
  );
};

Encounter.propTypes = {
  encounter: PropTypes.object,
  feed: PropTypes.array,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  nextMessage: PropTypes.array,
  resetEncounter: PropTypes.func,
};

export default Encounter;
