import { useState } from 'react';
import getAuthClient from 'Core_Helpers/getAuthClient';
import urlcat from 'urlcat';

const getPet = async (url) => {
  const authorizedApiClient = await getAuthClient();
  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetPet = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getPetForUser = async (userId = {}) => {
    const petUrl = `v1/pets/${userId}`;

    try {
      setIsLoading(true);
      const result = await getPet(urlcat(process.env.API_BASE_URL, petUrl));
      setIsLoading(false);
      return result;
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  };

  return {
    isLoading,
    getPetForUser,
  };
};

export default useGetPet;
