import React from 'react';
import { StyledSegmentShapeWrapper } from '../../styles';
import { results_bar } from 'Assets/img/screening-icons';
import SegmentShape from '../SegmentShape/SegmentShape';
import PropTypes from 'prop-types';

const ResultsBar = ({ segments, scoreIndex }) => {
  return (
    <StyledSegmentShapeWrapper>
      <SegmentShape svg={results_bar} segments={segments} currentSegment={scoreIndex} />
    </StyledSegmentShapeWrapper>
  );
};

ResultsBar.propTypes = {
  segments: PropTypes.arrayOf(PropTypes.shape({ color: PropTypes.string, label: PropTypes.string })),
  scoreIndex: PropTypes.number,
};

export default ResultsBar;
