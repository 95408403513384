import styled from 'styled-components';

export const SectionItemContainer = styled.span`
  color: black;
  display: grid;
  grid-template-columns: 74px 1fr;
  padding: 10px 0 10px 16px;
  margin: 2px 2px;
`;

export const SectionItemIcon = styled.i`
  margin: auto;
  font-size: 1.5625rem;
  color: ${(props) => (props.customColor ? '#3F447D' : '')};
`;

export const SectionItemLabel = styled.span`
  color: #216a9e;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  line-height: 27px;
`;
