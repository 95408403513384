import styled from '@emotion/styled';
import { legacyBackgroundColors } from 'Styles/colors';

export const StyledRemindersIcon = styled.button`
  position: absolute;
  right: 18px;
  bottom: 22px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  text-align: center;
  background-color: ${legacyBackgroundColors.indigo};
  z-index: 2;
  filter: drop-shadow(0px 2px 3px grey);
  span {
    color: white;
    font-size: 32px;
    line-height: 58px;
  }
`;
