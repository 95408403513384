import { calmMind } from 'Assets/img/my-tools-icons';

export const calmYourMindEs = {
  key: 'calm-your-mind',
  title: 'Calma Tu Mente',
  titleImage: calmMind,
  titleImageAlt: '',
  description:
    'Pasar tiempo en la naturaleza puede ser una forma inspiradora de refrescar tu bienestar mental, emocional y físico.',
  contentId: 10,
  sections: [
    {
      key: 'plan-walk',
      header: 'Piensa en una zona bonita al aire libre y planea ir allí a dar un paseo.',
      description: 'Intenta encontrar un entorno tranquilo y natural y date un paseo relajado por ahí.',
      itemsHeader: 'Cuéntame más:',
      items: [
        {
          text: 'Elige un lugar cercano, como un bosque, un parque, una zona de actividades al aire libre, una playa, un camino de montaña, una zona rural, etc.',
        },
        {
          text: 'Planifica con antelación para estar seguro mientras caminas; vístete cómodamente, lleva un calzado adecuado para caminar, lleva agua y algo de comer y, por supuesto, averigua sobre las condiciones meteorológicas.',
        },
        { text: 'La duración del paseo depende de ti.' },
      ],
    },
    {
      key: 'take-walk',
      header: 'Visita el lugar y date un relajante paseo por ahí.',
      description:
        'Explora el lugar de forma consciente y permítete disfrutarlo tranquilamente, sin prisas y apreciando el entorno.',
      itemsHeader: 'Cuéntame más:',
      items: [
        {
          text: 'Consejos útiles para visitar y pasear por la zona de forma consciente:',
          subItems: [
            {
              text: 'Antes de empezar a caminar, pon tu celular en silencio y tómate unos momentos para tranquilizar tu mente.',
            },
            {
              text: 'Dirige tu atención al entorno natural que te rodea y respira profundamente unas cuantas veces. Disfruta el hecho de que te encuentras en ese lugar.',
            },
            {
              text: 'Abre el corazón mientras caminas a un ritmo que te permita disfrutar que estás ahí, seguro y consciente del entorno más amplio que te rodea. Continúa durante todo el tiempo que te sientas cómodo.',
            },
            {
              text: 'Haz una pausa de vez en cuando para permitirte apreciar el entorno. Si algo te llama la atención, date tiempo para verlo y reconocerlo. Deja que el entorno se vaya mostrando ante ti de forma fluida y fácil mientras caminas.',
            },
            {
              text: 'Abre tus sentidos al exterior; busca estar en sintonía con tu entorno utilizando todo tu cuerpo y siente cada sensación.Fíjate en cómo se siente el aire: ¿es cálido o fresco? ¿Húmedo o seco? Fíjate en los sonidos que oyes y en los aromas que hueles.',
            },
            {
              text: 'Simplemente fíjate en tus sensaciones sin juzgarlas ni etiquetarlas, y sin distraerte por pensar en si las encuentras agradables o desagradables.',
            },
            {
              text: 'Considera la posibilidad de salirte del “horario normal” y abrirte a los ritmos orgánicos de la naturaleza. Fíjate en cómo te sientes al conectar con la naturaleza.',
            },
            {
              text: 'Al final de tu paseo, quédate quieto un momento. Fíjate en cómo te sientes. Presta atención a cada uno de tus sentidos. Observa lo que percibes y tu estado de ánimo.',
            },
            {
              text: 'Termina la caminata mostrando gratitud y aprecio a la naturaleza, al entorno y a tu cuerpo.',
            },
          ],
        },
      ],
    },
    {
      key: 'self-reflection',
      header: 'Autorreflexión ',
      description: 'Tómate un tiempo para revisar la experiencia con esta actividad.',
      itemsHeader: 'Percepción y conciencia',
      items: [
        { text: '¿Qué has aprendido de este ejercicio?' },
        { text: '¿Cómo te sentías antes de completar este ejercicio?' },
        { text: '¿Cómo te sientes después de hacerlo?' },
        { text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?' },
        { text: '¿Qué disfrutaste más de este ejercicio?' },
        { text: '¿Qué ayudaría a facilitar el primer paso?' },
        { text: '¿Cuáles serían algunos de los beneficios de realizar esta actividad con más frecuencia en general?' },
      ],
    },
    {
      key: 'self-reflection-pointers',
      itemsHeader: 'Marcadores del camino ',
      items: [
        {
          text: 'Caminar tranquilamente por la naturaleza puede contribuir a refrescar la mente, el cuerpo y las emociones de forma saludable.',
        },
        {
          text: 'Pasar tiempo en la naturaleza puede contribuir a enfocarse en el momento presente y proporcionar un espacio de apoyo para reconocer y aceptar con calma los sentimientos, los pensamientos y las sensaciones físicas.',
        },
        {
          text: 'La belleza de la naturaleza puede ayudarnos a conectar con los ritmos naturales del mundo y puede brindar una experiencia enriquecedora y asombrosa que fomente la felicidad, la compasión y la interconexión.',
        },
        {
          text: 'Las transiciones diarias de la naturaleza y su abundancia pueden ayudarnos a recordar el potencial que tenemos para adaptarnos, colaborar, resolver problemas y evolucionar.',
        },
        {
          text: 'Tómate un tiempo al aire libre en la naturaleza para permitirte una renovación, y así regenerar y aumentar tu bienestar en tu camino a la prosperidad.',
        },
      ],
    },
  ],
};
