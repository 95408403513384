import { ApiService } from 'Core_Helpers/ApiService';

export class ScreeningResponseApiDataService {
  private static _instance: ScreeningResponseApiDataService = new ScreeningResponseApiDataService();
  private api: ApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (ScreeningResponseApiDataService._instance) {
      return ScreeningResponseApiDataService._instance;
    }
    this.api = new ApiService();
  }

  public startScreeningResponse = async (request: { userId: number; screeningGuid: string }) => {
    if (!request?.userId) return;
    const { response } = await this.api.post('/v1/screeningresponse/start', null, request);
    return response;
  };

  public completeScreeningResponse = async (request: {
    userId: number;
    screeningResponseGuid: string;
    answers: Array<string>;
  }) => {
    if (!request?.userId) return;
    if (!request?.screeningResponseGuid) return;
    const { screeningResponseGuid, ...screeningRequest } = request;
    const { success } = await this.api.put(
      '/v2/screeningresponse/:screeningResponseGuid/complete',
      { screeningResponseGuid },
      screeningRequest,
    );
    return success;
  };
}
