import { Singleton } from 'Core_Helpers/Singleton';
import { IStateStore } from 'Core_Helpers/IStateStore';
import { HolidayContentModalModuleStore } from './HolidayContent/HolidayContentModalModuleStore';
import { WhatsNewModalModuleStore } from './WhatsNew/WhatsNewModalModuleStore';
import { UpdateAppPromptModuleStore } from 'Stores/UpdateAppPromptModuleStore';

/*
// The purpose of this store is to manage "app wide" modals.
// This store will determine which modals are shown and the order of presedence they have.
*/

export class ModalModuleStore extends Singleton implements IStateStore {
  private HolidayContentModalModuleStore = HolidayContentModalModuleStore.Instance();
  private WhatsNewModalModuleStore = WhatsNewModalModuleStore.Instance();
  private updateAppModalStore = UpdateAppPromptModuleStore.Instance();
  private modalsShownCount = 0;

  constructor() {
    super();
  }

  public Use = () => {
    return this;
  };

  public async initialize() {
    // the order here dictates the precedence of modals
    if (await this.updateAppModalStore.readyForDisplay()) {
      this.updateAppModalStore.open();
    } else if (this.modalsShownCount == 0 && this.WhatsNewModalModuleStore.readyForDisplay()) {
      this.WhatsNewModalModuleStore.open();
      this.modalsShownCount = this.modalsShownCount + 1;
    } else if (this.modalsShownCount == 0 && this.HolidayContentModalModuleStore.readyForDisplay()) {
      this.HolidayContentModalModuleStore.open();
      this.modalsShownCount = this.modalsShownCount + 1;
    }
  }
}
