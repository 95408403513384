import { bodyPart } from '../../../../constants/bodyPart';
import { gameImportType } from '../../../../constants/gameImportType';
import { actorType } from '../../../../constants/actorType';
import { Config as movementConfig } from './objectData/movementConfig';

export const puppyAssetConfig = {
  NAME: 'PUPPY',
  ACTORTYPE: actorType.OBJECT,
  DATA: [movementConfig],
  TYPE: gameImportType.SPRITESHEET,
  SPRITESHEET_FOLDER: 'puppy',
  SPRITESHEET_NAME: 'Puppy-Sprite-Map-',
  INVENTORY: {
    [bodyPart.MOUTH]: [],
    [bodyPart.NECK]: [],
  },
  AUDIO: {},
  SCALE: 0.6,
  HEIGHT: 800,
  WIDTH: 800,
};
