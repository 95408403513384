import React from 'react';
import PropTypes from 'prop-types';
import { StyledCharacter } from './styles';
import GreetingPyxir from 'Assets/img/greeting-pyxir.svg';
import { transformText } from 'Core_Helpers/transformText';
import { useIntl } from 'react-intl';
import HeaderBar from 'Core_Components/HeaderBar/HeaderBar';
import { Typography } from '@mui/material';

function GreetingHeader({ syncUser }) {
  const intl = useIntl();
  return (
    <HeaderBar>
      <StyledCharacter src={GreetingPyxir} alt={intl.formatMessage({ id: 'greeting.pyxir' })} />
      <Typography align="center" fontSize={18} fontWeight={700}>
        {transformText(intl.formatMessage({ id: 'explore.header' }), syncUser)}
      </Typography>
    </HeaderBar>
  );
}

GreetingHeader.propTypes = {
  syncUser: PropTypes.object,
};

export default GreetingHeader;
