import React from 'react';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import { selectSignupMetadata, completePronounMaritalEntry, skipPronounMarital } from 'Onboard_Redux/signup';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { Header, Select } from 'Onboard_Components/Account/styles';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Button from 'Stories/components/Button';
import SkipButton from 'Onboard_Components/Account/SignUp/SkipButton';
import { purple } from 'Styles/colors';

function PronounMartialStatus() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const { progress } = useOnboardSelector(selectSignupMetadata);
  const {
    control,
    formState: { dirtyFields },
    handleSubmit,
  } = useForm();

  function onSkip() {
    dispatch(skipPronounMarital());
  }

  const pronounOptions = [
    { pronounOption: intl.formatMessage({ id: 'account.sign-up.pronouns-he' }), code: 'HE' },
    { pronounOption: intl.formatMessage({ id: 'account.sign-up.pronouns-she' }), code: 'SHE' },
    { pronounOption: intl.formatMessage({ id: 'account.sign-up.pronouns-they' }), code: 'THEY' },
    { pronounOption: intl.formatMessage({ id: 'account.sign-up.prefer-not-to-specify' }), code: 'UNSPECIFIED' },
  ];

  const maritalStatus = [
    { status: intl.formatMessage({ id: 'account.sign-up.marital-status-single' }), code: 'SINGLE' },
    { status: intl.formatMessage({ id: 'account.sign-up.marital-status-married' }), code: 'MARRIED' },
    { status: intl.formatMessage({ id: 'account.sign-up.marital-status-widowed' }), code: 'WIDOWED' },
    { status: intl.formatMessage({ id: 'account.sign-up.marital-status-divorced' }), code: 'DIVORCED' },
  ];

  const formComplete = dirtyFields.preferredPronouns || dirtyFields.maritalStatus;

  function onSubmit(data) {
    dispatch(
      completePronounMaritalEntry({ preferredPronouns: data.preferredPronouns, maritalStatus: data.maritalStatus }),
    );
  }

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <Header>{intl.formatMessage({ id: 'account.sign-up.a-little-about-you' })}</Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="preferredPronouns"
          control={control}
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <FormControl fullWidth variant="filled">
              <InputLabel sx={{ color: purple._900 }}>
                {intl.formatMessage({ id: 'account.sign-up.preferred-pronouns' })}
              </InputLabel>
              <Select onChange={onChange} value={value}>
                {pronounOptions.map((o) => (
                  <MenuItem key={o.code} value={o.code}>
                    {o.pronounOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="maritalStatus"
          control={control}
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <FormControl fullWidth variant="filled">
              <InputLabel sx={{ color: purple._900 }}>
                {intl.formatMessage({ id: 'account.sign-up.marital-status' })}
              </InputLabel>
              <Select onChange={onChange} value={value}>
                {maritalStatus.map((o) => (
                  <MenuItem key={o.code} value={o.code}>
                    {o.status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Button fullWidth type="submit" disabled={!formComplete} sx={{ margin: '8px 0' }}>
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
      <SkipButton onClick={onSkip}>{intl.formatMessage({ id: 'common.skip' })}</SkipButton>
    </>
  );
}

export default PronounMartialStatus;
