import styled from '@emotion/styled';

export const StyledSingleSelectQuestion = styled.div`
  .FormQuestion-root {
    height: 425px;
    width: 285px;
    background-color: #565c89;
    display: grid;
    grid-template-rows: min-content auto 50px;
    border-radius: 4px;
    box-shadow: 0 12px 0 -10px grey, 0 16px 0 -12px lightgrey, 0 18px 0 -13px grey, 0 22px 0 -15px lightgrey,
      0 24px 0 -16px grey;
    margin-bottom: 24px;
    padding: 12px 24px 0;
  }

  [id='0c8594dc-9387-47d1-96f0-ceff0f018c5e-form-question'] {
    height: 440px;
    padding: 12px 12px 0;
  }

  [id='1bd482d8-c840-4b0f-99bf-5d8c3881a817-form-question'] {
    padding: 6px 12px 0;
  }

  [id='5abc3255-cdcb-4757-826d-28813a5e6d7e-skip-button'] {
    padding: 0px 6px 14px;
  }

  .FormQuestion-question {
    font-weight: 700;
    text-align: center;
    font-size: 24px;
  }

  [id='06c25395-0d2a-48d2-8f40-c5c8ca2c3863-question'],
  [id='1873b467-3f37-4569-861d-df63fa1ac141-question'],
  [id='1d7b1e1e-5316-4c8d-ab59-4e461d079fb8-question'],
  [id='b1770566-cf3f-45ed-9887-b43321f20be2-question'],
  [id='59d7fdef-d4d6-419f-951e-4da7913fab4e-question'] {
    margin: 6px 0 24px 0;
  }

  [id='1bd482d8-c840-4b0f-99bf-5d8c3881a817-question'] {
    margin: 6px 0 6px 0;
  }

  [id='0c8594dc-9387-47d1-96f0-ceff0f018c5e-question'],
  [id='d1365a6a-aa1b-4a1b-89ce-0bc451379235-question'] {
    font-size: 16px;
    padding: 6px 0;
  }

  [id='1d7b1e1e-5316-4c8d-ab59-4e461d079fb8-answer-group'] {
    height: 200px;
  }

  .MuiFormControlLabel-root {
    margin: 0;
    padding: 0;
    width: 100%;
    justify-content: flex-start;
  }

  .MuiFormControlLabel-label {
    font-size: 18px;
    font-weight: 700;
  }

  .MuiRadio-root {
    color: white;
    padding: 6px;
    margin: 0;
  }

  .MuiRadio-root.Mui-checked {
    color: #29265e;
  }

  .MuiSvgIcon-root {
    border-radius: 50%;
    background-color: white;
    path: {
      transform: translate(-2px, -2px) scale(1.2);
    }
  }
`;
