export const LANGUAGE_SELECT = 'LanguageSelect';
export const NAME = 'Name';
export const CREDENTIALS = 'Credentials';
export const PHONE = 'Phone';
export const MATCHING_PROFILE = 'MatchingProfile';
export const EMAIL = 'Email';
export const MATCHING_EMAIL = 'MatchingEmail';
export const OTP = 'SignUpOtp';
export const BIRTHDAY = 'Birthday';
export const HEALTH_PLAN_INFORMATION = 'HealthPlanInformation';
export const AGREEMENTS = 'Agreements';
export const PRONOUN_MARITAL = 'PronounMaritalStatus';
export const FAVORITE_HOLIDAY = 'FavoriteHoliday';
export const PARENTAL_STATUS = 'ParentalStatus';
export const HOW_DID_YOU_HEAR_ABOUT_US = 'HowDidYouHearAboutUs';

export const stepToComponentMap = {};
