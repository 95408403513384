import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const StyledSelectionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 100px 0;
`;

export const StyledHeaderImage = styled.img`
  max-width: 600px;
  width: 100%;
  height: auto;
  align-self: center;
`;

export const StyledImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledDogButton = styled(Button)`
  padding: 0;
  margin-left: 20px;
  margin-right: 10px;
  border-radius: 15px;
  box-shadow: 0 5px 15px -7px rgba(0, 0, 0, 0.75);
`;

export const StyledCatButton = styled(Button)`
  padding: 0;
  margin-left: 10px;
  margin-right: 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px -7px rgba(0, 0, 0, 0.75);
`;
