import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { Header } from 'Onboard_Components/Account/styles';
import { ArrowBackIos } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import { navigateBack, selectSignupMetadata, selectContact, phoneTypes } from 'Onboard_Redux/signup';
import { useGetOTPMutation, useVerifyOTPMutation } from 'Onboard_Redux/services/identity';
import { OtpForm, OtpFooter } from 'Onboard_Components/Account/Otp';
import Snackbar from 'Onboard_Components/Account/Snackbar';

function SignUpOtp() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const { progress } = useOnboardSelector(selectSignupMetadata);
  const { phoneType, phoneNumber, emailAddress } = useOnboardSelector(selectContact);
  const [getOTP] = useGetOTPMutation();
  const [verifyOTP, { isLoading, error: incorrectCodeError }] = useVerifyOTPMutation();
  const [codeSent, setCodeSent] = useState(false);
  const isLandline = phoneType === phoneTypes.LANDLINE;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm();

  const otpVerificationError =
    incorrectCodeError?.data?.status === 400 || (errors.otp && errors.otp.message)
      ? intl.formatMessage({ id: 'error.incorrect-code' })
      : null;

  useEffect(() => {
    getOTP({ emailOrPhoneNumber: emailAddress ? emailAddress : phoneNumber });
  }, []);

  function onBack() {
    dispatch(navigateBack());
  }

  function onResend() {
    getOTP({ emailOrPhoneNumber: emailAddress ? emailAddress : phoneNumber }).then(() => setCodeSent(true));
  }

  async function onContinue(data) {
    await verifyOTP({ emailOrPhoneNumber: emailAddress ? emailAddress : phoneNumber, code: data.otp });
  }

  const formComplete = !!dirtyFields.otp;

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <Header>
        <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />
        {intl.formatMessage({ id: 'account.otp.enter' })}
      </Header>
      <OtpForm
        control={control}
        loading={isLoading}
        formComplete={formComplete}
        onSubmit={handleSubmit(onContinue)}
        otpVerificationError={otpVerificationError}
        onResend={onResend}
      />
      <OtpFooter
        sentTo={isLandline ? emailAddress : phoneNumber}
        contactType={isLandline ? 'email' : 'phone'}
        onBack={onBack}
      />
      <Snackbar
        message={intl.formatMessage({ id: 'account.otp.code-resent' })}
        open={codeSent}
        onClose={() => setCodeSent(false)}
      />
    </>
  );
}

export default SignUpOtp;
