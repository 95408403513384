import { reframeJudgements } from 'Assets/img/my-tools-icons';

export const reframeJudgementsEs = {
  key: 'reframe-judgements',
  title: 'Reformular los Juicios',
  titleImage: reframeJudgements,
  titleImageAlt: '',
  description:
    'Cambiar los pensamientos negativos por unos más constructivos puede ayudar a incrementar el bienestar general.',
  contentId: 9,
  sections: [
    {
      key: 'create-chart',
      header: 'Crea una tabla con 4 columnas para comenzar.',
      description: 'Usa lápiz y papel, o tu computadora/dispositivo.',
      itemsHeader: 'Cuéntame más',
      items: [
        { text: 'Nombra a la columna 1 “Situación”' },
        { text: 'Nombra a la columna 2 “Mi juicio inicial”' },
        { text: 'Nombra a la columna 3 “Mi emoción o sentimiento”' },
        { text: 'Nombra a la columna 4 “Pensamientos reformulados”' },
        { text: 'Crea filas horizontales en las 4 columnas para empezar. Puedes agregar más después.' },
      ],
    },
    {
      key: 'write-negative-judgements',
      header: 'Luego, en la siguiente semana, escribe los juicios negativos en la tabla.',
      description:
        'Anota cuando tengas juicios negativos sobre personas, grupos, ti mismo, situaciones, etc., y escríbelos en la tabla. No pasa nada si no los recuerdas todos.',
      itemsHeader: 'Cuéntame más',
      items: [
        { text: 'En la columna 1, describe qué provocó el juicio.' },
        {
          text: 'En la columna 2, escribe tu juicio inicial respecto a ello.',
        },
        {
          text: 'Puedes ser breve o tan detallado como quieras.',
        },
      ],
    },
    {
      key: 'write-emotions',
      header: 'Después, piensa cómo tu juicio y pensamientos afectaron tus emociones.',
      description:
        'En la columna 3, escribe los sentimientos o emociones que tuviste después de tus pensamientos sobre la situación.',
      itemsHeader: 'Cuéntame más',
      items: [
        { text: 'Con una mente abierta, anota cómo los pensamientos sobre la situación te hicieron sentir.' },
        {
          text: 'Nota si tus pensamientos sobre la situación te brindaron pensamientos útiles o positivos, o bien emociones desagradables y negativas.',
        },
        {
          text: 'Recuerda cualquier sensación física que sentiste en el cuerpo en ese momento y cómo recuerdas ahora esos eventos.',
        },
      ],
    },
    {
      key: 'reframe-negative-thoughts',
      header: 'Después, reformula los pensamientos negativos para que sean más constructivos.',
      description:
        'En la columna 4, escribe otros modos en los que pensar sobre la situación de la columna 1 que sean más positivos para tu bienestar emocional.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Piensa en pensamientos positivos, constructivos o realistas sobre la situación para reformular los juicios no constructivoso los pensamientos negativos.',
        },
        {
          text: 'Sugerencias útiles: Algunas preguntas sobre reformular pensamientos...',
          subItems: [
            {
              text: '¿Puedo ver esta situación como una experiencia de aprendizaje desde la que crecer o más bien como una amenaza que atacar?',
            },
            { text: '¿Qué consejo le daría a mi mejor amigo si estuviera pasando por una situación similar?' },
            { text: '¿Qué beneficio me brinda esta situación?' },
            { text: '¿Este pensamiento es real?' },
            { text: '¿Merece la pena gastar energía así?' },
            { text: '¿De qué otros modos puedo ver esta situación para que me ayude?' },
            { text: '¿Qué obtengo de este juicio o pensamiento? ¿Qué pierdo?' },
            { text: '¿Qué pensaré de esta situación en una semana?' },
            { text: '¿Es útil entrar en bucle con este pensamiento?' },
          ],
        },
      ],
    },
    {
      key: 'self-reflection',
      header: 'Autorreflexión',
      description: 'Tómate un tiempo para revisar la experiencia con esta actividad.',
      itemsHeader: 'Percepción y conciencia',
      items: [
        {
          text: '¿Qué has aprendido de este ejercicio?',
        },
        { text: '¿Cómo te sentías antes de completar este ejercicio?' },
        { text: '¿Cómo te sientes después de hacerlo?' },
        { text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?' },
        { text: '¿Qué disfrutaste más de este ejercicio?' },
        {
          text: '¿Cuáles serían algunos de los beneficios de que las personas realicen esta actividad con más frecuencia en general?',
        },
      ],
    },
    {
      key: 'self-reflection-pointers',
      itemsHeader: 'Marcadores del camino',
      items: [
        {
          text: 'Puesto que las emociones siguen a los pensamientos, los juicios o pensamientos negativos pueden afectar en gran medida cómo nos sentimos sin darnos cuenta.',
        },
        {
          text: 'Una reformulación consciente de los eventos negativos ayuda a lograr un equilibrio emocional y modifica las reacciones negativas sin cambiar la situación.',
        },
        {
          text: 'Sé amable contigo mismo cuando aprendas a reformular los patrones de pensamiento. Resulta útil ver la reformulación como la obtención de hábitos positivos en lugar de como la necesidad de acabar con la negatividad.',
        },
        { text: 'Concédete tiempo a medida que tomas nuevos pasos hacia el bienestar en tu Camino para triunfar.' },
      ],
    },
  ],
};
