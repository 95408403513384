import { threeGoodThings } from 'Assets/img/my-tools-icons';

export const threeGoodThingsEn = {
  key: 'three-good-things',
  title: '3 Good Things',
  titleImage: threeGoodThings,
  titleImageAlt: '',
  description:
    'Taking time to recall people, events, or things that made us feel good can boost feelings of well-being!',
  contentId: 5,
  sections: [
    {
      key: 'bed-good-things',
      header: 'Before you go to bed, think about just 3 good things that happened today.',
      description: '',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'At the end of the day, especially before you go to bed, take a few moments to think about 3 good things you experienced today.',
        },
        {
          text: 'Good things occur in big or small ways like drinking a nice cup of coffee, or having someone open the door for you, or even having a short chat with someone in line at the grocery store checkout!',
        },
        {
          text: 'Examples you could ask yourself are:',
          subItems: [
            { text: 'What 3 things went right today?' },
            { text: 'What did I experience that made me feel good?' },
            { text: 'What happened that was positive?' },
          ],
        },
      ],
    },
    {
      key: 'write-down',
      header: 'Write those things down.',
      description: '',
      itemsHeader: 'Tell me more',
      items: [
        { text: 'Write them down either using paper/pen or your computer/device.' },
        { text: 'List them from your personal perspective only.' },
        { text: 'Spelling and grammar do not matter!' },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Self-Reflection',
      description: 'Then take some quiet time to reflect on each of those things and review your experience.',
      itemsHeader: 'Insights & pointers',
      items: [
        { text: 'How did they make you feel at the time?' },
        { text: 'How do they make you feel now as you recall them?' },
        { text: 'Do you notice a physical sensation in your body?' },
        { text: 'What was your part in having those good things happen?', divider: true },
        {
          text: 'Becoming mindful about good things and events that happen can create more gratitude and appreciation in life. Even small, positive things can help you become stronger when challenges happen.',
        },
        {
          text: 'Before you go to bed tonight, empower your Thrive Pathway! Write down your personal 3 Good Things for today.',
        },
      ],
    },
  ],
};
