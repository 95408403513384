import { getDefaultStore } from 'jotai';
import { experienceAtom } from '../atoms/experienceAtom';

export class ExperienceStore {
  experience;
  atomStore;

  constructor() {
    this.atomStore = getDefaultStore();
    this.experience = this.atomStore.get(experienceAtom);
  }

  updateExperience(experienceVal) {
    this.atomStore.set(experienceAtom, experienceVal);
  }

  getExperience() {
    return this.atomStore.get(experienceAtom);
  }
}
