import styled from '@emotion/styled';

export const StyledMessageReceived = styled.div`
  background-color: #f0eeef;
  color: black;
  padding: 4px 8px 4px 8px;
  margin: 0 4px 4px 6px;
  border-radius: 4px;
  position: relative;
  max-width: 95%;
  float: left;
`;

export const StyledMessageSent = styled.div`
  background-color: #007aff;
  color: white;
  padding: 4px 8px 4px 8px;
  margin: 0 4px 4px 6px;
  border-radius: 4px;
  position: relative;
  max-width: 95%;
  float: right;
`;

export const StyledMessageReceivedWrapper = styled.div`
  display: block;
`;

export const StyledMessageSentWrapper = styled.div`
  display: block;
`;
