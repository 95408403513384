import LanguageSelect from 'Onboard_Components/Account/SignUp/LanguageSelect';
import Name from 'Onboard_Components/Account/SignUp/Name';
import Credentials from 'Onboard_Components/Account/SignUp/Credentials';
import Phone from 'Onboard_Components/Account/SignUp/Phone';
import Email from 'Onboard_Components/Account/SignUp/Email';
import MatchingEmail from 'Onboard_Components/Account/SignUp/MatchingEmail';
import SignUpOtp from 'Onboard_Components/Account/SignUp/SignUpOtp';
import Birthday from 'Onboard_Components/Account/SignUp/Birthday';
import HealthPlanInformation from 'Onboard_Components/Account/SignUp/HealthPlanInformation';
import PartnerCode from 'Onboard_Components/Account/SignUp/PartnerCode';
import Agreements from 'Onboard_Components/Account/SignUp/Agreements';
import MatchingProfile from 'Onboard_Components/Account/SignUp/MatchingProfile';
import PronounMaritalStatus from 'Onboard_Components/Account/SignUp/PronounMaritalStatus';
import FavoriteHoliday from 'Onboard_Components/Account/SignUp/FavoriteHoliday';
import ParentalStatus from 'Onboard_Components/Account/SignUp/ParentalStatus';
import HowDidYouHearAboutUs from 'Onboard_Components/Account/SignUp/HowDidYouHearAboutUs';

export const LANGUAGE_SELECT = 'LanguageSelect';
export const NAME = 'Name';
export const CREDENTIALS = 'Credentials';
export const PHONE = 'Phone';
export const MATCHING_PROFILE = 'MatchingProfile';
export const EMAIL = 'Email';
export const MATCHING_EMAIL = 'MatchingEmail';
export const OTP = 'SignUpOtp';
export const BIRTHDAY = 'Birthday';
export const HEALTH_PLAN_INFORMATION = 'HealthPlanInformation';
export const PARTNER_CODE = 'PartnerCode';
export const AGREEMENTS = 'Agreements';
export const PRONOUN_MARITAL = 'PronounMaritalStatus';
export const FAVORITE_HOLIDAY = 'FavoriteHoliday';
export const PARENTAL_STATUS = 'ParentalStatus';
export const HOW_DID_YOU_HEAR_ABOUT_US = 'HowDidYouHearAboutUs';

export const stepToComponentMap = {
  [LANGUAGE_SELECT]: LanguageSelect,
  [NAME]: Name,
  [CREDENTIALS]: Credentials,
  [PHONE]: Phone,
  [MATCHING_PROFILE]: MatchingProfile,
  [EMAIL]: Email,
  [MATCHING_EMAIL]: MatchingEmail,
  [OTP]: SignUpOtp,
  [BIRTHDAY]: Birthday,
  [HEALTH_PLAN_INFORMATION]: HealthPlanInformation,
  [PARTNER_CODE]: PartnerCode,
  [AGREEMENTS]: Agreements,
  [PRONOUN_MARITAL]: PronounMaritalStatus,
  [FAVORITE_HOLIDAY]: FavoriteHoliday,
  [PARENTAL_STATUS]: ParentalStatus,
  [HOW_DID_YOU_HEAR_ABOUT_US]: HowDidYouHearAboutUs,
};
