import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { InputProps } from './props';
import { ErrorAdornment } from './Adornments';
import { TextField, PrimaryTextField } from './styles';

const TextInput = forwardRef(({ color, helperText, errorMessage, ...props }, ref) => {
  const storyProps = {
    inputRef: ref,
    color: color,
    helperText: errorMessage || helperText,
    error: !!errorMessage,
    InputProps: {
      endAdornment: errorMessage && <ErrorAdornment />,
    },
    ...props,
  };

  if (color === InputProps.color.PRIMARY) {
    return <PrimaryTextField {...storyProps} />;
  }

  return <TextField {...storyProps} />;
});

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
  color: PropTypes.oneOf(Object.values(InputProps.color)),
  variant: PropTypes.oneOf(Object.values(InputProps.variant)),
  type: PropTypes.oneOf(Object.values(InputProps.type)),
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
};

TextInput.defaultProps = {
  color: InputProps.color.PRIMARY,
  variant: InputProps.variant.OUTLINED,
  type: InputProps.type.TEXT,
};

export default TextInput;
