export const DIMENSION = {
  SOCIAL: 'social',
  EMOTIONAL: 'emotional',
  PHYSICAL: 'physical',
  INTELLECTUAL: 'intellectual',
};

export const mockScoreMessaging = {
  en: {
    social: {
      negative: 'Build a social support network.',
      neutral: 'Feel more connected to others.',
      positive: 'Make meaningful connections.',
    },
    emotional: {
      negative: 'Feel more emotionally balanced & uplifted.',
      neutral: 'Feel stress-free & balanced.',
      positive: 'Express gratitude & self-appreciation.',
    },
    physical: {
      negative: 'Boost physical well-being.',
      neutral: 'Engage movement & fun activity.',
      positive: 'Refresh naturally.',
    },
    intellectual: {
      negative: 'Reframe negative thoughts.',
      neutral: 'Boost motivation & self-awareness.',
      positive: 'Feel more inspired.',
    },
  },
  es: {
    social: {
      negative: 'Construir una red de apoyo social.',
      neutral: 'Sentirte más conectado con los demás.',
      positive: 'Establecer conexiones significativas.',
    },
    emotional: {
      negative: 'Sentirte más equilibrado y animado emocionalmente.',
      neutral: 'Sentirte libre de estrés y equilibrado.',
      positive: 'Expresar gratitud y aprecio por ti mismo.',
    },
    physical: {
      negative: 'Aumentar el bienestar físico.',
      neutral: 'Fomentar la actividad física y la diversión.',
      positive: 'Refrescarte de forma natural.',
    },
    intellectual: {
      negative: 'Reformular los pensamientos negativos.',
      neutral: 'Aumentar la motivación y el autoconocimiento.',
      positive: 'Sentirte más inspirado.',
    },
  },
};
