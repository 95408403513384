import React, { useEffect } from 'react';
import Routes from 'Core_Pages/Routes/Routes';
import { browserHistory } from 'Core_Helpers/AppInsights';
import {
  patchSyncUser,
  selectSyncUser,
  selectSyncUserPending,
  selectUserInfo,
  selectUserInfoPending,
} from 'Core_Redux/user';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import {
  useLazySynchronizeUserQuery,
  useLazyGetNetworkGroupConfigQuery,
  useGetAgreementsMutation,
  useLazyGetUserMetaQuery,
} from 'Core_Redux/services/api';
import { selectAuthenticationStatus, authenticationStatusUpdated } from 'Core_Redux/authentication';
import FullScreenLoading from './components/FullScreenLoading/FullScreenLoading';
import { UserResourcesStore } from 'Core_Services/userResources/UserResourcesStore';
import { DirectMessageStore } from 'Core_Pages/DirectMessage/Store/DirectMessageStore';
import SessionExpiredModal from 'Core_Pages/DirectMessage/Components/Modals/SessionExpiredModal';
import { ModalModuleStore } from 'Core_Pages/Home/Modals/ModalModuleStore';
import { LanguageService } from 'Core_Helpers/LanguageService';

const Core = () => {
  const appUserInfo = useCoreSelector(selectUserInfo);
  const syncUser = useCoreSelector(selectSyncUser);
  const syncUserPending = useCoreSelector(selectSyncUserPending);
  const userInfoPending = useCoreSelector(selectUserInfoPending);
  const dispatch = useCoreDispatch();
  const [synchronizeUser] = useLazySynchronizeUserQuery();
  const [getNetworkGroupConfig] = useLazyGetNetworkGroupConfigQuery();
  const [getAgreements] = useGetAgreementsMutation();
  const [getUserMeta] = useLazyGetUserMetaQuery();
  const authenticationStatus = useCoreSelector(selectAuthenticationStatus);
  const isLoading = syncUserPending || userInfoPending;
  const languageService = new LanguageService();

  const directMessageStore = DirectMessageStore.Instance().Use();
  const userResourceStore = UserResourcesStore.Instance();
  const hasDirectMessageUserResource = userResourceStore.Use().HasDirectMessageUserResource;

  const modalModuleStore = ModalModuleStore.Instance().Use();
  modalModuleStore.initialize();

  useEffect(() => {
    dispatch(authenticationStatusUpdated({ history: browserHistory }));
  }, [authenticationStatus]);

  useEffect(() => {
    if (hasDirectMessageUserResource) {
      directMessageStore.StartAgentPoolStatusMonitor();
    }
  }, [hasDirectMessageUserResource]);

  useEffect(() => {
    if (appUserInfo?.sub) {
      synchronizeUser(appUserInfo.sub);
      getNetworkGroupConfig(appUserInfo.sub);
      getAgreements(appUserInfo.sub);
      getUserMeta(appUserInfo.sub);
      userResourceStore.initialize();
    }
  }, [appUserInfo]);

  useEffect(() => {
    if (syncUser && syncUser.activeProfileId) {
      getNetworkGroupConfig(syncUser.userId);
      // Ideally, language will be updated as part of an event bus system. Implementation is scoped in ticket PYX-5057.
      languageService.updateLanguage();
    }
  }, [syncUser]);

  useEffect(() => {
    if (syncUser && appUserInfo) {
      let patchObject = {
        isNonAppUser: false,
      };
      const storedTimezone = syncUser.timeZoneId;
      const actualTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (storedTimezone != actualTimezone) {
        patchObject.timeZoneId = actualTimezone;
      }
      dispatch(patchSyncUser({ userId: +appUserInfo.sub, patchObject }));
    }
  }, [syncUser]);

  return !syncUser && isLoading ? (
    <div style={{ display: 'flex', margin: 'auto' }}>
      <FullScreenLoading showMessage={true} />;
    </div>
  ) : (
    <>
      <Routes />
      <SessionExpiredModal />
    </>
  );
};

export default Core;
