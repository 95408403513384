export const findUI = (game, sceneConfig, uiType, key) => {
  return getCurrentSceneConfig(game, sceneConfig)
    .UI.find((x) => x.TYPE == uiType && x.DATA.find((y) => y.NAME == key))
    ?.DATA.find((y) => y.NAME == key);
};

export const findUIData = (game, sceneConfig, uiType) => {
  return getCurrentSceneConfig(game, sceneConfig).UI.find((x) => x.TYPE == uiType);
};

export const findAsset = (game, sceneConfig, key) => {
  return getCurrentSceneConfig(game, sceneConfig).ASSETS[key];
};

export const getCurrentSceneConfig = (game, sceneConfig) => {
  return sceneConfig[game.sceneData.gameData.gameData.petState.petType][game.sceneData.sceneType.NAME];
};

export const findActor = (game, name) => {
  return game.engine.engineAssets.actors.find((x) => x.NAME == name);
};
