import React, { forwardRef } from 'react';
import { StyledSelect } from './styles';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';

const options = [
  { code: '1', answer: 'Answer 1' },
  { code: '2', answer: 'Answer 2' },
];

const Select = forwardRef(({ inputProps, ...props }, ref) => {
  const extraInputProps = { 'aria-labelledby': props['aria-labelledby'] };
  return (
    <StyledSelect ref={ref} inputProps={{ ...inputProps, ...extraInputProps }}>
      {options.map((o) => (
        <MenuItem key={o.code} value={o.code}>
          {o.answer}
        </MenuItem>
      ))}
    </StyledSelect>
  );
});

Select.displayName = 'Select';

Select.propTypes = {
  inputProps: PropTypes.object,
  ref: PropTypes.object,
  ['aria-labelledby']: PropTypes.string,
};

export default Select;
