import styled from 'styled-components';
import { Card } from '@mui/material';
import Button from 'Stories/components/Button';

export const StyledIcon = styled.img`
  height: 40px;
  margin-top: 10px;
`;

export const StyledCard = styled(Card)`
  boxshadow: 3;
  min-height: 160px;
  max-width: 350px;
  border-top: ${(props) => (props.hasborder ? '5px solid #5da443' : '')};
  margin: auto;
`;

export const StyledButton = styled(Button)`
  background: #5da443;
  margin: 8px;
  min-width: 125px;
`;
