import React from 'react';
import { useParams } from 'react-router-dom';
import { Tools } from './MyToolsPage/tools';

function MyTools() {
  const { toolType } = useParams();
  const ToolPage = Tools[toolType?.replace('-', '')?.toUpperCase()];

  return <ToolPage />;
}

export default MyTools;
