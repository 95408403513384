import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useMediaType from 'Core_Helpers/useMediaType';
import { breakpoints } from 'Styles/theme';
import { MyToolsGridContainer } from './styles';
import { Grid } from '@mui/material';
import VideoCard from '../../components/VideoCard/VideoCard';
import { video } from 'Assets/img/my-tools-icons';
import GradientCard from 'Core_Components/GradientCard/GradientCard';
import { MyVideoToolsModuleStore } from '../../../../Video/MyVideoToolsModuleStore';
import { black } from 'Styles/colors';
import { StyledCard, StyledToolsGrid } from '../../styles';

function VideoSelection() {
  const { id } = useParams();
  const mediaType = useMediaType();
  const videoStore = MyVideoToolsModuleStore.Instance().Use();
  const videoShowcase = videoStore.Videos;
  const videoCount = videoStore.VideoCount;
  const videosLoading = videoStore.IsLoading;

  useEffect(() => {
    if (videoCount < 1 && !videosLoading) {
      videoStore.getVideoShowcase();
    }
  }, [videoShowcase]);

  if (videosLoading || (mediaType !== breakpoints.DESKTOP && id)) {
    return <></>;
  }

  return (
    <MyToolsGridContainer
      mobile={12}
      desktop={5}
      container
      justifyContent="left"
      rowSpacing={4}
      columnSpacing={4}
      height={videosLoading ? '100%' : 'auto'}
    >
      {mediaType !== breakpoints.DESKTOP && (
        <Grid item mobile={12}>
          <GradientCard
            CardComponent={StyledCard}
            fontSize={16}
            fontWeight={400}
            color={black._100}
            backgroundColor="#FBC290"
            titleId="tools.videos.description"
            imageStyle={{ position: 'relative', width: '104px', margin: '40px 0px 10px 0px' }}
            icon={video}
          />
        </Grid>
      )}
      {videoShowcase?.map((videoItem, index) => (
        <StyledToolsGrid item key={index} mobile={6} tablet={4} desktop={5}>
          <VideoCard
            onClick={() => videoStore.watchVideo(videoItem)}
            title={videoItem.name}
            labelId={videoItem.name}
            isActive={videoItem.id === id}
          />
        </StyledToolsGrid>
      ))}
    </MyToolsGridContainer>
  );
}

export default VideoSelection;
