import styled from 'styled-components';
import { StyledContainer, StyledParagraphStacked } from 'Core_Pages/GreetingCards/featureStyles';

export const StyledParagraphStackedWithMargin = styled(StyledParagraphStacked)`
  margin-top: 45px;
`;

export const StyledContainerWithOverflow = styled(StyledContainer)`
  overflow: auto !important;
`;

export const StyledImagePreview = styled.div`
  height: inherit;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;
