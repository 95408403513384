import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { StyledButton, StyledCard, StyledIcon } from './styles';
import { andyIcon } from 'Assets/img/talk-to-someone-icons';
import ConditionalLink from 'Core_Components/ConditionalLink/ConditionalLink';

const CallCard = ({ hasBorder, isTablet }) => {
  const intl = useIntl();

  return (
    <StyledCard hasborder={hasBorder}>
      <Grid container>
        <Grid item mobile={3}>
          <StyledIcon src={andyIcon} alt={intl.formatMessage({ id: 'talk-to-someone.call.icon' })} />
        </Grid>
        <Grid item mobile={9}>
          <Grid container marginTop={'16px'}>
            <Grid item mobile={4}>
              <Typography align="left" fontSize={18} fontWeight={700}>
                {intl.formatMessage({ id: 'talk-to-someone.call' })}
              </Typography>
            </Grid>
            <Grid item mobile={8}>
              <Typography align="center" fontSize={16} fontWeight={400}>
                {intl.formatMessage({ id: 'talk-to-someone.hours-available' })}:<br />
                8am-6pm
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              mobile={12}
              container
              direction="column"
              alignItems="flex-end"
              justify="flex-start"
              marginTop={'30px'}
            >
              {isTablet ? (
                <Typography marginTop="12px" marginRight="24px" fontSize={16} fontWeight={700}>
                  1 (855) 499-4777
                </Typography>
              ) : (
                <ConditionalLink linkType={'Phone'} link={'18554994777'}>
                  <StyledButton variant="contained">
                    {intl.formatMessage({ id: 'talk-to-someone.call-us' })}
                  </StyledButton>
                </ConditionalLink>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

CallCard.propTypes = {
  hasBorder: PropTypes.bool,
  isTablet: PropTypes.bool,
};

export default CallCard;
