export const Config = {
  KEY: 'DOGBOWL',
  PETTYPE: 'DOG',
  ANIMATIONS: {
    IDLE: {
      frames: [7],
      frameRate: 5,
      repeat: 0,
    },
    EAT: {
      frames: [7, 6, 5, 4],
      frameRate: 2,
      repeat: 0,
    },
    IDLE2: {
      frames: [3],
      frameRate: 5,
      repeat: 0,
    },
    DRINK: {
      frames: [3, 2, 1, 0],
      frameRate: 2,
      repeat: 0,
    },
    DRINKHALF: {
      frames: [0, 1],
      frameRate: 2,
      repeat: 0,
    },
    DRINKREMAINING: {
      frames: [2, 3],
      frameRate: 2,
      repeat: 0,
    },
  },
  ATTACHPOINTS: {
    MOUTH: {
      0: {
        x: -0.43,
        y: -0.08,
        scale: 0.05,
      },
    },
    NECK: {
      0: {
        x: -0.15,
        y: -0.04,
        scale: 0.085,
      },
    },
  },
};
