import styled from '@emotion/styled';
import { Select } from '@mui/material';

export const StyledContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const StyledRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -10px;

  .MuiButtonBase-root {
    :first-child {
      margin-bottom: 6px;
    }
  }
`;

export const StyledSelectContainer = styled.div`
  width: 100%;
`;

export const StyledSelectRow = styled.div`
  margin: 3px 0 8px;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
`;

export const StyledSelect = styled(Select)`
  flex: 1;
  margin-left: 8px;

  @media (max-width: 359px) {
    // force 3rd element to wrap
    flex: 1 0 50%;
    :nth-of-type(2) {
      margin-left: 0;
    }
  }
`;
