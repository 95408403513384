import styled from 'styled-components';

export const CardContentContainer = styled.div`
  @media screen and (max-height: 420px) {
    padding-bottom: 10px;
  }
`;

export const StyledParagraph = styled.p`
  color: #000000;
  text-align: center;
  font-size: 16px;
  margin: 0 25px;
`;

export const StyledImage = styled.img`
  align-self: center;
  width: 18%;
  max-width: 150px;
  max-height: 150px;
  margin: 35px;
`;
