import React, { forwardRef } from 'react';
import { Slider as MuiSlider } from '@mui/material';
import PropTypes from 'prop-types';

const Slider = forwardRef(({ inputProps, ...props }, ref) => {
  const extraInputProps = { 'aria-labelledby': props['aria-labelledby'] };
  return <MuiSlider ref={ref} inputProps={{ ...inputProps, ...extraInputProps }} name="slider" defaultValue={50} />;
});

Slider.displayName = 'Slider';

Slider.propTypes = {
  inputProps: PropTypes.object,
  ref: PropTypes.object,
  ['aria-labelledby']: PropTypes.string,
};

export default Slider;
