import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { breakpoints } from 'Styles/theme';

export const SupportLink = styled.a(
  ({ theme }) => `
  display: flex;
  justify-content: center;

  ${theme.breakpoints.up(breakpoints.TABLET)} {
    display: none;
  }
  ${theme.breakpoints.down(breakpoints.TABLET)} {
    display: contents;
  }
`,
);

export const SupportText = styled(Typography)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
  
    ${theme.breakpoints.down(breakpoints.TABLET)} {
      display: none;
    }
  `,
);
