import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Stage, Layer, Group } from 'react-konva';
import WheelWedge from '../WheelWedge/WheelWedge';
import { WheelContainer } from './styles';
import { useIntl } from 'react-intl';

function WheelSelect({ height, width, onSelect, data, isEnabled, rotation }) {
  Konva.angleDeg = false;

  const [wedges, setWedges] = useState([]);
  const [wedgeCount, setWedgeCount] = useState(0);
  const [wheelAngle, setWheelAngle] = useState(0);
  const [controlled, setControlled] = useState(false);
  const [angle, setAngle] = useState(0);
  const [target, setTarget] = useState();

  const wheel = useRef();
  const stage = useRef();
  const container = useRef();
  const intl = useIntl();

  useEffect(() => {
    stage.current?.content?.children[0].append(intl.formatMessage({ id: 'wheel.name' }));
  }, []);

  useEffect(() => {
    if (data) {
      const count = data.length;
      setAngle((2 * Math.PI) / count);
      setWedgeCount(count);
      // Rotates wheel left 90 degrees at the start
      setWheelAngle(-Math.PI / 2);
    }
  }, [data]);

  useEffect(() => {
    buildWedges();
  }, [wedgeCount, width]);

  /* istanbul ignore next */
  useEffect(() => {
    if (rotation) {
      const wedgeCount = data.length;
      setWheelAngle((rotation - 0.5) * ((2 * Math.PI) / wedgeCount) + -Math.PI / 2);
    }
  }, [rotation]);

  /* istanbul ignore next */
  useEffect(() => {
    if (rotation) {
      let timer = setTimeout(() => {
        if (wheelAngle) {
          const shape = stage.current.getIntersection({ x: width / 2, y: height / 3 });
          if (shape) {
            onSelect(shape.getAttrs());
          }
        }
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [wheelAngle]);

  /* istanbul ignore next */
  const addWedge = (n) => {
    const slice = data[n];
    const padding = 2.25;
    const rotation = (2 * n * Math.PI) / wedgeCount;
    const radius = height <= width ? height / padding : width / padding;
    const iconSize = radius / padding;
    return (
      <WheelWedge
        id={n + 1}
        key={n + 1}
        slice={slice}
        rotation={rotation}
        radius={radius}
        angle={angle}
        iconSize={iconSize}
        wedgeCount={wedgeCount}
      />
    );
  };

  const buildWedges = () => {
    let wedgeList = [];
    for (let n = 0; n < wedgeCount; n++) {
      wedgeList.push(addWedge(n));
    }

    setWedges(wedgeList);
  };

  /* istanbul ignore next */
  const onMouseDown = (event) => {
    setControlled(true);
    setTarget(event.target);
  };

  /* istanbul ignore next */
  const onMouseUp = () => {
    setControlled(false);
    setTarget(null);
  };

  // Handler for rotation
  /* istanbul ignore next */
  const onMouseMove = () => {
    if (controlled && isEnabled) {
      const mousePos = stage.current.getPointerPosition();
      const x = mousePos.x - wheel.current.getX();
      const y = mousePos.y - wheel.current.getY();
      const atan = Math.atan(y / x);
      const rotation = x >= 0 ? atan : atan + Math.PI;
      const targetGroup = target.getParent();
      // Rotates the wheel from the center of the selected wedge based on the current mouse position
      setWheelAngle(rotation - targetGroup.attrs.rotation - targetGroup.children[0].angle() / 2);
      const shape = stage.current.getIntersection({ x: width / 2, y: height / 3 });
      onSelect(shape.getAttrs());
    }
  };

  return (
    <WheelContainer ref={container}>
      <Stage
        ref={stage}
        width={width}
        height={height}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onTouchEnd={onMouseUp}
        onTouchMove={onMouseMove}
      >
        <Layer>
          <Group
            ref={wheel}
            rotation={wheelAngle}
            x={width / 2}
            y={height / 2.25}
            onMouseDown={onMouseDown}
            onTouchStart={onMouseDown}
          >
            {wedges &&
              wedges.map((wedge) => {
                return wedge;
              })}
          </Group>
        </Layer>
      </Stage>
    </WheelContainer>
  );
}

WheelSelect.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
  data: PropTypes.array,
  isEnabled: PropTypes.bool,
  rotation: PropTypes.number,
};

export default WheelSelect;
