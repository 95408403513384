import styled from '@emotion/styled';

export const StyledIncomeSliderQuestion = styled.div`
  .FormQuestion-root {
    height: 395px;
    width: 285px;
    background-color: #565c89;
    justify-content: center;
    display: grid;
    grid-template-rows: 340px 50px;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 12px 0 -10px grey, 0 16px 0 -12px lightgrey, 0 18px 0 -13px grey, 0 22px 0 -15px lightgrey,
      0 24px 0 -16px grey;
    margin-bottom: 24px;
    padding: 12px 24px;
  }

  .FormQuestion-question {
    font-weight: 700;
    margin-bottom: 6px;
    font-size: 24px;
  }

  .FormQuestion-description {
    margin-bottom: 24px;
  }

  .MuiSlider-root {
    color: #979797;
  }

  .MuiSlider-thumb {
    color: white;
  }

  .MuiSlider-markLabel {
    top: 40px;
    color: white;
    font-weight: 700;
  }

  [id='c52804fd-c03b-476a-9d2f-c62b3494f0f1-skip-button'] {
    padding: 0 6px 8px;
  }
`;
