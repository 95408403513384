import React, { useState } from 'react';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import { useIntl } from 'react-intl';
import {
  selectCredentialType,
  selectRecoveryMethod,
  selectRecoveryMethodInput,
  selectBirthday,
  navigate,
} from 'Onboard_Redux/login';
import { ArrowBackIos } from '@mui/icons-material';
import { Header } from 'Onboard_Components/Account/styles';
import Button from 'Stories/components/Button';
import { LOGIN_SCREEN } from 'Onboard_Components/Account/LogIn/steps';
import { ConfirmationContentContainer, ConfirmationText, ResendButton } from './styles';
import { useForgotUsernameMutation } from 'Onboard_Redux/services/identity';
import { PatternFormat } from 'react-number-format';
import Snackbar from 'Onboard_Components/Account/Snackbar';

function Confirmation() {
  const dispatch = useOnboardDispatch();
  const intl = useIntl();

  const credentialType = useOnboardSelector(selectCredentialType);
  const recoveryMethodInput = useOnboardSelector(selectRecoveryMethodInput);
  const birthday = useOnboardSelector(selectBirthday);
  const [forgotUsername] = useForgotUsernameMutation();
  const [codeSent, setCodeSent] = useState(false);
  const recoveryMethodTextContent =
    useOnboardSelector(selectRecoveryMethod) === 'phone'
      ? intl.formatMessage(
          { id: 'account.log-in.confirmation-text-message' },
          {
            recoveryMethodInput: (
              <PatternFormat
                valueIsNumericString
                format="+1 (###) ###-####"
                value={recoveryMethodInput}
                displayType="text"
              />
            ),
          },
        )
      : intl.formatMessage({ id: 'account.log-in.confirmation-email' }, { recoveryMethodInput });

  function onBack() {
    dispatch(navigate({ target: LOGIN_SCREEN }));
  }

  function onResendClick() {
    forgotUsername({ emailOrPhoneNumber: recoveryMethodInput, birthday }).then(() => setCodeSent(true));
  }

  return (
    <>
      <Header>
        <ArrowBackIos id="backArrow" onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />
        {credentialType === 'username' && intl.formatMessage({ id: 'account.log-in.forgot-username' })}
        {credentialType === 'password' && intl.formatMessage({ id: 'account.log-in.forgot-password' })}
      </Header>
      <ConfirmationContentContainer>
        <ConfirmationText>{recoveryMethodTextContent}</ConfirmationText>
        <ResendButton id="resendButton" variant={Button.variant.TEXT} onClick={onResendClick}>
          {intl.formatMessage({ id: 'account.otp.resend' })}
        </ResendButton>
      </ConfirmationContentContainer>
      <Button fullWidth id="backToLoginButton" onClick={onBack}>
        {intl.formatMessage({ id: 'account.log-in.back' })}
      </Button>
      <Snackbar
        message={intl.formatMessage({ id: 'account.otp.code-resent' })}
        open={codeSent}
        onClose={() => setCodeSent(false)}
      />
    </>
  );
}

export default Confirmation;
