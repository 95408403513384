import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './api/useAxiosSubmit/useAxiosSubmit';

const useCreatePet = () => {
  const [petRequest, setPetRequest] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored } = useAxiosSubmit(url, petRequest, 0, 1);

  const createPet = async (request) => {
    setUrl(`${process.env.API_BASE_URL}/v1/pets`);
    setPetRequest(request);
  };

  return { petInformation: response, petError: hasErrored, petIsPending: isLoading, createPet };
};

export default useCreatePet;
