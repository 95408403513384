import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { breakpoints } from 'Styles/theme';

export const PageContainer = styled.div`
  height: 100%;
  text-align: center;
  position: relative;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  flex: none;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: green;
  font-size: 28px;
  font-weight: 300;
  height: 300px;
  color: white;
`;

export const StyledContainerWithLinearGradientBackground = styled.div`
  background: linear-gradient(to top left, #60a945, #7df352);
  padding: 20px 10px 50px 10px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  color: white;
  align-items: center;
`;

export const StyledChatOptionsContainer = styled.div(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    margin: -40px auto 34px;
    max-width: 770px;
    justify-content: center;

    ${theme.breakpoints.down(breakpoints.TABLET)} {
      max-width: 90%;
    }
  `,
);

export const StyledBackButton = styled(Button)`
  position: absolute;
  left: 20px;
  color: white;
  padding: 0px;
  left: 0;
`;

export const StyledHeaderContent = styled.div`
  margin-top: 8px;
`;
