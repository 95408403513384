import getAuthClient from 'Core_Helpers/getAuthClient';

export async function submit(url, data) {
  const authorizedApiClient = await getAuthClient();
  if (!url) return;
  if (authorizedApiClient) {
    const headers = {
      'Content-Type': 'application/json',
    };
    return await authorizedApiClient.post(url, { data, headers });
  }
}
