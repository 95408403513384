import { useMediaQuery } from '@mui/material';
import { breakpoints } from 'Styles/theme';

function useMediaType() {
  const breakPoints = {
    [breakpoints.DESKTOP]: useMediaQuery('(min-width: 1200px)'),
    [breakpoints.TABLET]: useMediaQuery('(min-width: 768px)'),
    [breakpoints.MOBILE]: useMediaQuery('(min-width: 0px)'),
  };

  const matchedMedia = Object.keys(breakPoints).find((x) => breakPoints[x]);

  return matchedMedia;
}

export default useMediaType;
