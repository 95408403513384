import React from 'react';
import { useIntl } from 'react-intl';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import { useForm, Controller } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Button from 'Stories/components/Button';
import { Header, Select, StyledTextInput, StyledPhoneInput, StyledDateInput } from 'Onboard_Components/Account/styles';
import { submitCredentialRecovery, selectCredentialType, navigate } from 'Onboard_Redux/login';
import { ArrowBackIos } from '@mui/icons-material';
import { LOGIN_SCREEN } from 'Onboard_Components/Account/LogIn/steps';
import { useForgotUsernameMutation, useForgotPasswordMutation } from 'Onboard_Redux/services/identity';

function ForgotCredential() {
  const dispatch = useOnboardDispatch();
  const intl = useIntl();
  const {
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
    watch,
  } = useForm();

  const [forgotUsername, { isLoading: loadingUsername }] = useForgotUsernameMutation();
  const [forgotPassword, { isLoading: loadingPassword }] = useForgotPasswordMutation();

  const credentialType = useOnboardSelector(selectCredentialType);
  const selectedRecoveryMethod = watch('recoveryMethod');
  const formComplete =
    dirtyFields.recoveryMethod &&
    (dirtyFields.phoneRecoveryInput || dirtyFields.emailRecoveryInput) &&
    (selectedRecoveryMethod !== 'phone' || dirtyFields.birthday);

  const recoveryMethodOptions = [
    { recoveryMethod: intl.formatMessage({ id: 'account.log-in.phone-number' }), code: 'phone' },
    { recoveryMethod: intl.formatMessage({ id: 'account.log-in.email-address' }), code: 'email' },
  ];

  function onBack() {
    dispatch(navigate({ target: LOGIN_SCREEN }));
  }

  async function onSubmit(data) {
    const { recoveryMethod, phoneRecoveryInput, emailRecoveryInput, birthday } = data;
    const recoveryMethodInput = { email: emailRecoveryInput, phone: phoneRecoveryInput }[recoveryMethod];

    await dispatch(submitCredentialRecovery({ recoveryMethod, recoveryMethodInput, birthday }));

    if (credentialType === 'username') {
      forgotUsername({ emailOrPhoneNumber: recoveryMethodInput, birthday });
    }

    if (credentialType === 'password') {
      forgotPassword({ emailOrPhoneNumber: recoveryMethodInput, birthday });
    }
  }

  const loading = loadingUsername || loadingPassword;

  // use today as max to prevent selecting birthday in the future
  const maxDate = new Date();
  const minDate = new Date('1900-01-01');

  return (
    <>
      <Header>
        <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />
        {credentialType === 'username' && intl.formatMessage({ id: 'account.log-in.forgot-username' })}
        {credentialType === 'password' && intl.formatMessage({ id: 'account.log-in.forgot-password' })}
      </Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="recoveryMethod"
          control={control}
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <FormControl fullWidth variant="filled">
              <InputLabel id="recoveryMethodSelectLabel">
                {intl.formatMessage({ id: 'account.log-in.choose-recover-method' })}
              </InputLabel>
              <Select id="recoveryMethodSelect" labelId="recoveryMethodSelectLabel" onChange={onChange} value={value}>
                {recoveryMethodOptions.map((o) => (
                  <MenuItem id={`recoveryMethodOption-${o.code}`} key={o.code} value={o.code}>
                    {o.recoveryMethod}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        {selectedRecoveryMethod === 'email' && (
          <Controller
            name="emailRecoveryInput"
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: intl.formatMessage({ id: 'error.invalid-input' }),
              },
            }}
            render={({ field }) => (
              <StyledTextInput
                {...field}
                id="emailRecoveryInput"
                label={intl.formatMessage({ id: 'account.log-in.email-address' })}
                errorMessage={errors.emailRecoveryInput?.message}
              />
            )}
          />
        )}
        {selectedRecoveryMethod === 'phone' && (
          <>
            <Controller
              name="phoneRecoveryInput"
              control={control}
              defaultValue=""
              rules={{
                validate: (v) => (v?.length === 10 && v != 0) || intl.formatMessage({ id: 'error.invalid-phone' }),
              }}
              render={({ field }) => (
                <StyledPhoneInput
                  {...field}
                  id="phoneRecoveryInput"
                  label={intl.formatMessage({ id: 'account.log-in.phone-number' })}
                  errorMessage={errors.phoneRecoveryInput?.message}
                />
              )}
            />
            <Controller
              name="birthday"
              control={control}
              defaultValue=""
              rules={{
                validate: (v) => {
                  const enteredDate = new Date(v);
                  return (
                    (!isNaN(enteredDate) && minDate < enteredDate && enteredDate < maxDate) ||
                    intl.formatMessage({ id: 'error.invalid-input' })
                  );
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <StyledDateInput
                  {...field}
                  disableFuture
                  id="birthdayInput"
                  label={intl.formatMessage({ id: 'dob.birthday' })}
                  error={!!errors.birthday?.message}
                  errorMessage={errors.birthday?.message}
                  slotProps={{ textField: { error: !!error, helperText: error?.message } }}
                  inputProps={{
                    'aria-label': intl.formatMessage({ id: 'account.sign-up.enter-birthday' }),
                    placeholder: 'mm/dd/yyyy',
                  }}
                />
              )}
            />
          </>
        )}
        <Button fullWidth type="submit" loading={loading} disabled={!formComplete} sx={{ margin: '8px 0' }}>
          {intl.formatMessage({ id: 'common.submit' })}
        </Button>
      </form>
    </>
  );
}

export default ForgotCredential;
