import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledTitle } from './styles';
import TooltipWrapper from './TooltipWrapper';

function CardTitle({ children }) {
  const thisRef = useRef();
  const [includeTooltip, setIncludeTooltip] = useState(false);

  useEffect(() => {
    setIncludeTooltip(thisRef.current.offsetWidth < thisRef.current.scrollWidth);
  }, [children]);

  if (includeTooltip) {
    return (
      <TooltipWrapper title={children}>
        <StyledTitle ref={thisRef}>{children}</StyledTitle>
      </TooltipWrapper>
    );
  }

  return <StyledTitle ref={thisRef}>{children}</StyledTitle>;
}

CardTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default CardTitle;
