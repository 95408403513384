export const Config = {
  KEY: 'MOVEMENT',
  ANIMATIONS: {
    WAVE: {
      frames: [16, 17, 18, 19],
      frameRate: 12,
      repeat: 2,
      yoyo: true,
    },
    IDLE: {
      frames: [20, 21],
      frameRate: 12,
      repeat: 0,
    },
    WALK: {
      frames: [0, 1, 3, 4, 5],
      frameRate: 16,
      repeat: -1,
    },
    IDLE2: {
      frames: [10, 11],
      frameRate: 12,
      repeat: 0,
    },
    WAVE2: {
      frames: [6, 7, 8, 9],
      frameRate: 12,
      repeat: 2,
      yoyo: true,
    },
    OBJECT_INTERACT: {
      frames: [15],
      frameRate: 12,
      repeat: 2,
      yoyo: true,
    },
    OBJECT_INTERACT_TRANSITION: {
      frames: [12, 13, 14],
      frameRate: 12,
      repeat: 0,
      yoyo: true,
    },
  },
  ATTACHPOINTS: {
    MOUTH: {},
    NECK: {},
  },
};
