import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import GoogleFontIcon from 'Core_Components/GoogleFontIcon/GoogleFontIcon';
import { StyledHiddenInput } from './styles';
import { useIntl } from 'react-intl';

function ActivityCategoryToggle({ categoryCode, label, control, validate }) {
  const intl = useIntl();
  const inputId = `categoryToggle.${categoryCode}`;

  return (
    <Controller
      key={categoryCode}
      name={`activityCategories[${categoryCode}]`}
      rules={{ validate: () => validate() || intl.formatMessage({ id: 'reminders.activity-error' }) }}
      control={control}
      defaultValue={false}
      render={({ field: { onChange, value: checked, ref } }) => (
        <>
          <StyledHiddenInput
            ref={ref}
            id={inputId}
            type="checkbox"
            value={categoryCode}
            checked={checked}
            onChange={(e) => onChange(e.target.checked && e.target.value)}
          />
          <label htmlFor={inputId}>
            <GoogleFontIcon name={GoogleFontIcon.Name.Check} />
            {label}
          </label>
        </>
      )}
    />
  );
}
ActivityCategoryToggle.propTypes = {
  categoryCode: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
};

export default ActivityCategoryToggle;
