import React from 'react';
import {
  StyledResultsDialog,
  Pyxir,
  DialogHeader,
  ResultContainer,
  StyledContinueButton,
  ContinueButtonWrapper,
} from './styles';
import { StyledCircularProgress } from 'Core_Components/LoadingWrapper/styles';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { CHECKINS } from 'Core_Pages/Routes/RoutesConfig';
import { pyxir } from 'Assets/img/home-option-icons';
import { ScreeningModuleStore } from '../ScreeningModuleStore';
import Result from './Result/Result';
import { Screenings } from '../enumerations/enums';

function Results() {
  const intl = useIntl();
  const history = useHistory();
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const screeningResultGuids = screeningStore.ScreeningResults?.map(({ screeningGuid }) => screeningGuid);
  const screeningResults = screeningResultGuids && screeningResultGuids?.length > 0 ? screeningResultGuids : undefined;
  const hasMountainBackground = screeningResults?.length === 1 && screeningResults[0] === Screenings.ADULT_WELLBEING;
  const hasError = screeningStore.ResultsHasError;

  const onContinue = () => {
    history.push(CHECKINS);
  };

  if ((!screeningStore.ScreeningResults || screeningStore.ScreeningResults.length === 0) && !hasError) {
    return <StyledCircularProgress />;
  }

  return (
    <>
      <StyledResultsDialog
        fullScreen
        open={true}
        aria-labelledby="screening-title"
        hasMountainBackground={hasMountainBackground}
      >
        <ResultContainer className="safe-area">
          <DialogHeader>
            <Pyxir alt="" aria-hidden="true" src={pyxir} />
            <Result.Header id="screening-title" screeningGuid={screeningStore.HighestScoreGuid} error={hasError} />
          </DialogHeader>
          {hasError ? (
            <Result.Body error={hasError} />
          ) : (
            screeningResults?.map((screeningGuid, index) => <Result.Body key={index} screeningGuid={screeningGuid} />)
          )}
          <Result.Description screeningGuid={screeningStore.HighestScoreGuid} error={hasError} />
          <Result.Resources screeningGuid={screeningStore.NeedsGuid} error={hasError} />
          <ContinueButtonWrapper>
            <StyledContinueButton fullWidth onClick={onContinue}>
              {intl.formatMessage({ id: 'common.continue' })}
            </StyledContinueButton>
          </ContinueButtonWrapper>
        </ResultContainer>
      </StyledResultsDialog>
    </>
  );
}

export default Results;
