const hasPermissions = (permissionData, roleData, userInfo) => {
  if (!permissionData && !roleData) {
    return true;
  }

  const permissions = [].concat(permissionData);
  const roles = [].concat(roleData);

  let isAuthorized = true;

  permissions?.forEach((permission) => {
    if (permission && (!userInfo?.permission || !userInfo?.permission?.includes(permission))) {
      isAuthorized = false;
    }
  });

  roles?.forEach((role) => {
    if (role && (!userInfo?.role || !userInfo?.role?.includes(role))) {
      isAuthorized = false;
    }
  });

  return isAuthorized;
};

export default hasPermissions;
