import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { Screenings } from 'Core_Pages/Screening/enumerations/enums';
import { CheckinModuleStore } from 'Core_Pages/Checkin/CheckinModuleStore';
import { StepperButton } from 'Core_Pages/Checkin/ScreeningStepper/styles';
import { responseIndicators } from './config';

const screeningGuid = Screenings.ADULT_PHQ4;

const CheckinResult = ({ onClick, screeningResponseGuid }) => {
  const intl = useIntl();
  const store = CheckinModuleStore.Instance().Use();
  const result = store.RecentScreeningScores.find(
    (x) => x.screeningGuid === screeningGuid && x.screeningResponseGuid === screeningResponseGuid,
  );
  const isNext = store.NextAvailableScreening === screeningGuid;

  const getSymptomUrgencyTextId = (responseIndicator) => {
    return result.subScores.some((x) => x.responseIndicator === responseIndicator && x.hasUrgentNeed)
      ? 'common.yes'
      : 'common.no';
  };

  return (
    <>
      <Typography fontSize={16} fontWeight={700}>
        {intl.formatMessage({ id: 'screenings.check-in.adult.depression-anxiety-title' })}
      </Typography>
      {!result ? (
        <StepperButton disabled={!isNext} onClick={() => onClick(screeningGuid)}>
          {intl.formatMessage({ id: 'title.check-in' })}
        </StepperButton>
      ) : (
        <>
          <Typography fontSize={16} whiteSpace={'pre-wrap'}>
            {`${intl.formatMessage({ id: 'screenings.check-in.result' })}:`}
          </Typography>
          <Typography fontSize={16} whiteSpace={'pre-wrap'}>
            {`${intl.formatMessage({
              id: 'screenings.results.adult.phq4.symptoms.anxiety',
            })}: ${intl.formatMessage({ id: getSymptomUrgencyTextId(responseIndicators.ANXIETY) })}`}
          </Typography>
          <Typography fontSize={16} whiteSpace={'pre-wrap'}>
            {`${intl.formatMessage({
              id: 'screenings.results.adult.phq4.symptoms.depression',
            })}: ${intl.formatMessage({ id: getSymptomUrgencyTextId(responseIndicators.DEPRESSION) })}`}
          </Typography>
        </>
      )}
    </>
  );
};

CheckinResult.propTypes = {
  onClick: PropTypes.func.isRequired,
  screeningResponseGuid: PropTypes.string,
};

export default CheckinResult;
