import React from 'react';
import PropTypes from 'prop-types';
import { ChatBubbleContainer, ChatText } from './styles.js';
import { Interweave } from 'interweave';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';

const ChatBubble = ({ position, text, status }) => {
  return (
    <ChatBubbleContainer position={position}>
      <ScreenReaderWrapper data-testid="ScreenReaderWrapper" style={{ height: '0px' }}>
        {status?.typingText[position]}
      </ScreenReaderWrapper>
      <ChatText data-testid="ChatText" position={position}>
        <Interweave content={text} />
      </ChatText>
    </ChatBubbleContainer>
  );
};

ChatBubble.propTypes = {
  text: PropTypes.string,
  position: PropTypes.string,
  status: PropTypes.object,
};

export default ChatBubble;
