import { uiTypes } from 'Core_Games/constants/uiTypes';
import { gameData } from 'Core_Games/constants/gameData';
import { InteractionType } from 'Core_Games/constants/interactionType';
import { playerActorType } from 'Core_Games/constants/playerActorType';
import { animationType } from 'Core_Games/constants/animationType';

export const keySceneDataTypes = {
  // Objects & NPCS
  OBJECT_FRONTDOOR: 'OBJECT_FRONTDOOR',
  OBJECT_PILLOW: 'OBJECT_PILLOW',
  OBJECT_NUTRIENTCONTAINER: 'OBJECT_NUTRIENTCONTAINER',
  OBJECT_NUTRIENTBOWL: 'OBJECT_NUTRIENTBOWL',
  NPC_FRIEND: 'NPC_FRIEND',
  // Backgrounds
  FOREGROUND_FRIENDTIME_FRONTDOOR: 'FOREGROUND_FRIENDTIME_FRONTDOOR',
  BACKGROUND_FRIENDTIME_ENTRYWAY: 'BACKGROUND_FRIENDTIME_ENTRYWAY',
  BACKGROUND_FRIENDTIME_WATER: 'BACKGROUND_FRIENDTIME_WATER',
  BACKGROUND_FRIENDTIME_FOOD: 'BACKGROUND_FRIENDTIME_FOOD',
  BACKGROUND_FRIENDTIME_LIVINGROOM: 'BACKGROUND_FRIENDTIME_LIVINGROOM',
  BACKGROUND_FRIENDTIME_LIVINGROOM2: 'BACKGROUND_FRIENDTIME_LIVINGROOM2',
  // Transition Types
  TRANSITION_FADE: 'TRANSITION_FADE',
  TRANSITION_MOVE: 'TRANSITION_MOVE',
  // UI
  // Captions
  CAPTION_FRIENDTIME_INTRO: 'CAPTION_FRIENDTIME_INTRO',
  CAPTION_FRIENDTIME_MEET: 'CAPTION_FRIENDTIME_MEET',
  CAPTION_FRIENDTIME_OUTRO: 'CAPTION_FRIENDTIME_OUTRO',
  // Buttons
  BUTTON_FRIENDTIME_INTRO: 'BUTTON_FRIENDTIME_INTRO',
  BUTTON_FRIENDTIME_OUTRO_NO: 'BUTTON_FRIENDTIME_OUTRO_NO',
  BUTTON_FRIENDTIME_OUTRO_YES: 'BUTTON_FRIENDTIME_OUTRO_YES',
  // Screen
  SCREEN_FRIENDTIME_INTRO: 'SCREEN_FRIENDTIME_INTRO',
  SCREEN_FRIENDTIME_OUTRO: 'SCREEN_FRIENDTIME_OUTRO',
};

export const friendTimeConfig = {
  [playerActorType.DOG.NAME]: {
    [InteractionType.FRIENDTIME.NAME]: {
      NAME: InteractionType.FRIENDTIME.NAME,
      PETSCALE: 0.75,
      BACKGROUNDSPLIT: 3,
      CAMERAOFFSETX: -0.45,
      STARTX: 0.8,
      STARTY: 0.3,
      BOUNDS: {
        HEIGHT: {
          START: 0.22,
          END: 0.0,
        },
        WIDTH: {
          START: 0.02638,
          END: 0.0,
          GAP: 0.0,
        },
      },
      ASSETS: {
        [keySceneDataTypes.FOREGROUND_FRIENDTIME_FRONTDOOR]: gameData.FOREGROUND.ALL.MAKEFRIEND_FRONTDOOR,
        [keySceneDataTypes.BACKGROUND_FRIENDTIME_LIVINGROOM]: gameData.BACKGROUND.ALL.LIVINGROOM,
        [keySceneDataTypes.BACKGROUND_FRIENDTIME_WATER]: gameData.BACKGROUND.ALL.WATER,
        [keySceneDataTypes.OBJECT_FRONTDOOR]: gameData.ACTOR.ITEM.OBJECT.FRONTDOOR,
        [keySceneDataTypes.OBJECT_NUTRIENTBOWL]: gameData.ACTOR.ITEM.OBJECT.BOWL,
        [keySceneDataTypes.OBJECT_NUTRIENTCONTAINER]: gameData.ACTOR.ITEM.OBJECT.NUTRIENTCONTAINER,
        [keySceneDataTypes.NPC_FRIEND]: gameData.ACTOR.NPC.STRAY.PUPPY,
      },
      COMBINED_BACKGROUND: [
        { FOREGROUND: [keySceneDataTypes.FOREGROUND_FRIENDTIME_FRONTDOOR] },
        { BACKGROUND: [keySceneDataTypes.BACKGROUND_FRIENDTIME_LIVINGROOM] },
        { BACKGROUND: [keySceneDataTypes.BACKGROUND_FRIENDTIME_WATER] },
      ],
      PAGES: [
        {
          SCREEN: keySceneDataTypes.SCREEN_FRIENDTIME_INTRO,
          CAPTION: keySceneDataTypes.CAPTION_FRIENDTIME_INTRO,
          CAMERA_POSITION: { x: 0.31, y: 0.7 },
          OBJECTS: [
            {
              NAME: keySceneDataTypes.OBJECT_FRONTDOOR,
              DEPTH: 2,
              COORDINATES: { x: -0.2, y: -0.185 },
              ANIMATION: {
                NAME: animationType.OBJECT_INTERACT,
                PLAYFIRST: true,
              },
            },
          ],
          INTERACT: {
            NPC: {
              DEPTH: 3,
              SCALE: 0.875,
              COORDINATES: { x: 0.34, y: 0.45 },
              ANIMATION_IDLE: animationType.SLEEP,
              ANIMATIONS_SEQUENTIAL: [
                {
                  NAME: animationType.SLEEP,
                },
              ],
            },
            MAIN: {
              DEPTH: 1,
              TRANSITION_AFTER_OBJECT: true,
              ALPHA: 0,
              COORDINATES: { x: -0.09, y: 0.05 },
              NAME: animationType.WALK2,
              ANIMATIONS_SEQUENTIAL: [
                {
                  NAME: animationType.WALK2,
                  ALPHA: 1,
                  COORDINATES: { x: 0.1, y: 0.12 },
                },
                {
                  FLIPX: false,
                  NAME: animationType.IDLE2,
                },
              ],
            },
          },
          TRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
          ENDPAGETRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
        },
        {
          BACKGROUND_INDEX: 1,
          CAPTION: keySceneDataTypes.CAPTION_FRIENDTIME_MEET,
          CAMERA_POSITION: { x: 0.15, y: 0.5 },
          INTERACT: {
            NPC: {
              SCALE: 0.675,
              DEPTH: 1,
              COORDINATES: { x: -0.05, y: 0.6 },
              ANIMATIONS_SEQUENTIAL: [
                { NAME: animationType.IDLE },
                { NAME: animationType.IDLE2, REPEAT: 3 },
                { NAME: animationType.IDLE },
              ],
            },
            MAIN: {
              COORDINATES: { x: 0.125, y: 0.28 },
              DEPTH: 2,
              SCALE: 2.425,
              ANIMATION_IDLE: animationType.IDLE5,
              FLIPX: true,
              ANIMATIONS_SEQUENTIAL: [
                { NAME: animationType.SITBENDDRINK },
                { NAME: animationType.DRINK, REPEAT: 10 },
                { NAME: animationType.SITBENDDRINKFINISH, REVERSE: true },
              ],
            },
          },
          TRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
          ENDPAGETRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
        },
        {
          OBJECTS: [
            {
              NAME: keySceneDataTypes.OBJECT_NUTRIENTCONTAINER,
              FLIPX: true,
              DEPTH: 7,
              COORDINATES: { x: -0.05, y: 0.5 },
              ALPHAONEND: 0,
              ANIMATION: {
                ANIMATIONS_SEQUENTIAL: [
                  {
                    NAME: animationType.DRINK,
                    PLAYAS: {
                      PET: { NAME: animationType.WATCH },
                      [keySceneDataTypes.NPC_FRIEND]: { NAME: animationType.IDLE },
                      [keySceneDataTypes.OBJECT_NUTRIENTBOWL]: { NAME: animationType.DRINK },
                    },
                  },
                ],
              },
            },
            {
              NAME: keySceneDataTypes.OBJECT_NUTRIENTBOWL,
              DEPTH: 5,
              COORDINATES: { x: 0.05, y: 0.675 },
              ANIMATION: {
                ANIMATIONS_SEQUENTIAL: [
                  {
                    NAME: animationType.DRINKHALF,
                    PLAYAS: {
                      PET: { NAME: animationType.SITBENDDRINK },
                      [keySceneDataTypes.NPC_FRIEND]: { NAME: animationType.DRINK },
                    },
                  },
                  {
                    NAME: animationType.DRINKREMAINING,
                    PLAYAS: {
                      PET: { NAME: animationType.DRINK },
                      [keySceneDataTypes.NPC_FRIEND]: { NAME: animationType.SITBENDDRINK, REVERSE: true },
                    },
                  },
                ],
              },
            },
          ],
          //
          BACKGROUND_INDEX: 2,
          CAMERA_POSITION: { x: 0.15, y: 0.8 },
          INTERACT: {
            NPC: {
              COORDINATES: { x: 0.01, y: 0.55 },
              DEPTH: 4,
              ANIMATION_IDLE: animationType.THIRSTY,
              ANIMATIONS_SEQUENTIAL: [{ NAME: animationType.THIRSTY }],
            },
            MAIN: {
              COORDINATES: { x: 0.125, y: 0.345 },
              DEPTH: 6,
              ANIMATION_IDLE: animationType.SITBENDDRINKFINISH,
              ANIMATIONS_SEQUENTIAL: [{ NAME: animationType.SITBENDDRINKFINISH }, { NAME: animationType.IDLE5 }],
            },
          },
          TRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
          ENDPAGETRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
        },
        {
          BACKGROUND_INDEX: 1,
          CAMERA_POSITION: { x: 0.15, y: 0.8 },
          INTERACT: {
            NPC: {
              COORDINATES: { x: -0.8, y: 0.6 },
              ANIMATIONS_SEQUENTIAL: [
                { NAME: animationType.WALK, COORDINATES: { x: -0.05, y: 0.6 } },
                { NAME: animationType.IDLE },
                { NAME: animationType.LAY },
                { NAME: animationType.SLEEP },
              ],
            },
            MAIN: {
              COORDINATES: { x: -0.8, y: 0.34 },
              DEPTH: 3,
              ANIMATIONS_SEQUENTIAL: [
                { NAME: animationType.WALK, COORDINATES: { x: 0.05, y: 0.34 }, FLIPX: false },
                { NAME: animationType.TURN, FLIPX: true },
                { NAME: animationType.LAY, FLIPX: true },
              ],
            },
          },
          TRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
          ENDPAGETRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
        },
        {
          BACKGROUND_INDEX: 1,
          CAMERA_POSITION: { x: 0.15, y: 0.8 },
          SCREEN: keySceneDataTypes.SCREEN_FRIENDTIME_OUTRO,
          CAPTION: keySceneDataTypes.CAPTION_FRIENDTIME_OUTRO,
          INTERACT: {
            NPC: {
              COORDINATES: { x: 0.2, y: 0.52 },
              ANIMATION_IDLE: animationType.WAVE,
              ANIMATIONS_SEQUENTIAL: [{ NAME: animationType.WAVE }, { NAME: animationType.WAVE }],
            },
            MAIN: {
              COORDINATES: { x: -0.0195, y: 0.335 },
              FLIPX: false,
              ANIMATION_IDLE: animationType.IDLE4,
              ANIMATIONS_SEQUENTIAL: [{ NAME: animationType.SILLY }],
            },
          },
          TRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
          ENDPAGETRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
        },
      ],
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.BUTTON_FRIENDTIME_INTRO,
              TEXTID: 'pets.buttons.friendtime.intro',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_FRIENDTIME_INTRO],
            },
            {
              NAME: keySceneDataTypes.BUTTON_FRIENDTIME_OUTRO_NO,
              TEXTID: 'pets.buttons.friendtime.outro.no',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_FRIENDTIME_OUTRO],
            },
            {
              NAME: keySceneDataTypes.BUTTON_FRIENDTIME_OUTRO_YES,
              TEXTID: 'pets.buttons.friendtime.outro.yes',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_FRIENDTIME_OUTRO],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.CAPTION_FRIENDTIME_INTRO,
              TITLEID: 'pets.caption.title.friendtime.intro',
              TEXTID: 'pets.caption.description.friendtime.intro',
            },
            {
              NAME: keySceneDataTypes.CAPTION_FRIENDTIME_MEET,
              TITLEID: 'pets.caption.title.friendtime.meet',
              TEXTID: 'pets.caption.description.friendtime.meet',
            },
            {
              NAME: keySceneDataTypes.CAPTION_FRIENDTIME_OUTRO,
              TITLEID: 'pets.caption.title.friendtime.outro',
              TEXTID: 'pets.caption.description.friendtime.outro',
            },
          ],
        },
      ],
    },
  },
  [playerActorType.CAT.NAME]: {
    [InteractionType.FRIENDTIME.NAME]: {
      NAME: InteractionType.FRIENDTIME.NAME,
      PETSCALE: 0.65,
      STARTX: 0.5,
      STARTY: 0.5,
      BACKGROUNDSPLIT: 4,
      BOUNDS: {
        HEIGHT: {
          START: 0.22,
          END: 0.0,
        },
        WIDTH: {
          START: 0.02638,
          END: 0.0,
          GAP: 0.0,
        },
      },
      ASSETS: {
        [keySceneDataTypes.FOREGROUND_FRIENDTIME_FRONTDOOR]: gameData.FOREGROUND.ALL.MAKEFRIEND_FRONTDOOR,
        [keySceneDataTypes.BACKGROUND_FRIENDTIME_LIVINGROOM]: gameData.BACKGROUND.ALL.LIVINGROOM,
        [keySceneDataTypes.BACKGROUND_FRIENDTIME_LIVINGROOM2]: gameData.BACKGROUND.CAT.ATTENTION,
        [keySceneDataTypes.BACKGROUND_FRIENDTIME_WATER]: gameData.BACKGROUND.ALL.WATER,
        [keySceneDataTypes.OBJECT_FRONTDOOR]: gameData.ACTOR.ITEM.OBJECT.FRONTDOOR,
        [keySceneDataTypes.OBJECT_NUTRIENTBOWL]: gameData.ACTOR.ITEM.OBJECT.BOWL,
        [keySceneDataTypes.OBJECT_NUTRIENTCONTAINER]: gameData.ACTOR.ITEM.OBJECT.NUTRIENTCONTAINER,
        [keySceneDataTypes.OBJECT_PILLOW]: gameData.ACTOR.ITEM.OBJECT.PILLOW,
        [keySceneDataTypes.NPC_FRIEND]: gameData.ACTOR.NPC.STRAY.KITTEN,
      },
      COMBINED_BACKGROUND: [
        { FOREGROUND: [keySceneDataTypes.FOREGROUND_FRIENDTIME_FRONTDOOR] },
        { BACKGROUND: [keySceneDataTypes.BACKGROUND_FRIENDTIME_LIVINGROOM] },
        { BACKGROUND: [keySceneDataTypes.BACKGROUND_FRIENDTIME_WATER] },
        { BACKGROUND: [keySceneDataTypes.BACKGROUND_FRIENDTIME_LIVINGROOM2] },
      ],
      PAGES: [
        {
          SCREEN: keySceneDataTypes.SCREEN_FRIENDTIME_INTRO,
          CAPTION: keySceneDataTypes.CAPTION_FRIENDTIME_INTRO,
          CAMERA_POSITION: { x: 0.31, y: 0.7 },
          OBJECTS: [
            {
              NAME: keySceneDataTypes.OBJECT_FRONTDOOR,
              DEPTH: 2,
              COORDINATES: { x: -0.225, y: -0.185 },
              ANIMATION: {
                NAME: animationType.OBJECT_INTERACT,
                PLAYFIRST: true,
              },
            },
          ],
          INTERACT: {
            NPC: {
              DEPTH: 3,
              SCALE: 0.65,
              COORDINATES: { x: 0.25, y: 0.51 },
              ANIMATION_IDLE: animationType.IDLE,
              ANIMATIONS_SEQUENTIAL: [
                {
                  NAME: animationType.IDLE3,
                },
              ],
            },
            MAIN: {
              DEPTH: 1,
              TRANSITION_AFTER_OBJECT: true,
              ALPHA: 0,
              COORDINATES: { x: -0.3, y: 0.135 },
              ANIMATIONS_SEQUENTIAL: [
                {
                  NAME: animationType.WALK2,
                  ALPHA: 1,
                  COORDINATES: { x: 0.1, y: 0.135 },
                },
                {
                  NAME: animationType.IDLE3,
                },
              ],
            },
          },
          TRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
          ENDPAGETRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
        },
        {
          BACKGROUND_INDEX: 1,
          CAPTION: keySceneDataTypes.CAPTION_FRIENDTIME_MEET,
          CAMERA_POSITION: { x: 0.15, y: 0.5 },
          INTERACT: {
            NPC: {
              SCALE: 0.675,
              DEPTH: 3,
              COORDINATES: { x: 0.2, y: 0.6 },
              ANIMATIONS_SEQUENTIAL: [
                { NAME: animationType.IDLE },
                { NAME: animationType.IDLE2, REPEAT: 3 },
                { NAME: animationType.IDLE },
              ],
            },
            MAIN: {
              COORDINATES: { x: -0.15, y: 0.295 },
              DEPTH: 2,
              SCALE: 2.425,
              ANIMATION_IDLE: animationType.IDLE4,
              ANIMATIONS_SEQUENTIAL: [
                { NAME: animationType.SITBENDDRINK },
                { NAME: animationType.DRINK, REPEAT: 12 },
                { NAME: animationType.SITBENDDRINKFINISH, REVERSE: true },
                { NAME: animationType.IDLE4 },
              ],
            },
          },
          TRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
          ENDPAGETRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
        },
        {
          OBJECTS: [
            {
              NAME: keySceneDataTypes.OBJECT_NUTRIENTCONTAINER,
              FLIPX: true,
              DEPTH: 7,
              COORDINATES: { x: -0.05, y: 0.5 },
              ALPHAONEND: 0,
              ANIMATION: {
                ANIMATIONS_SEQUENTIAL: [
                  {
                    NAME: animationType.DRINK,
                    PLAYAS: {
                      PET: { NAME: animationType.WATCH },
                      [keySceneDataTypes.NPC_FRIEND]: { NAME: animationType.IDLE },
                      [keySceneDataTypes.OBJECT_NUTRIENTBOWL]: { NAME: animationType.DRINK },
                    },
                  },
                ],
              },
            },
            {
              NAME: keySceneDataTypes.OBJECT_NUTRIENTBOWL,
              DEPTH: 5,
              COORDINATES: { x: 0.05, y: 0.675 },
              ANIMATION: {
                ANIMATIONS_SEQUENTIAL: [
                  {
                    NAME: animationType.DRINKHALF,
                    PLAYAS: {
                      PET: { NAME: animationType.SITBENDDRINK },
                      [keySceneDataTypes.NPC_FRIEND]: { NAME: animationType.DRINK },
                    },
                  },
                  {
                    NAME: animationType.DRINKREMAINING,
                    PLAYAS: {
                      PET: { NAME: animationType.DRINK },
                      [keySceneDataTypes.NPC_FRIEND]: { NAME: animationType.SITBENDDRINK, REVERSE: true },
                    },
                  },
                ],
              },
            },
          ],
          BACKGROUND_INDEX: 2,
          CAMERA_POSITION: { x: 0.15, y: 0.8 },
          INTERACT: {
            NPC: {
              COORDINATES: { x: 0.01, y: 0.57 },
              DEPTH: 4,
              ANIMATION_IDLE: animationType.THIRSTY,
              ANIMATIONS_SEQUENTIAL: [{ NAME: animationType.THIRSTY }],
            },
            MAIN: {
              COORDINATES: { x: 0.125, y: 0.365 },
              DEPTH: 6,
              FLIPX: true,
              ANIMATION_IDLE: animationType.SITBENDDRINKFINISH,
              ANIMATIONS_SEQUENTIAL: [{ NAME: animationType.SITBENDDRINKFINISH }, { NAME: animationType.IDLE4 }],
            },
          },
          TRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
          ENDPAGETRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
        },
        {
          BACKGROUND_INDEX: 3,
          SCREEN: keySceneDataTypes.SCREEN_FRIENDTIME_OUTRO,
          CAPTION: keySceneDataTypes.CAPTION_FRIENDTIME_OUTRO,
          CAMERA_POSITION: { x: 0.15, y: 0.8 },
          OBJECTS: [
            {
              NAME: keySceneDataTypes.OBJECT_PILLOW,
              SCALE: 1.0,
              COORDINATES: { x: 0.4, y: 0.8 },
            },
          ],
          INTERACT: {
            NPC: {
              COORDINATES: { x: -0.8, y: 0.6 },
              DEPTH: 4,
              ANIMATIONS_SEQUENTIAL: [
                { NAME: animationType.WALK, COORDINATES: { x: 0.3, y: 0.6 } },
                { NAME: animationType.IDLE },
                { NAME: animationType.LAY },
                { NAME: animationType.SLEEP },
              ],
            },
            MAIN: {
              COORDINATES: { x: -0.8, y: 0.2 },
              DEPTH: 3,
              ANIMATIONS_SEQUENTIAL: [
                { NAME: animationType.WALK, COORDINATES: { x: -0.05, y: 0.3 }, FLIPX: false },
                { NAME: animationType.STAND_ATTENTION_TRANSITION },
                { NAME: animationType.PENDING_ATTENTION, DEPTH: 5 },
                { NAME: animationType.PENDING_SNOOZE },
                { NAME: animationType.SNOOZE },
              ],
            },
          },
          TRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
          ENDPAGETRANSITION: {
            TYPE: keySceneDataTypes.TRANSITION_FADE,
          },
        },
      ],
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.BUTTON_FRIENDTIME_INTRO,
              TEXTID: 'pets.buttons.friendtime.intro',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_FRIENDTIME_INTRO],
            },
            {
              NAME: keySceneDataTypes.BUTTON_FRIENDTIME_OUTRO_NO,
              TEXTID: 'pets.buttons.friendtime.outro.no',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_FRIENDTIME_OUTRO],
            },
            {
              NAME: keySceneDataTypes.BUTTON_FRIENDTIME_OUTRO_YES,
              TEXTID: 'pets.buttons.friendtime.outro.yes',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_FRIENDTIME_OUTRO],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.CAPTION_FRIENDTIME_INTRO,
              TITLEID: 'pets.caption.title.friendtime.intro',
              TEXTID: 'pets.caption.description.friendtime.intro',
            },
            {
              NAME: keySceneDataTypes.CAPTION_FRIENDTIME_MEET,
              TITLEID: 'pets.caption.title.friendtime.meet',
              TEXTID: 'pets.caption.description.friendtime.meet',
            },
            {
              NAME: keySceneDataTypes.CAPTION_FRIENDTIME_OUTRO,
              TITLEID: 'pets.caption.title.friendtime.outro',
              TEXTID: 'pets.caption.description.friendtime.outro',
            },
          ],
        },
      ],
    },
  },
};
