import styled from 'styled-components';
import { purple } from 'Styles/colors';

export const StyledGreetingCardsContainer = styled.div`
  min-height: fit-content;
  line-height: 1.25;
  flex: 1;
  align-item: center;

  > div {
    overflow-y: auto;
  }
`;

export const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledParagraph = styled.p`
  color: #000000;
  text-align: center;
  font-size: 16px;
`;

export const StyledParagraphStacked = styled(StyledParagraph)`
  margin-bottom: 0;
  margin-top: 0;
`;

const StyledButton = styled.button`
  padding: 16px 0px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 15px;

  &:disabled {
    color: #29265e50;
    background-color: #e8e8e8;
    border: solid 1px #e8e8e8;
  }
`;

export const StyledOutlineButton = styled(StyledButton)`
  color: ${purple._900};
  background-color: #ffffff;
  border: solid 1px #4a4f7f;
`;

export const StyledSolidButton = styled(StyledButton)`
  color: #ffffff;
  background-color: ${purple._900};
  border: solid 1px ${purple._900};
`;
