import { atom, getDefaultStore, useAtom } from 'jotai';
import { IScreeningTakerModuleStore } from 'Core_Pages/Screening/IScreeningTakerModuleStore';
import { questions } from './Form/config';

export class AdultNeedsScreeningModuleStore implements IScreeningTakerModuleStore {
  private atomStore = getDefaultStore();
  private answersAtom;
  private formValidAtom;
  private loadingAtom;
  private completeCallback: (answers) => Promise<void>;

  constructor() {
    this.answersAtom = atom(this.createDefaultAnswers());
    this.formValidAtom = atom(false);
    this.loadingAtom = atom(false);
  }

  private get Answers(): { [key: string]: string | null } {
    return this.atomStore.get(this.answersAtom);
  }

  public get IsValid(): boolean {
    return this.atomStore.get(this.formValidAtom);
  }

  public get IsLoading(): boolean {
    return this.atomStore.get(this.loadingAtom);
  }

  private set IsLoading(isLoading: boolean) {
    this.atomStore.set(this.loadingAtom, isLoading);
  }

  private createDefaultAnswers() {
    const defaultQuestions = {};
    questions.map((x) => (defaultQuestions[x.name] = undefined));
    return defaultQuestions;
  }

  public Use = () => {
    useAtom(this.answersAtom);
    useAtom(this.formValidAtom);
    useAtom(this.loadingAtom);

    return this;
  };

  create(completeCallback: (answers) => Promise<void>): this {
    this.completeCallback = completeCallback;
    return this;
  }

  updateAnswer(question, answer) {
    const currentAnswers = this.atomStore.get(this.answersAtom);
    currentAnswers[question] = answer;
    this.atomStore.set(this.answersAtom, currentAnswers);
    this.validate();
  }

  validate() {
    const missingAnswers = Object.keys(this.Answers).filter((x) => !this.Answers[x]);
    const isValid = missingAnswers.length === 0;
    this.atomStore.set(this.formValidAtom, isValid);
  }

  async complete() {
    this.IsLoading = true;

    this.validate();

    if (this.IsValid) {
      const answers = Object.keys(this.Answers).map((x) => this.Answers[x]);
      const formattedAnswers = answers.flat().map((r) => {
        if (typeof r == 'string') {
          return { answerOptionGuid: r };
        } else return r;
      });

      this.completeCallback(formattedAnswers).then(() => (this.IsLoading = false));
    } else {
      this.IsLoading = false;
    }
  }
}
