// theme colors in sketch https://www.sketch.com/s/8f8b71c9-5e50-4517-b70f-851a5ad15bcd/a/DPWJrMa
export const purple = {
  _050: '#e8eaf2',
  _100: '#c6c9e0',
  _200: '#a1a7ca',
  _300: '#7e85b4',
  _400: '#646ba5',
  _500: '#4b5196',
  _600: '#45498d',
  _700: '#3c4082',
  _800: '#353675',
  _900: '#29265e',
};
export const teal = {
  _050: '#e0f2f3',
  _100: '#b1dee0',
  _200: '#7dc9cc',
  _300: '#45b4b6',
  _400: '#0fa4a5',
  _500: '#009493',
  _600: '#008785',
  _700: '#007774',
  _800: '#006764',
  _900: '#004b46',
};

export const blue = {
  _050: '#eee5fb',
  _100: '#d2c0f5',
  _200: '#b495ef',
  _300: '#9569ea',
  _400: '#7a45e6',
  _500: '#5d1be0',
  _600: '#5116da',
  _700: '#3d0ad2',
  _800: '#2200cc',
  _900: '#0000c5',
};

// these are (4) "official" options for black
export const black = {
  _100: 'rgba(0, 0, 0, 1)',
  _87: 'rgba(0, 0, 0, 0.87)',
  _74: 'rgba(0, 0, 0, 0.74)',
  _50: 'rgba(0, 0, 0, 0.50)',
  _60: 'rgba(0, 0, 0, 0.60)',
  _38: 'rgba(0, 0, 0, 0.38)',
};

/*
 * the below color variable will be phased out as some of these
 * colors are not present in the new theme
 *  */

export const legacyPurple = '#9047B7';

export const legacyFontColors = {
  indigo: '#4a4f7f',
  teal: '#2f6e6f',
  red: '#de1e0d',
  grey: '#2B2B2B',
};

export const legacyBackgroundColors = {
  // lets chat
  yellowGreen: '#C0D23F',
  // Andy
  teal: '#009688',
  // My Tools
  purple: legacyPurple,
  // Thrive
  yellowOrange: '#F9C927',

  indigo: '#3F437C',
};
