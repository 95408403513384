import React, { useEffect } from 'react';
import {
  ScrollableContent,
  StyledButtonWithMargin,
  StyledContainerWithLinearGradientBackground,
  ThriveActivitiesContainer,
  StyledBackButton,
} from 'Core_Pages/Thrive/ThriveActivities/styles';
import { useIntl } from 'react-intl';
import ThriveWeek from './ThriveWeek/ThriveWeek';
import { List, Typography } from '@mui/material';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { nextPage, selectThrive } from 'Core_Redux/thrive';
import { selectSyncUser, selectUserInfo } from 'Core_Redux/user';
import { useHistory } from 'react-router-dom';
import HeaderBar from 'Core_Components/HeaderBar/HeaderBar';
import { ArrowBackIos } from '@mui/icons-material';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';
import useMediaType from 'Core_Helpers/useMediaType';
import { breakpoints } from 'Styles/theme';
import { transformText } from 'Core_Helpers/transformText';
import api from 'Core_Redux/services/api';
import { thriveProgramTypes } from '../ThriveConfig/enums';

function ThriveActivities() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useCoreDispatch();
  const syncUser = useCoreSelector(selectSyncUser);
  const user = useCoreSelector(selectUserInfo);
  const { thriveProgress, thriveAccess } = useCoreSelector(selectThrive);
  const hasFirstScreeningBadge =
    thriveProgress?.thriveWeeks[0].badges.find((a) => a.badgeType === 'SCREENING')?.badgeStatus === 'COMPLETE';

  const mediaType = useMediaType();

  const onNext = () => dispatch(nextPage({ history }));
  const onBack = () => history.push(HOME);

  useEffect(() => {
    if (
      thriveAccess?.programType === thriveProgramTypes.STANDARD &&
      !hasFirstScreeningBadge &&
      thriveProgress?.currentThriveWeek === 1
    ) {
      dispatch(api.endpoints.getThriveProgress.initiate(user?.sub));
    }
  }, []);

  return (
    <ThriveActivitiesContainer>
      <HeaderBar sx={{ boxShadow: 2, zIndex: 1 }} style={{ background: 'linear-gradient(#FFE8A1, #ffffff)' }}>
        <Typography component="h1" fontSize={18} fontWeight={700}>
          {intl.formatMessage({ id: 'thrive.title' })}
        </Typography>
      </HeaderBar>
      <StyledContainerWithLinearGradientBackground>
        {mediaType !== breakpoints.DESKTOP && (
          <StyledBackButton onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })}>
            <ArrowBackIos />
          </StyledBackButton>
        )}
        <Typography component="h2" fontSize={20} fontWeight={700}>
          {intl.formatMessage({ id: 'thrive.activities.title' })}
        </Typography>
        <Typography fontSize={16} textAlign={'center'} width={200} paddingTop={'15px'}>
          {transformText(intl.formatMessage({ id: 'thrive.activities.keep-going' }), syncUser)}
        </Typography>
      </StyledContainerWithLinearGradientBackground>
      <ScrollableContent>
        <List>
          {thriveProgress?.thriveWeeks?.map((thriveWeek, weekIndex) => (
            <ThriveWeek
              key={weekIndex}
              disabled={thriveProgress?.currentThriveWeek < weekIndex + 1}
              index={weekIndex}
              thriveWeek={thriveWeek}
            />
          ))}
        </List>
        <StyledButtonWithMargin onClick={onNext}>{intl.formatMessage({ id: 'common.ok' })}</StyledButtonWithMargin>
      </ScrollableContent>
    </ThriveActivitiesContainer>
  );
}

export default ThriveActivities;
