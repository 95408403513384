export const loadingScreenConfig = {
  frameHeight: 1200,
  frameWidth: 3600,
  startFrame: 0,
  endFrame: 11,
  repeat: -1,
  xPosition: 0.5,
  yPosition: 0.5,
  scale: 0.175,
  animationKey: 'loading-spinner',
  spriteKey: 'spinner',
  loadingScreenSprite: 'spinner.png',
};
