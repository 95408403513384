import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import Button from 'Stories/components/Button';
import { useIntl } from 'react-intl';
import { selectStep } from 'Onboard_Redux/login';
import { useOnboardSelector } from 'Contexts/StoreContext';
import { OTP } from '../LogIn/steps';
import { PatternFormat } from 'react-number-format';
import { blue } from 'Styles/colors';

function OtpFooter({ sentTo, contactType, onBack }) {
  const intl = useIntl();
  const currentStep = useOnboardSelector(selectStep);

  const address =
    contactType === 'phone' ? (
      <PatternFormat valueIsNumericString format="+1 (###) ###-####" value={sentTo} displayType="text" />
    ) : (
      sentTo
    );

  return (
    <>
      <Typography textAlign={'center'}>{intl.formatMessage({ id: 'account.otp.code-sent' }, { address })}</Typography>
      {currentStep === OTP ? null : (
        <Button fullWidth onClick={onBack} variant={Button.variant.TEXT} sx={{ margin: '5px 0 0', color: blue._800 }}>
          {intl.formatMessage({
            id: contactType === 'email' ? 'account.otp.change-email' : 'account.otp.change-phone',
          })}
        </Button>
      )}
    </>
  );
}

OtpFooter.propTypes = {
  sentTo: PropTypes.string.isRequired,
  contactType: PropTypes.oneOf(['phone', 'email']).isRequired,
  onBack: PropTypes.func.isRequired,
};

export default OtpFooter;
