import { uiTypes } from 'Core_Games/constants/uiTypes';
import { gameData } from 'Core_Games/constants/gameData';
import { InteractionType } from 'Core_Games/constants/interactionType';
import { playerActorType } from 'Core_Games/constants/playerActorType';
import { animationType } from 'Core_Games/constants/animationType';

export const keySceneDataTypes = {
  // Objects
  BACKGROUND: 'BACKGROUND',
  BOWL: 'BOWL',
  NUTRIENTCONTAINER: 'NUTRIENTCONTAINER',
  PET_NUTRIENTPOURED: 'PET_NUTRIENTPOURED',
  BOWL_NUTRIENTPOURED: 'BOWL_NUTRIENTPOURED',
  // Captions
  INTRO_CAPTION: 'INTRO_CAPTION',
  FEED_END_CAPTION: 'FEED_END_CAPTION',
  AGAIN_CAPTION: 'AGAIN_CAPTION',
  CARE_CAPTION: 'CARE_CAPTION',
  OUTRO_CAPTION: 'OUTRO_CAPTION',
  BACK_CAPTION: 'BACK_CAPTION',
  // Buttons
  FEED_BUTTON: 'FEED_BUTTON',
  DONE_BUTTON: 'DONE_BUTTON',
  AGAIN_BUTTON: 'AGAIN_BUTTON',
  NOT_REALLY_BUTTON: 'NOT_REALLY_BUTTON',
  IAM_BUTTON: 'IAM_BUTTON',
  OUTRO_BUTTON: 'OUTRO_BUTTON',
  BACK_BUTTON: 'BACK_BUTTON',
  // Screen
  SCREEN_INTRO: 'SCREEN_INTRO',
  SCREEN_AGAIN: 'SCREEN_AGAIN',
  SCREEN_CARE: 'SCREEN_CARE',
  SCREEN_OUTRO: 'SCREEN_OUTRO',
  SCREEN_BACK: 'SCREEN_BACK',
};

export const nutritionConfig = {
  [playerActorType.DOG.NAME]: {
    [InteractionType.FEED.NAME]: {
      GAMEBACKGROUNDCOLOR: '#FFFFFF',
      NAME: InteractionType.FEED.NAME,
      NUTRITIONANIMATIONTYPE: animationType.EAT,
      IDLENUTRITIONANIMATION: animationType.HUNGRY,
      ACTIONLIMIT: 3,
      PETSCALE: 2.5,
      ADJUSTFORANIMATIONOFFSET: true,
      STARTXOFFSET: 0.15,
      PETOFFSETY: 0.065,
      BOWLOFFSETY: 1.125,
      PETFINISHEDOFFSETX: 0.65,
      PETFINISHEDOFFSETY: 1.75,
      STARTX: 0.4,
      STARTY: 0.38,
      BACKGROUNDSPLIT: 1,
      BOUNDS: {
        HEIGHT: {
          START: 0.10606061,
          END: 0.0,
        },
        WIDTH: {
          START: 0.05555,
          END: 0.41666666666,
        },
      },
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.DOG.FOOD,
        [keySceneDataTypes.BOWL]: gameData.ACTOR.ITEM.OBJECT.BOWL,
        [keySceneDataTypes.NUTRIENTCONTAINER]: gameData.ACTOR.ITEM.OBJECT.NUTRIENTCONTAINER,
      },
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.FEED_BUTTON,
              TEXTID: 'pets.buttons.feed.feed-now',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_INTRO],
            },
            {
              NAME: keySceneDataTypes.DONE_BUTTON,
              TEXTID: 'pets.buttons.feed.no-thanks',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_AGAIN],
            },
            {
              NAME: keySceneDataTypes.AGAIN_BUTTON,
              TEXTID: 'pets.buttons.feed.feed-again',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_AGAIN],
            },
            {
              NAME: keySceneDataTypes.NOT_REALLY_BUTTON,
              TEXTID: 'pets.buttons.feed.not-really',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_CARE],
            },
            {
              NAME: keySceneDataTypes.IAM_BUTTON,
              TEXTID: 'pets.buttons.feed.i-am',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_CARE],
            },
            {
              NAME: keySceneDataTypes.OUTRO_BUTTON,
              TEXTID: 'pets.buttons.feed.activities',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.BACK_BUTTON,
              TEXTID: 'pets.buttons.feed.back',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_BACK],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.INTRO_CAPTION,
              TITLEID: 'pets.caption.title.feed.hungry',
            },
            {
              NAME: keySceneDataTypes.FEED_END_CAPTION,
              TITLEID: 'pets.caption.title.feed.good-job',
              TEXTID: 'pets.caption.description.feed.good-job',
            },
            {
              NAME: keySceneDataTypes.AGAIN_CAPTION,
              TITLEID: 'pets.caption.title.feed.hungry-again',
              TEXTID: 'pets.caption.description.feed.hungry-again',
            },
            {
              NAME: keySceneDataTypes.CARE_CAPTION,
              TITLEID: 'pets.caption.title.feed.care',
              TEXTID: 'pets.caption.description.feed.care',
            },
            {
              NAME: keySceneDataTypes.OUTRO_CAPTION,
              TITLEID: 'pets.caption.title.feed.outro-food',
              TEXTID: 'pets.caption.description.feed.outro-food',
            },
            {
              NAME: keySceneDataTypes.BACK_CAPTION,
              TITLEID: 'pets.caption.title.feed.back-food',
            },
          ],
        },
      ],
    },
    [InteractionType.HYDRATE.NAME]: {
      NAME: InteractionType.HYDRATE.NAME,
      NUTRITIONANIMATIONTYPE: animationType.DRINK,
      IDLENUTRITIONANIMATION: animationType.THIRSTY,
      ACTIONLIMIT: 1,
      PETSCALE: 2.5,
      ADJUSTFORANIMATIONOFFSET: true,
      RIGHTFACING: true,
      BOWLOFFSETX: 2,
      NUTRIENTOFFSETX: 0.2,
      PETFINISHEDOFFSETX: 4.25,
      PETFINISHEDOFFSETY: 1.4,
      PETOFFSETY: 0.1,
      STARTX: -0.3,
      STARTY: 0.375,
      BACKGROUNDSPLIT: 1,
      BOUNDS: {
        HEIGHT: {
          START: 0.10606061,
          END: 0.0,
        },
        WIDTH: {
          START: 0.05555,
          END: 0.41666666666,
        },
      },
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.ALL.WATER,
        [keySceneDataTypes.BOWL]: gameData.ACTOR.ITEM.OBJECT.BOWL,
        [keySceneDataTypes.NUTRIENTCONTAINER]: gameData.ACTOR.ITEM.OBJECT.NUTRIENTCONTAINER,
      },
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.FEED_BUTTON,
              TEXTID: 'pets.buttons.feed.water-now',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_INTRO],
            },
            {
              NAME: keySceneDataTypes.OUTRO_BUTTON,
              TEXTID: 'pets.buttons.feed.activities',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.BACK_BUTTON,
              TEXTID: 'pets.buttons.feed.back',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_BACK],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.INTRO_CAPTION,
              TITLEID: 'pets.caption.title.feed.thirsty',
            },
            {
              NAME: keySceneDataTypes.OUTRO_CAPTION,
              TITLEID: 'pets.caption.title.feed.outro-water',
              TEXTID: 'pets.caption.description.feed.outro-water',
            },
            {
              NAME: keySceneDataTypes.BACK_CAPTION,
              TITLEID: 'pets.caption.title.feed.back-water',
              TEXTID: 'pets.caption.description.feed.back-water',
            },
          ],
        },
      ],
    },
  },
  [playerActorType.CAT.NAME]: {
    [InteractionType.FEED.NAME]: {
      NAME: InteractionType.FEED.NAME,
      NUTRITIONANIMATIONTYPE: animationType.EAT,
      IDLENUTRITIONANIMATION: animationType.HUNGRY,
      WATCHIMMEDIATELY: true,
      ACTIONLIMIT: 3,
      PETSCALE: 1.6,
      PETFINISHEDOFFSETX: 0.5,
      PETFINISHEDOFFSETY: -0.15,
      STARTXOFFSET: 0.15,
      PETOFFSETY: 0.1,
      BOWLSCALE: 0.3,
      BOWLOFFSETY: 1.025,
      BOWLOFFSETX: 0.5,
      STARTX: 0.4,
      STARTY: 0.565,
      BACKGROUNDSPLIT: 1,
      BOUNDS: {
        HEIGHT: {
          START: 0.10606061,
          END: 0.0,
        },
        WIDTH: {
          START: 0.05555,
          END: 0.41666666666,
        },
      },
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.CAT.FOOD,
        [keySceneDataTypes.BOWL]: gameData.ACTOR.ITEM.OBJECT.BOWL,
        [keySceneDataTypes.NUTRIENTCONTAINER]: gameData.ACTOR.ITEM.OBJECT.NUTRIENTCONTAINER,
      },
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.FEED_BUTTON,
              TEXTID: 'pets.buttons.feed.feed-now',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_INTRO],
            },
            {
              NAME: keySceneDataTypes.DONE_BUTTON,
              TEXTID: 'pets.buttons.feed.no-thanks',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_AGAIN],
            },
            {
              NAME: keySceneDataTypes.AGAIN_BUTTON,
              TEXTID: 'pets.buttons.feed.feed-again',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_AGAIN],
            },
            {
              NAME: keySceneDataTypes.NOT_REALLY_BUTTON,
              TEXTID: 'pets.buttons.feed.not-really',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_CARE],
            },
            {
              NAME: keySceneDataTypes.IAM_BUTTON,
              TEXTID: 'pets.buttons.feed.i-am',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_CARE],
            },
            {
              NAME: keySceneDataTypes.OUTRO_BUTTON,
              TEXTID: 'pets.buttons.feed.activities',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.BACK_BUTTON,
              TEXTID: 'pets.buttons.feed.back',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_BACK],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.INTRO_CAPTION,
              TITLEID: 'pets.caption.title.feed.hungry',
            },
            {
              NAME: keySceneDataTypes.FEED_END_CAPTION,
              TITLEID: 'pets.caption.title.feed.good-job',
              TEXTID: 'pets.caption.description.feed.good-job',
            },
            {
              NAME: keySceneDataTypes.AGAIN_CAPTION,
              TITLEID: 'pets.caption.title.feed.hungry-again',
              TEXTID: 'pets.caption.description.feed.hungry-again',
            },
            {
              NAME: keySceneDataTypes.CARE_CAPTION,
              TITLEID: 'pets.caption.title.feed.care',
              TEXTID: 'pets.caption.description.feed.care',
            },
            {
              NAME: keySceneDataTypes.OUTRO_CAPTION,
              TITLEID: 'pets.caption.title.feed.outro-food',
              TEXTID: 'pets.caption.description.feed.outro-food',
            },
            {
              NAME: keySceneDataTypes.BACK_CAPTION,
              TITLEID: 'pets.caption.title.feed.back-food',
            },
          ],
        },
      ],
    },
    [InteractionType.HYDRATE.NAME]: {
      NAME: InteractionType.HYDRATE.NAME,
      NUTRITIONANIMATIONTYPE: animationType.DRINK,
      IDLENUTRITIONANIMATION: animationType.THIRSTY,
      ACTIONLIMIT: 1,
      WATCHIMMEDIATELY: true,
      RIGHTFACING: true,
      PETFINISHEDOFFSETX: 0.35,
      PETFINISHEDOFFSETY: -0.25,
      NUTRIENTOFFSETX: 0.1,
      PETOFFSETY: 0.095,
      BOWLOFFSETX: 2.1,
      BOWLSCALE: 0.3,
      PETSCALE: 1.6,
      STARTX: -0.1,
      STARTY: 0.55,
      BACKGROUNDSPLIT: 1,
      BOUNDS: {
        HEIGHT: {
          START: 0.10606061,
          END: 0.0,
        },
        WIDTH: {
          START: 0.05555,
          END: 0.41666666666,
        },
      },
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.ALL.WATER,
        [keySceneDataTypes.BOWL]: gameData.ACTOR.ITEM.OBJECT.BOWL,
        [keySceneDataTypes.NUTRIENTCONTAINER]: gameData.ACTOR.ITEM.OBJECT.NUTRIENTCONTAINER,
      },
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.FEED_BUTTON,
              TEXTID: 'pets.buttons.feed.water-now',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_INTRO],
            },
            {
              NAME: keySceneDataTypes.OUTRO_BUTTON,
              TEXTID: 'pets.buttons.feed.activities',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.BACK_BUTTON,
              TEXTID: 'pets.buttons.feed.back',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_BACK],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.INTRO_CAPTION,
              TITLEID: 'pets.caption.title.feed.thirsty',
            },
            {
              NAME: keySceneDataTypes.OUTRO_CAPTION,
              TITLEID: 'pets.caption.title.feed.outro-water',
              TEXTID: 'pets.caption.description.feed.outro-water',
            },
            {
              NAME: keySceneDataTypes.BACK_CAPTION,
              TITLEID: 'pets.caption.title.feed.back-water',
              TEXTID: 'pets.caption.description.feed.back-water',
            },
          ],
        },
      ],
    },
  },
};
