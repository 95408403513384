import React from 'react';
import { ScreeningModuleStore } from 'Core_Pages/Screening/ScreeningModuleStore';
import ResultsBar from 'Core_Pages/Screening/Results/components/ResultsBar/ResultsBar';
import { Screenings } from 'Core_Pages/Screening/enumerations/enums';
import { useIntl } from 'react-intl';
import { scoreConfig } from './config';

const screeningGuid = Screenings.YOUTH_UCLA3;

const ResultsBody = () => {
  const intl = useIntl();
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const result = screeningStore.ScreeningResults.find((x) => x.screeningGuid === screeningGuid);
  const segments = scoreConfig.map((x) => ({ color: x.color, label: intl.formatMessage({ id: x.textId }) }));
  const invertedScoreIndex = [...scoreConfig]
    .sort((a, b) => b.minScore - a.minScore)
    .findIndex((x) => result.score >= x.minScore);

  return <ResultsBar segments={segments} scoreIndex={scoreConfig.length - 1 - invertedScoreIndex} />;
};

export default ResultsBody;
