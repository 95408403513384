import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useIntl } from 'react-intl';
import { TextContainer, IconContainer } from './styles';
import StoredImage from 'Onboard_Components/StoredImage';

function SampleModal({ show, setShow }) {
  const intl = useIntl();

  function onCloseClick() {
    setShow(false);
  }

  return (
    <Dialog sx={{ marginTop: '100px' }} open={show}>
      <IconContainer>
        <CloseIcon
          data-testid="CloseButton"
          onClick={onCloseClick}
          alt={intl.formatMessage({ id: 'button.close' })}
          sx={{ margin: '16px 16px 0 0', fontSize: '24px' }}
        />
      </IconContainer>
      <TextContainer>
        <DialogTitle sx={{ paddingBottom: '0', paddingTop: '30px' }}>
          {intl.formatMessage({ id: 'account.sign-up.member-id-sample' })}
        </DialogTitle>
        <p style={{ fontSize: '16px', marginBottom: '32px' }}>
          {intl.formatMessage({ id: 'account.sign-up.member-id-sample-text' })}
        </p>
        <StoredImage
          container="pwa-images"
          assetName="example-health-plan-card.svg"
          altText={intl.formatMessage({ id: 'account.sign-up.member-id-sample' })}
        ></StoredImage>
      </TextContainer>
    </Dialog>
  );
}

SampleModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
};

export default SampleModal;
