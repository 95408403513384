export const NONE = 0;
export const PYXIR_CHAT = 1;
export const ANDY_CHAT = 2;
export const SYMPTOM_SCREENING = 3;
export const HAMBURGER = 4;
export const PROFILE = 5;
export const MANAGE_PYX_PALS = 6;
export const FIND_PROVIDER = 7;
export const FIND_SERVICES = 8;
export const TRIAGE = 9;
export const EXTENDED_MENU = 10;

export const appViews = {
  0: 'None',
  1: 'PyxirChat',
  2: 'AndyChat',
  3: 'SymptomScreening',
  4: 'Hamburger',
  5: 'Profile',
  6: 'ManagePyxPals',
  7: 'FindProvider',
  8: 'FindServices',
  9: 'Triage',
  10: 'ExtendedMenu',
};
