import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Answer from './Answer/Answer';
import { AnswerWrapper, QuestionWrapper, StyledQuestion } from './styles';

const Question = ({ name, text, QuestionComponent, questionProps, ...props }) => {
  const WrapperQuestion = QuestionComponent ? QuestionComponent : StyledQuestion;
  return (
    <QuestionWrapper className="FormQuestion-root">
      <WrapperQuestion {...questionProps} className="FormQuestion-wrapper-question">
        <Typography id={`${name}-question`} className="FormQuestion-question">
          {text}
        </Typography>
      </WrapperQuestion>
      <AnswerWrapper className="FormAnswer-root">
        <Answer questionName={name} {...props} />
      </AnswerWrapper>
    </QuestionWrapper>
  );
};

Question.displayName = 'Question';

Question.propTypes = {
  QuestionComponent: PropTypes.element,
  questionProps: PropTypes.object,
  text: PropTypes.string,
  name: PropTypes.string,
};

export default Question;
