import React from 'react';
import { useIntl } from 'react-intl';
import { ScreeningModuleStore } from 'Core_Pages/Screening/ScreeningModuleStore';
import ResultsSymptom from 'Core_Pages/Screening/Results/components/ResultsSymptom/ResultsSymptom';
import { Screenings } from 'Core_Pages/Screening/enumerations/enums';
import SymptomWrapper from 'Core_Pages/Screening/Results/components/ResultsSymptom/SymptomWrapper';

const screeningGuid = Screenings.YOUTH_PHQ2;

const ResultsBody = () => {
  const intl = useIntl();
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const result = screeningStore.ScreeningResults.find((x) => x.screeningGuid === screeningGuid);

  return (
    <SymptomWrapper>
      <ResultsSymptom
        title={intl.formatMessage({ id: 'screenings.results.youth.phq2.symptoms' })}
        value={result?.hasUrgentNeed}
      />
    </SymptomWrapper>
  );
};

export default ResultsBody;
