import React from 'react';
import PropTypes from 'prop-types';

// https://developers.google.com/fonts/docs/material_icons
function GoogleFontIcon({ name, variant }) {
  return (
    <span className={variant} aria-hidden="true">
      {name}
    </span>
  );
}

GoogleFontIcon.Name = {
  AccessAlarm: 'access_alarm',
  AutoRenew: 'autorenew',
  Check: 'check',
  ChevronRight: 'chevron_right',
  Clear: 'clear',
  MoreHoriz: 'more_horiz',
  Notifications: 'notifications',
  NotificationsAdd: 'notification_add',
};

GoogleFontIcon.Variant = {
  Default: 'material-icons',
  Outlined: 'material-icons-outlined',
};

GoogleFontIcon.propTypes = {
  name: PropTypes.oneOf(Object.values(GoogleFontIcon.Name)).isRequired,
  variant: PropTypes.oneOf(Object.values(GoogleFontIcon.Variant)),
};

GoogleFontIcon.defaultProps = {
  variant: GoogleFontIcon.Variant.Default,
};

export default GoogleFontIcon;
