import styled from 'styled-components';

export const StyledHiddenRadio = styled.input`
  opacity: 0;
  position: absolute;

  &:focus + label {
    outline: 2px solid -webkit-focus-ring-color;
  }
`;
