import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { breakpoints } from 'Styles/theme';

export const StyledGrid = styled(Grid)(
  ({ theme }) => `
    padding: 9px 15px;

    ${theme.breakpoints.up(breakpoints.TABLET)} {
      padding: 20px 15px;
    }
`,
);
