import { memoryImpairments } from 'Assets/img/my-tools-icons';

export const memoryLossEn = {
  key: 'memory-loss',
  title: 'Engage Those With Memory Loss',
  titleImage: memoryImpairments,
  titleImageAlt: '',
  contentId: 25,
  sections: [
    {
      key: 'activities-to-engage',
      description:
        'If you are reading this article, you likely have someone in your life who has memory loss, cognition impairments, or a diagnosis of dementia or Alzheimer’s. This is a challenging diagnosis and as the disease progresses, it is one that typically impacts you more than the person with cognition impairments. Here are some activities that you can do during different progressions of the disease that can help you better connect with this individual or that can help create more enjoyable interactions together throughout each day.',
    },
    {
      key: 'finding-interest',
      header: 'Finding Activities of Interest',
      description:
        'It can be challenging to find activities that can fill the day for someone with cognition impairments. As the disease progresses, the individual with memory loss often has a decreased attention span or increased challenges staying focused on a task or activity. To engage someone with memory loss, it can be important to find activities that will keep their attention for as long as possible. This means finding activities that were once a passion of theirs. For example, if the individual you are caring for was a car mechanic, find some old car parts they can “fix”. If they were a construction working find someone who can make a smooth, sanded board with some pre-drilled holes (so it is safe) that they can a hammer or use a screwdriver to create something. If they loved sewing or cross stitch, find some crafts that use larger, less sharp needles. As the disease progresses, finding items (even toys) that are similar to what they loved can be activities they enjoy doing over and over again. There are items available for purchase that are blankets or materials with multiple activities such as buttons, zippers, or other activities. Not only are these activities great for enjoyment, but they also help maintain and work on fine motor skills.',
    },
    {
      key: 'memory-interests-music',
      header: 'Music',
      description:
        'Music can be a great way to help with memory recall and is often one of the last portions of the brain to be impacted by the disease. Music that was played, sung, or listened to when the individual was younger can often bring up good memories. You may be surprised that singing familiar songs, even for those in advanced stages of the disease, can allow them to recall melodies, words, and how their hands may have moved when playing an instrument. There are many free music applications that you can use to search by artist, year, or genre to play as well.',
    },
    {
      key: 'memory-interests-games',
      header: 'Games',
      description:
        'Did you know that games can help retain memories and slow the progression of cognition impairment diseases? There are many types of games that help with cognition. Start with games that the individual enjoyed previously. This way they can use their long-term memory to recall how to play instead of trying to teach a new game. Card or board games can be great or any type of game with tiles that need matching or sequencing. There are number and sequencing games that are made for youth or have been re-branded for this older population. Trivia type games can help be fun and can encourage using long and short-term memory recall.',
    },
    {
      key: 'memory-interests-get-outside',
      header: 'Get Outside',
      description:
        'Sunshine and fresh air can greatly improve our emotional well-being. Here are a handful of activities that you can do outside together:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Take a walk (or push the individual you are caring for in a wheelchair) on a beautiful path at a nature area, at a park, or around your neighborhood.',
        },
        {
          text: 'Raised garden beds can offer hours of enjoyment working in the dirt and watching the garden grow vegetables or flowers. There are multiple type and heights of garden beds that you can make or purchase that are great for gardening while sitting in a wheelchair or a chair. This can be a great activity for those who enjoyed working with their hands. ',
        },
        {
          text: 'Take a trip to see an event. Going to a sporting event, a play, a music concert, or an art show can help with memory recall and can be an enjoyable activity for you and the individual you are caring for. Try attending smaller events that would be less stimulating or overwhelming such as a kid’s soccer game or a local art fair.',
        },
      ],
    },
    {
      key: 'memory-outro',
      description:
        'Outside activities can be challenging depending on the mobility of the individual you are caring for. For example, find or ask about transportation options that can transport wheelchairs using a lift. Opening windows on a nice day or placing the individual’s chair or bed by an open window can also be helpful.',
    },
  ],
};
