import { Actor } from 'Core_Games/Actor/Actor';
import { oldManAssetConfig } from './oldManConfig';

export class OldMan extends Actor {
  constructor(scene, x, y, color, petType, frame) {
    let currentConfig = oldManAssetConfig;
    super(scene, x, y, currentConfig, currentConfig.NAME, frame);

    this.spriteName = this.ObjectData.NAME;
    this.Inventory = this.ObjectData.INVENTORY;
    this.Sounds = this.ObjectData.AUDIO;
    this.chatBubbleCoordinatesPercentage = this.ObjectData.CHATBUBBLECOORDINATES;

    this.currentSpriteSheet = color;
    this.originalScale = this.ObjectData.SCALE;
  }
}
