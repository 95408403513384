import { stressReduction } from 'Assets/img/my-tools-icons';

export const stressReductionEs = {
  key: 'countdown-stress',
  title: 'Reducción de Estrés',
  titleImage: stressReduction,
  titleImageAlt: '',
  description: 'Usar los sentidos puede ayudarte a sentirte conectado en el presente cuando surgen desafíos.',
  contentId: 6,
  sections: [
    {
      key: 'calm-breaths',
      header: 'Primero, comienza por tomar algunas respiraciones profundas para calmarte, luego mira a tu alrededor.',
      description: '',
      itemsHeader: 'Cuéntame más:',
      items: [
        {
          text: 'Inhala lentamente durante 5 segundos, mantén el aire durante 5 segundos y exhala lentamente durante 5 segundos.',
        },
        { text: 'Luego, mira a tu alrededor.' },
      ],
    },
    {
      key: 'focus-around',
      header:
        'A continuación, describe el entorno con 5 cosas que puedas ver, 4 cosas que puedas sentir, 3 cosas que puedas escuchar, 2 cosas que puedas oler y 1 cosa que puedas saborear.',
      description: '',
      itemsHeader: 'Cuéntame más:',
      items: [
        { text: 'Enfócate en el espacio inmediato a tu alrededor.' },
        {
          text: 'Descríbelo a través de tus percepciones sensoriales de la vista, el oído, el olfato, el tacto y el gusto.',
        },
        { text: 'Puedes expresar las descripciones en voz alta o mentalmente.' },
        {
          text: '¿Qué elementos notas?',
          subItems: [
            { text: '¿5 cosas que puedas ver?' },
            { text: '¿4 cosas que puedas sentir?' },
            { text: '¿3 cosas que puedas escuchar?' },
            { text: '¿2 cosas que puedas oler?' },
            { text: '¿1 cosa que puedas saborear?' },
          ],
        },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Autorreflexión',
      description: 'Dedica un momento de tranquilidad para revisar tu experiencia.',
      itemsHeader: 'Percepciones y marcadores del camino',
      items: [
        { text: '¿Cómo te sentías antes de completar este ejercicio?' },
        { text: '¿Cómo te sientes después de hacerlo?' },
        { text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?' },
        { text: '¿Qué disfrutaste más de este ejercicio?' },
        {
          text: 'En una escala del 1 (nada estresado) al 10 (muy estresado), ¿cómo calificarías el estrés que sentías antes de completar este ejercicio?',
        },
        {
          text: 'En una escala del 1 (nada estresado) al 10 (muy estresado), ¿cómo calificarías el estrés que sientes después de completar este ejercicio?',
          divider: true,
        },
        {
          text: 'Describir conscientemente tu entorno mediante los sentidos puede ayudar a bloquear los pensamientos fugaces o las emociones en aumento, y respaldar la sensación de estar más enraizado y equilibrado en el presente.',
        },
        {
          text: 'Las percepciones sensoriales pueden ayudar a manejar situaciones estresantes y fortalecer la capacidad de sentirte equilibrado frente a los desafíos.',
        },
        {
          text: 'Intenta usar los sentidos para desviar la atención de cualquier sentimiento negativo de estrés y vuelve a enfocar la atención para mantener el equilibrio.',
        },
      ],
    },
  ],
};
