import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledContainer,
  StyledDescriptionContainer,
  StyledHeader,
  StyledHeaderText,
  StyledParagraph,
  StyledMenuOptionsContainer,
  MenuNoHeaderSection,
  ColoredText,
} from './styles';
import MenuSection from './MenuSection';
import MenuSectionItem from './MenuSectionItem';

function Menu({
  title,
  updateScrollHeight,
  backlink,
  src,
  description,
  minActorHeaderHeight,
  hasWarning,
  children,
  style,
  ...props
}) {
  return (
    <StyledContainer {...props} style={{ ...style }} onScroll={(e) => updateScrollHeight(e.target.scrollTop)}>
      <StyledHeader src={src} minActorHeight={minActorHeaderHeight}>
        <StyledHeaderText>{title}</StyledHeaderText>
        {description ? (
          <StyledDescriptionContainer>
            {hasWarning ? <ColoredText>⚠</ColoredText> : ''}
            <StyledParagraph>{description}</StyledParagraph>
          </StyledDescriptionContainer>
        ) : null}
      </StyledHeader>
      <StyledMenuOptionsContainer minActorHeight={minActorHeaderHeight}>
        {backlink ? (
          <MenuNoHeaderSection>
            <MenuSectionItem
              key={`backlink_menuSectionItem`}
              disabled={!backlink?.LINK}
              icon={backlink?.SRC}
              style={backlink?.SRCSTYLE}
              onClick={backlink?.ONCLICK}
            >
              {backlink?.TEXT}
            </MenuSectionItem>
          </MenuNoHeaderSection>
        ) : null}
        {children}
      </StyledMenuOptionsContainer>
    </StyledContainer>
  );
}
Menu.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  hasWarning: PropTypes.bool,
  backlink: PropTypes.object,
  updateScrollHeight: PropTypes.func,
  minActorHeaderHeight: PropTypes.number,
  description: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.arrayOf(MenuSection)]).isRequired,
  style: PropTypes.object,
};

export default Menu;
