import * as paths from 'Core_Pages/Routes/RoutesConfig';
import urlcat from 'urlcat';
import { InteractionType } from '../constants/interactionType';

export const onClickActivityMenu = (history) => {
  onClickGoToGameRoute(history, InteractionType.ACTIVITYMENU.NAME);
};

export const onClickGoToGameRoute = (history, sceneType) => {
  const url = urlcat(paths.PETSGAME, { petSceneType: sceneType });
  history.push(url);
  history.go();
};

export const onClickGoToRoute = (history, route, params = undefined) => {
  let url = route;
  if (params) {
    url = urlcat(route, params);
  }
  history.push({ pathname: url, state: { from: 'PyxPets' } });
  history.go();
};
