import styled from 'styled-components';
import { black } from 'Styles/colors';
import { IconButton } from '@mui/material';

export const DisclaimerContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  margin-bottom: 12px;
`;

export const Description = styled.div`
  color: ${black._100};
  font-size: 16px;
  text-align: left;
  letter-spacing: 2px;
  font-weight: 200;
  font-family: 'Karla';
  margin: 0 auto;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 18px;
  margin-top: 12px;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: start;
`;

export const StyledTitle = styled.h2`
  font-size: 24px;
  font-family: 'Karla';
  color: ${black._87};
  font-weight: 700;
  margin: 12px 0;
  width: 100%;
`;

export const StyledCloseIconContainer = styled(IconButton)`
  text-align: right;
  padding: 0;
  margin-left: auto;
  color: ${black._100};
  font-size: 24px;
`;

export const PageContainer = styled.div`
  position: relative;
  overflow-y: auto;
  padding: 24px;
  height: 100%;
  scroll: auto;
`;
