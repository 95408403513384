import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { OnlineIcon, OfflineIcon } from './styles';

const TalkToSomeoneCardStatus = ({ isOnline }) => {
  const intl = useIntl();

  return isOnline ? (
    <Typography align="right" fontSize={16} fontWeight={400} marginRight={4}>
      {intl.formatMessage({ id: 'talk-to-someone.online' })}
      <span>
        <OnlineIcon />
      </span>
    </Typography>
  ) : (
    <Typography align="right" fontSize={16} fontWeight={400} marginRight={4}>
      {intl.formatMessage({ id: 'talk-to-someone.offline' })}
      <span>
        <OfflineIcon />
      </span>
    </Typography>
  );
};

TalkToSomeoneCardStatus.propTypes = {
  isOnline: PropTypes.bool,
};

export default TalkToSomeoneCardStatus;
