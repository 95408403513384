import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { SkipButtonContainer } from '../../styles';
import Button from 'Stories/components/Button';

function SkipButton({ onChange, skipOption }) {
  const intl = useIntl();

  return (
    <SkipButtonContainer>
      <Button
        id={`${skipOption.name}-skip-button`}
        style={{ color: 'white' }}
        variant="text"
        onClick={() => onChange({ answerOptionGuid: skipOption.name })}
      >
        {intl.formatMessage({ id: `${skipOption.textId}` })}
      </Button>
    </SkipButtonContainer>
  );
}

SkipButton.propTypes = {
  onChange: PropTypes.func,
  skipOption: PropTypes.object,
};

export default SkipButton;
