import React, { Component } from 'react';
import manager, { ComponentManager } from './manager';
import { logDiagnosticTrace } from '../../helpers/logDiagnosticTrace';

type Props = {
  extraProps: object;
  manager: ComponentManager;
  mainManager: typeof manager;
  Comp: any;
};

class Wrapper extends Component<Props> {
  stateManager: ComponentManager;

  constructor(props: Props) {
    super(props);

    this.state = { ...props.extraProps };
    this.stateManager = props.manager;
  }

  componentDidMount() {
    this.stateManager.events.on('state-change', this.setState, this);
    this.stateManager.events.emit('state-change', this.stateManager.state);
    logDiagnosticTrace('Phaser-react:Wrapper Mounted');
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    logDiagnosticTrace(`Phaser-react:Wrapper Error ${errorInfo} ${error}`);
  }

  componentWillUnmount(): void {
    logDiagnosticTrace('Phaser-react:Wrapper Unmounted');
  }

  render() {
    const { Comp, manager, mainManager } = this.props;

    return (
      <div style={{ pointerEvents: 'auto' }}>
        <Comp manager={manager} mainManager={mainManager} {...this.state} />
      </div>
    );
  }
}

export default Wrapper;
