import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { StyledSwitchLabel, StyledSwitch } from './styles.js';

function ActiveToggle({ id, isActive, handleReminderSwitch }) {
  const intl = useIntl();

  return (
    <StyledSwitchLabel htmlFor={id}>
      <StyledSwitch id={id} checked={isActive} onClick={handleReminderSwitch} inputProps={{ 'aria-labelledby': id }} />
      {isActive
        ? intl.formatMessage({ id: 'reminders.switch-on' })
        : intl.formatMessage({ id: 'reminders.switch-off' })}
    </StyledSwitchLabel>
  );
}

ActiveToggle.propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool,
  handleReminderSwitch: PropTypes.func,
};

export default ActiveToggle;
