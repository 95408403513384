import React, { useEffect, useState } from 'react';
import {
  ButtonContainer,
  ColorImage,
  ColorPageContainer,
  ColorSelection,
  ColorSelectionContainer,
  Container,
  Content,
  GridContainer,
  Header,
  HeaderText,
  ImageButton,
  ImageGrid,
  ImageRow,
  NavButton,
  NavIcon,
  ScrollableContent,
  StyledLeftButton,
  StyledRightButton,
} from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { appInsights } from 'Core_Helpers/AppInsights';
import hasPermissions from 'Core_Helpers/authentication/hasPermissions';
import getSvgImageConfig from 'Core_Helpers/getSvgImageConfig';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import * as permissions from 'Core_Helpers/authentication/PermissionsConfig';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectUserInfo } from 'Core_Redux/user';
import Flower from './svgs/Flower';
import Landscape from './svgs/Landscape';
import Puppy from './svgs/Puppy';
import Pyxir from './svgs/Pyxir';
import Owl from './svgs/Owl';
import Deer from './svgs/Deer';
import Wolf from './svgs/Wolf';
import Motorcycle from './svgs/Motorcycle';
import Mandala from './svgs/Mandala';

const colorsOptions = [
  '#901BE2',
  '#D93D86',
  '#FF9300',
  '#F9C82B',
  '#72A351',
  '#C2CF51',
  '#FFFFFF',
  '#47B5B7',
  '#3F457A',
  '#0C94E8',
  '#B2A676',
  '#AA7942',
  '#E2E3DF',
  '#000000',
];

const defaultColors = Array(100).fill('white');

const ColoringBook = () => {
  const intl = useIntl();
  const [colors, setColors] = useState(defaultColors);
  const [currentColor, setCurrentColor] = useState('#E2E3DF');
  const [currentImage, setCurrentImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const location = useLocation();
  const profile = useCoreSelector(selectUserInfo);
  let exitMethod = 'Left_Coloring_Book';

  useEffect(() => {
    initializeImageFromCard();

    return () => {
      appInsights.trackEvent({ name: exitMethod });
    };
  }, []);

  const initializeImageFromCard = () => {
    if (location?.state?.card) {
      const { coloringBookImageColors, coloringBookImageKey } = location.state.card;

      setCurrentImage(coloringBookImageKey);
      setColors(coloringBookImageColors);
      history.replace();
    }
  };

  const imagePicked = (data) => {
    setCurrentImage(data);
  };

  const colorPicked = (data) => {
    setCurrentColor(data);
  };

  const onFillColor = (i) => {
    let newFillColors = colors.slice(0);
    newFillColors[i] = currentColor;
    setColors(newFillColors);
  };

  const displayColorOptions = () => {
    return colorsOptions.map((data, index) => {
      return (
        <ColorSelection
          data-testid={`ColorSelection${index}`}
          onClick={() => colorPicked(data)}
          key={index}
          picked={data === currentColor}
          color={data}
        />
      );
    });
  };

  const displayImage = () => {
    appInsights.trackEvent({ name: `Coloring_Book_Image_Shown_${currentImage}` });
    return getSvgImageConfig(currentImage, colors, onFillColor) || <Flower colors={colors} onFill={onFillColor} />;
  };

  const startAgain = () => {
    setCurrentImage(null);
    setColors(defaultColors);
  };

  const imDone = () => {
    exitMethod = 'Coloring_Book_Finished';
    history.push(paths.HOME);
  };

  const pagePrev = () => {
    if (currentPage <= 1) return;
    setCurrentPage(currentPage - 1);
  };

  const pageNext = () => {
    if (currentPage >= 2) return;
    setCurrentPage(currentPage + 1);
  };

  return (
    <ColorPageContainer>
      <Header data-testid="Header">
        <i aria-hidden="true" data-testid="HeaderIcon" className={'fas fa-spa lg'} />
        <HeaderText data-testid="HeaderText">
          <FormattedMessage id="common.coloring-book" />
        </HeaderText>
      </Header>
      <ScrollableContent>
        {currentImage !== null ? (
          <>
            <ColorImage data-testid="ImageDisplay">{displayImage()}</ColorImage>
            <Container>
              <ColorSelectionContainer data-testid="ColorSelectionContainer">
                {displayColorOptions()}
              </ColorSelectionContainer>
              <ButtonContainer>
                <StyledLeftButton
                  data-testid="LeftButton"
                  onClick={() => {
                    hasPermissions(permissions.CARDS, undefined, profile) ? startAgain() : imDone();
                  }}
                >
                  <FormattedMessage
                    id={
                      hasPermissions(permissions.CARDS, [], profile) ? 'common.back-with-arrow' : 'coloring-book.done'
                    }
                  />
                </StyledLeftButton>
                <StyledRightButton
                  data-testid="RightButton"
                  onClick={() => {
                    hasPermissions(permissions.CARDS, [], profile)
                      ? history.push({
                          pathname: paths.COLORINGBOOKIMAGEPREVIEW,
                          state: { colors: colors, imageKey: currentImage },
                        })
                      : startAgain();
                  }}
                >
                  <FormattedMessage
                    id={
                      hasPermissions(permissions.CARDS, [], profile) ? 'common.next-with-arrow' : 'coloring-book.again'
                    }
                  />
                </StyledRightButton>
              </ButtonContainer>
            </Container>
          </>
        ) : (
          <>
            <Content data-testid="SelectContent">
              <FormattedMessage id="coloring-book.select-image" />
            </Content>
            <GridContainer data-testid="SelectGrid">
              <NavButton
                data-testid="PreviousButton"
                disabled={currentPage <= 1}
                onClick={() => pagePrev()}
                style={{ marginLeft: '12px' }}
                aria-label={intl.formatMessage({ id: 'common.previous-page' })}
              >
                <NavIcon aria-hidden="true" className="fas fa-chevron-left" />
              </NavButton>
              {currentPage === 1 ? (
                <ImageGrid>
                  <ImageRow>
                    <ImageButton
                      data-testid="FlowerButton"
                      onClick={() => imagePicked('Flower')}
                      aria-labelledby="svgFlowerDescription"
                    >
                      <Flower colors={defaultColors} onFill={Function.prototype} />
                    </ImageButton>
                    <ImageButton onClick={() => imagePicked('Owl')} aria-labelledby="svgOwlDescription">
                      <Owl colors={defaultColors} onFill={Function.prototype} />
                    </ImageButton>
                  </ImageRow>
                  <ImageRow>
                    <ImageButton onClick={() => imagePicked('Pyxir')} aria-labelledby="svgPyxirDescription">
                      <Pyxir colors={defaultColors} onFill={Function.prototype} />
                    </ImageButton>
                    <ImageButton onClick={() => imagePicked('Landscape')} aria-labelledby="svgLandscapeDescription">
                      <Landscape colors={defaultColors} onFill={Function.prototype} />
                    </ImageButton>
                  </ImageRow>
                  <ImageRow>
                    <ImageButton onClick={() => imagePicked('Puppy')} aria-labelledby="svgPuppyDescription">
                      <Puppy colors={defaultColors} onFill={Function.prototype} />
                    </ImageButton>
                    <ImageButton onClick={() => imagePicked('Deer')} aria-labelledby="svgDeerDescription">
                      <Deer colors={defaultColors} onFill={Function.prototype} />
                    </ImageButton>
                  </ImageRow>
                </ImageGrid>
              ) : (
                <ImageGrid>
                  <ImageRow>
                    <ImageButton
                      data-testid="WolfButton"
                      onClick={() => imagePicked('Wolf')}
                      aria-labelledby="svgWolfDescription"
                    >
                      <Wolf colors={defaultColors} onFill={Function.prototype} />
                    </ImageButton>
                  </ImageRow>
                  <ImageRow>
                    <ImageButton onClick={() => imagePicked('Motorcycle')} aria-labelledby="svgMotorcycleDescription">
                      <Motorcycle colors={defaultColors} onFill={Function.prototype} />
                    </ImageButton>
                  </ImageRow>
                  <ImageRow>
                    <ImageButton onClick={() => imagePicked('Mandala')} aria-labelledby="svgMandalaDescription">
                      <Mandala colors={defaultColors} onFill={Function.prototype} />
                    </ImageButton>
                  </ImageRow>
                </ImageGrid>
              )}
              <NavButton
                data-testid="NextButton"
                disabled={currentPage >= 2}
                onClick={() => pageNext()}
                style={{ marginRight: '12px' }}
                aria-label={intl.formatMessage({ id: 'common.next-page' })}
              >
                <NavIcon aria-hidden="true" className="fas fa-chevron-right" />
              </NavButton>
            </GridContainer>
          </>
        )}
      </ScrollableContent>
    </ColorPageContainer>
  );
};

export default ColoringBook;
