import { useEffect } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { appInsights } from 'Core_Helpers/AppInsights';

const useAppPaused = () => {
  useEffect(() => {
    CapacitorApp.addListener('appStateChange', async ({ isActive }) => {
      if (isActive) {
        appInsights.trackEvent({ name: 'App_Active' });
      } else {
        appInsights.trackEvent({ name: 'App_Inactive' });
      }
    });
  }, []);
};

export default useAppPaused;
