import getAuthClient from 'Core_Helpers/getAuthClient';
import urlcat from 'urlcat';
import { PushNotifications } from '@capacitor/push-notifications';
import { PushEvents } from './PushEvents';
import { appInsights } from 'Core_Helpers/AppInsights';

const registerPushNotifications = async (userId) => {
  if (navigator.serviceWorker) {
    const appPubkey = process.env.PUBLIC_KEY;
    const registration = await navigator.serviceWorker.ready;

    const client = await getAuthClient();

    if (client) {
      const url = urlcat(process.env.API_BASE_URL, '/v1/pushsubscriptions');

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(appPubkey),
      });

      const sub = JSON.stringify(subscription);
      const { endpoint, keys } = JSON.parse(sub);
      return await client.post(url, {
        userId: userId,
        endpoint,
        encryptionKey: keys.p256dh,
        authKey: keys.auth,
      });
    }
  }
};

const requestNotificationPermissions = async () => {
  await PushEvents.Instance;

  PushNotifications.requestPermissions()
    .then(() => {
      PushNotifications.register();
    })
    .catch((e) => {
      appInsights.trackException({
        customProperties: `Exception thrown while trying to enable notifications ${e?.message}`,
        exception: e,
      });
    });
};

const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

const isSubscribed = async (userId) => {
  const registration = await navigator.serviceWorker?.ready;
  if (registration) {
    const url = urlcat(process.env.API_BASE_URL, '/v1/pushsubscriptions');
    const client = await getAuthClient();

    if (client) {
      const currentSubscriptions = await client.get(urlcat(url, { userId }));
      const subscription = await registration.pushManager.getSubscription();
      const hasEndpoint = currentSubscriptions.data.find(
        (currentSubscription) => currentSubscription.endpoint === subscription?.endpoint,
      );

      return !(!hasEndpoint || !subscription);
    }
  }
};

const permissionDenied = () => {
  return Notification.permission === 'denied';
};

const askPermission = async () => {
  const permissionResult = await Notification.requestPermission();

  if (permissionResult !== 'granted') {
    throw new Error('Not granted permission');
  }
};

export { registerPushNotifications, requestNotificationPermissions, permissionDenied, isSubscribed, askPermission };
