import { mindfulnessBalance } from 'Assets/img/my-tools-icons';

export const mindfulnessBalanceEs = {
  key: 'mindfulness-balance-es',
  title: 'Prueba la conciencia plena para el equilibrio emocional',
  titleImage: mindfulnessBalance,
  titleImageAlt: '',
  contentId: 23,
  sections: [
    {
      key: 'mindfulness-balance-intro-es',
      hasNoSectionMargin: true,
      description:
        'Por ejemplo, sin juzgar, la “carga” emocional de la ira se vuelve neutral. Su impacto negativo puede pasar más fácilmente porque no hay necesidad de reaccionar ante ello. Es solo un estado de energía. Y la energía fluye a nuestro alrededor.\n\nSe sabe que la práctica de la conciencia plena tiene un impacto positivo en la autoestima. Nos permite dar un paso atrás, calmar los nervios, desprendernos del estrés y pasar a un estado mental más útil. Puede hacerlo cualquier persona en cualquier momento: mientras conduce, camina, nada o incluso se cepilla los dientes.\n\nAunque hay muchas formas de utilizarla, la conciencia de la respiración es un elemento bien conocido de esta práctica y una forma fácil de empezar. Este es un ejercicio sencillo para conocer cómo puede ser y sentirse la conciencia plena.',
    },
    {
      key: 'mindfulness-balance-moments-es',
      header: 'Prueba un momento de conciencia',
      bulletedItems: [
        {
          text: 'Deja lo que estés haciendo y tómate un momento para comprobar tu atención. ¿Dónde está? ¿En qué piensas?',
        },
        {
          text: 'Respira profundamente, libera tus pensamientos y trae tu atención al aquí y al ahora.',
        },
        {
          text: 'Pon tu atención en tu respiración.',
        },
        {
          text: 'Tómate un momento para concentrarte en ella. Siente cómo entra y sale. Observa cómo tu estómago sube y baja con cada respiración. Concéntrate solo en respirar durante un rato más.',
        },
        {
          text: 'Luego, toma conciencia de tus sentimientos y pensamientos. Simplemente observa y escucha sin juzgar.',
        },
        {
          text: '¿Qué emociones sientes en este momento? Simplemente acéptelas como son. Deja de lado cualquier juicio o “carga” que puedas sentir sobre ellas. Basta con reconocer que están ahí, y dejar que se aparten.',
        },
        {
          text: 'Solo debes estar presente en este momento. Estás a salvo. Estás asentando tu sistema nervioso.',
        },
        {
          text: 'Si ves que tu mente divaga, no pasa nada. Tan solo vuelve a centrarte en la respiración que entra y sale de tu cuerpo.',
        },
        {
          text: 'Céntrate en ti en este momento.',
        },
        {
          text: 'Te estás centrando en ti mismo.',
        },
        {
          text: 'Tómate unos minutos de tranquilidad para asentarte y equilibrarte.',
        },
        {
          text: 'Mantén tu atención en el momento presente.',
        },
        {
          text: 'Tu mente y tu cuerpo te ayudan a recuperar el equilibrio.',
        },
        {
          text: 'Deja que cualquier factor estresante desaparezca sin reaccionar.',
        },
        {
          text: 'Siente cómo te vuelves más equilibrado y centrado en tu ser.',
        },
        {
          text: 'Sigue respirando y céntrate en ti mismo en este momento.',
        },
        {
          text: 'Agradécete por esta autoconciencia de bienestar.',
        },
        {
          text: 'Agradece a tus factores estresantes por recordarte a ti mismo.',
        },
        {
          text: 'Recuerda que puedes tomar un momento de conciencia plena y entrar en tu “cuerpo consciente” en cualquier momento y en cualquier lugar.',
        },
      ],
    },
    {
      key: 'mindfulness-balance-grand-finale-es',
      description:
        'La práctica de la conciencia plena nos ayuda a mostrar lo que ocurre en nuestro cuerpo, nuestras emociones y nuestra mente. Es una herramienta útil para afrontar el estrés y responder a él de forma más positiva en lugar de reaccionar emocionalmente',
    },
  ],
};
