import { atom, getDefaultStore, useAtom } from 'jotai';
import { ScreeningScoresApiDataService } from 'Core_Pages/Screening/services/ScreeningScoresApiDataService';
import { Singleton } from 'Core_Helpers/Singleton';
import { UserAccessor } from 'Core_Helpers/UserAccessor';
import { ScreeningScheduleApiDataService } from 'Core_Pages/Screening/services/ScreeningScheduleApiDataService';
import { browserHistory } from 'Core_Helpers/AppInsights';
import { ASSESSMENT } from 'Core_Pages/Routes/RoutesConfig';
import { IStateStore } from 'Core_Helpers/IStateStore';
import { Screenings } from 'Core_Pages/Screening/enumerations/enums';

export class CheckinModuleStore extends Singleton implements IStateStore {
  private screeningScheduleApiDataService: ScreeningScheduleApiDataService;
  private screeningScoresApiDataService: ScreeningScoresApiDataService;
  private userAccessor: UserAccessor;
  private atomStore = getDefaultStore();
  private dueScreeningsAtom;
  private dueScreeningsCountAtom;
  private screeningScoresAtom;
  private availableScreeningsAtom;
  private nextAvailableScreeningAtom;

  constructor() {
    super();

    this.screeningScheduleApiDataService = new ScreeningScheduleApiDataService();
    this.screeningScoresApiDataService = new ScreeningScoresApiDataService();
    this.userAccessor = new UserAccessor();
    this.dueScreeningsAtom = atom([]);
    this.dueScreeningsCountAtom = atom(null);
    this.nextAvailableScreeningAtom = atom(null);
    this.screeningScoresAtom = atom([]);
    this.availableScreeningsAtom = atom([]);
  }

  private hydrateAvailableScreenings = () => {
    const availableScreenings = [].concat(this.RecentScreeningScores).concat(this.DueScreenings);
    const nextAvailableScreening = this.DueScreeningsCount > 0 ? this.DueScreenings[0]?.screeningGuid : null;

    this.atomStore.set(this.availableScreeningsAtom, availableScreenings);
    this.atomStore.set(this.nextAvailableScreeningAtom, nextAvailableScreening);
  };

  private filterInvalidScreenigs = (screenings) => {
    const validScreeningGuids = Object.values(Screenings);
    const validDueScreenings = screenings?.filter((x) => validScreeningGuids.includes(x.screeningGuid));

    return !validDueScreenings ? [] : validDueScreenings;
  };

  public Use = () => {
    useAtom(this.dueScreeningsCountAtom);
    useAtom(this.dueScreeningsAtom);
    useAtom(this.screeningScoresAtom);
    useAtom(this.availableScreeningsAtom);
    useAtom(this.nextAvailableScreeningAtom);

    return this;
  };

  public get DueScreeningsCount(): number {
    return this.atomStore.get(this.dueScreeningsCountAtom);
  }

  public get DueScreenings(): Array<any> {
    return this.atomStore.get(this.dueScreeningsAtom);
  }

  public get RecentScreeningScores(): Array<any> {
    return this.atomStore.get(this.screeningScoresAtom);
  }

  public get AvailableScreenings(): Array<any> {
    return this.atomStore.get(this.availableScreeningsAtom);
  }

  public get NextAvailableScreening(): Array<any> {
    return this.atomStore.get(this.nextAvailableScreeningAtom);
  }

  public hydrateDueScreenings = async () => {
    if (!this.userAccessor.User.UserId) return;

    let dueScreenings = await this.screeningScheduleApiDataService.getScreeningSchedule({
      userId: this.userAccessor.User.UserId,
    });

    dueScreenings = this.filterInvalidScreenigs(dueScreenings?.screenings);

    this.atomStore.set(this.dueScreeningsAtom, dueScreenings);
    this.atomStore.set(this.dueScreeningsCountAtom, dueScreenings?.length);
    this.hydrateAvailableScreenings();
  };

  /**
   * Retrieves screening scores within the last 24 hours and updates the atom with its response
   * @param userId
   * @returns
   */
  public hydrateScreeningScores = async () => {
    if (!this.userAccessor.User.UserId) return;

    const today = new Date();
    const yesterday = new Date(new Date().setDate(today.getDate() - 1));
    const { response } = await this.screeningScoresApiDataService.getScreeningScores({
      scoreDateStartUtc: yesterday.toISOString(),
      scoreDateEndUtc: today.toISOString(),
      userId: this.userAccessor.User.UserId,
    });

    const validScreeningScores = response ? this.filterInvalidScreenigs(response) : [];
    this.atomStore.set(this.screeningScoresAtom, validScreeningScores);
    this.hydrateAvailableScreenings();
  };

  public startCheckin = () => {
    browserHistory.push(ASSESSMENT);
  };
}
