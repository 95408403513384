import styled from '@emotion/styled';
import { Link as MuiLink } from '@mui/material';
import { black } from 'Styles/colors';

export const ForgotCredentialLink = styled(MuiLink)`
  color: ${black._87};
  font-size: 16px;
  margin-bottom: 16px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
`;

export const CredentialError = styled.p`
  color: ${({ theme }) => theme.palette.error.main};
  text-align: center;
`;
