import styled from 'styled-components';

export const GameBoardContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  color: black;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const GameBoard = styled.div`
  position: relative;
  margin: auto;
  margin-bottom: 10%;
  border-style: solid;
  border-color: black;
  background-color: black;
`;

export const SnakeBlock = styled.div`
  position: absolute;
`;

export const AppleBlock = styled.div`
  position: absolute;
`;

export const Score = styled.div`
  position: relative;
  top: 105%;
  text-align: center;
  font-weight: bold;
  color: black;
  background-color: white;
  line-height: 22.5px;
`;

export const FlowButtonContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
`;

export const ResetButton = styled.button`
  color: white;
  background-color: #277f3b;
  border-radius: 15px;
  font-family: lato;
  font-weight: bold;
  font-size: 18px;
  border: none;
  padding: 10px 19px;
  margin: 10px;
  cursor: fixed;
  display: flex;
  justify-content: center;
  width: 30%;
`;

export const ExitButton = styled.button`
  color: #277f3b;
  background-color: white;
  border-radius: 15px;
  font-family: lato;
  font-weight: bold;
  font-size: 18px;
  border: solid;
  border-color: #277f3b;
  padding: 10px 19px;
  margin: 10px;
  cursor: fixed;
  display: flex;
  justify-content: center;
  width: 30%;
`;

export const ControlContainer = styled.div`
  width: 100%;
  padding: 0px 15px 15px 15px;
  display: grid;
  justify-content: center;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: 1fr 1fr 1fr;
  margin: auto;
`;

export const ControlButton = styled.button`
  background-color: #4a4f7f;
  color: white;
  border-radius: 15px;
  font-family: karla;
  border: solid;
  border-width: 1px;
  font-weight: bold;
  padding: 10px 17px 10px 13px;
  margin: 10% auto;
  cursor: fixed;
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100px;
`;

export const DirectionalIcon = styled.i`
  color: white;
  cursor: pointer;
  font-size: 22px;
  margin: auto;
`;
