import React, { useState, useEffect, useRef } from 'react';
import { detect } from 'detect-browser';
import {
  PushNotificationPromptContainer,
  StyledNoButton,
  StyledYesButton,
  PushNotificationPromptContent,
  Title,
  Overlay,
} from './styles';
import {
  askPermission,
  isSubscribed,
  permissionDenied,
  registerPushNotifications,
} from 'Core_Helpers/pushNotifications/pushNotifications';
import { FormattedMessage } from 'react-intl';
import { isNative } from 'Core_Helpers/isNative';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectUserInfo } from 'Core_Redux/user';
import StorageService, { PUSH_NOTIFICATION_KEY } from 'Core_Helpers/authentication/StorageService';

const PushNotificationPrompt = () => {
  const [show, setShow] = useState(false);
  const browser = detect();
  const yesButton = useRef(null);
  const user = useCoreSelector(selectUserInfo);

  const softDecline = async () => {
    const storage = await StorageService.getInstance();
    await storage.setItem(PUSH_NOTIFICATION_KEY, false);
    setShow(false);
  };

  const subscribe = async () => {
    await askPermission();
    await registerPushNotifications(user.sub);
  };

  const handleYesClick = async () => {
    await subscribe();
    setShow(false);
  };

  const setupSubscription = async () => {
    if (!isNative()) {
      const subbed = await isSubscribed(user.sub);
      if (!subbed && !permissionDenied()) {
        if (browser.name.toLowerCase() === 'firefox') {
          const storage = await StorageService.getInstance();
          const value = storage.getItem(PUSH_NOTIFICATION_KEY);
          value ? setShow(JSON.parse(value)) : setShow(true);
        } else if (browser.name.toLocaleLowerCase() !== 'safari') {
          await registerPushNotifications(user.sub);
        }
      }
    }
  };

  useEffect(() => {
    setupSubscription();
  }, []);

  useEffect(() => {
    if (show) {
      yesButton.current.focus();
    }
  }, [show]);

  return (
    <>
      {show && (
        <>
          <PushNotificationPromptContainer
            role="alertdialog"
            aria-live="assertive"
            data-testid="PushNotificationPromptContainer"
          >
            <div role="document">
              <Title data-testid="NotificationTitle">
                <FormattedMessage id="notifications.allow" />
              </Title>
              <PushNotificationPromptContent>
                <StyledNoButton data-testid="NoButton" onClick={softDecline}>
                  <FormattedMessage id="common.no" />
                </StyledNoButton>
                <StyledYesButton ref={yesButton} data-testid="YesButton" onClick={handleYesClick}>
                  <FormattedMessage id="common.yes" />
                </StyledYesButton>
              </PushNotificationPromptContent>
            </div>
          </PushNotificationPromptContainer>
          <Overlay />
        </>
      )}
    </>
  );
};

export default PushNotificationPrompt;
