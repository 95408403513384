import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosDelete from './api/useAxiosDelete/useAxiosDelete';

const useDeleteMember = () => {
  const [url, setUrl] = useState();
  const { isLoading, hasErrored, isComplete } = useAxiosDelete(url);

  const deleteMember = async (userId) => {
    setUrl(`${process.env.API_BASE_URL}/v5/members/${userId}`);
  };

  return { error: hasErrored, isPending: isLoading, isComplete, deleteMember };
};

export default useDeleteMember;
