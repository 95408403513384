import styled from '@emotion/styled';

export const StyledMultiSelectQuestion = styled.div`
  .FormQuestion-root {
    height: 425px;
    width: 285px;
    background-color: #565c89;
    justify-content: center;
    display: grid;
    grid-template-rows: min-content min-content auto min-content;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 12px 0 -10px grey, 0 16px 0 -12px lightgrey, 0 18px 0 -13px grey, 0 22px 0 -15px lightgrey,
      0 24px 0 -16px grey;
    margin-bottom: 24px;
    padding: 12px 24px 0;
  }

  [id='f466317a-bb05-47eb-92cf-5cc6dec355fd-form-question'] {
    height: 600px;
    padding: 12px 9px 0;
  }

  [id='3e7a167b-51fb-438f-a5f7-aa310b7bbc09-skip-button'] {
    padding: 0px 8px 14px;
  }

  .FormQuestion-question {
    font-weight: 700;
    margin-bottom: 6px;
    font-size: 24px;
  }

  .FormQuestion-description {
    margin-bottom: 24px;
  }

  [id='f466317a-bb05-47eb-92cf-5cc6dec355fd-question'],
  [id='be51576b-1524-4cba-bc03-73a1a1453fa8-question'] {
    font-size: 16px;
  }

  [id='f466317a-bb05-47eb-92cf-5cc6dec355fd-question-description'] {
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 12px;
  }

  [id='ec55df0d-0769-43a8-9699-4331a23d1aef-question-description'] {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .MuiFormControlLabel-root {
    margin: 0;
    padding: 0;
    width: 100%;
    justify-content: flex-start;
  }

  .MuiFormControlLabel-label {
    font-size: 18px;
    font-weight: 700;
  }

  .MuiCheckbox-root {
    color: white;
    padding: 6px;
    margin: 0;
  }

  .MuiCheckbox-root.Mui-checked {
    color: #29265e;
  }

  .MuiSvgIcon-root {
    background-color: white;
    path: {
      transform: translate(-2px, -2px) scale(1.2);
    }
  }
`;
