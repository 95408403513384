import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Container, TextField, CircularProgress } from '@mui/material';
import { black } from 'Styles/colors';
import { breakpoints } from 'Styles/theme';

export const DirectMessageContainer = styled.div(
  ({ theme }) => `
    position: fixed;
    bottom: 0;
    right: 0;
    height: 550px;
    width: 365px;
    border: 1px solid #979797;
    box-shadow: 0px 2px 4px ${black._50};
    background-color: #ffffff;
    padding: 0;

    ${theme.breakpoints.down(breakpoints.DESKTOP)} {
      height: 100%;
      width: 100%;
      text-align: center;
    }
  `,
);

export const Header = styled.div`
  display: flex;
  flex: none;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3f447d;
  font-size: 28px;
  font-weight: 300;
  height: 80px;
  color: white;
`;

export const StyledPaper = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 60%;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 10px;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  position: relative;
  margin: auto;
`;

export const StyledTextInput = forwardRef((props, ref) => <TextField ref={ref} {...attrs} {...props} />);
StyledTextInput.displayName = 'TextInput';
