import { Actor } from 'Core_Games/Actor/Actor';
import { butterflyAssetConfig } from './ButterflyConfig';

export class Butterfly extends Actor {
  constructor(scene, x, y, color, petType, frame) {
    let currentButterflyConfig = butterflyAssetConfig;
    super(scene, x, y, currentButterflyConfig, currentButterflyConfig.NAME, frame);

    this.spriteName = this.ObjectData.NAME;
    this.Inventory = this.ObjectData.INVENTORY;
    this.Sounds = this.ObjectData.AUDIO;

    this.currentSpriteSheet = color;
    this.originalScale = this.ObjectData.SCALE;
  }
}
