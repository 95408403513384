import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  StyledFieldset,
  StyledForm,
  StyledLegend,
  StyledNextButton,
  StyledQuestionNumberText,
  StyledRadioButton,
} from './styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { NavigateNext } from '@mui/icons-material';
import PyxirHeaderFullScreenLayout from '../Layout/PyxirHeaderFullScreenLayout/PyxirHeaderFullScreenLayout';
import RadioOptions from './RadioOptions/RadioOptions';
import { Interweave } from 'interweave';

const Question = ({
  onSubmit,
  question,
  isLoading,
  header,
  children,
  disabledAdditional,
  onAnswerSelect,
  headerOverride,
}) => {
  const [answer, setAnswer] = useState();
  const intl = useIntl();
  const nextQuestionButtonText = intl.formatMessage({ id: 'next.question' });

  const submit = (e) => {
    e.preventDefault();

    if (isLoading || !answer) return;

    question.answers.forEach((potentialAnswer) => {
      if (potentialAnswer.text === answer) {
        onSubmit(potentialAnswer);
      }
    });
    setAnswer(null);
  };

  return (
    <PyxirHeaderFullScreenLayout>
      <ScreenReaderWrapper>
        <h1>
          <FormattedMessage id="title.screening" />
        </h1>
      </ScreenReaderWrapper>
      <StyledQuestionNumberText>
        {headerOverride?.length
          ? headerOverride
          : header?.questionCount && (
              <>
                {header.currentQuestion} <FormattedMessage id="common.of" /> {header.questionCount}
              </>
            )}
      </StyledQuestionNumberText>
      {question && !isLoading && (
        <StyledForm onSubmit={submit}>
          <StyledFieldset>
            <StyledLegend>
              <Interweave content={question.text} />
            </StyledLegend>
            <RadioOptions
              choices={question.answers.map((a) => a.text)}
              selectedChoice={answer}
              ChoiceComponent={StyledRadioButton}
              selectChoice={(choice) => {
                setAnswer(choice);
                onAnswerSelect ? onAnswerSelect(choice) : null;
              }}
            />
            {children}
          </StyledFieldset>
          <StyledNextButton
            type="submit"
            aria-label={nextQuestionButtonText}
            variant="contained"
            disabled={(!answer && !isLoading) || disabledAdditional}
            endIcon={<NavigateNext />}
            data-testid="Submit"
          >
            {!answer && !isLoading
              ? intl.formatMessage({ id: 'action.select-above' })
              : intl.formatMessage({ id: 'common.next' })}
          </StyledNextButton>
        </StyledForm>
      )}
    </PyxirHeaderFullScreenLayout>
  );
};

Question.propTypes = {
  onSubmit: PropTypes.func,
  question: PropTypes.object,
  isLoading: PropTypes.bool,
  severity: PropTypes.string,
  header: PropTypes.object,
  children: PropTypes.element,
  disabledAdditional: PropTypes.bool,
  onAnswerSelect: PropTypes.func,
  headerOverride: PropTypes.string,
};

export default Question;
