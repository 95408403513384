import { sceneType } from '../../constants/sceneType';
import { uiTypes } from '../../constants/uiTypes';
import CaptionManager from './components/Caption/CaptionManager';
import ChatBubbleManager from './components/ChatBubble/ChatBubbleManager';
import MenuSectionManager from './components/MenuSection/MenuSectionManager';
import SimpleButtonRowManager from './components/SimpleButtonRow/SimpleButtonRowManager';

export class UI extends Phaser.Scene {
  constructor() {
    super(sceneType.UI.NAME);
  }

  init(sceneData) {
    this.assetConfig = sceneData.assetConfig;
    this.gameData = sceneData.appData;

    // Custom UI Component Managers
    this.buttonManager = undefined;
    this.captionManager = undefined;
    this.menuSectionManager = undefined;
    this.chatBubbleManager = undefined;
  }

  preload() {}

  create() {
    for (var object in this.assetConfig?.UI) {
      var currentUI = this.assetConfig?.UI[object];

      if (currentUI.TYPE == uiTypes.BUTTONROW) {
        this.buttonManager = new SimpleButtonRowManager(this, this.gameData, currentUI);
      } else if (currentUI.TYPE == uiTypes.CAPTION) {
        this.captionManager = new CaptionManager(this, this.gameData, currentUI);
      } else if (currentUI.TYPE == uiTypes.MENUSECTION) {
        this.menuSectionManager = new MenuSectionManager(this, this.gameData, currentUI);
      } else if (currentUI.TYPE == uiTypes.CHATBUBBLE) {
        this.chatBubbleManager = new ChatBubbleManager(this, this.gameData, currentUI);
      }
    }
  }

  update() {}
}
