import React from 'react';
import PropTypes from 'prop-types';
import { StyledRow } from './styles';

function SimpleButtonRow({ children, style, ...props }) {
  return (
    <StyledRow {...props} style={{ ...style }}>
      {children}
    </StyledRow>
  );
}
SimpleButtonRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.arrayOf(PropTypes.element)]),
  style: PropTypes.object,
};

export default SimpleButtonRow;
