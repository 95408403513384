import { gameData } from '../../../../constants/gameData';
import { bodyPart } from '../../../../constants/bodyPart';
import { Config as movementConfig } from './objectData/movementConfig';

export const dogAssetConfig = {
  NAME: 'DOG',
  SPRITESHEET_FOLDER: 'dog',
  SPRITESHEET_NAME: 'Dog-Sprite-Map-',
  SCALE: 0.3,
  DATA: [movementConfig],
  INVENTORY: {
    [bodyPart.MOUTH]: [],
    [bodyPart.NECK]: [{ ...gameData.ACTOR.ITEM.WEARABLE.BANDANA, OBJECT: undefined }],
  },
  AUDIO: {},
  HEIGHT: 800,
  WIDTH: 800,
};
