import { increaseMovement } from 'Assets/img/my-tools-icons';

export const increaseMovementEs = {
  key: 'increase-movement',
  title: 'Aumentar el Movimiento',
  titleImage: increaseMovement,
  titleImageAlt: '',
  description: 'Añadir más movimiento físico a tu vida puede mejorar el bienestar mental, emocional y físico.',
  contentId: 11,
  sections: [
    {
      key: 'create-activity-plan',
      header: 'Crea un plan de actividades',
      description:
        'Piensa en formas en las que puedes incorporar a tu vida movimiento o actividades físicas agradables y crea un plan de acción.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Algunos consejos útiles que se deben tener en cuenta para ser más activo; anota:',
          subItems: [
            { text: 'Hasta 5 razones por las que te gustaría ser más activo ahora' },
            {
              text: 'Actividades que crees que son divertidas, que te gustaba practicar antes o que siempre quisiste probar',
            },
            { text: 'Actividades que puedes practicar con otros' },
            { text: 'Actividades que te gustaría practicar a corto o largo plazo' },
            { text: 'Las horas que más te convienen para ser más activo' },
            { text: 'Al menos 2 ocasiones en las que podrías hacer movimiento físico durante la próxima semana' },
          ],
        },
      ],
    },
    {
      key: 'schedule-activity-plan',
      header: 'Planifica tus actividades físicas y ¡a trabajar!',
      description: '¡Diviértete incluyendo más actividad física en tu semana!',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Ten en cuenta que debes comer bien y beber mucha agua para mantenerte sano.',
        },
        {
          text: 'Prepárate con antelación para las actividades al aire libre y averigua de antemano las condiciones meteorológicas para estar seguro.',
        },
        { text: 'No es un problema ajustar las actividades a tu nivel de energía.' },
      ],
    },
    {
      key: 'self-reflection',
      header: 'Autorreflexión',
      description: 'Tómate un tiempo para revisar la experiencia con esta actividad.',
      itemsHeader: 'Percepción y conciencia',
      items: [
        {
          text: '¿Qué has aprendido de este ejercicio?',
        },
        { text: '¿Cómo te sentías antes de completar este ejercicio?' },
        { text: '¿Cómo te sientes después de hacerlo?' },
        { text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?' },
        { text: '¿Qué disfrutaste más de este ejercicio?' },
        { text: '¿Qué ayudaría a facilitar el primer paso?' },
        { text: '¿Cuáles serían algunos de los beneficios de realizar esta actividad con más frecuencia en general?' },
      ],
    },
    {
      key: 'self-reflection-pointers',
      itemsHeader: 'Marcadores del camino',
      items: [
        {
          text: 'Añadir una actividad física agradable a tu vida puede ayudar a aumentar el bienestar general, desde un baile de movimiento libre que puedes hacer en casa hasta participar en una clase de fitness más estructurada y de alta intensidad.',
        },
        {
          text: '¡Sigue a tu propio ritmo! Incluso un movimiento corporal de baja o moderada intensidad pero realizado de forma regular puede ayudar a potenciar la salud mental, emocional y física.',
        },
        { text: '¡Disfruta mientras incluyes más actividad física en tu camino a la prosperidad!' },
      ],
    },
  ],
};
