import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

const Landscape = (props) => {
  const intl = useIntl();

  return (
    <svg
      role="img"
      focusable="false"
      aria-labelledby="svgLandscapeTitle"
      aria-describedby="svgLandscapeDescription"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 900 900"
    >
      <title id="svgLandscapeTitle">
        <FormattedMessage id="image.coloring-book.landscape" />
      </title>
      <desc id="svgLandscapeDescription">
        {intl.formatMessage({ id: 'image.coloring-book.landscape-description' })}
      </desc>
      <g id="LandscapeLayer_2" data-name="Layer 2">
        <path
          onClick={() => props.onFill(39)}
          fill={props.colors[39]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M809.79,39H90.21A51.21,51.21,0,0,0,39,90.21v14l8.59-18.6a387,387,0,0,1,58-6.44l-25.8,20.2,36-20.48c92.64-1.51,173.8,24.7,173.8,24.7l-.14.31c-1.56,3.4-18,35.89-93.19,75.46L145.7,135.37l41.57,48.55a695.85,695.85,0,0,1-64.63,28.17,265.92,265.92,0,0,1,57.27,8.16l-10.29,25.21L196.7,225a386.54,386.54,0,0,1,54.18,21.64l-32.27,5.67,41.41-1.1c82.43,42.3,141.67,103.66,141.67,103.66l-.27.21c-3,2.26-32.8,23.16-117.75,22.65l-23.89-62.57,13.79,62.41a720.71,720.71,0,0,1-83.38-7.36l-25.4-66.52,14.33,64.87c-7.09-1.09-14.36-2.29-21.87-3.61-7.8-1.38-15.45-2.57-23-3.63l-17.85-46.16-7.26,43.16c-3-.29-5.87-.56-8.78-.81l1.35-69.67L90.15,356.68q-11.75-.8-23-1.1l-12.5-32.34L50.2,349.83l-.93,5.52c-3.48,0-6.87.09-10.27.16v9.84a203.12,203.12,0,0,1,55.81.86c35.58,5.26,79.82,20.24,130.42,54.68,14.72,10,27.74,19.34,39.3,28.11,47.7,8.45,97.66-62.46,164.48-63.76,46.91-.91,75.78,61.14,171.67,88.15,34.83,9.81,47.27-33.08,116.66-67.77,54.89-27.44,92.46-4.84,121.58,4.45,7.68,2.45,15.08,7.06,22.08,13.47V90.21A51.21,51.21,0,0,0,809.79,39ZM630.64,348c-11.61,35.13-52.31,56.78-92.48,44-17.33-5.53-44-35.21-42-68.11,2.19-36,44-72.25,85.73-60.86C614,271.76,641.2,316.05,630.64,348Z"
        />
        <path
          onClick={() => props.onFill(0)}
          fill={props.colors[0]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M99.73,220.49l1.45-.71c-8.39,3-17,6-21.14,7.39Q90.21,223.84,99.73,220.49Z"
        />
        <path
          onClick={() => props.onFill(1)}
          fill={props.colors[1]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M117,214.24c1.91-.71,3.83-1.42,5.7-2.14-.45.18-2.81,1-6.23,2.25Z"
        />
        <path
          onClick={() => props.onFill(2)}
          fill={props.colors[2]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M818.45,860.23l-1.09.19Z"
        />
        <path
          onClick={() => props.onFill(3)}
          fill={props.colors[3]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M820.94,859.74c-.37.09-.75.18-1.13.25C820.19,859.92,820.57,859.83,820.94,859.74Z"
        />
        <path
          onClick={() => props.onFill(4)}
          fill={props.colors[4]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M86.85,860.86c-.67-.05-1.34-.09-2-.16C85.51,860.77,86.18,860.81,86.85,860.86Z"
        />
        <path
          onClick={() => props.onFill(5)}
          fill={props.colors[5]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M823.38,859.14l-1.16.31Z"
        />
        <path
          onClick={() => props.onFill(6)}
          fill={props.colors[6]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M90.23,861c-.91,0-1.81,0-2.71-.07C88.42,861,89.32,861,90.23,861Z"
        />
        <path
          onClick={() => props.onFill(7)}
          fill={props.colors[7]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M813.19,860.86c-.29,0-.57,0-.85.06C812.62,860.9,812.9,860.87,813.19,860.86Z"
        />
        <path
          onClick={() => props.onFill(8)}
          fill={props.colors[8]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M815.89,860.6l-1,.13Z"
        />
        <path
          onClick={() => props.onFill(9)}
          fill={props.colors[9]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M836.83,853.25l-1.38.82Z"
        />
        <path
          onClick={() => props.onFill(10)}
          fill={props.colors[10]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M838.85,851.92c-.47.33-1,.65-1.45,1C837.89,852.57,838.38,852.25,838.85,851.92Z"
        />
        <path
          onClick={() => props.onFill(11)}
          fill={props.colors[11]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M840.81,850.5c-.51.39-1,.77-1.57,1.14C839.77,851.27,840.3,850.89,840.81,850.5Z"
        />
        <path
          onClick={() => props.onFill(12)}
          fill={props.colors[12]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M825.76,858.42l-1.18.38Z"
        />
        <path
          onClick={() => props.onFill(13)}
          fill={props.colors[13]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M834.74,854.48c-.44.25-.88.48-1.33.71C833.86,855,834.3,854.73,834.74,854.48Z"
        />
        <path
          onClick={() => props.onFill(14)}
          fill={props.colors[14]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M832.58,855.62c-.42.21-.85.42-1.28.61C831.73,856,832.16,855.83,832.58,855.62Z"
        />
        <path
          onClick={() => props.onFill(15)}
          fill={props.colors[15]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M828.09,857.59c-.4.16-.8.31-1.21.45C827.29,857.9,827.69,857.75,828.09,857.59Z"
        />
        <path
          onClick={() => props.onFill(16)}
          fill={props.colors[16]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M830.37,856.66l-1.25.53Z"
        />
        <path
          onClick={() => props.onFill(17)}
          fill={props.colors[17]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M79.25,859.79c-.79-.17-1.57-.36-2.35-.57C77.68,859.43,78.46,859.62,79.25,859.79Z"
        />
        <path
          onClick={() => props.onFill(18)}
          fill={props.colors[18]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M39.17,813.54c0-.4-.07-.8-.09-1.2C39.1,812.74,39.14,813.14,39.17,813.54Z"
        />
        <path
          onClick={() => props.onFill(19)}
          fill={props.colors[19]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M84.2,860.62c-.68-.08-1.36-.17-2-.27C82.84,860.45,83.52,860.54,84.2,860.62Z"
        />
        <path
          onClick={() => props.onFill(20)}
          fill={props.colors[20]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M52.83,844.68A51.25,51.25,0,0,1,40.5,822a51.3,51.3,0,0,0,36.37,37.19A51.24,51.24,0,0,1,52.83,844.68Z"
        />
        <path
          onClick={() => props.onFill(21)}
          fill={props.colors[21]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M365.56,590c-.06.92-.11,1.79-.18,2.72,0,0-13.56-3-35.16-11.68H194.65c44.42,10,58.14,22.5,120.82,21.24,47-1,75.37-8.74,105.7,11,16.83,11,18.29,20,58.61,77.9,44.06,63.27,54.58,70.1,64.77,73.63,33.57,11.64,55-34.19,90.49-24,23.44,6.71,21.72,26.7,45.29,32.42,33.47,8.11,52.47-28.42,79.12-20.7,30.29,8.77,21.18,60.39,51.26,73.88,16,7.18,32.68-1.17,50.06-12.37A51.16,51.16,0,0,1,841,850a51.09,51.09,0,0,0,20-40.61V581H363.36C364.1,583.94,364.82,586.92,365.56,590Z"
        />
        <path
          onClick={() => props.onFill(22)}
          fill={props.colors[22]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M39.47,816.5c-.07-.55-.14-1.1-.2-1.65C39.33,815.4,39.4,816,39.47,816.5Z"
        />
        <path
          onClick={() => props.onFill(23)}
          fill={props.colors[23]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M81.69,860.26c-.73-.12-1.45-.25-2.17-.41C80.24,860,81,860.14,81.69,860.26Z"
        />
        <path
          onClick={() => props.onFill(24)}
          fill={props.colors[24]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M40.5,822q-.28-1.12-.51-2.28Q40.22,820.86,40.5,822Z"
        />
        <path
          onClick={() => props.onFill(25)}
          fill={props.colors[25]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M39.91,819.29c-.12-.66-.24-1.32-.34-2C39.67,818,39.79,818.63,39.91,819.29Z"
        />
        <path
          onClick={() => props.onFill(26)}
          fill={props.colors[26]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M319.44,539.8l-33,20.17a424.93,424.93,0,0,1-61.9-42.21l25.25-39.3-41.45,25.36c-7.18-6.48-14.37-13.36-21.52-20.77-7.53-7.78-14.51-14.89-21.1-21.45a137.83,137.83,0,0,0-28,9.6,125,125,0,0,0-20.83,12.14L92.52,463.82l8.59,33.34a127.62,127.62,0,0,0-21.25,31L48.92,503.4l27,33.32c-5,11.3-8.8,22.69-12.64,33.38a708.19,708.19,0,0,1,71.87,2.23c25.56,2.15,44.34,5.26,59.53,8.67H330.2c-8.48-3.42-18.2-7.73-28.82-13.09Z"
        />
        <path
          onClick={() => props.onFill(27)}
          fill={props.colors[27]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M717.34,405.21C648,439.89,635.52,482.79,600.7,473,504.8,446,475.93,383.92,429,384.83c-66.82,1.3-116.78,72.21-164.48,63.76C340.27,506,352.85,539.12,363.36,581H861V423.13c-7-6.41-14.4-11-22.08-13.47C809.8,400.36,772.23,377.77,717.34,405.21Z"
        />
        <path
          onClick={() => props.onFill(28)}
          fill={props.colors[28]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M581.92,263c-41.73-11.39-83.54,24.82-85.73,60.86-2,32.9,24.64,62.58,42,68.11,40.17,12.81,80.87-8.84,92.48-44C641.2,316.05,614,271.76,581.92,263Z"
        />
        <path
          onClick={() => props.onFill(29)}
          fill={props.colors[29]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M860.73,814.43c-17.37,11.21-34,19.56-50.05,12.37-30.08-13.48-21-65.11-51.26-73.87-26.66-7.72-45.66,28.81-79.13,20.7-23.57-5.72-21.85-25.71-45.29-32.42-35.5-10.16-56.92,35.67-90.49,24-10.19-3.53-20.71-10.36-64.77-73.63-40.32-57.9-41.78-66.93-58.61-77.9-30.33-19.77-58.65-12-105.69-11C252.76,604,239,591.41,194.61,581.45c-15.19-3.41-34-6.52-59.53-8.67a705.29,705.29,0,0,0-71.87-2.23c-6.35,17.69-12.71,33.5-24.21,44v2.75c37.77-4.11,81.47-7.42,130.32-5.51,88.65,3.46,199.4,7.78,275,81.41,58.8,57.24,46.71,108,99,126.64,76.93,27.4,158.72-62.72,204.89-26.5,17.94,14.07,8.31,29.78,23.62,55.06a65.51,65.51,0,0,0,10,12.63h27.84c.87,0,1.72,0,2.57-.06l.85-.07,1.68-.12,1-.13c.49-.06,1-.11,1.47-.18l1.1-.19,1.35-.24,1.14-.25,1.27-.29,1.16-.31,1.2-.34,1.18-.38,1.12-.38c.41-.14.81-.29,1.22-.45l1-.4,1.25-.53.93-.43,1.28-.61.84-.43c.44-.23.88-.46,1.32-.71l.71-.41c.46-.27.93-.54,1.38-.82l.57-.37c.49-.32,1-.63,1.46-1l.38-.27c.53-.37,1.06-.75,1.57-1.15l.14-.11A51.14,51.14,0,0,0,860.73,814.43Z"
        />
        <path
          onClick={() => props.onFill(30)}
          fill={props.colors[30]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M748.24,793.31c-46.17-36.22-128,53.9-204.89,26.5-52.3-18.61-40.21-69.4-99-126.64-75.62-73.63-186.37-77.95-275-81.41-48.85-1.91-92.55,1.4-130.32,5.51v97.41c25.06-2.19,51.84-3.56,80.3-3.34,97,.74,218.18,1.65,303.36,79.52C449.93,815.79,464,839.92,475.2,861H606.45c48.8-20.4,92.33-56.45,125.16-35.18,14,9.09,13.41,20.41,19,35.18h31.32a65.51,65.51,0,0,1-10-12.63C756.55,823.09,766.18,807.38,748.24,793.31Z"
        />
        <path
          onClick={() => props.onFill(31)}
          fill={props.colors[31]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M731.63,825.82C698.8,804.55,655.27,840.6,606.47,861H750.6C745,846.23,745.66,834.91,731.63,825.82Z"
        />
        <path
          onClick={() => props.onFill(32)}
          fill={props.colors[32]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M422.66,790.86C337.48,713,216.3,712.08,119.3,711.34c-28.46-.22-55.24,1.15-80.3,3.34v95.11c0,.86,0,1.72.06,2.57,0,.4.06.8.09,1.2s.06.88.1,1.31.13,1.1.2,1.64c0,.27.07.55.11.81.09.67.21,1.33.34,2,0,.14,0,.28.07.42.15.76.32,1.53.51,2.28v0a51.6,51.6,0,0,0,36.37,37.19h0c.78.21,1.56.4,2.36.57l.27.06c.71.15,1.43.29,2.16.41l.48.08c.68.11,1.35.2,2,.28l.64.08c.67.07,1.34.11,2,.16l.66,0c.9.05,1.8.07,2.71.07h385C464,839.92,449.93,815.79,422.66,790.86Z"
        />
        <path
          onClick={() => props.onFill(33)}
          fill={props.colors[33]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M271.82,298.74c49.36,24,97.29,43.73,129.58,56.32l.27-.21S342.44,293.49,260,251.19l-41.41,1.09,32.27-5.66A386.9,386.9,0,0,0,196.7,225l-27.08,20.5,10.28-25.21a266.47,266.47,0,0,0-57.26-8.17c-1.87.72-3.79,1.43-5.7,2.14l-.53.11c-4.06,1.45-9.6,3.43-15.25,5.43l-1.44.71q-9.54,3.34-19.7,6.68l-1.3.44c-7.53,2.45-14.84,5-22,7.62l-12.65-10.9L39,220v61C112.62,258.4,199.21,263.43,271.82,298.74Z"
        />
        <path
          onClick={() => props.onFill(34)}
          fill={props.colors[34]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M63.21,570.53c3.84-10.69,7.67-22.08,12.64-33.38L48.9,503.83l30.94,24.8a127.62,127.62,0,0,1,21.25-31L92.5,464.25l24.36,19.52a125.38,125.38,0,0,1,20.83-12.14,138,138,0,0,1,28-9.6C110.38,406.92,85,392.86,39,383.05V614.5C50.5,604,56.86,588.22,63.21,570.53Z"
        />
        <path
          onClick={() => props.onFill(35)}
          fill={props.colors[35]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M165.72,462c6.59,6.56,13.57,13.67,21.1,21.45,7.15,7.41,14.34,14.29,21.52,20.77l41.46-25.36-25.26,39.3a424.93,424.93,0,0,0,61.9,42.21l33-20.17-18.06,28.11C312,573.7,321.7,578,330.18,581.43c21.6,8.72,35.16,11.68,35.16,11.68.07-.93.12-1.8.18-2.72-.73-3-1.46-6-2.2-9-10.51-41.88-23.08-75-98.8-132.41-11.56-8.77-24.58-18.1-39.3-28.11-50.6-34.44-94.84-49.42-130.42-54.68a203,203,0,0,0-55.8-.86v17.68C85,392.86,110.38,406.92,165.72,462Z"
        />
        <path
          onClick={() => props.onFill(36)}
          fill={props.colors[36]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M50.2,349.85l4.47-26.59,12.5,32.34q11.24.31,23,1.1l11.57-68.88-1.34,69.67q4.35.36,8.78.81l7.25-43.16,17.85,46.16c7.54,1.06,15.19,2.25,23,3.63q11.26,2,21.88,3.61l-14.34-64.87,25.4,66.52a720.71,720.71,0,0,0,83.38,7.36l-13.79-62.41,23.89,62.57c84.94.51,114.76-20.39,117.74-22.65-32.29-12.59-80.22-32.31-129.58-56.32C199.21,263.43,112.62,258.4,39,281v74.56c3.4-.07,6.79-.15,10.27-.16Z"
        />
        <path
          onClick={() => props.onFill(37)}
          fill={props.colors[37]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M289.43,103.87l.14-.31s-81.16-26.21-173.79-24.7l-36,20.48,25.8-20.2a386.83,386.83,0,0,0-58,6.43L39,104.18v35.68a316.58,316.58,0,0,1,109.6-24.61C203.44,113.17,255,108,289.43,103.87Z"
        />
        <path
          onClick={() => props.onFill(38)}
          fill={props.colors[38]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          id="LandscapeTestPath"
          className="LandscapeTestPath"
          d="M56.74,235.23c7.14-2.61,14.45-5.17,22-7.62l1.3-.44c4.18-1.41,12.75-4.42,21.14-7.39,5.65-2,11.19-4,15.25-5.43,3.42-1.22,5.78-2.07,6.23-2.25a694.23,694.23,0,0,0,64.62-28.16l-41.57-48.55,50.55,43.93c75.17-39.56,91.63-72,93.19-75.45-34.41,4.11-86,9.3-140.83,11.38A316.58,316.58,0,0,0,39,139.86V220l5.09,4.38Z"
        />
      </g>
    </svg>
  );
};

Landscape.propTypes = {
  onFill: PropTypes.func,
  colors: PropTypes.array,
};

export default Landscape;
