import { transformText } from '../../../../Scripts/textHelper';
import ChatBubble from './ChatBubble';

export default class ChatBubbleManager {
  constructor(game, gameData, chatBubbleData) {
    this.game = game;
    this.gameData = gameData;
    this.coordinates = { x: 0, y: 0 };
    this.chatBubbles = {};
    this.chatBubble = undefined;
    this.createChatBubble(chatBubbleData);
  }

  createChatBubble = (chatBubbleData) => {
    this.chatBubble = this.create(chatBubbleData.DATA[0]);
    for (let i = 0; i < chatBubbleData.DATA.length; i++) {
      this.chatBubbles = {
        ...this.chatBubbles,
        [chatBubbleData.DATA[i].NAME]: chatBubbleData.DATA[i],
      };
    }
  };

  create = (chatBubbleData, disable = undefined) => {
    return this.game.add.reactDom(ChatBubble, {
      text: chatBubbleData.TEXTID ? transformText(this.gameData, chatBubbleData.TEXTID) : '',
      isResponse: chatBubbleData.ISRESPONSE ?? false,
      coordinates: this.coordinates,
      disabled: disable ? disable : chatBubbleData.disabled ? chatBubbleData.disabled : true,
    });
  };

  updateChatBubble = (chatBubbleKey, { textId, disabled = false }) => {
    this.chatBubbles[chatBubbleKey] = {
      ...this.chatBubbles[chatBubbleKey],
      TEXTID: textId,
      disabled: disabled,
    };
  };

  showChatBubble = (
    chatBubbleKey,
    newCoordinates = undefined,
    isFlipped = false,
    // eslint-disable-next-line no-unused-vars
    fadeIn = true,
    disable = undefined,
  ) => {
    const currentChatBubble = this.chatBubbles[chatBubbleKey];
    this.coordinates = { x: newCoordinates?.x ?? this.coordinates.x, y: newCoordinates?.y ?? this.coordinates.y };
    this.chatBubble.setState({
      text: currentChatBubble.TEXTID ? transformText(this.gameData, currentChatBubble.TEXTID) : '',
      isResponse: currentChatBubble.ISRESPONSE ?? false,
      coordinates: this.coordinates,
      fadeIn: false,
      disabled: disable ? disable : currentChatBubble.disabled,
      isFlipped: isFlipped,
    });
    return this.chatBubble;
  };

  updateFadeIn = (fadeIn) => {
    this.fadeIn = fadeIn;
    this.chatBubble.setState({
      text: this.chatBubble.state.text,
      isResponse: this.chatBubble.state.isResponse,
      coordinates: this.chatBubble.state.coordinates,
      fadeIn: this.fadeIn,
      disabled: this.chatBubble.state.disabled,
    });
    return this.chatBubble;
  };

  toggleChatBubble = (disabled) => {
    this.chatBubble.setState({
      text: this.chatBubble.state.text,
      isResponse: this.chatBubble.state.isResponse ?? false,
      coordinates: this.chatBubble.state.coordinates,
      fadeIn: false,
      disabled: disabled,
    });
    return this.chatBubble;
  };

  chatBubbleExists = (chatBubbleKey) => {
    return !!this.chatBubbles[chatBubbleKey];
  };

  displayChatBubble = (chatBubbleKey, newCoordinates = undefined, isFlipped = false) => {
    this.chatBubbles[chatBubbleKey].disabled = false;
    this.showChatBubble(chatBubbleKey, newCoordinates, isFlipped);
  };

  updateCoordinates = (x, y) => {
    this.coordinates.x = x;
    this.coordinates.y = y;
  };

  update = ({ textId, disabled = false }) => {
    this.chatBubbleData.TEXTID = textId;
    this.chatBubbleData.disabled = disabled;

    this.chatBubble.setState({
      text: textId ? transformText(this.gameData, textId) : '',
      isResponse: this.chatBubble.isResponse ?? false,
      coordinates: this.coordinates,
      disabled: this.chatBubbleData.disabled,
    });

    return this.chatBubble;
  };
}
