import React from 'react';
import { useIntl } from 'react-intl';
import { useOnboardDispatch } from 'Contexts/StoreContext';
import { StyledProgressBar } from 'Onboard_Components/Account/SignUp/styles';
import { Header } from 'Onboard_Components/Account/styles';
import { Typography } from '@mui/material';
import Button from 'Stories/components/Button';
import { resetLoginState } from 'Onboard_Redux/login';

function LogInTimeOut() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();

  function onLogIn() {
    dispatch(resetLoginState());
  }

  return (
    <>
      <StyledProgressBar aria-hidden="true" variant="determinate" value={0} />
      <Header>{intl.formatMessage({ id: 'account.log-in.session-timed-out' })}</Header>
      <Typography textAlign={'center'} fontSize={16} sx={{ width: '90%', alignSelf: 'center', fontWeight: '700' }}>
        {intl.formatMessage({ id: 'account.log-in.session-timed-out-text' })}
      </Typography>
      <Button fullWidth onClick={onLogIn} sx={{ marginTop: '32px', fontSize: '18px' }}>
        {intl.formatMessage({ id: 'account.log-in' })}
      </Button>
    </>
  );
}

export default LogInTimeOut;
