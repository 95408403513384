import React from 'react';
import PropTypes from 'prop-types';
import { StyledRow } from './styles';

function ButtonRow({ children, ...props }) {
  return <StyledRow {...props}>{children}</StyledRow>;
}
ButtonRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};

export default ButtonRow;
