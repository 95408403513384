export const Config = {
  KEY: 'MOVEMENT',
  ANIMATIONS: {
    WAVE: {
      frames: [0, 1, 2, 3],
      frameRate: 12,
      repeat: 2,
      yoyo: true,
    },
    IDLE: {
      frames: [4, 5],
      frameRate: 12,
      repeat: 0,
    },
    WALK: {
      frames: [6, 7, 8, 9, 10, 11],
      frameRate: 16,
      repeat: -1,
    },
  },
  ATTACHPOINTS: {
    MOUTH: {},
    NECK: {},
  },
};
