import React from 'react';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@mui/material';
import HeaderBar from 'Core_Components/HeaderBar/HeaderBar';
import { StyledBackButton, StyledCard, StyledGrid, StyledToolsGrid, ToolContainer } from '../styles';
import { useHistory } from 'react-router-dom';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';
import hasPermissions from 'Core_Helpers/authentication/hasPermissions';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectUserInfo, selectUserMeta } from 'Core_Redux/user';
import { appInsights } from 'Core_Helpers/AppInsights';
import GradientCard from 'Core_Components/GradientCard/GradientCard';
import { black } from 'Styles/colors';
import ToolCard from '../components/ToolCard/ToolCard';
import { ArrowBackIos } from '@mui/icons-material';
import useMediaType from 'Core_Helpers/useMediaType';
import { breakpoints } from 'Styles/theme';
import filterUserMetaByTag from 'Core_Pages/MyTools/userMetaHelpers';
import { relationshipItems } from './config';
import { relationships } from 'Assets/img/my-tools-icons';

function MyRelationshipTools() {
  const intl = useIntl();
  const history = useHistory();
  const profile = useCoreSelector(selectUserInfo);
  const userMeta = useCoreSelector(selectUserMeta);
  const mediaType = useMediaType();

  function onToolActionClick({ to, loggedEvent }) {
    if (loggedEvent) {
      appInsights.trackEvent({ name: loggedEvent });
    }

    history.push({ pathname: to });
  }

  function onBack() {
    history.push(HOME);
  }

  const toolItems = relationshipItems.filter(
    (x) => hasPermissions(x.permissions, x.roles, profile) && filterUserMetaByTag(x.contentCategory, userMeta),
  );

  return (
    <ToolContainer>
      <HeaderBar backgroundColor="#D9FCFF" sx={{ boxShadow: 2, zIndex: 1 }}>
        {mediaType !== breakpoints.DESKTOP && (
          <StyledBackButton onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })}>
            <ArrowBackIos />
          </StyledBackButton>
        )}
        <Typography component="h2" fontSize={18} fontWeight={700}>
          {intl.formatMessage({ id: 'home.tools.relationships' })}
        </Typography>
      </HeaderBar>
      <StyledGrid container justifyContent="left" rowSpacing={4} columnSpacing={4}>
        <Grid item mobile={12}>
          <GradientCard
            CardComponent={StyledCard}
            fontSize={16}
            fontWeight={400}
            color={black._100}
            backgroundColor="#D9FCFF"
            titleId="tools.relationships.description"
            imageStyle={{ position: 'relative', width: '104px', margin: '40px 0px 10px 0px' }}
            icon={relationships}
          />
        </Grid>
        {toolItems?.map((sectionItem, index) => (
          <StyledToolsGrid item key={index} mobile={6} tablet={4} desktop={3}>
            <ToolCard onClick={() => onToolActionClick(sectionItem)} {...sectionItem} />
          </StyledToolsGrid>
        ))}
      </StyledGrid>
    </ToolContainer>
  );
}

export default MyRelationshipTools;
