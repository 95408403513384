import styled from 'styled-components';

const StyledLayout = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  font-family: 'Karla', serif;
`;

const StyledHeader = styled.div`
  height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: linear-gradient(#9b9baf, #666666);
  box-shadow: 5px 5px 5px #b3b3b3;

  > img {
    height: 85px;
  }
`;

const StyledContent = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  padding: 0 40px 20px;
  color: black;

  @media screen and (max-width: 360px) {
    padding: 0 20px 20px;
  }
`;

export { StyledLayout, StyledHeader, StyledContent };
