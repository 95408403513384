import React from 'react';
import GoogleFontIcon from 'Core_Components/GoogleFontIcon/GoogleFontIcon';
import { StyledSeparator } from './styles';

function Separator() {
  return (
    <StyledSeparator>
      <GoogleFontIcon name={GoogleFontIcon.Name.ChevronRight} />
    </StyledSeparator>
  );
}

export default Separator;
