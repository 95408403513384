import jwt_decode from 'jwt-decode';
import { appInsights } from 'Onboard_Helpers/AppInsights';

export function areTokensValid(accessToken, refreshToken) {
  if (accessToken && refreshToken) {
    // divide by 1000 to convert to UNIX seconds to use the same units as used for the token expiration (exp) property
    const currentTime = new Date().getTime() / 1000;

    try {
      const jwt = jwt_decode(accessToken);
      return jwt.exp >= currentTime;
    } catch (e) {
      appInsights.trackException({
        exception: `Failed to verify user token with error: ${e}`,
        properties: { errorMessage: error?.message },
      });
    }
  }

  return false;
}
