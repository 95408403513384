import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useCoreDispatch } from 'Contexts/StoreContext';
import { draftEditReminder, draftDeleteReminder } from 'Core_Redux/reminders';
import { StyledMenuCard, StyledMenuOption } from './styles';

function ActionMenu({ guid, onClose, buttonRef }) {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useCoreDispatch();
  const actionMenuRef = useRef();

  useEffect(() => {
    const onBodyClick = (event) => {
      if (
        (actionMenuRef.current && actionMenuRef.current.contains(event.target)) ||
        (buttonRef.current && buttonRef.current.contains(event.target))
      ) {
        return;
      }
      onClose();
    };
    document.body.addEventListener('click', onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener('click', onBodyClick, { capture: true });
    };
  }, []);

  const handleEdit = () => dispatch(draftEditReminder({ guid, history }));
  const handleDelete = () => dispatch(draftDeleteReminder(guid));

  return (
    <StyledMenuCard ref={actionMenuRef}>
      <StyledMenuOption onClick={handleDelete}>{intl.formatMessage({ id: 'common.delete' })}</StyledMenuOption>
      <StyledMenuOption onClick={handleEdit}>{intl.formatMessage({ id: 'common.edit' })}</StyledMenuOption>
    </StyledMenuCard>
  );
}

ActionMenu.propTypes = {
  guid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  buttonRef: PropTypes.object,
};

export default ActionMenu;
