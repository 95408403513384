import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { black } from 'Styles/colors';

export const ErrorMessage = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  text-align: center;
`;

export const Message = styled(Typography)`
  color: ${black._74};
  font-style: italic;
  text-align: center;
`;
