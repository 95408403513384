import React from 'react';
import PropTypes from 'prop-types';
import { Capacitor } from '@capacitor/core';
import DeviceInformation from 'Onboard_Components/DeviceInformation';
import { GreetingContainer, Pyxir } from './styles';
import HeaderPyxir from 'Assets/img/header-pyxir.svg';

const OnboardingHeader = ({ hasBrowserInfo, isShown = true, hasMenu = false }) => {
  if (!isShown) {
    return <></>;
  }

  let isNative = false;
  if (Capacitor.getPlatform() === 'ios' && !hasMenu) {
    isNative = true;
  }

  return (
    <GreetingContainer ProfilePage data-testid="GreetingContainer" isNative={isNative}>
      {hasBrowserInfo && <DeviceInformation />}
      <Pyxir alt="" aria-hidden="true" src={HeaderPyxir} />
    </GreetingContainer>
  );
};

OnboardingHeader.propTypes = {
  hasBrowserInfo: PropTypes.bool,
  hasLogin: PropTypes.bool,
  isShown: PropTypes.bool,
  hasMenu: PropTypes.bool,
};

export default OnboardingHeader;
