import React from 'react';
import { useHistory } from 'react-router-dom';
import { accessTypes, activities } from '../homeConfig';
import ActivityItem from './ActivityItem/ActivityItem';
import { ArrowTabs } from './styles';
import hasPermissions from 'Core_Helpers/authentication/hasPermissions';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectUserInfo } from 'Core_Redux/user';
import { selectThriveAccess } from 'Core_Redux/thrive';
import { getUtcDate, toUtcDate } from 'Core_Helpers/dates';
import { CheckinModuleStore } from 'Core_Pages/Checkin/CheckinModuleStore';
import { DirectMessageStore } from 'Core_Pages/DirectMessage/Store/DirectMessageStore';

function ActivityTabs() {
  const history = useHistory();
  const user = useCoreSelector(selectUserInfo);
  const thriveAccess = useCoreSelector(selectThriveAccess);
  const checkinModuleStore = CheckinModuleStore.Instance().Use();
  const screeningsCount = checkinModuleStore.DueScreeningsCount;
  const directmessageStore = DirectMessageStore.Instance().Use();
  const hasUnreadMessage = directmessageStore.HasUnreadMessage;

  const showThriveGraduateBadge = () => {
    return thriveAccess?.thriveStatus == 'GRADUATE' && getUtcDate(-7) < toUtcDate(thriveAccess?.graduationDateUtc);
  };

  const allowedAccess = {
    [accessTypes.THRIVE]: {
      hasAccess: !!thriveAccess && thriveAccess?.thriveStatus == 'ACTIVE',
    },
    [accessTypes.THRIVEGRADUATE]: {
      hasAccess: !!thriveAccess && showThriveGraduateBadge(),
    },
    [accessTypes.CHECKINSREQUIRED]: {
      hasAccess: screeningsCount > 0,
    },
    [accessTypes.CHECKINSREQUIREDDONE]: {
      hasAccess: screeningsCount === 0,
    },
    [accessTypes.TALKTOSOMEONENOMESSAGE]: {
      hasAccess: !hasUnreadMessage,
    },
    [accessTypes.TALKTOSOMEONEUNREADMESSAGE]: {
      hasAccess: hasUnreadMessage,
    },
  };

  function hasPermission({ permissions, roles, access }) {
    const accessGranted = !access || allowedAccess[access]?.hasAccess;
    const permissionsGranted = hasPermissions(permissions, roles, user);
    return accessGranted && permissionsGranted;
  }

  function onActivityClick({ to, permissions, roles, access }) {
    if (!hasPermission({ permissions, roles, access })) {
      return;
    }

    history.push(to);
  }

  return (
    <ArrowTabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={0}>
      {activities.map((activityItem, index) => {
        const itemLoading = activityItem.access && allowedAccess[activityItem?.access]?.isLoading;
        return (
          <ActivityItem
            {...activityItem}
            key={index}
            loading={itemLoading}
            hasPermissions={hasPermission(activityItem)}
            onClick={() => onActivityClick(activityItem)}
          />
        );
      })}
    </ArrowTabs>
  );
}

export default ActivityTabs;
