import 'regenerator-runtime/runtime.js';
import { useEffect, useState } from 'react';
import useAxiosPutSubmit from './api/useAxiosPutSubmit/useAxiosPutSubmit';

const useReadCard = () => {
  const [url, setUrl] = useState();
  const [request, setRequest] = useState();
  const { response, isLoading } = useAxiosPutSubmit(url, request);

  useEffect(() => {
    if (response && !isLoading) {
      setUrl();
      setRequest();
    }
  }, [isLoading]);

  const readCard = (cardId) => {
    setUrl(`${process.env.API_BASE_URL}/v1/cards/${cardId}/transmissions/read`);
    setRequest({});
  };

  return { cardReadInformation: response, cardReadIsPending: isLoading, readCard };
};

export default useReadCard;
