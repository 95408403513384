import React from 'react';
import { StyledCircularProgress } from './styles';
import PropTypes from 'prop-types';

const LoadingWrapper = ({ loading, children }) => {
  return <>{loading ? <StyledCircularProgress /> : children}</>;
};

LoadingWrapper.displayName = 'LoadingWrapper';

LoadingWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.element]),
  loading: PropTypes.bool.isRequired,
};

export default LoadingWrapper;
