import React, { useEffect, useRef, useState } from 'react';
import { StyledCircularProgress, StyledPaper } from './styles';
import { DirectMessageStore } from 'Core_Pages/DirectMessage/Store/DirectMessageStore';
import DirectMessageHeader from 'Core_Pages/DirectMessage/Components/DirectMessageHeader';
import MessageInput from 'Core_Pages/DirectMessage/Components/MessageInput';
import TypingBubbles from 'Core_Pages/DirectMessage/Components/TypingBubbles';
import { SessionStatus } from '../Models/SessionStatus';
import ErrorModal from 'Core_Components/ErrorModal/ErrorModal';
import { ErrorType } from '../Models/ErrorType';
import CORSModal from 'Core_Pages/DirectMessage/Components/Modals/CORSModal/CORSModal';

const DirectMessage = () => {
  const directMessageStore = DirectMessageStore.Instance().Use();
  const [hasGeneralNetworkErrored, setGeneralNetworkError] = useState(false);
  const [hasCORSNetworkErrored, setCORSNetworkError] = useState(false);
  const directMessages = directMessageStore.DirectMessages;
  const sessionStatus = directMessageStore.SessionStatus;
  const hasUnreadMessage = directMessageStore.HasUnreadMessage;
  const isAgentTyping = directMessageStore.IsAgentTyping;
  const errorType = directMessageStore.ErrorType;

  const messagesEndRef = useRef(null);
  const scrollChatToBottom = () => messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  directMessageStore.SetScrollChatToBottom(scrollChatToBottom);

  const onCloseDisconnected = () => {
    setGeneralNetworkError(false);
    setCORSNetworkError(false);
  };

  useEffect(() => {
    directMessageStore.ScrollChatToBottom();
  }, [directMessages.length, isAgentTyping]);

  useEffect(() => {
    if (hasUnreadMessage) {
      directMessageStore.AcknowledgeMessagesRead();
    }
  }, [hasUnreadMessage]);

  useEffect(() => {
    directMessageStore.Connect();
  }, [sessionStatus]);

  useEffect(() => {
    setGeneralNetworkError(errorType === ErrorType.GENERALNETWORKERROR);
    setCORSNetworkError(errorType === ErrorType.CORSNETWORKERROR);
  }, [errorType]);

  const messages = directMessages.map((message) => {
    return message.Component ?? <></>;
  });

  return (
    <>
      <DirectMessageHeader />
      <StyledPaper>
        {sessionStatus == SessionStatus.CONNECTING ? (
          <StyledCircularProgress />
        ) : (
          <>
            {messages}
            {isAgentTyping && <TypingBubbles />}
            <div ref={messagesEndRef} />
          </>
        )}
        {sessionStatus == SessionStatus.CONNECTED ? <MessageInput /> : null}
      </StyledPaper>
      <ErrorModal open={hasGeneralNetworkErrored} onClose={onCloseDisconnected} />
      <CORSModal open={hasCORSNetworkErrored} onClose={onCloseDisconnected} />
    </>
  );
};

export default DirectMessage;
