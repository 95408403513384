import { appInsights } from './AppInsights';
import { isEnvironment, LOCAL, TEST, QA } from './isEnvironment';

/* eslint-disable no-console */
export const logDiagnosticTrace = (event) => {
  if (isEnvironment(LOCAL)) {
    console.log(event);
  } else if (isEnvironment(TEST) || isEnvironment(QA)) {
    console.log(event);
    appInsights.trackTrace({ message: `Diagnostic: ${event}` });
  }
};
