import styled from 'styled-components';
import { StyledParagraph } from 'Core_Pages/GreetingCards/featureStyles';

export const StyledParagraphWithMediaQuery = styled(StyledParagraph)`
  @media screen and (max-height: 460px) {
    margin-bottom: 0;
  }
`;

export const StyledParagraphStacked = styled(StyledParagraph)`
  margin-bottom: 10px;
  margin-top: 0;
  width: 75%;
  align-self: center;
`;

export const StyledEnvelopeImage = styled.img`
  width: 120px;
  flex: 1 0 105px;
`;
