/* eslint-disable react/prop-types */
//disable prop types due to complex store objects
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import en from 'Translations/en.json';
import es from 'Translations/es.json';
import InstallPrompt from 'Core_Components/InstallPrompt/InstallPrompt';
import { IonApp } from '@ionic/react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import theme from 'Styles/theme';
import useAppClosed from 'Core_Hooks/helpers/useAppClosed';
import useAppPaused from 'Core_Hooks/helpers/useAppPaused';
import './App.scss';
import { UserContext } from 'Contexts/UserContext';
import AppRenderer from './AppRenderer';
import { browserHistory } from 'Core_Helpers/AppInsights';
import { IonReactRouter } from '@ionic/react-router';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectLanguage } from 'Core_Redux/user';

//Entry component to instantiate any relevant providers and routers that are required to wrap the rendered app
const AppProvider = () => {
  const [userInfo, setUserInfo] = useState({ screen: '', DOB: '', HealthId: '' });
  const userLanguage = useCoreSelector(selectLanguage);

  // Global events for app closing and pausing/continuing
  useAppClosed();
  useAppPaused();

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    window.handleOpenURL = (url) => {
      // This can be used to interact with the url the app is being opened with
      // If this method does not exist it will throw exceptions
    };
  }, []);

  return (
    <IntlProvider locale={userLanguage ?? 'en'} messages={userLanguage?.includes('es') ? es : en}>
      <UserContext.Provider value={{ userInfo, setUserInfo }}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <IonApp>
              <IonReactRouter history={browserHistory}>
                <AppRenderer />
              </IonReactRouter>
            </IonApp>
          </StyledEngineProvider>
        </ThemeProvider>
      </UserContext.Provider>
      <InstallPrompt />
    </IntlProvider>
  );
};

export default AppProvider;
