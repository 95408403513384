import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'Core_Components/PageHeader/PageHeader';
import ButtonRow from 'Core_Pages/GreetingCards/ButtonRow/ButtonRow';
import {
  StyledGreetingCardWrapperButton,
  StyledImage,
  StyledParagraph,
} from 'Core_Pages/GreetingCards/GreetingCardsInbox/styles';
import { useIntl } from 'react-intl';
import useSearchCards from 'Core_Hooks/useSearchCards';
import useGetCard from 'Core_Hooks/useGetCard';
import useReadCard from 'Core_Hooks/useReadCard';
import GreetingCard from 'Core_Pages/GreetingCards/GreetingCard/GreetingCard';
import RudyLooking from 'Assets/img/Rudy-looking.png';
import Spinner from 'Core_Components/Spinner/Spinner';
import { StyledContainer, StyledOutlineButton, StyledSolidButton } from 'Core_Pages/GreetingCards/featureStyles';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import { useHistory } from 'react-router-dom';

function GreetingCardsInbox() {
  const intl = useIntl();
  const { cards, isSearchCardsPending } = useSearchCards();
  const { card, isGetCardPending, retrieveCard } = useGetCard();
  const { readCard } = useReadCard();
  const [isPending, setIsPending] = useState(true);
  const [currentCard, setCurrentCard] = useState();
  const history = useHistory();

  useEffect(() => {
    if (cards) {
      retrieveCard(cards[0]?.cardId);
    }
  }, [cards, isSearchCardsPending]);

  useEffect(() => {
    if (card && !isGetCardPending) {
      setCurrentCard(card);
      readCard(card?.cardId);
    }
  }, [isGetCardPending]);

  useEffect(() => {
    setIsPending(isSearchCardsPending || (isGetCardPending && cards?.length > 0));
  }, [isSearchCardsPending, isGetCardPending]);

  const showEmptyInboxScreen = () => {
    return (
      <>
        <StyledParagraph>{intl.formatMessage({ id: 'greeting-cards.inbox.description-empty' })}</StyledParagraph>
        <StyledImage src={RudyLooking} alt={intl.formatMessage({ id: 'image.rudy.looking' })} />
        <ButtonRow>
          <StyledSolidButton onClick={() => history.push(paths.GREETINGCARDS)}>
            {intl.formatMessage({ id: 'greeting-cards.inbox.back-button' })}
          </StyledSolidButton>
        </ButtonRow>
      </>
    );
  };

  const showReceivedCardScreen = () => {
    return (
      <>
        <StyledParagraph>{intl.formatMessage({ id: 'greeting-cards.inbox.description-message' })}</StyledParagraph>
        <StyledGreetingCardWrapperButton onClick={() => history.push(paths.DESIGNYOURCARD)}>
          <GreetingCard
            cardImage={currentCard?.storagePath}
            cardText={currentCard?.text}
            cardFrom={'Andy'}
            imageDescription={currentCard?.imageDescription}
          />
        </StyledGreetingCardWrapperButton>
        <ButtonRow>
          <StyledOutlineButton onClick={() => history.push(paths.GREETINGCARDS)}>
            {intl.formatMessage({ id: 'greeting-cards.inbox.done-button' })}
          </StyledOutlineButton>
          <StyledSolidButton onClick={() => history.push(paths.DESIGNYOURCARD)}>
            {intl.formatMessage({ id: 'greeting-cards.inbox.respond-button' })}
          </StyledSolidButton>
        </ButtonRow>
      </>
    );
  };

  return (
    <StyledContainer>
      <PageHeader>{intl.formatMessage({ id: 'greeting-cards.inbox.header' })}</PageHeader>
      {isPending ? <Spinner /> : currentCard ? showReceivedCardScreen() : showEmptyInboxScreen()}
    </StyledContainer>
  );
}

GreetingCardsInbox.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, () => undefined]),
};

export default GreetingCardsInbox;
