import { transformText } from '../../../../Scripts/textHelper';
import Caption from './Caption';

export default class CaptionManager {
  constructor(game, gameData, captionData) {
    this.game = game;
    this.gameData = gameData;
    this.captions = {};
    this.caption = undefined;
    this.createCaptions(captionData);
  }

  createCaptions = (captionData) => {
    this.caption = this.create(captionData.DATA[0]);
    for (let i = 0; i < captionData.DATA.length; i++) {
      this.captions = {
        ...this.captions,
        [captionData.DATA[i].NAME]: captionData.DATA[i],
      };
    }
  };

  create = (captionData, disable = undefined) => {
    return this.game.add.reactDom(Caption, {
      title: captionData.TITLEID ? transformText(this.gameData, captionData.TITLEID) : '',
      text: captionData.TEXTID ? transformText(this.gameData, captionData.TEXTID) : '',
      disabled: disable ? disable : captionData.disabled ? captionData.disabled : true,
    });
  };

  updateCaption = (captionKey, { titleId, textId, disabled = false }) => {
    this.captions[captionKey] = {
      ...this.captions[captionKey],
      TITLEID: titleId,
      TEXTID: textId,
      disabled: disabled,
    };
  };

  showCaption = (captionKey, disable = undefined) => {
    const currentCaption = this.captions[captionKey];
    this.currentCaption = captionKey;
    return this.caption.setState({
      title: currentCaption.TITLEID ? transformText(this.gameData, currentCaption.TITLEID) : '',
      text: currentCaption.TEXTID ? transformText(this.gameData, currentCaption.TEXTID) : '',
      disabled: disable ? disable : currentCaption.disabled,
    });
  };

  toggleCaption = (disabled) => {
    return this.caption.setState({
      title: this.caption.title,
      text: this.caption.text,
      disabled: disabled,
    });
  };

  captionExists = (captionKey) => {
    return !!this.captions[captionKey];
  };

  displayCaption = (captionKey) => {
    this.captions[captionKey].disabled = false;
    this.showCaption(captionKey);
  };

  update = ({ titleId, textId, disabled = false }) => {
    this.captionData.TITLEID = titleId;
    this.captionData.TEXTID = textId;
    this.captionData.disabled = disabled;

    this.caption.setState({
      title: titleId ? transformText(this.gameData, titleId) : '',
      text: textId ? transformText(this.gameData, textId) : '',
      disabled: this.captionData.disabled,
    });

    return this.caption;
  };
}
