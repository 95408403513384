import { lgbtqiaSocialMedia } from 'Assets/img/my-tools-icons';

export const lgbtqiaSocialMediaEn = {
  key: 'lgbtqia-social-media',
  title: 'Keeping a safe space on LGBTQIA+ social media',
  titleImage: lgbtqiaSocialMedia,
  titleImageAlt: '',
  contentId: 19,
  description:
    'Online LGBTQIA+ communities can be a positive experience. Many social media channels can provide life-affirming connections and ways celebrate authenticity. It’s just as important for social channels to create a safe space for expression as it is to be mindful of how to participate on them to support your well-being. Here are a few tips to keep in mind when online and off.',
  sections: [
    {
      key: 'lgbtqia-sm-protect-space',
      header: 'Protect your space',
      description:
        'Each social channel has changeable settings you can control. For example, the privacy setting lets you choose who follows you and views the photos and videos you post.',
    },
    {
      key: 'lgbtqia-sm-be-picky',
      header: 'Be picky',
      description:
        'If you’re excited to share something, but only want a select group of friends or followers to see, share it through the channel’s direct messaging option vs posting publicly.',
    },
    {
      key: 'lgbtqia-sm-own-feed',
      header: 'Own your feed',
      description:
        'If someone you follow makes you feel bad about yourself, it’s time to unfollow them. Think of it as a small but powerful way to practice self-care. Typically, they are not notified about the change.',
    },
    {
      key: 'lgbtqia-sm-block-negativity',
      header: 'Block negativity',
      description:
        'If someone is negatively affecting your experience, you can block them. Again, they typically aren’t notified, and they can no longer view your posts or account.  If you change your mind, you can simply unblock them.',
    },
    {
      key: 'lgbtqia-sm-control-comments',
      header: 'Control comments',
      description:
        'Social media channels have increased monitoring for offensive comments. Check settings options for choosing who comments on your posts, plus words, phrases, and emojis you want filtered out.',
    },
    {
      key: 'lgbtqia-sm-pausing-noise',
      header: 'Pausing noise',
      description:
        'If you need a break from someone you follow, it’s okay to pause their posts for a while. Check settings to hide or mute them. When you want to see them again, simply unhide or un-pause them.',
    },
    {
      key: 'lgbtqia-sm-support-others',
      header: 'Support others',
      description:
        'If you see a post that makes you worry someone might hurt themselves, know that you don’t have to handle it alone. You can connect them to support resources such as a helpline, or encourage them to talk to others.\n\nNOTE: Your Pyx Health ANDY is available to talk to. They can provide caring understanding and help find resources your health care plan has available. Use the “Call ANDY” button on your Pyx Health app or call them at 1-855-499-4777.',
    },
    {
      key: 'lgbtqia-sm-own-friend',
      header: 'Be your own friend',
      description:
        'Everyone is deserving of love and respect. Having a community where you feel welcomed and supported helps both physical and mental well-being.  You can use hashtags to search for groups and content creators on social media you feel aligned with such as #YouAreNotAlone, #SelfCare, #TransIsBeautiful, #LoveIsLove, #LoveWins, #BeKind, and #YouMatter.',
    },
  ],
};
