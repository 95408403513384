import React, { useState } from 'react';
import { getUniqueSudoku } from './SudokuGenerator';
import { useHistory } from 'react-router-dom';
import StartGame from 'Core_Components/StartGame/StartGame';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { appInsights } from 'Core_Helpers/AppInsights';
import {
  ActionButton,
  ActionIcon,
  GameBoardCell,
  GameBoardContainer,
  GameBoardRow,
  GameButton,
  GameButtonContainer,
  GameContainer,
  RudySolvedImage,
  RudySolvedImageContainer,
  SolvedButtonContainer,
  SolvedContainer,
  StatusContainer,
  StatusNumber,
  StatusNumbers,
  StatusOptions,
  StatusSectionContainer,
  SudokuContainer,
  SudokuGameBoardContainer,
  SudokuGameContainer,
  SudokuOverlayContainer,
} from './styles';
import Dropdown from 'Core_Components/Dropdown/Dropdown';
import RudySolvedHeaderEn from 'Assets/img/rudy-game-solved-header-en.svg';
import RudySolvedHeaderEs from 'Assets/img/rudy-game-solved-header-es.svg';
import SudokuImage from 'Assets/img/sudoku-game.svg';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';

import { useIntl } from 'react-intl';

// Game is the main React component.
const SudokuGame = () => {
  /**
   * All the variables for holding state:
   * gameArray: Holds the current state of the game.
   * initArray: Holds the initial state of the game.
   * solvedArray: Holds the solved position of the game.
   * difficulty: Difficulty level - 'Easy', 'Medium' or 'Hard'
   * numberSelected: The Number selected in the Status section.
   * cellSelected: If a game cell is selected by the user, holds the index.
   * history: history of the current game, for 'Undo' purposes.
   * overlay: Is the 'Game Solved' overlay enabled?
   */
  const intl = useIntl();
  const history = useHistory();
  const rows = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const headerLocale = intl.formatMessage({ id: 'games.sudoku.header-locale' });
  const actionConfig = [
    {
      testId: 'ActionOption-Undo',
      text: intl.formatMessage({ id: 'games.sudoku.game-option.undo' }),
      icon: 'fas fa-undo-alt',
      onClickAction: onClickUndo,
    },
    {
      testId: 'ActionOption-Erase',
      text: intl.formatMessage({ id: 'common.clear' }),
      icon: 'fas fa-eraser',
      onClickAction: onClickErase,
    },
    {
      testId: 'ActionOption-Hint',
      text: intl.formatMessage({ id: 'games.sudoku.game-option.hint' }),
      icon: 'fal fa-lightbulb-exclamation',
      onClickAction: onClickHint,
    },
  ];
  const difficultyConfig = [
    {
      testId: 'DifficultyOptions_Easy',
      value: 'Easy',
      text: 'games.sudoku.game-difficulty.easy',
    },
    {
      testId: 'DifficultyOptions_Medium',
      value: 'Medium',
      text: 'games.sudoku.game-difficulty.medium',
    },
    {
      testId: 'DifficultyOptions_Hard',
      value: 'Hard',
      text: 'games.sudoku.game-difficulty.hard',
    },
  ];

  const [gameHistory, setGameHistory] = useState([]);
  const [solvedArray, setSolvedArray] = useState([]);
  const [gameArray, setGameArray] = useState([]);
  const [initArray, setInitArray] = useState([]);
  const [cellSelected, setCellSelected] = useState(-1);
  const [numberSelected, setNumberSelected] = useState(-1);
  const [difficulty, setDifficulty] = useState('Easy');
  const [overlay, setOverlay] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);

  //Creates a new game and initializes the state variables.
  const createNewGame = (e) => {
    const [temporaryInitArray, temporarySolvedArray] = getUniqueSudoku(difficulty, e);

    setGameArray(temporaryInitArray);
    setInitArray(temporaryInitArray);
    setSolvedArray(temporarySolvedArray);
    setGameHistory([]);
    setNumberSelected('0');
    setCellSelected(-1);
    setGameStarted(true);
  };

  // Checks if the game is solved.
  function isSolved(index, value) {
    if (
      gameArray.every((cell, cellIndex) => {
        if (cellIndex === index) return value === solvedArray[cellIndex];
        else return cell === solvedArray[cellIndex];
      })
    ) {
      return true;
    }
    return false;
  }

  // Fills the cell with the given 'value'. Used to Fill / Erase as required.
  function fillCell(index, value) {
    if (initArray[index] === '0') {
      // Direct copy results in interesting set of problems, investigate more!
      let tempArray = gameArray.slice();
      let tempHistory = gameHistory.slice();

      // Can't use tempArray here, due to Side effect below!!
      tempHistory.push(gameArray.slice());
      setGameHistory(tempHistory);

      tempArray[index] = value;
      setGameArray(tempArray);

      if (isSolved(index, value)) {
        setOverlay(true);
      }
    }
  }

  // A 'user fill' will be passed on to the _fillCell function above.
  function userFillCell(index, value) {
    fillCell(index, value);
  }

  /**
   * Cell Highlight Method 2: Highlight all cells with
   * the same number as in the current cell.
   */
  function isCellSameAsSelectedCell(row, column) {
    return numberSelected === gameArray[row * 9 + column];
  }

  /**
   * Returns the classes for a cell related to the selected cell.
   */
  function selectedCell(indexOfArray, value, highlight) {
    if (value !== '0') {
      if (initArray[indexOfArray] === '0') {
        return (
          <GameBoardCell
            userfilled
            data-testid={`Cell_${indexOfArray}`}
            highlight={highlight}
            selected={!highlight}
            key={indexOfArray}
            onClick={() => onClickCell(indexOfArray)}
          >
            {value}
          </GameBoardCell>
        );
      } else {
        return (
          <GameBoardCell
            data-testid={`Cell_${indexOfArray}`}
            highlight={highlight}
            selected={!highlight}
            key={indexOfArray}
            onClick={() => onClickCell(indexOfArray)}
          >
            {value}
          </GameBoardCell>
        );
      }
    } else {
      return (
        <GameBoardCell
          data-testid={`Cell_${indexOfArray}`}
          highlight={highlight}
          selected={!highlight}
          key={indexOfArray}
          onClick={() => onClickCell(indexOfArray)}
        >
          {value}
        </GameBoardCell>
      );
    }
  }

  /**
   * Returns the classes or a cell not related to the selected cell.
   */
  function unselectedCell(indexOfArray, value) {
    if (value !== '0') {
      if (initArray[indexOfArray] === '0') {
        return (
          <GameBoardCell
            data-testid={`Cell_${indexOfArray}`}
            userfilled
            key={indexOfArray}
            onClick={() => onClickCell(indexOfArray)}
          >
            {value}
          </GameBoardCell>
        );
      } else {
        return (
          <GameBoardCell
            data-testid={`Cell_${indexOfArray}`}
            filled
            key={indexOfArray}
            onClick={() => onClickCell(indexOfArray)}
          >
            {value}
          </GameBoardCell>
        );
      }
    } else {
      return (
        <GameBoardCell
          data-testid={`Cell_${indexOfArray}`}
          key={indexOfArray}
          onClick={() => onClickCell(indexOfArray)}
        >
          {value}
        </GameBoardCell>
      );
    }
  }

  // User Event Functions

  /**
   * On Click of 'New Game' link,
   * create a new game.
   */
  function onClickNewGame() {
    appInsights.trackEvent({ name: 'New_Sudoku_Game_Started' });
    setOverlay(false);
    createNewGame();
  }

  function onClickDone() {
    appInsights.trackEvent({ name: 'Sudoku_Exited_After_Completing_Game' });
    history.push(HOME);
  }

  /**
   * On Click of a Game cell.
   */
  function onClickCell(indexOfArray) {
    if (!overlay) {
      if (numberSelected !== '0') {
        userFillCell(indexOfArray, numberSelected);
      }
      setCellSelected(indexOfArray);
    }
  }

  /**
   * On Change Difficulty,
   * 1. Update 'Difficulty' level
   * 2. Create New Game
   */
  const onChangeDifficulty = (e) => {
    setDifficulty(e.target.value);
    createNewGame(e);
  };

  /**
   * On Click of Number in Status section,
   * either fill cell or set the number.
   */
  function onClickNumber(number) {
    if (!overlay) {
      if (number == numberSelected) {
        setNumberSelected('0');
      } else {
        setNumberSelected(number);
      }
    }
  }

  /**
   * On Click Undo,
   * try to Undo the latest change.
   */
  function onClickUndo() {
    if (gameHistory.length) {
      let tempHistory = gameHistory.slice();
      let tempArray = tempHistory.pop();
      setGameHistory(tempHistory);
      if (tempArray !== undefined) setGameArray(tempArray);
    }
  }

  // On Click Erase, try to delete the cell.
  function onClickErase() {
    if (cellSelected !== -1 && gameArray[cellSelected] !== '0') {
      fillCell(cellSelected, '0');
    }
  }

  // On Click Hint, fill the selected cell if its empty or wrong number is filled.
  function onClickHint() {
    if (cellSelected !== -1) {
      fillCell(cellSelected, solvedArray[cellSelected]);
    }
  }

  // Create Game Options
  const createNumbers = () => {
    return (
      <StatusNumbers>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => {
          return (
            <StatusNumber
              key={number}
              data-testid={`NumberSelectionOption_${number}`}
              onClick={() => onClickNumber(number.toString())}
              selected={numberSelected.toString() === number.toString()}
            >
              {number}
            </StatusNumber>
          );
        })}
      </StatusNumbers>
    );
  };

  const createDifficulty = () => {
    return (
      <Dropdown
        title={intl.formatMessage({ id: 'games.sudoku.difficulty' })}
        items={difficultyConfig}
        currentValue={difficultyConfig.find((d) => d.value == difficulty)}
        handleSelection={onChangeDifficulty}
      ></Dropdown>
    );
  };

  const createActions = () => {
    return actionConfig.map((action, index) => {
      return (
        <ActionButton data-testid={action.testId} key={index} onClick={action.onClickAction}>
          <ActionIcon alt={action.text} className={action.icon} /*style={{ top: action.top }}*/ />
          {action.text}
        </ActionButton>
      );
    });
  };

  const exitIntro = () => {
    appInsights.trackEvent({ name: 'Sudoku_Exited_From_Start_Screen' });
    history.push(HOME);
  };

  return (
    <GameContainer data-testid="GameContainer">
      <ScreenReaderWrapper>
        <h1 id="SudokuGame">{intl.formatMessage({ id: 'title.sudoku-game' })}</h1>
      </ScreenReaderWrapper>
      {!gameStarted ? (
        <StartGame
          startNewGame={onClickNewGame}
          exitIntro={exitIntro}
          gameName={intl.formatMessage({ id: 'title.sudoku-game' })}
          image={SudokuImage}
          descriptionHeader={intl.formatMessage({ id: 'games.description-header' })}
          description={intl.formatMessage({ id: 'games.sudoku.description' })}
          directionsHeader={intl.formatMessage({ id: 'games.directions-header' })}
          directions={intl.formatMessage({ id: 'games.sudoku.directions' })}
          haveFun={intl.formatMessage({ id: 'games.have-fun' })}
        />
      ) : (
        <SudokuContainer data-testid="SudokuContainer">
          <SudokuOverlayContainer overlay={overlay}>
            <SudokuGameContainer overlay={overlay}>
              <StatusSectionContainer>
                <SudokuGameBoardContainer>
                  <GameBoardContainer>
                    <tbody>
                      {rows.map((row) => {
                        return (
                          <GameBoardRow key={row}>
                            {rows.map((column) => {
                              const indexOfArray = row * 9 + column;
                              const value = gameArray[indexOfArray];

                              if (cellSelected === indexOfArray) {
                                return selectedCell(indexOfArray, value, true);
                              }

                              if (numberSelected !== '0' && isCellSameAsSelectedCell(row, column)) {
                                return selectedCell(indexOfArray, value, false);
                              } else {
                                return unselectedCell(indexOfArray, value);
                              }
                            })}
                          </GameBoardRow>
                        );
                      })}
                    </tbody>
                  </GameBoardContainer>
                </SudokuGameBoardContainer>
                <StatusContainer>
                  {createNumbers()}
                  <StatusOptions>{createActions()}</StatusOptions>
                  <GameButtonContainer>
                    {createDifficulty()}
                    <GameButton done onClick={onClickDone}>
                      {intl.formatMessage({ id: 'games.sudoku.game-done' })}
                    </GameButton>
                  </GameButtonContainer>
                </StatusContainer>
              </StatusSectionContainer>
            </SudokuGameContainer>
          </SudokuOverlayContainer>
          <SolvedContainer overlay={overlay}>
            <RudySolvedImageContainer>
              <RudySolvedImage
                alt={intl.formatMessage({ id: 'games.sudoku.game-solved' })}
                src={RudySolvedHeaderEn.includes(headerLocale) ? RudySolvedHeaderEn : RudySolvedHeaderEs}
              />
            </RudySolvedImageContainer>
            <SolvedButtonContainer>
              <GameButton data-testid={'Won_Done_Button'} done overlay onClick={onClickDone}>
                {intl.formatMessage({ id: 'games.sudoku.game-done' })}
              </GameButton>
              <GameButton data-testid={'Won_Again_Button'} overlay onClick={onClickNewGame}>
                {intl.formatMessage({ id: 'games.sudoku.game-again' })}
              </GameButton>
            </SolvedButtonContainer>
          </SolvedContainer>
        </SudokuContainer>
      )}
    </GameContainer>
  );
};

export default SudokuGame;
