import React from 'react';
import { useIntl } from 'react-intl';
import ResultsHeader from 'Core_Pages/Screening/Results/components/ResultsHeader/ResultsHeader';

const Header = () => {
  const intl = useIntl();
  return <ResultsHeader title={intl.formatMessage({ id: 'screenings.results.adult.header.depression' })} />;
};

export default Header;
