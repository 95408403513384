import React from 'react';
import { Grow, Grid } from '@mui/material';
import useFunFact from 'Core_Hooks/useFunFact';
import { FunFactContainer, FunFactHeader, FunFactContent } from './styles';
import { useIntl } from 'react-intl';
import { Interweave } from 'interweave';

const FunFactGrid = () => {
  const { funFact } = useFunFact();
  const intl = useIntl();

  return (
    <>
      {funFact && (
        <Grid mobile={10} tablet={6}>
          <FunFactContainer>
            <Grow in={true}>
              <FunFactHeader>{intl.formatMessage({ id: 'home.fun-fact' })}</FunFactHeader>
            </Grow>
            <Grow in={true}>
              <FunFactContent>
                <Interweave content={funFact.text} />
              </FunFactContent>
            </Grow>
          </FunFactContainer>
        </Grid>
      )}
    </>
  );
};

export default FunFactGrid;
