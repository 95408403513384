import { adminstratingMedication } from 'Assets/img/my-tools-icons';

export const administeringMedicationsEn = {
  key: 'administering-medications',
  title: 'Administering Medications',
  titleImage: adminstratingMedication,
  titleImageAlt: '',
  contentId: 26,
  sections: [
    {
      key: 'administering-medications-intro',
      description:
        'As a caregiver there are important things to know about administering medications. Reach out to your caregiver organization, the individual’s Legally Authorized Representative or their doctor or nurse to understand what you can and cannot do as their family member who is a caregiver or a paid caregiver. Medication prescribing and who can assess or administer medications also varies based on the state where you live. This article will provide a brief overview about what you need to know as a caregiver who is supporting an individual to receive their medications.',
    },
    {
      key: 'medications-overview',
      header: 'Medication Overview',
      description:
        'Medications have different categories based on their strength, possibility of dependency or addiction, and how they are administered. It is important to know the category of your medication for the following reason:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: '<b>Refills</b> – Understand which medications are eligible for a 90-day supply while others you can only refill every 30-days or for other lengths of time. ',
        },
        {
          text: '<b>Authorization</b> – It is important to know the length of time that doctors can authorize certain medications. For example, some controlled substances need a doctor’s authorization each time it is refilled, and it can take a few days to receive this authorization.',
        },
        {
          text: '<b>As Needed Medications</b> – As a caregiver you may not assess symptoms to determine when to offer as needed medications. As needed medications can only be given by the individual’s legally authorized representative or a medical provider who is licensed to assess and administer medications. ',
        },
        {
          text: '<b>Injectable</b> – If the individual you are caring for has a condition that requires injectable medications, such as diabetes, you need to know what you can and cannot do as a caregiver. Reach out to the individual’s doctor, their nurse or care manager, or your caregiver organization to receive training about the signs and symptoms of when the individual needs as needed medications and they ways you can support the individual self-administer the medication.',
        },
      ],
    },
    {
      key: 'organizing-medications',
      header: 'Organizing Medications',
      description:
        'As a caregiver, you are limited in what you can do to help organize the individual’s medications. What you can and cannot do can be dependent on the cognition functioning level of the individual. For example, if the individual not cognitively capable of making their own decisions and their Legally Authorized Representative is making their medical decisions, the individual is not able to organize their routine medication or request “as needed” medication. In these scenarios a doctor, pharmacist, nurse, or the individual’s legally authorized representative must organize the medications. Here are a few tips to what you can do as a caregiver to assist with the individual’s medication organization:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: '<b>Keep a List of All Medications on the Fridge</b> – Make sure to have an updated list of medications including name, type, usage, dose, and a picture (if it is a pill form) on the fridge. In the event of an emergency, first responders will look on the fridge to learn the individual’s medications and diagnoses. This is also helpful for any caregivers in the home or if you are speaking with one of the individual’s doctors. You can get this list from their doctor.',
        },
        {
          text: '<b>Pill boxes</b> – Typically nurses or the individual’s Legally Authorized Representative will fill weekly pill boxes for all routine (daily and twice daily) medications. As a caregiver you can offer reminders to take medications and can open and hand the pill box to the individual. You may not organize their medications or hand medications directly to the individual.',
        },
        {
          text: '<b>Automated medication dispensers and pill packs</b> – For medications that are eligible for auto refill and delivery, there are pill packs that the individual can receive in the mail (individually-labeled packets with medications based on the time of day) and medication dispensers (that a nurse or the Legally Authorized Representative will fill) that alerts the individual of when it is time to take the medications. Ask your doctor, nurse, or care manager if this could be a good fit for you.',
        },
      ],
    },
    {
      key: 'creating-calendar-schedule',
      header: 'Creating a Calendar or Schedule',
      description:
        'It is important to create a schedule or a calendar to track medication refills, “as needed” medications, condition-specific symptoms, or anything else requested from the individual’s care manager, nurse, or doctor.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: '<b>30-day Medication Refills</b> – Make sure you track and request a refill at least a week before the medication runs out. If you need a doctor’s authorization, be sure to add in a few extra days to receive the authorization.',
        },
        {
          text: '<b>Doctor Authorization Calendar</b> – Know when you need to see your doctor face-to-face for them to refill medications. Add this to the calendar as well.',
        },
        {
          text: '<b>90-day Medication Refills</b> – Ask the individuals nurse or care manager if they are eligible for auto-refill on their routine medications. If the medication cannot be auto-filled, be sure to request a refill at least two weeks before it runs out.',
        },
        {
          text: '<b>As Needed Medications</b> – Keep a calendar of when the individual took as needed medications and include the symptoms they were experiencing and the time of day they took it. Request a refill at least a week (estimated based on use) before the medication runs out.',
        },
      ],
    },
  ],
};
