import React from 'react';
import { Image } from 'react-konva';
import PropTypes from 'prop-types';
import useImage from 'use-image';

/*
  If the image you are adding is an SVG then you need to make sure you have a height
  and width in the svg file or they won't appear in Firefox 
*/
const DynamicImage = ({ rotation, image, x, y, width, height }) => {
  const [loadedImage] = useImage(image);

  return (
    <>
      <Image rotation={rotation} image={loadedImage} x={x} y={y} width={width} height={height} />
    </>
  );
};

DynamicImage.propTypes = {
  rotation: PropTypes.number,
  image: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default DynamicImage;
