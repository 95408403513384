import { smallTalk } from 'Assets/img/my-tools-icons';

export const smallTalkEn = {
  key: 'small-talk',
  title: 'Small Talk To Connect',
  titleImage: smallTalk,
  titleImageAlt: '',
  description:
    'Having short conversations with people you don’t know by using small talk to start can help build positive connections and create uplifting feelings!',
  contentId: 1,
  sections: [
    {
      key: 'stranger-conversation',
      header:
        'Select a place or situation you would be willing to try small talk to strike up a conversation with a stranger.',
      description: 'Think about where you would feel comfortable to try talking with someone you don’t know.',
      itemsHeader: 'Tell me more',
      itemsDescription:
        'Think about some places you typically visit where other people are, too. Select one where you feel comfortable going to or find it easy to be yourself when you’re there. Then think of the possible people you could meet there to try using small talk to start a conversation. Here are a few examples to consider:',
      items: [
        { text: 'Retail stores – with a clerk or other customer' },
        { text: 'Restaurants – with the wait staff; barista; another person eating there' },
        {
          text: 'Community areas – with a neighbor; at a dog park with another dog owner; another parent picking up their child at school',
        },
        { text: 'Sports or fitness centers – with another spectator; yoga studio' },
        { text: 'Special events – with conference attendees; party goers' },
        { text: 'Commuting in ride share or on public transport – with other riders on the bus or train' },
      ],
    },
    {
      key: 'stranger-write-down',
      header: 'Then, write down topics you could bring up with a stranger when you’re there.',
      description: 'Use paper and pen or type your ideas on a computer/device.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Think about a few easy things to keep in the back of your mind to say to someone you don’t know when you’re there.',
        },
        { text: 'It’s okay to start with just 1 or 2 things for now.' },
        {
          text: 'For example, think about small, obvious, or funny things you might notice such as',
          subItems: [
            { text: 'Shopping cart: What are you going to make with that (vegetable/ingredient)?' },
            { text: 'Clothes: What is the event you’re going to wear that outfit to?' },
            { text: 'General: How has your day been so far?' },
            { text: 'Weather: How are you doing in this weather?' },
            { text: 'Current: Where did you find the (Covid) face mask you’re using?' },
          ],
        },
        {
          text: 'Helpful Hint: Asking open-ended questions helps the other person respond in a more conversational or longer way, rather than asking them “Yes/No” questions.',
        },
        {
          text: 'To help keep the connection going, ask follow-up questions to the stranger’s answers rather than bringing up a new topic. Some examples of follow up questions to the questions above could be:',
          subItems: [
            { text: 'Follow up – Shopping cart: How do you like to prepare it?' },
            { text: 'Follow up - Clothes: Where will the event be held?' },
            { text: 'Follow up – General: It’s so good to hear when things go well for people.' },
            { text: 'Follow up – Weather: Remember to drink fluids!' },
            { text: 'Follow up – Current: How does it feel on your face?' },
          ],
        },
        { text: 'Small talk does not need to be long or highly clever.' },
        { text: 'What could you ask that is interesting to you?' },
        { text: 'What would you like to talk about?' },
        { text: 'What would you like to be asked by a stranger?' },
        { text: 'What are things you can share about yourself related to that situation?' },
      ],
    },
    {
      key: 'experiment',
      header: 'Go out and experiment!',
      description:
        'Consider when and where you could try striking up a conversation with a stranger in the next week. \n\nSet a time and place to try it out and give it a go!',
    },
    {
      key: 'self-reflection-insights',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your experience with this activity.',
      itemsHeader: 'Insights & pointers',
      items: [
        {
          text: 'How was it to talk to a stranger?',
        },
        { text: 'How did you feel immediately afterward?' },
        { text: 'Would you do it again?' },
        { text: 'What would be some benefits of doing this more often in general?' },
        { text: 'How do you feel before completing this exercise?' },
        { text: 'How do you feel after it?' },
        { text: 'What part(s) of this exercise did you find most challenging? Why?' },
        { text: 'What did you enjoy most about this exercise?', divider: true },
        {
          text: 'We spend much of our lives in the company of strangers and even small interactions can be valuable to boosting happiness and well-being.',
        },
        {
          text: 'Talking with someone you don’t know can help create uplifting feelings and encouragement as you go about your day.',
        },
        {
          text: 'Feeling socially connected helps create positive health benefits and can boost the quality of life experiences.',
        },
        {
          text: 'This week, try using small talk with a stranger to grow your social connections along your Thrive Pathway!',
        },
      ],
    },
  ],
};
