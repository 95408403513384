export const ACCOUNTDELETED = '/account/deleted';
export const ACCOUNTDELETEDISCLAIMER = '/account/delete-disclaimer';
export const ANDY = '/andy';
export const CHAT = '/chat';
export const GREETINGCARDS = '/greeting-cards';
export const GREETINGCARDSIMAGESELECTION = '/greeting-cards/image-selection';
export const GREETINGCARDSINBOX = '/greeting-cards/inbox';
export const GREETINGCARDSUBMISSION = '/greeting-cards/greeting-card-submission';
export const GREETINGCARDSMESSAGESELECTION = '/greeting-cards/message-selection';
export const DESIGNYOURCARD = '/greeting-cards/design-your-card';
export const LOGIN = '/account/login';
export const SIGNUP = '/account/signup';
export const HOME = '/';
export const MENU = '/menu';
export const NOTIFICATIONS = '/notification-settings';
export const POLICYCONTENT = '/policy-content';
export const PROFILE = '/profile';
export const REMINDERS = '/reminder';
export const REMINDERS_CREATE = '/reminder/create';
export const REMINDERS_EDIT = '/reminder/edit';
export const SNAKE = '/snake';
export const SUDOKU = '/sudoku';
export const THRIVEPATHWAY = '/thrive';
export const THRIVECONGRATS = '/thrive/congratulations';
export const THRIVEASSESSMENT = '/thrive/thrive-assessment';
export const THRIVEASSESSMENTRESULTS = `/thrive/assessment-results`;
export const THRIVEACTIVITIES = '/thrive/activities';
export const THRIVECONTENT = '/thrive/:thriveContentType';
export const STATICCONTENT = '/static-content/:contentType';
export const COLORINGBOOK = '/coloring-book';
export const COLORINGBOOKIMAGEPREVIEW = '/coloring-book-image-preview';
export const PETS = '/pets';
export const PETSHOME = '/pets/home';
export const PETSWELCOME = '/pets/welcome';
export const ADOPTIONCENTER = '/pets/adoption-center';
export const DOGADOPTION = '/pets/adoption-center/dog';
export const CATADOPTION = '/pets/adoption-center/cat';
export const PETSHELLO = '/pets/adoption-center/hello';
export const PETSVISITDAILY = '/pets/adoption-center/visit-daily';
export const ACTIVITYMENU = '/pets/game/activitymenu';
export const PETSGAME = '/pets/game/:petSceneType';
export const UNSIGNEDAGREEMENTS = '/unsigned-agreements';
export const MYTOOLS = '/my-tools/:toolType';
export const TALKTOSOMEONE = '/talk';
export const CHECKINS = '/check-ins';
export const ASSESSMENT = '/screenings/:screeningGuid?';
export const VIDEOS = '/videos/:id';
