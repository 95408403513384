import { createAsyncThunk, createSlice, isAllOf, isFulfilled } from '@reduxjs/toolkit';
import api from './services/api';
import identityApi from './services/identity';
import {
  LANGUAGE_SELECT,
  NAME,
  CREDENTIALS,
  PHONE,
  OTP,
  MATCHING_PROFILE,
  EMAIL,
  MATCHING_EMAIL,
  BIRTHDAY,
  HEALTH_PLAN_INFORMATION,
  PARTNER_CODE,
  AGREEMENTS,
  PRONOUN_MARITAL,
  PARENTAL_STATUS,
  FAVORITE_HOLIDAY,
  HOW_DID_YOU_HEAR_ABOUT_US,
} from 'Onboard_Components/Account/SignUp/steps';
import { sendAgreementAcknowledgement, sendUnsignedAgreementAcknowledgement } from './agreements';
import { callUpdateSyncUser } from './user';

const initialState = {
  history: [],
  initialStep: LANGUAGE_SELECT,
  loading: false,
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  phoneNumber: '',
  landlinePhoneNumber: '',
  phoneType: '',
  matchingProfile: false,
  userId: 0,
  emailAddress: '',
  birthday: null,
  healthPlanName: '',
  memberId: '',
  termsAndConditions: false,
  privacyPolicy: false,
  preferredPronouns: '',
  maritalStatus: '',
  isPregnant: false,
  pregnancyDueDate: '',
  parentalStatus: '',
  favoriteHoliday: '',
  howDidYouHearAboutUs: '',
};

const PROGRESS_INCREMENT = 7;

const getProgress = (state) => {
  let step = state.signup.history.length ? state.signup.history.slice(-1) : state.signup.initialStep;
  switch (step[0]) {
    case NAME:
      return PROGRESS_INCREMENT * 1;
    case CREDENTIALS:
      return PROGRESS_INCREMENT * 2;
    case PHONE:
      return PROGRESS_INCREMENT * 3;
    case MATCHING_PROFILE:
      return PROGRESS_INCREMENT * 4;
    case OTP:
      if (state.signup.phoneType === phoneTypes.LANDLINE) {
        return PROGRESS_INCREMENT * 5;
      } else {
        return PROGRESS_INCREMENT * 4;
      }
    case EMAIL:
      if (state.signup.phoneType === phoneTypes.LANDLINE) {
        return PROGRESS_INCREMENT * 4;
      } else {
        return PROGRESS_INCREMENT * 5;
      }
    case BIRTHDAY:
      return PROGRESS_INCREMENT * 6;
    case HEALTH_PLAN_INFORMATION:
      return PROGRESS_INCREMENT * 7;
    case PARTNER_CODE:
      return PROGRESS_INCREMENT * 8;
    case AGREEMENTS:
      return PROGRESS_INCREMENT * 9;
    case PRONOUN_MARITAL:
      return PROGRESS_INCREMENT * 10;
    case PARENTAL_STATUS:
      return PROGRESS_INCREMENT * 11;
    case FAVORITE_HOLIDAY:
      return PROGRESS_INCREMENT * 12;
    case HOW_DID_YOU_HEAR_ABOUT_US:
      return PROGRESS_INCREMENT * 13;
    default:
      return 0;
  }
};

export const phoneTypes = {
  MOBILE: 'MOBILE',
  LANDLINE: 'LANDLINE',
};
export const signupStatuses = {
  UPDATED: 'UPDATED',
  REGISTERED: 'REGISTERED',
  COMPLETED: 'COMPLETED',
};

export const completeSignup = createAsyncThunk('signup/completeSignup', async ({ userId }, thunkApi) => {
  const { signup } = thunkApi.getState();
  const patchObject = {
    pronouns: signup.preferredPronouns,
    maritalStatus: signup.maritalStatus,
    isPregnant: signup.isPregnant,
    pregnancyDueDate: !!signup.pregnancyDueDate ? new Date(signup.pregnancyDueDate) : null,
    parentalStatus: signup.parentalStatus,
    favoriteHoliday: signup.favoriteHoliday,
    healthPlanName: signup.healthPlanName,
  };
  thunkApi.dispatch(callUpdateSyncUser({ userId, patchObject }));
});

export const selectStep = (state) =>
  state.signup.history.length ? state.signup.history.slice(-1) : state.signup.initialStep;
export const selectName = (state) => ({ firstName: state.signup.firstName, lastName: state.signup.lastName });
export const selectCredentials = (state) => ({ username: state.signup.username, password: state.signup.password });
export const selectContact = (state) => ({
  phoneNumber: state.signup.phoneNumber,
  landlinePhoneNumber: state.signup.landlinePhoneNumber,
  phoneType: state.signup.phoneType,
  emailAddress: state.signup.emailAddress,
});
export const selectSignupMetadata = (state) => ({
  progress: getProgress(state),
  loading: state.signup.loading,
  initialStep: state.signup.initialStep,
});
export const selectUserId = (state) => ({ userId: state.signup.userId });
export const selectBirthday = (state) => ({ birthday: state.signup.birthday });
export const selectAboutYouInfo = (state) => ({
  preferredPronouns: state.signup.preferredPronouns,
  maritalStatus: state.signup.maritalStatus,
  isPregnant: state.signup.isPregnant,
  pregnancyDueDate: state.signup.pregnancyDueDate,
  parentalStatus: state.signup.parentalStatus,
  favoriteHoliday: state.signup.favoriteHoliday,
  healthPlanName: state.signup.healthPlanName,
});
export const selectSignupStatus = (state) => ({ status: state.signup.status });

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    completeLanguageSelection: (state) => {
      state.loading = true;
      state.history.push(NAME);
      state.loading = false;
    },
    completeNameEntry: (state, action) => {
      state.loading = true;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.history.push(CREDENTIALS);
      state.loading = false;
    },
    completeCredentialsEntry: (state, action) => {
      state.loading = true;
      state.username = action.payload.username;
      state.password = action.payload.password;
      state.history.push(PHONE);
      state.loading = false;
    },
    completeBirthdayEntry: (state, action) => {
      state.loading = true;
      state.birthday = action.payload.birthday;
      state.history.push(HEALTH_PLAN_INFORMATION);
      state.loading = false;
    },
    completePhoneEntry: (state, action) => {
      state.phoneNumber = action.payload.phoneNumber;
      state.landlinePhoneNumber = action.payload.landlinePhoneNumber;
      state.phoneType = action.payload.phoneType;

      if (state.phoneType === phoneTypes.LANDLINE) {
        state.history.push(EMAIL);
      }
    },
    goToBirthday: (state) => {
      state.history.push(BIRTHDAY);
    },
    goToMatchingEmailEntry: (state) => {
      state.history.push(MATCHING_EMAIL);
    },
    completeEmailEntry: (state, action) => {
      state.emailAddress = action.payload.emailAddress;

      if (state.phoneType === phoneTypes.LANDLINE) {
        state.history.push(OTP);
      }
    },
    skipEmailEntry: (state) => {
      const nextStep = state.phoneType === phoneTypes.LANDLINE ? OTP : BIRTHDAY;
      state.history.push(nextStep);
    },
    completeHealthPlanEntry: (state, action) => {
      state.healthPlanName = action.payload?.healthPlanName;
      state.memberId = action.payload.memberId;
    },
    skipHealthPlanEntry: (state) => {
      state.history.push(PARTNER_CODE);
    },
    completePronounMaritalEntry: (state, action) => {
      state.preferredPronouns = action.payload?.preferredPronouns;
      state.maritalStatus = action.payload?.maritalStatus;
      state.history.push(PARENTAL_STATUS);
    },
    skipPronounMarital: (state) => {
      state.history.push(PARENTAL_STATUS);
    },
    completeParentalStatusEntry: (state, action) => {
      state.isPregnant = action.payload?.isPregnant;
      state.pregnancyDueDate = action.payload?.pregnancyDueDate;
      state.parentalStatus = action.payload?.parentalStatus;
      state.history.push(FAVORITE_HOLIDAY);
    },
    skipParentalStatus: (state) => {
      state.history.push(FAVORITE_HOLIDAY);
    },
    completeFavoriteHolidayEntry: (state, action) => {
      state.favoriteHoliday = action.payload?.favoriteHoliday;
    },
    startHowDidYouHearAboutUsSurvey: (state) => {
      state.history.push(HOW_DID_YOU_HEAR_ABOUT_US);
    },
    completeHowDidYouHearAboutUs: (state, action) => {
      state.howDidYouHearAboutUs = action.payload?.howDidYouHearAboutUs;
      state.healthPlanName = action.payload?.healthPlanName;
    },
    startWelcomeBackPhoneFlow: (state, action) => {
      state.initialStep = PHONE;
      state.history = [];
      state.userId = action.payload.sub;
      state.firstName = action.payload.given_name;
      state.lastName = action.payload.family_name;
      state.history.push(PHONE);
    },
    startWelcomeBackOnboardingFlow: (state, action) => {
      state.initialStep = BIRTHDAY;
      state.history = [];
      state.userId = action.payload.sub;
      state.history.push(BIRTHDAY);
    },
    startMatchingProfileEntry: (state, action) => {
      state.matchingProfile = true;
      state.phoneNumber = action.payload.phoneNumber;
      state.landlinePhoneNumber = action.payload.landlinePhoneNumber;
      state.phoneType = action.payload.phoneType;
      state.history.push(MATCHING_PROFILE);
    },
    navigateBack: (state) => {
      state.history.pop();
    },
    resetSignup: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(identityApi.endpoints.createAccount.matchFulfilled, (state, action) => {
      state.userId = action.payload;
      state.status = signupStatuses.REGISTERED;

      if (state.phoneType === phoneTypes.LANDLINE) {
        state.history.push(EMAIL);
      }
    });
    builder.addMatcher(identityApi.endpoints.userInfo.matchFulfilled, (state) => {
      const curStep = state.history.length ? state.history.slice(-1)[0] : state.initialStep;

      if (
        state.status === signupStatuses.REGISTERED &&
        (curStep === PHONE || curStep === MATCHING_PROFILE) &&
        state.phoneType === phoneTypes.MOBILE
      ) {
        state.history.push(OTP);
      }
    });
    builder.addMatcher(identityApi.endpoints.updateAccount.matchFulfilled, (state) => {
      state.status = signupStatuses.UPDATED;
      const nextStep = state.phoneType === phoneTypes.LANDLINE ? EMAIL : state.emailAddress ? BIRTHDAY : OTP;

      state.history.push(nextStep);
    });
    builder.addMatcher(identityApi.endpoints.verifyOTP.matchFulfilled, (state) => {
      const nextStep = state.phoneType === phoneTypes.LANDLINE ? BIRTHDAY : EMAIL;
      state.history.push(nextStep);
    });
    builder.addMatcher(api.endpoints.getAgreements.matchFulfilled, (state, action) => {
      if (action.payload.agreementList.length > 0) {
        state.history.push(AGREEMENTS);
      }
    });
    builder.addMatcher(isAllOf(isFulfilled(callUpdateSyncUser), completeSignup), (state) => {
      state.status = signupStatuses.COMPLETED;
    });
    builder.addMatcher(isFulfilled(sendUnsignedAgreementAcknowledgement), (state) => {
      state.status = signupStatuses.COMPLETED;
    });
    builder.addMatcher(isFulfilled(sendAgreementAcknowledgement), (state) => {
      state.history.push(PRONOUN_MARITAL);
    });
  },
});

export const {
  completeLanguageSelection,
  completeNameEntry,
  completeCredentialsEntry,
  completePhoneEntry,
  startWelcomeBackOnboardingFlow,
  goToMatchingEmailEntry,
  completeEmailEntry,
  skipEmailEntry,
  completeBirthdayEntry,
  completeHealthPlanEntry,
  skipHealthPlanEntry,
  completePronounMaritalEntry,
  skipPronounMarital,
  completeParentalStatusEntry,
  skipParentalStatus,
  completeFavoriteHolidayEntry,
  startHowDidYouHearAboutUsSurvey,
  completeHowDidYouHearAboutUs,
  navigateBack,
  startWelcomeBackPhoneFlow, // Temporary as testing workaround for PYX-4499
  startMatchingProfileEntry,
  resetSignup,
} = signupSlice.actions;

export default signupSlice.reducer;
