import React from 'react';
import PropTypes from 'prop-types';
import { StyledProgressBar } from '../styles';
import { useIntl } from 'react-intl';

function SignUpProgressBar({ progressValue }) {
  const intl = useIntl();

  return (
    <StyledProgressBar
      variant="determinate"
      aria-label={intl.formatMessage({ id: 'account.sign-up-progress' })}
      value={progressValue}
    ></StyledProgressBar>
  );
}

SignUpProgressBar.propTypes = {
  progressValue: PropTypes.number,
};

export default SignUpProgressBar;
