import { createSlice, isFulfilled } from '@reduxjs/toolkit';
import { appInsights } from 'Onboard_Helpers/AppInsights';
import { logout } from './authentication';
import api from './services/api';

const initialState = {
  networkGroupConfig: null,
};

const networkGroup = createSlice({
  name: 'networkGroup',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getNetworkGroupConfig.matchFulfilled, (state, action) => {
      state.networkGroupConfig = action.payload;
    });
    builder.addMatcher(api.endpoints.getNetworkGroupConfig.matchRejected, (state, action) => {
      appInsights.trackException({ exception: action.error });
    });
    builder.addMatcher(isFulfilled(logout), () => initialState);
  },
});

export const selectNetworkGroupConfig = (state) => state.networkGroup.networkGroupConfig;
export default networkGroup.reducer;
