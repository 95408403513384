import { mindfulnessBalance } from 'Assets/img/my-tools-icons';

export const mindfulnessBalanceEn = {
  key: 'mindfulness-balance',
  title: 'Try mindfulness for emotional balance',
  titleImage: mindfulnessBalance,
  titleImageAlt: '',
  contentId: 23,
  sections: [
    {
      key: 'mindfulness-balance-intro',
      hasNoSectionMargin: true,
      description:
        'Mindfulness is a useful tool to help remain calm and balanced regardless of what’s happening. Simply put, mindfulness means being in the “here and now” and allowing things to be as they are – the thoughts, feelings, and emotions we perceive, both inside and out – without judging them.\n\nFor example, without judgement, the emotional ‘charge’ of anger becomes neutral. Its negative impact can pass through more easily because there is no need to react to it. It’s just a state of energy. And energy flows all around us.\n\nPracticing mindfulness is known to have a positive impact on self-esteem. It allows us to step back, quiet nerves, detach from stress, and shift into a more helpful state of mind. It can be done by anyone at any time – while driving, walking, swimming, even just brushing your teeth. While there are many ways to use it, mindful breathing is a well-known building block of this practice and an easy place to start. Here is a simple exercise to introduce what mindfulness can look and feel like.',
    },
    {
      key: 'mindfulness-balance-moments',
      header: 'Try a mindful moment',
      bulletedItems: [
        {
          text: 'Stop whatever you are doing and take a moment check in with your attention. Where is it? Where are your thoughts?',
        },
        {
          text: 'Take a deep breath, release your thoughts, and bring your attention to the here and now.',
        },
        {
          text: 'Put your attention on your breathing.',
        },
        {
          text: 'Take a few moments to focus on it. Feel how it flows in and out. Notice how your stomach rises and falls with each breath you take. Focus on just breathing for a while longer.',
        },
        {
          text: 'Then, become aware of your feelings and thoughts. Simply observe and listen in a judgment-free way.',
        },
        {
          text: 'What emotions do you feel in this moment? Simply accept them as they are. Let go of any judgment or ‘charge’ you might be feeling about them. Just recognize they are there, and let them move to the side.',
        },
        {
          text: 'Just be present in this moment. You are safe. You are settling your nervous system.',
        },
        {
          text: 'If you catch your mind wandering, that’s okay. Simply return your focus to your breath coming in and out of your body.',
        },
        {
          text: 'Focus on you in this moment.',
        },
        {
          text: 'You are centering yourself.',
        },
        {
          text: 'Take a few quiet minutes to settle and balance.',
        },
        {
          text: 'Keep your attention on the present moment.',
        },
        {
          text: 'Your mind and body are helping you reclaim your balance.',
        },
        {
          text: 'Let any stressors fall away without reaction.',
        },
        {
          text: 'Feel yourself becoming more balanced and centered in your being.',
        },
        {
          text: 'Continue breathing and focus on yourself in this moment.',
        },
        {
          text: 'Thank yourself for this self-awareness of well-being.',
        },
        {
          text: 'Thank your stressors for reminding you about yourself.',
        },
        {
          text: 'Know you can take a mindful moment and enter your “mindful body” anytime, anywhere.',
        },
      ],
    },
    {
      key: 'mindfulness-balance-grand-finale',
      description:
        'Practicing mindfulness helps show us what is happening in our bodies, our emotions, and our minds. It is a helpful tool to deal with stress and respond to it in more positive ways rather than emotionally reacting.',
    },
  ],
};
