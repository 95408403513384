import { useEffect } from 'react';
import { appInsights } from 'Core_Helpers/AppInsights';

const useAppClosed = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', async () => {
      appInsights.trackEvent({ name: 'App_Closed' });
    });
  }, []);
};

export default useAppClosed;
