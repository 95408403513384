import React from 'react';
import PropTypes from 'prop-types';
import GoogleFontIcon from 'Core_Components/GoogleFontIcon/GoogleFontIcon';
import { StyledIndicatorContainer } from './styles';

function ReminderStatusIndicator({ isActive }) {
  return (
    <StyledIndicatorContainer isActive={isActive}>
      <GoogleFontIcon name={GoogleFontIcon.Name.Check} />
    </StyledIndicatorContainer>
  );
}

ReminderStatusIndicator.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default ReminderStatusIndicator;
