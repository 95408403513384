import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import TimeField from './TimeField/TimeField';
import { StyledAddTimeButton } from './styles';

function TimeConfiguration({ control, watch, getValues, setValue }) {
  const intl = useIntl();

  const reminderTime3 = watch('reminderTime3');
  const reminderTime4 = watch('reminderTime4');
  const reminderTime5 = watch('reminderTime5');
  const reminderTime6 = watch('reminderTime6');

  function addReminderTime() {
    const values = getValues();
    for (let i = 2; i <= 6; i++) {
      const time = `reminderTime${i}`;
      if (!values[time]) {
        setValue(time, TimeField.DefaultTime);
        return;
      }
    }
  }

  return (
    <>
      <TimeField reminderNumber={1} control={control} required />
      <TimeField reminderNumber={2} control={control} required={!!reminderTime3} />
      <TimeField reminderNumber={3} control={control} required={!!reminderTime4} />
      <TimeField reminderNumber={4} control={control} required={!!reminderTime5} />
      <TimeField reminderNumber={5} control={control} required={!!reminderTime6} />
      <TimeField reminderNumber={6} control={control} />
      {!reminderTime6 && (
        <StyledAddTimeButton type="button" onClick={addReminderTime}>
          {intl.formatMessage({ id: 'reminders.add-reminder-time' })}
        </StyledAddTimeButton>
      )}
    </>
  );
}

TimeConfiguration.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default TimeConfiguration;
