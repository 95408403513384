import React from 'react';
import { useIntl } from 'react-intl';
import {
  StyledCongratsImage,
  StyledContainer,
  StyledTextContainer,
  StyledCircularProgress,
} from 'Core_Pages/Thrive/ThriveCongratulations/styles';
import { StyledBoldParagraphText, StyledButton, StyledParagraphText } from 'Core_Pages/Thrive/featureStyles';
import congratsImageEn from 'Assets/img/thrive-congrats-en.svg';
import congratsImageEs from 'Assets/img/thrive-congrats-es.svg';
import { selectSyncUser } from 'Core_Redux/user';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { useLogThriveCongratsMutation } from 'Core_Redux/services/api';
import { nextPage } from 'Core_Redux/thrive';
import { useHistory } from 'react-router-dom';

//intl.locale returns ISO 639-1 codes, if adding more locale reference: https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
const imageToLocaleConfig = {
  en: congratsImageEn,
  es: congratsImageEs,
};

function ThriveCongratulations() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useCoreDispatch();
  const { userId, firstName } = useCoreSelector(selectSyncUser);
  const [logThriveCongrats, { isLoading: isLoggingShownCongrats }] = useLogThriveCongratsMutation();
  const locale = intl.locale.split('-')[0] ?? 'en';

  const onNext = () => dispatch(nextPage({ history }));

  const handleButtonClick = async () => {
    await logThriveCongrats({ userId });
    onNext();
  };

  return (
    <StyledContainer>
      <StyledCongratsImage
        alt={intl.formatMessage({ id: 'image.congrats-message' })}
        src={imageToLocaleConfig[locale] ?? congratsImageEn}
      />
      <StyledTextContainer>
        <StyledBoldParagraphText>
          {intl.formatMessage({ id: 'thrive.completed-thrive-pathway' })} {`${firstName}!`}
        </StyledBoldParagraphText>
        <StyledParagraphText>{intl.formatMessage({ id: 'thrive.support' })}</StyledParagraphText>
        <StyledParagraphText>{intl.formatMessage({ id: 'thrive.proud' })}</StyledParagraphText>
      </StyledTextContainer>
      {isLoggingShownCongrats ? (
        <StyledCircularProgress data-testid="CongratsCircularProgress" />
      ) : (
        <StyledButton onClick={handleButtonClick}>{intl.formatMessage({ id: 'common.continue' })}</StyledButton>
      )}
    </StyledContainer>
  );
}

export default ThriveCongratulations;
