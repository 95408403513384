import styled from 'styled-components';
import { Orientation } from 'Libraries/screen-orientation/index';

export const StyledWarningOverlay = styled.div`
  display: none;

  @media (orientation: landscape) {
    position: fixed;
    display: flex;
    z-index: 3;
    height: 100%;
    padding-top: 15%;
    text-align: center;
    justify-content: center;
    width: 100%;
    color: black;
    font-weight: 700;
    font-size: 1.25rem;
    background-color: white;
  }
`;

export const StyledOrientation = styled(Orientation)`
  margin: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
`;

export const StyledOrientationWarning = styled(Orientation)`
  display: none;
  @media (orientation: landscape) {
    margin: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    z-index: 2;
    position: fixed;
  }
`;
