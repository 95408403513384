import { animationType } from 'Core_Games/constants/animationType';
import { sceneType } from 'Core_Games/constants/sceneType';
import { uiTypes } from 'Core_Games/constants/uiTypes';
import { SingleAction } from '../SingleAction';
import { keySceneDataTypes, nutritionConfig } from './nutritionConfig';
import { findUI } from 'Core_Games/Scripts/configHelper';
import { addCharacterActor } from 'Core_Games/Scripts/characterHelper';
import { playerActorType } from 'Core_Games/constants/playerActorType';
import { onClickActivityMenu, onClickGoToRoute } from 'Core_Games/Scripts/buttonHelper';
import { STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';
import { LogInteraction, interactionExceedsLimit } from '../../../Scripts/interactionHelper';

export class Nutrition extends SingleAction {
  constructor() {
    super(sceneType.NUTRITION.NAME);

    this.background = undefined;
    this.speak = undefined;

    this.timerFeedAgain = undefined;
    this.timerDropProjectileActive = false;

    this.finishedLoading = false;
    this.bowlScale = 0.4;
    this.nutrientScale = 0.425;
  }

  init(sceneData) {
    this.sceneData = sceneData;
    this.nutritionConfig = nutritionConfig;

    let feedButton = findUI(this, this.nutritionConfig, uiTypes.BUTTONROW, keySceneDataTypes.FEED_BUTTON);
    let againButton = findUI(this, this.nutritionConfig, uiTypes.BUTTONROW, keySceneDataTypes.AGAIN_BUTTON);
    let iamButton = findUI(this, this.nutritionConfig, uiTypes.BUTTONROW, keySceneDataTypes.IAM_BUTTON);
    let noThanksButton = findUI(this, this.nutritionConfig, uiTypes.BUTTONROW, keySceneDataTypes.DONE_BUTTON);
    let sureButton = findUI(this, this.nutritionConfig, uiTypes.BUTTONROW, keySceneDataTypes.NOT_REALLY_BUTTON);
    let outroButton = findUI(this, this.nutritionConfig, uiTypes.BUTTONROW, keySceneDataTypes.OUTRO_BUTTON);
    let backButton = findUI(this, this.nutritionConfig, uiTypes.BUTTONROW, keySceneDataTypes.BACK_BUTTON);

    // Attach Button Functions
    feedButton.ONCLICK = () => this.feed(this, this.pet);
    againButton ? (againButton.ONCLICK = () => this.feed(this, this.pet)) : againButton;
    iamButton ? (iamButton.ONCLICK = () => this.displayOutroScreen(this)) : iamButton;
    noThanksButton ? (noThanksButton.ONCLICK = () => this.displayCareScreen(this)) : noThanksButton;
    outroButton.ONCLICK = () => {
      onClickActivityMenu(sceneData.appData.history);
    };

    //TODO this may change following My Tools implementation; also changes in activity menu will affect how we link to pages
    sureButton
      ? (sureButton.ONCLICK = () =>
          onClickGoToRoute(sceneData.appData.history, STATICCONTENT, { contentType: 'mindfuleating' }))
      : sureButton;

    backButton.ONCLICK = () => {
      onClickActivityMenu(sceneData.appData.history);
    };

    super.init(sceneType.NUTRITION, this.nutritionConfig, sceneData);

    this.gainNutritientsAnimation = this.currentSceneConfig.NUTRITIONANIMATIONTYPE ?? animationType.EAT;

    this.idlePetNutientsAnimation = this.currentSceneConfig.IDLENUTRITIONANIMATION;

    this.nutrientIdleAnimation =
      this.currentSceneConfig.NUTRITIONANIMATIONTYPE == animationType.EAT ? animationType.IDLE : animationType.IDLE2;

    this.bendNutrientsAnimation =
      this.currentSceneConfig.NUTRITIONANIMATIONTYPE == animationType.EAT
        ? animationType.SITBENDEAT
        : animationType.SITBENDDRINK;

    this.bendNutrientsFinishAnimation =
      this.currentSceneConfig.NUTRITIONANIMATIONTYPE == animationType.EAT
        ? animationType.SITBENDEATFINISH
        : animationType.SITBENDDRINKFINISH;

    this.watchImmediately = this.currentSceneConfig.WATCHIMMEDIATELY;
    this.adjustForAnimationOffset = this.currentSceneConfig.ADJUSTFORANIMATIONOFFSET;

    this.petOffsetY = this.currentSceneConfig.PETOFFSETY ?? 0.0;
    this.petFinishedOffsetX = this.currentSceneConfig.PETFINISHEDOFFSETX ?? 1;
    this.petFinishedOffsetY = this.currentSceneConfig.PETFINISHEDOFFSETY ?? 1;
    this.nutrientOffsetX = this.currentSceneConfig.NUTRIENTOFFSETX ?? 1;
    this.bowlOffsetX = this.currentSceneConfig.BOWLOFFSETX ?? 1;
    this.bowlOffsetY = this.currentSceneConfig.BOWLOFFSETY ?? 1;
    this.bowlScale = this.currentSceneConfig.BOWLSCALE ?? 0.4;
  }

  preload() {
    super.preload();
  }

  create() {
    let _this = this;

    super.create();

    // set initial parameters
    this.engine.engineAssets.load.on(Phaser.Loader.Events.COMPLETE, function () {
      // create timers
      _this.timerFeedAgain = new Phaser.Time.TimerEvent({ delay: 4000 });
      _this.time.addEvent(_this.timerFeedAgain);

      // pet
      _this.pet.play(_this.idlePetNutientsAnimation);

      _this.bowl = addCharacterActor(_this, 0, 0, playerActorType.BOWL.NAME);
      _this.bowl.scaleActor(_this.bowlScale);
      _this.bowl.play(_this.nutrientIdleAnimation);

      _this.nutrientContainer = addCharacterActor(_this, 0, 0, playerActorType.NUTRIENTCONTAINER.NAME);
      _this.nutrientContainer.scaleActor(_this.nutrientScale);
      _this.nutrientContainer.play(_this.nutrientIdleAnimation);
      _this.nutrientContainer.alpha = 0;

      // set positions
      _this.pet?.body?.setEnable(false); // turn off body so we can offset it

      // set config options
      _this.bowl.flipX = _this.isFacingRight;
      _this.background.depth = -2;
      _this.bowl.depth = -1;

      // configure locations
      _this.bowlStartX =
        _this.startLocationX - (_this.isFacingRight ? -1 : 1) * _this.bowlOffsetX * (_this.pet.displayWidth * 0.3);
      _this.bowlStartY = _this.startLocationY + _this.bowlOffsetY * _this.pet.displayHeight * 0.5;

      _this.nutrientStartX =
        _this.bowlStartX -
        (_this.isFacingRight ? -1 : 1) * _this.nutrientOffsetX * _this.nutrientContainer.displayWidth * 0.33;
      _this.nutrientStartY = _this.bowlStartY - _this.nutrientContainer.displayHeight * 0.7;

      // When the pet finishes eating/drinking etc, change the location to this, relative to the bowl/pet
      _this.nutrientFinishedLocationX = _this.adjustForAnimationOffset
        ? _this.bowlStartX + (_this.isFacingRight ? -1 : 1) * _this.petFinishedOffsetX * _this.bowl.displayWidth * 0.25
        : _this.startLocationX -
          (_this.isFacingRight ? -1 : 1) * _this.petFinishedOffsetX * _this.pet.displayWidth * 0.3;

      _this.nutrientFinishedLocationY =
        _this.bowlStartY -
        _this.bowl.displayHeight * 0.8 -
        _this.petOffsetY * _this.pet.displayHeight * _this.petFinishedOffsetY;

      // update bowl, pet, nutrient container to newly configured locations
      _this.nutrientContainer.x = _this.nutrientStartX;
      _this.nutrientContainer.y = _this.nutrientStartY;
      _this.pet.x = _this.startLocationX;
      _this.bowl.x = _this.bowlStartX;
      _this.bowl.y = _this.bowlStartY;

      // ui manager
      _this.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_INTRO);
      _this.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.INTRO_CAPTION);

      _this.finishedLoading = true;

      // physics overlap logic
      _this.addEventLogic(_this, _this.pet, _this.nutrientContainer, _this.bowl);
    });
  }

  update() {
    super.update();

    if (this.finishedLoading) {
      if (
        this.timerFeedAgainActive &&
        this.timerFeedAgain.getProgress() >= 0.7 &&
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_CARE &&
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_OUTRO
      ) {
        this.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.AGAIN_CAPTION);
        this.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_AGAIN);
        this.engine.engineUi.buttonManager.update(keySceneDataTypes.DONE_BUTTON, false);
        this.engine.engineUi.buttonManager.update(keySceneDataTypes.AGAIN_BUTTON, false);
      }
      // update UI
      else if (
        (this.actionTimes >= this.actionLimit || interactionExceedsLimit(this)) &&
        !this.feedIsActive &&
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_OUTRO &&
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_CARE &&
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_BACK
      ) {
        if (
          this.engine.engineUi.buttonManager.screenExists(keySceneDataTypes.SCREEN_BACK) &&
          interactionExceedsLimit(this) &&
          this.actionTimes == 0
        ) {
          this.displayBackScreen(this);
        } else if (this.engine.engineUi.buttonManager.screenExists(keySceneDataTypes.SCREEN_CARE)) {
          this.displayCareScreen(this);
        } else {
          this.displayOutroScreen(this);
        }
      }
    }
  }

  // Scene-Specific Functions

  // Add Collision & event logic
  addEventLogic = (game, actor, nutrientContainer, bowl) => {
    actor.on(Phaser.Animations.Events.ANIMATION_COMPLETE, function () {
      if (actor.animationIsActive(game.idlePetNutientsAnimation) && game.feedIsActive) {
        if (game.isFacingRight) {
          game.pet.flipX = !game.pet.flipX;
        }
        actor.play(animationType.WATCH);
      } else if (actor.animationIsActive(game.bendNutrientsAnimation) && game.feedIsActive) {
        actor.play(game.gainNutritientsAnimation);
        bowl.playReverse(game.gainNutritientsAnimation);
        actor.x = game.nutrientFinishedLocationX;
        actor.y = game.nutrientFinishedLocationY;
      } else if (actor.animationIsActive(game.bendNutrientsFinishAnimation) && game.feedIsActive) {
        actor.x = game.startLocationX;
        actor.y = game.startLocationY;
      }
    });
    nutrientContainer.on(Phaser.Animations.Events.ANIMATION_COMPLETE, function () {
      if (nutrientContainer.animationIsActive(game.gainNutritientsAnimation) && game.feedIsActive) {
        nutrientContainer.alpha = 0;
        if (game.isFacingRight && game.pet.animationIsActive(animationType.WATCH)) {
          game.pet.flipX = !game.pet.flipX;
        }
        actor.play(game.bendNutrientsAnimation);
        actor.x = game.startLocationX;
        actor.y = game.startLocationY;
        game.foodIsPoured = true;
      }
    });
    bowl.on(Phaser.Animations.Events.ANIMATION_COMPLETE, function () {
      if (bowl.animationIsActive(game.gainNutritientsAnimation) && game.feedIsActive && game.foodIsPoured) {
        actor.play(game.bendNutrientsFinishAnimation);
        if (game.engine.engineUi.captionManager.captionExists(keySceneDataTypes.FEED_END_CAPTION)) {
          game.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.FEED_END_CAPTION);
        }
        game.time.addEvent(game.timerFeedAgain);
        game.timerFeedAgainActive = true;
        actor.x = game.startLocationX;
        actor.y = game.startLocationY;
        game.feedIsActive = false;
      }
    });
  };

  feed = (game) => {
    game.actionTimes++;
    if (game.pet.animationIsActive(game.idlePetNutientsAnimation) && !game.watchImmediately) {
      game.pet.playReverse(game.idlePetNutientsAnimation);
    } else {
      if (game.isFacingRight) {
        game.pet.flipX = !game.pet.flipX;
      }
      game.pet.play(animationType.WATCH);
    }

    game.feedIsActive = true;
    game.timerFeedAgainActive = false;
    game.foodIsPoured = false;

    game.nutrientContainer.alpha = 1;
    game.nutrientContainer.play(game.gainNutritientsAnimation);
    game.bowl.play(game.gainNutritientsAnimation);

    game.engine.engineUi.captionManager.toggleCaption(true);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.FEED_BUTTON, true);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.DONE_BUTTON, true);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.AGAIN_BUTTON, true);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.IAM_BUTTON, true);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.NOT_REALLY_BUTTON, true);

    LogInteraction(game, game.sceneData?.sceneType);
  };

  displayOutroScreen = (game) => {
    game.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_OUTRO);
    game.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.OUTRO_CAPTION);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.OUTRO_BUTTON, false);
  };

  displayCareScreen = (game) => {
    game.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_CARE);
    game.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.CARE_CAPTION);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.IAM_BUTTON, false);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.NOT_REALLY_BUTTON, false);
  };

  displayBackScreen = (game) => {
    game.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_BACK);
    game.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.BACK_CAPTION);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.BACK_BUTTON, false);
  };
}
