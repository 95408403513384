import { THRIVEPATHWAY, THRIVEACTIVITIES, THRIVECONGRATS } from 'Core_Pages/Routes/RoutesConfig';
import { thriveWorkflowTypes } from '../enums';
import { thriveContentTypes, thriveContentUrls } from './thriveContent';

export const workflowTypes = {
  WELCOME: 'WELCOME',
  CONGRATS: 'CONGRATS',
};

export const workflows = {
  [thriveWorkflowTypes.DEFAULT]: {
    steps: {
      [THRIVEPATHWAY]: THRIVEACTIVITIES,
    },
  },
  [workflowTypes.CONGRATS]: {
    steps: {
      [THRIVEPATHWAY]: THRIVECONGRATS,
    },
  },
  [workflowTypes.WELCOME]: {
    steps: {
      [THRIVEPATHWAY]: thriveContentUrls[thriveContentTypes.OVERVIEW],
    },
  },
};
