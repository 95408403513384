import { supportingMentalHealthNeeds } from 'Assets/img/my-tools-icons';

export const supportingIndividualsMentalHealthEs = {
  key: 'supporting-individuals-mental-health-es',
  title: 'Apoyar las necesidades de salud mental',
  titleImage: supportingMentalHealthNeeds,
  titleImageAlt: '',
  contentId: 40,
  sections: [
    {
      key: 'supporting-individuals-mental-health-intro-es',
      description:
        'Las necesidades de salud mental se presentan en diferentes formas y tamaños. Varían en tipo y gravedad. En este artículo se explican algunas de las formas en las que puedes apoyar a las personas a tu cuidado que tienen necesidades de salud mental, como depresión o ansiedad.',
    },
    {
      key: 'reach-out-for-help-es',
      header: 'Cuándo pedir ayuda',
      description:
        'Si la persona a la que cuidas ha demostrado alguno de los siguientes síntomas, debes comunicarte con su médico, gerente de prestaciones médicas o proveedor de salud mental para obtener ayuda: ',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Expresar que siente que no vale nada o querer lastimarse a sí misma o a alguien más',
        },
        {
          text: 'Permanecer en la cama todo el día (para aquellos que no están postrados) o dormir más a menudo y tener mucha menos energía',
        },
        { text: 'Demostrar poco interés o placer en hacer cosas' },
        { text: 'Moverse excesivamente con comportamientos nerviosos' },
      ],
    },
    {
      key: 'general-mental-health-tips-es',
      header: 'Consejos generales de salud mental',
      description:
        'Navegar por las necesidades de salud mental de otra persona, en especial de una a la que amas o cuidas, es un desafío. A veces, sus necesidades pueden ser difíciles de entender o pueden parecer irracionales. Estos son algunos consejos que tener en cuenta: ',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Sé paciente: las necesidades de salud mental varían mucho de un día para el otro en cuanto a la frecuencia de las necesidades, los síntomas y la gravedad.',
        },
        {
          text: 'Reconoce tus propias reacciones y desencadenantes: cuando surjan rápidamente necesidades de salud mental, reconoce lo que necesitas como cuidador para brindar apoyo, ya que a veces las palabras o acciones de la persona pueden ser emocional o físicamente dolorosas.',
        },
        {
          text: 'Las limitaciones cognitivas pueden afectar las necesidades de salud mental: las necesidades de salud mental combinadas con las limitaciones cognitivas pueden empeorar mucho los síntomas, los comportamientos y las acciones. Por ejemplo, si una persona tiene necesidades de salud mental leves durante su vida, esto puede cambiar rápidamente a necesidades moderadas o graves cuando su función cognitiva ya no pueda procesar pensamientos o emociones de la misma manera que solía hacerlo.',
        },
        {
          text: 'Conoce las mejores maneras de apoyar a la persona a la que estás cuidando: averigua con la familia o los proveedores de atención de la persona qué ha funcionado y qué no en el pasado y qué puedes hacer para ayudarla mejor.',
        },
        {
          text: 'Maneja tus expectativas: el hecho de que un día sea bueno no significa que todos los días serán buenos en el futuro. Permítete dejar de lado tus expectativas y concentrarte en cada día y momento según vayan llegando.',
        },
        {
          text: 'Equípate con conocimientos: pregunta a la persona, a su familia o a su gerente de prestaciones médicas/enfermeros/médicos lo que necesitas saber al cuidar de él/ella y cuál es la mejor manera de ayudarle.',
        },
        {
          text: 'Asegúrate de que la persona esté usando sus medicamentos de manera adecuada y según la receta médica: si la persona está tomando medicamentos de rutina o según sus necesidades para cuestiones de salud mental, asegúrate de que sepa cómo tomarlos y que los esté tomando adecuadamente.',
        },
      ],
    },
    {
      key: 'anxiety-tips-es',
      header: 'Consejos específicos para la ansiedad',
      description:
        'Si bien cada persona experimenta la ansiedad de manera diferente, aquí hay algunas estrategias para prevenir los síntomas de ansiedad o ayudar a calmar algunos síntomas leves:',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Evita ambientes sobreestimulantes: estar en lugares con mucho ruido o sonidos provenientes de múltiples direcciones (como un restaurante lleno de gente) puede aumentar los síntomas de ansiedad.',
        },
        {
          text: 'Crea un espacio tranquilo: reproduce música relajante o sonidos de la naturaleza y utiliza aromas como menta o lavanda. ',
        },
        {
          text: 'Fomenta los juegos o actividades repetitivas: es una buena idea tener varias actividades disponibles y listas para usar, como doblar calcetines, apilar objetos, colorear o juegos simples como el solitario. Esto puede ayudar a la persona a calmarse y puede ser reconfortante.',
        },
        {
          text: 'Designa y mantén un horario: comparte y habla sobre las actividades que planeas realizar durante el día y evita situaciones en las que puedas sentirte apresurado o llegues tarde.',
        },
        {
          text: 'Conoce los desencadenantes de la ansiedad de la persona: es posible que debas evitar hablar sobre ciertos temas o ir a ciertos lugares.',
        },
        {
          text: 'Averigua qué ayuda con los síntomas de la persona: habla con quienes son más cercanos a la persona para comprender mejor qué ha ayudado con sus síntomas de ansiedad en el pasado y saber qué puede aliviar sus síntomas.',
        },
      ],
    },
    {
      key: 'depression-tips-es',
      header: 'Consejos para la depresión, la soledad o el aislamiento social',
      description:
        'Como cuidador de una persona que ha experimentado o está experimentando depresión, soledad o aislamiento social, es fundamental encontrar un equilibrio adecuado entre fomentar la actividad y mostrar empatía. Estos son algunos consejos que te ayudarán con estos síntomas: ',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Salgan al aire libre: aunque sea solo por unos minutos o para una caminata corta, procura salir durante el día. Si el clima no es bueno para estar al aire libre, permite la entrada de luz natural y que la persona se siente junto al sol, cuando sea posible.',
        },
        {
          text: 'Fomenta actividades de interés: ofrece juegos, salidas, jardinería, tejido u otras actividades para involucrar a la persona con frecuencia durante el día. Incluso aunque te rechace una o varias veces, ¡sigue intentándolo!',
        },
        {
          text: 'Escucha con empatía: Anima a la persona a hablar y expresar lo que pueda estar sintiendo. Escucha lo que dice sin juzgar ni buscar una solución o intentar dar una respuesta genial. Solo escucha. Ofrece frases empáticas como "Eso parece desafiante", "Lamento que te sientas así" o "¿Cuál es la mejor manera de apoyarte?".',
        },
      ],
    },
  ],
};
