import styled from '@emotion/styled';
import { Button } from '@mui/material';
import dogBackground from 'Assets/img/adopt-dog-background-670.png';
import catBackground from 'Assets/img/adopt-cat-background-670.png';
import { PetsConfig } from 'Core_Pages/Pets/PetsConfig';

export const StyledPetsContainer = styled.div`
  min-height: fit-content;
  line-height: 1.25;
  height: 100%;

  > div {
    overflow: auto;
  }
`;

export const StyledGamePetsContainer = styled.div`
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  position: relative;
  height: auto;

  // TODO - This is optimal size for backgrounds,
  // so once we figure out how to make canvas scrollable on ios devices,
  // make this min height adjustment
  // @media screen and (max-height: 670px) {
  //   min-height: 670px;
  //   overflow: hidden;
  // }
`;

export const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledParagraph = styled.p`
  color: #000000;
  text-align: center;
  font-size: 16px;
`;

export const StyledParagraphStacked = styled(StyledParagraph)`
  margin-bottom: 0;
`;

export const StyledButton = styled(Button)`
  background-color: #3249a5;
  color: #ffffff;
  width: 140px;
  margin: auto auto 10px auto;
  font-weight: 700;
  border-radius: 10px;
  height: 40px;

  :hover {
    background-color: #3249a5;
  }

  &.MuiButton-root.Mui-disabled {
    background-color: #ebebeb;
    color: #505050;
  }
`;

export const StyledPetImageOnBackground = styled.img`
  height: auto;
  width: 250px;
  position: absolute;
  bottom: 100px;

  @media screen and (max-height: 600px) {
    width: 200px;
    bottom: 65px;
  }
  @media screen and (min-width: 768px) {
    width: 400px;
  }
  @media screen and (min-width: 1024px) {
    width: 500px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;

  div.MuiFormControl-root.MuiTextField-root {
    width: 90%;
  }

  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #1976d2;
    }
  }

  .MuiFormLabel-root.Mui-error {
    color: #c92213;
  }
`;

export const StyledParagraphWithPadding = styled(StyledParagraph)`
  padding: 0 20px;
`;

export const StyledParagraphWithPaddingAndMargin = styled(StyledParagraph)`
  padding: 0 50px;
  margin-top: 10px;
`;

export const StyledContainerWithBackgroundImage = styled(StyledContainer)`
  background-image: url(${(props) => (props.pet.petType === PetsConfig.types.dog ? dogBackground : catBackground)});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
`;

export const StyledButtonWithMargin = styled(StyledButton)`
  margin-top: auto;
  margin-bottom: 10px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
