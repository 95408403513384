import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import ClearIcon from './ClearIcon/ClearIcon';
import { StyledContainer, StyledInput, StyledInputContainer, StyledPromptText, StyledLabel } from './styles';

function TimeField({ reminderNumber, control, required }) {
  const intl = useIntl();

  return (
    <Controller
      name={`reminderTime${reminderNumber}`}
      control={control}
      defaultValue={null}
      render={({ field: { value, onChange } }) => {
        if (!value && reminderNumber !== 1) return null;

        return (
          <StyledContainer>
            <StyledLabel id={`timeInput${reminderNumber}`}>
              {intl.formatMessage({ id: 'reminders.time' }) + ' *'}
            </StyledLabel>
            <StyledInputContainer required={required}>
              <StyledPromptText id={'timeFieldInput' + reminderNumber}>
                {intl.formatMessage({ id: 'reminders.reminder-time-field' })}
              </StyledPromptText>
              <StyledInput
                aria-labelledby={'timeFieldInput' + reminderNumber}
                type="time"
                value={value}
                onChange={onChange}
              />
            </StyledInputContainer>
            {!required && <ClearIcon onClick={() => onChange(null)} />}
          </StyledContainer>
        );
      }}
    />
  );
}
TimeField.DefaultTime = '12:00';

TimeField.propTypes = {
  reminderNumber: PropTypes.number.isRequired,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};
TimeField.defaultProps = {
  required: false,
};

export default TimeField;
