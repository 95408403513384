import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ResultsHeader = ({ title }) => {
  return (
    <Typography
      id="screening-title"
      component="h1"
      fontSize={24}
      fontWeight={700}
      color="white"
      paddingBottom={'20px'}
      justifyContent={'center'}
    >
      {title}
    </Typography>
  );
};

ResultsHeader.propTypes = {
  title: PropTypes.string,
};

export default ResultsHeader;
