import { bathingAndToileting } from 'Assets/img/my-tools-icons';

export const bathingToiletingEs = {
  key: 'bathing-toileting-es',
  title: 'Procedimientos de ayuda para bañarse e ir al baño',
  titleImage: bathingAndToileting,
  titleImageAlt: '',
  contentId: 27,
  sections: [
    {
      key: 'bathing-toileting-intro-es',
      description:
        'Si bien este artículo sobre cómo ayudar a bañarse e ir al baño no abarca todos los aspectos, ofrece varios consejos útiles para que puedas ofrecer cuidado personal de manera exitosa. Es importante hablar con el proveedor, el gerente de prestaciones médicas, el enfermero o el terapeuta de la persona a la que estás cuidando para conocer las capacitaciones disponibles a fin de brindar cuidados personales, como ayudar a bañarse e ir al baño. Muchas organizaciones ofrecen capacitaciones, videos y recursos gratuitos para ayudarte a brindar cuidado personal sin que te lastimes. En este artículo comenzamos con consejos generales, y luego damos consejos más específicos para ambas actividades. ¡Si tienes alguna pregunta no dudes en contactarnos!',
    },
    {
      key: 'general-tips-bathing-toileting-es',
      header: 'Consejos generales para ayudar a bañarse e ir al baño',
      description:
        'Bañarse e ir al baño son actividades muy privadas y, a menudo, resulta incómodo realizarlas frente a alguien más. No querrás obligar a la persona a la que estás cuidando a hacer algo que no quiere o que no es seguro. Tu objetivo es ayudarle a ser lo más independiente posible y brindarle un espacio que fomente el envejecimiento con dignidad. A continuación te damos algunos consejos generales que tener en cuenta.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: '<b>Conoce las necesidades de la persona. Es importante comprender qué es lo más cómodo para la persona y sus necesidades físicas, emocionales y cognitivas. Explica qué y cómo brindarás atención, desde el principio y con frecuencia, para asegurarte de que no se sienta asustado/a ni amenazado/a. Si tiene un deterioro cognitivo, es posible que tengas que repetir lo que dices y hablar con mucha claridad.',
        },
        {
          text: '<b>Conoce sus preferencias y capacidades. No le des un baño en la cama a una persona que sea capaz y quiera usar la ducha. Si suele bañarse antes de acostarse por la noche, no la obligues a bañarse por la mañana al despertarse. Al ir al baño, es posible que tengas que darle tiempo y espacio en lugar de quedarte cerca sin hacer nada. Pregúntale qué quiere y conoce sus métodos preferidos.',
        },
        {
          text: '<b>Háblale a la persona igual que como quieres que te hablen a ti.</b> El hecho de que necesite ayuda para bañarse no significa que sea un niño o que deba ser tratado como tal. Utiliza un lenguaje que promueva la autonomía, tal como lo harías al conversar con cualquier otro adulto. Evita hablar como harías con un bebé o usar lenguaje infantil como "¿Cómo vas, campeón?" o "Vamos, cariño".',
        },
        {
          text: '<b>Anticipa sus necesidades.</b> Crear un horario para bañarse e ir al baño puede ayudarte a sentirte menos apurado/a, provocar menos lesiones y ayudará a la persona a saber qué esperar a lo largo del día. Para bañarse, conoce con qué frecuencia necesita o quiere que lo/a bañes. En los días en que no se baña, trata de saber qué le gusta como rutina de limpieza. Por ejemplo, ¿le gusta que le pongan una loción o que le laven la cara por las mañanas? Para ir al baño, un horario puede provocar menos accidentes y lesiones, y también puede ayudar con la hidratación. Si un horario para ir al baño no es lo más adecuado para la persona, puedes hacer un seguimiento de su uso del baño y alentarla o tener la iniciativa de llevarla al baño, antes de que surja una necesidad urgente.',
        },
        {
          text: '<b>Proporciona transferencias seguras.</b> Si la persona necesita ayuda, asegúrate de saber cómo hacer una transferencia segura. Si la persona a la que estás cuidando es frágil, nunca debes tirar de sus manos o brazos, ya que esto podría dañar sus huesos o crear hematomas dolorosos. Los hospitales, las agencias de atención médica en el hogar y los médicos cuentan con personal al que puedes observar y aprender de ellos. Existen capacitaciones, videos y otros recursos disponibles; solo debes pedirlos. Asegúrate de tener barras de agarre que te ayuden a ti y a la persona ¡y usa ese cinturón de transferencia!',
        },
        {
          text: '<b>Emplea una buena mecánica corporal.</b> Tanto al bañarse como al ir al baño, es muy importante evitar doblarse o torcerse. Confía en la parte más fuerte de tu cuerpo: ¡tus piernas! Brindar atención puede ser físicamente agotador y preservar tu salud y bienestar es muy importante.',
        },
        {
          text: '<b>Usa el equipo y considera realizar modificaciones.</b> Explora si es posible realizar modificaciones en el hogar, como instalar una ducha accesible en lugar de una bañera. Durante el baño, una silla de ducha, una regadera de mano u otros equipos de baño pueden resultar útiles, y para ir al baño, un asiento de inodoro elevado podría reducir la carga de transferencia. Las modificaciones de equipos y viviendas son cada vez más comunes y accesibles, ¡así que asegúrate de preguntar!',
        },
        {
          text: '<b>Usa vestimenta adecuada.</b> Se recomienda usar calzado cerrado con buena suela y ropa cómoda pero holgada. Si no vives en la misma casa que la persona a la que estás cuidando, lleva siempre una muda de ropa adicional.',
        },
        {
          text: '<b>Muéstrate dispuesto/a a adaptar la forma en que brindas atención.</b> Piensa en las maneras en que brindas ayuda para bañarse y usar el baño hoy en día. ¿Alguno de estos consejos te parece relevante? ¿Hay cosas que puedes hacer como cuidador para llevar a cabo esta tarea de manera más sencilla y beneficiosa tanto para ti como para la persona a la que estás cuidando?',
        },
      ],
    },
    {
      key: 'bathing-tips-es',
      header: 'Consejos para ayudar a bañarse',
      description: 'Aquí puedes ver algunos consejos específicos para el baño que debes tener en cuenta.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: '<b>Haz que el cuarto de baño sea atractivo.</b> Observa la temperatura del cuarto de baño y tómate el tiempo para calentar el ambiente. Encuentra formas de hacer que la experiencia sea más cómoda; por ejemplo, reproduce música o agrega aromas como lavanda.',
        },
        {
          text: '<b>Revisa la temperatura del agua.</b> Asegúrate de que el agua no esté demasiado caliente; siente la temperatura con la parte interna de tu muñeca.',
        },
        {
          text: '<b>Prepara el espacio con antelación.</b> Antes de comenzar con el baño, ten listo todo lo que necesitas, incluidos los artículos de tocador, las toallas y cualquier equipo.',
        },
        {
          text: '<b>Conoce el orden en el que bañas a la persona.</b> Es importante el orden de las partes del cuerpo y las zonas que se van a limpiar. Por ejemplo, si se está bañando en una bañera, lavarse el cabello primero puede hacer que sienta frío durante el resto del baño.',
        },
        {
          text: '<b>Observa los cambios.</b> Mantente atento/a a cambios como heridas o hematomas nuevos o que empeoran, cambios en el color de la piel o cualquier otro cambio físico. Todas estas son cuestiones que debes informar de inmediato a su médico.',
        },
      ],
    },
    {
      key: 'toileting-tips-es',
      header: 'Consejos para ir al baño',
      description: 'Aquí puedes ver algunos consejos específicos para ir al baño que debes tener en cuenta.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: '<b>Conoce la mejor manera de acompañar a la persona al baño.</b> La mejor manera de acompañar al baño a alguien que necesita cuidados puede cambiar de un día para el otro o de una hora a la siguiente. Asegúrate de tener varios métodos (y todo el equipo necesario) disponibles. La persona puede tener más energía y querer caminar al baño por la mañana, pero quizás quiera quedarse en la cama y usar el orinal por la noche. Sé flexible, adáptate y no des tu opinión sobre qué método crees que es mejor. No obligues a la persona a esforzarse físicamente mientras va al baño; esto puede provocar que alguno de los dos se lesione.',
        },
        {
          text: '<b>Protégete a ti mismo/a y a la persona de posibles infecciones.</b> Asegúrate de tener un equipo de prevención de infecciones completo, que incluya guantes, productos de limpieza y toallitas. Crea un sistema para estar siempre abastecido: usa pedidos recurrentes o comunícate con el gerente de prestaciones médicas o el trabajador social para asegurarte de que puedan darte suministros antes de que se acaben. Si la persona tiene dificultades con escaras, sarpullidos, resequedad o irritación en la piel, es posible que tengas que cambiar la marca o el tipo de guantes o toallitas que estás usando. Incluso aunque uses guantes, lávate las manos con frecuencia (esto incluye antes y después de ayudar a ir al baño).',
        },
        {
          text: '<b>Conoce las técnicas adecuadas para limpiar y cambiar.</b> Habla con tu médico o gerente de prestaciones médicas sobre las capacitaciones o videos disponibles para asegurarte de realizar esta tarea correctamente. Por ejemplo, las mujeres tienen necesidades diferentes a las de los hombres en cuanto a limpiarse para prevenir infecciones. Hay habilidades importantes que puedes aprender de tus proveedores sobre cómo disminuir el riesgo de irritaciones en la piel, por ejemplo, al asegurarte de que el área esté completamente limpia, usar toallitas que no irriten y usar cremas de protección de la barrera cutánea (solo de acuerdo con las recomendaciones de tu proveedor de atención médica).',
        },
        {
          text: '<b>Observa los cambios.</b> Mantente atento/a a los cambios en la orina, incluidos el color, el olor, la frecuencia y la cantidad. Todos estos son cambios que debes informar de inmediato a su médico.',
        },
      ],
    },
  ],
};
