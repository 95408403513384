import React, { useEffect, useState } from 'react';
import Section from 'Core_Pages/Menu/components/MenuContent/Section/Section';
import useMenu from 'Core_Hooks/useMenu';
import Spinner from 'Core_Components/Spinner/Spinner';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { FormattedMessage } from 'react-intl';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectUserInfo } from 'Core_Redux/user';

function MenuContent() {
  const [menuSections, setMenuSections] = useState([]);
  const [isExpandable, setIsExpandable] = useState(true);
  const [activeTab, setActiveTab] = useState();
  const user = useCoreSelector(selectUserInfo);
  const { menu, isPending } = useMenu(user.sub);

  const modifyIconColors = (menu) => {
    return menu.mainMenu.map((data) => {
      return data.items.map((data) => {
        if (data.severity === 'None') {
          data.severity = 'Custom';
        }
        if (data.severity === 'Info') {
          data.severity = 'Custom';
        }
        if (data.icon === 'fas fa-phone-alt') {
          data.icon = 'fas fa-phone';
        }

        return data;
      });
    });
  };

  useEffect(() => {
    if (menu && menu.mainMenu) {
      modifyIconColors(menu);
      setMenuSections(menu.mainMenu);
      setIsExpandable(menu.mainMenu.length > 1);
      menu.mainMenu.map((section, index) => {
        if (section.expanded) {
          setActiveTab(index);
        }
      });
    }
  }, [menu]);

  const activateTab = (index) => {
    setActiveTab(index);
  };

  if (isPending) {
    return <Spinner />;
  }

  return (
    <div style={{ overflow: 'auto' }}>
      <ScreenReaderWrapper>
        <h1>
          <FormattedMessage id="title.menu" />
        </h1>
      </ScreenReaderWrapper>
      {menuSections &&
        menuSections.map((section, index) => (
          <Section
            key={index}
            index={index}
            activeTab={activeTab}
            isExpandable={isExpandable}
            activateTab={activateTab}
            {...section}
          />
        ))}
    </div>
  );
}

export default MenuContent;
