import styled from 'styled-components';
import { purple } from 'Styles/colors';

const StyledGreetingCardLine = styled.p`
  color: ${purple._900};
  text-align: center;
  vertical-align: top;
  margin: 0 auto;
  cursor: default;
`;

export const StyledGreetingCardHeader = styled(StyledGreetingCardLine)`
  font-size: 18px;
  font-weight: 700;
`;

export const StyledGreetingCardText = styled(StyledGreetingCardLine)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
`;

export const StyledTextContainer = styled.fieldset`
  ${({ className }) =>
    className === 'active' &&
    `background: HSLA(221, 100%, 50%, 0.05); border: solid 3px HSL(216, 100%, 50%); border-radius: 12px;`};
  ${({ className }) => (className === 'active' ? `padding: 5px` : `padding: 8px`)};
  margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0;
  }
`;

export const StyledBackIcon = styled.i`
  font-size: 13px;
  padding-right: 4px;
`;

export const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: -6px;
  margin: -8px;
`;
