import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { detect } from 'detect-browser';
import { Deploy } from 'cordova-plugin-ionic';
import { Paper, IconButton, Popper, Typography, ClickAwayListener } from '@mui/material';
import { Info } from '@mui/icons-material';
import { isEnvironment, PRODUCTION } from 'Onboard_Helpers/isEnvironment';

function DeviceInformation() {
  const intl = useIntl();
  const browser = detect();
  const [version, setVersion] = useState(process.env.FALLBACK_APP_VERSION);
  const [liveUpdateVersion, setLiveUpdateVersion] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'device-information-popper' : undefined;

  useEffect(() => {
    getAzureBuildVersion();
    getLiveUpdateVersion();
  }, []);

  const handleClick = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  async function getLiveUpdateVersion() {
    const receivedVersion = await Deploy.getCurrentVersion();
    if (receivedVersion) {
      setLiveUpdateVersion(receivedVersion.buildId);
    }
  }

  function getAzureBuildVersion() {
    // builds ran in Azure will have this env variable
    if (process.env.APP_VERSION) {
      setVersion(process.env.APP_VERSION);
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <IconButton
          aria-describedby={id}
          aria-label={intl.formatMessage({ id: 'common.show-device-info' })}
          onClick={handleClick}
        >
          <Info sx={{ color: 'white', fontSize: '1.25rem' }} />
        </IconButton>
        {browser && (
          <Popper id={id} open={open} anchorEl={anchorEl}>
            <Paper sx={{ margin: '0 1rem', padding: '1rem' }} data-testid={'DeviceInfoContainer'}>
              <Typography component="h1" variant="h6">
                <FormattedMessage id="common.device-info" />
              </Typography>
              <FormattedMessage id="common.operating-system" />: {browser.os}
              <br />
              <FormattedMessage id="common.browser" />: {browser.name}
              <br />
              <FormattedMessage id="common.version" />: {browser.version}
              <br />
              <FormattedMessage id="common.application-version" />: {liveUpdateVersion || version}
              {!isEnvironment(PRODUCTION) && (
                <>
                  <br />
                  Env: {process.env.ENVIRONMENT}
                  <br />
                  URL: {process.env.API_BASE_URL}
                </>
              )}
            </Paper>
          </Popper>
        )}
      </div>
    </ClickAwayListener>
  );
}

export default DeviceInformation;
