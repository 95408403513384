import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import { RRule } from 'rrule';
import { MenuItem } from '@mui/material';
import WeekIntervalConfiguration from './WeekIntervalConfiguration/WeekIntervalConfiguration';
import MonthIntervalConfiguration from './MonthIntervalConfiguration/MonthIntervalConfiguration';
import {
  StyledContainer,
  StyledRecurrenceRow,
  StyledIntervalSelect,
  StyledFrequencySelect,
  StyledIconContainer,
} from './styles';
import {
  frequencies,
  weekdayArray,
  ordinalToRule,
  ruleToOrdinal,
  weekdayToRule,
  ruleToWeekday,
} from './reminderConstants';
import ReminderRecurrenceText from './ReminderRecurrenceText';
import GoogleFontIcon from 'Core_Components/GoogleFontIcon/GoogleFontIcon';

function RecurrenceConfiguration({ form }) {
  const intl = useIntl();
  const [rruleOptions, setRruleOptions] = useState({});
  const { control, watch } = form;
  const allFields = watch();

  function updateRecurrenceValues(values) {
    const rruleString = RecurrenceConfiguration.GenerateRRuleFromFormValues(values);
    setRruleOptions(RRule.fromString(rruleString).options);
  }

  // update plain-text recurrence description on any field update
  useEffect(() => {
    updateRecurrenceValues(form.getValues());
    const subscription = watch((value) => {
      updateRecurrenceValues(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <StyledContainer>
      <StyledRecurrenceRow>
        <StyledIconContainer>
          <GoogleFontIcon name={GoogleFontIcon.Name.AutoRenew} />
        </StyledIconContainer>
        <Controller
          name="interval"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledIntervalSelect
              value={value}
              onChange={onChange}
              variant="standard"
              content={intl.formatMessage({ id: 'reminders.repeat-every' })}
            >
              {[...Array(99)].map((i, x) => (
                <MenuItem key={x} value={x + 1}>
                  {x + 1}
                </MenuItem>
              ))}
            </StyledIntervalSelect>
          )}
        />
        <Controller
          name="frequency"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledFrequencySelect value={value} onChange={onChange} variant="standard">
              <MenuItem value={frequencies.DAILY}>{intl.formatMessage({ id: 'reminders.recurrence-days' })}</MenuItem>
              <MenuItem value={frequencies.WEEKLY}>{intl.formatMessage({ id: 'reminders.recurrence-weeks' })}</MenuItem>
              <MenuItem value={frequencies.MONTHLY}>
                {intl.formatMessage({ id: 'reminders.recurrence-months' })}
              </MenuItem>
            </StyledFrequencySelect>
          )}
        />
      </StyledRecurrenceRow>
      {allFields.frequency === frequencies.WEEKLY && <WeekIntervalConfiguration control={control} />}
      {allFields.frequency === frequencies.MONTHLY && <MonthIntervalConfiguration control={control} />}
      <ReminderRecurrenceText rruleOptions={rruleOptions} />
    </StyledContainer>
  );
}

RecurrenceConfiguration.DefaultFormState = {
  interval: 1,
  frequency: frequencies.DAILY,
  days: [],
};

RecurrenceConfiguration.FrequencyIndex = {
  en: {
    0: 'Yearly',
    1: 'Monthly',
    2: 'Weekly',
    3: 'Daily',
  },
  es: {
    0: 'YearlyInSpanish',
    1: 'Mensualmente',
    2: 'Semanalmente',
    3: 'Diariamente',
  },
};

RecurrenceConfiguration.GenerateRRuleFromFormValues = (values) => {
  const { frequency, interval, days, monthIntervalConfigOption, dayOfMonth, day, ordinal } = values;

  if (frequency === frequencies.DAILY) {
    const rule = new RRule({ freq: RRule.DAILY, interval });
    return rule.toString();
  }

  if (frequency === frequencies.WEEKLY) {
    const rule = new RRule({
      freq: RRule.WEEKLY,
      interval,
      byweekday: Object.values(days ?? [])
        .filter((d) => d)
        .map(weekdayToRule),
    });
    return rule.toString();
  }

  if (frequency === frequencies.MONTHLY) {
    if (monthIntervalConfigOption === MonthIntervalConfiguration.ConfigurationOptions.CARDINAL) {
      const rule = new RRule({ freq: RRule.MONTHLY, interval, bymonthday: dayOfMonth });
      return rule.toString();
    }

    if (monthIntervalConfigOption === MonthIntervalConfiguration.ConfigurationOptions.ORDINAL) {
      const rule = new RRule({
        freq: RRule.MONTHLY,
        interval,
        byweekday: weekdayToRule(day).nth(ordinalToRule(ordinal)),
      });
      return rule.toString();
    }

    const rule = new RRule({ freq: RRule.MONTHLY, interval, bymonthday: dayOfMonth });
    return rule.toString();
  }
};

RecurrenceConfiguration.GenerateFormValuesFromRRule = (recurrencePattern, language) => {
  if (recurrencePattern) {
    const rule = RRule.fromString(recurrencePattern);
    const {
      options: { freq, interval, byweekday, bymonthday, bynweekday },
    } = rule;

    let frequencyIndex = RecurrenceConfiguration.FrequencyIndex[language];
    if (freq === parseInt(Object.keys(frequencyIndex).filter((k) => frequencyIndex[k] == 'Daily')[0])) {
      return { frequency: frequencies.DAILY, interval };
    }
    if (freq === parseInt(Object.keys(frequencyIndex).filter((k) => frequencyIndex[k] == 'Weekly')[0])) {
      const selectedDays = byweekday.map(ruleToWeekday);
      const formDays = weekdayArray.reduce(
        (acc, curr) => ((acc[`days[${curr}]`] = selectedDays.includes(curr) ? curr : false), acc),
        {},
      );
      return {
        frequency: frequencies.WEEKLY,
        interval,
        days: selectedDays,
        ...formDays,
      };
    }
    if (freq === parseInt(Object.keys(frequencyIndex).filter((k) => frequencyIndex[k] == 'Monthly')[0])) {
      if (bymonthday?.length > 0) {
        return {
          frequency: frequencies.MONTHLY,
          monthIntervalConfigOption: MonthIntervalConfiguration.ConfigurationOptions.CARDINAL,
          interval,
          dayOfMonth: bymonthday[0],
        };
      }
      if (bynweekday?.length > 0) {
        return {
          frequency: frequencies.MONTHLY,
          monthIntervalConfigOption: MonthIntervalConfiguration.ConfigurationOptions.ORDINAL,
          interval,
          day: ruleToWeekday(bynweekday[0][0]),
          ordinal: ruleToOrdinal(bynweekday[0][1]),
        };
      }
    }
  }

  return RecurrenceConfiguration.DefaultFormState;
};

RecurrenceConfiguration.propTypes = {
  form: PropTypes.object.isRequired,
};

export default RecurrenceConfiguration;
