import AuthorizationService from 'Core_Helpers/authentication/AuthService';
import getAuthClient from 'Core_Helpers/getAuthClient';
import { DirectMessageAuthentication } from 'Core_Pages/DirectMessage/Models/DirectMessageAuthentication';
import Axios from 'axios';
import urlcat from 'urlcat';
import { appInsights } from 'Core_Helpers/AppInsights';

export class DirectMessageAPIService {
  static async GetDirectMessageAuth() {
    const directMessageAuthURL = '/v1/directmessage/:userId/authenticate';

    const authService = await AuthorizationService.getInstance();
    const user = await authService.getUserInfo();

    const authorizedApiClient = await getAuthClient();
    if (authorizedApiClient) {
      return await authorizedApiClient.get(
        urlcat(process.env.API_BASE_URL as string, directMessageAuthURL, { userId: user.sub }),
      );
    }
  }

  static async GetAgentPoolStatus() {
    const directMessageAuthURL = '/v1/directmessage/:userId/agentpools';

    const authService = await AuthorizationService.getInstance();
    const user = await authService.getUserInfo();

    const authorizedApiClient = await getAuthClient();
    if (authorizedApiClient) {
      return await authorizedApiClient.get(
        urlcat(process.env.API_BASE_URL as string, directMessageAuthURL, { userId: user.sub }),
      );
    }
  }

  static async RegisterSession(directMessageAuth: DirectMessageAuthentication, session: string = null) {
    const requestBody = {
      register: {
        authtoken: directMessageAuth.auth_token,
        pid: directMessageAuth.pid,
        disp: 'The User',
        group: process.env.LINK_LIVE_GROUP,
        refresher: 'client',
        expires: 600,
        expires_call: 600,
        expires_msg: 600,
      },
    };
    try {
      const result = await Axios.post((process.env.LINK_LIVE_API_REGISTER_URL as string) + `${session}`, requestBody, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      return result;
    } catch (e) {
      appInsights.trackTrace({ message: 'Diagnostic: Direct Messaging - Register session error. ' + e });
    }
  }

  static async ReAuthSession(authToken: string) {
    try {
      const params = {
        access_token: authToken,
      };

      const result = await Axios.get(process.env.LINK_LIVE_API_REAUTH_URL as string, {
        headers: { 'Content-Type': 'application/json' },
        params: params,
        withCredentials: true,
      });

      return result;
    } catch (e) {
      appInsights.trackTrace({ message: 'Diagnostic: Direct Messaging - Reauth error. ' + e });
    }
  }
}
