import { createContext } from 'react';

export const ResultContext = createContext(null);

export const ResultVariantTypes = {
  Header: 'Header',
  Body: 'Body',
  Description: 'Description',
  Resources: 'Resources',
};

export const ResultStates = {
  Default: 'Default',
  Error: 'Error',
  Found: 'Found',
};
