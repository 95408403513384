import styled from '@emotion/styled';

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Loading = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Dot = styled.div`
  @keyframes skBouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }

  margin: 5px;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-color: #2f6e6f;
  animation: skBouncedelay 1s infinite ease-in-out both;
  animation-delay: ${(props) => props.delay};
`;
