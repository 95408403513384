import React, { forwardRef } from 'react';
import { Rating as MuiRating } from '@mui/material';
import PropTypes from 'prop-types';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const Rating = forwardRef(({ inputProps, ...props }, ref) => {
  const extraInputProps = { 'aria-labelledby': props['aria-labelledby'] };
  const { value, icon: Icon, emptyIcon: EmptyIcon } = props;
  const ratingValue = value ? parseInt(value) : undefined;
  const RatingIcon = Icon ? <Icon /> : <FavoriteIcon />;
  const RatingIconEmpty = EmptyIcon ? <EmptyIcon /> : <FavoriteBorderIcon />;

  return (
    <MuiRating
      ref={ref}
      name="hover-feedback"
      inputProps={{ ...inputProps, ...extraInputProps }}
      {...props}
      value={ratingValue}
      icon={RatingIcon}
      emptyIcon={RatingIconEmpty}
    />
  );
});

Rating.displayName = 'Rating';

Rating.propTypes = {
  inputProps: PropTypes.object,
  ref: PropTypes.object,
  value: PropTypes.number,
  icon: PropTypes.element,
  emptyIcon: PropTypes.element,
  ['aria-labelledby']: PropTypes.string,
};

export default Rating;
