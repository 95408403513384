import styled from '@emotion/styled';
import { Tabs } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { black, teal, purple } from 'Styles/colors';

export const PageContainer = styled.div`
  position: relative;
  overflow-y: auto;
  background-color: ${black._60};
  padding: 20px 10px;

  height: 100%;
`;

export const ContentContainer = styled.div`
  height: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding: 16px 16px 32px;
  background-color: ${purple._050};
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  color: ${black._87};
`;

export const Background = styled.div`
  position: relative;
  overflow-y: auto;
  background: #737373;

  height: 100%;
  padding: 25px 10px;
`;

export const Title = styled.h1`
  color: ${black._87}
  font-size: 24px;
  text-align: center;
  letter-spacing: 2px;
`;

export const StyledTabs = styled(Tabs)`
  min-height: auto;

  .MuiTabs-flexContainer {
    gap: 12px;
  }

  .MuiTab-root {
    padding: 4px;
    font-size: 14px;
    min-height: auto;
  }

  .MuiButtonBase-root.MuiTab-root {
    color: ${teal._900};
  }

  .MuiTabs-indicator {
    background-color: ${teal._900};
  }
`;

export const TabContent = styled(TabPanel)`
  border: 1px solid #979797;
  padding: 8px 16px;
  flex: 1;
  overflow: auto;
  margin: 4px 0;
  color: #979797;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  text-align: center;
  margin: 16px 10px;
`;

export const SpinnerContainer = styled.div`
  height: 500px;
  align-items: center;
  display: flex;
`;

export const CircularProgressContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
