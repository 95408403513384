import { affirmations } from 'Assets/img/my-tools-icons';

export const affirmationsEn = {
  key: 'affirmations',
  title: 'Creating your own affirmations',
  titleImage: affirmations,
  titleImageAlt: '',
  description:
    'We all experience ups and downs. And we can help make more of the ups with a simple tool, positive affirmations!',
  contentId: 21,
  sections: [
    {
      key: 'affirmations-intro',
      hasNoSectionMargin: true,
      description:
        "Affirmations are positive phrases that change negative thoughts into helpful ones instead. For example, the negative thought, “I am so stupid,” is changed to a more helpful one with the affirmation “I am capable and learn things every day.”  \n\nBecause what the mind focuses on grows, affirmations state and “affirm” the outcome wanted rather than the negative thoughts that drag it down. In the same way pro athletes and performers use visualization to “see” their success, affirmations help fuel and train our “inner influencer” – the subconscious mind - to focus on what is wanted rather than what is not wanted.\n\nUsing affirmations can help put you in the driver's seat to the experiences you want. Here are some tips for creating affirmations of your own.",
    },
    {
      key: 'creating-affirmations',
      header: 'Creating affirmations',
      description:
        'Affirmations are all about you and target a specific area. They are often short so they’re easier to remember, but you can use whatever length works for you.\n\nTo begin, think about any negative thoughts that might be on your mind. It’s okay to say them to yourself or write them down. Then think of the positive opposite of it and state what you want instead. It’s helpful to think about how you will feel once you have achieved it. Here are some examples.',
      bulletedItems: [
        {
          text: 'I am a valuable person.',
        },
        {
          text: 'I am good enough.',
        },
        {
          text: 'I am proud of myself.',
        },
        {
          text: 'I can do this!',
        },
        {
          text: 'I am resilient.',
        },
        {
          text: 'I do my best.',
        },
        {
          text: 'I am loved.',
        },
        {
          text: 'I like being me.',
        },
        {
          text: 'I am better with each day.',
        },
        {
          text: 'I am creating myself and that is wonderful.',
        },
        {
          text: 'I give myself permission to do what is best for me.',
        },
        {
          text: 'I gracefully treat myself and others with respect.',
        },
        {
          text: 'I appreciate my ability to remain calm in all situations.',
        },
        {
          text: 'I think positive thoughts about myself and others.',
        },
      ],
    },
    {
      key: 'using-affirmations',
      header: 'Using affirmations ',
      description:
        'To begin, write them out and put them in an area you often see, like the bathroom mirror, your desk, or wallet. Then review them regularly to remind yourself of your goals. Use journals, index cards, post-it notes, white boards, e-notes, etc.\n\nAffirmations ‘stick’ better when repeated. To get into the habit using them, try setting a schedule to focus on them. For example, repeat them before you get out of bed in the morning or while you’re brushing your teeth at night. Say them during lunch breaks, riding the bus, or during self-care time to become a regular part of your life.\n\nTry using affirmations to harness the power of positive thinking and impact your experiences! ',
      bulletedItems: [
        {
          text: 'Start your affirmations with “I” or “My.”',
        },
        {
          text: 'Make all your affirmations positive statements.',
        },
        {
          text: 'Write your affirmations in the present tense.',
        },
        {
          text: 'Write as though you’re grateful for already having or being what you want.',
        },
        {
          text: 'See, sense, and feel into your affirmations as though they’ve already happened.',
        },
      ],
    },
  ],
};
