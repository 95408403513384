import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import StartGame from 'Core_Components/StartGame/StartGame';
import SnakeImage from 'Assets/img/snake-game.svg';
import {
  GameBoardContainer,
  GameBoard,
  SnakeBlock,
  AppleBlock,
  Score,
  FlowButtonContainer,
  ExitButton,
  ResetButton,
  ControlContainer,
  ControlButton,
  DirectionalIcon,
} from './styles';
import { useIntl } from 'react-intl';
import { appInsights } from 'Core_Helpers/AppInsights';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { v4 as uuidv4 } from 'uuid';
import useGameTimer from 'Core_Hooks/helpers/useGameTimer';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';
import useInterval from 'Core_Hooks/useInterval';

const TRACKING_EVENTS = {
  leftPage: 'Snake Page Left',
  newGame: 'New Snake Game Started',
  resetGame: 'Snake Game Reset',
  endGame: 'Snake Game Over',
  exitStart: 'Snake Game Exited From Start Screen',
  exitGame: 'Snake Game Exited From Game Screen',
};

//Arcade color palette from https://cdn-learn.adafruit.com/assets/assets/000/077/574/original/gaming_ZXSpectrum_palette.png?1561747149
const colors = ['#FFFFFF', '#FEFE33', '#00FBFE', '#00FA2C', '#FF3FFC', '#FF3114', '#1426FB'];
//Game logic courtesy of open sourced repo: https://github.com/MaelDrapier/react-simple-snake
const SnakeGame = () => {
  const initialLoopTimeout = 640;
  const history = useHistory();
  const intl = useIntl();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [blockWidth, setBlockWidth] = useState(0);
  const [blockHeight, setBlockHeight] = useState(0);
  const [isGamePaused, setIsGamePaused] = useState(false);
  const [gameLoopTimeout, setGameLoopTimeout] = useState(initialLoopTimeout);
  const [startSnakeSize, setStartSnakeSize] = useState(0);
  const [snake, setSnake] = useState([]);
  const [apple, setApple] = useState({});
  const [direction] = useState(['right']);
  const [isGameOver, setIsGameOver] = useState(false);
  const [snakeColor, setSnakeColor] = useState();
  const [appleColor, setAppleColor] = useState();
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(Number(localStorage.getItem('snakeHighScore')) || 0);
  const [newHighScore, setNewHighScore] = useState(false);
  const [gameInit, setGameInit] = useState(false);
  const [isGameStart, setIsGameStart] = useState(true);
  const [playerTracking, setPlayerTracking] = useState({});
  const { timer, handleStart, handleReset } = useGameTimer(
    () => setIsGamePaused(true),
    () => setIsGamePaused(false),
  );
  const componentWillUnmount = useRef(false);
  const opposites = {
    ['right']: 'left',
    ['left']: 'right',
    ['down']: 'up',
    ['up']: 'down',
  };

  const gameAction = () => {
    const originalSnake = snake;
    if (!isGamePaused && !isGameOver && gameInit && originalSnake.length > 0) {
      moveSnake();
    }
  };

  useInterval(gameAction, gameLoopTimeout);

  useEffect(() => {
    return () => {
      endTracking(TRACKING_EVENTS.leftPage);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (componentWillUnmount.current) {
        endTracking(TRACKING_EVENTS.leftPage);
      }
    };
  }, [timer]);

  useEffect(() => {
    if (playerTracking.action === 'reset') {
      handleReset();
      startTracking(TRACKING_EVENTS.newGame);
      let gameWidth = width;
      let gameHeight = height;
      let gameBlockWidth = blockWidth;
      let gameBlockHeight = blockHeight;
      let gameApple = apple;

      // snake reset
      let originalSnake = [];
      let Xpos = gameWidth / 2;
      let Ypos = gameHeight / 2;
      let snakeHead = { Xpos: gameWidth / 2, Ypos: gameHeight / 2 };
      originalSnake.push(snakeHead);
      for (let i = 1; i < startSnakeSize; i++) {
        Xpos -= gameBlockWidth;
        let snakePart = { Xpos: Xpos, Ypos: Ypos };
        originalSnake.push(snakePart);
      }

      // apple position reset
      gameApple.Xpos =
        Math.floor(Math.random() * ((gameWidth - gameBlockWidth * 2) / gameBlockWidth + 1)) * gameBlockWidth;
      gameApple.Ypos =
        Math.floor(Math.random() * ((gameHeight - gameBlockWidth * 2) / gameBlockHeight + 1)) * gameBlockHeight;
      while (isAppleOnSnake(gameApple.Xpos, gameApple.Ypos)) {
        gameApple.Xpos =
          Math.floor(Math.random() * ((gameWidth - gameBlockWidth * 2) / gameBlockWidth + 1)) * gameBlockWidth;
        gameApple.Ypos =
          Math.floor(Math.random() * ((gameHeight - gameBlockHeight * 2) / gameBlockHeight + 1)) * gameBlockHeight;
      }

      setSnake(originalSnake);
      setApple(gameApple);
      direction[0] = 'right';
      setIsGameOver(false);
      setGameLoopTimeout(initialLoopTimeout);
      setSnakeColor(getRandomColor());
      setAppleColor(getRandomColor());
      setScore(0);
      setNewHighScore(false);
      handleStart();
    } else if (playerTracking.action == 'new-game') {
      startTracking(TRACKING_EVENTS.newGame);
      setGameInit(true);
      setIsGameStart(false);
      // Game size initialization
      let percentageWidth = 80;
      let gameWidth = document.getElementById('GameContainer').offsetWidth * (percentageWidth / 100);
      gameWidth -= gameWidth % 30;
      if (gameWidth < 30) gameWidth = 30;
      let gameHeight = (gameWidth / 3) * 2;
      let gameBlockWidth = gameWidth / 30;
      let gameBlockHeight = gameHeight / 20;

      // snake initialization
      let gameStartSnakeSize = 6;
      let originalSnake = [];
      let Xpos = gameWidth / 2;
      let Ypos = gameHeight / 2;
      let snakeHead = { Xpos: gameWidth / 2, Ypos: gameHeight / 2 };
      originalSnake.push(snakeHead);
      for (let i = 1; i < gameStartSnakeSize; i++) {
        Xpos -= gameBlockWidth;
        let snakePart = { Xpos: Xpos, Ypos: Ypos };
        originalSnake.push(snakePart);
      }

      // apple position initialization
      let appleXpos =
        Math.floor(Math.random() * ((gameWidth - gameBlockWidth * 2) / gameBlockWidth + 1)) * gameBlockWidth;
      let appleYpos =
        Math.floor(Math.random() * ((gameHeight - gameBlockHeight * 2) / gameBlockHeight + 1)) * gameBlockHeight;
      while (appleYpos === originalSnake[0].Ypos) {
        appleYpos =
          Math.floor(Math.random() * ((gameHeight - gameBlockHeight * 2) / gameBlockHeight + 1)) * gameBlockHeight;
      }

      setWidth(gameWidth);
      setHeight(gameHeight);
      setBlockWidth(gameBlockWidth);
      setBlockHeight(gameBlockHeight);
      setStartSnakeSize(gameStartSnakeSize);
      setSnake(originalSnake);
      setApple({ Xpos: appleXpos, Ypos: appleYpos });
      setSnakeColor(getRandomColor());
      setAppleColor(getRandomColor());
    }
  }, [playerTracking]);

  const startTracking = (eventName) => {
    appInsights.trackEvent({
      name: eventName,
      properties: { id: playerTracking.uuid, startTime: Date.now() },
    });
  };

  const endTracking = (eventName) => {
    appInsights.trackEvent({
      name: eventName,
      properties: { id: playerTracking.uuid, endTime: Date.now(), duration: timer },
    });
  };

  const startNewGame = () => {
    handleStart();
    setPlayerTracking({
      uuid: uuidv4(),
      action: 'new-game',
    });
  };

  const resetGame = async () => {
    endTracking(TRACKING_EVENTS.resetGame);
    setPlayerTracking({
      uuid: uuidv4(),
      action: 'reset',
    });
  };

  const moveSnake = () => {
    let originalSnake = snake;
    let gameApple = apple;
    let gameWidth = width;
    let gameHeight = height;
    let gameBlockWidth = blockWidth;
    let gameBlockHeight = blockHeight;
    let gameHighScore = highScore;
    let sessionNewHighScore = newHighScore;
    let sessionGameLoopTimeout = gameLoopTimeout;
    let shiftedSnake;

    originalSnake.pop();

    switch (direction[0]) {
      case 'left':
        shiftedSnake = [
          {
            Xpos: originalSnake[0].Xpos <= 0 ? gameWidth - gameBlockWidth * 2 : originalSnake[0].Xpos - gameBlockWidth,
            Ypos: originalSnake[0].Ypos,
          },
        ].concat(originalSnake);
        break;
      case 'up':
        shiftedSnake = [
          {
            Xpos: originalSnake[0].Xpos,
            Ypos:
              originalSnake[0].Ypos <= 0 ? gameHeight - gameBlockHeight * 2 : originalSnake[0].Ypos - gameBlockHeight,
          },
        ].concat(originalSnake);
        break;
      case 'right':
        shiftedSnake = [
          {
            Xpos: originalSnake[0].Xpos >= gameWidth - gameBlockWidth * 2 ? 0 : originalSnake[0].Xpos + gameBlockWidth,
            Ypos: originalSnake[0].Ypos,
          },
        ].concat(originalSnake);
        break;
      default:
        shiftedSnake = [
          {
            Xpos: originalSnake[0].Xpos,
            Ypos:
              originalSnake[0].Ypos >= gameHeight - gameBlockHeight * 2 ? 0 : originalSnake[0].Ypos + gameBlockHeight,
          },
        ].concat(originalSnake);
    }

    // if the snake's head is on an apple
    if (shiftedSnake[0].Xpos === gameApple.Xpos && shiftedSnake[0].Ypos === gameApple.Ypos) {
      let newTail = { Xpos: gameApple.Xpos, Ypos: gameApple.Ypos };

      // increase snake size
      shiftedSnake.push(newTail);

      setSnake(shiftedSnake);

      // create another apple
      gameApple.Xpos =
        Math.floor(Math.random() * ((gameWidth - gameBlockWidth * 2) / gameBlockWidth + 1)) * gameBlockWidth;
      gameApple.Ypos =
        Math.floor(Math.random() * ((gameHeight - gameBlockHeight * 2) / gameBlockHeight + 1)) * gameBlockHeight;
      while (isAppleOnSnake(gameApple.Xpos, gameApple.Ypos)) {
        gameApple.Xpos =
          Math.floor(Math.random() * ((gameWidth - gameBlockWidth * 2) / gameBlockWidth + 1)) * gameBlockWidth;
        gameApple.Ypos =
          Math.floor(Math.random() * ((gameHeight - gameBlockHeight * 2) / gameBlockHeight + 1)) * gameBlockHeight;
      }

      // increment high score if needed
      if (score === gameHighScore) {
        gameHighScore++;
        localStorage.setItem('snakeHighScore', gameHighScore);
        sessionNewHighScore = true;
      }

      // decrease the game loop timeout
      if (sessionGameLoopTimeout > 25) sessionGameLoopTimeout -= 0.5;

      setScore(score + 1);
      setApple(gameApple);
      setHighScore(gameHighScore);
      setNewHighScore(sessionNewHighScore);
      setGameLoopTimeout(sessionGameLoopTimeout);
    } else {
      for (let i = 1; i < shiftedSnake.length; i++) {
        if (shiftedSnake[0].Xpos === shiftedSnake[i].Xpos && shiftedSnake[0].Ypos === shiftedSnake[i].Ypos) {
          endTracking(TRACKING_EVENTS.endGame);
          setIsGameOver(true);
        }
      }
      setSnake(shiftedSnake);
    }
  };

  const isAppleOnSnake = (appleXpos, appleYpos) => {
    let originalSnake = snake;
    for (let i = 0; i < originalSnake.length; i++) {
      if (appleXpos === originalSnake[i].Xpos && appleYpos === originalSnake[i].Ypos) return true;
    }
    return false;
  };

  const handleMove = (command) => {
    if (direction[0] != command && command != opposites[direction[0]]) {
      direction[0] = command;
    }
  };

  const exitIntro = () => {
    appInsights.trackEvent({
      name: TRACKING_EVENTS.exitStart,
    });
    history.push(HOME);
  };

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <GameBoardContainer id="GameContainer" data-testid="GameContainer">
      <ScreenReaderWrapper>
        <h1 id="SnakeGame">{intl.formatMessage({ id: 'title.snake-game' })}</h1>
      </ScreenReaderWrapper>
      {isGameStart || isGameOver ? (
        <StartGame
          startNewGame={isGameOver ? resetGame : startNewGame}
          exitIntro={exitIntro}
          gameName={intl.formatMessage({ id: 'title.snake-game' })}
          image={SnakeImage}
          descriptionHeader={
            isGameOver
              ? intl.formatMessage({ id: 'games.game-over' })
              : intl.formatMessage({ id: 'games.description-header' })
          }
          description={
            isGameOver
              ? `${intl.formatMessage({ id: 'games.score' })}: ${score} ${newHighScore ? 'NEW HIGH SCORE' : ''}`
              : intl.formatMessage({ id: 'games.snake-description' })
          }
        />
      ) : (
        <>
          <FlowButtonContainer data-testid="FlowButtonContainer">
            <ExitButton
              data-testid="ExitButton"
              onClick={() => {
                endTracking(TRACKING_EVENTS.exitGame);
                history.push(HOME);
              }}
            >
              {intl.formatMessage({ id: 'games.exit' })}
            </ExitButton>
            <ResetButton data-testid="ResetButton" onClick={() => resetGame()}>
              {intl.formatMessage({ id: 'games.reset' })}
            </ResetButton>
          </FlowButtonContainer>

          <GameBoard
            data-testid="GameBoard"
            style={{
              width: width,
              height: height,
              borderWidth: width / 58,
            }}
          >
            {snake?.map((snakePart, index) => {
              return (
                <SnakeBlock
                  data-testid="SnakeBlock"
                  key={index}
                  style={{
                    width: blockWidth,
                    height: blockHeight,
                    left: snakePart.Xpos,
                    top: snakePart.Ypos,
                    background: snakeColor,
                  }}
                />
              );
            })}
            <AppleBlock
              data-testid="AppleBlock"
              style={{
                width: blockWidth,
                height: blockHeight,
                left: apple.Xpos,
                top: apple.Ypos,
                background: appleColor,
              }}
            />
            <Score data-testid="ScoreSection" style={{ fontSize: width / 20 }}>
              {intl.formatMessage({ id: 'games.high-score' }) + ': '} {highScore}&ensp; &ensp; &ensp; &ensp;
              {intl.formatMessage({ id: 'games.score' }) + ': '}
              {score}
            </Score>
          </GameBoard>
          <ControlContainer>
            <ControlButton
              data-testid="UpDirectionControl"
              style={{ gridRow: 1, gridColumn: 2 }}
              onClick={() => handleMove('up')}
              aria-label={intl.formatMessage({ id: 'games.directional-up' })}
            >
              <DirectionalIcon aria-hidden="true" className={'fa fa-arrow-up'}></DirectionalIcon>
            </ControlButton>
            <ControlButton
              data-testid="RightDirectionControl"
              style={{ gridRow: 2, gridColumn: 3 }}
              onClick={() => handleMove('right')}
              aria-label={intl.formatMessage({ id: 'games.directional-right' })}
            >
              <DirectionalIcon aria-hidden="true" className={'fa fa-arrow-right'}></DirectionalIcon>
            </ControlButton>
            <ControlButton
              data-testid="LeftDirectionControl"
              style={{ gridRow: 2, gridColumn: 1 }}
              onClick={() => handleMove('left')}
              aria-label={intl.formatMessage({ id: 'games.directional-left' })}
            >
              <DirectionalIcon aria-hidden="true" className={'fa fa-arrow-left'}></DirectionalIcon>
            </ControlButton>

            <ControlButton
              data-testid="DownDirectionControl"
              style={{ gridRow: 3, gridColumn: 2 }}
              onClick={() => handleMove('down')}
              aria-label={intl.formatMessage({ id: 'games.directional-down' })}
            >
              <DirectionalIcon aria-hidden="true" className={'fa fa-arrow-down'}></DirectionalIcon>
            </ControlButton>
          </ControlContainer>
        </>
      )}
    </GameBoardContainer>
  );
};

export default SnakeGame;
