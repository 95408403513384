import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import AdapterDateFns from '@date-io/date-fns';
import TextInput from './TextInput';
import { ErrorAdornment } from './Adornments';
import { InputProps } from './props';
import { toStandardizedISOString } from 'Core_Helpers/dates';
import { Capacitor } from '@capacitor/core';

const DateInput = forwardRef(({ onChange, ...props }, ref) => {
  const [date, setDate] = useState('');

  const { fullWidth, variant, sx, errorMessage, error, inputProps, color, ...rest } = props;
  const textInputProps = { fullWidth, variant, sx, errorMessage, error, color };

  const onDateChange = (v) => {
    setDate(v);
    const dateValue = !v ? '' : toStandardizedISOString(v);
    onChange(dateValue);
  };

  const isNative = Capacitor.getPlatform() === 'ios';

  const Component = isNative ? DesktopDatePicker : DatePicker;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Component
        onChange={onDateChange}
        disableOpenPicker={isNative}
        renderInput={(params) => (
          <TextInput
            {...params}
            {...textInputProps}
            ref={ref}
            inputProps={{ ...params?.inputProps, ...inputProps }}
            InputProps={{
              endAdornment: (
                <>
                  {errorMessage && <ErrorAdornment />}
                  {params?.InputProps?.endAdornment}
                </>
              ),
            }}
          />
        )}
        {...rest}
        value={date}
      />
    </LocalizationProvider>
  );
});

DateInput.displayName = 'DateInput';

DateInput.propTypes = {
  errorMessage: PropTypes.string,
  inputProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  sx: PropTypes.object,
  error: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(InputProps.color)),
  variant: PropTypes.oneOf(Object.values(InputProps.variant)),
  onChange: PropTypes.func.isRequired,
};

DateInput.defaultProps = {
  inputProps: { placeholder: 'mm/dd/yyyy' },
};

export default DateInput;
