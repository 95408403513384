import React from 'react';
import { Grid, Typography } from '@mui/material';
import { StyledMessageSent, StyledMessageSentWrapper } from './styles';
import PropTypes from 'prop-types';
import { black } from 'Styles/colors';

const SentMessage = ({ message }) => {
  return (
    <Grid container justifyContent="right" alignItems="right">
      <Grid item mobile={2}></Grid>
      <Grid item mobile={10}>
        <StyledMessageSentWrapper>
          <Typography fontSize="18px" textAlign="right" color={black._60} marginRight="8px">
            {message.timestamp} {message.from}
          </Typography>
          <StyledMessageSent>
            <Typography fontSize="18px" textAlign="left">
              {message.text}
            </Typography>
          </StyledMessageSent>
        </StyledMessageSentWrapper>
      </Grid>
    </Grid>
  );
};

SentMessage.propTypes = {
  message: PropTypes.object,
};

export default SentMessage;
