import React from 'react';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import {
  HeaderContainer,
  IconContainer,
  ContentContainer,
  ContentText,
  StyledDialog,
  StyledDialogTitle,
} from './styles';
import GreetingPyxir from 'Assets/img/greeting-pyxir.svg';
import Button from 'Stories/components/Button';
import { HolidayContentModalModuleStore } from './HolidayContentModalModuleStore';
import { appInsights } from 'Core_Helpers/AppInsights';

function HolidayContentModal() {
  const intl = useIntl();
  const modalStore = HolidayContentModalModuleStore.Instance().Use();
  const isOpen = modalStore.IsOpen;

  function onClose() {
    modalStore.acknowledge();
    modalStore.close();
  }

  const onClick = () => {
    appInsights.trackEvent({ name: 'HolidayContent_Clicked' });
    window.open('https://www.pyxhealth.com/holidayhelp', '_blank');
  };

  return (
    <>
      <StyledDialog open={isOpen}>
        <HeaderContainer>
          <img
            src={GreetingPyxir}
            alt={intl.formatMessage({ id: 'image.pyxir.portrait' })}
            style={{ width: '50px', margin: '10px' }}
          />
          <StyledDialogTitle></StyledDialogTitle>
          <IconContainer>
            <CloseIcon
              role="button"
              aria-label={intl.formatMessage({ id: 'button.close' })}
              onClick={onClose}
              fontSize="large"
              sx={{ margin: '5px' }}
            />
          </IconContainer>
        </HeaderContainer>
        <ContentContainer>
          <ContentText>
            {intl.formatMessage({ id: 'dialog.holiday-content-modal.title' }, { b: (item) => <strong>{item}</strong> })}
          </ContentText>
          <Button onClick={onClick} sx={{ maxWidth: '240px' }}>
            <Typography color="white">{intl.formatMessage({ id: 'dialog.holiday-content-modal.button' })}</Typography>
          </Button>
        </ContentContainer>
      </StyledDialog>
    </>
  );
}

export default HolidayContentModal;
