import styled from '@emotion/styled';
import { Card, Paper, Typography } from '@mui/material';

export const StyledCard = styled(Card)`
  width: 100%;
  height: 147px;
  min-height: 147px;
  position: relative;
`;

export const StyledIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 106px;
  padding-right: 8px;
`;

export const StyledLabel = styled(Paper)`
  position: absolute;
  bottom: 12px;
  left: 15px;
  padding: 2px 7px;
`;

export const Description = styled(Typography)`
  text-align: left;
  padding: 16px;
  white-space: pre-wrap;
`;
