import styled from '@emotion/styled';
import { purple } from 'Styles/colors';

export const StyledHeader = styled.div`
  background: linear-gradient(to top left, ${purple._800}, ${purple._400});
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  padding: 10px;
`;

export const ExploreContainer = styled.div`
  margin-left: 6px;
`;
