import React from 'react';
import SimpleButtonRow from './SimpleButtonRow';
import { StyledButton } from '../styles';

export default class SimpleButtonRowManager {
  constructor(game, gameData, buttonRowData) {
    this.game = game;
    this.gameData = gameData;
    this.buttonRowData = buttonRowData;
    this.buttonRow = undefined;
    this.currentScreen = undefined;
    this.create();
  }

  create = () => {
    let buttons = [];

    for (let i = 0; i < this.buttonRowData.DATA.length; i++) {
      buttons.push(this.createButton(this.buttonRowData.DATA[i]));
    }

    this.buttonRow = this.game.add.reactDom(SimpleButtonRow, {
      style: { bottom: 0, position: 'absolute' },
      children: undefined,
    });

    return this.buttonRow;
  };

  screenExists = (screen) => {
    return this.buttonRowData.DATA.find((x) => x.ROWS.includes(screen));
  };

  displayScreen = (screen) => {
    let buttons = [];

    this.currentScreen = screen;

    for (let i = 0; i < this.buttonRowData.DATA.length; i++) {
      if (
        !this.buttonRowData.DATA[i].ROWS ||
        this.buttonRowData.DATA[i].ROWS.length < 1 ||
        this.buttonRowData.DATA[i].ROWS?.includes(this.currentScreen)
      ) {
        buttons.push(this.createButton(this.buttonRowData.DATA[i], this.buttonRowData.DATA[i].disabled));
      }
    }

    this.buttonRow.setState({
      style: { bottom: 0, position: 'absolute' },
      children: buttons,
    });

    return this.buttonRow;
  };

  createButton = (buttonData, disabled = false) => {
    return (
      <StyledButton
        key={`${buttonData.NAME}_doneButton`}
        color="primary"
        variant={buttonData.BUTTONTYPE == 'PRIMARY' ? 'contained' : 'outlined'}
        disabled={disabled}
        onClick={buttonData.ONCLICK}
        disableElevation={buttonData.BUTTONTYPE == 'PRIMARY'}
      >
        {this.gameData.intl.formatMessage({ id: buttonData.TEXTID })}
      </StyledButton>
    );
  };

  update = (sceneButtonType, disabled = false) => {
    let buttons = [];

    for (let i = 0; i < this.buttonRowData.DATA.length; i++) {
      if (
        !this.currentScreen ||
        !this.buttonRowData.DATA[i].ROWS ||
        this.buttonRowData.DATA[i].ROWS.length < 1 ||
        this.buttonRowData.DATA[i].ROWS?.includes(this.currentScreen)
      ) {
        this.buttonRowData.DATA[i].disabled =
          this.buttonRowData.DATA[i].NAME == sceneButtonType ? disabled : this.buttonRowData.DATA[i].disabled ?? false;

        buttons.push(this.createButton(this.buttonRowData.DATA[i], this.buttonRowData.DATA[i].disabled));
      }
    }

    this.buttonRow.setState({
      style: { bottom: 0, position: 'absolute' },
      children: buttons,
    });

    return this.buttonRow;
  };
}
