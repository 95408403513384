import React from 'react';
import PropTypes from 'prop-types';
import ConditionalLink from 'Core_Components/ConditionalLink/ConditionalLink';
import { SectionItemContainer, SectionItemLabel, SectionItemIcon } from './styles';
import { appInsights } from 'Core_Helpers/AppInsights';

function SectionItem({ label, linkType, link, icon, severity = '', menuItemId, clickHandler = null }) {
  const onClick = () => {
    if (linkType === 'AppView') {
      appInsights.trackEvent({ name: label, properties: { menuItemId } });
    } else {
      appInsights.trackEvent({ name: `NetworkResource_${label}`, properties: { menuItemId: menuItemId } });
    }
  };

  return (
    <ConditionalLink onClick={clickHandler ? clickHandler : onClick} linkType={linkType} link={link}>
      <SectionItemContainer>
        <SectionItemIcon
          aria-hidden="true"
          customColor={severity === 'Custom'}
          data-testid={`MenuItem${menuItemId}`}
          className={`${icon} ${severity.toLowerCase()}`}
        />
        <SectionItemLabel>{label}</SectionItemLabel>
      </SectionItemContainer>
    </ConditionalLink>
  );
}

SectionItem.propTypes = {
  menuItemId: PropTypes.number,
  label: PropTypes.string,
  linkType: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
  severity: PropTypes.string,
  clickHandler: PropTypes.func,
};

export default SectionItem;
