import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { IconButton, InputAdornment } from '@mui/material';
import { Error, Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

export function ErrorAdornment() {
  return (
    <InputAdornment position="end">
      <Error color="error" />
    </InputAdornment>
  );
}

export function VisibilityAdornment({ visible, onClick }) {
  const intl = useIntl();

  return (
    <InputAdornment position="end">
      <IconButton
        edge="end"
        onClick={onClick}
        aria-label={
          visible
            ? intl.formatMessage({ id: 'account.common.password-visibile' })
            : intl.formatMessage({ id: 'account.common.password-hidden' })
        }
      >
        {visible ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
}

export function ClearAdornment({ onClick }) {
  const intl = useIntl();

  return (
    <InputAdornment position="end">
      <IconButton edge="end" onClick={onClick} aria-label={intl.formatMessage({ id: 'common.clear' })}>
        <CloseIcon />
      </IconButton>
    </InputAdornment>
  );
}

VisibilityAdornment.propTypes = {
  visible: PropTypes.bool,
  onClick: PropTypes.func,
};

VisibilityAdornment.defaultProps = {
  visible: false,
  onClick: null,
};

ClearAdornment.propTypes = {
  onClick: PropTypes.func,
};

ClearAdornment.defaultProps = {
  onClick: null,
};
