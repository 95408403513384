export const getCurrentDate = () => {
  return toUtcDate(new Date());
};

export const getCurrentFullDate = () => {
  return toFullUtcDate(new Date());
};

export const toUtcDate = (date) => {
  const currentDate = new Date(date);
  return new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate()));
};

export const toFullUtcDate = (date) => {
  const currentDate = new Date(date);
  return new Date(
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
      currentDate.getUTCHours(),
      currentDate.getUTCMinutes(),
      currentDate.getUTCSeconds(),
      currentDate.getUTCMilliseconds(),
    ),
  );
};

export const isNowPastDate = (date, daysLate = 1.0) => {
  const comparedDate = date ? toFullUtcDate(date) : 0;
  return (toFullUtcDate(new Date()) - comparedDate) / (1000 * 60 * 60 * 24.0) >= daysLate;
};
