export const InputProps = {
  variant: {
    OUTLINED: 'outlined',
    FILLED: 'filled',
    STANDARD: 'standard',
  },
  color: {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
  },
  type: {
    DATE: 'date',
    PASSWORD: 'password',
    TEL: 'tel',
    TEXT: 'text',
  },
};
