import React from 'react';
import PropTypes from 'prop-types';
import {
  GreetingCardContainer,
  GreetingCardFrom,
  GreetingCardColoringBookImage,
  GreetingCardImage,
  GreetingCardMessage,
  GreetingCardMessageContainer,
} from './styles';

function GreetingCard({ cardText, cardImage, cardFrom, imageDescription, image }) {
  return (
    <>
      <GreetingCardContainer>
        <GreetingCardMessageContainer>
          {!image ? (
            <GreetingCardImage alt={imageDescription} src={cardImage} role="img" />
          ) : (
            <GreetingCardColoringBookImage>{image}</GreetingCardColoringBookImage>
          )}
          <GreetingCardMessage>{cardText}</GreetingCardMessage>
          <GreetingCardFrom>{cardFrom}</GreetingCardFrom>
        </GreetingCardMessageContainer>
      </GreetingCardContainer>
    </>
  );
}

GreetingCard.propTypes = {
  image: PropTypes.object,
  cardText: PropTypes.string,
  cardImage: PropTypes.string,
  cardFrom: PropTypes.string,
  imageDescription: PropTypes.string,
};

export default GreetingCard;
