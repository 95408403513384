import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Answer from 'Stories/components/Form/Answer/Answer';
import { QuestionWrapper, AnswerWrapper } from 'Stories/components/Form/styles';
import { Typography } from '@mui/material';
import { StyledSingleSelectQuestion } from './singleSelectStyles';
import Button from 'Stories/components/Button';

const SingleSelect = ({ name, onChange, text, ...props }) => {
  const intl = useIntl();
  const [selected, setSelected] = useState('');
  const updateSelection = (chosenOption) => {
    setSelected(chosenOption);
  };
  const continueClicked = () => {
    onChange(selected);
    setSelected('');
  };

  return (
    <StyledSingleSelectQuestion>
      <QuestionWrapper id={`${name}-form-question`} className="FormQuestion-root">
        <Typography id={`${name}-question`} className="FormQuestion-question">
          {text}
        </Typography>
        <AnswerWrapper id={`${name}-question-answers`} className="FormAnswer-root">
          <Answer questionName={name} updateSelection={updateSelection} {...props} />
        </AnswerWrapper>
      </QuestionWrapper>
      <Button style={{ width: '100%' }} onClick={continueClicked} disabled={!selected}>
        {intl.formatMessage({ id: 'common.continue' })}
      </Button>
    </StyledSingleSelectQuestion>
  );
};

SingleSelect.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  answerType: PropTypes.string,
  onChange: PropTypes.func,
};

export default SingleSelect;
