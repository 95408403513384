import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Core_Helpers/getAuthClient';
import urlcat from 'urlcat';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectUserInfo } from 'Core_Redux/user';

const url = '/v1/notificationsettings/:userId';
const options = {
  revalidateOnFocus: false,
};

const getNotifications = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useNotifications = (swrOptions) => {
  const user = useCoreSelector(selectUserInfo);
  const [isPending, setIsPending] = useState(true);
  const [notificationSetting, setNotificationSetting] = useState();

  const { data: notificationResult, error } = useSWR(
    () => urlcat(process.env.API_BASE_URL, url, { userId: user.sub }),
    getNotifications,
    {
      ...options,
      ...swrOptions,
    },
  );

  useEffect(() => {
    if (notificationResult) {
      setNotificationSetting(notificationResult.data);
    }

    setIsPending(!notificationResult && !error);
  }, [notificationResult, error]);

  return { notificationSetting, error, isPending, user };
};

export default useNotifications;
