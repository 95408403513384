import { thriveStatuses, thriveWorkflowTypes } from '../enums';
import { workflowTypes } from './workflow';

export const getWorkflowState = (state) => {
  let response = { workflowState: {}, workflowType: thriveWorkflowTypes.DEFAULT };

  if (state?.thriveAccess?.thriveStatus === thriveStatuses.GRADUATE && state.congrats?.showCongrats) {
    response.workflowType = workflowTypes.CONGRATS;
  }

  if (state?.thriveAccess?.thriveStatus !== thriveStatuses.ACTIVE) {
    return response;
  }

  if (!!state.thriveAssessments?.thriveAssessmentTerm && state?.thriveAssessmentWeek === 1) {
    response.workflowType = workflowTypes.ASSESSMENT_WEEK1;
  } else if (
    !!state.thriveAssessments?.thriveAssessmentTerm &&
    state?.thriveAssessmentWeek >= 8 &&
    state?.thriveAssessmentWeek <= 10
  ) {
    response.workflowType = workflowTypes.ASSESSMENT_WEEK8;
  }

  return response;
};
