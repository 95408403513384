import { rephrasingThoughts } from 'Assets/img/my-tools-icons';

export const rephrasingThoughtsGuardianEs = {
  key: 'rephrasing-thoughts-guardian-es',
  title: 'Reformular mis pensamientos',
  titleImage: rephrasingThoughts,
  titleImageAlt: '',
  contentId: 52,
  sections: [
    {
      key: 'rephrasing-thoughts-intro-es',
      description:
        '¿Tienes pensamientos negativos o preocupantes que te están consumiendo? ¿Piensas a menudo en los peores escenarios? ¿O tal vez te cuesta relajarte o dormir porque estás pensando en demasiadas cosas? Combatir los pensamientos poco saludables puede ayudarte a sentirte menos solitario o aislado. A continuación te damos algunos consejos que te ayudarán a redirigir y cambiar lo que estás pensando.',
    },
    {
      key: 'acknowledge-thoughts-es',
      header: 'Paso 1: reconoce y comprende mejor tus pensamientos',
      description:
        'Esta es una actividad que puedes hacer en tu mente, pero si es la primera vez que haces este ejercicio, o simplemente prefieres ver tus pensamientos escritos, toma un bolígrafo y un poco de papel (o utiliza una herramienta digital).',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Piensa o anota tus pensamientos. Reconócelos y captúralos todos: los buenos pensamientos y cualquier pensamiento que pueda estar causando preocupación, enojo, tristeza o cualquier otra emoción.',
        },
        {
          text: 'Piensa en cada uno de tus pensamientos e intenta recordar cómo te sientes cuando tienes estos pensamientos.',
        },
        {
          text: 'Ahora, agrúpalos en categorías de tu elección. Algunos pensamientos pueden encajar en múltiples categorías. El objetivo es comprender de dónde vienen y cómo te sientes al respecto. Estos son algunos ejemplos de áreas de categorías para comenzar:',
          subItems: [
            { text: 'Preocupante' },
            { text: 'Triste' },
            { text: 'Enojado' },
            { text: 'Sobre mi familia' },
            { text: 'Crianza de los hijos' },
            { text: 'Feliz' },
            { text: 'Alentador' },
            { text: 'Validador' },
            { text: 'Que apoya a alguien más' },
            { text: 'Muestra gratitud' },
          ],
        },
      ],
    },
    {
      key: 'rephrase-thoughts-es',
      header: 'Paso 2: reformula tus pensamientos',
      description:
        'Luego decide qué es lo más útil de cada pensamiento para avanzar en tu vida. A continuación encontrarás algunos consejos que tener en cuenta al reformular tus pensamientos, seguidos de algunos ejemplos.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Está bien sentir soledad, enojo o tristeza. No queremos eliminar estos sentimientos. Un pensamiento reformulado podría ser permitirte sentir estas emociones, pero sin obsesionarte.',
        },
        {
          text: 'Utiliza tus sentimientos de ira, frustración o tristeza para motivarte a ser mejor. Por ejemplo, podrías reformular tu pensamiento de la siguiente manera:',
          subItems: [
            {
              text: 'Hoy usaré mi enojo para hacer un buen entrenamiento o para motivarme a realizar una tarea.',
            },
          ],
        },
        {
          text: 'Reformular tus pensamientos no significa solo hacerlos positivos; a veces puede ser tan simple como un cambio sutil que modifica cómo percibes el pensamiento.',
        },
        {
          text: 'Estos son algunos ejemplos de pensamientos para que practiques cómo replantearlos. Después de haber leído cada pensamiento “original”, mira el ejemplo de cómo reformularlo. Ten en cuenta que no existe una única forma "correcta" de reformular un pensamiento.',
          subItems: [
            {
              text: '<b>Pensamiento original</b>: Me preocupa que la forma en que hablé con mi hijo/a me convierta en un mal padre/madre.',
            },
            {
              text: '<b>Pensamiento reformulado</b>: Soy un/a gran padre/madre que se esfuerza por reflexionar sobre cómo cuido de mi hijo/a y cómo siempre puedo mejorar.',
            },
            {
              text: '<b>Pensamiento original</b>: Extraño a los niños de crianza que vivían conmigo antes y me preocupo por ellos.',
            },
            {
              text: '<b>Pensamiento reformulado</b>: Voy a tomarme un tiempo cada día para pensar en el maravilloso niño o niña al que he podido demostrar amor y dar un excelente hogar. Siempre será parte de mí y yo siempre seré parte de él/ella.',
            },
            { text: '<b>Pensamiento original</b>: No estoy haciendo nada bien hoy. ' },
            {
              text: '<b>Pensamiento reformulado</b>: Aunque hoy ha sido un día más difícil, logré completar todas las tareas que me propuse hacer y voy a aprovechar la oportunidad de los desafíos de hoy para hacer que mañana sea mejor. ',
            },
            { text: '<b>Pensamiento original</b>: Nadie entiende por lo que estoy pasando.' },
            {
              text: '<b>Pensamiento reformulado</b>: Tengo una vida diferente y soy el/la único/a que realmente puede comprender mis luchas, frustraciones o alegrías. Solo puedo controlar mis pensamientos; no puedo controlar lo que los demás piensan de mí y de mi situación.',
            },
          ],
        },
        {
          text: 'Ahora, toma uno de tus pensamientos y reformúlalo.',
        },
        { text: 'Puedes hacer este ejercicio tantas veces como quieras.' },
      ],
    },
  ],
};
