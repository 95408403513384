import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { Screenings } from 'Core_Pages/Screening/enumerations/enums';
import { CheckinModuleStore } from 'Core_Pages/Checkin/CheckinModuleStore';
import { StepperButton } from 'Core_Pages/Checkin/ScreeningStepper/styles';

const screeningGuid = Screenings.YOUTH_NEEDS;

const CheckinResult = ({ onClick, screeningResponseGuid }) => {
  const intl = useIntl();
  const store = CheckinModuleStore.Instance().Use();
  const result = store.RecentScreeningScores.find(
    (x) => x.screeningGuid === screeningGuid && x.screeningResponseGuid === screeningResponseGuid,
  );
  const isNext = store.NextAvailableScreening === screeningGuid;
  const textId = result?.hasUrgentNeed
    ? 'screenings.results.youth.needs.concerns'
    : 'screenings.results.youth.needs.no-concerns';

  return (
    <>
      <Typography fontSize={16} fontWeight={700}>
        {intl.formatMessage({ id: 'screenings.check-in.needs-title' })}
      </Typography>
      {!result || !textId ? (
        <StepperButton disabled={!isNext} onClick={() => onClick(screeningGuid)}>
          {intl.formatMessage({ id: 'title.check-in' })}
        </StepperButton>
      ) : (
        <Typography fontSize={16} whiteSpace={'pre-wrap'}>
          {`${intl.formatMessage({ id: 'screenings.check-in.result' })}: \n${intl.formatMessage({ id: textId })}`}
        </Typography>
      )}
    </>
  );
};

CheckinResult.propTypes = {
  onClick: PropTypes.func.isRequired,
  screeningResponseGuid: PropTypes.string,
};

export default CheckinResult;
