import React from 'react';
import PropTypes from 'prop-types';
import ConditionalLink from 'Core_Components/ConditionalLink/ConditionalLink';
import { LinkButtonContainer } from './styles';

const LinkButton = ({ link, linkType, text, className, children, onClick, style, customStyle }) => {
  return (
    <ConditionalLink link={link} linkType={linkType} onClick={onClick} style={style}>
      <LinkButtonContainer style={customStyle} data-testid="LinkButton" className={className}>
        {children ? children : text}
      </LinkButtonContainer>
    </ConditionalLink>
  );
};

LinkButton.propTypes = {
  link: PropTypes.string,
  linkType: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  // Great for passing in translated elements
  children: PropTypes.element,
  onClick: PropTypes.func,
  style: PropTypes.object,
  customStyle: PropTypes.object,
};

export default LinkButton;
