import { lgbtqiaSocialMedia } from 'Assets/img/my-tools-icons';

export const lgbtqiaSocialMediaEs = {
  key: 'lgbtqia-social-media-es',
  title: 'Mantener un espacio seguro en las redes sociales LGBTQIA+',
  titleImage: lgbtqiaSocialMedia,
  titleImageAlt: '',
  contentId: 19,
  description:
    'Las comunidades LGBTQIA+ en línea pueden ser una experiencia positiva. Muchos canales de redes sociales pueden proporcionar conexiones positivas y formas de celebrar la autenticidad. Que los canales de redes sociales creen un espacio seguro donde expresarse es tan importante como ser consciente de cómo participar en ellos para asegurar tu bienestar. A continuación encontrarás algunos consejos para tener en cuenta cuando estés en línea o no.',
  sections: [
    {
      key: 'lgbtqia-sm-protect-space-es',
      header: 'Protege tu espacio',
      description:
        'Cada canal de redes sociales tiene ajustes modificables que puedes controlar. Por ejemplo, los ajustes de privacidad te permiten elegir quién te sigue y ve las fotos y los videos que publicas.',
    },
    {
      key: 'lgbtqia-sm-be-picky-es',
      header: 'Sé selectivo',
      description:
        'Si quieres compartir algo, pero solo quieres que lo vea un grupo específico de amigos o seguidores, compártelo a través de la opción de mensaje directo del canal en lugar de publicarlo públicamente.',
    },
    {
      key: 'lgbtqia-sm-own-feed-es',
      header: 'Ten el control de tu muro',
      description:
        'Si alguien a quien sigues te hace sentir mal, es hora de dejar de seguirlo. Considéralo una forma pequeña pero poderosa de practicar el cuidado personal. Por lo general, no se les notifica al respecto.',
    },
    {
      key: 'lgbtqia-sm-block-negativity-es',
      header: 'Bloquea la negatividad',
      description:
        'Si alguien está afectando negativamente tu experiencia, puedes bloquearlo. Una vez más, normalmente no se les notifica y no podrán ver tus publicaciones ni tu cuenta.  Si cambias de opinión, simplemente puedes desbloquearlos.',
    },
    {
      key: 'lgbtqia-sm-control-comments-es',
      header: 'Controla los comentarios',
      description:
        'Los canales de las redes sociales han aumentado el control de los comentarios ofensivos. Verifica las opciones de ajustes para elegir quién comenta tus publicaciones, además de las palabras, frases y emojis que deseas excluir.',
    },
    {
      key: 'lgbtqia-sm-pausing-noise-es',
      header: 'Pausa el ruido',
      description:
        'Si necesitas un descanso de alguien a quien sigues, está bien pausar sus publicaciones por un tiempo. Verifica los ajustes para ocultarlas o silenciarlas. Cuando quieras volver a verlas, simplemente deja de ocultarlas o de pausarlas.',
    },
    {
      key: 'lgbtqia-sm-support-others-es',
      header: 'Ayuda a los demás',
      description:
        'Si ves una publicación que te hace preocuparte de que alguien pueda lastimarse, es importante que sepas que no tienes que manejar la situación solo. Puedes conectar a la persona con recursos de apoyo, como una línea de ayuda, o alentarlos a hablar con otras personas.\n\nNOTA: Recuerda que puedes hablar con tu miembro del personal de apoyo (ANDY) de Pyx Health. Puede brindarte comprensión y empatía, y ayudarte a encontrar los recursos que tu plan de salud tiene disponibles. Utiliza el botón “Llamar a ANDY” en tu aplicación de Pyx Health o llama al 1- 855- 499- 4777.',
    },
    {
      key: 'lgbtqia-sm-own-friend-es',
      header: 'Cuida de ti',
      description:
        'Todo el mundo merece amor y respeto. Contar con una comunidad en la que te sientas bienvenido y apoyado ayuda al bienestar físico y mental.  Puedes usar hashtags para buscar grupos y creadores de contenido en las redes sociales con los que te sientas en sintonía, como #YouAreNotAlone, #SelfCare, #TransIsBeautiful, #LoveIsLove, #LoveWins, #BeKind y #YouMatter.',
    },
  ],
};
