import { learningEmpathy } from 'Assets/img/my-tools-icons';

export const learningEmpathyEs = {
  key: 'learning-empathy-es',
  title: 'Aprender a ser empático/a',
  titleImage: learningEmpathy,
  titleImageAlt: '',
  contentId: 49,
  sections: [
    {
      key: 'learning-empathy-intro-es',
      description:
        '¿Qué es la empatía y por qué es importante? La capacidad de ser empático/a es uno de los mayores dones que puedes ofrecer a los demás. Piensa en alguna ocasión en la que te hayas sentido estresado/a o frustrado/a y hayas hablado con alguien acerca de ello. Entonces, en lugar de ofrecerte empatía, te ofrecieron varias soluciones para “resolver” tu estrés o te dijeron por qué no debías estar enojado/a. ¿Qué respuesta habría sido mejor? ¿Quizá una más empática? Ofrecer y recibir empatía puede ayudarte a sentirte menos solo/a al mejorar la forma en que conectas con los demás y contigo mismo/a. Estas son algunas formas de aprender y practicar la empatía.',
    },
    {
      key: 'empathy-everyone-es',
      header: 'La empatía es para todos',
      description:
        'Afortunadamente, la empatía no es una habilidad genética o hereditaria, es algo que cualquiera puede aprender. A veces, la gente piensa que la empatía no es importante o que no empuja a otra persona a ser mejor. Estas son algunas razones por las que la empatía es importante.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Cuando das una respuesta empática, haces que la otra persona se sienta validada.',
        },
        {
          text: 'Ofrecer una respuesta empática anima a la otra persona a seguir hablando y le da un espacio seguro para compartir más acerca de lo que siente.',
        },
        {
          text: 'Ser empático/a proporciona un espacio para que la otra persona determine qué le hará sentirse mejor.',
        },
        {
          text: 'En la crianza, la empatía puede ayudar a que el niño o la niña se sienta más independiente y confíe en que puede conseguir un resultado mejor sin que le digas lo que debe hacer.',
        },
      ],
    },
    {
      key: 'learning-about-empathy-sec-es',
      header: 'Aprender a ser empático/a',
      description:
        'La empatía consiste en permitirse pensar en lo que la otra persona está diciendo o sintiendo desde su perspectiva. Está en la naturaleza humana querer ofrecer una solución cuando alguien que te importa puede estar enojado. La forma en que ofrezcas apoyo y respondas a los demás puede aportarte una mayor sensación de paz y hacer que te sientas menos aislado/a o solo/a. ¡La buena noticia es que existen varios tipos de respuestas empáticas!',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Escenario: Le dices a alguien de confianza que te sientes estresado/a y enojado/a.',
        },
        {
          text: 'Estas son algunos tipos de respuestas empáticas que podrías utilizar:',
          subItems: [
            { text: 'Reformular con empatía: Siento escuchar que estás estresado/a.' },
            { text: 'Validación: Eso parece muy frustrante.' },
            { text: 'Análisis de perspectiva: Es comprensible que estés enojado/a por eso.' },
            { text: 'Gratitud: Gracias por confiar en mí y compartirlo conmigo.' },
            {
              text: 'Fomentar la autorreflexión: ¿Te sientes cómodo/a contándome más de por qué te hace sentir enojado/a?',
            },
          ],
        },
      ],
    },
    {
      key: 'practicing-empathy-es',
      header: 'Practicar la empatía',
      description:
        'A continuación se muestran algunos escenarios seguidos de un ejemplo de respuesta empática. Revisa cada ejemplo y piensa o escribe cuál crees que podría ser una respuesta empática. Ten en cuenta que en cada escenario puede haber varias respuestas empáticas y que nuestro ejemplo no es el único “correcto”.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Escenario: Estoy muy preocupado/a por mi próxima prueba de matemáticas. No entiendo nada.',
          subItems: [
            {
              text: 'Respuesta empática: Siento escuchar que estás preocupado/a. ¿Puedes explicarme mejor qué partes te cuesta entender?',
            },
          ],
        },
        {
          text: 'Escenario: Nadie quiere pasar el rato conmigo. No se me da bien hacer amigos.',
          subItems: [
            {
              text: 'Respuesta empática: Gracias por contármelo. Hacer amigos puede ser difícil y a veces no sucede tan rápido como quisiéramos.',
            },
          ],
        },
        {
          text: 'Escenario: Mi vecino/a me ha dicho que tengo que controlar mejor a mis hijos. ¿Lo puedes creer?',
          subItems: [
            {
              text: 'Respuesta empática: Siento que tengas que escuchar eso. Sentirse juzgado por otros, que no saben por lo que estás pasando, puede ser muy frustrante.',
            },
          ],
        },
        {
          text: 'Escenario: Me resulta difícil encontrar tiempo para completar todas mis tareas diarias. No hay suficientes horas en el día.',
          subItems: [
            {
              text: 'Respuesta empática: Eso parece frustrante. Puede ser difícil encontrar tiempo en nuestras atareadas vidas. ¿Sientes que hubo veces en que se te ha dado muy bien completar tus tareas diarias?',
            },
          ],
        },
        {
          text: 'Escenario: Me siento enfermo/a y parece que no mejoro.',
          subItems: [
            {
              text: 'Respuesta empática: Lamento que no te sientas bien. Hazme saber si hay algo que pueda hacer para que te sientas más cómodo/a.',
            },
          ],
        },
      ],
    },
  ],
};
