import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyledIcon, StyledListItem, Divider, StyledListItemText } from './styles';

function MenuSectionItem({ icon, iconDescription, children, imageStyle, ...props }) {
  return (
    <Fragment key={children}>
      <StyledListItem button {...props}>
        <StyledIcon>
          <img src={icon} alt={iconDescription ?? ''} style={imageStyle} />
        </StyledIcon>
        <StyledListItemText primary={children} />
      </StyledListItem>
      <Divider />
    </Fragment>
  );
}

MenuSectionItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
  icon: PropTypes.string,
  imageStyle: PropTypes.object,
  iconDescription: PropTypes.string,
};

export default MenuSectionItem;
