import React, { useState, useEffect } from 'react';
import Encounter from 'Core_Components/Encounter/Encounter';
import { appInsights } from 'Core_Helpers/AppInsights';
import useEncounter from 'Core_Hooks/useEncounter';
import useFeed from 'Core_Hooks/useFeed';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { FormattedMessage } from 'react-intl';

const PyxirChat = () => {
  const [encounterHistoryId, setEncounterHistoryId] = useState();
  const [lastAnswer, setLastAnswer] = useState();
  const { encounter, isPending, respond, nextMessage, resetEncounter } = useEncounter(true);
  const { feed, isPending: feedIsPending, updateFeed, resetFeed } = useFeed(encounter);

  useEffect(() => {
    return () => {
      appInsights.trackEvent({ name: 'Left_Pyxir_Chat' });
    };
  }, []);

  useEffect(() => {
    if (encounter) {
      setEncounterHistoryId(encounter.encounterHistoryId);
    } else if (encounter === null) {
      resetEncounter();
    }
  }, [encounter]);

  useEffect(() => {
    if (nextMessage) {
      if (!nextMessage.length) {
        resetFeed();
      } else {
        setEncounterHistoryId(nextMessage[0].encounterHistoryId);
        updateFeed(lastAnswer, nextMessage);
      }
    }
  }, [nextMessage]);

  const onSubmit = ({ encounterId, interactionId, text }) => {
    setLastAnswer({ messageText: text });
    const sendRequest = {
      InteractionId: interactionId,
      EncounterId: encounterId,
      EncounterHistoryId: encounterHistoryId,
      MessageText: text,
    };
    respond(sendRequest);
  };

  const reset = () => {
    resetFeed();
    resetEncounter();
  };

  return (
    <>
      <ScreenReaderWrapper id="PageTitle">
        <h1>
          <FormattedMessage id="title.pyxir-chat" />
        </h1>
      </ScreenReaderWrapper>
      <Encounter
        onSubmit={onSubmit}
        feed={feed}
        encounter={encounter}
        isLoading={isPending || (feedIsPending && !nextMessage)}
        nextMessage={nextMessage}
        resetEncounter={reset}
      />
    </>
  );
};

export default PyxirChat;
