import { InteractionType } from './interactionType';
import { playerActorType } from './playerActorType';

export const InteractionLimit = {
  [playerActorType.DOG.NAME]: {
    [InteractionType.FEED.CODE]: {
      LIMIT: 3,
    },
    [InteractionType.ATTENTION.CODE]: {
      LIMIT: 3,
    },
    [InteractionType.FETCH.CODE]: {
      LIMIT: 5,
    },
    [InteractionType.STROLL.CODE]: {
      LIMIT: 3,
    },
    [InteractionType.TREAT.CODE]: {
      LIMIT: 3,
    },
  },
  [playerActorType.CAT.NAME]: {
    [InteractionType.FEED.CODE]: {
      LIMIT: 3,
    },
    [InteractionType.ATTENTION.CODE]: {
      LIMIT: 3,
    },
    [InteractionType.FETCH.CODE]: {
      LIMIT: 5,
    },
    [InteractionType.STROLL.CODE]: {
      LIMIT: 3,
    },
    [InteractionType.TREAT.CODE]: {
      LIMIT: 3,
    },
  },
};
