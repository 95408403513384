import styled from '@emotion/styled';
import { Dialog, IconButton } from '@mui/material';
import Button from 'Stories/components/Button/Button';
import { black } from 'Styles/colors';

export const CheckinsContainer = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: white;
  }

  .MuiDialog-paperScrollPaper {
    background-color: #ededed;
    display: flex;
    flex-direction: column;
    height: 99%;
    overflow-y: auto;
    margin-top: auto;
    border-radius: 3px 3px 0px 0px;
    border: 1px solid #b7b7b7;
    border-bottom: none;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const OptionalCheckinsContainer = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: white;
  }

  .MuiDialog-paperScrollPaper {
    background-color: #ededed;
    display: flex;
    flex-direction: column;
    height: 99%;
    overflow-y: auto;
    margin-top: auto;
    border-radius: 3px 3px 0px 0px;
    border: 1px solid #b7b7b7;
    border-bottom: none;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ButtonInfoLink = styled(Button)`
  text-align: left;
  color: #0300ff;
  width: 60%;
  margin-top: auto;
  margin-bottom: 30px;
  margin-left: 20px;
  line-height: 1.25;

  @media screen and (max-width: 335px) {
    width: 80%;
    padding-top: 30px;
  }
`;

export const OptionalDialogHeader = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  display: flex;

  .MuiTypography-root {
    margin-left: auto;
    padding: 20px 0px 20px 47px;
  }
`;

export const DialogHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${black._38};
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  display: flex;

  .MuiTypography-root {
    margin-left: auto;
    padding: 20px 0px 20px 47px;
  }
`;

export const StyledCloseIconContainer = styled(IconButton)`
  text-align: right;
  padding: 0;
  margin-left: auto;
  color: ${black._38};
  font-size: 42px;
  float: right;
  align-items: start;
  padding: 5px;
  height: min-content;
`;

export const CompletedContainer = styled.div`
  text-align: center;
  padding: 65px 15%;

  .MuiTypography-root {
    padding-bottom: 30px;
    font-weight: 600;
  }
`;

export const OptionalContainer = styled.div`
  text-align: center;
  padding: 65px 10%;

  .MuiTypography-root {
    padding-bottom: 30px;
  }
`;
