import { buildingSupportSystem } from 'Assets/img/my-tools-icons';

export const buildingSupportSystemEs = {
  key: 'building-support-system-es',
  title: 'Creación de un sistema de apoyo',
  titleImage: buildingSupportSystem,
  titleImageAlt: '',
  contentId: 28,
  sections: [
    {
      key: 'building-support-system-intro-es',
      description:
        'Es normal y natural sentirse solitario o aislado como cuidador. ¿Quieres tener compañía o conseguir apoyo? A continuación verás algunos consejos que considerar al crear tu sistema de apoyo.',
    },
    {
      key: 'find-other-caregivers-es',
      header: 'Encuentra a otros cuidadores',
      description:
        'Encontrar a otras personas con situaciones similares puede ser útil para conversar y apoyarse mutuamente frente desafíos comunes.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Hablar sobre tus alegrías y desafíos como cuidador con otras personas que están en situaciones similares puede brindar validación y apoyo.',
        },
        {
          text: 'Puede ser valioso encontrar a un cuidador más experimentado con quien hablar sobre sus experiencias, lo que es normal y lo que no.',
        },
        {
          text: 'Una vez que eres un cuidador veterano, aconsejar a otras personas que recién están comenzando en este camino podría resultar reconfortante para ti y beneficioso para ellos.',
        },
        {
          text: 'Existen grupos de apoyo para cuidadores dirigidos por profesionales de la salud mental: comunícate con tu médico, proveedor de atención médica en el hogar o cualquier otra persona involucrada en el cuidado de tus seres queridos para averiguar si tienen algo que ofrecer.',
        },
      ],
    },
    {
      key: 'find-empathetic-listeners-es',
      header: 'Encuentra a personas que sean oyentes empáticos',
      description:
        'Puede ser útil encontrar a otras personas que sean sensibles a tus desafíos como cuidador y que puedan ofrecer un espacio emocional.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Pueden brindarte apoyo con la energía, la paciencia y la empatía que otros cuidadores tal vez no tengan la capacidad de dar.',
        },
        {
          text: 'Si tienes dificultades para encontrar a un oyente empático, puedes contactar a un terapeuta de salud mental para que te brinde un espacio seguro donde poder expresar tus emociones.',
        },
        {
          text: 'Tu médico o proveedor de seguro médico es el mejor punto de partida para encontrar a un excelente terapeuta de salud mental y además contamos con recursos adicionales en nuestra aplicación.',
        },
      ],
    },
    {
      key: 'appreciation-support-es',
      header: 'Expresa agradecimiento por tu apoyo',
      description: 'Una vez que encuentres tu sistema de apoyo, expresa lo importantes que son estas personas para ti.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Si sientes que alguien te está apoyando, házselo saber con frecuencia.',
        },
        {
          text: 'Cuéntale la importancia que tiene en tu vida y las formas en que te ayuda.',
        },
        {
          text: 'Es de gran ayuda dejarle saber a las personas que te apoyan que no solo han sido importantes para ti en el pasado, sino que también son una parte fundamental de tu sistema de apoyo futuro.',
        },
      ],
    },
    {
      key: 'toss-ego-take-help-es',
      header: 'Deja de lado tu ego y acepta la ayuda',
      description:
        'Ya sea que lo expresen o no, muchas personas que no son cuidadores sienten una gran empatía y respeto por ti como cuidador y desean ayudarte.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Si alguien se ofrece a ayudar, ¡acepta!',
        },
        {
          text: 'Deja que te preparen la cena.',
        },
        {
          text: 'Deja que te ayuden a limpiar tu casa o a cortar el césped.',
        },
        {
          text: 'Permite que compren artículos para ti o para tu ser querido o cualquier cosa que puedas necesitar.',
        },
        {
          text: 'Hazles saber lo que necesitas o lo que te resulta más útil.',
        },
      ],
    },
  ],
};
