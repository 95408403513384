import { Select, Textbox, Radio, Rating, Slider, Switch, SelectCard, Checkbox } from './Answer';

export const AnswerTypes = {
  RADIO: 'RADIO',
  SELECT: 'SELECT',
  TEXTBOX: 'TEXTBOX',
  CARD: 'CARD',
  RATING: 'RATING',
  SLIDER: 'SLIDER',
  SWITCH: 'SWITCH',
  CHECKBOX: 'CHECKBOX',
};

export const answerMap = {
  [AnswerTypes.RADIO]: Radio,
  [AnswerTypes.SELECT]: Select,
  [AnswerTypes.TEXTBOX]: Textbox,
  [AnswerTypes.RATING]: Rating,
  [AnswerTypes.SLIDER]: Slider,
  [AnswerTypes.SWITCH]: Switch,
  [AnswerTypes.CARD]: SelectCard,
  [AnswerTypes.CHECKBOX]: Checkbox,
};
