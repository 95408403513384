import { fosterTips } from 'Assets/img/my-tools-icons';

export const fosterParentTipsEs = {
  key: 'foster-parenting-tips-es',
  title: 'Consejos para padres de crianza',
  titleImage: fosterTips,
  titleImageAlt: '',
  contentId: 47,
  sections: [
    {
      key: 'foster-parenting-tips-intro-es',
      description:
        'Lo más probable es que ya hayas recibido al menos algo de capacitación y consejos sobre cómo ser un buen padre o madre de crianza. Hemos desarrollado algunos consejos y reflexiones para que los leas cuando te sienta solo/a, atrapado/a o tengas dificultades para relacionarte con tu hijo/a o hijos.',
    },
    {
      key: 'remove-expectations-es',
      header: 'Elimina tus expectativas',
      description:
        'Cada niño/a de crianza tiene necesidades y capacidades emocionales diferentes. Eso significa que la forma de criar y reaccionar ante un niño o niña puede no funcionar con otro. Esto puede ser especialmente importante de recordar cuando tienes varios niños de crianza en transición que entran y salen de tu casa. Estas son algunas cosas que debes decirte a ti mismo/a cuando sientas que tus expectativas no se están cumpliendo:',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Respira hondo.',
        },
        {
          text: 'Eres un/a gran padre/madre o cuidador/a.',
        },
        {
          text: 'Puedes comunicarte con tu hijo y relacionarte con él.',
        },
        {
          text: 'Deja de lado tus ideas acerca de cómo “debería” ser.',
        },
      ],
    },
    {
      key: 'learn-trauma-es',
      header: 'Aprender acerca de los traumas',
      description:
        '¿Por qué es importante aprender acerca de los traumas? Los traumas son muy diversos. La inestabilidad que siente un niño de crianza puede inducir una respuesta traumática. Estas son algunas cosas que considerar acerca del trauma:',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'La separación de los padres biológicos y la transición de un hogar a otro es traumática.',
        },
        {
          text: 'Muchos acontecimientos o circunstancias en la vida de tu hijo/a antes o durante el tiempo que está contigo pueden ser traumas.',
        },
        {
          text: 'Mirar a tu hijo/a a través de la “lente del trauma” puede ayudarte a comprenderlo/a mejor: algunos de sus enojos, arrebatos, frustración, tristeza o soledad pueden expresar un trauma.',
        },
        {
          text: 'Tu agencia de crianza puede proporcionarte materiales o clases para ayudarte a aprender acerca de los traumas.',
        },
        {
          text: 'Consulta algunos de los recursos que te pueden ayudar a identificar y encontrar apoyos para tu hijo/a si sientes que necesita ayuda con sus traumas.',
        },
      ],
    },
    {
      key: 'treat-yourself-es',
      header: 'Cuidar de ti mismo/a',
      description:
        'Cuidarte a ti mismo/a te ayuda a cuidar a los demás. Serás un mejor cuidador y padre/madre cuando tus necesidades estén cubiertas y te tomes tiempo para ti mismo/a.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Las formas de practicar el autocuidado pueden variar de un día a otro y de una semana a la siguiente.',
        },
        {
          text: 'Escucha lo que necesitan tu mente y tu cuerpo.',
        },
        {
          text: 'A veces, el autocuidado solo requiere unos minutos de tu tiempo, mientras que otras veces puede llevarte unas horas sentirte recargado/a y renovado/a.',
        },
      ],
    },
    {
      key: 'cultural-needs-es',
      header: 'Aceptar las necesidades culturales de tu hijo/a',
      description:
        'Cada niño/a que entre por la puerta de tu casa tendrá necesidades culturales diferentes: su raza, las normas de sus hogares anteriores, cómo se identifica espiritualmente, su orientación sexual, las partes del país o del mundo en las que ha vivido, o incluso las fiestas y cómo las celebra.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Tómate un tiempo para conocer todas estas facetas de tu hijo/a.',
        },
        {
          text: 'Hazle muchas preguntas acerca de lo que le gusta y lo que es importante para él o ella.',
        },
        {
          text: 'Aprovecha para crear un sistema de apoyo tanto para ti como para tu hijo/a.',
        },
      ],
    },
  ],
};
