import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import MedicationConfig from './ReminderTypeConfig/MedicationConfig/MedicationConfig';
import SupplementConfig from './ReminderTypeConfig/SupplementConfig/SupplementConfig';
import ActivitiesConfig from './ReminderTypeConfig/ActivitiesConfig/ActivitiesConfig';
import AlarmBlockIcon from './AlarmBlockIcon/AlarmBlockIcon';
import StartDateField from './DateConfiguration/StartDateField';
import EndDateField from './DateConfiguration/EndDateField';
import TimeField from './TimeConfiguration/TimeField/TimeField';
import ReminderTypeSelect from './ReminderTypeSelect/ReminderTypeSelect';
import RecurrenceConfiguration from './RecurrenceConfiguration/RecurrenceConfiguration';
import TimeConfiguration from './TimeConfiguration/TimeConfiguration';
import { StyledCard } from '../styles';
import { StyledButtonsContainer, StyledDiscardButton, StyledForm, StyledSaveButton } from './styles';
import { REMINDERS } from 'Core_Pages/Routes/RoutesConfig';
import { ACTIVITIES, MEDICATION, SUPPLEMENT } from './ReminderTypeConfig/reminderTypes';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectIsCreatePending, selectIsEditPending } from 'Core_Redux/reminders';
import { selectLanguage } from 'Core_Redux/user';
import { CircularProgress } from '@mui/material';

function ReminderConfigurationForm({ submit, defaultValues }) {
  const history = useHistory();
  const intl = useIntl();
  const language = useCoreSelector(selectLanguage);
  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    watch,
  } = useForm({ defaultValues });
  const defaultRecurrenceValues = RecurrenceConfiguration.GenerateFormValuesFromRRule(
    defaultValues.recurrencePattern,
    language,
  );
  const recurrenceForm = useForm({ defaultValues: defaultRecurrenceValues });
  const isCreatePending = useCoreSelector(selectIsCreatePending);
  const isEditPending = useCoreSelector(selectIsEditPending);

  const watchFields = watch();

  const handleDiscardClick = () => history.push(REMINDERS);

  const hasStartDate = !!watchFields.startDateUtc;
  const hasReminderTime = !!watchFields.reminderTime1;
  const isSaveActive = hasStartDate && hasReminderTime;

  function validateActivityCategories() {
    const { activityCategories } = getValues();
    const isValid = Object.values(activityCategories).filter((c) => c).length >= 1;
    if (errors.activityCategories && isValid) clearErrors('activityCategories');
    return isValid;
  }

  function validateDateRange() {
    const { startDateUtc, endDateUtc } = getValues();
    return !endDateUtc || DateTime.fromISO(endDateUtc) >= DateTime.fromISO(startDateUtc);
  }

  function onSubmit(data) {
    // remove unselected categories from form data
    if (data.activityCategories) {
      data.activityCategories = Object.values(data.activityCategories).filter((c) => c);
    }

    data.recurrencePattern = RecurrenceConfiguration.GenerateRRuleFromFormValues(recurrenceForm.getValues());

    submit(data);
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledCard>
        <AlarmBlockIcon />
        <ReminderTypeSelect control={control} />
        {watchFields.reminderType === MEDICATION && <MedicationConfig control={control} errors={errors} />}
        {watchFields.reminderType === SUPPLEMENT && <SupplementConfig control={control} errors={errors} />}
        {watchFields.reminderType === ACTIVITIES && (
          <ActivitiesConfig control={control} errors={errors} validate={validateActivityCategories} />
        )}
      </StyledCard>

      <StyledCard>
        <StartDateField control={control} />
        <TimeConfiguration control={control} watch={watch} getValues={getValues} setValue={setValue} />
      </StyledCard>

      <StyledCard>
        <RecurrenceConfiguration form={recurrenceForm} />
        <EndDateField control={control} validate={validateDateRange} errors={errors} />
      </StyledCard>

      <StyledButtonsContainer>
        <StyledSaveButton id="save-button" type="submit" disabled={!isSaveActive}>
          {isCreatePending || isEditPending ? (
            <CircularProgress size={'30px'} color={'inherit'} />
          ) : (
            intl.formatMessage({ id: 'common.save' })
          )}
        </StyledSaveButton>
        <StyledDiscardButton type="button" onClick={handleDiscardClick} variant="text">
          {intl.formatMessage({ id: 'common.cancel' })}
        </StyledDiscardButton>
      </StyledButtonsContainer>
    </StyledForm>
  );
}

ReminderConfigurationForm.DefaultFormState = {
  reminderType: MEDICATION,
  activityCategories: [],
  startDateUtc: DateTime.local().toISODate(),
  recurrencePattern: '',
  reminderTime1: TimeField.DefaultTime,
  isActive: true,
};

ReminderConfigurationForm.propTypes = {
  submit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
};

export default ReminderConfigurationForm;
