import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { appInsights, browserHistory } from 'Core_Helpers/AppInsights';

export const registerNotificationActions = () => {
  PushNotifications.addListener('pushNotificationReceived', (notification) => {
    appInsights.trackEvent({ name: `Push Notification ${notification?.id} received` });
    sendLocalNotification(notification);
  });

  PushNotifications.addListener('pushNotificationActionPerformed', (event) => {
    appInsights.trackEvent({ name: `Push Notification ${event?.notification.id} clicked` });
    if (event?.notification.data.url) {
      browserHistory.push(event?.notification.data.url);
    }
  });

  LocalNotifications.addListener('localNotificationActionPerformed', (event) => {
    appInsights.trackEvent({ name: `Push Notification ${event?.notification.id} clicked` });
    if (event?.notification.url) {
      browserHistory.push(event?.notification.url);
    }
  });
};

export const sendLocalNotification = (notification) => {
  LocalNotifications.schedule({
    notifications: [
      {
        title: notification.title,
        body: notification.body,
        /*
         * The notification identifier.
         * On Android it's a 32-bit int.
         * So the value should be between -2147483648 and 2147483647 inclusive.
         * https://capacitorjs.com/docs/apis/local-notifications#scheduleoptions
         */
        id: Date.now() % 2147483648,
        url: notification.data.url,
      },
    ],
  });
};
