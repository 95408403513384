import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

const Puppy = (props) => {
  const intl = useIntl();

  return (
    <svg
      role="img"
      focusable="false"
      aria-labelledby="svgPuppyTitle"
      aria-describedby="svgPuppyDescription"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 900 900"
    >
      {' '}
      <title id="svgPuppyTitle">
        <FormattedMessage id="image.coloring-book.puppy" />
      </title>
      <desc id="svgPuppyDescription">{intl.formatMessage({ id: 'image.coloring-book.puppy-description' })}</desc>
      <g id="PuppyLayer_2" data-name="Layer 2">
        <path
          onClick={() => props.onFill(0)}
          fill={props.colors[0]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M271.77,157.23c-16,22.67-19.28,52.18-31.32,72.62A210.08,210.08,0,0,0,241.91,254c-22.27,11.38-37.64,41.79-29.32,81.75a36.07,36.07,0,0,1,2.71-9.08c6.18-13.69,12-18.79,27.44-22.82,18.7-4.88,52.07-7.86,60.46-26.86,20.79-47.1,22.68-89.16,31.23-130.62C320.14,127.33,289.09,136.36,271.77,157.23Z"
        />
        <path
          onClick={() => props.onFill(1)}
          fill={props.colors[1]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M241.07,535.06l0-.06a59.44,59.44,0,0,0,5.25,13.68,100.31,100.31,0,0,0,9.91,14.52A83.92,83.92,0,0,1,244,542.39C242.91,540,242,537.51,241.07,535.06Z"
        />
        <path
          onClick={() => props.onFill(2)}
          fill={props.colors[2]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M370.81,281.43c-.94-1.42-1.9-2.8-2.78-4.34C368.92,278.65,369.85,280.07,370.81,281.43Z"
        />
        <path
          onClick={() => props.onFill(3)}
          fill={props.colors[3]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M368,277.09c-1.19-2.44-2.25-4.86-3.24-7.28C365.78,272.23,366.84,274.65,368,277.09Z"
        />
        <path
          onClick={() => props.onFill(4)}
          fill={props.colors[4]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M312,388.07c-5.54,9.47-11.51,15.43-17.47,19,10.25,7.44,23.23,12.88,39.49,16.14a191.15,191.15,0,0,0,41.41,2.74C334.83,420.81,312,388.07,312,388.07Z"
        />
        <path
          onClick={() => props.onFill(5)}
          fill={props.colors[5]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M404.12,111.85s.06-.1.1-.16-.06.11-.1.16C395.46,125.3,386.85,139,379.38,153l.32-.11C387,139.69,396.51,123.68,404.12,111.85Z"
        />
        <path
          onClick={() => props.onFill(6)}
          fill={props.colors[6]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M260.31,567.89c37.2,39.63,98,33,127.47-19.4C359.5,598.78,295.45,605,260.31,567.89Z"
        />
        <path
          className="cls-1"
          d="M407,506.59c-7.87,12.87-12.29,28.12-19.18,41.9C392.6,538.86,399.55,522.08,407,506.59Z"
        />
        <path
          onClick={() => props.onFill(7)}
          fill={props.colors[7]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M501.41,830.06a24.12,24.12,0,0,0-.54-12.44A52.17,52.17,0,0,1,501.41,830.06Z"
        />
        <path
          onClick={() => props.onFill(8)}
          fill={props.colors[8]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M365.86,182.52c1.82-4.93,3.88-9.82,6.11-14.67C369.74,172.71,367.68,177.59,365.86,182.52Z"
        />
        <path
          onClick={() => props.onFill(9)}
          fill={props.colors[9]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M467.35,396.1a83.15,83.15,0,0,0,2.49-8.16A83.15,83.15,0,0,1,467.35,396.1Z"
        />
        <path
          onClick={() => props.onFill(10)}
          fill={props.colors[10]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M394.71,790.83h0a196.47,196.47,0,0,0,3.68-23.28c-10.8-3.2-22-6.51-33.06-10.42a247.53,247.53,0,0,0,31,11.1A126,126,0,0,1,394.71,790.83Z"
        />
        <path
          onClick={() => props.onFill(11)}
          fill={props.colors[11]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M382.24,426.78a68.38,68.38,0,0,0,7.45.19,138.38,138.38,0,0,1-14.24-1.06C377.67,426.26,379.91,426.59,382.24,426.78Z"
        />
        <path
          onClick={() => props.onFill(12)}
          fill={props.colors[12]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M326.74,801.15c-.09,1.48-.18,3-.29,4.44a60.69,60.69,0,0,1-1.07,7.55c0,.21,0,.41,0,.62,1.07,8.6,6.41,14,11,15.88-10-13.25.57-41.68.57-41.68A27.48,27.48,0,0,0,326.74,801.15Z"
        />
        <path
          onClick={() => props.onFill(13)}
          fill={props.colors[13]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M397.54,427l.94-.22c-1,.06-2,.1-3.08.14C396.1,427,396.81,427,397.54,427Z"
        />
        <path
          onClick={() => props.onFill(14)}
          fill={props.colors[14]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M236.16,492.22c0-2.63,0-5.25,0-7.83C236.15,486.53,236.13,489.21,236.16,492.22Z"
        />
        <path
          onClick={() => props.onFill(15)}
          fill={props.colors[15]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M488.63,791.71c-.12.36-.2.75-.3,1.12h0C488.43,792.46,488.51,792.07,488.63,791.71Z"
        />
        <path
          onClick={() => props.onFill(16)}
          fill={props.colors[16]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M594.52,748.55c-1.54-.59-3-1.28-4.55-1.94h0C591.48,747.27,593,748,594.52,748.55Z"
        />
        <path
          onClick={() => props.onFill(17)}
          fill={props.colors[17]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M513.59,774.35c-5.89.61-10.71,1.71-13.46,3.52A98.27,98.27,0,0,1,513.59,774.35Z"
        />
        <path
          onClick={() => props.onFill(18)}
          fill={props.colors[18]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M310.21,368.37c2.78-11.59,41.18-26.9,44.31-37.73,4-14-6.51-19.52-6.51-19.52-34.67-26.59-61.74-8.46-61.74-8.46s-7.44,2.86-9.69,17.52C273.13,342.68,310.21,368.37,310.21,368.37Z"
        />
        <path
          onClick={() => props.onFill(19)}
          fill={props.colors[19]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M394.71,790.83a126,126,0,0,0,1.62-22.6,247.53,247.53,0,0,1-31-11.1c-7.06-2.49-14.08-5.24-20.95-8.35,5.78,5.54,15.83,12.94,33.17,20.79,0,0-30.67-3.31-41.77,18.92.4-.18.79-.38,1.21-.53-9.36,50.55,16.92,44.34,25.88,40.9a22.78,22.78,0,0,1-3.18-6c-4-19.5,10.72-29,10.72-29-13.52,17.67-2.37,34,1.43,37.17,2.76,2.3,13.46,4.41,24-.44a41.94,41.94,0,0,1-3-19.71,70.24,70.24,0,0,0,2,7.5C392.05,808.54,393.17,799.9,394.71,790.83Z"
        />
        <path
          onClick={() => props.onFill(20)}
          fill={props.colors[20]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          d="M371.84,831c-3.8-3.19-14.95-19.5-1.43-37.17,0,0-14.73,9.52-10.72,29a22.78,22.78,0,0,0,3.18,6,31,31,0,0,0,2.9-1.26l3.5,5.61c-.58.18-1.1.3-1.66.47,4.91,3.7,12.56,6.18,24.22,3a36.51,36.51,0,0,0,6-2.11,34.75,34.75,0,0,1-2-4C385.3,835.41,374.6,833.3,371.84,831Z"
        />
        <path
          onClick={() => props.onFill(21)}
          fill={props.colors[21]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          d="M365.77,827.6a31,31,0,0,1-2.9,1.26c-9,3.44-35.24,9.65-25.88-40.9,0,0-10.61,28.43-.57,41.68a9.07,9.07,0,0,0,3.86.83l3.73,3.86a15.39,15.39,0,0,1-1.65.06c5.43,2.51,13.51,2.74,25.25-.71.56-.17,1.08-.29,1.66-.47Z"
        />
        <path
          onClick={() => props.onFill(22)}
          fill={props.colors[22]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M327,797q-.09,2.07-.21,4.14A27.48,27.48,0,0,1,337,788c-.42.15-.81.35-1.21.53A18.9,18.9,0,0,0,327,797Z"
        />
        <path
          onClick={() => props.onFill(23)}
          fill={props.colors[23]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          d="M340.28,830.47a9.07,9.07,0,0,1-3.86-.83c-4.59-1.93-9.93-7.28-11-15.88,0-.21,0-.41,0-.62a47.34,47.34,0,0,1-1.3,5c3,14.66,14.08,16.2,18.28,16.24a15.39,15.39,0,0,0,1.65-.06Z"
        />
        <path
          onClick={() => props.onFill(24)}
          fill={props.colors[24]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M232.25,826.45s5.4,8.68,20.05-.48c-6.32-11.2-3.51-25.41-3.51-25.41-1.44,16.54,7.91,30.2,22.29,33.08,10.59,2.12,16.49-2.78,18.87-5.51-11.37-9.69-4.49-29.73-4.49-29.73-.71,27.33,9.4,28.37,15.82,28.77,11.5.72,18-5.76,20.85-15.82s-2.87-53.22,0-63.28a43,43,0,0,1,4-8.83,128.45,128.45,0,0,1-25.49-19.79c-36.74-37.24-53.32-61.65-61.65-84.64,4.32,25.47,9.17,57.45,9.65,75,0,1,0,1.86,0,2.71,0,30,1.5,60.08,1.5,60.08s-12.63,2.12-20.62,11.12C227.23,788.61,219.08,808.89,232.25,826.45Z"
        />
        <path
          onClick={() => props.onFill(25)}
          fill={props.colors[25]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          d="M326.45,805.59c.11-1.47.2-3,.29-4.44s.15-2.76.21-4.14a441.15,441.15,0,0,0-1.22-51.1l2.66-5.27c-.77-.45-1.53-.94-2.29-1.4a43,43,0,0,0-4,8.83c-2.87,10.06,2.88,53.21,0,63.28s-9.35,16.54-20.85,15.82c-6.42-.4-16.53-1.44-15.82-28.77,0,0-6.88,20,4.49,29.73a11.86,11.86,0,0,0,1.26-1.68l7.19,2.88a17.44,17.44,0,0,1-2,2.53c11.32,4.69,22.7,1.4,27.67-13.71a47.34,47.34,0,0,0,1.3-5A60.69,60.69,0,0,0,326.45,805.59Z"
        />
        <path
          onClick={() => props.onFill(26)}
          fill={props.colors[26]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          d="M291.21,826.45a11.86,11.86,0,0,1-1.26,1.68c-2.38,2.73-8.28,7.63-18.87,5.51-14.38-2.88-23.73-16.54-22.29-33.08,0,0-2.81,14.21,3.51,25.41.5-.32,1-.6,1.52-1l4.9,8.39a26.36,26.36,0,0,0,7.32,3.84c17,5.85,26.55-1.27,30.37-5.38a17.44,17.44,0,0,0,2-2.53Z"
        />
        <path
          onClick={() => props.onFill(27)}
          fill={props.colors[27]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          d="M252.3,826c-14.65,9.16-20.05.48-20.05.48-13.17-17.56-5-37.84-2.71-42.75.33-.72.55-1.11.55-1.11C214.27,795.53,220,818.54,228.65,830s30.2,3.6,30.2,3.6l-.13-.24-4.9-8.39C253.29,825.37,252.8,825.65,252.3,826Z"
        />
        <path
          onClick={() => props.onFill(28)}
          fill={props.colors[28]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M241.05,535l0,.06c.89,2.45,1.84,4.89,2.93,7.33a83.92,83.92,0,0,0,12.21,20.81l.13.17c.82,1,1.68,2,2.53,2.91.48.53.95,1.09,1.44,1.61,35.14,37.15,99.19,30.89,127.47-19.4,6.89-13.78,11.31-29,19.18-41.9,6-12.44,12.2-24,17.87-30.42l0,.05a66.09,66.09,0,0,1,8.07-9.86c.27-.27.55-.5.83-.76,16.29-17.18,30.88-61.9,32.22-66.86.17-.65.36-1.29.53-1.93a127.79,127.79,0,0,1-26.56,16.3c-8.6,4.49-19.77,12.36-41.42,13.7l-.94.22c-.73,0-1.44,0-2.14-.08-1.83.05-3.73.06-5.71,0a68.38,68.38,0,0,1-7.45-.19c-2.33-.19-4.57-.52-6.79-.87A191.15,191.15,0,0,1,334,423.17c-16.26-3.26-29.24-8.7-39.49-16.14-14.41,8.53-28.73,2.84-36.64-1.87-5.53,21.42-12.05,45.56-19.16,70.39-.84,2.94-1.69,5.88-2.55,8.84-.06,2.58,0,5.2,0,7.83C236.28,503.91,237.29,521,241.05,535Z"
        />
        <path
          onClick={() => props.onFill(29)}
          fill={props.colors[29]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M471.86,726.65c.78,16.39,2.34,37.22,3.28,49,6.67.53,11.92.77,15.43.88,1.69,0,7.9,1.37,9.56,1.33,2.75-1.81,7.57-2.91,13.46-3.52a111.13,111.13,0,0,1,26.92-1.45c10.27.47,25.89,1,19.19-2.38s-16.51-1.9-29.45-5.71c-8.94-2.63-43.59-16.68-56.55-63.47C472.71,713.11,471.77,724.79,471.86,726.65Z"
        />
        <path
          onClick={() => props.onFill(30)}
          fill={props.colors[30]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M326.1,739.24c.76.46,1.52,1,2.29,1.4,5.18,3,10.54,5.67,16,8.14,6.87,3.11,13.89,5.86,20.95,8.35,11.09,3.91,22.26,7.22,33.06,10.42,0-.12,0-.24,0-.36a32.18,32.18,0,0,1,.54-6.43c.92-13.53.63-29.58-3.41-43.7-7.8-27.3-5.34-70.74-4.46-82.73.55-4.62,1.13-9.14,1.75-13.45,4.5-31.55,13.51-100.63,13.51-100.63a184.41,184.41,0,0,1,7.57-23.19,110.73,110.73,0,0,1,10.88-20.84l0-.05c-5.67,6.38-11.92,18-17.87,30.42-7.41,15.49-14.36,32.27-19.18,41.9-29.49,52.43-90.27,59-127.47,19.4-.49-.52-1-1.08-1.44-1.61-.85-1-1.71-1.9-2.53-2.91l-.13-.17a100.31,100.31,0,0,1-9.91-14.52A59.44,59.44,0,0,1,241.05,535c-3.76-14-4.77-31.09-4.89-42.78,0-3,0-5.69,0-7.83-.85,2.94-11.16,53.84-7.66,89.88,2.29,23.51,3.54,41.54,10.42,60.54,8.33,23,24.91,47.4,61.65,84.64A128.45,128.45,0,0,0,326.1,739.24Z"
        />
        <path
          onClick={() => props.onFill(31)}
          fill={props.colors[31]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M590,746.61a3.68,3.68,0,0,1-.35-.15l.35.16Z"
        />
        <path
          onClick={() => props.onFill(32)}
          fill={props.colors[32]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M571.68,736.48a102.87,102.87,0,0,1-25.58-26A102.37,102.37,0,0,0,571.68,736.48Z"
        />
        <path
          onClick={() => props.onFill(33)}
          fill={props.colors[33]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M699.27,495.11c-12.26,18.74-22.48,41.17-31.81,59.81a78.56,78.56,0,0,1-39,37.3c1,2.82,12.1,24.64,9.15,59.15,19.92-14.47,49.75-49.33,59.9-80.8A226.83,226.83,0,0,1,721,520.77c-2.65-3-5.22-6.1-7.81-9.18C708.52,506.07,703.91,500.53,699.27,495.11Z"
        />
        <path
          onClick={() => props.onFill(34)}
          fill={props.colors[34]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M776.19,461.65a89.6,89.6,0,0,0-14.86-4.2c-26-5.49-45.84,12.86-62.06,37.66,4.64,5.42,9.25,11,13.9,16.48,2.59,3.08,5.16,6.17,7.81,9.18,10.63-16.86,22.3-29.95,31.93-34.44C768.37,479.12,797,470.13,776.19,461.65Z"
        />
        <path
          onClick={() => props.onFill(35)}
          fill={props.colors[35]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M628.5,592.22C601,518.32,551.81,480.08,516,457.16c-21.95-14.05-38.76-40.92-48.94-60.83-.2.16-.42.32-.61.48-.17.64-.36,1.28-.53,1.93-1.34,5-15.93,49.68-32.22,66.86a44.14,44.14,0,0,1,12.51-8.6,34.23,34.23,0,0,1,12.35-2.88,33.76,33.76,0,0,1,8.15.68c12.44,2.54,28.35,11.76,32.54,26.43,3.79,13.24,5.51,39.39-.18,56.53l.18.08a319.62,319.62,0,0,1-5.76,34.59c-.39,1.8-.88,3.78-1.41,5.87a50.51,50.51,0,0,1,5-5.41c1.05-1,2.1-1.85,3.15-2.73a73.53,73.53,0,0,1,28.9-15.25c30.42,44.43-17,107.37,14.94,152.71.66,1,1.38,1.91,2.06,2.88a102.87,102.87,0,0,0,25.58,26l.21.14c2.31,1.61,4.66,3.13,7.07,4.52.38.23.79.41,1.17.63,2.12,1.19,4.26,2.35,6.44,3.39,1,.47,2,.86,3,1.3a3.68,3.68,0,0,0,.35.15c1.51.66,3,1.35,4.55,1.94,2.73,1,5.5,2,8.3,2.79a55.26,55.26,0,0,0-.24-5.64s24.9-31.87,32.09-75h0a145.61,145.61,0,0,0,3-19.31C640.6,616.86,629.55,595,628.5,592.22Z"
        />
        <path
          onClick={() => props.onFill(36)}
          fill={props.colors[36]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M590,746.62l-.35-.16c-1-.44-2-.83-3-1.3-2.18-1-4.32-2.2-6.44-3.39-.38-.22-.79-.4-1.17-.63-2.41-1.39-4.76-2.91-7.07-4.52l-.21-.14a102.37,102.37,0,0,1-25.58-26c-.68-1-1.4-1.87-2.06-2.88-31.9-45.34,15.48-108.28-14.94-152.71a73.53,73.53,0,0,0-28.9,15.25c-1.05.88-2.1,1.77-3.15,2.73a50.51,50.51,0,0,0-5,5.41c-1.8,7.05-4.12,15.4-5.19,22.85-1.33,9.21-2.79,18.84-4.28,28.33h0l-1.1,6.94c-.07.4-.13.82-.2,1.22-.16,1-.31,2-.47,3-1.4,8.83-5.54,35.77-7.72,58.74.18.68.37,1.33.56,2,13,46.79,47.61,60.84,56.55,63.47,12.94,3.81,22.76,2.38,29.45,5.71s-8.92,2.85-19.19,2.38a111.13,111.13,0,0,0-26.92,1.45,98.27,98.27,0,0,0-13.46,3.52c-6.1,2-10.18,7.66-12.94,14,.38.32.76.63,1.14,1,.1-.37.18-.76.3-1.12-.12.36-.2.75-.3,1.12a36.55,36.55,0,0,1,11.79,20.33c.3,1.55.54,3,.75,4.46a24.12,24.12,0,0,1,.54,12.44c0,.36-.09.69-.13,1A31.88,31.88,0,0,0,512,828.86a22.42,22.42,0,0,1-3.18-6c-4-19.5,10.71-29,10.71-29C506,811.5,517.18,827.81,521,831s22.8,6.07,35.43-9.41,25-22.28,33.53-25.61c4.38-1.71,6.94-6,8.39-9.77.34-1.12.65-2.3.94-3.59.08-.36.18-.68.25-1.06a174.19,174.19,0,0,0,2.74-18.45c.45-4.93.58-8.89.56-11.77-2.8-.82-5.57-1.76-8.3-2.79C593,748,591.48,747.27,590,746.62Z"
        />
        <path
          onClick={() => props.onFill(37)}
          fill={props.colors[37]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          d="M589.94,796c-8.56,3.33-20.91,10.14-33.53,25.61S524.78,834.18,521,831s-15-19.5-1.44-37.17c0,0-14.73,9.52-10.71,29a22.42,22.42,0,0,0,3.18,6,29.94,29.94,0,0,0,2.89-1.26l3.51,5.61c-.58.18-1.1.3-1.67.47,4.92,3.7,12.57,6.18,24.22,3,0,0,15.17-3.33,24.1-17.13s19.64-17.6,19.64-17.6,13.92-3.19,15.42-22.44a32,32,0,0,1-1.79,6.73C596.88,790,594.32,794.27,589.94,796Z"
        />
        <path
          onClick={() => props.onFill(38)}
          fill={props.colors[38]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          d="M514.9,827.6a29.94,29.94,0,0,1-2.89,1.26,31.88,31.88,0,0,1-10.73,2.24,24.14,24.14,0,0,1-1.22,5.15,53,53,0,0,0,16.68-2.57c.57-.17,1.09-.29,1.67-.47Z"
        />
        <path
          onClick={() => props.onFill(39)}
          fill={props.colors[39]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M424.81,476.22a110.73,110.73,0,0,0-10.88,20.84,184.41,184.41,0,0,0-7.57,23.19s-9,69.08-13.51,100.63c-.62,4.31-1.2,8.83-1.75,13.45-.88,12-3.34,55.43,4.46,82.73,4,14.12,4.33,30.17,3.41,43.7q-.23,3.33-.54,6.43c0,.12,0,.24,0,.36a196.47,196.47,0,0,1-3.68,23.28h0c-1.54,9.07-2.66,17.71.17,27.52.21.65.41,1.32.63,1.94l.3.82c7.79,20.51,22.22,22.83,28.75,20.32,7.5-2.89,4-28.84,4-28.84,5.19,18.45,6.92,34.6,24.8,31.14,9.07-1.75,12.49-8.55,13.67-14.83,0-3.23.19-6.44.37-8.93l-.2-2.77s.14.79.25,2.07c.17-2.28.33-3.8.33-3.8S466.66,840.28,480.5,842s17.31-7.5,16.73-20.18-7.5-19-13.26-23.65-16.15-8.65-23.07-8.65-29.42,3.46-24.23,0,31.15-6.35,31.72-13.84-1.15-36.34-1.72-50.18,6.33-81.48,15.95-96c1.49-9.49,2.95-19.12,4.28-28.33,1.07-7.45,3.39-15.8,5.19-22.85.53-2.09,1-4.07,1.41-5.87a319.62,319.62,0,0,0,5.76-34.59l-.18-.08c5.69-17.14,4-43.29.18-56.53-4.19-14.67-20.1-23.89-32.54-26.43a33.76,33.76,0,0,0-8.15-.68A34.23,34.23,0,0,0,446.22,457a44.14,44.14,0,0,0-12.51,8.6c-.28.26-.56.49-.83.76A66.09,66.09,0,0,0,424.81,476.22Z"
        />
        <path
          onClick={() => props.onFill(40)}
          fill={props.colors[40]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          d="M501.41,830.06a52.17,52.17,0,0,0-.54-12.44c-.21-1.44-.45-2.91-.75-4.46a36.55,36.55,0,0,0-11.79-20.33h0c-.38-.35-.76-.66-1.14-1s-1-.83-1.51-1.22l-.37-.28-1-.73-.23-.16-.5-.34a40.77,40.77,0,0,0-7.71-4.22s-.22-2.57-.55-6.73c-.06-.79-.13-1.61-.2-2.51-.94-11.79-2.5-32.62-3.28-49-.18-3.68-.08-8.31.21-13.49.24-4.29.61-9,1.07-13.81,2.18-23,6.32-49.91,7.72-58.74l.47-3c.07-.4.13-.82.2-1.22l1.1-6.94h0c-9.62,14.54-16.53,82.18-15.95,96s2.3,42.68,1.72,50.18-26.53,10.38-31.72,13.84,17.31,0,24.23,0,17.3,4,23.07,8.65,12.68,11,13.26,23.65S494.35,843.73,480.5,842s-12.68-26.53-12.68-26.53-.16,1.52-.33,3.8c.08.92.15,2.08.14,3.41L469,841.43c3.46,4,16.15,5.77,16.15,5.77,7.86-1.21,12.68-4.82,14.94-11a24.14,24.14,0,0,0,1.22-5.15C501.32,830.75,501.38,830.42,501.41,830.06Z"
        />
        <path
          onClick={() => props.onFill(41)}
          fill={props.colors[41]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M467.07,828.9a33.82,33.82,0,0,0,.56-6.22l-.19-2.71C467.26,822.46,467.09,825.67,467.07,828.9Z"
        />
        <path
          onClick={() => props.onFill(42)}
          fill={props.colors[42]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M467.44,820l.19,2.71c0-1.33-.06-2.49-.14-3.41-.11-1.28-.25-2.07-.25-2.07Z"
        />
        <path
          onClick={() => props.onFill(43)}
          fill={props.colors[43]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          d="M467.07,828.9c-1.18,6.28-4.6,13.08-13.67,14.83-17.88,3.46-19.61-12.69-24.8-31.14,0,0,3.46,25.95-4,28.84-6.53,2.51-21,.19-28.75-20.32l-.3-.82c-.22-.62-.42-1.29-.63-1.94a70.24,70.24,0,0,1-2-7.5,41.94,41.94,0,0,0,3,19.71,34.75,34.75,0,0,0,2,4c3.75,6.39,10.1,12.4,21,15.54,0,0,10.39,1.15,16.15-4.62,0,0,16.73,16.15,34-4l-1.34-18.75A33.82,33.82,0,0,1,467.07,828.9Z"
        />
        <path
          onClick={() => props.onFill(44)}
          fill={props.colors[44]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M212.75,348.43a37.26,37.26,0,0,1-.68-7.77c0-.25,0-.52.06-.78a37.43,37.43,0,0,1,.46-4.17c-8.32-40,7-70.37,29.32-81.75a210.08,210.08,0,0,1-1.46-24.11c-.42-38.8,7-115.18,66.7-159l.75-.46c-.37-.3-.74-.63-1.13-.9A20,20,0,0,0,301,66.73c-4.65-1.4-9.93-1-15.91,2.4-30.52,17.15-81.86,128.65-116.82,155.63s-59.09,46.1-49.82,84c2.06,8.43,9.73,17,20.74,24.34h0a108.47,108.47,0,0,0,12.33,7.05c12,5.88,25.93-.77,40.59,1.22C199.09,342.29,212.87,350,212.75,348.43Z"
        />
        <path
          onClick={() => props.onFill(45)}
          fill={props.colors[45]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M493,280.14c-23.15-28.52-21-56.18-33.27-97.46-16.89-36.26-44.82-38-82.85-24.53.9-1.69,1.86-3.45,2.86-5.27l-.32.11-.06.11c-1,1.93-2,3.86-3,5.8-.25.5-.52,1-.77,1.49q-1.82,3.65-3.51,7.3a1.11,1.11,0,0,1-.07.16c-2.23,4.85-4.29,9.74-6.11,14.67-.07.18-.14.36-.2.54q-1.28,3.47-2.4,6.95l-.13.4c-.76,2.39-1.46,4.8-2.1,7.21l-.21.83c-.55,2.15-1.06,4.31-1.51,6.48l-.21,1q-.71,3.54-1.22,7.1c-.05.32-.08.64-.12,1-.29,2.08-.51,4.17-.68,6.26,0,.45-.08.9-.11,1.35-.16,2.35-.26,4.71-.27,7.07,0,.33,0,.65,0,1q0,3.1.21,6.22c0,.53.06,1.07.1,1.61.18,2.35.43,4.72.78,7.09,0,.28.1.57.14.85q.49,3.18,1.2,6.36c.13.59.26,1.18.4,1.77.56,2.39,1.19,4.77,1.94,7.17,0,.18.12.37.18.55.71,2.23,1.52,4.46,2.39,6.7.24.62.49,1.24.74,1.87,1,2.42,2,4.84,3.24,7.28.88,1.54,1.84,2.92,2.78,4.34,22.33,31.82,62.41,12.61,91.81,53.77,11.77,19.14,11,31.44,8.64,45.6-.1.58-.19,1.17-.3,1.76,0,.21-.08.41-.11.62-.21,1.14-.44,2.28-.71,3.42-.1.44-.19.89-.3,1.33h0a83.15,83.15,0,0,1-2.49,8.16v0c12.79-10.62,20.77-22.51,25.44-34.63C489.69,336.25,490.92,306.29,493,280.14ZM387.48,274.2l.57.53Zm45.16,1.25S425.1,285.14,417,286.1c-13.66,1.61-30-12.4-30-12.4s-1.68-26.88,6.83-40.66l.17-.24.09-.11c.15-.19.36-.45.65-.76s.65-.68,1.07-1.07l.17-.15a18.2,18.2,0,0,1,3.54-2.46l.24-.13.81-.4c3.84-2.38,8.92-3.65,15.63-2.05,20.24,4.83,21.58,20.89,21.78,30.42S432.64,275.45,432.64,275.45Z"
        />
        <path
          onClick={() => props.onFill(46)}
          fill={props.colors[46]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M240.45,229.85c12-20.44,15.28-49.95,31.32-72.62,17.32-20.87,48.37-29.9,62.66-10.9,4.12-20,9.78-39.81,19.86-60l0,0h0c26.28-38.36,64.87,3.81,49.91,25.4,0,.06-.06.11-.1.16-7.61,11.83-17.15,27.84-24.42,41-1,1.82-2,3.58-2.86,5.27,38-13.43,66-11.73,82.85,24.53C472,224,469.81,251.62,493,280.14c1.29-16.59,2.9-31.65,3.91-43C501.44,186,474.52,93.65,474.52,93.65c.58.64,1.15,1.33,1.71,2-.49-1.63-.93-3.22-1.44-4.85,0,0,1-.18,2.81-.39-14.33-16.58-34-27.52-62-36.95-49.7-16.71-101,12.84-107.7,16.91l-.75.46C247.48,114.67,240,191.05,240.45,229.85Z"
        />
        <path
          onClick={() => props.onFill(47)}
          fill={props.colors[47]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M294.55,407c6-3.53,11.93-9.49,17.47-19,0,0,22.81,32.74,63.43,37.84A138.38,138.38,0,0,0,389.69,427c2,0,3.88,0,5.71,0,1.05,0,2.08-.08,3.08-.14,21.65-1.34,32.82-9.21,41.42-13.7a127.79,127.79,0,0,0,26.56-16.3c.19-.16.41-.32.61-.48l.28-.21h0v0a83.15,83.15,0,0,0,2.49-8.16h0c.11-.44.2-.89.3-1.33.27-1.14.5-2.28.71-3.42,0-.21.08-.41.11-.62.11-.59.2-1.18.3-1.76,2.35-14.16,3.13-26.46-8.64-45.6-29.4-41.16-69.48-21.95-91.81-53.77-1-1.36-1.89-2.78-2.78-4.34-1.19-2.44-2.25-4.86-3.24-7.28-.25-.63-.5-1.25-.74-1.87-.87-2.24-1.68-4.47-2.39-6.7-.06-.18-.13-.37-.18-.55-.75-2.4-1.38-4.78-1.94-7.17-.14-.59-.27-1.18-.4-1.77q-.7-3.18-1.2-6.36c0-.28-.1-.57-.14-.85-.35-2.37-.6-4.74-.78-7.09,0-.54-.07-1.08-.1-1.61q-.19-3.12-.21-6.22c0-.33,0-.65,0-1,0-2.36.11-4.72.27-7.07,0-.45.08-.9.11-1.35.17-2.09.39-4.18.68-6.26,0-.32.07-.64.12-1q.51-3.56,1.22-7.1l.21-1c.45-2.17,1-4.33,1.51-6.48l.21-.83c.64-2.41,1.34-4.82,2.1-7.21l.13-.4q1.13-3.48,2.4-6.95c.06-.18.13-.36.2-.54,1.82-4.93,3.88-9.81,6.11-14.67a1.11,1.11,0,0,0,.07-.16q1.69-3.66,3.51-7.3c.25-.5.52-1,.77-1.49,1-1.94,2-3.87,3-5.8l.06-.11c7.47-14,16.08-27.69,24.74-41.14,0,0,.06-.1.1-.16,15-21.59-23.63-63.76-49.91-25.4h0l0,0c-10.08,20.2-15.74,40-19.86,60C325.88,187.79,324,229.85,303.2,277c-8.39,19-41.76,22-60.46,26.86-15.39,4-21.26,9.13-27.44,22.82a36.07,36.07,0,0,0-2.71,9.08,37.43,37.43,0,0,0-.46,4.17c0,.26,0,.53-.06.78a37.26,37.26,0,0,0,.68,7.77c.12,1.56,11.54,37.35,38.9,52.32a44.28,44.28,0,0,0,6.26,4.41C265.82,409.87,280.14,415.56,294.55,407Zm-18-86.85c2.25-14.66,9.69-17.52,9.69-17.52s27.07-18.13,61.74,8.46c0,0,10.55,5.55,6.51,19.52-3.13,10.83-41.53,26.14-44.31,37.73C310.21,368.37,273.13,342.68,276.58,320.18Z"
        />
        <path
          onClick={() => props.onFill(48)}
          fill={props.colors[48]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M476.23,95.69c-.56-.71-1.13-1.4-1.71-2,0,0,26.92,92.39,22.35,143.52-1,11.32-2.62,26.38-3.91,43-2,26.15-3.27,56.11-.17,81.35,3.81,31.1,14.2,55,37.87,55.75,0,0,50.94,1.64,89.5-42.07,17.41-18.29,30.76-41.54,30.87-67.25.09-20.85-25.91-67.81-35.17-84.07-22.26-39.08-49.82-86-67.79-103.3-36.79-35.51-51-32.68-66-30.3,0-.08-.08-.17-.13-.25-1.74.15-3.2.31-4.36.45-1.79.21-2.81.39-2.81.39C475.3,92.47,475.74,94.06,476.23,95.69Z"
        />
        <path
          onClick={() => props.onFill(49)}
          fill={props.colors[49]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M416.18,225.67c-6.71-1.6-11.79-.33-15.63,2.05l-.25.17c-.18.08-.39.15-.56.23l-.24.13a18.65,18.65,0,0,0-3.54,2.46l-.17.15c-.42.39-.78.75-1.07,1.07s-.5.57-.65.76l-.09.11-.17.24C385.3,246.82,387,273.7,387,273.7s16.36,14,30,12.4c8.1-1,15.64-10.65,15.64-10.65s5.52-9.83,5.32-19.36S436.42,230.5,416.18,225.67Z"
        />
        <path
          onClick={() => props.onFill(50)}
          fill={props.colors[50]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M388.05,274.73l-.57-.53"
        />
        <path
          onClick={() => props.onFill(51)}
          fill={props.colors[51]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M394.72,231.93c.29-.32.65-.68,1.07-1.07"
        />
        <path
          onClick={() => props.onFill(52)}
          fill={props.colors[52]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M394.07,232.69c.15-.19.36-.45.65-.76"
        />
        <path
          onClick={() => props.onFill(53)}
          fill={props.colors[53]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M400.55,227.72l-.81.4c.17-.08.38-.15.56-.23Z"
        />
        <path
          onClick={() => props.onFill(54)}
          fill={props.colors[54]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M393.81,233l.17-.24Z"
        />
        <path
          onClick={() => props.onFill(55)}
          fill={props.colors[55]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M396,230.71a18.65,18.65,0,0,1,3.54-2.46A18.2,18.2,0,0,0,396,230.71Z"
        />
        <path
          onClick={() => props.onFill(56)}
          fill={props.colors[56]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M292.63,227.74l-.08-.1a15.16,15.16,0,0,0-1.67-1.77l-.16-.14a17.48,17.48,0,0,0-3.44-2.38,1.39,1.39,0,0,0-.21-.11c-.25-.14-.51-.27-.78-.4-3.71-2.3-8.62-3.53-15.1-2-19.55,4.66-20.84,20.17-21,29.38s5.13,29.16,20.25,29c13.28-.16,24.52-7.8,27.93-11h0c.68-.62,1.07-1,1.07-1s1.61-26-6.6-39.28Z"
        />
      </g>
    </svg>
  );
};

Puppy.propTypes = {
  onFill: PropTypes.func,
  colors: PropTypes.array,
};

export default Puppy;
