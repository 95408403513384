export const Config = {
  KEY: 'MOVEMENT',
  ANIMATIONS: {
    IDLE: {
      frames: [24],
      frameRate: 12,
      repeat: 0,
    },
    IDLE2: {
      frames: [0, 1, 2, 3],
      frameRate: 14,
      repeat: -1,
      yoyo: true,
    },
    BEND: {
      frames: [24, 4, 5, 6],
      frameRate: 12,
      repeat: 0,
    },
    WAVE: {
      frames: [15, 16, 17, 18],
      frameRate: 12,
      repeat: 2,
      yoyo: true,
    },
    WALK: {
      frames: [19, 20, 21, 22, 23],
      frameRate: 16,
      repeat: -1,
    },
    LAY: {
      frames: [4, 5, 6, 7],
      frameRate: 12,
      repeat: 0,
    },
    SLEEP: {
      frames: [8],
      frameRate: 12,
      repeat: 0,
    },
    THIRSTY: {
      frames: [9],
      frameRate: 14,
      repeat: 0,
    },
    SITBENDDRINK: {
      frames: [10, 11],
      frameRate: 14,
      repeat: 0,
    },
    DRINK: {
      frames: [12, 13, 14],
      frameRate: 12,
      repeat: -1,
      yoyo: true,
    },
  },
  ATTACHPOINTS: {
    MOUTH: {},
    NECK: {},
  },
};
