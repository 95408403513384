import { stressReduction } from 'Assets/img/my-tools-icons';

export const stressReductionEn = {
  key: 'countdown-stress',
  title: 'Stress Reduction',
  titleImage: stressReduction,
  titleImageAlt: '',
  description: 'Using the senses can help you feel ‘grounded’ in the present moment when challenges arise.',
  contentId: 6,
  sections: [
    {
      key: 'calm-breaths',
      header: 'First, begin by taking a few calming deep breaths, then look around you.',
      description: '',
      itemsHeader: 'Tell me more',
      items: [
        { text: 'Slowly breathe in for 5 seconds, hold for 5 seconds, and exhale slowly for 5 seconds.' },
        { text: 'Then, look around you.' },
      ],
    },
    {
      key: 'focus-around',
      header:
        'Next, describe the environment with 5 things you can see; 4 things you can feel; 3 things you can hear; 2 things you can smell; and 1 thing you can taste.',
      description: '',
      itemsHeader: 'Tell me more',
      items: [
        { text: 'Focus on the space immediately around you.' },
        { text: 'Describe it through your sensory perceptions of sight, sound, smell, touch, and taste.' },
        { text: 'It’s okay to say your descriptions out loud or in your head.' },
        {
          text: 'What are the things you notice?',
          subItems: [
            { text: '5 things you can see?' },
            { text: '4 things you can feel?' },
            { text: '3 things you can hear?' },
            { text: '2 things you can smell?' },
            { text: '1 thing you can taste?' },
          ],
        },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your experience.',
      itemsHeader: 'Insights & pointers',
      items: [
        { text: 'How did you feel before completing this exercise?' },
        { text: 'How do you feel after it?' },
        { text: 'What part(s) of this exercise did you find most challenging? Why?' },
        { text: 'What did you enjoy most about this exercise?' },
        {
          text: 'On a scale of 1 (not stressed at all) to 10 (extremely stressed), how would you rate the stress you felt before completing this exercise?',
        },
        {
          text: 'On a scale of 1 (not stressed at all) to 10 (extremely stressed), how would you rate the stress you feel after completing this exercise?',
          divider: true,
        },
        {
          text: 'Purposely describing your surroundings using your senses can help block runaway thoughts or rising emotions, and support feeling more ‘grounded’ and balanced in the present moment.',
        },
        {
          text: 'Sensory perceptions can help to manage stressful situations and strengthen the ability to feel balanced facing challenges.',
        },
        {
          text: 'Try using your senses to redirect attention away from any negative feelings of stress and refocus attention to maintain balance.',
        },
      ],
    },
  ],
};
