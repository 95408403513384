import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownInteractiveElement,
  DropdownLabel,
  DropdownContainer,
  DropdownList,
  DropdownListItem,
  DropdownWrapper,
} from './styles';

import { useIntl } from 'react-intl';

const Dropdown = ({ currentValue, items, title, handleSelection }) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(currentValue);

  const handleOnClick = () => {
    setOpen(!open);
  };

  const handleDropdownSelection = (e) => {
    setSelected(items.find((i) => i.value == e.target.value));
    setOpen(!open);
    handleSelection(e);
  };

  return (
    <DropdownWrapper className="DropdownWrapper" role="listbox" aria-label={title}>
      <DropdownContainer>
        <DropdownInteractiveElement
          data-testid="DropdownOptions"
          className={open ? 'DropdownInteractiveElementOpen' : 'DropdownInteractiveElementClosed'}
          tabIndex={0}
          role="button"
          onKeyPress={() => handleOnClick()}
          onClick={() => handleOnClick()}
        >
          <DropdownLabel id="DropdownLabel" data-testid="DropdownLabel" className="DropdownLabel">
            {title}
          </DropdownLabel>
          <i className="fas fa-caret-down" style={{ marginLeft: '0.5em' }}></i>
          {!open ? <div style={{ fontSize: '1.25em' }}>{intl.formatMessage({ id: `${selected.text}` })}</div> : null}
        </DropdownInteractiveElement>
      </DropdownContainer>
      {open && (
        <DropdownList>
          {items.map((item, index) => (
            <DropdownListItem key={index}>
              <button
                data-testid={item.testId}
                className="listItemButton"
                value={item.value}
                onClick={(e) => handleDropdownSelection(e)}
              >
                {intl.formatMessage({ id: `${item.text}` })}
              </button>
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

Dropdown.propTypes = {
  items: PropTypes.array,
  currentValue: PropTypes.object,
  title: PropTypes.string,
  handleSelection: PropTypes.func,
};

export default Dropdown;
