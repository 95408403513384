import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import PageHeader from 'Core_Components/PageHeader/PageHeader';
import ButtonRow from 'Core_Pages/GreetingCards/ButtonRow/ButtonRow';
import {
  CardContentContainer,
  StyledImage,
  StyledParagraph,
} from 'Core_Pages/GreetingCards/GreetingCardsSubmission/styles';
import { IntlProvider, useIntl } from 'react-intl';
import GreetingCard from '../GreetingCard/GreetingCard';
import useCreateCard from 'Core_Hooks/useCreateCard';
import useSendCard from 'Core_Hooks/useSendCard';
import paperAirplane from 'Assets/img/font-awesome-paper-plane.svg';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import Spinner from 'Core_Components/Spinner/Spinner';
import { StyledContainer, StyledOutlineButton, StyledSolidButton } from 'Core_Pages/GreetingCards/featureStyles';
import ReactDOMServer from 'react-dom/server';
import en from 'Translations/en.json';
import useCreateCardImage from 'Core_Hooks/useCreateCardImage';

function GreetingCardsSubmission({ user, card, image }) {
  const intl = useIntl();
  const history = useHistory();
  const { cardInformation, cardIsPending, createCard } = useCreateCard();
  const { cardImageInformation, cardImageIsPending, createCardImage } = useCreateCardImage();
  const { cardSendIsPending, sendCard } = useSendCard();

  useEffect(() => {
    if (cardInformation && !cardSendIsPending) {
      sendCard(cardInformation, { SentToUserId: null });
    }
  }, [cardIsPending]);

  useEffect(() => {
    if (cardImageInformation) {
      createCard({ MessageId: card?.messageId, ImageId: cardImageInformation });
    }
  }, [cardImageIsPending]);

  const onSendClick = () => {
    if (image) {
      const formData = new FormData();
      const svgString = ReactDOMServer.renderToString(
        <IntlProvider locale={'en'} messages={en}>
          {image}
        </IntlProvider>,
      );
      formData.append('image', svgString);
      createCardImage(formData);
    } else {
      createCard({ MessageId: card?.messageId, ImageId: card?.imageId });
    }
  };

  const showSentScreen = () => {
    return (
      <>
        <PageHeader>{intl.formatMessage({ id: 'greeting-cards.submission.header-sent' })}</PageHeader>
        <StyledParagraph>
          {intl.formatMessage({ id: 'greeting-cards.submission.description-sent-part-1' })}
        </StyledParagraph>
        <StyledImage src={paperAirplane} alt={intl.formatMessage({ id: 'image.greeting-cards.sent' })} />
        <StyledParagraph>
          {intl.formatMessage({ id: 'greeting-cards.submission.description-sent-part-2' })}
        </StyledParagraph>
        <ButtonRow>
          <StyledOutlineButton onClick={() => history.push(paths.GREETINGCARDSINBOX)}>
            {intl.formatMessage({ id: 'greeting-cards.submission.inbox-button' })}
          </StyledOutlineButton>
          <StyledSolidButton onClick={() => history.push(paths.HOME)}>
            {intl.formatMessage({ id: 'greeting-cards.inbox.done-button' })}
          </StyledSolidButton>
        </ButtonRow>
      </>
    );
  };

  const showCardScreen = () => {
    return (
      <>
        <PageHeader>{intl.formatMessage({ id: 'greeting-cards.submission.header' })}</PageHeader>
        <CardContentContainer>
          <GreetingCard
            image={image}
            cardImage={card?.storagePath}
            cardText={card?.message}
            cardFrom={user}
            imageDescription={card?.imageDescription}
          />
        </CardContentContainer>
        <ButtonRow>
          <StyledSolidButton onClick={() => onSendClick()}>
            {intl.formatMessage({ id: 'greeting-cards.submission.send-button' })}
          </StyledSolidButton>
        </ButtonRow>
      </>
    );
  };

  return (
    <StyledContainer>
      {cardImageIsPending || cardIsPending || cardSendIsPending ? (
        <Spinner />
      ) : cardInformation ? (
        showSentScreen()
      ) : (
        showCardScreen()
      )}
    </StyledContainer>
  );
}

GreetingCardsSubmission.displayName = 'GreetingCardsSubmission';

GreetingCardsSubmission.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, () => undefined]),
  card: PropTypes.object.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, () => undefined]),
};

export default GreetingCardsSubmission;
