import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ResultContext, ResultStates } from '../ResultContext';
import { Forms } from '../../forms';
import { StyledDescription, StyledDescriptionButton } from '../../styles';
import { useIntl } from 'react-intl';
import ResourceButton from '../../components/ResultsResources/ResourceButton/ResourceButton';
import Button from 'Stories/components/Button/Button';

const Description = ({ DefaultComponent, ErrorComponent, ...rest }) => {
  const intl = useIntl();
  const { screeningGuid, state, variant } = useContext(ResultContext);

  const renderDefault = () => (
    <>
      <StyledDescription id="results-description" fontSize={18} fontWeight={700} color="white" paddingBottom={'20px'}>
        {intl.formatMessage({ id: 'screenings.results.youth.description.contact' })}
      </StyledDescription>
      <StyledDescriptionButton
        variant={Button.variant.TEXT}
        value={process.env.ANDY_PHONE_NUMBER}
        type={ResourceButton.variant.PHONE}
      >
        {process.env.ANDY_PHONE_NUMBER}
      </StyledDescriptionButton>
    </>
  );

  const ComponentStates = {
    [ResultStates.Default]: DefaultComponent ?? renderDefault,
    [ResultStates.Error]: ErrorComponent ?? renderDefault,
  };

  if (state !== ResultStates.Found) {
    const Component = ComponentStates[state];
    if (!Component) return <></>;
    return <Component />;
  }

  const FormComponent = Forms[screeningGuid][variant];

  return <FormComponent {...rest} />;
};

Description.propTypes = {
  DefaultComponent: PropTypes.element,
  ErrorComponent: PropTypes.element,
};

export default Description;
