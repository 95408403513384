import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledParagraphWithMediaQuery,
  StyledEnvelopeImage,
  StyledParagraphStacked,
} from 'Core_Pages/GreetingCards/GreetingCardsLanding/styles';
import { StyledContainer, StyledOutlineButton, StyledSolidButton } from 'Core_Pages/GreetingCards/featureStyles';
import PageHeader from 'Core_Components/PageHeader/PageHeader';
import ButtonRow from 'Core_Pages/GreetingCards/ButtonRow/ButtonRow';
import greetingCard from 'Assets/img/greeting-card.svg';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as paths from 'Core_Pages/Routes/RoutesConfig';

function GreetingCardsLanding({ user }) {
  const intl = useIntl();
  const history = useHistory();

  function handleGreeting() {
    return `${intl.formatMessage({ id: 'common.hi' })}, ${user}!`;
  }

  const handleInboxClick = () => {
    history.push(paths.GREETINGCARDSINBOX);
  };
  const handleDesignNewCardClick = () => {
    history.push(paths.DESIGNYOURCARD);
  };

  return (
    <StyledContainer>
      <PageHeader>{intl.formatMessage({ id: 'title.greeting-cards' })}</PageHeader>
      <StyledParagraphWithMediaQuery>{handleGreeting()}</StyledParagraphWithMediaQuery>
      <StyledParagraphStacked>{intl.formatMessage({ id: 'greeting-cards.landing.part-1' })}</StyledParagraphStacked>
      <StyledParagraphStacked>{intl.formatMessage({ id: 'greeting-cards.landing.part-2' })}</StyledParagraphStacked>
      <StyledEnvelopeImage alt={intl.formatMessage({ id: 'image.greeting-cards.envelope' })} src={greetingCard} />
      <ButtonRow>
        <StyledOutlineButton onClick={handleInboxClick}>
          {intl.formatMessage({ id: 'greeting-cards.landing.inbox-button' })}
        </StyledOutlineButton>
        <StyledSolidButton onClick={handleDesignNewCardClick}>
          {intl.formatMessage({ id: 'greeting-cards.landing.new-card-button' })}
        </StyledSolidButton>
      </ButtonRow>
    </StyledContainer>
  );
}

GreetingCardsLanding.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, () => undefined]),
};

export default GreetingCardsLanding;
