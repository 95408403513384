import { knowingWhenYouNeedHelp } from 'Assets/img/my-tools-icons';

export const knowingWhenYouNeedHelpEs = {
  key: 'knowing-when-you-need-help-es',
  title: 'Cómo saber cuando necesitas ayuda',
  titleImage: knowingWhenYouNeedHelp,
  titleImageAlt: '',
  contentId: 35,
  sections: [
    {
      key: 'knowing-when-you-need-help-intro-es',
      description:
        '¿Cómo sabes cuando necesitas ayuda? Después de dedicar tu tiempo, energía y apoyo emocional a los demás, puede resultar difícil saber cuándo es el momento de buscar ayuda para ti mismo. Ser cuidador puede hacer que te sientas aislado y solitario, especialmente si cuidas a un ser querido que tal vez no pueda comunicarse o hacerte compañía de la manera que necesitas. Cuando hay tantas emociones entorno a una vida cotidiana llena de tareas y de entregarse a los demás, es completamente normal experimentar una variedad de sentimientos como tristeza o nerviosismo. Está bien que te tomes un tiempo para ti. Serás un mejor cuidador cuando te cuides a ti mismo/a. Estas son algunas pautas generales para saber cuándo pedir ayuda.',
    },
    {
      key: 'sign-of-help-es',
      header: 'Señales seguras de que necesitas ayuda',
      description:
        'Si has tenido pensamientos de querer lastimarte a ti mismo/a o a otra persona, sientes que la vida podría no valer la pena, duermes demasiado o tienes poca energía, o encuentras poco interés o placer en hacer cosas, todas estas son señales claras de que debes buscar ayuda.',
    },
    {
      key: 'trust-gut-es',
      header: 'Confía en tu instinto',
      description:
        'Cuando piensas en tus últimos meses, ¿has sentido que tus emociones son diferentes o extrañas? Por ejemplo, ¿hay varios días en los que no te sientes motivado/a? ¿Te sientes enojado/a sin una buena explicación? Esto puede ser una señal de que hay sentimientos subyacentes que debes superar.',
    },
    {
      key: 'family-friends-es',
      header: 'Tus familiares o amigos lo expresan',
      description:
        'Incluso aunque sientas que puedes manejar tus emociones, las personas más cercanas a ti saben cuándo podrías estar enfrentando dificultades. Confía en ellos. A veces, los estallidos frecuentes de ira o emociones tristes inexplicables les indican a nuestros seres queridos que necesitas ayuda.',
    },
    {
      key: 'feeling-sad-es',
      header: 'Sentirse triste',
      description:
        'Cuando algo en tu vida te causa decepción o dolor, los sentimientos de tristeza son completamente normales. Si esos sentimientos no se van resolviendo con el tiempo y comienzas a sentirte más desesperado o pierdes interés en actividades o tareas agradables, es ahí donde puede haber un cambio hacia la depresión. La depresión es una afección mental que puede mejorar con la ayuda adecuada. Si tienes sentimientos diarios (o casi diarios) de tristeza o desesperanza inexplicables, ya no sientes placer al hacer actividades que normalmente disfrutas, o estás cansado más de lo habitual, todos estos son sentimientos que no son normales y debes buscar ayuda.',
    },
    {
      key: 'feeling-nervous-es',
      header: 'Sentirse nervioso/a',
      description:
        'Hay muchas razones por las que puedes sentirte nervioso/a. A veces, cuando una relación o algo que sucede en nuestras vidas nos molesta, tendemos a pensar mucho en ello y a preocuparnos. Si bien puede ser normal sentirse preocupado o nervioso, no lo es cuando comienzas a tener síntomas físicos como dolor de cabeza o estómago, o si sientes que tu corazón se acelera. Los sentimientos diarios (o casi diarios) de nerviosismo o pensamientos preocupantes que no desaparecen pueden ser un síntoma de ansiedad. ¡Tener ansiedad es muy común y puedes mejorar con ayuda! Si crees que puede estar teniendo síntomas de ansiedad, debes buscar ayuda.',
    },
    {
      key: 'reach-out-for-help-es',
      header: 'Buscar ayuda',
      description:
        'En primer lugar, ¡felicidades por dar el primer paso para sentirte mejor! A continuación, ¿tienes un médico? Si la respuesta es sí, es probable que tu médico pueda ofrecerte excelentes referencias de terapeutas de la salud mental que puedan ayudarte a procesar tus emociones. En segundo lugar, ¿tienes seguro médico? Comunícate con tu compañía de seguro médico llamando al número de servicios para miembros que figura en su tarjeta o busca en línea o en tu portal para pacientes. También tenemos recursos en tu área en la pestaña de recursos de nuestra pantalla de inicio. Lo más importante es hablar y buscar ayuda para asegurarte de recibir el apoyo que necesitas.',
    },
  ],
};
