import React, { useContext, useEffect } from 'react';
import { useOnboardSelector } from 'Contexts/StoreContext';
import { useHistory } from 'react-router-dom';
import { selectStep } from 'Onboard_Redux/login';
import { stepToComponentMap } from 'Onboard_Components/Account/LogIn/steps';
import Layout from 'Onboard_Pages/Account/Layout';
import Toggle from 'Onboard_Components/Account/Toggle';
import { SIGNUP } from 'Onboard_Pages/Routes/RoutesConfig';
import { SplashScreen } from '@capacitor/splash-screen';
import { isNative } from 'Onboard_Helpers/isNative';
import { selectUserVerified, selectSyncUser, selectUserInfo } from 'Onboard_Redux/user';
import { selectIsAuthenticated } from 'Onboard_Redux/authentication';
import { ExperienceContext } from 'Contexts/ExperienceContext';
import { selectSignupMetadata } from 'Onboard_Redux/signup';

function LogIn() {
  const history = useHistory();
  const currentStep = useOnboardSelector(selectStep);
  const Step = stepToComponentMap[currentStep];
  const userVerified = useOnboardSelector(selectUserVerified);
  const syncUser = useOnboardSelector(selectSyncUser);
  const user = useOnboardSelector(selectUserInfo);
  const isLoggedIn = useOnboardSelector(selectIsAuthenticated);
  const signupMetadata = useOnboardSelector(selectSignupMetadata);
  const experience = useContext(ExperienceContext);

  useEffect(() => {
    if (isNative()) {
      SplashScreen.hide();
    }
  }, []);

  useEffect(() => {
    // Redetermine experience
    if (isLoggedIn && userVerified && syncUser?.activeProfileId) {
      experience.experienceUpdatedAsync();
    } else if (
      isLoggedIn &&
      user &&
      (!userVerified || (userVerified && syncUser?.activeProfileId === null)) &&
      signupMetadata?.progress < 1
    ) {
      // Redirect to signup
      // IF user phone number is not verified
      // OR if user phone number is verified but user has no active profile
      // AND check sign up progress to allow user to toggle between login and signup
      history.push(SIGNUP);
    }
  }, [isLoggedIn, userVerified, syncUser]);

  function onToggle() {
    history.push(SIGNUP);
  }

  return (
    <Layout>
      <Toggle selectedOption={Toggle.states.LOGIN} onToggle={onToggle} />
      <Step />
    </Layout>
  );
}

export default LogIn;
