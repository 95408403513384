import { supportingMentalHealthNeeds } from 'Assets/img/my-tools-icons';

export const supportingChildWithTraumaEs = {
  key: 'supporting-child-with-trauma-es',
  title: 'Apoyar a mi hijo/a con su trauma',
  titleImage: supportingMentalHealthNeeds,
  titleImageAlt: '',
  contentId: 53,
  sections: [
    {
      key: 'supporting-child-with-trauma-intro-es',
      description:
        '¿Por qué es importante aprender acerca de los traumas? Los traumas son muy diversos. La inestabilidad que siente un niño o niña de crianza también puede inducir una respuesta traumática. Muchos acontecimientos o circunstancias en la vida de tu hijo/a antes o durante el tiempo que está contigo se consideran traumas. Mirar a tu hijo a través de la “lente del trauma” puede ayudarte a comprender mejor que algunos de sus enojos, arrebatos, frustración, tristeza o soledad pueden expresar un trauma. Estos consejos te ayudarán a apoyar a tu hijo/a con su trauma.',
    },
    {
      key: 'physical-touch-es',
      header: 'Contacto físico',
      description:
        'Puedes saber o no si tu hijo/a ha sufrido abusos físicos o sexuales en el pasado. Aunque el contacto humano y el tacto son una parte importante de nuestras vidas, los niños que sufrieron abusos en el pasado pueden tener una fuerte aversión al contacto.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Es importante pedir consentimiento siempre antes de hacer contacto físico. Esto incluye preguntar antes de dar un abrazo, tomar la mano, dar una palmada en la espalda o cualquier otro tipo de contacto físico.',
        },
        {
          text: 'A medida que los niños superen su trauma, déjalos tomar la iniciativa en cualquier tipo de contacto físico. Estarán reaprendiendo a distinguir el buen contacto del malo en su propio proceso terapéutico.',
        },
        {
          text: 'Lo que tú percibes como contacto afectuoso y apropiado puede resultar incómodo o incluso peligroso para un niño o niña maltratado.',
        },
        {
          text: 'Si tu hijo/a ha sido maltratado o muestra signos de haberlos sufrido (como estremecimiento cuando te acercas a él o una fuerte aversión al contacto saludable), asegúrate de que reciba terapia de salud mental.',
        },
      ],
    },
    {
      key: 'create-safe-space-es',
      header: 'Crear un espacio seguro',
      description:
        'Crea un plan con tu hijo/a para conversar con él o ella acerca de lo que cree que puede ayudarle más cuando esté enojado/a, triste o ansioso/a, o experimente una emoción que puede ser difícil de controlar. Ayudar a tu hijo/a a determinar cómo puede ayudarse a sí mismo/a también le empodera. Aquí tienes algunos ejemplos:',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Si tu hijo/a presenta agresividad física, crea una zona donde pueda expresar su enojo. Por ejemplo, un bate blando, almohadas o cojines, o una colchoneta donde pueda liberar físicamente su ira de forma segura.',
        },
        {
          text: 'Guía a tu hijo/a en prácticas de respiración profunda u otras actividades de relajación. Encontrarás algunos ejemplos de actividades de relajación en nuestra aplicación.',
        },
        {
          text: 'Ten “palabras seguras” que puedas utilizar cuando necesite espacio. Por ejemplo, tu hijo/a podría elegir una palabra segura, como “caimán” si está empezando a enojarse y necesita sentarse en su habitación o si necesita tu ayuda para salir de una situación.',
        },
        {
          text: 'Utiliza aromas relajantes como lavanda, menta o naranja en sus “espacios seguros”.',
        },
        {
          text: 'Crea un espacio de actividades que tenga artículos divertidos y relajantes donde tu hijo/a pueda ir siempre que empiece a sentirse enojado/a. Puede ser una habitación separada o una zona de la habitación que tenga cosas como libros para colorear, una silla de puff o una manta con peso, objectos sensoriales como arena cinética y animales de peluche divertidos.',
        },
        {
          text: 'Utiliza música suave para cambiar el ánimo o el tono.',
        },
      ],
    },
    {
      key: 'learn-about-trauma-es',
      header: 'Aprender acerca de los traumas',
      description:
        'Existen excelentes capacitaciones y materiales educativos para ayudarte a comprender mejor cómo puede estar afectando el trauma a tu hijo/a.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Tu agencia de crianza puede proporcionarte materiales o clases para ayudarte a aprender acerca de los traumas.',
        },
        {
          text: 'Habla con los consejeros o terapeutas de tu hijo/a para conocer más acerca de cómo están ayudando a tu hijo/a y cómo puedes apoyar sus esfuerzos.',
        },
      ],
    },
  ],
};
