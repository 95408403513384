import { uiTypes } from 'Core_Games/constants/uiTypes';
import { gameData } from 'Core_Games/constants/gameData';
import { animationType } from 'Core_Games/constants/animationType';
import { InteractionType } from 'Core_Games/constants/interactionType';
import { playerActorType } from 'Core_Games/constants/playerActorType';

export const keySceneDataTypes = {
  // Objects
  BACKGROUND: 'BACKGROUND',
  FOREGROUND: 'FOREGROUND',
  // NPCs
  HUMAN_FAMILY: 'HUMAN_FAMILY',
  HUMAN_OLDMAN: 'HUMAN_OLDMAN',
  HUMAN_WHEELCHAIRWOMAN: 'HUMAN_WHEELCHAIRWOMAN',
  // Captions
  STROLL_INTRO_CAPTION: 'STROLL_INTRO_CAPTION',
  STROLL_CHATPROMPT_CAPTION: 'STROLL_CHATPROMPT_CAPTION',
  STROLL_CARE_CAPTION: 'STROLL_CARE_CAPTION',
  STROLL_BACK_CAPTION: 'STROLL_BACK_CAPTION',
  // Buttons
  READY_BUTTON: 'READY_BUTTON',
  NOTTODAY_BUTTON: 'NOTTODAY_BUTTON',
  YES_BUTTON: 'YES_BUTTON',
  LAUGH_BUTTON: 'LAUGH_BUTTON',
  YESOUTRO_BUTTON: 'YESOUTRO_BUTTON',
  NOTTODAY_OUTRO_BUTTON: 'NOTTODAY_OUTRO_BUTTON',
  BACK_BUTTON: 'BACK_BUTTON',
  // Screen
  STROLL_SCREEN_INTRO: 'STROLL_SCREEN_INTRO',
  STROLL_SCREEN_CHATPROMPT: 'STROLL_SCREEN_CHATPROMPT',
  STROLL_SCREEN_CARE: 'STROLL_SCREEN_CARE',
  STROLL_SCREEN_CHAT_CONTINUE: 'STROLL_SCREEN_CHAT_CONTINUE',
  STROLL_SCREEN_BACK: 'STROLL_SCREEN_BACK',
  // Chat Bubbles
  FAMILY_GREETING_CHATBUBBLE: 'FAMILYGREETING_CHATBUBBLE',
  WHEELCHAIRWOMAN_GREETING_CHATBUBBLE: '',

  USER_OLDMAN_GREETING_CHATBUBBLE: 'USER_OLDMAN_GREETING_CHATBUBBLE',
  OLDMAN_USER_GREETING_RESPONSE_CHATBUBBLE: 'OLDMAN_USER_GREETING_RESPONSE_CHATBUBBLE',
  USER_OLDMAN_GREETING_RESPONSE_CHATBUBBLE: 'USER_OLDMAN_GREETING_RESPONSE_CHATBUBBLE',
  //

  PROJECTILE: 'PROJECTILE',
  PROJECTILETHROWN_SOUND: 'PROJECTILETHROWN_SOUND',
};

export const strollConfig = {
  // PHYSICS: [sceneType.FAKE3DPHYSICSWORLD.NAME],
  PHYSICS: ['fake3dphysicsworld'], // TODO: figure out how to get around the odd import issue when importing scenetype into chaseConfig only
  [playerActorType.DOG.NAME]: {
    [InteractionType.STROLL.NAME]: {
      GAMEBACKGROUNDCOLOR: '#FFFFFF',
      NAME: InteractionType.STROLL.NAME,
      FLIPXATSTART: false,
      BACKGROUNDSPLIT: 6,
      BACKGROUNDSPACING: 0.2,
      HASLEASH: true,
      GUTTER: 60,
      STARTX: -0.15,
      STARTY: 0.4,
      ALLOWPETBASEDSCALING: false,
      PETSCALE: 0.5,
      DEADZONESCALEX: -1.5,
      BACKGROUNDCOMPRESS: 1.06,
      BACKGROUNDTRANSFORM: { scaleX: 3075 / 2046, scaleY: 1320 / 877 },
      CAMERAOFFSETX: -0.7,
      BACKGROUNDHEIGHTSCALE: 1.5,
      HORIZON_LINE: 0.7,
      GROUND_LINE: 0.8,
      BOUNDS: {
        HEIGHT: {
          START: 0.42755102,
          END: 0.0916,
        },
        WIDTH: {
          GAP: 0.02628178,
        },
      },
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.DOG.STROLL,
        [keySceneDataTypes.HUMAN_FAMILY]: gameData.ACTOR.NPC.HUMAN.FAMILY,
        [keySceneDataTypes.HUMAN_OLDMAN]: gameData.ACTOR.NPC.HUMAN.OLDMAN,
        [keySceneDataTypes.HUMAN_WHEELCHAIRWOMAN]: gameData.ACTOR.NPC.HUMAN.WHEELCHAIRWOMAN,
        [keySceneDataTypes.PROJECTILE]: gameData.ACTOR.ITEM.OBJECT.BUTTERFLY,
      },
      PAGES: [
        {
          BACKGROUND_INDEX: 0,
          SCREEN: keySceneDataTypes.STROLL_SCREEN_INTRO,
          CAPTION: keySceneDataTypes.STROLL_INTRO_CAPTION,
          ANIMATIONS: {
            IDLE: animationType.SIT,
          },
        },
        {
          BACKGROUND_INDEX: 1,
          // humans
          ANIMATIONS: {
            TRANSITION: animationType.WALK,
            IDLE: animationType.IDLE,
          },
          POSITION: { y: 0.5 },
          NPC: {
            ACTOR: keySceneDataTypes.HUMAN_FAMILY,
            LOCATIONPERCENTAGE: { x: 1.25, y: 0.55 },
            SCALE: 0.95,
            ANIMATIONS: {
              WAVE: animationType.WAVE,
              IDLE: animationType.IDLE,
            },
            CHATBUBBLES: [{ NAME: keySceneDataTypes.FAMILY_GREETING_CHATBUBBLE }],
          },
        },
        {
          SCENE: InteractionType.BUTTERFLYCHASE,
          BACKGROUND_INDEX: 2,
          POSITION: { y: 0.5 },
          SCENECONFIG: {
            LOCATION: {
              STARTPERCENTAGE: { x: 0.55, y: 0.25 },
              END: { x: 0.5, y: 0.45 },
            },
          },
          ANIMATIONS: {
            TRANSITION: animationType.WALK,
            IDLE: animationType.IDLE,
          },
        },
        {
          BACKGROUND_INDEX: 3,
          POSITION: { x: -0.175, y: 0.575 },
          CAMERAOFFSET: { x: -0.4 },
          SCALE: 0.435,
          DURATION: 1000,
          ANIMATIONS: {
            TRANSITION: animationType.WALK,
            IDLE: animationType.SIT,
          },
          NPC: {
            ACTOR: keySceneDataTypes.HUMAN_WHEELCHAIRWOMAN,
            LOCATIONPERCENTAGE: { x: 0.5, y: 0.65 },
            SCALE: 0.5,
            ANIMATIONS: {
              WAVE: animationType.WAVE,
              IDLE: animationType.IDLE,
            },
            CHATBUBBLES: [{ NAME: keySceneDataTypes.WHEELCHAIRWOMAN_GREETING_CHATBUBBLE }],
          },
          // human
        },
        {
          BACKGROUND_INDEX: 4,
          SCREEN: keySceneDataTypes.STROLL_SCREEN_CHATPROMPT,
          CAPTION: keySceneDataTypes.STROLL_CHATPROMPT_CAPTION,
          STARTCHATONCLICK: keySceneDataTypes.YES_BUTTON,
          SCALE: 0.325,
          POSITION: { x: 0.25, y: 0.45 },
          CAMERAOFFSET: { x: -0.3 },
          ANIMATIONS: {
            TRANSITION: animationType.WALK,
            IDLE: animationType.IDLE,
          },
          NPC: {
            ACTOR: keySceneDataTypes.HUMAN_OLDMAN,
            LOCATIONPERCENTAGE: { x: 0.62, y: 0.525 },
            SCALE: 0.4,
            ANIMATIONS: {
              WAVE: animationType.WAVE,
              IDLE: animationType.IDLE,
            },
            CHATBUBBLES: [
              { NAME: keySceneDataTypes.USER_OLDMAN_GREETING_CHATBUBBLE },
              {
                NAME: keySceneDataTypes.OLDMAN_USER_GREETING_RESPONSE_CHATBUBBLE,
                BUTTON: keySceneDataTypes.LAUGH_BUTTON,
              },
              { NAME: keySceneDataTypes.USER_OLDMAN_GREETING_RESPONSE_CHATBUBBLE },
            ],
          },
          // end screen
        },
        {
          BACKGROUND_INDEX: 5,
          // small talk screen
          SCREEN: keySceneDataTypes.STROLL_SCREEN_CARE,
          CAPTION: keySceneDataTypes.STROLL_CARE_CAPTION,
          CAMERAOFFSET: { x: -0.75 },
          POSITION: { y: 0.4, x: -0.075 },
          FLIPXATSTART: true,
          ANIMATIONS: {
            TRANSITION: animationType.WALK,
            IDLE: animationType.POSE_HAPPY,
          },
        },
      ],
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.READY_BUTTON,
              TEXTID: 'pets.buttons.stroll.ready',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_INTRO],
            },
            {
              NAME: keySceneDataTypes.NOTTODAY_BUTTON,
              TEXTID: 'pets.buttons.stroll.nottoday',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_CHATPROMPT],
            },
            {
              NAME: keySceneDataTypes.YES_BUTTON,
              TEXTID: 'pets.buttons.stroll.yes',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_CHATPROMPT],
            },
            {
              NAME: keySceneDataTypes.LAUGH_BUTTON,
              TEXTID: 'pets.buttons.stroll.laugh',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_CHAT_CONTINUE],
            },
            {
              NAME: keySceneDataTypes.NOTTODAY_OUTRO_BUTTON,
              TEXTID: 'pets.buttons.stroll.nottoday',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_CARE],
            },
            {
              NAME: keySceneDataTypes.YESOUTRO_BUTTON,
              TEXTID: 'pets.buttons.stroll.yes',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_CARE],
            },
            {
              NAME: keySceneDataTypes.BACK_BUTTON,
              TEXTID: 'pets.buttons.stroll.back',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_BACK],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.STROLL_INTRO_CAPTION,
              TITLEID: 'pets.caption.title.stroll.intro',
              TEXTID: 'pets.caption.description.stroll.intro.dog',
            },
            {
              NAME: keySceneDataTypes.STROLL_CHATPROMPT_CAPTION,
              TITLEID: 'pets.caption.title.stroll.chatprompt',
              TEXTID: 'pets.caption.description.stroll.chatprompt',
            },
            {
              NAME: keySceneDataTypes.STROLL_CARE_CAPTION,
              TITLEID: 'pets.caption.title.stroll.care',
              TEXTID: 'pets.caption.description.stroll.care',
            },
            {
              NAME: keySceneDataTypes.STROLL_BACK_CAPTION,
              TITLEID: 'pets.caption.title.stroll.back.dog',
            },
          ],
        },
        {
          TYPE: uiTypes.CHATBUBBLE,
          DATA: [
            {
              NAME: keySceneDataTypes.FAMILY_GREETING_CHATBUBBLE,
              TEXTID: 'pets.chatbubble.text.stroll.family.user.greeting',
            },
            {
              NAME: keySceneDataTypes.WHEELCHAIRWOMAN_GREETING_CHATBUBBLE,
              TEXTID: 'pets.chatbubble.text.stroll.wheelchairwoman.user.greeting',
            },
            {
              NAME: keySceneDataTypes.USER_OLDMAN_GREETING_CHATBUBBLE,
              ISRESPONSE: true,
              TEXTID: 'pets.chatbubble.text.stroll.user.oldman.greeting',
            },
            {
              NAME: keySceneDataTypes.OLDMAN_USER_GREETING_RESPONSE_CHATBUBBLE,
              TEXTID: 'pets.chatbubble.text.stroll.oldman.user.greeting.response',
            },
            {
              NAME: keySceneDataTypes.USER_OLDMAN_GREETING_RESPONSE_CHATBUBBLE,
              ISRESPONSE: true,
              TEXTID: 'pets.chatbubble.text.stroll.user.oldman.greeting.response',
            },
          ],
        },
      ],
    },
  },
  [playerActorType.CAT.NAME]: {
    [InteractionType.STROLL.NAME]: {
      GAMEBACKGROUNDCOLOR: '#FFFFFF',
      NAME: InteractionType.STROLL.NAME,
      BACKGROUNDCOMPRESS: 0.5,
      GUTTER: 60,
      PETSCALE: 0.5,
      CAMERAOFFSETX: -0.7,
      SQUISHFOREGROUND: 1.0,
      SQUISHBACKGROUND: 0.2,
      DEADZONEOFFSETY: 0.624,
      STARTX: 0.0,
      STARTY: 0.55,
      // HORIZON_LINE: 0.65,
      HORIZON_LINE: 0.7,
      GROUND_LINE: 0.9,
      BACKGROUNDHEIGHTSCALE: 1.5,
      DEADZONESCALEX: 0.19,
      ALLOWBOUNDPADDING: false,
      BACKGROUNDSPLIT: 2,
      BOUNDS: {
        HEIGHT: {
          START: 0.22,
          END: 0.0916,
        },
        WIDTH: {
          START: 0.02638,
          END: 0.0,
          GAP: 0.083,
        },
      },
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.CAT.STROLL,
        [keySceneDataTypes.FOREGROUND]: gameData.FOREGROUND.CAT.STROLL,
        [keySceneDataTypes.HUMAN_FAMILY]: gameData.ACTOR.NPC.HUMAN.FAMILY,
        [keySceneDataTypes.HUMAN_OLDMAN]: gameData.ACTOR.NPC.HUMAN.OLDMAN,
        [keySceneDataTypes.HUMAN_WHEELCHAIRWOMAN]: gameData.ACTOR.NPC.HUMAN.WHEELCHAIRWOMAN,
        [keySceneDataTypes.PROJECTILE]: gameData.ACTOR.ITEM.OBJECT.BUTTERFLY,
      },
      PAGES: [
        {
          BACKGROUND_INDEX: 0,
          SCREEN: keySceneDataTypes.STROLL_SCREEN_INTRO,
          CAPTION: keySceneDataTypes.STROLL_INTRO_CAPTION,
          ANIMATIONS: {
            IDLE: animationType.IDLE,
          },
        },
        {
          BACKGROUND_INDEX: 0,
          ANIMATIONS: {
            TRANSITION: animationType.WALK,
            IDLE: animationType.IDLE2,
          },
          NPC: {
            ACTOR: keySceneDataTypes.HUMAN_FAMILY,
            LOCATIONPERCENTAGE: { x: 0.6, y: 0.4 },
            SCALE: 0.85,
            ANIMATIONS: {
              WAVE: animationType.WAVE,
              IDLE: animationType.IDLE,
            },
            WALK: {
              ANIMATION: animationType.WALK,
              LOCATIONPERCENTAGE: { x: 1.2, y: 0.4 },
            },
            CHATBUBBLES: [{ NAME: keySceneDataTypes.FAMILY_GREETING_CHATBUBBLE }],
          },
          // humans
        },
        {
          SCENE: InteractionType.BUTTERFLYCHASE,
          BACKGROUND_INDEX: 1,
          POSITION: { x: 0.4, y: 0.615 },
          SCENECONFIG: {
            LOCATION: {
              END: { x: 0.5, y: 0.0 },
            },
          },
          ANIMATIONS: {
            TRANSITION: animationType.WALK,
            IDLE: animationType.IDLE5,
          },
        },
        {
          BACKGROUND_INDEX: 0,
          POSITION: { x: 0.075, y: 0.425 },
          CAMERAOFFSET: { x: -0.4 },
          SCALE: 0.3,
          ANIMATIONS: {
            TRANSITION: animationType.WALK,
            IDLE: animationType.IDLE,
          },
          NPC: {
            ACTOR: keySceneDataTypes.HUMAN_WHEELCHAIRWOMAN,
            LOCATIONPERCENTAGE: { x: 0.6, y: 0.4 },
            SCALE: 0.55,
            ANIMATIONS: {
              WAVE: animationType.WAVE,
              IDLE: animationType.IDLE,
            },
            WALK: {
              ANIMATION: animationType.WALK,
              LOCATIONPERCENTAGE: { x: 0.8, y: 0.4 },
            },
            CHATBUBBLES: [{ NAME: keySceneDataTypes.WHEELCHAIRWOMAN_GREETING_CHATBUBBLE }],
          },
          // human
        },
        {
          BACKGROUND_INDEX: 0,
          // human
          SCREEN: keySceneDataTypes.STROLL_SCREEN_CHATPROMPT,
          CAPTION: keySceneDataTypes.STROLL_CHATPROMPT_CAPTION,
          STARTCHATONCLICK: keySceneDataTypes.YES_BUTTON,
          POSITION: { x: 0.33, y: 0.395 },
          ANIMATIONS: {
            TRANSITION: animationType.WALK,
            IDLE: animationType.IDLE2,
          },
          NPC: {
            ACTOR: keySceneDataTypes.HUMAN_OLDMAN,
            LOCATIONPERCENTAGE: { x: 0.5875, y: 0.4 },
            FLIPCHATBUBBLEOVERRIDE: false,
            SCALE: 0.5,
            FLIPXATSTART: true,
            ANIMATIONS: {
              WAVE: animationType.WAVE2,
              IDLE: animationType.IDLE2,
            },
            WALK: {
              ANIMATION: animationType.WALK,
              LOCATIONPERCENTAGE: { x: 1.4, y: 0.4 },
            },
            CHATINTERACT: {
              NPC_INTERACT_TRANSITION_ANIMATION: animationType.OBJECT_INTERACT_TRANSITION,
              NPC_INTERACT_ANIMATION: animationType.OBJECT_INTERACT,
              PET_INTERACT_ANIMATION: animationType.OBJECT_INTERACT,
              PET_TRANSITION_ANIMATION: animationType.WALK,
              PET_POSITION: { x: -0.4, y: -0.0 },
            },
            CHATBUBBLES: [
              { NAME: keySceneDataTypes.USER_OLDMAN_GREETING_CHATBUBBLE },
              {
                NAME: keySceneDataTypes.OLDMAN_USER_GREETING_RESPONSE_CHATBUBBLE,
                BUTTON: keySceneDataTypes.LAUGH_BUTTON,
              },
              { NAME: keySceneDataTypes.USER_OLDMAN_GREETING_RESPONSE_CHATBUBBLE },
            ],
          },
          // respond to human scene
          // response text
          // end screen
        },
        {
          BACKGROUND_INDEX: 0,
          // small talk screen
          FLIPXATSTART: false,
          SCALE: 0.6,
          SCREEN: keySceneDataTypes.STROLL_SCREEN_CARE,
          CAPTION: keySceneDataTypes.STROLL_CARE_CAPTION,
          POSITION: { x: -0.125, y: 0.6 },
          ANIMATIONS: {
            TRANSITION: animationType.WALK_FRONT,
            TURN: animationType.IDLE6,
            IDLE: animationType.POSE_PROUD,
          },
        },
      ],
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.READY_BUTTON,
              TEXTID: 'pets.buttons.stroll.ready',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_INTRO],
            },
            {
              NAME: keySceneDataTypes.NOTTODAY_BUTTON,
              TEXTID: 'pets.buttons.stroll.nottoday',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_CHATPROMPT],
            },
            {
              NAME: keySceneDataTypes.YES_BUTTON,
              TEXTID: 'pets.buttons.stroll.yes',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_CHATPROMPT],
            },
            {
              NAME: keySceneDataTypes.LAUGH_BUTTON,
              TEXTID: 'pets.buttons.stroll.laugh',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_CHAT_CONTINUE],
            },
            {
              NAME: keySceneDataTypes.NOTTODAY_OUTRO_BUTTON,
              TEXTID: 'pets.buttons.stroll.nottoday',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_CARE],
            },
            {
              NAME: keySceneDataTypes.YESOUTRO_BUTTON,
              TEXTID: 'pets.buttons.stroll.yes',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_CARE],
            },
            {
              NAME: keySceneDataTypes.BACK_BUTTON,
              TEXTID: 'pets.buttons.stroll.back',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.STROLL_SCREEN_BACK],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.STROLL_INTRO_CAPTION,
              TITLEID: 'pets.caption.title.stroll.intro',
              TEXTID: 'pets.caption.description.stroll.intro.cat',
            },
            {
              NAME: keySceneDataTypes.STROLL_CHATPROMPT_CAPTION,
              TITLEID: 'pets.caption.title.stroll.chatprompt',
              TEXTID: 'pets.caption.description.stroll.chatprompt',
            },
            {
              NAME: keySceneDataTypes.STROLL_CARE_CAPTION,
              TITLEID: 'pets.caption.title.stroll.care',
              TEXTID: 'pets.caption.description.stroll.care',
            },
            {
              NAME: keySceneDataTypes.STROLL_BACK_CAPTION,
              TITLEID: 'pets.caption.title.stroll.back.cat',
            },
          ],
        },

        {
          TYPE: uiTypes.CHATBUBBLE,
          DATA: [
            {
              NAME: keySceneDataTypes.FAMILY_GREETING_CHATBUBBLE,
              TEXTID: 'pets.chatbubble.text.stroll.family.user.greeting',
            },
            {
              NAME: keySceneDataTypes.WHEELCHAIRWOMAN_GREETING_CHATBUBBLE,
              TEXTID: 'pets.chatbubble.text.stroll.wheelchairwoman.user.greeting',
            },
            {
              NAME: keySceneDataTypes.USER_OLDMAN_GREETING_CHATBUBBLE,
              ISRESPONSE: true,
              TEXTID: 'pets.chatbubble.text.stroll.user.oldman.greeting',
            },
            {
              NAME: keySceneDataTypes.OLDMAN_USER_GREETING_RESPONSE_CHATBUBBLE,
              TEXTID: 'pets.chatbubble.text.stroll.oldman.user.greeting.response',
            },
            {
              NAME: keySceneDataTypes.USER_OLDMAN_GREETING_RESPONSE_CHATBUBBLE,
              ISRESPONSE: true,
              TEXTID: 'pets.chatbubble.text.stroll.user.oldman.greeting.response',
            },
          ],
        },
      ],
    },
  },
};
