import { buildingConfidence } from 'Assets/img/my-tools-icons';

export const buildingConfidenceGuardianEs = {
  key: 'building-confidence-guardian-es',
  title: 'Desarrollar las declaraciones Soy',
  titleImage: buildingConfidence,
  titleImageAlt: '',
  contentId: 43,
  sections: [
    {
      key: 'building-confidence-intro-es',
      description:
        'Si la vida cotidiana te deprime o sientes que ya no sabes quién eres, puede ser el momento de desarrollar o reconstruir tu confianza. Entregarse a los demás puede resultar desafiante, ingrato, aislado o solitario. Comprender quién eres y sentirte seguro de ti mismo/a puede ayudarte a ganar confianza y capacidad para cuidar mejor a los demás. A continuación se detallan los pasos para hacer declaraciones “soy”, una poderosa herramienta que te ayudará a desarrollar o reconstruir tu confianza.',
    },
    {
      key: 'create-i-am-statements-es',
      header: 'Crea declaraciones “soy”',
      description:
        'Decirte a ti mismo/a declaraciones “soy” te ayudará a recordar y a creer que eres capaz de más de lo que te das cuenta.',
    },
    {
      key: 'step-one-create-statements-es',
      header: 'Paso 1',
      bulletedItems: [
        {
          text: 'Toma un bolígrafo y un poco de papel (o utiliza una herramienta digital).',
        },
        {
          text: 'Piensa en un momento o lugar en el que te sentiste bien contigo mismo/a.',
        },
        {
          text: 'Piensa en lo que estabas haciendo, en lo que sentías y en lo que hiciste bien.',
        },
        {
          text: 'Apunta eso.',
        },
        {
          text: 'A continuación se muestran algunos ejemplos de escenarios:',
          subItems: [
            { text: 'Preparé una comida deliciosa para la familia' },
            { text: 'Gané un juego de cartas' },
            { text: 'Cumplí un objetivo' },
            { text: 'Completé un entrenamiento' },
            { text: 'Ayudé a mi hijo a superar un miedo' },
          ],
        },
        { text: 'Escribe de 3 a 5 momentos en los que te sentiste bien contigo mismo/a.' },
        { text: 'Estos escenarios pueden ser desde cosas pequeñas hasta grandes acontecimientos.' },
      ],
    },
    {
      key: 'step-two-create-statements-es',
      header: 'Paso 2',
      bulletedItems: [
        {
          text: 'Piensa en esos momentos y trata de recordar lo que estabas sintiendo.',
        },
        {
          text: 'A veces, puede ser difícil encontrar palabras para expresar sentimientos o emociones, así que aquí tienes algunos ejemplos:',
          subItems: [
            { text: 'Orgulloso' },
            { text: 'En paz' },
            { text: 'Optimista' },
            { text: 'Alegre' },
            { text: 'Agradecido/a' },
            { text: 'Inspirado/a' },
            { text: 'Feliz' },
          ],
        },
        {
          text: 'Para cada uno de los escenarios que escribiste anteriormente, escribe las emociones que recuerdas haber sentido.',
        },
      ],
    },
    {
      key: 'step-three-create-statements-es',
      header: 'Paso 3',
      bulletedItems: [
        {
          text: 'A continuación, para cada escenario en el que te sentiste bien contigo mismo/a, piensa en lo que hiciste bien para llegar allí.',
        },
        {
          text: 'Puede resultar complicado pensar en estos escenarios y no dar crédito a otras personas que te ayudaron. Si bien es importante estar agradecido por las personas en tu vida, utiliza este ejercicio para redirigir tus pensamientos hacia lo que hiciste bien personalmente.',
        },
        {
          text: 'A continuación se muestran algunos ejemplos de cosas que quizás hayas hecho bien:',
          subItems: [
            { text: 'Escuché con empatía' },
            { text: 'Trabajé duro' },
            { text: 'Perseveré' },
            { text: 'Actué con resiliencia' },
            { text: 'Demostré amabilidad, amor o consideración' },
            { text: 'Mostré ambición' },
          ],
        },
        {
          text: 'Si te resulta difícil determinar lo que hiciste bien, puedes repetir cualquiera de los pasos anteriores, lo cual podría incluir pensar en algunos escenarios diferentes en los que te hayas sentido bien contigo mismo.',
        },
      ],
    },
    {
      key: 'step-four-create-statements-es',
      header: 'Paso 4: crea y utiliza tus declaraciones "soy"',
      bulletedItems: [
        {
          text: 'Ahora es el momento de darte amor y un impulso de confianza.',
        },
        {
          text: 'Una declaración "soy" puede ser cualquier cosa que demuestre lo grandioso que eres.',
        },
        {
          text: 'Estas declaraciones pueden expresar una gran cualidad, habilidad o simplemente pueden ser algo que necesitas escuchar.',
        },
        {
          text: 'A continuación se muestran algunos ejemplos de declaraciones "soy":',
          subItems: [
            { text: 'Soy un(a) gran oyente.' },
            { text: 'Soy resiliente y puedo lograr mis objetivos.' },
            { text: 'Soy cariñoso/a y amable.' },
            { text: 'Soy empático/a.' },
            { text: 'Estoy orgulloso/a de quién soy.' },
          ],
        },
        {
          text: 'Crea declaraciones “soy” tantas veces como quieras.',
        },
        {
          text: 'Para comenzar, tómate un tiempo para hacer cinco declaraciones “soy” cada semana.',
        },
        {
          text: 'Coloca tus declaraciones en un lugar que puedas ver con frecuencia; léelas cuando no te sientas bien contigo mismo/a. Como mínimo, léelas o repítelas a diario.',
        },
      ],
    },
  ],
};
