export const createAnimations = (game, name, spriteData, prefix, suffix, atlasKey) => {
  var animationKeys = Object.keys(spriteData.ANIMATIONS);

  // create animations
  for (let i = 0; i < animationKeys.length; i++) {
    let animationKey = `${animationKeys[i]}`;
    let animation = spriteData.ANIMATIONS[animationKey];

    let currentAnimationKey = `${name}-${animationKey}`;
    game.anims.create({
      key: currentAnimationKey,
      frames:
        animation.startFrame && animation.endFrame
          ? game.anims.generateFrameNames(atlasKey, {
              start: animation.startFrame,
              end: animation.endFrame,
              prefix: prefix,
              suffix: suffix,
            })
          : game.anims.generateFrameNames(atlasKey, {
              frames: animation.frames,
              prefix: prefix,
              suffix: suffix,
            }),
      frameRate: animation.frameRate,
      yoyo: animation.yoyo,
      repeat: animation.repeat === undefined ? -1 : animation.repeat,
    });
  }
};
