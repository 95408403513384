import * as React from 'react';
import DeterminedChat from 'Core_Pages/Andy/components/DeterminedChat/DeterminedChat';
import screenType from 'Core_Pages/Andy/components/DeterminedChat/screenType';
import CharacterGreetingHeader from 'Core_Components/CharacterGreetingHeader/CharacterGreetingHeader';
import AndyGreet from 'Assets/img/andy-profile-full.svg';
import AndyHelpEn from 'Assets/img/character-header/andy-greeting.en.png';
import AndyHelpEs from 'Assets/img/character-header/andy-greeting.es.png';
import { useIntl, FormattedMessage } from 'react-intl';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';

const Andy = () => {
  const intl = useIntl();

  return (
    <>
      <ScreenReaderWrapper id="PageTitle">
        <h1>
          <FormattedMessage id="title.andy-chat" />
        </h1>
      </ScreenReaderWrapper>
      <CharacterGreetingHeader
        imageSrc={AndyGreet}
        imageAlt={intl.formatMessage({ id: 'image.andy.portrait' })}
        imageAlignment="flex-end"
        backgroundColor="#62a744"
        greeting={{
          src: intl.formatMessage({ id: 'andy.image' }).includes('en.svg') ? AndyHelpEn : AndyHelpEs,
          alt: intl.formatMessage({ id: 'image.andy.help' }),
        }}
      />
      <DeterminedChat type={screenType.andy} />
    </>
  );
};

export default Andy;
