import React from 'react';
import { Step, StepLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { ScreeningContainer, StepperContainer, VerticalConnectedStepper } from './styles';
import { Forms } from '../forms';
import { ScreeningType } from 'Core_Pages/Screening/enumerations/enums';

function ScreeningStepper({ screenings, nextScreening, onClickCheckin }) {
  const requiredAndCompletedScreenings = screenings.filter(
    (s) => s.screeningType === ScreeningType.REQUIRED || s.screeningResponseGuid,
  );

  return (
    <VerticalConnectedStepper activeStep={nextScreening} orientation="vertical">
      {requiredAndCompletedScreenings.map(({ screeningGuid, screeningResponseGuid }, index) => {
        const ResultComponent = Forms[screeningGuid] ?? (() => <></>);
        return (
          <Step key={index}>
            <StepLabel />
            <StepperContainer className="StepperContent">
              <ScreeningContainer elevation={0}>
                <ResultComponent onClick={onClickCheckin} screeningResponseGuid={screeningResponseGuid} />
              </ScreeningContainer>
            </StepperContainer>
          </Step>
        );
      })}
    </VerticalConnectedStepper>
  );
}

ScreeningStepper.propTypes = {
  onClickCheckin: PropTypes.func.isRequired,
  nextScreening: PropTypes.number,
  screenings: PropTypes.array,
};

export default ScreeningStepper;
