import React from 'react';
import PropTypes from 'prop-types';
import { ToggleContainer, Overlay } from './styles';
import { useIntl } from 'react-intl';
import { useOnboardSelector } from 'Contexts/StoreContext';
import { selectStep } from 'Onboard_Redux/login';
import { TIMEOUT } from '../LogIn/steps';

function Toggle({ selectedOption, onToggle }) {
  const intl = useIntl();
  const selectCurrentStep = useOnboardSelector(selectStep);
  const isSessionTimeOut = selectCurrentStep === TIMEOUT;
  const toggleClass = selectedOption === Toggle.states.LOGIN ? 'right' : 'left';

  const signupOption = intl.formatMessage({ id: 'account.sign-up' });
  const loginOption = intl.formatMessage({ id: 'account.log-in' });
  const signupOptionLabel = intl.formatMessage({ id: 'account.toggle.sign-up' });
  const loginOptionLabel = intl.formatMessage({ id: 'account.toggle.log-in' });
  const selectedOptionText = selectedOption === Toggle.states.SIGNUP ? signupOption : loginOption;
  const selectedOptionLabel = selectedOption === Toggle.states.SIGNUP ? signupOptionLabel : loginOptionLabel;

  return (
    <ToggleContainer onClick={onToggle} disabled={isSessionTimeOut} aria-label={selectedOptionLabel}>
      <span>{signupOption}</span>
      <span>{loginOption}</span>
      <Overlay className={toggleClass} isDisabled={isSessionTimeOut}>
        {selectedOptionText}
      </Overlay>
    </ToggleContainer>
  );
}

Toggle.propTypes = {
  selectedOption: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

Toggle.states = { SIGNUP: false, LOGIN: true };

export default Toggle;
