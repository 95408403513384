import Phaser from 'phaser';
import { sceneType } from '../../../constants/sceneType';
import Fake3DPhysicsObject from './Fake3DPhysicsObject';

export class Fake3DPhysicsWorld extends Phaser.Scene {
  constructor() {
    super(sceneType.FAKE3DPHYSICSWORLD.NAME);
  }

  init = async ({ positionBoxX = 0, positionBoxY = 0, worldSizeX, worldSizeY, worldSizeZ }) => {
    this.positionBoxX = positionBoxX;
    this.positionBoxY = positionBoxY;
    this.objects = [];
    this.worldSizeX = worldSizeX;
    this.worldSizeY = worldSizeY;
    this.worldSizeZ = worldSizeZ;
  };

  preload = async () => {};

  create = async () => {};

  update = async () => {
    if (this.objects.length > 0) {
      for (let i = 0; i < this.objects.length; i++) {
        this.objects[i].updateLocation();
      }
    }
  };

  // helper functions to connect to 3d object

  resetObjects = () => {
    if (this.objects.length > 0) {
      this.objects.forEach((object) => {
        object.ballX.destroy();
        object.ballY.destroy();
      });
    }
    this.objects = [];
  };

  packOriginalParams = () => {
    return {
      positionBoxX: this.positionBoxX,
      positionBoxY: this.positionBoxY,
      worldSizeZ: this.worldSizeZ,
      worldSizeX: this.worldSizeX,
      worldSizeY: this.worldSizeY,
    };
  };

  addObject = (physicsParams) => {
    const newObject = new Fake3DPhysicsObject(this, this.packOriginalParams(), {
      worldSizeX: physicsParams.worldSizeX,
      worldSizeY: physicsParams.worldSizeY,
      worldSizeZ: physicsParams.worldSizeZ,
      positionBoxX: physicsParams.positionBoxX,
      positionBoxY: physicsParams.positionBoxY,
      velocity: physicsParams.velocity,
      bounce: physicsParams.bounce,
      maxScale: physicsParams.maxScale,
      minScale: physicsParams.minScale,
      zoomFactor: physicsParams.zoomFactor,
      scale: physicsParams.scale,
      size: physicsParams.size,
      startX: physicsParams.startX,
      startZ: physicsParams.startZ,
      startY: physicsParams.startY,
      velocityPower: physicsParams.velocityPower,
      bounceFactor: physicsParams.bounceFactor,
      startFromRight: physicsParams.startFromRight,
      disableGravity: physicsParams.disableGravity,
      hasDamping: physicsParams.hasDamping,
      drag: physicsParams.drag,
      object: physicsParams.object,
      horizonLine: physicsParams.horizonLine,
      groundLine: physicsParams.groundLine,
    });
    this.objects.push(newObject);
    return newObject;
  };
}
