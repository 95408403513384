import { ToolTypes } from '../enumerations/enums';
import MyBrainBoosterTools from './MyBrainBoosterTools/MyBrainBoosterTools';
import MyCaregivingTools from './MyCaregivingTools/MyCaregivingTools';
import MyGuardianTools from './MyGuardianTools/MyGuardianTools';
import MyMindsetTools from './MyMindsetTools/MyMindsetTools';
import MyPhysicalHealthTools from './MyPhysicalHealthTools/MyPhysicalHealthTools';
import MyRelationshipTools from './MyRelationshipTools/MyRelationshipTools';
import MyVideoTools from './MyVideoTools/MyVideoTools';

export const Tools = {
  [ToolTypes.RELATIONSHIPS]: MyRelationshipTools,
  [ToolTypes.MINDSET]: MyMindsetTools,
  [ToolTypes.BRAINBOOSTERS]: MyBrainBoosterTools,
  [ToolTypes.PHYSICALHEALTH]: MyPhysicalHealthTools,
  [ToolTypes.CAREGIVING]: MyCaregivingTools,
  [ToolTypes.GUARDIAN]: MyGuardianTools,
  [ToolTypes.VIDEOS]: MyVideoTools,
};
