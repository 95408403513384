import React from 'react';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import { completeCredentialsEntry, navigateBack, selectName, selectSignupMetadata } from 'Onboard_Redux/signup';
import { ArrowBackIos } from '@mui/icons-material';
import Button from 'Stories/components/Button';
import { Header, StyledTextInput, StyledPasswordInput } from 'Onboard_Components/Account/styles';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { useValidateMutation } from 'Onboard_Redux/services/identity';
import hasErrorForProperty from '../hasValidationErrorForProperty';
import hasErrorWithMessage from '../hasValidationErrorMessage';
import { selectLanguage } from 'Onboard_Redux/user';

function Credentials() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const { progress } = useOnboardSelector(selectSignupMetadata);
  const language = useOnboardSelector(selectLanguage);
  const { firstName, lastName } = useOnboardSelector(selectName);
  const [validate, { isLoading, error }] = useValidateMutation();
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm();

  const usernameError = !hasErrorForProperty(error, 'UserName')
    ? null
    : hasErrorWithMessage(error, 'Username has been taken.')
    ? intl.formatMessage({ id: 'account.log-in.username-taken' })
    : intl.formatMessage({ id: 'account.log-in.username-invalid' });

  async function onContinue(data) {
    const { username, password } = data;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    validate({ username, password, locale: language, timeZoneId: timeZone, firstName: firstName, lastName: lastName })
      .unwrap()
      .catch((errors) => {
        if (!hasErrorForProperty(errors, 'UserName')) {
          dispatch(completeCredentialsEntry(data));
        }
      });
  }

  function onBack() {
    dispatch(navigateBack());
  }

  const formComplete = dirtyFields.username && dirtyFields.password;

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <Header>
        <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />
        {intl.formatMessage({ id: 'account.sign-up.create-account' })}
      </Header>
      <form onSubmit={handleSubmit(onContinue)}>
        <Controller
          name="username"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <StyledTextInput
              {...field}
              id="usernameInput"
              label={intl.formatMessage({ id: 'account.sign-up.create-username' })}
              errorMessage={usernameError}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ minLength: { value: 6, message: intl.formatMessage({ id: 'error.min-characters' }, { min: 6 }) } }}
          render={({ field }) => (
            <StyledPasswordInput
              {...field}
              id="passwordInput"
              label={intl.formatMessage({ id: 'account.sign-up.create-password' })}
              errorMessage={errors.password?.message}
            />
          )}
        />
        <Button fullWidth loading={isLoading} disabled={!formComplete} type="submit">
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
    </>
  );
}

export default Credentials;
