import getAuthClient from 'Core_Helpers/getAuthClient';

export async function fetch(url) {
  const authorizedApiClient = await getAuthClient();
  if (!url) return;
  if (authorizedApiClient) {
    return await authorizedApiClient.get(url).catch(function (thrown) {
      if (!axios.isCancel(thrown)) {
        throw thrown;
      }
    });
  }
}
