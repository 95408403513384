import React from 'react';
import PropTypes from 'prop-types';
import { StyledExternalLink, StyledLink } from './styles';

const ConditionalLink = ({ children, linkType = '', onClick, style, link }) => {
  const isReactLink = linkType && (linkType.toUpperCase() === 'INAPPURL' || linkType.toUpperCase() === 'APPVIEW');
  const target = linkType && linkType.toUpperCase() === 'OUTOFAPPURL' ? { rel: 'noreferrer', target: '_blank' } : null;
  const isPhoneLink = linkType.toUpperCase() === 'PHONE' || linkType.toUpperCase() === 'PHONENUMBER';

  const phoneClick = (event) => {
    event.preventDefault();
    window.open(`tel:${link}`, '_self');
    if (onClick) {
      onClick(event);
    }
    return false;
  };

  return (
    <>
      {isReactLink ? (
        <StyledLink data-testid="StyledLink" style={style} to={link ?? '#'} onClick={onClick} role="button">
          {children}
        </StyledLink>
      ) : isPhoneLink ? (
        <StyledExternalLink data-testid="StyledExternalLink" style={style} href="#" onClick={phoneClick} role="button">
          {children}
        </StyledExternalLink>
      ) : (
        <StyledExternalLink
          data-testid="StyledExternalLink"
          style={style}
          href={link}
          onClick={onClick}
          {...target}
          role="button"
        >
          {children}
        </StyledExternalLink>
      )}
    </>
  );
};

ConditionalLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  linkType: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  link: PropTypes.string,
};

export default ConditionalLink;
