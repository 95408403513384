import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Toggle from './Toggle';
import { weekdays } from '../reminderConstants';
import { StyledToggleContainer } from './styles';

function WeekIntervalConfiguration({ control }) {
  const intl = useIntl();

  return (
    <StyledToggleContainer>
      <Toggle value={weekdays.SUNDAY} control={control}>
        {intl.formatMessage({ id: 'common.day-initial-sunday' })}
      </Toggle>
      <Toggle value={weekdays.MONDAY} control={control}>
        {intl.formatMessage({ id: 'common.day-initial-monday' })}
      </Toggle>
      <Toggle value={weekdays.TUESDAY} control={control}>
        {intl.formatMessage({ id: 'common.day-initial-tuesday' })}
      </Toggle>
      <Toggle value={weekdays.WEDNESDAY} control={control}>
        {intl.formatMessage({ id: 'common.day-initial-wednesday' })}
      </Toggle>
      <Toggle value={weekdays.THURSDAY} control={control}>
        {intl.formatMessage({ id: 'common.day-initial-thursday' })}
      </Toggle>
      <Toggle value={weekdays.FRIDAY} control={control}>
        {intl.formatMessage({ id: 'common.day-initial-friday' })}
      </Toggle>
      <Toggle value={weekdays.SATURDAY} control={control}>
        {intl.formatMessage({ id: 'common.day-initial-saturday' })}
      </Toggle>
    </StyledToggleContainer>
  );
}

WeekIntervalConfiguration.propTypes = {
  control: PropTypes.object.isRequired,
};

export default WeekIntervalConfiguration;
