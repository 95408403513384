import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledLegend = styled.legend`
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
`;

export const StyledFieldset = styled.fieldset`
  flex: 1;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  label {
    margin-bottom: 24px;
  }
  label:last-child {
    margin-bottom: 0;
  }
`;

export const StyledQuestionNumberText = styled.p`
  text-align: center;
  color: #5d5d5d;
  font-size: 18px;
  margin-top: 20px;
  min-height: 27px;
  margin-bottom: 16px;
  line-height: 27px;
`;

export const StyledRadioButton = styled.label`
  border: 1px solid #3c417d;
  border-radius: 15px;
  margin: 0;
  padding: 6px 16px;
  text-align: center;
  font-weight: 700;
  min-height: 40px;
  min-width: 270px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.checked {
    color: #3c417d;
    background: #4a4f7f50;
  }
`;

export const StyledNextButton = styled(Button)`
  width: 160px;
  height: 40px;
  align-self: center;
  font-weight: 700;
  border-radius: 10px;
  box-shadow: none;
  font-size: 16px;
  background-color: #3249a5;
  color: #ffffff;

  &.Mui-disabled {
    background-color: #ebebeb;
    color: #505050;
    width: 200px;
  }

  @media (hover: hover) {
    :hover {
      background-color: #3249a5;
      color: #ffffff;
    }
  }
`;

export const AnswerBubbleContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.bubbleCount < 3 ? 'column' : 'row')};
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 320px;
  @media screen and (max-width: 350px) {
    max-width: 290px;
  }
`;
export const StyledCircleContainer = styled.div`
  height: 125px;
  width: 125px;
  border-radius: 50%;
  margin-bottom: ${(props) => (props.bubbleCount % 3 ? '20px' : '5px')};

  @media screen and (max-width: 350px) {
    height: 110px;
    width: 110px;
    margin-bottom: ${(props) => (props.bubbleCount % 3 ? '10px' : '0')};
  }
`;
