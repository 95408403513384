import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import {
  navigateBack,
  selectSignupMetadata,
  completeHowDidYouHearAboutUs,
  selectUserId,
  completeSignup,
} from 'Onboard_Redux/signup';
import { Controller, useForm } from 'react-hook-form';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { CenteredSelect, Header, StyledTextInput } from 'Onboard_Components/Account/styles';
import { ArrowBackIos } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import SkipButton from 'Onboard_Components/Account/SignUp/SkipButton';
import Button from 'Stories/components/Button';
import { InputProps } from 'Stories/components/Input';
import { useCompleteHiFriendSurveyMutation } from 'Onboard_Redux/services/api';

function HowDidYouHearAboutUs() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const { progress } = useOnboardSelector(selectSignupMetadata);
  const {
    control,
    formState: { dirtyFields },
    handleSubmit,
    watch,
    errors,
  } = useForm();
  const howDidYouHearAboutUs = watch('howDidYouHearAboutUs');
  const { userId } = useOnboardSelector(selectUserId);
  const [isPending, setIsPending] = useState(false);
  const [completeHiFriendSurvey] = useCompleteHiFriendSurveyMutation();

  function onContinue(data) {
    setIsPending(true);
    dispatch(completeHowDidYouHearAboutUs(data));

    const answers = [
      {
        AnswerText: data.howDidYouHearAboutUs,
        AnswerValue: data.howDidYouHearAboutUs,
        QuestionText: intl.formatMessage({ id: 'account.sign-up.hear-about-us' }),
      },
    ];

    completeHiFriendSurvey({ userId, answers });
    dispatch(completeSignup({ userId }));
  }

  function onBack() {
    dispatch(navigateBack());
  }

  function onSkip() {
    setIsPending(true);
    dispatch(completeSignup({ userId }));
  }

  const options = [
    { translationKey: 'account.sign-up.hear-about-us.health-plan', code: 'healthPlan' },
    { translationKey: 'account.sign-up.hear-about-us.someone-called', code: 'someoneCalled' },
    { translationKey: 'account.sign-up.hear-about-us.mail', code: 'mail' },
    { translationKey: 'account.sign-up.hear-about-us.friend', code: 'friend' },
    { translationKey: 'account.sign-up.hear-about-us.internet-search', code: 'internetSearch' },
    { translationKey: 'account.sign-up.hear-about-us.email', code: 'email' },
    { translationKey: 'account.sign-up.hear-about-us.loneliness-week', code: 'lonelinessWeek' },
  ];

  const formComplete =
    howDidYouHearAboutUs === 'healthPlan'
      ? dirtyFields.howDidYouHearAboutUs && dirtyFields.healthPlanName
      : dirtyFields.howDidYouHearAboutUs;

  return (
    <>
      <SignUpProgressBar aria-label={intl.formatMessage({ id: 'account.sign-up-progress' })} progressValue={progress} />
      <Header>
        <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />
        {intl.formatMessage({ id: 'account.sign-up.hear-about-us' })}
      </Header>
      <form onSubmit={handleSubmit(onContinue)}>
        <Controller
          name="howDidYouHearAboutUs"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <CenteredSelect
              id="howDidYouHearAboutUsInput"
              {...field}
              variant={InputProps.variant.FILLED}
              fullWidth
              displayEmpty
              renderValue={(selected) => {
                return selected === ''
                  ? intl.formatMessage({ id: 'account.sign-up.hear-about-us.please-select' })
                  : intl.formatMessage({ id: options.find((o) => o.code === selected).translationKey });
              }}
            >
              {options.map((o) => (
                <MenuItem key={o.code} value={o.code}>
                  {intl.formatMessage({ id: o.translationKey })}
                </MenuItem>
              ))}
            </CenteredSelect>
          )}
        />
        {howDidYouHearAboutUs === 'healthPlan' && (
          <Controller
            name="healthPlanName"
            control={control}
            defaultValue=""
            rules={{
              maxLength: {
                value: 250,
                message: intl.formatMessage({ id: 'error.max-characters' }, { max: 250 }),
              },
            }}
            render={({ field }) => (
              <StyledTextInput
                {...field}
                id="healthPlanNameInput"
                label={intl.formatMessage({ id: 'account.log-in.health-plan-name' })}
                errorMessage={errors?.healthPlanName?.message}
              />
            )}
          />
        )}
        <Button
          id="continue"
          fullWidth
          disabled={!formComplete || isPending}
          loading={isPending}
          type="submit"
          sx={{ margin: '8px 0' }}
        >
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
      <SkipButton disabled={isPending} onClick={onSkip}>
        {intl.formatMessage({ id: 'common.skip' })}
      </SkipButton>
    </>
  );
}

export default HowDidYouHearAboutUs;
