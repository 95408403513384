import styled from '@emotion/styled';
import CircleIcon from '@mui/icons-material/Circle';

export const OnlineIcon = styled(CircleIcon)`
  color: #60a945;
  position: relative;
  top: 2px;
  left: 4px;
  font-size: 14px;
  width: 14px;
`;

export const OfflineIcon = styled(CircleIcon)`
  color: #e0e0e0;
  position: relative;
  top: 2px;
  left: 4px;
  font-size: 14px;
  width: 14px;
`;
