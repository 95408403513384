import React from 'react';
import PropTypes from 'prop-types';
import { SymptomContainer } from '../../styles';

const SymptomWrapper = ({ children }) => {
  return <>{children ? <SymptomContainer>{children}</SymptomContainer> : <></>}</>;
};

SymptomWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default SymptomWrapper;
