import AndyExplained from 'Assets/img/andy-explained.svg';
import { appInsights } from 'Core_Helpers/AppInsights';
import { LINKBUTTON } from 'Core_Components/ButtonRow/ButtonTypes.js';
import { BUBBLE, IMAGE, BUTTONROW } from 'Core_Components/Chat/ChatItem/ChatTypes.js';
import { CENTER, LEFT } from 'Core_Components/Chat/ChatItem/Position.js';

const messages = (type, setType, username, intl) => {
  return {
    Andy: [
      {
        text: AndyExplained,
        type: IMAGE,
        position: CENTER,
        alt: intl.formatMessage({ id: 'image.andy.acronym' }),
      },
      {
        text: intl.formatMessage({ id: 'andy.message1' }, { username }),
        position: LEFT,
        type: BUBBLE,
      },
      {
        text: intl.formatMessage({ id: 'andy.message2' }),
        position: LEFT,
        type: BUBBLE,
      },
      {
        text: intl.formatMessage({ id: 'andy.message3' }),
        position: LEFT,
        type: BUBBLE,
      },
      {
        type: BUTTONROW,
        buttons: [
          {
            text: intl.formatMessage({ id: 'andy.call' }),
            link: process.env.ANDY_PHONE_NUMBER,
            linkType: 'Phone',
            className: 'button x-large round secondary',
            type: LINKBUTTON,
            onClick: () => {
              appInsights.trackEvent({ name: 'Andy_Call' });
            },
          },
        ],
      },
    ],
  }[type];
};

export default messages;
