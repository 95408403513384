import styled from 'styled-components';

export const ReminderContainer = styled.div`
  color: black;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const Header = styled.h2`
  margin-bottom: 8px;
  font-size: 1.125rem;
  font-weight: bold;
`;

export const Content = styled.p`
  line-height: 1.25rem;
  margin-top: 0;
`;
