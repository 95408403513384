import React from 'react';
import { useIntl } from 'react-intl';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import {
  selectCurrentModal,
  selectIsEditPending,
  selectSelectedReminder,
  editReminder,
  cancelReminderDeactivation,
} from 'Core_Redux/reminders';
import {
  StyledButton,
  StyledCancelButton,
  StyledContent,
  StyledHeader,
  StyledModalBodyContainer,
  StyledModalContainer,
  StyledMultiButtonContainer,
} from './styles';
import { CONFIRM_DEACTIVATE, DEACTIVATION_SUCCESS } from './modals';

function ConfirmReminderDeactivationModal() {
  const intl = useIntl();
  const dispatch = useCoreDispatch();
  const currentModal = useCoreSelector(selectCurrentModal);
  const selectedReminder = useCoreSelector(selectSelectedReminder);
  const isEditPending = useCoreSelector(selectIsEditPending);

  const handleCancel = () => dispatch(cancelReminderDeactivation());
  const handleDeactivate = () => {
    if (isEditPending) {
      return;
    }
    dispatch(
      editReminder({ updatedReminder: { ...selectedReminder, isActive: false }, successModal: DEACTIVATION_SUCCESS }),
    );
  };

  return (
    <StyledModalContainer
      id="DeactivateReminder_Confirm"
      open={currentModal === CONFIRM_DEACTIVATE}
      aria-labelledby="DeactivateReminder_Confirm_Title"
      aria-describedby="DeactivateReminder_Confirm_Content"
    >
      <StyledModalBodyContainer>
        <StyledHeader id="DeactivateReminder_Confirm_Title">
          {intl.formatMessage({ id: 'reminders.modal-confirm-deactivation-header' })}
        </StyledHeader>
        <StyledContent id="DeactivateReminder_Confirm_Content">
          {intl.formatMessage({ id: 'reminders.modal-deactivation-body' })}
        </StyledContent>
        <StyledMultiButtonContainer>
          <StyledCancelButton onClick={handleCancel}>{intl.formatMessage({ id: 'common.cancel' })}</StyledCancelButton>
          <StyledButton onClick={handleDeactivate}>
            {intl.formatMessage({ id: 'reminders.modal-confirm-deactivation-turn-off' })}
          </StyledButton>
        </StyledMultiButtonContainer>
      </StyledModalBodyContainer>
    </StyledModalContainer>
  );
}

export default ConfirmReminderDeactivationModal;
