import styled from 'styled-components';
import { black, purple } from 'Styles/colors';

export const ColorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  flex: none;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #007070;
  font-size: 55px;
  height: 90px;
`;

export const HeaderText = styled.h1`
  font-size: 16px;
  margin: 0;
`;

export const ColorImage = styled.div`
  width: 85%;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const ColorSelectionContainer = styled.div`
  width: 275px;
  text-align: center;
  margin: auto;
  padding-bottom: 15px;

  @media only screen and (max-width: 350px) {
    padding-bottom: 0;
  }
`;

export const ColorSelection = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${(props) => props.color};
  box-shadow: ${(props) => (props.picked ? 'inset 0 0 0 0.15em #b3b3b3' : '')};
  border-radius: 50%;
  display: inline-block;
  margin: 3px 3px;
  border: ${(props) => (!props.picked && props.color === '#FFFFFF' ? 'solid 1px #e2e3de' : 'none')};
`;

export const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  height: 100%;
  width: 100%;
`;

export const Container = styled.div`
  text-align: center;
  width: 100%;
`;

export const ButtonContainer = styled(Container)`
  display: flex;
  justify-content: space-evenly;
  padding: 30px;

  @media screen and (max-width: 350px) {
    padding-bottom: 5px;
  }
`;

export const StyledLeftButton = styled.button`
  color: #4a4f7f;
  font-weight: bold;
  padding: 12px;
  width: 50%;
  min-height: 60px;
  height: 100%;
  border-radius: 15px;
  cursor: pointer;
  border: 2px solid #4a4f7f;
  font-size: 1.125rem;
  background-color: white;

  @media screen and (max-width: 450px) {
    height: 80px;
  }
`;

export const StyledRightButton = styled.button`
  font-weight: bold;
  margin-left: 12px;
  padding: 12px;
  width: 50%;
  min-height: 60px;
  height: 100%;
  background-color: #211f58;
  border-radius: 15px;
  cursor: pointer;
  color: white;
  border: 2px none;
  font-size: 1.125rem;
  line-height: 1.1875rem;

  @media screen and (max-width: 450px) {
    height: 80px;
  }
`;

export const ImageGrid = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImageRow = styled.div`
  width: 100%;
  height: 33%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const ImageButton = styled.button`
  width: 50%;
  max-width: 200px;
  background: transparent;
  border-color: white;
  border-style: solid;
`;

export const GridContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const NavButton = styled.button`
  background: transparent;
  border-color: white;
  border-style: solid;
  color: ${purple._900};
  :disabled {
    color: #b6b8b8;
  }
`;

export const NavIcon = styled.i`
  font-size: 2rem;
`;

export const Content = styled.p`
  padding-top: 8px;
  margin: 0;
  width: 90%;
  text-align: center;
  color: ${black._100};
  font-weight: bold;
  line-height: 24px;
`;
