import { ToolTypes } from 'Core_Pages/MyTools/enumerations/enums';
import RelationshipsToolCard from './MyRelationshipsTile/MyRelationshipsTile';
import VideosToolCard from './MyVideosTile/MyVideosTile';
import MyBrainBoostersTile from './MyBrainBoostersTile/MyBrainBoostersTile';
import MyMindsetTile from './MyMindsetTile/MyMindsetTile';
import MyPhysicalHealthTile from './MyPhysicalHealthTile/MyPhysicalHealthTile';
import MyCaregivingTile from './MyCaregivingTile/MyCaregivingTile';
import MyGuardianTile from './MyGuardianTile/MyGuardianTile';

export const ToolTiles = {
  [ToolTypes.VIDEOS]: VideosToolCard,
  [ToolTypes.RELATIONSHIPS]: RelationshipsToolCard,
  [ToolTypes.BRAINBOOSTERS]: MyBrainBoostersTile,
  [ToolTypes.MINDSET]: MyMindsetTile,
  [ToolTypes.PHYSICALHEALTH]: MyPhysicalHealthTile,
  [ToolTypes.CAREGIVING]: MyCaregivingTile,
  [ToolTypes.GUARDIAN]: MyGuardianTile,
};
