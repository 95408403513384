import { bodyAwareness } from 'Assets/img/my-tools-icons';

export const bodyAwarenessEn = {
  key: 'body-awareness',
  title: 'Body Awareness',
  titleImage: bodyAwareness,
  titleImageAlt: '',
  description: 'Becoming aware of physical sensations can help support balance throughout the day!',
  contentId: 12,
  sections: [
    {
      key: 'quiet-comfortable',
      header: 'Find a quiet place and make yourself comfortable there.',
      description: 'Go to an area where you will be safe, secure, and undisturbed for a little while.',
      itemsHeader: 'Tell me more:',
      items: [
        {
          text: 'Select a location inside or outdoors where you can get relaxed and comfortable.',
        },
        {
          text: 'Feel free to lie on your back, sit in a chair, or stand.',
        },
        {
          text: 'It’s okay to keep your eyes open or have them closed.',
        },
      ],
    },
    {
      key: 'deep-breaths-notice-body',
      header: 'Once you’re comfortable, take a few deep breaths and notice how your body feels.',
      description: 'For a few minutes, focus on your breathing; notice where it flows and how your body feels.',
      itemsHeader: 'Tell me more:',
      items: [
        { text: 'Slowly breathe in and out in a way that’s comfortable to you.' },
        {
          text: 'As you breathe, feel your breath filling your lungs and then picture it flowing out and expanding into your body.',
        },
        {
          text: 'Imagine your breath flowing down into your belly, legs, feet, and toes... back up into your chest, down your arms, and into your hands... then back up and into your head, shoulders, and chest again.',
        },
        {
          text: 'With each breath, relax deeper and act as a kind observer as you follow your breath through your body.',
        },
        {
          text: 'If your mind wanders, simply acknowledge that and gently bring your attention back to your breathing and the part of your body you were focusing on last.',
        },
      ],
    },
    {
      key: 'body-scan',
      header: 'Do a "body scan."',
      description: 'Next, pay attention to individual body parts and how they feel.',
      itemsHeader: 'Tell me more:',
      items: [
        {
          text: 'While you continue breathing and relaxing, gently move your attention to the bottom of your left foot. Notice how it feels. Is it loose? Tight? Soft? Achy?',
        },
        {
          text: 'Next, slowly move your awareness up to the top of your foot, and then your ankle. What do you feel there?',
        },
        {
          text: 'Continue moving your attention up your lower left leg, knee, upper leg, and then pelvis; the front, back, and sides. With gentle curiosity, notice how each area feels without forcing anything to appear. Just take it in and sense how they feel to you.',
        },
        {
          text: 'If you notice a tense spot or unpleasant feelings or thoughts come up in any of those areas, take a deep, slow breath and imagine relaxing the part more. Picture the feeling releasing and letting go as it relaxes.',
        },
        {
          text: 'Next, move your awareness to your right side of your body and repeat the process, scanning from the bottom of your right foot and up your leg.',
        },
        {
          text: 'Continue slowly moving your awareness upwards to your stomach, chest, neck, arms, shoulders, hairline, forehead, head, and face, taking in each area and how it feels.',
        },
        {
          text: 'Notice all the feelings that come up and gently let go of any unpleasant ones as you move your awareness throughout your whole body.',
        },
        {
          text: 'When you’re done scanning your body, continue breathing calmly and be present with yourself; appreciate the relaxation for a while longer. Take a moment to acknowledge yourself as a living, breathing, integrated, and whole being. ',
        },
        {
          text: 'When you’re ready, end the process and bring your awareness back to the setting around you.',
        },
      ],
    },
    {
      key: 'self-reflection',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your experience with this activity.',
      itemsHeader: 'Awareness Insights',
      items: [
        {
          text: 'What have you learned from this exercise?',
        },
        { text: 'How did you feel before completing this exercise?' },
        { text: 'How do you feel after it?' },
        { text: 'What part(s) of this exercise did you find most challenging? Why?' },
        { text: 'What did you enjoy most about this exercise?' },
        { text: 'What would help make the first step easy to begin?' },
        { text: 'What would be some benefits of doing this activity more often in general?' },
      ],
    },
    {
      key: 'self-reflection-pointers',
      itemsHeader: 'Pathway Pointers',
      items: [
        {
          text: 'Taking time to become aware of physical sensations can help quiet the mind, refresh the connection with oneself, and support overall well-being.',
        },
        {
          text: 'Self-guided body scanning and relaxation activities can help to boost attention, strengthen physical awareness, and recognize signals for maintaining stress-free and balanced throughout the day.',
        },
        {
          text: 'Take some time to relax and appreciate your physical and integrated wellness on your Thrive Pathway!',
        },
      ],
    },
  ],
};
