import { mindfulEating } from 'Assets/img/my-tools-icons';

export const mindfulEatingEs = {
  key: 'mindful-eating',
  title: 'Comer Pensativo',
  titleImage: mindfulEating,
  titleImageAlt: '',
  description:
    'Tomar conciencia de los sentimientos al momento de comer puede ayudar a incrementar la energía y mejorar el bienestar general.',
  contentId: 13,
  sections: [
    {
      key: 'food-item',
      header: 'Selecciona un alimento y llévalo contigo a un lugar tranquilo.',
      description:
        'Selecciona un alimento que disfrutas y llévatelo a un lugar donde te sientas seguro y nadie te interrumpa.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Elige un alimento provechoso que te quepa en la mano como una fruta o verdura, un trozo de pan, un producto lácteo, etc.',
        },
        {
          text: 'Selecciona un lugar interior o exterior donde te puedas sentar cómodamente.',
        },
        {
          text: 'Evita distracciones y no realices otras tareas durante este tiempo.',
        },
        {
          text: 'Si lo prefieres, puedes usar lápiz y papel para anotar tus observaciones.',
        },
      ],
    },
    {
      key: 'deep-breaths',
      header: 'Una vez que te sientas cómodo, respira profundamente y fíjate en cómo se siente tu cuerpo.',
      description:
        'Durante unos minutos, concéntrate en tu respiración; fíjate en cómo fluye y cómo se siente tu cuerpo.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Inspira y expira lentamente de una manera que te resulte cómoda.',
        },
        {
          text: 'Calma la mente y observa cómo se siente tu cuerpo. ¿Cómo está tu energía el día de hoy? ¿Sientes estrés? ¿En qué piensas?',
        },
        {
          text: 'Libera gradualmente tus pensamientos del día y dirige tu atención al momento presente.',
        },
        {
          text: 'Si tu mente divaga, simplemente vuelve a centrar tu atención poco a poco en la respiración.',
        },
        {
          text: 'Puedes mantener los ojos abiertos o cerrados mientras respiras.',
        },
      ],
    },
    {
      key: 'food-feelings',
      header: 'A continuación, concéntrate en el alimento que seleccionaste y en tus sentimientos.',
      description:
        'De manera relajada y sin hacer juicios, observa el alimento y fíjate en las sensaciones que surgen.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Sostén el alimento en la mano y toma nota de sus cualidades: descríbelo para ti mismo, ya sea en voz alta o por escrito.',
        },
        {
          text: 'Sugerencias para describir sus cualidades:',
          subItems: [
            {
              text: 'Aspecto: explora con los ojos cada parte del alimento. ¿De qué color es? ¿Cuál es su forma? ¿Qué tamaño tiene?',
            },
            {
              text: 'Sensación: ¿qué textura tiene? ¿Es liso? ¿Es irregular? ¿Es blando? ¿Es duro?',
            },
            {
              text: 'Olor: acércate el alimento a la nariz y percibe su aroma. ¿Sucede algo en tu boca o tu estómago? ¿Escuchaste algo? ¿Salivaste? ¿Te vino alguna imagen a la mente? ¿Evocó algún recuerdo?',
            },
            {
              text: 'Sonido: acércate el alimento al oído y observa si suena. ¿Algún rumor? ¿Zumba? ¿Cascabelea?',
            },
          ],
        },
        {
          text: 'A continuación, llévatelo a la boca y muérdelo con cuidado, pero sin masticarlo. Observa cómo te entra en la boca y dedica unos momentos a tomar conciencia de cómo se siente.',
        },
        {
          text: 'Empieza a masticar el alimento con una o dos mordidas lentas y saboréalo con atención. Observa cómo experimentas el sabor. Sin tragarlo, siéntelo en la boca. ¿Cambia a medida que continúas masticando? Asegúrate de masticarlo durante suficiente tiempo para apreciar el sabor.',
        },
        {
          text: 'Observa si puedes detectar la sensación de tragar antes de tragarlo. Después de tragarlo, ¿puedes sentirlo bajando hacia el estómago?',
        },
        {
          text: 'Antes de dar la siguiente mordida, percibe cómo se siente tu cuerpo después de tragarlo por completo.',
        },
        {
          text: 'Continúa comiendo el alimento, masticando lentamente y enfocándote en el proceso de comer con plena atención durante todo el tiempo que te resulte cómodo hacerlo.',
        },
      ],
    },
    {
      key: 'moment-of-gratitude',
      header: 'Cuando termines, finaliza con un momento de gratitud.',
      description: 'Reconoce el alimento y el esfuerzo dedicado a hacerlo crecer y prepararlo para tu sustento.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Haz una pausa por un momento y piensa sobre lo que notaste y cómo te sentiste al consumir el alimento.',
        },
        {
          text: 'Considera todos los elementos que fueron necesarios para que el alimento creciera y llegara a tus manos, incluidas las personas. Si lo deseas, haz un dibujo del alimento y su recorrido.',
        },
        {
          text: 'En tu manera particular, expresa gratitud por el alimento y el sustento que provee a tu cuerpo y a tu ser.',
        },
      ],
    },
    {
      key: 'self-reflection',
      header: 'Autorreflexión',
      description: 'Tómate un tiempo para revisar la experiencia con esta actividad.',
      itemsHeader: 'Percepciones y marcadores del camino',
      items: [
        { text: '¿Qué has aprendido de este ejercicio?' },
        { text: '¿Cómo te sentías antes de completar este ejercicio?' },
        { text: '¿Cómo te sientes después de hacerlo?' },
        { text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?' },
        { text: '¿Qué disfrutaste más de este ejercicio?' },
        { text: '¿Cuáles serían algunos beneficios de hacer esta actividad de manera regular?', divider: true },
        {
          text: 'Ser consciente de lo que comemos, de cómo lo comemos y del increíble trayecto que recorren los alimentos desde su origen hasta que nos llegan a nosotros nos puede ayudar a fortalecer una relación positiva con la comida, así como a desarrollar un mayor sentido de gratitud y apreciación de su contribución a nuestra salud y bienestar.',
        },
        {
          text: 'Detenerte un momento, dejar de hacer varias cosas a la vez y evitar las distracciones para saborear realmente la comida puede ayudarte a mantener una salud equilibrada y estimular pensamientos positivos durante el día.',
        },
        {
          text: 'Desarrollar conciencia sobre los pensamientos y sensaciones asociados con la comida puede ayudar a fortalecer las señales para apoyar un bienestar físico y emocional positivo e integrado.',
        },
        {
          text: 'Disfruta de tiempos para dedicarte a ti mismo en el camino hacia la prosperidad y experimenta el proceso de la alimentación consciente.',
        },
      ],
    },
  ],
};
