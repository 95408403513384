import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { CodeHelperText, CodeBox } from './styles';
import { Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const CodeField = ({ id, type, onChange, codeLength, value: inputData, helperText: errorMessage }) => {
  const [otpCode, setOtpCode] = useState(Array(codeLength).fill(''));
  const [verificationCodeRefs, setVerificationCodeRefs] = useState(Array(codeLength).fill(useRef(null)));
  const intl = useIntl();

  function updateOtpCode(index, data) {
    let curOtpCode = otpCode;
    curOtpCode[index] = data;
    setOtpCode(otpCode);
    inputData = otpCode.join('');
    onChange(inputData);
  }

  function updateOtp(data, index) {
    let val = data.currentTarget.value;
    let focusNext = false;

    if (!checkType(val)) {
      val = '';
      data.currentTarget.value = '';
    }

    if (val !== '') {
      focusNext = true;
    }
    updateOtpCode(index, val);
    if (focusNext && index + 1 < codeLength) {
      verificationCodeRefs[index + 1].focus();
    }
  }

  function reFocus(data, index) {
    if (data.keyCode == 8 && index - 1 >= 0 && data.target.value == '') {
      verificationCodeRefs[index - 1].focus();
    }
  }

  function initializeRef(ref, index) {
    let verificationRefCodes = verificationCodeRefs;
    verificationRefCodes[index] = ref;
    setVerificationCodeRefs(verificationRefCodes);
  }

  function checkType(data) {
    return !(type in patterns) || data == '' || data?.match(patterns[type]);
  }

  const patterns = {
    tel: /^\d+$/g,
  };

  return (
    <Grid
      key={`${id}_container`}
      id={`${id}_container`}
      container
      columnSpacing={7}
      aria-label={intl.formatMessage({ id: 'account.otp.enter' })}
    >
      {Array.from(Array(codeLength).keys()).map((index) => {
        const ariaLabel = intl.formatMessage(
          { id: 'account.sign-up.step-code' },
          { current: index + 1, total: codeLength },
        );
        return (
          <Grid
            key={`${id}_item_${index}`}
            id={`${id}_item_${index}`}
            item
            mobile={1}
            sx={{ marginBottom: `${errorMessage ? '10px' : '30px'}` }}
          >
            <CodeBox
              id={`${id}Input_${index}`}
              key={`${id}Input_${index}`}
              role="textbox"
              type={type}
              error={!!errorMessage}
              onChange={(data) => {
                updateOtp(data, index);
              }}
              inputRef={(ref) => initializeRef(ref, index)}
              onKeyDown={(data) => reFocus(data, index)}
              aria-label={ariaLabel}
              inputProps={{
                'aria-label': ariaLabel,
                maxLength: 1,
              }}
              variant="outlined"
            />
          </Grid>
        );
      })}
      {!!errorMessage && (
        <CodeHelperText error={!!errorMessage}>
          {errorMessage}
          <ErrorIcon />
        </CodeHelperText>
      )}
    </Grid>
  );
};

CodeField.displayName = 'CodeField';
CodeField.propTypes = {
  codeLength: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helperText: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.any,
};

export default CodeField;
