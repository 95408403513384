import { sceneType } from './constants/sceneType';
import { InteractionType } from './constants/interactionType';
import Phaser from 'phaser';

export class Game extends Phaser.Scene {
  constructor() {
    super(sceneType.GAME.NAME);
  }

  init = async () => {};

  preload = async () => {
    this.load.spritesheet('spinner', 'spinner.png', {
      frameHeight: 600,
      frameWidth: 600,
    });
  };

  create = async () => {
    this.gameData = this.registry.getAll();
    this.gameData.info.isInitialized = true;
    this.gameData.info.setData(this.gameData.info);
    this.gameDataInfo = this.gameData.info;
    this.petConfig = this.gameDataInfo.data;
    this.currentSceneType = InteractionType[this.gameDataInfo?.data?.sceneType];

    this.currentScene = this.scene.add(
      sceneType[this.currentSceneType.SCENE].NAME,
      sceneType[this.currentSceneType.SCENE].DATA,
      true,
      {
        appData: this.gameData,
        gameData: this.gameDataInfo.data,
        sceneType: this.currentSceneType,
      },
    );
  };

  update = async () => {};
}
