import { celebrateProgress } from 'Assets/img/my-tools-icons';

export const celebrateProgressEn = {
  key: 'celebrate-progress',
  title: 'Celebrate Your Progress',
  titleImage: celebrateProgress,
  titleImageAlt: '',
  description: 'Pausing to celebrate your accomplishments helps boost positive feelings and wellness!',
  contentId: 8,
  sections: [
    {
      key: 'write-goal',
      header: 'Write down something you want to accomplish – a goal, ambition, desire, etc.',
      description: 'Use paper and pen or your computer/device to create.',
    },
    {
      key: 'scale-goal',
      header: 'Then create a scale to rate where you currently are in reaching your goal.',
      description: '',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Draw a horizontal line and write the numbers 0 to 10 on top of it.',
        },
        {
          text: '“0” means you haven’t started yet, and “10” means you’ve completed it.',
        },
        { text: 'Circle the number you believe you are on the way to reaching your goal.' },
      ],
    },
    {
      key: 'recall-steps',
      header: 'Recall your action steps.',
      description:
        'For goals you marked above “0,” take a moment to recall the steps you’ve already taken toward them and write them down.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Below the rating scale, list the steps - no matter how small or how few - you’ve already taken towards your goal.',
        },
        {
          text: 'You can be as brief or detailed as you’d like!',
        },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your list and experience with this activity.',
      itemsHeader: 'Insights & pointers',
      items: [
        { text: 'How do you feel when you consider the progress you’ve made so far?' },
        { text: 'What can you do to celebrate even the small successes in the coming weeks?' },
        { text: 'Which of your personal strengths helped you get this far?' },
        { text: 'What plans and activities helped you get this far?' },
        {
          text: 'How can you use these strengths, plans, and activities in your next steps to get closer to your goal?',
        },
        { text: 'What have you learned from this exercise?' },
        { text: 'How did you feel before completing this exercise?' },
        { text: 'How do you feel after it?' },
        { text: 'What part(s) of this exercise did you find most challenging? Why?' },
        { text: 'What did you enjoy most about this exercise?' },
        { text: 'Which aspects/elements of the challenge/goal do you want to work on first?' },
        { text: 'What would help make the first step easier?' },
        { text: 'What would be some benefits of doing this activity more often?', divider: true },
        {
          text: 'With each step you take towards a new goal, it’s important to pause and celebrate your accomplishments along the way.',
        },
        {
          text: 'Acknowledging successes of the past and “owning” your part in them helps build positive energy and movement towards new goals.',
        },
        {
          text: 'Celebrate your accomplishments in a way that is meaningful to you. That can be in a small way, like pausing to smile in self-appreciation and acknowledgement, or treating yourself to a nice cup of coffee,or with something bigger like taking some time off.',
        },
        {
          text: 'Awareness of progress no matter how small is important to acknowledge for well-being. Be kind to yourself and enjoy the accomplishments regardless of the speed of moving forward. ',
        },
      ],
    },
  ],
};
