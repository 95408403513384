import styled from 'styled-components';
import { legacyFontColors } from 'Styles/colors';

const { indigo } = legacyFontColors;

export const PushNotificationPromptContainer = styled.div`
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 100%;
  z-index: 3;
  background-color: ${indigo};
`;

const StyledButton = styled.button`
  border-radius: 15px;
  height: 40px;
  font-size: 14px;
  min-width: 70px;
  border: none;
  margin: 15px 15px;
`;

export const StyledYesButton = styled(StyledButton)`
  background: white;
  color: ${indigo};
`;

export const StyledNoButton = styled(StyledButton)`
  background: none;
  color: white;
  margin-right: 0;
`;

export const PushNotificationPromptContent = styled.div`
  display: flex;
  justify-content: right;
  align-content: center;
`;

export const Title = styled.h2`
  color: white;
  font-size: 16px;
  padding: 15px 15px 0 15px;
`;

export const Overlay = styled.div`
  z-index: 2;
  background: black;
  opacity: 0.8;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow-x: hidden;
`;
