import styled from '@emotion/styled';

export const StyledActivitiesConfigHeader = styled.h3`
  margin: 15px 0 18px;
  font-size: 20px;
  font-weight: 600;
`;

export const StyledToggleContainer = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-flow: row wrap;
  color: black;
`;

export const StyledErrorText = styled.p`
  margin: -18px 0 15px 14px;
  color: #c92213;
  font-size: 0.75rem;
`;
