import { gameImportType } from '../../constants/gameImportType';
import { sceneType } from '../../constants/sceneType';
import { createAnimations } from '../../Scripts/createAnimations';
import { loadingScreenConfig } from './loading-screen';
// TODO (part of another jira ticket): Refine asset management,
// figure out how we will be storing, retrieving & managing assets
export class Asset extends Phaser.Scene {
  constructor() {
    let pack = {
      files: [
        { type: 'spritesheet', key: loadingScreenConfig.spriteKey, url: loadingScreenConfig.loadingScreenSprite },
      ],
    };
    super({
      key: sceneType.ASSET.NAME,
      pack: pack,
    });
    this.actors = [];
  }

  init(sceneData) {
    this.assetConfig = sceneData.assetConfig;
    this.petConfig = sceneData.petConfig;
    this.isWebGL = sceneData.isWebGL;
    this.gameType = sceneData.gameType;
  }

  preload() {
    this.setupLoadingScreen();
    this.loadActor(this.petConfig, true);
    this.load.multiatlas(this.gameType.ATLAS, this.gameType.ATLAS_PATH, this.gameType.ASSET_LOCATION);

    for (var object in this.assetConfig?.ASSETS) {
      var currentAsset = this.assetConfig?.ASSETS[object];

      if (currentAsset.TYPE == gameImportType.SPRITESHEET && currentAsset.NAME && currentAsset.DATA) {
        let currentAssetActor = currentAsset.DATA;
        const currentData = currentAssetActor.DATA.filter((x) => x.PETTYPE == this.petConfig.NAME);
        currentAssetActor.DATA = currentData?.length ? currentData : currentAssetActor.DATA;
        this.loadActor(currentAssetActor);
      } else if (currentAsset.TYPE == gameImportType.AUDIO && currentAsset.NAME && currentAsset.DATA) {
        this.load.audio(currentAsset.NAME, currentAsset.DATA);
      }
    }
  }

  create() {
    if (this.actors?.length > 0) {
      let y = 0;
      for (y = 0; y < this.actors.length; y++) {
        var currentActor = this.actors[y];

        let i = 0;
        if (currentActor.DATA?.length > 0) {
          let spriteName = `${currentActor.SPRITESHEET_FOLDER}/${currentActor.SPRITESHEET_NAME}`;
          for (i = 0; i < currentActor.DATA.length; i++) {
            createAnimations(
              this,
              currentActor.NAME,
              currentActor.DATA[i],
              `${spriteName}`,
              '.png',
              this.gameType.ATLAS,
            );
          }
        }
      }
    }
  }

  update() {}

  loadActor = (actorData, isPet = false) => {
    if (actorData?.DATA?.length > 0) {
      for (let i = 0; i < actorData.DATA.length; i++) {
        const data = this.getSpriteData(actorData.DATA[i]);
        if (isPet) {
          this.petScale = data.SCALE;
          this.petHeight = data.FRAMEHEIGHT;
          this.petWidth = data.FRAMEWIDTH;
        } else {
          actorData.originalScale = data.SCALE;
          actorData.originalHeight = data.FRAMEHEIGHT;
          actorData.originalWidth = data.FRAMEWIDTH;
        }
      }
    }

    for (const object in actorData.AUDIO) {
      const currentAsset = actorData.AUDIO[object];
      if (currentAsset.NAME && currentAsset.DATA) {
        this.load.audio(currentAsset.NAME, currentAsset.DATA);
      }
    }

    this.actors.push(actorData);
  };

  getSpriteData(spriteData) {
    const currentData = spriteData.RESDATA ?? spriteData.DATA;

    var curData = {
      DATA: undefined,
      FRAMEHEIGHT: 0,
      FRAMEWIDTH: 0,
      SCALE: 0,
    };

    if (this.isWebGL) {
      const resD = this.getMaxSpriteSizeData(currentData);
      curData.FRAMEHEIGHT = resD.SIZE;
      curData.FRAMEWIDTH = resD.SIZE;
      curData.SCALE = resD.SCALE;
      curData.DATA = resD.DATA;
    } else {
      curData.FRAMEHEIGHT = currentData[currentData.length - 1].SIZE;
      curData.FRAMEWIDTH = currentData[currentData.length - 1].SIZE;
      curData.SCALE = currentData[currentData.length - 1].SCALE;
      curData.DATA = currentData[currentData.length - 1].DATA;
    }
    return curData;
  }

  getMaxSpriteSizeData(currentData) {
    // This only works if using WebGL. GetMaxTextureSize is not a function in Canvas and will crash it if you attempt to use it
    // there. As part of todo's (another jira ticket), we will be finding a better way to handle switching between sizes
    this.maxPixelSize = this.game.renderer.getMaxTextureSize();

    var resD = {
      DATA: undefined,
      RES: 0,
      SIZE: 0,
      SCALE: 0,
    };

    for (var i = 0; i < currentData?.length; i++) {
      if (this.maxPixelSize > currentData[i].RES && resD.RES < currentData[i].RES) {
        resD.RES = currentData[i].RES;
        resD.DATA = currentData[i].DATA;
        resD.SIZE = currentData[i].SIZE;
        resD.SCALE = currentData[i].SCALE;
      }
    }

    return resD;
  }

  setupLoadingScreen() {
    this.anims.create({
      key: loadingScreenConfig.animationKey,
      frames: this.game.anims.generateFrameNumbers(loadingScreenConfig.spriteKey, {
        start: loadingScreenConfig.startFrame,
        end: loadingScreenConfig.endFrame,
      }),
      repeat: -1,
    });
    let x = this.game.renderer.width * loadingScreenConfig.xPosition;
    let y = this.game.renderer.height * loadingScreenConfig.yPosition;

    let spinner = this.add.sprite(x, y, loadingScreenConfig.spriteKey);
    spinner.setScale(loadingScreenConfig.scale);
    spinner.play(loadingScreenConfig.animationKey);

    this.load.on('complete', function () {
      spinner.destroy();
    });
  }
}
