import styled from '@emotion/styled';
import Button from 'Stories/components/Button/Button';
import { black, purple } from 'Styles/colors';
import { breakpoints } from 'Styles/theme';
import { ScreeningDialog } from '../styles';
import { Box } from '@mui/material';

export const LikertForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  
  ${theme.breakpoints.up(breakpoints.TABLET)} { 
    padding-top: 20px;
    row-gap: 25px;
  }

  .FormQuestion-question {
    font-size: 16px;
    width: 80%;
    margin: 0 auto;

    ${theme.breakpoints.up(breakpoints.TABLET)} { 
      padding-bottom: 10px;
    }
  }

  .FormQuestion-answer-group {
    display: flex;
    justify-content: space-between;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .FormQuestion-root {
    font-size: 16px;

    ${theme.breakpoints.up(breakpoints.TABLET)} { 
      border-bottom: 1px solid rgba(0, 0, 0, 0.24);
      padding-bottom: 15px;
    }
  }

  .FormQuestion-answer-unchecked {
    background-color: rgba(0, 0, 0, 0.06);
  }

  .FormQuestion-answer-unchecked,
  .FormQuestion-answer-checked {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;

    .FormQuestion-answer-label {
      font-size: 16px;
    }

    width: 100px;
    height: 80px;
  }

  .MuiCheckbox-root {
    padding: 5px;
  }

  .MuiFormControlLabel-root {
    margin: 0px;
  }
`,
);

export const LikertWrapper = styled(ScreeningDialog)`
  .MuiDialog-paperScrollPaper {
    color: ${black._87};
    background-color: white;
    text-align: center;
    padding: 20px 5px;
    align-items: center;
  }
`;

export const LikertContinueButton = styled(Button)(
  ({ theme }) => `
  width: 92%;
  margin: 30px 0px;
  max-width: 327px;

  ${theme.breakpoints.up(breakpoints.TABLET)} { 
    padding-top: 15px;
  }
`,
);

export const Bookmark = styled(Box)`
  display: flex;
  position: absolute;
  align-self: flex-start;
  background-color: ${purple._900};
  color: white;
  width: min-content;
  padding: 6px;
  top: 0;
  left: 0;
  line-height: 1.4;
  text-align: center;
`;
