import styled from '@emotion/styled';

export const ChatContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

export const ChatList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
  padding: 25px 20px 0;
`;
