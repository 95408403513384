import GradientCard from 'Core_Components/GradientCard/GradientCard';
import WarningCard from './WarningCard/WarningCard';

export const CardTypes = {
  WARNING: 'WARNING',
  DEFAULT: 'DEFAULT',
};

export const CardTypeMap = {
  [CardTypes.WARNING]: WarningCard,
  [CardTypes.DEFAULT]: GradientCard,
};
