import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import {
  selectSignupMetadata,
  navigateBack,
  completeHealthPlanEntry,
  selectBirthday,
  skipHealthPlanEntry,
} from 'Onboard_Redux/signup';
import { useForm, Controller } from 'react-hook-form';
import { ArrowBackIos } from '@mui/icons-material';
import Button from 'Stories/components/Button';
import SampleModal from '../SampleModal';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { Header, StyledTextInput } from 'Onboard_Components/Account/styles';
import { HealthPlanInfoTextContainer, SeeSampleButton } from './styles';
import SkipButton from 'Onboard_Components/Account/SignUp/SkipButton';
import { useOnboardMutation } from 'Onboard_Redux/services/api';
import { confirmedUserUpdated } from 'Onboard_Redux/authentication';

function HealthPlanInformation() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const { loading, progress } = useOnboardSelector(selectSignupMetadata);
  const { birthday } = useOnboardSelector(selectBirthday);
  const [onboard, { isLoading: userOnboarding, error }] = useOnboardMutation();
  const {
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
  } = useForm();
  const [show, setShow] = useState(false);
  const [isPending, setIsPending] = useState(false);

  function onBack() {
    if (!isLoading) {
      dispatch(navigateBack());
    }
  }

  function onSeeSampleClick() {
    setShow(true);
  }

  async function onContinue(data) {
    setIsPending(true);
    await dispatch(confirmedUserUpdated()).unwrap();
    await onboard({ healthPlanId: data.memberId, birthday }).unwrap();
    dispatch(completeHealthPlanEntry(data));
  }

  async function onSkip() {
    setIsPending(true);
    dispatch(skipHealthPlanEntry());
  }

  const formComplete = dirtyFields.healthPlanName && dirtyFields.memberId;

  const isLoading = (isPending && !error) || userOnboarding;

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <Header>
        <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />
        {intl.formatMessage({ id: 'account.log-in.health-plan' })}
      </Header>
      <HealthPlanInfoTextContainer>
        <SeeSampleButton fullWidth variant={Button.variant.TEXT} onClick={onSeeSampleClick}>
          {intl.formatMessage({ id: 'account.sign-up.see-sample' })}
        </SeeSampleButton>
        <p>
          {intl.formatMessage(
            { id: 'account.sign-up.health-plan-info.skip-to-partner-code' },
            { br: <br />, buttonText: <i>{intl.formatMessage({ id: 'common.skip-lower' })}</i> },
          )}
        </p>
      </HealthPlanInfoTextContainer>
      <form onSubmit={handleSubmit(onContinue)}>
        <Controller
          name="healthPlanName"
          control={control}
          rules={{
            maxLength: {
              value: 250,
              message: intl.formatMessage({ id: 'error.max-characters' }, { max: 250 }),
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <StyledTextInput
              {...field}
              id="healthPlanNameInput"
              errorMessage={errors.healthPlanName?.message}
              label={intl.formatMessage({ id: 'account.log-in.health-plan-name' })}
            />
          )}
        />
        <Controller
          name="memberId"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <StyledTextInput
              {...field}
              id="memberIdInput"
              label={intl.formatMessage({ id: 'account.log-in.member-id' })}
            />
          )}
        />
        <Button fullWidth loading={loading || isLoading} disabled={!formComplete} type="submit">
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
      <SkipButton disabled={isLoading} onClick={onSkip}>
        {intl.formatMessage({ id: 'common.skip' })}
      </SkipButton>
      <SampleModal show={show} setShow={setShow} />
    </>
  );
}

export default HealthPlanInformation;
