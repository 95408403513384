import { IVideoService } from 'Core_Pages/Video/IVideoService';
import { VimeoVideoService } from 'Core_Pages/Video/services/VimeoVideoService';
import { UserAccessor } from 'Core_Helpers/UserAccessor';

export class GeneralVideoService implements IVideoService {
  private static _instance: GeneralVideoService = new GeneralVideoService();
  private vimeoVideoService: VimeoVideoService;
  private userAccessor: UserAccessor;
  private vimeoAuthToken;
  private videoCount;
  private videos;

  constructor() {
    if (GeneralVideoService._instance) {
      return GeneralVideoService._instance;
    }

    this.vimeoVideoService = new VimeoVideoService();
    this.userAccessor = new UserAccessor();
    this.videoCount = 0;
  }

  private loadVideos = async () => {
    if (!this.vimeoAuthToken) {
      const vimeoAuth = await this.vimeoVideoService.getVimeoAuthorization();
      this.vimeoAuthToken = vimeoAuth;
    }

    if (!this.videoCount || this.videoCount < 1) {
      let videoPlaylist = await this.vimeoVideoService.get(this.vimeoAuthToken, '/10868003/videos');

      videoPlaylist = videoPlaylist
        ?.filter(
          (x) =>
            (!x.language && this.userAccessor.User.Language != 'es') || x.language === this.userAccessor.User.Language,
        )
        ?.map((x) => ({
          ...x,
          id: x.uri.split('/').pop(),
        }));

      this.videos = videoPlaylist;
      this.videoCount = videoPlaylist?.length;
    }
  };

  async getVideos() {
    await this.loadVideos();
    return this.videos;
  }
}
