import { supportingMentalHealthNeeds } from 'Assets/img/my-tools-icons';

export const supportingIndividualsMentalHealthEn = {
  key: 'supporting-individuals-mental-health',
  title: 'Supporting Mental Health Needs',
  titleImage: supportingMentalHealthNeeds,
  titleImageAlt: '',
  contentId: 40,
  sections: [
    {
      key: 'supporting-individuals-mental-health-intro',
      description:
        'Mental health needs come in all different shapes and sizes. They range in type and severity. This article will provide some ways that you can support those you are caring for who have mental health needs such as depression or anxiety.',
    },
    {
      key: 'reach-out-for-help',
      header: 'When to Reach Out for Help',
      description:
        'If the individual you are caring for has demonstrated any of the following, you should reach out to their doctor, care manager, or mental health provider for help:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Expressing feelings of worthlessness or wanting to hurt themselves or someone else',
        },
        {
          text: 'Staying in bed all day (for those who are not bed bound) or sleeping more often and with significantly less energy',
        },
        { text: 'Demonstrating little interest or pleasure in doing things' },
        { text: 'Moving around excessively with nervous behaviors' },
      ],
    },
    {
      key: 'general-mental-health-tips',
      header: 'General Mental Health Tips',
      description:
        'Navigating the mental health needs of another person, especially one that you love or are caring for is challenging. Sometimes their needs are ones that may be hard to understand or may not seem rational. Here are a few tips to keep in mind:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Be patient – Mental health need vary a lot day-to-day in frequency of needs, symptoms, and severity.',
        },
        {
          text: 'Recognize your own reaction and triggers – When mental health needs arise quickly, recognize what you need as a caregiver to be supportive as sometimes words or actions from the individually can be emotionally or physically painful.',
        },
        {
          text: 'Cognition impairments can impact mental health needs – Mental health needs combined with cognition impairments can make symptoms, behaviors, and actions much worse. For example, if an individual has mild mental health needs during their life this can quickly shift to moderate or severe needs when their cognitive functioning is no longer able to process thoughts or emotions the way they had previously.',
        },
        {
          text: 'Know the best ways to help support the individual that you are caring for – Find out from the individual’s family or care providers what has and has not worked in the past and what you can do to best help them.',
        },
        {
          text: 'Manage your expectations – Just because one day is good, it does not mean that all days will be good moving forward. Allow yourself to erase your expectations and focus on each day and moment as it comes.',
        },
        {
          text: 'Equip yourself with knowledge – Ask the individual, their family, or their care manager/nurse/doctor what you need to know when caring for them and how you can best help them.',
        },
        {
          text: 'Make sure the individual is using their medication appropriately and as prescribed – If the individual is taking routine or as needed medication for their mental health needs, make sure they know how to and are appropriately taking and prescriptions.',
        },
      ],
    },
    {
      key: 'anxiety-tips',
      header: 'Anxiety-Specific Tips',
      description:
        'While every person experiences anxiety differently, here are a few strategies to prevent anxiety symptoms from occurring or can help calm some mild symptoms:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Avoid overstimulating environments – Being in places with a lot of noise or sounds coming from multiple directions (like a crowded restaurant) can increase symptoms of anxiety.',
        },
        {
          text: 'Create a calm space – Play calming music or nature sounds and use scents such as mint or lavender.',
        },
        {
          text: 'Encourage repetitious games or activities – It is a good idea to have multiple activities available and ready to use such as folding socks, stacking objects, coloring, or simple games such as solitaire. These can help the individual self soothe and can be calming.',
        },
        {
          text: 'Create and keep a schedule – Share and talk through the activities that you are planning to complete during the day and avoid situations where you may feel rushed or are running late.',
        },
        {
          text: 'Know the individual’s anxiety triggers – You may need to avoid talking about certain topics or going to certain places.',
        },
        {
          text: 'Know what helps the individual’s symptoms – Talk to those closest to the individual to best understand what has helped their anxiety symptoms in the past and learn what can ease their symptoms.',
        },
      ],
    },
    {
      key: 'depression-tips',
      header: 'Depression, Loneliness, or Social Isolation Tips ',
      description:
        'As a caregiver to an individual who has experienced or is experiencing depression, loneliness, or social isolation – finding the right balance of encouraging activity and offering empathy is key. Here are a few tips to help with these symptoms:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Get outside – Even if for only a few minutes or for a short walk, make a point to get outside during the day. If your climate is not great for being outside, allow natural light in and sit by the sun, when possible.',
        },
        {
          text: 'Encourage activities of interest – Offer games, outings, gardening, knitting, or other activities to engage the individual often throughout the day. Even if they reject you once, or even multiple times – keep offering!',
        },
        {
          text: 'Listen empathetically – Encourage the individual to talk and express whatever they may be feeling. Listen to what they are saying without offering judgement, a solution, or to come up with a great response. Just listen. Offer empathetic phrases such as, “That sounds challenging”, “I’m sorry you are feeling that way”, or “How can I best support you?”',
        },
      ],
    },
  ],
};
