import { caringForYourself } from 'Assets/img/my-tools-icons';

export const caringForYourselfEn = {
  key: 'caring-for-yourself',
  title: 'Caring for Yourself',
  titleImage: caringForYourself,
  titleImageAlt: '',
  contentId: 31,
  sections: [
    {
      key: 'caring-for-yourself-intro',
      description:
        'Caregiving for a loved one is one of the toughest jobs out there – and can feel isolating and lonely. Your willingness to take on this role means that you have a big heart and want to give of yourself to help others. What a wonderful thing you are doing! Here are a few tips that can help you care for yourself so that you can better care for those around you.',
    },
    {
      key: 'take-time',
      header: 'Take Time for Self-Care',
      description:
        'Caring for yourself helps you to care for others. Read through that last sentence again. You will be a better caregiver when your needs are met and you take time for yourself. How you practice great self-care can vary day-to-day and week-to-week. For example, listen to what your mind and body needs. Sometimes self-care only takes a few minutes of your time, while other times it may take a few hours away to feel recharged and refreshed. Here are some examples of self-care to consider:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Deep breathing, meditation, yoga, or other relaxation activities – check out some of the other resources and relaxation activities in our Pyx app ',
        },
        { text: 'Take an hour to spend time by yourself reading, journaling, or simply enjoying the quiet' },
        { text: 'Get outside! Sunshine and fresh air can help clear your mind and offer you a sense of calm' },
        { text: 'Take a walk or exercise in a way that is enjoyable to you ' },
        {
          text: 'Find a sport or activity that you enjoy and join a group or a league that meets regularly – bridge club or axe throwing anyone?',
        },
        {
          text: 'Allow yourself some time to take a warm bath – bath salts or scents such as lavender and peppermint can be natural and relaxing',
        },
        {
          text: 'Schedule time with a friend or your significant other – building and maintaining relationships helps you feel less alone',
        },
      ],
    },
    {
      key: 'schedule-and-goals',
      header: 'Write Down Your Schedule and Your Goals',
      description:
        'Find a notebook, planner, or an app on your phone to help you stay organized. Write down your goals for each day, week, month, or year. When caring for someone else, your schedule can change quickly. To best care for yourself, it is important to not only know your schedule, but what you want to accomplish each day. Writing it down (or electronically noting it in a calendar or other application) is proven to help you accomplish it! Here are some examples of some daily, weekly, and annual goals to set for yourself:',
      itemsHeader: 'Tell me more',
      items: [
        { key: 'daily-header', description: '<b>Daily</b>' },
        {
          text: 'Take a few minutes to do some deep breathing',
        },
        {
          text: 'Read a book for 30 minutes',
        },
        {
          text: 'Take a 20-minute walk',
        },
        {
          text: 'Journal about how I am feeling',
        },
        { key: 'weekly-header', description: '<b>Weekly</b>' },
        {
          text: 'Schedule lunch with a friend',
        },
        {
          text: 'Walk four days this week',
        },
        {
          text: 'Plan out your meals',
        },
        {
          text: 'Schedule a mental health visit',
        },
        {
          text: 'Rent a bowling lane for our family',
        },
        { key: 'annual-header', description: '<b>Annual</b>' },
        { text: 'Read 5 books' },
        { text: 'Meet with a mental health therapist 10 times' },
        { text: 'Join a pickleball league' },
        { text: 'Learn a new skill' },
        { text: 'Walk or exercise at least four times each week' },
      ],
    },
  ],
};
