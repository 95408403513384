import styled from '@emotion/styled';

export const ChatImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.position};
  padding: 10px;
  max-width: ${(props) => (props.position !== 'center' ? '80%' : '100%')};

  @media screen and (max-width: 350px) {
    max-width: 100%;
  }

  @media screen and (min-width: 600px) {
    max-width: ${(props) => (props.position !== 'center' ? '45%' : '100%')};
  }
`;

export const StyledChatImage = styled.img`
  height: auto;
  width: 100%;
  max-height: 250px;
`;

export const Placeholder = styled.div`
  height: 250px;
  background-color: lightgray;
`;

export const IconContainer = styled.button`
  height: auto;
  width: 100%;
  background-color: #3d417d;
  color: #ffffff;
  border: none;
  font-size: 24px;
  justify-content: right;
`;
