import React from 'react';
import { StyledHeader, ExploreContainer } from './styles';
import { Grid, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';

const ChatHeader = () => {
  const intl = useIntl();
  const history = useHistory();

  const onClickHome = () => {
    history.push(HOME);
  };

  return (
    <StyledHeader className="safe-area">
      <Grid container marginTop="8px" alignItems="center" textAlign="center">
        <Grid item mobile={3} textAlign="left">
          <ExploreContainer onClick={onClickHome}>
            <HomeOutlinedIcon fontSize="large" />
            <Typography fontSize={12}>{intl.formatMessage({ id: 'menu-header.explore' })}</Typography>
          </ExploreContainer>
        </Grid>
        <Grid item mobile={6}>
          <Typography fontSize={22} fontWeight={700}>
            {intl.formatMessage({ id: 'common.pyxir' })}
          </Typography>
        </Grid>
        <Grid item mobile={3}></Grid>
      </Grid>
    </StyledHeader>
  );
};

export default ChatHeader;
