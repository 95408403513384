import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

const displayLightStatusBar = async () => {
  if (Capacitor.getPlatform() === 'ios' && Capacitor.isPluginAvailable('StatusBar')) {
    await StatusBar.setStyle({ style: Style.Light });
    await StatusBar.show();
  }
};

const displayDarkStatusBar = async () => {
  if (Capacitor.getPlatform() === 'ios' && Capacitor.isPluginAvailable('StatusBar')) {
    await StatusBar.setStyle({ style: Style.Dark });
    await StatusBar.show();
  }
};

export { displayLightStatusBar, displayDarkStatusBar };
