import styled from '@emotion/styled';

export const StyledCardIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -13px;
  margin-right: -7px;

  .MuiSvgIcon-root {
    font-size: 33px;
    color: red;
  }
`;
