import reduxStore from 'Core_Redux/store';

export class UserResourceAccessor {
  private static _instance: UserResourceAccessor = new UserResourceAccessor();

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (UserResourceAccessor._instance) {
      return UserResourceAccessor._instance;
    }
  }

  public get Resources() {
    const userState = reduxStore.store.getState().user;

    return {
      Permissions: userState.userInfo?.permission,
      Roles: userState.userInfo?.role,
    };
  }
}
