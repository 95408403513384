import { atom, getDefaultStore, useAtom } from 'jotai';
import { DirectMessage, MessageType } from '../Models/DirectMessage';
import { DirectMessageService, Agent } from '../Services/DirectMessageService';
import { SessionStatus } from '../Models/SessionStatus';
import { Singleton } from 'Core_Helpers/Singleton';
import { ModalStatusTypes } from '../Models/ModalStatusTypes';
import { browserHistory } from 'Core_Helpers/AppInsights';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';
import { ErrorType } from '../Models/ErrorType';
import { Keyboard } from '@capacitor/keyboard';
import { isNative } from 'Core_Helpers/isNative';
export class DirectMessageStore extends Singleton {
  private directMessageService: DirectMessageService;
  private atomStore = getDefaultStore();
  private isChatOpenAtom;
  private directMessagesAtom;
  private sessionStatusAtom;
  private errorMessageAtom;
  private errorTypeAtom;
  private isAgentOnlineAtom;
  private hasUnreadMessageAtom;
  private showSessionExpiredModalAtom;
  private isAgentTypingAtom;
  private scrollChatToBottomCallback;

  constructor() {
    super();

    this.directMessageService = new DirectMessageService();
    this.directMessageService.OnMessageReceivedCallback = this.onReceiveMessage;
    this.directMessageService.OnErrorCallback = this.onError;
    this.directMessageService.OnConnectedCallback = this.onConnected;
    this.directMessageService.OnSessionEndCallback = this.onSessionEnd;
    this.directMessageService.IsAgentOnlineChangeCallback = this.onIsAgentOnlineChange;
    this.directMessageService.OnAgentJoinCallback = this.onAgentJoin;
    this.directMessageService.OnAgentTypingCallback = this.onAgentTyping;

    this.isChatOpenAtom = atom<boolean>(false);
    this.directMessagesAtom = atom<Array<DirectMessage>>([]);
    this.sessionStatusAtom = atom<SessionStatus>(SessionStatus.DISCONNECTED);
    this.errorMessageAtom = atom<string>('');
    this.isAgentOnlineAtom = atom<boolean>(false);
    this.hasUnreadMessageAtom = atom<boolean>(false);
    this.showSessionExpiredModalAtom = atom<ModalStatusTypes>(ModalStatusTypes.CLOSED);
    this.isAgentTypingAtom = atom<boolean>(true);
    this.errorTypeAtom = atom<ErrorType>(ErrorType.NONE);
  }

  public Use = () => {
    useAtom(this.isChatOpenAtom);
    useAtom(this.directMessagesAtom);
    useAtom(this.sessionStatusAtom);
    useAtom(this.errorMessageAtom);
    useAtom(this.errorTypeAtom);
    useAtom(this.isAgentOnlineAtom);
    useAtom(this.hasUnreadMessageAtom);
    return this;
  };

  public StartAgentPoolStatusMonitor = () => {
    this.directMessageService.MonitorAgentPoolStatus();
  };

  public get IsChatOpen(): boolean {
    return this.atomStore.get(this.isChatOpenAtom);
  }

  public get DirectMessages(): Array<DirectMessage> {
    return this.atomStore.get(this.directMessagesAtom);
  }

  public get SessionStatus(): SessionStatus {
    return this.atomStore.get(this.sessionStatusAtom);
  }

  public get ErrorMessage(): string {
    return this.atomStore.get(this.errorMessageAtom);
  }

  public get ErrorType(): ErrorType {
    return this.atomStore.get(this.errorTypeAtom);
  }

  public get IsAgentOnline(): boolean {
    return this.atomStore.get(this.isAgentOnlineAtom);
  }

  public get IsAgentTyping(): boolean {
    return this.atomStore.get(this.isAgentTypingAtom);
  }

  public get HasUnreadMessage(): boolean {
    return this.atomStore.get(this.hasUnreadMessageAtom);
  }

  public get ShowSessionExpiredModal(): string {
    return this.atomStore.get(this.showSessionExpiredModalAtom);
  }

  public OpenChat = () => {
    this.atomStore.set(this.isChatOpenAtom, true);
    this.setScrollOnKeyboardOpen();
  };

  public onReceiveMessage = (message: string, agent: Agent): void => {
    this.atomStore.set(this.isAgentTypingAtom, false);
    this.AddMessage(new DirectMessage(MessageType.RECEIVED, message, agent.display, agent.isHuntGroup));
    this.atomStore.set(this.hasUnreadMessageAtom, true);
  };

  public Connect = async () => {
    if (this.SessionStatus == SessionStatus.DISCONNECTED) {
      this.atomStore.set(this.errorMessageAtom, '');
      this.atomStore.set(this.sessionStatusAtom, SessionStatus.CONNECTING);
      this.atomStore.set(this.errorTypeAtom, ErrorType.NONE);
      await this.directMessageService.Initialize();
    }
  };

  public SendMessage = (message: string): void => {
    if (message == null || message == '') {
      return;
    }

    this.directMessageService.SendMessage(message);
    this.AddMessage(new DirectMessage(MessageType.SENT, message, 'You', false));
  };

  public AcknowledgeMessagesRead = (): void => {
    this.atomStore.set(this.hasUnreadMessageAtom, false);
  };

  public CloseSessionExpiredModal = () => {
    this.atomStore.set(this.showSessionExpiredModalAtom, ModalStatusTypes.CLOSED);
  };

  public EndSession = () => {
    this.directMessageService.EndSession();
  };

  public HideSession = () => {
    this.atomStore.set(this.isChatOpenAtom, false);
    if (this.SessionStatus === SessionStatus.ENDED) {
      this.ResetSession();
    }
    browserHistory.push(HOME);
  };

  public SetScrollChatToBottom(fn) {
    this.scrollChatToBottomCallback = fn;
  }

  public ScrollChatToBottom() {
    if (this.scrollChatToBottomCallback) {
      this.scrollChatToBottomCallback();
    }
  }

  private onIsAgentOnlineChange = (isAgentOnline: boolean): void => {
    this.atomStore.set(this.isAgentOnlineAtom, isAgentOnline);
  };

  private ResetSession = () => {
    this.atomStore.set(this.sessionStatusAtom, SessionStatus.DISCONNECTED);
    this.atomStore.set(this.directMessagesAtom, []);
    this.atomStore.set(this.hasUnreadMessageAtom, false);
    this.atomStore.set(this.isAgentTypingAtom, true);
    this.atomStore.set(this.isChatOpenAtom, false);
  };

  private onError = (errorType: ErrorType, errorMessage: string): void => {
    if (this.SessionStatus === SessionStatus.ENDED) return;

    this.atomStore.set(this.errorTypeAtom, errorType);
    this.atomStore.set(this.errorMessageAtom, errorMessage ?? 'An error has occurred');
  };

  private onConnected = (): void => {
    this.atomStore.set(this.errorMessageAtom, '');
    this.atomStore.set(this.sessionStatusAtom, SessionStatus.CONNECTED);
  };

  private onSessionEnd = (): void => {
    if (this.SessionStatus === SessionStatus.ENDED) return;

    this.atomStore.set(this.sessionStatusAtom, SessionStatus.ENDED);
    this.atomStore.set(this.showSessionExpiredModalAtom, ModalStatusTypes.OPEN);

    this.AddMessage(new DirectMessage(MessageType.SESSIONEND, null, null, false));
    this.atomStore.set(this.isAgentTypingAtom, false);
  };

  private onAgentJoin = (agent): void => {
    this.AddMessage(new DirectMessage(MessageType.JOINED, null, agent.display, agent.isHuntGroup));
  };

  private AddMessage = (message: DirectMessage) => {
    const directMessages: Array<DirectMessage> = this.atomStore.get(this.directMessagesAtom);
    directMessages.push(message);
    this.atomStore.set(this.directMessagesAtom, [...directMessages]);
  };

  private onAgentTyping = (): void => {
    this.atomStore.set(this.isAgentTypingAtom, true);
  };

  private setScrollOnKeyboardOpen() {
    if (isNative()) {
      Keyboard.removeAllListeners();
      Keyboard.addListener('keyboardDidShow', () => {
        setTimeout(() => {
          if (this.ScrollChatToBottom) {
            this.ScrollChatToBottom();
          }
        }, 500);
      });
    }
  }
}
