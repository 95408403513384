import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

const Pyxir = (props) => {
  const intl = useIntl();

  return (
    <svg
      role="img"
      focusable="false"
      aria-labelledby="svgPyxirTitle"
      aria-describedby="svgPyxirDescription"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 900 900"
    >
      <title id="svgPyxirTitle">
        <FormattedMessage id="image.coloring-book.pyxir" />
      </title>
      <desc id="svgPyxirDescription">{intl.formatMessage({ id: 'image.coloring-book.pyxir-description' })}</desc>
      <g id="PyxirLayer_2" data-name="Layer 2">
        <path
          onClick={() => props.onFill(0)}
          fill={props.colors[0]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M106.28,279.3c0-5.83.33-11.59.81-17.3H100.2c-13.66,0-25.72,9.56-28.08,23a28.08,28.08,0,0,0,27.52,33l10.28,0a206,206,0,0,1-3.64-38.72Z"
        />
        <path
          onClick={() => props.onFill(1)}
          fill={props.colors[1]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M798.6,262h-3.25c.53,6.06.85,12.18.84,18.38h0a205.56,205.56,0,0,1-4,39.63h6.91a29.09,29.09,0,0,0,29-31.28C826.93,273.54,813.83,262,798.6,262Z"
        />
        <path
          onClick={() => props.onFill(2)}
          fill={props.colors[2]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M640.61,545.15l-.57.19c-45.14,15-113.2,24.54-189.34,24.41C374.87,569.62,307.09,560,262,544.88l-1.14-.37A162.64,162.64,0,0,0,243,618.19c-.17,100.55,207.26,234.66,207.26,234.66s207.87-133.42,208-234A162.52,162.52,0,0,0,640.61,545.15ZM500.83,691c-17,24.55-50.31,39.53-50.31,39.53l-.09-.11-.08.11s-33.22-15.1-50.18-39.7c-15.52-22.53-13.11-52.6,12.49-61.74,14.63-5.22,27.58-1.43,37.93,8.1,10.38-9.5,23.34-13.25,38-8C514.11,638.43,516.42,668.5,500.83,691Z"
        />
        <path
          onClick={() => props.onFill(3)}
          fill={props.colors[3]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M670.18,471.23a205,205,0,0,1-41,12.78,207.09,207.09,0,0,1-40.71,4l-275.24-.46a205.68,205.68,0,0,1-81.66-17c-17,9.26-26.65,19.75-26.67,30.88,0,16.51,21,31.68,56,43.55l1.14.37C307.09,560.38,374.87,570,450.7,570.17c76.14.13,144.2-9.38,189.34-24.42l.57-.18c35-11.76,56.1-26.86,56.13-43.37C696.76,491.07,687.14,480.55,670.18,471.23Z"
        />
        <path
          onClick={() => props.onFill(4)}
          fill={props.colors[4]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M795.27,262c-8.13-92.7-77.94-168.41-167.93-185.51C577.92,58.08,517.24,47.14,451.57,47S325.19,57.66,275.71,75.92C185.28,92.79,115,168.85,107.06,262.07c-.48,5.7-.8,11.45-.81,17.27h0A206,206,0,0,0,109.88,318c12.89,68.24,59.46,125,121.68,152a205.68,205.68,0,0,0,81.66,17l275.24.46a207.09,207.09,0,0,0,40.71-4,205,205,0,0,0,41-12.78C732.12,444,778.72,388,792.14,320.32a206.76,206.76,0,0,0,4-39.83h0C796.13,274.27,795.81,268.11,795.27,262ZM450.9,449c-159.21-.27-288.16-66.76-288-148.52S292.19,152.66,451.4,152.93s288.17,66.76,288,148.51S610.12,449.26,450.9,449Z"
        />
        <path
          onClick={() => props.onFill(5)}
          fill={props.colors[5]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M451.4,152.93C292.19,152.66,163,218.72,162.87,300.48S291.69,448.73,450.9,449s288.39-65.8,288.53-147.56S610.61,153.19,451.4,152.93Zm51.65,240.4c0,13.78-23.35,24.9-52.1,24.86s-52-11.26-52-25S503.07,379.56,503.05,393.33ZM578.5,266.59a26.75,26.75,0,1,1-26.79,26.7A26.75,26.75,0,0,1,578.5,266.59Zm-254.58-.42a26.75,26.75,0,1,1-26.79,26.7A26.75,26.75,0,0,1,323.92,266.17Z"
        />
        <path
          onClick={() => props.onFill(6)}
          fill={props.colors[6]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M488.55,629.2c-14.62-5.28-27.58-1.53-38,8-10.35-9.53-23.3-13.32-37.93-8.1-25.6,9.14-28,39.21-12.49,61.74,17,24.6,50.18,39.7,50.18,39.7l.08-.11.09.11s33.27-15,50.31-39.53C516.42,668.5,514.11,638.43,488.55,629.2Z"
        />
        <path
          onClick={() => props.onFill(7)}
          fill={props.colors[7]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M451,418.19c28.75,0,52.08-11.08,52.1-24.86S399,379.38,398.94,393.16,422.21,418.14,451,418.19Z"
        />
        <circle
          onClick={() => props.onFill(8)}
          fill={props.colors[8]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          cx="323.87"
          cy="292.91"
          r="26.74"
          transform="translate(-112.26 314.81) rotate(-45)"
        />
        <path
          onClick={() => props.onFill(9)}
          fill={props.colors[9]}
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="2px"
          strokeMiterlimit="10"
          className="cls-1"
          d="M578.41,320.08a26.75,26.75,0,1,0-26.7-26.79A26.75,26.75,0,0,0,578.41,320.08Z"
        />
      </g>
    </svg>
  );
};

Pyxir.propTypes = {
  onFill: PropTypes.func,
  colors: PropTypes.array,
};

export default Pyxir;
