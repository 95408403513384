import { griefLossTransitions } from 'Assets/img/my-tools-icons';

export const griefAndLossEn = {
  key: 'grief-and-loss',
  title: 'Grief and Loss During Transitions',
  titleImage: griefLossTransitions,
  titleImageAlt: '',
  contentId: 34,
  sections: [
    {
      key: 'grief-and-loss-intro',
      description:
        'One of the most challenging parts of being a caregiver can be emotionally navigating the needs of your loved one while anticipating the loss of a loved one and, for some, actively experiencing the cognitive loss of someone important to you. Emotions that we experience can look and feel similar to the grief process after the loss of a loved one. We are going to explore common signs of grief and ways that can help you move forward. This information is applicable to all types of grief and can be used if you are experiencing other types of loss as well.',
    },
    {
      key: 'stages-grief-loss',
      header: 'The Stages and Feelings of Grief and Loss',
      description:
        'There are multiple schools of thought about the stages and feelings that one experiences during a loss. We provide brief descriptions of some of these stages. It is important to note that the way that people experience grief is unique – no one person grieves the same, and that’s okay. It is also completely normal for you to float in and out of different stages and feelings or to stay a long time in one or more of the stages.',
      itemsHeader: 'Tell me more',
      items: [
        {
          key: 'shock-desc',
          description:
            '<b>Shock</b><br />Whether or not a loss is planned, it can feel shocking to you and your body when the actual event occurs. This can also occur if you receive a new diagnosis or other unwanted news. Sometimes this part is often described as feeling numb, or without feeling, or it may feel like you are walking around in a fog. It may be challenging to complete some of your daily functions when you are in this stage. You may feel forgetful or like you are not able to complete simple tasks. Crying and anger outbursts are normal, as well as feeling apathetic or having a decreased interest in things your previously found enjoyable. You may have difficulty sleeping, a loss of appetite, or a strong desire to binge eat or drink.',
        },
        {
          key: 'denial-bargaining',
          description:
            '<b>Denial and Bargaining</b><br />Denial and bargaining are one of your mind’s ways of trying to protect you from the loss or potential loss. You may feel like your loved one will wake up the next morning with their memory back or that a miracle will change terminal diagnosis. You mind and body can feel like they are tricking you into thinking that person is still be available to you in the same capacity. Similarly, you may find yourself making pleas to let them come back to you in the same way they were before.',
        },
        {
          key: 'anger-sadness',
          description:
            '<b>Anger and Sadness</b><br />Anger and sadness are two common feelings that you may experience during multiple stages of grief. They are both normal and natural emotions with loss. The time and place you feel your anger and sadness may surprise or sneak up on you. Simple and routine tasks may bring on frustration leading to feelings of anger or sadness. You may experience anger or sadness outbursts or feel like you cannot stop feeling angry or sad.',
        },
      ],
    },
    {
      key: 'processing-grief',
      header: 'Processing Your Grief – Finding Acceptance and Hope',
      description:
        'No matter what stage in the grief process you are in, it is important to allow yourself the time and space to feel and process your grief. Here are a few tips to help you in each of the stages.',
      itemsHeader: 'Tell me more',
      items: [
        {
          key: 'shock-process',
          description:
            '<b>Shock</b><br />When you are experiencing shock, the best way to support yourself is to be kind to yourself and allow others to help you. Recognize that you are not the best equipped to complete some of your daily tasks and allow yourself to ask for and accept help.',
        },
        {
          key: 'denial-bargaining-process',
          description:
            '<b>Denial and Bargaining</b><br />It is important not to rush any of the stages of grief and loss, including this stage. Denying or bargaining that this loss will not continue to be true is a normal and natural defense mechanism. While you should allow yourself these feelings, if you have a hard time not feeling this way, it is time for you to reach out to find a mental health provider to help you.',
        },
        {
          key: 'anger-sadness-process',
          description:
            '<b>Anger and Sadness</b><br />These are the most common feelings of grief and loss – and tend to be the feelings that stick around the longest. To find hope and acceptance, you need to express and release your anger and sadness. Some examples of healthy ways to release anger can be exercising, mindfulness or relaxation activities (you can find a couple examples in our app), or physical activities to release your anger such as kick boxing, axe throwing, or simply hitting a pillow. Releasing sadness typically occurs through crying or talking about the person that you lost. This is also the most difficult stage to know when you need help processing. If you have little interest in typically pleasurable activities, or are having difficulty getting out of bed, then it is time for you to reach out to find a mental health provider to support you.',
        },
      ],
    },
  ],
};
