import { gratitudeLetterEn } from './GratitudeLetter/en';
import { gratitudeLetterEs } from './GratitudeLetter/es';
import { smallTalkEn } from './SmallTalk/en';
import { smallTalkEs } from './SmallTalk/es';
import { socialSupportEn } from './SocialSupport/en';
import { socialSupportEs } from './SocialSupport/es';
import { threeGoodThingsEn } from './ThreeGoodThings/en';
import { threeGoodThingsEs } from './ThreeGoodThings/es';
import { mindfulEatingEn } from './MindfulEating/en';
import { mindfulEatingEs } from './MindfulEating/es';
import { stressReductionEn } from './StressReduction/en';
import { stressReductionEs } from './StressReduction/es';
import { threeCaringConnectionsEn } from './ThreeCaringConnections/en';
import { threeCaringConnectionsEs } from './ThreeCaringConnections/es';
import { celebrateProgressEn } from './CelebrateProgress/en';
import { celebrateProgressEs } from './CelebrateProgress/es';
import { habitTrackerEn } from './HabitTracker/en';
import { habitTrackerEs } from './HabitTracker/es';
import { increaseMovementEn } from './IncreaseMovement/en';
import { increaseMovementEs } from './IncreaseMovement/es';
import { calmYourMindEn } from './CalmYourMind/en';
import { calmYourMindEs } from './CalmYourMind/es';
import { bodyAwarenessEn } from './BodyAwareness/en';
import { bodyAwarenessEs } from './BodyAwareness/es';
import { reframeJudgementsEn } from './ReframeJudgements/en';
import { reframeJudgementsEs } from './ReframeJudgements/es';
import { pregnancyEn } from './Pregnancy/en';
import { pregnancyEs } from './Pregnancy/es';
import { controlEs } from './Control/es';
import { controlEn } from './Control/en';
import { affirmationsEn } from './Affirmations/en';
import { affirmationsEs } from './Affirmations/es';
import { balancedSensesEn } from './BalancedSenses/en';
import { balancedSensesEs } from './BalancedSenses/es';
import { bullyingEn } from './Bullying/en';
import { bullyingEs } from './Bullying/es';
import { friendshipEn } from './Friendship/en';
import { friendshipEs } from './Friendship/es';
import { mindfulnessBalanceEn } from './MindfulnessBalance/en';
import { mindfulnessBalanceEs } from './MindfulnessBalance/es';
import { lgbtqiaSocialMediaEn } from './LGBTQIASocialMedia/en';
import { lgbtqiaSocialMediaEs } from './LGBTQIASocialMedia/es';
import { reframeJudgementsYouthEn } from './ReframeJudgementsYouth/en';
import { reframeJudgementsYouthEs } from './ReframeJudgementsYouth/es';
import { thingsILikeEn } from './ThingsILikeAboutMe/en';
import { thingsILikeEs } from './ThingsILikeAboutMe/es';
import { visionBoardEn } from './VisionBoard/en';
import { visionBoardEs } from './VisionBoard/es';
import { memoryLossEn } from './MemoryLoss/en';
import { memoryLossEs } from './MemoryLoss/es';
import { administeringMedicationsEn } from './AdministeringMedication/en';
import { administeringMedicationsEs } from './AdministeringMedication/es';
import { bathingToiletingEn } from './BathingToileting/en';
import { bathingToiletingEs } from './BathingToileting/es';
import { buildingSupportSystemEn } from './BuildingSupportSystem/en';
import { buildingSupportSystemEs } from './BuildingSupportSystem/es';
import { buildingConfidenceEn } from './BuildingConfidence/en';
import { buildingConfidenceEs } from './BuildingConfidence/es';
import { caregiverBurnoutEn } from './CaregiverBurnout/en';
import { caregiverBurnoutEs } from './CaregiverBurnout/es';
import { completingDailyTasksEn } from './CompletingDailyTasks/en';
import { completingDailyTasksEs } from './CompletingDailyTasks/es';
import { caringForYourselfEn } from './CaringForYourself/en';
import { caringForYourselfEs } from './CaringForYourself/es';
import { decidingHealthcareOptionsEn } from './DecidingHealthcareOptions/en';
import { decidingHealthcareOptionsEs } from './DecidingHealthcareOptions/es';
import { buildingConfidenceGuardianEn } from './BuildingConfidenceGuardian/en';
import { buildingConfidenceGuardianEs } from './BuildingConfidenceGuardian/es';
import { buildingSupportSystemGuardianEn } from './BuildingSupportSystemGuardian/en';
import { buildingSupportSystemGuardianEs } from './BuildingSupportSystemGuardian/es';
import { caregiverBurnoutGuardianEn } from './CaregiverBurnoutGuardian/en';
import { caregiverBurnoutGuardianEs } from './CaregiverBurnoutGuardian/es';
import { caringForYourselfGuardianEn } from './CaringForYourselfGuardian/en';
import { caringForYourselfGuardianEs } from './CaringForYourselfGuardian/es';
import { griefAndLossTransitionsEn } from './ExperiencingGriefAndLossTransitions/en';
import { griefAndLossTransitionsEs } from './ExperiencingGriefAndLossTransitions/es';
import { griefAndLossEn } from './ExperiencingGriefAndLoss/en';
import { griefAndLossEs } from './ExperiencingGriefAndLoss/es';
import { fosterParentTipsEn } from './FosterParentTips/en';
import { fosterParentTipsEs } from './FosterParentTips/es';
import { knowingWhenYouNeedHelpGuardianEn } from './KnowingWhenYouNeedHelpGuardian/en';
import { knowingWhenYouNeedHelpGuardianEs } from './KnowingWhenYouNeedHelpGuardian/es';
import { learningEmpathyEn } from './LearningEmpathy/en';
import { learningEmpathyEs } from './LearningEmpathy/es';
import { childIsActingOutEn } from './ChildActingOut/en';
import { childIsActingOutEs } from './ChildActingOut/es';
import { relaxationActivitiesGuardianEn } from './RelaxationActivitiesGuardian/en';
import { relaxationActivitiesGuardianEs } from './RelaxationActivitiesGuardian/es';
import { rephrasingThoughtsGuardianEn } from './RephrasingThoughtsGuardian/en';
import { rephrasingThoughtsGuardianEs } from './RephrasingThoughtsGuardian/es';
import { supportingChildWithTraumaEn } from './SupportingChildWithTrauma/en';
import { supportingChildWithTraumaEs } from './SupportingChildWithTrauma/es';
import { knowingWhenYouNeedHelpEn } from './KnowingWhenYouNeedHelp/en';
import { knowingWhenYouNeedHelpEs } from './KnowingWhenYouNeedHelp/es';
import { senseOfSelfEn } from './MaintainingSenseSelf/en';
import { senseOfSelfEs } from './MaintainingSenseSelf/es';
import { navigatingHealthcareEn } from './NavigatingHealthcare/en';
import { navigatingHealthcareEs } from './NavigatingHealthcare/es';
import { relaxationActivitiesEn } from './RelaxationActivities/en';
import { relaxationActivitiesEs } from './RelaxationActivities/es';
import { rephrasingThoughtsEn } from './RephrasingThoughts/en';
import { rephrasingThoughtsEs } from './RephrasingThoughts/es';
import { supportingIndividualsMentalHealthEn } from './SupportingIndividualsMentalHealth/en';
import { supportingIndividualsMentalHealthEs } from './SupportingIndividualsMentalHealth/es';
import { understandingHowToCareEn } from './UnderstandingHowToCare/en';
import { understandingHowToCareEs } from './UnderstandingHowToCare/es';

export const staticContentConfig = {
  THREEGOODTHINGS: {
    es: threeGoodThingsEs,
    en: threeGoodThingsEn,
  },
  SMALLTALK: {
    es: smallTalkEs,
    en: smallTalkEn,
  },
  MINDFULEATING: {
    en: mindfulEatingEn,
    es: mindfulEatingEs,
  },
  SOCIALSUPPORT: {
    es: socialSupportEs,
    en: socialSupportEn,
  },
  GRATITUDELETTER: {
    es: gratitudeLetterEs,
    en: gratitudeLetterEn,
  },
  STRESSREDUCTION: {
    es: stressReductionEs,
    en: stressReductionEn,
  },
  THREECARINGCONNECTIONS: {
    es: threeCaringConnectionsEs,
    en: threeCaringConnectionsEn,
  },
  CELEBRATEPROGRESS: {
    es: celebrateProgressEs,
    en: celebrateProgressEn,
  },
  HABITTRACKER: {
    es: habitTrackerEs,
    en: habitTrackerEn,
  },
  INCREASEMOVEMENT: {
    es: increaseMovementEs,
    en: increaseMovementEn,
  },
  CALMYOURMIND: {
    es: calmYourMindEs,
    en: calmYourMindEn,
  },
  BODYAWARENESS: {
    es: bodyAwarenessEs,
    en: bodyAwarenessEn,
  },
  REFRAMEJUDGEMENTS: {
    es: reframeJudgementsEs,
    en: reframeJudgementsEn,
  },
  PREGNANCY: {
    es: pregnancyEs,
    en: pregnancyEn,
  },
  STRESSMANAGEMENT: {
    es: controlEs,
    en: controlEn,
  },
  VISIONBOARD: {
    es: visionBoardEs,
    en: visionBoardEn,
  },
  REFRAMEJUDGEMENTSYOUTH: {
    es: reframeJudgementsYouthEs,
    en: reframeJudgementsYouthEn,
  },
  BULLYING: {
    es: bullyingEs,
    en: bullyingEn,
  },
  LGBTQIASOCIALMEDIA: {
    es: lgbtqiaSocialMediaEs,
    en: lgbtqiaSocialMediaEn,
  },
  THINGSILIKEABOUTME: {
    es: thingsILikeEs,
    en: thingsILikeEn,
  },
  AFFIRMATIONS: {
    es: affirmationsEs,
    en: affirmationsEn,
  },
  FRIENDSHIP: {
    es: friendshipEs,
    en: friendshipEn,
  },
  MINDFULNESSBALANCE: {
    es: mindfulnessBalanceEs,
    en: mindfulnessBalanceEn,
  },
  BALANCEDSENSES: {
    es: balancedSensesEs,
    en: balancedSensesEn,
  },
  MEMORYLOSS: {
    en: memoryLossEn,
    es: memoryLossEs,
  },
  ADMINISTERINGMEDICATIONS: {
    en: administeringMedicationsEn,
    es: administeringMedicationsEs,
  },
  BATHINGTOILETING: {
    en: bathingToiletingEn,
    es: bathingToiletingEs,
  },
  BUILDINGSUPPORTSYSTEM: {
    en: buildingSupportSystemEn,
    es: buildingSupportSystemEs,
  },
  BUILDINGCONFIDENCE: {
    en: buildingConfidenceEn,
    es: buildingConfidenceEs,
  },
  CAREGIVERBURNOUT: {
    en: caregiverBurnoutEn,
    es: caregiverBurnoutEs,
  },
  COMPLETINGDAILYTASKS: {
    en: completingDailyTasksEn,
    es: completingDailyTasksEs,
  },
  CARINGFORYOURSELF: {
    en: caringForYourselfEn,
    es: caringForYourselfEs,
  },
  DECIDINGHEALTHCAREOPTIONS: {
    en: decidingHealthcareOptionsEn,
    es: decidingHealthcareOptionsEs,
  },
  EXPERIENCINGGRIEFANDLOSS: {
    en: griefAndLossEn,
    es: griefAndLossEs,
  },
  KNOWINGWHENYOUNEEDHELP: {
    en: knowingWhenYouNeedHelpEn,
    es: knowingWhenYouNeedHelpEs,
  },
  SENSEOFSELF: {
    en: senseOfSelfEn,
    es: senseOfSelfEs,
  },
  NAVIGATINGHEALTHCARE: {
    en: navigatingHealthcareEn,
    es: navigatingHealthcareEs,
  },
  RELAXATIONACTIVITIES: {
    en: relaxationActivitiesEn,
    es: relaxationActivitiesEs,
  },
  REPHRASINGTHOUGHTS: {
    en: rephrasingThoughtsEn,
    es: rephrasingThoughtsEs,
  },
  SUPPORTINGINDIVIDUALSMENTALHEALTH: {
    en: supportingIndividualsMentalHealthEn,
    es: supportingIndividualsMentalHealthEs,
  },
  UNDERSTANDINGCOGNITIVEIMPAIRMENT: {
    en: understandingHowToCareEn,
    es: understandingHowToCareEs,
  },
  BUILDINGSUPPORTSYSTEMGUARDIAN: {
    en: buildingSupportSystemGuardianEn,
    es: buildingSupportSystemGuardianEs,
  },
  BUILDINGCONFIDENCEGUARDIAN: {
    en: buildingConfidenceGuardianEn,
    es: buildingConfidenceGuardianEs,
  },
  CAREGIVERBURNOUTGUARDIAN: {
    en: caregiverBurnoutGuardianEn,
    es: caregiverBurnoutGuardianEs,
  },
  CARINGFORYOURSELFGUARDIAN: {
    en: caringForYourselfGuardianEn,
    es: caringForYourselfGuardianEs,
  },
  GRIEFANDLOSSTRANSITION: {
    en: griefAndLossTransitionsEn,
    es: griefAndLossTransitionsEs,
  },
  FOSTERPARENTINGTIPS: {
    en: fosterParentTipsEn,
    es: fosterParentTipsEs,
  },
  KNOWINGWHENYOUNEEDHELPGUARDIAN: {
    en: knowingWhenYouNeedHelpGuardianEn,
    es: knowingWhenYouNeedHelpGuardianEs,
  },
  LEARNINGEMPATHY: {
    en: learningEmpathyEn,
    es: learningEmpathyEs,
  },
  CHILDISACTINGOUT: {
    en: childIsActingOutEn,
    es: childIsActingOutEs,
  },
  RELAXATIONACTIVITIESGUARDIAN: {
    en: relaxationActivitiesGuardianEn,
    es: relaxationActivitiesGuardianEs,
  },
  REPHRASINGTHOUGHTSGUARDIAN: {
    en: rephrasingThoughtsGuardianEn,
    es: rephrasingThoughtsGuardianEs,
  },
  SUPPORTINGMYCHILDWITHTRAUMA: {
    en: supportingChildWithTraumaEn,
    es: supportingChildWithTraumaEs,
  },
};

export const logKey = {
  THREEGOODTHINGS: 'ThreeGoodThings',
  SMALLTALK: 'SmallTalk',
  MINDFULEATING: 'MindfulEating',
  SOCIALSUPPORT: 'SocialSupport',
  GRATITUDELETTER: 'GratitudeLetter',
  STRESSREDUCTION: 'StressReduction',
  THREECARINGCONNECTIONS: 'ThreeCaringConnections',
  CELEBRATEPROGRESS: 'CelebrateProgress',
  HABITTRACKER: 'HabitTracker',
  INCREASEMOVEMENT: 'IncreaseMovement',
  CALMYOURMIND: 'CalmYourMind',
  BODYAWARENESS: 'BodyAwareness',
  REFRAMEJUDGEMENTS: 'ReframeJudgements',
  PREGNANCY: 'Pregnancy',
  STRESSMANAGEMENT: 'StressManagement',
  MEMORYLOSS: 'MemoryLoss',
  ADMINISTERINGMEDICATIONS: 'AdministeringMedications',
  BATHINGTOILETING: 'BathingToileting',
  BUILDINGSUPPORTSYSTEM: 'BuildingSupportSystemCaregiver',
  BUILDINGCONFIDENCE: 'BuildingConfidenceCaregiver',
  CAREGIVERBURNOUT: 'CaregiverBurnoutCaregiver',
  COMPLETINGDAILYTASKS: 'CompletingDailyTasks',
  CARINGFORYOURSELF: 'CaringForYourselfCaregiver',
  DECIDINGHEALTHCAREOPTIONS: 'DecidingHealthcareOptions',
  EXPERIENCINGGRIEFANDLOSS: 'GriefAndLossCaregiver',
  KNOWINGWHENYOUNEEDHELP: 'KnowingWhenYouNeedHelpCaregiver',
  SENSEOFSELF: 'MaintainingSenseOfSelf',
  NAVIGATINGHEALTHCARE: 'NavigatingChallengingHealthcare',
  RELAXATIONACTIVITIES: 'RelaxationActivitiesCaregiver',
  REPHRASINGTHOUGHTS: 'RephrasingThoughtsCaregiver',
  SUPPORTINGINDIVIDUALSMENTALHEALTH: 'SupportingIndividualsWithMentalHealthNeeds',
  UNDERSTANDINGCOGNITIVEIMPAIRMENT: 'UnderstandingHowToCareForSomeoneWithCognitiveImpairments',
  BUILDINGSUPPORTSYSTEMGUARDIAN: 'BuildingSupportSystemGuardian',
  BUILDINGCONFIDENCEGUARDIAN: 'BuildingConfidenceGuardian',
  CAREGIVERBURNOUTGUARDIAN: 'CaregiverBurnoutGuardian',
  CARINGFORYOURSELFGUARDIAN: 'CaringForYourselfGuardian',
  GRIEFANDLOSSTRANSITION: 'GriefAndLossGuardian',
  FOSTERPARENTINGTIPS: 'FosterParentingTips',
  KNOWINGWHENYOUNEEDHELPGUARDIAN: 'KnowingWhenYouNeedHelpGuardian',
  LEARNINGEMPATHY: 'LearningEmpathy',
  CHILDISACTINGOUT: 'ChildIsActingOut',
  RELAXATIONACTIVITIESGUARDIAN: 'RelaxationActivitiesGuardian',
  REPHRASINGTHOUGHTSGUARDIAN: 'RephrasingThoughtsGuardian',
  SUPPORTINGMYCHILDWITHTRAUMA: 'SupportingMyChildWithTrauma',
};
