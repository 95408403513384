import React, { useEffect, useContext } from 'react';
import * as paths from './RoutesConfig';
import { useIntl } from 'react-intl';
import { useLocation, Route, Redirect } from 'react-router-dom';
import SecureRoute from './SecureRoute';
import GreetingCards from 'Core_Pages/GreetingCards/GreetingCards';
import * as permissions from 'Core_Helpers/authentication/PermissionsConfig';
import getSvgImageConfig from 'Core_Helpers/getSvgImageConfig';
import ColoringBookImagePreview from 'Core_Pages/GreetingCards/ColoringBookImagePreview/ColoringBookImagePreview';
import { selectIsAuthenticated, checkAuthRoute, logout } from 'Core_Redux/authentication';
import { selectUserInfo, selectSyncUser } from 'Core_Redux/user';
import { browserHistory } from 'Core_Helpers/AppInsights';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { isNative } from 'Core_Helpers/isNative';
import { SplashScreen } from '@capacitor/splash-screen';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import identityApi from 'Core_Redux/services/identity';

import {
  Andy,
  ColoringBook,
  Deleted,
  DeleteDisclaimer,
  Home,
  Menu,
  NotificationSettings,
  Pets,
  Profile,
  PyxirChat,
  Reminders,
  Snake,
  StaticContent,
  SudokuGame,
  Thrive,
  UnsignedAgreements,
  MyTools,
  TalkToSomeone,
  Checkin,
  Screening,
  MyVideoTools,
} from 'Core_Pages';
import { ExperienceContext } from 'Contexts/ExperienceContext';

const Routes = () => {
  const intl = useIntl();
  const { state } = useLocation();
  const dispatch = useCoreDispatch();
  const isAuthenticated = useCoreSelector(selectIsAuthenticated);
  const experience = useContext(ExperienceContext);

  const userInfo = useCoreSelector(selectUserInfo);
  const syncUser = useCoreSelector(selectSyncUser);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(identityApi.endpoints.userInfo.initiate());
    } else {
      dispatch(logout());
      experience.experienceUpdatedAsync();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(checkAuthRoute({ history: browserHistory, experienceUpdated: experience.experienceUpdatedAsync }));
  }, [userInfo, syncUser]);

  useEffect(() => {
    if (isNative()) {
      SplashScreen.hide();
    }
  }, []);

  return (
    <IonPage>
      <IonRouterOutlet>
        <SecureRoute exact path={paths.HOME} title={intl.formatMessage({ id: 'title.home' })} hasNav>
          <Home />
        </SecureRoute>

        <SecureRoute exact path={paths.UNSIGNEDAGREEMENTS} title={intl.formatMessage({ id: 'title.agreements' })}>
          <UnsignedAgreements />
        </SecureRoute>

        <SecureRoute path={paths.MENU} title={intl.formatMessage({ id: 'title.menu' })} hasNav>
          <Menu />
        </SecureRoute>

        <SecureRoute path={paths.ANDY} title={intl.formatMessage({ id: 'title.andy-chat' })} hasNav>
          <Andy />
        </SecureRoute>

        <SecureRoute path={paths.CHAT} title={intl.formatMessage({ id: 'title.pyxir-chat' })}>
          <PyxirChat />
        </SecureRoute>

        <SecureRoute path={paths.COLORINGBOOK} hasNav title={intl.formatMessage({ id: 'title.coloring-book' })}>
          <ColoringBook />
        </SecureRoute>

        <SecureRoute path={paths.PROFILE} hasNav title={intl.formatMessage({ id: 'title.profile' })}>
          <Profile />
        </SecureRoute>

        <SecureRoute path={paths.NOTIFICATIONS} hasNav title={intl.formatMessage({ id: 'menu.notification-settings' })}>
          <NotificationSettings />
        </SecureRoute>

        <SecureRoute path={paths.SNAKE} hasNav title={intl.formatMessage({ id: 'title.snake-game' })}>
          <Snake />
        </SecureRoute>

        <SecureRoute path={paths.SUDOKU} hasNav title={intl.formatMessage({ id: 'title.sudoku-game' })}>
          <SudokuGame />
        </SecureRoute>

        <SecureRoute
          path={paths.GREETINGCARDS}
          permissions={permissions.CARDS}
          hasNav
          title={intl.formatMessage({ id: 'title.greeting-cards' })}
        >
          <GreetingCards />
        </SecureRoute>

        <SecureRoute path={paths.PETS} hasNav title={intl.formatMessage({ id: 'title.pyx-pets' })}>
          <Pets />
        </SecureRoute>

        <SecureRoute path={paths.TALKTOSOMEONE} title={intl.formatMessage({ id: 'title.talk-to-someone' })}>
          <TalkToSomeone />
        </SecureRoute>

        <SecureRoute
          path={paths.THRIVEPATHWAY}
          permissions={permissions.THRIVEPATHWAY}
          title={intl.formatMessage({ id: 'title.thrive-pathway' })}
        >
          <Thrive />
        </SecureRoute>

        <SecureRoute path={paths.STATICCONTENT} hasNav title={intl.formatMessage({ id: 'title.static-content' })}>
          <StaticContent />
        </SecureRoute>

        <SecureRoute
          path={paths.COLORINGBOOKIMAGEPREVIEW}
          permissions={permissions.CARDS}
          hasNav
          title={intl.formatMessage({ id: 'title.coloring-book' })}
        >
          <ColoringBookImagePreview image={getSvgImageConfig(state?.imageKey, state?.colors, () => {})} />
        </SecureRoute>

        <SecureRoute path={paths.MYTOOLS} hasNav title={intl.formatMessage({ id: 'title.my-tools' })}>
          <MyTools />
        </SecureRoute>

        <SecureRoute path={paths.REMINDERS} hasNav title={intl.formatMessage({ id: 'title.reminders' })}>
          <Reminders />
        </SecureRoute>

        <SecureRoute path={paths.CHECKINS} hasNav title={intl.formatMessage({ id: 'title.check-ins' })}>
          <Checkin />
        </SecureRoute>

        <SecureRoute path={paths.ASSESSMENT} hasNav title={intl.formatMessage({ id: 'title.screenings' })}>
          <Screening />
        </SecureRoute>

        <SecureRoute path={paths.VIDEOS} hasNav title={intl.formatMessage({ id: 'home.tools.videos' })}>
          <MyVideoTools />
        </SecureRoute>

        <Route path={paths.ACCOUNTDELETEDISCLAIMER}>
          <DeleteDisclaimer />
        </Route>

        <Route path={paths.ACCOUNTDELETED}>
          <Deleted />
        </Route>

        <SecureRoute>
          <Redirect to={paths.HOME} push />
        </SecureRoute>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Routes;
