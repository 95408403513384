import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { RRule } from 'rrule';
import { useIntl } from 'react-intl';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { selectLanguage } from 'Core_Redux/user';
import {
  draftReminderDeactivation,
  editReminder,
  selectIsDeletePending,
  selectIsEditPending,
  selectSelectedReminder,
} from 'Core_Redux/reminders';
import ActionButton from './ActionButton/ActionButton';
import ActiveToggle from './ActiveToggle/ActiveToggle';
import DateRange from './DateRange/DateRange';
import ActionMenu from './ActionMenu/ActionMenu';
import ReminderStatusIndicator from './ReminderStatusIndicator/ReminderStatusIndicator';
import RecurrenceConfiguration from '../ReminderConfigurationForm/RecurrenceConfiguration/RecurrenceConfiguration';
import { mapCodeToLabel } from '../ReminderConfigurationForm/ReminderTypeConfig/ActivitiesConfig/activityCategories';
import {
  StyledCardTitleContainer,
  StyledChipContainer,
  StyledChip,
  StyledCircularProgress,
  StyledReminderCard,
} from './styles';
import { ACTIVITIES, MEDICATION, SUPPLEMENT } from '../ReminderConfigurationForm/ReminderTypeConfig/reminderTypes';
import { SET_SUCCESS } from '../Modals/modals';
import CardTitle from './TruncatedTitles/CardTitle';
import CardSubtitle from './TruncatedTitles/CardSubtitle';

function ReminderCard({ data }) {
  const intl = useIntl();
  const language = useCoreSelector(selectLanguage);
  const dispatch = useCoreDispatch();
  const isEditPending = useCoreSelector(selectIsEditPending);
  const isDeletePending = useCoreSelector(selectIsDeletePending);
  const selectedReminder = useCoreSelector(selectSelectedReminder);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [frequency, setFrequency] = useState('');
  const [occurrencesTag, setOccurrencesTag] = useState('');
  const [activityCategories, setActivityCategories] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const actionMenuButtonRef = useRef();

  function getOccurrencesCount() {
    let count = 0;
    if (data.reminderTime1) count++;
    if (data.reminderTime2) count++;
    if (data.reminderTime3) count++;
    if (data.reminderTime4) count++;
    if (data.reminderTime5) count++;
    if (data.reminderTime6) count++;
    return count;
  }

  const toggleMenu = () => setShowMenu((prev) => !prev);
  const hideMenu = () => setShowMenu(false);

  useEffect(() => {
    const rRule = RRule.fromString(data.recurrencePattern);

    setFrequency(RecurrenceConfiguration.FrequencyIndex[language][rRule.options.freq]);
    const occurrencesCount = getOccurrencesCount();
    const occurrencesDoseTag = `${occurrencesCount} ${
      occurrencesCount > 1
        ? intl.formatMessage({ id: 'reminders.doses' })
        : intl.formatMessage({ id: 'reminders.dose' })
    }`;

    switch (data.reminderType) {
      case MEDICATION:
        setTitle(data.medicationName);
        setSubtitle(data.medicationDose);
        setOccurrencesTag(occurrencesDoseTag);
        break;
      case SUPPLEMENT:
        setTitle(data.supplementName);
        setSubtitle(data.supplementDose);
        setOccurrencesTag(occurrencesDoseTag);
        break;
      case ACTIVITIES:
        setTitle(data.activityName);
        setOccurrencesTag(
          `${occurrencesCount} ${
            occurrencesCount > 1
              ? intl.formatMessage({ id: 'reminders.sessions' })
              : intl.formatMessage({ id: 'reminders.session' })
          }`,
        );
        setActivityCategories(data.activityCategories.map(mapCodeToLabel));
        break;
      default:
        return;
    }
  }, [data]);

  const handleReminderSwitch = () => {
    if (isEditPending) {
      return;
    } else if (data.isActive) {
      dispatch(draftReminderDeactivation(data.guid));
    } else {
      dispatch(editReminder({ updatedReminder: { ...data, isActive: true }, successModal: SET_SUCCESS }));
    }
  };

  const isCardLoading = (isEditPending || isDeletePending) && selectedReminder.guid === data.guid;

  return (
    <StyledReminderCard>
      {isCardLoading ? (
        <StyledCircularProgress color={'primary'} />
      ) : (
        <>
          <ReminderStatusIndicator isActive={data.isActive} />
          <ActionButton ref={actionMenuButtonRef} onClick={toggleMenu} />
          {showMenu && <ActionMenu guid={data.guid} onClose={hideMenu} buttonRef={actionMenuButtonRef} />}
          <StyledCardTitleContainer>
            <CardTitle>{title}</CardTitle>
            {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
          </StyledCardTitleContainer>
          <StyledChipContainer>
            <StyledChip>{frequency}</StyledChip>
            <StyledChip>{occurrencesTag}</StyledChip>
          </StyledChipContainer>
          {activityCategories?.length > 0 && (
            <StyledChipContainer>
              {activityCategories.map((ac) => (
                <StyledChip key={ac}>{intl.formatMessage({ id: ac })}</StyledChip>
              ))}
            </StyledChipContainer>
          )}
          <ActiveToggle
            id={`remindersToggle${data.guid}`}
            isActive={data.isActive}
            handleReminderSwitch={handleReminderSwitch}
          />
          <DateRange startDate={data.startDateUtc} endDate={data.endDateUtc} />
        </>
      )}
    </StyledReminderCard>
  );
}

ReminderCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReminderCard;
