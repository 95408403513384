import { sudoku, snakeGame, visionBoard, reframeNegativeThoughts } from 'Assets/img/my-tools-icons';
import urlcat from 'urlcat';
import { itemTypes } from '../components/ToolCard/toolCardConfig';
import { userMetaTagCategories } from 'Core_Pages/MyTools/userMetaHelpers';
import { SNAKE, SUDOKU, STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';

export const brainBoosterItems = [
  {
    icon: sudoku,
    labelId: 'games.sudoku.title',
    type: itemTypes.PLAY,
    to: SUDOKU,
    loggedEvent: 'Sudoku_Clicked',
  },
  {
    icon: snakeGame,
    labelId: 'games.snake.title',
    type: itemTypes.PLAY,
    to: SNAKE,
    loggedEvent: 'Snake_Clicked',
  },
  {
    icon: visionBoard,
    labelId: 'my-tools.visionboard',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'visionboard' }),
    loggedEvent: 'Vision_Board_Content_Clicked',
    contentCategory: userMetaTagCategories.YOUTH,
  },
  {
    icon: reframeNegativeThoughts,
    labelId: 'my-tools.reframejudgementsyouth',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'reframejudgementsyouth' }),
    loggedEvent: 'Reframe_Negative_Thoughts_Content_Clicked',
    contentCategory: userMetaTagCategories.YOUTH,
  },
];
