// backgrounds is an array of backgrounds
export const splitBackgrounds = (
  backgrounds,
  topBoundsPercentageWidth = 0.0,
  bottomBoundsPercentageWidth = 0.0,
  leftBoundsPercentageWidth = 0.0,
  rightBoundsPercentageWidth = 0.0,
  backgroundSplits = 1,
  gapBackgroundPercentageWidth = 0,
) => {
  let backgroundBoundingBoxes = [];
  for (let i = 0; i < backgrounds.length; i++) {
    const backgroundIndexBounds = splitBackgroundsIndexes(
      topBoundsPercentageWidth,
      bottomBoundsPercentageWidth,
      leftBoundsPercentageWidth,
      rightBoundsPercentageWidth,
      backgroundSplits,
      gapBackgroundPercentageWidth,
    );
    backgroundBoundingBoxes.push({
      key: backgrounds[i].NAME ? backgrounds[i].NAME : backgrounds[i].key,
      data: backgrounds[i],
      boundingBoxes: backgroundIndexBounds,
    });
  }
  return backgroundBoundingBoxes;
};

export const splitBackgroundsIndexes = (
  topBoundsPercentageWidth = 0.0,
  bottomBoundsPercentageWidth = 0.0,
  leftBoundsPercentageWidth = 0.0,
  rightBoundsPercentageWidth = 0.0,
  backgroundSplits = 1,
  gapBackgroundPercentageWidth = 0,
) => {
  let backgroundIndexBoundingBoxes = [];
  for (let i = 0; i < backgroundSplits; i++) {
    const heightBounds = splitBackgroundHeightPercentage(topBoundsPercentageWidth, bottomBoundsPercentageWidth);
    const widthBounds = splitBackgroundWidthPercentage(
      leftBoundsPercentageWidth,
      rightBoundsPercentageWidth,
      i,
      backgroundSplits,
      gapBackgroundPercentageWidth,
    );
    backgroundIndexBoundingBoxes.push({
      index: i,
      page: {
        width: widthBounds.page.width,
        height: heightBounds.page.height,
      },
      min: {
        start: {
          x: widthBounds.min.start,
          y: heightBounds.min.start,
        },
        end: {
          x: widthBounds.min.start,
          y: heightBounds.min.end,
        },
        center: {
          x: widthBounds.min.center,
          y: heightBounds.min.center,
        },
      },
      max: {
        start: {
          x: widthBounds.max.start,
          y: heightBounds.max.start,
        },
        end: {
          x: widthBounds.max.end,
          y: heightBounds.max.end,
        },
        center: {
          x: widthBounds.max.center,
          y: heightBounds.max.center,
        },
      },
    });
  }
  return backgroundIndexBoundingBoxes;
};

// TODO: update zoom formula with one that favors maximum zoom out to show minimum bounding box
// and if the width exceeds the maximum zoom x, zoom out to the maximum zoom without going beyond the maximum X
export const getZoomScale = (_this, background, backgroundBoundingBoxes, isCombined, index = 0) => {
  const currentUsedBackground = isCombined ? _this.combinedBackground : background.data;
  let currentRatioH;
  let currentRatioW;

  const currentBackgroundData = getBackgroundBoundingBox(backgroundBoundingBoxes, background.NAME);
  const currentBackground = currentBackgroundData.boundingBoxes[index];

  if (!currentUsedBackground.isScaled) {
    currentRatioH = _this.game.renderer.height / currentUsedBackground.height;
    currentRatioW = _this.game.renderer.width / currentUsedBackground.width;
  } else {
    currentRatioH = _this.game.renderer.height / currentUsedBackground.displayHeight;
    currentRatioW = _this.game.renderer.width / currentUsedBackground.displayWidth;
  }

  const minZoomY = currentRatioH / currentBackground.page.height;
  const maxZoomY = currentRatioH / (currentBackground.max.end.y - currentBackground.max.start.y);

  const maxZoomX = currentRatioW / (currentBackground.max.end.x - currentBackground.max.start.x);

  let currentZoomY;
  let useMax = false;

  if (minZoomY > 1.0) {
    currentZoomY = maxZoomY;
    useMax = true;
  } else {
    currentZoomY = minZoomY;
  }

  if (currentZoomY < maxZoomX) {
    currentZoomY = maxZoomX;
  }

  return { isMax: useMax, zoom: currentZoomY };
};

export const getBackgroundCenter = (backgroundBoundingBoxes, index = 0, useMax = false, backgroundKey = undefined) => {
  const currentBackground = getBackgroundBoundingBox(backgroundBoundingBoxes, backgroundKey, index).boundingBoxes[
    index
  ];
  const currentBackgroundCenter = useMax ? currentBackground.min.center : currentBackground.max.center;
  return currentBackgroundCenter;
};

export const getBackgroundBoundingBox = (backgroundBoundingBoxes, backgroundKey = undefined) => {
  return backgroundKey ? backgroundBoundingBoxes.find((x) => x.key == backgroundKey) : backgroundBoundingBoxes[0];
};

export const getBackgroundPagePoint = (
  _this,
  background,
  backgroundBoundingBoxes,
  percentageX,
  percentageY,
  index = 0,
  usingCamera = false,
) => {
  const currentBackgroundData = getBackgroundBoundingBox(backgroundBoundingBoxes, background.NAME);
  let absoluteX;
  let absoluteY;
  const currentBackground = currentBackgroundData.boundingBoxes[index];
  const currentPagePercentageX = currentBackground.page.width * percentageX;
  const currentPagePercentageY = currentBackground.page.height * percentageY;

  const pagePercentageX =
    (_this.zoomScaleInitial.isMax && usingCamera ? currentBackground.max.start.x : currentBackground.min.start.x) +
    currentPagePercentageX;
  const pagePercentageY =
    (_this.zoomScaleInitial.isMax && usingCamera ? currentBackground.max.start.y : currentBackground.min.start.y) +
    currentPagePercentageY;
  if (!background.isScaled) {
    absoluteX = pagePercentageX * background.width;
    absoluteY = pagePercentageY * background.height;
  } else {
    absoluteX = pagePercentageX * background.displayWidth;
    absoluteY = pagePercentageY * background.displayHeight;
  }

  return {
    percentage: {
      x: pagePercentageX,
      y: pagePercentageY,
    },
    absolute: {
      x: absoluteX,
      y: absoluteY,
    },
  };
};

export const splitBackgroundHeightPercentage = (topBoundsPercentageWidth = 0.0, bottomBoundsPercentageWidth = 0.0) => {
  const pagePercentageHeight = 1.0 - topBoundsPercentageWidth - bottomBoundsPercentageWidth;

  const minHeightStart = topBoundsPercentageWidth;
  const minHeightEnd = topBoundsPercentageWidth + pagePercentageHeight;
  const minHeightCenter = (minHeightStart + minHeightEnd) / 2;

  const maxHeightStart = 0.0;
  const maxHeightEnd = 1.0;
  const maxHeightCenter = (maxHeightStart + maxHeightEnd) / 2;

  return {
    page: {
      height: pagePercentageHeight,
    },
    min: {
      start: minHeightStart,
      end: minHeightEnd,
      center: minHeightCenter,
    },
    max: {
      start: maxHeightStart,
      end: maxHeightEnd,
      center: maxHeightCenter,
    },
  };
};

export const splitBackgroundWidthPercentage = (
  leftBoundsPercentageWidth = 0.0,
  rightBoundsPercentageWidth = 0.0,
  backgroundIndex = 0,
  backgroundSplits = 1,
  gapBackgroundPercentageWidth = 0,
) => {
  const totalAmountOfGaps = backgroundIndex + Math.ceil(((backgroundIndex + 1) % backgroundSplits) / backgroundSplits);
  const amountOfGapsToRight = totalAmountOfGaps - backgroundIndex;
  const amountOfGapsToLeft = totalAmountOfGaps - amountOfGapsToRight;
  const backgroundPagePercentageWidth =
    (1.0 -
      ((backgroundSplits - 1) * gapBackgroundPercentageWidth +
        leftBoundsPercentageWidth +
        rightBoundsPercentageWidth)) *
    (1 / backgroundSplits);

  const backgroundOffsetLeftWidth = leftBoundsPercentageWidth + amountOfGapsToLeft * gapBackgroundPercentageWidth;

  const totalAmountOfGapsInPreviousPage = backgroundIndex - 1 > 0 ? backgroundIndex - 1 : 0;

  const backgroundOffsetLeftWidthPreviousPage =
    leftBoundsPercentageWidth + totalAmountOfGapsInPreviousPage * gapBackgroundPercentageWidth;

  const totalPageWidthBeforeIndex = backgroundIndex * backgroundPagePercentageWidth;

  const backgroundMinWidthStart = backgroundOffsetLeftWidth + totalPageWidthBeforeIndex;
  const backgroundMinWidthEnd = backgroundMinWidthStart + backgroundPagePercentageWidth;
  const backgroundMinWidthCenter = (backgroundMinWidthStart + backgroundMinWidthEnd) / 2;

  const indexAtEnd = Math.ceil(((backgroundIndex + 1) % backgroundSplits) / backgroundSplits);

  const backgroundMaxWidthStart = backgroundOffsetLeftWidthPreviousPage + totalPageWidthBeforeIndex;
  const backgroundMaxWidthEnd =
    backgroundMinWidthEnd +
    amountOfGapsToRight * gapBackgroundPercentageWidth * indexAtEnd +
    (1 - indexAtEnd) * (1.0 - backgroundMinWidthEnd);
  const backgroundMaxWidthCenter = (backgroundMaxWidthStart + backgroundMaxWidthEnd) / 2;

  return {
    page: {
      width: backgroundPagePercentageWidth,
    },
    min: {
      start: backgroundMinWidthStart,
      end: backgroundMinWidthEnd,
      center: backgroundMinWidthCenter,
    },
    max: {
      start: backgroundMaxWidthStart,
      end: backgroundMaxWidthEnd,
      center: backgroundMaxWidthCenter,
    },
  };
};

export const phaserToCssCoordinates = (gameScene, originalX, originalY) => {
  let worldviewRendererOffsetX = gameScene.cameras.main.worldView.x;
  let worldviewRendererOffsetY = gameScene.cameras.main.worldView.y;
  let originalShiftX = originalX - worldviewRendererOffsetX;
  let originalShiftY = originalY - worldviewRendererOffsetY;

  let normalizedShiftedX = originalShiftX / gameScene.cameras.main.worldView.width;
  let normalizedShiftedY = originalShiftY / gameScene.cameras.main.worldView.height;
  let cssAbsoluteShiftedX = normalizedShiftedX * gameScene.game.renderer.width;
  let cssAbsoluteShiftedY = normalizedShiftedY * gameScene.game.renderer.height;

  return {
    x: cssAbsoluteShiftedX,
    y: cssAbsoluteShiftedY,
  };
};
