import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from 'Core_Components/Navbar/Navbar';
import { Redirect, Route } from 'react-router-dom';
import FullScreenLoading from 'Core_Components/FullScreenLoading/FullScreenLoading';
import { appInsights, isInsightsEnabled } from 'Core_Helpers/AppInsights';
import Header from 'Core_Components/Header/Header';
import { useIntl } from 'react-intl';
import hasPermissions from 'Core_Helpers/authentication/hasPermissions';
import AppInsightsAiTracking from './AppInsightsAiTracking';
import { HOME } from './RoutesConfig';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectUserInfo, selectSyncUser, selectSyncUserPending } from 'Core_Redux/user';

const SecureRoute = ({ path, children, hasNav, hasAppInsights, title, permissions, roles, exact }) => {
  const intl = useIntl();

  const [userTracked, setUserTracking] = useState(false);
  const profile = useCoreSelector(selectUserInfo);
  const syncUser = useCoreSelector(selectSyncUser);
  const syncUserPending = useCoreSelector(selectSyncUserPending);

  useEffect(() => {
    if (isInsightsEnabled && profile && syncUser && !userTracked) {
      setUserTracking(true);
      appInsights.addTelemetryInitializer(updateTelemetry);
    }
  }, [profile, syncUser]);

  const updateTelemetry = (envelope) => {
    const telemetryItem = envelope.baseData;
    telemetryItem.properties = telemetryItem.properties || {};
    if (profile.sub) {
      telemetryItem.properties['UserId'] = profile.sub;
    }
    if (syncUser?.activeOrganizationId) {
      telemetryItem.properties['OrganizationId'] = syncUser.activeOrganizationId;
    }
  };

  const returnPage = () => {
    let page = children;
    if (hasNav) page = <Navbar>{page}</Navbar>;
    if (hasAppInsights) page = <AppInsightsAiTracking>{page}</AppInsightsAiTracking>;
    return <>{page}</>;
  };

  if (!profile) {
    return null;
  }

  if (!hasPermissions(permissions, roles, profile)) {
    return <Redirect to={HOME} />;
  }

  if (!syncUser && syncUserPending) {
    return (
      <Route data-testid="Route" path={path} exact={exact}>
        <Header title={intl.formatMessage({ id: 'title.loading' })} />
        <FullScreenLoading showMessage={true} />
      </Route>
    );
  } else {
    return (
      <Route data-testid="Route" path={path} exact={exact}>
        <Header title={title} />
        {returnPage()}
      </Route>
    );
  }
};

SecureRoute.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  hasNav: PropTypes.bool,
  hasAppInsights: PropTypes.bool,
  title: PropTypes.string,
  syncUser: PropTypes.object,
  permissions: PropTypes.oneOfType([PropTypes.array, () => undefined]),
  roles: PropTypes.oneOfType([PropTypes.array, () => undefined]),
  exact: PropTypes.bool,
};
SecureRoute.defaultProps = {
  hasAppInsights: true,
  hasNav: false,
};

export default SecureRoute;
