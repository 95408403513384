import { visionBoard } from 'Assets/img/my-tools-icons';

export const visionBoardEn = {
  key: 'vision-board',
  title: 'Vision Boards and You',
  titleImage: visionBoard,
  titleImageAlt: '',
  contentId: 16,
  sections: [
    {
      key: 'vision-board-intro',
      hasNoSectionMargin: true,
      description:
        'Sometimes when words don’t quite say what you want in life, using pictures can do the trick – and even help to reach your goals! Making a vision board is a great way to bring your ideas and goals to life. Some people call them “dream” or “inspiration” boards, too. Whichever name you like, it is a unique work of art using pictures, words, or other things put together in a way that shows your desires.\n\nPictures give a lot of information to our brains in a single glance. So, seeing your goals and dreams on a vision board can remind you of all the good things you will gain from reaching your goals, and can help your subconscious mind to ‘find’ ways to reach them, too. Plus, they’re fun to do!\n\nThere is no right or wrong way to make a vision board. It’s personal to your unique self. They can be as simple or complex as you want. Before you begin collecting images, though, take a moment to think about what is important and matters to you. What goal do you want to accomplish? What dream would you like to experience?\n\nFor example, maybe that’s becoming a singer, or getting high grades in a certain subject. Maybe it’s to stand your ground with a bully and feel strong. Or maybe write a book or create your own wardrobe line. Whatever it is that’s calling to you.',
    },
    {
      key: 'vision-board-questions',
      description:
        'Next comes the juicy part! Go a step farther and think about how achieving your goal makes you FEEL inside and out. You could ask yourself…',
      bulletedItems: [
        { text: 'What emotions do you feel?' },
        { text: 'What will you gain from achieving this goal?' },
        { text: 'How will your life change for the better?' },
      ],
    },
    {
      key: 'vision-board-explanation',
      description:
        'Maybe that’s a sense of confidence, or being appreciated by people who matter to you. Or maybe it’s a feeling of inner peace, or just plain feeling good about yourself. Sit with whatever comes up for a while.\n\nThen, take some time to find pictures that represent your goal and bring up those feelings for you. The pictures you choose will help remind you about what’s most important to you, or even see other possibilities, and help inspire you towards your desire.',
    },
    {
      key: 'vision-board-collecting-images',
      header: 'Collecting images',
      description:
        "You can use pictures from magazines, newspapers, the Internet, flyers, brochures, labels, emojis, drawings, personal photos, etc. When you find an image that excites, energizes, or speaks to you, cut it out or print it and put to the side. You don’t have to find the exact picture of whatever your end goal is, and the images might be “stuff,” and that’s okay – think about the feeling the “stuff” brings up for you. And you might like the colors or patterns in an image rather than the subject matter itself. Color can be a great way to express feelings, personality, or energy.\n\nIt's also okay to use words that speak to you, too. Some ideas are “strong,” “brave,” “healthy,” “loved,” “happy,” “safe,” etc. Consider adding a picture of yourself in with the images, as well. Seeing yourself smiling while surrounded by your goals and dreams can be powerful! Go with what feels right. No matter what images you pick, it is important you feel a connection with them.\n\nYou might end up with more pictures than you need, and they might look scattered in themes. That’s okay! The creative process is happening!",
    },
    {
      key: 'vision-board-putting-it-together',
      header: 'Putting it together',
      description:
        'When you’re ready to make your vision board, you’ll need background material to put your images on such as poster paper, cardboard, corkboard, or even a wall. Any size or shape is okay.\n\nThen, arrange your pictures on the board in a way that feels right to you. You can overlap images, angle them, scatter them randomly, leave space between them, or line up evenly. Again, there’s no right or wrong way to create it. Take your time, enjoy the process, and go with what feels right without overthinking it. When you’ve finished laying out your images, then apply glue, gummy tack, or tape to hold them in place.',
    },
    {
      key: 'vision-board-result',
      header: 'Feeling the result',
      description:
        'When you’re done making your vision board, put it in a place where you can easily see it — desk, closet door, or even by the TV. Look at it at least once or twice a day to feel inspired and feed your subconscious mind. For example, you could look at it just before going to bed and first thing in the morning, then visualize your success as if it was happening right now…',
      bulletedItems: [
        { text: 'Close your eyes and visualize yourself living the life depicted by the images.' },
        { text: 'Feel yourself in the future you have designed.' },
        { text: 'Believe it is already yours.' },
        { text: 'Read your inspirational words aloud.' },
        { text: 'Be grateful for the good already present in your life.' },
        { text: 'Recognize and appreciate the changes you have seen and felt.' },
      ],
    },
    {
      key: 'vision-board-exit',
      description:
        'Another cool thing with vision boards you can try is to think of them as a ‘living’ thing. You can make updates, rip things off, add things, or even start over. They can change and transform just like you and show you your progress.\n\nNo matter what your goals and dreams are, a vision board is an awesome tool. Use it to imagine your desires, feel them coming true, and charge your inspiration towards them!',
    },
  ],
};
