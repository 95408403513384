import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { StyledGrid } from '../styles';
import { Button } from '@mui/material';
import GradientCard from 'Core_Components/GradientCard/GradientCard';
import { appInsights } from 'Core_Helpers/AppInsights';
import { selectUserInfo, selectUserMeta } from 'Core_Redux/user';
import { useCoreSelector } from 'Contexts/StoreContext';
import hasPermissions from 'Core_Helpers/authentication/hasPermissions';
import filterUserMetaByTag from 'Core_Pages/MyTools/userMetaHelpers';
import urlcat from 'urlcat';
import { ToolTypes } from 'Core_Pages/MyTools/enumerations/enums';
import { MYTOOLS } from 'Core_Pages/Routes/RoutesConfig';
import { guardian } from 'Assets/img/my-tools-icons';
import { guardianItems } from 'Core_Pages/MyTools/MyToolsPage/MyGuardianTools/config';

function MyGuardianTile() {
  const history = useHistory();
  const intl = useIntl();
  const profile = useCoreSelector(selectUserInfo);
  const userMeta = useCoreSelector(selectUserMeta);
  const isGuardianTile = userMeta?.caregiver?.isGuardian;

  function onToolClick() {
    appInsights.trackEvent({ name: 'MyTools_Guardian' });
    history.push(urlcat(MYTOOLS, { toolType: 'guardian' }));
  }

  const getLessonTotal = () => {
    return guardianItems.filter(
      (x) => hasPermissions(x.permissions, x.roles, profile) && filterUserMetaByTag(x.contentCategory, userMeta),
    )?.length;
  };

  return (
    <>
      {isGuardianTile && (
        <StyledGrid mobile={12} tablet={6}>
          <Button onClick={onToolClick} fullWidth sx={{ padding: '0px' }}>
            <GradientCard
              titleId="home.tools.guardian"
              backgroundColor="#FFF0A7"
              icon={guardian}
              to={urlcat(MYTOOLS, { toolType: 'guardian' })}
              toolType={ToolTypes.GUARDIAN}
              imageStyle={{ margin: '22px 6px' }}
              label={`${getLessonTotal()} ${intl.formatMessage({ id: 'home.tools.label' })}`}
            />
          </Button>
        </StyledGrid>
      )}
    </>
  );
}

export default MyGuardianTile;
