import { sceneType } from 'Core_Games/constants/sceneType';
import { bodyPart } from 'Core_Games/constants/bodyPart';
import { uiTypes } from 'Core_Games/constants/uiTypes';
import { animationType } from 'Core_Games/constants/animationType';
import { SingleAction } from '../SingleAction';
import { chaseConfig, keySceneDataTypes } from './chaseConfig';
import { findUI, findAsset } from 'Core_Games/Scripts/configHelper';
import {
  moveActorToPointWithScaling,
  actorBodyPartNearItemNotEquipped,
  resetActorAtPoint,
  isAtPoint,
} from 'Core_Games/Scripts/actorPointHelper';
import { onClickActivityMenu, onClickGoToRoute } from 'Core_Games/Scripts/buttonHelper';
import { PetsConfig } from 'Core_Pages/Pets/PetsConfig';
import { STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';
import { addCharacterActor } from 'Core_Games/Scripts/characterHelper';
import { gameImportType } from 'Core_Games/constants/gameImportType';
import { LogInteraction, interactionExceedsLimit } from '../../../Scripts/interactionHelper';

export class Chase extends SingleAction {
  constructor() {
    super(sceneType.CHASE.NAME);

    this.background = undefined;
    this.speak = undefined;

    this.timerDropProjectile = undefined;
    this.timerReturnToIdle = undefined;
    this.timerRunAfterBall = false;
    this.horizonLine = undefined;

    this.timerReturnToIdleActive = false;
    this.timerRunAfterBallActive = false;
    this.timerDropProjectileActive = false;

    this.objectMovingThreshX = 2000;
    this.objectMovingThreshY = 2000;
    this.objectMovingThreshPickUpX = 70;
    this.objectMovingThreshPickUpTurnX = 35;
    this.objectMovingThreshPickUpY = 150;
    this.objectMovingThreshPickUpTurnY = 5.0;
    this.projectileRelationScaleFactor = 0.5;
    this.walkThresh = 0.5;
    this.maxPetScale = 1.0;
    this.petScaleTransformRate = 1.0;
    this.petballscaleThreshold = 0.1;
    this.ballWorldSize = 40;
    this.dropProjectile = true;

    this.flipXOverrideAtStart = true;
    this.finishedLoading = false;
  }

  init(sceneData) {
    this.sceneData = sceneData;
    this.chaseConfig = chaseConfig;

    this.generateButtons();

    super.init(sceneType.CHASE, this.chaseConfig, sceneData);

    this.initializeScene();

    this.initializePet();

    this.initializeProjectile();
  }

  preload() {
    super.preload();
  }

  create() {
    let _this = this;

    super.create();

    if (this.engineComplete) {
      _this.createScene(_this, _this.parentScene);
    } else {
      this.engine.engineAssets.load.on(Phaser.Loader.Events.COMPLETE, function () {
        _this.createScene(_this);
      });
    }
  }

  generateButtons() {
    let launchButton = findUI(this, this.chaseConfig, uiTypes.BUTTONROW, keySceneDataTypes.LAUNCH_BUTTON);
    let doneButton = findUI(this, this.chaseConfig, uiTypes.BUTTONROW, keySceneDataTypes.DONE_BUTTON);
    let outroButton = findUI(this, this.chaseConfig, uiTypes.BUTTONROW, keySceneDataTypes.OUTRO_BUTTON);
    let maybeLaterButton = findUI(this, this.chaseConfig, uiTypes.BUTTONROW, keySceneDataTypes.MAYBELATER_BUTTON);
    let readyButton = findUI(this, this.chaseConfig, uiTypes.BUTTONROW, keySceneDataTypes.READY_BUTTON);
    let letsGoButton = findUI(this, this.chaseConfig, uiTypes.BUTTONROW, keySceneDataTypes.LETSGO_BUTTON);
    let backButton = findUI(this, this.chaseConfig, uiTypes.BUTTONROW, keySceneDataTypes.BACK_BUTTON);

    // Attach Button Functions
    launchButton
      ? (launchButton.ONCLICK = () => this.onClickLaunch(this, this.pet, this.projectile))
      : (this.launchOnLoad = true);

    doneButton
      ? (doneButton.ONCLICK = () => {
          this.onClickOk(this, this.pet);
        })
      : doneButton;
    maybeLaterButton
      ? (maybeLaterButton.ONCLICK = () => {
          onClickActivityMenu(this.sceneData.appData.history);
        })
      : maybeLaterButton;
    outroButton ? (outroButton.ONCLICK = () => this.onClickOk(this, this.pet)) : outroButton;
    readyButton
      ? (readyButton.ONCLICK = () =>
          onClickGoToRoute(this.sceneData.appData.history, STATICCONTENT, { contentType: 'increasemovement' }))
      : readyButton;
    letsGoButton
      ? (letsGoButton.ONCLICK = () =>
          onClickGoToRoute(this.sceneData.appData.history, STATICCONTENT, { contentType: 'increasemovement' }))
      : letsGoButton;
    backButton
      ? (backButton.ONCLICK = () => {
          onClickActivityMenu(this.sceneData.appData.history);
        })
      : backButton;
  }

  initializeScene() {
    this.useRenderer = this.currentSceneConfig.USERENDERER ?? false;
    this.horizonLinePercentage = this.currentSceneConfig.HORIZON_LINE ?? 0.9;
    this.groundLinePercentage = this.currentSceneConfig.GROUND_LINE ?? 0.1;
    this.moveSpeed = this.currentSceneConfig.MOVESPEED ?? 75;
    this.walkSpeed = this.currentSceneConfig.WALKSPEED ?? 50;
    this.turnBeforeRunning = this.currentSceneConfig.TURNBEFORERUNNING ?? false;
    this.flipAtEnd = this.currentSceneConfig.FLIPATEND ?? false;
    this.dropToLinePercentage = this.currentSceneConfig.DROPTOLINEPERCENTAGE ?? 0.85;
  }

  initializeProjectile() {
    this.objectMovingThreshX =
      this.currentSceneConfig.OBJECTMOVINGTHRESH && this.currentSceneConfig.OBJECTMOVINGTHRESH.x
        ? this.currentSceneConfig.OBJECTMOVINGTHRESH.x
        : 500;
    this.objectMovingThreshY =
      this.currentSceneConfig.OBJECTMOVINGTHRESH && this.currentSceneConfig.OBJECTMOVINGTHRESH.y
        ? this.currentSceneConfig.OBJECTMOVINGTHRESH.y
        : 500;
    this.disableProjectileGravity = this.currentSceneConfig.DISABLEPROJECTILEGRAVITY ?? false;
    this.minProjectileScale = this.currentSceneConfig.MINPROJECTILESCALE;
    this.projectileRelationScaleFactor = this.currentSceneConfig.PROJECTILESCALEFACTOR ?? 0.5;
    this.ballPhysicsParams = {
      velocity: {
        min: this.currentSceneConfig.BALLPHYSICSPARAMS?.velocity?.min ?? 300,
        max: this.currentSceneConfig.BALLPHYSICSPARAMS?.velocity?.max ?? 1000,
      },
      zoomFactor: {
        min: this.currentSceneConfig.BALLPHYSICSPARAMS?.zoomFactor?.min ?? 0.1,
        max: this.currentSceneConfig.BALLPHYSICSPARAMS?.zoomFactor?.max ?? 0.2,
      },
      velocityPower: {
        min: this.currentSceneConfig.BALLPHYSICSPARAMS?.velocityPower?.min ?? 0.001,
        max: this.currentSceneConfig.BALLPHYSICSPARAMS?.velocityPower?.max ?? 0.001,
      },
      bounceFactor: {
        min: this.currentSceneConfig.BALLPHYSICSPARAMS?.bounceFactor?.min ?? 1,
        max: this.currentSceneConfig.BALLPHYSICSPARAMS?.bounceFactor?.max ?? 4,
      },
      bounce: {
        min: this.currentSceneConfig.BALLPHYSICSPARAMS?.bounce?.min ?? 0.6,
        max: this.currentSceneConfig.BALLPHYSICSPARAMS?.bounce?.max ?? 0.8,
      },
      start: {
        x: this.currentSceneConfig.BALLPHYSICSPARAMS?.start?.x ?? 0.8,
        z: this.currentSceneConfig.BALLPHYSICSPARAMS?.start?.z ?? 0.8,
        y: this.currentSceneConfig.BALLPHYSICSPARAMS?.start?.y ?? 0.9,
      },
      position: {
        x: this.currentSceneConfig.BALLPHYSICSPARAMS?.position?.x ?? 0.0,
        y: this.currentSceneConfig.BALLPHYSICSPARAMS?.position?.y ?? this.horizonLinePercentage,
      },
      worldSize: {
        x: this.currentSceneConfig.BALLPHYSICSPARAMS?.worldSize?.x ?? 1.0,
        y: this.currentSceneConfig.BALLPHYSICSPARAMS?.worldSize?.y ?? 1.0,
      },
      scale: this.currentSceneConfig.BALLPHYSICSPARAMS?.SCALE ?? 0.3,
      maxScale: this.currentSceneConfig.BALLPHYSICSPARAMS?.MAXSCALE ?? 1.0,
      minScale: this.currentSceneConfig.BALLPHYSICSPARAMS?.MINSCALE ?? 0.2,
      startFromRight: this.currentSceneConfig.BALLPHYSICSPARAMS?.startFromRight ?? true,
    };
  }

  initializePet() {
    this.catchTouchTimes = 0;
    this.returnToStartDuration = this.currentSceneConfig.RETURNTOSTARTDURATION
      ? this.currentSceneConfig.RETURNTOSTARTDURATION
      : 2000;
    this.dropProjectile =
      this.currentSceneConfig.DROPPROJECTILE !== undefined ? this.currentSceneConfig.DROPPROJECTILE : true;
    this.jumpVelocity = this.currentSceneConfig.JUMPVELOCITY ?? 100;
    this.catchTouchTimesMax = this.currentSceneConfig.CATCH_TOUCH_TIMES ?? 0;
    this.catchObject = this.currentSceneConfig.CATCH_OBJECT ?? true;
    this.maxPetScale = this.currentSceneConfig.MAXPETSCALE ? this.currentSceneConfig.MAXPETSCALE : 1.0;
    this.petScaleTransformRate = this.currentSceneConfig.PET_SCALE_TRANSFORM_RATE;
    this.flipOnStart = this.currentSceneConfig.FLIPONSTART ? this.currentSceneConfig.FLIPONSTART : false;
    this.flipOnEnd = this.currentSceneConfig.FLIPONEND ? this.currentSceneConfig.FLIPONEND : false;
    this.catchBodyPart = this.currentSceneConfig.CATCH_BODYPART ?? bodyPart.MOUTH;
    this.petChaseOffset = this.currentSceneConfig.PETCHASEOFFSET ?? 0;
    this.catchEndPercentage = {
      x: this.currentSceneConfig.CATCHENDPERCENTAGE?.x ?? 0.0,
      y: this.currentSceneConfig.CATCHENDPERCENTAGE?.y ?? 0.0,
      speed: this.currentSceneConfig.CATCHENDPERCENTAGE?.MOVESPEED
        ? this.currentSceneConfig.CATCHENDPERCENTAGE.MOVESPEED
        : this.moveSpeed,
    };
    this.configurableAnimations = {
      START:
        this.currentSceneConfig.ANIMATIONS && this.currentSceneConfig.ANIMATIONS.START
          ? this.currentSceneConfig.ANIMATIONS.START
          : undefined,
      ENDPOSE:
        this.currentSceneConfig.ANIMATIONS && this.currentSceneConfig.ANIMATIONS.ENDPOSE
          ? this.currentSceneConfig.ANIMATIONS.ENDPOSE
          : undefined,
      STARTPOSE:
        this.currentSceneConfig.ANIMATIONS && this.currentSceneConfig.ANIMATIONS.STARTPOSE
          ? this.currentSceneConfig.ANIMATIONS.STARTPOSE
          : undefined,
      IDLEREADY:
        this.currentSceneConfig.ANIMATIONS && this.currentSceneConfig.ANIMATIONS.IDLEREADY
          ? this.currentSceneConfig.ANIMATIONS.IDLEREADY
          : undefined,
      RETURNTOIDLE:
        this.currentSceneConfig.ANIMATIONS && this.currentSceneConfig.ANIMATIONS.RETURNTOIDLE
          ? this.currentSceneConfig.ANIMATIONS.RETURNTOIDLE
          : undefined,
      PICKUPTRANSITION:
        this.currentSceneConfig.ANIMATIONS && this.currentSceneConfig.ANIMATIONS.PICKUPTRANSITION
          ? this.currentSceneConfig.ANIMATIONS.PICKUPTRANSITION
          : undefined,
      PICKUP:
        this.currentSceneConfig.ANIMATIONS && this.currentSceneConfig.ANIMATIONS.PICKUP
          ? this.currentSceneConfig.ANIMATIONS.PICKUP
          : undefined,
      PICKUPFINISH:
        this.currentSceneConfig.ANIMATIONS && this.currentSceneConfig.ANIMATIONS.PICKUPFINISH
          ? this.currentSceneConfig.ANIMATIONS.PICKUPFINISH
          : undefined,
    };
  }

  createScene(_this) {
    // set initial parameters
    this.drawGroundAndHorizonLines(_this);
    this.defineCatchLocationInScene(_this);

    // initialize fake 3d world
    _this.engine.enginePhysics.fake3dworld.init({
      worldSizeX: _this.game.renderer.width,
      worldSizeY: _this.game.renderer.height - _this.game.renderer.height / 2,
      worldSizeZ: _this.game.renderer.height,
    });

    this.createTimers(_this);

    // add projectile & projectile settings
    _this.playConfigurableAnimation(_this.pet, _this.configurableAnimations.START, animationType.IDLE);

    this.drawProjectile(_this);

    this.drawIntroScreenUi(_this);

    _this.pet.setDepth(1);
    _this.projectileDummy.setDepth(3);

    _this.pet.isTurned = _this.flipTurn;
    _this.originalFlip = _this.pet.flipX;

    if (!_this.parentScene) {
      _this.pet.setOrigin(0.5, 0.5);
    } else {
      _this.pet.setOrigin(0.1, 0.1);
    }

    // Set state for play time
    _this.isDonePlaying = false;

    _this.playConfigurableAnimation(_this.pet, _this.configurableAnimations.START, animationType.IDLE);
    if (_this.configurableAnimations.START && _this.configurableAnimations.START.FLIP) {
      _this.unFlipFirst = true;
    }

    _this.finishedLoading = true;

    // physics overlap logic
    _this.addEventLogic(_this, _this.pet, _this.projectile);
  }

  drawGroundAndHorizonLines(scene) {
    // Create a line at which the ball will be stopped when the pet drops it before throwing again
    // 80px is reasonable padding to keep ball over buttons at bottom of pages
    scene.dropToLine = scene.game.renderer.height - 80;

    if (scene.parentScene) {
      scene.horizonLine = scene.otherSceneBoundingBox.horizonLine;
      scene.groundLine = scene.otherSceneBoundingBox.groundLine;
      scene.horizonLinePercentage = scene.otherSceneBoundingBox.horizonLinePercentage;
      scene.groundLinePercentage = scene.otherSceneBoundingBox.groundLinePercentage;
    } else {
      scene.horizonLine = scene.getPagePoint(scene.singleActionBackgroundIndex, 0, scene.horizonLinePercentage).y;
      scene.groundLine = scene.getPagePoint(scene.singleActionBackgroundIndex, 0, scene.groundLinePercentage).y;
      scene.dropToLine =
        scene.getPagePoint(scene.singleActionBackgroundIndex, 0, scene.endLocationPercentageX).y +
        scene.pet.displayHeight * scene.dropToLinePercentage;

      scene.pet.x = scene.startLocationX;
      scene.pet.y = scene.startLocationY;
    }

    // Draw the horizon line, for debuggin purposes (uncomment to show)
    // var line = new Phaser.Geom.Line(0, scene.horizonLine, scene.game.renderer.width * 6, scene.horizonLine);
    // var line2 = new Phaser.Geom.Line(0, scene.groundLine, scene.game.renderer.width * 6, scene.groundLine);
    // var graphics = scene.add.graphics({ lineStyle: { width: 4, color: 0xaa00aa } });
    // var graphics = scene.parentScene.add.graphics({ lineStyle: { width: 4, color: 0xaa00aa } });
    // graphics.strokeLineShape(line);
    // graphics.strokeLineShape(line2);
  }

  defineCatchLocationInScene(scene) {
    if (scene.parentScene) {
      scene.catchEndPointX =
        scene.parentScene.cameras.main.worldView.x +
        scene.parentScene.cameras.main.worldView.width * scene.catchEndPercentage.x;

      scene.catchEndPointY =
        scene.parentScene.cameras.main.worldView.y +
        scene.parentScene.cameras.main.worldView.height * scene.catchEndPercentage.y;
    }
  }

  createTimers(scene) {
    scene.timerDropProjectile = new Phaser.Time.TimerEvent({ delay: 4000 });
    scene.time.addEvent(scene.timerDropProjectile);

    scene.timerReturnToIdle = new Phaser.Time.TimerEvent({ delay: 4000 });
    scene.time.addEvent(scene.timerReturnToIdle);

    scene.timerRunAfterBall = new Phaser.Time.TimerEvent({ delay: 4000 });
    scene.time.addEvent(scene.timerRunAfterBall);
  }

  drawProjectile(scene) {
    const projectileData = findAsset(scene, scene.chaseConfig, keySceneDataTypes.PROJECTILE);

    if (projectileData.TYPE == gameImportType.SPRITESHEET) {
      scene.projectile = addCharacterActor(scene, 0, 0, projectileData.NAME);
      scene.projectile.play(animationType.IDLE);
    } else {
      scene.projectile = scene.add.sprite(400, 350, scene.gameData.gameType.ATLAS, projectileData.DATA);
    }
    scene.projectile.type = projectileData.TYPE ?? gameImportType.IMAGE;
    scene.projectile.name = projectileData.NAME;
    scene.projectile.setSize(50, 50);
    scene.projectile.alpha = 0;

    scene.projectileDummy = scene.add.sprite(400, 350, scene.gameData.gameType.ATLAS, projectileData.DATA);
    scene.projectileDummy.alpha = 0;
  }

  drawIntroScreenUi(scene) {
    if (scene.engine.engineUi?.buttonManager?.screenExists(keySceneDataTypes.SCREEN_INTRO)) {
      scene.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_INTRO);
    }

    if (scene.engine.engineUi?.captionManager?.captionExists(keySceneDataTypes.INTRO_CAPTION)) {
      scene.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.INTRO_CAPTION);
    }
  }

  update() {
    super.update();

    // initialize the world
    if (this.engine?.enginePhysics?.fake3dworld && this.finishedLoading) {
      this.checkProjectileOverlap(this, this.pet, this.projectile);
      if (this.launchOnLoad && !this.isLaunched) {
        this.onClickLaunch(this, this.pet, this.projectile);
        this.isLaunched = true;
      }

      if (this.tweenToEndInProgress) {
        const currentDistanceToEnd = Phaser.Math.Distance.Between(
          this.pet.getCenter().x,
          this.pet.getCenter().y,
          this.endLocationX,
          this.endLocationY,
        );
        const scalePercentage = currentDistanceToEnd / this.endScaleDistance;
        const updatedScale = (this.aimScaleEndTo - this.scaleStart) * (1.0 - scalePercentage) + this.scaleStart;
        this.pet.setScale(updatedScale);
      }

      if (this.timerRunAfterBallActive && this.timerRunAfterBall.getProgress() >= 0.2) {
        this.timerRunAfterBallActive = false;
        if (this.turnBeforeRunning) {
          this.pet.flipX = !this.pet.flipX;
        }
        this.pet.play(this.currentMoveAnimation);
      }

      if (!this.timerRunAfterBallActive) {
        // update projectile logic
        if (
          this.isMovingToStart &&
          isAtPoint(this.pet, this.endLocationX, this.movingToStartY, this.lastLocation.x, this.lastLocation.y)
        ) {
          this.isMovingToStart = false;
        } else if (!this.isMovingToStart) {
          this.updateProjectile();
        }

        this.chaseProjectile(this);

        this.checkSceneCompleteThenDrawEndUi(this);

        this.stopScene(this);

        this.returnAndDropProjectile(this);

        this.checkDoneWithThrow(this, this.pet);
      }
    }
  }

  chaseProjectile(scene) {
    if (!scene.catchObject && scene.catchTouchTimes >= scene.catchTouchTimesMax && !scene.projectileInMotionToEnd) {
      scene.projectileInMotionToEnd = true;
      scene.projectile.body.allowGravity = false;
      scene.projectile.body.collideWorldBounds = false;
      scene.physics.moveTo(
        scene.projectile,
        scene.catchEndPointX,
        scene.catchEndPointY,
        scene.catchEndPercentage.speed,
      );
    }
  }

  checkSceneCompleteThenDrawEndUi(scene) {
    if (
      (scene.actionTimes >= scene.actionLimit ||
        interactionExceedsLimit(scene) ||
        (!scene.catchObject && scene.catchTouchTimes >= scene.catchTouchTimesMax)) &&
      !scene.careActive &&
      !scene.parentScene?.chaseIsDone &&
      ((!scene.parentScene && interactionExceedsLimit(scene) && scene.actionTimes == 0) ||
        (scene.timerDropProjectileActive && scene.timerReturnToIdleActive))
    ) {
      if (!scene.isDonePlaying) {
        scene.playConfigurableAnimation(scene.pet, scene.configurableAnimations.ENDPOSE);
        scene.isDonePlaying = true;
      }

      if (
        scene.engine.engineUi.buttonManager.screenExists(keySceneDataTypes.SCREEN_BACK) &&
        interactionExceedsLimit(scene) &&
        scene.actionTimes == 0
      ) {
        scene.engine.engineUi.buttonManager?.displayScreen(keySceneDataTypes.SCREEN_BACK);
        scene.engine.engineUi.buttonManager?.update(keySceneDataTypes.BACK_BUTTON, false);
        scene.engine.engineUi.captionManager?.displayCaption(keySceneDataTypes.BACK_CAPTION);
      } else if (
        scene.engine.engineUi.buttonManager.screenExists(keySceneDataTypes.SCREEN_OUTRO) &&
        scene.engine.engineUi.captionManager.captionExists(keySceneDataTypes.OUTRO_CAPTION)
      ) {
        scene.engine.engineUi.buttonManager?.displayScreen(keySceneDataTypes.SCREEN_OUTRO);
        scene.engine.engineUi.buttonManager?.update(keySceneDataTypes.LAUNCH_BUTTON, true);
        scene.engine.engineUi.captionManager?.displayCaption(keySceneDataTypes.OUTRO_CAPTION);
      } else {
        scene.parentScene.chaseIsDone = true;
        scene.pet.body.allowGravity = false;
      }

      if (this.timerDropProjectileActive && this.timerReturnToIdleActive && !this.dropProjectile) {
        this.projectileDummy.x = this.pet.getAttachmentLocation(this.catchBodyPart).x;
        this.projectileDummy.y = this.pet.getAttachmentLocation(this.catchBodyPart).y;
        if (this.pet.depth > 0 && this.pet.getAttachmentLocation(this.catchBodyPart).depth) {
          this.projectileDummy.setDepth(this.pet.depth + this.pet.getAttachmentLocation(this.catchBodyPart).depth);
        }
      }
    }
  }

  stopScene(scene) {
    if (
      !scene.catchObject &&
      scene.catchTouchTimes >= scene.catchTouchTimesMax &&
      scene.timerDropProjectileActive &&
      scene.timerReturnToIdleActive &&
      scene.timerReturnToIdle.getProgress() >= 0.3 &&
      scene.parentScene
    ) {
      scene.projectile.alpha = 0;
      scene.engine.enginePhysics.ball3D.toggleMotion(false);
      scene.scene.stop();
      scene.pet.body.collideWorldBounds = false;
      scene.parentScene.sceneIsDone = true;
    }
  }

  returnAndDropProjectile(scene) {
    if (scene.timerDropProjectileActive && scene.timerReturnToIdleActive && !scene.dropProjectile) {
      scene.projectileDummy.x = scene.pet.getAttachmentLocation(scene.catchBodyPart).x;
      scene.projectileDummy.y = scene.pet.getAttachmentLocation(scene.catchBodyPart).y;
      if (scene.pet.depth > 0 && scene.pet.getAttachmentLocation(scene.catchBodyPart).depth) {
        scene.projectileDummy.setDepth(scene.pet.depth + scene.pet.getAttachmentLocation(scene.catchBodyPart).depth);
      }
    }
  }

  // Scene-Specific Functions
  // Add Collision & event logic
  addEventLogic = (game, actor, projectile) => {
    game.chaseProjectileOverlapCollider = game.physics.add.overlap(
      actor,
      projectile,
      function () {
        if (!game.parentScene) {
          game.checkProjectileOverlap(game, actor, projectile);
        }
      },
      null,
      game,
    );

    game.chaseAnimationComplete = actor.on(Phaser.Animations.Events.ANIMATION_COMPLETE, function () {
      game.pickUpAnimation = game.configurableAnimations.PICKUP
        ? game.configurableAnimations.PICKUP.NAME
        : animationType.OBJECT_INTERACT;
      game.pickUpFinishAnimation = game.configurableAnimations.PICKUPFINISH
        ? game.configurableAnimations.PICKUPFINISH.NAME
        : animationType.OBJECT_INTERACT;
      game.pickUpAnimationTransition = game.configurableAnimations.PICKUPTRANSITION
        ? game.configurableAnimations.PICKUPTRANSITION.NAME
        : animationType.OBJECT_INTERACT;
      if (actor.lastAnimationPlayed != actor.rawAnimName()) {
        actor.lastAnimationPlayed = actor.rawAnimName();
      }
      if (
        !game.timerRunAfterBallActive &&
        (actor.animationIsActive(animationType.TURN) ||
          actor.animationIsActive(animationType.BACKTURN_HALF) ||
          actor.animationIsActive(animationType.FRONTTURN_HALF) ||
          actor.animationIsActive(animationType.BACKTURN) ||
          actor.animationIsActive(animationType.TURN_TO_ANGLE))
      ) {
        if (actor.animationIsActive(animationType.TURN) && !game.timerDropProjectileActive) {
          actor.play(animationType.RUNTOWARDS);
          actor.flipX = !actor.flipX;
        } else if (!game.timerDropProjectileActive) {
          actor.play(game.currentMoveAnimation);
          actor.flipX = !actor.flipX;
        } else if (game.timerDropProjectileActive) {
          if (!actor.animationIsActive(animationType.SIT)) {
            actor.play(animationType.SIT);
          }
          actor.flipX = !actor.flipX;
        }
      } else if (
        actor.animationIsActive(game.pickUpAnimationTransition) &&
        game.isPickingUpTransition &&
        game.pickupInProgress
      ) {
        game.isPickingUpTransition = false;
        game.isPickingUp = true;
        game.playConfigurableAnimation(actor, game.configurableAnimations.PICKUP, animationType.OBJECT_INTERACT);
      } else if (actor.animationIsActive(game.pickUpAnimation) && game.isPickingUp && game.pickupInProgress) {
        game.isPickingUp = false;
        game.isFinishingPickUp = true;
        if (game.isWithinPickupDistance) {
          game.pickUpProjectile(game, actor, projectile);
          game.playConfigurableAnimation(
            actor,
            game.configurableAnimations.PICKUPFINISH,
            animationType.OBJECT_INTERACT,
          );
        } else {
          game.playConfigurableAnimation(
            actor,
            game.configurableAnimations.PICKUPFINISH,
            animationType.OBJECT_INTERACT,
          );
        }
      } else if (
        actor.animationIsActive(game.pickUpFinishAnimation) &&
        !game.isPickingUp &&
        game.pickupInProgress &&
        game.isFinishingPickUp
      ) {
        game.isPickingUp = false;
        game.isFinishingPickUp = false;
        game.pickupInProgress = false;
      }
    });
  };

  checkDoneWithThrow = (game, actor) => {
    if (
      ((game.configurableAnimations.IDLEREADY && actor.animationIsActive(game.configurableAnimations.IDLEREADY.NAME)) ||
        (game.configurableAnimations.RETURNTOIDLE &&
          actor.animationIsActive(game.configurableAnimations.RETURNTOIDLE.NAME)) ||
        (!game.configurableAnimations.RETURNTOIDLE && !game.configurableAnimations.IDLEREADY)) &&
      !game.isDonePlaying &&
      !game.againScreenTriggered
    ) {
      if (game.timerDropProjectileActive && game.timerReturnToIdleActive) {
        // TODO: once we have the proud stance animation, play that instead of either sit or idle,
        // or at least in-between (may need another timer if that is the case)
        if (
          game.engine.engineUi.captionManager.captionExists(keySceneDataTypes.AGAIN_CAPTION) &&
          game.engine.engineUi.buttonManager.screenExists(keySceneDataTypes.SCREEN_AGAIN)
        ) {
          game.timerRunAfterBallActive = false;
          game.againScreenTriggered = true;
          game.engine.engineUi.captionManager?.showCaption(keySceneDataTypes.AGAIN_CAPTION);
          game.engine.engineUi.buttonManager?.displayScreen(keySceneDataTypes.SCREEN_AGAIN);
          game.engine.engineUi.buttonManager?.update(keySceneDataTypes.LAUNCH_BUTTON, false);
          game.engine.engineUi.buttonManager?.update(keySceneDataTypes.DONE_BUTTON, false);
        }
      }
    }
  };

  checkProjectileOverlap(game, actor, projectile) {
    game.projectilePointOverlap = {
      x: projectile.x,
      y: projectile.y,
    };
    if (game.parentScene) {
      game.projectilePointOverlap = {
        x:
          game.parentScene.cameras.main.worldView.x +
          (game.projectile.getCenter().x / game.game.renderer.width) * game.parentScene.cameras.main.worldView.width,
        y:
          game.parentScene.cameras.main.worldView.y +
          (game.projectile.getCenter().y / game.game.renderer.height) * game.parentScene.cameras.main.worldView.height,
      };
    }

    game.pickUpProjectileCollider(game, actor, projectile);
  }

  pickUpProjectileCollider = (game, actor, projectile) => {
    const projectileIsStopped =
      game.engine.enginePhysics.ball3D &&
      (game.disableProjectileGravity ||
        !game.engine.enginePhysics.ball3D.objectIsMoving(
          game.objectMovingThreshPickUpX,
          game.objectMovingThreshPickUpY,
        ));
    const isLookingForProjectile =
      !game.timerReturnToIdleActive &&
      !game.timerDropProjectileActive &&
      !actor.itemEquipped(game.catchBodyPart, projectile.name);

    this.updatePickupCoordinates(game, actor, projectile);

    this.updatePetProjectilePerspective(game, actor, projectile);

    if (
      projectileIsStopped &&
      isLookingForProjectile &&
      game.isWithinPickupDistance &&
      !actor.animationIsActive(animationType.TURN) &&
      !actor.animationIsActive(animationType.BACKTURN_HALF) &&
      !actor.animationIsActive(animationType.TURN_TO_ANGLE) &&
      !actor.animationIsActive(animationType.BACKTURN) &&
      !actor.animationIsActive(animationType.FRONTTURN_HALF)
    ) {
      if (
        !actor.animationIsActive(animationType.JUMP) &&
        !actor.animationIsActive(animationType.LAND) &&
        !game.pickupInProgress
      ) {
        let currentConfigurableAnimation;

        if (game.configurableAnimations.PICKUPTRANSITION) {
          currentConfigurableAnimation = game.configurableAnimations.PICKUPTRANSITION;
          game.isPickingUpTransition = true;
        } else {
          currentConfigurableAnimation = game.configurableAnimations.PICKUP;
          game.isPickingUp = true;
        }

        game.pickupInProgress = true;

        if (currentConfigurableAnimation && currentConfigurableAnimation.DEPTH !== undefined) {
          game.originalPickupProjectileDepth = projectile.depth;
          projectile.setDepth(currentConfigurableAnimation.DEPTH);
        }
        actor.body.stop();
        game.playConfigurableAnimation(actor, currentConfigurableAnimation, animationType.OBJECT_INTERACT);
      } else if (
        !game.pickupInProgress &&
        ((game.configurableAnimations.PICKUP && !actor.animationIsActive(game.configurableAnimations.PICKUP.NAME)) ||
          (!game.configurableAnimations.PICKUP && !actor.animationIsActive(animationType.OBJECT_INTERACT)))
      ) {
        game.pickUpProjectile(game, actor, projectile);
      }
    }
  };

  updatePickupCoordinates = (game, actor) => {
    game.currentPickupFrameToCheck =
      actor.animationIsActive(animationType.JUMP) || actor.animationIsActive(animationType.LAND)
        ? undefined
        : game.configurableAnimations.PICKUP
        ? actor.getLastFrameNumberOfAnimation(game.configurableAnimations.PICKUP.NAME)
        : undefined;

    if (game.currentPickupFrameToCheck) {
      const currentCoords = actor.getAttachmentLocation(game.catchBodyPart, game.currentPickupFrameToCheck);
      game.currentFetchObjectLocation = {
        x: currentCoords.x,
        y: currentCoords.y,
      };
    } else {
      const currentCoords = actor.getAttachmentLocation(game.catchBodyPart);
      game.currentFetchObjectLocation = {
        x: currentCoords.x,
        y: currentCoords.y,
      };
    }

    game.isWithinPickupDistance = actorBodyPartNearItemNotEquipped(
      game,
      actor,
      game.projectile,
      game.projectilePointOverlap,
      game.scaleAim,
      game.catchBodyPart,
      game.petballscaleThreshold,
      game.horizonLine,
      game.currentFetchObjectLocation,
      game.otherSceneBoundingBox,
      game.projectileRelationScaleFactor,
      game.maxPetScale,
    );
  };

  updatePetProjectilePerspective = (game, actor) => {
    const currentDistanceToProjectileX = Math.abs(game.currentFetchObjectLocation?.x - game.projectilePointOverlap?.x);
    const currentDistanceToProjectileY = Math.abs(game.currentFetchObjectLocation?.y - game.projectilePointOverlap?.y);

    const walkingDistanceRatioX = currentDistanceToProjectileX / game.cameras.main.worldView.width;
    const walkingDistanceRatioY = currentDistanceToProjectileY / game.cameras.main.worldView.height;

    const isWithinWalkingDistance =
      walkingDistanceRatioX <= game.walkThresh && walkingDistanceRatioY <= game.walkThresh;

    if (
      isWithinWalkingDistance &&
      game.currentMoveAnimation != animationType.WALK &&
      !actor.itemEquipped(game.catchBodyPart, game.projectile.name)
    ) {
      game.currentMoveSpeed = game.walkSpeed;
      if (
        game.currentFetchObjectLocation?.y - game.projectilePointOverlap?.y < 0 &&
        Math.abs(game.currentFetchObjectLocation?.y - game.projectilePointOverlap?.y) >
          game.objectMovingThreshPickUpTurnY
      ) {
        game.currentMoveAnimation = animationType.WALK_FRONT_ANGLE;
        if (!game.disableProjectileGravity && game.projectile.depth < actor.depth) {
          game.projectile.setDepth(actor.depth + 4);
        }
      } else {
        game.currentMoveAnimation = animationType.WALK_BACK_ANGLE;
        if (!game.disableProjectileGravity && game.projectile.depth >= actor.depth) {
          game.projectile.setDepth(actor.depth - 1);
        }
      }
    } else if (game.currentMoveAnimation != animationType.RUNAWAY && !isWithinWalkingDistance) {
      game.currentMoveSpeed = game.moveSpeed;
      if (game.currentFetchObjectLocation?.y - game.projectilePointOverlap?.y < 0) {
        game.currentMoveAnimation = animationType.RUNTOWARDS;
        if (!game.disableProjectileGravity && game.projectile.depth < actor.depth) {
          game.projectile.setDepth(actor.depth + 4);
        }
      } else {
        game.currentMoveAnimation = animationType.RUNAWAY;
        if (!game.disableProjectileGravity && game.projectile.depth >= actor.depth) {
          game.projectile.setDepth(actor.depth - 1);
        }
      }
    }
  };

  pickUpProjectile = (game, actor, projectile) => {
    if (game.catchObject) {
      actor.equip(game.catchBodyPart, projectile.name, game.projectileDummy);
      game.ballScaleOnPickup = game.projectile.scale;
    } else {
      game.catchTouchTimes++;
      actor.equip(game.catchBodyPart, projectile.name, game.projectileDummy);
      game.ballScaleOnPickup = game.projectile.scale;
      game.projectileDummy.ghostEquip = true;
      game.engine.enginePhysics.ball3D.toggleMotion(false);
    }

    if (
      game.originalPickupProjectileDepth !== undefined &&
      game.configurableAnimations.PICKUPTRANSITION &&
      game.configurableAnimations.PICKUPTRANSITION.DEPTH !== undefined
    ) {
      projectile.setDepth(game.originalPickupProjectileDepth);
    }
    actor.getItem(game.catchBodyPart, game.projectile.name).allowExternalUpdate = false;
  };

  updateProjectile = () => {
    if (
      this.timerDropProjectileActive &&
      this.projectileDummy.getBottomCenter().y >= this.dropToLine &&
      this.projectileDummy.body &&
      this.projectileDummy.body.allowGravity &&
      this.dropProjectile
    ) {
      this.projectileDummy.body.allowGravity = false;
      this.projectileDummy.body.stop();
      this.projectileDummy.stop();
      if (this.pet.itemEquipped(this.catchBodyPart, this.projectile.name)) {
        this.pet.getItem(this.catchBodyPart, this.projectile.name).allowExternalUpdate = true;
      }
    }
    if (this.pet.itemEquipped(this.catchBodyPart, this.projectile.name)) {
      if (
        (this.projectile.alpha != 0 && this.catchObject) ||
        (!this.catchObject && this.projectile.x < -(this.pet.getCenter().x - this.otherSceneBoundingBox.startX))
      ) {
        this.projectile.stop();
        this.projectile.alpha = 0;
      }

      if (!this.timerDropProjectileActive) {
        // if actor returns with item to location
        if (
          this.startEndLocationX &&
          this.startEndLocationY &&
          (isAtPoint(
            this.pet,
            this.endLocationX,
            this.endLocationY,
            this.startEndLocationX,
            this.startEndLocationY,
            5.0,
            5.0,
          ) ||
            this.endLocationY - this.pet.y <= 0)
        ) {
          if (!this.pet.animationIsActive(animationType.SIT)) {
            this.pet.play(animationType.SIT);
            if (this.parentScene) {
              this.pet.body.stop();
            }
          }
          if (!this.parentScene) {
            resetActorAtPoint(this, this.pet, this.endLocationX, this.endLocationY, this.otherSceneBoundingBox);
          }
          this.pet.body.allowGravity = false;
          if (this.pet.flipX != this.flipXOverrideAtStart) {
            this.pet.flipX = !this.pet.flipX;
            this.pet.isTurned = !this.pet.isTurned;
            this.pet.play(animationType.TURN);
          }

          this.time.addEvent(this.timerDropProjectile);
          this.timerDropProjectileActive = true;
        } else {
          if (
            !this.tweenToEndInProgress &&
            !this.pickupInProgress &&
            !this.pet.animationIsActive(animationType.JUMP) &&
            !this.pet.animationIsActive(animationType.LAND)
          ) {
            this.scaleStart = this.pet.scale;
            this.scaleTo = this.configurablePetScale ? this.configurablePetScale : this.pet.originalScale;
            this.aimScaleEndTo = this.pet.getRelativeActorScale(this.scaleTo)
              ? this.pet.getRelativeActorScale(this.scaleTo)
              : this.scaleTo;
            this.endScaleDistance = Phaser.Math.Distance.Between(
              this.pet.getCenter().x,
              this.pet.getCenter().y,
              this.endLocationX,
              this.endLocationY,
            );
            this.pet.play(animationType.RUNTOWARDS);
            this.startEndLocationX = this.pet.getCenter().x;
            this.startEndLocationY = this.pet.getCenter().y;
            this.physics.moveTo(
              this.pet,
              this.endLocationX,
              this.endLocationY,
              this.currentMoveSpeed,
              this.returnToStartDuration,
            );
            this.tweenToEndInProgress = true;
          } else if (
            !this.timerDropProjectileActive &&
            this.engine.enginePhysics.ball3D &&
            !this.tweenToEndInProgress &&
            (this.pet.animationIsActive(animationType.LAND) || this.pet.animationIsActive(animationType.JUMP))
          ) {
            if (this.horizonLine && this.pet.getBottomCenter().y < this.horizonLine && !this.pet.body.allowGravity) {
              this.pet.body.setVelocityY(-1 * jumpVelocity);
              this.pet.body.allowGravity = true;
              this.pet.play(animationType.JUMP);
            } else if (
              this.horizonLine &&
              this.pet.getBottomCenter().y >= this.horizonLine &&
              this.pet.body.allowGravity
            ) {
              this.pet.body.allowGravity = false;
              this.pet.play(animationType.RUNAWAY);
            } else if (this.horizonLine && this.pet.body.allowGravity && Math.abs(this.pet.body.velocity.y) <= 5.0) {
              this.pet.play(animationType.LAND);
            }
          }
        }
      }
      // drop ball once returned to start & sitting
      else if (
        this.timerDropProjectileActive &&
        !this.timerReturnToIdleActive &&
        !this.returnToIdleInProgress &&
        this.pet.lastAnimationPlayed == animationType.SIT
      ) {
        if (
          !this.pet.animationIsActive(animationType.SIT) ||
          this.configurableAnimations.RETURNTOIDLE.NAME != animationType.SIT
        ) {
          this.playConfigurableAnimation(this.pet, this.configurableAnimations.RETURNTOIDLE, animationType.IDLE);
        }
        this.returnToIdleInProgress = true;
      } else if (
        this.timerDropProjectileActive &&
        this.timerDropProjectile.getProgress() >= 0.2 &&
        !this.timerReturnToIdleActive &&
        this.returnToIdleInProgress &&
        ((this.configurableAnimations.RETURNTOIDLE &&
          this.pet.lastAnimationPlayed == this.configurableAnimations.RETURNTOIDLE.NAME) ||
          (!this.configurableAnimations.RETURNTOIDLE && this.pet.lastAnimationPlayed == animationType.IDLE))
      ) {
        this.physics.world.disable(this.projectile);
        this.pet.unequip(this.catchBodyPart);
        if (this.dropProjectile) {
          this.physics.world.enable(this.projectileDummy);
          this.projectileDummy.body.allowGravity = true;
        }
        this.time.addEvent(this.timerReturnToIdle);
        this.timerReturnToIdleActive = true;
      }
    } else {
      // get pet back into idle postion after the ball is dropped
      if (this.timerReturnToIdleActive && this.actionTimes > 0) {
        const progressBallIdle = this.timerReturnToIdle.getProgress();
        if (
          progressBallIdle >= 0.2 &&
          progressBallIdle <= 0.21 &&
          (!this.catchObject || this.actionTimes < this.actionLimit)
        ) {
          this.playConfigurableAnimation(this.pet, this.configurableAnimations.IDLEREADY);
        }
      }

      // if pet doesn't have ball and the ball has been tossed, command the pet to go get it
      if (!this.timerDropProjectileActive && this.engine.enginePhysics.ball3D) {
        if (
          !this.pet.animationIsActive(this.currentMoveAnimation) &&
          !this.pet.animationIsActive(animationType.BACKTURN_HALF) &&
          !this.pet.animationIsActive(animationType.FRONTTURN_HALF) &&
          !this.pet.animationIsActive(animationType.BACKTURN) &&
          !this.pet.animationIsActive(animationType.TURN_TO_ANGLE) &&
          !this.pet.animationIsActive(animationType.TURN) &&
          !this.pet.animationIsActive(animationType.JUMP) &&
          !this.pet.animationIsActive(animationType.LAND) &&
          !this.pickupInProgress
        ) {
          this.pet.play(this.currentMoveAnimation);
        }

        this.scaleAim =
          this.originalPetScale * this.projectile.scale + this.projectileRelationScaleFactor * this.originalPetScale;

        this.projectilePointTo = {
          x: this.projectile.getCenter().x,
          y: this.projectile.getCenter().y,
        };

        if (this.parentScene) {
          this.projectilePointTo = {
            x:
              this.parentScene.cameras.main.worldView.x +
              (this.projectile.getCenter().x / this.game.renderer.width) *
                this.parentScene.cameras.main.worldView.width -
              this.pet.displayWidth * this.petChaseOffset,
            y:
              this.parentScene.cameras.main.worldView.y +
              (this.projectile.getCenter().y / this.game.renderer.height) *
                this.parentScene.cameras.main.worldView.height +
              this.projectile.displayHeight * 0.5 -
              this.pet.displayHeight * this.petChaseOffset,
          };
        }

        if (!this.pickupInProgress) {
          this.distanceToProjectile = moveActorToPointWithScaling(
            this,
            this.pet,
            this.petScaleTransformRate,
            this.projectilePointTo.x,
            this.projectilePointTo.y,
            this.distanceToProjectile,
            this.jumpVelocity,
            this.currentFetchObjectLocation,
            this.currentMoveSpeed,
            this.currentMoveAnimation,
            this.projectileRelationScaleFactor,
            this.maxPetScale,
            this.horizonLine,
            this.groundLine,
            this.otherSceneBoundingBox,
            this.scaleAim,
            this.catchBodyPart,
          );
        }
      }
    }

    this.keepPetFacingDirectionOfMovement();
  };

  keepPetFacingDirectionOfMovement = () => {
    if (
      ((!this.pet.itemEquipped(this.catchBodyPart, this.projectile.name) && this.engine.enginePhysics.ball3D) ||
        (this.pet.itemEquipped(this.catchBodyPart, this.projectile.name) && !this.timerDropProjectileActive)) &&
      !this.timerReturnToIdleActive
    ) {
      const farEnoughAwayFromProjectile =
        Math.abs(this.currentFetchObjectLocation?.x - this.projectilePointOverlap?.x) >
        this.objectMovingThreshPickUpTurnX
          ? true
          : false;

      const theChosenTurn = this.tweenToEndInProgress && this.flipAtEnd ? !this.flipTurn : this.flipTurn;

      const shouldTurn =
        (farEnoughAwayFromProjectile &&
          !this.pet.animationIsActive(animationType.JUMP) &&
          !this.pet.animationIsActive(animationType.LAND) &&
          !this.pickupInProgress &&
          !this.parentScene &&
          ((this.pet.body.velocity.x < 0 && this.pet.isTurned != theChosenTurn) ||
            (this.pet.body.velocity.x > 0 && this.pet.isTurned == theChosenTurn))) ||
        (this.parentScene &&
          ((this.pet.parentVelocityDirectionX < 0 && this.pet.isTurned == theChosenTurn) ||
            (this.pet.parentVelocityDirectionX > 0 && this.pet.isTurned != theChosenTurn)));

      if (shouldTurn) {
        let currentAnimationToPlay;
        if (this.pet.itemEquipped(this.catchBodyPart, this.projectile.name)) {
          currentAnimationToPlay = animationType.TURN;
        } else {
          currentAnimationToPlay =
            this.pet.animationIsActive(animationType.WALK_FRONT_ANGLE) ||
            this.pet.animationIsActive(animationType.RUNTOWARDS)
              ? animationType.FRONTTURN_HALF
              : animationType.BACKTURN_HALF;
        }

        if (!this.pet.animationIsActive(currentAnimationToPlay)) {
          this.pet.isTurned = !this.pet.isTurned;
          this.pet.flipX = !this.pet.flipX;
          this.pet.play(currentAnimationToPlay);
        }
      }
      this.currentX = this.pet.getCenter().x;
      this.currentY = this.pet.getCenter().y;
    }
  };

  // Launch Projectile
  onClickLaunch = (game, actor, object) => {
    // Hide projectile if it was sitting in view due to previous throw
    game.projectileDummy.alpha = 0;
    game.engine.enginePhysics.fake3dworld?.resetObjects();
    if (actor.animationIsActive(animationType.SIT)) {
      actor.playReverse(animationType.SIT);
    }
    if (game.projectileDummy.body) {
      game.engine.physics.world.disable(game.projectileDummy);
    }
    if (actor.itemEquipped(this.catchBodyPart, findAsset(game, game.chaseConfig, keySceneDataTypes.PROJECTILE).NAME)) {
      actor.unequip(this.catchBodyPart);
    }

    if (object.type == gameImportType.SPRITESHEET) {
      object.play(animationType.IDLE);
    }

    if (game.unFlipFirst) {
      actor.flipX = !actor.flipX;
      game.unFlipFirst = false;
    }

    game.againScreenTriggered = false;
    game.returnToIdleInProgress = false;
    game.pickupInProgress = false;
    game.timerDropProjectileActive = false;
    game.timerReturnToIdleActive = false;
    game.distanceToProjectile = undefined;
    game.distanceToStart = undefined;
    game.tweenToEndInProgress = false;
    object.alpha = 1;
    game.actionTimes++;

    actor.isTurned = game.flipTurn;
    actor.flipX = game.originalFlip;

    if (game.turnBeforeRunning) {
      actor.play(animationType.TURN_TO_ANGLE);
      actor.flipX = !actor.flipX;
      game.time.addEvent(game.timerRunAfterBall);
      game.timerRunAfterBallActive = true;
    } else {
      game.timerRunAfterBallActive = false;
    }

    // For storyboards using this single action
    if (game.parentScene) {
      game.isMovingToStart = true;
      game.lastLocation = { x: actor.x, y: actor.y };
      game.pet.play(animationType.WALK);
      game.movingToStartY = game.groundLine - game.pet.displayHeight * 0.5;
      game.movingToStartX = game.pet.x;
      game.physics.moveTo(actor, game.endLocationX, game.movingToStartY, 50, 150);
    }

    if (
      (game.engine.engineUi.captionManager.captionExists(keySceneDataTypes.INTRO_CAPTION) ||
        game.engine.engineUi.captionManager.captionExists(keySceneDataTypes.AGAIN_CAPTION)) &&
      (game.engine.engineUi.buttonManager.screenExists(keySceneDataTypes.SCREEN_INTRO) ||
        game.engine.engineUi.buttonManager.screenExists(keySceneDataTypes.SCREEN_AGAIN))
    ) {
      game.engine.engineUi.captionManager?.toggleCaption(true);
      game.engine.engineUi.buttonManager?.update(keySceneDataTypes.LAUNCH_BUTTON, true);
      game.engine.engineUi.buttonManager?.update(keySceneDataTypes.DONE_BUTTON, true);
    }

    if (!game.parentScene) {
      LogInteraction(game, game.sceneData?.sceneType);
    }

    // Get relative points to be used in physics fake 3d world engine
    const currentEndY =
      game.ballPhysicsParams.end && game.ballPhysicsParams.end.y ? game.ballPhysicsParams.end.y : 0.9999;

    let startPositionBox;
    let worldSizeEnd;
    let worldSizeStart;
    let posX;
    let posY;

    startPositionBox = {
      y1: game.getPagePoint(game.singleActionBackgroundIndex, 0, game.ballPhysicsParams.start.y, true).y,
      y2: game.getPagePoint(game.singleActionBackgroundIndex, 0, currentEndY, true).y,
      x1:
        game.getPagePoint(
          game.singleActionBackgroundIndex,
          game.ballPhysicsParams.start.x,
          game.ballPhysicsParams.start.z,
          true,
        ).x -
        game.ballWorldSize -
        2,
      z1: game.getPagePoint(
        game.singleActionBackgroundIndex,
        game.ballPhysicsParams.start.x,
        game.ballPhysicsParams.start.z,
        true,
      ).y,
    };
    worldSizeEnd = {
      x: game.getPagePoint(
        game.singleActionBackgroundIndex,
        game.ballPhysicsParams.worldSize.x,
        game.ballPhysicsParams.worldSize.y,
        true,
      ).x,
      y: game.getPagePoint(
        game.singleActionBackgroundIndex,
        game.ballPhysicsParams.worldSize.x,
        game.ballPhysicsParams.worldSize.y,
        true,
      ).y,
    };
    worldSizeStart = {
      x: game.getPagePoint(game.singleActionBackgroundIndex, 0, 0, true).x,
      y: game.getPagePoint(game.singleActionBackgroundIndex, 0, 0, true).y,
    };

    posX = game.getPagePoint(
      game.singleActionBackgroundIndex,
      game.ballPhysicsParams.position.x,
      game.ballPhysicsParams.position.y,
      true,
    ).x;
    posY = game.getPagePoint(
      game.singleActionBackgroundIndex,
      game.ballPhysicsParams.position.x,
      game.ballPhysicsParams.position.y,
      true,
    ).y;

    let worldSizes = {
      x: worldSizeEnd.x - worldSizeStart.x,
      y: worldSizeEnd.y - worldSizeStart.y,
    };

    if (game.useRenderer) {
      worldSizes = {
        x: game.cameras.main.worldView.width,
        y: worldSizeEnd.y - worldSizeStart.y,
      };
      posX = game.cameras.main.worldView.x;
    }

    game.engine.enginePhysics.ball3D = game.engine.enginePhysics.fake3dworld.addObject({
      positionBoxX: posX,
      positionBoxY: posY,
      worldSizeX: worldSizes.x,
      worldSizeY: worldSizes.y,
      velocity: Phaser.Math.FloatBetween(game.ballPhysicsParams.velocity.min, game.ballPhysicsParams.velocity.max),
      bounce: Phaser.Math.FloatBetween(game.ballPhysicsParams.bounce.min, game.ballPhysicsParams.bounce.max),
      zoomFactor: Phaser.Math.FloatBetween(
        game.ballPhysicsParams.zoomFactor.min,
        game.ballPhysicsParams.zoomFactor.max,
      ),
      scale: game.ballPhysicsParams.scale,
      minScale: game.ballPhysicsParams.minScale,
      maxScale: game.ballPhysicsParams.maxScale,
      size: game.ballWorldSize,
      startX: startPositionBox.x1,
      startZ: startPositionBox.z1,
      startY: Phaser.Math.FloatBetween(startPositionBox.y1, startPositionBox.y2),
      velocityPower: Phaser.Math.FloatBetween(
        game.ballPhysicsParams.velocityPower.min,
        game.ballPhysicsParams.velocityPower.max,
      ),
      bounceFactor: Phaser.Math.FloatBetween(
        game.ballPhysicsParams.bounceFactor.min,
        game.ballPhysicsParams.bounceFactor.max,
      ),
      startFromRight: game.ballPhysicsParams.startFromRight,
      disableGravity: game.disableProjectileGravity,
      object: object,
      horizonLine: game.parentScene ? game.horizonLinePercentage : undefined,
      groundLine: game.parentScene ? game.groundLinePercentage : undefined,
    });
  };

  onClickOk = (game, actor) => {
    game.careActive = true;
    game.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_CARE);
    game.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.CARE_CAPTION);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.MAYBELATER_BUTTON, false);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.LETSGO_BUTTON, false);
    game.playConfigurableAnimation(actor, game.configurableAnimations.STARTPOSE);
    if (actor.ObjectData.NAME === PetsConfig.types.cat) {
      game.isDonePlaying = true;
    }
  };

  playConfigurableAnimation = (actor, config, defaultAnimation = undefined, reverse = false) => {
    if (config) {
      if (reverse || config.REVERSE) {
        actor.playReverse(config.NAME);
      } else {
        actor.play(config.NAME);
      }
      if (config.FLIP) {
        actor.flipX = !actor.flipX;
        actor.isTurned = !actor.isTurned;
      }
    } else if (defaultAnimation) {
      actor.play(defaultAnimation);
      if (reverse) {
        actor.playReverse(defaultAnimation);
      } else {
        actor.play(defaultAnimation);
      }
    }
  };
}
