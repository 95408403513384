import { THRIVECONTENT } from 'Core_Pages/Routes/RoutesConfig';
import urlcat from 'urlcat';
import { andy, mytools, pyxir, plant } from 'Assets/img/thrive-icons';
import * as permissions from 'Core_Helpers/authentication/PermissionsConfig';
import { updateThriveWorkflow } from 'Core_Redux/thrive';
import { contentTypes } from 'Core_Pages/Thrive/ThriveContent/enums';
import { StyledContainerLayout } from './styles';
import Button from 'Stories/components/Button';

export const thriveContentTypes = {
  OVERVIEW: 'OVERVIEW',
};

export const thriveContentUrls = {
  [thriveContentTypes.OVERVIEW]: urlcat(THRIVECONTENT, { thriveContentType: 'overview' }),
};

export const thriveContent = {
  [thriveContentTypes.OVERVIEW]: {
    permissions: permissions.THRIVEASSESSMENT,
    onEnterDispatch: ({ dispatch }) => {
      dispatch(updateThriveWorkflow({ shownWelcome: true }));
    },
    title: 'title.thrive-welcome',
    header: 'thrive.overview.header',
    headerProps: {
      style: {
        alignSelf: 'center',
        marginBottom: '10px',
      },
    },
    ContainerComponent: StyledContainerLayout,
    CustomButton: Button,
    buttonProps: {
      fullWidth: true,
    },
    gridContainerProps: {
      sx: {
        rowGap: 1,
      },
    },
    content: [
      {
        contentType: contentTypes.TEXT,
        textId: 'thrive.overview.next-four-weeks',
        lineHeight: 1.2,
        sx: {
          textAlign: 'center',
          width: '80%',
          margin: '0 auto',
        },
      },
      {
        contentType: contentTypes.LIST_IMAGE,
        textId: 'thrive.overview.andy',
        items: [
          { textId: 'thrive.overview.andy.title', props: { fontWeight: 700 } },
          {
            textId: 'thrive.overview.andy.description',
          },
        ],
        src: andy,
        altId: 'image.andy.portrait',
        lineHeight: 1.2,
        listGridProps: { style: { minWidth: '70px' } },
        imageStyle: { maxWidth: '70px' },
        gridGroupProps: {
          paddingBottom: '10px',
          borderBottom: '1px solid #979797',
          direction: 'row',
          alignItems: 'center',
        },
      },
      {
        contentType: contentTypes.LIST_IMAGE,
        textId: 'thrive.overview.my-tools',
        items: [
          { textId: 'thrive.overview.my-tools.title', props: { fontWeight: 700 } },
          {
            textId: 'thrive.overview.my-tools.description',
          },
        ],
        src: mytools,
        altId: 'image.my-tools.portrait',
        lineHeight: 1.2,
        listGridProps: { style: { minWidth: '70px' } },
        imageStyle: { maxWidth: '70px' },
        gridGroupProps: {
          paddingBottom: '10px',
          borderBottom: '1px solid #979797',
          direction: 'row',
          alignItems: 'center',
        },
      },
      {
        contentType: contentTypes.LIST_IMAGE,
        textId: 'thrive.overview.progress-area',
        items: [
          { textId: 'thrive.overview.program-tracker.title', props: { fontWeight: 700 } },
          {
            textId: 'thrive.overview.program-tracker.description',
          },
        ],
        src: plant,
        altId: 'thrive.image.plant',
        lineHeight: 1.2,
        listGridProps: { style: { minWidth: '70px' } },
        imageStyle: { maxWidth: '70px' },
        gridGroupProps: {
          paddingBottom: '10px',
          borderBottom: '1px solid #979797',
          direction: 'row',
          alignItems: 'center',
        },
      },
      {
        contentType: contentTypes.LIST_IMAGE,
        textId: 'thrive.overview.pyxir',
        items: [
          { textId: 'thrive.overview.pyxir.title', props: { fontWeight: 700 } },
          {
            textId: 'thrive.overview.pyxir.description',
          },
        ],
        src: pyxir,
        altId: 'image.pyxir.smiling',
        lineHeight: 1.2,
        listGridProps: { style: { minWidth: '70px' } },
        imageStyle: { maxWidth: '70px' },
        gridGroupProps: {
          paddingBottom: '10px',
          borderBottom: '1px solid #979797',
          direction: 'row',
          alignItems: 'center',
        },
      },
      {
        contentType: contentTypes.TEXT,
        textId: 'thrive.overview.not-alone',
        lineHeight: 1.2,
        sx: {
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
          width: '80%',
          margin: '0 auto',
          marginBottom: '15px',
        },
      },
    ],
    buttonText: 'thrive.overview.button',
  },
};
