import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  position: relative;
  min-height: 83px;
  background: #9047b7;
`;

export const StyledTextBubble = styled.div`
  position: absolute;
  right: 40px;
  top: 22px;
  width: 170px;
  padding: 10px 0 9px 0;
  border-radius: 18px;
  background: #fff;
  color: #0f0f0f;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;

export const StyledMyToolsImage = styled.img`
  position: absolute;
  left: 24px;
  top: 15px;
  height: 53px;
  width: 53px;
`;
