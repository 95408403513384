import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import CodeField from 'Onboard_Components/Account/Otp/CodeField';
import { InputProps } from 'Stories/components/Input';
import Button from 'Stories/components/Button';
import { useIntl } from 'react-intl';
import { blue } from 'Styles/colors';

function OtpForm({ control, loading, formComplete, onSubmit, otpVerificationError, onResend }) {
  const intl = useIntl();
  return (
    <form onSubmit={onSubmit}>
      <Controller
        name="otp"
        control={control}
        defaultValue=""
        rules={{
          minLength: {
            value: 6,
            message: intl.formatMessage({ id: 'error.incorrect-code' }),
          },
        }}
        render={({ field: { value, onChange } }) => (
          <CodeField
            id="otp"
            onChange={onChange}
            value={value}
            type={InputProps.type.TEL}
            codeLength={6}
            helperText={otpVerificationError}
          />
        )}
      />
      <Button id="resendButton" variant={Button.variant.TEXT} sx={{ color: blue._800 }} onClick={onResend}>
        {intl.formatMessage({ id: 'account.otp.resend' })}
      </Button>
      <Button
        id="continue"
        fullWidth
        disabled={!formComplete}
        loading={loading}
        type="submit"
        sx={{ margin: '15px 0' }}
      >
        {intl.formatMessage({ id: 'common.continue' })}
      </Button>
    </form>
  );
}

OtpForm.propTypes = {
  control: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  formComplete: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  otpVerificationError: PropTypes.string,
  onResend: PropTypes.func.isRequired,
};

export default OtpForm;
