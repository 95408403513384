import { useState, useEffect } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Core_Helpers/getAuthClient';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectLanguage } from 'Core_Redux/user';
import urlcat from 'urlcat';

const reminderUrl = 'v5/reminder';
const options = {
  revalidateOnFocus: false,
};

const getReminder = async (url, language) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url, {
      params: {
        localeid: language,
      },
    });
  }
};

const useReminder = (swrOptions) => {
  const language = useCoreSelector(selectLanguage);
  const { data: reminderResult, error } = useSWR(
    () => urlcat(`${process.env.API_BASE_URL}/${reminderUrl}`, { language }),
    getReminder,
    {
      ...options,
      ...swrOptions,
    },
  );
  const [reminder, setReminder] = useState();
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    if (reminderResult && reminderResult.data) {
      setReminder({
        ...reminderResult.data,
        text: reminderResult.data.text,
        providerResource: reminderResult.data.providerResource,
      });
    }
    setIsPending(!reminderResult);
  }, [reminderResult]);

  return { reminder, isPending, error };
};

export default useReminder;
