import { SIGNUP, LOGIN } from 'Onboard_Pages/Routes/RoutesConfig';
import { signupStatuses } from 'Onboard_Redux/signup';

export const getAuthRedirect = ({
  isAuthenticated,
  userInfo,
  syncUser,
  userVerified,
  shouldRedirectToAgreements,
  currentPath,
  signupStatus,
  initialSignupStep,
  currentSignupStep,
}) => {
  if (!isAuthenticated) {
    return getNewRoute(SIGNUP, currentPath);
  }

  if (!userInfo || (!syncUser && userVerified)) {
    return null;
  }

  const isOnboardingRoute = currentPath === SIGNUP || currentPath === LOGIN;
  const unsignedAgreements = shouldRedirectToAgreements && !isOnboardingRoute;
  const userOnboarded = userVerified && userInfo?.sub && syncUser?.activeProfileId;
  const signupFinished = signupStatus === signupStatuses.COMPLETED || initialSignupStep === currentSignupStep;

  let nextRoute = null;

  if ((!userOnboarded || !signupFinished) && !unsignedAgreements) {
    nextRoute = SIGNUP;
  } else if (isOnboardingRoute) {
    nextRoute = '/';
  }

  return getNewRoute(nextRoute, currentPath);
};

const getNewRoute = (path, currentPath) => {
  if (!path || currentPath === path) {
    return null;
  }

  return path;
};
