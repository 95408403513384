import React from 'react';
import { StyledCard, ActionLabel, StyledIcon } from '../styles';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { playIcon, videoPlaying } from 'Assets/img/my-tools-icons';

function VideoCard({ labelId, isActive, onClick }) {
  // TODO: after MVP, revisit translations for video titles.
  return (
    <Button onClick={onClick} fullWidth sx={{ padding: '0px' }}>
      <StyledCard elevation={3} isActive={isActive}>
        <ActionLabel fontSize={16} fontWeight={600}>
          {labelId}
        </ActionLabel>
        <StyledIcon isActive={isActive} src={isActive ? videoPlaying : playIcon} alt="" />
      </StyledCard>
    </Button>
  );
}

VideoCard.propTypes = {
  labelId: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export default VideoCard;
