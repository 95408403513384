import React from 'react';
import { ToolTiles } from './toolTile';
import { ToolTypes } from 'Core_Pages/MyTools/enumerations/enums';

function MyToolsTileGrid() {
  const Videos = ToolTiles[ToolTypes.VIDEOS];
  const Caregiving = ToolTiles[ToolTypes.CAREGIVING];
  const Guardian = ToolTiles[ToolTypes.GUARDIAN];
  const PhysicalHealth = ToolTiles[ToolTypes.PHYSICALHEALTH];
  const Mindset = ToolTiles[ToolTypes.MINDSET];
  const Relationships = ToolTiles[ToolTypes.RELATIONSHIPS];
  const BrainBoosters = ToolTiles[ToolTypes.BRAINBOOSTERS];

  return (
    <>
      <Videos />
      <Caregiving />
      <Guardian />
      <PhysicalHealth />
      <Mindset />
      <Relationships />
      <BrainBoosters />
    </>
  );
}

export default MyToolsTileGrid;
