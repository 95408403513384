import styled from '@emotion/styled';
import { Card, Typography } from '@mui/material';
import { black } from 'Styles/colors';

export const StyledCard = styled(Card)`
  width: 100%;
  height: 147px;
  position: relative;
  background-color: ${(props) => (props.isActive ? '#FF9308' : 'rgba(232, 234, 242, 0.24)')};
`;

export const ActionLabel = styled(Typography)`
  line-height: 1.2;
  padding: 5px 14px;
  width: 140px;
  text-align: left;
  color: ${black._74};

  @media screen and (max-width: 400px) {
    width: 125px;
    font-size: 14px;
  }
`;

export const ActionHeader = styled.div`
  width: 100%;
  text-align: center;
`;

export const StyledIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 64px;
  padding-right: 8px;
  margin-bottom: 8px;

  @media screen and (max-width: 400px) {
    width: 54px;
  }

  @media screen and (max-width: 360px) {
    display: none;
  }

  border-radius: ${(props) => (props.isActive ? '50%' : '0')};
`;

export const StyledPlayIcon = styled.img``;
