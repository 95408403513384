import React from 'react';
import { useIntl } from 'react-intl';
import { StyledDescription } from 'Core_Pages/Screening/Results/styles';

const Description = () => {
  const intl = useIntl();
  return (
    <StyledDescription id="results-description" fontSize={18} fontWeight={700} color="white" paddingBottom={'20px'}>
      {intl.formatMessage({ id: 'screenings.results.youth.description.recommend' })}
    </StyledDescription>
  );
};

export default Description;
