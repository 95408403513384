import React from 'react';
import loadingGif from 'Assets/img/loading-icon.gif';
import { useIntl } from 'react-intl';

const Spinner = () => {
  const intl = useIntl();
  return (
    <img
      alt={intl.formatMessage({ id: 'image.loading' })}
      data-testid="Spinner"
      style={{
        width: '75px',
        margin: 'auto',
      }}
      src={loadingGif}
    />
  );
};

export default Spinner;
