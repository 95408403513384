import styled from '@emotion/styled';
import { Checkbox, FormControlLabel } from '@mui/material';
import { black } from 'Styles/colors';

export const Label = styled(FormControlLabel)`
  span {
    font-size: 16px;
    color: ${black._87};
    margin-left: 6px;
  }

  width: fit-content;
`;

export const StyledCheckbox = styled(Checkbox)`
  .MuiSvgIcon-root {
    font-size: 24px;
  }
`;
