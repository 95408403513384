import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useMediaType from 'Core_Helpers/useMediaType';
import { breakpoints } from 'Styles/theme';
import { StyledEmptyVideo, VideoContainer, VideoGrid } from './styles';
import { Typography } from '@mui/material';
import Video from 'Core_Pages/Video/Video';
import { MyVideoToolsModuleStore } from '../../../../Video/MyVideoToolsModuleStore';

function VideoView() {
  const { id } = useParams();
  const intl = useIntl();
  const mediaType = useMediaType();
  const videoStore = MyVideoToolsModuleStore.Instance().Use();
  const videosLoading = videoStore.IsLoading;

  const renderEmptyVideo = () => (
    <StyledEmptyVideo>
      <Typography fontSize={24} alignSelf={'center'} padding="20px">
        {intl.formatMessage({ id: 'tools.videos.empty-video' })}
      </Typography>
    </StyledEmptyVideo>
  );

  const VideoComponent = id ? Video : renderEmptyVideo;

  if (videosLoading || (mediaType !== breakpoints.DESKTOP && !id)) {
    return <></>;
  }

  return (
    <>
      <VideoGrid mobile={12} desktop={7}>
        <VideoContainer>
          <VideoComponent />
        </VideoContainer>
      </VideoGrid>
    </>
  );
}

export default VideoView;
