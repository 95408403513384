import { visionBoard } from 'Assets/img/my-tools-icons';

export const visionBoardEs = {
  key: 'vision-board-es',
  title: 'Mapas de visualización y tú',
  titleImage: visionBoard,
  titleImageAlt: '',
  contentId: 16,
  sections: [
    {
      key: 'vision-board-intro-es',
      hasNoSectionMargin: true,
      description:
        'A veces, cuando las palabras no expresan exactamente lo qué deseas en la vida, usar imágenes puede ser la solución, ¡e incluso ayudarte a alcanzar tus metas! Hacer un mapa de visualización es una gran manera de lograr que tus ideas y metas cobren vida. Algunas personas también los llaman mapas de “sueños” o de “inspiración”. Cualquiera que sea el nombre que te guste, es una obra de arte única en la que se integran imágenes, palabras y otras cosas de tal manera que se muestran tus deseos.\n\nLas imágenes aportan mucha información a nuestro cerebro de un solo vistazo. Así que ver tus metas y sueños en un mapa de visualización te puede recordar todas las cosas buenas que obtendrás al alcanzar tus metas, además de que también le puede ayudar a tu subconsciente a “encontrar” maneras de lograrlas. Además, ¡son divertidos de hacer!\n\nNo hay formas correctas o incorrectas de hacer un mapa de visualización. Es personal para tu yo único. Pueden ser tan sencillos o complejos como los quieras. Aunque, antes de comenzar a recopilar imágenes, tómate un momento para pensar sobre qué es importante y qué es relevante para ti. ¿Qué meta quieres lograr? ¿Qué sueño te gustaría experimentar?\n\nPor ejemplo, quizá sea convertirte en cantante o alcanzar calificaciones altas en cierta materia. Quizá sea imponértele a alguien que te molesta y sentirte fuerte. O quizá escribir un libro o crear tu propia línea de ropa. Lo que sea que te esté llamando.',
    },
    {
      key: 'vision-board-questions-es',
      description:
        '¡En seguida viene la parte jugosa! Ve un paso adelante y piensa en cómo lograr tu meta te hace SENTIRTE por dentro y por fuera. Podrías preguntarte…',
      bulletedItems: [
        { text: '¿Qué emociones sientes?' },
        { text: '¿Qué ganarás al conseguir esa meta?' },
        { text: '¿Cómo cambiará tu vida para mejor?' },
      ],
    },
    {
      key: 'vision-board-explanation-es',
      description:
        'Quizá esta sea una sensación de confianza, o bien sentir el aprecio de las personas que te importan. O quizá sea un sentimiento de paz interior, o tan solo sentirte bien contigo mismo. Quédate con lo que resulte durante un momento.\n\nDespués, dedica tiempo a encontrar imágenes que representen tu meta y hagan aflorar esos sentimientos en ti. Las imágenes que elijas te ayudarán a recordar qué es más importante para ti, o incluso a ver otras posibilidades que te ayudarán a inspirarte hacia tu deseo.',
    },
    {
      key: 'vision-board-collecting-images-es',
      header: 'Recolección de imágenes',
      description:
        'Puedes utilizar imágenes de revistas, periódicos, Internet, folletos, etiquetas, emojis, dibujos, fotos personales, etc. Cuando encuentres una imagen que te emocione, te dé energía o te hable, recórtala o imprímela y ponla a un lado. No tienes que encontrar la imagen exacta de cualquiera que sea tu meta final, y las imágenes podrían ser “cosas”, y eso está bien; piensa en el sentimiento que aflora en ti con esa “cosa”. Y podría ser que te gusten los colores o patrones de una imagen en lugar del tema en sí. El color puede ser una forma estupenda de expresar sentimientos, personalidad o energía.\n\nTambién está bien usar palabras que te hablen. Algunas ideas son “fuerte”, “valiente”, “saludable”, “amado”, “feliz”, “seguro”, etc. También considera agregar una imagen tuya con las imágenes. ¡Verte sonreír y rodeado de tus metas y sueños puede ser poderoso! Opta por lo que se sienta bien. No importa qué imágenes elijas, lo importante es que sientas una conexión con ellas.\n\nPuede que termines por tener más imágenes de las que necesitas y que parezcan de temas desordenados. ¡No pasa nada! ¡El proceso creativo está en marcha!',
    },
    {
      key: 'vision-board-putting-it-together-es',
      header: 'Cómo integrar todo',
      description:
        'Cuando estés listo para hacer tu mapa de visualización, necesitarás un material de fondo en el que poner tus imágenes, como papel de póster, cartón, corcho o incluso una pared. Sirve cualquier tamaño o forma.\n\nDespués, arregla tus imágenes sobre el mapa de manera que te parezca que está bien. Puedes superponer las imágenes, colocarlas en ángulo, esparcirlas al azar, dejar espacio entre ellas o alinearlas de manera uniforme. De nuevo, no existen maneras correctas o incorrectas de crearlo. Tómate tu tiempo, disfruta del proceso y opta por lo que te parezca correcto sin pensarlo demasiado. Cuando hayas terminado de colocar las imágenes, aplica pegamento, tachuelas o cinta adhesiva para fijarlas en su sitio.',
    },
    {
      key: 'vision-board-result-es',
      header: 'Cómo sentir el resultado',
      description:
        'Cuando hayas terminado de hacer tu mapa de visualización, colócalo en un lugar donde puedas verlo con facilidad: un escritorio, la puerta del clóset o incluso junto al televisor. Míralo al menos una o dos veces al día para sentirte inspirado y alimentar tu subconsciente. Por ejemplo, podrías mirarlo justo antes de irte a dormir y a primera hora de la mañana, y luego visualizar tu éxito como si estuviera sucediendo ahora…',
      bulletedItems: [
        { text: 'Cierra los ojos y visualízate viviendo la vida que muestran las imágenes.' },
        { text: 'Siéntete en el futuro que diseñaste.' },
        { text: 'Cree que ya es tuyo.' },
        { text: 'Lee tus palabras inspiradoras en voz alta.' },
        { text: 'Agradece lo bueno que ya está presente en tu vida.' },
        { text: 'Reconoce y aprecia los cambios que has visto y sentido.' },
      ],
    },
    {
      key: 'vision-board-exit-es',
      description:
        'Otro punto increíble de los mapas de visualización que puedes probar es pensar en ellos como algo “viviente”. Puedes hacerle actualizaciones, arrancarle cosas, añadirlas, o incluso volver a empezar. Se pueden cambiar y transformar al igual que tú y mostrarte tu progreso.\n\nSin importar qué metas y sueños sean, un mapa de visualización es una herramienta increíble. Úsalo para imaginar tus deseos, para sentir cómo se vuelven realidad y ¡para cargarte de inspiración con ellos!',
    },
  ],
};
