export const transformText = (gameData, textId) => {
  let currentMessage = `${gameData.intl.formatMessage({
    id: textId,
  })}`;

  currentMessage = currentMessage.replace(/({Profile:FirstName})/g, `${gameData.userInfo?.firstName}`);
  currentMessage = currentMessage.replace(/({Pet:FirstName})/g, `${gameData.info?.data?.gameData?.petState?.name}`);
  currentMessage = currentMessage.replace(
    /({Date:Weekday})/g,
    `${new Date()
      .toLocaleTimeString(navigator?.languages[0] ?? 'en', { weekday: 'long' })
      .split(' ')[0]
      .replace(',', '')}`,
  );

  return currentMessage;
};
