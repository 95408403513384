import styled from '@emotion/styled';

export const StyledYesNoQuestion = styled.div`
  .FormQuestion-root {
    height: 425px;
    width: 285px;
    background-color: #565c89;
    display: grid;
    grid-template-rows: min-content auto 50px;
    border-radius: 4px;
    box-shadow: 0 12px 0 -10px grey, 0 16px 0 -12px lightgrey, 0 18px 0 -13px grey, 0 22px 0 -15px lightgrey,
      0 24px 0 -16px grey;
    margin-bottom: 24px;
    padding: 12px 24px 0;
  }

  .FormQuestion-question {
    font-weight: 700;
    text-align: center;
    font-size: 24px;
  }

  [id='05c68f54-0af3-4b6d-8c08-d4fbf8aca390-question'] {
    padding: 16px 0px;
  }

  [id='be40d46d-6bc6-4fb4-a831-0015b930aa7c-question'],
  [id='3c7855c9-ad2c-4c77-846b-056742ccabc2-question'],
  [id='ae3e27e0-fea3-4983-b30a-51d9379e4d0f-question'] {
    margin: 6px 0 24px 0;
  }

  [id='7adf5691-4a1b-4607-a954-e0b2d76f555a-question'] {
    font-size: 19px;
    padding: 12px 0;
  }

  [id='4980e9c3-0900-4de5-a0c8-0a8955f441fe-question'] {
    font-size: 18px;
  }

  [id='0e8a09f8-1005-441b-88e8-638d24083e96-question'] {
    padding: 24px 14px;
  }

  .MuiFormControlLabel-root {
    margin: 0;
    padding: 0;
    width: 100%;
    justify-content: flex-start;
  }

  .MuiFormControlLabel-label {
    font-size: 18px;
    font-weight: 700;
  }

  .MuiRadio-root {
    color: white;
    padding: 6px;
    margin: 0;
  }

  .MuiRadio-root.Mui-checked {
    color: #29265e;
  }

  .MuiSvgIcon-root {
    border-radius: 50%;
    background-color: white;
    path: {
      transform: translate(-2px, -2px) scale(1.2);
    }
  }
`;
