import styled from '@emotion/styled';
import { breakpoints } from 'Styles/theme';

export const StyledCharacter = styled.img(
  ({ theme }) => `
  width: 70px;
  margin: 21px 0 0 -7px;
  position: absolute;
  left: 0;

  ${theme.breakpoints.up(breakpoints.DESKTOP)} {
    display: none;
  }
`,
);
