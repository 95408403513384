import { smallTalk } from 'Assets/img/my-tools-icons';

export const smallTalkEs = {
  key: 'small-talk',
  title: 'Charla Casual para Conectar',
  titleImage: smallTalk,
  titleImageAlt: '',
  description:
    '¡Mantener pequeñas charlas con personas que no conozcas mediante conversaciones triviales para comenzar puede ayudar a desarrollar conexiones positivas y crear sentimientos inspiradores!',
  contentId: 1,
  sections: [
    {
      key: 'stranger-conversation',
      header:
        'Selecciona un lugar o situación en la que estarías dispuesto a intentar entablar una conversación trivial con un extraño.',
      description: 'Piensa dónde te sentirías cómodo para intentar hablar con una persona que no conoces.',
      itemsHeader: 'Cuéntame más',
      itemsDescription:
        'Piensa en algunos lugares que sueles visitar donde también hay otras personas. Selecciona un lugar donde te sientas cómodo o donde te resulte fácil ser auténtico cuando estés allí. Luego, piensa en las posibles personas que podrías encontrar allí para intentar iniciar una conversación sobre temas triviales. A continuación, se presentan algunos ejemplos a tener en cuenta:',
      items: [
        { text: 'Tiendas minoristas: con un empleado u otro cliente' },
        { text: 'Restaurantes: con los meseros, encargados del bar u otra persona que esté comiendo allí' },
        {
          text: 'Áreas de la comunidad: con un vecino, en un parque para perros con el dueño de otro perro, con un padre que recoge a su hijo en la escuela, etc.',
        },
        { text: 'Centros deportivos o gimnasios: con otro espectador del juego, estudio de yoga, etc.' },
        { text: 'Eventos especiales: con asistentes a una conferencia, asistentes a una fiesta, etc.' },
        {
          text: 'En un servicio de viaje compartido o en el transporte público: con otros pasajeros en el autobús o tren',
        },
      ],
    },
    {
      key: 'stranger-write-down',
      header: 'Luego, escribe los temas de los que podrías hablar con un extraño cuando estés allí.',
      description: 'Usa lápiz y papel, o escribe tus ideas en una computadora/dispositivo.',
      itemsHeader: 'Cuéntame cómo',
      items: [
        {
          text: 'Piensa en algunas cosas fáciles de recordar para decirle a alguien que no conozcas cuando estés allí.',
        },
        { text: 'Está bien empezar con 1 o 2 cosas por ahora.' },
        {
          text: 'Por ejemplo, piensa en cosas pequeñas, obvias o divertidas que puedas notar, como',
          subItems: [
            { text: 'Carro de compras: ¿Qué vas a preparar con ese (vegetal/ingrediente)?' },
            { text: 'Vestimenta: ¿En qué evento vas a usar ese atuendo?' },
            { text: 'General: ¿Cómo ha estado tu día hasta el momento?' },
            { text: 'Clima: ¿Cómo te sientes con este clima?' },
            { text: 'Actualidad: ¿Dónde compraste el cubrebocas (COVID) que estás usando?' },
          ],
        },
        {
          text: 'Sugerencia útil: Formular preguntas abiertas ayuda a la otra persona a responder de una manera más conversacional o más larga en lugar de formular preguntas del tipo “Sí/No”.',
        },
        {
          text: 'Para ayudar a mantener la conexión, formula preguntas de seguimiento a las respuestas del extraño en lugar de mencionar un tema nuevo. Algunos ejemplos de preguntas de seguimiento a los ejemplos anteriores podrían ser:',
          subItems: [
            { text: 'Seguimiento - Carro de compras: ¿Cómo prefieres prepararlo?' },
            { text: 'Seguimiento - Vestimenta: ¿Dónde se realizará el evento?' },
            { text: 'Seguimiento - General: Es bueno escuchar cuando una persona está bien.' },
            { text: 'Seguimiento - Clima: ¡Recuerda tomar líquido!' },
            { text: 'Seguimiento - Actualidad: ¿Se siente cómoda?' },
          ],
        },
        { text: 'La conversación trivial no tiene por qué ser larga o muy inteligente.' },
        { text: '¿Qué podrías preguntar que te resulte interesante?' },
        { text: '¿De qué deseas hablar?' },
        { text: '¿Qué te gustaría que te pregunte un extraño?' },
        { text: '¿Qué deseas compartir con un extraño en relación con esa situación?' },
      ],
    },
    {
      key: 'experiment',
      header: '¡Avanza y experimenta!',
      description:
        'Considera cuándo y dónde podrías intentar entablar una conversación con un extraño en la próxima semana. ¡Establece una hora y un lugar para probarlo e inténtalo!',
    },
    {
      key: 'self-reflection-insights',
      header: 'Autorreflexión',
      description: 'Dedica un momento de tranquilidad para revisar tu experiencia con esta actividad.',
      itemsHeader: 'Percepciones y marcadores del camino',
      items: [
        {
          text: '¿Cómo te sentiste al hablar con un extraño?',
        },
        { text: '¿Cómo te sentiste inmediatamente después?' },
        { text: '¿Lo harías nuevamente?' },
        {
          text: '¿Cuáles serían algunos de los beneficios de que las personas realicen esto con más frecuencia en general?',
        },
        { text: '¿Cómo te sentías antes de completar este ejercicio?' },
        { text: '¿Cómo te sientes después de hacerlo?' },
        { text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?' },
        { text: '¿Qué disfrutaste más de este ejercicio?', divider: true },
        {
          text: 'Pasamos gran parte de nuestra vida en compañía de extraños e incluso las pequeñas interacciones pueden ser valiosas para aumentar la felicidad y el bienestar.',
        },
        {
          text: 'Hablar con una persona que no conoces puede ayudarte a crear sentimientos gratificantes y aliento a medida que avanzas en tu día.',
        },
        {
          text: 'Sentirte socialmente conectado ayuda a generar beneficios positivos para la salud y puede mejorar la calidad de las experiencias de vida.',
        },
        {
          text: '¡Esta semana, intenta entablar una conversación trivial con un extraño para aumentar tus conexiones sociales y tu tiempo en tu Camino para triunfar!',
        },
      ],
    },
  ],
};
