import React from 'react';
import { useIntl } from 'react-intl';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { closeModal, selectCurrentModal } from 'Core_Redux/reminders';
import SuccessModal from './SuccessModal';
import { DEACTIVATION_SUCCESS } from './modals';

function DeactivateReminderSuccessModal() {
  const intl = useIntl();
  const dispatch = useCoreDispatch();
  const currentModal = useCoreSelector(selectCurrentModal);

  const handleAcknowledge = () => dispatch(closeModal());

  return (
    <SuccessModal
      id={'ReminderDeactivate'}
      open={currentModal == DEACTIVATION_SUCCESS}
      header={intl.formatMessage({ id: 'reminders.modal-deactivation-success-header' })}
      content={intl.formatMessage({ id: 'reminders.modal-deactivation-body' })}
      onClose={handleAcknowledge}
    />
  );
}

export default DeactivateReminderSuccessModal;
