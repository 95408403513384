import { RRule } from 'rrule';
import { useIntl } from 'react-intl';
import React from 'react';
import { StyledRecurrenceText } from './styles';
import PropTypes from 'prop-types';

function ReminderRecurrenceText(rruleOptions) {
  const intl = useIntl();

  function determineWording(frequency, interval) {
    if (frequency && interval) {
      intl.formatMessage({ id: 'reminders.repeat-every' });
      const idDict = {
        [RRule.DAILY]: 'reminders.recurrence.day',
        [RRule.WEEKLY]: 'reminders.recurrence.week',
        [RRule.MONTHLY]: 'reminders.recurrence.month',
      };
      let id = idDict[frequency];
      let adjective = intl.formatMessage({ id: 'reminders.recurrence.adjective' });
      if (interval > 1) {
        return `${adjective} ${interval} ${intl.formatMessage({ id: `${id}.plural` })}`;
      } else {
        return `${adjective} ${intl.formatMessage({ id: `${id}.singular` })}`;
      }
    }
  }

  return (
    <StyledRecurrenceText>
      {intl.formatMessage({ id: 'reminders.occurs' })}{' '}
      {determineWording(rruleOptions?.rruleOptions?.freq, rruleOptions?.rruleOptions?.interval)}
    </StyledRecurrenceText>
  );
}

ReminderRecurrenceText.propTypes = {
  rruleOptions: PropTypes.object.isRequired,
};

export default ReminderRecurrenceText;
