import { caregiverBurnout } from 'Assets/img/my-tools-icons';

export const caregiverBurnoutEs = {
  key: 'caregiver-burnout-es',
  title: 'Agotamiento del cuidador',
  titleImage: caregiverBurnout,
  titleImageAlt: '',
  contentId: 30,
  sections: [
    {
      key: 'caregiver-burnout-intro-es',
      description:
        'Lo más probable es que, si abriste este artículo, sientas cansancio, estrés, preocupación o tal vez no tengas la certeza de que podrás seguir siendo cuidador. Es probable que te sientas agotado. Ser cuidador puede ser un trabajo ingrato que te hace sentir solitario y aislado. Aquí verás algunos signos de agotamiento para comprender mejor lo que puedes estar sintiendo y algunas formas posibles de reducir estos sentimientos.',
    },
    {
      key: 'signs-of-burnout-es',
      header: 'Signos físicos y emocionales de agotamiento',
      description:
        'Existen varios signos físicos y emocionales comunes de agotamiento. Presentamos una breve lista de algunos de los signos más comunes de agotamiento. Los clasificamos en dos categorías diferentes: signos típicos de agotamiento y cuándo saber si debes acudir a tu médico, a un profesional en salud mental u otras personas para obtener ayuda.',
      itemsHeader: 'Cuéntame más',
      items: [
        { key: 'signs-burnout', description: '<b>Signos típicos de agotamiento</b>' },
        { text: 'Cansado frecuente o fatiga' },
        { text: 'Dificultad para dormir' },
        { text: 'Sentirse enojado o frustrado con más frecuencia' },
        { text: 'Cambios en el apetito o el peso' },
        { text: 'Pérdida de interés en actividades agradables' },
        { text: 'Sentirse triste' },
        { text: 'Alejarse de familiares y amigos' },
        {
          key: 'secondary-accordion-header',
          description:
            '<b>Señales de que necesitas más ayuda</b><br/><br/>Todos los siguientes son motivos por los que deberías buscar ayuda:',
        },
        { text: 'Sientes que quieres lastimarte a ti mismo/a o a alguien más' },
        { text: 'Te sientes desesperado/a o impotente' },
        {
          text: 'Te sientes tan triste o deprimido/a que te resulta difícil levantarte de la cama o permaneces en la cama más de las ocho horas habituales.',
        },
        {
          text: 'Te sientes mareado/a, como si tu corazón se acelerara, tienes náuseas o experimentas cualquier otro síntoma físico que no desaparece después de varios días consecutivos.',
        },
      ],
    },
    {
      key: 'reasons-for-burnout-es',
      header: 'Motivos por los que puedes estar experimentando agotamiento',
      description:
        'Si tienes alguno de estos síntomas físicos o emocionales, estos son algunos de los motivos por los que podrías sentirte así.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Quizás sientas que has perdido el control de tus actividades diarias, tus pensamientos o tu capacidad para hacer las cosas que disfrutas.',
        },
        {
          text: 'Expectativas poco realistas sobre tu función: es posible que estés imaginando una relación más idílica con la persona a quien cuidas o quizás esperabas dormir más o tener más tiempo para ti.',
        },
        {
          text: 'Expectativas poco realistas de ti mismo/a: es fácil compararse con los demás y pensar que deberías hacer más o que deberías hacerlo mejor.',
        },
      ],
    },
    {
      key: 'burnout-validation-es',
      header: 'Validación',
      description:
        'Es fácil olvidarse rápidamente de tus propias necesidades cuando estás cuidando a otra persona. Estas frases de validación pueden ayudarte a recordar por qué eres un cuidador y a sentirte bien acerca de cómo ayudas a los demás.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Ser cuidador es un trabajo duro: tú es fuerte y capaz de realizar este trabajo.',
        },
        {
          text: 'Aquellos a quienes cuidas tienen suerte de tenerte.',
        },
        { text: 'Estás mejorando la vida de quienes te rodean, incluso si no siempre lo sientes así.' },
        { text: 'Eres resiliente, fuerte y tienes un corazón maravilloso.' },
      ],
    },
    {
      key: 'reduce-burnout-es',
      header: 'Cómo puedes reducir tus sentimientos de agotamiento',
      description:
        'Cuidarte a ti mismo/a te ayuda a cuidar a los demás. Estos son algunos consejos para sentirte menos agotado/a.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Tómate un descanso: puede que te resulte difícil dejar a la persona a la que estás cuidando, pero es importante. Asegúrate de contar con un excelente plan para que la cuiden, como servicios de relevo o familiares y amigos de confianza que te brinden un tiempo libre.',
        },
        {
          text: 'Practica el autocuidado, como respiración profunda, meditación, yoga u otras actividades de relajación. Consulta algunos de los demás recursos y actividades de relajación en nuestra aplicación Pyx.',
        },
        {
          text: 'Habla con un médico o proveedor de salud mental sobre cómo te sientes.',
        },
        {
          text: 'Únete a un grupo de apoyo con otros cuidadores.',
        },
        {
          text: 'Asegúrate de hacer algo por ti todos los días. Puede ser grande o pequeño, pero asegúrate de hacer algo.',
        },
      ],
    },
  ],
};
