import { socialSupport } from 'Assets/img/my-tools-icons';

export const socialSupportEn = {
  key: 'social-support',
  title: 'Social Support Network',
  titleImage: socialSupport,
  titleImageAlt: '',
  description:
    'When challenges pop up, a Social Support Network can help you keep balanced and boost feelings of well-being!',
  contentId: 2,
  sections: [
    {
      key: 'goal-write-down',
      header: 'Make a chart and write down a challenge or goal you want to achieve.',
      description: '',
      itemsHeader: 'Tell me more',
      items: [
        { text: 'Make a chart with 4 columns and a couple of rows to begin.' },
        {
          text: 'Use paper and pen or a computer/device.',
        },
        { text: 'Title the 1st column “My challenge or goal”' },
        { text: 'Title the 2nd column “Type of support I could use”' },
        { text: 'Title the 3rd column “Who”' },
        { text: 'Title the 4th column “How”' },
      ],
    },
    {
      key: 'people-identify',
      header: 'Identify the people in your Social Support Network.',
      description:
        'On a separate list, write down the names of people who you think could offer you support in these 4 areas: emotional, practical, informational, and companionship. Note: Some people may offer one or more types of support!',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Emotional support – People who let you know you are valued or offer affection, love, empathy, concern, trust, acceptance, intimacy, encouragement, or caring.',
        },
        {
          key: 'Emotional support helpful hints',
          text: 'Helpful hints: Who are the people you feel...',
          subItems: [
            { text: 'Let you be yourself?' },
            { text: 'You can talk to when you feel lonely?' },
            { text: 'Help you feel more relaxed when you feel tense?' },
            { text: 'You can count on to listen to your innermost feelings without judgement?' },
            { text: 'You can share your most private thoughts?' },
            { text: 'You can count on to care about you, regardless of what is happening?' },
            { text: 'Accept you totally, with what you think are both your best and worst parts?' },
            { text: 'Will comfort you when you need it?' },
            { text: 'Appreciate you as a person?' },
            { text: 'Are genuinely interested in you?' },
          ],
        },
        {
          text: 'Informational support – People who can provide advice, guidance, suggestions, or useful information to help you solve a problem or reach your goal.',
        },
        {
          key: 'Informational support helpful hints',
          text: 'Helpful hints: Who are the people you feel...',
          subItems: [
            { text: 'You can turn to for advice about how to reach your goals?' },
            { text: 'Have already reached the goal you want and could share tips to help you reach yours?' },
            { text: 'You can count on to give you ideas or help avoid mistakes?' },
            { text: 'You can ask for input to handle problems?' },
          ],
        },
        {
          text: 'Practical support – People who can provide more direct or practical ways to help such as financial assistance, goods, or services like giving a ride to a clinic or fixing your computer.',
        },
        {
          key: 'Practical support helpful hints',
          text: 'Helpful hints: Who are the people you feel...',
          subItems: [
            { text: 'Can help you with issues you find hard to do yourself?' },
            { text: 'You could turn to for help with practical things?' },
          ],
        },
        {
          text: 'Companionship support – People you can do social activities with or who give you a sense of belonging.',
        },
        {
          key: 'Companionship support helpful hints',
          text: 'Helpful hints: Who are the people you feel...',
          subItems: [
            { text: 'You can have fun with?' },
            { text: 'You enjoy spending time with?' },
            { text: 'You can go out and do things together?' },
            { text: 'Enjoy the same things you do?' },
            { text: 'Share an interest or passion with you?' },
          ],
        },
      ],
    },
    {
      key: 'chart-fill',
      header: 'Fill out the chart and match your Social Support Network to your challenge or goal.',
      description: '',
      itemsHeader: 'Tell me more',
      items: [
        { text: 'Column 1: Write down every part of the challenge/goal you’re working on.' },
        {
          text: 'Column 2: Mark if it is emotional, practical, informational, or companionship support you need for the part(s) listed in column 1.',
        },
        {
          text: 'Column 3: Enter the people from your Social Support Network list you could turn to for the type of support you marked in column 2.',
        },
        { text: 'Column 4: Briefly write how or why that person could help you reach your challenge/goal.' },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your experience with this activity.',
      itemsHeader: 'Insights & pointers',
      items: [
        { text: 'How can you use your Social Support Network more often?' },
        {
          text: 'What steps can you take to involve your Social Support Network more actively to achieve your challenge/goal?',
        },
        { text: 'What have you learned from this exercise?' },
        { text: 'How do you feel before completing this exercise?' },
        { text: 'How do you feel after it?' },
        { text: 'What part(s) of this exercise did you find most challenging? Why?' },
        { text: 'What did you enjoy most about this exercise?' },
        { text: 'Which aspects/elements of the challenge/goal do you want to work on first?' },
        { text: 'What would help make the first step easier?' },
        { text: 'What would be some benefits of doing this activity more often in general?', divider: true },
        {
          text: 'Your Social Support Network can be helpful to develop useful abilities and keep balanced when difficulties arise.',
        },
        {
          text: 'Family, friends, neighbors, coworkers, and acquaintances can create a Social Support Network to help meet your basic daily needs, reach larger goals and aspirations, and boost feelings of well-being!',
        },
      ],
    },
  ],
};
