import { bullies } from 'Assets/img/my-tools-icons';

export const bullyingEn = {
  key: 'bullying',
  title: 'What to do about bullies',
  titleImage: bullies,
  titleImageAlt: '',
  contentId: 18,
  sections: [
    {
      key: 'bullying-intro',
      hasNoSectionMargin: true,
      description:
        "Nobody deserves to be bullied. Everyone has the right to live in a safe environment at home, school, and work. It's not always easy to tell the difference between bullying and someone in a temporary bad mood. But bullies often pick on people over and over again, and that does not – and should not – be tolerated.\n\nHaving to deal with a bully is challenging. It’s important to remember bullying is not about you. People who bully are unhappy in their own lives. Putting others down gives them a feeling of power and control because they want their victims to feel as bad as they do inside. And they get attention.\n\nBullies usually pick on people they think are weaker than they are and thrive on getting a reaction out of them. They’ll do things to push ‘buttons’ and make others angry or upset. Bullying shows up in different ways and can be done by anyone. When their victim reacts the way they want, they feel like they're the ‘winner.’",
      bulletedItems: [
        {
          text: '<b>Verbally</b> – Taunting, teasing, insulting, etc.',
        },
        {
          text: '<b>Physically</b> – Any form of touching a person does not want can be bullying, and possibly be sexual assault like shoving, tripping, punching, or hitting',
        },
        {
          text: '<b>Psychologically</b> – Gossiping about or ignoring people to make them feel bad about themselves',
        },
        {
          text: '<b>Cyberbullying</b> – Sending mean texts, posting insults about someone else on their channels, etc.',
        },
      ],
    },
    {
      key: 'bullying-what-to-do',
      header: 'What to do',
      description:
        'Just like with other people, we can’t control the bully’s behavior, but we can learn how to respond to it. Here are some strategies to keep in mind about bullying and to help keep your self-esteem and confidence strong.\n\n• Avoid bullies\n\nBullies like to work where adults or authority figures aren\'t nearby. So, avoid unsupervised areas like hallways, bathrooms, certain lockers at school, and places where bullies hang out.\n\nIf you receive bullying messages online, immediately turn off your device. When you turn it back on, block whoever is bullying you.\n\n• Travel in groups\n\nBullies are less likely to pick on others when they’re not alone. Try to be around friends, peers, or others to be less of a target. A simple, "let’s go" can signal to your friends you’d like them to leave the situation.\n\n• Ignore and walk away\n\nIgnoring a bully’s insults and rude comments isn’t easy – especially if you’re feeling upset or other people are urging you to react. But you are never obligated to respond to someone who is bullying you. Fighting back gives bullies satisfaction because it shows them they can get to you. Simply ignoring a bully shows strength, that you’re in control of yourself, and denies them any false power over you.\n\nAvoid getting "hooked" and not show any reaction in front of the bully, and don’t bully back. Take a deep breath, pretend you can\'t hear them, and continue what you were doing. You could also look at or start talking to someone else as if nothing has happened. Walk away from the situation with confidence, standing up straight with your chin up, and go to a safe place like a classroom, office, or business where others are present.',
    },
    {
      key: 'bullying-standing-up-for-yourself',
      header: 'Standing up for yourself',
      description:
        "Remember you don’t have to confront a bully, especially if you don’t feel safe. But if you feel ready, standing up to them cuts off their power and stops them from seeing you as a target.\n\nYou might be scared but gather your courage and act confident through your body language. Stand tall and be brave – or pretend to be even if you don't feel that way. Keep your back straight and your head high without fidgeting. Face them and look them directly in the eyes with confidence. Consider practicing at home first so you can be more comfortable when the moment happens.",
    },
    {
      key: 'bullying-what-to-say',
      header: 'What to say',
      description:
        'Firmly saying a "no" shows you are not afraid of the bully and won’t take their abuse. Try to keep your statement short and avoid getting angry or sarcastic. It can help to be loud and firm to show you mean it. Some ideas are…',
      bulletedItems: [
        {
          text: '"That\'s enough. Don\'t talk like that again."',
        },
        {
          text: '"Why are you picking on me? Stop it."',
        },
        {
          text: '"What do you want? I don’t want to fight you."',
        },
        {
          text: '"I don\'t like being called that. I want you to call me by my name."',
        },
        {
          text: '"Hands off my body."',
        },
      ],
    },
    {
      key: 'bullying-stop',
      hasNoSectionMargin: true,
      description:
        "Bullies eventually give up and move on to someone else. But if they don’t seem to listen and the bullying doesn't stop, you need to walk away. It may be time to ask for help. ",
    },
    {
      key: 'bullying-reach-out',
      header: 'Reach out',
      description:
        "If you continue to struggle with bullying and feel anxious, stressed, depressed, sad, or fearful about it, reach out for additional help.\n\n• If the bully is an adult threatening or hurting you, that's abuse. Talk to someone about it immediately.\n\n• NOTE: Your Pyx Health ANDY is available to talk to. They can provide compassionate understanding to your experience and help to find resources your health care plan has available. Use the “Call ANDY” button on your Pyx Health app or call them at 1-855-499-4777.",
    },
    {
      key: 'bullying-consider-perspective',
      header: 'Consider a new perspective',
      description:
        "In the long run, a single mean bully isn't worth your time or mental energy. Once you can see the situation from that perspective, it can make it easier to maintain the focus on your own well-being. While you shouldn't change yourself just because you're being bullied, you can use this opportunity to make positive changes in your life and grow as a person. In your own way… ",
      bulletedItems: [
        {
          text: 'Acknowledge what happened and be truthful about any pain you felt.',
        },
        {
          text: 'Focus on what you’ve learned and the things you can control in your life.',
        },
        {
          text: 'Find closure for the situation and let it go so you have more room for what the future holds in store.',
        },
        {
          text: 'Practice self-care – Treat yourself kindly and make extra time to take care yourself, relax, and do things you enjoy. Listen to music, do a favorite hobby, hang out with friends, meditate, exercise, or doing some form of movement can help restore and strengthen self-esteem.',
        },
      ],
    },
    {
      key: 'bullying-you-got-this',
      header: 'You’ve got this!',
      description:
        "Remember, there is no shame in being scared by a bully, in walking away, or in telling an adult/authority figure, and asking for help. It's best to stay safe and find the nearest help.",
    },
  ],
};
