import Description from './Description';
import Header from './Header';
import { ResultVariantTypes } from 'Core_Pages/Screening/Results/Result/ResultContext';

const AdultWellBeingAssessment = {
  [ResultVariantTypes.Header]: Header,
  [ResultVariantTypes.Description]: Description,
};

export default AdultWellBeingAssessment;
