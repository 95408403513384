import { balanceSenses } from 'Assets/img/my-tools-icons';

export const balancedSensesEn = {
  key: 'balanced-senses',
  title: 'Staying balanced using your 5 senses',
  titleImage: balanceSenses,
  titleImageAlt: '',
  contentId: 24,
  sections: [
    {
      key: 'balanced-senses-intro',
      hasNoSectionMargin: true,
      description:
        'During those times when things get more challenging and anxiety starts to build, the body has ready-made ways to help keep stress down – the sensory perceptions. Try this simple countdown going through your five senses to help feel more calm, balanced, and ‘grounded’ when facing stress or upset.\n\nFirst, take a few deep breaths and look around you. Then describe the space through your sensory perceptions of sight, sound, smell, touch, and taste. Counting down from five to one, what are…',
      bulletedItems: [
        {
          text: '5 things you can see?',
        },
        {
          text: '4 things you can feel?',
        },
        {
          text: '3 things you can hear? ',
        },
        {
          text: '2 things you can smell? ',
        },
        {
          text: '1 thing you can taste? ',
        },
      ],
    },
    {
      key: 'balanced-senses-finale',
      description:
        'It’s okay to say your comments in your head or out loud. This simple technique can help block runaway thoughts and cool rising emotions. Your senses can support you wherever you are and help to redirect attention away from negative feelings and refocus it to stay strong so you can engage the issue with self-confidence.\n\nGo ahead and give this a try now! What are the things you notice? How did you feel before doing this? How do you feel after it?',
    },
  ],
};
