import { Actor } from '../../../Actor';
import { frontDoorAssetConfig } from './FrontDoorConfig';

export class FrontDoor extends Actor {
  constructor(scene, x, y, color, petType, frame) {
    let currentConfig = frontDoorAssetConfig;
    super(scene, x, y, currentConfig, currentConfig.NAME, frame);

    this.spriteName = this.ObjectData.NAME;
    this.Inventory = this.ObjectData.INVENTORY;
    this.Sounds = this.ObjectData.AUDIO;

    this.currentSpriteSheet = color;
    this.originalScale = this.ObjectData.SCALE;
  }
}
