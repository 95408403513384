import { senseOfSelf } from 'Assets/img/my-tools-icons';

export const senseOfSelfEs = {
  key: 'sense-of-self-es',
  title: 'Mantener un sentido de identidad',
  titleImage: senseOfSelf,
  titleImageAlt: '',
  contentId: 36,
  sections: [
    {
      key: 'sense-of-self-intro-es',
      description:
        'Ser cuidador puede resultar agotador tanto física como emocionalmente. Puede ser solitario. Es posible que te sientas aislado/a, literalmente, como si estuvieras en una isla mientras el mundo sigue funcionando a tu alrededor, sin ti. Como cuidador, es importante comprender y reconocer cuándo tus sentimientos y tu cuerpo te indican que es posible que necesites cuidarte mejor.',
    },
    {
      key: 'body-tell-me-es',
      header: 'Lo que mi cuerpo intenta decirme',
      description:
        '¿Te sientes cansado/a con frecuencia o te resulta difícil levantarte de la cama por la mañana? Pasar mucho tiempo en la cama, querer descansar con frecuencia, tener dificultades para dormir y cambios en el peso son signos comunes de agotamiento del cuidador. Es normal perder el sueño como cuidador, en particular, si la persona a la que cuidas necesita ayuda durante la noche o te impide dormir. Ser cuidador puede generar estrés en tu vida, lo que puede llevar a hábitos de alimentación, sueño y actividad diaria poco saludables. A medida que tu bienestar físico y emocional disminuye, estos patrones poco saludables pueden provocar afecciones médicas o de salud mental graves. Es importante mantener el sentido de identidad y ser fiel a quién eres, no solo para seguir siendo un buen cuidador, sino también para tener una mejor calidad de vida.',
    },
    {
      key: 'feeling-tell-me-es',
      header: 'What my Feelings are Trying to Tell Me',
      description:
        'Cuando eres cuidador, es normal sentirse solo, abrumado, triste, enojado o frustrado. Estás dando tanto de ti mismo/a que es posible que hayas notado que tienes menos interés en actividades que antes disfrutabas o que lloras sin saber por qué. Si alguno de estos sentimientos te suena familiar, es probable que sea el momento de escucharte y encontrar formas de apoyarte a ti mismo/a.',
    },
    {
      key: 'help-maintain-self-es',
      header: 'Cómo puedo ayudar a mantener mi sentido de identidad',
      description:
        'A menudo, los cuidadores, como tú, están dispuestos a entregarse a los demás, pero les cuesta pedir ayuda o permitir que los ayuden. Una de las mejores formas de combatir el aislamiento y la soledad es reservar algo de tiempo en tu rutina de cuidado para concentrarte en ti mismo/a, para volverte a encontrar, en medio de tu horario tan ocupado. Cuidarte a ti mismo te ayuda a cuidar mejor a los demás. Estos son algunos consejos para encontrar algo de tiempo para ti.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Tómate un descanso: puede que te resulte difícil dejar a la persona a la que estás cuidando, pero es importante. Asegúrate de contar con un excelente plan para que la cuiden, como servicios de relevo o familiares y amigos de confianza que te brinden un tiempo libre.',
        },
        {
          text: 'Encuentra suficiente tiempo para dormir. El sueño es muy importante para el bienestar físico y mental en general, y a menudo se pasa por alto. Quizás te digas a ti mismo/a que no quieres ayuda para cuidar a tu ser querido durante la noche porque puedes descansar durante el día, o consideras que puedes funcionar con menos horas de sueño. Hay opciones de apoyo disponibles para ayudarte a dormir por la noche. Haz que sea una prioridad hablar con el equipo de atención de la persona para ver qué opciones pueden ayudarte a dormir más. Si quieres ser un buen cuidador durante el día, necesitas dormir bien por la noche.',
        },
        {
          text: 'Busca a personas que puedan apoyarte. Puede ser un grupo de apoyo con otros cuidadores, un amigo o conocido, o un grupo local al que quieras unirte.',
        },
        {
          text: 'Asegúrate de hacer algo por ti todos los días. Puede ser grande o pequeño, pero asegúrate de hacer algo.',
        },
        {
          text: '¿Qué actividades te gustaban antes de ser cuidador? Haz que sea una prioridad hacer una de esas actividades cada semana. Es importante tener la intención de dedicar tiempo a ti mismo y realizar actividades que disfrutes.',
        },
        {
          text: 'Utiliza el tiempo que tienes para participar en actividades que te hagan sentir renovado/a, como dedicar un tiempo a caminar o apartar 30 minutos para sentarte y tomar tu café de la mañana. Participa en actividades que sean nuevas o que se basen en tus propios intereses (o cosas que puedan haber sido de tu interés) para sentirte conectado/a con tu sentido de identidad y ayudarte a mantenerte en contacto con tus amistades y seres queridos. Podría ser caminar o hacer senderismo con un amigo/a o unirte a un grupo local de yoga.',
        },
      ],
    },
  ],
};
