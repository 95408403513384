import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormControl } from '@mui/material';
import { StyledMenuItem, StyledSelect } from './styles';
import { ACTIVITIES, MEDICATION, SUPPLEMENT } from '../ReminderTypeConfig/reminderTypes';

function ReminderTypeSelect({ control }) {
  const intl = useIntl();

  return (
    <Controller
      name="reminderType"
      control={control}
      rules={{ required: true }}
      render={({ field: { value, onChange } }) => (
        <FormControl variant="outlined">
          <StyledSelect value={value} onChange={onChange}>
            <StyledMenuItem value={MEDICATION}>
              {intl.formatMessage({ id: 'reminders.medication-reminder' })}
            </StyledMenuItem>
            <StyledMenuItem value={SUPPLEMENT}>
              {intl.formatMessage({ id: 'reminders.supplement-reminder' })}
            </StyledMenuItem>
            <StyledMenuItem value={ACTIVITIES}>
              {intl.formatMessage({ id: 'reminders.activity-reminder' })}
            </StyledMenuItem>
          </StyledSelect>
        </FormControl>
      )}
    />
  );
}

ReminderTypeSelect.propTypes = {
  control: PropTypes.object.isRequired,
};

export default ReminderTypeSelect;
