import { Typography } from '@mui/material';
import Question from 'Stories/components/Form/Question';
import React from 'react';
import { useForm } from 'react-hook-form';
import { questions } from './config';
import { useIntl } from 'react-intl';
import { AnswerTypes } from 'Stories/components/Form/answerTypes';
import { ScreeningModuleStore } from 'Core_Pages/Screening/ScreeningModuleStore';
import { LikertContinueButton, Bookmark } from 'Core_Pages/Screening/ScreeningImplementations/styles';
import { AnswerKey, AnswerKeyWrapper, StyledLikertForm, StyledLikertWrapper } from '../styles';
import ErrorModal from 'Core_Components/ErrorModal/ErrorModal';

const answerKey = [
  'screenings.form.likert.youth-phq2.answer-not-at-all',
  'screenings.form.likert.youth-phq2.answer-several-days',
  'screenings.form.likert.youth-phq2.answer-over-half',
  'screenings.form.likert.youth-phq2.answer-nearly-daily',
];

function YouthPHQ2() {
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const screeningTakerStore = screeningStore.ScreeningForm.Use();
  const intl = useIntl();
  const { control, handleSubmit } = useForm();

  const onAnswer = (question, answer) => screeningTakerStore.updateAnswer(question, answer);
  const onComplete = () => screeningTakerStore.complete();
  const onCloseErrorModal = () => screeningStore.acknowledgeError();

  const formComplete = screeningTakerStore.IsValid;
  const isLoading = screeningTakerStore.IsLoading || screeningStore.IsLoading;
  const hasError = screeningStore.ScreeningHasError;
  const numberOfScreeningsLeft = screeningStore.DueScreenings?.length > 0 ? screeningStore.DueScreenings?.length : 1;
  const currentScreeningIndex = screeningStore.DueScreeningsIndex + 1;

  return (
    <>
      <StyledLikertWrapper fullScreen open={true} aria-labelledby="screening-title" className="safe-area">
        <Bookmark>
          {`${currentScreeningIndex} ${intl.formatMessage({ id: 'common.of' })} ${numberOfScreeningsLeft}`}
        </Bookmark>
        <div>
          <Typography
            id="screening-title"
            component="h1"
            fontSize={16}
            fontWeight={700}
            paddingBottom={'20px'}
            marginLeft={'21px'}
          >
            {intl.formatMessage({ id: 'screenings.form.likert.youth-phq2.header' })}
          </Typography>
          <AnswerKeyWrapper>
            {answerKey.map((textId, index) => (
              <AnswerKey key={index}>{intl.formatMessage({ id: textId })}</AnswerKey>
            ))}
          </AnswerKeyWrapper>
        </div>
        <StyledLikertForm onSubmit={handleSubmit(onComplete)}>
          {questions.map(({ textId, ...question }, index) => (
            <Question
              key={index}
              text={intl.formatMessage({ id: textId })}
              control={control}
              answerType={AnswerTypes.CARD}
              onChange={(e) => onAnswer(question?.name, e?.currentTarget?.name)}
              {...question}
            />
          ))}
        </StyledLikertForm>
        <LikertContinueButton loading={isLoading} fullWidth onClick={onComplete} type="submit" disabled={!formComplete}>
          {intl.formatMessage({ id: 'common.continue' })}
        </LikertContinueButton>
      </StyledLikertWrapper>
      <ErrorModal open={hasError} onClose={onCloseErrorModal} />
    </>
  );
}

export default YouthPHQ2;
