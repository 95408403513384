import { bodyPart } from '../../../../constants/bodyPart';
import { gameImportType } from '../../../../constants/gameImportType';
import { actorType } from '../../../../constants/actorType';
import { Config as movementConfig } from './objectData/movementConfig';

export const oldManAssetConfig = {
  NAME: 'OLDMAN',
  ACTORTYPE: actorType.OBJECT,
  DATA: [movementConfig],
  TYPE: gameImportType.SPRITESHEET,
  SPRITESHEET_FOLDER: 'old-man',
  SPRITESHEET_NAME: 'Old-Man-Sprite-Map-',
  CHATBUBBLECOORDINATES: { x: -0.4, y: -0.3 },
  INVENTORY: {
    [bodyPart.MOUTH]: [],
    [bodyPart.NECK]: [],
  },
  AUDIO: {},
  SCALE: 1.0,
  HEIGHT: 800,
  WIDTH: 800,
};
