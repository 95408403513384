import React, { useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import * as permissions from 'Core_Helpers/authentication/PermissionsConfig';
import Spinner from 'Core_Components/Spinner/Spinner';
import ThriveAssessment from 'Core_Pages/Thrive/ThriveAssessment/ThriveAssessment';
import ThriveAssessmentResults from 'Core_Pages/Thrive/ThriveAssessmentResults/ThriveAssessmentResults';
import Navbar from 'Core_Components/Navbar/Navbar';
import ThriveActivities from 'Core_Pages/Thrive/ThriveActivities/ThriveActivities';
import { useCoreSelector, useCoreDispatch } from 'Contexts/StoreContext';
import { selectSyncUser, selectSyncUserPending } from 'Core_Redux/user';
import SecureRoute from 'Core_Pages/Routes/SecureRoute';
import { useIntl } from 'react-intl';
import ThriveCongratulations from 'Core_Pages/Thrive/ThriveCongratulations/ThriveCongratulations';
import { selectThrive, initializeThriveWorkflow, resetThriveInitialization } from 'Core_Redux/thrive';
import ThriveContent from './ThriveContent/ThriveContent';
import { thriveWorkflowTypes } from './ThriveConfig/enums';
import { getThriveConfig } from './ThriveConfig/thriveConfig';

function Thrive() {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useCoreDispatch();
  const { userId } = useCoreSelector(selectSyncUser);
  const syncUserIsPending = useCoreSelector(selectSyncUserPending);
  const { isLoading: initiateThriveIsLoading, isLoadingInitializeThrive, ...thrive } = useCoreSelector(selectThrive);
  const thriveConfig = getThriveConfig(thrive);

  useEffect(() => {
    dispatch(initializeThriveWorkflow());

    return () => {
      dispatch(resetThriveInitialization());
    };
  }, []);

  useEffect(() => {
    if (!initiateThriveIsLoading && !isLoadingInitializeThrive) {
      const steps = thriveConfig?.program?.workflow[thrive?.workflowType]?.steps;
      if (thrive?.workflowType === thriveWorkflowTypes.DEFAULT) {
        history.replace(steps[paths.THRIVEPATHWAY]);
      }
    }
  }, [initiateThriveIsLoading, isLoadingInitializeThrive]);

  if (!userId || syncUserIsPending || initiateThriveIsLoading || isLoadingInitializeThrive) {
    return <Spinner />;
  }

  return (
    <>
      <Switch>
        <SecureRoute
          title={intl.formatMessage({ id: 'title.thrive-pathway' })}
          permissions={permissions.THRIVEASSESSMENT}
          hasAppInsights={false}
          path={paths.THRIVEACTIVITIES}
        >
          <Navbar>
            <ThriveActivities />
          </Navbar>
        </SecureRoute>
        <SecureRoute
          title={intl.formatMessage({ id: 'title.thrive-assessment' })}
          path={paths.THRIVEASSESSMENT}
          permissions={permissions.THRIVEASSESSMENT}
          hasAppInsights={false}
        >
          <ThriveAssessment />
        </SecureRoute>
        <SecureRoute
          title={intl.formatMessage({ id: 'title.thrive-assessment-results' })}
          path={paths.THRIVEASSESSMENTRESULTS}
          permissions={permissions.THRIVEASSESSMENT}
          hasAppInsights={false}
        >
          <Navbar disabled>
            <ThriveAssessmentResults />
          </Navbar>
        </SecureRoute>
        <SecureRoute
          title={intl.formatMessage({ id: 'title.thrive-pathway' })}
          path={paths.THRIVECONGRATS}
          permissions={permissions.THRIVEPATHWAY}
          hasAppInsights={false}
        >
          <Navbar disabled>
            <ThriveCongratulations />
          </Navbar>
        </SecureRoute>

        <SecureRoute
          title={intl.formatMessage({ id: 'title.thrive-pathway' })}
          path={paths.THRIVECONTENT}
          hasAppInsights={false}
        >
          <Navbar disabled>
            <ThriveContent />
          </Navbar>
        </SecureRoute>
      </Switch>
    </>
  );
}

export default Thrive;
