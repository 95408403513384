import { Singleton } from 'Helpers/Singleton';
import { IStateStore } from 'Helpers/IStateStore';
import { Deploy } from 'cordova-plugin-ionic';
import { atom, getDefaultStore, useAtom } from 'jotai';
import { appInsights } from 'Helpers/AppInsights';
import { LOCAL, isEnvironment } from 'Core_Helpers/isEnvironment';

export class UpdateAppPromptModuleStore extends Singleton implements IStateStore {
  private atomStore = getDefaultStore();
  private isOpenAtom;
  private isLiveUpdateAvaliableAtom;
  private hasBeenShown = false;

  constructor() {
    super();

    this.isOpenAtom = atom(false);
    this.isLiveUpdateAvaliableAtom = atom(false);
  }

  public Use = () => {
    useAtom(this.isOpenAtom);

    return this;
  };

  public get IsOpen(): boolean {
    return this.atomStore.get(this.isOpenAtom);
  }

  public get IsLiveUpdateAvaliable(): boolean {
    return this.atomStore.get(this.isLiveUpdateAvaliableAtom);
  }

  public acknowledgeAppUpdate = async () => {
    this.atomStore.set(this.isOpenAtom, false);
    await Deploy.reloadApp();
  };

  public checkForLiveUpdate = async () => {
    if (!isEnvironment(LOCAL)) {
      const update = await Deploy.checkForUpdate();
      if (update.available) {
        try {
          await Deploy.downloadUpdate();
          await Deploy.extractUpdate();
          this.atomStore.set(this.isLiveUpdateAvaliableAtom, true);
        } catch {
          appInsights.trackTrace({ message: 'Diagnostic: Live Update download or extract exception.' });
        }
      }
    }
    this.atomStore.set(this.isOpenAtom, false);
  };

  public readyForDisplay = () => {
    return !this.hasBeenShown && this.IsLiveUpdateAvaliable;
  };

  public close() {
    this.atomStore.set(this.isOpenAtom, false);
  }

  public open() {
    this.atomStore.set(this.isOpenAtom, true);
    this.hasBeenShown = true;
  }
}
