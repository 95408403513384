import styled from '@emotion/styled';
import { StyledContainer, StyledParagraph } from 'Core_Pages/Pets/featureStyles';

export const StyledPetsWelcomeContainer = styled(StyledContainer)`
  overflow-x: hidden !important;
`;

export const StyledWelcomeMessage = styled(StyledParagraph)`
  width: 220px;
  margin-right: auto;
  margin-left: auto;
`;

export const StyledCatImage = styled.img`
  width: 300px;
  height: auto;
  margin-left: auto;
  margin-right: -40px;
`;

export const StyledDogImage = styled.img`
  width: 300px;
  height: auto;
  margin-right: auto;
  margin-top: -55px;
`;
