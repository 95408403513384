import { buildingSupportSystem } from 'Assets/img/my-tools-icons';

export const buildingSupportSystemGuardianEs = {
  key: 'building-support-system-guardian-es',
  title: 'Creación de un sistema de apoyo',
  titleImage: buildingSupportSystem,
  titleImageAlt: '',
  contentId: 42,
  sections: [
    {
      key: 'building-support-system-intro-es',
      description:
        'Es normal y natural sentirse solo/a, solitario/a o aislado/a como padre/madre de crianza o cuidador. A continuación verás algunos consejos que considerar al crear tu sistema de apoyo.',
    },
    {
      key: 'find-other-caregivers-es',
      header: 'Busca a otros padres de crianza o cuidadores',
      description:
        'Encontrar a otras personas con situaciones similares puede ser útil para conversar y apoyarse mutuamente frente desafíos comunes.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Hablar sobre tus alegrías y desafíos como padre/madre de crianza o cuidador/a en un espacio compartido con otras personas que están haciendo lo mismo puede brindar validación y apoyo.',
        },
        {
          text: 'Puede ser valioso encontrar a un padre/madre de crianza experimentado con quien hablar sobre sus experiencias, lo que es normal y lo que no.',
        },
        {
          text: 'Una vez que te conviertes en un padre/madre de crianza experimentado, aconsejar a otras personas que recién están comenzando en este camino podría resultar reconfortante para ti y beneficioso para ellos.',
        },
        {
          text: 'Existen grupos de apoyo para padres de crianza dirigidos por profesionales de la salud mental: comunícate con tu agencia de crianza para averiguar si tienen algo que ofrecer.',
        },
      ],
    },
    {
      key: 'find-empathetic-listeners-es',
      header: 'Encuentra a personas que sean oyentes empáticos',
      description:
        'Es útil encontrar a otras personas que no tengan los mismos problemas que tú, pero que sean sensibles a ellos.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Pueden brindarte apoyo con la energía, la paciencia y la empatía que otras personas que están en un hogar de crianza similar tal vez no tengan la capacidad de dar.',
        },
        {
          text: 'Si tienes dificultades para encontrar a un oyente empático, contacta a un terapeuta de salud mental para que te brinde un espacio seguro donde poder expresar tus emociones. Tu médico o proveedor de seguro médico es el mejor punto de partida para encontrar a un excelente terapeuta de salud mental y además contamos con recursos en nuestra aplicación.',
        },
      ],
    },
    {
      key: 'appreciation-support-es',
      header: 'Expresa agradecimiento por tu apoyo',
      description: 'Una vez que encuentres tu sistema de apoyo, expresa lo importantes que son estas personas para ti.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Si sientes que alguien te está apoyando, házselo saber con frecuencia.',
        },
        {
          text: 'Cuéntale la importancia que tiene en tu vida y las formas en que te ayuda.',
        },
        {
          text: 'Esto ayuda a dejarle saber que no solo ha sido importante para ti en el pasado, sino que también es una parte fundamental de tu sistema de apoyo futuro.',
        },
      ],
    },
    {
      key: 'toss-ego-take-help-es',
      header: 'Deja de lado tu ego y acepta la ayuda',
      description:
        'Ya sea que lo expresen o no, muchas personas que no son padres de crianza o parte del sistema de crianza sienten una gran empatía y respeto por ti como cuidador/a.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Si alguien se ofrece a ayudar, ¡acepta!',
        },
        {
          text: 'Deja que te preparen la cena.',
        },
        {
          text: 'Deja que te ayuden a limpiar tu casa o a cortar el césped.',
        },
        {
          text: 'Deja que se ofrezcan a comprar ropa u objetos para el niño o la niña de crianza.',
        },
        {
          text: 'Hazles saber lo que necesitas o lo que te resulta más útil.',
        },
      ],
    },
  ],
};
