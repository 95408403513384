import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './api/useAxiosSubmit/useAxiosSubmit';

const useCreatePetInteraction = () => {
  const [petRequest, setPetRequest] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored } = useAxiosSubmit(url, petRequest, 0, 1);

  const createPetInteraction = async (userId, request) => {
    setUrl(`${process.env.API_BASE_URL}/v1/pets/${userId}/Interaction`);
    setPetRequest(request);
  };

  return {
    petInteractionInformation: response,
    petInteractionError: hasErrored,
    petInteractionIsPending: isLoading,
    createPetInteraction,
  };
};

export default useCreatePetInteraction;
