import React, { useState } from 'react';
import useIosInstallPrompt from 'Core_Hooks/useIosInstallPrompt';
import useWebInstallPrompt from 'Core_Hooks/useWebInstallPrompt';
import IosShareIcon from 'Assets/img/ios-share-icon.svg';
import IosAddIcon from 'Assets/img/ios-homescreen-icon.svg';
import {
  InstallPromptContainer,
  CardHeaderContainer,
  CardBodyContainer,
  CardBodyContent,
  IosIcon,
  InstructionLine,
  IconContainer,
  StyledHeaderTextIconContainer,
  CloseIcon,
  AndroidCardBodyContainer,
} from './styles';
import { useIntl, FormattedMessage } from 'react-intl';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { isPlatform } from '@ionic/react';
import { CardContent } from '@mui/material';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';

const InstallPrompt = () => {
  const PROMPT_STORAGE_KEY = 'isPromptShown';
  const intl = useIntl();
  const [iosInstallPrompt] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallAccepted] = useWebInstallPrompt();
  const [show, setShow] = useState(true);

  const toggleShow = () => {
    if (iosInstallPrompt) {
      localStorage.setItem(PROMPT_STORAGE_KEY, true);
    }
    setShow(!show);
  };

  const alreadyShown = () => {
    return JSON.parse(localStorage.getItem(PROMPT_STORAGE_KEY)) || false;
  };

  if (isNative() || (!iosInstallPrompt && !webInstallPrompt) || alreadyShown() || !show) {
    return null;
  }

  function isNative() {
    return !(isPlatform('desktop') || isPlatform('mobileweb'));
  }

  return (
    <InstallPromptContainer data-testid="InstallPromptContainer" raised>
      <CardContent>
        <CardHeaderContainer>
          <ScreenReaderWrapper>
            <h2 data-testid="InstallPrompt">
              <FormattedMessage id="install-prompt.description" />
            </h2>
          </ScreenReaderWrapper>
          <StyledHeaderTextIconContainer style={{ marginRight: 'auto' }}>
            <FormattedMessage
              id={window.location.pathname === HOME ? 'install-prompt.welcome-header' : 'install-prompt.header'}
            />
            <CloseIcon
              data-testid="CloseButton"
              onClick={toggleShow}
              alt={intl.formatMessage({ id: 'button.close' })}
            />
          </StyledHeaderTextIconContainer>
        </CardHeaderContainer>
        {!webInstallPrompt && iosInstallPrompt && (
          <CardBodyContainer>
            <CardBodyContent>
              <InstructionLine>
                <IconContainer>
                  <IosIcon alt={intl.formatMessage({ id: 'image.ios.share' })} src={IosShareIcon} />
                </IconContainer>
                <FormattedMessage id="install-prompt.ios1" />
              </InstructionLine>
              <InstructionLine>
                <IconContainer>
                  <IosIcon alt={intl.formatMessage({ id: 'image.ios.add' })} src={IosAddIcon} />
                </IconContainer>
                <FormattedMessage id="install-prompt.ios2" />
              </InstructionLine>
            </CardBodyContent>
          </CardBodyContainer>
        )}
        {webInstallPrompt && (
          <AndroidCardBodyContainer>
            <button
              aria-label={intl.formatMessage({ id: 'install-prompt.add-homescreen' })}
              data-testid="AddButton"
              className="button small primary round"
              onClick={handleWebInstallAccepted}
            >
              <FormattedMessage id="common.add" />
            </button>
          </AndroidCardBodyContainer>
        )}
      </CardContent>
    </InstallPromptContainer>
  );
};

export default InstallPrompt;
