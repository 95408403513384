import styled from 'styled-components';

export const LoadingContainer = styled.div`
  margin: auto;
`;

export const Label = styled.div`
  text-align: center;
  padding-top: 15px;
  color: black;
`;
