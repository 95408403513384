import { Singleton } from 'Core_Helpers/Singleton';
import { getDefaultStore, useAtom, atom } from 'jotai';
import { IStateStore } from 'Core_Helpers/IStateStore';
import { appInsights, browserHistory } from 'Core_Helpers/AppInsights';
import { UserAccessor } from 'Core_Helpers/UserAccessor';
import { IVideoService } from './IVideoService';
import { YouthVideoService } from './VideoImplementations/Youth/YouthVideoService';
import { GeneralVideoService } from './VideoImplementations/General/GeneralVideoService';

export class MyVideoToolsModuleStore extends Singleton implements IStateStore {
  private userAccessor: UserAccessor;
  private atomStore = getDefaultStore();
  private videosAtom;
  private videoCountAtom;
  private loadingAtom;
  private videoService: IVideoService;

  constructor() {
    super();

    this.userAccessor = new UserAccessor();
    this.videosAtom = atom([]);
    this.videoCountAtom = atom(0);
    this.loadingAtom = atom(true);
  }

  public Use = () => {
    useAtom(this.videoCountAtom);
    useAtom(this.loadingAtom);
    useAtom(this.videosAtom);

    return this;
  };

  public get Videos(): Array<any> {
    return this.atomStore.get(this.videosAtom);
  }

  public get VideoCount(): number {
    return this.atomStore.get(this.videoCountAtom);
  }

  public get IsLoading(): boolean {
    return this.atomStore.get(this.loadingAtom);
  }

  private set IsLoading(isLoading: boolean) {
    this.atomStore.set(this.loadingAtom, isLoading);
  }

  public get VideoService(): IVideoService {
    if (this.videoService) return this.videoService;

    if (this.userAccessor.User.Meta?.age?.isYouth) {
      this.videoService = new YouthVideoService();
    } else {
      this.videoService = new GeneralVideoService();
    }

    return this.videoService;
  }

  public getVideoShowcase = async () => {
    this.IsLoading = true;

    const videoPlaylist = await this.VideoService.getVideos();

    this.atomStore.set(this.videosAtom, videoPlaylist);
    this.atomStore.set(this.videoCountAtom, videoPlaylist?.length);

    this.IsLoading = false;
  };

  public watchVideo = async (item: any) => {
    const loggedEvent = item.name.replaceAll(' ', '_');
    appInsights.trackEvent({ name: loggedEvent });

    browserHistory.push({ pathname: item.uri });
  };
}
