import screenType from 'Core_Pages/Andy/components/DeterminedChat/screenType';

const useGetChatScreen = () => {
  const screens = (type, userInfo) => {
    if (type === screenType.andy) {
      return screenType.andy;
    } else if (userInfo.screen === '') {
      return screenType.pyxir;
    }
  };
  return { screens };
};

export default useGetChatScreen;
