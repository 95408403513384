import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { appInsights } from 'Core_Helpers/AppInsights';
import { HOME, THRIVEPATHWAY } from 'Core_Pages/Routes/RoutesConfig';
import { getThriveConfig, getCurrentWorkflow } from 'Core_Pages/Thrive/ThriveConfig/thriveConfig';
import { logout } from './authentication';
import api from './services/api';
import { thriveStatuses, thriveWorkflowTypes } from 'Core_Pages/Thrive/ThriveConfig/enums';

export const determineThriveWorkflowAndRedirect = createAsyncThunk(
  'thrive/determineThriveWorkflowAndRedirect',
  async ({ userId, history }, thunkApi) => {
    let thrive = {};

    ({ thrive } = thunkApi.getState());
    const { thriveAccess } = thrive;

    if (thriveAccess?.thriveStatus === thriveStatuses.ACTIVE) {
      await thunkApi.dispatch(api.endpoints.getThriveProgress.initiate(userId));
      await thunkApi.dispatch(api.endpoints.getThriveAssessments.initiate(userId));
    } else if (thriveAccess?.thriveStatus === thriveStatuses.GRADUATE) {
      await thunkApi.dispatch(api.endpoints.getThriveCongrats.initiate(userId));
    }

    ({ thrive } = thunkApi.getState());
    const { workflowType, steps } = getCurrentWorkflow(thrive);

    if (workflowType !== thriveWorkflowTypes.DEFAULT) {
      const nextStepRoute = steps ? steps[THRIVEPATHWAY] : '';

      if (nextStepRoute) {
        history.push(nextStepRoute);
      }
    }
  },
);

export const nextPage = createAsyncThunk('thrive/nextPage', async ({ history }, { getState }) => {
  const { thrive } = getState();
  const { steps } = getCurrentWorkflow(thrive, thrive.workflowType);
  const nextStepRoute = steps ? steps[history.location.pathname] : HOME;
  const nextRoute = nextStepRoute ?? HOME;
  history.replace(nextRoute);
});

const initialState = {
  thriveProgress: null,
  thriveAccess: null,
  thriveAssessments: null,
  thriveAssessmentWeek: null,
  congrats: null,
  workflowState: null,
  workflowType: null,
  assessmentScore: null,
  isLoadingInitializeThrive: true,
  isLoading: true,
};

const thrive = createSlice({
  name: 'thrive',
  initialState,
  reducers: {
    completeThriveAssessment: (state, action) => {
      state.assessmentScore = action.payload;
      state.thriveAssessments = null;
    },
    updateThriveWorkflow: (state, action) => {
      state.workflowState = { ...state.workflowState, ...action.payload };
    },
    initializeThriveWorkflow: (state) => {
      state.isLoadingInitializeThrive = true;
      const thriveConfig = getThriveConfig(state);
      const workflow = thriveConfig?.program?.getWorkflowState(state);
      state.workflowType = workflow?.workflowType;
      state.workflowState = workflow?.workflowState;
      state.isLoadingInitializeThrive = false;
    },
    resetThriveInitialization: (state) => {
      state.isLoadingInitializeThrive = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getThriveAccess.matchFulfilled, (state, action) => {
      state.thriveAccess = action.payload;
    });
    builder.addMatcher(api.endpoints.getThriveAccess.matchRejected, (state, action) => {
      appInsights.trackException({ exception: action.error });
    });
    builder.addMatcher(api.endpoints.getThriveProgress.matchFulfilled, (state, action) => {
      state.thriveProgress = action.payload;
      state.thriveAssessmentWeek = state.thriveProgress?.currentThriveWeek;
    });
    builder.addMatcher(api.endpoints.getThriveProgress.matchRejected, (state, action) => {
      appInsights.trackException({ exception: action.error });
    });
    builder.addMatcher(api.endpoints.getThriveAssessments.matchFulfilled, (state, action) => {
      state.thriveAssessments = action.payload;
    });
    builder.addMatcher(api.endpoints.getThriveAssessments.matchRejected, (state, action) => {
      appInsights.trackException({ exception: action.error });
    });
    builder.addMatcher(api.endpoints.getThriveCongrats.matchFulfilled, (state, action) => {
      state.congrats = action.payload;
    });
    builder.addMatcher(api.endpoints.logThriveCongrats.matchFulfilled, (state) => {
      state.congrats = null;
    });
    builder.addMatcher(isPending(determineThriveWorkflowAndRedirect), (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isFulfilled(determineThriveWorkflowAndRedirect), (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isFulfilled(logout), () => initialState);
  },
});

export const selectThriveAssessment = (state) => ({
  assessments: state.thrive.thriveAssessments,
  assessmentScore: state.thrive.assessmentScore,
});
export const selectThriveAccess = (state) => state.thrive.thriveAccess;
export const selectThrive = (state) => ({
  thriveAssessmentWeek: state.thrive.thriveAssessmentWeek,
  thriveProgress: state.thrive.thriveProgress,
  thriveAccess: state.thrive.thriveAccess,
  thriveAssessments: state.thrive.thriveAssessments,
  workflowType: state.thrive.workflowType,
  congrats: state.thrive.congrats,
  isLoading: state.thrive.isLoading,
  isLoadingInitializeThrive: state.thrive.isLoadingInitializeThrive,
});

export const { completeThriveAssessment, updateThriveWorkflow, initializeThriveWorkflow, resetThriveInitialization } =
  thrive.actions;

export default thrive.reducer;
