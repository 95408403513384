import styled from '@emotion/styled';
import { Button, Dialog, DialogContent } from '@mui/material';
import { purple } from 'Styles/colors';

export const StyledModalContainer = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 15px;
    text-align: center;
    color: #000000;
  }
`;

export const StyledModalBodyContainer = styled(DialogContent)`
  padding: 20px;
  line-height: 23px;
  letter-spacing: 0.17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 35px;
  max-width: 360px;
`;

export const StyledHeader = styled.h2`
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  padding: 12px;
`;

export const StyledContent = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  overflow: auto;
  padding: 0 5px;
`;

export const StyledMultiButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

export const StyledButton = styled(Button)`
  align-self: center;
  justify-self: center;
  height: 50px;
  width: 100%;
  max-width: 135px;
  background: ${purple._900};
  border-radius: 15px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  :hover {
    background: #d8d8d8;
  }
`;
export const StyledCancelButton = styled(StyledButton)`
  background: white;
  color: ${purple._900};
  border: 1px solid ${purple._900};
`;
