import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Typography } from '@mui/material';
import { SymptomResult } from '../../styles';
import { useIntl } from 'react-intl';

const ResultsSymptom = ({ title, value }) => {
  const intl = useIntl();
  const labelId = value ? 'common.yes' : 'common.no';
  return (
    <>
      <SymptomResult>
        <Typography fontSize={16} fontWeight={700}>
          {title}:
        </Typography>
      </SymptomResult>
      <Chip label={intl.formatMessage({ id: labelId })} color={value ? 'error' : 'success'} />
    </>
  );
};

ResultsSymptom.propTypes = {
  title: PropTypes.string,
  value: PropTypes.bool,
};

export default ResultsSymptom;
