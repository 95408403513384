import { HOME } from 'Core_Pages/Routes/RoutesConfig';
import { signupStatuses } from 'Core_Redux/signup';

export const getAuthRedirect = ({
  isAuthenticated,
  userInfo,
  syncUser,
  userVerified,
  shouldRedirectToAgreements,
  currentPath,
  signupStatus,
  initialSignupStep,
  currentSignupStep,
}) => {
  if (!isAuthenticated) {
    return getNewRoute('/account/signup', currentPath);
  }

  if (!userInfo || (!syncUser && userVerified)) {
    return null;
  }

  const isOnboardingRoute = currentPath === '/account/signup' || currentPath === '/account/login';
  const unsignedAgreements = shouldRedirectToAgreements && !isOnboardingRoute;
  const userOnboarded = userVerified && userInfo?.sub && syncUser?.activeProfileId;
  const signupFinished = signupStatus === signupStatuses.COMPLETED || initialSignupStep === currentSignupStep;

  let nextRoute = null;

  if ((!userOnboarded || !signupFinished) && !unsignedAgreements) {
    nextRoute = '/account/signup';
  } else if (isOnboardingRoute) {
    nextRoute = HOME;
  }

  return getNewRoute(nextRoute, currentPath);
};

const getNewRoute = (path, currentPath) => {
  if (!path || currentPath === path) {
    return null;
  }

  return path;
};
