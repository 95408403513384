import styled from 'styled-components';

export const StyledGoals = styled.div`
  ul {
    padding-left: 25px;
  }
`;

export const StyledBoldParagraph = styled.p`
  font-weight: 700;
`;

export const StyledParagraph = styled.p`
  margin-bottom: 10px;
`;
