import styled from '@emotion/styled';
import { Badge } from '@mui/material';

export const StyledBadge = styled(Badge)`
  display: flex;
  flex-flow: column;
  padding-right: 0px;
  padding-left: 10px;

  img {
    width: 70px;
    height: 70px;
  }

  .MuiTypography-root {
    color: black;
    width: 40px;
    text-align: center;
    word-break: keep-all;
    white-space: pre-wrap;
    display: table;
    align-self: center;
  }
`;
