import React from 'react';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import { ArrowBackIos } from '@mui/icons-material';
import Button from 'Stories/components/Button';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { Header, StyledTextInput } from 'Onboard_Components/Account/styles';
import {
  goToMatchingEmailEntry,
  completeEmailEntry,
  skipEmailEntry,
  navigateBack,
  selectSignupMetadata,
  selectContact,
  selectName,
  selectCredentials,
  phoneTypes,
} from 'Onboard_Redux/signup';
import { useCreateAccountMutation, useUpdateAccountMutation } from 'Onboard_Redux/services/identity';
import { selectLanguage, selectUserInfo } from 'Onboard_Redux/user';
import SkipButton from 'Onboard_Components/Account/SignUp/SkipButton';
import hasValidationErrorForProperty from '../hasValidationErrorForProperty';

function Email() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const { progress } = useOnboardSelector(selectSignupMetadata);
  const { firstName, lastName } = useOnboardSelector(selectName);
  const { phoneType, phoneNumber, landlinePhoneNumber } = useOnboardSelector(selectContact);
  const { username, password } = useOnboardSelector(selectCredentials);
  const language = useOnboardSelector(selectLanguage);
  const [createAccount, { isLoading: isCreatingAccount }] = useCreateAccountMutation();
  const [updateAccount, { isLoading: isUpdatingAccount, error: updateError }] = useUpdateAccountMutation();
  const user = useOnboardSelector(selectUserInfo);
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm();

  function onBack() {
    dispatch(navigateBack());
  }

  async function onSkip() {
    dispatch(skipEmailEntry());
  }

  const updateAccountInvalidEmail = updateError?.data?.errors.find((err) =>
    err.errorMessage?.includes('Email is invalid.'),
  )
    ? intl.formatMessage({ id: 'error.account-exists' })
    : null;

  async function onContinue(data) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      if (!username || user?.sub) {
        dispatch(completeEmailEntry(data));
        await updateAccount({
          phoneNumber,
          landlinePhoneNumber,
          emailAddress: data.emailAddress,
          userId: user?.sub,
        }).unwrap();
      } else {
        await createAccount({
          firstName,
          lastName,
          username,
          password,
          landlinePhoneNumber,
          emailAddress: data.emailAddress,
          locale: language,
          timeZoneId: timeZone,
        })
          .unwrap()
          .catch((errors) => {
            if (hasValidationErrorForProperty(errors, 'EmailAddress')) {
              dispatch(goToMatchingEmailEntry());
              return Promise.reject();
            }
          });
      }
      dispatch(completeEmailEntry(data));
    } catch (e) {
      // error handled in mutation hook
    }
  }

  const isLandline = phoneType === phoneTypes.LANDLINE;
  const formComplete = dirtyFields.emailAddress;

  const isLoading = isCreatingAccount || isUpdatingAccount;

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <Header>
        <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />
        {intl.formatMessage({ id: isLandline ? 'account.sign-up.verify-email' : 'account.sign-up.add-email' })}
      </Header>
      <form onSubmit={handleSubmit(onContinue)}>
        <Controller
          name="emailAddress"
          control={control}
          rules={{
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: intl.formatMessage({ id: 'error.invalid-input' }),
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <StyledTextInput
              {...field}
              id="emailAddressInput"
              errorMessage={updateAccountInvalidEmail || errors?.emailAddress?.message}
              label={intl.formatMessage({ id: 'account.sign-up.email-address' })}
            />
          )}
        />
        <Button id="continue" fullWidth disabled={!formComplete} loading={isLoading} type="submit">
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
      {!isLandline && (
        <SkipButton disabled={isLoading} onClick={onSkip}>
          {intl.formatMessage({ id: 'common.skip' })}
        </SkipButton>
      )}
    </>
  );
}

export default Email;
