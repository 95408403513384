import { appInsights } from 'Core_Helpers/AppInsights';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { convertStringToUpperCamelCase } from 'Helpers/StringHelper';
import { MyVideoToolsModuleStore } from './MyVideoToolsModuleStore';

function Video() {
  const { id } = useParams();

  useEffect(() => {
    const videoStore = MyVideoToolsModuleStore.Instance();
    const videoName = videoStore.Videos.find((v) => v.id === id).name;
    const contentKey = convertStringToUpperCamelCase(videoName);

    appInsights.trackEvent({ name: `MyToolsVideos_Enter_${contentKey}_video` });
    // use IIFE to bind keys to scope within callback
    return (
      () => () =>
        appInsights.trackEvent({ name: `MyToolsVideos_Leave_${contentKey}_video` })
    )();
  }, [id]);

  return (
    <>
      <iframe
        src={`https://player.vimeo.com/video/${id}`}
        height="100%"
        width="100%"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture;"
        title={id}
      ></iframe>
    </>
  );
}

export default Video;
