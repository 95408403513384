import { Actor } from '../../../Actor';
import { nutrientContainerAssetConfig } from './NutrientContainerConfig';

export class NutrientContainer extends Actor {
  constructor(scene, x, y, color, petType, frame) {
    let currentNutrientContainerConfig = nutrientContainerAssetConfig;
    currentNutrientContainerConfig.DATA = currentNutrientContainerConfig.DATA.filter((x) => x.PETTYPE == petType);
    super(scene, x, y, currentNutrientContainerConfig, currentNutrientContainerConfig.NAME, frame);

    this.spriteName = this.ObjectData.NAME;
    this.Inventory = this.ObjectData.INVENTORY;
    this.Sounds = this.ObjectData.AUDIO;

    this.currentSpriteSheet = color;
    this.originalScale = this.ObjectData.SCALE;
  }
}
