import React from 'react';
import { useIntl } from 'react-intl';
import { LOGIN } from 'Onboard_Pages/Routes/RoutesConfig';
import { useOnboardSelector, useOnboardDispatch } from 'Contexts/StoreContext';
import {
  navigateBack,
  selectSignupMetadata,
  selectName,
  resetSignup,
  selectContact,
  selectCredentials,
} from 'Onboard_Redux/signup';
import { selectLanguage } from 'Onboard_Redux/user';
import { ArrowBackIos } from '@mui/icons-material';
import Button from 'Stories/components/Button';
import { Header } from 'Onboard_Components/Account/styles';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { black } from 'Styles/colors';
import { useCreateAccountMutation } from 'Onboard_Redux/services/identity';

function MatchingProfile() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useOnboardDispatch();
  const { phoneNumber, landlinePhoneNumber } = useOnboardSelector(selectContact);
  const { username, password } = useOnboardSelector(selectCredentials);
  const language = useOnboardSelector(selectLanguage);
  const { progress } = useOnboardSelector(selectSignupMetadata);
  const { firstName, lastName } = useOnboardSelector(selectName);
  const [createAccount, { isLoading: creatingAccount }] = useCreateAccountMutation();

  async function onContinue() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    await createAccount({
      firstName,
      lastName,
      username,
      password,
      phoneNumber,
      landlinePhoneNumber,
      locale: language,
      timeZoneId: timeZone,
    }).unwrap();
  }

  function onBack() {
    dispatch(navigateBack());
  }

  function onClickLogin() {
    dispatch(resetSignup());
    history.push(LOGIN);
  }

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <Header>
        <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />
        {intl.formatMessage({ id: 'account.sign-up.matching-profile' })}
      </Header>
      <Typography textAlign={'center'} fontSize={16} sx={{ width: '90%', alignSelf: 'center' }}>
        {intl.formatMessage({ id: 'account.sign-up.matching-description' })}
      </Typography>
      <Typography textAlign={'center'} sx={{ margin: '24px 0px' }}>
        {`${firstName} ${lastName[0]}.`}
      </Typography>
      {!creatingAccount ? (
        <Button fullWidth onClick={onClickLogin} type="submit">
          {intl.formatMessage({ id: 'account.log-in' })}
        </Button>
      ) : (
        ''
      )}
      <Button
        fullWidth
        loading={creatingAccount}
        onClick={onContinue}
        variant={Button.variant.TEXT}
        sx={{ marginTop: '14px', fontSize: '16px', color: black._100 }}
      >
        {intl.formatMessage({ id: 'account.sign-up.matching-profile-continue' })}
      </Button>
    </>
  );
}

export default MatchingProfile;
