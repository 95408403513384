import { fosterTips } from 'Assets/img/my-tools-icons';

export const fosterParentTipsEn = {
  key: 'foster-parenting-tips',
  title: 'Foster Parenting Tips',
  titleImage: fosterTips,
  titleImageAlt: '',
  contentId: 47,
  sections: [
    {
      key: 'foster-parenting-tips-intro',
      description:
        'Chances are you have already received at least some training and tips about how to be a great foster parent. We developed a few tips and thoughts to read through when you are feeling lonely, stuck, or having a difficult time relating to your child or children.',
    },
    {
      key: 'remove-expectations',
      header: 'Remove Your Expectations',
      description:
        'Each foster child has different needs and emotional abilities. That means that how you parent and react to one child may not work for another child. This can be especially important to remember when you have multiple foster children transitioning in and out of your home. Here are some things to tell yourself when you feel like you have expectations that are not being met:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Take a deep breath',
        },
        {
          text: 'You are a great parent or caregiver',
        },
        {
          text: 'You have the ability to communicate with and relate to your child',
        },
        {
          text: 'Let go of your ideas about what this “should” look like',
        },
      ],
    },
    {
      key: 'learn-trauma',
      header: 'Learn About Trauma',
      description:
        'Why is it important to learn about trauma? Trauma comes in very different shapes and sizes. The instability that a foster child feels can induce a trauma response. Here are some things to consider about trauma:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Separation from a biological parent and transitioning to and from placements is traumatic',
        },
        {
          text: 'Many events or circumstances in your child’s life prior to or during the time that they are with you can be trauma',
        },
        {
          text: 'Viewing your child through a “trauma lens” can help you to better understand your child – some of their anger, outbursts, frustration, sadness, or loneliness can be an expression of trauma',
        },
        {
          text: 'Your foster agency can provide you with materials or classes that can help you learn about trauma',
        },
        {
          text: 'Check out some of our other resources that can help you identify and find supports for your child if you feel they need help with their trauma',
        },
      ],
    },
    {
      key: 'treat-yourself',
      header: 'Care for Yourself',
      description:
        'Caring for yourself helps you to care for others. You will be a better caregiver and parent when your needs are met and you take time for yourself.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'How you practice great self-care can vary day-to-day and week-to-week',
        },
        {
          text: 'Listen to what your body and your mind need',
        },
        {
          text: 'Sometimes self-care only takes a few minutes of your time, while sometimes it may take a few hours away to feel recharged and refreshed',
        },
      ],
    },
    {
      key: 'cultural-needs',
      header: 'Embrace Your Child’s Cultural Needs ',
      description:
        'Every child that walks through your door will have different cultural needs – such as their race, norms from their previous homes, how they identify spiritually, sexual orientation, parts of the country or the world where they have lived, or even holidays and how they celebrate them.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Take time to get to know all these pieces of your child',
        },
        {
          text: 'Ask lots of questions about what they like and what is important to them',
        },
        {
          text: 'Use these as an opportunity to build a support system for both you and your child',
        },
      ],
    },
  ],
};
