import React from 'react';
import cat from 'Assets/img/adoption-cat-666.gif';
import dog from 'Assets/img/adoption-dog-666.gif';
import PageHeader from 'Core_Components/PageHeader/PageHeader';
import {
  StyledCatImage,
  StyledDogImage,
  StyledPetsWelcomeContainer,
  StyledWelcomeMessage,
} from 'Core_Pages/Pets/AdoptionCenter/PetsWelcome/styles';
import * as paths from 'Core_Pages/Routes/RoutesConfig';

import { useHistory } from 'react-router-dom';
import { StyledButton } from 'Core_Pages/Pets/featureStyles';
import { useIntl } from 'react-intl';

function PetsWelcome() {
  const history = useHistory();
  const intl = useIntl();

  return (
    <StyledPetsWelcomeContainer>
      <PageHeader>{intl.formatMessage({ id: 'pets.welcome.header' })}</PageHeader>
      <StyledWelcomeMessage>{intl.formatMessage({ id: 'pets.welcome.paragraph' })}</StyledWelcomeMessage>
      <StyledCatImage alt={intl.formatMessage({ id: 'pets.welcome.cat' })} src={cat} />
      <StyledDogImage alt={intl.formatMessage({ id: 'pets.welcome.dog' })} src={dog} />
      <StyledButton onClick={() => history.push(paths.ADOPTIONCENTER)}>
        {intl.formatMessage({ id: 'common.lets-go' })}
      </StyledButton>
    </StyledPetsWelcomeContainer>
  );
}

export default PetsWelcome;
