import styled from '@emotion/styled';
import { Select } from '@mui/material';
import Button from 'Stories/components/Button/Button';
import { black, legacyBackgroundColors } from 'Styles/colors';

export const StyledForm = styled.form`
  display: grid;
  gap: 12px;
  width: 100%;
  max-width: 480px;
  // ignore padding at bottom of container to account for not having bell icon
  margin-bottom: -96px;

  & .MuiFormControl-root {
    width: 100%;

    :last-child {
      margin: 0;
    }
  }

  // field border
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${legacyBackgroundColors.indigo};
  }

  & .MuiInputBase-root {
    font-size: 16px;
    font-weight: 700;
    border-radius: 15px;
    border-color: ${legacyBackgroundColors.indigo};
    height: 40px;
    color: ${legacyBackgroundColors.indigo};

    input::placeholder {
      opacity: 1;
    }
  }

  & .MuiFormLabel-root {
    transform: translate(14px, 13px) scale(1);
  }

  & .MuiTextField-root {
    margin-bottom: 18px;
  }

  & .MuiFormHelperText-root {
    // helper text as error message
    color: #c92213;
  }
`;

export const StyledSelect = styled(Select)`
  margin: 10px 0 20px 75px;
`;

export const StyledButtonsContainer = styled.div`
  margin: 24px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 12px;
`;

export const StyledDiscardButton = styled(Button)`
  background: #ffffff;
  color: ${black._100};
  font-weight: 400;
  font-size: 16px;
`;

export const StyledSaveButton = styled(Button)`
  font-size: 18px;

  &.MuiButton-root:disabled {
    box-shadow: 0px 1px 1px #00000080;
  }
`;
