import { SecureStorageEcho } from '@ionic-native/secure-storage-echo';
import { appInsights } from 'Core_Helpers/AppInsights';

export class IonicSecureStorage {
  constructor(storeName) {
    this.storeName = storeName;
  }

  async getItem(name) {
    if (!this.storage) {
      this.storage = await SecureStorageEcho.create(this.storeName);
    }

    // returns an error if it doesn't find it
    return this.storage
      .get(name)
      .then((data) => data)
      .catch(() => {
        appInsights.trackTrace({ message: `Diagnostic: Unable to get ${name} from secure storage` });
      });
  }

  async removeItem(name) {
    if (!this.storage) {
      this.storage = await SecureStorageEcho.create(this.storeName);
    }
    return this.storage.remove(name).catch(() => {
      appInsights.trackTrace({ message: `Diagnostic: Unable to remove ${name} from secure storage` });
    });
  }

  async clear() {
    if (!this.storage) {
      this.storage = await SecureStorageEcho.create(this.storeName);
    }
    return this.storage.clear().catch(() => {
      appInsights.trackTrace({ message: 'Diagnostic: Unable to clear secure storage' });
    });
  }

  async setItem(name, value) {
    if (!this.storage) {
      this.storage = await SecureStorageEcho.create(this.storeName);
    }
    return this.storage.set(name, value).catch(() => {
      appInsights.trackTrace({ message: `Diagnostic: Unable to set ${name} to ${value} in secure storage` });
    });
  }

  static async checkAvailability(storeName) {
    return SecureStorageEcho.create(storeName).catch(() => false);
  }
}
