export const NONE = 0;
export const LOCATION_ALERT_FOLLOW_UP = 1;
export const SDOH_FOLLOW_UP = 2;
export const SDOH_RESOURCE = 3;
export const FOOD_FOLLOW_UP = 4;

export const actionTypes = {
  0: 'None',
  1: 'LocationAlertFollowUp',
  2: 'SDOHFollowUp',
  3: 'SDOHResource',
  4: 'FoodFollowUp',
};
