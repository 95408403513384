import { Asset } from '../Engine/Asset/Asset';
import { Engine } from '../Engine/Engine';
import { Fake3DPhysicsWorld } from '../Engine/Physics/Fake3DPhysicsWorld/Fake3DPhysicsWorld';
import { Physics } from '../Engine/Physics/Physics';
import { UI } from '../Engine/UI/UI';
import { ActivityMenu } from '../Scene/Menu/ActivityMenu/ActivityMenu';
import { Menu } from '../Scene/Menu/Menu';
import { Scene } from '../Scene/Scene';
import { Chase } from '../Scene/SingleAction/Chase/Chase';
import { Treat } from '../Scene/SingleAction/Treat/Treat';
import { Nutrition } from '../Scene/SingleAction/Nutrition/Nutrition';
import { Attention } from '../Scene/SingleAction/Attention/Attention';
import { Stroll } from 'Core_Games/Scene/Storyboard/Stroll/Stroll';
import { FriendTime } from 'Core_Games/Scene/Storyboard/FriendTime/FriendTime';

export const sceneType = {
  GAME: { NAME: 'game' },
  SCENE: { NAME: 'scene', DATA: Scene },
  // Engine
  ENGINE: { NAME: 'engine', DATA: Engine },
  PHYSICS: { NAME: 'physics', DATA: Physics },
  ASSET: { NAME: 'asset', DATA: Asset },
  UI: { NAME: 'ui', DATA: UI },
  // Single Action
  CHASE: { NAME: 'chase', DATA: Chase },
  NUTRITION: { NAME: 'nutrition', DATA: Nutrition },
  ATTENTION: { NAME: 'attention', DATA: Attention },
  TREAT: { NAME: 'treat', DATA: Treat },
  SINGLEACTION: { NAME: 'singleaction' },
  // Story Board
  STORYBOARD: { NAME: 'storyboard' },
  STROLL: { NAME: 'stroll', DATA: Stroll },
  FRIENDTIME: { NAME: 'friendtime', DATA: FriendTime },
  // Menu
  ACTIVITYMENU: { NAME: 'activitymenu', DATA: ActivityMenu },
  MENU: { NAME: 'menu', DATA: Menu },
  // Physics
  FAKE3DPHYSICSWORLD: { NAME: 'fake3dphysicsworld', DATA: Fake3DPhysicsWorld },
};
