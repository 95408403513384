import styled from 'styled-components';

export const StyledRow = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  row-gap: 10px;
  padding: 15px 20px;
  flex-direction: column;

  button {
    flex-grow: 1;
    width: 100%;

    :last-child {
      margin-right: 0;
    }
  }
`;
