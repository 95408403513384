import Phaser from 'phaser';

// Fake 3D physics object works by creating two seperate "planes" - one plane with the xz axis, and the other with the y
// it then inserts phaser physics balls in custom boundaries (planes) in order to use the phaser physics engine
// This has the capability to allow non-gravity (see comments)
export default class Fake3DPhysicsObject {
  constructor(physics, paramsOriginal, params) {
    this.init(paramsOriginal, params);
    this.createObject(physics);
  }

  init = (paramsOriginal, params) => {
    this.positionBoxX = params.positionBoxX ? params.positionBoxX : paramsOriginal.positionBoxX;
    this.positionBoxY = params.positionBoxY ? params.positionBoxY : paramsOriginal.positionBoxY;
    this.worldSizeX = params.worldSizeX ? params.worldSizeX : paramsOriginal.worldSizeX;
    this.worldSizeY = params.worldSizeY ? params.worldSizeY : paramsOriginal.worldSizeY;
    this.worldSizeZ = params.worldSizeZ ? params.worldSizeZ : paramsOriginal.worldSizeZ;

    this.angle = 200;
    this.x = 0;
    this.y = 0;
    this.z = 0;

    this.horizonLine = this.worldSizeZ * params.horizonLine;
    this.groundLine = this.worldSizeZ;

    this.angleOfCameraX = 0;
    this.angleOfCameraY = 0;
    this.angleOfCameraZ = 0;

    this.zoomFactor = params.zoomFactor;
    this.velocity = params.velocity;
    this.bounce = params.disableGravity ? 1.0 : params.bounce;
    this.minScale = params.minScale;
    this.maxScale = (params.maxScale ?? 1.0) * (params.object?.originalScale ?? 1.0);
    this.scale = params.scale;
    this.size = params.size;
    this.velocityPower = params.velocityPower ?? 0;
    this.bounceFactor = params.bounceFactor ?? 1;
    this.startFromRight = params.startFromRight ?? false;
    this.object = params.object;
    this.isMoving = true;

    this.gravityBounce = params.disableGravity ? 1.0 : this.bounce * 0.8;

    this.disableGravity = params.disableGravity;

    this.drag = params.drag;
    this.hasDamping = params.hasDamping;

    this.startX = this.positionBoxX + params.startX;
    this.startY = this.worldSizeZ + params.size - params.startY;
    this.startZ = this.worldSizeY + params.size - params.startZ;
  };

  createObject = (physics) => {
    // fake horizontal dimension (by creating another plane with gravity not allowed with fake ball to use phaser physics,

    // taking the x dimension as our x, and z as our y from this plane)
    this.ballX = physics.physics.add
      .image(this.startX, this.startZ, '')
      .setCircle(this.size)
      .setVelocity(this.startFromRight ? -this.velocity : this.velocity, this.velocity)
      // To "Disable gravity" (allow object to fly endlessly), set bounce to 1 for both values
      .setBounce(this.bounce, this.gravityBounce)
      .setAngle(this.angle)
      .setScale(this.scale, this.scale)
      .setCollideWorldBounds(true);
    this.ballX.body.setBoundsRectangle(
      new Phaser.Geom.Rectangle(this.positionBoxX, this.positionBoxY, this.worldSizeX, this.worldSizeZ),
    );
    this.ballX.alpha = 0;
    if (this.hasDamping) {
      this.ballX.setDamping(this.hasDamping);
      this.ballX.setDrag(this.drag, this.drag);
    }
    this.ballX.body.allowGravity = false;

    // fake vertical dimension (by creating another plane with gravity allowed with fake ball to use phaser physics,
    // taking only the y from this plane)
    this.ballY = physics.physics.add
      .image(this.positionBoxX + this.worldSizeZ, this.startY, '')
      .setCircle(this.size)
      .setVelocity(0, this.velocity * this.bounceFactor)
      // To "Disable gravity" (allow object to fly endlessly), set bounce to 1 for both values
      .setBounce(this.bounce, this.bounce)
      .setScale(this.scale, this.scale)
      .setCollideWorldBounds(true);
    this.ballY.body.setBoundsRectangle(
      new Phaser.Geom.Rectangle(
        this.positionBoxX + this.worldSizeZ,
        this.positionBoxY,
        this.worldSizeX,
        this.worldSizeY,
      ),
    );
    if (this.hasDamping) {
      this.ballY.setDamping(this.hasDamping);
      this.ballY.setDrag(this.drag, this.drag);
    }
    this.ballY.alpha = 0;
    this.ballY.body.allowGravity = !this.disableGravity;

    // add our object (allow physics for our object)
    physics.physics.world.enable(this.object);
  };

  updateLocation = async () => {
    // To disable gravity, don't run the velocity decrease here
    if (!this.disableGravity) {
      this.ballX.body.velocity.x -= this.ballX.body.velocity.x * this.velocityPower;
      this.ballX.body.velocity.y -= this.ballX.body.velocity.y * this.velocityPower;
    }

    this.x = this.startFromRight ? this.ballX.body.x : this.ballX.body.x - this.size;
    this.y = this.startFromRight ? this.ballY.body.y : this.ballY.body.y - this.size;
    this.z = this.ballX.body.y;

    this.rotateZAxis();
    this.rotateXAxis();
    this.rotateYAxis();

    const scale = this.getObjectScale();

    this.object.setScale(scale, scale);

    if (this.isMoving) {
      this.object.body.x = this.x;
      this.object.body.y = this.y;
      this.object.setScale(scale, scale);
    }
  };

  getObjectScale = () => {
    if (!this.horizonLine || !this.groundLine) {
      const scaleTo =
        ((this.worldSizeZ - (this.z - this.positionBoxY)) / this.worldSizeZ) * this.zoomFactor + this.minScale;
      const proposedScale = scaleTo * this.maxScale;
      return proposedScale < this.minScale
        ? this.minScale
        : proposedScale > this.maxScale
        ? this.maxScale
        : proposedScale;
    } else {
      this.groundDistance = Phaser.Math.Distance.Between(0, this.groundLine, 0, this.horizonLine);
      const currentDistance = Phaser.Math.Distance.Between(0, this.groundLine, 0, this.z);
      const scaleFactor = this.scale * (currentDistance / this.groundDistance);
      const currentChosenScale =
        scaleFactor > this.maxScale ? this.maxScale : scaleFactor < this.minScale ? this.minScale : scaleFactor;
      this.object.scaleAimRatio = currentChosenScale / this.maxScale;
      return currentChosenScale;
    }
  };

  objectIsMoving = (threshX, threshY) => {
    return !(
      Math.abs(this.ballX.body.velocity.x) < threshX &&
      Math.abs(this.ballX.body.velocity.y) < threshX / 2 &&
      Math.abs(this.ballY.body.velocity.y) < threshY
    );
  };

  rotateZAxis = () => {
    this.x = this.x * Math.cos(this.angleOfCameraZ) - this.y * Math.sin(this.angleOfCameraZ);
    this.y = this.y * Math.cos(this.angleOfCameraZ) + this.x * Math.sin(this.angleOfCameraZ);
  };
  rotateXAxis = () => {
    this.y = this.y * Math.cos(this.angleOfCameraX) - this.z * Math.sin(this.angleOfCameraX);
    this.z = this.z * Math.cos(this.angleOfCameraX) + this.y * Math.sin(this.angleOfCameraX);
  };
  rotateYAxis = () => {
    this.x = this.x * Math.cos(this.angleOfCameraX) + this.z * Math.sin(this.angleOfCameraX);
    this.z = this.z * Math.cos(this.angleOfCameraX) - this.x * Math.sin(this.angleOfCameraX);
  };

  toggleMotion = (enabled = undefined) => {
    this.isMoving = enabled ?? !this.isMoving;
  };
}
