import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { StyledHiddenInput } from './styles';

function Toggle({ children, value, control }) {
  const inputId = `dayToggle.${value}`;

  return (
    <Controller
      key={value}
      name={`days[${value}]`}
      control={control}
      defaultValue={false}
      render={({ field: { onChange, value: checked } }) => (
        <>
          <StyledHiddenInput
            id={inputId}
            type="checkbox"
            value={value}
            checked={checked}
            onChange={(e) => onChange(e.target.checked && e.target.value)}
          />
          <label htmlFor={inputId}>
            <span>{children}</span>
          </label>
        </>
      )}
    />
  );
}
Toggle.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};

export default Toggle;
