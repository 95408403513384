import { useState, useEffect } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Core_Helpers/getAuthClient';
import urlcat from 'urlcat';

const menuUrl = '/v5/menus/:userId';
const options = {
  revalidateOnFocus: false,
};

const getMenu = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useMenu = (userId) => {
  const { data: menuResult, error } = useSWR(() => urlcat(process.env.API_BASE_URL, menuUrl, { userId }), getMenu, {
    ...options,
  });
  const [menu, setMenu] = useState();
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    if (menuResult && menuResult.data) {
      setMenu({
        ...menuResult.data,
      });
    }
    setIsPending(!menuResult);
  }, [menuResult]);

  return { menu, isPending, error };
};

export default useMenu;
