import React from 'react';
import { useIntl } from 'react-intl';
import CharacterGreetingHeader from 'Core_Components/CharacterGreetingHeader/CharacterGreetingHeader';
import { legacyBackgroundColors } from 'Styles/colors';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { StyledRemindersContentContainer, StyledRemindersPageContainer } from 'Core_Pages/Reminders/styles';
import GreetingPyxir from 'Assets/img/greeting-pyxir.svg';
import HiFriendMessageEn from 'Assets/img/character-header/pyxir-greeting-2x.en.png';
import HiFriendMessageEs from 'Assets/img/character-header/pyxir-greeting-2x.es.png';
import SetReminderSuccessModal from '../components/Modals/SetReminderSuccessModal';
import ReminderConfigurationForm from '../components/ReminderConfigurationForm/ReminderConfigurationForm';
import { useCoreDispatch } from 'Contexts/StoreContext';
import { createReminder } from 'Core_Redux/reminders';

function CreateReminder() {
  const intl = useIntl();
  const dispatch = useCoreDispatch();

  const submit = (newReminder) => dispatch(createReminder({ newReminder }));

  return (
    <StyledRemindersPageContainer>
      <ScreenReaderWrapper>
        <h1>{intl.formatMessage({ id: 'title.reminders-create' })}</h1>
      </ScreenReaderWrapper>
      <CharacterGreetingHeader
        isPyxirHeader={true}
        message={intl.formatMessage({ id: 'reminders.header' })}
        imageSrc={GreetingPyxir}
        imageAlt={intl.formatMessage({ id: 'greeting.pyxir' })}
        imageAlignment="flex-start"
        backgroundColor={legacyBackgroundColors.indigo}
        greeting={{
          src: intl.formatMessage({ id: 'hi.friend' }).includes('en.svg') ? HiFriendMessageEn : HiFriendMessageEs,
          alt: intl.formatMessage({ id: 'image.pyxir.hifriend' }),
        }}
      />
      <StyledRemindersContentContainer>
        <ReminderConfigurationForm defaultValues={ReminderConfigurationForm.DefaultFormState} submit={submit} />
      </StyledRemindersContentContainer>
      <SetReminderSuccessModal id={'CreateReminder'} />
    </StyledRemindersPageContainer>
  );
}

export default CreateReminder;
