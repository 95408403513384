import React from 'react';
import PropTypes from 'prop-types';
import { StyledTextBox, StyledHeader, StyledParagraph, StyledTextContainer } from './styles';

function Caption({ title, text, disabled }) {
  return !disabled ? (
    <StyledTextBox>
      <StyledTextContainer>
        {title ? <StyledHeader>{title}</StyledHeader> : ''}
        <StyledParagraph>{text}</StyledParagraph>
      </StyledTextContainer>
    </StyledTextBox>
  ) : null;
}
Caption.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Caption;
