import { bodyPart } from '../../../../constants/bodyPart';
import { Config as doorConfig } from './objectData/doorConfig';
import { gameImportType } from '../../../../constants/gameImportType';
import { actorType } from '../../../../constants/actorType';

export const frontDoorAssetConfig = {
  NAME: 'FRONTDOOR',
  SPRITESHEET_FOLDER: 'front-door',
  SPRITESHEET_NAME: 'Front-Door-Sprite-Map-',
  DATA: [doorConfig],
  ACTORTYPE: actorType.OBJECT,
  TYPE: gameImportType.SPRITESHEET,
  INVENTORY: {
    [bodyPart.MOUTH]: [],
    [bodyPart.NECK]: [],
  },
  AUDIO: {},
  SCALE: 1.0,
  HEIGHT: 800,
  WIDTH: 800,
};
