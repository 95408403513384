import styled from '@emotion/styled';
import { FormHelperText, TextField } from '@mui/material';
import { teal, black } from 'Styles/colors';

export const CodeBox = styled(TextField)`
  height: 43px;
  width: 48px;
  margin: 0px 2px;

  & .MuiInputBase-input {
    font-weight: 400;
    font-size: 24px;
    padding: 6px;
    text-align: center;
  }

  & .MuiOutlinedInput-root {
    &:hover fieldset,
    & fieldset {
      border-color: ${black._60};
    }
    &.Mui-focused fieldset {
      border-color: ${teal._300};
      border-width: 1px;
    }
  }
`;

export const CodeHelperText = styled(FormHelperText)`
  width: 60%;
  margin: 0% 0% 2% 28%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
`;
