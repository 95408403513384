import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import urlcat from 'urlcat';
import getAuthClient from 'Core_Helpers/getAuthClient';
import { REMINDERS_EDIT } from 'Core_Pages/Routes/RoutesConfig';
import {
  CONFIRM_DEACTIVATE,
  CONFIRM_DELETE,
  DELETE_SUCCESS,
  DUPLICATE_SUCCESS,
  SET_SUCCESS,
} from 'Core_Pages/Reminders/components/Modals/modals';
import { activityCategories } from 'Core_Pages/Reminders/components/ReminderConfigurationForm/ReminderTypeConfig/ActivitiesConfig/activityCategories';

const initialState = {
  reminders: null,
  isEditPending: false,
  isCreatePending: false,
  isDuplicatePending: false,
  isDeletePending: false,
  currentModal: null,
  selectedReminderGuid: null,
};

export const selectReminders = (state) => state.reminders.reminders;
export const selectCurrentModal = (state) => state.reminders.currentModal;
export const selectIsEditPending = (state) => state.reminders.isEditPending;
export const selectIsDeletePending = (state) => state.reminders.isDeletePending;
export const selectIsDuplicatePending = (state) => state.reminders.isDuplicatePending;
export const selectIsCreatePending = (state) => state.reminders.isCreatePending;
export const selectAreRemindersLoading = (state) => !state.reminders.reminders;
export const selectSelectedReminder = (state) =>
  state.reminders.reminders?.find((r) => r.guid === state.reminders.selectedReminderGuid) || null;

export const fetchReminders = createAsyncThunk('reminders/loadReminders', async (_, { rejectWithValue, getState }) => {
  const { user } = getState();
  const userId = user.userInfo.sub;

  const authorizedApiClient = await getAuthClient();
  if (authorizedApiClient) {
    const uri = urlcat(process.env.API_BASE_URL, 'v1/reminders/:userId', { userId });
    const response = await authorizedApiClient.get(uri);

    if (response.status === 200 && response.data?.constructor === Array) {
      return response.data;
    } else if (response.status === 204) {
      return [];
    } else {
      return rejectWithValue();
    }
  }
});
export const createReminder = createAsyncThunk('reminders/create', async ({ newReminder }, { getState }) => {
  const authorizedApiClient = await getAuthClient();
  if (authorizedApiClient) {
    const { user } = getState();
    await authorizedApiClient.post(
      urlcat(process.env.API_BASE_URL, 'v1/reminders'),
      mapFormStateToApiReminder({
        ...newReminder,
        isActive: true,
        userId: user.userInfo.sub,
      }),
    );
  }
});
export const draftEditReminder = createAsyncThunk('reminders/draftEdit', async ({ guid, history }, { getState }) => {
  const { reminders } = getState();
  const reminderToEdit = reminders.reminders.find((r) => r.guid === guid);
  history.push({
    pathname: REMINDERS_EDIT,
    state: reminderToEdit,
  });
});
export const editReminder = createAsyncThunk(
  'reminder/submitEdit',
  async ({ updatedReminder, successModal }, { getState }) => {
    const authorizedApiClient = await getAuthClient();
    if (authorizedApiClient) {
      const { user } = getState();
      await authorizedApiClient.put(
        urlcat(process.env.API_BASE_URL, 'v1/reminders/:guid', { guid: updatedReminder.guid }),
        mapFormStateToApiReminder({
          ...updatedReminder,
          userId: user.userInfo.sub,
        }),
      );
      return { updatedReminder, successModal };
    }
  },
);
export const deleteReminder = createAsyncThunk('reminders/deleteReminder', async (_, { getState }) => {
  const { reminders } = getState();
  const authorizedApiClient = await getAuthClient();
  if (authorizedApiClient) {
    await authorizedApiClient.delete(
      urlcat(process.env.API_BASE_URL, 'v1/reminders/:guid', { guid: reminders.selectedReminderGuid }),
    );
  }
});
export const duplicateReminder = createAsyncThunk('reminders/duplicate', async ({ guid }, { getState, dispatch }) => {
  const { user, reminders } = getState();
  const newReminder = { ...reminders.reminders.find((r) => r.guid === guid) };
  // remove id of reminder to be copied from new reminder object
  delete newReminder.guid;
  const authorizedApiClient = await getAuthClient();
  if (authorizedApiClient) {
    await authorizedApiClient.post(
      urlcat(process.env.API_BASE_URL, 'v1/reminders'),
      mapFormStateToApiReminder({
        userId: user.userInfo.sub,
        ...newReminder,
      }),
    );
    dispatch(fetchReminders());
  }
});

export const remindersSlice = createSlice({
  name: 'reminders',
  initialState,
  reducers: {
    draftDeleteReminder: (state, action) => {
      state.selectedReminderGuid = action.payload;
      state.currentModal = CONFIRM_DELETE;
    },
    draftReminderDeactivation: (state, action) => {
      state.selectedReminderGuid = action.payload;
      state.currentModal = CONFIRM_DEACTIVATE;
    },
    cancelDelete: (state) => {
      state.selectedReminderGuid = null;
      state.currentModal = null;
    },
    cancelReminderDeactivation: (state) => {
      state.selectedReminderGuid = null;
      state.currentModal = null;
    },
    closeModal: (state) => {
      state.currentModal = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReminders.fulfilled, (state, action) => {
      state.reminders = action.payload?.map(mapApiReminderToFormState);
    });
    builder.addCase(fetchReminders.rejected, (state) => {
      state.reminders = [];
      // TODO: handle error in UI as defined by UX
    });
    builder.addCase(createReminder.pending, (state) => {
      state.isCreatePending = true;
    });
    builder.addCase(createReminder.fulfilled, (state) => {
      state.isCreatePending = false;
      state.currentModal = SET_SUCCESS;
    });
    builder.addCase(createReminder.rejected, (state) => {
      state.isCreatePending = false;
      // TODO: handle error in UI as defined by UX
    });
    builder.addCase(deleteReminder.pending, (state) => {
      state.currentModal = false;
      state.isDeletePending = true;
    });
    builder.addCase(deleteReminder.fulfilled, (state) => {
      state.isDeletePending = false;
      state.currentModal = DELETE_SUCCESS;
      state.reminders = state.reminders.filter((r) => r.guid !== state.selectedReminderGuid);
      state.selectedReminderGuid = null;
    });
    builder.addCase(duplicateReminder.pending, (state) => {
      state.isDuplicatePending = true;
    });
    builder.addCase(duplicateReminder.fulfilled, (state) => {
      state.isDuplicatePending = false;
      state.currentModal = DUPLICATE_SUCCESS;
    });
    builder.addCase(editReminder.pending, (state, action) => {
      state.selectedReminderGuid = action.meta.arg.updatedReminder.guid;
      state.currentModal = null;
      state.isEditPending = true;
    });
    builder.addCase(editReminder.fulfilled, (state, action) => {
      state.isEditPending = false;
      state.selectedReminderGuid = null;
      const { updatedReminder, successModal } = action.payload;
      state.reminders = state.reminders?.map((r) => (r.guid === updatedReminder.guid ? updatedReminder : r));
      state.currentModal = successModal;
    });
    builder.addCase(editReminder.rejected, (state) => {
      state.isEditPending = false;
      // TODO: handle error in UI as defined by UX
    });
  },
});

export const { cancelDelete, cancelReminderDeactivation, closeModal, draftDeleteReminder, draftReminderDeactivation } =
  remindersSlice.actions;

export default remindersSlice.reducer;

function mapApiReminderToFormState(reminder) {
  const reminderFormState = reminder;
  // reminders without end date are represented by 12/31/9999
  if (new Date(reminder.endDateUtc).getFullYear() === 9999) {
    reminderFormState.endDateUtc = null;
  }

  if (reminder.activityCategories?.length === 1 && reminder.activityCategories[0] === '') {
    reminder.activityCategories = null;
  }

  // convert array of categories into a set of objects usable by react-hook-form
  if (reminder.activityCategories) {
    Object.assign(
      reminderFormState,
      activityCategories.reduce(
        (acc, curr) => (
          (acc[`activityCategories[${curr.code}]`] = reminder.activityCategories.includes(curr.code)
            ? curr.code
            : false),
          acc
        ),
        {},
      ),
    );
  }

  return reminder;
}

function mapFormStateToApiReminder(reminder) {
  // reminders with no end date are represented as 12-31-9999 from API
  if (!reminder.endDateUtc) {
    reminder.endDateUtc = new Date('9999-12-31');
  }

  // remove activity categories fields which are submitted as array
  activityCategories.forEach((category) => {
    delete reminder[`activityCategories[${category.code}]`];
  });

  return reminder;
}
