import { getCurrentSceneConfig } from 'Core_Games/Scripts/configHelper';
import { sceneType } from '../constants/sceneType';
import { Asset } from './Asset/Asset';
import Phaser from 'phaser';

let currentId = 0;
export class Engine extends Phaser.Scene {
  constructor() {
    super(`${sceneType.ENGINE.NAME}_${currentId}`);
    currentId++;
  }

  init(sceneData) {
    this.sceneData = sceneData;
    this.sceneConfig = sceneData.sceneConfig;
    this.petConfig = sceneData.petConfig;
    this.sceneKey = sceneData.sceneKey;
    this.sceneType = sceneData.sceneType;
    this.assetConfig = getCurrentSceneConfig(this, sceneData.sceneConfig);
    this.isWebGL = this.game.context.constructor == WebGLRenderingContext;
    this.appData = sceneData.appData;
    this.gameType = sceneData.gameData.gameType;
  }

  preload = async () => {};

  create = async () => {
    if (this.assetConfig?.ASSETS) {
      this.engineAssets = this.scene.add(sceneType.ASSET.NAME, Asset, true, {
        assetConfig: this.assetConfig,
        sceneConfig: this.sceneConfig,
        petConfig: this.petConfig,
        isWebGL: this.isWebGL,
        gameType: this.gameType,
      });
    }

    if (this.sceneConfig?.PHYSICS) {
      this.enginePhysics = this.scene.add(sceneType.PHYSICS.NAME, sceneType.PHYSICS.DATA, true, {
        sceneConfig: this.sceneConfig,
        petConfig: this.petConfig,
      });
    }

    if (this.assetConfig?.UI) {
      this.engineUi = this.scene.add(sceneType.UI.NAME, sceneType.UI.DATA, true, {
        assetConfig: this.assetConfig,
        sceneConfig: this.sceneConfig,
        petConfig: this.petConfig,
        appData: this.appData,
      });
    }
  };

  update = async () => {
    if (this.engineAssets) {
      this.engineAssets.update();
    }
    if (this.enginePhysics) {
      this.enginePhysics.update();
    }
    if (this.engineUi) {
      this.engineUi.update();
    }
  };
}
