import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { StyledBadge } from './styles';
import { ListItem, Typography } from '@mui/material';

function ThriveBadge({ src, textId, altId, disabled }) {
  const intl = useIntl();

  const imageAlt = altId ? intl.formatMessage({ id: altId }) : '';
  const text = textId ? intl.formatMessage({ id: textId }) : '';

  return (
    <ListItem disabled={disabled}>
      <StyledBadge aria-labelledby="badgeLabel">
        <img src={src} alt={imageAlt} />
        <Typography fontSize={14} id="badgeLabel">
          {text}
        </Typography>
      </StyledBadge>
    </ListItem>
  );
}

ThriveBadge.propTypes = {
  src: PropTypes.string,
  textId: PropTypes.string,
  altId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ThriveBadge;
