import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import {
  OptionalCheckinsContainer,
  OptionalDialogHeader,
  StyledCloseIconContainer,
  OptionalContainer,
} from '../../Checkin/styles';
import CloseIcon from '@mui/icons-material/Close';
import LoadingWrapper from 'Core_Components/LoadingWrapper/LoadingWrapper';
import GreetingPyxir from 'Assets/img/greeting-pyxir.svg';
import Button from 'Stories/components/Button';
import { ScreeningModuleStore } from 'Core_Pages/Screening/ScreeningModuleStore';

function OptionalCheckin() {
  const intl = useIntl();
  const [loading] = useState(false);
  const screeningStore = ScreeningModuleStore.Instance().Use();

  const onCloseOptionalCheckins = () => {
    screeningStore.removeOptionalScreenings();
  };

  const onContinueClick = () => {
    screeningStore.startOptionalQuestions();
  };

  return (
    <OptionalCheckinsContainer fullScreen open={true} aria-labelledby="checkins-title" className="safe-area">
      <OptionalDialogHeader>
        <img src={GreetingPyxir} alt="" />
        <StyledCloseIconContainer
          aria-label={intl.formatMessage({ id: 'button.close' })}
          onClick={onCloseOptionalCheckins}
        >
          <CloseIcon fontSize="inherit" fontWeight="inherit" />
        </StyledCloseIconContainer>
      </OptionalDialogHeader>
      <LoadingWrapper loading={loading}>
        <OptionalContainer>
          <Typography fontSize={36} fontWeight={700}>
            {intl.formatMessage({ id: 'screenings.optional-check-in.title' })}
          </Typography>
          <Typography fontSize={24} fontWeight={400}>
            {intl.formatMessage({ id: 'screenings.optional-check-in.description' })}
          </Typography>
          <Button style={{ width: '100%' }} onClick={onContinueClick}>
            {intl.formatMessage({ id: 'common.continue' })}
          </Button>
        </OptionalContainer>
      </LoadingWrapper>
    </OptionalCheckinsContainer>
  );
}

export default OptionalCheckin;
