import reduxStore from 'Core_Redux/store';

export class UserAccessor {
  private static _instance: UserAccessor = new UserAccessor();

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (UserAccessor._instance) {
      return UserAccessor._instance;
    }
  }

  public get User() {
    const userState = reduxStore.store.getState().user;

    return {
      UserId: userState.userInfo?.sub,
      Language: userState.language,
      Meta: userState.userMeta,
    };
  }
}
