import { bodyPart } from '../../../../constants/bodyPart';
import { Config as dogBowlConfig } from './objectData/DogBowlConfig';
import { Config as catBowlConfig } from './objectData/CatBowlConfig';
import { gameImportType } from '../../../../constants/gameImportType';
import { actorType } from '../../../../constants/actorType';

export const bowlAssetConfig = {
  NAME: 'BOWL',
  SPRITESHEET_FOLDER: 'nutrient-bowl',
  SPRITESHEET_NAME: 'NutrientBowl-SpriteMap-',
  DATA: [dogBowlConfig, catBowlConfig],
  ACTORTYPE: actorType.OBJECT,
  TYPE: gameImportType.SPRITESHEET,
  INVENTORY: {
    [bodyPart.MOUTH]: [],
    [bodyPart.NECK]: [],
  },
  AUDIO: {},
  SCALE: 1.0,
  HEIGHT: 800,
  WIDTH: 800,
};
