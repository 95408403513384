/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import OnboardingHeader from 'Core_Components/OnboardingHeader/OnboardingHeader';
import { useForm, Controller } from 'react-hook-form';
import { PropTypes } from 'prop-types';
import {
  ProfilePageContainer,
  PageTitle,
  NotificationSettingFormContainer,
  SubmitButton,
  ScrollableContent,
  NotificationSettingForm,
  NotificationSetting,
  Label,
} from './styles';
import useAxiosPutSubmit from 'Core_Hooks/api/useAxiosPutSubmit/useAxiosPutSubmit';
import useNotifications from 'Core_Hooks/useNotifications';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { appInsights } from 'Core_Helpers/AppInsights';
import ToggleSwitch from 'Core_Components/ToggleSwitch/ToggleSwitch';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';

const NotificationSettings = () => {
  const intl = useIntl();
  const saveUrl = '/v1/notificationsettings/';
  const defaultValues = {
    isGloballyEnabled: false,
  };
  const [data, setData] = useState();
  const history = useHistory();
  const { notificationSetting, user } = useNotifications();
  const { response, isLoading } = useAxiosPutSubmit(`${process.env.API_BASE_URL}${saveUrl}${user?.sub}`, data);

  const { control, handleSubmit, setValue, formState, reset } = useForm({
    defaultValues,
  });

  const { isDirty } = formState;

  useEffect(() => {
    if (notificationSetting) {
      setValue('isGloballyEnabled', notificationSetting.isEnabled);
      reset({
        isGloballyEnabled: notificationSetting.isEnabled,
      });
    }
  }, [notificationSetting]);

  useEffect(() => {
    if (response) {
      history.push(HOME);
    }
  }, [response]);

  const onSubmit = (data) => {
    if (!isLoading) {
      appInsights.trackEvent({ name: 'NotificationSettingsSave' });
      setData(data);
    }
  };

  const returnNotificationForm = () => {
    return (
      <NotificationSettingFormContainer id="NotificationSettingFormContainer">
        <NotificationSettingForm data-testid="NotificationSettingForm" onSubmit={handleSubmit(onSubmit)}>
          <PageTitle>
            <FormattedMessage id="menu.notification-settings" />
          </PageTitle>
          <NotificationSetting>
            <Label htmlFor="isGloballyEnabled" id="isGloballyEnabledLabel">
              <FormattedMessage id="routine.checkins" />
            </Label>
            <Controller
              control={control}
              name="isGloballyEnabled"
              render={({ field }) => (
                <ToggleSwitch
                  id="isGloballyEnabled"
                  name="isGloballyEnabled"
                  checked={field.value}
                  onChange={(isEnabled) => field.onChange(isEnabled)}
                  aria-required="true"
                />
              )}
            />
          </NotificationSetting>
          <SubmitButton
            disabled={isLoading || !isDirty}
            data-testid="SubmitButton"
            value={intl.formatMessage({ id: 'profile.save' })}
            type="submit"
          />
        </NotificationSettingForm>
      </NotificationSettingFormContainer>
    );
  };

  return (
    <ProfilePageContainer>
      <OnboardingHeader id="OnboardingHeader" />
      <ScrollableContent>{returnNotificationForm()}</ScrollableContent>
    </ProfilePageContainer>
  );
};

NotificationSettings.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.any,
};

export default NotificationSettings;
