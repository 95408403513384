import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { breakpoints } from 'Styles/theme';
import { black } from 'Styles/colors';

export const StyledEmptyVideo = styled.div`
  background-color: ${black._100};
  min-width: 340px;
  min-height: 526px;
  height: 100%;
  text-align: center;
  aspect-ratio: 382 / 680;
  display: flex;
  justify-content: center;
`;

export const VideoContainer = styled.div(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  max-width: 100%;
  text-align: center;
  
  ${theme.breakpoints.up(breakpoints.DESKTOP)} { 
    max-width: 100%;
    padding: 5% 16% 1% 18%;
    display: flex;
    justify-content: center;
  }
`,
);

export const VideoGrid = styled(Grid)(
  ({ theme }) => `
  padding: 7% 0px 8% 0px;

  ${theme.breakpoints.up(breakpoints.TABLET)} { 
    padding: 10% 0% 18% 0%;
  }

  ${theme.breakpoints.up(breakpoints.DESKTOP)} {
    padding: 3% 0% 5% 1%;

    ::after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      border-left: 1px solid #979797;
      height: 79%;
      width: 2px;
      top: 12%;
    }
  }
`,
);
