import { Actor } from '../../../Actor';
import { bowlAssetConfig } from './BowlConfig';

export class Bowl extends Actor {
  constructor(scene, x, y, color, petType, frame) {
    let currentBowlConfig = bowlAssetConfig;
    currentBowlConfig.DATA = currentBowlConfig.DATA.filter((x) => x.PETTYPE == petType);
    super(scene, x, y, currentBowlConfig, currentBowlConfig.NAME, frame);

    this.spriteName = this.ObjectData.NAME;
    this.Inventory = this.ObjectData.INVENTORY;
    this.Sounds = this.ObjectData.AUDIO;

    this.currentSpriteSheet = color;
    this.originalScale = this.ObjectData.SCALE;
  }
}
