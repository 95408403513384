import React from 'react';
import GoogleFontIcon from 'Core_Components/GoogleFontIcon/GoogleFontIcon';
import { StyledAlarmBlockIcon } from './styles';

function AlarmBlockIcon() {
  return (
    <StyledAlarmBlockIcon>
      <GoogleFontIcon name={GoogleFontIcon.Name.AccessAlarm} variant={GoogleFontIcon.Variant.Outlined} />
    </StyledAlarmBlockIcon>
  );
}

export default AlarmBlockIcon;
