import { childActingOut } from 'Assets/img/my-tools-icons';

export const childIsActingOutEn = {
  key: 'child-acting-out',
  title: 'My Child is Acting Out',
  titleImage: childActingOut,
  titleImageAlt: '',
  contentId: 50,
  sections: [
    {
      key: 'child-acting-out-intro',
      description:
        'Children in foster care may experience a range of emotions including anger, sadness, loneliness, and more. This can impact how you are feeling and may make it more challenging to manage your own emotions. Without a stable living environment, your child may be coping through uncontrolled emotions and searching for ways to establish more control in their lives. While this may look like your child is “acting out,” it is most likely not intentional. It is a learned skill from what they have experienced in their lives. If you are reading this article and your child is acting out, remember to make sure that you are communicating with their mental health therapist about ways to best support your child. If they are not engaged in routine therapy, it is important to reach out to get them the mental health supports they need. Here are a few tips to support your child with their emotions.',
    },
    {
      key: 'acting-out-anger',
      header: 'Anger',
      description:
        'It is important for all humans to express anger. For those that have had trauma in their past (and it is likely that a foster child has experienced trauma), they may not know how to appropriately express anger. Anger from prior traumas comes often comes up in normal day-to-day situations and it can appear in a number of different ways. Here are a few examples of anger that could be a response to trauma:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'A constant state of feeling irritated or angry at anything around you',
        },
        {
          text: 'Sudden angry outbursts that appear to “come out of nowhere”',
        },
        {
          text: 'Anger at a seemingly small frustration that escalates quickly to yelling, throwing things, or physical aggression',
        },
      ],
    },
    {
      key: 'reacting-to-anger',
      header: 'Reacting to Your Child’s Anger',
      description:
        'As parents or caregivers, when we see a child act in this way, our first reaction is often to quickly put a stop to it by raising our own voice or giving a punishment. However, if you can provide an empathetic and a calm reaction, this can better help the child feel that they are in a safe space. While it will take time, if you can consistently provide this type of reaction, your child will begin to feel they can express their anger safely around you. Depending on the age of your child, some tips to help your child with their anger in the moment can be:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'If you are around others, find a quite space where they can yell or express themselves comfortably',
        },
        {
          text: 'The art of redirection – offer a more enjoyable activity or directing your child to something that is away from what is making them angry',
        },
        {
          text: 'Offer reassurances that your child is safe with you',
        },
      ],
    },
    {
      key: 'acting-out-sadness',
      header: 'Sadness',
      description:
        'Your child experiencing sadness is completely normal. As parents or caregivers, it is not our role to prevent our child from being sad, but rather to help them experience, acknowledge, and work through these emotions.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Sadness may appear more like anger that leads into emotional expressions such as crying or yelling',
        },
        {
          text: 'Do not give your child false sentiments of hope such as “everything is okay” or “you will not get hurt again.” While that may seem to be helpful in the moment, the reality of a foster child is that everything is likely not okay and that they may have more pain and hurt in their lives. Making statements that you cannot guarantee may lead you child to lose trust in you and what you say.',
        },
        {
          text: 'Shift your support to things that you can control such as: “I want to help make things better for you” or “I am going to do everything I can to keep you safe”',
        },
        {
          text: 'Offering comfort and empathy is the most important thing you can do for your child',
        },
        {
          text: 'Acknowledge where they are and that its okay to be sad',
        },
        {
          text: 'Talk about healthy ways to express sadness like crying or talking about what makes them sad',
        },
      ],
    },
  ],
};
