import { bodyPart } from '../../../../constants/bodyPart';
import { Config as movementConfig } from './objectData/movementConfig';

export const catAssetConfig = {
  NAME: 'CAT',
  SPRITESHEET_FOLDER: 'cat',
  SPRITESHEET_NAME: 'Cat-Sprite-Map-',
  SCALE: 0.3,
  DATA: [movementConfig],
  INVENTORY: {
    [bodyPart.MOUTH]: [],
    [bodyPart.NECK]: [],
  },
  AUDIO: {},
  HEIGHT: 800,
  WIDTH: 800,
};
