import { pregnancy } from 'Assets/img/my-tools-icons';

export const pregnancyEs = {
  key: 'pregnancy-es',
  title: 'Mantenerse conectado/a durante el embarazo: consejos para sentirse mejor',
  titleImage: pregnancy,
  titleImageAlt: 'pregnant person silouhette',
  description:
    'Sentirse sola durante el embarazo es habitual, incluso para las mujeres que ya han dado a luz antes. La soledad puede darse en cualquier etapa del embarazo. Puede afectar tanto al bienestar de la madre como al del bebé en crecimiento, por lo que es importante abordarla.\n\nHe aquí algunos consejos para encontrar apoyo y reducir la soledad y el aislamiento durante el embarazo.',
  contentId: 14,
  sections: [
    {
      key: 'pregnancy-talk-about-it-es',
      header: 'Habla de ello',
      description:
        'Habla con tu pareja, tus amigos, tu familia, otras mujeres embarazadas o tu proveedor sanitario y comparte tus sentimientos abiertamente. Aunque parezca una tontería, permítete expresar tus sentimientos. Te ayudará a controlar los niveles de estrés y a reducir la sensación de aislamiento.',
    },
    {
      key: 'pregnancy-support-group-es',
      header: 'Únete a un grupo de apoyo al embarazo',
      description:
        'Puedes encontrar muchos grupos para mujeres embarazadas en las comunidades locales o en línea. Encontrar una «tribu» formada por otras mujeres embarazadas a través de reuniones informales o clases de ejercicio en grupo puede ser una forma estupenda de conectar y compartir experiencias.\n\nNOTA: Recuerda que puedes hablar con tu miembro del personal de apoyo (ANDY) de Pyx Health. Puede brindarte comprensión y empatía, y ayudarte a encontrar los recursos que tu plan de salud tiene disponibles. Utiliza el botón «Call ANDY» (Llamar a ANDY) en tu aplicación de Pyx Health o llámalo al 1-855-499-4777.',
    },
    {
      key: 'pregnancy-bump-time-es',
      header: 'Hora de la barriga',
      description:
        'Dedícale tiempo a tu barriga. A partir de las 15 semanas, el bebé es capaz de oír voces. Prueba a cantarle a tu barriga, a leerle en voz alta o a daros un baño caliente «juntos». Pintar tu barriga de embarazada con pinturas respetuosas con el cuerpo es una forma especial de conectar con tu bebé, ya sea con otras mujeres embarazadas o a solas en casa. Se sabe que los bebés reaccionan a este tipo de situaciones de juego con su mamá. Es una forma divertida de conectar y ayudar a potenciar los sentimientos positivos.',
    },
    {
      key: 'pregnancy-online-help-es',
      header: 'Ayuda en línea',
      description:
        'Para aliviar tu mente, busca en Internet videos o pódcast sobre temas relacionados con el embarazo. Encontrarás información sobre lo que ocurre durante cada semana de embarazo proporcionada por profesionales médicos, comadronas, mamás experimentadas y profesionales de la salud natural.',
    },
    {
      key: 'pregnancy-take-break-es',
      header: 'Tómate un descanso',
      description:
        'Haz algo que te ayude a distraer tu mente del embarazo durante un rato. Puede ser algo activo o no, desde un rato de mimos en casa o una respiración consciente, hasta ver una película que te haga sentir bien o ponerte al día con tus amigos durante la comida. Tomar un descanso mental puede ayudar a interrumpir los sentimientos desagradables de soledad.',
    },
    {
      key: 'pregnancy-bottom-line-es',
      header: 'Conclusión',
      description:
        'Sé amable contigo misma. Es normal que te sientas sola o que tengas altibajos emocionales de vez en cuando. Aquí tienes unas cuantas afirmaciones positivas que te ayudarán a mantener un equilibrio:',
      itemsHeader: 'Afirmaciones sobre el embarazo',
      items: [
        {
          text: 'Mi cuerpo está hecho para esto',
        },
        {
          text: 'Soy resiliente, fuerte y valiente.',
        },
        {
          text: 'Soy capaz de hacer cosas increíbles.',
        },
        {
          text: 'Respiro profundamente y estoy tranquila.',
        },
        {
          text: 'Soy increíble, ¡estoy dando vida a un pequeño ser humano!',
        },
        {
          text: 'Soy una fuerza que tener en cuenta.',
        },
        {
          text: 'Estoy en un viaje, siempre creciendo y desarrollándome.',
        },
        {
          text: 'Me siento bien con lo que soy y me encanta en lo que me estoy convirtiendo.',
        },
        {
          text: 'Mi cuerpo me dice lo que necesita. Mi trabajo es escuchar.',
        },
        {
          text: 'Me encantan mis estrías de tigresa.',
        },
      ],
    },
  ],
};
