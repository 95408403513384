import styled from '@emotion/styled';
import { Card } from '@mui/material';

export const StyledCard = styled(Card)`
  position: relative;
  padding: 20px 28px;
  border-radius: 10px;
  width: 100%;
  max-width: 480px;
  overflow: visible;
`;
