import styled from '@emotion/styled';
import { legacyBackgroundColors } from 'Styles/colors';

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 12px;
`;

export const StyledLabel = styled.span`
  font-weight: 700;
`;

export const StyledInputContainer = styled.div`
  position: absolute;
  left: 42px;
  right: 0;
  border-bottom: 1px solid black;
  padding-left: 11px;
`;

export const StyledInput = styled.input`
  border: none;
  width: 100%;
  background: inherit;
`;

export const StyledTextButton = styled.button`
  border: none;
  background-color: inherit;
  padding: 0;
  font-size: 14px;
  color: ${legacyBackgroundColors.indigo};
  text-decoration: underline;
`;

export const StyledErrorText = styled.p`
  margin: -6px 14px 12px;
  color: #c92213;
  font-size: 0.75rem;
`;
