import React from 'react';
import Menu from './Menu';
import MenuSectionItem from './MenuSectionItem';
import MenuSection from './MenuSection';
import { transformText } from '../../../../Scripts/textHelper';

export default class MenuSectionManager {
  constructor(game, gameData, menuData) {
    this.game = game;
    this.gameData = gameData;
    this.menuData = menuData;
    this.menu = undefined;
    this.currentScreen = undefined;
    this.scrollHeight = 0;
    this.create();
  }

  updateScrollHeight = (newHeight) => {
    this.scrollHeight = newHeight;
  };

  create = () => {
    let menuSections = [];

    for (let i = 0; i < this.menuData.DATA.length; i++) {
      menuSections.push(this.createSection(i, this.menuData.DATA[i]));
    }

    this.menu = this.game.add.reactDom(Menu, {
      children: [],
    });

    return this.menu;
  };

  toggleMenuSecionData = (sceneMenuSectionType, disabled = false) => {
    let menuSections = [];

    for (let i = 0; i < this.menuData.DATA.length; i++) {
      let currentChangedData = this.menuData.DATA[i]?.MENUSECTIONITEMS?.find((x) => x.KEY === sceneMenuSectionType);

      if (currentChangedData) {
        currentChangedData.DISABLED = disabled ? disabled : !currentChangedData.DISABLED;
        currentChangedData.CURRENTTEXTID = currentChangedData.DISABLED
          ? currentChangedData.DISABLEDTEXTID
          : currentChangedData.DISABLED.TEXTID;
      }
      menuSections.push(this.createSection(i, this.menuData.DATA[i]));
    }

    const currentHeaderData = this.menuData.HEADERS.find((x) => x.ROWS.includes(this.currentScreen));
    let backItemData = this.menuData.BACKITEM;
    if (backItemData) {
      backItemData.TEXT = `< ${this.gameData.intl.formatMessage({ id: backItemData.TEXTID })}`;
    }

    this.menu.setState({
      title: transformText(this.gameData, currentHeaderData.TITLEID),
      description: currentHeaderData.TEXTID
        ? this.gameData.intl.formatMessage({ id: currentHeaderData.TEXTID })
        : undefined,
      hasWarning: currentHeaderData.HASWARNING,
      src: this.menuData.BACKGROUND,
      backlink: backItemData,
      minActorHeaderHeight: this.minActorHeaderHeight,
      updateScrollHeight: this.updateScrollHeight,
      children: menuSections,
    });

    return this.menu;
  };

  screenExists = (screen) => {
    return this.menuData.MENUSECTIONITEMS.find((x) => x.ROWS.includes(screen));
  };

  displayScreen = (screen, minActorHeaderHeight) => {
    let menuItems = [];

    this.currentScreen = screen;
    this.minActorHeaderHeight = minActorHeaderHeight;

    for (let i = 0; i < this.menuData.DATA.length; i++) {
      menuItems.push(this.createSection(i, this.menuData.DATA[i], this.menuData.DATA[i].disabled));
    }

    const currentHeaderData = this.menuData.HEADERS.find((x) => x.ROWS.includes(screen));
    let backItemData = this.menuData.BACKITEM;
    if (backItemData) {
      backItemData.TEXT = `< ${this.gameData.intl.formatMessage({ id: backItemData.TEXTID })}`;
    }

    this.menu.setState({
      title: transformText(this.gameData, currentHeaderData.TITLEID),
      description: currentHeaderData.TEXTID
        ? this.gameData.intl.formatMessage({ id: currentHeaderData.TEXTID })
        : undefined,
      hasWarning: currentHeaderData.HASWARNING,
      src: this.menuData.BACKGROUND,
      backlink: backItemData,
      minActorHeaderHeight: minActorHeaderHeight,
      updateScrollHeight: this.updateScrollHeight,
      children: menuItems,
    });

    return this.menu;
  };

  createSection = (id, sectionData, disabled = false) => {
    return (
      <MenuSection
        key={`${id}_menuSection`}
        disabled={disabled}
        title={this.gameData.intl.formatMessage({ id: sectionData.TEXTID })}
      >
        {sectionData?.MENUSECTIONITEMS?.map((item, i) => {
          return this.createItem(i, item, false);
        })}
      </MenuSection>
    );
  };

  createItem = (id, sectionItemData, disabled = false) => {
    const currentTextId = sectionItemData.CURRENTTEXTID ?? sectionItemData.TEXTID;
    return (
      <MenuSectionItem
        key={`${id}_menuSectionItem`}
        itemkey={sectionItemData.KEY}
        toggledisabled={this.toggledisabled}
        disabled={disabled || !sectionItemData.LINK || sectionItemData.DISABLED}
        icon={sectionItemData.SRC}
        style={sectionItemData.SRCSTYLE}
        imageStyle={sectionItemData.SRCIMAGESTYLE}
        onClick={sectionItemData.ONCLICK}
      >
        {transformText(this.gameData, currentTextId)}
      </MenuSectionItem>
    );
  };
}
