import { requestNotificationPermissions } from 'Core_Helpers/pushNotifications/pushNotifications';
import StorageService, { TOKEN_RESPONSE_KEY } from 'Core_Helpers/authentication/StorageService';
import { Singleton } from 'Helpers/Singleton';
import { Capacitor } from '@capacitor/core';

export class NotificationPromptStore extends Singleton {
  private isPushNotificationsPluginAvailable;
  public HasSeenPromptKey = 'has_seen_notification_prompt';

  constructor() {
    super();

    this.isPushNotificationsPluginAvailable = Capacitor.isPluginAvailable('PushNotifications');
  }

  public promptNativeNotificationAsync = async () => {
    const storageService = await StorageService.getInstance();
    const tokens = await storageService.getItem(TOKEN_RESPONSE_KEY);
    const hasSeenPrompt = await storageService.getItem(this.HasSeenPromptKey);

    if (this.isPushNotificationsPluginAvailable && tokens && Capacitor.isNativePlatform() && !hasSeenPrompt) {
      await requestNotificationPermissions();
      await storageService.setItem(this.HasSeenPromptKey, 'true');
    }
  };
}
