import React from 'react';
import PropTypes from 'prop-types';
import { ResultContext, ResultStates, ResultVariantTypes } from './ResultContext';
import Header from './SubResults/Header';
import Description from './SubResults/Description';
import Body from './SubResults/Body';
import Resources from './SubResults/Resources';
import { Forms } from '../forms';

const ResultVariants = {
  [ResultVariantTypes.Header]: Header,
  [ResultVariantTypes.Description]: Description,
  [ResultVariantTypes.Body]: Body,
  [ResultVariantTypes.Resources]: Resources,
};

const Result = ({ screeningGuid, variant, error, ...rest }) => {
  const FormResult = ResultVariants[variant];
  const state = error
    ? ResultStates.Error
    : !Forms[screeningGuid] || !Forms[screeningGuid][variant]
    ? ResultStates.Default
    : ResultStates.Found;

  return (
    <ResultContext.Provider
      value={{
        variant: variant || '',
        screeningGuid: screeningGuid || '',
        error: error || false,
        state: state,
      }}
    >
      <FormResult {...rest} />
    </ResultContext.Provider>
  );
};
Result.displayName = 'Result';

Result.Header = (props) => {
  return <Result variant={ResultVariantTypes.Header} {...props} />;
};
Result.Header.displayName = 'Header';

Result.Description = (props) => {
  return <Result variant={ResultVariantTypes.Description} {...props} />;
};
Result.Description.displayName = 'Description';

Result.Body = (props) => {
  return <Result variant={ResultVariantTypes.Body} {...props} />;
};
Result.Body.displayName = 'Body';

Result.Resources = (props) => {
  return <Result variant={ResultVariantTypes.Resources} {...props} />;
};
Result.Resources.displayName = 'Resources';

Result.propTypes = {
  screeningGuid: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(ResultVariants)).isRequired,
  error: PropTypes.bool,
};

export default Result;
