import React from 'react';
import MenuGreeting from 'Core_Pages/Menu/components/MenuGreeting/MenuGreeting';
import MenuContent from 'Core_Pages/Menu/components/MenuContent/MenuContent';

const Menu = () => {
  return (
    <>
      <MenuGreeting />
      <MenuContent />
    </>
  );
};

export default Menu;
