import { completingDailyTasks } from 'Assets/img/my-tools-icons';

export const completingDailyTasksEn = {
  key: 'completing-daily-tasks',
  title: 'Completing Daily Tasks',
  titleImage: completingDailyTasks,
  titleImageAlt: '',
  contentId: 32,
  sections: [
    {
      key: 'completing-daily-tasks-intro',
      description:
        'As a caregiver, you will most likely be called upon to provide support for both Activities of Daily Living (ADLs) and Instrumental Activities of Daily Living (IADLs). ADLs include activities such as assistance with bathing, toileting, ambulation and transfers, personal care, and dressing. IADLs include activities such as laundry, cleaning, grocery shopping and meal preparation, transportation, and companionship. This article provides some tips and ideas to think about when assisting with IADLs.',
    },
    {
      key: 'general-tips-for-assistance',
      header: 'General Tips for Assisting with Instrumental Activities of Daily Living',
      description:
        'Whenever possible, encourage independence and participation from the individual you are caring for when supporting them with IADLs. If they are an older adult, remember they have completed these tasks independently for decades and likely have a system and preferences for how things are done. Or perhaps the individual you are caring for has only temporarily lost the ability to complete some of these tasks due to an injury or some other event. Remaining patient with the person you are caring for, offering opportunities for them to participate, observing their abilities, and monitoring your own energy levels will help you both find a smooth path forward. Here are some of the more common IADLs you may be called upon to assist with, and some things to keep in mind as you complete them.',
    },
    {
      key: 'grocery-meal-prep',
      header: 'Grocery Shopping and Meal Preparation',
      description: 'Nutrition and a well-balanced diet are important for all of us!',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Create a meal plan and a shopping list with the input of the individual that you are caring for.',
        },
        {
          text: 'Several time saving options exist for getting groceries including delivery or pickup of items ordered online.',
        },
        {
          text: 'Depending on the individual’s abilities it may be fun to shop together or share recipes.',
        },
        {
          text: 'Removal of expired food is an important task to complete each week so try to build that into your routine.',
        },
      ],
    },
    {
      key: 'cleaning-household-chores',
      header: 'Cleaning and Household Chores',
      description:
        'Living situations and standards of cleanliness can vary widely from person to person. With older adults you may encounter hording or living situations that are not sanitary.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Try creating a checklist and schedule to break tasks down into a more manageable workload that suits your time and energy level.',
        },
        {
          text: 'Involve the person you are caring for in creating a plan and strive to complete tasks in accordance with their preferences.',
        },
        {
          text: 'Recognize that some tasks may not be appropriate or safe for you—shoveling snow, cleaning gutters, washing high or tall windows—and confidently set limits with the person you are caring for as needed.',
        },
        {
          text: 'Involve other service providers for a “deep clean” or to assist with decluttering could be a tremendous help, or perhaps there are local volunteer organizations that could assist.',
        },
        {
          text: 'Contact your local area agency on aging and collaborating with any healthcare providers involved in the home may be a great starting place for resources and support.',
        },
      ],
    },
    {
      key: 'laundry',
      header: 'Laundry',
      description:
        'Collecting and folding laundry and changing bedsheets can be one of the most physically challenging tasks in the home.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Remember to use good body mechanics - especially if the washer and dryer is on a different level in the home.',
        },
        {
          text: 'Include the individual you are caring for in some of the laundry folding like washcloths and socks to encourage independence and meaning but not to put the individual in a position that could impact individuals experiencing chronic pain, as can collecting dirty laundry, or removing and replacing bed linens.',
        },
        {
          text: 'Make sure you always have fresh, clean clothes and regularly changed bed linens to help prevent skin breakdown, and they can be a great mood boost.',
        },
        {
          text: 'Create a laundry routine along with a sufficient supply of clothing and linens in case you fall behind.',
        },
      ],
    },
    {
      key: 'transportation',
      header: 'Transportation',
      description:
        'It is possible the person you are caring for will have frequent medical appointments or opportunities for outings throughout the week.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Stay organized and scheduling outings close together or on the same day can reduce the feeling of overwhelm.',
        },
        {
          text: 'Have a transportation plan and know the supportive equipment and people that you need to help the individual get in and out of the vehicle.',
        },
        {
          text: 'Know the transportation resources available – especially if transporting the individual in the wheelchair is the only option.',
        },
        {
          text: 'Encourage outings as they can give you and the individual a break from your normal routines.',
        },
        {
          text: 'Schedule time for yourself during outings – such as finding a coffee hour or meet up group that regularly plays board games – when appointments or adult day cares are for extended periods of time.',
        },
      ],
    },
    {
      key: 'companionship',
      header: 'Companionship',
      description:
        'This activity can be hard to quantify as it is never really “complete.” Companionship can be one of the most overlooked and most valuable supports that you can provide. As human beings we are hardwired to communicate and connect with others. Try saving a few minutes during each visit to be intentional and connect. You may find that building rapport and trust with the individual you are caring for makes completing the other tasks easier.',
    },
  ],
};
