import styled from '@emotion/styled';
import { Card } from '@mui/material';

export const StyledMenuCard = styled(Card)`
  position: absolute;
  top: 40px;
  right: 0;
  overflow: hidden;
  border-radius: 14px;
  width: 100px;
  z-index: 1;
`;

export const StyledMenuOption = styled.button`
  width: inherit;
  padding: 8px 21px;
  font-size: 12px;
  background-color: white;
  color: black;
  border-bottom: 1px solid #ececec;
  :last-child {
    border-bottom: none;
  }
`;
