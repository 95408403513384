import styled from '@emotion/styled';
import { ArrowUpward } from '@mui/icons-material';
import { black } from 'Styles/colors';
import { breakpoints } from 'Styles/theme';

export const StyledHeader = styled.div`
  background: linear-gradient(to top left, #60a945, #7df352);
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  min-height: 60px;
`;

export const ExploreContainer = styled.div`
  margin: 0px 0px 2px 0px;
`;

export const EndSessionContainer = styled.div`
  border-radius: 4px;
  border: 2px solid #ffffff;
  margin-right: 14px;
  padding: 4px;
`;

export const StyledMessageInputContainer = styled.div(
  ({ theme }) => `
    border-radius: 19px;
    border: 2px solid #979797;
    background-color: white;
    min-height: 38px;
    width: 90%;
    position: fixed;
    bottom: 10px;
    margin: 0px 10px 10px 4px; 

    ${theme.breakpoints.up(breakpoints.DESKTOP)} {
      width: 325px;
    }
  `,
);

export const StyledTypingBubbleWrapper = styled.div`
  float: left;
  padding-left: 10px;
  padding-top: 10px;
`;

export const StyledArrowUpward = styled(ArrowUpward)`
  border-radius: 50%;
  background-color: ${(props) => (props.isdirty ? '#60A945' : '#F0EEEF')};
  color: ${(props) => (props.isdirty ? '#ffffff' : `${black._60}`)};
`;
