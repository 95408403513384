import React from 'react';
import { StyledContainer, StyledParagraphStacked } from 'Core_Pages/Pets/featureStyles';
import PropsTypes from 'prop-types';
import dog from 'Assets/img/adoption-dog-667.png';
import cat from 'Assets/img/adoption-cat-667.png';
import banner from 'Assets/img/adoption-center-banner-text-667.png';
import bannerEs from 'Assets/img/adoption-center-banner-text-667-spanish.png';
import { useHistory } from 'react-router-dom';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import {
  StyledCatButton,
  StyledDogButton,
  StyledHeaderImage,
  StyledImageContainer,
  StyledSelectionContainer,
} from 'Core_Pages/Pets/AdoptionCenter/styles';
import { useIntl, FormattedMessage } from 'react-intl';
import { PetsConfig } from 'Core_Pages/Pets/PetsConfig';
import { ScreenReaderLegend } from 'Styles/ScreenReaderOnly';
import { useCoreSelector } from 'Contexts/StoreContext';
import { selectLanguage } from 'Core_Redux/user';

function AdoptionCenter({ pet, setPet, userFirstName }) {
  const language = useCoreSelector(selectLanguage);
  const history = useHistory();
  const intl = useIntl();

  function handleDogAdoption() {
    setPet({ ...pet, petType: PetsConfig.types.dog });
    history.push(paths.DOGADOPTION);
  }

  function handleCatAdoption() {
    setPet({ ...pet, petType: PetsConfig.types.cat });
    history.push(paths.CATADOPTION);
  }

  return (
    <StyledContainer>
      <StyledHeaderImage
        alt={intl.formatMessage({ id: 'pets.adoption-center.banner' })}
        src={language == 'es' ? bannerEs : banner}
      />
      <StyledParagraphStacked>{`${intl.formatMessage({
        id: 'pets.adoption-center.header',
      })} ${userFirstName}.`}</StyledParagraphStacked>
      <StyledParagraphStacked>{intl.formatMessage({ id: 'pets.adoption-center.tap-to-adopt' })}</StyledParagraphStacked>
      <fieldset>
        <ScreenReaderLegend>
          <FormattedMessage id="pets.adoption-center.tap-to-adopt" />
        </ScreenReaderLegend>
        <StyledSelectionContainer>
          <StyledDogButton variant={'outlined'} onClick={handleDogAdoption}>
            <StyledImageContainer>
              <img src={dog} alt={intl.formatMessage({ id: 'pets.adoption-center.dog.image' })} />
            </StyledImageContainer>
          </StyledDogButton>
          <StyledCatButton variant={'outlined'} onClick={handleCatAdoption}>
            <StyledImageContainer>
              <img src={cat} alt={intl.formatMessage({ id: 'pets.adoption-center.cat.image' })} />
            </StyledImageContainer>
          </StyledCatButton>
        </StyledSelectionContainer>
      </fieldset>
    </StyledContainer>
  );
}

AdoptionCenter.propTypes = {
  pet: PropsTypes.object,
  setPet: PropsTypes.func,
  userFirstName: PropsTypes.string,
};

export default AdoptionCenter;
