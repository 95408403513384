import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import {
  navigateBack,
  selectSignupMetadata,
  completeFavoriteHolidayEntry,
  selectUserId,
  completeSignup,
  startHowDidYouHearAboutUsSurvey,
} from 'Onboard_Redux/signup';
import { useGetPendingHiFriendSurveyQuery } from 'Onboard_Redux/services/api';
import SignUpProgressBar from 'Onboard_Components/Account/SignUp/SignUpProgressBar';
import { Header, StyledTextInput } from 'Onboard_Components/Account/styles';
import { ArrowBackIos } from '@mui/icons-material';
import Button from 'Stories/components/Button';
import SkipButton from 'Onboard_Components/Account/SignUp/SkipButton';

function FavoriteHoliday() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const { progress } = useOnboardSelector(selectSignupMetadata);
  const { userId } = useOnboardSelector(selectUserId);
  const { data: requiresSurvey, isLoading: isLoadingSurvey } = useGetPendingHiFriendSurveyQuery(userId);
  const {
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
  } = useForm();
  const [isCompletePending, setCompletePending] = useState(false);

  function onBack() {
    dispatch(navigateBack());
  }

  function onSkip() {
    setCompletePending(true);

    if (requiresSurvey) {
      dispatch(startHowDidYouHearAboutUsSurvey());
    } else {
      dispatch(completeSignup({ userId }));
    }
  }

  const formComplete = dirtyFields.favoriteHoliday;

  function onSubmit(data) {
    setCompletePending(true);
    dispatch(
      completeFavoriteHolidayEntry({
        favoriteHoliday: data?.favoriteHoliday,
      }),
    );

    if (requiresSurvey) {
      dispatch(startHowDidYouHearAboutUsSurvey());
    } else {
      dispatch(completeSignup({ userId }));
    }
  }

  return (
    <>
      <SignUpProgressBar progressValue={progress} />
      <Header>
        <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />
        {intl.formatMessage({ id: 'account.sign-up.a-little-about-you' })}
      </Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="favoriteHoliday"
          control={control}
          rules={{
            maxLength: {
              value: 50,
              message: intl.formatMessage({ id: 'error.max-characters' }, { max: 50 }),
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <StyledTextInput
              {...field}
              id="favoriteHolidayInput"
              label={intl.formatMessage({ id: 'account.sign-up.favorite-holiday' })}
              errorMessage={errors.favoriteHoliday?.message}
            />
          )}
        />
        <Button
          fullWidth
          type="submit"
          disabled={!formComplete || isCompletePending}
          loading={isCompletePending || isLoadingSurvey}
          sx={{ margin: '8px 0' }}
        >
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
      <SkipButton disabled={isCompletePending || isLoadingSurvey} onClick={onSkip}>
        {intl.formatMessage({ id: 'common.skip' })}
      </SkipButton>
    </>
  );
}

export default FavoriteHoliday;
