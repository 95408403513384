import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import { DateTime } from 'luxon';
import { StyledContainer, StyledLabel, StyledInputContainer, StyledInput } from './styles';

function StartDateField({ control }) {
  const intl = useIntl();
  return (
    <Controller
      name="startDateUtc"
      control={control}
      defaultValue={DateTime.local().toISODate()}
      render={({ field: { value, onChange } }) => {
        const formattedDate = DateTime.fromISO(value).toISODate();
        return (
          <StyledContainer>
            <StyledLabel id="startDateInput">{intl.formatMessage({ id: 'reminders.start' })} *</StyledLabel>
            <StyledInputContainer>
              <StyledInput
                aria-labelledby="startDateInput"
                type="date"
                value={formattedDate}
                onChange={onChange}
                max="9999-12-30"
              />
            </StyledInputContainer>
          </StyledContainer>
        );
      }}
    />
  );
}

StartDateField.propTypes = {
  control: PropTypes.object.isRequired,
};

export default StartDateField;
