import React from 'react';
import PropTypes from 'prop-types';
import {
  StartGameContainer,
  StartGameButton,
  BannerSection,
  BannerImage,
  GameTitleSection,
  GameTitle,
  DirectionsHeader,
  DescriptionSection,
  DescriptionHeader,
  Description,
  ReturnHome,
  DirectionStep,
  StyledHaveFun,
} from './styles';
import { useIntl } from 'react-intl';

const StartGame = ({
  startNewGame,
  exitIntro,
  gameName,
  image,
  descriptionHeader,
  description,
  directionsHeader,
  directions,
  haveFun,
}) => {
  const intl = useIntl();

  return (
    <StartGameContainer data-testid="StartGameContainer">
      <BannerSection>
        <BannerImage alt="" src={image}></BannerImage>
      </BannerSection>
      <GameTitleSection>
        <GameTitle>{gameName}</GameTitle>
      </GameTitleSection>
      <DescriptionSection>
        <DescriptionHeader>{descriptionHeader}</DescriptionHeader>
        <Description>{description}</Description>
        <DirectionsHeader>{directionsHeader}</DirectionsHeader>
        {gameName === 'Sudoku' ? (
          <>
            <ul>
              {directions.split('.').map((step, stepNumber) => (
                <DirectionStep key={stepNumber}>{`${step}.`}</DirectionStep>
              ))}
            </ul>
            <StyledHaveFun>{haveFun}</StyledHaveFun>
          </>
        ) : null}
        <StartGameButton data-testid="StartGameButton" onClick={() => startNewGame()}>
          {intl.formatMessage({ id: 'games.play-game' })}
        </StartGameButton>
        <ReturnHome data-testid="ExitStartButton" onClick={() => exitIntro()}>
          {intl.formatMessage({ id: 'games.exit-home' })}
        </ReturnHome>
      </DescriptionSection>
    </StartGameContainer>
  );
};

StartGame.propTypes = {
  startNewGame: PropTypes.func,
  exitIntro: PropTypes.func,
  gameName: PropTypes.string,
  image: PropTypes.string,
  descriptionHeader: PropTypes.string,
  description: PropTypes.string,
  directionsHeader: PropTypes.string,
  directions: PropTypes.string,
  haveFun: PropTypes.string,
};

export default StartGame;
