/**
 * This service manages singleton instances. If an instance does not exist, it creates one. Otherwise, it returns the instance
 */
const SingletonManagementService = (() => {
  const instances = {};

  const getInstance = <Type extends Singleton>(type: { new (): Type }): Type => {
    return instances[type.name] || createInstance(type);
  };

  /** Creates a new instance.
   *
   * FAQ - Why use "type: { new (): Type }" and "... = new type()"?
   * See "Using Class Types in Generics" in https://www.typescriptlang.org/docs/handbook/2/generics.html
   */
  const createInstance = <Type extends Singleton>(type: { new (): Type }): Type => {
    const moduleInstance = new type();
    instances[type.name] = moduleInstance;
    return moduleInstance;
  };

  return {
    createInstance: <Type extends Singleton>(type: { new (): Type }) => getInstance(type),
  };
})();

/**
 * This is for classes that require single instances and use of generics, such as through inheritence,
 * with a focus on having the methods of the class visible through intellisense. The instance methods
 * are static methods, which can be used without first creating a new instance of the class.
 *
 * See "Using Class Types in Generics" in https://www.typescriptlang.org/docs/handbook/2/generics.html
 *
 */
export class Singleton {
  public static Instance<T extends Singleton>(this: { new (): T }): T {
    return SingletonManagementService.createInstance(this);
  }
}
