import {
  socialSupport,
  smallTalk,
  caringConnections,
  pyxPets,
  friendshipIngredients,
  bullies,
} from 'Assets/img/my-tools-icons';
import urlcat from 'urlcat';
import { PETS, STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';
import { itemTypes } from '../components/ToolCard/toolCardConfig';
import { userMetaTagCategories } from 'Core_Pages/MyTools/userMetaHelpers';

export const relationshipItems = [
  {
    icon: socialSupport,
    labelId: 'my-tools.social-support',
    to: urlcat(STATICCONTENT, { contentType: 'socialsupport' }),
    loggedEvent: 'Social_Support_Static_Content_Clicked',
  },
  {
    icon: caringConnections,
    labelId: 'my-tools.caring-connections',
    to: urlcat(STATICCONTENT, { contentType: 'threecaringconnections' }),
    loggedEvent: '3_Caring_Connections_Static_Content_Clicked',
  },
  {
    icon: smallTalk,
    labelId: 'my-tools.small-talk',
    to: urlcat(STATICCONTENT, { contentType: 'smalltalk' }),
    loggedEvent: 'Small_Talk_Static_Content_Clicked',
  },
  {
    icon: pyxPets,
    labelId: 'my-tools.pyx-pets',
    type: itemTypes.PLAY,
    to: PETS,
    loggedEvent: 'Pyx_Pets_Clicked',
  },
  {
    icon: friendshipIngredients,
    labelId: 'my-tools.friendship',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'friendship' }),
    loggedEvent: 'Friendship_Ingredients_Content_Clicked',
    contentCategory: userMetaTagCategories.YOUTH,
  },
  {
    icon: bullies,
    labelId: 'my-tools.bullying',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'bullying' }),
    loggedEvent: 'Bullying_Content_Clicked',
    contentCategory: userMetaTagCategories.YOUTH,
  },
];
