import React from 'react';
import PropTypes from 'prop-types';
import { SwitchToggle, Toggle } from './styles';

const ToggleSwitch = ({ id, name, checked, onChange, small, ariaRequired }) => {
  function handleKeyPress(e) {
    e.preventDefault();
    onChange(!checked);
  }

  return (
    <SwitchToggle
      id={id}
      size={small}
      checked={checked}
      data-testid={'ToggleSwitch'}
      name={name}
      aria-checked={checked}
      value={checked}
      onClick={handleKeyPress}
      role="switch"
      aria-required={ariaRequired}
    >
      <Toggle checked={checked} />
    </SwitchToggle>
  );
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  small: PropTypes.bool,
  ariaRequired: PropTypes.bool,
};

export default ToggleSwitch;
