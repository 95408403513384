import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'konva/lib/shapes/Wedge';
import 'konva/lib/shapes/Image';
import { Group, Wedge } from 'react-konva';
import DynamicImage from '../DynamicImage/DynamicImage';

const WheelWedge = ({ id, slice, rotation, radius, angle, iconSize, wedgeCount }) => {
  const defaultColor = '#fff';
  const [wedgeCenter, setWedgeCenter] = useState({ x: 0, y: 0 });
  const [wedgeConfig, setWedgeConfig] = useState({ image: '', text: '', fill: '' });

  useEffect(() => {
    setWedgeCenter(calculateWedgeCenter(radius, angle));
  }, [radius, angle]);

  useEffect(() => {
    setWedgeConfig({
      image: slice.Image,
      fill: slice.BackgroundColor ? slice.BackgroundColor.toLowerCase() : defaultColor,
      text: slice.Text,
    });
  }, []);

  // Source https://mathworld.wolfram.com/CircularSegment.html
  const calculateWedgeCenter = (radius, angle) => {
    const multiplier = 0.5;
    const r = radius * multiplier;
    const a = angle * multiplier;
    let x = r * Math.cos(multiplier * a);
    let y = 2 * r * Math.sin(multiplier * a);

    return { x, y };
  };

  return (
    <Group rotation={rotation}>
      <Wedge
        id={`${id}`}
        radius={radius}
        angle={angle}
        fill={wedgeConfig.fill}
        stroke={defaultColor}
        strokeWidth={3}
        text={wedgeConfig.text}
      />
      <DynamicImage
        rotation={(Math.PI + angle) / 2}
        image={wedgeConfig.image}
        x={wedgeCenter.x + iconSize}
        y={wedgeCenter.y - iconSize / wedgeCount}
        width={iconSize}
        height={iconSize}
      />
    </Group>
  );
};

WheelWedge.propTypes = {
  id: PropTypes.number.isRequired,
  slice: PropTypes.object.isRequired,
  rotation: PropTypes.number.isRequired,
  radius: PropTypes.number.isRequired,
  angle: PropTypes.number.isRequired,
  color: PropTypes.string,
  iconSize: PropTypes.number.isRequired,
  wedgeCount: PropTypes.number.isRequired,
};

export default WheelWedge;
