import { buildingSupportSystem } from 'Assets/img/my-tools-icons';

export const buildingSupportSystemEn = {
  key: 'building-support-system',
  title: 'Building a Support System',
  titleImage: buildingSupportSystem,
  titleImageAlt: '',
  contentId: 28,
  sections: [
    {
      key: 'building-support-system-intro',
      description:
        'Feeling lonely or isolated are normal and natural feelings as a caregiver. Do you find yourself wishing for companionship or that you had more support? Here are some tips to think about when building your support system.',
    },
    {
      key: 'find-other-caregivers',
      header: 'Find Other Caregivers',
      description:
        'Finding others with similar situations can be helpful to talk through and support each other through shared challenges.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Talking about your joys and challenges as a caregiver, who are also in similar situations, can offer validation and support',
        },
        {
          text: 'It can be valuable to find a more experienced caregiver who you can talk with about their experiences and what is and is not normal',
        },
        {
          text: 'Once you are a veteran caregiver, offering your advice to others who are just getting started on this journey could feel restorative for you and beneficial for them',
        },
        {
          text: 'There are support groups for caregivers led by mental health professionals – reach out to your doctor, home health provider, or anyone else involved in your loved one’s care to see if they have something to offer',
        },
      ],
    },
    {
      key: 'find-empathetic-listeners',
      header: 'Find Empathetic Listeners',
      description:
        'Finding others who are sensitive to your caregiving challenges and can hold an emotional space for you can be helpful.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'They can provide support to you with the energy, patience, and empathy that others who are caregivers may not have the ability to give',
        },
        {
          text: 'If you are having a hard time finding an empathetic listener, you can connect with a mental health therapist who can provide you with a safe space to express your emotions',
        },
        {
          text: 'Your doctor or health insurance provider is the best starting point to find a great mental health therapist, and we have additional resources in our app',
        },
      ],
    },
    {
      key: 'appreciation-support',
      header: 'Express Appreciation for Your Support',
      description: 'Once you find your support system, tell them how important they are to you.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'If you feel like someone is a support to you, let them know that – often',
        },
        {
          text: 'Tell them the importance they have in your life and the ways that they help you',
        },
        {
          text: 'It helps to let your support know that not only have they been important to you in the past, but they are also an important part of your support system moving forward',
        },
      ],
    },
    {
      key: 'toss-ego-take-help',
      header: 'Toss Your Ego and Take the Help',
      description:
        'Whether they say it or not, many people who are not caregivers have great empathy and respect for you as a caregiver and want to help you.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'If someone offers to help, take it!',
        },
        {
          text: 'Let them make you dinner',
        },
        {
          text: 'Let them come help clean your house or mow your lawn',
        },
        {
          text: 'Let them buy items for you, your loved one, or any things you may need',
        },
        {
          text: 'Let them know what you need or what is most helpful to you',
        },
      ],
    },
  ],
};
