import { supportingMentalHealthNeeds } from 'Assets/img/my-tools-icons';

export const supportingChildWithTraumaEn = {
  key: 'supporting-child-with-trauma',
  title: "Supporting My Child's Trauma",
  titleImage: supportingMentalHealthNeeds,
  titleImageAlt: '',
  contentId: 53,
  sections: [
    {
      key: 'supporting-child-with-trauma-intro',
      description:
        'Why is it important to learn about trauma? Trauma comes in very different shapes and sizes. The instability that a foster child feels can also induce a trauma response. Many events or circumstances in your child’s life prior to or during the time that they are with you are considered trauma. Viewing your child through a “trauma lens” can help you to better understand that some of their anger, outbursts, frustration, sadness, or loneliness can be an expression of trauma. Here are a few tips to help you support your child with their trauma.',
    },
    {
      key: 'physical-touch',
      header: 'Physical Touch',
      description:
        'You may or may not know if your child has been physically or sexually abused in the past. While human contact and touch is an important part of our lives, children who were abused in the past may have strong aversions to touch.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'It is always important to ask for consent before offering physical touch. This includes asking before offering a hug, holding their hand, patting them on the back, or any other type of physical contact.',
        },
        {
          text: 'As children work through their trauma, let them take the lead on any type of physical touch. They will be re-learning how to distinguish good touch from bad touch in their own therapeutic processing.',
        },
        {
          text: 'What you perceive as affectionate and appropriate touch may feel uncomfortable or even dangerous to an abused child.',
        },
        {
          text: 'If your child was abused or they are showing signs of being abused (including flinching when you come near them or any strong aversions to healthy touch) make sure they are receiving mental health therapy.',
        },
      ],
    },
    {
      key: 'create-safe-space',
      header: 'Create a Safe Space',
      description:
        'Create a plan with your child to discuss with them what they think may help them the most when they are angry, sad, anxious, or are experiencing an emotion that may be challenging to control. Helping your child to determine how they can help themselves is also very empowering! Here are some examples of how this could be done:',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'If your child has physical aggression, create an area where they can express their anger. For example, have a soft bat, pillows or cushions, a tumbling mat where they can go to physically release their anger safely',
        },
        {
          text: 'Guide your child through deep breathing or other relaxation activities – you will find some examples of relaxation activities on our app',
        },
        {
          text: 'Have “safe words” they can use when they need their space. For example, your child could choose a safe word, like “alligator”, if they are starting to feel mad and need to go sit in their room, or if they need your help to leave a situation.',
        },
        {
          text: 'Use relaxing scents such as lavender, mint, or orange in their “safe spaces”',
        },
        {
          text: 'Create an activity space that has fun and relaxing items where your child can go whenever they begin to feel angry. This can be a separate room or area of a room that has things like coloring books, a bean bag chair or a weighted blanket, sensory items such as kinetic sand, and some fun stuffed animals.',
        },
        {
          text: 'Use soft music to change the mood or tone',
        },
      ],
    },
    {
      key: 'learn-about-trauma',
      header: 'Learn about Trauma',
      description:
        'There are some great trainings and educational materials to help you better understand how trauma may be impacting your child.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Your foster agency can provide you with materials or classes that can help you learn about trauma',
        },
        {
          text: 'Talk with your child’s counselors or therapists to learn more about how they are helping your child and how you can support their efforts',
        },
      ],
    },
  ],
};
