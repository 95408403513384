import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Header } from 'Onboard_Components/Account/styles';
import { ArrowBackIos } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import {
  completeOtpEntry,
  navigate,
  selectRecoveryMethodInput,
  selectRecoveryMethod,
  selectBirthday,
} from 'Onboard_Redux/login';
import { FORGOT_CREDENTIAL } from 'Onboard_Components/Account/LogIn/steps';
import { OtpForm, OtpFooter } from 'Onboard_Components/Account/Otp';
import { useForgotPasswordMutation, useForgotPasswordVerifyOtpMutation } from 'Onboard_Redux/services/identity';
import Snackbar from 'Onboard_Components/Account/Snackbar';

function LogInOtp() {
  const intl = useIntl();
  const dispatch = useOnboardDispatch();
  const recoveryMethodInput = useOnboardSelector(selectRecoveryMethodInput);
  const recoveryMethod = useOnboardSelector(selectRecoveryMethod);
  const birthday = useOnboardSelector(selectBirthday);
  const [forgotPassword] = useForgotPasswordMutation();
  const [forgotPasswordVerifyOtp, { isLoading, error: incorrectCodeError }] = useForgotPasswordVerifyOtpMutation();
  const [codeSent, setCodeSent] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm();

  const otpVerificationError =
    incorrectCodeError?.status === 400 || (errors.otp && errors.otp.message)
      ? intl.formatMessage({ id: 'error.incorrect-code' })
      : null;

  function onBack() {
    dispatch(navigate({ target: FORGOT_CREDENTIAL }));
  }

  async function onContinue({ otp }) {
    await forgotPasswordVerifyOtp({ emailOrPhoneNumber: recoveryMethodInput, code: otp });
    dispatch(completeOtpEntry({ otp }));
  }

  function handleResend() {
    forgotPassword({ emailOrPhoneNumber: recoveryMethodInput, birthday }).then(() => setCodeSent(true));
  }

  const formComplete = !!dirtyFields.otp;

  return (
    <>
      <Header>
        <ArrowBackIos onClick={onBack} aria-label={intl.formatMessage({ id: 'common.back' })} />
        {intl.formatMessage({ id: 'account.otp.enter' })}
      </Header>
      <OtpForm
        control={control}
        loading={isLoading}
        formComplete={formComplete}
        onSubmit={handleSubmit(onContinue)}
        otpVerificationError={otpVerificationError}
        onResend={handleResend}
      />
      <OtpFooter sentTo={recoveryMethodInput} contactType={recoveryMethod} onBack={onBack} />
      <Snackbar
        message={intl.formatMessage({ id: 'account.otp.code-resent' })}
        open={codeSent}
        onClose={() => setCodeSent(false)}
      />
    </>
  );
}

export default LogInOtp;
