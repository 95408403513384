import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { breakpoints } from 'Styles/theme';

export const MyToolsGridContainer = styled(Grid)(
  ({ theme }) => `
  width: inherit;
  height: max-content;
  display: inherit;
  flex-flow: inherit;
  margin: inherit;
  margin-left: -16px;

  ${theme.breakpoints.up(breakpoints.DESKTOP)} { 
    margin-left: 0px;
  }
`,
);
