import {
  gratitudeLetter,
  threeGoodThings,
  celebrateProgress,
  habitTracker,
  greetingCard,
  coloringBook,
  mindfulEating,
  calmMind,
  reframeJudgements,
  stressReduction,
  pregnancy,
  control,
  lgbtqiaSocialMedia,
  thingAboutMe,
  affirmations,
  mindfulnessBalance,
} from 'Assets/img/my-tools-icons';
import urlcat from 'urlcat';
import { GREETINGCARDS, COLORINGBOOK, STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';
import * as permissions from 'Core_Helpers/authentication/PermissionsConfig';
import { itemTypes } from '../components/ToolCard/toolCardConfig';
import { userMetaTagCategories } from 'Core_Pages/MyTools/userMetaHelpers';

export const mindsetItems = [
  {
    icon: gratitudeLetter,
    labelId: 'my-tools.gratitude-letter',
    to: urlcat(STATICCONTENT, { contentType: 'gratitudeletter' }),
    loggedEvent: 'Gratitude_Letter_Static_Content_Clicked',
  },
  {
    icon: threeGoodThings,
    labelId: 'my-tools.three-good-things',
    to: urlcat(STATICCONTENT, { contentType: 'threegoodthings' }),
    loggedEvent: 'Three_Good_Things_Static_Content_Clicked',
  },
  {
    icon: celebrateProgress,
    labelId: 'my-tools.celebrate-your-progress',
    to: urlcat(STATICCONTENT, { contentType: 'celebrateprogress' }),
    loggedEvent: 'Celebrate_Progress_Static_Content_Clicked',
  },
  {
    icon: habitTracker,
    labelId: 'my-tools.habit-tracker',
    to: urlcat(STATICCONTENT, { contentType: 'habittracker' }),
    loggedEvent: 'Habit_Tracker_Static_Content_Clicked',
  },
  {
    icon: greetingCard,
    labelId: 'my-tools.greeting-cards',
    to: GREETINGCARDS,
    loggedEvent: 'Greeting_Cards_Clicked',
    permissions: permissions.CARDS,
  },
  {
    icon: coloringBook,
    labelId: 'my-tools.coloring-book',
    type: itemTypes.PLAY,
    to: COLORINGBOOK,
    loggedEvent: 'Coloring_Book_Launched',
  },
  {
    icon: mindfulEating,
    labelId: 'my-tools.mindful-eating',
    to: urlcat(STATICCONTENT, { contentType: 'mindfuleating' }),
    loggedEvent: 'Mindful_Eating_Static_Content_Clicked',
  },
  {
    icon: calmMind,
    labelId: 'my-tools.calm-your-mind',
    to: urlcat(STATICCONTENT, { contentType: 'calmyourmind' }),
    loggedEvent: 'Calm_Your_Mind_Static_Content_Clicked',
  },
  {
    icon: reframeJudgements,
    labelId: 'my-tools.reframe-judgements',
    to: urlcat(STATICCONTENT, { contentType: 'reframejudgements' }),
    loggedEvent: 'Reframe_Judgements_Static_Content_Clicked',
  },
  {
    icon: stressReduction,
    labelId: 'my-tools.stress-reduction',
    to: urlcat(STATICCONTENT, { contentType: 'stressreduction' }),
    loggedEvent: 'Stress_Reduction_Static_Content_Clicked',
  },
  {
    icon: pregnancy,
    labelId: 'my-tools.connection-in-pregnancy',
    to: urlcat(STATICCONTENT, { contentType: 'pregnancy' }),
    loggedEvent: 'Connection_In_Pregnancy_Content_Clicked',
    imageStyle: { paddingRight: '0px', marginBottom: '0px' },
  },
  {
    icon: control,
    labelId: 'my-tools.control',
    to: urlcat(STATICCONTENT, { contentType: 'stressmanagement' }),
    loggedEvent: 'Things_I_Can_And_Cant_Control_Content_Clicked',
  },
  {
    icon: lgbtqiaSocialMedia,
    labelId: 'my-tools.lgbtqiasocialmedia',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'lgbtqiasocialmedia' }),
    loggedEvent: 'LGBTQIA_Social_Media_Content_Clicked',
    contentCategory: userMetaTagCategories.YOUTH,
  },
  {
    icon: thingAboutMe,
    labelId: 'my-tools.thingsilikeaboutme',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'thingsilikeaboutme' }),
    loggedEvent: 'Thing_I_Like_About_Me_Content_Clicked',
    contentCategory: userMetaTagCategories.YOUTH,
  },
  {
    icon: affirmations,
    labelId: 'my-tools.affirmations',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'affirmations' }),
    loggedEvent: 'Affirmations_Content_Clicked',
    contentCategory: userMetaTagCategories.YOUTH,
  },
  {
    icon: mindfulnessBalance,
    labelId: 'my-tools.mindfulnessbalance',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'mindfulnessbalance' }),
    loggedEvent: 'Mindfulness_Balance_Content_Clicked',
    contentCategory: userMetaTagCategories.YOUTH,
  },
];
