import styled from '@emotion/styled';

export const TextContainer = styled.div`
  margin: 0 24px;
  text-align: center;
  height: 450px;
  max-width: 255px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: right;
`;
