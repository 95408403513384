import {
  memoryImpairments,
  adminstratingMedication,
  bathingAndToileting,
  buildingSupportSystem,
  buildingConfidence,
  caregiverBurnout,
  completingDailyTasks,
  caringForYourself,
  decidingHealthcareOptions,
  griefLossTransitions,
  knowingWhenYouNeedHelp,
  senseOfSelf,
  navigatingChallengingHealthcareSystem,
  relaxationActivities,
  rephrasingThoughts,
  supportingMentalHealthNeeds,
  cognitionImpairments,
} from 'Assets/img/my-tools-icons';
import { itemTypes } from '../components/ToolCard/toolCardConfig';
import urlcat from 'urlcat';
import { STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';

export const caregivingItems = [
  {
    icon: memoryImpairments,
    labelId: 'my-tools.memoryloss',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'memoryloss' }),
    loggedEvent: 'Memory_Loss_Content_Clicked',
  },
  {
    icon: adminstratingMedication,
    labelId: 'my-tools.administeringmedications',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'administeringmedications' }),
    loggedEvent: 'Administering_Medications_Content_Clicked',
  },
  {
    icon: bathingAndToileting,
    labelId: 'my-tools.bathingtoileting',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'bathingtoileting' }),
    loggedEvent: 'Bathing_and_Toileting_Content_Clicked',
  },
  {
    icon: buildingSupportSystem,
    labelId: 'my-tools.buildingsupportsystem',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'buildingsupportsystem' }),
    loggedEvent: 'Building_Support_System_Caregiver_Content_Clicked',
  },
  {
    icon: buildingConfidence,
    labelId: 'my-tools.buildingconfidence',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'buildingconfidence' }),
    loggedEvent: 'Building_Confidence_Caregiver_Content_Clicked',
  },
  {
    icon: caregiverBurnout,
    labelId: 'my-tools.caregiverburnout',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'caregiverburnout' }),
    loggedEvent: 'Caregiver_Burnout_Caregiver_Content_Clicked',
  },
  {
    icon: completingDailyTasks,
    labelId: 'my-tools.completingdailytasks',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'completingdailytasks' }),
    loggedEvent: 'Completing_Daily_Tasks_Content_Clicked',
  },
  {
    icon: caringForYourself,
    labelId: 'my-tools.caringforyourself',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'caringforyourself' }),
    loggedEvent: 'Caring_For_Yourself_Caregiver_Content_Clicked',
  },
  {
    icon: decidingHealthcareOptions,
    labelId: 'my-tools.decidinghealthcareoptions',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'decidinghealthcareoptions' }),
    loggedEvent: 'Deciding_Healthcare_Options_Content_Clicked',
  },
  {
    icon: griefLossTransitions,
    labelId: 'my-tools.experiencinggriefandloss',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'experiencinggriefandloss' }),
    loggedEvent: 'Grief_and_Loss_Caregiver_Content_Clicked',
  },
  {
    icon: knowingWhenYouNeedHelp,
    labelId: 'my-tools.knowingwhenyouneedhelp',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'knowingwhenyouneedhelp' }),
    loggedEvent: 'Knowing_When_You_Need_Help_Caregiver_Content_Clicked',
  },
  {
    icon: senseOfSelf,
    labelId: 'my-tools.senseofself',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'senseofself' }),
    loggedEvent: 'Maintaining_Sense_of_Self_Content_Clicked',
  },
  {
    icon: navigatingChallengingHealthcareSystem,
    labelId: 'my-tools.navigatinghealthcare',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'navigatinghealthcare' }),
    loggedEvent: 'Navigating_Challenging_Healthcare_System_Content_Clicked',
  },
  {
    icon: relaxationActivities,
    labelId: 'my-tools.relaxationactivities',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'relaxationactivities' }),
    loggedEvent: 'Relaxation_Activities_Caregiver_Content_Clicked',
  },
  {
    icon: rephrasingThoughts,
    labelId: 'my-tools.rephrasingthoughts',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'rephrasingthoughts' }),
    loggedEvent: 'Rephrasing_Thoughts_Caregiver_Content_Clicked',
  },
  {
    icon: supportingMentalHealthNeeds,
    labelId: 'my-tools.supportingindividualsmentalhealth',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'supportingindividualsmentalhealth' }),
    loggedEvent: 'Supporting_Individuals_Mental_Health_Content_Clicked',
  },
  {
    icon: cognitionImpairments,
    labelId: 'my-tools.understandingcognitiveimpairment',
    type: itemTypes.DO,
    to: urlcat(STATICCONTENT, { contentType: 'understandingcognitiveimpairment' }),
    loggedEvent: 'Understanding_Cognitive_Impairment_Content_Clicked',
  },
];
