import { decidingHealthcareOptions } from 'Assets/img/my-tools-icons';

export const decidingHealthcareOptionsEs = {
  key: 'deciding-healthcare-options-es',
  title: 'Opciones de cuidados de salud',
  titleImage: decidingHealthcareOptions,
  titleImageAlt: '',
  contentId: 33,
  sections: [
    {
      key: 'deciding-healthcare-intro-es',
      description:
        'Al cuidar a alguien con una enfermedad crónica (como un deterioro cognitivo, una enfermedad cardíaca o pulmonar, un trastorno neurológico o cáncer), puede resultar difícil entender cuáles son las mejores opciones de atención médica. Este artículo proporciona un resumen de algunos de los proveedores de atención médica que puedes utilizar y cómo decidir cuándo recurrir a ellos.',
    },
    {
      key: 'care-available-es',
      header: '¿Qué tipo de atención hay disponible?',
      description:
        'Si la persona a la que cuidas tiene una enfermedad crónica, es posible que ya estés trabajando con varios proveedores de atención médica. Un médico de atención primaria o un gerente de prestaciones médicas puede ayudarte a explorar las diferentes opciones disponibles. Siempre puedes llamar al médico de atención primaria o al gerente de prestaciones médicas de la persona para pedir ayuda y explorar o coordinar la atención que la persona puede estar recibiendo de otros proveedores.',
    },
    {
      key: 'immediate-care-needs-es',
      header: 'Necesidades de atención inmediata',
      description:
        'Si la persona a la que estás cuidando tiene una necesidad que pone en riesgo su vida, siempre debes llamar al 911 o ir al hospital. Sin embargo, existen varios escenarios que pueden requerir atención más rápida y es posible que no requieran atención en el hospital. Se recomienda mantener una lista actualizada de todos los médicos de la persona, incluidas todas las opciones para recibir atención, y tenerla en el refrigerador o en un lugar de fácil acceso para un uso rápido. Estas son algunas de las opciones que tener en cuenta que por lo general están disponibles y pueden ser una buena alternativa al uso del hospital:',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: '<b>Líneas de enfermería</b>: a menudo, el médico de atención primaria o el seguro médico tendrán una línea de enfermería que funciona las 24 horas, los 7 días de la semana, a la que puedes llamar para solicitar asesoramiento sobre el tipo de atención que se necesita. Ellos pueden decirte si necesitas ir al hospital o si un tratamiento en casa o una consulta de telesalud puede ser una buena opción.',
        },
        {
          text: '<b>Línea de crisis de salud conductual</b>: si tú o la persona a la que cuidas está experimentando una crisis de salud mental o uso de sustancias, puedes utilizar la línea de crisis nacional 988 (puedes marcar o enviar un mensaje de texto al 988). Muchos proveedores y seguros de salud (como Medicaid) también cuentan con un número al que puedes llamar.',
        },
        {
          text: '<b>Telesalud</b>: el médico de atención primaria, el seguro médico o el plan de Medicaid de la persona pueden tener una opción de telesalud que esté disponible fuera del horario de atención o incluso las 24 horas, los 7 días de la semana. Esta suele ser una excelente opción si necesitas un medicamento con rapidez o quieres saber si puedes brindar atención desde casa.',
        },
        {
          text: '<b>Atención de urgencia</b>: asegúrate de conocer las ubicaciones y los horarios de todas las opciones de atención de urgencia cercanas. La atención de urgencia suele ser una opción mejor y menos costosa, por ejemplo, si necesitas medicamentos para una infección.',
        },
        {
          text: '<b>Farmacia abierta las 24 horas</b>: conoce las ubicaciones y los horarios de tu farmacia preferida y la farmacia abierta las 24 horas más cercana que acepte el seguro de la persona.',
        },
      ],
    },
    {
      key: 'rehab-hospitilization-es',
      header: 'Rehabilitación después de una lesión u hospitalización',
      description:
        'Después de una lesión o si la persona a la que cuidas fue hospitalizada, el hospital puede recomendar terapia en un centro para ayudar a recuperarse y acercarse lo más posible al nivel de funcionamiento que tenía antes de la lesión o la hospitalización. Este suele ser un beneficio cubierto y puede ser un ambiente propicio para recibir atención de enfermería y terapia supervisada por un médico.',
    },
    {
      key: 'home-services-es',
      header: 'Servicios en el hogar',
      description:
        'Cada vez son más los servicios que pueden llegar hasta el hogar. Puede resultar útil saber qué servicios están disponibles en el hogar, especialmente si la persona a tu cuidado tiene dificultades para ser transportada fuera de casa. Por ejemplo, hay médicos de atención primaria a domicilio y otros especialistas que pueden ofrecer visitas en el hogar. Pregunta a tus proveedores actuales si esto es algo que ofrecen, o puedes preguntarle al gerente de prestaciones médicas si puede ayudarte a encontrar proveedores que puedan ir a la casa. Por lo general, puedes recibir enfermería y otras terapias en el hogar a través de una agencia de atención médica a domicilio. Si tienes preguntas sobre la atención y tienes una agencia de atención médica a domicilio en el hogar para enfermería o terapia, asegúrate de preguntar si puedes tener una reunión con un trabajador social. El trabajo social es un beneficio cubierto si recibes otros servicios y puede ser muy útil si tienes preguntas o te resulta difícil explorar las opciones de atención o las necesidades diarias de la persona.',
    },
  ],
};
