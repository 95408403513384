import React from 'react';
import PropTypes from 'prop-types';
import RudyParachute from 'Assets/img/rudy-parachute.gif';
import { LoadingContainer, Label } from './styles';
import { FormattedMessage, useIntl } from 'react-intl';

const FullScreenLoading = ({ showMessage }) => {
  const intl = useIntl();
  return (
    <div style={{ display: 'flex' }}>
      <LoadingContainer>
        <img alt={intl.formatMessage({ id: 'image.loading' })} style={{ width: '200px' }} src={RudyParachute} />
        {showMessage && (
          <Label>
            <FormattedMessage id="sync-data" />
          </Label>
        )}
      </LoadingContainer>
    </div>
  );
};

FullScreenLoading.propTypes = {
  showMessage: PropTypes.bool,
};

export default FullScreenLoading;
