import React, { useEffect, useContext } from 'react';
import * as paths from './RoutesConfig';
import { Route, Redirect } from 'react-router-dom';
import { selectIsAuthenticated, checkAuthRoute } from 'Onboard_Redux/authentication';
import { selectUserInfo, selectSyncUser } from 'Onboard_Redux/user';
import { browserHistory } from 'Onboard_Helpers/AppInsights';
import { useOnboardDispatch, useOnboardSelector } from 'Contexts/StoreContext';
import { isNative } from 'Onboard_Helpers/isNative';
import { SplashScreen } from '@capacitor/splash-screen';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import identityApi from 'Onboard_Redux/services/identity';

import { LogIn, SignUp } from 'Onboard_Pages/';
import { ExperienceContext } from 'Contexts/ExperienceContext';

const Routes = () => {
  const dispatch = useOnboardDispatch();
  const isAuthenticated = useOnboardSelector(selectIsAuthenticated);

  const userInfo = useOnboardSelector(selectUserInfo);
  const syncUser = useOnboardSelector(selectSyncUser);
  const experience = useContext(ExperienceContext);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(identityApi.endpoints.userInfo.initiate());
    } else {
      browserHistory.push(paths.SIGNUP);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(checkAuthRoute({ history: browserHistory, experienceUpdated: experience.experienceUpdatedAsync }));
  }, [userInfo, syncUser]);

  useEffect(() => {
    if (isNative()) {
      SplashScreen.hide();
    }
  }, []);

  return (
    <IonPage>
      <IonRouterOutlet>
        <Route path={paths.LOGIN}>
          <LogIn />
        </Route>

        <Route path={paths.SIGNUP}>
          <SignUp />
        </Route>

        <Redirect to={paths.SIGNUP} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Routes;
