import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import identityApi, { accountCreated } from './services/identity';
import api from './services/api';
import StorageService, { TOKEN_RESPONSE_KEY } from 'Core_Helpers/authentication/StorageService';
import { SIGNUP } from 'Core_Pages/Routes/RoutesConfig';
import { areTokensValid } from 'Core_Helpers/authentication/authTokens';
import { userOnboarded } from './user';
import { resetSignup } from './signup';
import { resetLoginState } from './login';
import { getAuthRedirect } from 'Core_Helpers/getAuthRedirect';
import AuthService from 'Core_Helpers/authentication/AuthService';
import { determineThriveWorkflowAndRedirect } from './thrive';

export const authenticationStatuses = {
  AUTHENTICATED: 'AUTHENTICATED',
  AUTHENTICATED_ONBOARD_IN_PROGRESS: 'AUTHENTICATED_ONBOARD_IN_PROGRESS',
  AUTHENTICATED_REQUIRES_CONFIRMATION: 'AUTHENTICATED_REQUIRES_CONFIRMATION',
  AUTHENTICATED_REQUIRES_ONBOARD: 'AUTHENTICATED_REQUIRES_ONBOARD',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
};

export const selectAuthenticationStatus = (state) => state.authentication.authenticationStatus;

export const authenticationStatusUpdated = createAsyncThunk(
  'authentication/authenticationStatusUpdated',
  async ({ history }, thunkApi) => {
    const { authentication, signup } = thunkApi.getState();
    const currentStatus = authentication.authenticationStatus;

    switch (currentStatus) {
      case authenticationStatuses.AUTHENTICATED_REQUIRES_CONFIRMATION:
        thunkApi.dispatch(accountCreated());
        thunkApi.dispatch(
          identityApi.endpoints.login.initiate({ username: signup.username, password: signup.password }),
        );
        break;
      case authenticationStatuses.AUTHENTICATED_REQUIRES_ONBOARD:
        thunkApi.dispatch(userConfirmed());
        break;
      case authenticationStatuses.AUTHENTICATED_ONBOARD_IN_PROGRESS:
        thunkApi.dispatch(userOnboarded());
        break;
      case authenticationStatuses.AUTHENTICATED:
        thunkApi.dispatch(identityApi.endpoints.userInfo.initiate());
        break;
      case authenticationStatuses.UNAUTHENTICATED:
      default:
        history.push(SIGNUP);
        break;
    }
  },
);

export const checkAuthRoute = createAsyncThunk('authentication/checkAuthRoute', async ({ history }, { getState }) => {
  const { authentication, user, signup, agreements } = getState();
  const { areTokensValid } = authentication;
  const { userInfo, syncUser, userVerified } = user;
  const { initialStep, status } = signup;
  const { shouldRedirectToAgreements } = agreements;
  const currentStep = signup.history.length ? signup.history.slice(-1)[0] : signup.initialStep;

  const nextRoute = getAuthRedirect({
    isAuthenticated: areTokensValid,
    userInfo,
    syncUser,
    userVerified,
    shouldRedirectToAgreements,
    currentPath: history.location.pathname,
    signupStatus: status,
    initialSignupStep: initialStep,
    currentSignupStep: currentStep,
  });

  if (nextRoute) {
    history.push(nextRoute);
  }
});

export const copyTokensToLocalStorage = createAsyncThunk(
  'authorization/copyTokensToLocalStorage',
  async ({ accessToken, refreshToken }) => {
    const storage = await StorageService.getInstance();
    const value = JSON.stringify({ accessToken, refreshToken });
    await storage.setItem(TOKEN_RESPONSE_KEY, value);
  },
);

export const copyTokensFromLocalStorage = createAsyncThunk(
  'authorization/copyTokensFromLocalStorage',
  async (_, { getState, dispatch }) => {
    const { authentication } = getState();
    const { accessToken, refreshToken } = authentication;

    // check for possible tokens in local storage when not in redux store
    if (!accessToken || !refreshToken) {
      //use storage service here to remove dependency on auth service from this function slice
      const storageService = await StorageService.getInstance();
      const storageTokens = await storageService.getItem(TOKEN_RESPONSE_KEY);

      if (storageTokens) {
        const parsedTokens = JSON.parse(storageTokens);
        await dispatch(identityApi.endpoints.refreshToken.initiate({ refreshToken: parsedTokens.refreshToken }));
      }
    }
  },
);

export const userConfirmed = createAsyncThunk('authentication/userAuthenticatedConfirmed', async (_, api) => {
  const { authentication } = api.getState();

  if (
    !authentication?.areTokensValid &&
    authentication?.authenticationStatus === authenticationStatuses.AUTHENTICATED_REQUIRES_ONBOARD
  ) {
    await api.dispatch(identityApi.endpoints.userInfo.initiate());
  }
});

export const confirmedUserUpdated = createAsyncThunk('authentication/refreshUserToken', async (_, api) => {
  const { authentication, user } = api.getState();
  const { tokenRefreshed } = user;

  if (!tokenRefreshed) {
    await api.dispatch(identityApi.endpoints.refreshToken.initiate({ refreshToken: authentication.refreshToken }));
    await api.dispatch(identityApi.endpoints.userInfo.initiate());
  }
});

export const retrievePermissionsToDetermineThrive = createAsyncThunk(
  'authentication/retrievePermissionsToDetermineThrive',
  async ({ history }, thunkApi) => {
    const { user } = thunkApi.getState();
    const authService = await AuthService.getInstance();
    await authService.tryRefreshTokens();
    thunkApi.dispatch(determineThriveWorkflowAndRedirect({ userId: user.userInfo.sub, history }));
  },
);

export const logout = createAsyncThunk('authorization/logout', async (_, thunkApi) => {
  thunkApi.dispatch(resetSignup());
  thunkApi.dispatch(resetLoginState());
  const storage = await StorageService.getInstance();
  storage.clear();
});

export const clearTokens = createAsyncThunk('authorization/clearTokens', async (_, thunkApi) => {
  thunkApi.dispatch(resetSignup());
  const storage = await StorageService.getInstance();
  storage.clear();
});

const initialState = {
  accessToken: null,
  refreshToken: null,
  areTokensValid: false,
  authenticationStatus: authenticationStatuses.UNAUTHENTICATED,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    tokenUpdated(state, { payload }) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addMatcher(
      isAnyOf(identityApi.endpoints.login.matchFulfilled, identityApi.endpoints.refreshToken.matchFulfilled),
      (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        state.areTokensValid = areTokensValid(payload.accessToken, payload.refreshToken);
        state.authenticationStatus = authenticationStatuses.AUTHENTICATED;
      },
    );
    builder.addMatcher(identityApi.endpoints.getSignUpTokens.matchFulfilled, (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    });
    builder.addMatcher(identityApi.endpoints.createAccount.matchFulfilled, (state) => {
      state.authenticationStatus = authenticationStatuses.AUTHENTICATED_REQUIRES_CONFIRMATION;
    });
    builder.addMatcher(identityApi.endpoints.verifyOTP.matchFulfilled, (state) => {
      state.authenticationStatus = authenticationStatuses.AUTHENTICATED_REQUIRES_ONBOARD;
    });
    builder.addMatcher(api.endpoints.onboard.matchFulfilled, (state) => {
      state.authenticationStatus = authenticationStatuses.AUTHENTICATED_ONBOARD_IN_PROGRESS;
    });
  },
});

export const { tokenUpdated } = authenticationSlice;

export default authenticationSlice.reducer;

export const selectIsAuthenticated = (state) => state.authentication.areTokensValid;
