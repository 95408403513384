import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import PageHeader from 'Core_Components/PageHeader/PageHeader';
import ButtonRow from 'Core_Pages/GreetingCards/ButtonRow/ButtonRow';
import GreetingCardPage from 'Core_Pages/GreetingCards/GreetingCardPage/GreetingCardPage';
import {
  HiddenCheckbox,
  StyledBackIcon,
  StyledGreetingCardHeader,
  StyledGreetingCardText,
  StyledTextContainer,
} from './styles';
import {
  StyledContainer,
  StyledParagraphStacked,
  StyledOutlineButton,
  StyledSolidButton,
} from 'Core_Pages/GreetingCards/featureStyles';
import PropTypes from 'prop-types';
import Spinner from 'Core_Components/Spinner/Spinner';
import useGetCardsMessages from 'Core_Hooks/useGetCardsMessages';
import { ScreenReaderWrapper, ScreenReaderLegend } from 'Styles/ScreenReaderOnly';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import { useHistory, useLocation } from 'react-router-dom';

function MessageSelection({ card, setCard }) {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const fetch = 10;
  const [offset, setOffset] = useState(0);
  const [messages, setMessages] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isLastRequest, setIsLastRequest] = useState(false);
  const [disabledPrevPage, setDisabledPrevPage] = useState(false);
  const [disabledNextPage, setDisabledNextPage] = useState(false);
  const { isLoading, getMessages } = useGetCardsMessages();
  const lastMessageIndex = messages.length - 1;
  const [selectedMessage, setSelectedMessage] = useState(false);

  async function fetchMessages() {
    let response = await getMessages({ fetch: fetch, offset: offset });
    if (response.data.length > 0) {
      setMessages(messages.concat(response.data));
    }
    return response.data;
  }

  useEffect(() => {
    fetchMessages().then((data) => {
      const fetchLength = fetch - 1;
      if (data.length === 0 || data.length % fetchLength > 0) {
        setIsLastRequest(true);
      }
    });
  }, [offset]);

  useEffect(() => {
    if (currentMessageIndex === lastMessageIndex && isLastRequest) {
      setDisabledNextPage(true);
    } else {
      setDisabledNextPage(false);
    }
    if (currentMessageIndex === 0) {
      setDisabledPrevPage(true);
    } else {
      setDisabledPrevPage(false);
    }
  }, [currentMessageIndex, lastMessageIndex, isLastRequest]);

  const forwardNav = () => {
    setSelectedMessage(false);
    if (currentMessageIndex !== lastMessageIndex) {
      setCurrentMessageIndex(currentMessageIndex + 1);
    }
    if (currentMessageIndex === lastMessageIndex && !isLastRequest) {
      if (!isLoading) {
        setCurrentMessageIndex(currentMessageIndex + 1);
        setOffset(offset + fetch);
      }
    }
  };

  const backwardNav = () => {
    setSelectedMessage(false);
    if (currentMessageIndex > 0) {
      setCurrentMessageIndex(currentMessageIndex - 1);
    }
  };

  const handleAddToCard = async () => {
    if (selectedMessage === true) {
      await setCard({
        ...card,
        messageId: messages[currentMessageIndex]?.id,
        message: messages[currentMessageIndex]?.text,
      });
      history.push({ pathname: paths.GREETINGCARDSUBMISSION });
    }
  };

  const generateMessagePage = (messages, index) => {
    let messageSplitNewline = messages[index]?.text.split(/\r\n|\r|\n/);
    return messageSplitNewline ? (
      <>
        <HiddenCheckbox
          type="checkbox"
          name="SelectedMessage"
          aria-checked={selectedMessage}
          tabindex="0"
          id={`SelectedMessage_${index}`}
          onClick={() => setSelectedMessage(!selectedMessage)}
          required
        />
        <label htmlFor={`SelectedMessage_${index}`}>
          <StyledGreetingCardHeader>{messageSplitNewline[0]}</StyledGreetingCardHeader>
          <StyledGreetingCardText>{messageSplitNewline.slice(1).join()}</StyledGreetingCardText>
        </label>
      </>
    ) : null;
  };

  const onBack = () => {
    const path = location?.state?.from ?? paths.GREETINGCARDSIMAGESELECTION;
    history.push({ pathname: path, state: { card } });
  };

  return (
    <StyledContainer>
      <ScreenReaderWrapper>
        <h1>
          <FormattedMessage id="greeting-cards.design.header" />
        </h1>
      </ScreenReaderWrapper>
      <PageHeader>{intl.formatMessage({ id: 'greeting-cards.design.step-message' })}</PageHeader>
      <StyledParagraphStacked>
        {intl.formatMessage({ id: 'greeting-cards.design.pick-message' })}
      </StyledParagraphStacked>
      <GreetingCardPage
        disableNextPage={disabledNextPage}
        disablePrevPage={disabledPrevPage}
        backNavButton={backwardNav}
        forwardNavButton={forwardNav}
        id="currentMessageIndex"
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <StyledTextContainer className={selectedMessage ? 'active' : ''} role="group">
            <ScreenReaderLegend>
              {intl.formatMessage({ id: 'greeting-cards.design.step-message' })},&nbsp;
              {intl.formatMessage({ id: 'greeting-cards.design.pick-message' })}
            </ScreenReaderLegend>
            {generateMessagePage(messages, currentMessageIndex)}
          </StyledTextContainer>
        )}
      </GreetingCardPage>
      <ButtonRow>
        {selectedMessage ? (
          <StyledOutlineButton onClick={onBack}>
            <StyledBackIcon aria-hidden="true" className="fas fa-chevron-left" />
            {intl.formatMessage({ id: 'common.back' })}
          </StyledOutlineButton>
        ) : (
          <StyledOutlineButton onClick={() => history.replace(paths.GREETINGCARDS)}>
            {intl.formatMessage({ id: 'greeting-cards.buttons.again-button' })}
          </StyledOutlineButton>
        )}
        <StyledSolidButton onClick={handleAddToCard} selected={selectedMessage} disabled={!selectedMessage}>
          {intl.formatMessage({ id: 'greeting-cards.buttons.add-to-card-button' })}
        </StyledSolidButton>
      </ButtonRow>
    </StyledContainer>
  );
}

MessageSelection.propTypes = {
  card: PropTypes.object.isRequired,
  setCard: PropTypes.func.isRequired,
};

export default MessageSelection;
