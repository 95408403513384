import styled from '@emotion/styled';
import { Select } from '@mui/material';

export const StyledContainer = styled.div`
  font-size: 14px;
`;

export const StyledRecurrenceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const StyledIconContainer = styled.div`
  display: none;

  @media (min-width: 360px) {
    display: flex;
    margin-right: 12px;
    span {
      font-size: 20px;
      padding: 10px 0;
    }
  }
`;

export const StyledIntervalSelect = styled(Select)`
  width: 100%;
  margin-right: 8px;
  padding-left: 112px;
  ::before {
    content: '${(props) => props.content}';
    padding: 6px 0 9px 4px;
  }
`;

export const StyledFrequencySelect = styled(Select)`
  .MuiSelect-root {
    padding-left: 4px;
  }
`;

export const StyledRecurrenceText = styled.div`
  margin-bottom: 20px;
`;
