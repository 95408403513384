import { gratitudeLetter } from 'Assets/img/my-tools-icons';

export const gratitudeLetterEs = {
  key: 'gratitude-letter',
  title: 'Carta de Agradecimiento',
  titleImage: gratitudeLetter,
  titleImageAlt: '',
  description:
    '¡Estar agradecido con los demás a través de una “carta de agradecimiento” es una excelente manera de ayudar a sentirse animado!',
  contentId: 4,
  sections: [
    {
      key: 'think-grateful',
      header: 'Piensa en alguien con quien te sientas agradecido en la vida.',
      description:
        'Hoy, piensa en alguien que haya tenido una influencia positiva en tu vida. ¿Quién es alguien que ha sido útil o amable contigo, o que actuó de una manera que te hizo sentir agradecido?',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Estas personas pueden ser un familiar, un amigo u otra persona, como un maestro, o tal vez un mesero, ¡o incluso un actor!',
        },
        {
          text: 'Piensa en cómo esta persona ha afectado tu vida... cómo es tu vida gracias a ella.',
        },
        { text: '¿Cómo te ha afectado su comportamiento?' },
        { text: '¿Cómo te sientes cuando piensas en ella?' },
      ],
    },
    {
      key: 'write-letter',
      header: 'Escribe una carta para decirle por qué estas agradecido.',
      description: 'Usa lápiz y papel, o escribe tu carta en una computadora/dispositivo.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Tu carta de agradecimiento no tiene por qué ser larga... ¡y la ortografía o la gramática no son importantes!',
        },
        {
          text: 'Dedica de 10 a 20 minutos para escribir la carta para expresar tu gratitud por lo que le dieron a tu vida.',
        },
        { text: 'Está bien empezar con 1 o 2 cosas por ahora.' },
        { text: '¡Puedes escribir una carta de agradecimiento tantas veces como quieras en tu Camino para triunfar!' },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Autorreflexión',
      description: 'Dedica un momento de tranquilidad para revisar tu experiencia al realizar esta actividad.',
      itemsHeader: 'Percepciones y marcadores del camino',
      items: [
        { text: '¿Cómo te sentías antes de completar este ejercicio?' },
        { text: '¿Cómo te sientes después de hacerlo?' },
        { text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?' },
        { text: '¿Qué disfrutaste más de este ejercicio?', divider: true },
        {
          text: 'Expresar gratitud a las personas que te hacen sentir bien brinda la oportunidad de mejorar las ventajas del bienestar y respalda los beneficios duraderos para la salud.',
        },
        {
          text: 'Cuando termines tu carta de agradecimiento, puedes enviársela a la persona o entregarla personalmente si lo deseas.',
        },
        {
          text: 'Y, si es posible, pídele a la persona que la lea cuando está contigo para compartir tu agradecimiento y hacer crecer aún más tu relación.',
        },
      ],
    },
  ],
};
