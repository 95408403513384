import React, { useEffect, useState } from 'react';
import { StyledButton, StyledContainer, StyledForm, StyledParagraphWithPadding } from 'Core_Pages/Pets/featureStyles';
import PageHeader from 'Core_Components/PageHeader/PageHeader';
import cat from 'Assets/img/adoption-cat-669.gif';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useCreatePet from 'Core_Hooks/useCreatePet';

function CatAdoption({ pet, setPet, setJustAdopted }) {
  const [name, setName] = useState('');
  const [shouldShowError, setShouldShowError] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const { createPet } = useCreatePet();
  const isInputEmpty = name.length < 1;

  useEffect(() => {
    if (shouldShowError && !isInputEmpty) {
      setShouldShowError(false);
    }
  }, [shouldShowError, name]);

  async function handleNamePet() {
    const namedPet = { ...pet, name };
    setPet({ ...pet, name });
    setJustAdopted(true);
    await createPet(namedPet);
    history.push(paths.PETSHELLO);
  }

  async function handleEnterKeyPress(event) {
    if (event.key === 'Enter' && !isInputEmpty) {
      event.preventDefault();
      await handleNamePet();
    } else if (event.key === 'Enter' && isInputEmpty) {
      event.preventDefault();
      setShouldShowError(true);
    }
  }

  return (
    <StyledContainer>
      <PageHeader>{intl.formatMessage({ id: 'pets.adoption-center.great-choice' })}</PageHeader>
      <StyledParagraphWithPadding>
        {intl.formatMessage({ id: 'pets.adoption-center.cat.paragraph' })}
      </StyledParagraphWithPadding>
      <StyledForm noValidate autoComplete="off">
        <TextField
          error={shouldShowError}
          helperText={shouldShowError && intl.formatMessage({ id: 'pets.adoption-center.name-error' })}
          onChange={(e) => setName(e.target.value)}
          id={intl.formatMessage({ id: 'pets.adoption-center.cat-name' })}
          label={intl.formatMessage({ id: 'pets.adoption-center.cat-name' })}
          variant={'outlined'}
          onKeyPress={(e) => handleEnterKeyPress(e)}
        />
      </StyledForm>
      <img alt={intl.formatMessage({ id: 'pets.adoption-center.cat.image' })} src={cat} />
      <StyledButton onClick={handleNamePet} disabled={isInputEmpty}>
        {intl.formatMessage({ id: 'common.next-with-arrow' })}
      </StyledButton>
    </StyledContainer>
  );
}

CatAdoption.propTypes = {
  pet: PropTypes.object,
  setPet: PropTypes.func,
  setJustAdopted: PropTypes.func,
};

export default CatAdoption;
