import { useState } from 'react';
import getAuthClient from 'Core_Helpers/getAuthClient';
import urlcat from 'urlcat';

const cardImagesUrl = 'v1/cards/images';

const getCardsImages = async (url) => {
  const authorizedApiClient = await getAuthClient();
  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetCardsImages = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getImages = async (options = {}) => {
    try {
      setIsLoading(true);
      const images = await getCardsImages(urlcat(process.env.API_BASE_URL, cardImagesUrl, options));
      setIsLoading(false);
      return images;
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  };

  return {
    isLoading,
    getImages,
  };
};

export default useGetCardsImages;
