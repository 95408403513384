import React from 'react';
import { useIntl } from 'react-intl';
import { Icon } from '@mui/material';
import { chatInactiveIcon } from 'Assets/img/talk-to-someone-icons';

const TalkIcon = () => {
  const intl = useIntl();
  return (
    <Icon>
      <img
        src={chatInactiveIcon}
        alt={intl.formatMessage({ id: 'menu-header.talk-to-someone.icon' })}
        style={{ height: '30px', margin: '3px' }}
      />
    </Icon>
  );
};

export default TalkIcon;
