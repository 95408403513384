import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import GoogleFontIcon from 'Core_Components/GoogleFontIcon/GoogleFontIcon';
import { StyledIconContainer } from './styles';

function ClearIcon({ onClick }) {
  const intl = useIntl();

  return (
    <StyledIconContainer onClick={onClick} aria-label={intl.formatMessage({ id: 'common.clear' })}>
      <GoogleFontIcon name={GoogleFontIcon.Name.Clear} />
    </StyledIconContainer>
  );
}

ClearIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ClearIcon;
