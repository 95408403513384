import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'Core_Components/PageHeader/PageHeader';
import { StyledOutlineButton, StyledSolidButton } from 'Core_Pages/GreetingCards/featureStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonRow from 'Core_Pages/GreetingCards/ButtonRow/ButtonRow';
import {
  HiddenRadio,
  Image,
  StyledContainer,
  StyledImageFieldset,
  StyledParagraph,
  RadioContainer,
} from 'Core_Pages/GreetingCards/ImageSelection/styles';
import GreetingCardPage from 'Core_Pages/GreetingCards/GreetingCardPage/GreetingCardPage';
import useGetCardsImages from 'Core_Hooks/useGetCardsImages';
import Spinner from 'Core_Components/Spinner/Spinner';
import { ScreenReaderWrapper, ScreenReaderLegend } from 'Styles/ScreenReaderOnly';
import { useHistory } from 'react-router-dom';
import * as paths from 'Core_Pages/Routes/RoutesConfig';

function ImageSelection({ setCard }) {
  const [selectedImage, setSelectedImage] = useState({
    imageId: undefined,
    storagePath: '',
    imageDescription: '',
  });
  const [offset, setOffset] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);
  const [disabledPrevPage, setDisabledPrevPage] = useState(true);
  const [disabledNextPage, setDisabledNextPage] = useState(false);
  const { isLoading, getImages } = useGetCardsImages();
  const intl = useIntl();
  const history = useHistory();

  async function fetchData() {
    const response = await getImages({ fetch: 4, offset: offset });
    setCurrentImages(response.data);
    return response.data;
  }

  useEffect(() => {
    fetchData().then((data) => {
      if (data.length < 4) {
        setDisabledNextPage(true);
      } else if (disabledNextPage) {
        setDisabledNextPage(false);
      }

      if (offset === 0) {
        setDisabledPrevPage(true);
      } else if (disabledPrevPage) {
        setDisabledPrevPage(false);
      }
    });
  }, [offset]);

  const displayImages = () => {
    return currentImages?.map((image, key) => {
      return (
        <RadioContainer active={+selectedImage.imageId === +image.id} key={key}>
          <HiddenRadio
            type="radio"
            name="ImageSelection"
            value={image.text}
            checked={+selectedImage.imageId === +image.id}
            aria-checked={+selectedImage.imageId === +image.id}
            id={`SelectedImage_${key}`}
            onChange={() => handleImageClick(image)}
            required
          />
          <label htmlFor={`SelectedImage_${key}`}>
            <Image id={image.id} src={image.storagePath} alt={image.text} role="img" />
          </label>
        </RadioContainer>
      );
    });
  };

  const handleImageClick = ({ id, storagePath, text }) => {
    setSelectedImage({ imageId: id, storagePath, imageDescription: text });
  };

  const backwardNav = () => {
    if (offset > 0) {
      setSelectedImage({ imageId: undefined, storagePath: '', imageDescription: '' });
      setCurrentImages([]);
      setOffset(offset - 4);
    }
  };

  const forwardNav = () => {
    setSelectedImage({ imageId: undefined, storagePath: '', imageDescription: '' });
    setCurrentImages([]);
    setOffset(offset + 4);
  };

  const handleAddToCard = () => {
    setCard(selectedImage);
    history.push({
      pathname: paths.GREETINGCARDSMESSAGESELECTION,
      state: { from: paths.GREETINGCARDSIMAGESELECTION },
    });
  };

  return (
    <StyledContainer>
      <ScreenReaderWrapper>
        <h1>
          <FormattedMessage id="greeting-cards.design.header" />
        </h1>
      </ScreenReaderWrapper>
      <PageHeader>{intl.formatMessage({ id: 'greeting-cards.design.step-image' })}</PageHeader>
      <StyledParagraph>{intl.formatMessage({ id: 'greeting-cards.design.pick-image' })}</StyledParagraph>
      <GreetingCardPage
        backNavButton={backwardNav}
        forwardNavButton={forwardNav}
        disablePrevPage={disabledPrevPage || isLoading}
        disableNextPage={disabledNextPage || isLoading}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <StyledImageFieldset role="radiogroup">
            <ScreenReaderLegend>
              {intl.formatMessage({ id: 'greeting-cards.design.step-image' })},&nbsp;
              {intl.formatMessage({ id: 'greeting-cards.design.pick-image' })}
            </ScreenReaderLegend>
            {displayImages()}
          </StyledImageFieldset>
        )}
      </GreetingCardPage>
      <ButtonRow>
        <StyledOutlineButton color="default" onClick={() => history.replace(paths.GREETINGCARDS)}>
          {intl.formatMessage({ id: 'greeting-cards.buttons.again-button' })}
        </StyledOutlineButton>
        <StyledSolidButton
          color="primary"
          onClick={handleAddToCard}
          selected={selectedImage?.imageId}
          disabled={!selectedImage?.imageId}
        >
          {intl.formatMessage({ id: 'greeting-cards.buttons.add-to-card-button' })}
        </StyledSolidButton>
      </ButtonRow>
    </StyledContainer>
  );
}

ImageSelection.propTypes = {
  setCard: PropTypes.func.isRequired,
};

export default ImageSelection;
