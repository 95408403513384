import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';
import { useCoreDispatch, useCoreSelector } from 'Contexts/StoreContext';
import { selectSyncUser } from 'Core_Redux/user';
import { sendUnsignedAgreementAcknowledgement, selectAgreements } from 'Core_Redux/agreements';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { TabContext } from '@mui/lab';
import { Tab, CircularProgress } from '@mui/material';
import ControlledAgreementCheckbox from 'Core_Pages/UnsignedAgreements/ControlledAgreementCheckbox';
import Button from 'Stories/components/Button';
import {
  PageContainer,
  ContentContainer,
  StyledTabs,
  TabContent,
  Title,
  Text,
  CircularProgressContainer,
} from './styles';
import { black } from 'Styles/colors';

const CenteredCircularProgress = () => (
  <CircularProgressContainer>
    <CircularProgress />
  </CircularProgressContainer>
);

const UnsignedAgreements = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useCoreDispatch();
  const { handleSubmit, control, watch } = useForm();
  const syncUser = useCoreSelector(selectSyncUser);
  const [acceptedAgreements, setAcceptedAgreements] = useState([]);
  const [tab, setTab] = useState('terms');
  const watchFields = watch();
  const formComplete = watchFields.termsAndConditions && watchFields.privacyPolicy;
  const agreements = useCoreSelector(selectAgreements);
  const [privacyAgreement, setPrivacyAgreement] = useState();
  const [termsAgreement, setTermsAgreement] = useState();
  const agreementType = {
    privacy: 5,
    terms: 6,
  };

  useEffect(() => {
    if (agreements && !agreements.agreementList?.length) {
      // list is empty send them back to home page
      returnToHome();
    } else {
      setPrivacyAgreement(
        agreements?.agreementList?.find((agreement) => agreement?.agreementTypeId === agreementType.privacy),
      );
      setTermsAgreement(
        agreements?.agreementList?.find((agreement) => agreement?.agreementTypeId === agreementType.terms),
      );
    }
  }, [agreements]);

  useEffect(() => {
    if (acceptedAgreements.length > 0) {
      dispatch(sendUnsignedAgreementAcknowledgement({ acceptedAgreements }));
    }
  }, [acceptedAgreements]);

  const returnToHome = async () => {
    history.replace(HOME);
  };

  const acceptAgreement = () => {
    let agreementsToAcknowledge = [];

    if (privacyAgreement) {
      agreementsToAcknowledge.push({
        'profile-id': syncUser.activeProfileId,
        'agreement-type': privacyAgreement.agreementTypeId,
        'agreement-id': privacyAgreement.id,
        initials: null,
      });
    }

    if (termsAgreement) {
      agreementsToAcknowledge.push({
        'profile-id': syncUser.activeProfileId,
        'agreement-type': termsAgreement.agreementTypeId,
        'agreement-id': termsAgreement.id,
        initials: null,
      });
    }

    setAcceptedAgreements(agreementsToAcknowledge);
  };

  const handleChange = (_, newTab) => setTab(newTab);

  return (
    <PageContainer>
      <ContentContainer>
        <Title>{intl.formatMessage({ id: 'account.sign-up.welcome-back' })}</Title>
        <Text>{intl.formatMessage({ id: 'agreements.welcome-back-text' })}</Text>
        <TabContext value={tab}>
          <StyledTabs value={tab} onChange={handleChange}>
            <Tab value="terms" label={intl.formatMessage({ id: 'agreements.terms-and-conditions_title' })} />
            <Tab value="privacy" label={intl.formatMessage({ id: 'agreements.privacy-policy_title' })} />
          </StyledTabs>
          <TabContent style={{ backgroundColor: '#ffffff', color: black._87 }} value="terms">
            {termsAgreement?.description ? termsAgreement?.description : <CenteredCircularProgress />}
          </TabContent>
          <TabContent style={{ backgroundColor: '#ffffff', color: black._87 }} value="privacy">
            {privacyAgreement?.description ? privacyAgreement?.description : <CenteredCircularProgress />}
          </TabContent>
        </TabContext>
        <form onSubmit={handleSubmit(acceptAgreement)}>
          <ControlledAgreementCheckbox
            id="termsAndConditionsInput"
            linkTo={termsAgreement?.contentHref ?? ''}
            control={control}
            fieldName="termsAndConditions"
            linkPrefix={intl.formatMessage({ id: 'agreements.agree-to.terms-and-conditions' })}
            linkLabel={intl.formatMessage({ id: 'agreements.terms-and-conditions' })}
            style={{ color: black._87 }}
          />
          <ControlledAgreementCheckbox
            id="privacyPolicyInput"
            linkTo={privacyAgreement?.contentHref ?? ''}
            control={control}
            fieldName="privacyPolicy"
            linkPrefix={intl.formatMessage({ id: 'agreements.agree-to.privacy-policy' })}
            linkLabel={intl.formatMessage({ id: 'agreements.privacy-policy' })}
            style={{ color: black._87 }}
          />
          <Button fullWidth disabled={!formComplete} type="submit" sx={{ marginTop: '8px' }}>
            {intl.formatMessage({ id: 'common.continue' })}
          </Button>
        </form>
      </ContentContainer>
    </PageContainer>
  );
};

export default UnsignedAgreements;
