import { IntlCache, IntlShape, createIntl, createIntlCache } from 'react-intl';
import en from 'Translations/en.json';
import es from 'Translations/es.json';
import { UserAccessor } from './UserAccessor';

export class LanguageService {
  private static _instance: LanguageService = new LanguageService();
  private cache: IntlCache;
  private intl: IntlShape;
  private userAccessor: UserAccessor;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (LanguageService._instance) {
      return LanguageService._instance;
    }
    // Intl usage outside of components: https://formatjs.io/docs/react-intl/api/#createintl
    this.userAccessor = new UserAccessor();
    this.cache = createIntlCache();
    this.intl = createIntl({ locale: this.userAccessor.User.Language, messages: this.Messages }, this.cache);
  }

  /**
   * Only use for the top-level of the app (when language may change)
   */
  public updateLanguage() {
    this.intl = createIntl({ locale: this.userAccessor.User.Language, messages: this.Messages }, this.cache);
  }

  private get Messages() {
    return this.userAccessor.User.Language == 'es' ? es : en;
  }

  public get Intl() {
    return this.intl;
  }
}
