import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';

export const StyledShapeClipping = styled.div`
  display: flex;
  position: relative;
  mask: url(${(props) => props.svg});
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 50%;
  justify-content: center;
  width: 100%;
`;

export const SegmentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Segment = styled.div`
  height: 100%;
  width: ${(props) => (props.widthPercentage ? `${props.widthPercentage}%` : '100%')};
`;

export const StyledTooltip = styled(Tooltip)`
  margin-bottom: -30%;
  background-color: none;

  .MuiTooltip-tooltip {
    margin-bottom: -30%;
    background-color: none;
  }
  &.MuiTooltip-tooltip {
    margin-bottom: -30%;
    background-color: none;
  }
  & .MuiTooltip-tooltip {
    margin-bottom: -30%;
    background-color: none;
  }

  .SegmentTooltip {
    margin-bottom: -30% !important;
    background-color: none !important;
  }
`;

export const SegmentLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
  color: white;
  width: auto;
  max-width: 100px;
  text-align: center;

  .SegmentLabel-text {
    font-weight: 700;
    font-size: 16px;
  }

  .SegmentLabel-arrow {
    font-size: 55px;
    margin-top: -15px;
  }
`;
