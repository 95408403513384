import { celebrateProgress } from 'Assets/img/my-tools-icons';

export const celebrateProgressEs = {
  key: 'celebrate-progress',
  title: 'Celebra tu Progreso',
  titleImage: celebrateProgress,
  titleImageAlt: '',
  description:
    'Hacer una pausa para celebrar tus logros te ayuda a reforzar los sentimientos positivos y el bienestar.',
  contentId: 8,
  sections: [
    {
      key: 'write-goal',
      header: 'Escribe algo que quieras conseguir: un objetivo, una ambición, un deseo, etc.',
      description: 'Usa lápiz y papel, o tu computadora/dispositivo.',
    },
    {
      key: 'scale-goal',
      header:
        'Luego crea una escala para determinar en qué punto te encuentras actualmente hasta alcanzar tu objetivo.',
      description: '',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Dibuja una línea horizontal y escribe en ella los números del 0 al 10. ',
        },
        {
          text: '“0” significa que aún no has empezado, y “10” significa que ya lo has completado.',
        },
        { text: 'Marca con un círculo el número en el que crees que estás para alcanzar tu objetivo.' },
      ],
    },
    {
      key: 'recall-steps',
      header: 'Recuerda los pasos que tomaste.',
      description:
        'Para los objetivos que pusiste más que “0”, tómate un momento para recordar los pasos que ya diste para conseguirlos y escríbelos.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Debajo de la escala de valoración, enumera los pasos, por pequeños o escasos que sean, que ya diste para alcanzar tu objetivo.',
        },
        { text: 'Puedes ser breve o tan detallado como gustes.' },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Autorreflexión',
      description: 'Tómate un tiempo para revisar tu lista y experiencia con esta actividad.',
      itemsHeader: 'Percepciones y marcadores del camino',
      items: [
        { text: '¿Cómo te sientes cuando piensas en los progresos que hiciste hasta ahora?' },
        { text: '¿Qué puedes hacer para celebrar incluso los éxitos más pequeños en las próximas semanas?' },
        { text: '¿Cuál de tus puntos fuertes personales te ha ayudado a llegar hasta aquí?' },
        { text: '¿Qué planes y actividades te han ayudado a llegar hasta aquí?' },
        {
          text: '¿Cómo puedes utilizar estos puntos fuertes, planes y actividades en tus próximos pasos para lograr acercarte a tu objetivo?',
        },
        { text: '¿Qué has aprendido de este ejercicio?' },
        { text: '¿Cómo te sentías antes de completar este ejercicio?' },
        { text: '¿Cómo te sientes después de hacerlo?' },
        { text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?' },
        { text: '¿Qué disfrutaste más de este ejercicio?' },
        { text: '¿En qué aspectos/elementos del desafío/objetivo deseas trabajar en primer lugar?' },
        { text: '¿Qué ayudaría a facilitar el primer paso?' },
        {
          text: '¿Cuáles serían algunos de los beneficios de que las personas realicen esta actividad con más frecuencia en general?',
          divider: true,
        },
        {
          text: 'Con cada paso que des hacia un nuevo objetivo, es importante hacer una pausa y celebrar tus logros mientras los vas consiguiendo.',
        },
        {
          text: 'Reconocer los éxitos del pasado y “asumir tu responsabilidad” en ellos te ayuda a crear energía positiva para avanzar hacia nuevos objetivos.',
        },
        {
          text: 'Celebra tus logros en una manera que sea significativa para ti. Puede ser algo pequeño, como detenerse a sonreír en señal de satisfacción y reconocimiento personal por un logro alcanzado, o tomarse una buena taza de café, o con algo más grande como tomarse un tiempo libre.',
        },
        {
          text: 'Es importante reconocer el progreso, por pequeño que sea, para lograr tu bienestar. Sé amable contigo mismo y disfruta de los logros, independientemente de la velocidad a la que avances.',
        },
      ],
    },
  ],
};
