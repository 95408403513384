import React from 'react';
import { Typography, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { StyledButton, StyledCard, StyledIcon } from './styles';
import { chatIcon } from 'Assets/img/talk-to-someone-icons';
import TalkToSomeoneCardStatus from './Status/TalkToSomeoneCardStatus';
import { DirectMessageStore } from 'Core_Pages/DirectMessage/Store/DirectMessageStore';

const MessagingCard = () => {
  const intl = useIntl();
  const directMessageStore = DirectMessageStore.Instance().Use();
  const isAgentOnline = directMessageStore.IsAgentOnline;
  const isChatOpen = directMessageStore.IsChatOpen;

  const onClick = () => directMessageStore.OpenChat();

  return (
    <StyledCard>
      <Grid container>
        <Grid item mobile={3}>
          <StyledIcon src={chatIcon} alt={intl.formatMessage({ id: 'talk-to-someone.messaging-icon' })} />
        </Grid>
        <Grid item mobile={9}>
          <Grid container marginTop={'16px'}>
            <Grid item mobile={6}>
              <Typography align="left" fontSize={18} fontWeight={700}>
                {intl.formatMessage({ id: 'talk-to-someone.messaging' })}
              </Typography>
            </Grid>
            <Grid item mobile={6}>
              <TalkToSomeoneCardStatus isOnline={isAgentOnline} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item mobile={10}>
              {isAgentOnline ? (
                <Typography align="left" fontSize={16} fontWeight={400}>
                  {intl.formatMessage({ id: 'talk-to-someone.messaging-caption' })}
                </Typography>
              ) : (
                <Typography align="center" fontSize={16} fontWeight={400} marginTop={'12px'}>
                  {intl.formatMessage({ id: 'talk-to-someone.hours-available' })}:<br />
                  8am-6pm
                </Typography>
              )}
            </Grid>
            <Grid item container mobile={12} direction="column" alignItems="flex-end" justify="flex-start">
              {isAgentOnline && (
                <StyledButton variant="contained" onClick={onClick} disabled={isChatOpen}>
                  {intl.formatMessage({ id: 'talk-to-someone.message-us' })}
                </StyledButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default MessagingCard;
