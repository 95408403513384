import { bodyAwareness } from 'Assets/img/my-tools-icons';

export const bodyAwarenessEs = {
  key: 'body-awareness',
  title: 'Conciencia Corporal',
  titleImage: bodyAwareness,
  titleImageAlt: '',
  description:
    'Tomar conciencia de las sensaciones físicas puede contribuir a mantener el equilibrio a lo largo del día.',
  contentId: 12,
  sections: [
    {
      key: 'quiet-comfortable',
      header: 'Busca un lugar tranquilo y ponte cómodo allí.',
      description: 'Ve a una zona en la que estés seguro y protegido y sin que te molesten durante un rato.',
      itemsHeader: 'Cuéntame más:',
      items: [
        {
          text: 'Selecciona un lugar en el interior o el exterior donde puedas estar relajado y cómodo.',
        },
        {
          text: 'Puedes tumbarte, sentarte en una silla o estar de pie.',
        },
        {
          text: 'Puedes tener los ojos abiertos o cerrados.',
        },
      ],
    },
    {
      key: 'deep-breaths-notice-body',
      header: 'Una vez que te sientas cómodo, respira profundamente y fíjate en cómo se siente tu cuerpo.',
      description:
        'Durante unos minutos, concéntrate en tu respiración; fíjate en cómo fluye y en cómo se siente tu cuerpo.',
      itemsHeader: 'Cuéntame más:',
      items: [
        { text: 'Inspira y expira lentamente de una manera que te resulte cómoda.' },
        {
          text: 'Al respirar, siente cómo el aire te llena los pulmones y luego imagínalo fluir hacia fuera y expandirse en tu cuerpo. ',
        },
        {
          text: 'Imagina que tu respiración fluye hacia el vientre, las piernas, los pies y los dedos de los pies... regresa al pecho, baja por los brazos y llega a las manos... y luego vuelve a subir a la cabeza, los hombros y el pecho.',
        },
        {
          text: 'Con cada respiración, relájate más profundamente y actúa como si estuvieras observando tranquilamente cómo el aire viaja por tu cuerpo.',
        },
        {
          text: 'Si tu mente se desvía, simplemente vuelve a centrar tu atención poco a poco en la respiración y en la parte del cuerpo en la que te concentraste por últimavez.',
        },
      ],
    },
    {
      key: 'body-scan',
      header: 'Haz una “exploración del cuerpo”.',
      description: 'A continuación, presta atención a cada una de las partes del cuerpo y a cómo se sienten.',
      itemsHeader: 'Cuéntame más:',
      items: [
        {
          text: 'Mientras sigues respirando y relajándote, traslada suavemente tu atención a la planta del pie izquierdo. Fíjate en cómo se siente. ¿Está relajada? ¿Tensa? ¿Suave? ¿Adolorida?',
        },
        {
          text: 'A continuación, dirige lentamente tu conciencia hacia el empeine, y luego hacia el tobillo. ¿Qué sientes ahí?',
        },
        {
          text: 'Continúa moviendo tu atención hacia la parte inferior de la pierna izquierda, la rodilla, el muslo y luego la pelvis; por delante, por detrás y a los lados.  Con suave curiosidad, observa cómo se siente cada zona sin esforzarte por sentir algo. Sólo tienes que asimilarlo y percibir cómo te hacen sentir.',
        },
        {
          text: 'Si notas un punto tenso o surgen sentimientos o pensamientos desagradables en alguna de esas zonas, respira profunda y lentamente e imagina que esa parte del cuerpo se relaja más. Imagina que esa sensación se libera y se va mientras la zona del cuerpo se relaja.',
        },
        {
          text: 'A continuación, mueve tu conciencia hacia el lado derecho de tu cuerpo y repite el proceso, explorando desde la planta del pie derecho y subiendo por la pierna.',
        },
        {
          text: 'Continúa moviendo lentamente tu conciencia hacia arriba, hacia el estómago, el pecho, elcuello, los brazos, los hombros, el nacimiento del cabello, la frente, la cabeza y la cara, asimilando cada área y su sensación.',
        },
        {
          text: 'Observa todas las sensaciones que surgen y suavemente deja que las desagradables se vayan mientras mueves tu conciencia por todo el cuerpo.',
        },
        {
          text: 'Cuando termines de explorar tu cuerpo, sigue respirando tranquilamente y trata de estar presente contigo mismo; aprecia la relajación durante un rato más. Tómate un momento para reconocerte como un ser vivo, que respira, íntegro y completo.',
        },
        {
          text: 'Cuando estés preparado, termina el proceso y vuelve a tomar conciencia del entorno que te rodea.',
        },
      ],
    },
    {
      key: 'self-reflection',
      header: 'Autorreflexión',
      description: 'Tómate un tiempo para revisar la experiencia con esta actividad.',
      itemsHeader: 'Percepción y conciencia',
      items: [
        { text: '¿Qué has aprendido de este ejercicio?' },
        { text: '¿Cómo te sentías antes de completar este ejercicio?' },
        { text: '¿Cómo te sientes después de hacerlo?' },
        { text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?' },
        { text: '¿Qué disfrutaste más de este ejercicio?' },
        { text: '¿Cuáles serían algunos de los beneficios de realizar esta actividad con más frecuencia en general?' },
      ],
    },
    {
      key: 'self-reflection-pointers',
      itemsHeader: 'Marcadores del camino',
      items: [
        {
          text: 'Tomarse un poco de tiempo para tomar conciencia de las sensaciones físicas puede ayudar a aquietar la mente, refrescar la conexión con uno mismo y favorecer el bienestar general.',
        },
        {
          text: 'Las actividades autoguiadas de exploración corporal y relajación pueden ayudar a potenciar la atención, reforzar la conciencia física y reconocer las señales que te permitan mantenerte libre de estrés y en equilibrio a lo largo del día.',
        },
        {
          text: 'Tómate un tiempo para relajarte y apreciar tu bienestar físico e íntegro en tu camino a la prosperidad.',
        },
      ],
    },
  ],
};
