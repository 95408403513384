import { andy, pyxir, screening, mytools } from 'Assets/img/thrive-icons';
import { badgeStatuses, badgeTypes } from './enums';

export const badges = {
  [badgeTypes.MYTOOLS]: {
    textId: 'title.my-tools',
    src: mytools,
    statuses: {
      [badgeStatuses.NOTSTARTED]: {
        altId: 'image.thrive.badge.my-tools-pale-incomplete',
      },
      [badgeStatuses.COMPLETE]: {
        altId: 'image.thrive.badge.my-tools-color',
      },
      [badgeStatuses.MISSED]: {
        altId: 'image.thrive.badge.my-tools-pale',
      },
    },
  },
  [badgeTypes.PYXIR]: {
    textId: 'title.pyxir-chat',
    src: pyxir,
    statuses: {
      [badgeStatuses.NOTSTARTED]: {
        altId: 'image.thrive.badge.pyxir-pale-incomplete',
      },
      [badgeStatuses.COMPLETE]: {
        altId: 'image.thrive.badge.pyxir-color',
      },
      [badgeStatuses.MISSED]: {
        altId: 'image.thrive.badge.pyxir-pale',
      },
    },
  },
  [badgeTypes.ANDYCALL]: {
    textId: 'thrive.andy-call',
    src: andy,
    statuses: {
      [badgeStatuses.NOTSTARTED]: {
        altId: 'image.thrive.badge.andy-pale-incomplete',
      },
      [badgeStatuses.COMPLETE]: {
        altId: 'image.thrive.badge.andy-color',
      },
      [badgeStatuses.MISSED]: {
        altId: 'image.thrive.badge.andy-pale',
      },
    },
  },
  [badgeTypes.SCREENING]: {
    textId: 'title.screening',
    src: screening,
    statuses: {
      [badgeStatuses.NOTSTARTED]: {
        altId: 'image.thrive.badge.screening-pale-incomplete',
      },
      [badgeStatuses.COMPLETE]: {
        altId: 'image.thrive.badge.screening-color',
      },
      [badgeStatuses.MISSED]: {
        altId: 'image.thrive.badge.screening-pale',
      },
    },
  },
};
