import Flower from 'Core_Pages/ColoringBook/svgs/Flower';
import Owl from 'Core_Pages/ColoringBook/svgs/Owl';
import Pyxir from 'Core_Pages/ColoringBook/svgs/Pyxir';
import Landscape from 'Core_Pages/ColoringBook/svgs/Landscape';
import Puppy from 'Core_Pages/ColoringBook/svgs/Puppy';
import Deer from 'Core_Pages/ColoringBook/svgs/Deer';
import Wolf from 'Core_Pages/ColoringBook/svgs/Wolf';
import Motorcycle from 'Core_Pages/ColoringBook/svgs/Motorcycle';
import Mandala from 'Core_Pages/ColoringBook/svgs/Mandala';
import React from 'react';

export default function getSvgImageConfig(imageKey, colors, onFillColor) {
  const imageList = {
    Flower: <Flower colors={colors} onFill={onFillColor} />,
    Owl: <Owl colors={colors} onFill={onFillColor} />,
    Pyxir: <Pyxir colors={colors} onFill={onFillColor} />,
    Landscape: <Landscape colors={colors} onFill={onFillColor} />,
    Puppy: <Puppy colors={colors} onFill={onFillColor} />,
    Deer: <Deer colors={colors} onFill={onFillColor} />,
    Wolf: <Wolf colors={colors} onFill={onFillColor} />,
    Motorcycle: <Motorcycle colors={colors} onFill={onFillColor} />,
    Mandala: <Mandala colors={colors} onFill={onFillColor} />,
  };

  return imageList[imageKey];
}
