import styled from '@emotion/styled';
import { Dialog, DialogContentText } from '@mui/material';
import Button from 'Stories/components/Button/Button';
import { breakpoints } from 'Styles/theme';

export const ErrorDialog = styled(Dialog)(
  ({ theme }) => `
  ${theme.breakpoints.up(breakpoints.TABLET)} {
    justify-content: start;
    flex-flow: column;
    padding-top: 175px;
  }

  .MuiDialog-paperScrollPaper {
    padding: 20px 25px 0px 25px;
    display: flex;
    flex-flow: column;
    row-gap: 15px;

    ${theme.breakpoints.up(breakpoints.TABLET)} { 
      width: 60%;
      max-width: 572px;
    }
  }

  .MuiTypography-root {
    line-height: 1.15em;
  }
`,
);

export const ErrorHeader = styled.div`
  row-gap: 10px;
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  padding-bottom: 10px;
`;

export const ErrorReason = styled(DialogContentText)`
  display: list-item;
  margin-left: 20px;
  font-size: 16px;
`;

export const AcknowledgeButton = styled(Button)`
  text-transform: uppercase;
  font-weight: 700;
`;
