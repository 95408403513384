import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './api/useAxiosSubmit/useAxiosSubmit';

const useSendCard = () => {
  const [cardRequest, setCardRequest] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored } = useAxiosSubmit(url, cardRequest, 0, 1);

  const sendCard = async (cardId, request) => {
    setUrl(`${process.env.API_BASE_URL}/v1/cards/${cardId}/transmissions`);
    setCardRequest(request);
  };

  return { cardSendInformation: response, cardSendError: hasErrored, cardSendIsPending: isLoading, sendCard };
};

export default useSendCard;
