import { appInsights } from 'Onboard_Helpers/AppInsights';

export const LOCAL = 'local';
export const TEST = 'test';
export const QA = 'qa';
export const STAGE = 'stage';
export const PRODUCTION = 'production';

export const isEnvironment = (environment) => {
  try {
    return !!process.env.ENVIRONMENT && environment === process.env.ENVIRONMENT;
  } catch (e) {
    //With the update to webpack 5, we can no longer polyfill the process.env variable
    //If process.env.ENVIRONMENT does not exist, it will throw an exception
    appInsights.trackException({ exception: e });
    return false;
  }
};
