import React, { useRef, useState, useEffect } from 'react';
import WheelSelect from 'Core_Components/Wheel/WheelSelect/WheelSelect';
import useElementResize from 'Core_Hooks/useElementResize';
import { WheelContainer, HeaderContainer, Header, Icon, Select, Button, ChevronButton } from './styles';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { appInsights } from 'Core_Helpers/AppInsights';
import {
  Angry,
  Bloated,
  Bored,
  Content,
  Depressed,
  Energetic,
  Excited,
  Good,
  Happy,
  Healthy,
  Hopeful,
  Hungry,
  Hurting,
  InPain,
  Lonely,
  Peaceful,
  Rested,
  Sad,
  Sick,
  Sleepy,
  Worried,
} from 'Assets/img/emoji-wheel';

const emojiMapping = {
  Angry,
  Bloated,
  Bored,
  Content,
  Depressed,
  Energetic,
  Excited,
  Good,
  Happy,
  Healthy,
  Hopeful,
  Hungry,
  Hurting,
  InPain,
  Lonely,
  Peaceful,
  Rested,
  Sad,
  Sick,
  Sleepy,
  Worried,
};

const colorMapping = {
  Yellow: '#ffff00',
  Orange: '#f69318',
  Green: '#559234',
  Teal: '#21928f',
  Blue: '#1475Bc',
  Purple: '#7c47aa',
  Violet: '#cf31a3',
  Red: '#da155b',
};

const Wheel = ({ messageText, responseChoices, onSubmit, isEnabled = true }) => {
  const defaultSize = 1000;
  // Any larger than 500 seems to make the wheel have a very large height
  const maxWidth = 500;
  const wheel = useRef();
  const nextButtonRef = useRef();
  const intl = useIntl();

  const { clientWidth } = useElementResize(wheel);

  const [wheelSize, setWheelSize] = useState({ x: defaultSize, y: defaultSize });
  const [selected, setSelected] = useState({ text: '' });
  const [emojiWheelData, setEmojiWheelData] = useState([]);
  const [wheelData, setWheelData] = useState([]);
  const [lastWidth, setLastWidth] = useState();
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    if (responseChoices[0]?.json) {
      try {
        appInsights.trackTrace({ message: 'Diagnostic: Parsing json object at Wheel:88' });
        const parsedWheel = JSON.parse(responseChoices[0].json);
        setWheelData(parsedWheel);
        setEmojiWheelData(
          parsedWheel.Slices.map((slice) => {
            slice.Image = emojiMapping[slice.Text.trim().replace(/\s/g, '')];
            slice.BackgroundColor = colorMapping[slice.BackgroundColor];
            slice.Text = intl.formatMessage({ id: slice.Text });
            return slice;
          }),
        );
      } catch (error) {
        appInsights.trackException({ exception: error });
      }
    } else {
      appInsights.trackTrace({ message: 'Diagnostic: Would have attempted to parse undefined object.' });
    }
  }, [responseChoices]);

  useEffect(() => {
    const calculatedWidth = clientWidth >= maxWidth ? maxWidth : clientWidth;
    // This accounts for the size of the styled scrollbar to prevent the screen shake
    if (!lastWidth || (lastWidth - clientWidth) % 10 !== 0) {
      setWheelSize({ x: calculatedWidth, y: calculatedWidth });
    }
    setLastWidth(clientWidth);
  }, [clientWidth]);

  const nextButtonClicked = () => {
    setRotation(rotation + 1);
    // Prevent losing focus on iOS devices when using hardware keyboards
    nextButtonRef?.current?.focus();
  };

  const onSelect = (selected) => {
    setSelected(selected);
  };

  const submitEmotion = () => {
    const interactionId = wheelData.Slices.find((el) => el.Text === selected.text)?.InteractionId;

    if (interactionId) {
      onSubmit({
        interactionId,
        encounterId: responseChoices[0].encounterId,
        ...selected,
      });
    }
  };
  return emojiWheelData ? (
    <WheelContainer>
      {messageText && (
        <HeaderContainer>
          <Header>
            <FormattedMessage id="wheel.instructions" />
          </Header>
          <ChevronButton
            data-testid="ChevronButton"
            aria-label={intl.formatMessage({ id: 'wheel.turn' })}
            onClick={nextButtonClicked}
            ref={nextButtonRef}
            disabled={!isEnabled}
          >
            <Icon aria-hidden="true" className="fal fa-chevron-down" />
          </ChevronButton>
        </HeaderContainer>
      )}
      <div ref={wheel}>
        <WheelSelect
          data-testid="WheelSelect"
          isEnabled={isEnabled}
          width={wheelSize.x}
          height={wheelSize.y}
          onSelect={onSelect}
          rotation={rotation}
          data={emojiWheelData}
        />
      </div>
      {isEnabled && (
        <Select aria-live="polite">
          <p style={{ margin: '0', fontSize: '18px' }} data-testid="SelectedEmoji">
            {selected && !!selected.text ? (
              `${selected.text}: ${selected.id} of ${emojiWheelData?.length}`
            ) : (
              <FormattedMessage id="wheel.select" />
            )}
          </p>
          <Button
            aria-label={intl.formatMessage({ id: 'common.submit-emoji' }) + ' ' + selected.text}
            onClick={submitEmotion}
          >
            {intl.formatMessage({ id: 'common.submit' })}
          </Button>
        </Select>
      )}
    </WheelContainer>
  ) : null;
};

Wheel.propTypes = {
  messageText: PropTypes.string,
  responseChoices: PropTypes.array,
  onSubmit: PropTypes.func,
  isEnabled: PropTypes.bool,
};

export default Wheel;
