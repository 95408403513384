export const ActivityType = {
  PHYSICAL: 'PHYSICAL',
  PLAYTIME: 'PLAYTIME',
  NUTRITION: 'NUTRITION',
  ATTENTION: 'ATTENTION',
  TREAT: 'TREAT',
  GOOUTSIDE: 'GOOUTSIDE',
  FRIENDTIME: 'FRIENDTIME',
};

export const InteractionType = {
  // Single Action
  FETCH: {
    NAME: 'FETCH',
    SCENE: 'CHASE',
    CODE: 'PLAYTIME',
    TYPE: ActivityType.PLAYTIME,
  },
  FEED: {
    NAME: 'FEED',
    SCENE: 'NUTRITION',
    CODE: 'FOOD',
    TYPE: ActivityType.NUTRITION,
  },
  HYDRATE: {
    NAME: 'HYDRATE',
    SCENE: 'NUTRITION',
    CODE: 'WATER',
    TYPE: ActivityType.NUTRITION,
  },
  ATTENTION: {
    NAME: 'ATTENTION',
    SCENE: 'ATTENTION',
    CODE: 'ATTENTION',
    TYPE: ActivityType.ATTENTION,
  },
  TREAT: {
    NAME: 'TREAT',
    SCENE: 'TREAT',
    CODE: 'TREAT',
    TYPE: ActivityType.TREAT,
  },
  // Storyboards
  STROLL: {
    NAME: 'STROLL',
    SCENE: 'STROLL',
    CODE: 'GOOUTSIDE',
    TYPE: ActivityType.GOOUTSIDE,
  },
  FRIENDTIME: {
    NAME: 'FRIENDTIME',
    SCENE: 'FRIENDTIME',
    CODE: 'NEWFRIEND',
    TYPE: ActivityType.FRIENDTIME,
  },
  // Storyboard Single Actions
  BUTTERFLYCHASE: {
    NAME: 'BUTTERFLYCHASE',
    SCENE: 'CHASE',
    CODE: 'PHYSICAL',
    TYPE: ActivityType.PHYSICAL,
  },
  // Menu
  ACTIVITYMENU: {
    NAME: 'ACTIVITYMENU',
    SCENE: 'ACTIVITYMENU',
    CODE: '',
    TYPE: '',
  },
  // ADOPTION
  SHOWNWELCOME: {
    CODE: 'SHOWNWELCOME',
  },
};
