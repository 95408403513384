import styled from '@emotion/styled';
import { Card, Tab } from '@mui/material';
import { breakpoints } from 'Styles/theme';

export const StyledCard = styled(Card)(
  ({ theme }) => `
  width: 147px;
  min-width: 147px;
  height: 147px;
  min-height: 147px;

  ${theme.breakpoints.up(breakpoints.TABLET)} { 
    width: 160px;
    min-width: 160px;
  }

  ${theme.breakpoints.up(breakpoints.DESKTOP)} { 
    width: 174px;
    min-width: 174px;
  }

  position: relative;
`,
);

export const ActivityTab = styled(Tab)`
  &.MuiTab-root {
    overflow: visible;
  }
`;
