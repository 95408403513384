import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, Typography } from '@mui/material';
import Button from 'Stories/components/Button/Button';
import { AcknowledgeButton, ErrorDialog, ErrorHeader, ErrorReason } from './styles';
import { useIntl } from 'react-intl';
import { black } from 'Styles/colors';

function ErrorModal({ open, onClose }) {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(open);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const onModalClose = () => {
    setModalOpen(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <ErrorDialog open={modalOpen} aria-labelledby="error-title">
        <ErrorHeader>
          <Typography id="error-title" component="h1" fontSize={18} color={black._87}>
            {intl.formatMessage({ id: 'screenings.error.header' })}
          </Typography>
          <Typography fontSize={16} color={black._60}>
            {intl.formatMessage({ id: 'screenings.error.description' })}
          </Typography>
        </ErrorHeader>
        <ErrorReason>{intl.formatMessage({ id: 'screenings.error.reasons.busy' })}</ErrorReason>
        <ErrorReason>{intl.formatMessage({ id: 'screenings.error.reasons.connection' })}</ErrorReason>
        <DialogActions>
          <AcknowledgeButton variant={Button.variant.TEXT} onClick={onModalClose}>
            {intl.formatMessage({ id: 'common.okay' })}
          </AcknowledgeButton>
        </DialogActions>
      </ErrorDialog>
    </>
  );
}

ErrorModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
ErrorModal.defaultProps = {
  open: false,
};

export default ErrorModal;
