import styled from '@emotion/styled';
import { TabList, TabPanel } from '@mui/lab';
import { black } from 'Styles/colors';

export const Tabs = styled(TabList)`
  min-height: auto;

  .MuiTabs-flexContainer {
    gap: 12px;
  }

  .MuiTab-root {
    padding: 4px;
    font-size: 14px;
    min-height: auto;

    &[aria-selected='false'] {
      color: ${black._87};
    }
  }
`;

export const TabContent = styled(TabPanel)`
  border: 1px solid #979797;
  padding: 8px 16px;
  flex: 1;
  overflow: auto;
  margin: 4px 0;
  color: #979797;
`;

export const CircularProgressContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
