import styled from '@emotion/styled';
import { Card, FormGroup } from '@mui/material';
import { teal } from 'Styles/colors';

export const StyledCard = styled(Card)`
  padding: 20px;
  &.FormQuestion-answer-checked {
    background-color: ${teal._300};
  }
`;

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
`;
