import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';
import TextInput from './TextInput';
import { InputProps } from './props';

const PhoneInput = forwardRef(({ onChange, ...props }, ref) => {
  return (
    <PatternFormat
      valueIsNumericString
      getInputRef={ref}
      type={InputProps.type.TEL}
      customInput={TextInput}
      placeholder="(___) ___-____"
      format="(###) ###-####"
      mask="_"
      onValueChange={(v) => onChange(v.value)}
      {...props}
    />
  );
});

PhoneInput.displayName = 'PhoneInput';

PhoneInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PhoneInput;
