import { animationType } from 'Core_Games/constants/animationType';
import { sceneType } from 'Core_Games/constants/sceneType';
import { uiTypes } from 'Core_Games/constants/uiTypes';
import { SingleAction } from '../SingleAction';
import { attentionConfig, keySceneDataTypes } from './attentionConfig';
import { findAsset, findUI } from 'Core_Games/Scripts/configHelper';
import { onClickGoToRoute, onClickActivityMenu } from 'Core_Games/Scripts/buttonHelper';
import { STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';
import { LogInteraction, interactionExceedsLimit } from '../../../Scripts/interactionHelper';

export class Attention extends SingleAction {
  constructor() {
    super(sceneType.ATTENTION.NAME);
    this.brushScale = 0.7;
    this.outroActive = false;
    this.brushEntered = false;
    this.brushXMovement = 300;
    this.swipeRight = false;
    this.brushPetX = undefined;
    this.brushPetEndX = undefined;
    this.brushPetY = undefined;
  }

  init(sceneData) {
    this.sceneData = sceneData;
    this.attentionConfig = attentionConfig;

    let brushButton = findUI(this, this.attentionConfig, uiTypes.BUTTONROW, keySceneDataTypes.BRUSH_BUTTON);
    let notNowButton = findUI(this, this.attentionConfig, uiTypes.BUTTONROW, keySceneDataTypes.NOT_NOW_BUTTON);
    let letsGoButton = findUI(this, this.attentionConfig, uiTypes.BUTTONROW, keySceneDataTypes.LETS_GO_BUTTON);
    let backButton = findUI(this, this.attentionConfig, uiTypes.BUTTONROW, keySceneDataTypes.BACK_BUTTON);
    // Attach Button Functions
    brushButton.ONCLICK = () => this.brushPet(this, this.pet);
    notNowButton.ONCLICK = () => onClickActivityMenu(sceneData.appData.history);
    letsGoButton.ONCLICK = () =>
      onClickGoToRoute(sceneData.appData.history, STATICCONTENT, { contentType: 'gratitudeletter' });
    backButton.ONCLICK = () => onClickActivityMenu(sceneData.appData.history);

    super.init(sceneType.ATTENTION, this.attentionConfig, sceneData);

    this.brushPetOffsetY = this.currentSceneConfig.BRUSHPETOFFSETY ?? 0.2;
    this.brushPetOffsetX = this.currentSceneConfig.BRUSHPETOFFSETX ?? 0.2;
    this.brushMotionCap = this.currentSceneConfig.BRUSHMOTIONCAP ?? 0.19;
  }

  preload() {
    super.preload();
  }

  create() {
    let _this = this;
    super.create();

    // set initial parameters
    this.engine.engineAssets.load.on(Phaser.Loader.Events.COMPLETE, function () {
      // set init state of pet
      _this.pet.play(animationType.PENDING_ATTENTION);
      _this.pet?.body?.setEnable(false);
      _this.pet.setDepth(1);

      // add brush and set init state
      _this.brush = _this.add.sprite(
        0,
        _this.pet.height * _this.brushOffset - (_this.currentSceneConfig.RIGHTFACING ? -1 : 1) * _this.pet.y,
        _this.sceneData.gameData.gameType.ATLAS,
        findAsset(_this, _this.attentionConfig, keySceneDataTypes.BRUSH).DATA,
      );

      _this.brush.flipX = true;
      _this.brushStartX = _this.getPagePoint(_this.singleActionBackgroundIndex, -0.75, 0).x;
      _this.brush.x = _this.brushStartX;
      _this.brush.setScale(_this.brushScale);
      //Set brush active movement variables
      _this.physics.world.enable(_this.brush);
      _this.brush.body.allowGravity = false;

      //Set motion coordinates
      _this.brushPetX =
        _this.pet.getCenter().x + _this.pet.displayWidth * (_this.brushPetOffsetX - _this.brushMotionCap);
      _this.brushPetY = _this.pet.getCenter().y - _this.pet.displayHeight * _this.brushPetOffsetY;
      _this.brushPetEndX =
        _this.pet.getCenter().x + _this.pet.displayWidth * (_this.brushPetOffsetX - (_this.brushMotionCap + 0.085));

      // add pillow if available and set init state
      if (_this.currentSceneConfig.ASSETS.PILLOW) {
        _this.pillow = _this.add.sprite(
          _this.pet.getCenter().x + _this.pet.displayWidth * _this.currentSceneConfig.OBJECTXDISPLAY,
          _this.pet.getCenter().y + _this.pet.displayHeight * _this.currentSceneConfig.OBJECTYDISPLAY,
          _this.sceneData.gameData.gameType.ATLAS,
          findAsset(_this, _this.attentionConfig, keySceneDataTypes.PILLOW).DATA,
        );
        _this.pillow.setScale(_this.currentSceneConfig.PETSCALE / 3);
      }
      // ui manager
      _this.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_INTRO);
      _this.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.INTRO_CAPTION);

      _this.finishedLoading = true;

      // physics overlap logic
      _this.addEventLogic(_this, _this.pet);
    });
  }

  update() {
    super.update();

    if (this.finishedLoading) {
      if (
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_INTRO &&
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_OUTRO &&
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_BACK &&
        !this.brushEntered
      ) {
        if (this.brush.x <= this.brushPetX) {
          this.brushEntered = true;
        }
        //enter brush
        this.physics.moveTo(this.brush, this.brushPetX, this.brushPetY, this.brushXMovement);
      }
      //brush
      if (
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_INTRO &&
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_SNOOZY &&
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_OUTRO &&
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_BACK &&
        this.brushEntered
      ) {
        if (this.brush.x >= this.brushPetX) {
          this.swipeRight = false;
        } else if (this.brush.x <= this.brushPetEndX) {
          this.swipeRight = true;
        }
        //swipe motion
        this.swipeRight
          ? this.physics.moveTo(this.brush, this.brushPetX, this.brushPetY, this.brushXMovement)
          : this.physics.moveTo(this.brush, this.brushPetEndX, this.brushPetY, this.brushXMovement);
      }
      if (
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_INTRO &&
        this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_BRUSH
      ) {
        if (!this.outroActive && this.engine.engineUi.buttonManager.currentScreen != keySceneDataTypes.SCREEN_SNOOZY) {
          this.displayOutroScreen(this);
        }
        //exit brush, removing 20 px on X to clear the screen completely
        this.physics.moveTo(this.brush, this.brushStartX - 20, this.brushPetY, this.brushXMovement);
      } else if (
        this.engine.engineUi.buttonManager.currentScreen == keySceneDataTypes.SCREEN_INTRO &&
        interactionExceedsLimit(this) &&
        this.actionTimes == 0
      ) {
        this.displayBackScreen(this);
      }
    }
  }

  // Scene-Specific Functions

  // Add Collision & event logic
  addEventLogic = (game, actor) => {
    actor.on(Phaser.Animations.Events.ANIMATION_COMPLETE, function () {
      if (actor.animationIsActive(animationType.ATTENTION)) {
        game.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_SNOOZY);
        actor.play(animationType.PENDING_SNOOZE);
      } else if (actor.animationIsActive(animationType.PENDING_SNOOZE)) {
        game.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_OUTRO);
        actor.play(animationType.SNOOZE);
      }
    });
  };

  //Handles triggering the motion logic for the brush in update() and side effects of screen/caption displays
  brushPet = (game) => {
    this.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_BRUSH);

    game.pet.play(animationType.ATTENTION);

    game.engine.engineUi.captionManager.toggleCaption(true);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.BRUSH_BUTTON, false);
    LogInteraction(game, game.sceneData?.sceneType);
  };

  displayOutroScreen = (game) => {
    this.outroActive = true;
    game.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_OUTRO);
    game.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.OUTRO_CAPTION);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.NOT_NOW_BUTTON, false);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.LETS_GO_BUTTON, false);
  };

  displayBackScreen = (game) => {
    this.outroActive = true;
    game.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_BACK);
    game.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.BACK_CAPTION);
    game.engine.engineUi.buttonManager.update(keySceneDataTypes.BACK_BUTTON, false);
  };
}
