import Header from './Header';
import ResultsBody from './ResultsBody';
import Description from './Description';
import { ResultVariantTypes } from 'Core_Pages/Screening/Results/Result/ResultContext';

const AdultUCLA3 = {
  [ResultVariantTypes.Header]: Header,
  [ResultVariantTypes.Body]: ResultsBody,
  [ResultVariantTypes.Description]: Description,
};

export default AdultUCLA3;
