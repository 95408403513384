import React from 'react';
import PropTypes from 'prop-types';
import { Description, StyledCard, StyledIcon, StyledLabel } from './styles';
import { black, purple } from 'Styles/colors';
import { useIntl } from 'react-intl';

function GradientCard({ titleId, altId, icon, backgroundColor, imageStyle, label, CardComponent, children, ...props }) {
  const intl = useIntl();

  const { fontSize, fontWeight, color } = props;
  const descriptionProps = { fontSize, fontWeight, color };

  const background = `linear-gradient(#ffffff, ${backgroundColor})`;
  const imageAlt = altId ? intl.formatMessage({ id: altId }) : '';

  return (
    <>
      <CardComponent bg={backgroundColor} sx={{ boxShadow: 3, background: background, overflow: 'visible' }}>
        <Description {...descriptionProps}>{intl.formatMessage({ id: titleId })}</Description>
        <StyledIcon src={icon} alt={imageAlt} style={{ ...imageStyle }} />
        {label && <StyledLabel elevation={4}>{label}</StyledLabel>}
        {children}
      </CardComponent>
    </>
  );
}

GradientCard.propTypes = {
  titleId: PropTypes.string,
  altId: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  childTotal: PropTypes.number,
  imageStyle: PropTypes.object,
  backgroundColor: PropTypes.string,
  CardComponent: PropTypes.any,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.element]),
};

GradientCard.defaultProps = {
  fontWeight: 600,
  fontSize: 18,
  color: black._74,
  backgroundColor: purple._050,
  CardComponent: StyledCard,
};

export default GradientCard;
