import React from 'react';
import PropTypes from 'prop-types';
import { ExpandMore } from '@mui/icons-material';
import {
  StyledContainer,
  StyledHeader,
  StyledDescription,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledItemsDescription,
  StyledItemsDropdownDescription,
  StyledSectionList,
  StyledItemDivider,
  StyledListItem,
} from './styles';

function Section({ config, isExpanded, setIsExpanded, index }) {
  const { key, header, hasNoSectionMargin, description, itemsHeader, itemsDescription, items, bulletedItems } = config;

  function handleChange(e, expanded) {
    setIsExpanded(key, expanded);
  }

  function renderItems(items) {
    return (
      <StyledSectionList>
        {items &&
          items.map((item) => {
            return (
              <React.Fragment key={item.key ?? item.text}>
                {item.description && (
                  <StyledItemsDropdownDescription>
                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                  </StyledItemsDropdownDescription>
                )}
                {item.text && (
                  <li>
                    {/* This is only safe to use as text is generated locally */}
                    <div dangerouslySetInnerHTML={{ __html: item.text }} />
                    {item.subItems && renderItems(item.subItems)}
                  </li>
                )}
                {item.divider && (
                  <StyledListItem>
                    <StyledItemDivider />
                  </StyledListItem>
                )}
              </React.Fragment>
            );
          })}
      </StyledSectionList>
    );
  }

  return (
    <StyledContainer hasNoSectionMargin={hasNoSectionMargin}>
      {header && <StyledHeader>{header}</StyledHeader>}
      {description && <StyledDescription>{description}</StyledDescription>}
      {bulletedItems && renderItems(bulletedItems)}
      {items && items.length && (
        <StyledAccordion expanded={isExpanded} onChange={handleChange}>
          <StyledAccordionSummary
            data-testid="Accordion"
            id={`Accordion_Summary_${index}`}
            aria-controls={`Accordion_Details_${index}`}
            expandIcon={<ExpandMore />}
          >
            {itemsHeader}
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {itemsDescription && <StyledItemsDescription>{itemsDescription}</StyledItemsDescription>}
            {renderItems(items)}
          </StyledAccordionDetails>
        </StyledAccordion>
      )}
    </StyledContainer>
  );
}

Section.propTypes = {
  config: PropTypes.shape({
    key: PropTypes.string,
    header: PropTypes.string,
    hasNoSectionMargin: PropTypes.bool,
    description: PropTypes.string,
    itemsHeader: PropTypes.string,
    itemsDescription: PropTypes.string,
    bulletedItems: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        subItems: PropTypes.array,
        divider: PropTypes.bool,
      }),
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        subItems: PropTypes.array,
        divider: PropTypes.bool,
      }),
    ),
  }),
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default Section;
