import { uiTypes } from 'Core_Games/constants/uiTypes';
import { gameData } from 'Core_Games/constants/gameData';
import { InteractionType } from 'Core_Games/constants/interactionType';
import { playerActorType } from 'Core_Games/constants/playerActorType';

export const keySceneDataTypes = {
  // Objects
  BACKGROUND: 'BACKGROUND',
  BRUSH: 'BRUSH',
  PILLOW: 'PILLOW',
  // Captions
  INTRO_CAPTION: 'INTRO_CAPTION',
  OUTRO_CAPTION: 'OUTRO_CAPTION',
  BACK_CAPTION: 'BACK_CAPTION',
  // Buttons
  BRUSH_BUTTON: 'BRUSH_BUTTON',
  NOT_NOW_BUTTON: 'NOT_NOW_BUTTON',
  LETS_GO_BUTTON: 'LETS_GO_BUTTON',
  BACK_BUTTON: 'BACK_BUTTON',
  // Sounds
  HAPPY: 'HAPPY',
  // Screen
  SCREEN_INTRO: 'SCREEN_INTRO',
  SCREEN_BRUSH: 'SCREEN_BRUSH',
  SCREEN_SNOOZY: 'SCREEN_SNOOZY',
  SCREEN_OUTRO: 'SCREEN_OUTRO',
  SCREEN_BACK: 'SCREEN_BACK',
};

export const attentionConfig = {
  [playerActorType.DOG.NAME]: {
    [InteractionType.ATTENTION.NAME]: {
      GAMEBACKGROUNDCOLOR: '#FFFFFF',
      NAME: InteractionType.ATTENTION.NAME,
      ACTIONLIMIT: 1,
      ADJUSTFORANIMATIONOFFSET: true,
      RIGHTFACING: true,
      PETSCALE: 5.6,
      BRUSHOFFSET: 0.6,
      BRUSHPETOFFSETX: 0.16,
      BRUSHPETOFFSETY: -0.0125,
      STARTX: -1.425,
      STARTY: 0.05,
      BACKGROUNDSPLIT: 1,
      BACKGROUNDTRANSFORM: { scaleY: 1.8 },
      BOUNDS: {
        HEIGHT: {
          START: 0.4,
          END: 0.0,
        },
        WIDTH: {
          START: 0.05555,
          END: 0.41666666666,
        },
      },
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.DOG.ATTENTION,
        [keySceneDataTypes.BRUSH]: gameData.ACTOR.ITEM.OBJECT.BRUSH,
      },
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.BRUSH_BUTTON,
              TEXTID: 'pets.buttons.brush',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_INTRO],
            },
            {
              NAME: keySceneDataTypes.NOT_NOW_BUTTON,
              TEXTID: 'pets.buttons.brush.not-now',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.LETS_GO_BUTTON,
              TEXTID: 'pets.buttons.brush.go',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.BACK_BUTTON,
              TEXTID: 'pets.buttons.brush.back',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_BACK],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.INTRO_CAPTION,
              TEXTID: 'pets.caption.description.brush',
            },
            {
              NAME: keySceneDataTypes.OUTRO_CAPTION,
              TITLEID: 'pets.caption.title.brush.outro',
              TEXTID: 'pets.caption.description.brush.outro',
            },
            {
              NAME: keySceneDataTypes.BACK_CAPTION,
              TITLEID: 'pets.caption.title.brush.back',
            },
          ],
        },
      ],
    },
  },
  [playerActorType.CAT.NAME]: {
    [InteractionType.ATTENTION.NAME]: {
      NAME: InteractionType.ATTENTION.NAME,
      ACTIONLIMIT: 1,
      PETSCALE: 4.95,
      ADJUSTFORANIMATIONOFFSET: true,
      RIGHTFACING: true,
      BRUSHOFFSET: 0.3,
      BRUSHPETOFFSETY: -0.025,
      BRUSHPETOFFSETX: 0.15,
      OBJECTYDISPLAY: 0.335,
      OBJECTXDISPLAY: 0.05,
      SOUNDACTIVE: true,
      STARTX: -1.18,
      STARTY: -0.002,
      BACKGROUNDSPLIT: 1,
      BOUNDS: {
        HEIGHT: {
          START: 0.10606061,
          END: 0.0,
        },
        WIDTH: {
          START: 0.05555,
          END: 0.41666666666,
        },
      },
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.DOG.ATTENTION,
        [keySceneDataTypes.BRUSH]: gameData.ACTOR.ITEM.OBJECT.BRUSH,
        [keySceneDataTypes.PILLOW]: gameData.ACTOR.ITEM.OBJECT.PILLOW,
      },
      UI: [
        {
          TYPE: uiTypes.BUTTONROW,
          DATA: [
            {
              NAME: keySceneDataTypes.BRUSH_BUTTON,
              TEXTID: 'pets.buttons.brush',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_INTRO],
            },
            {
              NAME: keySceneDataTypes.NOT_NOW_BUTTON,
              TEXTID: 'pets.buttons.brush.not-now',
              BUTTONTYPE: 'SECONDARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.LETS_GO_BUTTON,
              TEXTID: 'pets.buttons.brush.go',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_OUTRO],
            },
            {
              NAME: keySceneDataTypes.BACK_BUTTON,
              TEXTID: 'pets.buttons.brush.back',
              BUTTONTYPE: 'PRIMARY',
              ROWS: [keySceneDataTypes.SCREEN_BACK],
            },
          ],
        },
        {
          TYPE: uiTypes.CAPTION,
          DATA: [
            {
              NAME: keySceneDataTypes.INTRO_CAPTION,
              TEXTID: 'pets.caption.description.brush',
            },
            {
              NAME: keySceneDataTypes.OUTRO_CAPTION,
              TITLEID: 'pets.caption.title.brush.outro',
              TEXTID: 'pets.caption.description.brush.outro',
            },
            {
              NAME: keySceneDataTypes.BACK_CAPTION,
              TITLEID: 'pets.caption.title.brush.back',
            },
          ],
        },
      ],
    },
  },
};
