import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorAdornment, VisibilityAdornment } from './Adornments';
import TextInput from './TextInput';

const PasswordInput = forwardRef(({ errorMessage, ...props }, ref) => {
  const [hideValue, setHideValue] = useState(true);
  const toggleVisibility = () => setHideValue((prev) => !prev);

  return (
    <TextInput
      ref={ref}
      type={hideValue ? 'password' : 'text'}
      errorMessage={errorMessage}
      InputProps={{
        endAdornment: (
          <>
            {errorMessage && <ErrorAdornment />}
            <VisibilityAdornment visible={!hideValue} onClick={toggleVisibility} />
          </>
        ),
      }}
      {...props}
    />
  );
});

PasswordInput.displayName = 'PasswordInput';

PasswordInput.propTypes = {
  errorMessage: PropTypes.string,
};

export default PasswordInput;
