import { friendshipIngredients } from 'Assets/img/my-tools-icons';

export const friendshipEn = {
  key: 'friendship',
  title: 'Ingredients of friendship',
  titleImage: friendshipIngredients,
  titleImageAlt: '',
  contentId: 22,
  sections: [
    {
      key: 'friendship-intro',
      hasNoSectionMargin: true,
      description:
        'What makes a healthy friendship? One that fills you up with energy more than draining it. A healthy friendship grows when both people in it like each other and participate in it equally. So, it’s important to be mindful of the kind of person you want as a friend. Like making a cake, friendships have special ingredients, such as…',
      bulletedItems: [
        { text: 'Kindness' },
        { text: 'Support' },
        { text: 'Listening' },
        { text: 'Honesty' },
        { text: 'Trust' },
        { text: 'Patience' },
        { text: 'Forgiveness' },
        { text: 'Respect' },
        { text: 'Loyalty' },
        { text: 'Fun' },
      ],
    },
    {
      key: 'friendship-examples',
      description: 'To find out what is important to you in a friend, ask yourself these questions:',
      bulletedItems: [
        { text: 'What is the trait or quality?' },
        { text: 'What does it look like?' },
        { text: 'Why does it matter?' },
      ],
    },
    {
      key: 'friendship-empathy',
      header: 'Empathy',
      description:
        'Empathy is the ability to understand someone’s emotional state – how they feel and why they feel it. Empathy helps lighten emotional stress without taking on those feelings.\n\nFor example, just being with someone as they cry creates a valuable, safe space for them. Simply listening shows you’re there for them as they deal with their emotions, without fixing or judging.',
    },
    {
      key: 'friendship-trust',
      header: 'Trust',
      description:
        'Friends respect each other’s private thoughts and information. For example, if you suspect someone is talking about your personal matters behind your back, it’s likely to create distrust and make you shut down around them. Distrust ruins friendships. It is the opposite of safety.',
    },
    {
      key: 'friendship-honesty',
      header: 'Honesty',
      description:
        'Being honest builds trust in friendships. Even saying hard things can be helpful when said honestly and kindly. And honesty creates a sense of freedom. Neither person has to work hard to be themself because they’re always honest with each other.',
    },
    {
      key: 'friendship-vulnerability',
      header: 'Vulnerability',
      description:
        'Exposing private information and feelings can make a person feel too open and vulnerable. Doing so, however, can help build intimacy in friendships which can lead to creating more trust and deeper connection. Feeling safe to express your truths openly is a sign a friendship has a foundation of trust.',
    },
    {
      key: 'friendship-reliability',
      header: 'Reliability',
      description:
        'Meaningful friendships happen when each person feels they can reach out to the other and get a reply in return. Whether texting every day, calling weekly, or getting together once a month, ongoing connections make the hard times easier and the easy times better.\n\nYou deserve to be seen, understood, and cherished. Healthy friendships share interest and participation and help make life’s journey rich and meaningful!',
    },
  ],
};
