import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, Typography } from '@mui/material';
import Button from 'Stories/components/Button/Button';
import { AcknowledgeButton, ErrorDialog, ErrorHeader, ErrorReason, ErrorListContainer, ErrorListItem } from './styles';
import { useIntl } from 'react-intl';
import { black } from 'Styles/colors';

function CORSModal({ open, onClose }) {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(open);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const onModalClose = () => {
    setModalOpen(false);

    if (onClose) {
      onClose();
    }

    window.location.reload();
  };

  return (
    <>
      <ErrorDialog open={modalOpen} aria-labelledby="cors-error-title">
        <ErrorHeader>
          <Typography id="cors-error-title" component="h1" fontSize={18} color={black._87}>
            {intl.formatMessage({ id: 'direct-message.corserror.header' })}
          </Typography>
          <Typography fontSize={16} color={black._60}>
            {intl.formatMessage({ id: 'direct-message.corserror.description' })}
          </Typography>
        </ErrorHeader>
        <ErrorListContainer>
          <ErrorListItem>
            <ErrorReason>{intl.formatMessage({ id: 'direct-message.corserror.steps.click-safari' })}</ErrorReason>
          </ErrorListItem>
          <ErrorListItem>
            <ErrorReason>{intl.formatMessage({ id: 'direct-message.corserror.steps.settings' })}</ErrorReason>
          </ErrorListItem>
          <ErrorListItem>
            <ErrorReason>{intl.formatMessage({ id: 'direct-message.corserror.steps.privacy' })}</ErrorReason>
          </ErrorListItem>
          <ErrorListItem>
            <ErrorReason>{intl.formatMessage({ id: 'direct-message.corserror.steps.cross-site' })}</ErrorReason>
          </ErrorListItem>
          <ErrorListItem>
            <ErrorReason>{intl.formatMessage({ id: 'direct-message.corserror.steps.refresh' })}</ErrorReason>
          </ErrorListItem>
        </ErrorListContainer>
        <DialogActions>
          <AcknowledgeButton variant={Button.variant.TEXT} onClick={onModalClose}>
            {intl.formatMessage({ id: 'common.refresh' })}
          </AcknowledgeButton>
        </DialogActions>
      </ErrorDialog>
    </>
  );
}

CORSModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
CORSModal.defaultProps = {
  open: false,
};

export default CORSModal;
