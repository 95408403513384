import React from 'react';
import { ScreeningModuleStore } from '../ScreeningModuleStore';
import { Forms } from './forms';
import { Redirect } from 'react-router-dom';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';

function ScreeningForm() {
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const FormComponent = Forms[screeningStore.ScreeningGuid];

  if (screeningStore.ScreeningGuid && !FormComponent) {
    return <Redirect to={HOME} />;
  }

  return (
    <>
      <FormComponent />
    </>
  );
}

export default ScreeningForm;
