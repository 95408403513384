import { dogAssetConfig } from 'Core_Games/Actor/Player/Pet/Dog/DogConfig';
import { Bowl } from '../Actor/Item/Object/Bowl/Bowl';
import { NutrientContainer } from '../Actor/Item/Object/NutrientContainer/NutrientContainer';
import { FrontDoor } from '../Actor/Item/Object/FrontDoor/FrontDoor';
import { Cat } from '../Actor/Player/Pet/Cat/Cat';
import { catAssetConfig } from 'Core_Games/Actor/Player/Pet/Cat/CatConfig';
import { Dog } from '../Actor/Player/Pet/Dog/Dog';
import { Butterfly } from '../Actor/NPC/Ambience/Butterfly/Butterfly';
import { Family } from '../Actor/NPC/Human/Family/Family';
import { OldMan } from '../Actor/NPC/Human/OldMan/OldMan';
import { WheelchairWoman } from '../Actor/NPC/Human/WheelchairWoman/WheelchairWoman';
import { Puppy } from '../Actor/NPC/Stray/Puppy/Puppy';
import { Kitten } from '../Actor/NPC/Stray/Kitten/Kitten';

export const playerActorType = {
  // Player Pets
  DOG: { NAME: 'DOG', DATA: Dog, CONFIG: dogAssetConfig },
  CAT: { NAME: 'CAT', DATA: Cat, CONFIG: catAssetConfig },
  // Objects
  BOWL: {
    NAME: 'BOWL',
    DATA: Bowl,
  },
  NUTRIENTCONTAINER: {
    NAME: 'NUTRIENTCONTAINER',
    DATA: NutrientContainer,
  },
  BUTTERFLY: {
    NAME: 'BUTTERFLY',
    DATA: Butterfly,
  },
  FRONTDOOR: {
    NAME: 'FRONTDOOR',
    DATA: FrontDoor,
  },
  // NPCs
  FAMILY: {
    NAME: 'FAMILY',
    DATA: Family,
  },
  WHEELCHAIRWOMAN: {
    NAME: 'WHEELCHAIRWOMAN',
    DATA: WheelchairWoman,
  },
  OLDMAN: {
    NAME: 'OLDMAN',
    DATA: OldMan,
  },
  KITTEN: {
    NAME: 'KITTEN',
    DATA: Kitten,
  },
  PUPPY: {
    NAME: 'PUPPY',
    DATA: Puppy,
  },
};
