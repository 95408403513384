import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '@mui/icons-material/Error';
import { StyledCardIcon } from './styles';
import GradientCard from 'Core_Components/GradientCard/GradientCard';

function WarningCard({ ...cardProps }) {
  return (
    <>
      <GradientCard {...cardProps}>
        <StyledCardIcon className="tile-icon">
          <ErrorIcon />
        </StyledCardIcon>
      </GradientCard>
    </>
  );
}

WarningCard.propTypes = {
  cardProps: PropTypes.object,
};

export default WarningCard;
