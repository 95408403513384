import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import Button from 'Stories/components/Button';

export const TodoContainer = styled(Paper)`
  background: linear-gradient(180deg, #8600d8, #810091);
  margin: 0 15px;
  border-radius: 10px;

  .MuiButton-root {
    border-bottom: 1px solid white;
    width: 100%;

    :last-child {
      border-bottom: none;
    }
  }
`;

export const TodoItemContainer = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const TodoHeader = styled.div`
  text-align: center;
  padding: 15px;
  border-bottom: 1px solid white;
`;

export const TodoItem = styled(Button)`
  color: white;
  display: inline-flex;
  justify-content: left;
  flex-flow: row;
  column-gap: 10px;
  border-radius: 0px;
  height: auto;

  .MuiTypography-root {
    margin: 5px 0;
  }

  :disabled {
    color: white;
  }
`;
