import { uiTypes } from 'Core_Games/constants/uiTypes';
import { gameData } from 'Core_Games/constants/gameData';
import { InteractionType } from 'Core_Games/constants/interactionType';
import { playerActorType } from 'Core_Games/constants/playerActorType';
import { MYTOOLS } from 'Core_Pages/Routes/RoutesConfig';
import urlcat from 'urlcat';
import { relationships } from 'Assets/img/my-tools-icons';
import {
  water,
  attention,
  dogfeed,
  dogplay,
  dogoutside,
  dogtreat,
  dogfriend,
  catfeed,
  catplay,
  catoutside,
  cattreat,
  catfriend,
} from 'Assets/game-data/activity-menu/index';

export const keySceneDataTypes = {
  // Objects
  BACKGROUND: 'BACKGROUND',
  // Screen
  SCREEN_HEALTHY: 'SCREEN_HEALTHY',
  SCREEN_SAD: 'SCREEN_SAD',
  // Key Menu Section Items
  MENUSECTION_WATER: 'MENUSECTION_WATER',
  MENUSECTION_FOOD: 'MENUSECTION_FOOD',
  MENUSECTION_ATTENTION: 'MENUSECTION_ATTENTION',
  MENUSECTION_CHASE: 'MENUSECTION_CHASE',
  MENUSECTION_GOOUTSIDE: 'MENUSECTION_GOOUTSIDE',
  MENUSECTION_TREAT: 'MENUSECTION_TREAT',
};

export const petStateDateNames = {
  FEED: 'lastFoodDateUtc',
  HYDRATE: 'lastWaterDateUtc',
  ATTENTION: 'lastAttentionDateUtc',
};

// TODO: Replace all menu icons here (all placeholders) with final assets
export const activityMenuConfig = {
  [playerActorType.DOG.NAME]: {
    [InteractionType.ACTIVITYMENU.NAME]: {
      GAMEBACKGROUNDCOLOR: '#FFFFFF',
      PETSCALE: 0.8,
      NAME: InteractionType.ACTIVITYMENU.NAME,
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.DOG.ACTIVITYMENU,
      },
      UI: [
        {
          TYPE: uiTypes.MENUSECTION,
          HEADERS: [
            {
              TITLEID: 'pets.menusection.header.title.healthy',
              ROWS: [keySceneDataTypes.SCREEN_HEALTHY],
            },
            {
              TITLEID: 'pets.menusection.header.title.sad',
              TEXTID: 'pets.menusection.header.description.sad',
              HASWARNING: true,
              ROWS: [keySceneDataTypes.SCREEN_SAD],
            },
          ],
          BACKITEM: {
            SRC: relationships,
            TEXTID: 'pets.menusection.item.relationships',
            LINK: { ROUTE: urlcat(MYTOOLS, { toolType: 'relationships' }) },
          },
          DATA: [
            {
              TEXTID: 'pets.menusection.section.daily-care',
              MENUSECTIONITEMS: [
                {
                  SRC: dogfeed,
                  TEXTID: 'pets.menusection.item.feed',
                  DISABLEDTEXTID: 'pets.menusection.item.feed.disabled',
                  REQUIRED: true,
                  KEY: keySceneDataTypes.MENUSECTION_FOOD,
                  LINK: InteractionType.FEED,
                  DATE: petStateDateNames.FEED,
                },
                {
                  SRC: water,
                  TEXTID: 'pets.menusection.item.water',
                  REQUIRED: true,
                  KEY: keySceneDataTypes.MENUSECTION_WATER,
                  LINK: InteractionType.HYDRATE,
                  DATE: petStateDateNames.HYDRATE,
                },
                {
                  SRC: attention,
                  TEXTID: 'pets.menusection.item.attention',
                  DISABLEDTEXTID: 'pets.menusection.item.attention.disabled',
                  REQUIRED: true,
                  KEY: keySceneDataTypes.MENUSECTION_ATTENTION,
                  LINK: InteractionType.ATTENTION,
                  DATE: petStateDateNames.ATTENTION,
                },
              ],
            },
            {
              TEXTID: 'pets.menusection.section.enriching-care',
              MENUSECTIONITEMS: [
                {
                  SRC: dogoutside, // placeholder for now, will replace once received
                  TEXTID: 'pets.menusection.item.outside',
                  DISABLEDTEXTID: 'pets.menusection.item.outside.disabled.dog',
                  LINK: InteractionType.STROLL,
                  KEY: keySceneDataTypes.MENUSECTION_GOOUTSIDE,
                },
                {
                  SRC: dogplay,
                  TEXTID: 'pets.menusection.item.play',
                  DISABLEDTEXTID: 'pets.menusection.item.play.disabled',
                  LINK: InteractionType.FETCH,
                  KEY: keySceneDataTypes.MENUSECTION_CHASE,
                },
                {
                  SRC: dogfriend,
                  TEXTID: 'pets.menusection.item.friend',
                  LINK: InteractionType.FRIENDTIME,
                },
                {
                  SRC: dogtreat,
                  TEXTID: 'pets.menusection.item.treat',
                  DISABLEDTEXTID: 'pets.menusection.item.treat.disabled',
                  LINK: InteractionType.TREAT,
                  KEY: keySceneDataTypes.MENUSECTION_TREAT,
                },
              ],
            },
          ],
        },
      ],
    },
  },
  [playerActorType.CAT.NAME]: {
    [InteractionType.ACTIVITYMENU.NAME]: {
      NAME: InteractionType.ACTIVITYMENU.NAME,
      GAMEBACKGROUNDCOLOR: '#FFFFFF',
      STARTXOFFSET: 0.05,
      PETSCALE: 0.6,
      ASSETS: {
        [keySceneDataTypes.BACKGROUND]: gameData.BACKGROUND.CAT.ACTIVITYMENU,
      },
      UI: [
        {
          TYPE: uiTypes.MENUSECTION,
          HEADERS: [
            {
              TITLEID: 'pets.menusection.header.title.healthy',
              ROWS: [keySceneDataTypes.SCREEN_HEALTHY],
            },
            {
              TITLEID: 'pets.menusection.header.title.sad',
              TEXTID: 'pets.menusection.header.description.sad',
              HASWARNING: true,
              ROWS: [keySceneDataTypes.SCREEN_SAD],
            },
          ],
          BACKITEM: {
            SRC: relationships,
            TEXTID: 'pets.menusection.item.relationships',
            LINK: { ROUTE: urlcat(MYTOOLS, { toolType: 'relationships' }) },
          },
          DATA: [
            {
              TEXTID: 'pets.menusection.section.daily-care',
              MENUSECTIONITEMS: [
                {
                  SRC: catfeed,
                  TEXTID: 'pets.menusection.item.feed',
                  DISABLEDTEXTID: 'pets.menusection.item.feed.disabled',
                  KEY: keySceneDataTypes.MENUSECTION_FOOD,
                  REQUIRED: true,
                  LINK: InteractionType.FEED,
                  DATE: petStateDateNames.FEED,
                },
                {
                  SRC: water,
                  TEXTID: 'pets.menusection.item.water',
                  KEY: keySceneDataTypes.MENUSECTION_WATER,
                  REQUIRED: true,
                  LINK: InteractionType.HYDRATE,
                  DATE: petStateDateNames.HYDRATE,
                },
                {
                  SRC: attention,
                  TEXTID: 'pets.menusection.item.attention',
                  DISABLEDTEXTID: 'pets.menusection.item.attention.disabled',
                  REQUIRED: true,
                  KEY: keySceneDataTypes.MENUSECTION_ATTENTION,
                  LINK: InteractionType.ATTENTION,
                  DATE: petStateDateNames.ATTENTION,
                },
              ],
            },
            {
              TEXTID: 'pets.menusection.section.enriching-care',
              MENUSECTIONITEMS: [
                {
                  SRC: catoutside,
                  TEXTID: 'pets.menusection.item.outside',
                  DISABLEDTEXTID: 'pets.menusection.item.outside.disabled.cat',
                  LINK: InteractionType.STROLL,
                  KEY: keySceneDataTypes.MENUSECTION_GOOUTSIDE,
                },
                {
                  SRC: catplay, // placeholder
                  TEXTID: 'pets.menusection.item.play',
                  DISABLEDTEXTID: 'pets.menusection.item.play.disabled',
                  LINK: InteractionType.FETCH,
                  KEY: keySceneDataTypes.MENUSECTION_CHASE,
                },
                {
                  SRC: catfriend,
                  TEXTID: 'pets.menusection.item.friend',
                  LINK: InteractionType.FRIENDTIME,
                },
                {
                  SRC: cattreat, // placeholder
                  TEXTID: 'pets.menusection.item.treat',
                  DISABLEDTEXTID: 'pets.menusection.item.treat.disabled',
                  LINK: InteractionType.TREAT,
                  KEY: keySceneDataTypes.MENUSECTION_TREAT,
                },
              ],
            },
          ],
        },
      ],
    },
  },
};
