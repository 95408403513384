import React, { useEffect, useState } from 'react';
import PropsTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { gameConfig } from 'Core_Games/gameConfig';
import { gameType } from 'Core_Games/constants/gameType';
import { useParams } from 'react-router-dom';
import useCreatePetInteraction from 'Core_Hooks/useCreatePetInteraction';
import { isMobileBrowser } from 'Core_Helpers/isMobileBrowser';
import PhaserGame from 'Core_Components/PhaserGame/PhaserGame';
import DeviceOrientation from 'Libraries/screen-orientation/index';
import { StyledOrientation, StyledOrientationWarning, StyledWarningOverlay } from './styles';

// TODO: Find out if we can load phaser in the background so the user doesn't have to wait, or, at least add a loading screen
// TODO: Add App Insights
// TODO: Unit Tests (We need to have a larger discussion of how we will be doing unit tests in phaser)

function PetsGame({ pet, syncUser, history, userId }) {
  const { petSceneType } = useParams();
  const [gameDataInfo, setGameDataInfo] = useState();
  const intl = useIntl();
  const { createPetInteraction } = useCreatePetInteraction();

  useEffect(() => {
    if (!gameDataInfo && pet && pet.petId && userId) {
      setGameDataInfo({
        gameType: gameType.PETS,
        sceneType: `${petSceneType}`.toUpperCase(),
        gameData: { petState: pet, userId: userId },
      });
    }
  }, []);

  const renderGame = () => {
    return (
      <PhaserGame
        id="pets"
        config={gameConfig}
        gameData={{
          userInfo: syncUser,
          intl: intl,
          history: history,
          createPetInteraction: createPetInteraction,
          info: {
            data: gameDataInfo,
            setData: setGameDataInfo,
          },
        }}
        initialize={true}
        crossOrigin="true"
      />
    );
  };

  const renderRequirePortraitForMobileBrowsers = () => {
    return (
      <DeviceOrientation lockOrientation={'portrait'}>
        <StyledOrientationWarning orientation="landscape" alwaysRender={gameDataInfo?.isInitialized ?? false}>
          <StyledWarningOverlay>{intl.formatMessage({ id: 'pets.pets-game.error.portrait' })}</StyledWarningOverlay>
        </StyledOrientationWarning>
        <StyledOrientation orientation="portrait" alwaysRender={gameDataInfo?.isInitialized ?? false}>
          {renderGame()}
        </StyledOrientation>
      </DeviceOrientation>
    );
  };

  return (
    <>
      {gameDataInfo && pet?.petId && syncUser && history && intl ? (
        <>{!isMobileBrowser() ? renderGame() : renderRequirePortraitForMobileBrowsers()}</>
      ) : null}
    </>
  );
}

PetsGame.propTypes = {
  pet: PropsTypes.object,
  syncUser: PropsTypes.object,
  history: PropsTypes.object,
  userId: PropsTypes.number,
};

export default PetsGame;
