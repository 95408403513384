import { buildingConfidence } from 'Assets/img/my-tools-icons';

export const buildingConfidenceGuardianEn = {
  key: 'building-confidence-guardian',
  title: 'Developing I Am Statements',
  titleImage: buildingConfidence,
  titleImageAlt: '',
  contentId: 43,
  sections: [
    {
      key: 'building-confidence-intro',
      description:
        'If daily life brings you down or you feel like you do not know who you are anymore, it may be time to build or rebuild your confidence. When you give of yourself to others, it can be challenging, thankless, isolating, or lonely. Understanding who you are and feeling confident with yourself can help you gain the confidence and ability to better care for others. Here are steps to develop “I am” statements – a powerful tool to help build or rebuild your confidence.',
    },
    {
      key: 'create-i-am-statements',
      header: 'Create “I am” Statements',
      description:
        'Telling yourself “I am” statements will help you remember and believe that you are capable of more than you realize.',
    },
    {
      key: 'step-one-create-statements',
      header: 'Step 1',
      bulletedItems: [
        {
          text: 'Grab a pen and some paper (or use a digital tool)',
        },
        {
          text: 'Think about a time or place where you felt good about yourself',
        },
        {
          text: 'Think about what you were doing, what were you feeling, and what you did well ',
        },
        {
          text: 'Write that down',
        },
        {
          text: 'Here are a few examples of scenarios:',
          subItems: [
            { text: 'Created a delicious meal for your family' },
            { text: 'Won a card game ' },
            { text: 'Accomplished a goal' },
            { text: 'Completed a workout' },
            { text: 'Helped your child overcome a fear' },
          ],
        },
        { text: 'Write down 3 to 5 times when you felt good about yourself ' },
        { text: 'These scenarios can be anything big or small ' },
      ],
    },
    {
      key: 'step-two-create-statements',
      header: 'Step 2',
      bulletedItems: [
        {
          text: 'Think about those moments and try to remember what you were feeling',
        },
        {
          text: 'Sometimes finding words for feeling or emotions can be challenging so here are a few examples:',
          subItems: [
            { text: 'Proud' },
            { text: 'Peaceful' },
            { text: 'Hopeful' },
            { text: 'Joyful' },
            { text: 'Grateful' },
            { text: 'Inspired' },
            { text: 'Happy' },
          ],
        },
        { text: 'For each of the scenarios you wrote down earlier, write down the emotions that you remember feeling' },
      ],
    },
    {
      key: 'step-three-create-statements',
      header: 'Step 3',
      bulletedItems: [
        {
          text: 'Next, for each scenario that you felt good about yourself, think about what you did well to get there',
        },
        {
          text: 'It may be challenging to think of these scenarios and not to give credit to others who helped you. While there is a time to be grateful for others in your life, use this exercise to redirect your thoughts to only what you personally did well',
        },
        {
          text: 'Here are a few examples of things you may have done well:',
          subItems: [
            { text: 'Listened empathetically' },
            { text: 'Worked hard' },
            { text: 'Persevered' },
            { text: 'Acted resiliently' },
            { text: 'Demonstrated kindness, love, or thoughtfulness' },
            { text: 'Showed ambition' },
          ],
        },
        {
          text: 'If you are having trouble determining what you did well, you can repeat any of the previous steps again, which may include coming up with some different scenarios where you felt good about yourself',
        },
      ],
    },
    {
      key: 'step-four-create-statements',
      header: 'Step 4 – Creating and Using Your “I am” Statements',
      bulletedItems: [
        {
          text: 'Now it is time to give yourself love and a confidence boost',
        },
        {
          text: 'An “I am” statement can be anything that demonstrates how great you are',
        },
        {
          text: 'These statements can express a great quality, skill, or may just be something that you need to hear',
        },
        {
          text: 'Here are a few example “I am” statements:',
          subItems: [
            { text: 'I am a great listener.' },
            { text: 'I am resilient and can accomplish my goals.' },
            { text: 'I am loving and kind.' },
            { text: 'I am empathetic.' },
            { text: 'I am proud of who I am.' },
          ],
        },
        {
          text: 'Create “I am” statements as often as you would like',
        },
        {
          text: 'To begin, take time to complete five “I am” statements each week',
        },
        {
          text: 'Put your statements in a place that you can see often; read through them when you are not feeling good about yourself. At minimum, read them or tell them to yourself daily.',
        },
      ],
    },
  ],
};
