import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)`
  && {
    padding: 12px 0px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
    border: solid 1px #4a4f7f;
    text-transform: none;

    background-color: ${(props) =>
      `${props.variant == 'contained' ? '#29265e' : props.variant == 'outlined' ? '#ffffff' : ''}${
        props.disabled ? '' : ' !important'
      }`};
    color: ${(props) =>
      `${props.variant == 'contained' ? '#ffffff' : props.variant == 'outlined' ? '#29265e' : ''}${
        props.disabled ? '' : ' !important'
      }`};
  }
`;
