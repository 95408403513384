import { socialSupport } from 'Assets/img/my-tools-icons';

export const socialSupportEs = {
  key: 'social-support',
  title: 'Red de Apoyo Social',
  titleImage: socialSupport,
  titleImageAlt: '',
  description:
    'Cuando surgen desafíos, una red de apoyo social puede ayudarte a mantener el equilibrio y aumentar la sensación de bienestar.',
  contentId: 2,
  sections: [
    {
      key: 'goal-write-down',
      header: 'Crea una tabla y escribe un desafío o un objetivo que quieras lograr.',
      description: '',
      itemsHeader: 'Cuéntame más:',
      items: [
        { text: 'Haga un gráfico con 4 columnas y un par de filas para comenzar.' },
        {
          text: 'Use papel y lápiz o una computadora/dispositivo.',
        },
        { text: 'Nombre de la primer columna "Mi desafío o objetivo"' },
        { text: 'Nombre de la segunda columna "Tipo de apoyo que yo podría utilizar"' },
        { text: 'Nombre de la tercer columna "Quién"' },
        { text: 'Nombre de la cuarta columna "Cómo"' },
      ],
    },
    {
      key: 'people-identify',
      header: 'Identifica a las personas de tu red de apoyo social.',
      description:
        'En una lista separada, escribe los nombres de las personas que crees que podrían ofrecer apoyo en estas 4 áreas: emocional, práctica, información y compañía. Nota: ¡Algunas personas pueden ofrecer uno o más tipos de apoyo!',
      itemsHeader: 'Cuéntame más:',
      items: [
        {
          text: 'Apoyo emocional: personas que te hacen saber que eres valorado u ofrecen afecto, amor, empatía, preocupación, confianza, aceptación, intimidad, aliento o cariño.',
        },
        {
          key: 'Apoyo emocional: Sugerencias útiles: ¿Qué personas...?',
          text: 'Sugerencias útiles: ¿Qué personas...?',
          subItems: [
            { text: '¿Te dejan ser auténtico?' },
            { text: '¿Puedes hablar con ellas cuando te sientes solo?' },
            { text: '¿Te ayudan a sentirte más relajado cuando estás tenso?' },
            { text: '¿Puedes contar con ellas para que escuchen tus sentimientos más internos sin juzgarte?' },
            { text: '¿Puedes compartir tus pensamientos más privados?' },
            { text: '¿Puedes contar con que se preocuparán por ti, independientemente de lo que suceda?' },
            { text: '¿Te aceptan totalmente, con lo que crees que es tu mejor y tu peor parte?' },
            { text: '¿Te consolarán cuando lo necesites?' },
            { text: '¿Te aprecian como persona?' },
            { text: '¿Están verdaderamente interesadas en ti?' },
          ],
        },
        {
          text: 'Apoyo informativo: personas que pueden brindarte consejos, orientación, sugerencias o información útil para ayudarte a resolver un problema o alcanzar tu objetivo.',
        },
        {
          key: 'Apoyo informativo: Sugerencias útiles: ¿Qué personas...?',
          text: 'Sugerencias útiles: ¿Qué personas...?',
          subItems: [
            { text: '¿Puedes recurrir a ellas para obtener un consejo sobre cómo alcanzar tus objetivos?' },
            {
              text: '¿Ya han alcanzado el objetivo que deseas y pueden compartir sugerencias para ayudarte a alcanzar el tuyo?',
            },
            { text: '¿Puedes contar con ellas para brindarte ideas o ayudarte a evitar los errores?' },
            { text: '¿Puedes solicitar una opinión para manejar los problemas?' },
          ],
        },
        {
          text: 'Apoyo práctico: personas que pueden proporcionar formas más directas o prácticas de ayudar, como asistencia financiera, productos o servicios como llevarte a una clínica o arreglar tu computadora.',
        },
        {
          key: 'Apoyo práctico: Sugerencias útiles: ¿Qué personas...?',
          text: 'Sugerencias útiles: ¿Qué personas...?',
          subItems: [
            { text: '¿Pueden ayudarte con problemas que te resultan difíciles de resolver?' },
            { text: '¿Puedes recurrir a ellas para que te ayuden con cosas prácticas?' },
          ],
        },
        {
          text: 'Apoyo de compañía: personas con quienes puedes hacer actividades sociales o que te dan una sensación de pertenencia.',
        },
        {
          key: 'Apoyo de compañía: Sugerencias útiles: ¿Qué personas...?',
          text: 'Sugerencias útiles: ¿Qué personas...?',
          subItems: [
            { text: '¿Te puedes divertir con ellas?' },
            { text: '¿Puedes disfrutar al estar juntos?' },
            { text: '¿Puedes salir con ellas y hacer cosas juntos?' },
            { text: '¿Disfrutan las mismas cosas que tú?' },
            { text: '¿Comparten un interés o pasión contigo?' },
          ],
        },
      ],
    },
    {
      key: 'chart-fill',
      header: 'Completa el cuadro y une tu red de apoyo social con tu desafío u objetivo.',
      description: '',
      itemsHeader: 'Cuéntame cómo:',
      items: [
        { text: 'Columna 1: Anota cada parte del desafío/objetivo en el que estás trabajando.' },
        {
          text: 'Columna 2: Marca si es apoyo emocional, práctico, informativo o de compañía lo que necesitas para la parte mencionada en la columna 1.',
        },
        {
          text: 'Columna 3: Ingresa las personas de tu lista de “Red de apoyo social” a las que podrías acudir para el tipo de apoyo que indicaste en la columna 2.',
        },
        {
          text: 'Columna 4: Escribe brevemente cómo o por qué esa persona podría ayudarte a alcanzar tu desafío/objetivo',
        },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Autorreflexión',
      description: 'Dedica un momento de tranquilidad para revisar tu experiencia con esta actividad.',
      itemsHeader: 'Percepciones y marcadores del camino',
      items: [
        { text: '¿Cómo puedes utilizar tu red de apoyo social con más frecuencia?' },
        {
          text: '¿Qué pasos puedes tomar para involucrar a tu red de apoyo social de manera más activa para lograr tu desafío/objetivo?',
        },
        { text: '¿Qué has aprendido de este ejercicio?' },
        { text: '¿Cómo te sentías antes de completar este ejercicio?' },
        { text: '¿Cómo te sientes después de hacerlo?' },
        { text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?' },
        { text: '¿Qué disfrutaste más de este ejercicio?' },
        { text: '¿En qué aspectos/elementos del desafío/objetivo deseas trabajar en primer lugar?' },
        { text: '¿Qué ayudaría a facilitar el primer paso?' },
        {
          text: '¿Cuáles serían algunos de los beneficios de que las personas realicen esta actividad con más frecuencia en general?',
          divider: true,
        },
        {
          text: 'Tu red de apoyo social puede servirte para desarrollar habilidades útiles y mantenerte equilibrado cuando surjan dificultades.',
        },
        {
          text: 'La familia, los amigos, los vecinos, los compañeros de trabajo y los conocidos pueden crear una red de apoyo social para ayudarte a cumplir tus necesidades diarias básicas, alcanzar objetivos y aspiraciones más amplias y aumentar la sensación de bienestar.',
        },
      ],
    },
  ],
};
