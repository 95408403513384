import React from 'react';
import { mytools } from 'Assets/img/thrive-icons';
import { FormattedMessage } from 'react-intl';
import { StyledContainer, StyledTextBubble, StyledMyToolsImage } from './styles';

function Banner() {
  return (
    <StyledContainer>
      <StyledMyToolsImage src={mytools} alt="" />
      <StyledTextBubble>
        <FormattedMessage id="my-tools.banner" />
      </StyledTextBubble>
    </StyledContainer>
  );
}

export default Banner;
