import React from 'react';
import { Grid } from '@mui/material';
import { StyledTypingBubbleWrapper } from './styles';
import CircleIcon from '@mui/icons-material/Circle';

const TypingBubbles = () => {
  return (
    <Grid container justifyContent="left" alignItems="left">
      <Grid item mobile={10}>
        <StyledTypingBubbleWrapper>
          <CircleIcon fontSize="small" sx={{ color: '#D8D8D8' }} />
          <CircleIcon fontSize="small" sx={{ color: '#D8D8D8' }} />
          <CircleIcon fontSize="small" sx={{ color: '#D8D8D8' }} />
        </StyledTypingBubbleWrapper>
      </Grid>
      <Grid item mobile={2}></Grid>
    </Grid>
  );
};

export default TypingBubbles;
