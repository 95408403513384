import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReminderContainer, Header, Content, ButtonContainer } from './styles';
import { Interweave } from 'interweave';
import useReminder from 'Core_Hooks/useReminder';
import { appInsights } from 'Core_Helpers/AppInsights';
import Button from 'Stories/components/Button';
import { Fade } from '@mui/material';

const Reminder = () => {
  const { reminder } = useReminder();

  const buttonTypes = {
    PHONE: 'PHONE',
    PHONENUMBER: 'PHONE',
    INAPPURL: 'APP',
    APPVIEW: 'APP',
    LINK: 'LINK',
  };

  const getButtonProps = (type, value) => {
    let buttonType = buttonTypes[type.toUpperCase()] ?? buttonTypes.LINK;
    if (buttonType === 'APP') {
      return {
        to: value ?? '#',
      };
    } else if (buttonType === 'LINK') {
      return {
        href: value,
        target: '_blank',
        rel: 'noreferrer',
      };
    } else {
      // PHONE
      return {
        href: '#',
        onClick: (event) => {
          event.preventDefault();
          window.open(`tel:${value}`, '_self');
          onClick(event);
        },
      };
    }
  };

  const onClick = () => {
    appInsights.trackEvent({ name: 'Reminder Button Clicked' });
  };

  return (
    <>
      {reminder && (
        <ReminderContainer>
          <Fade in={true}>
            <Header>
              <FormattedMessage id="reminder.header" />
            </Header>
          </Fade>
          <Fade in={true}>
            <Content>
              <Interweave content={reminder.text} />
            </Content>
          </Fade>
          <Fade in={true}>
            <ButtonContainer>
              {reminder.providerResource?.length > 0 &&
                reminder.providerResource?.some((reminder) => reminder.type?.toUpperCase() !== 'NONE') &&
                reminder.providerResource?.map(({ type, value, title }, index) => (
                  <Button key={index} onClick={onClick} {...getButtonProps(type, value)}>
                    {title}
                  </Button>
                ))}
            </ButtonContainer>
          </Fade>
        </ReminderContainer>
      )}
    </>
  );
};

export default Reminder;
