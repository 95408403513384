import React, { Component, ElementType } from 'react';
import Wrapper from './wrapper';
import manager, { ComponentManager } from './manager';
import { flushSync } from 'react-dom';
import { logDiagnosticTrace } from '../../helpers/logDiagnosticTrace';

type Comp = {
  Component: ElementType<any>;
  props: object;
  manager: ComponentManager;
  mainManager: typeof manager;
};

type Props = object;

type State = {
  components: Array<Comp>;
  lastId: number;
};

class Renderer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      lastId: 0,
      components: [],
    };

    this.addComponent = this.addComponent.bind(this);
  }

  componentDidMount() {
    manager.events.on('component-added', this.addComponent, this);
    manager.events.on('component-removed', this.removeComponent, this);
    logDiagnosticTrace('Phaser-react:Renderer Mounted');
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    logDiagnosticTrace(`Phaser-react:Renderer Error ${errorInfo} ${error}`);
  }

  componentWillUnmount(): void {
    logDiagnosticTrace('Phaser-react:Renderer Unmounted');
  }

  addComponent(component: Comp) {
    flushSync(() => {
      this.setState((previousState) => ({
        lastId: previousState.lastId + 1,
        components: [...previousState.components, component],
      }));
    });
    logDiagnosticTrace(`Phaser-react:Renderer Update-addComponent-${this.state.lastId}`);
  }

  removeComponent(id: number) {
    this.setState((previousState) => ({
      lastId: previousState.lastId + 1,
      components: previousState.components.filter((Comp) => Comp.manager.id !== id),
    }));
    logDiagnosticTrace(`Phaser-react:Renderer Update-removeComponent-${this.state.lastId}`);
  }

  render() {
    const { components } = this.state;

    return (
      <>
        {components.map(({ Component, props, manager, mainManager }) => {
          return (
            <Wrapper key={manager.id} Comp={Component} extraProps={props} manager={manager} mainManager={mainManager} />
          );
        })}
      </>
    );
  }
}

export default Renderer;
