import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform } from '@ionic/react';
import { CapacitorStorage } from 'Core_Helpers/authentication/CapacitorStorage';
import urlcat from 'urlcat';
import getAuthClient from 'Core_Helpers/getAuthClient';
import AuthorizationService from 'Core_Helpers/authentication/AuthService';

export class PushEvents {
  static pushEvents;

  static doesTokenExist(results, newToken) {
    if (results) {
      return results.filter((result) => result.token === newToken).length >= 1;
    }

    return false;
  }

  static determineSubscription() {
    if (isPlatform('ios')) {
      return 'APNS';
    } else if (isPlatform('android')) {
      return 'FCM';
    }
  }

  static buildPushEventsInstance() {
    const platform = this.determineSubscription();

    PushNotifications.addListener('registrationError', (error) => {
      throw 'Error on registration: ' + JSON.stringify(error);
    });

    return PushNotifications.addListener('registration', async (token) => {
      const authService = await AuthorizationService.getInstance();
      const user = await authService.getUserInfo();
      const userId = user.sub;
      const getTokenUrl = urlcat(process.env.API_BASE_URL, '/v1/pushsubscriptions');
      const nativePushUrl = urlcat(process.env.API_BASE_URL, '/v1/pushsubscriptions/nativePush');
      const enableNotificationsUrl = urlcat(process.env.API_BASE_URL, '/v1/notificationsettings/:userId', {
        userId: userId,
      });
      const capacitorStorage = new CapacitorStorage();
      let nativePushNotificationCommand = {
        UserId: userId,
        Token: token.value,
        Platform: platform,
      };
      let notificationSettings = {
        UserId: userId,
        IsGloballyEnabled: true,
      };

      const client = await getAuthClient();

      // Following logic will help to prevent duplicate registrations of a user on a platform
      const existingTokens = await client.get(urlcat(getTokenUrl, { userId, includeExpired: false }));
      if (this.doesTokenExist(existingTokens?.data, token.value)) {
        await client.put(nativePushUrl, nativePushNotificationCommand);
      } else {
        await client.post(nativePushUrl, nativePushNotificationCommand);
      }

      await capacitorStorage.setItem('pushNotificationToken', token.value);
      await client.put(enableNotificationsUrl, notificationSettings);
    });
  }

  // Wrapping the listeners into a single instance to prevent them from ever being registered multiple times.
  static get Instance() {
    if (!this.pushEvents) {
      this.pushEvents = this.buildPushEventsInstance();
    }

    return this.pushEvents;
  }
}
