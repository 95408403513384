import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { appInsights } from 'Core_Helpers/AppInsights';
import PropTypes from 'prop-types';
import { Tab } from '@mui/material';
import { REMINDERS } from 'Core_Pages/Routes/RoutesConfig';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

const RemindersNavbarItem = ({ onClick, ...props }) => {
  const history = useHistory();
  const intl = useIntl();

  const { selected } = props;

  const onNavItemClick = (event) => {
    onClick(event);
    history.push(REMINDERS);
    appInsights.trackEvent({ name: 'Reminders_Clicked' });
  };

  const Icon = selected ? NotificationsIcon : NotificationsNoneIcon;

  return (
    <>
      <Tab
        {...props}
        icon={<Icon aria-hidden="true" />}
        label={intl.formatMessage({ id: 'menu-header.reminders' })}
        href={REMINDERS}
        aria-current={selected}
        onClick={onNavItemClick}
      />
    </>
  );
};

RemindersNavbarItem.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default RemindersNavbarItem;
