import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormGroup } from '@mui/material';
import { Label, StyledCheckbox, CheckboxLink } from './styles';
import { black } from 'Styles/colors';

function ControlledAgreementCheckbox({ id, linkTo, fieldName, control, linkPrefix, linkLabel }) {
  return (
    <Controller
      id={id}
      name={fieldName}
      control={control}
      defaultValue={false}
      render={({ field: { onChange } }) => {
        return (
          <FormGroup sx={{ color: black._87 }}>
            <Label
              control={<StyledCheckbox sx={{ fontSize: '22px' }} onChange={(e) => onChange(e.target.checked)} />}
              label={
                <>
                  {linkPrefix}{' '}
                  <CheckboxLink href={linkTo} target="_blank" rel="noopener">
                    {linkLabel}
                  </CheckboxLink>
                </>
              }
            />
          </FormGroup>
        );
      }}
    />
  );
}

ControlledAgreementCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  linkPrefix: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
};

export default ControlledAgreementCheckbox;
