import { useState, useEffect } from 'react';

const useWebInstallPrompt = () => {
  const [installPromptEvent, setInstallPromptEvent] = useState(undefined);

  useEffect(() => {
    const beforeInstallPromptHandler = (event) => {
      event.preventDefault();

      setInstallPromptEvent(event);
    };
    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
  }, []);

  const handleInstallAccepted = async () => {
    installPromptEvent.prompt();

    // This might be a good thing to track when we get app insights setup
    setInstallPromptEvent(null);
  };

  return [installPromptEvent, handleInstallAccepted];
};

export default useWebInstallPrompt;
