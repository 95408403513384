import React from 'react';
import PropTypes from 'prop-types';
import { StyledLoadingButton } from './styles';

function Button({ children, ...props }) {
  return <StyledLoadingButton {...props}>{children}</StyledLoadingButton>;
}

Button.variant = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text',
};

Button.color = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.oneOf(Object.values(Button.color)),
  variant: PropTypes.oneOf(Object.values(Button.variant)),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  color: Button.color.PRIMARY,
  variant: Button.variant.CONTAINED,
  loading: false,
  disabled: false,
};

export default Button;
