import { addBackgroundsToScene, addCharacterToScene, useCamera } from 'Core_Games/Scripts/sceneHelper';
import { Scene } from '../Scene';

export class Storyboard extends Scene {
  constructor(childName) {
    super(childName);

    this.flipXOverrideAtStart = false;
    this.currentPageIndex = 0;
    this.gutter = 0;

    this.combinedBackgrounds = [];
    this.combinedForegrounds = [];

    this.backgroundIndex = 0;
  }

  init(scene, sceneConfig, sceneData) {
    super.init(scene, sceneConfig, sceneData);
    this.currentSceneConfig = this.sceneConfig[this.sceneData.gameData.gameData.petState.petType][this.sceneType.NAME];

    this.cameras.main.backgroundColor = Phaser.Display.Color.HexStringToColor(
      this.currentSceneConfig.GAMEBACKGROUNDCOLOR ?? '#FFFFFF',
    );

    this.backgroundSplit = this.currentSceneConfig.BACKGROUNDSPLIT ?? 2;
    this.flipXOverrideAtStart = this.currentSceneConfig.FLIPXATSTART ?? false;

    this.startLocationPercentageX = this.currentSceneConfig.STARTX ?? 1.0;
    this.startLocationPercentageY = this.currentSceneConfig.STARTY ?? 0.9;
    this.startXOffset = this.currentSceneConfig.STARTXOFFSET ?? 0.0;
    this.startYOffset = this.currentSceneConfig.STARTYOFFSET ?? 0.0;
    this.squishBackgroundWidth = this.currentSceneConfig.SQUISHBACKGROUND ?? 1.0;
    this.isFacingRight = this.currentSceneConfig.RIGHTFACING ?? false;
    this.backgroundCompress = this.currentSceneConfig.BACKGROUNDCOMPRESS ?? 1.0;
    this.backgroundHeightScale = this.currentSceneConfig.BACKGROUNDHEIGHTSCALE ?? 1.5;
    this.endLocationPercentageX = this.currentSceneConfig.endLocationPercentageX ?? this.startLocationPercentageX;
    this.endLocationPercentageY = this.currentSceneConfig.endLocationPercentageY ?? this.startLocationPercentageY;
  }

  preload() {
    super.preload();
  }

  create() {
    super.create();

    let _this = this;
    let _sceneConfig = this.currentSceneConfig;

    this.engine.engineAssets.load.on(Phaser.Loader.Events.COMPLETE, function () {
      // config
      _this.originalPetScale = _this.engine.engineAssets.petScale;
      _this.petWidth = _this.engine.engineAssets.petWidth;
      _this.petHeight = _this.engine.engineAssets.petHeight;

      // add backgrounds/foreground
      if (_sceneConfig.ASSETS.BACKGROUND) {
        _this.background = _this.add.sprite(
          0,
          0,
          _this.sceneData.gameData.gameType.ATLAS,
          _sceneConfig.ASSETS.BACKGROUND.DATA,
        );
        _this.backgrounds.push({ key: _sceneConfig.ASSETS.BACKGROUND.NAME, data: _this.background });
      }
      if (_sceneConfig.ASSETS.FOREGROUND) {
        _this.foreground = _this.add.sprite(
          0,
          0,
          _this.sceneData.gameData.gameType.ATLAS,
          _sceneConfig.ASSETS.FOREGROUND.DATA,
        );
        _this.backgrounds.push({ key: _sceneConfig.ASSETS.FOREGROUND.NAME, data: _this.foreground });
      }

      if (_sceneConfig.COMBINED_BACKGROUND) {
        _this.combineBackgrounds(_this, _sceneConfig, _sceneConfig.COMBINED_BACKGROUND);
      }

      if (_sceneConfig.BACKGROUNDTRANSFORM) {
        const scaleX = _sceneConfig.BACKGROUNDTRANSFORM.scaleX ?? 1.0;
        _this.background.setScale(scaleX, _sceneConfig.BACKGROUNDTRANSFORM.scaleY);
        _this.background.isScaled = true;
      }
      addBackgroundsToScene(_this);

      // set asset pet scale data
      const startLocation = _this.getPagePoint(0, _this.startLocationPercentageX, _this.startLocationPercentageY);
      _this.startLocationStoryX = startLocation.x;
      _this.startLocationStoryY = startLocation.y;
      _this.endLocationStoryX = startLocation.x;
      _this.endLocationStoryY = startLocation.y;

      // add pet to scene
      _this.pet = addCharacterToScene(
        _this,
        { x: _this.startLocationStoryX, y: _this.startLocationStoryY },
        _this.sceneData.gameData.gameData.petState.petType,
        _this.configurablePetScale,
        _this.flipXOverrideAtStart,
      );
      _this.pet.setScale(_this.configurablePetScale);

      const isCombined = _this.combinedBackgrounds.length ? true : false;

      // set up camera
      useCamera(_this, _this.pet, isCombined);
    });
  }

  combineBackgrounds(_this, sceneConfig, backgrounds) {
    let allBackgrounds = [];
    let allForegrounds = [];
    let combined = [];
    let currentXLocation = 0;
    let lastBackgroundWidth = 0;
    let currentBackgroundWidth = 0;
    let currentBackgroundHeight = 0;
    let perBackgroundWidth = 0;
    for (let i = 0; i < backgrounds.length; i++) {
      let background = backgrounds[i];
      if (background.BACKGROUND) {
        const backgroundKey = background.BACKGROUND[0];
        const currentBackgroundData = sceneConfig.ASSETS[backgroundKey];
        let currentBackground = _this.add.sprite(
          currentXLocation,
          0,
          _this.sceneData.gameData.gameType.ATLAS,
          currentBackgroundData.DATA,
        );

        if (perBackgroundWidth < currentBackground.width) {
          perBackgroundWidth = currentBackground.width;
        }
        if (currentBackgroundHeight < currentBackground.height) {
          currentBackgroundHeight = currentBackground.height;
        }
        currentBackground.setDisplaySize(perBackgroundWidth, currentBackgroundHeight);

        currentBackground.isCombined = true;

        let currentData = { key: currentBackgroundData.NAME, data: currentBackground };
        allBackgrounds.push(currentData);
        combined.push(currentData);

        currentBackgroundWidth = currentBackground.width;
      } else if (background.FOREGROUND) {
        const foregroundKey = background.FOREGROUND[0];
        const currentBackgroundData = sceneConfig.ASSETS[foregroundKey];
        let currentBackground = _this.add.sprite(
          currentXLocation,
          0,
          _this.sceneData.gameData.gameType.ATLAS,
          currentBackgroundData.DATA,
        );
        // keep the width confined so it doesn't overlap other backgrounds
        if (!background.BACKGROUND) {
          if (perBackgroundWidth < currentBackground.width) {
            perBackgroundWidth = currentBackground.width;
          }
          if (currentBackgroundHeight < currentBackground.height) {
            currentBackgroundHeight = currentBackground.height;
          }
          currentBackground.isCombined = true;
        }
        currentBackground.setDisplaySize(perBackgroundWidth, currentBackgroundHeight);
        currentBackground.setOrigin(0, 0);
        let currentData = { key: currentBackgroundData.NAME, data: currentBackground };
        allForegrounds.push(currentData);
        combined.push(currentData);
      }
      lastBackgroundWidth = currentBackgroundWidth;
      currentXLocation += lastBackgroundWidth;
      currentBackgroundWidth = 0;
    }

    // update coordinates
    for (let i = 0; i < combined.length; i++) {
      let currentBackgroundData = combined[i].data;
      currentBackgroundData.setOrigin(0, 0);
      currentBackgroundData.x = i * perBackgroundWidth;
      currentBackgroundData.y = 0;
    }

    let combinedBackground = [];
    let combinedForeground = [];
    combinedBackground.push.apply(combinedBackground, allBackgrounds);
    combinedForeground.push.apply(combinedForeground, allForegrounds);

    _this.combinedBackground = {
      height: currentBackgroundHeight,
      perWidth: perBackgroundWidth,
      NAME: 'COMBINED_BACKGROUND',
      key: 'COMBINED_BACKGROUND',
      width: backgrounds.length * perBackgroundWidth,
      data: combined,
    };

    _this.combinedBackgrounds.push(_this.combinedBackgrounds);
    _this.combinedForegrounds.push(combinedForeground);

    _this.backgrounds.push.apply(_this.backgrounds, combined);
  }

  update() {
    super.update();
  }
}
