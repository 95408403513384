import React, { useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { SuccessfulDeleteContainer, StyledCheckCircleIcon, Description } from './styles';
import Layout from 'Core_Pages/Account/Layout';
import Button from 'Stories/components/Button';
import { SIGNUP } from 'Core_Pages/Routes/RoutesConfig';
import { useHistory } from 'react-router-dom';
import { displayDarkStatusBar } from 'Core_Helpers/statusBar';

function Deleted() {
  const intl = useIntl();
  const history = useHistory();
  const accountDeletedText = intl.formatMessage({ id: 'profile.delete-account.deleted' });

  const onClickContinue = () => {
    history.push(SIGNUP);
  };

  useEffect(() => {
    displayDarkStatusBar();
  }, []);

  return (
    <>
      <ScreenReaderWrapper id="PageTitle">
        <h1>
          <FormattedMessage id="pyxir.deleted" />
        </h1>
      </ScreenReaderWrapper>
      <Layout>
        <SuccessfulDeleteContainer>
          <StyledCheckCircleIcon />
          <Description>{accountDeletedText}</Description>
          <Button fullWidth id={`Return_To_Signup_Button`} autoCapitalize="none" onClick={onClickContinue}>
            {intl.formatMessage({ id: 'account.sign-up' })}
          </Button>
        </SuccessfulDeleteContainer>
      </Layout>
    </>
  );
}

export default Deleted;
