import React from 'react';
import PropTypes from 'prop-types';

function StoredImage({ container, assetName, altText }) {
  return <img src={`${process.env.STORAGE_ACCOUNT}/${container}/${assetName}`} alt={altText} />;
}

StoredImage.propTypes = {
  container: PropTypes.string.isRequired,
  assetName: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
};

export default StoredImage;
