import { interactionTypes, SMARTWHEEL } from 'Core_Components/Encounter/EncounterDictionary';
import encounterMessageToChatConfig from './encounterMessageToChatConfig';
import { BUBBLE, WHEEL } from 'Core_Components/Chat/ChatItem/ChatTypes.js';
import { RIGHT } from 'Core_Components/Chat/ChatItem/Position.js';

const feedToChatConfig = (chatMessage, isLastMessage, resources) => {
  const messages = [];

  if (interactionTypes[chatMessage.interactionTypeId] === SMARTWHEEL) {
    messages.push({
      type: WHEEL,
      isEnabled: false,
      responseChoices: [{ json: chatMessage.json }],
    });

    messages.push({ type: BUBBLE, text: chatMessage.messageText, position: RIGHT });
  } else if (isLastMessage && chatMessage.json) {
    const parsedMessage = JSON.parse(chatMessage.json);
    if (parsedMessage.Slices) {
      messages.push({
        type: WHEEL,
        isEnabled: true,
        responseChoices: [{ json: chatMessage.json }],
      });
    } else {
      messages.push(encounterMessageToChatConfig(chatMessage, resources));
    }
  } else {
    messages.push(encounterMessageToChatConfig(chatMessage, resources));
  }

  return messages.flat();
};

export default feedToChatConfig;
