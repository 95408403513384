import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCoreSelector } from 'Contexts/StoreContext';
import { appInsights } from 'Core_Helpers/AppInsights';
import MyToolsStaticContent from 'Core_Components/StaticContent/MyToolsStaticContent';
import Spinner from 'Core_Components/Spinner/Spinner';
import { staticContentConfig, logKey } from 'Core_Components/StaticContent/StaticContentConfig/staticContentConfig';
import { selectLanguage } from 'Core_Redux/user';

function StaticContent() {
  const { contentType } = useParams();
  const language = useCoreSelector(selectLanguage);
  const [config, setConfig] = useState();

  const getContentConfig = () => {
    const config = staticContentConfig[contentType?.toUpperCase()];
    return config ? config[language] : null;
  };

  useEffect(() => {
    const currentConfig = getContentConfig();
    setConfig(currentConfig);
    const contentKey = logKey[contentType?.toUpperCase()];

    appInsights.trackEvent({ name: `MyTools_Enter_${contentKey}` });

    // use IIFE to bind keys to scope within callback
    return (
      () => () =>
        appInsights.trackEvent({ name: `MyTools_Leave_${contentKey}` })
    )();
  }, []);

  return config ? <MyToolsStaticContent config={config} /> : <Spinner />;
}

export default StaticContent;
