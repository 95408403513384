import { useEffect, useReducer } from 'react';
import getAuthClient from 'Core_Helpers/getAuthClient';

const dataDeleteReducer = (state, action) => {
  switch (action.type) {
    case 'DELETE_INIT':
      return { ...state, isLoading: true, isError: false, isComplete: false };
    case 'DELETE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasErrored: false,
        errorMessage: '',
        isComplete: true,
      };
    case 'DELETE_FAILURE':
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
        errorMessage: 'Delete Failed',
        isComplete: true,
      };
  }
};

const useAxiosDelete = (url, delay = 0) => {
  const [state, dispatch] = useReducer(dataDeleteReducer, {
    isLoading: false,
    hasErrored: false,
    errorMessage: '',
    isComplete: false,
  });

  useEffect(() => {
    let didCancel = false;

    const submitDelete = async () => {
      dispatch({ type: 'DELETE_INIT' });

      try {
        const authorizedApi = await getAuthClient();
        const response = await authorizedApi.delete(url);
        if (!didCancel) {
          setTimeout(() => {
            dispatch({ type: 'DELETE_SUCCESS', payload: response });
          }, delay - response.responseTime);
        }
      } catch (err) {
        if (!didCancel) {
          dispatch({ type: 'DELETE_FAILURE' });
        }
      }
    };

    if (url) {
      submitDelete();
    }

    return () => {
      didCancel = true;
    };
  }, [url]);

  return { ...state };
};

export default useAxiosDelete;
