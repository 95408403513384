import { createTheme } from '@mui/material/styles';
import { purple, teal, black } from './colors';

export const breakpoints = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    desktop: true;
  }
}

// Schema for default Material theme object - https://mui.com/customization/default-theme/
const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      desktop: 1200,
    },
  },
  palette: {
    primary: {
      main: purple._900,
      contrastText: '#fff',
    },
    secondary: {
      main: teal._300,
      contrastText: black._100,
    },
    error: {
      main: '#b10221',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Karla',
    fontSize: 16,
    button: {
      textTransform: 'none',
      fontSize: 18,
    },
  },
});

export default theme;
