import { childActingOut } from 'Assets/img/my-tools-icons';

export const childIsActingOutEs = {
  key: 'child-acting-out-es',
  title: 'Mi hijo/a se comporta mal',
  titleImage: childActingOut,
  titleImageAlt: '',
  contentId: 50,
  sections: [
    {
      key: 'child-acting-out-intro-es',
      description:
        'Los niños de crianza pueden experimentar una serie de emociones, como ira, tristeza, soledad y otras. Esto puede afectar a tus sentimientos y dificultar el manejo de tus propias emociones. Sin un entorno de vida estable, es posible que tu hijo/a maneje la situación con emociones incontroladas y busque formas de establecer un mayor control en su vida. Aunque pueda parecer que tu hijo/a “se está comportando mal”, lo más probable es que no sea deliberado. Es una habilidad aprendida de lo que ha experimentado en su vida. Si estás leyendo este artículo y tu hijo/a se está comportando mal, no olvides asegurarte de estar comunicándote con su terapeuta de salud mental para encontrar la mejor manera de apoyarlo/a. Si no recibe terapia habitual, es importante que busques ayuda para conseguirle el apoyo de salud mental que necesita. Estos consejos te ayudarán a apoyar a tu hijo/a con sus emociones.',
    },
    {
      key: 'acting-out-anger',
      header: 'Ira',
      description:
        'Expresar la ira es importante para todos. Los que han sufrido traumas en el pasado (y es probable que un niño de crianza los haya sufrido) pueden no saber cómo expresarlos apropiadamente. La ira provocada por traumas anteriores aparece a menudo en situaciones cotidianas y puede manifestarse de distintas maneras. A continuación se muestran algunos ejemplos de ira que podrían ser una respuesta al trauma:',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Un estado constante de irritación o enojo por todo lo que le rodea.',
        },
        {
          text: 'Arrebatos repentinos de enojo que parecen “aparecer de la nada”.',
        },
        {
          text: 'Enojo ante una frustración aparentemente pequeña que escala rápidamente a gritos, lanzamiento de objetos o agresión física.',
        },
      ],
    },
    {
      key: 'reacting-to-anger-es',
      header: 'Reacción a la ira de tu hijo/a',
      description:
        'Como padres o cuidadores, cuando vemos a un niño o niña actuar de esta manera, nuestra primera reacción suele ser detenerlo/a rápidamente levantando la voz o castigándolo/a. Sin embargo, reaccionar con empatía y calma puede ayudarle a sentirse en un espacio seguro. Aunque llevará tiempo, si consigues reaccionar así de forma constante, tu hijo/a empezará a sentir que puede expresar su enojo de forma segura cuando estés presente. Dependiendo de la edad de tu hijo/a, aquí tienes algunos consejos para ayudarle con su enojo en el momento:',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Si estás con otras personas, busca un espacio tranquilo donde pueda gritar o expresarse cómodamente.',
        },
        {
          text: 'El arte de desviar la atención: ofrécele una actividad más agradable o dirige a tu hijo/a hacia algo que se aleje de lo que le enoja.',
        },
        {
          text: 'Asegúrale que está seguro/a contigo.',
        },
      ],
    },
    {
      key: 'acting-out-sadness-es',
      header: 'Tristeza',
      description:
        'Que tu hijo/a esté triste es completamente normal. Como padres o cuidadores, nuestro papel no es impedir que nuestro hijo/a esté triste, sino ayudarle a experimentar, reconocer y trabajar esas emociones.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'La tristeza puede parecer más un enojo que lleva a expresiones emocionales como el llanto o los gritos.',
        },
        {
          text: 'No des a tu hijo/a falsos sentimientos de esperanza como “todo va bien” o “no volverás a lastimarte”. Aunque eso pueda parecer útil en el momento, la realidad de un niño o niña de crianza es que probablemente no todo esté bien y que puede sufrir más dolor y heridas en su vida. Hacer afirmaciones que no puedes garantizar puede hacer que tu hijo/a pierda la confianza en ti y en lo que dices.',
        },
        {
          text: 'Cambia tu apoyo a cosas que puedas controlar, como: “quiero ayudar a que las cosas vayan mejor para ti” o “voy a hacer todo lo que pueda para que estés seguro”.',
        },
        {
          text: 'Ofrecer consuelo y empatía es lo más importante que puedes hacer por tu hijo/a.',
        },
        {
          text: 'Reconoce su situación y dile que no pasa nada por estar triste.',
        },
        {
          text: 'Háblale de formas saludables de expresar la tristeza, como llorar o hablar de lo que le entristece.',
        },
      ],
    },
  ],
};
