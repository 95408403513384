import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const UnderlinedGrid = styled(Grid)`
  ::after {
    content: '';
    width: 135%;
    border-bottom: 1px solid grey;
    margin: 0 auto;
    display: inline-block;
    float: right;
  }
`;

export const StyledContainerLayout = styled.div`
  padding: 0 20px 15px 25px;

  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  flex: 1;
  flex-flow: column nowrap;
  align-items: flex-start;
  color: black;

  h2 {
    margin: 20px 0 16px 0;
    font-size: 20px;
    color: #3c417d;
    font-weight: 700;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
