import { rephrasingThoughts } from 'Assets/img/my-tools-icons';

export const rephrasingThoughtsEn = {
  key: 'rephrasing-thoughts',
  title: 'Rephrasing my Thoughts',
  titleImage: rephrasingThoughts,
  titleImageAlt: '',
  contentId: 39,
  sections: [
    {
      key: 'rephrasing-thoughts-intro',
      description:
        'Are you consumed with negative or worrisome thoughts? Do you often think about worst-case scenarios? Or perhaps have a hard time relaxing or sleeping because you are thinking about too many things? Combating unhealthy thoughts can help you feel less lonely or isolated. Here are a few tips for you to help you redirect and change what you are thinking about.',
    },
    {
      key: 'acknowledge-thoughts',
      header: 'Step 1 – Acknowledge and Better Understand Your Thoughts',
      description:
        'This is an activity that you can do in your head, but if this is your first time with this exercise, or you simply prefer to visually see your thoughts, grab a pen and some paper (or use a digital tool).',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Think about or write down your thoughts. Acknowledge and capturing all of them – the good thoughts and any thoughts that may be causing worry, anger, sadness, or any other emotion.',
        },
        { text: 'Think about each of your thoughts and try to remember how you feel when you have these thoughts.' },
        {
          text: 'Now group them into categories of your own choosing. Some thoughts may fit into multiple categories. The goal is to understand where your thoughts come from and how you feel about them. Here are some example category areas to get you started:',
          subItems: [
            { text: 'Worrisome' },
            { text: 'Sad' },
            { text: 'Angry' },
            { text: 'About my family' },
            { text: 'Parenting' },
            { text: 'Happy' },
            { text: 'Encouraging' },
            { text: 'Validating' },
            { text: 'Supportive to someone else' },
            { text: 'Show gratitude' },
          ],
        },
      ],
    },
    {
      key: 'rephrase-thoughts',
      header: 'Step 2 – Rephrase Your Thoughts',
      description:
        'Next, for each thought, decide what is most helpful about this thought for you moving forward. Here are a few tips to keep in mind when rephrasing your thoughts, followed by some examples.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'It is okay to feel loneliness, anger, or sadness. We do not want to eliminate these feelings. A rephrased thought could be allowing yourself to feel these emotions, but not allowing yourself to dwell on it.',
        },
        {
          text: 'Use your feelings of anger, frustration, or sadness to motivate yourself to be better. For example, you could rephrase your thought to:',
          subItems: [
            {
              text: 'Today I will use my anger to give me energy to walk or to motivate myself to complete a task around the house.',
            },
          ],
        },
        {
          text: 'Rephrasing your thoughts does not mean only making them positive, it sometimes can be as simple as a subtle or slight shift that changes how you view the thought.',
        },
        {
          text: 'The following are example thoughts for you to practice reframing. After you have read each “original” thought, look at the example way to reframe it. Keep in mind that here is no single “right” way to rephrase a thought.',
          subItems: [
            {
              text: '<b>Original Thought</b>: I am worried that the way I talked to the individual I am caring for makes me a bad caregiver.',
            },
            {
              text: '<b>Rephrased Thought</b>: I am a great caregiver who works hard to self-reflect about how I provide care and how I can always be working improve my patience and frustrations.',
            },
            { text: '<b>Original Thought</b>: I am missing the independence I had before I was a caregiver.' },
            {
              text: '<b>Rephrased Thought</b>: I am going to take time each day to think about one way that I can feel independent or do something for myself. It is okay to take care of myself.',
            },
            { text: '<b>Original Thought</b>: I’m not doing anything right today.' },
            {
              text: '<b>Rephrased Thought</b>: While today has been a more challenging day, I completed all the tasks I set out to do and I am going to take the opportunity to use today’s challenges to make tomorrow better.',
            },
            { text: '<b>Original Thought</b>: No one understands what I am going through.' },
            {
              text: '<b>Rephrased Thought</b>: I have a unique life and am the only one who can truly understand my struggles, frustrations, or joys. I can only control my thoughts and I cannot control what others think of me and my situation.',
            },
          ],
        },
        {
          text: 'Now take one of your thoughts and rephrase it.',
        },
        { text: 'You can do this exercise as often as you would like.' },
      ],
    },
  ],
};
