import { completingDailyTasks } from 'Assets/img/my-tools-icons';

export const completingDailyTasksEs = {
  key: 'completing-daily-tasks-es',
  title: 'Completar las tareas diarias',
  titleImage: completingDailyTasks,
  titleImageAlt: '',
  contentId: 32,
  sections: [
    {
      key: 'completing-daily-tasks-intro-es',
      description:
        'Como cuidador, lo más probable es que te pidan apoyo tanto en las actividades de la vida diaria (AVD) como en las actividades instrumentales de la vida diaria (AIVD). Las AVD incluyen actividades como ayuda para bañarse, ir al baño, vestirse, y para la movilización, las transferencias y el cuidado personal. Las AIVD incluyen actividades como lavandería, limpieza, compra de comestibles y preparación de comidas, transporte y compañía. En este artículo ofrecemos algunos consejos e ideas que considerar al ayudar con las AIVD.',
    },
    {
      key: 'general-tips-for-assistance-es',
      header: 'Consejos generales para ayudar con las actividades instrumentales de la vida diaria',
      description:
        'Siempre que sea posible, fomenta la independencia y la participación de la persona a la que cuidas cuando la ayudes con las AIVD. Si es un adulto mayor, recuerda que ha realizado estas tareas de forma independiente durante décadas y probablemente tenga un sistema y preferencias sobre cómo se hacen las cosas. O tal vez la persona a la que estás cuidando solo haya perdido temporalmente la capacidad de realizar algunas de estas tareas debido a una lesión o algún otro evento. Ser paciente con la persona a la que estás cuidando, ofrecerle oportunidades de participar, observar sus habilidades y controlar sus propios niveles de energía los ayudará a ambos a encontrar un camino más armonioso. Estas son algunas de las AIVD más comunes en las que te puede pedir ayuda y algunos aspectos que debes tener en cuenta al realizarlas.',
    },
    {
      key: 'grocery-meal-prep-es',
      header: 'Compra de comestibles y preparación de comidas',
      description: '¡La nutrición y una dieta bien equilibrada son importantes para todos!',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Crea un plan de alimentación y una lista de compras con la colaboración de la persona a la que estás cuidando.',
        },
        {
          text: 'Existen varias opciones para ahorrar tiempo al comprar alimentos, incluida la entrega o la recolección de artículos pedidos en línea.',
        },
        {
          text: 'Según las capacidades de la persona, puede ser divertido comprar juntos o compartir recetas.',
        },
        {
          text: 'La eliminación de alimentos vencidos es una tarea importante que debes realizar cada semana, así que trata de incorporarla a tu rutina.',
        },
      ],
    },
    {
      key: 'cleaning-household-chores-es',
      header: 'Limpieza y tareas del hogar',
      description:
        'Las condiciones de vida y los niveles de limpieza pueden variar mucho de una persona a otra. En el caso de los adultos mayores, es posible que te encuentres con situaciones de acumulación excesiva o condiciones de vida que no son sanitarias.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Intenta crear una lista de verificación y un cronograma para dividir las tareas en una carga de trabajo más manejable que se adapte a tu tiempo y nivel de energía.',
        },
        {
          text: 'Involucra a la persona a quien cuidas en la creación de un plan y esfuérzate por realizar las tareas de acuerdo con sus preferencias.',
        },
        {
          text: 'Reconoce que algunas tareas pueden no ser apropiadas o seguras para ti, como retirar la nieve con una pala, limpiar canaletas, lavar ventanas altas o que estén elevadas, y establece límites con confianza con la persona a la que estás cuidando según sea necesario.',
        },
        {
          text: 'Involucrar a otros proveedores de servicios para una “limpieza profunda” o para ayudar a ordenar podría ser de gran ayuda, o tal vez existan organizaciones locales de voluntarios que pueden ayudar.',
        },
        {
          text: 'Contactar a tu agencia local de asuntos relacionados con el envejecimiento y colaborar con los proveedores de atención médica que participan en el hogar puede ser un excelente punto de partida para obtener recursos y apoyo.',
        },
      ],
    },
    {
      key: 'laundry-es',
      header: 'Lavandería',
      description:
        'Recoger y doblar la ropa y cambiar las sábanas puede ser una de las tareas más desafiantes en cuanto al esfuerzo físico en el hogar.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Recuerda utilizar una buena mecánica corporal, especialmente si la lavadora y la secadora están en diferentes niveles de la casa.',
        },
        {
          text: 'Involucra a la persona a la que estás cuidando en el proceso de doblar la ropa, como toallas y calcetines, para fomentar la independencia y el sentido, pero ten cuidado de no poner a la persona en una posición que pueda afectarla si experimenta dolor crónico, en tareas como recoger la ropa sucia o cambiar las sábanas.',
        },
        {
          text: 'Asegúrate de tener siempre ropa limpia y fresca y de cambiar las sábanas con regularidad para ayudar a prevenir problemas en la piel; esto puede mejorar el estado de ánimo de la persona.',
        },
        {
          text: 'Crea una rutina de lavado y ten un suministro suficiente de ropa y ropa de cama en caso de que te retrases.',
        },
      ],
    },
    {
      key: 'transportation-es',
      header: 'Transporte',
      description:
        'Es posible que la persona a la que cuidas tenga citas médicas frecuentes u oportunidades de salidas durante la semana.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Mantente organizado/a y programa salidas juntas o el mismo día para reducir la sensación de agobio.',
        },
        {
          text: 'Ten un plan de transporte y familiarízate con el equipo de apoyo y las personas necesarias para ayudar a la persona a subir y bajar del vehículo.',
        },
        {
          text: 'Conoce los recursos de transporte disponibles, en especial si la única opción es transportar a la persona en silla de ruedas.',
        },
        {
          text: 'Fomenta las salidas, ya que pueden brindarte a ti y a la persona un respiro de sus rutinas habituales.',
        },
        {
          text: 'Designa un tiempo para ti durante las salidas; por ejemplo, busca un momento para tomar un café o reunirte con un grupo que juegue juegos de mesa con frecuencia, cuando las citas o el cuidado diurno de adultos sean por períodos prolongados.',
        },
      ],
    },
    {
      key: 'companionship-es',
      header: 'Compañerismo',
      description:
        'Esta actividad puede ser difícil de cuantificar ya que realmente nunca se "completa". El compañerismo puede ser uno de los apoyos más valiosos y más pasados ​​por alto que puedes brindar. Como seres humanos, estamos programados para comunicarnos y conectarnos con los demás. Intenta dedicar unos minutos durante cada visita para hacer un esfuerzo deliberado y establecer un vínculo. Es posible que descubras que establecer una buena relación y confianza con la persona a la que cuidas facilita las demás tareas.',
    },
  ],
};
