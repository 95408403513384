import React from 'react';
import PropTypes from 'prop-types';
import HeaderPyxir from 'Assets/img/header-pyxir.svg';
import { StyledLayout, StyledHeader, StyledContent } from './styles';

function PyxirHeaderFullScreenLayout({ children }) {
  return (
    <StyledLayout>
      <StyledHeader className="safe-area">
        <img alt="" aria-hidden="true" src={HeaderPyxir} />
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
    </StyledLayout>
  );
}

PyxirHeaderFullScreenLayout.propTypes = {
  children: PropTypes.node,
};

export default PyxirHeaderFullScreenLayout;
