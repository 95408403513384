import { increaseMovement } from 'Assets/img/my-tools-icons';

export const increaseMovementEn = {
  key: 'increase-movement',
  title: 'Increase Movement',
  titleImage: increaseMovement,
  titleImageAlt: '',
  description: 'Adding more physical movement into your life can enhance mental, emotional, and physical well-being!',
  contentId: 11,
  sections: [
    {
      key: 'create-activity-plan',
      header: 'Create an activity plan',
      description:
        'Think of ways to add enjoyable physical movement or activities into your life and create an action plan.',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Some helpful hints to consider for becoming more active: Write down...',
          subItems: [
            { text: 'Up to 5 reasons why you’d like to become more active now' },
            { text: 'Activities you think are fun, previously liked doing, or always wanted to try' },
            { text: 'Activities you can do with others' },
            { text: 'Activities you’d like to do over a short- or long-term' },
            { text: 'The most convenient times for you to be more active' },
            { text: 'At least 2 times you could do physical movement over the next week' },
          ],
        },
      ],
    },
    {
      key: 'schedule-activity-plan',
      header: 'Schedule your physical activity and do it!',
      description: 'Have fun mixing more physical activity into your week!',
      itemsHeader: 'Tell me more',
      items: [
        {
          text: 'Be mindful to eat well and drink plenty of water to support yourself.',
        },
        { text: 'Prepare ahead for outdoor activities and know the weather conditions to keep safe!' },
        { text: 'It’s okay to adjust activities to match your energy level.' },
      ],
    },
    {
      key: 'self-reflection',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your experience with this activity.',
      itemsHeader: 'Awareness Insights',
      items: [
        {
          text: 'What have you learned from this exercise?',
        },
        { text: 'How did you feel before completing this exercise?' },
        { text: 'How do you feel after it?' },
        { text: 'What part(s) of this exercise did you find most challenging? Why?' },
        { text: 'What did you enjoy most about this exercise?' },
        { text: 'What would help make the first step easy to begin?' },
        { text: 'What would be some benefits of doing this activity more often in general?' },
      ],
    },
    {
      key: 'self-reflection-pointers',
      itemsHeader: 'Pathway Pointers',
      items: [
        {
          text: 'Adding enjoyable physical activity into your life can help boost overall well-being, from free-form dance movement at home, to participating in a more structured, high-intensity fitness class.',
        },
        {
          text: 'Go at your own pace! Even low and moderate-intensity body movement on a regular basis can help boost positive mental, emotional, and physical health.',
        },
        { text: 'Enjoy yourself as you integrate more physical activity into your Thrive Pathway!' },
      ],
    },
  ],
};
