import React from 'react';
import { useIntl } from 'react-intl';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import PageHeader from 'Core_Components/PageHeader/PageHeader';
import ButtonRow from 'Core_Pages/GreetingCards/ButtonRow/ButtonRow';
import {
  StyledContainer,
  StyledParagraphStacked,
  StyledOutlineButton,
  StyledSolidButton,
} from 'Core_Pages/GreetingCards/featureStyles';
import { StyledEnvelopeImage } from './styles';
import greetingCard from 'Assets/img/greeting-card.svg';
import { useHistory } from 'react-router-dom';

function DesignYourCard() {
  const intl = useIntl();
  const history = useHistory();

  return (
    <StyledContainer>
      <PageHeader>{intl.formatMessage({ id: 'greeting-cards.design.header' })}</PageHeader>
      <StyledParagraphStacked>{intl.formatMessage({ id: 'greeting-cards.design.part-1' })}</StyledParagraphStacked>
      <StyledParagraphStacked>{intl.formatMessage({ id: 'greeting-cards.design.part-2' })}</StyledParagraphStacked>
      <StyledEnvelopeImage alt={intl.formatMessage({ id: 'image.greeting-cards.envelope' })} src={greetingCard} />
      <ButtonRow>
        <StyledOutlineButton onClick={() => history.push(paths.GREETINGCARDSIMAGESELECTION)}>
          {intl.formatMessage({ id: 'greeting-cards.design.image-library-button' })}
        </StyledOutlineButton>
        <StyledSolidButton onClick={() => history.push(paths.COLORINGBOOK)}>
          {intl.formatMessage({ id: 'greeting-cards.design.coloring-book-button' })}
        </StyledSolidButton>
      </ButtonRow>
    </StyledContainer>
  );
}

export default DesignYourCard;
