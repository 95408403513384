import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { StyledGrid } from '../styles';
import { Button } from '@mui/material';
import GradientCard from 'Core_Components/GradientCard/GradientCard';
import { appInsights } from 'Core_Helpers/AppInsights';
import urlcat from 'urlcat';
import { ToolTypes } from 'Core_Pages/MyTools/enumerations/enums';
import { MYTOOLS } from 'Core_Pages/Routes/RoutesConfig';
import { video } from 'Assets/img/my-tools-icons';
import { UserResourcesStore } from 'Core_Services/userResources/UserResourcesStore';
import { MyVideoToolsModuleStore } from 'Core_Pages/Video/MyVideoToolsModuleStore';

function MyVideosTile() {
  const history = useHistory();
  const intl = useIntl();
  const userResourcesStore = UserResourcesStore.Instance().Use();
  const hasMyVideoToolsResource = userResourcesStore.HasMyVideoToolsResource;
  const videoStore = MyVideoToolsModuleStore.Instance().Use();
  const lessonCount = videoStore.VideoCount;

  function onToolClick() {
    appInsights.trackEvent({ name: 'MyTools_Videos' });
    history.push(urlcat(MYTOOLS, { toolType: 'videos' }));
  }

  const show = lessonCount > 0 && hasMyVideoToolsResource;

  return (
    <>
      {show && (
        <StyledGrid mobile={12} tablet={6}>
          <Button onClick={onToolClick} fullWidth sx={{ padding: '0px' }}>
            <GradientCard
              titleId="home.tools.videos"
              backgroundColor="#FBC290"
              icon={video}
              to={urlcat(MYTOOLS, { toolType: 'videos' })}
              toolType={ToolTypes.VIDEOS}
              imageStyle={{ margin: '23px 15px' }}
              label={`${lessonCount} ${intl.formatMessage({ id: 'home.tools.label' })}`}
            />
          </Button>
        </StyledGrid>
      )}
    </>
  );
}

export default MyVideosTile;
