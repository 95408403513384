import styled from '@emotion/styled';
import { Card, Divider } from '@mui/material';

export const SelectableCard = styled(Card)`
  height: 95px;
  width: 100%;
  outline: 1px solid #979797;
`;

export const StyledDivider = styled(Divider)`
  height: 0.25px;
  width: 90%;
  margin: 0 auto;
`;

export const Icon = styled.div`
  width: 100%;
  display: flex;
  margin: 8px 0px;
  justify-content: center;

  svg {
    font-size: 3rem;
  }
`;
