import { animationType } from 'Core_Games/constants/animationType';
import { sceneType } from 'Core_Games/constants/sceneType';
import { SingleAction } from '../SingleAction';
import { treatConfig, keySceneDataTypes } from './treatConfig';
import { findAsset, findUI } from 'Core_Games/Scripts/configHelper';
import { uiTypes } from 'Core_Games/constants/uiTypes';
import { onClickGoToRoute, onClickActivityMenu } from 'Core_Games/Scripts/buttonHelper';
import { bodyPart } from 'Core_Games/constants/bodyPart';
import { STATICCONTENT } from 'Core_Pages/Routes/RoutesConfig';
import { LogInteraction, interactionExceedsLimit } from '../../../Scripts/interactionHelper';

const states = {
  walkingToBox: 'WALKINGTOBOX',
  atBox: 'ATBOX',
  steppingIntoBox: 'STEPINGINTOBOX',
  walkToBall: 'WALKTOBALL',
  walkingToBall: 'WALKINGTOBALL',
  atBall: 'ATBAll',
  nudgingBall: 'NUDGINGBALL',
  ballNudged: 'BALLNUDGED',
  walkBackToBox: 'WALKBACKTOBOX',
  walkingBackToBox: 'WALKINGBACKTOBOX',
  atBoxInside: 'ATBOXINSIDE',
  turn: 'TURN',
  turning: 'TURNING',
  steppingOutOfBox: 'STEPPINGOUTOFBOX',
  walkToStart: 'WALKTOSTART',
  walkingToStart: 'WALKINGTOSTART',
  atStart: 'ATSTART',
  stop: 'STOP',
  stepOutOfBox: 'STEPOUTOFBOX',
  walkToTreat: 'WALKTOTREAT',
  walkingToTreat: 'WALKTINGOTREAT',
  eatTreat: 'EATTREAT',
  eatingTreat: 'EATINGTREAT',
};

export class Treat extends SingleAction {
  constructor() {
    super(sceneType.TREAT.NAME);
    this.treatBoxScale = 0.6;
    this.treatScale = 0.2;

    this.timerTrapBall = undefined;
    this.timerTrapBallActive = false;

    this.timerNudgeBall = undefined;
    this.timerNudgeBallActive = false;

    this.firstToss = true;

    this.treatBallScaleThreshold = 0.15;
    this.ballWorldSize = 20;

    this.objectMovingThreshX = 500;
    this.objectMovingThreshY = 500;

    this.ballScale = 0.215;

    this.petScaleTransformRate = 1.0;
    this.finishedLoading = false;

    this.currentState = null;
    this.nudgeCount = 0;
    this.isPetInBox = false;

    this.initialPetDepth = 3;
    this.initialTreatBoxDepth = 2;
    this.initialTreatDepth = 4;
    this.initialBallDepth = 3;
  }

  init(sceneData) {
    this.sceneData = sceneData;
    this.treatConfig = treatConfig;

    const launchButton = findUI(this, this.treatConfig, uiTypes.BUTTONROW, keySceneDataTypes.TOSS_BUTTON);
    launchButton.ONCLICK = () => this.onClickToss(this, this.pet, this.projectile);

    const tossAgainButton = findUI(this, this.treatConfig, uiTypes.BUTTONROW, keySceneDataTypes.TOSS_AGAIN_BUTTON);
    tossAgainButton.ONCLICK = () => this.onClickToss(this, this.pet, this.projectile);

    const maybeLaterButton = findUI(this, this.treatConfig, uiTypes.BUTTONROW, keySceneDataTypes.MAYBE_LATER_BUTTON);
    maybeLaterButton.ONCLICK = () => onClickActivityMenu(this.sceneData.appData.history);

    const letsGoButton = findUI(this, this.treatConfig, uiTypes.BUTTONROW, keySceneDataTypes.LETS_GO_BUTTON);
    letsGoButton.ONCLICK = () =>
      onClickGoToRoute(sceneData.appData.history, STATICCONTENT, { contentType: 'celebrateprogress' });

    const backButton = findUI(this, this.treatConfig, uiTypes.BUTTONROW, keySceneDataTypes.BACK_BUTTON);
    backButton.ONCLICK = () => onClickActivityMenu(this.sceneData.appData.history);

    super.init(sceneType.TREAT, this.treatConfig, sceneData);

    this.petScale = this.currentSceneConfig.PETSCALE;
    this.eatingPosition = this.currentSceneConfig.EATINGPOSITION;
    this.nudgeOffsetY = this.currentSceneConfig.NUDGEOFFSETY;
    this.nudgeOffsetX = this.currentSceneConfig.NUDGEOFFSETX;
    this.petOffsetX = this.currentSceneConfig.PETOFFSETX;
    this.petOffsetY = this.currentSceneConfig.PETOFFSETY;
    this.walkSpeed = this.currentSceneConfig.WALKSPEED;
    this.stepIntoOffsetX = this.currentSceneConfig.STEPINTOOFFSETX;
    this.stepIntoOffsetY = this.currentSceneConfig.STEPINTOOFFSETY;
    this.jumpIntoOffsetX = this.currentSceneConfig.JUMPINTOOFFSETX;
    this.petWalkOffsetX = this.currentSceneConfig.PETWALKOFFSETX;
    this.shouldTurn = this.currentSceneConfig.SHOULDTURN;
    this.shouldJump = this.currentSceneConfig.SHOULDJUMP;
    this.shouldEnterFromTop = this.currentSceneConfig.SHOULDENTERFROMTOP;
    this.animations = {
      POSE_END: this.currentSceneConfig.ANIMATIONS?.POSE_END ?? animationType.POSE_PROUD,
    };

    this.treatBoxLocationX = this.currentSceneConfig.TREATBOXLOCATIONX ?? 0.5;
    this.treatBoxLocationY = this.currentSceneConfig.TREATBOXLOCATIONY ?? 0.65;
  }

  preload() {
    super.preload();
  }

  update() {
    super.update();

    if (this.engine?.enginePhysics?.fake3dworld && this.finishedLoading) {
      this.updatePetDirection();
      this.updateInteraction();
    }
  }

  create() {
    let _this = this;
    super.create();

    this.engine.engineAssets.load.on(Phaser.Loader.Events.COMPLETE, function () {
      _this.startLocationTreatBox = _this.getPagePoint(
        _this.singleActionBackgroundIndex,
        _this.treatBoxLocationX,
        _this.treatBoxLocationY,
      );
      _this.createProjectile(_this);
      _this.createTreatBox(_this);
      _this.createTreat(_this);
      _this.createPet(_this);
      _this.createInitialUI(_this);
      _this.createTimers(_this);
      _this.createPhysicsWorld(_this);
      _this.finishedLoading = true;
    });
  }

  updateInteraction = () => {
    if (this.firstToss && this.timerTrapBallActive && this.timerTrapBall.getProgress() >= 1) {
      // First throw is in here
      this.projectile.alpha = 0;
      this.projectileDummy.x = this.projectile.x;
      this.projectileDummy.y = this.projectile.y;
      this.projectileDummy.alpha = 1;
      this.firstToss = false;
      this.timerTrapBallActive = false;
    } else if (this.secondToss && this.timerNudgeBallActive && this.timerNudgeBall.getProgress() >= 1) {
      this.projectile.alpha = 0;
      this.projectileDummy.x = this.projectile.x;
      this.projectileDummy.y = this.projectile.y;
      this.projectileDummy.alpha = 1;
      this.secondToss = false;
      this.secondTossDone = true;
      this.timerNudgeBallActive = false;
    } else if (!this.firstToss && !this.secondToss) {
      this.walkToBox();
      this.interactWithBox();
      this.walkToBall();
      this.nudgeBall();
      this.turn();
      this.walkToStart();
      this.walkToTreat();
      this.eatTreat();
    }
  };

  walkToBox = () => {
    if (!this.currentState && this.currentState !== states.walkingToBox) {
      const _this = this;
      this.currentState = states.walkingToBox;
      if (this.shouldEnterFromTop) {
        this.pet.setDepth(this.pet.depth - 1);
        if (this.pet.flipX) {
          this.pet.flipX = !this.pet.flipX;
        }
        this.pet.play(animationType.WALK_BACK);
        this.tweens.add({
          targets: this.pet,
          x: this.pet.x,
          y: this.treatBoxInner.y - this.treatBoxInner.displayHeight,
          duration: 1500,
          onComplete: function () {
            _this.pet.play(animationType.WALK);
            _this.tweens.add({
              targets: _this.pet,
              x: _this.projectileDummy.x - _this.pet.displayWidth / 2,
              y: _this.pet.y,
              duration: 1700,
              onComplete: function () {
                _this.currentState = states.atBox;
              },
            });
          },
        });
      } else {
        if (this.pet.flipX) {
          this.pet.flipX = !this.pet.flipX;
        }
        this.pet.play(animationType.WALK);
        this.tweens.add({
          targets: this.pet,
          x: this.treatBoxInner.x - this.pet.displayWidth * this.petWalkOffsetX,
          y: this.projectileDummy.y - this.pet.displayHeight * this.nudgeOffsetY,
          duration: 500,
          onComplete: function () {
            _this.currentState = states.atBox;
          },
        });
      }
    }
    if (this.currentState === states.walkBackToBox) {
      const _this = this;
      if (this.shouldTurn && this.isPetInBox) {
        this.currentState = states.turn;
        this.pet.once(
          Phaser.Animations.Events.ANIMATION_COMPLETE_KEY + animationType.BACKTURN_FACEAWAY,
          function () {
            _this.currentState = states.stepOutOfBox;
          },
          this.pet,
        );
      } else {
        this.currentState = states.walkingBackToBox;
        this.pet.playReverse(animationType.WALK);
        this.tweens.add({
          targets: this.pet,
          x: this.treatBoxInner.x * this.petWalkOffsetX - this.treatBoxInner.displayWidth,
          y: this.pet.y,
          duration: 1500,
          onComplete: function () {
            _this.currentState = states.stepOutOfBox;
          },
        });
      }
    }
  };

  turn = () => {
    if (this.currentState === states.turn) {
      this.currentState = states.turning;
      this.pet.play(animationType.BACKTURN_FACEAWAY);
    }
  };

  interactWithBox = () => {
    const _this = this;
    if (this.currentState === states.atBox) {
      this.currentState = states.steppingIntoBox;
      if (this.shouldJump) {
        this.isPetInBox = true;
        this.jumpOverBox(1, states.walkToBall);
      } else {
        this.pet.play(animationType.STEPINTO);
        this.tweens.add({
          targets: this.pet,
          x: this.pet.x + Math.abs(this.pet.x * (1 - this.stepIntoOffsetX)),
          y: this.pet.y,
          duration: 500,
        });
        this.pet.once(
          Phaser.Animations.Events.ANIMATION_COMPLETE_KEY + animationType.STEPINTO,
          function () {
            _this.currentState = states.walkToBall;
          },
          this.pet,
        );
      }
    } else if (this.currentState === states.stepOutOfBox) {
      this.currentState = states.steppingOutOfBox;
      if (this.shouldJump && this.isPetInBox) {
        const _this = this;
        this.pet.play(animationType.WALK_BACK);
        this.tweens.add({
          targets: this.pet,
          x: this.treatBoxInner.x - this.treatBoxInner.displayWidth / 3,
          y: this.treatBoxInner.y - this.treatBoxInner.displayHeight * this.nudgeOffsetY,
          duration:
            10 *
            _this.getDistance(
              _this.pet.x,
              _this.treatBoxInner.x - _this.treatBoxInner.displayWidth / 3,
              _this.pet.y,
              _this.treatBoxInner.y - _this.treatBoxInner.displayHeight * _this.nudgeOffsetY,
            ),
          onComplete: function () {
            _this.isPetInBox = false;
            _this.jumpOverBox(-1, states.walkToStart);
          },
        });
      } else {
        this.pet.playReverse(animationType.STEPINTO);
        this.pet.x = this.pet.x - this.pet.displayWidth * 0.1;
        this.pet.once(
          Phaser.Animations.Events.ANIMATION_COMPLETE_KEY + animationType.STEPINTO,
          function () {
            _this.currentState = states.walkToStart;
          },
          this.pet,
        );
      }
    }
  };

  jumpOverBox = (direction = 1, endState) => {
    const _this = this;
    const jumpAnimation =
      direction > 0
        ? { jump: animationType.JUMP_FORWARD, land: animationType.LAND_FORWARD }
        : { jump: animationType.JUMP_BACK, land: animationType.LAND_BACK };
    this.pet.play(jumpAnimation.jump);
    this.tweens.add({
      targets: this.pet,
      y: _this.pet.y + direction * this.pet.displayHeight * 0.1,
      duration: 500,
    });

    this.pet.once(
      Phaser.Animations.Events.ANIMATION_COMPLETE_KEY + jumpAnimation.jump,
      function () {
        _this.pet.setDepth(_this.treatBoxInner.depth + direction);
        _this.pet.play(jumpAnimation.land);
        _this.tweens.add({
          targets: _this.pet,
          y: _this.pet.y + direction * _this.pet.displayHeight * 0.1,
          duration: 500,
        });
      },
      this.pet,
    );

    this.pet.once(
      Phaser.Animations.Events.ANIMATION_COMPLETE_KEY + jumpAnimation.land,
      function () {
        _this.currentState = endState;
      },
      this.pet,
    );
  };

  walkToBall = () => {
    if (this.currentState === states.walkToBall) {
      const _this = this;
      this.currentState = states.walkingToBall;
      if (this.shouldEnterFromTop) {
        this.pet.play(animationType.WALK_FRONT);
        this.tweens.add({
          targets: this.pet,
          x: _this.projectileDummy.x - _this.pet.displayWidth / 2,
          y: _this.projectileDummy.y - _this.pet.displayHeight * _this.nudgeOffsetY,
          duration:
            10 *
            _this.getDistance(
              _this.pet.x,
              _this.projectileDummy.x - _this.pet.displayWidth / 2,
              _this.pet.y,
              _this.projectileDummy.y - _this.pet.displayHeight * _this.nudgeOffsetY,
            ),
          onComplete: function () {
            _this.currentState = states.atBall;
          },
        });
      } else {
        this.pet.play(animationType.WALK);
        this.tweens.add({
          targets: this.pet,
          x: this.projectileDummy.x - this.pet.displayWidth * this.nudgeOffsetX,
          y: this.projectileDummy.y - this.pet.displayHeight * this.nudgeOffsetY,
          duration: 1500,
          onComplete: function () {
            _this.currentState = states.atBall;
          },
        });
      }
    }
  };

  walkToTreat = () => {
    if (this.currentState === states.walkToTreat) {
      this.currentState = states.walkingToTreat;
      const _this = this;
      this.pet.play(animationType.WALK_FRONT);
      this.tweens.add({
        targets: this.pet,
        y: this.projectileDummy.y + this.projectileDummy.displayHeight - this.pet.displayHeight * 0.85,
        duration: 2000,
        onComplete: function () {
          _this.pet.setDepth(_this.treatBoxOuter.depth + 1);
          if (_this.pet.flipX) {
            _this.pet.flipX = !_this.pet.flipX;
          }
          _this.pet.play(animationType.WALK);
          _this.tweens.add({
            targets: _this.pet,
            x: _this.treat.x - _this.pet.displayWidth * _this.eatingPosition,
            duration: 2000,
            onComplete: function () {
              _this.currentState = states.eatTreat;
            },
          });
        },
      });
    }
  };

  eatTreat = () => {
    if (this.currentState === states.eatTreat) {
      const _this = this;
      this.currentState = states.eatingTreat;
      this.pet.body.stop();
      if (this.shouldEnterFromTop) {
        this.pet.flipX = !this.pet.flipX;
      }
      this.pet.play(animationType.EATTREAT);
      this.pet.once(Phaser.Animations.Events.ANIMATION_COMPLETE_KEY + animationType.EATTREAT, () => {
        _this.treat.alpha = 0;
        _this.pet.playReverse(animationType.EATTREAT);

        _this.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_OUTRO);
        _this.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.OUTRO_CAPTION);

        _this.pet.once(Phaser.Animations.Events.ANIMATION_COMPLETE_KEY + animationType.EATTREAT, () => {
          _this.pet.play(this.animations.POSE_END);
        });
      });
    }
  };

  getDistance = (x1, x2, y1, y2) => {
    return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
  };

  nudgeBall = () => {
    if (this.currentState === states.atBall) {
      const _this = this;
      this.currentState = states.nudgingBall;
      this.pet.play(animationType.NUDGE);
      this.pet.once(
        Phaser.Animations.Events.ANIMATION_COMPLETE_KEY + animationType.NUDGE,
        function () {
          if (_this.currentState === states.nudgingBall) {
            _this.currentState = states.ballNudged;
            _this.pet.playReverse(animationType.NUDGE);
            _this.tweens.add({
              targets: _this.projectileDummy,
              x: !_this.secondTossDone
                ? _this.treatBoxOuter.x + _this.treatBoxOuter.displayWidth * 0.25
                : _this.startLocationTreatBox.x,
              y: !_this.secondTossDone
                ? _this.treatBoxOuter.y
                : _this.treatBoxOuter.y + _this.projectileDummy.displayHeight / 2,
              duration: 2000,
              ease: !_this.secondTossDone ? 'Linear.easeOut' : 'Linear',
              onComplete: function () {
                if (_this.nudgeCount > 0) {
                  _this.nudgeCount = 0;
                  _this.currentState = states.walkBackToBox;
                } else if (!_this.secondTossDone) {
                  _this.nudgeCount++;
                  _this.currentState = states.walkToBall;
                } else {
                  // nudge to done
                  _this.projectileDummy.setDepth(_this.projectileDummy.depth + 1);
                  _this.currentState = states.walkBackToBox;
                  _this.moveBallToOuterBox();
                }
              },
            });
          }
        },
        this.pet,
      );
    }
  };

  moveBallToOuterBox = () => {
    const _this = this;
    this.tweens.add({
      targets: this.projectileDummy,
      y: this.projectileDummy.y + this.projectileDummy.displayHeight / 2,
      duration: 1000,
      ease: 'Linear.easeOut',
      onComplete: () => {
        _this.treat.alpha = 1;
        _this.treatShown = true;
      },
    });
  };

  walkToStart = () => {
    if (this.currentState === states.walkToStart) {
      this.currentState = states.walkingToStart;
      const _this = this;

      this.pet.play(animationType.WALK);
      if (this.shouldEnterFromTop) {
        if (!this.pet.flipX) {
          this.pet.flipX = !this.pet.flipX;
        }
        this.tweens.add({
          targets: this.pet,
          x: this.originalPetLocation.x,
          duration: 10 * this.getDistance(this.pet.x, this.originalPetLocation.x, this.pet.y, this.pet.y),
          onComplete: function () {
            _this.pet.play(animationType.WALK_FRONT);
            _this.tweens.add({
              targets: _this.pet,
              y: _this.originalPetLocation.y,
              duration: 10 * _this.getDistance(_this.pet.x, _this.pet.x, _this.pet.y, _this.originalPetLocation.y),
              onComplete: function () {
                _this.currentState = states.atStart;
                _this.pet.play(animationType.PENDING_TREAT_THROW);
                if (!_this.firstTossDone) {
                  _this.firstTossDone = true;
                  _this.secondToss = true;
                  // Sets the UI to throw again
                  _this.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_AGAIN);
                  _this.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.STUCK_CAPTION);
                } else if (_this.secondTossDone) {
                  _this.currentState = states.walkToTreat;
                }
              },
            });
          },
        });
      } else {
        this.tweens.add({
          targets: this.pet,
          x: this.originalPetLocation.x,
          y: this.originalPetLocation.y,
          duration:
            10 * this.getDistance(this.pet.x, this.originalPetLocation.x, this.pet.y, this.originalPetLocation.y),
          onComplete: function () {
            _this.currentState = states.atStart;
            _this.pet.play(animationType.PENDING_TREAT_THROW);
            if (!_this.firstTossDone) {
              _this.firstTossDone = true;
              _this.secondToss = true;
              // Sets the UI to throw again
              _this.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_AGAIN);
              _this.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.STUCK_CAPTION);
            } else if (_this.secondTossDone) {
              _this.currentState = states.walkToTreat;
            }
          },
        });
      }
    }
  };

  onClickToss = (game, actor, object) => {
    this.projectile.alpha = 1;
    game.engine.enginePhysics.fake3dworld?.resetObjects();
    const { x, y } = this.treatBoxInner;

    if (this.firstToss) {
      LogInteraction(game, game.sceneData?.sceneType);
      this.time.addEvent(this.timerTrapBall);
      this.timerTrapBallActive = true;
      game.engine.engineUi.captionManager.toggleCaption(true);
      game.engine.engineUi.buttonManager.update(keySceneDataTypes.TOSS_BUTTON, true);
    }

    if (this.firstTossDone) {
      game.projectileDummy.alpha = 0;
      this.time.addEvent(this.timerNudgeBall);
      this.timerNudgeBallActive = true;
      game.engine.engineUi.captionManager.toggleCaption(true);
      game.engine.engineUi.buttonManager.update(keySceneDataTypes.TOSS_AGAIN_BUTTON, true);
      this.currentState = null;
    }

    game.engine.enginePhysics.ball3D = game.engine.enginePhysics.fake3dworld.addObject({
      velocity: game.firstToss ? Phaser.Math.FloatBetween(220, 230) : Phaser.Math.FloatBetween(240, 260),
      bounce: 0.4,
      minScale: game.ballScale,
      zoomFactor: 0,
      scale: game.ballScale,
      size: 1,
      startX: game.firstToss ? 0 : x - game.treatBoxInner.displayWidth * 0.5,
      startZ: 1,
      startY: game.firstToss ? y : y + game.treatBoxInner.displayHeight * 0.5,
      velocityPower: Phaser.Math.FloatBetween(0.001, 0.001),
      bounceFactor: 2,
      startFromRight: false,
      disableGravity: true,
      hasDamping: true,
      drag: 0.3,
      object: object,
    });
  };

  createProjectile = (game) => {
    game.projectile = game.add.sprite(
      game.startLocationTreatBox.x,
      game.startLocationTreatBox.y,
      this.gameData.gameType.ATLAS,
      findAsset(game, game.treatConfig, keySceneDataTypes.PROJECTILE).DATA,
    );
    game.projectile.name = findAsset(game, game.treatConfig, keySceneDataTypes.PROJECTILE).DATA;
    game.projectile.setScale(game.ballScale);
    game.projectile.alpha = 0;
    game.projectile.setDepth(game.initialBallDepth);

    game.projectileDummy = game.add.sprite(350, 350, this.gameData.gameType.ATLAS, game.projectile.name);
    game.projectileDummy.setScale(game.ballScale);
    game.projectileDummy.setDepth(game.initialBallDepth);
    game.projectileDummy.alpha = 0;
  };

  createTreatBox = (game) => {
    game.treatBoxInner = game.add.sprite(
      game.startLocationTreatBox.x,
      game.startLocationTreatBox.y,
      this.gameData.gameType.ATLAS,
      findAsset(game, game.treatConfig, keySceneDataTypes.TREATBOXINNER).DATA,
    );
    game.treatBoxInner.setScale(game.treatBoxScale);
    game.treatBoxInner.setDepth(game.initialTreatBoxDepth);

    game.treatBoxMiddle = game.add.sprite(
      game.startLocationTreatBox.x,
      game.startLocationTreatBox.y,
      this.gameData.gameType.ATLAS,
      findAsset(game, game.treatConfig, keySceneDataTypes.TREATBOXMIDDLE).DATA,
    );
    game.treatBoxMiddle.setDepth(game.initialTreatBoxDepth + 1);
    game.treatBoxMiddle.setScale(game.treatBoxScale);

    game.treatBoxOuter = game.add.sprite(
      game.startLocationTreatBox.x,
      game.startLocationTreatBox.y,
      this.gameData.gameType.ATLAS,
      findAsset(game, game.treatConfig, keySceneDataTypes.TREATBOXOUTER).DATA,
    );
    game.treatBoxOuter.setScale(game.treatBoxScale);
    game.treatBoxOuter.setDepth(game.initialTreatBoxDepth + 2);
  };

  createTreat = (game) => {
    game.treat = game.add.sprite(
      game.startLocationTreatBox.x + game.treatBoxOuter.displayWidth / 5,
      game.treatBoxOuter.y * 1.19,
      this.gameData.gameType.ATLAS,
      findAsset(game, game.treatConfig, keySceneDataTypes.TREAT).DATA,
    );
    game.treat.setScale(game.treatScale);
    game.treat.setDepth(game.initialTreatDepth);
    game.treat.alpha = 0;
  };

  createPet = (game) => {
    game.pet.play(animationType.PENDING_TREAT_THROW);
    game.pet?.body?.setEnable(true);
    game.pet?.setScale(game.petScale);
    game.pet.setDepth(game.shouldEnterFromTop ? game.initialPetDepth - 1 : game.initialPetDepth);
    game.pet.body.x = game.treatBoxOuter.x - game.pet.displayWidth * game.petOffsetX;
    game.pet.body.y = game.treatBoxOuter.y - game.pet.displayHeight * game.petOffsetY;
    game.pet.x = game.pet.body.x;
    game.pet.y = game.pet.body.y;
    game.pet.flipX = !game.pet.flipX;
    game.pet.body.collideWorldBounds = false;
    game.originalPetLocation = { x: game.pet.body.x, y: game.pet.body.y };
    if (game.pet.Inventory[bodyPart.NECK]?.length) {
      game.pet.Inventory[bodyPart.NECK][0].OBJECT.alpha = 0;
      game.pet.unequip(bodyPart.NECK);
    }

    game.pet.on(
      Phaser.Animations.Events.ANIMATION_COMPLETE,
      function (anim, frame) {
        this.emit(Phaser.Animations.Events.ANIMATION_COMPLETE_KEY + game.pet.rawAnimName(), anim, frame);
      },
      game.pet,
    );
  };

  createInitialUI = (game) => {
    if (interactionExceedsLimit(game)) {
      game.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_BACK);
      game.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.BACK_CAPTION);
    } else {
      game.engine.engineUi.buttonManager.displayScreen(keySceneDataTypes.SCREEN_INTRO);
      game.engine.engineUi.captionManager.displayCaption(keySceneDataTypes.INTRO_CAPTION);
    }
  };

  createTimers = (game) => {
    game.timerTrapBall = new Phaser.Time.TimerEvent({ delay: 4000 });
    game.timerNudgeBall = new Phaser.Time.TimerEvent({ delay: 5000 });
  };

  createPhysicsWorld = (game) => {
    game.engine.enginePhysics.fake3dworld.init({
      positionBoxX: game.treatBoxInner.x - game.treatBoxInner.displayWidth * 0.35,
      positionBoxY: game.treatBoxInner.y - game.treatBoxInner.displayHeight * 0.55 + game.projectile.displayHeight,
      worldSizeX: game.treatBoxInner.displayWidth / 2,
      worldSizeY: game.treatBoxInner.displayHeight / 2 - game.projectile.displayHeight,
      worldSizeZ: 0.1,
    });
  };

  updatePetDirection = () => {
    if (this.pet.body.velocity.x > 0 && this.pet.flipX) {
      this.pet.flipX = !this.pet.flipX;
    } else if (this.pet.body.velocity.x < 0 && !this.pet.flipX) {
      this.pet.flipX = !this.pet.flipX;
    }
  };
}
