import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ScreenReaderWrapper } from 'Styles/ScreenReaderOnly';
import { FormattedMessage, useIntl } from 'react-intl';

const ChatScreenReader = ({ position, alt, text, type, status, buttons, value }) => {
  const intl = useIntl();

  const createInputs = () => {
    if (value === 'DOB') {
      return (
        <>
          <FormattedMessage id="dob.birth-date-legend" />
          <FormattedMessage id="common.month" />
          <FormattedMessage id="common.day" />
          <FormattedMessage id="common.year" />
        </>
      );
    }
    if (value === 'HealthPlan') {
      return (
        <>
          <FormattedMessage id="common.health-plan-id" />
        </>
      );
    }
  };

  const createWheel = () => {
    return (
      <>
        <FormattedMessage id="wheel.instructions" />
      </>
    );
  };

  const createImage = () => {
    return <>{alt && <>{`${alt}, ${intl.formatMessage({ id: 'common.image' })}`}</>}</>;
  };

  const createButtonRow = () => {
    return (
      <>
        {`${intl.formatMessage({ id: 'chat.chat-responses' })},`}
        {buttons?.map((button, index) => (
          <Fragment key={`ButtonFragment_${index}`}>{button.text}</Fragment>
        ))}
      </>
    );
  };

  const createBubble = () => {
    const typingText = status?.typingText ? `${status?.typingText[position]}, ` : '';
    return <>{`${typingText}${text}`}</>;
  };

  return (
    <ScreenReaderWrapper>
      {
        {
          bubble: <>{createBubble()}</>,
          buttonRow: <>{createButtonRow()}</>,
          image: <>{createImage()}</>,
          input: <>{createInputs()}</>,
          wheel: <>{createWheel()}</>,
        }[type]
      }
    </ScreenReaderWrapper>
  );
};

ChatScreenReader.propTypes = {
  position: PropTypes.string,
  alt: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.object,
  buttons: PropTypes.array,
  value: PropTypes.string,
};

export default ChatScreenReader;
