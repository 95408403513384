import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Core_Helpers/getAuthClient';
import useAxiosSubmit from 'Core_Hooks/api/useAxiosSubmit/useAxiosSubmit';
import urlcat from 'urlcat';

const encounterUrl = '/v5/chat/encounter';
const sendUrl = '/v5/chat/send';
const chatDelay = process.env.CHAT_DELAY || 2000;
const RETRY_DELAY = 3000;
const RETRY_COUNT = 3;
const options = {
  revalidateOnFocus: false,
};

const getEncounter = async (url) => {
  const authorizedApiClient = await getAuthClient();
  if (authorizedApiClient) {
    return await authorizedApiClient.get(url).then((result) => {
      return new Promise((resolve) =>
        setTimeout(() => {
          resolve(result.data);
        }, chatDelay - result.responseTime),
      );
    });
  }
};

const useEncounter = (shouldInitialize, swrOptions) => {
  let defaultEncounterValue;
  const [isPending, setIsPending] = useState(true);
  const [encounter, setEncounter] = useState();
  const [nextMessage, setNextMessage] = useState();
  const [request, setRequest] = useState();

  const { response, isLoading } = useAxiosSubmit(urlcat(process.env.API_BASE_URL, sendUrl), request, chatDelay);
  const {
    data: encounterResult,
    error,
    mutate,
    isValidating,
  } = useSWR(
    () => {
      if (!shouldInitialize) {
        return false;
      }

      return urlcat(process.env.API_BASE_URL, encounterUrl);
    },
    getEncounter,
    {
      ...options,
      ...swrOptions,
      onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
        if (retryCount > RETRY_COUNT || error.status === 404) return;
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), RETRY_DELAY);
      },
    },
  );

  useEffect(() => {
    setIsPending((isValidating && !encounter) || isLoading);
  }, [isValidating, isLoading]);

  useEffect(() => {
    setEncounter(encounterResult);
  }, [encounterResult]);

  useEffect(() => {
    if (response) {
      setNextMessage(response);
    }
  }, [response]);

  const respond = (userResponse) => {
    setRequest(userResponse);
  };

  const resetEncounter = (shouldRefresh = true) => {
    setEncounter();
    setNextMessage();
    return mutate(shouldRefresh ? defaultEncounterValue : null, shouldRefresh);
  };

  return { encounter, error, isPending, nextMessage, respond, resetEncounter };
};

export default useEncounter;
