import React from 'react';
import PropTypes from 'prop-types';
import { StyledParagraph, StyledOutlineButton, StyledSolidButton } from 'Core_Pages/GreetingCards/featureStyles';
import {
  StyledContainerWithOverflow,
  StyledParagraphStackedWithMargin,
  StyledImagePreview,
} from 'Core_Pages/GreetingCards/ColoringBookImagePreview/styles';
import ButtonRow from 'Core_Pages/GreetingCards/ButtonRow/ButtonRow';
import { useHistory, useLocation } from 'react-router-dom';
import * as paths from 'Core_Pages/Routes/RoutesConfig';
import { useIntl } from 'react-intl';

function ColoringBookImagePreview({ image }) {
  const intl = useIntl();
  const history = useHistory();
  const { state } = useLocation();

  function handleNewImage() {
    history.push(paths.COLORINGBOOK);
  }

  async function handleAddToCard() {
    const card = {
      coloringBookImageColors: state.colors,
      coloringBookImageKey: state.imageKey,
    };
    history.push({ pathname: paths.GREETINGCARDSMESSAGESELECTION, state: { card, from: paths.COLORINGBOOK } });
  }

  return (
    <StyledContainerWithOverflow data-testid={'ColoringBookImagePreview'}>
      <StyledParagraphStackedWithMargin>
        {intl.formatMessage({ id: 'greeting-cards.coloring-book-image-preview.nice-work' })}
      </StyledParagraphStackedWithMargin>
      <StyledParagraph>{intl.formatMessage({ id: 'greeting-cards.coloring-book-image-preview.add' })}</StyledParagraph>
      <StyledImagePreview>{image}</StyledImagePreview>
      <ButtonRow>
        <StyledOutlineButton onClick={handleNewImage}>
          {intl.formatMessage({ id: 'coloring-book.new-image' })}
        </StyledOutlineButton>
        <StyledSolidButton onClick={handleAddToCard}>{intl.formatMessage({ id: 'common.add' })}</StyledSolidButton>
      </ButtonRow>
    </StyledContainerWithOverflow>
  );
}

ColoringBookImagePreview.displayName = 'ColoringBookImagePreview';

ColoringBookImagePreview.propTypes = {
  image: PropTypes.object,
  setCard: PropTypes.func,
};

export default ColoringBookImagePreview;
