import styled from '@emotion/styled';
import { Dialog, LinearProgress } from '@mui/material';
import { Bookmark, LikertForm } from '../../styles';
import { breakpoints } from 'Styles/theme';
import { teal } from 'Styles/colors';
import { leaf } from 'Assets/img/screening-icons';

export const StyledLikertForm = styled(LikertForm)(
  ({ theme }) => `
  width: 100%;
  flex-flow: wrap;
  justify-content: center;
  column-gap: 30px;

  ${theme.breakpoints.up(breakpoints.DESKTOP)} {
    max-width: 380px;
  }

  .FormQuestion-answer-card {
    width: 147px;
    height: 115px;
  }
`,
);

export const NeedsWrapper = styled(Dialog)(
  ({ theme }) => `

  h1 {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin: 32px auto;
  }
  
  ${theme.breakpoints.up(breakpoints.TABLET)} {
    h1 {
      font-size: 24px;
    }
  }

  .MuiDialog-scrollPaper {
    background-image: linear-gradient(rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.68)), url(${leaf}), linear-gradient(black, black);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
  }

  .MuiDialog-paper {
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
    background-color: transparent;
    color: white;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  ${theme.breakpoints.up(breakpoints.TABLET)} {
    .MuiDialog-paper {
      grid-template-rows: 1fr 5fr 1fr;
    }
  }
  `,
);

export const NeedsProgressBar = styled(LinearProgress)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 9px;
  margin-top: env(safe-area-inset-top);

  .MuiLinearProgress-bar {
    background-color: ${teal._400};
  }
`;

export const StyledBookmark = styled(Bookmark)`
  top: calc(9px + env(safe-area-inset-top));
`;

export const SkipButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  max-height: 50px;
`;
