import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './api/useAxiosSubmit/useAxiosSubmit';

const useCreateImageCard = () => {
  const [cardImageRequest, setCardImageRequest] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored } = useAxiosSubmit(url, cardImageRequest);

  const createCardImage = async (request) => {
    setUrl(`${process.env.API_BASE_URL}/v1/cards/Images/ColoringBook`);
    setCardImageRequest(request);
  };

  return { cardImageInformation: response, cardImageError: hasErrored, cardImageIsPending: isLoading, createCardImage };
};

export default useCreateImageCard;
