import { Actor } from 'Core_Games/Actor/Actor';
import { puppyAssetConfig } from './puppyConfig';

export class Puppy extends Actor {
  constructor(scene, x, y, color, petType, frame) {
    let currentConfig = puppyAssetConfig;
    super(scene, x, y, currentConfig, currentConfig.NAME, frame);

    this.spriteName = this.ObjectData.NAME;
    this.Inventory = this.ObjectData.INVENTORY;
    this.Sounds = this.ObjectData.AUDIO;

    this.currentSpriteSheet = color;
    this.originalScale = this.ObjectData.SCALE;
  }
}
