import { cognitionImpairments } from 'Assets/img/my-tools-icons';

export const understandingHowToCareEn = {
  key: 'understanding-how-to-care',
  title: 'Care for Someone with Cognitive Needs',
  titleImage: cognitionImpairments,
  titleImageAlt: '',
  contentId: 41,
  sections: [
    {
      key: 'understanding-how-to-care-intro',
      description:
        'If you are reading this article, you likely have someone in your life who has cognition impairment or has a diagnosis of dementia or Alzheimer’s. This is a challenging diagnosis and as the disease progresses, it is one that typically impacts you more than the person with cognition impairments. Here are a few things to help you as you navigate this journey.',
    },
    {
      key: 'whats-this',
      header: 'What is this Disease?',
      description:
        'Dementia is a category of diseases that includes Alzheimer’s and multiple other cognition impaired diagnoses. For purposes of this article, we will use the term “dementia” when speaking in general terms about any disease with cognition impairments. When this disease begins, it’s like the person’s brain is a ball of yarn beginning to unravel and even break apart in multiple spots. Sometimes it unravels slowly and sometimes it happens more quickly. It begins with the yarn on the outside of the ball – the person’s short-term memory. The first signs of dementia are typically the person forgetting things in the past few hours, days, months, or years while the long-term memory stays intact. As the disease progresses, the long-term memory is also impacted. Sometimes the person with the disease may remember their daily tasks or appear to be acting “back to normal”. Unfortunately, this is also a typical part of the disease progression. It is as if the person is getting better, but in reality it is like the unraveling yarn pieces connecting momentarily back together. Unfortunately, they will return back to their unraveled state.',
    },
    {
      key: 'tips-dementia',
      header: 'Tips to Help Care for Those with Dementia',
      description:
        'Depending on how far along the individual you are caring for is with the disease process, you may have also noticed some physical changes. For example, they may startle easily, tire more quickly, or have difficulty with motor skills such as using a can opener or completing tasks that require the use of tools. The reason for this goes back to the analogy of the yarn unraveling or breaking apart. When we are young, we learn tasks such as tying our shoe or writing. Once those skills or tasks are learned they are saved as a memory for us – like the yarn connecting. Dementia essentially disconnects that yarn; tasks that were once simple can no longer be completed. Here are a few tips to help in caring for those with this disease.',
    },
    {
      key: 'approach-person',
      header: 'Always approach the person from the front',
      description:
        'Did you know our brains speak to our eyes to tell us where to look and determine what we are seeing? Dementia impacts vision and peripheral view. Think about what you can see as you are sitting here reading. When you look forward, you can still see objects to your right and left. Dementia will often impact a person’s peripheral vision and they will startle easily if you approach from the side or from behind.',
    },
    {
      key: 'activities-childhood',
      header: 'Choose activities to watch or do that were enjoyed by the person during childhood',
      description:
        'Long-term memories, especially those that were a part of enjoyed activities when they were younger, tend to stay intact the longest. As a result, card or board games can often be something to enjoy together – especially if the game was one that was played early on in the individual’s childhood. For example, go fish, dominos, or simple matching type games can be played even with those who have severe cognition impairments. If they loved sports, you could try attending a sporting event or watch it on TV.',
    },
    {
      key: 'activities-music',
      header: 'Play, sing, or listen to familiar music',
      description:
        'Music can be a great way to help with memory recall and is often one of the last portions of the brain to be impacted by the disease. Music that was played, sung, or listened to when the individual was younger can often bring up good memories. You may be surprised that singing familiar songs, even for those in advanced stages of the disease, can allow them to recall melodies, words, and how their hands may have moved when playing an instrument.',
    },
    {
      key: 'remember-always-right',
      header: 'What they remember is always right',
      description:
        'Incorrect memory recall can be really frustrating for someone who knows the story they are sharing is incorrect or combined with multiple memories. False memories or incorrect memory recall is common in both beginning and advanced stages of dementia. For example, characters in a TV program that was recently watched may begin to feel like memories that occurred in their life. Correcting the individual or telling them that their memory is wrong typically only leads to anger and frustration. Plus, if they really think they are the hero in a movie they recently watched – it may be enjoyable to play along and get into character with them.',
    },
    {
      key: 'avoid-memory-loss',
      header: 'Avoid calling attention to their memory loss',
      description:
        'Have you been in a conversation with someone who asked you the same question multiple times? This is often one of the first recognizable signs of Dementia. Pointing out that they have already asked you a question can lead to frustration or sadness for that person. Consider making a game out of it for yourself by coming up with slightly different responses each time. Since short-term memories—especially those that have happened in the last few minutes, hours, or days—are often the first ones lost during the disease process, they are unlikely to remember any of your responses.',
    },
  ],
};
