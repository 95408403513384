import styled from 'styled-components';

export const ProfilePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
  overflow: hidden;
`;

export const NotificationSettingFormContainer = styled.div`
  width: 100%;
  padding-top: 90px;
  @media screen and (max-width: 350px) {
    padding-top: 60px;
  }
`;

export const NotificationSettingForm = styled.form`
  width: 100%;
  text-align: center;
`;

export const PageTitle = styled.h1`
  color: #4a4f7f;
  font-size: 28px;
  margin-top: 0;
`;

export const SupportText = styled.div`
  font-weight: bold;
  color: #7fb036;
  text-align: center;
  font-size: 16px;
  justify-self: center;
  align-self: center;
`;

export const SupportButtonContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 25px;
`;

export const SupportButton = styled.div`
  display: flex;
  justify-content: center;
  color: #4a4f7f;
  width: 150px;
  height: 35px;
  border-radius: 25px;
  border-color: #4a4f7f;
  border-style: solid;
  justify-self: center;
  align-items: center;
`;

export const InputStyle = styled.input`
  border-radius: 10px;
  padding-left: 10px;
  height: 36px;
  border: 2px solid #0f145b;
  color: #0f145b;
  margin-bottom: 10px;
`;

export const SubmitButton = styled.input`
  background-color: ${(props) => (props.disabled ? '#979CCC' : '#4a4f7f')};
  color: white;
  width: 219px;
  height: 35px;
  border: 1px solid ${(props) => (props.disabled ? '#979CCC' : '#4a4f7f')};
  border-radius: 25px;
  margin: 30px auto 20px auto;
`;

export const Label = styled.label`
  color: #4a4f7f;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  width: 100%;
`;

export const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 0 25px;
  margin-top: -30px;
`;

export const HeaderText = styled.h3`
  color: #4a4f7f;
  font-size: 28px;
  font-weight: normal;
  margin-top: 15px;
`;

export const NotificationSetting = styled.div`
  border: 1px solid #d0d0d0;
  background: transparent;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 15px 20px;
  border-radius: 10px;
`;
