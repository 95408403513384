import { atom, getDefaultStore, useAtom } from 'jotai';
import { Singleton } from 'Core_Helpers/Singleton';
import { TodoListDataService } from './services/TodoListDataService';
import { appInsights } from 'Core_Helpers/AppInsights';

export class InterventionEngineModuleStore extends Singleton {
  private todoListDataService: TodoListDataService;
  private atomStore = getDefaultStore();
  private todoListAtom;

  constructor() {
    super();

    this.todoListDataService = new TodoListDataService();
    this.todoListAtom = atom([]);
  }

  public Use = () => {
    useAtom(this.todoListAtom);

    return this;
  };

  public get TodoList(): Array<any> {
    return this.atomStore.get(this.todoListAtom);
  }

  public getTodoList = async () => {
    const existingList = await this.todoListDataService.checkForTodaysTodoList();
    if (existingList) {
      this.atomStore.set(this.todoListAtom, existingList?.response ?? []);
      return;
    }

    const isReadyToGenerateNewList = await this.todoListDataService.isReadyToGenerateNewList();

    if (isReadyToGenerateNewList) {
      const response = await this.todoListDataService.generateNewToDoList();

      if (response) {
        this.atomStore.set(this.todoListAtom, response?.response ?? []);
      }
    }
  };

  public startTodoItem = async (item: any) => {
    const response = await this.todoListDataService.completeTodoListItem({ name: item?.name });

    if (response) {
      this.atomStore.set(this.todoListAtom, response?.response);
    }

    appInsights.trackEvent({ name: `todo-${item?.key}` });
  };
}
