import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link || 'div')`
  &:hover {
    text-decoration: none;
  }
`;

export const StyledExternalLink = StyledLink.withComponent('a');
