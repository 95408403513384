import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { appInsights } from 'Core_Helpers/AppInsights';
import PropTypes from 'prop-types';
import { Tab } from '@mui/material';
import { HOME } from 'Core_Pages/Routes/RoutesConfig';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const HomeNavbarItem = ({ onClick, ...props }) => {
  const history = useHistory();
  const intl = useIntl();

  const { selected } = props;

  const onNavItemClick = (event) => {
    onClick(event);
    history.push(HOME);
    appInsights.trackEvent({ name: 'Explore_Clicked' });
  };

  const Icon = selected ? HomeIcon : HomeOutlinedIcon;

  return (
    <>
      <Tab
        {...props}
        icon={<Icon aria-hidden="true" />}
        label={intl.formatMessage({ id: 'menu-header.explore' })}
        href={HOME}
        aria-current={selected}
        onClick={onNavItemClick}
      />
    </>
  );
};

HomeNavbarItem.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default HomeNavbarItem;
