import { appInsights } from 'Core_Helpers/AppInsights';
import axios from 'axios';
import urlcat from 'urlcat';

export class VimeoVideoService {
  private static _instance: VimeoVideoService = new VimeoVideoService();

  constructor() {
    if (VimeoVideoService._instance) {
      return VimeoVideoService._instance;
    }
  }

  public getVimeoAuthorization = async () => {
    const creds = 'basic ' + btoa(`${process.env.VIMEO_CLIENT_ID}:${process.env.VIMEO_CLIENT_SECRET}`);

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: creds,
        Accept: 'application/vnd.vimeo.*+json;version=3.4',
      },
    };

    const data = {
      grant_type: 'client_credentials',
      scope: 'public',
    };

    const vimeoAuthorize = await axios.post(process.env.VIMEO_AUTH_URL as string, data, headers);
    return vimeoAuthorize?.data?.access_token;
  };

  public get = async (vimeoAuthTokenAtom: string, url: string) => {
    const config = {
      headers: {
        Authorization: `Bearer ${vimeoAuthTokenAtom}`,
      },
    };

    try {
      const vimeoShowcaseURL = urlcat(process.env.VIMEO_ALBUMS_URL as string, url);
      const result = await axios.get(vimeoShowcaseURL, config);

      return result?.data?.data;
    } catch (e) {
      appInsights.trackTrace({ message: 'Diagnostic: My Video Tools - Error retrieving video showcase' + e });
    }
  };
}
