import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';
import { StyledCard, StyledFormGroup } from './styles';

const SelectCard = forwardRef(
  (
    { inputProps, onChange, label, multi, filteredOptions, options, className, ActiveIcon, InactiveIcon, ...props },
    ref,
  ) => {
    const intl = useIntl();
    const defaultValue = multi ? {} : '';
    const classNames = `${className}-card ${className}`;
    const [cards, setCards] = useState(defaultValue);
    const cardOptions = filteredOptions ?? options;
    const onCardChange = (e) => {
      const chosenOption = e.currentTarget.name;
      const optionData = multi ? { ...cards, [chosenOption]: !cards[chosenOption] } : chosenOption;
      setCards(optionData);
      onChange(e);
    };

    return (
      <FormControl>
        <StyledFormGroup
          ref={ref}
          aria-labelledby={props['aria-labelledby']}
          className={`${classNames}-group`}
          {...inputProps}
        >
          {cardOptions.map(({ name, text, textId, ariaLabel, value, sx }) => {
            const isChecked = multi ? !!cards[name] : cards === name;
            const cardProps = {
              className: isChecked ? `${classNames}-checked` : `${classNames}-unchecked`,
              sx: sx,
            };
            const ActiveCard = ActiveIcon ?? StyledCard;
            const InactiveCard = InactiveIcon ?? StyledCard;
            const CardIcon = isChecked ? ActiveCard : InactiveCard;
            const StyledCardIcon = (
              <CardIcon {...cardProps}>
                <Typography className={`${classNames}-label`}>
                  {text ? text : textId ? intl.formatMessage({ id: textId }) : ''}
                </Typography>
              </CardIcon>
            );

            return (
              <FormControlLabel
                key={name}
                aria-label={ariaLabel ? intl.formatMessage({ id: `${ariaLabel}` }) : text}
                control={
                  <Checkbox
                    checked={isChecked}
                    icon={StyledCardIcon}
                    checkedIcon={StyledCardIcon}
                    onChange={onCardChange}
                    name={name}
                    label={label}
                    value={value}
                  />
                }
              />
            );
          })}
        </StyledFormGroup>
      </FormControl>
    );
  },
);

SelectCard.displayName = `SelectCard`;

SelectCard.propTypes = {
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string,
  multi: PropTypes.bool,
  ref: PropTypes.object,
  className: PropTypes.string,
  ActiveIcon: PropTypes.element,
  InactiveIcon: PropTypes.element,
  options: PropTypes.array,
  filteredOptions: PropTypes.array,
  ['aria-labelledby']: PropTypes.string,
};

export default SelectCard;
