import { threeGoodThings } from 'Assets/img/my-tools-icons';

export const threeGoodThingsEs = {
  key: 'three-good-things',
  title: '3 Cosas Buenas',
  titleImage: threeGoodThings,
  titleImageAlt: '',
  description:
    '¡Dedicar un tiempo a recordar personas, eventos o cosas que nos hicieron sentir bien puede aumentar la sensación de bienestar!',
  contentId: 5,
  sections: [
    {
      key: 'bed-good-things',
      header: 'Antes de ir a dormir, piensa en alrededor de 3 cosas buenas que sucedieron hoy.',
      description: '',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Al final del día, especialmente antes de ir a dormir, dedica unos minutos para pensar en 3 cosas buenas que experimentaste hoy.',
        },
        {
          text: 'Las cosas buenas pueden ser grandes o pequeñas, como tomar una buena taza de café, o que alguien te abra la puerta, ¡o incluso tener una conversación agradable con alguien en la fila en la caja de la tienda de abarrotes!',
        },
        {
          text: 'Algunos ejemplos de preguntas que podrías formularte son:',
          subItems: [
            { text: '¿Qué 3 cosas salieron bien hoy?' },
            { text: '¿Qué experimenté que me hizo sentir bien?' },
            { text: '¿Qué sucedió que fue positivo?' },
          ],
        },
      ],
    },
    {
      key: 'write-down',
      header: 'Anota estas cosas.',
      description: '',
      itemsHeader: 'Cuéntame más',
      items: [
        { text: 'Anótalas en lápiz y papel, o en tu computadora/dispositivo.' },
        { text: 'Enuméralas solo desde tu perspectiva personal.' },
        { text: '¡La ortografía y la gramática no son importantes!' },
      ],
    },
    {
      key: 'self-reflection-pointers',
      header: 'Autorreflexión',
      description:
        'Luego, dedica un momento de tranquilidad para reflexionar sobre cada una de esas cosas y revisa tu experiencia.',
      itemsHeader: 'Percepciones y marcadores del camino',
      items: [
        { text: '¿Cómo te sentiste en ese momento?' },
        { text: '¿Cómo te hacen sentir ahora cuando las recuerdas?' },
        { text: '¿Notas alguna sensación física en tu cuerpo?' },
        { text: '¿Qué hiciste para que sucedieran esas cosas buenas?', divider: true },
        {
          text: 'Ser consciente de las cosas buenas y los eventos que suceden puede generar más gratitud y aprecio en la vida. Incluso las cosas pequeñas y positivas pueden ayudarte a fortalecerte cuando surgen desafíos.',
        },
        {
          text: '¡Antes de irte a la cama esta noche,fortalece tu camino para triunfar! Anota tus 3 cosas buenas personales de hoy.',
        },
      ],
    },
  ],
};
