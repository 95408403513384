import {
  THRIVEASSESSMENT,
  THRIVEASSESSMENTRESULTS,
  THRIVEPATHWAY,
  THRIVECONGRATS,
  THRIVEACTIVITIES,
  HOME,
} from 'Core_Pages/Routes/RoutesConfig';
import { thriveWorkflowTypes } from '../enums';
import { thriveContentTypes, thriveContentUrls } from './thriveContent';

export const workflowTypes = {
  ASSESSMENT_WEEK1: 'ASSESSMENT_WEEK1',
  ASSESSMENT_WEEK8: 'ASSESSMENT_WEEK8',
  CONGRATS: 'CONGRATS',
};

export const workflows = {
  [thriveWorkflowTypes.DEFAULT]: {
    steps: {
      [THRIVEPATHWAY]: THRIVEACTIVITIES,
    },
  },
  [workflowTypes.CONGRATS]: {
    steps: {
      [THRIVEPATHWAY]: THRIVECONGRATS,
    },
  },
  [workflowTypes.ASSESSMENT_WEEK1]: {
    resultsOverviewId: 'thrive.plan-ready',
    steps: {
      [THRIVEPATHWAY]: thriveContentUrls[thriveContentTypes.WELCOME],
      [thriveContentUrls[thriveContentTypes.WELCOME]]: THRIVEASSESSMENT,
      [THRIVEASSESSMENT]: THRIVEASSESSMENTRESULTS,
      [THRIVEASSESSMENTRESULTS]: thriveContentUrls[thriveContentTypes.OVERVIEW],
      [thriveContentUrls[thriveContentTypes.OVERVIEW]]: THRIVEACTIVITIES,
    },
  },
  [workflowTypes.ASSESSMENT_WEEK8]: {
    resultsOverviewId: 'thrive.plan-ready-week8',
    steps: {
      [THRIVEPATHWAY]: thriveContentUrls[thriveContentTypes.CHECKIN],
      [thriveContentUrls[thriveContentTypes.CHECKIN]]: THRIVEASSESSMENT,
      [THRIVEASSESSMENT]: THRIVEASSESSMENTRESULTS,
      [THRIVEASSESSMENTRESULTS]: THRIVECONGRATS,
      [THRIVECONGRATS]: HOME,
    },
  },
};
