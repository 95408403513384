import { caringConnections } from 'Assets/img/my-tools-icons';

export const threeCaringConnectionsEs = {
  key: 'three-caring-connections',
  title: '3 Conexiones Cariñosas',
  titleImage: caringConnections,
  titleImageAlt: '',
  description:
    'Las interacciones positivas con otras personas pueden acentuar los sentimientos de bienestar incondicional y conexión social',
  contentId: 3,
  sections: [
    {
      key: 'three-meaningful',
      header: 'Cada día de la próxima semana, ten 3 interacciones significativas con otras personas.',
      description:
        'Busca 3 oportunidades en las que puedas interactuar y conectar con otras personas durante la próxima semana.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          text: 'Con quiénes interactúes depende de ti: pueden ser amigos, parientes, compañeros de trabajo o hasta desconocidos.',
        },
        { text: 'Tus interacciones pueden ser con 3 personas diferentes o con la misma persona.' },
        { text: 'Emprende tus interacciones con calidez, respeto y buena voluntad hacia la otra persona.' },
        {
          text: 'Haz un esfuerzo por estar presente, escucha con respeto a la otra persona con el corazón abierto y haz contacto visual.',
        },
        { text: 'Trata de socializar con pensamientos y sentimientos alegres.' },
      ],
    },
    {
      key: 'recall-interactions',
      header: 'Luego, cada noche, antes de acostarte, rememora tus interacciones.',
      description: 'Recuerda tus 3 interacciones y evalúa tu conexión con la otra persona durante la interacción.',
      itemsHeader: 'Cuéntame más',
      items: [
        { text: 'Piensa en lo conectado que te sentiste con la otra persona durante tus interacciones.' },
        {
          text: 'A continuación, ya sea en tu cabeza o en un papel, haz una escala de valoración del 1 al 7, en la que “1” significa “Nada cierto” y “7” significa “Muy cierto”, y califica si en tu conexión con la otra persona te sentiste...',
          subItems: [{ text: '“en sintonía” con la otra persona' }, { text: 'cerca de la otra persona' }],
        },
      ],
    },
    {
      key: 'self-reflection-insights',
      header: 'Autorreflexión',
      description: 'Tómate un tiempo para revisar tu lista y experiencia con esta actividad.',
      itemsHeader: 'Percepciones y marcadores del camino',
      items: [
        { text: '¿Por qué te sentiste conectado con la otra persona?' },
        { text: '¿Por qué no te sentiste tan conectado con la otra persona?' },
        { text: '¿Qué cosas podrías hacer la próxima vez para mejorar tu(s) conexión(es)?' },
        { text: '¿Qué has aprendido de este ejercicio?' },
        { text: '¿Cómo te sentías antes de completar este ejercicio?' },
        { text: '¿Cómo te sientes después de hacerlo?' },
        { text: '¿Qué partes de este ejercicio te resultaron más difíciles? ¿Por qué?' },
        { text: '¿Qué disfrutaste más de este ejercicio?' },
        { text: '¿En qué aspectos/elementos del desafío/objetivo deseas trabajar en primer lugar?' },
        { text: '¿Qué ayudaría a facilitar el primer paso?' },
        {
          text: '¿Cuáles serían algunos de los beneficios de que las personas realicen esta actividad con más frecuencia en general?',
          divider: true,
        },
        {
          text: 'Crear interacciones significativas con los demás, incluso en pequeños “micromomentos”, puede favorecer el bienestar y potenciar las emociones positivas diarias.',
        },
        {
          text: 'Al interactuar con otras personas de manera que te sientas ‘en sintonía’ o cerca de ellas, puedes generar emociones positivas.',
        },
      ],
    },
  ],
};
