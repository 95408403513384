import { THRIVECONTENT } from 'Core_Pages/Routes/RoutesConfig';
import urlcat from 'urlcat';
import { andy, mytools, pyxir, plant } from 'Assets/img/thrive-icons';
import pyxirAndRudy from 'Assets/img/pyxir-and-rudy-side-by-side.png';
import { contentTypes } from 'Core_Pages/Thrive/ThriveContent/enums';
import * as permissions from 'Core_Helpers/authentication/PermissionsConfig';
import Plant from 'Assets/img/plant.svg';
import { StyledContainerLayout } from './styles';
import Button from 'Stories/components/Button';

export const thriveContentTypes = {
  WELCOME: 'WELCOME',
  OVERVIEW: 'OVERVIEW',
  CHECKIN: 'CHECKIN',
};

export const thriveContentUrls = {
  [thriveContentTypes.WELCOME]: urlcat(THRIVECONTENT, { thriveContentType: 'welcome' }),
  [thriveContentTypes.OVERVIEW]: urlcat(THRIVECONTENT, { thriveContentType: 'overview' }),
  [thriveContentTypes.CHECKIN]: urlcat(THRIVECONTENT, { thriveContentType: 'check-in' }),
};

export const thriveContent = {
  [thriveContentTypes.WELCOME]: {
    permissions: permissions.THRIVEASSESSMENT,
    title: 'title.thrive-welcome',
    header: 'thrive.lets-thrive',
    gridContainerProps: {
      sx: {
        height: '100%',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'flex-start',
        display: 'flex',
      },
    },
    content: [
      {
        contentType: contentTypes.TEXT,
        textId: 'thrive.excited',
        gridProps: { style: { flex: 0, marginTop: '10px' } },
      },
      {
        contentType: contentTypes.TEXT,
        textId: 'thrive.begin',
        gridProps: { style: { flex: 0 } },
      },
      {
        contentType: contentTypes.TEXT,
        textId: 'thrive.be-back-after',
        gridProps: { style: { flex: 0 } },
      },
      {
        contentType: contentTypes.IMAGE,
        src: Plant,
        altId: 'thrive.image.plant',
        imageStyle: {
          width: '33%',
          height: '100%',
          margin: '0 auto',
        },
        gridProps: { style: { flex: 1, width: '100%' } },
      },
    ],
    buttonText: 'thrive.do-screening',
  },
  [thriveContentTypes.CHECKIN]: {
    permissions: permissions.THRIVEASSESSMENT,
    title: 'title.thrive-check-in',
    header: 'thrive.lets-thrive',
    content: [
      {
        contentType: contentTypes.IMAGE,
        src: pyxirAndRudy,
        altId: 'thrive.image.pyxir-and-rudy',
      },
      {
        contentType: contentTypes.TEXT,
        textId: 'thrive.check-in',
      },
    ],
    buttonText: 'thrive.do-screening',
  },
  [thriveContentTypes.OVERVIEW]: {
    permissions: permissions.THRIVEASSESSMENT,
    title: 'title.thrive-welcome',
    header: 'thrive.overview.header',
    headerProps: {
      style: {
        alignSelf: 'center',
        marginBottom: '10px',
      },
    },
    gridContainerProps: {
      sx: {
        rowGap: 1,
      },
    },
    ContainerComponent: StyledContainerLayout,
    CustomButton: Button,
    buttonProps: {
      fullWidth: true,
    },
    content: [
      {
        contentType: contentTypes.TEXT,
        textId: 'thrive.overview.next-eight-weeks',
        lineHeight: 1.2,
        sx: {
          textAlign: 'center',
          width: '80%',
          margin: '0 auto',
        },
      },
      {
        contentType: contentTypes.LIST_IMAGE,
        textId: 'thrive.overview.andy',
        items: [
          { textId: 'thrive.overview.andy.title', props: { fontWeight: 700 } },
          {
            textId: 'thrive.overview.andy.description',
          },
        ],
        src: andy,
        altId: 'image.andy.portrait',
        lineHeight: 1.2,
        listGridProps: { style: { minWidth: '70px' } },
        imageStyle: { maxWidth: '70px' },
        gridGroupProps: {
          paddingBottom: '10px',
          borderBottom: '1px solid #979797',
          direction: 'row',
          alignItems: 'center',
        },
      },
      {
        contentType: contentTypes.LIST_IMAGE,
        textId: 'thrive.overview.my-tools',
        items: [
          { textId: 'thrive.overview.my-tools.title', props: { fontWeight: 700 } },
          {
            textId: 'thrive.overview.my-tools.description',
          },
        ],
        src: mytools,
        altId: 'image.my-tools.portrait',
        lineHeight: 1.2,
        listGridProps: { style: { minWidth: '70px' } },
        imageStyle: { maxWidth: '70px' },
        gridGroupProps: {
          paddingBottom: '10px',
          borderBottom: '1px solid #979797',
          direction: 'row',
          alignItems: 'center',
        },
      },
      {
        contentType: contentTypes.LIST_IMAGE,
        textId: 'thrive.overview.progress-area',
        items: [
          { textId: 'thrive.overview.program-tracker.title', props: { fontWeight: 700 } },
          {
            textId: 'thrive.overview.program-tracker.description',
          },
        ],
        src: plant,
        altId: 'thrive.image.plant',
        lineHeight: 1.2,
        listGridProps: { style: { minWidth: '70px' } },
        imageStyle: { maxWidth: '70px' },
        gridGroupProps: {
          paddingBottom: '10px',
          borderBottom: '1px solid #979797',
          direction: 'row',
          alignItems: 'center',
        },
      },
      {
        contentType: contentTypes.LIST_IMAGE,
        textId: 'thrive.overview.pyxir',
        src: pyxir,
        altId: 'image.pyxir.smiling',
        lineHeight: 1.2,
        listGridProps: { style: { minWidth: '70px' } },
        imageStyle: { maxWidth: '70px' },
        items: [
          { textId: 'thrive.overview.pyxir.title', props: { fontWeight: 700 } },
          {
            textId: 'thrive.overview.pyxir.description',
          },
        ],
        gridGroupProps: {
          paddingBottom: '10px',
          borderBottom: '1px solid #979797',
          direction: 'row',
          alignItems: 'center',
        },
      },
      {
        contentType: contentTypes.TEXT,
        textId: 'thrive.overview.not-alone',
        lineHeight: 1.2,
        sx: {
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
          width: '80%',
          margin: '0 auto',
          marginBottom: '15px',
        },
      },
    ],
    buttonText: 'thrive.overview.button',
  },
};
