import { playerActorType } from 'Core_Games/constants/playerActorType';
import { actorType } from '../constants/actorType';

// For adding character actors which are more complex and may have a palette, inventory, sounds, etc
export const addCharacterActor = (game, startLocationX, startLocationY, characterType) => {
  const currentType = playerActorType[characterType];
  const actorConfig = game.engine.engineAssets.actors.find((x) => x.NAME == characterType);
  game.currentSpriteSheet = game.isWebGL && actorConfig.pallette ? actorConfig.pallette : undefined;
  let newActor =
    actorConfig.ACTORTYPE == actorType.OBJECT
      ? new currentType.DATA(game, startLocationX, startLocationY, game.currentSpriteSheet, game.pet.getName())
      : new currentType.DATA(game, startLocationX, startLocationY, game.currentSpriteSheet);
  game.playerActors.push(newActor);

  // equip any items
  if (newActor.Inventory) {
    for (let attachPoint in newActor.Inventory) {
      var currentAttachment = newActor.Inventory[attachPoint];
      for (let i = 0; i < currentAttachment.length; i++) {
        if (currentAttachment[i].NAME && !currentAttachment[i].OBJECT) {
          let currentObject = game.add.sprite(0, 0, game.gameData.gameType.ATLAS, currentAttachment[i].DATA);
          newActor.equip(attachPoint, currentAttachment[i].NAME, currentObject);
        }
      }
    }
  }

  // add sounds
  if (newActor.Sounds) {
    for (let sound in newActor.Sounds) {
      let currentSound = newActor.Sounds[sound];
      if (!currentSound.OBJECT) {
        newActor.Sounds[sound].OBJECT = game.sound.add(currentSound.NAME);
      }
    }
  }

  return newActor;
};
