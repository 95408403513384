import { griefLossTransitions } from 'Assets/img/my-tools-icons';

export const griefAndLossEs = {
  key: 'grief-and-loss-es',
  title: 'Duelo y pérdida durante las transiciones',
  titleImage: griefLossTransitions,
  titleImageAlt: '',
  contentId: 34,
  sections: [
    {
      key: 'grief-and-loss-intro-es',
      description:
        'Uno de los desafíos más difíciles que te toca vivir como cuidador es el manejo emocional de las necesidades de un ser querido mientras anticipas su pérdida y, en algunos casos, experimentar activamente la pérdida cognitiva de alguien que es importante para ti. Las emociones pueden verse y sentirse similares al proceso de duelo después de la pérdida de un ser querido. Exploraremos los signos comunes del duelo que pueden ayudarte a seguir adelante. Esta información sirve para todos los tipos de duelo y también puede utilizarse si estás experimentando otros tipos de pérdida.',
    },
    {
      key: 'stages-grief-loss-es',
      header:
        'Las etapas y los sentimientos del duelo y la pérdida Las etapas y los sentimientos del duelo y la pérdida',
      description:
        'Existen diversas escuelas de pensamiento acerca de las etapas y los sentimientos que uno experimenta durante una pérdida. Proporcionamos descripciones breves de algunas de estas etapas. Es importante destacar que la forma en que las personas experimentan el duelo es única; nadie atraviesa el duelo de la misma manera, y eso está bien. También es completamente normal que entres y salgas de las diferentes etapas y sentimientos o que permanezcas mucho tiempo en una o varias de las etapas.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          key: 'shock-desc-es',
          description:
            '<b>Conmoción</b><br />Sin importar si la pérdida es esperada o no, puede resultar impactante para ti y tu cuerpo cuando sucede el evento real. Esto también puede ocurrir si recibes un nuevo diagnóstico u otras noticias no deseadas. A veces, esta parte se describe como una sensación de entumecimiento o falta de sensibilidad, o puede parecer que estás caminando en medio de la niebla. Cuando te encuentras en esta etapa, puede resultarte difícil realizar algunas de tus funciones diarias. Es posible que te sientas olvidadizo/a o que no puedas realizar tareas simples. El llanto y los arrebatos de ira son normales, además de sentirte apático/a o tener menos interés en cosas que antes te gustaban. Es posible que tengas dificultad para dormir, pérdida de apetito o un fuerte deseo de comer o beber en exceso.',
        },
        {
          key: 'denial-bargaining-es',
          description:
            '<b>Negación y negociación</b><br />La negación y la negociación son una de las formas que tiene tu mente de intentar protegerte de la pérdida o de una pérdida potencial. Es posible que sientas que tu ser querido se despertará al día siguiente con la memoria recuperada o que un milagro cambiará el diagnóstico terminal. Tu mente y tu cuerpo pueden hacerte sentir como si te estuvieran engañando al hacerte creer que esa persona aún está disponible para ti en la misma medida. De manera similar, es posible que te encuentres rogando que regrese a ti de la misma forma que solían estar.',
        },
        {
          key: 'anger-sadness-es',
          description:
            '<b>Ira y tristeza</b><br />La ira y la tristeza son dos sentimientos comunes que puedes experimentar durante múltiples etapas del duelo. Son emociones normales y naturales ante una pérdida. El momento y el lugar en el que sientes ira y tristeza pueden sorprenderte o aparecer de imprevisto. Las tareas simples y rutinarias pueden provocar frustración y sentimientos de ira o tristeza. Puedes experimentar arrebatos de ira o tristeza o sentir que no puedes dejar de sentirte enojado o triste.',
        },
      ],
    },
    {
      key: 'processing-grief-es',
      header: 'Procesar tu duelo: encontrar aceptación y esperanza',
      description:
        'Sin importar en qué etapa del proceso de duelo te encuentres, es importante permitirte el tiempo y el espacio para sentir y procesar tu duelo. A continuación te damos algunos consejos que te ayudarán en cada una de las etapas.',
      itemsHeader: 'Cuéntame más',
      items: [
        {
          key: 'shock-process-es',
          description:
            '<b>Conmoción</b><br />Cuando estás conmocionado/a, la mejor manera de apoyarte es ser amable contigo mismo/a y permitir que otros te ayuden. Reconoce que no eres la persona mejor preparada para realizar algunas de tus tareas diarias y permítete pedir y aceptar ayuda.',
        },
        {
          key: 'denial-bargaining-process-es',
          description:
            '<b>Negación y negociación</b><br />Es importante no apresurar ninguna de las etapas del duelo y la pérdida, incluida esta etapa. Negar o negociar que esta pérdida no seguirá siendo real es un mecanismo de defensa normal y natural. Si bien debes permitirte estos sentimientos, si te resulta difícil no sentirte así, es hora de que busques la ayuda de un proveedor de salud mental.',
        },
        {
          key: 'anger-sadness-process-es',
          description:
            '<b>Ira y tristeza</b><br />Estos son los sentimientos más comunes de duelo y pérdida, y tienden a ser los que perduran por más tiempo. Para encontrar esperanza y aceptación, necesitas expresar y liberar tu enojo y tristeza. Algunos ejemplos de formas saludables de liberar la ira pueden ser hacer ejercicio, practicar actividades de atención plena o relajación (puedes encontrar un par de ejemplos en nuestra aplicación) o participar en actividades físicas para liberar la ira, como kick-boxing, lanzamiento de hacha o simplemente golpear una almohada. Llorar o hablar de la persona que perdiste por lo general ayuda a liberar la tristeza. En esta etapa también es más difícil reconocer cuándo necesitas ayuda para procesarlo. Si tienes poco interés en actividades que suelen ser placenteras o tienes dificultades para levantarte de la cama, es hora de que busques a un proveedor de salud mental que te apoye.',
        },
      ],
    },
  ],
};
