import { calmMind } from 'Assets/img/my-tools-icons';

export const calmYourMindEn = {
  key: 'calm-your-mind',
  title: 'Calm Your Mind',
  titleImage: calmMind,
  titleImageAlt: '',
  description:
    'Spending time in nature can be an uplifting way to refresh your mental, emotional, and physical well-being!',
  contentId: 10,
  sections: [
    {
      key: 'plan-walk',
      header: 'Think of a nice outdoor area and plan to go there for a walk.',
      description: 'Try to find a calm, natural setting and take yourself on a relaxed walk there.',
      itemsHeader: 'Tell me more:',
      items: [
        {
          text: 'Choose a location near you such as a wooded area, city park, outdoor activity area, beachfront, mountain path, rural country area, etc.',
        },
        {
          text: 'Plan ahead to be safe while you walk; dress comfortably, wear good-fitting walking shoes, bring water and possibly some snacks, and of course, know the weather conditions!',
        },
        { text: 'The length of your walk is up to you.' },
      ],
    },
    {
      key: 'take-walk',
      header: 'Visit the location and take a relaxing walk there.',
      description:
        'Move mindfully through the area and allow yourself to leisurely take it in, enjoy yourself, and appreciate the environment.',
      itemsHeader: 'Tell me more:',
      items: [
        {
          text: 'Helpful hints for mindfully visiting and walking in the area...',
          subItems: [
            {
              text: 'Before you start to walk, put your cell phone on mute and take a few moments to quiet your mind.',
            },
            {
              text: 'Turn your attention to the natural environment around you and take a few deep breaths. Just be there in the moment.',
            },
            {
              text: 'Walk with an open heart at whatever pace allows you to stay present, safe, and aware of the larger environment around you. Continue for as long as it feels comfortable.',
            },
            {
              text: 'Pause now and then to let yourself experience the surroundings. If something catches your attention, allow yourself time to view and acknowledge it. Allow the environment to unfold in a free-flowing, easy way as you walk.',
            },
            {
              text: 'Open your senses to the outdoors; tune in using your whole body and feel each sensation. Notice how the air feels – is it warm or cool? Damp or dry? Notice the sounds you hear and the scents you smell.',
            },
            {
              text: 'Simply notice your sensations without judging, labeling, or being distracted by if you find them pleasant or unpleasant. ',
            },
            {
              text: 'Consider stepping outside of normal ‘clock time’ and open to nature’s organic rhythms. Notice how you feel connecting with nature.',
            },
            {
              text: 'At the end of your walk, stand still for a moment. Notice how you feel. Check in with each one of your senses. Note what you are aware of and your mood. ',
            },
            {
              text: 'End your walk giving gratitude and appreciation to nature, the environment, nature, and your body.',
            },
          ],
        },
      ],
    },
    {
      key: 'self-reflection',
      header: 'Self-Reflection',
      description: 'Take some quiet time to review your experience with this activity.',
      itemsHeader: 'Awareness Insights',
      items: [
        {
          text: 'What have you learned from this exercise?',
        },
        { text: 'How did you feel before completing this exercise?' },
        { text: 'How do you feel after it?' },
        { text: 'What part(s) of this exercise did you find most challenging? Why?' },
        { text: 'What did you enjoy most about this exercise?' },
        { text: 'What would help make the first step easier?' },
        { text: 'What would be some benefits of doing this activity more often in general?' },
      ],
    },
    {
      key: 'self-reflection-pointers',
      itemsHeader: 'Pathway Pointers',
      items: [
        {
          text: 'Walking through nature peacefully can help refresh the mind, body, and emotions in healthful ways.',
        },
        {
          text: 'Spending time in nature can help focus awareness on the present moment and provide a supportive space to calmly acknowledge and accept feelings, thoughts, and physical sensations.',
        },
        {
          text: 'The beauty of nature can help connect us with the natural rhythms of the world and can provide an enriching, awe-inspiring experience boosting happiness, compassion, and interconnectedness.',
        },
        {
          text: 'Nature’s everyday transitions and abundance can help remind us of our potential to adapt, collaborate, problem solve, and evolve.',
        },
        {
          text: 'Take some time outside in nature to help renew, regenerate, and boost your well-being on your Thrive Pathway!',
        },
      ],
    },
  ],
};
